export const dashboardAnalytics = {
    /* @ngInject */
    controller: function() {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            // TODO
            this.charts = [
                {
                    id: 'chart1',
                    title: 'Xray-Compliance',
                    icon: 'xray-compliance.svg',
                    colorName: 'Xray-Compliance',
                    style: 'background-color: #20951b',
                },
                {
                    id: 'chart2',
                    title: 'Employees',
                    icon: 'accounts_employees.svg',
                    colorName: 'Employees',
                    style: 'background-color: #e60084',
                },
                {
                    id: 'chart3',
                    title: 'Personal Dosimetry',
                    icon: 'personal-dosimetry.svg',
                    colorName: 'PersonalDosimetry',
                    style: 'background-color: #3f51b5',
                },
                {
                    id: 'chart4',
                    title: 'Accreditations',
                    icon: 'accreditation_polices_procedures.svg',
                    colorName: 'Accreditations',
                    style: 'background-color: #a934e4',
                },
            ];
        }

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            // TODO
        };
    },
    template: require('./dashboard-analytics.html'),
};
