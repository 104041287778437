import { TabsReducer } from '../../common/tabs/tabs.reducer';

export const tabs = {
    'public.safetyTraining.laser.video': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'public.safetyTraining.laser.video.player',
                title: 'Training Video Review',
                visible: true,
                disabled: false,
                loading: false,
                buttons: []
            },
            {
                state: 'public.safetyTraining.laser.video.acknowledgement',
                title: 'Training Completed',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    execute: { label: 'Confirm' }
                }
            },
            {
                state: 'public.safetyTraining.laser.video.confirmation',
                title: 'Training Confirmation',
                visible: false,
                disabled: true,
                loading: false,
                buttons: []
            }
        ]
    }
};
