import { GridController } from '../../../../../../common/grid/grid.controller';

/* @ngInject */
export function AdministrationAccountsDepartmentsGridController($controller, $ngRedux, $state, NavigationService, AdministrationActions) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'administration';
        ctrl.identifier = 'administrationAccountsDepartmentsGrid';
        ctrl.filterComponent = false;
        ctrl.exportComponent = false;

        ctrl.columns = [
            {title: '#',            mapper: null},
            {title: 'ID',           mapper: 'id'},
            {title: 'Department',   mapper: 'name'},
            {title: 'Account Number', mapper: 'accountNumber'},
            //{title: 'Create Date',  mapper: 'created'},
            {title: 'Parent',       mapper: 'parentUsername'},
            {title: '',             mapper: 'warning'},
            {title: '',             mapper: 'critical'}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), AdministrationActions)(ctrl);
        ctrl.dataAction = ctrl.getDepartments;

        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
