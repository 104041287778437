import API from '../../../../common/http/api.fetch';
import {success, responseMessage} from '../../../../common/utils/util.notifier';
import { TYPES } from '../settings-action-type.constants';
//import { TYPES as ACC_TYPES } from '../../accreditation-action-type.constants';
import { IDS } from '../settings-action-id.constants';
//import { IDS as ACC_IDS } from '../../accreditation-action-id.constants';

export const getSectionDetails = (node) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/section-details',
            method: 'GET',
            params: {id: node.id}
        }).then(response => {
            dispatch({
                type:   TYPES.SECTION_DETAILS_REFRESH,
                id:     IDS.accreditationSettingsSectionDetails,
                data:   {
                    ...response.data[0],
                    id:                 node.id,
                    standardParentId:   node.standardParentId,
                    level:              node.level,
                    nodeName:           node.name,
                    prefix:             node.number,
                    siblings:           node.children.map(item => parseInt(item.number.split('.').pop())),
                    schemeCode:         node.schemeCode,
                }
            })
            // dispatch({
            //     type: ACC_TYPES.SIMPLE_STD_TREE_SELECT,
            //     standardId: node.id,
            // })
        }, error => {
            responseMessage(error);
        });
    };
}

export const addSection = (data) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/add-section',
            method: 'POST',
            data:   data,
        }).then(response => {
            dispatch({
                type:   TYPES.SECTION_DETAILS_REFRESH,
                id:     IDS.accreditationSettingsSectionDetails,
                data:   {parent_id: data.parent_id},
            });
            success('ACCREDITATION.NOTIFICATIONS.STANDARD.ADD')
        }, error => {
            responseMessage(error);
        })
    }
}

export const updateSectionDetails = (data) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/update-section',
            method: 'POST',
            data:   data,
        }).then(response => {
            dispatch({
                type:   TYPES.SECTION_DETAILS_REFRESH,
                id:     IDS.accreditationSettingsSectionDetails,
                data:   data
            });
            success('ACCREDITATION.NOTIFICATIONS.STANDARD.EDIT')
        }, error => {
            responseMessage(error);
        })
    }
}

export const deleteSection = (id) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/delete-section',
            method: 'POST',
            data:   { id },
        }).then(() => {
            success('ACCREDITATION.NOTIFICATIONS.STANDARD.DELETE')
        }, error => {
            responseMessage(error);
        })
    }
}
