import get from 'lodash/get';

/**
 * Creates a new InputRadioGrid.
 * @class
 * @extends InputController
 */
class InputRadioGrid {
    /**
     * @constructor
     * @param $ngRedux
     */
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);
    }

    onChange() {
        this.$ngRedux.dispatch({
            type: 'INPUT_RADIO_CHANGE',
            id: this.identifier,
            path: this.statePath,
            input: this.input
        });
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeModule();
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        };
    }

    mapState(state) {
        return {
            input: get(state[this.module][this.identifier], this.statePath)
        }
    }
}

InputRadioGrid.$inject = ['$ngRedux'];

export const inputRadioGrid = {
    bindings: {
        identifier: '@',
        statePath: '@',
        label: '@',
        data: '<'
    },
    controller: InputRadioGrid,
    template: `
        <md-radio-group class="gridRadioGroup" ng-model="$ctrl.input" ng-change="$ctrl.onChange()">
            <md-grid-list md-cols-xs="1" md-cols-sm="3" md-cols-md="4" md-cols-gt-md="4" md-row-height="20px" md-gutter="15px" md-gutter-gt-sm="15px">
                <md-grid-tile ng-repeat="item in $ctrl.data" md-rowspan="1" md-colspan="1" md-colspan-sm="1" md-colspan-xs="1">
                    <md-radio-button ng-value="item.value" aria-label="item.label">{{item.label}}</md-radio-button>
                </md-grid-tile>
            </md-grid-list>
        </md-radio-group>
    `
};
