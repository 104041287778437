import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../sass/style.scss'
import ROOT_MODULE from './root.module';
import { detectFeatures, fallbackTemplate } from './common/utils/util.browser';

angular.element(document).ready(() => {
    document.getElementById('loaderMessage').innerHTML = 'Checking Browser Compatibility...';

    setTimeout(() => {
        if (detectFeatures())
            angular.bootstrap(document, [ROOT_MODULE.name], {strictDi: true});
        else
            document.getElementById('loaderMessage').innerHTML = fallbackTemplate;
    }, 1000);
});
