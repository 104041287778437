/* @ngInject */
export function EquipmentProposalManager($rootScope, $mdDialog, ProposalService) {

    this.showRequestDetails = function(item) {

        $mdDialog.show({
            controller: requestDetailsCtrl,
            templateUrl: 'views/partial/proposal_request_details_dialog.html',
            parent: angular.element(document.body),
            locals: {item: item},
            clickOutsideToClose: true
        });

        function requestDetailsCtrl($scope, $rootScope, $mdDialog, item) {

            $scope.toolbarColor = $rootScope.toolbarColor;
            $scope.proposal = item;

            ProposalService.fetchProposalRequestVendors($scope.proposal.id).then(function(data) {
                $scope.proposal.vendors = data;
            });

            ProposalService.fetchProposalRequestEquipment($scope.proposal.id).then(function(data) {
                $scope.proposal.equipment = data;
            });

            $scope.cancel = function() {
                $mdDialog.cancel();
            };
        };
    };
}
