/*
*   Actions which are passed from accreditation.reducer.js to master-doc-list.reducer.js
 */

export const PREFIX = 'ACC_MASTER_DOC_LIST';

export const TYPES = {
    SET_CURRENT_FISCAL_YEAR:        `${PREFIX}_SET_CURRENT_FISCAL_YEAR`,
    FETCH_SITE_LOCATION_DETAILS:    `${PREFIX}_FETCH_SITE_LOCATION_DETAILS`,
    FETCH_DOCUMENT_DETAILS:         `${PREFIX}_FETCH_DOCUMENT_DETAILS`,
    INIT_REVIEW_STATUSES:           `${PREFIX}_INIT_REVIEW_STATUSES`,
};
