/* @ngInject */
export function EmployeesAddController($controller, $ngRedux, $scope, $rootScope, $q, $timeout, $mdDialog, AuthService, NavigationService, EmployeeService, EmployeeManager, EmployeeQualificationService, EmployeeModalityTrainingService, AccountService, LicenceService, LicenceManager, Licence, Employee, EmployeeQualification, EmployeeModalityTraining, Notifier, Grid, Location, COUNTRY_SELECT_EVENT, GENDERS, $filter) {

    angular.extend(this, $controller('EmployeesBaseController', {
        $ngRedux: $ngRedux,
        $scope: $scope,
        $rootScope: $rootScope,
        $q: $q,
        $timeout: $timeout,
        $mdDialog: $mdDialog,
        AuthService: AuthService,
        NavigationService: NavigationService,
        EmployeeService: EmployeeService,
        EmployeeQualificationService: EmployeeQualificationService,
        EmployeeModalityTrainingService: EmployeeModalityTrainingService,
        EmployeeManager: EmployeeManager,
        LicenceService: LicenceService,
        LicenceManager: LicenceManager,
        Notifier: Notifier,
        AccountService: AccountService,
        Grid: Grid,
        Employee: Employee,
        Licence: Licence,
        EmployeeQualification: EmployeeQualification,
        EmployeeModalityTraining: EmployeeModalityTraining,
        Location: Location,
        COUNTRY_SELECT_EVENT: COUNTRY_SELECT_EVENT,
        GENDERS: GENDERS,
        $filter: $filter
    }));

    $scope.MODULE_STATE = $scope.MODULE_STATE_CREATE;
    $scope.EMPLOYEE_STATE = $scope.MODULE_STATE_CREATE;

    $scope.isAdmin = AuthService.getUser().type == 'Administrator';

    $scope.tabs = _.cloneDeep(EmployeeManager.tabs);
    $scope.tabs[EmployeeManager.TAB_EMPLOYEE_DETAILS].disabled = false;
    $scope.tabs.shift();

    $scope.grantAccess = {enable: false};

    $scope.mutable = false;
    if (AuthService.getUser().employees_mutable) {
        $scope.mutable = true;
    }
    $scope.$on('LOCATION_SUBJECT_SELECTED', function(event, locationItem) {
        if (locationItem.in_employees_mutable === 1) {
            $scope.mutable = true;
        } else {
            $scope.mutable = false;
        }
        EmployeeService.getOccupations(locationItem.uuid, 'slo').then(function(items) {
            $scope.occupations = items;
        });
    });

    _.extend(_.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_DETAILS]), {
        exec: function() {
            var deferred = $q.defer();
            switch ($scope.EMPLOYEE_STATE) {
                case $scope.MODULE_STATE_CREATE:
                    EmployeeService.addEmployee($scope.employee).then(function(uuid) {
                        // TODO consider to return employee
                        $scope.employee.uuid = uuid;
                        $scope.employee.addEmploymentLocation($scope.employee.location);
                        $scope.EMPLOYEE_STATE = $scope.MODULE_STATE_UPDATE;
                        $ngRedux.dispatch({
                            type: 'EMPLOYEE_SELECTED',
                            id: 'employeesGrid',
                            uuid: $scope.employee.uuid
                        });
                        Notifier.success($filter('translate')('EMPLOYEES.ADD.NOTIFIER_SUCCESS'));
                        deferred.resolve();
                    }, function(error) {
                        deferred.reject(error);
                    });
                    break;
                case $scope.MODULE_STATE_UPDATE:
                    EmployeeService.updateEmployee($scope.employee).then(function() {
                        Notifier.success($filter('translate')('EMPLOYEES.ADD.NOTIFIER_UPDATE_SUCCESS'));
                        deferred.resolve();
                    });
                    break;
            }
            return deferred.promise;
        },
        post: (function() {
            var deferred = $q.defer();
            $scope.isServiceEngineer.status = EmployeeManager.OCCUPATION_ENGINEER == $scope.employee.occupation.ocuCode;
            _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_LICENCES]).visible = true;
            _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_QUALIFICATIONS]).visible = true;
            _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_MODALITY_TRAININGS]).visible = true;
            EmployeeQualificationService.getQualifications($scope.employee.uuid, 0).then(function(qualifications) {
                $scope.qualifications = qualifications;
            });
            EmployeeQualificationService.getQualifications($scope.employee.uuid, 1).then(function(qualifications) {
                $scope.employee.qualifications = qualifications;
                $scope.gridQualifications.items = $scope.employee.qualifications;
            });
            EmployeeModalityTrainingService.getTrainingCategories($scope.employee.uuid).then(function(data) {
                $scope.trainingCategories = data.categories;
            });
            this.enableTabs();
            $rootScope.$broadcast('SELECT_CASCADE_DISABLE', _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_DETAILS]).form.$name);
            deferred.resolve();
            return deferred.promise;
        }).bind(this)
    });

    _.extend(_.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_QUALIFICATIONS]), {
        exec: (function() {
            var deferred = $q.defer();
            this.assignQualification().then(function() {
                deferred.resolve();
            });
            return deferred.promise;
        }).bind(this)
    });

    _.extend(_.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_LOCATIONS]), {
        exec: (function() {
            var deferred = $q.defer();
            this.addEmploymentLocation().then(function() {
                deferred.resolve();
            });
            return deferred.promise;
        }).bind(this)
    });
}
