/* @ngInject */
export function UserManagementService($http, $q, API_BASE) {

    /**
     * Stores values of fields supplied to isUsernameAvailableCrossCheck when
     * sourceField is provided
     * @type {Array}
     */
    var crossCheckValues = [];

    this.fetchAllUsers = function() {
        return $http({
            url: API_BASE+"/users",
            method: "GET"
        }).then(function(data) {
            return data.data;
        });
    };

    this.isUsernameAvailable = function(username) {
        return $http({
            url: API_BASE + '/account/available/username/' + username,
            method: "GET"
        }).then(function(response) {
            return response.data.result;
        });
    };

    this.isUsernameAvailableCrossCheck = function(usernameCandidate,sourceField)
    {


        if(_.isUndefined(sourceField))
            return this.isUsernameAvailable(usernameCandidate);

        var deferred = $q.defer();

        if(this.valueStored(usernameCandidate))
        {
            deferred.reject();
        }
        else
        {
            crossCheckValues[sourceField] = usernameCandidate;

            this.isUsernameAvailable(usernameCandidate).then(
                function(data)
                {
                   if (data === 1)
                       deferred.resolve(data)
                   else
                       deferred.reject()
                },
            );

        }
        return deferred.promise;
    }

    this.valueStored = function(value)
    {
        var hasValue = false;
        _.each(crossCheckValues, function(data) {
            if (data === value) {
                hasValue = true;
                return false;
            }
        });
        return hasValue;
    };

    this.setUserStatus = function(uuid, locUuid, status) {
        return $http({
            url: API_BASE+"/user/status",
            method: "POST",
            data: {
                uuid: uuid,
                loc_uuid: locUuid,
                status: status
            }
        }).then(function(response) {
            return response.data;
        });
    };
}
