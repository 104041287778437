/* @ngInject */
export function AccComplaintFeedbackAdd($scope, Notifier, AccreditationDocumentsService,
                                 AccComplaintFeedbackItem, EmployeeService,
                                 ACC_ACCREDITATION_CODE, AuthService, SUBJECT_TYPE, AccreditationService, $filter) {

    $scope.cursorState = 'default';

    $scope.accreditationSchemaCode = AccreditationService.getCurrentAccreditationFromState();
    $scope.selectedEmployee = null;
    $scope.employeeList = null;
    $scope.selectedUserParentLocation = ""; //uuid
    $scope.selectedUserParentLocationName = ""; //location name

    $scope.maxTestDate = new Date();

    $scope.complaintFeedbackItem = new AccComplaintFeedbackItem();

	var user = AuthService.getUser();

    /**
     * Create filter function for a query string */
    function createFilterFor(query) {
        var lowercaseQuery = query.toLowerCase();
        return function filterFn(employee) {
            return ((employee.firstName + ' ' + employee.lastName).toLowerCase().indexOf(lowercaseQuery) === 0);
        };
    }

    $scope.querySearch = function (query) {
        var results = $scope.employeeList.filter(createFilterFor(query));
        return results;
    };

    $scope.searchTextChange = function (text) { };
    $scope.selectedItemChange = function (selectedEmployee) {
        $scope.complaintFeedbackItem.completedBy = selectedEmployee && (selectedEmployee.firstName+" "+selectedEmployee.lastName);
        $scope.complaintFeedbackItem.completedByUuid = selectedEmployee &&  selectedEmployee.uuid;

    };

    //Get Active employees for currently selected site location
    $scope.cursorState = 'wait';
    EmployeeService.getEmployees(
        {
            status: 'act', /* Active employees, hardcoded id*/
            // location: { uuid: $scope.$parent.accComplaintsFeedbackReg.selectedUserParentLocation }
        }
    ).then(function (employeeList) {
        $scope.employeeList = employeeList.items;
        $scope.cursorState = 'default';
    }, function(error){
        $scope.cursorState = 'default';
    });

    //Dialog cancel button
    $scope.clearForm = function () {
        $scope.selectedEmployee = null;
        $scope.complaintFeedbackItem = new AccComplaintFeedbackItem();
        $scope.complFeedbacktemAddForm.$setPristine();
        $scope.complFeedbacktemAddForm.$setUntouched();
    };

    $scope.submitNewComplaintFeedbackItem = function () {
        $scope.$broadcast('show-errors-check-validity', 'complFeedbacktemAddForm');
        if ($scope.complaintFeedbackItem.dateReceived && $scope.complaintFeedbackItem.customerDetails && $scope.complaintFeedbackItem.detailsCommunication) {
            $scope.complaintFeedbackItem.locationUuid = $scope.selectedUserParentLocation;
            AccreditationDocumentsService.createAccComplaintFeedbackItem(
                $scope.accreditationSchemaCode,
                $scope.complaintFeedbackItem.locationUuid,
                $scope.complaintFeedbackItem)
                .then(function (response) {
                    Notifier.success($filter('translate')('ACCREDITATION.TABS.COMPLAINTS_AND_FEEDBACK.NOTIFY_SAVE_SUCCESS'));
                    $scope.clearForm();
                }, function (error) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.COMPLAINTS_AND_FEEDBACK.NOTIFY_SAVE_COMPLAINT_ERROR'));
                }).then(function () {
            });
        } else {
            Notifier.error($filter('translate')('ACCREDITATION.TABS.COMPLAINTS_AND_FEEDBACK.NOTIFY_INPUT_ERROR'));
        }
    };

    $scope.$on('LOCATION_SUBJECT_SELECTED', function (event, locationItem) {
        if (locationItem.in_subject_basic_type_code === "slo") {
            $scope.selectedUserParentLocation = locationItem.in_subject_uuid;
            $scope.selectedUserParentLocationName = locationItem.in_username_par;
        }
    });

    //Load data for parent site location if user belongs to only one site location
    if (user && (user.parentCount === 1) && (user.parents[user.parents.length-1])
        && (user.parents[user.parents.length-1].basicType == SUBJECT_TYPE.SITE_LOCATION)) {

        $scope.selectedUserParentLocation = user.parents[user.parents.length-1].uuid;
        $scope.selectedUserParentLocationName = user.parents[user.parents.length-1].name;
    }
}
