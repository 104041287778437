import { product } from '../../bootstrap.json';
import moment from 'moment';
import { saveAs } from 'file-saver';

export default function DoseReferenceLevelsActions($http, Notifier, $filter) {
    function generateDoseReport(dateFrom, dateTo, bodyPart, protocol, dlp, ctdivol) {
        return dispatch => {
            dispatch({
                type: 'DRL_GENERATE_PDF',
                id: 'drlCtScannerDoseReport'
            });
            return $http({
                url: 'https://drlvpn1.myrso.org/drl_ct_report.php',
                method: 'GET',
                responseType: 'arraybuffer',
                params: {
                    username: 'client0002',
                    password: 'XoNSDAF2734',
                    startdate: moment(dateFrom).format('YYYY-MM-DD'),
                    enddate: moment(dateTo).format('YYYY-MM-DD'),
                    selectioncontrol: bodyPart.id,
                    protocol: protocol,
                    dlpmaxext: dlp,
                    ctdivolmaxext: ctdivol,
                    download: 1
                }
            }).then(response => {
                Notifier.success($filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.NOTIFIER_SUCCESS'));
                dispatch({
                    type: 'DOSE_REPORT_RESET',
                    id: 'drlCtScannerDoseReport'
                });
                saveAs(new Blob([response.data], {type: 'application/pdf'}), `${product}-DRL-CT-SCANNER-DoseReport.pdf`);
            })
        }
    }

    function getContrastList() {
        return dispatch => {
            return $http({
                url: 'https://drlvpn1.myrso.org/contrast_list_json.php',
                method: 'GET',
                params: {
                    username: 'client0002',
                    password: 'drlXuX0002'
                }
            }).then(response => {
                dispatch({
                    type: 'CONTRAST_FETCH',
                    id: 'drlCtScannerContrastReport',
                    items: response.data
                });
            }, error => {
                Notifier.responseMessage(error);
            })
        }
    }

    function generateContrastReport(contrast, dateFrom, dateTo, location) {
        return dispatch => {
            dispatch({
                type: 'DRL_GENERATE_PDF',
                id: 'drlCtScannerContrastReport'
            });
            return $http({
                url: 'https://drlvpn1.myrso.org/contrast_report.php',
                method: 'GET',
                responseType: 'arraybuffer',
                params: {
                    username: 'anonymous',
                    password: 'XoNSDAF2734',
                    idcontrast: contrast,
                    startdate: moment(dateFrom).format('YYYY-MM-DD'),
                    enddate: moment(dateTo).format('YYYY-MM-DD'),
                    location: location,
                    download: 1
                }
            }).then(response => {
                Notifier.success($filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.NOTIFIER_SUCCESS'));
                dispatch({
                    type: 'CONTRAST_REPORT_RESET',
                    id: 'drlCtScannerContrastReport'
                });
                saveAs(new Blob([response.data], {type: 'application/pdf'}), `${product}-DRL-CT-SCANNER-ContrastReport.pdf`);
            })
        }
    }

    function getSystemStatus(filter) {
        return dispatch => {
            return $http({
                url: 'https://drlvpn1.myrso.org/monitor_json.php',
                method: 'GET',
                params: {
                    username: 'client0002',
                    password: 'XoNSDAF2734'
                }
            }).then(response => {
                dispatch({
                    type: 'GRID_FETCH',
                    id: filter.identifier,
                    items: response.data.map(function(item) {
                        item.last_seen = moment(item.last_seen, 'YYYY-MM-DD').format('DD-MM-YYYY');
                        switch (parseInt(item.division)) {
                            case 0:
                                item.class = 'colorGreen';
                                item.status = $filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.RESPONSE_24HR');
                                break;
                            case 1:
                                item.class = 'colorBlue';
                                item.status = $filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.RESPONSE_72HR');
                                break;
                            case 2:
                                item.class = 'colorRed';
                                item.status = $filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.RESPONSE_MORE_THAN_72HR');
                                break;
                        }
                        return item;
                    })
                });
            }, error => {
                Notifier.responseMessage(error);
            })
        }
    }

    return {
        generateDoseReport,
        getContrastList,
        generateContrastReport,
        getSystemStatus
    }
}

DoseReferenceLevelsActions.$inject = ['$http', 'Notifier', '$filter'];
