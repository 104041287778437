/* @ngInject */
export function AccessMenuController($scope, NavigationService, Notifier) {

    $scope.selMenuPath = [];

    $scope.menu = NavigationService.getConfigs();
    $scope.selMenuPath.push({
        name: 'Dashboard',
        components: $scope.menu
    });

    $scope.selectItem = function(item, index){
        $scope.selectedItem = item;
        $scope.selectedOccupation =null;
        $scope.modifiedItem = null;

        $scope.itemSelected = null;
        $scope.itemSelected = index;

        //        if($scope.occupations.length==0 && $scope.selectedItem.department.uuid){
        //            UserService.fetchOccupationsSiteLocation($scope.selectedItem.department.uuid)
        //                .then(function (items) {
        //                    $scope.occupations = items;
        //                    console.log($scope.occupations);
        //                });
        //        }

    };

    $scope.getTiles = function(state) {
        var navConfig = NavigationService.getNavConfig('state', state);
        $scope.menu = navConfig.components;
        $scope.selMenuPath.push(navConfig);
    };

    $scope.revTiles = function(config) {
        while (_.last($scope.selMenuPath) !== config)
            $scope.selMenuPath.pop();

        $scope.menu = _.last($scope.selMenuPath).components;
        $scope.selectedItem = null;
        $scope.modifiedItem = null;
    };

    $scope.modify = function(item) {
        $scope.modifiedItem = item;
        $scope.selectedItem = null;
    };

    $scope.update = function(config) {
        NavigationService.updateNavConfig(config).then(function(response) {
            if (response.status == 200 && response.data.status == 0)
                Notifier.success('Menu item successfully updated');
            else
                Notifier.error('There was a problem. Menu item not saved');

            $scope.modifiedItem = null;
            $scope.selectedItem = item;
        }, function(error) {
            Notifier.responseMessage(error);
        });
    };
}
