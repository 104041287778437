/*
*   List of Action IDs that should be passed from accreditation.reducer.js to scheme.reducer.js
 */

export const IDS = {
    // module
    accStandard: 'accStandard',

    //paths
    standardDocumentsProgressGrid:      'standardDocumentsProgressGrid',
    standardDocumentReviewsGrid:        'standardDocumentReviewsGrid',

    // states:

    app_accreditation_scheme_standards:                     'app.accreditation.scheme.standards',
    app_accreditation_scheme_standards_dashboard:           'app.accreditation.scheme.standards.dashboard',
    app_accreditation_scheme_standards_documents:           'app.accreditation.scheme.standards.documents',
    app_accreditation_scheme_standards_documents_list:      'app.accreditation.scheme.standards.documents.list',
    app_accreditation_scheme_standards_documents_details:   'app.accreditation.scheme.standards.documents.details',
};
