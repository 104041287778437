import {TAB_SHOW} from '../../../common/tabs/tabs.constants';
import {TYPES as ACTION_TYPES} from './acc-documents-action-type.constants';
import {TYPES as ACC_TYPES} from "../accreditation-action-type.constants";
import {IDS} from './acc-documents-action-id.constants';
import * as Actions from './acc-documents.actions';
import * as AccreditationActions from '../accreditation.actions';
import * as SectionsActions from '../settings/sections/sections.actions';
import watch from "redux-watch";
import get from "lodash/get";
import {GRID_FILTER, GRID_FILTER_TOGGLE, GRID_RELOAD} from '../../../common/grid/grid.constants';
import {error} from '../../../common/utils/util.notifier';

class AccDocumentsController {
    constructor($ngRedux, $uiRouterGlobals, $state, $stateParams, $scope, $rootScope, $filter, NavigationService, AuthService, EmployeeService, DocumentManager) {
        this.$ngRedux = $ngRedux;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.NavigationService = NavigationService;
        this.AuthService = AuthService;
        this.EmployeeService = EmployeeService;
        this.DocumentManager = DocumentManager;
    }

    $onInit() {
        this.identifier = IDS.accDocuments;
        this.schemeCode = this.$stateParams.itemCode;
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.iconSvg.replace(/(\.\/)/g, '');

        this.unsubscribe = this.$ngRedux.connect(state => {
            const sectionDetails = state.accreditation.accDocuments.sectionDetails;
            return {
                schemeId:       state.accreditation.accDocuments.schemeDetails.id,
                // headOffice:     state.accreditation.accDocuments.headOfficeDocumentsGrid.filter.hof_uuid,
                sectionsTree:  state.accreditation.simpleSectionsTree,

                standardSchemeCode: sectionDetails?.schemeCode,
                standardId:         sectionDetails?.id,
                standardParentId:   sectionDetails?.standardParentId,
                title:              this.schemeName,
            };
        }, {...AccreditationActions, ...SectionsActions, ...Actions})(this);

        const ctrl = this;
        // const watchSelectedState = watch(this.$ngRedux.getState, ['accreditation', IDS.app_accreditation_scheme_acc_documents, 'selectedState']);
        // this.unsubscribeWatch = this.$ngRedux.subscribe(watchSelectedState(selectedState => {
        //     if (selectedState === IDS.app_accreditation_scheme_acc_documents_list) {
        //         this.$ngRedux.dispatch({
        //             id: IDS.accDocuments,
        //             type: ACTION_TYPES.NEW_ASSESSMENT_ITEM_RESET,
        //         })
        //     }
        // }));

        var user = this.AuthService.getUser();
        if(user.parentCount !== 1){
            ctrl.fetchStandardsTree(ctrl.schemeCode).then(()=>{
                ctrl.initStandardsInputSelects({});
            })
        }
        else{
            this.EmployeeService.getEmploymentLocations(user.uuid).then(siteLocations => {
                const siteLocation = get(siteLocations,'[0].uuid');
                this.$stateParams.slo = siteLocation;
                this.$ngRedux.dispatch({
                    type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                    accDashboardLocationYear: {
                        siteLocation,
                    }
                });
                ctrl.fetchStandardsTree(ctrl.schemeCode, siteLocation).then(()=>{
                    ctrl.initStandardsInputSelects({});
                })
            });
        }

        this.getSchemeDetails(this.schemeCode).then(scheme => {
            this.$ngRedux.dispatch({
                type:   ACTION_TYPES.SCHEME_DETAILS_REFRESH,
                id:     IDS.accDocuments,
                data: {
                    ...scheme,
                }
            });
            this.schemeName = scheme.name;
        })
    }

    initStandardsInputSelects({deselectAll}) {
        // insert standards to first standard select box
        this.$ngRedux.dispatch({
            type: ACTION_TYPES.SELECT_CASCADE_STANDARD,
            id: IDS.accDocuments,
            level:0,
            standards: this.sectionsTree,
            standardId: null,
            standardParentId: null,
        });

        let selectedLevel1Id, selectedLevel2Id, selectedLevel3Id;
        if(this.standardId && this.standardParentId && this.standardSchemeCode === this.schemeCode){
            const standard2 = this.findChildStandardById(this.standardParentId, this.sectionsTree);
            selectedLevel1Id = standard2.standardParentId;
            selectedLevel2Id = this.standardParentId;
            selectedLevel3Id = this.standardId;
        }
        else{
            selectedLevel1Id = get(this.sectionsTree,'[0].id');
            selectedLevel2Id = get(this.sectionsTree,'[0].children[0].id');
            selectedLevel3Id = get(this.sectionsTree,'[0].children[0].children[0].id');
        }

        if(selectedLevel1Id) this.onSelectStandard({item:{value: selectedLevel1Id}, skipGridFetch: true, deselectAll});
        if(selectedLevel2Id) this.onSelectStandard({item:{value: selectedLevel2Id}, skipGridFetch: !!selectedLevel3Id, deselectAll});
        let level3Standard;
        if(selectedLevel3Id)
            level3Standard = this.onSelectStandard({item:{value: selectedLevel3Id}, skipGridFetch: !!deselectAll, deselectAll});

        if(level3Standard && deselectAll === false){
            this.getSectionDetails({
                ...level3Standard,
                schemeCode: this.schemeCode
            });
        }
        if(deselectAll)
            this.$ngRedux.dispatch({
                type:   ACTION_TYPES.SELECT_CASCADE_STANDARD_RESET,
                id:     IDS.accDocuments,
            });
    }

    onSelectStandard({item, skipGridFetch = undefined, deselectAll = false}){
        if(!item) // selecting 3rd level standard when no standard 3rd level standard exist
            return;
        const standard = this.findChildStandardById(item.value, this.sectionsTree);
        const level3Standards = standard.level === 1 && standard.children.length
            ? this.findChildStandardById(standard.children[0].id, this.sectionsTree).children
            : undefined;
        this.$ngRedux.dispatch({
            type:       ACTION_TYPES.SELECT_CASCADE_STANDARD,
            id:         IDS.accDocuments,
            standardId: standard.id,
            level:      standard.level,
            standards:  standard.children,
            level3Standards,
            schemeCode: this.schemeCode
        });

        const level3Standard =
            level3Standards?.length && level3Standards[0]
            || standard.children?.length && standard.children[0]
            || standard;

        if(deselectAll === false) {
            this.$ngRedux.dispatch({
                type: ACTION_TYPES.SECTION_DETAILS_REFRESH,
                id: this.identifier,
                data: {
                    id: level3Standard.id,
                    standardParentId: level3Standard.standardParentId,
                    level: level3Standard.level,
                    nodeName: level3Standard.name,
                    prefix: level3Standard.number,
                    siblings: level3Standard.children.map(item => parseInt(item.number.split('.').pop())),
                    schemeCode: level3Standard.schemeCode,
                }
            });
        }

        this.$ngRedux.dispatch({
            type: skipGridFetch ? GRID_FILTER_TOGGLE : GRID_FILTER,
            id: IDS.accDocuments,
            path: IDS.accDocumentsGrid,
            filter: {
                standard_id: level3Standard.id,
                scheme_code: this.schemeCode
            }
        });
        return level3Standard
    }

    findChildStandardById(id, standards) {
        return standards.reduce((result, item) => {
            if(result)
                return result;
            if(item.id === id)
                return item
            else if (item.children.length)
                return this.findChildStandardById(id, item.children)
        }, null);
    }

    onGridFilterChange(event) {
        this.$ngRedux.dispatch({
            type: GRID_RELOAD,
            id: IDS.accDocuments,
            path: IDS.accDocumentsGrid,
            filter: {
            }
        });
    }

    onResetGridFilterButton(){
        this.initStandardsInputSelects({deselectAll: true});

        this.$ngRedux.dispatch({
            type:   GRID_FILTER,
            id:     IDS.accDocuments,
            path:   IDS.accDocumentsGrid,
            filter: {
                loc_uuid: null,
                standard_id: null,
                keyword: null,
                searched_column: 'name',
                doc_type: 'all',
                due_date_to: null,
                due_date_from: null,
            }
        });

        this.$rootScope.$broadcast('SELECT_CASCADE_RESET', 'accDocumentsSelectCascadeForm');
    }

    onSelect({item}) {
        this.$ngRedux.dispatch({
            type:   ACTION_TYPES.DOC_DETAILS_REFRESH,
            id:     IDS.accDocuments,
            data:  {
                ...item,
                schemeId: this.schemeId,

            }
        });
        this.$ngRedux.dispatch({
            type: TAB_SHOW,
            id: IDS.app_accreditation_scheme_acc_documents,
            state: IDS.app_accreditation_scheme_acc_documents_details,
        });
    };

    // onRemove({item}) {
    //     this.invalidateRequiredDocument(item.id, IDS.accDocumentsGrid);
    // };

    onDownloadButton({item}){
        this.DocumentManager.downloadFile(item.doc_uri);
    }

    onButtonAddDocument(event) {
        // if no standard selected throw notification
        if(!this.standardId){
            error('Please select a standard first');
            return;
        }
        // this.$ngRedux.dispatch({
        //     type: ACTION_TYPES.ASSESSMENT_DETAILS_REFRESH,
        //     id: IDS.accDocuments,
        //     data: {
        //         type_code: 'assupl',
        //         assignedHeadOffices: [],
        //         name: null,
        //         request: null,
        //     }
        // });
        this.$ngRedux.dispatch({
            type: TAB_SHOW,
            id:     IDS.app_accreditation_scheme_acc_documents,
            state:  IDS.app_accreditation_scheme_acc_documents_add,
        });
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

AccDocumentsController.$inject = ['$ngRedux', '$uiRouterGlobals', '$state', '$stateParams', '$scope', '$rootScope', '$filter', 'NavigationService', 'AuthService','EmployeeService', 'DocumentManager']

export const accDocuments = {
    controller: AccDocumentsController,
    template: `
        <tabs-header title="{{$ctrl.title}}" icon="{{$ctrl.icon}}" color-name="{{$ctrl.colorName}}"></tabs-header>
        <form name="accDocumentsSelectCascadeForm" novalidate layout="row" style="margin: 20px">
            <div layout="column" flex>
                <div layout-gt-xs="row" flex>
                    <input-select flex label="Standard"
                                  identifier="{{$ctrl.identifier}}"
                                  state-path="selectCascadeStandard.level1StandardId"
                                  state-data="selectCascadeStandard.level1Standards"
                                  on-change="$ctrl.onSelectStandard($event)"
                                  select-first-item="true">
                    </input-select>
                    <input-select flex label="Standard"
                                  identifier="{{$ctrl.identifier}}"
                                  state-path="selectCascadeStandard.level2StandardId"
                                  state-data="selectCascadeStandard.level2Standards"
                                  on-change="$ctrl.onSelectStandard($event)"
                                  select-first-item="true">
                    </input-select>
                    <input-select flex label="Standard"
                                  identifier="{{$ctrl.identifier}}"
                                  state-path="selectCascadeStandard.level3StandardId"
                                  state-data="selectCascadeStandard.level3Standards"
                                  on-change="$ctrl.onSelectStandard($event)"
                                  select-first-item="true">
                    </input-select>
                </div>
                <div layout-gt-xs="row">
                    <input-select flex-gt-xs="33"
                                    label="{{'COMMON.DOCUMENT.TYPE' | translate}}"
                                    identifier="{{$ctrl.identifier}}"
                                    state-data="gridFilterDocumentTypes"
                                    state-path="accDocumentsGrid.filter.doc_type">
                    </input-select>
                    <input-text-area flex-gt-md="60"
                                     rows="1"
                                     max-length="45"
                                     label="{{'COMMON.FIELD.KEYWORD' | translate}}"
                                     identifier="{{$ctrl.identifier}}"
                                     name="request"
                                     state-path="accDocumentsGrid.filter.keyword"
                                     max-length="45">
                    </input-text-area>
            
                    <input-select flex-gt-xs="33" label="Search text in"
                                  identifier="{{$ctrl.identifier}}"
                                  state-path="accDocumentsGrid.filter.searched_column"
                                  state-data="searchedColumns">
                    </input-select>
                </div>
                <div layout-gt-xs="row" layout-align="center center" flex>
                    <div layout-gt-xs="row"  flex>
                        <div layout-gt-xs="row"  flex>
                            <select-cascade flex 
                                identifier="{{$ctrl.identifier}}"
                                state-path="accDocumentsGrid" 
                                purpose="'aeq'" 
                                validate="true"
                                max="slo">
                            </select-cascade>
                        </div>
                        <input-date name="dueDateFrom"
                                    label="Due Date From"
                                    identifier="{{$ctrl.identifier}}"
                                    state-path="accDocumentsGrid.filter.due_date_from">
                        </input-date>
                        <input-date name="dueDateTo"
                                    label="Due Date To"
                                    identifier="{{$ctrl.identifier}}"
                                    state-path="accDocumentsGrid.filter.due_date_to">
                        </input-date>
                    </div>
                    <div layout-="100px">
                        <button class="btnDefault" ng-click="$ctrl.onResetGridFilterButton()">{{'COMMON.BUTTON.RESET_FILTER' | translate}}</button>
                    </div>
                </div>
            </div>
            
        </form>
        <acc-documents-grid
                on-select="$ctrl.onSelect($event)"
                on-download="$ctrl.onDownloadButton($event)">
        </acc-documents-grid>

        <div layout-xs="column" layout-gt-xs="row" layout-align="center center" class="tabs">
            <div flex></div>
            <button type="submit" class="md-button btnColor{{$ctrl.colorName}}" ng-click="$ctrl.onButtonAddDocument()" aria-label="Add Head Office Document">Add Document</button>
        </div>
`}
