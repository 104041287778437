import { TabsReducer } from '../../../../common/tabs/tabs.reducer';
import {IDS} from '../import-settings-action-id.constants';
export const EmpImportSettingsUsersTabs = {
    [IDS.app_employees_import_settings_users]: Object.assign(TabsReducer(), {
        tabs: [{
            state: IDS.app_employees_import_settings_users_relocated_list,
            title: 'Relocated Employees',
            visible: true,
            disabled: false,
            loading: false
        },
        {
            state: IDS.app_employees_import_settings_users_relocated_details,
            title: 'Replacement Details',
            visible: false,
            disabled: false,
            loading: false,
            buttons: {
                // execute: {label: 'Download'}
            }
        },
        {
            state: IDS.app_employees_import_settings_users_skipped_list,
            title: 'Excluded Employees',
            visible: true,
            disabled: false,
            loading: false
        }
    ]}),
};
