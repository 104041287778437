import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';

class InputDatePeriodController {
    constructor($ngRedux, $filter) {
        this.$ngRedux = $ngRedux;
        this.$filter = $filter
    }

    $onInit() {
        if (isUndefined(this.validate))
            this.validate = false;
        if (isUndefined(this.labelFrom))
            this.labelFrom = this.$filter('translate')("COMMON.DATE.FROM");
        if (isUndefined(this.labelTo))
            this.labelTo = this.$filter('translate')("COMMON.DATE.TO");

        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeModule();
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        };
    }

    mapState(state) {
        return {
            dateFrom: get(state[this.module][this.identifier], this.statePathFrom),
            dateTo: get(state[this.module][this.identifier], this.statePathTo)
        };
    }
}

InputDatePeriodController.$inject = ['$ngRedux', '$filter'];

export const inputDatePeriod = {
    bindings: {
        identifier: '@',
        statePathFrom: '@',
        statePathTo: '@',
        nameFrom: '@',
        nameTo: '@',
        labelFrom: '@',
        labelTo: '@',
        fx: '@',
        fxGtXs: '@',
        fxGtSm: '@',
        validate: '<',
        required: '<'
    },
    require : {
        form : '^form'
    },
    controller: InputDatePeriodController,
    template: `
        <input-date ng-attr-flex="$ctrl.fx" ng-attr-flex-gt-xs="$ctrl.fxGtXs" ng-attr-flex-gt-sm="$ctrl.fxGtSm" 
                    name="{{$ctrl.nameFrom}}" label="{{$ctrl.labelFrom}}" validate="$ctrl.validate" required="$ctrl.required"
                    identifier="{{$ctrl.identifier}}" state-path="{{$ctrl.statePathFrom}}" max-date="$ctrl.dateTo">
        </input-date>
        <input-date ng-attr-flex="$ctrl.fx" ng-attr-flex-gt-xs="$ctrl.fxGtXs" ng-attr-flex-gt-sm="$ctrl.fxGtSm" 
                    name="{{$ctrl.nameTo}}" label="{{$ctrl.labelTo}}" validate="$ctrl.validate" required="$ctrl.required"
                    identifier="{{$ctrl.identifier}}" state-path="{{$ctrl.statePathTo}}" min-date="$ctrl.dateFrom">
        </input-date>
    `
};
