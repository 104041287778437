import API from '../../../common/http/api.fetch';
import { formatDate } from '../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../common/utils/util.constants';


export const getActionPlan = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/event/action-plan`,
            method: 'GET',
            params: { id }
        }).then(response => {
            dispatch({
                id: 'eventActionPlan',
                type: 'EVENT_ACTION_PLAN_DETAILS',
                data: response.data
            });
        });
    };
};

export const assignActionPlan = (data) => {
    return dispatch => {
        return API.fetch({
            url: `/event/action-plan/assign`,
            method: 'POST',
            data
        }).then(response => {
            dispatch({
                id: 'eventActionPlan',
                type: 'EVENT_ACTION_PLAN_ASSIGN',
                data: response.data
            });
        });
    };
};

export const getActionPlanList = (keyword) => {
    return dispatch => {
        return API.fetch({
            url: `/event/action-plan/library`,
            method: 'GET',
            params: {
                keyword,
                offset: 0
            }
        }).then(response => {
            dispatch({
                id: 'eventActionPlan',
                type: 'EVENT_ACTION_PLAN_LIST',
                data: response.data.items
            });
        });
    };
};

export const preselectActionPlan = () => {
    return dispatch => {
        dispatch({
            id: 'eventActionPlan',
            type: 'EVENT_ACTION_PLAN_LIST_SELECT'
        });
    };
};

export const resetActionPlanList = () => {
    return dispatch => {
        dispatch({
            id: 'eventActionPlan',
            type: 'EVENT_ACTION_PLAN_LIST_RESET'
        });
    };
};

export const getActionPlanLibrary = (filter) => {
    return dispatch => {
        return API.fetch({
            url: `/event/action-plan/library`,
            method: 'GET',
            params: {
                keyword: filter.keyword,
                offset: filter.offset,
                limit: filter.limit
            }
        }).then(response => {
            dispatch({
                id: 'eventsActionPlanLibraryGrid',
                type: 'GRID_FETCH',
                items: response.data.items,
                itemsCount: parseInt(response.data.items_count)
            });
        });
    };
};

export const manageActionPlan = (data) => {
    return dispatch => {
        return API.fetch({
            url: `/event/action-plan/library/manage`,
            method: 'POST',
            data
        }).then(response => {
            dispatch({
                id: 'eventsActionPlanLibraryGrid',
                type: 'GRID_RELOAD'
            });
        });
    };
};

export const deleteActionPlan = (pln_id) => {
    return dispatch => {
        return API.fetch({
            url: `/event/action-plan/library/delete`,
            method: 'GET',
            params: { pln_id }
        }).then(response => {
            dispatch({
                id: 'eventsActionPlanLibraryGrid',
                type: 'GRID_RELOAD'
            });
        });
    };
};

export const setActionPlanLibraryDetails = (plan) => {
    return dispatch => {
        dispatch({
            id: 'eventActionPlan',
            type: 'EVENT_ACTION_PLAN_LIBRARY_DETAILS',
            plan
        });
    };
};

export const resetActionPlanLibraryDetails = () => {
    return dispatch => {
        dispatch({
            id: 'eventActionPlan',
            type: 'EVENT_ACTION_PLAN_LIBRARY_RESET'
        });
    };
};

export const getActionTaskList = (keyword) => {
    return dispatch => {
        return API.fetch({
            url: `/event/action-task/library`,
            method: 'GET',
            params: {
                keyword,
                offset: 0,
                limit: -1
            }
        }).then(response => {
            dispatch({
                id: 'eventActionPlan',
                type: 'EVENT_ACTION_TASK_LIST',
                data: response.data.items
            });
        });
    };
};

export const preselectActionTask = () => {
    return dispatch => {
        dispatch({
            id: 'eventActionPlan',
            type: 'EVENT_ACTION_TASK_LIST_SELECT'
        });
    };
};

export const resetActionTaskList = () => {
    return dispatch => {
        dispatch({
            id: 'eventActionPlan',
            type: 'EVENT_ACTION_TASK_LIST_RESET'
        });
    };
};

export const getActionTaskLibrary = (filter) => {
    return dispatch => {
        return API.fetch({
            url: `/event/action-task/library`,
            method: 'GET',
            params: {
                keyword: filter.keyword,
                offset: filter.offset,
                limit: filter.limit
            }
        }).then(response => {
            dispatch({
                id: 'eventsActionTaskLibraryGrid',
                type: 'GRID_FETCH',
                items: response.data.items,
                itemsCount: parseInt(response.data.items_count)
            });
        });
    };
};

export const manageActionTask = (data) => {
    return dispatch => {
        return API.fetch({
            url: `/event/action-task/library/manage`,
            method: 'POST',
            data
        }).then(() => {
            dispatch({
                id: 'eventsActionTaskLibraryGrid',
                type: 'GRID_RELOAD'
            });
        });
    };
};

export const deleteActionTask = (tsk_id) => {
    return dispatch => {
        return API.fetch({
            url: `/event/action-task/library/delete`,
            method: 'GET',
            params: { tsk_id }
        }).then(() => {
            dispatch({
                id: 'eventsActionTaskLibraryGrid',
                type: 'GRID_RELOAD'
            });
        });
    };
};

export const setActionTaskLibraryDetails = (task) => {
    return dispatch => {
        dispatch({
            id: 'eventActionPlan',
            type: 'EVENT_ACTION_TASK_LIBRARY_DETAILS',
            task
        });
    };
};

export const resetActionTaskLibraryDetails = () => {
    return dispatch => {
        dispatch({
            id: 'eventActionPlan',
            type: 'EVENT_ACTION_TASK_LIBRARY_RESET'
        });
    };
};

export const getActionTasks = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({
            id: 'eventsActionTaskGrid',
            type: 'GRID_FETCH',
            items: state.events.eventActionPlan.tasks,
            itemsCount: state.events.eventActionPlan.tasks.length
        });
    };
};

export const getResponsiblePersons = (id, keyword) => {
    return dispatch => {
        return API.fetch({
            url: `/employees`,
            method: 'GET',
            params: {
                keyword,
                status: 'act',
                licences: false,
                offset: 0,
                limit: 20,
            }
        }).then(response => {
            dispatch({
                id,
                type: 'EVENT_RESPONSIBLE_PERSONS',
                data: response.data
            });
        });
    };
};

export const assignActionTask = (task) => {
    return dispatch => {
        dispatch({
            id: 'eventActionPlan',
            type: 'EVENT_ACTION_TASK_ASSIGN',
            task
        });
    };
};

export const updateActionTask = (data) => {
    return dispatch => {
        if (data.tsk_id) {
            return API.fetch({
                url: `/event/action-task/update`,
                method: 'POST',
                data: {
                    iev_id: data.iev_id,
                    tsk_id: data.tsk_id,
                    task_name: data.task_name,
                    details: data.details,
                    responsible_person_uuid: data.responsible_person.subject_uuid,
                    completion_date: formatDate(data.completion_date, DATE_FORMAT.OUTPUT),
                    status_id: data.status_id
                }
            });
        } else {
            dispatch({
                id: 'eventActionPlan',
                type: 'EVENT_ACTION_TASK_UPDATE',
                data
            });
            dispatch({
                id: 'eventsActionTaskGrid',
                type: 'GRID_RELOAD'
            });
        }
    };
};

export const removeActionTask = (tsk_id, index) => {
    return async (dispatch) => {
        if (tsk_id) {
            await API.fetch({
                url: `/event/action-task/remove`,
                method: 'POST',
                params: { tsk_id }
            });
        }
        dispatch({
            id: 'eventActionPlan',
            type: 'EVENT_ACTION_TASK_REMOVE',
            index
        });
    };
};

export const resetActionTask = () => {
    return dispatch => {
        dispatch({
            id: 'eventActionPlan',
            type: 'EVENT_ACTION_TASK_RESET'
        });
    };
};

export const setActionTaskDetails = (index) => {
    return dispatch => {
        dispatch({
            id: 'eventActionPlan',
            type: 'EVENT_ACTION_TASK_DETAILS',
            index
        });
    };
};

export const resetActionTaskDetails = () => {
    return dispatch => {
        dispatch({
            id: 'eventActionPlan',
            type: 'EVENT_ACTION_TASK_DETAILS_RESET'
        });
    };
};

export const getActionTaskStatusList = () => {
    return dispatch => {
        return API.fetch({
            url: `/events/categories/miscellaneous`,
            method: 'GET',
            params: { category_code: 'actsts' }
        }).then(response => {
            dispatch({
                id: 'eventActionPlan',
                type: 'EVENT_ACTION_TASK_STATUS_LIST',
                data: response.data
            });
        });
    };
};
