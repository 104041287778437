import isObject from 'lodash/isObject';
import * as EventsAlertEscalateActions from './events-alert-escalate.actions'

/**
 * @const {object}
 * @memberOf module:Events
 */
export const eventsAlertEscalate = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $rootScope, $scope, NavigationService, $mdDialog, Notifier) {
        const unsubscribe = $ngRedux.connect(({ events }) => ({
            eventID: events.eventItem.id,
            eventAlertEscalate: events.eventAlertEscalate,
            receivers: events.eventsAlertReceiversGrid.items.reduce((res, item) => {
                if (events.eventsAlertReceiversGrid.itemsSelect.indexOf(item.ntp_id.toString()) !== -1 || item.type === 'Mandatory') {
                    res.push(item.ntp_id);
                }
                return res;
            }, []),
        }), {
            ...EventsAlertEscalateActions,
        })(this);

        /**
         * @function $onInit
         */
        this.$onInit = () => {
            const navConfig = NavigationService.getConfig($state.$current);
            this.colorName = navConfig.colorName;
            this.icon = navConfig.icon;

            this.identifier = $state.$current.parent.name;
            this.state = `${$state.$current.parent.name}.alertEscalate`;
        };

        this.onAddReceiver = () => {
            const receiverCtrl = function ($scope, ctrl) {
                $scope.disableInput = false;
                $scope.onGetEmployees = async (evt) => await ctrl.getEmployees('eventAlertEscalate', evt);
                $scope.onAutocomplete = (evt) => {
                    $scope.disableInput = isObject(evt);
                };
                $scope.save = () => {
                    if ($scope.receiverDialogForm.$valid) {
                        ctrl.addReceiver({
                            iev_id: ctrl.eventID,
                            emp_uuid: ctrl.eventAlertEscalate.receiver_uuid,
                            first_name: ctrl.eventAlertEscalate.receiver_first_name,
                            last_name: ctrl.eventAlertEscalate.receiver_last_name,
                            email: ctrl.eventAlertEscalate.receiver_email
                        }).then(() => {
                            Notifier.success('New receiver has been successfully added');
                            $mdDialog.hide();
                        }).catch((error) => {
                            if (error.data.status === 16) {
                                Notifier.error('Employee location does not match Incident location!');
                            } else {
                                Notifier.error(error.data);
                            }
                        });
                    } else {
                        Notifier.error('Please fill in the form fields');
                    }
                };
                $scope.cancel = () => {
                    $mdDialog.cancel();
                };
            };

            this.dialog = $mdDialog.show({
                controller: receiverCtrl,
                locals: { ctrl: this },
                template: require('./dialogs/events-alert-receiver-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true
            }).then(this.resetReceiver, this.resetReceiver);
        };

        this.onRemoveReceiver = (event) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen receiver will be removed permanently.')
                .ariaLabel('Remove Receiver')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.removeReceiver(this.eventID, event.item.ntp_id);
            }, angular.noop);
        }

        this.onNotificationDetails = (event) => {
            const notificationCtrl = function ($scope) {
                $scope.cancel = () => {
                    $mdDialog.cancel();
                };
            };

            this.dialog = $mdDialog.show({
                controller: notificationCtrl,
                template: require('./dialogs/events-alert-notification-details-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                onShowing: () => {
                    $ngRedux.dispatch({
                        id: 'eventAlertEscalate',
                        type: 'EVENT_NOTIFICATION_DETAILS',
                        notification: event.item
                    });
                }
            }).then(this.resetNotification, this.resetNotification);
        }

        this.onSendNotification = () => {
            if (this.receivers.length) {
                const notificationCtrl = function ($scope, ctrl) {
                    $scope.send = () => {
                        if ($scope.notificationDialogForm.$valid) {
                            ctrl.sendNotification({
                                iev_id: ctrl.eventID,
                                ntp_ids: ctrl.receivers,
                                subject: ctrl.eventAlertEscalate.notification_subject,
                                message: ctrl.eventAlertEscalate.notification_message
                            }).then(() => {
                                Notifier.success('Notification has been successfully sent');
                                $mdDialog.hide();
                            });
                        } else {
                            Notifier.error('Please fill in the form fields');
                        }
                    };
                    $scope.cancel = () => {
                        $mdDialog.cancel();
                    };
                };

                this.dialog = $mdDialog.show({
                    controller: notificationCtrl,
                    locals: { ctrl: this },
                    template: require('./dialogs/events-alert-notification-dialog.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: true
                }).then(this.resetNotification, this.resetNotification);
            } else {
                Notifier.error('Please select at least one receiver');
            }
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        };
    },
    template: require('./events-alert-escalate.html')
};
