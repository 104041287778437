export const appHeader = {
    /* @ngInject */
    controller: function ($ngRedux) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(({ navigation }) => {
                return {
                    accDashboardLocationYearEnabled: [
                        'app.accreditation.dashboard',
                        'app.accreditation.scheme.standards.dashboard',
                    ].includes(navigation.state)
                };
            }, {})(this);
        }

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            this.unsubscribe();
        };
    },
    template: `
        <div ng-if="$ctrl.accDashboardLocationYearEnabled" class="search">
            <acc-dashboard-location-year></acc-dashboard-location-year>
        </div>
    `
};
