/* @ngInject */
export function EmployeeModalityTrainingService($http, API_BASE, Helpers, EmployeeModalityTraining, EmployeeModalityTrainingCategory) {

    this.getTrainings = function(employeeUuid) {
        return $http({
            url: API_BASE + "/administration/employee/modality-trainings",
            method: "GET",
            params: {employeeUuid: employeeUuid}
        }).then(function(response) {
            return response.data[0].map(function(item) {
                var training        = new EmployeeModalityTraining();
                training.id         = item.mtr_id;
                training.issueDate  = Helpers.formatDate(item.issue_date);
                training.document   = item.document_url;
                training.categories = response.data[1].reduce(function(result, obj) {
                    if (training.id == obj.mtr_id) {
                        var category    = new EmployeeModalityTrainingCategory();
                        category.id     = obj.ety_id;
                        category.name   = obj.ety_name;
                        result.push(category);
                    }
                    return result;
                }, []);
                return training;
            });
        });
    };

    this.getTrainingCategories = function(employeeUuid) {
        return $http({
            url: API_BASE + "/administration/employee/modality-training-categories",
            method: "GET",
            params: {employeeUuid: employeeUuid}
        }).then(function(response) {
            var categories = mapCategories(response.data[0]);
            var selectedCategories = mapCategories(response.data[1]);
            function mapCategories(data) {
                return data.map(function(item) {
                    var category        = new EmployeeModalityTrainingCategory();
                    category.id         = item.category_id;
                    category.name       = item.category_name;
                    category.orderNUm   = item.order_number;
                    return category;
                });
            }
            return {
                categories: categories,
                selectedCategories: selectedCategories
            };
        });
    };

    this.addTraining = function(employeeUuid, training) {
        return $http({
            url: API_BASE + "/administration/employee/modality-training-add",
            method: "POST",
            data: {
                employeeUuid:   employeeUuid,
                categories:     training.categories.map(function(item) {return item.id}).join(),
                documentUrl:    training.document,
                issueDate:      Helpers.formatDate(training.issueDate)
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.removeTraining = function(training) {
        return $http({
            url: API_BASE + "/administration/employee/modality-training-remove",
            method: "POST",
            data: {id: training.id}
        }).then(function(response) {
            return response.data;
        });
    };
}
