import { SystemAlertsController } from '../../../../components/system/alerts/system-alerts.component';

class EquipmentAlertsController extends SystemAlertsController {
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
    }

    selectEquipment(event) {
        this.$state.go(this.state, {id: event.item.equipmentId});
    }
}

EquipmentAlertsController.$inject = ['$ngRedux', '$state', 'NavigationService'];

export const equipmentAlerts = {
    bindings: {
        state: '@'
    },
    controller: EquipmentAlertsController,
    template: `
        <system-alerts-grid identifier="{{$ctrl.identifier}}"
                           module-id="$ctrl.moduleId"
                           icon="alerts.svg"
                           on-select="$ctrl.selectEquipment($event)">
        </system-alerts-grid>
    `
};
