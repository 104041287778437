/* @ngInject */
export function EmployeesActionsFactory(EmployeeService, LicenceService) {
    return {
        getEmployees: function(filter) {
            return function(dispatch) {
                EmployeeService.getEmployeesListGrid(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: filter.identifier,
                        items: data.items,
                        itemsCount: data.total
                    });
                });
            };
        },
        getEmployeeLocations: function(filter) {
            return function(dispatch) {
                EmployeeService.getEmploymentLocations(filter.uuid).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'employeeLocationsGrid',
                        items: data.map(function(item) {
                            item.employeeUuid = filter.uuid;
                            return item;
                        })
                    });
                });
            };
        },
        getEmployeeLicences: function(filter) {
            return function(dispatch) {
                LicenceService.subjectLicences(filter.uuid, 'uli', filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'employeeLicencesGrid',
                        items: data.items,
                        itemsCount: data.total
                    });
                });
            };
        }
    };
}
