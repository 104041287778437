/* @ngInject */
export function LocationService($http, API_BASE, Country, CountryState) {

    function appendTransform(defaults, transform) {
        defaults = angular.isArray(defaults) ? defaults : [defaults];
        return defaults.concat(transform);
    };

    return {
        getCountries: function() {
            return $http({
                url: API_BASE + "/location/countries",
                method: "GET",
                transformResponse: appendTransform($http.defaults.transformResponse, function(data) {
                    return data.reduce(function(items, obj) {
                        var country = new Country();
                        country.id = obj.cou_id;
                        country.name = obj.name;
                        items.push(country);
                        return items;
                    }, []);
                })
            }).then(function(response) {
                return response;
            }, function(error) {
                //console.log(error);
            });
        },

        getStates: function(countryID) {
            return $http({
                url: API_BASE + "/location/countries/" + countryID + "/states",
                method: "GET",
                transformResponse: appendTransform($http.defaults.transformResponse, function(data) {
                    return data.reduce(function(items, obj) {
                        var state = new CountryState();
                        state.id = obj.sta_id;
                        state.name = obj.sta_name;
                        items.push(state);
                        return items;
                    }, []);
                })
            }).then(function(response) {
                return response;
            }, function(error) {
                //console.log(error);
            });
        }
    };
}
