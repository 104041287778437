/* @ngInject */
export function AccIAAuditReport($scope, EXPORT_FORMAT_PDF, Notifier, AuthService, AccountService,
                          AccreditationDocumentsService, ACC_ACCREDITATION_CODE, DocumentManager,
                          ACC_AUDIT_CHECKLIST_ITEM_STATUS, DOCUMENT_TYPE, $window,
                          ACC_ACCREDITATION_DOC_URI, SUBJECT_TYPE, AccreditationService, $filter) {

    //todo, find out somehow here which accreditation is this (DIAS, DENTAL)

    $scope.cursorState = 'default';
    $scope.ACC_AUDIT_CHECKLIST_ITEM_STATUS = ACC_AUDIT_CHECKLIST_ITEM_STATUS;

    var ctrl = this;

    ctrl.accreditationSchemaCode = AccreditationService.getCurrentAccreditationFromState();
    ctrl.accreditationUriPath = AccreditationService.getCurrentAccreditationPathFromState();

    ctrl.selectedUserParentLocation = ""; //uuid
    ctrl.selectedUserParentLocationName = ""; //location name

    ctrl.YEARS = [new Date().getFullYear(), new Date().getFullYear()-1, new Date().getFullYear() - 2];
    ctrl.year = new Date().getFullYear();
    ctrl.auditChecklists = [];
    ctrl.selectedChecklist = null;
    ctrl.dirty = false;

    var user = AuthService.getUser();
    ctrl.isAdmin = user.type == 'Administrator';

    ctrl.fetchChecklists = function () {
        AccreditationDocumentsService.fetchAccAuditCheckLists(ctrl.accreditationSchemaCode,
            ctrl.selectedUserParentLocation, ctrl.year)
            .then(function (response) {
                ctrl.auditChecklists = response.data.result;
                ctrl.selectedChecklist = ctrl.auditChecklists[0];
            }, function (error) {

            });
    };

    ctrl.downloadPdfAuditReport = function () {
        $scope.cursorState = 'wait';
        AccreditationDocumentsService.fetchAccAuditReport(ctrl.accreditationSchemaCode,
            ctrl.selectedUserParentLocation, ctrl.year, EXPORT_FORMAT_PDF)
            .then(function (response) {
                $scope.cursorState = 'default';
                Notifier.success($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_GENERATE_REPORT_SUCCESS'));
                DocumentManager.downloadFile("/" + response.data.result, DOCUMENT_TYPE.ACCREDITATION_DOCUMENT);
            }, function (error) {
                $scope.cursorState = 'default';
                Notifier.error($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_GENERATE_REPORT_ERROR'));
            });
    };

    ctrl.downloadPdfAuditFileUploadReport = function () {
        $scope.cursorState = 'wait';
        AccreditationDocumentsService.fetchAccAuditFileUploadReport(ctrl.accreditationSchemaCode,
            ctrl.selectedUserParentLocation, ctrl.year, EXPORT_FORMAT_PDF)
            .then(function (response) {
                $scope.cursorState = 'default';
                Notifier.success($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_GENERATE_REPORT_SUCCESS'));
                DocumentManager.downloadFile("/" + response.data.result, DOCUMENT_TYPE.ACCREDITATION_DOCUMENT);
            }, function (error) {
                $scope.cursorState = 'default';
                Notifier.error($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_GENERATE_REPORT_ERROR'));
            });
    };

    $scope.$on('LOCATION_SUBJECT_SELECTED', function (event, locationItem) {
        if (locationItem.in_subject_basic_type_code === "slo") {
            ctrl.selectedUserParentLocation = locationItem.in_subject_uuid;
            ctrl.selectedUserParentLocationName = locationItem.in_username_par;
        }
    });

    $scope.$watch('accIAAuditReport.selectedUserParentLocation', function () {
        if (ctrl.selectedUserParentLocation != "") {
            ctrl.fetchChecklists();
        }
    });

    $scope.$watch('accIAAuditReport.selectedChecklist', function () {
        if (ctrl.selectedChecklist != null) {
        }
    });

    $scope.$watch('accIAAuditReport.year', function (newYear, oldYear) {
        if ((ctrl.selectedChecklist != null) && (ctrl.year != null)) {

            AccreditationDocumentsService.fetchAccAuditCheckLists(ctrl.accreditationSchemaCode,//For progress calculation for new year
                ctrl.selectedUserParentLocation, ctrl.year)
                .then(function (response) {
                    ctrl.auditChecklists = response.data.result;
                }, function (error) {

                });
        }
    });

    ctrl.makeDirty = function() {
        ctrl.dirty = true;
    };

    ctrl.confirmChange2 = function (event, message) {
        var confirmed;
        if (ctrl.dirty) {
            confirmed = $window.alert(message);
            if (event && !confirmed) {
            }
        }
    };

    $scope.$on('$locationChangeStart', function (event) {
        var confirmed;
        if (ctrl.dirty) {
            confirmed = $window.confirm($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_UNSAVED_CHANGES'));
            if (event && !confirmed) {
                event.preventDefault();
            }
        }
    });


    //Load data for parent site location if user belongs to only one site location
    if (user && (user.parentCount === 1) && (user.parents[user.parents.length - 1])
        && (user.parents[user.parents.length - 1].basicType == SUBJECT_TYPE.SITE_LOCATION)) {

        ctrl.selectedUserParentLocation = user.parents[user.parents.length - 1].uuid;
        ctrl.selectedUserParentLocationName = user.parents[user.parents.length - 1].name;
        ctrl.fetchChecklists();
    }
}
