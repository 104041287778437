import { TabsReducer } from '../../../../common/tabs/tabs.reducer';
import {IDS} from '../import-action-id.constants';
export const EmpImportHistoryTabs = {
    [IDS.app_employees_import_history]: Object.assign(TabsReducer(), {
        tabs: [{
            state: IDS.app_employees_import_history_list,
            title: 'Import History',
            visible: true,
            disabled: false,
            loading: false
        },
        {
            state: IDS.app_employees_import_history_details,
            title: 'Import Details',
            visible: false,
            disabled: false,
            loading: false,
            buttons: {
                execute: {label: 'Download'}
            }
        }]
    }),
};
