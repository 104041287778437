import API from '../../../common/http/api.fetch';

export const getEmployeeTrainings = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: `/employee/trainings`,
            method: 'GET',
            params: {
                uuid: filter.uuid,
                status: filter.status,
                offset: filter.offset,
                limit: filter.limit
            }
        }).then(({ data }) => {
            dispatch({
                type: 'GRID_FETCH',
                id: 'employeeTrainingsGrid',
                items: data.items.map((item) => ({
                    ...item,
                })),
                itemsCount: parseInt(data.items_count)
            })
        });
    }
}
