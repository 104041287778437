/**
 * @module root/legacy/components/protective-equipment
*/

import { ProtectiveEquipmentController } from "./protective-equipment.controller";
import { ProtectiveEquipmentBaseController } from './controllers/protective-equipment-controller-base';
import { ProtectiveEquipmentAddController } from './controllers/protective-equipment-controller-base-add';
import { ProtectiveEquipmentManageController } from './controllers/protective-equipment-controller-base-manage';
import { ProtectiveEquipmentBaseTestController } from './controllers/protective-equipment-controller-base-test';
import { ProtectiveEquipmentTestReportsController } from './controllers/protective-equipment-controller-base-test-reports';
import { ProtectiveEquipmentGridController } from './equipment/grid/protective-equipment-grid.controller';
import { SelectProtectiveEquipmentTypeController } from './form/select/select-protective-equipment-type.controller';
import { ProtectiveEquipmentHistoryGridController } from './history/grid/protective-equipment-history-grid.controller';
import { ProtectiveEquipmentTestsGridController } from './tests/grid/protective-equipment-tests-grid.controller'
import { ProtectiveEquipmentTestReportsGridController } from './reports/grid/protective-equipment-test-reports-grid.controller';
import { SelectPpeTestStatusController } from './form/select-ppe-test-status/select-ppe-test-status.controller';

import { protectiveEquipmentGrid } from './equipment/grid/protective-equipment-grid.component';
import { selectProtectiveEquipmentType } from './form/select/select-protective-equipment-type.component';
import { protectiveEquipmentHistoryGrid } from './history/grid/protective-equipment-history-grid.component';
import { protectiveEquipmentTestReportsGrid } from './reports/grid/protective-equipment-test-reports-grid.component'
import { protectiveEquipmentTestsGrid } from './tests/grid/protective-equipment-tests-grid.component';
import { selectPpeTestStatus } from './form/select-ppe-test-status/select-ppe-test-status.component';

import { ProtectiveEquipmentActionsFactory } from "./protective-equipment.actions";
import { PersonalBodyProtectionFactory } from './equipment/personal-body-protection.factory';
import { PersonalEquipmentFactory } from './equipment/personal-equipment.factory';
import { PersonalEquipmentTypeFactory } from './equipment/personal-equipment-type.factory';
import { PersonalLeadEquivalenceFactory } from './equipment/personal-lead-equivalence.factory';
import { PersonalEquipmentHistoryActivityFactory } from './history/personal-equipment-history-activity.factory';
import { PersonalEquipmentTestFactory } from './tests/personal-equipment-test.factory';
import { PersonalEquipmentTestCommentFactory } from './tests/personal-equipment-test-comment.factory';
import { PersonalEquipmentTesterFactory } from './tests/personal-equipment-tester.factory';

import { ProtectiveEquipmentService } from './services/protective-equipment.service';
import { ProtectiveEquipmentManager } from './services/protective-equipment-manager';
import { ProtectiveEquipmentTestService } from './services/protective-equipment-test.service';

angular
    .module(
        'components.protective-equipment',
        []
    )
    .controller({
        ProtectiveEquipmentController,
        ProtectiveEquipmentBaseController,
        ProtectiveEquipmentAddController,
        ProtectiveEquipmentManageController,
        ProtectiveEquipmentBaseTestController,
        ProtectiveEquipmentTestReportsController,
        ProtectiveEquipmentGridController,
        SelectProtectiveEquipmentTypeController,
        ProtectiveEquipmentHistoryGridController,
        ProtectiveEquipmentTestReportsGridController,
        ProtectiveEquipmentTestsGridController,
        SelectPpeTestStatusController,
    })
    .service({
        ProtectiveEquipmentService,
        ProtectiveEquipmentManager,
        ProtectiveEquipmentTestService
    })
    .factory({
        PersonalBodyProtection: PersonalBodyProtectionFactory,
        PersonalEquipment: PersonalEquipmentFactory,
        PersonalEquipmentType: PersonalEquipmentTypeFactory,
        PersonalLeadEquivalence: PersonalLeadEquivalenceFactory,
        PersonalEquipmentHistoryActivity: PersonalEquipmentHistoryActivityFactory,
        PersonalEquipmentTest: PersonalEquipmentTestFactory,
        PersonalEquipmentTestComment: PersonalEquipmentTestCommentFactory,
        PersonalEquipmentTester: PersonalEquipmentTesterFactory,
        ProtectiveEquipmentActions: ProtectiveEquipmentActionsFactory
    })
    .component({
        protectiveEquipmentGrid,
        selectProtectiveEquipmentType,
        protectiveEquipmentHistoryGrid,
        protectiveEquipmentTestReportsGrid,
        protectiveEquipmentTestsGrid,
        selectPpeTestStatus
    })
    .run(($templateCache) => {
        $templateCache.put('./protective-equipment-grid-filter.html', require('./equipment/grid/protective-equipment-grid-filter.html'));
        $templateCache.put('./select-protective-equipment-type.html', require('./form/select/select-protective-equipment-type.html'));
        $templateCache.put('./protective-equipment-test-reports-grid-filter.html', require('./reports/grid/protective-equipment-test-reports-grid-filter.html'));
        $templateCache.put('./personal-equipment-listing.html', require('./views/personal-equipment/personal-equipment-listing.html'));
        $templateCache.put('./personal-equipment-details.html', require('./views/personal-equipment/personal-equipment-details.html'));
        $templateCache.put('./personal-equipment-test-details.html', require('./views/personal-equipment/personal-equipment-test-details.html'));
        $templateCache.put('./personal-equipment-test-reports-listing.html', require('./views/personal-equipment/personal-equipment-test-reports-listing.html'));
        $templateCache.put('./personal-equipment-tests.html', require('./views/personal-equipment/personal-equipment-tests.html'));
        $templateCache.put('./personal-equipment-history.html', require('./views/personal-equipment/personal-equipment-history.html'));
        $templateCache.put('./personal-equipment-history-details.html', require('./views/personal-equipment/personal-equipment-history-details.html'));
        $templateCache.put('./personal-equipment-type-dialog.html', require('./views/personal-equipment/details/personal-equipment-type-dialog.html'));
        $templateCache.put('./personal-equipment-lead-equivalence-dialog.html', require('./views/personal-equipment/details/personal-equipment-lead-equivalence-dialog.html'));
        $templateCache.put('./personal-equipment-tester-dialog.html', require('./views/personal-equipment/test/personal-equipment-tester-dialog.html'));
        $templateCache.put('./protective-equipment-tests-grid-filter.html', require('./tests/grid/protective-equipment-tests-grid-filter.html'));
        $templateCache.put('./select-ppe-test-status.html', require('./form/select-ppe-test-status/select-ppe-test-status.html'));
    });
