/* @ngInject */
export const CertificateDataFactory = (Helpers, DATE_FORMAT) => {
    return function CertificateData() {

        this.certificate = null;
        this.expiryDate = null;

        this.formatExpiryDate = function() {
            return Helpers.formatDate(this.expiryDate, DATE_FORMAT.INPUT);
        };
    }
};
