import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsHeadOfficesController($ngRedux, $state, $uiRouterGlobals, $timeout, $mdDialog, AdministrationActions, AuthService) {
    var ctrl = this;
    var state = $state.current.name;
    var stateParams = _.clone($uiRouterGlobals.params);
    var dialog = null;
    var unsubscribe = angular.noop;
    var onHeadOffice = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);

        var watchHeadOffice = watch($ngRedux.getState, 'administration.administrationAccountsHeadOffice');
        onHeadOffice = $ngRedux.subscribe(watchHeadOffice(function(headOffice) {
            if (headOffice.uuid) onHeadOfficeReady(headOffice.uuid);
        }));
    };

    ctrl.admin = AuthService.getUser().typeCode === 'adm';

    ctrl.selectHeadOffice = function(event) {
        $ngRedux.dispatch({
            type: 'GRID_RESET',
            id: 'administrationAccountsSiteLocationsGrid'
        });
        state = 'app.administration.accounts.headOfficesDetails';
        if (event.item.uuid === ctrl.headOffice.uuid)
            onHeadOfficeReady(event.item.uuid);
        else
            ctrl.getHeadOfficeDetails(event.item.uuid, true);
    };

    ctrl.newHeadOffice = function() {
        onHeadOfficeReady();
    };

    ctrl.inactivateDialog = function(event) {
        var confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('This action will inactivate location.')
            .ariaLabel('Inactivate Head Office')
            .ok('Inactivate')
            .cancel('Cancel');

        dialog = $mdDialog.show(confirm).then(function() {
            ctrl.inactivateLocation(event);
        }, angular.noop);
    };

    ctrl.$onDestroy = function() {
        if (dialog) $mdDialog.hide(dialog);
        unsubscribe();
        onHeadOffice();
    };

    function onHeadOfficeReady(uuid) {
        if (uuid) {
            $ngRedux.dispatch({
                type: 'TAB_ENABLE',
                id: 'app.administration.accounts',
                state: [
                    'app.administration.accounts.headOfficesDetails',
                    'app.administration.accounts.headOfficesContacts',
                    'app.administration.accounts.headOfficesLicences',
                    'app.administration.accounts.headOfficesEquipment',
                    'app.administration.accounts.headOfficesModules',
                ]
            });
            if (state === 'app.administration.accounts.headOfficesContactDetails') {
                $ngRedux.dispatch({
                    type: 'TAB_SHOW',
                    id: 'app.administration.accounts',
                    state: ['app.administration.accounts.headOfficesContactDetails'],
                    stateParams: {
                        hof: stateParams.hof,
                        contact: stateParams.contact
                    }
                });
                return;
            }
        }
        else {
            state = 'app.administration.accounts.headOfficesDetails';
            $ngRedux.dispatch({
                type: 'TAB_ENABLE',
                id: 'app.administration.accounts',
                state: [state]
            });
        }

        $timeout(function() {
            $ngRedux.dispatch({
                type: 'TAB_SELECT',
                id: 'app.administration.accounts',
                state: state,
                stateParams: {hof: uuid}
            });
        }, 200);
    }

    function mapStateToThis(state) {
        return {
            headOffice: state.administration.administrationAccountsHeadOffice
        };
    }
}
