import { GridReducer } from '../../common/grid/grid.reducer';
import { tabs } from './credentialing.tabs';

export default {
    ...tabs,
    creInvite: {
        purpose: null,
        type: null,
        typeData: [
            { value: '3pcspr', label: '3rd party contractor (service provider)' },
            { value: '3pcsog', label: '3rd party contractor (supply of goods)' },
            { value: '3pcssg', label: '3rd party contractor (service & supply of goods)' }
        ],
        supplyHealthcare: 0,
        supplyNonHealthcare: 0,
        gst: null,
        gstData: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        vendor: null,
        vendors: [],
        vendor_uuid: null,
        vendor_name: null,
        abn_num: null,
        acn_num: null,
        address_billing: null,
        address_delivery: null,
        position: null,
        positions: [],
        title: null,
        first_name: null,
        last_name: null,
        email: null,
        contact_position: null,
        contact_title: null,
        contact_first_name: null,
        contact_last_name: null,
        contact_email: null,
        contacts: [],
        applicant_signature: null,
        applicant_signature_date: null,
        submit_date: new Date(),
        hof_name: null,
        hof_contact_name: null,
        hof_contact_phone: null,
        hof_contact_email: null,
        approval_signature: null,
        status: null,
    },
    creVendor: {
        application_id: null,
        localRegLicence: null,
        localRegLicenceData: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        otherLicence: null,
        otherLicenceData: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        company_local: 0,
        bank_acc_name: null,
        bank_bsb_code: null,
        bank_acc_no: null,
        bank_pay_id: null,
        company_international: 0,
        beneficiary_acc_name: null,
        beneficiary_acc_num_iban: null,
        beneficiary_country: null,
        beneficiary_address: null,
        beneficiary_swift_bic_code: null,
        beneficiary_bank_address: null,
        beneficiary_bank_country: null,
        currency: null,
        wcp_number: null,
        wcp_expiry_date: null,
        wcp_doc: null,
        plp_number: null,
        plp_expiry_date: null,
        plp_doc: null,
        pip_number: null,
        pip_expiry_date: null,
        pip_doc: null,
        insurance_docs: [],
        acc_id: null,
        acc_scheme: null,
        acc_scheme_name: null,
        acc_scheme_data: [],
        acc_std_next_audit_date: null,
        acc_std_doc: null,
        acc_docs: [],
        cag_id: null,
        gty_healthcare: 0,
        gty_non_healthcare: 0,
        gty_description: null,
        man_id: null,
        man_name: null,
        man_data: [],
        model_number: null,
        cou_of_origin_id: null,
        tda_artg_fda_ce: null,
        gcol_doc: null,
        pog_ex_tax: null,
        pog_in_tax: null,
        price_is_valid_for: null,
        is_price_sub_to_roe: null,
        roeData: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        roe_from: null,
        roe_to: null,
        roe_cur_id: null,
        perc_of_price_sub_to_roe: null,
        gquo_doc: null,
        goods_docs: [],
        caw_id: null,
        caw_description: null,
        swp: null,
        is_aw_available: null,
        is_aw_available_data: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        is_ac_involved: null,
        is_ac_involved_data: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        ac_per_year: null,
        aca: null,
        are_you_res_for_as: null,
        are_you_res_for_as_data: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        asr_com_details: null,
        does_item_req_ins_by_ur_com: null,
        does_item_req_ins_by_ur_com_data: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        do_emp_req_lic_by_lra: null,
        do_emp_req_lic_by_lra_data: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        emp_lra_lic_type_id: null,
        emp_lra_lic_type_data: [],
        emp_lic_iss_by_lra_name: null,
        do_emp_req_eoo_lic: null,
        do_emp_req_eoo_lic_data: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        emp_eoo_lic_type_id: null,
        emp_eoo_lic_type_id_data: [],
        does_your_org_req_al_to_rtg: null,
        does_your_org_req_al_to_rtg_data: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        org_al_type_id: null,
        org_al_type_data: [],
        other_rtp_this_item: null,
        warranty_docs: [],
        cas_id: null,
        cas_healthcare: 0,
        cas_non_healthcare: 0,
        cas_description: null,
        cas_do_emp_req_lic_by_lra: null,
        cas_do_emp_req_lic_by_lra_data: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        cas_emp_lra_lic_type_id: null,
        cas_emp_lra_lic_type_data: [],
        cas_emp_lic_iss_by_lra_name: null,
        cas_do_emp_req_eoo_lic: null,
        cas_do_emp_req_eoo_lic_data: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        cas_emp_eoo_lic_type_id: null,
        cas_emp_eoo_lic_type_data: [],
        fixed_rate: 0,
        hourly_rate: 0,
        pos_ex_tax: null,
        pos_in_tax: null,
        cas_price_is_valid_for: null,
        hos_from_am: null,
        hos_from_pm: null,
        cos_poh: null,
        pos_poh_ex_tax: null,
        pos_poh_in_tax: null,
        service_docs: [],
        countries: [],
        currencies: [],
        statuses: [],
    },
    creVendorsGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0
    },
    creVendorsAccStdGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: undefined,
    },
    creVendorsGoodsGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: undefined,
    },
    creVendorsWarrantiesGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: undefined,
    },
    creVendorsServicesGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: undefined,
    },
};
