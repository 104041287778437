/** @module root/components/accreditation/document */

import * as STATES                      from './master-doc-list.states';
import { IDS }                          from './master-doc-list-action-id.constants';
import { accMasterDocDetails }          from './details/details.component';
import { accMasterDocReviewsGrid }          from './details/reviews-grid.component';
import { accMasterDocList }             from './list/list.component';
import { accMasterDocListGrid }             from './list/list-grid.component';

export const MASTER_DOC_LIST_MODULE = angular.module(
    'migration.accreditation.master-doc-list',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
        Object.values({
            ...STATES,
        }).map(state => stateRegistry.register(state));
    }]
).component({
    accMasterDocDetails,
    accMasterDocReviewsGrid,

    accMasterDocList,
    accMasterDocListGrid,

}).run(['$templateCache', ({ put }) => {
    put(IDS.app_accreditation_scheme_master_doc_list_list,     '<acc-master-doc-list></acc-master-doc-list>');
    put(IDS.app_accreditation_scheme_master_doc_list_details,  '<acc-master-doc-details></acc-master-doc-details>');
    put(IDS.app_accreditation_scheme_master_doc_list_add,      '<acc-master-doc-details></acc-master-doc-details>');
    put('./acc-documents-list-grid-filter.html',                require('./list/list-grid-filter.html'));
}]);
