export const RoomFactory = () => {
    return function Room() {
        this.uuid = null;
        this.name = null;
        this.displayName = null;
        this.level = null;
        this.building = null;
        this.id = null;
        this.accountId = null;
        this.email = null;
        this.phone = null;
        this.parentUsername = null;
        this.parentUuid = null;
        this.typeName = null;
        this.number = null;
    };
};
