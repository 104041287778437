import { AP_REQUEST_RESET } from '../applications-permits.actions.types';

export const apLaserRequestPublicState = {
    name: 'public.applicationsPermits.laser.request',
    redirectTo: 'public.applicationsPermits.laser.request.details',
    url: '/request',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: AP_REQUEST_RESET,
            id: 'rmLaserRequest'
        });
    }
};

export const apLaserRequestIntroPublicState = {
    name: 'public.applicationsPermits.laser.request.intro',
    url: '?token&invite',
    component: 'apLaserRequestIntro'
};
