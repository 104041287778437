export function AccAssessmentFactory() {
    return function AccAssessment() {

        this.id = null;
        this.standardId = null;
        this.standardNumber = null;
        this.value = null;
        this.assessmentItems = [];
    };
};
