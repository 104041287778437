/* @ngInject */
export function AccIAAuditSchedule($scope, Notifier,
                            AuthService, AccountService,
                            AccreditationDocumentsService, ACC_ACCREDITATION_CODE, $mdDialog,
                            ACC_AUDIT_SCHEDULE_ITEM_STATUS, $rootScope, SUBJECT_TYPE, AccreditationService, $filter) {

    //todo, find out somehow here which accreditation is this (DIAS, DENTAL)

    $scope.cursorState = 'wait';
    $scope.ACC_AUDIT_SCHEDULE_ITEM_STATUS = ACC_AUDIT_SCHEDULE_ITEM_STATUS;

    var ctrl = this;
    ctrl.accreditationSchemaCode = AccreditationService.getCurrentAccreditationFromState();

    ctrl.selectedUserParentLocation = ""; //uuid
    ctrl.selectedUserParentLocationName = ""; //location name

    ctrl.YEARS = [new Date().getFullYear(), new Date().getFullYear() + 1, new Date().getFullYear() + 2];
    ctrl.year = new Date().getFullYear();
    ctrl.auditSheduleItems = [];
    ctrl.auditShedule = {}; //Current schedule
    ctrl.changedSheduleFields = []; //Fields currently updated

    ctrl.tableMonths = Object.values(
        $filter('translate')(
            [1,2,3,4,5,6,7,8,9,10,11,12].map(index => `COMMON.DATE.MONTHS.SHORT.${index}`)
        )
    );

    var user = AuthService.getUser();
    ctrl.isAdmin = false;

    var user = AuthService.getUser();
    ctrl.isAdmin = user.type == 'Administrator';

    function initializeAuditSchedule() {
        if (ctrl.auditSheduleItems) {
            ctrl.auditSheduleItems.forEach(function (item) {
                ctrl.auditShedule[item.id] = [null, null, null, null, null, null, null, null, null, null, null, null];
            });
        }
    }

    $scope.determineStatusAndColor = function (value, auditScheduleItem, index) {
        if (value == ACC_AUDIT_SCHEDULE_ITEM_STATUS.DONE) {
            return "table-success";
        } else if (value == ACC_AUDIT_SCHEDULE_ITEM_STATUS.SCHEDULED) {
            return "table-warning";
        } else if (value == ACC_AUDIT_SCHEDULE_ITEM_STATUS.OVERDUE) {
            return "table-danger";
        } else if (value == ACC_AUDIT_SCHEDULE_ITEM_STATUS.NOT_APPLICABLE) {
            return "table-info";
        }

        if (auditScheduleItem.some(function (item) {
                if (item == ACC_AUDIT_SCHEDULE_ITEM_STATUS.NOT_APPLICABLE) {
                    return true;
                }
            })) {
            return "info";
        } else {
            var d = new Date();
            var m = d.getMonth();
            var y = d.getFullYear();
            if (ctrl.year * 100 + index < y * 100 + m) {
                return "background-light-gray";
            }
        }
    };

    function SelectItemValueDialog($scope, $mdDialog, title, slocUuid, scheduleItem, month, year, isAdmin, scheduleItemDescription) {
        $scope.headerTitle = title;
        $scope.scheduleItem = scheduleItem;
        $scope.slocUuid = slocUuid;
        $scope.month = month;
        $scope.year = year;
        $scope.isAdmin = isAdmin;
        $scope.scheduleItemDescription = scheduleItemDescription;


        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.submit = function (selectedValue) {
            //Check if it is not already passed month
            var currentMonth = new Date().getMonth();
            //currentMonth = 0;
            if (!$scope.isAdmin) {
                for (var i = 0; i < scheduleItem.length; i++) {
                    if (!_.isEmpty(scheduleItem[i])) {
                        Notifier.error($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_SCHEDULE_SET_ALREADY'));
                        return;
                    }
                }
            }
            if ($scope.year * 100 + $scope.month < new Date().getFullYear() * 100 + currentMonth) {
                Notifier.error($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_SCHEDULE_SET_ERROR'));
            } else {
                for (var i = 0; i < scheduleItem.length; i++) {
                    scheduleItem[i] = null;
                }
                scheduleItem[month] = selectedValue;
                //Update list of changed items
                ctrl.changedSheduleFields.push({
                    'year': ctrl.year,
                    'month': month,
                    'standard': $scope.scheduleItemDescription.standard,
                    'field': $scope.scheduleItemDescription.name,
                    'value': selectedValue
                });
            }

            $mdDialog.hide();
        };
    }

    ctrl.onSelectItemValueDialog = function (scheduleAuditItem, index, selectedScheduleItem) {

        $mdDialog.show({
            controller: SelectItemValueDialog,
            template: require('../../../../views/accreditation/quality-controll-documents/internal-audit/accreditation-select-audit-schedule-value-dialog.html'),
            scope: $scope, // use parent scope in template
            preserveScope: true,
            locals: {
                title: $filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.SCHEDULE_TITLE'),
                slocUuid: ctrl.selectedUserParentLocation,
                scheduleItem: scheduleAuditItem,
                month: index,
                year: ctrl.year,
                isAdmin: ctrl.isAdmin,
                scheduleItemDescription: selectedScheduleItem
            },
            clickOutsideToClose: true
        });
    };

    ctrl.checkIfItemIsOverdue = function (schedule) {
        var currentMonth = new Date().getMonth();
        var currentYear = new Date().getFullYear();
        for (var key in schedule) {
            if (schedule.hasOwnProperty(key)) {
                var item = schedule[key];
                for (var i = 0; i < item.length; i++) {
                    if ((item[i] == ACC_AUDIT_SCHEDULE_ITEM_STATUS.SCHEDULED) && (ctrl.year * 100 + i < currentYear * 100 + currentMonth)) {
                        item[i] = ACC_AUDIT_SCHEDULE_ITEM_STATUS.OVERDUE;
                    }
                }

            }
        }
    };

    ctrl.fetchAccAuditScheduleItems = function () {
        $scope.cursorState = 'wait';
        AccreditationDocumentsService.fetchAccAuditScheduleItems(ctrl.accreditationSchemaCode,
            ctrl.selectedUserParentLocation, ctrl.year)
            .then(function (response) {
                $scope.cursorState = 'default';
                ctrl.auditSheduleItems = response.data.result;
                ctrl.fetchLocationAuditSchedule();
            }, function (error) {
                $scope.cursorState = 'default';
            });
    };

    ctrl.fetchLocationAuditSchedule = function () {
        $scope.cursorState = 'wait';
        AccreditationDocumentsService.fetchAccAuditSchedule(ctrl.accreditationSchemaCode,
            ctrl.selectedUserParentLocation, ctrl.year)
            .then(function (response) {
                $scope.cursorState = 'default';
                if (_.isEmpty(response.data.result)) {
                    initializeAuditSchedule();
                } else {
                    ctrl.auditShedule = response.data.result;
                    ctrl.checkIfItemIsOverdue(ctrl.auditShedule);
                }

            }, function (error) {
                $scope.cursorState = 'default';
                initializeAuditSchedule();
            });
    };

    ctrl.clearCurrentSchedule = function () {
        ctrl.changedSheduleFields = [];
        ctrl.fetchAccAuditScheduleItems();
    };

    ctrl.saveCurrentForm = function () {
        $rootScope.confirmationDialog($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_SCHEDULE_CONFIRM'))
            .then(function (result) {
                AccreditationDocumentsService.editAccAuditSchedule(ctrl.accreditationSchemaCode,
                    ctrl.selectedUserParentLocation, ctrl.year, ctrl.auditShedule, ctrl.changedSheduleFields)
                    .then(function (response) {
                        Notifier.success($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_SCHEDULE_SUCCESS'));
                        ctrl.changedSheduleFields = [];
                    }, function (error) {

                    });
            }, function (error) {
                return;
            });
    };

    $scope.$on('LOCATION_SUBJECT_SELECTED', function (event, locationItem) {
        if (locationItem.in_subject_basic_type_code === "slo") {
            $scope.cursorState = 'default';
            ctrl.selectedUserParentLocation = locationItem.in_subject_uuid;
            ctrl.selectedUserParentLocationName = locationItem.in_username_par;
        }
    });

    $scope.$watch('accIAAuditSchedule.selectedUserParentLocation', function () {
        if (ctrl.selectedUserParentLocation != "") {
            ctrl.fetchAccAuditScheduleItems();
        }
    });


    //Load data for parent site location if user belongs to only one site location
    if (user && (user.parentCount === 1) && (user.parents[user.parents.length - 1])
        && (user.parents[user.parents.length - 1].basicType == SUBJECT_TYPE.SITE_LOCATION)) {

        ctrl.selectedUserParentLocation = user.parents[user.parents.length - 1].uuid;
        ctrl.selectedUserParentLocationName = user.parents[user.parents.length - 1].name;
        ctrl.fetchAccAuditScheduleItems();
    }
}
