/*
*   List of Actions with TYPE that should be passed from accreditation.reducer.js to editor.reducer.js
 */
export const PREFIX = 'ACCREDITATION_SETTINGS';

export const TYPES = {
    SCHEME_DETAILS_REFRESH:             `${PREFIX}_SCHEME_DETAILS_REFRESH`,
    SCHEME_STATUS_REFRESH:              `${PREFIX}_SCHEME_STATUS_REFRESH`,

    SECTION_DETAILS_REFRESH:      `${PREFIX}_SECTION_DETAILS_REFRESH`,

    FETCH_SCHEME_ENABLED_HEAD_OFFICES:  `${PREFIX}_FETCH_DEF_HOF_ENABLED_HEAD_OFFICES`,
    FETCH_SCHEME_ENABLED_SITE_LOCATIONS:`${PREFIX}_FETCH_HOF_ENABLED_SITE_LOCATIONS`,

    HOF_DOC_DETAILS_REFRESH:            `${PREFIX}_HOF_DOC_DETAILS_REFRESH`,
    HOF_DOC_DETAILS_RESET:              `${PREFIX}_HOF_DOC_DETAILS_RESET`,

    SLO_DOC_DETAILS_REFRESH:            `${PREFIX}_SLO_DOC_DETAILS_REFRESH`,
    SLO_DOC_DETAILS_RESET:              `${PREFIX}_SLO_DOC_DETAILS_RESET`,
    SLO_DOCS_LOCATION_SELECTS_RESET:    `${PREFIX}_SLO_DOCS_LOCATION_SELECTS_RESET`,

    DEFAULT_DOCUMENT_DETAILS_REFRESH:   `${PREFIX}_DEFAULT_DOCUMENT_DETAILS_REFRESH`,
    DOCUMENT_SAMPLE_DOC_CHANGED:        `${PREFIX}_DOCUMENT_SAMPLE_DOC_CHANGED`,

    FETCH_ASS_HOF_SCHEME_HEAD_OFFICES:  `${PREFIX}_FETCH_ASS_HOF_SCHEME_HEAD_OFFICES`,
    ASS_HOF_SELECT_HEAD_OFFICE:         `${PREFIX}_ASS_HOF_SELECT_HEAD_OFFICE`,
    FETCH_NOT_ENABLED_HEAD_OFFICES:     `${PREFIX}_FETCH_NOT_ENABLED_HEAD_OFFICES`,
    SELECTED_HEAD_OFFICE_RESET:         `${PREFIX}_SELECTED_HEAD_OFFICE_RESET`,
    SET_SITE_LOCATIONS_HEADER:          `${PREFIX}_SET_SITE_LOCATIONS_HEADER`,
    SELECT_CASCADE_STANDARD:            `${PREFIX}_SELECT_CASCADE_STANDARD`,
    SELECT_CASCADE_STANDARD_RESET:      `${PREFIX}_SELECT_CASCADE_STANDARD_RESET`,
    ASSESSMENT_FETCH_PNP_TEMPLATES:     `${PREFIX}_ASSESSMENT_FETCH_PNP_TEMPLATES`,
    NEW_ASSESSMENT_ASSIGNED_TO_HOF:     `${PREFIX}_NEW_ASSESSMENT_ASSIGNED_TO_HOF`,
    NEW_ASSESSMENT_DETACH_FROM_HOF:     `${PREFIX}_NEW_ASSESSMENT_DETACH_FROM_HOF`,
    NEW_ASSESSMENT_ITEM_RESET:          `${PREFIX}_NEW_ASSESSMENT_ITEM_RESET`,
};
