import {TYPES} from './audit-action-type.constants';
import {IDS} from './audit-action-id.constants';
import * as FORM from '../../../common/form/input/input.constants';
import {GridReducer} from '../../../common/grid/grid.reducer';
import * as GRID from '../../../common/grid/grid.constants';
import {getAvailableYearsRange} from '../../../reducers/accreditation.reducer';
import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';

/**
 * @function AuditReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function AuditReducer(state, action) {

    if (typeof state === 'undefined') {
        const currentYear = new Date().getFullYear();
        return {
            siteLocation: {
                uuid: null,
                locationName: null,
                fiscalYearStart: null,
                autocompleteReviewers: [],
            },
            schedule: {
                fiscalYear: currentYear,
                monthColumns:       [],
                standardRows:       [],
            },

            addAudit: {
                availableYears: getAvailableYearsRange(currentYear),
                sectionsTree: [],
                level1Standards: [],
                level2Standards: [],
                level3Standards: [],
                level1StandardId: null,
                level2StandardId: null,
                level3StandardId: null,
            },

            details: {
                activities: null,
                standardId: null,
                standardParentId: null,
                standardName:   null,
                standardLevel:  null,
                sourceTab:      null,
                selectedEvidenceProvider: null,
                selectedReviewer: null,

                //review
                name:           null,
                description:    null,
                dueDate:        null,
                // evidenceProviders: [],
                reviewers: [],

                //new item for external reviewer
                // evidenceProviderFirstName:  null,
                // evidenceProviderLastName:   null,
                // evidenceProviderEmail:      null,

                // qualityManagerFirstName:    null,
                // qualityManagerLastName:     null,
                // qualityManagerEmail:        null,

                // autocompleteEvidenceProviders: [],
            },
        }
    }

    switch (action.type) {
        case TYPES.RESET_ADD_AUDIT_FIELDS:
            if (action.id === IDS.addAudit) {
                state = set({...state}, 'details.auditRequestId', null);
                set(state, 'details.name', null);
                set(state, 'details.description', null);
                set(state, 'details.date', null);
                set(state, 'details.reviewers', []);
                set(state, 'details.selectedReviewer', null);
                // set(state, 'details.evidenceProviders', []);
                // set(state, 'details.evidenceProviderFirstName', null);
                // set(state, 'details.qualityManagerLastName', null);
                // set(state, 'details.qualityManagerEmail', null);
                // set(state, 'details.autocompleteEvidenceProviders', []);
                return state
            }
        case TYPES.RESET_ADD_AUDIT_ASSIGNEES:
            if (action.id === IDS.addAudit) {
                state = set({...state}, 'details.auditRequestId', null);
                set(state, 'details.reviewers', []);
                set(state, 'details.selectedReviewer', null);
                // set(state, 'details.evidenceProviders', []);
                // set(state, 'details.evidenceProviderFirstName', null);
                // set(state, 'details.qualityManagerLastName', null);
                // set(state, 'details.qualityManagerEmail', null);
                // set(state, 'details.autocompleteEvidenceProviders', []);
                return state
            }
        case TYPES.FETCH_SITE_LOCATION_DETAILS:
                return { ...state,
                    siteLocation: { ...state.siteLocation,  ...action.siteLocation }
                };
        case TYPES.SELECT_NEW_AUDIT_FISCAL_YEAR:
                return { ...state,
                    siteLocation: {...state.siteLocation, maxDueDate: action.maxDueDate},
                    details: {...state.details, date: null}
                };
        case TYPES.FETCH_SITE_LOCATION_MANAGERS:
                return  set({...state}, 'siteLocation.autocompleteReviewers', action.autocompleteReviewers);
        case TYPES.FETCH_SCHEDULE:
            if (action.id === IDS.accAudit) {
                state = set({...state}, 'schedule.monthColumns',        action.monthColumns)
                set(            state,  'schedule.standardRows',        action.standardRows)
                return set(     state,  'schedule.fiscalYear',          action.currentFiscalYear)
            }
        case TYPES.RESET_SCHEDULE:
            if (action.id === IDS.accAudit) {
                state = set({...state}, 'schedule.monthColumns',        [])
                set(            state,  'schedule.standardRows',        [])
                set(            state,  'siteLocation.fiscalYearStart', null)
                return set(     state,  'schedule.fiscalYear', null)
            }

        case TYPES.FETCH_EVIDENCE_PROVIDER_CANDIDATES:
            if (action.id === IDS.accAudit) {
                return set({...state}, 'details.autocompleteEvidenceProviders', action.data)
            }
        case TYPES.REMOVE_AUDIT_ASSIGNEE:
            return set({...state}, action.path, get(state, action.path).filter(
                item => item !== action.item
            ))

        case TYPES.RESET_EVIDENCE_PROVIDERS:
            return set({...state}, 'details.evidenceProviders', [])

        case TYPES.FETCH_USER_DEAILS:
            if (action.id === IDS.accAudit) {
                return set({...state}, 'details.reviewers', [{
                    employee_uuid:  action.data.sub_uuid,
                    first_name:     action.data.first_name,
                    last_name:      action.data.last_name,
                    email:          action.data.email,
                    type_name:      action.data.sty_name,
                    username:       action.data.username,
                    assignee:       action.data.first_name && action.data.last_name ?
                                 `${action.data.first_name} ${action.data.last_name}`:
                                    action.data.username,

                }])
            }
        case TYPES.REFRESH_AUDIT_DETAILS:
            if (action.id === IDS.accAudit) {
                return set({...state}, 'details', {...action.data,
                    autocompleteEvidenceProviders: [],
                    selectedEvidenceProvider: null,
                    selectedReviewer: null,
                })
            }
        case TYPES.ADD_AUDIT_ASSIGNEE:
            if (action.id === IDS.accAudit) {
                state = set({...state}, action.path, get(state, action.path).concat([action.item]))
                return merge(state, {details: {
                    // selectedEvidenceProvider: null,
                    selectedReviewer: null,

                    // evidenceProviderFirstName:  null,
                    // evidenceProviderLastName:   null,
                    // evidenceProviderEmail:      null,

                    // qualityManagerFirstName:    null,
                    // qualityManagerLastName:     null,
                    // qualityManagerEmail:        null,
                }});
            }
        case TYPES.SELECT_NEW_AUDIT_STANDARD:
            if (action.id === IDS.addAudit) {
                state = {...state};
                if(action.level < 3){
                    state = set(state,
                        `addAudit.level${action.level + 1}Standards`,
                        action.standards.map(item => ({
                            value: item.id,
                            label: item.name,
                            disabled: item.disabled,
                        }))
                    )
                    set(state, 'addAudit.level3StandardId', null)
                }
                let name = '';
                let standardId = null;
                let standardParentId = null;
                let activities = []; // all standard ids for which evidence task will be created
                switch(action.level){
                    case 1:
                        const firstChild = get(action.standards,'[0]');
                        name = firstChild.name;
                        standardId = firstChild.id;
                        activities = firstChild.children.map(item => item.id);
                        standardParentId = action.standardId;
                        set(state, 'addAudit.level2StandardId', standardId);
                        set(state, 'addAudit.level3Standards',
                            firstChild.children.map(item => ({
                                value: item.id,
                                label: item.name,
                            }))
                        )
                    break;
                    case 2:
                        name = action.name;
                        standardId = action.standardId;
                        activities = action.standards.map(item => item.id);
                        standardParentId = action.standardParentId;
                        set(state, 'addAudit.level3Standards',
                            action.standards.map(item => ({
                                value: item.id,
                                label: item.name,
                            }))
                        )
                    break;
                    case 3:
                        name = action.name;
                        standardId = action.standardId;
                        activities = [action.standardId];
                        standardParentId = action.standardParentId;
                    break;
                }
                set(state, 'details.activities', activities);
                set(state, 'details.standardId', standardId);
                set(state, 'details.standardParentId', standardParentId);

                return set(state, 'details.name', name);
            }

        /* GENERIC TYPES */

        case 'LOCATION_SELECTED':
            if(action.id === IDS.accAudit) { // slo select-cascade
                return set({...state}, 'siteLocation', action)
            }
        case 'AUTOCOMPLETE_SELECTED':
            if (action.id === IDS.accAudit && action.item) {
                return set({...state}, action.path, action.item)
            }
        case FORM.INPUT_SELECT:
        case FORM.INPUT_TEXT:
        case FORM.INPUT_CHECKBOX:
        case FORM.INPUT_DATE:
        case FORM.INPUT_RADIO:
        case 'INPUT_EMAIL_CHANGE':
        case 'INPUT_PHONE_CHANGE':
        case 'INPUT_TEXT_AREA_CHANGE':
        case 'INPUT_SIGNATURE':
            if (action.id === IDS.accAudit) { //for fiscalYear
                return set({...state}, action.path, action.input)
            }
        case GRID.GRID_INIT:
        case GRID.GRID_FILTER:
        case GRID.GRID_FILTER_TOGGLE:
        case GRID.GRID_RESET_FILTER:
        case GRID.GRID_INIT_FILTER:
        case GRID.GRID_LOADING:
        case GRID.GRID_PAGINATE:
        case GRID.GRID_RELOAD:
        case GRID.GRID_FETCH:
        case GRID.GRID_SELECT:
        case GRID.GRID_SELECT_ALL:
        case GRID.GRID_DESELECT:
        case GRID.GRID_DESELECT_ALL:
            if (action.id === IDS.accAudit) {
                return set({...state}, action.path,
                    GridReducer( get(state, action.path), action)
                )
            }
        case GRID.GRID_RESET:
            if (action.id === IDS.accAudit) {
                return set({...state}, action.path,
                    GridReducer( get(state, action.path),{
                        type: GRID.GRID_RESET_FILTER,
                        exclude: action.exclude || (action.path === IDS.documentAddGrid ? ['slo_uuid','doc_type_code','assignedDocIds'] : []),
                    })
                )
            }
        default:
            return state;
    }
}

const index = (children, list = []) => {
    children && children.forEach(item => {
        if(item) {
            list[parseInt(item.id)] = item;
            index(item.children, list);
        }
    })
    return list;
};
