import watch from 'redux-watch';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import has from 'lodash/has';
import moment from 'moment';
import Pikaday from 'pikaday';
import { DATE_FORMAT } from '../../../utils/util.constants';

/**
 * Creates a new InputDateController.
 * @class
 * @extends InputController
 */
class InputDateController {
    /**
     * @constructor
     * @param $ngRedux
     * @param $element
     */
    constructor($ngRedux, $element) {
        this.$ngRedux = $ngRedux;
        this.$element = $element;
        this.ICON_CALENDAR = 'calendar.svg';
        this.ICON_REMOVE = 'remove.svg';
    }

    $onInit() {
        if (isUndefined(this.validate))
            this.validate = false;
        if (isUndefined(this.dateFormat))
            this.dateFormat = DATE_FORMAT.INPUT;
        if (isUndefined(this.minDate))
            this.minDate = null;
        if (isUndefined(this.maxDate))
            this.maxDate = null;
        if (isUndefined(this.yearRange))
            this.yearRange = [1900, new Date().getFullYear()+50];
        if (isUndefined(this.disabled))
            this.disabled = false;

        if (this.validate && isUndefined(this.name))
            throw new Error('Missing input name attribute');

        if (this.disabled)
            this.ICON_REMOVE = this.ICON_CALENDAR;

        this.iconPath = this.ICON_CALENDAR;
        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);

        this.picker = new Pikaday({
            field: this.$element.find('input')[0],
            format: this.dateFormat,
            minDate: this.minDate,
            maxDate: this.maxDate,
            yearRange: this.yearRange,
            onSelect: (function(date) {
                this.iconPath = this.ICON_REMOVE;
                this.$ngRedux.dispatch({
                    type: 'DATE_SELECTED',
                    id: this.identifier,
                    input: date,
                    path: this.statePath,
                    formValid: this.form.$valid,
                    data: {[this.statePath]: date}
                });
                if (this.onChange) {
                    this.onChange({
                        $event: {input: date}
                    });
                }
            }).bind(this)
        });

        const watchChange = watch(this.$ngRedux.getState, this.getStateContainer());
        this.onInputChange = this.$ngRedux.subscribe(watchChange(newValue => {
            if (isNull(newValue)) {
                this.dateString = null;
                this.iconPath = this.ICON_CALENDAR;
            }
            else {
                this.picker.setDate(newValue, true);
                this.iconPath = this.ICON_REMOVE;
            }
        }));

        if (this.date) {
            this.picker.setDate(this.date, true);
            this.dateString = moment(this.date).format(DATE_FORMAT.INPUT);
            this.iconPath = this.ICON_REMOVE;
        }
    }

    $onChanges(changes) {
        if (has(changes, 'minDate')) {
            if (this.picker)
                this.picker.setMinDate(changes.minDate.currentValue);
        }
        if (has(changes, 'maxDate')) {
            if (this.picker)
                this.picker.setMaxDate(changes.maxDate.currentValue);
        }
    }

    $onDestroy() {
        if (this.picker)
            this.picker.destroy();
        this.unsubscribe();
        this.unsubscribeModule();
        this.onInputChange();
    }

    reset() {
        if (this.dateString && !this.disabled) {
            this.dateString = null;
            this.iconPath = this.ICON_CALENDAR;
            this.$ngRedux.dispatch({
                type: 'DATE_SELECTED',
                id: this.identifier,
                input: null,
                path: this.statePath,
                formValid: this.form.$valid
            });
        }
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        };
    }

    mapState(state) {
        return {
            date: get(state[this.module][this.identifier], this.statePath)
        };
    }

    getStateContainer() {
        const path = this.statePath.includes('.')
            ? this.statePath.split('.')
            : [this.statePath];
        return [this.module, this.identifier, ...path];
    }
}

InputDateController.$inject = ['$ngRedux', '$element'];

export const inputDate = {
    bindings: {
        identifier: '@',
        statePath: '@',
        name: '@',
        label: '@',
        dateFormat: '<',
        minDate: '<',
        maxDate: '<',
        yearRange: '<',
        validate: '<',
        required: '<',
        disabled: '<',
        onChange: '&'
    },
    require : {
        form : '^form'
    },
    controller: InputDateController,
    template: `
        <md-input-container class="md-block md-icon-right" flex>
            <label>{{$ctrl.label}}</label>
            <input id="{{$ctrl.name}}"
                name="{{$ctrl.name}}"
                ng-model="$ctrl.dateString"
                ng-required="$ctrl.required"
                ng-disabled="$ctrl.disabled"
                readonly="readonly">
            <asset layout="row" src="{{$ctrl.iconPath}}" ng-click="$ctrl.reset()"></asset>
            <div ng-if="$ctrl.validate" ng-messages="$ctrl.form[$ctrl.name].$error" ng-show="$ctrl.form.$submitted || $ctrl.form[$ctrl.name].$touched">
                <div ng-message="required">{{'COMMON.FIELD_REQUIRED' | translate}}</div>
            </div>
        </md-input-container>
    `
};
