export function ProposalResponseFactory() {
    return function ProposalResponse() {
        this.id = null;
        this.uuid = null;
        this.account = null;
        this.deadline = null;
        this.vendor = null;
        this.equipment = [];
        this.proposalStatus = null;
        this.proposalPurpose = null;
    };
}
