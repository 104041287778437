/** @namespace Reducers.Accreditation */

import {IDS as PNP_ACCRED_ACTION_IDS} from '../components/policies-and-procedures/pnp-accred/pnp-accred-action-id.constants';
import {PREFIX as PNP_ACCRED_ACTION_PREFIX } from '../components/policies-and-procedures/pnp-accred/pnp-accred-action-type.constants';
import { PnpAccredReducer } from '../components/policies-and-procedures/pnp-accred/pnp-accred.reducer';
import { pnpAccredTabs } from "../components/policies-and-procedures/pnp-accred/pnp-accred-tabs";
import * as TABS from "../common/tabs/tabs.constants";
import {TabsReducer} from "../common/tabs/tabs.reducer";


/**
 * @function policiesAndProceduresReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function policiesAndProceduresReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            pnpAccred: PnpAccredReducer(),
            ...pnpAccredTabs,

            // pnpTemplates: TemplateReducer(),
            // ...templateTabs,
        };
    }
    switch(action.type) {
        case TABS.TAB_INIT:
        case TABS.TAB_OVERRIDE:
        case TABS.TAB_PARAMS:
        case TABS.TAB_ENABLE:
        case TABS.TAB_DISABLE:
        case TABS.TAB_SHOW:
        case TABS.TAB_HIDE:
        case TABS.TAB_LOADING:
        case TABS.TAB_SELECT:
        case TABS.TAB_LABEL:
        case TABS.TAB_BUTTON_LABEL:
            if (action.id.startsWith('app.pnp.')){
                return {
                    ...state,
                    [action.id]: TabsReducer(state[action.id], action)
                }
            }
        case TABS.TAB_SWITCH:
            if (action.id.startsWith('app.pnp.')){
                return {
                    ...state,
                    [action.id]: Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                }
            }
    }

    if(action.type.startsWith(PNP_ACCRED_ACTION_PREFIX)
    || action.id && PNP_ACCRED_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, pnpAccred: PnpAccredReducer(state.pnpAccred, action) }
    }

    return state;
}
