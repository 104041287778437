import Modernizr from 'modernizr';
import { product } from '../../bootstrap.json';

export function detectFeatures() {
    const features = [];

    if (Modernizr.localstorage)
        features.push('localstorage');

    if (Modernizr.csstransitions)
        features.push('csstransitions');

    if (Modernizr.cssgradients)
        features.push('cssgradients');

    if (Modernizr.mediaqueries)
        features.push('mediaqueries');

    if (Modernizr.canvas)
        features.push('canvas');

    if (Modernizr.svg)
        features.push('svg');

    if (Modernizr.svgclippaths)
        features.push('svgclippaths');

    if (Modernizr.video)
        features.push('video');

    return Object.keys(Modernizr).length === features.length;
}

export const fallbackTemplate = `
<div>Your Browser is not compatible with `+product+`</div>
<div>
    <small>We would recommend you to install one of the following browsers:</small>
</div>
<div class="browsers">
    <a href="https://www.google.com/chrome/" target="_blank">
        <figure>
            <img src="img/browser-chrome.jpg" alt="Google Chrome" />
            <figcaption>Google Chrome</figcaption>
        </figure>
    </a>
    <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">
        <figure>
            <img src="img/browser-firefox.jpg" alt="Mozilla Firefox" />
            <figcaption>Mozilla Firefox</figcaption>
        </figure>
    </a>
    <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank">
        <figure>
            <img src="img/browser-edge.jpg" alt="Microsoft Edge" />
            <figcaption>Microsoft Edge</figcaption>
        </figure>
    </a>
    <a href="https://support.apple.com/downloads/safari" target="_blank">
        <figure>
            <img src="img/browser-safari.jpg" alt="Apple Safari" />
            <figcaption>Apple Safari</figcaption>
        </figure>
    </a>
</div>
`;
