/**
 * Creates a new ComponentBaseController.
 * @class
 * @memberOf module:root/components
 */
class ComponentBaseController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     */
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    /**
     * @method $onInit
     * @memberof module:root/components.ComponentBaseController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.initBase();
    }

    /**
     * @method initBase
     * @memberof module:root/components.ComponentBaseController
     */
    initBase() {
        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
    }

    /**
     * @method $onDestroy
     * @memberof module:root/components.ComponentBaseController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.destroyBase();
    }

    /**
     * @method destroyBase
     * @memberof module:root/components.ComponentBaseController
     */
    destroyBase() {
        this.unsubscribeModule();
    }

    /**
     * @method mapModule
     * @param state {object} Redux Store
     * @memberof module:root/components.ComponentBaseController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapModule(state) {
        return {
            /** @member {string} module:root/components.ComponentBaseController#module */
            module: state.navigation.module,
            /** @member {string} module:root/components.ComponentBaseController#moduleColor */
            moduleColor: state.navigation.moduleColor,
            /** @member {string} module:root/components.ComponentBaseController#moduleIcon */
            moduleIcon: state.navigation.moduleIcon,
            /** @member {string} module:root/components.ComponentBaseController#tabsPath */
            tabsPath: state.navigation.tabsPath,
            /** @member {number} module:root/components.ComponentBaseController#tabsIndex */
            tabsIndex: state.navigation.tabsIndex,
            /** @member {string} module:root/components.ComponentBaseController#tabsState */
            tabsState: state.navigation.tabsState,
        }
    }
}

export default ComponentBaseController;
