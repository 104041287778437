export const applicationsPermitsState = {
    name: 'app.applicationsPermits',
    abstract: true,
    url: '/applications'
};

export const applicationsPermitsDashboardState = {
    name: 'app.applicationsPermits.dashboard',
    url: '',
    component: 'dashboard'
};

export const apLaserState = {
    name: 'app.applicationsPermits.laser',
    abstract: true,
    url: '/laser-permits'
};

export const apLaserDashboardState = {
    name: 'app.applicationsPermits.laser.dashboard',
    url: '',
    component: 'dashboard'
};

export const apNuclearEnergyState = {
    name: 'app.applicationsPermits.nuclearEnergy',
    abstract: true,
    url: '/nuclear-energy-permits'
};

export const apNuclearEnergyDashboardState = {
    name: 'app.applicationsPermits.nuclearEnergy.dashboard',
    url: '',
    component: 'dashboard'
};
