export const reportingCnscState = {
    name: 'app.reporting.cnsc',
    redirectTo: 'app.reporting.cnsc.listing',
    url: '/cnsc-report',
    component: 'tabs',
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'GRID_RESET_FILTER',
            id: 'reportingCnscGrid',
        });
        $ngRedux.dispatch({
            type: 'SHOW_LOADING',
            id: 'reportingCnscGrid',
        });
    }
};
reportingCnscState.onEnter.$inject = ['$ngRedux'];

export const reportingCnscListingState = {
    name: 'app.reporting.cnsc.listing',
    url: '',
    component: 'reportingCnsc'
};