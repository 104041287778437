import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import * as EventsAssessmentActions from '../events.assessment.actions'

class EventsAssessmentGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$filter = $filter;
    }

    $onInit() {
        this.module = 'events';
        this.identifier = 'eventsAssessmentGrid';
        this.icon = false;
        this.autoload = false;
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                    mapper: null},
            {title: 'Event ID',             mapper: 'print_event_id'},
            {title: 'Event Date',           mapper: 'event_date'},
            {title: 'Event',                mapper: 'event'},
            {title: 'Event Type',           mapper: 'event_type'},
            {title: 'Event Category',       mapper: 'event_category'},
            {title: 'Severity Potential',   mapper: 'severity_potential'},
            {title: 'Risk Advice Point',    mapper: 'risk_advice_point'}
        ];

        this.buttons = [{label: 'Details', class: 'btnGridModify', callback: this.onSelect}];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this),
            { ...EventsAssessmentActions }
        )(this);

        this.dataAction = this.getAssessmentHistoricalRecords;
        this.filter.identifier = this.identifier;
        this.filter.code = this.filterData.code;
        this.filter.type_id = this.filterData.type_id;
        this.filter.category_id = this.filterData.category_id;
        this.filter.subcategory_id = this.filterData.subcategory_id;
        this.filter.exclude_iev_id = this.filterData.exclude_iev_id;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    };
}

export const eventsAssessmentGrid = {
    bindings: {
        title: '@',
        filterData: '<',
        onSelect: '&',
    },
    controller: EventsAssessmentGridController,
    ...gridTpl
};
