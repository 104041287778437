/* @ngInject */
export function DicomQCPlotDiagramFilterFactory(Location, MammoQCEquipment) {
    function DicomQCPlotDiagramFilter() {

        this.visible = false;
        this.dateFrom = null;
        this.dateTo = null;
        this.dicomQCEquipment = new MammoQCEquipment();

        this.reset = function() {
            this.visible = false;
            this.equipmentId = null;
            this.dateFrom = null;
            this.dateTo = null;
            this.location = new Location();
            this.dicomQCEquipment = new MammoQCEquipment();
        };
    }

    return DicomQCPlotDiagramFilter;
}
