import { GridController, gridTpl } from '../../../../../../common/grid/grid.controller';
import { getVendorAccStandards } from '../../../cre-vendors.actions';

class CredentialingVendorsAccSdtGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, $location, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$location = $location;
    }

    $onInit() {
        this.module = 'credentialing';
        this.identifier = 'creVendorsAccStdGrid';
        this.header = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',						mapper: null},
            {title: 'Accreditation Standard',	mapper: 'acc_scheme_name'},
            {title: 'Next Audit Date',			mapper: 'next_audit_date'},
            {title: 'Certificate',				mapper: 'file-download'},
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), { getVendorAccStandards }
        )(this);

        this.dataAction = this.getVendorAccStandards;
        this.filter.token = this.$location.search().token;
        this.filter.identifier = this.identifier;
        this.filter.application_id = this.$location.search().invite || this.$ngRedux.getState().credentialing.creVendor.application_id;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const creVendorsAccStdGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onRemove: '&'
    },
    controller: CredentialingVendorsAccSdtGridController,
    ...gridTpl
};
