export const systemState = {
    name: 'app.system',
    abstract: true,
    url: '/system'
};

export const systemDashboardState = {
    name: 'app.system.dashboard',
    url: '',
    component: 'dashboard'
};
