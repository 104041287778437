import { TAB_HIDE } from "../../../common/tabs/tabs.constants";
import { IDS } from "./import-settings-action-id.constants"

export const abstractState = {
    name: IDS.app_employees_import_settings,
    abstract: true,
    url: '/import-settings',
};

export const dashboardState = {
    name: IDS.app_employees_import_settings_dashboard,
    url: '',
    component: 'dashboard',
};

export const centersTabsState = {
    name: IDS.app_employees_import_settings_centers,
    redirectTo: IDS.app_employees_import_settings_centers_list,
    url: '/cost-centers',
    component: 'tabs',
};

export const centersListState = {
    name: IDS.app_employees_import_settings_centers_list,
    url: '/list',
    component: IDS.empImportSettingsCentersList,
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: IDS.app_employees_import_settings_centers,
            state: [IDS.app_employees_import_settings_centers_details]
        })
    }
};
centersListState.onEnter.$inject = ['$ngRedux'];

export const centersDetailsState = {
    name: IDS.app_employees_import_settings_centers_details,
    url: '/details?slo',
    component: IDS.empImportSettingsCentersList
};

export const occupationsTabsState = {
    name: IDS.app_employees_import_settings_occupations,
    redirectTo: IDS.app_employees_import_settings_occupations_list,
    url: '/occupations',
    component: 'tabs',
};

export const occupationsListState = {
    name: IDS.app_employees_import_settings_occupations_list,
    url: '/list',
    component: IDS.empImportSettingsOccupationsList,
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: IDS.app_employees_import_settings_occupations,
            state: [IDS.app_employees_import_settings_occupations_details]
        })
    }
};
occupationsListState.onEnter.$inject = ['$ngRedux'];

export const occupationsDetailsState = {
    name: IDS.app_employees_import_settings_occupations_details,
    url: '/details',
    component: IDS.empImportSettingsOccupationsList
};

export const usersTabsState = {
    name: IDS.app_employees_import_settings_users,
    redirectTo: IDS.app_employees_import_settings_users_relocated_list,
    url: '/users',
    component: 'tabs',
};

export const usersRelocatedListState = {
    name: IDS.app_employees_import_settings_users_relocated_list,
    url: '/relocated-list',
    component: IDS.empImportSettingsUsersRelocatedList,
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: IDS.app_employees_import_settings_users,
            state: [IDS.app_employees_import_settings_users_relocated_details]
        })
    }
};
usersRelocatedListState.onEnter.$inject = ['$ngRedux'];

export const usersSkippedListState = {
    name: IDS.app_employees_import_settings_users_skipped_list,
    url: '/skipped-list',
    component: IDS.empImportSettingsUsersSkippedList,
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: IDS.app_employees_import_settings_users,
            state: [IDS.app_employees_import_settings_users_skipped_details]
        })
    }
};
usersSkippedListState.onEnter.$inject = ['$ngRedux'];

export const usersDetailsState = {
    name: IDS.app_employees_import_settings_users_relocated_details,
    url: '/details',
    component: IDS.empImportSettingsUsersRelocatedDetails
};