/* @ngInject */
export function autocompleteEmployeeQualification(Helpers) {
    return {
        restrict: 'E',
        require: '^form',
        scope: {
            equipment: '=',
            data: '=',
            validate: '='
        },
        template: require('./autocomplete-employee-qualification.html'),
        link: function(scope, element, attrs, formCtrl) {

            scope.selectForm = formCtrl;
            scope.selectedQualification = null;
            scope.identifier = Helpers.generateIdentifier();

            if (_.isUndefined(scope.validate)) scope.validate = false;

            scope.itemSelected = function() {
                _.assign(scope.equipment, scope.selectedQualification);
            };

            scope.modelSearch = function(query) {
                return query ? scope.data.filter(createFilterFor(query)) : scope.data;
            };

            function createFilterFor(query) {
                var lowercaseQuery = query.toLowerCase();
                return function filterFn(item) {
                    if (_.isNull(item.name))
                        return false;
                    else
                        return ((item.name.toLowerCase().indexOf(lowercaseQuery) !== -1));
                };
            }

            scope.$on('CREATE_QUALIFICATION_COMPLETE', function(event) {
                scope.selectedQualification = scope.equipment;
            });

            scope.$on('RESET_AUTOCOMPLETE', function(event) {
                scope.selectedQualification = null;
                scope.searchText = '';
                formCtrl[scope.identifier].$setPristine();
                formCtrl[scope.identifier].$setUntouched();
            });

            scope.$on('show-errors-check-validity', function() {
                if (scope.validate && _.isNull(scope.selectedQualification)) {
                    formCtrl[scope.identifier].$setDirty();
                }
            });
        }
    };
}
