import * as AccreditationActions from '../accreditation.actions';
import {GRID_FILTER} from '../../../common/grid/grid.constants';
import moment from 'moment';
import { IDS as ACC_IDS } from '../accreditation-action-id.constants'
import { IDS as ASSESSMENT_IDS } from '../standard/standard-action-id.constants';
import { TYPES as ACC_TYPES } from "../accreditation-action-type.constants";
import {APPROVAL_USERS} from "../accreditation.constants";

/**
 * Creates a new TasksController.
 * @class
 */
class TasksController {
    constructor($ngRedux,
                $state,
                $stateParams,
                $scope,
                $rootScope,
                $filter,
                $timeout,
                $mdDialog,
                $location,
                Notifier
                ) {

        this.$ngRedux   = $ngRedux;
        this.$state     = $state;
        this.$stateParams = $stateParams;
        this.$scope     = $scope;
        this.$rootScope = $rootScope;
        this.$filter    = $filter;
        this.$timeout   = $timeout;
        this.$mdDialog  = $mdDialog;
        this.$location  = $location;
        this.Notifier   = Notifier;
    }

    $onInit() {
        this.isPublic = this.$state.$current.name.split('.')[0] === 'public';
        this.identifier = ACC_IDS.accTasksGrid;
        this.unsubscribe = this.$ngRedux.connect(state => {
            const standards = state.accreditation.accScheme.standards;
            const tasksGrid = state.accreditation.accTasksGrid;
            const user = state.app.user;

            return {
                siteLocationUuid:   standards.selectedSiteLocation  && standards.selectedSiteLocation.uuid,
                selectedYear:       standards.selectedYear,
                userType:           user.typeCode,
                userUuid:           user.uuid,
                isApprovalUser:     APPROVAL_USERS.includes(user.typeCode),
                taskStatuses:       tasksGrid.taskStatuses,
                taskTypes:          tasksGrid.taskTypes,
            }
        }, AccreditationActions)(this);

        this.localizeTasksGridFilter(this.taskStatuses, this.taskTypes);

        this.$ngRedux.dispatch({
            type: GRID_FILTER,
            id:   ACC_IDS.accTasksGrid,
            filter: {
                user_uuid: this.userUuid,
                emp_uuid: this.isApprovalUser ? undefined : this.userUuid,
                year_start: moment().year() - 1,
                year_end: moment().year() + 2,
            }
        });
    }

    onSelect({item}){
        switch(item.uta_type) {
            case 'utaevd':
                this.$ngRedux.dispatch({ // reset state.accreditation.accDocumentAdd.assessmentItem
                    type: ACC_TYPES.SELECT_ASSESSMENT_ITEM,
                    id: ACC_IDS.accMasterDocListAdd,
                    data: { assessmentItem: null },
                });
                this.$state.go( ACC_IDS.app_accreditation_scheme_add_document, {
                    itemCode:   item.acc_code.toLowerCase(),
                    standard:   item.ast_id,
                    year:       item.fiscal_year,
                    slo:        item.location_uuid,
                    assessmentItem: item.aas_id,
                })
            break;

            case 'utaaud':
                let stateName = ASSESSMENT_IDS.app_accreditation_scheme_standards_dashboard;
                if (item.ast_level === 3) {
                    stateName = ASSESSMENT_IDS.app_accreditation_scheme_standards_documents;
                }
                this.$state.go(stateName, {
                    itemCode:   item.acc_code.toLowerCase(),
                    standard:   item.ast_id,
                    year:       item.fiscal_year,
                    slo:        item.location_uuid,
                    task:       item,
                });
            break;
        }
    }

    onLinkDialog($event){
        const ctrl = this;
        this.dialog = {
            controller: ($scope, token) => {
                const loc = this.$location;
                $scope.url = [
                    loc.protocol(), '://',
                    loc.host(),
                    loc.port() !== '80' ? `:${loc.port()}` : '',
                    '/public/accreditation/task?token=', token
                ].join('');
                $scope.close = () => {
                    this.$mdDialog.cancel();
                    ctrl.dialog = undefined;
                }
            },
            template: require('./tasks-link-dialog.html'),
            parent: angular.element(document.body),
            locals: {
                token: $event.item.token
            },
        };
        this.dialog.controller.$inject = ['$scope', 'token'];

        this.$mdDialog.show(this.dialog);
    }

    $onDestroy() {
        this.unsubscribe();
        if (this.dialog)
            this.$mdDialog.hide(this.dialog);
    }
}

TasksController.$inject = [
    '$ngRedux',
    '$state',
    '$stateParams',
    '$scope',
    '$rootScope',
    '$filter',
    '$timeout',
    '$mdDialog',
    '$location',
    'Notifier',
]

export const accTasks = {
    bindings: {
    },
    controller: TasksController,
    template: `
        <acc-tasks-grid
            identifier="$ctrl.identifier"
            on-select="$ctrl.onSelect($event)"
            on-link-dialog="$ctrl.onLinkDialog($event)">
        </acc-tasks-grid>
`,
}
