/* @ngInject */
export function SafetyPlanReviewCandidateFactory(User) {
    return function SafetyPlanReviewCandidate() {

        this.id = null;
        this.userUuid = null;
        this.user = new User();
        this.startDate = null;
        this.endDate = null;
        this.dueDate = null;
        this.finished = null;
        this.accountId = null;
        this.reviewId = null;
        this.locationUuid = null;
        this.locationName = null;
        this.checked = null;
    };
}
