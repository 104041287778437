import API from '../../../common/http/api.fetch';
import { product } from '../../../bootstrap.json';
import { formatDate } from '../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../common/utils/util.constants';
import { getSignature } from '../../../common/utils/util.signature';
import { saveAs } from 'file-saver';

export const getDosimetryBadges = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: `/dosimetry-badges`,
            method: 'GET',
            params: {
                uuid: filter.uuid,
                offset: filter.offset,
                limit: filter.limit
            }
        }).then(({ data }) => {
            dispatch({
                type: 'GRID_FETCH',
                id: 'employeeDosimetryBadgesGrid',
                items: data.items.map((item) => ({
                    ...item,
                })),
                itemsCount: parseInt(data.items_count)
            })
        });
    }
}

export const addDosimetryBadge = (data, userUuid) => {
    return (dispatch) => {
        return API.fetch({
            url: `/dosimetry-badge/add`,
            method: 'POST',
            data: {
                userUuid: userUuid,
                badgeType: data.badgeType,
                participantNumber: data.participantNumber,
                //distributedDate: formatDate(data.distributedDate, DATE_FORMAT.OUTPUT),
                //returnDate: formatDate(data.returnDate, DATE_FORMAT.OUTPUT),
                maximumActivity: data.maximumActivity,
                inceptionDate: formatDate(data.inceptionDate, DATE_FORMAT.OUTPUT),
                wearPeriod: data.wearPeriod
            }
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'employeeDosimetryBadgesGrid'
            }),
            dispatch({
                type: 'RESET_BADGE_FORM',
                id: 'employeeDosimetryBadge'
            })
        });
    }
}

export const removeDosimetryBadge = (badgeId) => {
    return (dispatch) => {
        return API.fetch({
            url: `/dosimetry-badge/remove`,
            method: 'POST',
            params: {
                badgeId: badgeId
            }
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'employeeDosimetryBadgesGrid'
            })
        });
    }
};

export const getDosimetryReports = ({ uuid, offset, limit }) => {
    return (dispatch) => {
        return API.fetch({
            url: `/dosimetry-reports`,
            method: 'GET',
            params: {
                employee_uuid: uuid,
                offset,
                limit,
            }
        }).then(({ data }) => {
            dispatch({
                type: 'GRID_FETCH',
                id: 'employeeDosimetryReportsGrid',
                items: data.items,
                itemsCount: parseInt(data.items_count)
            });
        });
    }
}

export const genDosimetryReport = (id, num, signature) => {
    return () => {
        return API.fetch({
            url: `/personal-dosimetry/generate/participant-report`,
            method: 'POST',
            responseType: 'arraybuffer',
            data: { id, signature: signature || getSignature() }
        }).then(({ data }) => {
            saveAs(
                new Blob([data], { type: 'application/pdf' }),
                `${product}-PersonalDosimetry-ParticipantReport-${num}.pdf`,
            );
        });
    }
};
