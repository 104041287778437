import {TabsReducer} from '../../../common/tabs/tabs.reducer';
import {IDS} from './master-doc-list-action-id.constants';

const tabs = [
    {
        state: IDS.app_accreditation_scheme_master_doc_list_list,
        title: 'ACCREDITATION.MASTER_DOC_LIST.LIST.TAB',
        visible: true,
        disabled: false,
        loading: false,
    },
    {
        state: IDS.app_accreditation_scheme_master_doc_list_details,
        title: 'ACCREDITATION.MASTER_DOC_LIST.DETAILS.TAB',
        visible: false,
        disabled: false,
        loading: false
    },
    // {
    //     state: IDS.app_accreditation_scheme_master_doc_list_add,
    //     title: 'ACCREDITATION.ADD_DOCUMENT.TAB',
    //     visible: false,
    //     disabled: false,
    //     loading: false,
    // },

];

const publicTabs = [
]

export const masterDocListTabs = {
    [IDS.app_accreditation_scheme_master_doc_list]: Object.assign(TabsReducer(), { tabs }),
    [IDS.public_accreditation_scheme_master_doc_list]: Object.assign(TabsReducer(), { tabs: publicTabs })
}
