import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsSiteLocationsController($ngRedux, $state, $uiRouterGlobals, $timeout, $mdDialog, AdministrationActions, AuthService) {
    var ctrl = this;
    var state = $state.current.name;
    var stateParams = _.clone($uiRouterGlobals.params);
    var dialog = null;
    var unsubscribe = angular.noop;
    var onSiteLocation = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);

        var watchSiteLocation = watch($ngRedux.getState, 'administration.administrationAccountsSiteLocation.data.uuid');
        onSiteLocation = $ngRedux.subscribe(watchSiteLocation(function(uuid) {
            if (uuid) onSiteLocationReady(uuid);
        }));

        $ngRedux.dispatch({
            type: 'LOCATION_SELECTED',
            id: 'administrationAccountsSiteLocationsGrid',
            hof: $uiRouterGlobals.params.hof
        });

        if (state === 'app.administration.accounts.siteLocations.details' && ctrl.siteLocation.uuid)
            onSiteLocationReady(ctrl.siteLocation.uuid);
    };

    ctrl.admin = AuthService.getUser().typeCode === 'adm';

    ctrl.selectSiteLocation = function(event) {
        state = 'app.administration.accounts.siteLocations.details';
        if (event.item.uuid === ctrl.siteLocation.uuid)
            onSiteLocationReady(event.item.uuid);
        else
            ctrl.getSiteLocationDetails(event.item.uuid, true);
    };

    ctrl.newSiteLocation = function() {
        onSiteLocationReady();
    };

    ctrl.inactivateDialog = function(event) {
        var confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('This action will inactivate location.')
            .ariaLabel('Inactivate Site Location')
            .ok('Inactivate')
            .cancel('Cancel');

        dialog = $mdDialog.show(confirm).then(function() {
            ctrl.inactivateLocation(event);
        }, angular.noop);
    };

    ctrl.$onDestroy = function() {
        if (dialog) $mdDialog.hide(dialog);
        unsubscribe();
        onSiteLocation();
    };

    function onSiteLocationReady(uuid) {
        if (uuid) {
            $ngRedux.dispatch({
                type: 'TAB_ENABLE',
                id: 'app.administration.accounts.siteLocations',
                state: [
                    'app.administration.accounts.siteLocations.details',
                    'app.administration.accounts.siteLocations.address',
                    'app.administration.accounts.siteLocations.invoiceAddress',
                    'app.administration.accounts.siteLocations.contacts',
                    'app.administration.accounts.siteLocations.licences',
                    'app.administration.accounts.siteLocations.equipment',
                    'app.administration.accounts.siteLocations.employees',
                    'app.administration.accounts.siteLocations.modules',
                ]
            });
            if (state === 'app.administration.accounts.siteLocations.contactDetails') {
                $ngRedux.dispatch({
                    type: 'TAB_SHOW',
                    id: 'app.administration.accounts.siteLocations',
                    state: ['app.administration.accounts.siteLocations.contactDetails'],
                    stateParams: {
                        hof: $uiRouterGlobals.params.hof,
                        slo: uuid,
                        rsc: stateParams.rsc
                    }
                });
                if (stateParams.rsc) {
                    $ngRedux.dispatch({
                        type: 'TAB_LOADING',
                        id: 'app.administration.accounts.siteLocations',
                        state: 'app.administration.accounts.siteLocations.contactDetails',
                        loading: true
                    });
                    ctrl.getContactDetails('administrationAccountsSiteLocationContacts', stateParams.contact);
                }
                return;
            }
            if (state === 'app.administration.accounts.siteLocations.licenceDetails') {
                $ngRedux.dispatch({
                    type: 'TAB_SHOW',
                    id: 'app.administration.accounts.siteLocations',
                    state: ['app.administration.accounts.siteLocations.licenceDetails'],
                    stateParams: {
                        hof: $uiRouterGlobals.params.hof,
                        slo: uuid,
                        lic: stateParams.lic
                    }
                });
                if (stateParams.lic) {
                    $ngRedux.dispatch({
                        type: 'TAB_LOADING',
                        id: 'app.administration.accounts.siteLocations',
                        state: 'app.administration.accounts.siteLocations.licenceDetails',
                        loading: true
                    });
                    ctrl.getSiteLocationLicence(stateParams.lic);
                }
                return;
            }
        }
        else {
            state = 'app.administration.accounts.siteLocations.details';
            $ngRedux.dispatch({
                type: 'TAB_ENABLE',
                id: 'app.administration.accounts.siteLocations',
                state: ['app.administration.accounts.siteLocations.details']
            });
        }

        $timeout(function() {
            $ngRedux.dispatch({
                type: 'TAB_SELECT',
                id: 'app.administration.accounts.siteLocations',
                state: state,
                stateParams: {
                    hof: $uiRouterGlobals.params.hof,
                    slo: uuid
                }
            });
        }, 200);
    }

    function mapStateToThis(state) {
        return {
            siteLocation: state.administration.administrationAccountsSiteLocation.data,
            title: [state.administration.administrationAccountsHeadOffice.name, 'Site Locations'].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
