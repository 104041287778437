/* @ngInject */
export function SelectManufacturerController($ngRedux, ManufacturersActions, EQUIPMENT_TYPE_CODE) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, ManufacturersActions)(ctrl);
        ctrl.options = [];

        // TODO consider autoload condition
        ctrl.getManufacturersList(EQUIPMENT_TYPE_CODE[ctrl.identifier]);
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            $ngRedux.dispatch({
                type: 'MANUFACTURER_SELECTED',
                id: ctrl.identifier,
                manufacturer: ctrl.selectedOption
            });
        }
    };

    function mapStateToThis(state) {
        return {
            options: state.manufacturers.manufacturersList,
            selectedOption: _.get(state, getStateContainer(), null)
        };
    }

    function getStateContainer() {
        return [ctrl.module, ctrl.identifier].join('.') + '.' + ctrl.statePath;
    }
}
