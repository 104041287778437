/** @module root/components/accreditation/settings */

import * as STATES                          from './settings.states';
import { IDS }                              from './settings-action-id.constants';
import { schemeDetails }                    from './scheme-details/scheme-details.component'

import { headOffices }                      from './enabled-accounts/head-offices/head-offices.component'
import { headOfficesGrid }                  from './enabled-accounts/head-offices/head-offices-grid.component'

import { siteLocations }                    from './enabled-accounts/site-locations/site-locations.component'
import { siteLocationsGrid }                from './enabled-accounts/site-locations/site-locations-grid.component'

import { sections }                         from './sections/sections.component'
import { sectionDetails }                   from './sections/details/section-details.component'

import { accDefaultDocuments }              from './default-documents/default-documents.component'
import { defaultDocumentsGrid }             from './default-documents/grid/default-documents-grid.component'
import { defaultDocumentDetails }           from './default-documents/details/default-document-details.component'
import { defaultDocumentAdd }               from './default-documents/add/default-document-add.component'

import { hofRequiredDocs }                  from './hof-required-docs/hof-required-docs.component'
import { hofRequiredDocsGrid }              from './hof-required-docs/hof-required-docs-grid.component'
import { hofRequiredDocDetails }            from './hof-required-docs/details/hof-required-doc-details.component'
import { hofRequiredDocActiveHofsGrid }     from './hof-required-docs/details/hof-required-doc-active-hofs-grid.component'
import { hofRequiredDocAdd }                from './hof-required-docs/add/hof-required-doc-add.component'

import { sloRequiredDocs }                  from './slo-required-docs/slo-required-docs.component'
import { sloRequiredDocsGrid }              from './slo-required-docs/slo-required-docs-grid.component'
import { sloRequiredDocDetails }            from './slo-required-docs/details/slo-required-doc-details.component'
import { sloRequiredDocAdd }                from './slo-required-docs/add/slo-required-doc-add.component'

export const SETTINGS_MODULE = angular.module(
    'migration.accreditation.settings',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values(STATES).map(
        state => stateRegistry.register(state)
    );
}]).component({
    schemeDetails,

    headOffices,
    headOfficesGrid,

    siteLocations,
    siteLocationsGrid,

    sections,
    sectionDetails,

    accDefaultDocuments,
    defaultDocumentsGrid,
    defaultDocumentDetails,
    defaultDocumentAdd,

    hofRequiredDocs,
    hofRequiredDocsGrid,
    hofRequiredDocDetails,
    hofRequiredDocActiveHofsGrid,
    hofRequiredDocAdd,

    sloRequiredDocs,
    sloRequiredDocsGrid,
    sloRequiredDocDetails,
    sloRequiredDocAdd,

}).run(['$templateCache', ({ put }) => {
    put(IDS.app_accreditation_scheme_settings_scheme_details,                   '<scheme-details></scheme-details>');
    put(IDS.app_accreditation_scheme_settings_enabled_head_offices,             '<head-offices></head-offices>');
    put(IDS.app_accreditation_scheme_settings_enabled_site_locations,           '<site-locations></site-locations>');
    put(IDS.app_accreditation_scheme_settings_sections,                         '<sections></sections>');

    put(IDS.app_accreditation_scheme_settings_default_documents_list,           '<acc-default-documents></acc-default-documents>');
    put(IDS.app_accreditation_scheme_settings_default_documents_details,        '<default-document-details></default-document-details>');
    put(IDS.app_accreditation_scheme_settings_default_documents_add,            '<default-document-add></default-document-add>');

    put(IDS.app_accreditation_scheme_settings_hof_required_docs_list,       '<hof-required-docs></hof-required-docs>');
    put(IDS.app_accreditation_scheme_settings_hof_required_docs_details,    '<hof-required-doc-details></hof-required-doc-details>');
    put(IDS.app_accreditation_scheme_settings_hof_required_docs_add,        '<hof-required-doc-add></hof-required-doc-add>');

    put(IDS.app_accreditation_scheme_settings_slo_required_docs_list,       '<slo-required-docs></slo-required-docs>');
    put(IDS.app_accreditation_scheme_settings_slo_required_docs_details,    '<slo-required-doc-details></slo-required-doc-details>');
    put(IDS.app_accreditation_scheme_settings_slo_required_docs_add,        '<slo-required-doc-add></slo-required-doc-add>');

    put('./default-documents-grid-filter.html', require('./default-documents/grid/default-documents-grid-filter.html'));
    put('./default-documents-grid-filter-controls.html', require('./default-documents/grid/default-documents-grid-filter-controls.html'));
}]);
