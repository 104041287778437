import { generateQRCode } from '../../../../../../common/utils/util.qrcode';

/* @ngInject */
export function AdministrationAccountsDepartmentDetailsController($ngRedux, $state, $uiRouterGlobals, $transitions, $timeout, $mdDialog, AuthService, NavigationService, AdministrationActions, PersonalDosimetryActions, AccountService, Notifier) {
    const ctrl = this;
    let unsubscribe = angular.noop;
    let onEnter = angular.noop;
    let onBeforeExit = angular.noop;

    ctrl.$onInit = function() {
        ctrl.module = 'administration';
        ctrl.identifier = 'administrationAccountsDepartment';

        unsubscribe = $ngRedux.connect(mapStateToThis, {
            ...AdministrationActions,
            ...PersonalDosimetryActions,
        })(ctrl);

        if (_.has($uiRouterGlobals.params, 'dep')) {
            const dep = _.isUndefined($uiRouterGlobals.params.dep) ? ctrl.stateParams.dep : $uiRouterGlobals.params.dep;
            if (dep) {
                ctrl.getDepartmentDetails(dep, true);
            } else {
                if ($state.current.name === 'app.administration.accounts.departments.details') {
                    ctrl.getDepartmentTypes('administrationAccountsDepartment', $uiRouterGlobals.params.slo);

                    $ngRedux.dispatch({
                        type: 'TAB_ENABLE',
                        id: 'app.administration.accounts.departments',
                        state: ['app.administration.accounts.departments.details']
                    });

                    $timeout(function() {
                        $ngRedux.dispatch({
                            type: 'TAB_SELECT',
                            id: 'app.administration.accounts.departments',
                            state: 'app.administration.accounts.departments.details'
                        });
                    }, 200);
                }
            }
        }

        ctrl.getReportProviders('administrationAccountsDepartment');

        onBeforeExit = $transitions.onBefore({exiting: 'app.administration.accounts.departments.details'}, function() {
            ctrl.departmentForm.$setPristine();
            ctrl.departmentForm.$setUntouched();
        });
    };

    ctrl.initQRCode = () => {
        if (ctrl.department.uuid) {
            generateQRCode('DEP-QRCode', {
                uuid: ctrl.department.uuid,
                name: ctrl.department.name,
                action: 'location',
            });
        }
    }

    ctrl.printQRCode = () => {
        AccountService.printQRcode(
            ctrl.department.uuid,
            ctrl.department.name,
            'location',
        );
    };

    ctrl.manageDepartment = function() {
        if (ctrl.departmentForm.$valid) {
            if (ctrl.department.uuid) {
                ctrl.updateDepartmentDetails(ctrl.department);
            }
            else {
                ctrl.department.parentUuid = $uiRouterGlobals.params.slo;
                ctrl.createDepartmentDetails(ctrl.department).then(function() {
                    $state.go($state.current.name, _.assign({}, $uiRouterGlobals.params, {dep: ctrl.department.uuid}), {notify:false, reload:false});
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            }
        }
        else Notifier.error('Please fill in the form fields');
    };

    ctrl.createType = function() {
        const createTypeCtrl = ($scope) => {
            const navConfig = NavigationService.getConfig($state.$current);
            $scope.headerColor = navConfig.colorName;
            $scope.save = () => {
                if ($scope.modelForm.$valid) {
                    ctrl.addNewDepartmentType($scope.typeName).catch((err) => {
                        Notifier.error('Existing Type, please select type from dropdown');
                    });
                    ctrl.getDepartmentTypes('administrationAccountsDepartment', $uiRouterGlobals.params.slo);
                    $mdDialog.hide();
                }
            };
            $scope.cancel = () => {
                $mdDialog.cancel();
            };
        };
        createTypeCtrl.$inject = ['$scope'];

        $mdDialog.show({
            controller: createTypeCtrl,
            template: require('./dialogs/department-type-dialog.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: true
        }).then(() => {}, angular.noop);
    }

    ctrl.$onDestroy = function() {
        unsubscribe();
        onEnter();
        onBeforeExit();
    };

    function mapStateToThis(state) {
        return {
            department: state.administration.administrationAccountsDepartment.data,
            userType: AuthService.getUser().typeCode,
            stateParams: state.administration['app.administration.accounts.departments'].stateParams,
            lock: _.isNull(_.find(state.administration['app.administration.accounts.departments'].tabs, ['state', 'app.administration.accounts.departments.details']).buttons.execute),
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                state.administration.administrationAccountsDepartment.title
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
