import { getRequestStatuses, inactivatePermit } from '../applications-permits.actions';
import { getRequestDetails, cancelRequest } from './ap-laser-request.actions';
import { TAB_SHOW } from '../../../common/tabs/tabs.constants';

/**
 * @const {object}
 * @memberOf module:ApplicationsPermits
 */
export const apLaserRequest = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $mdDialog, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.stateId = $state.$current.parent.name;
            this.unsubscribe = $ngRedux.connect(() => ({}), {
                getRequestStatuses,
                getRequestDetails,
                cancelRequest,
                inactivatePermit
            })(this);

            this.getRequestStatuses();
        }

        this.onSelect = ({ item }) => {
            this.getRequestDetails(item.request_id).then(() => {
                $ngRedux.dispatch({
                    type: TAB_SHOW,
                    id: this.stateId,
                    state: [
                        `${this.stateId}.details`,
                        `${this.stateId}.equipment`,
                        `${this.stateId}.trainings`,
                        `${this.stateId}.safetyPlan`,
                    ],
                    stateParams: { id: item.request_id }
                });
            });
        };

        this.onCancel = ({ item }) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen request will be canceled permanently.')
                .ariaLabel('Cancel request')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.cancelRequest(item.request_id, item.email);
            }, angular.noop);
        };

        this.onPermitRemove = ({ item }) => {
            const confirm = $mdDialog.confirm()
                .title('Inactivate Application Permit')
                .textContent('Chosen application permit will be inactivated.')
                .ariaLabel('Inactivate Permit')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.inactivatePermit(item.request_id);
                Notifier.success('Permit Inactivated');
            }, angular.noop);
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: `
        <ap-laser-request-grid
            type="{{$ctrl.type}}"
            on-select="$ctrl.onSelect($event)" 
            on-cancel="$ctrl.onCancel($event)"
            on-remove="$ctrl.onPermitRemove($event)">
        </ap-laser-request-grid>
    `
};
