import * as AuthActions from '../auth.actions';
import { resolveOtpParams } from '../auth.utils';

export const authOTP = {
    /* @ngInject */
    controller: function($ngRedux, $state, $uiRouterGlobals, $filter, AppService, AuthService, Notifier) {
        this.headlineClass = 'md-headline';
        this.headline = $uiRouterGlobals.params.headline;
        this.headlineDetail = $uiRouterGlobals.params.message;
        this.status = $uiRouterGlobals.params.status;
        this.pattern = /^\d{6}$/;

        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(() => ({}), { ...AuthActions })(this);
            if ($uiRouterGlobals.params.class === 'error') {
                this.headlineClass = 'md-headline-error';
            }
        };

        /**
         * @function submit
         */
        this.submit = () => {
            if (this.loginOtpForm.$valid) {
                $ngRedux.dispatch({ type: 'LOADING_START' });
                this.otpVerify(this.otp, $uiRouterGlobals.params.token).then((response) => {
                    $ngRedux.dispatch({ type: 'LOADING_END' });
                    AuthService.createSession(response);
                    AppService.moduleOverride();
                    AuthService.recoverSession();
                }).catch((err) => {
                    $ngRedux.dispatch({ type: 'LOADING_END' });
                    const params = resolveOtpParams(err.data.status);
                    this.headlineClass = 'md-headline-error';
                    this.headline = params.headline;
                    this.headlineDetail = params.message;
                    resetForm();
                });
            }
        };

        /**
         * @function resend
         */
        this.resend = () => {
            resetForm()
            this.otpGenerate($uiRouterGlobals.params.token).then(({ data }) => {
                $state.go('.', { token: data.token }, { reload: true });
                Notifier.success('Please check you email!');
            }).catch(({ status, data }) => {
                if (status === 429) {
                    /** Too Many Requests */
                    this.headlineClass = 'md-headline-error';
                    this.headline = `COMMON.LOGIN.YOU_HAVE_HIT_MAX_NUM_OF_LOGIN_ATTEMPTS_ALLOWED`;
                    this.headlineDetail = $filter('translate')('COMMON.LOGIN.TRY_AGAIN_IN', { time: data });
                } else {
                    Notifier.error('System Error!');
                    $state.go('login.auth');
                }
            });
        };

        const resetForm = () => {
            this.otp = null;
            this.loginOtpForm.$setPristine();
            this.loginOtpForm.$setUntouched();
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            this.unsubscribe();
        };
    },
    template: require('./auth-otp.html'),
};
