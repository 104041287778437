import { permitApprove, permitReject } from '../ap-laser-request.actions';
import { formatDate } from '../../../../common/utils/util.date';
import i18n from '../../i18n/en.json';

/**
 * @const {object}
 * @memberOf module:ApplicationsPermits
 */
export const apLaserRequestEquipmentAuth = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $mdDialog, Notifier, FileActions) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(({ applicationsPermits }) => ({
                apLaserRequest: applicationsPermits.apLaserRequest,
            }), {
                ...FileActions,
                permitApprove,
                permitReject
            })(this);

            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.equipment`;

            const { title, first_name, last_name } = this.apLaserRequest;
            this.title = `${title} ${first_name} ${last_name} - ${i18n[this.type].equipment.title}`;
            this.icon = i18n[this.type].equipment.icon;
        }

        this.onApprove = () => {
            if (this.apLaserRequest.approval_officer_signature) {
                const confirm = $mdDialog.confirm()
                    .title('Authorization Approval')
                    .textContent('Chosen application will be authorized for use.')
                    .ariaLabel('Cancel Approval')
                    .ok('Yes')
                    .cancel('No');
                this.dialog = $mdDialog.show(confirm).then(() => {
                    this.permitApprove(this.apLaserRequest);
                    Notifier.success('Authorization Approved');
                }, angular.noop);
            } else {
                Notifier.error('First add Signature');
            }
        }

        this.onReject = () => {
            if (this.apLaserRequest.approval_officer_signature) {
                const ctrl = this;
                this.dialog = $mdDialog.show({
                    /* @ngInject */
                    controller: function($scope) {
                        $scope.submodule = i18n[ctrl.type].submodule;
                        $scope.save = () => {
                            if ($scope.dialogForm.$valid && ($scope.addNewQualificationDocument || $scope.addNewTrainingDocument || $scope.watchLaserSafetyTraining || $scope.rejectReason)) {
                                $mdDialog.hide();
                                ctrl.permitReject(ctrl.apLaserRequest, $scope);
                                Notifier.success('Authorization Rejected');
                            } else {
                                Notifier.error('Input Reject reason or select one option form checkboxes');
                            }
                        };
                        $scope.cancel = () => $mdDialog.cancel();
                        $scope.onOtherReason = () => {
                            if ($scope.otherReason) {
                                $scope.addNewQualificationDocument = false;
                                $scope.addNewTrainingDocument = false;
                                $scope.watchLaserSafetyTraining = false;
                            } else {
                                $scope.rejectReason = null;
                            }
                        }
                    },
                    template: require('./dialogs/reject-application-dialog.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: true
                });
            } else {
                Notifier.error('First add Signature');
            }
        }

        this.onDownloadCertificate = () => {
            this.download(`storage/`+formatDate(this.apLaserRequest.approval_date, 'YYYY-MM')+`/certificate-permit-no-`+this.apLaserRequest.id+`.pdf`);
        }

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./ap-laser-request-equipment-auth.html')
};
