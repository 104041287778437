import { api } from '../../../bootstrap.json';
import { version } from '../../../../package.json';

angular
    .module('components.constants', [])
    .constant('_', window._)
    .constant('VERSION', version)
    .constant('API_BASE', api)
    .constant('EVENT', {
        UNAUTHORIZED: 'unauthorized'
    })
    .constant('NOTIFICATION', {
        SUCCESS:    'success',
        INFO:       'info',
        WARN:       'warn',
        ERROR:      'error'
    })
    .constant('SUBJECT_TYPE', {
        HEAD_OFFICE:        'hof',
        SITE_LOCATION:      'slo',
        DEPARTMENT:         'dep',
        ROOM:               'rom',
        EMPLOYEE:           'emp',
        VENDOR:             'ven',
        SELLER:             'sel',
        SERVICE_PROVIDER:   'spr',
        VENDOR_AGENT:       'vag',
        USER:               'usr',
        HOF_RSC:            'hrs',
        SITE_RSC:           'sro',
        SITE_ARSC:          'aso',
        DEPT_RSC:           'drs'

    })
    .constant('EQUIPMENT_TYPE', {
        XRay:           1,
        tubeHousing:    2,
        tubeInsert:     3,
        laser:          8,
        ultrasound:     16,
        transducer:     17,
        mammo:          18
    })
    .constant('EQUIPMENT_TYPE_CODE', {
        XRay:                       'xra',
        laser:                      'las',
        ultrasound:                 'uso',
        calibration:                'cal',
        mammo:                      'mam',
        equipmentXrayGrid:          'xra',
        equipmentLasersGrid:        'las',
        equipmentUltrasoundGrid:    'uso',
        equipmentMammoGrid:         'mam',
        equipmentCalibrationGrid:   'cal'
    })
    .constant('DATE_TYPE', {
        STRING:     'string',
        DATE:       'jsdate',
        MOMENT:     'jsmoment'
    })
    .constant('DATE_FORMAT', {
        INPUT:      'DD-MM-YYYY',
        OUTPUT:     'YYYY-MM-DD'
    })
    .constant('GENDERS', [
        {id: 1, title: 'Male'},
        {id: 2, title: 'Female'},
        {id: 3, title: 'Other'}
    ])
    .constant('EXPORT_FORMAT', [
        {format: 'pdf',     name: 'PDF'},
        {format: 'csv',     name: 'CSV'},
        {format: 'xls',     name: 'Excel 5'},
        {format: 'xlsx',    name: 'Excel 2007'}
    ])
    .constant('COUNTRY_SELECT_EVENT', {
        RESET:      'country-cascade-reset',
        SELECTED:   'country-state-selected'
    })
    .constant('SAFETY_PLAN_REVIEW_NET_SPEED', {
        BANDWIDTH_LIMIT: 2000, //2000 Kbps for smooth playback
    })
    .constant('EXPORT_FORMATS', [
        {value: 'EXPORT_FORMAT_PDF',  name: 'Pdf'},
        {value: 'EXPORT_FORMAT_CSV',  name: 'CSV'},
        {value: 'EXPORT_FORMAT_XLS',  name: 'Excel 5'},
        {value: 'EXPORT_FORMAT_XLSX', name: 'Excel 2007'}
    ])
    .constant('SUBJECT_FILTER_STATUS', [
        {id: 'act', name: 'Active'},
        {id: 'pen', name: 'Pending'},
        {id: 'ina', name: 'Inactive'},
        {id: 'dup', name: 'Duplicated'},
        {id: 'iex', name: 'Import - Excluded'},
        {id: 'all', name: 'All'}
    ])
    .constant('ACC_STANDARD_TYPE', {
        NONE:               "",
        STANDARD:           "std",
        SUB_STANDARD:       "sbstd",
        SUB_SUB_STANDARD:   "ssbstd"
    })
    .constant('ACC_ACCREDITATION_CODE', {
        NONE: "",
        ANY: "accany",
        DIAS: "accdia",
        NSQHS: "accnhs",
        NSQHS2: "accnq2",
        ACQS: "accacq"
    })
    .constant('ACC_ASSESSMENT_ITEM_TYPE', {
        NONE: "",
        BOOL: "bool",
        TEXT: "text",
        SELECT_FROM_MULTIPLE: "selm", //select from multiple given options (should be implemented with radio buttons)
        BOOLEAN_AND_COMMENT: "bocm", //if checked, additional explanation should be given
        DOCUMENT: "docu", //document that should be uploaded,
        DOCUMENT_GENERATED: "docg" //document generated from the MyRso system
    })
    .constant('ACC_AUDIT_SCHEDULE_ITEM_STATUS', {
        NONE: null,
        OVERDUE: "Overdue",
        DONE: "Done",
        SCHEDULED: "Scheduled",
        NOT_APPLICABLE: "NA",
    })
    .constant('ACC_AUDIT_CHECKLIST_ITEM_STATUS', {
        NONE: null,
        MET: "Met",
        NOT_MET: "Not Met",
        NOT_APPLICABLE: "NA",
    })
    .constant('ACC_ACCREDITATION_DOC_URI', {
        NONE: "",
        DIAS: "/accreditation/dias/dias_documents",
        NSQHS: "/accreditation/nsqhs/nsqhs_documents",
        NSQHS2: "/accreditation/nsqhs2/nsqhs2_documents",
        ACQS: "/accreditation/acqs/acqs_documents"
    })
    .constant('SAFETY_PLAN_REVIEW_SETTINGS', {
        DATE_FORMAT: 'dd/MM/yyyy',
        REVIEW_DEFAULT_LENGTH: 14,
    })
    .constant('DOCUMENT_TYPE', {
        EQUIPMENT_DOCUMENT: 1,
        LICENSE_DOCUMENT: 2,
        RSPP_DOCUMENT: 3,
        ACCREDITATION_DOCUMENT: 3,
    })
    .constant('EXPORT_FORMAT_PDF', 'pdf')
    .constant('EXPORT_FORMAT_CSV', 'csv')
    .constant('EXPORT_FORMAT_XLS', 'xls')
    .constant('EXPORT_FORMAT_XLSX', 'xlsx')
    .constant('ACC_ASSESSMENT_ITEM_APPROVAL_STATUS', {
        NONE: null,
        PENDING: "pending",
        APPROVED: "approved",
        NOT_APPROVED: "not_approved",
        NOT_APPLICABLE: "not_applicab",
    })
    .constant('DOCUMENT_CODE', {
        EQUIPMENT:      'edo',
        EMPLOYEES:      'uli',
    })
    .constant('VALIDATION', {
        EMAIL: '([a-zA-Z0-9_\\.-]+)@([\\da-zA-Z\\.-]+)\\.([a-zA-Z\\.]{2,6})',
        PHONE: '\\({0,1}((0|\\+61)(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{3}',
        FAX: '\\({0,1}((0|\\+61)(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{3}',
        MOBILE: '\\({0,1}((0|\\+61)(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{3}'
    });
