import { DATE_FORMAT } from '../../../../common/utils/util.constants';
import { formatDate } from '../../../../common/utils/util.date';
import { generateQRCode } from '../../../../common/utils/util.qrcode';

/**
 * Creates a new EquipmentCalibrationInfoController.
 * @class
 */
class EquipmentCalibrationInfoController {
    constructor($ngRedux, $state, $scope, NavigationService, EquipmentCalibrationActions) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$scope = $scope;
        this.NavigationService = NavigationService;
        this.actions = { ...EquipmentCalibrationActions };
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);

        this.identifier = 'app.equipmentCalibration.manage';
        this.state = 'app.equipmentCalibration.manage.info';

        generateQRCode( 'info', {
            id: this.equipment.visualId,
            action: 'equipment',
        });
    }

    onPrintQRcode = () => {
        this.printQRcode(this.equipment.id, this.equipment.visualId, 'equipment');
    };

    mapState(state) {
        if (state.equipmentCalibration.equipment.mobility === "1") {
            state.equipmentCalibration.equipment.mobilityName = 'Mobile';
        } else {
            state.equipmentCalibration.equipment.mobilityName = 'Fixed';
        }

        return {
            equipment: state.equipmentCalibration.equipment,
            expiryDate: formatDate(state.equipmentCalibration.equipment.expiryDate, DATE_FORMAT.INPUT),
            installationDate: formatDate(state.equipmentCalibration.equipment.installationDate, DATE_FORMAT.INPUT),
            nextCalibrationDueDate: formatDate(state.equipmentCalibration.equipment.nextCalibrationDueDate, DATE_FORMAT.INPUT),
            nextServiceDueDate: formatDate(state.equipmentCalibration.equipment.nextServiceDueDate, DATE_FORMAT.INPUT),
            nextElectricalTagTestingDueDate: formatDate(state.equipmentCalibration.equipment.nextElectricalTagTestingDueDate, DATE_FORMAT.INPUT),
            purchaseDate: formatDate(state.equipmentCalibration.equipment.purchaseDate, DATE_FORMAT.INPUT)
        }
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

EquipmentCalibrationInfoController.$inject = ['$ngRedux', '$state', '$scope', 'NavigationService', 'EquipmentCalibrationActions'];

export const equipmentCalibrationInfo = {
    controller: EquipmentCalibrationInfoController,
    template: require('./equipment-calibration-info.html')
};
