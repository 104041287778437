import { GridController, gridTpl } from '../../../../common/grid/grid.controller';

class ReportingEquipmentHistoryGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, ReportingActions, EquipmentService, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.ReportingActions = ReportingActions;
        this.EquipmentService = EquipmentService;
        this.grid_filter = $filter;
    }

    $onInit() {
        this.module = 'reporting';
        this.identifier = 'reportingEquipmentHistoryGrid';
        this.filterTemplate = './reporting-equipment-history-grid-filter.html';
        this.filterOpen = true;
        this.autoload = false;
        this.message = this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_HISTORY.FILTER_FIELDS_MESSAGE");
        this.exportService = this.EquipmentService.history;

        this.columns = [
            {title: '#',                    mapper: null},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_HISTORY.GRID_EQUIPMENT_CATEGORY"),   mapper: 'category'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_HISTORY.GRID_IN"),                   mapper: 'countIn',  class: 'green'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_HISTORY.GRID_OUT"),                  mapper: 'countOut', class:'red'}
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.ReportingActions)(this);
        this.dataAction = this.getEquipmentInOutHistory;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

ReportingEquipmentHistoryGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'ReportingActions', 'EquipmentService', "$filter"];

export const reportingEquipmentHistoryGrid = {
    bindings: {
        identifier: '@',
        onReset: '&'
    },
    controller: ReportingEquipmentHistoryGridController,
    ...gridTpl
};
