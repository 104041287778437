import watch from 'redux-watch';

/* @ngInject */
export function SelectDepartmentTypeController($ngRedux, AdministrationActions) {
    var ctrl = this;
    var unsubscribe = angular.noop;
    var onDepartment = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);

        var watchDepartment = watch($ngRedux.getState, 'administration.administrationAccountsDepartment.data');
        onDepartment = $ngRedux.subscribe(watchDepartment(function(department) {
            if (department.uuid) {
                ctrl.getDepartmentTypes(ctrl.identifier, department.parentUuid, {
                    id: department.type,
                    name: department.typeName
                });
            }
        }));
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            $ngRedux.dispatch({
                type: 'DEPARTMENT_TYPE_SELECTED',
                id: ctrl.identifier,
                departmentType: ctrl.selectedOption,
                departmentName: _.find(ctrl.options, ['id', ctrl.selectedOption]).name
            });
        }
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onDepartment();
    };

    function mapStateToThis(state) {
        return {
            options: _.get(state, getStateContainer('types'), []),
            selectedOption: _.get(state, getStateContainer('data.type'), null)
        };
    }

    function getStateContainer(statePath) {
        return [ctrl.module, ctrl.identifier].join('.') + '.' + statePath;
    }
}
