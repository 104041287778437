import moment from 'moment';

export function ServiceContractVisitFactory() {
    return function ServiceContractVisit(accountUuid, month) {

        this.id = null;
        this.accountUuid = accountUuid;
        this.month = month;
        this.subjectName = null;
        this.locationName = null;
        this.allDayEvent = false;
        this.date = moment('1 ' + month, 'D MMMM YYYY').toDate();
        this.startTime = null;
        this.startTimePeriod = null;
        this.endTime = null;
        this.endTimePeriod = null;
        this.equipment = [];

        this.getDateString = function() {
            return moment(this.date).format('DD-MM-YYYY');
        };

        this.getStartTime = function() {
            return _.isNull(this.startTime) || this.allDayEvent ? null : this.startTime;
        };

        this.getFormattedStartTime = function() {
            return _.isNull(this.startTime) || this.allDayEvent ? '' : moment(this.startTime, 'hh:mm:ss').format('LT');
        };

        this.getEndTime = function() {
            return _.isNull(this.startTime) || this.allDayEvent ? null : this.endTime;
        };

        this.getFormattedEndTime = function() {
            return _.isNull(this.startTime) || this.allDayEvent ? '' : moment(this.endTime, 'hh:mm:ss').format('LT');
        };

        this.getEquipmentIds = function() {
            return this.equipment.map(function(item) {
                return item.equipment_id;
            }).join();
        };

        this.getAllDayEvent = function() {
            return this.allDayEvent ? 'Yes' : 'No';
        };

        this.removeEquipment = function(equipment) {
            var idx = _.findIndex(this.equipment, ['equipment_id', equipment.equipment_id]);
            if (idx > -1) {
                this.equipment.splice(idx, 1);
                return true;
            }
            return false;
        };

        this.countEquipment = function() {
            return this.equipment.length;
        };

        this.getEquipmentUserFriendlyIds = function() {
            return this.equipment.map(function(item) {
                return item.user_friendly_id;
            }).join();
        };
    };
}
