export const systemWelcomeState = {
    name: 'app.system.welcome',
    // redirectTo: 'app.system.welcome.home',
    url: '/welcome',
    component: 'system'
};

export const systemWelcomeHomeState = {
    name: 'app.system.welcome.home',
    url: '',
    component: 'systemWelcome',
    params: {
        navConfig: 'app.system.welcome'
    }
};

export const systemWelcomeDocumentationState = {
    name: 'app.system.welcome.documentation',
    url: '/documentation',
    component: 'systemWelcomeDocumentation',
    params: {
        navConfig: 'app.system.welcome'
    }
};
