import { GridController, gridTpl } from '../../../../../../common/grid/grid.controller';
import { getVendorServices } from '../../../cre-vendors.actions';

class CredentialingVendorsServicesGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, $location, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$location = $location;
    }

    $onInit() {
        this.module = 'credentialing';
        this.identifier = 'creVendorsServicesGrid';
        this.header = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                mapper: null},
            {title: 'Service Type',     mapper: 'type'},
            {title: 'Pricing',          mapper: 'pricing'},
            {title: 'Price ex tax',     mapper: 'price_ex_tax'},
            {title: 'Price inc tax',    mapper: 'price_inc_tax'},
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), { getVendorServices }
        )(this);

        this.dataAction = this.getVendorServices;
        this.filter.token = this.$location.search().token;
        this.filter.identifier = this.identifier;
        this.filter.application_id = this.$location.search().invite || this.$ngRedux.getState().credentialing.creVendor.application_id;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const creVendorsServicesGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onRemove: '&',
    },
    controller: CredentialingVendorsServicesGridController,
    ...gridTpl
};
