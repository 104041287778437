import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';
import { DATE_FORMAT } from '../../../../../common/utils/util.constants';
import moment from 'moment';

class EquipmentCalibrationLoanHistoryGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, EquipmentCalibrationLoanActions) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.actions = EquipmentCalibrationLoanActions;
    }

    $onInit() {
        this.module = 'equipmentCalibration';
        this.identifier = 'equipmentCalibrationLoanHistoryGrid';
        this.filterTemplate = './equipment-calibration-loan-history-grid-filter.html';
        this.exportComponent = false;

        this.columns = [
            {title: '#',                mapper: null},
            {title: 'Loaned To',        mapper: 'loaned_to'},
            {title: 'Loan Date',        mapper: 'loan_date'},
            {title: 'Loan Due Date',    mapper: 'loan_due_date'},
            {title: 'Receipted By',     mapper: 'receipted_by'},
            {title: 'Receipt Date',     mapper: 'return_date'}
        ];

        this.buttons = [{
            label: (item) => {
                if (item.receipted_by && item.return_date) {
                    return 'Details';
                }
                if (moment().isAfter(moment(item.loan_due_date, DATE_FORMAT.OUTPUT))) {
                    return 'Overdue';
                }
                return 'Receipt';
            },
            class: (item) => {
                if (item.receipted_by && item.return_date) {
                    return 'btnGrid';
                }
                if (moment().isAfter(moment(item.loan_due_date, DATE_FORMAT.OUTPUT))) {
                    return 'btnGridLoanOverdue';
                }
                return 'btnGridModify';
            },
            callback: this.onSelect
        }];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.actions)(this);
        this.dataAction = this.getLoanHistory;
        this.filter.identifier = this.identifier;
        this.filter.equ_id = this.equipmentId;

        this.init();
    }
}

EquipmentCalibrationLoanHistoryGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'EquipmentCalibrationLoanActions'];

export const equipmentCalibrationLoanHistoryGrid = {
    bindings: {
        title: '@',
        equipmentId: '<',
        onSelect: '&'
    },
    controller: EquipmentCalibrationLoanHistoryGridController,
    ...gridTpl
};
