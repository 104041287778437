class ReportingCnscController {
    constructor($ngRedux, $rootScope, FileActions) {
        this.$ngRedux = $ngRedux;
        this.$rootScope = $rootScope;
        this.FileActions = FileActions;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(() => ({}), {
            ...this.FileActions
        })(this);
    }

    getCnscReport(event) {
        const currentDate = new Date();
        this.download(`storage/`+currentDate.getFullYear()+`-01/reporting-cnsc/`+event.item.headOffice.replace(' ', '-')+`/`+event.item.regIdNo+`-`+event.item.id+`.pdf`);
    }

    onGridReset() {
        this.$rootScope.$broadcast('SELECT_CASCADE_RESET', 'reportingCnscFilterForm');
    }
}

ReportingCnscController.$inject = ['$ngRedux', '$rootScope', 'FileActions'];

export const reportingCnsc = {
    controller: ReportingCnscController,
    template: `
        <reporting-cnsc-grid on-reset="$ctrl.onGridReset()" on-get-cnsc="$ctrl.getCnscReport($event)"></reporting-cnsc-grid>
    `
};
