import { GridReducer } from '../../../common/grid/grid.reducer';

const initialState = {
    eventAlertEscalate: {
        receiver_preselect: null,
        receiver_uuid: null,
        receiver_first_name: null,
        receiver_last_name: null,
        receiver_email: null,
        receiverList: [],
        notification_subject: null,
        notification_message: null
    },
    eventsAlertReceiversGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0,
        itemsSelect: []
    },
    eventsAlertNotificationsGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0
    }
};

export default (state = initialState, action) => {
    if (action) {
        switch (action.type) {
            case 'EVENT_ALERT_EMPLOYEES':
                if (action.id === 'eventAlertEscalate') {
                    return {
                        ...state,
                        receiverList: action.data.list
                    };
                }
            case 'EVENT_RECEIVER_RESET':
                if (action.id === 'eventAlertEscalate') {
                    return {
                        ...state,
                        receiver_preselect: null,
                        receiver_uuid: null,
                        receiver_first_name: null,
                        receiver_last_name: null,
                        receiver_email: null,
                        receiverList: []
                    };
                }
            case 'EVENT_NOTIFICATION_DETAILS':
                if (action.id === 'eventAlertEscalate') {
                    const { content } = JSON.parse(action.notification.message);
                    return {
                        ...state,
                        notification_subject: action.notification.subject,
                        notification_message: content
                    };
                }
            case 'EVENT_NOTIFICATION_RESET':
                if (action.id === 'eventAlertEscalate') {
                    return {
                        ...state,
                        notification_subject: null,
                        notification_message: null
                    };
                }
            default:
                return state;
        }
    }
    return state;
};
