export function kdValidate() {
    return {
        require: '^form',
        scope: {
            'kdValidate':'@',
            'kdMatch':'@'
        },
        link: function (scope, elm, attrs, formCtrl) {

            var inputEl = elm[0].querySelector("[name]");
            var inputNgEl = angular.element(inputEl);
            var inputName = inputNgEl.attr('name');

            scope.$on('show-errors-check-validity', function(event, formName) {
                if (!_.isUndefined(formName) && formCtrl.$name != formName)
                    return false;

                formCtrl[inputName].$validate();
                if (formCtrl[inputName].$invalid)
                    formCtrl[inputName].$setTouched();
            });
        }
    };
}
