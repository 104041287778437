/**
 * @module root/legacy/components/administration
 */
import { AdministrationController } from './administration.controller';
import { AdministrationAddAccountController } from './controllers/accounts/administration-accounts-controller-add';
import { AdministrationAddHeadOfficeController } from './controllers/accounts/administration-headoffice-controller-add';
import { AdministrationAccountsHeadOfficesController } from './accounts/head-offices/administration-accounts-head-offices.controller';
import { AdministrationAccountsHeadOfficesGridController } from './accounts/head-offices/grid/administration-accounts-head-offices-grid.controller';
import { AdministrationAccountsHeadOfficeDetailsController } from './accounts/head-offices/details/administration-accounts-head-office-details.controller';
import { AdministrationAccountsHeadOfficeEquipmentController } from './accounts/head-offices/equipment/administration-accounts-head-office-equipment.controller';
import { AdministrationAccountsHeadOfficeQualityManagersController } from './accounts/head-offices/quality-managers/administration-accounts-head-office-quality-managers.controller';
import { AdministrationAccountsHeadOfficeQualityManagerDetailsController } from './accounts/head-offices/quality-managers/details/administration-accounts-head-office-quality-manager-details.controller';
import { AdministrationAccountsHeadOfficeRscController } from './accounts/head-offices/rsc/administration-accounts-head-offices-rsc.controller';
import { AdministrationAccountsHeadOfficeRscDetailsController } from './accounts/head-offices/rsc/details/administration-accounts-head-offices-rsc-details.controller';
import { AdministrationAccountsSiteLocationsController } from './accounts/site-locations/administration-accounts-site-locations.controller';
import { AdministrationAccountsSiteLocationsGridController } from './accounts/site-locations/grid/administration-accounts-site-locations-grid.controller';
import { AdministrationAccountsSiteLocationDetailsController } from './accounts/site-locations/details/administration-accounts-site-location-details.controller';
import { AdministrationAccountsSiteLocationAddressController } from './accounts/site-locations/address/administration-accounts-site-location-address.controller';
import { AdministrationAccountsSiteLocationInvoiceAddressController } from './accounts/site-locations/invoice-address/administration-accounts-site-location-invoice-address.controller';
import { AdministrationAccountsSiteLocationEmployeesController } from './accounts/site-locations/employees/administration-accounts-site-location-employees.controller';
import { AdministrationAccountsSiteLocationEquipmentController } from './accounts/site-locations/equipment/administration-accounts-site-location-equipment.controller';
import { AdministrationAccountsSiteLocationLicencesController } from './accounts/site-locations/licences/administration-accounts-site-location-licences.controller';
import { AdministrationAccountsSiteLocationLicencesGridController } from './accounts/site-locations/licences/grid/administration-accounts-site-location-licences-grid.controller';
import { AdministrationAccountsSiteLocationLicenceDetailsController } from './accounts/site-locations/licences/details/administration-accounts-site-location-licence-details.controller';
import { AdministrationAccountsLicenceSiteLocationsGridController } from './accounts/site-locations/licences/details/grid/administration-accounts-licence-site-locations-grid.controller';
import { AdministrationAccountsSiteLocationQualityManagersController } from './accounts/site-locations/quality-managers/administration-accounts-site-location-quality-managers.controller';
import { AdministrationAccountsSiteLocationQualityManagerDetailsController } from './accounts/site-locations/quality-managers/details/administration-accounts-site-location-quality-manager-details.controller';
import { AdministrationAccountsSiteLocationRscController } from './accounts/site-locations/rsc/administration-accounts-site-location-rsc.controller';
import { AdministrationAccountsSiteLocationRscDetailsController } from './accounts/site-locations/rsc/details/administration-accounts-site-location-rsc-details.controller';
import { AdministrationAccountsSiteLocationRscAssistantsController } from './accounts/site-locations/rsc-assistants/administration-accounts-site-location-rsc-assistants.controller';
import { AdministrationAccountsSiteLocationRscAssistantDetailsController } from './accounts/site-locations/rsc-assistants/details/administration-accounts-site-location-rsc-assistant-details.controller';
import { AdministrationAccountsDepartmentsController } from './accounts/departments/administration-accounts-departments.controller';
import { AdministrationAccountsDepartmentsGridController } from './accounts/departments/grid/administration-accounts-departments-grid.controller';
import { AdministrationAccountsDepartmentDetailsController } from './accounts/departments/details/administration-accounts-department-details.controller';
import { AdministrationAccountsDepartmentEmployeesController } from './accounts/departments/employees/administration-accounts-department-employees.controller';
import { AdministrationAccountsDepartmentEquipmentController } from './accounts/departments/equipment/administration-accounts-department-equipment.controller';
import { AdministrationAccountsDepartmentRscController } from './accounts/departments/rsc/administration-accounts-department-rsc.controller';
import { AdministrationAccountsDepartmentRscDetailsController } from './accounts/departments/rsc/details/administration-accounts-department-rsc-details.controller';
import { AdministrationAccountsRoomsController } from './accounts/rooms/administration-accounts-rooms.controller';
import { AdministrationAccountsRoomsGridController } from './accounts/rooms/grid/administration-accounts-rooms-grid.controller';
import { AdministrationAccountsRoomDetailsController } from './accounts/rooms/details/administration-accounts-room-details.controller';
import { AdministrationAccountsRoomEquipmentController } from './accounts/rooms/equipment/administration-accounts-room-equipment.controller';
import { AdministrationAccountsEmployeesGridController } from './accounts/grid/administration-accounts-employees-grid.controller';
import { AdministrationAccountsEquipmentGridController } from './accounts/grid/administration-accounts-equipment-grid.controller';
import { AdministrationAccountsRscGridController } from './accounts/grid/administration-accounts-rsc-grid.controller';
import { AdministrationAccountsQualityManagersGridController } from './accounts/grid/administration-accounts-quality-managers-grid.controller';
import { AdministrationVendorsGridController } from './vendors/grid/administration-vendors-grid.controller';
import { UserManagementAllUsersController } from './controllers/accounts/administration-user-management-all-users-controller';
import { VendorBaseController } from './controllers/vendor/vendor-controller-base';
import { VendorAddController } from './controllers/vendor/vendor-controller-base-add';
import { VendorManageController } from './controllers/vendor/vendor-controller-base-manage';
import { AccessSubjectTypesController } from './controllers/access/access-subject-types-controller';
import { AccessMenuController } from './controllers/access/access-menu-controller';
import { SettingsController } from './controllers/settings/settings-controller';
import { SelectSiteLocationCenterTypeController } from './accounts/site-locations/form/select-site-location-center-type/select-site-location-center-type.controller';
import { SelectLicenceTypeController } from './accounts/site-locations/licences/form/select-licence-type/select-licence-type.controller';
import { SelectDepartmentTypeController } from './accounts/departments/form/select-department-type/select-department-type.controller';
import { AdministrationManager } from './services/administration-manager';
import { AccountService } from './services/account/account-service';
import { SubjectTypesService } from './services/account/subject-types-services';
import { LocationService } from './services/location-service';
import { DepartmentService } from './services/account/department-service';
import { UserService } from './services/account/user-service';
import { UserManagementService } from './services/account/user-management-service';
import { VendorService } from './services/vendor/vendor-service';
import { VendorManager } from './services/vendor/vendor-manager';
import { SettingsService } from './services/settings/settings-service';
import { SettingsManager } from './services/settings/settings-manager';
import { AdministrationActionsFactory } from './administration.actions';
import { UserFactory } from './factories/user-factory';
import { UserCredentialsFactory } from './factories/user-credentials-factory';
import { VendorFactory } from './factories/vendor/vendor-factory';
import { VendorHeadOfficeFactory } from './factories/vendor/subject/vendor-head-office';
import { VendorSiteLocationFactory } from './factories/vendor/subject/vendor-site-location';
import { VendorManagerFactory } from './factories/vendor/user/vendor-manager-user';
import { VendorAgentFactory } from './factories/vendor/vendor-agent-factory';
import { VendorServiceTypeFactory } from './factories/vendor/vendor-service-type';
import { VendorGridFilterFactory } from './factories/vendor/vendor-grid-filter-factory';
import { CountryFactory } from './factories/country-factory';
import { CountryStateFactory } from './factories/country-state-factory';
import { SubjectFactory } from './factories/subject-factory';
import { SubjectTypeFactory } from './factories/subject-type-factory';
import { SimpleSubjectFactory } from './factories/simple-subject-factory';
import { AccountFactory } from './factories/account/account-factory';
import { AccountTypeFactory } from './factories/account/account-type-factory';
import { AccountParentFactory } from './factories/account/account-parent-factory';
import { LocationFactory } from './factories/location-factory';
import { LocationDepartmentFactory } from './factories/location-department-factory';
import { LocationDepartmentTypeFactory } from './factories/location-department-type-factory';
import { LocationDepartmentRsoFactory } from './factories/location-department-rso-factory';
import { HeadOfficeFactory } from './factories/head-office-factory';
import { RSCFactory } from './factories/rsc-factory';
import { AddressFactory } from './factories/address-factory';
import { ContactFactory } from './factories/contact-factory';
import { FilterFactory } from './factories/filter-factory';
import { FuncFactory } from './factories/func-factory';
import { administrationAccountsHeadOffices } from './accounts/head-offices/administration-accounts-head-offices.component';
import { administrationAccountsHeadOfficesGrid } from './accounts/head-offices/grid/administration-accounts-head-offices-grid.component';
import { administrationAccountsHeadOfficeDetails } from './accounts/head-offices/details/administration-accounts-head-office-details.component';
import { administrationAccountsHeadOfficeEquipment } from './accounts/head-offices/equipment/administration-accounts-head-office-equipment.component';
import { administrationAccountsHeadOfficeQualityManagers } from './accounts/head-offices/quality-managers/administration-accounts-head-office-quality-managers.component';
import { administrationAccountsHeadOfficeQualityManagerDetails } from './accounts/head-offices/quality-managers/details/administration-accounts-head-office-quality-manager-details.component';
import { administrationAccountsHeadOfficeRsc } from './accounts/head-offices/rsc/administration-accounts-head-offices-rsc.component';
import { administrationAccountsHeadOfficeRscDetails } from './accounts/head-offices/rsc/details/administration-accounts-head-offices-rsc-details.component';
import { administrationAccountsSiteLocations } from './accounts/site-locations/administration-accounts-site-locations.component';
import { administrationAccountsSiteLocationsGrid } from './accounts/site-locations/grid/administration-accounts-site-locations-grid.component';
import { administrationAccountsSiteLocationDetails } from './accounts/site-locations/details/administration-accounts-site-location-details.component';
import { administrationAccountsSiteLocationAddress } from './accounts/site-locations/address/administration-accounts-site-location-address.component';
import { administrationAccountsSiteLocationInvoiceAddress } from './accounts/site-locations/invoice-address/administration-accounts-site-location-invoice-address.component';
import { administrationAccountsSiteLocationEmployees } from './accounts/site-locations/employees/administration-accounts-site-location-employees.component';
import { administrationAccountsSiteLocationEquipment } from './accounts/site-locations/equipment/administration-accounts-site-location-equipment.component';
import { administrationAccountsSiteLocationLicences } from './accounts/site-locations/licences/administration-accounts-site-location-licences.component';
import { administrationAccountsSiteLocationLicencesGrid } from './accounts/site-locations/licences/grid/administration-accounts-site-location-licences-grid.component';
import { administrationAccountsSiteLocationLicenceDetails } from './accounts/site-locations/licences/details/administration-accounts-site-location-licence-details.component';
import { administrationAccountsLicenceSiteLocationsGrid } from './accounts/site-locations/licences/details/grid/administration-accounts-licence-site-locations-grid.component';
import { administrationAccountsSiteLocationQualityManagers } from './accounts/site-locations/quality-managers/administration-accounts-site-location-quality-managers.component';
import { administrationAccountsSiteLocationQualityManagerDetails } from './accounts/site-locations/quality-managers/details/administration-accounts-site-location-quality-manager-details.component';
import { administrationAccountsSiteLocationRsc } from './accounts/site-locations/rsc/administration-accounts-site-location-rsc.component';
import { administrationAccountsSiteLocationRscDetails } from './accounts/site-locations/rsc/details/administration-accounts-site-location-rsc-details.component';
import { administrationAccountsSiteLocationRscAssistants } from './accounts/site-locations/rsc-assistants/administration-accounts-site-location-rsc-assistants.component';
import { administrationAccountsSiteLocationRscAssistantDetails } from './accounts/site-locations/rsc-assistants/details/administration-accounts-site-location-rsc-assistant-details.component';
import { administrationAccountsDepartments } from './accounts/departments/administration-accounts-departments.component';
import { administrationAccountsDepartmentsGrid } from './accounts/departments/grid/administration-accounts-departments-grid.component';
import { administrationAccountsDepartmentDetails } from './accounts/departments/details/administration-accounts-department-details.component';
import { administrationAccountsDepartmentEmployees } from './accounts/departments/employees/administration-accounts-department-employees.component';
import { administrationAccountsDepartmentEquipment } from './accounts/departments/equipment/administration-accounts-department-equipment.component';
import { administrationAccountsDepartmentRsc } from './accounts/departments/rsc/administration-accounts-department-rsc.component';
import { administrationAccountsDepartmentRscDetails } from './accounts/departments/rsc/details/administration-accounts-department-rsc-details.component';
import { administrationAccountsRooms } from './accounts/rooms/administration-accounts-rooms.component';
import { administrationAccountsRoomsGrid } from './accounts/rooms/grid/administration-accounts-rooms-grid.component';
import { administrationAccountsRoomDetails } from './accounts/rooms/details/administration-accounts-room-details.component';
import { administrationAccountsRoomEquipment } from './accounts/rooms/equipment/administration-accounts-room-equipment.component';
import { administrationAccountsEmployeesGrid } from './accounts/grid/administration-accounts-employees-grid.component';
import { administrationAccountsEquipmentGrid } from './accounts/grid/administration-accounts-equipment-grid.component';
import { administrationAccountsRscGrid } from './accounts/grid/administration-accounts-rsc-grid.component';
import { administrationAccountsQualityManagersGrid } from './accounts/grid/administration-accounts-quality-managers-grid.components';
import { administrationVendorsGrid } from './vendors/grid/administration-vendors-grid.component';
import { userPasswordButton } from './directives/user-password-button';
import { userStatusButton } from './directives/user-status-button';
import { rscManagement } from './directives/rsc';
import { licenceListDialog } from './directives/site-location-licence';
import { selectSiteLocationCenterType } from './accounts/site-locations/form/select-site-location-center-type/select-site-location-center-type.component';
import { selectLicenceType } from './accounts/site-locations/licences/form/select-licence-type/select-licence-type.component';
import { selectDepartmentType } from './accounts/departments/form/select-department-type/select-department-type.component';

angular.module(
    'components.administration',
    []
).controller({
    AdministrationController,
    AdministrationAddAccountController,
    AdministrationAddHeadOfficeController,
    AdministrationAccountsHeadOfficesController,
    AdministrationAccountsHeadOfficesGridController,
    AdministrationAccountsHeadOfficeDetailsController,
    AdministrationAccountsHeadOfficeEquipmentController,
    AdministrationAccountsHeadOfficeQualityManagersController,
    AdministrationAccountsHeadOfficeQualityManagerDetailsController,
    AdministrationAccountsHeadOfficeRscController,
    AdministrationAccountsHeadOfficeRscDetailsController,
    AdministrationAccountsSiteLocationsController,
    AdministrationAccountsSiteLocationsGridController,
    AdministrationAccountsSiteLocationDetailsController,
    AdministrationAccountsSiteLocationAddressController,
    AdministrationAccountsSiteLocationInvoiceAddressController,
    AdministrationAccountsSiteLocationEmployeesController,
    AdministrationAccountsSiteLocationEquipmentController,
    AdministrationAccountsSiteLocationLicencesController,
    AdministrationAccountsSiteLocationLicencesGridController,
    AdministrationAccountsSiteLocationLicenceDetailsController,
    AdministrationAccountsLicenceSiteLocationsGridController,
    AdministrationAccountsSiteLocationQualityManagersController,
    AdministrationAccountsSiteLocationQualityManagerDetailsController,
    AdministrationAccountsSiteLocationRscController,
    AdministrationAccountsSiteLocationRscDetailsController,
    AdministrationAccountsSiteLocationRscAssistantsController,
    AdministrationAccountsSiteLocationRscAssistantDetailsController,
    AdministrationAccountsDepartmentsController,
    AdministrationAccountsDepartmentsGridController,
    AdministrationAccountsDepartmentDetailsController,
    AdministrationAccountsDepartmentEmployeesController,
    AdministrationAccountsDepartmentEquipmentController,
    AdministrationAccountsDepartmentRscController,
    AdministrationAccountsDepartmentRscDetailsController,
    AdministrationAccountsRoomsController,
    AdministrationAccountsRoomsGridController,
    AdministrationAccountsRoomDetailsController,
    AdministrationAccountsRoomEquipmentController,
    AdministrationAccountsEmployeesGridController,
    AdministrationAccountsEquipmentGridController,
    AdministrationAccountsRscGridController,
    AdministrationAccountsQualityManagersGridController,
    AdministrationVendorsGridController,
    UserManagementAllUsersController,
    VendorBaseController,
    VendorAddController,
    VendorManageController,
    AccessSubjectTypesController,
    AccessMenuController,
    SettingsController,
    SelectSiteLocationCenterTypeController,
    SelectLicenceTypeController,
    SelectDepartmentTypeController
}).service({
    AdministrationManager,
    AccountService,
    SubjectTypesService,
    LocationService,
    DepartmentService,
    UserService,
    UserManagementService,
    VendorService,
    VendorManager,
    SettingsService,
    SettingsManager
}).factory({
    AdministrationActions: AdministrationActionsFactory,
    User: UserFactory,
    UserCredentials: UserCredentialsFactory,
    Vendor: VendorFactory,
    VendorHeadOffice: VendorHeadOfficeFactory,
    VendorSiteLocation: VendorSiteLocationFactory,
    VendorManagerUser: VendorManagerFactory,
    VendorAgent: VendorAgentFactory,
    VendorServiceType: VendorServiceTypeFactory,
    VendorGridFilter: VendorGridFilterFactory,
    Country: CountryFactory,
    CountryState: CountryStateFactory,
    Subject: SubjectFactory,
    SubjectType: SubjectTypeFactory,
    SimpleSubject: SimpleSubjectFactory,
    Account: AccountFactory,
    AccountType: AccountTypeFactory,
    AccountParent: AccountParentFactory,
    Location: LocationFactory,
    LocationDepartment: LocationDepartmentFactory,
    LocationDepartmentType: LocationDepartmentTypeFactory,
    LocationDepartmentRso: LocationDepartmentRsoFactory,
    HeadOffice: HeadOfficeFactory,
    RSC: RSCFactory,
    Address: AddressFactory,
    Contact: ContactFactory,
    Filter: FilterFactory,
    Func: FuncFactory
}).component({
    administrationAccountsHeadOffices,
    administrationAccountsHeadOfficesGrid,
    administrationAccountsHeadOfficeDetails,
    administrationAccountsHeadOfficeEquipment,
    administrationAccountsHeadOfficeQualityManagers,
    administrationAccountsHeadOfficeQualityManagerDetails,
    administrationAccountsHeadOfficeRsc,
    administrationAccountsHeadOfficeRscDetails,
    administrationAccountsSiteLocations,
    administrationAccountsSiteLocationsGrid,
    administrationAccountsSiteLocationDetails,
    administrationAccountsSiteLocationAddress,
    administrationAccountsSiteLocationInvoiceAddress,
    administrationAccountsSiteLocationEmployees,
    administrationAccountsSiteLocationEquipment,
    administrationAccountsSiteLocationLicences,
    administrationAccountsSiteLocationLicencesGrid,
    administrationAccountsSiteLocationLicenceDetails,
    administrationAccountsLicenceSiteLocationsGrid,
    administrationAccountsSiteLocationQualityManagers,
    administrationAccountsSiteLocationQualityManagerDetails,
    administrationAccountsSiteLocationRsc,
    administrationAccountsSiteLocationRscDetails,
    administrationAccountsSiteLocationRscAssistants,
    administrationAccountsSiteLocationRscAssistantDetails,
    administrationAccountsDepartments,
    administrationAccountsDepartmentsGrid,
    administrationAccountsDepartmentDetails,
    administrationAccountsDepartmentEmployees,
    administrationAccountsDepartmentEquipment,
    administrationAccountsDepartmentRsc,
    administrationAccountsDepartmentRscDetails,
    administrationAccountsRooms,
    administrationAccountsRoomsGrid,
    administrationAccountsRoomDetails,
    administrationAccountsRoomEquipment,
    administrationAccountsEmployeesGrid,
    administrationAccountsEquipmentGrid,
    administrationAccountsRscGrid,
    administrationAccountsQualityManagersGrid,
    administrationVendorsGrid,
    selectSiteLocationCenterType,
    selectLicenceType,
    selectDepartmentType
}).directive({
    userPasswordButton,
    userStatusButton,
    licenceListDialog,
    rscManagement
}).config(['$provide', ($provide) => {
    $provide.decorator('VendorManager', ['$delegate', 'AdministrationManager',
        ($delegate, AdministrationManager) => {
            Object.setPrototypeOf($delegate, AdministrationManager);
            return $delegate;
        }
    ]);
}]).run(($templateCache) => {
    $templateCache.put('app.administration.accounts.headOffices', '<administration-accounts-head-offices></administration-accounts-head-offices>');
    $templateCache.put('app.administration.accounts.headOfficesDetails', '<administration-accounts-head-office-details></administration-accounts-head-office-details>');
    $templateCache.put('app.administration.accounts.headOfficesRsc', '<administration-accounts-head-office-rsc></administration-accounts-head-office-rsc>');
    $templateCache.put('app.administration.accounts.headOfficesRscDetails', '<administration-accounts-head-office-rsc-details></administration-accounts-head-office-rsc-details>');
    $templateCache.put('app.administration.accounts.headOfficesQualityManagers', '<administration-accounts-head-office-quality-managers></administration-accounts-head-office-quality-managers>');
    $templateCache.put('app.administration.accounts.headOfficesQualityManagerDetails', '<administration-accounts-head-office-quality-manager-details></administration-accounts-head-office-quality-manager-details>');
    $templateCache.put('app.administration.accounts.headOfficesEquipment', '<administration-accounts-head-office-equipment></administration-accounts-head-office-equipment>');
    $templateCache.put('app.administration.accounts.siteLocations.list', '<administration-accounts-site-locations></administration-accounts-site-locations>');
    $templateCache.put('app.administration.accounts.siteLocations.details', '<administration-accounts-site-location-details></administration-accounts-site-location-details>');
    $templateCache.put('app.administration.accounts.siteLocations.address', '<administration-accounts-site-location-address></administration-accounts-site-location-address>');
    $templateCache.put('app.administration.accounts.siteLocations.invoiceAddress', '<administration-accounts-site-location-invoice-address></administration-accounts-site-location-invoice-address>');
    $templateCache.put('app.administration.accounts.siteLocations.rsc', '<administration-accounts-site-location-rsc></administration-accounts-site-location-rsc>');
    $templateCache.put('app.administration.accounts.siteLocations.rscDetails', '<administration-accounts-site-location-rsc-details></administration-accounts-site-location-rsc-details>');
    $templateCache.put('app.administration.accounts.siteLocations.rscAssistants', '<administration-accounts-site-location-rsc-assistants></administration-accounts-site-location-rsc-assistants>');
    $templateCache.put('app.administration.accounts.siteLocations.rscAssistantDetails', '<administration-accounts-site-location-rsc-assistant-details></administration-accounts-site-location-rsc-assistant-details>');
    $templateCache.put('app.administration.accounts.siteLocations.qualityManagers', '<administration-accounts-site-location-quality-managers></administration-accounts-site-location-quality-managers>');
    $templateCache.put('app.administration.accounts.siteLocations.qualityManagerDetails', '<administration-accounts-site-location-quality-manager-details></administration-accounts-site-location-quality-manager-details>');
    $templateCache.put('app.administration.accounts.siteLocations.licences', '<administration-accounts-site-location-licences></administration-accounts-site-location-licences>');
    $templateCache.put('app.administration.accounts.siteLocations.licenceDetails', '<administration-accounts-site-location-licence-details></administration-accounts-site-location-licence-details>');
    $templateCache.put('app.administration.accounts.siteLocations.equipment', '<administration-accounts-site-location-equipment></administration-accounts-site-location-equipment>');
    $templateCache.put('app.administration.accounts.siteLocations.employees', '<administration-accounts-site-location-employees></administration-accounts-site-location-employees>');
    $templateCache.put('app.administration.accounts.departments.list', '<administration-accounts-departments></administration-accounts-departments>');
    $templateCache.put('app.administration.accounts.departments.details', '<administration-accounts-department-details></administration-accounts-department-details>');
    $templateCache.put('app.administration.accounts.departments.rsc', '<administration-accounts-department-rsc></administration-accounts-department-rsc>');
    $templateCache.put('app.administration.accounts.departments.rscDetails', '<administration-accounts-department-rsc-details></administration-accounts-department-rsc-details>');
    $templateCache.put('app.administration.accounts.departments.equipment', '<administration-accounts-department-equipment></administration-accounts-department-equipment>');
    $templateCache.put('app.administration.accounts.departments.employees', '<administration-accounts-department-employees></administration-accounts-department-employees>');
    $templateCache.put('app.administration.accounts.rooms.list', '<administration-accounts-rooms></administration-accounts-rooms>');
    $templateCache.put('app.administration.accounts.rooms.details', '<administration-accounts-room-details></administration-accounts-room-details>');
    $templateCache.put('app.administration.accounts.rooms.equipment', '<administration-accounts-room-equipment></administration-accounts-room-equipment>');
    $templateCache.put('./administration-accounts-head-offices-grid-filter.html', require('./accounts/head-offices/grid/administration-accounts-head-offices-grid-filter.html'));
    $templateCache.put('./administration-accounts-site-locations-grid-filter.html', require('./accounts/site-locations/grid/administration-accounts-site-locations-grid-filter.html'));
    $templateCache.put('./vendors-listing.html', require('./views/vendors/vendors-listing.html'));
    $templateCache.put('./vendors-add.html', require('./views/vendors/vendors-add.html'));
    $templateCache.put('./vendors-services.html', require('./views/vendors/vendors-services.html'));
    $templateCache.put('./vendors-site-locations-add.html', require('./views/vendors/site-locations/vendors-site-locations-add.html'));
    $templateCache.put('./vendors-site-locations.html', require('./views/vendors/vendors-site-locations.html'));
    $templateCache.put('./vendors-site-locations-details.html', require('./views/vendors/site-locations/vendors-site-locations-details.html'));
    $templateCache.put('./vendors-site-locations-grid.html', require('./views/vendors/site-locations/vendors-site-locations-grid.html'));
    $templateCache.put('./vendors-site-locations-filter.html', require('./views/vendors/site-locations/vendors-site-locations-filter.html'));
    $templateCache.put('./vendors-site-locations-managers-details.html', require('./views/vendors/site-locations/managers/vendors-site-locations-managers-details.html'));
    $templateCache.put('./vendors-site-locations-managers-grid.html', require('./views/vendors/site-locations/managers/vendors-site-locations-managers-grid.html'));
    $templateCache.put('./vendors-site-locations-managers-filter.html', require('./views/vendors/site-locations/managers/vendors-site-locations-managers-filter.html'));
    $templateCache.put('./settings-password.html', require('./views/settings/settings-password.html'));
});
