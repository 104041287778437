import { GridController } from '../../../../common/grid/grid.controller';
import watch from 'redux-watch';

/* @ngInject */
export function EmployeeLicencesGridController($controller, $ngRedux, $state, NavigationService, EmployeesActions, $filter) {
    var ctrl = this;
    var onSwitch = angular.noop;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'employees';
        ctrl.identifier = 'employeeLicencesGrid';
        ctrl.filterTemplate = './employee-licences-grid-filter.html';
        ctrl.exportComponent = false;
        ctrl.buttons = [
            {label: 'Modify', class: 'btnGridModify', callback: ctrl.onModifyLicence},
            {label: 'Remove', class: 'btnGridRemove', callback: ctrl.onRemoveLicence}
        ];

        ctrl.columns = [
            {title: '#',                mapper: null},
            {title: '',                 mapper: 'alert'},
            {title: $filter('translate')('EMPLOYEES.LICENCES.GRID_LICENCE_NO'),       mapper: 'regIdNo'},
            {title: $filter('translate')('EMPLOYEES.LICENCES.GRID_LICENCE_TYPE'),     mapper: 'type.name'},
            {title: $filter('translate')('EMPLOYEES.LICENCES.GRID_ISSUED'),           mapper: 'getIssuedDate'},
            {title: $filter('translate')('EMPLOYEES.LICENCES.GRID_EXPIRED'),          mapper: 'getExpiryDate'},
            {title: $filter('translate')('EMPLOYEES.LICENCES.GRID_STATE'),            mapper: 'state'},
            {title: $filter('translate')('EMPLOYEES.LICENCES.GRID_DOCUMENT'),         mapper: 'file-download'}
        ];

        if (ctrl.editLicence !== null) {
            var watchStatus = watch($ngRedux.getState, 'employees.' + ctrl.identifier + '.filter.status');
            onSwitch = $ngRedux.subscribe(watchStatus(function(newValue) {
                if (newValue === 'pen') {
                    ctrl.buttons = [
                        {label: 'Approve', class: 'btnGridInfo', callback: ctrl.onApprove},
                        {label: 'Modify', class: 'btnGridModify', callback: ctrl.onModifyLicence},
                        {label: 'Remove', class: 'btnGridRemove', callback: ctrl.onRemoveLicence}
                    ];
                } else {
                    ctrl.buttons = [
                        {label: 'Modify', class: 'btnGridModify', callback: ctrl.onModifyLicence},
                        {label: 'Remove', class: 'btnGridRemove', callback: ctrl.onRemoveLicence}
                    ];
                }
            }));
        } else {
            ctrl.buttons = [];
        }

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), EmployeesActions)(ctrl);
        ctrl.dataAction = ctrl.getEmployeeLicences;
        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
