/**
 * @const {object}
 * @memberOf module:SafetyTraining
 */
export const stVideoConfirmation = {
    /* @ngInject */
    controller: function () {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            // TODO
        }
    },
    template: `
        <div style="height: 400px">
            <div class="loader formHeader">
                <div style="margin-left: 8px;">
                    <asset class="colorLaserSafetyTraining" src="training-video-review.svg"></asset>
                </div>
                <div class="colorLaserSafetyTraining">Thank you</div>
                <div class="colorLaserSafetyTraining">Your training completion has been sent to the Laser Safety Officer of The Ottawa Hospital</div>
            </div>
        </div>
    `
};
