import {IDS} from '../audit-action-id.constants';
import {TYPES} from '../audit-action-type.constants';
import * as AuditActions from '../audit.actions';
import * as AuditAddActions from './add.actions';
import * as AccreditationActions from '../../accreditation.actions'
import {formatDate} from '../../../../common/utils/util.date';
import {DATE_FORMAT} from '../../../../common/utils/util.constants';
import pick from 'lodash/pick';
import get from 'lodash/get';
import {TYPES as ACC_TYPES} from "../../accreditation-action-type.constants";
import moment from "moment";

class AuditAddController {
    constructor($ngRedux,
                $stateParams,
                $location,
                $scope,
                $rootScope,
                $filter,
                Notifier,
                DocumentManager,
                AuthService,
                EmployeeService,
                $timeout) {
        this.$ngRedux = $ngRedux;
        this.$stateParams = $stateParams;
        this.$location = $location;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.Notifier = Notifier;
        this.DocumentManager = DocumentManager;
        this.AuthService = AuthService;
        this.EmployeeService = EmployeeService;
        this.$timeout = $timeout;
        this.identifier = IDS.accAudit;
        this.selectedFormat = null;
        this.MAX_QUALITY_MANAGERS = 4;
    }

    $onInit() {
        this.schemeCode = this.$stateParams.itemCode;
        this.unsubscribe = this.$ngRedux.connect(state => {
            const addAudit =    state.accreditation.accAudit.addAudit;
            const details =     state.accreditation.accAudit.details;
            const {locationName, autocompleteReviewers, fiscalYearStart, maxDueDate, minDueDate} = state.accreditation.accAudit.siteLocation;
            const {siteLocation, headOffice, year} = state.accreditation.accDashboardLocationYear;

            return {
                headOffice,     // uuid
                siteLocation,   // uuid
                fiscalYear: year,
                fiscalYearStart,
                maxDueDate,//moment(`${year}`).add(fiscalYearStart, 'months').subtract(1,'days').toDate(),
                minDueDate,//new Date();
                sectionsTree:          state.accreditation.simpleSectionsTree,


                standardId:             details.standardId,
                standardParentId:       details.standardParentId,
                standardName:           details.standardName,
                standardLevel:          details.standardLevel,

                //review
                name:                   details.name,
                description:            details.description,
                date:                   details.date,
                evidenceProviders:      details.evidenceProviders,
                reviewers:              details.reviewers,
                auditRequestId:         details.auditRequestId,
                sourceTab:              details.sourceTab,
                selectedEvidenceProvider: details.selectedEvidenceProvider,
                selectedReviewer: details.selectedReviewer,

                //external evidence provider
                evidenceProviderFirstName:  details.evidenceProviderFirstName,
                evidenceProviderLastName:   details.evidenceProviderLastName,
                evidenceProviderEmail:      details.evidenceProviderEmail,

                //external quality manager
                qualityManagerFirstName:    details.qualityManagerFirstName,
                qualityManagerLastName:     details.qualityManagerLastName,
                qualityManagerEmail:        details.qualityManagerEmail,
                activities:                 details.activities,
            }
        }, { ...AccreditationActions, ...AuditActions, ...AuditAddActions})(this);

        this.schemeCode =   this.$stateParams.itemCode;

        this.resetFields();
        this.updateAutocompleteLabel();

        this.unsubscribeLocationChange = this.$scope.$on('LOCATION_SUBJECT_SELECTED', this.onLocationChange.bind(this));
        this.unsubscribeSelectCascadeLoaded = this.$scope.$on('SELECT_CASCADE_SUBJECTS_LOADED', this.onSelectCascadeLoaded.bind(this));
        this.unsubscribeLocationNotFound = this.$scope.$on('LOCATION_SUBJECT_NOT_FOUND', this.onLocationNotFound.bind(this));
    }

    onSelectCascadeLoaded() {
        if(this.headOffice && this.siteLocation) {
            this.$rootScope.$broadcast('FORCE_SELECT_PARENT', 0, this.headOffice, this.siteLocation);
        }
        else {
            if(this.$stateParams.slo) {
                const siteLocation = this.$stateParams.slo;
                this.EmployeeService.getEmploymentLocations(siteLocation).then(result => {
                    const headOffice = result[0].uuid;
                    this.$rootScope.$broadcast('FORCE_SELECT_PARENT', 0, headOffice, siteLocation );
                })
            }
            if(this.$stateParams.year){
                this.$ngRedux.dispatch({
                    type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                    accDashboardLocationYear: {
                        year: this.$stateParams.year,
                    }
                });
            }
        }
    }

    initStandardsInputSelects() { // init first select input
        this.$ngRedux.dispatch({
            type: TYPES.SELECT_NEW_AUDIT_STANDARD,
            id: IDS.addAudit,
            level:0,
            standards: this.sectionsTree,
            standardId: null,
            standardParentId: null,
        });
        const selectedLevel1Id = get(this.sectionsTree, '[0].id');
        const selectedLevel2Id = get(this.sectionsTree,'[0].children[0].id');
        if(selectedLevel1Id)
            this.onSelectStandard({item:{value: selectedLevel1Id}});
        if(selectedLevel2Id)
            this.onSelectStandard({item:{value: selectedLevel2Id}});
    }
    onSelectStandard({item}){
        const standard = this.findChildStandardById(item.value, this.sectionsTree);
            this.$ngRedux.dispatch({
                type:       TYPES.SELECT_NEW_AUDIT_STANDARD,
                id:         IDS.addAudit,
                level:      standard.level,
                standards:  standard.children,
                name:       standard.name,
                standardId: standard.id,
                standardParentId: standard.parentId,
            });
    }

    findChildStandardById(id, standards) {
        return standards.reduce((result, item) => {
            if(result)
                return result;
            if(item.id === id)
                return item
            else if (item.children.length)
                return this.findChildStandardById(id, item.children)
        }, null);
    }

    onLocationChange (evt, loc) {
        const subjectType = get(loc,'in_subject_basic_type_code');
        const siteLocation = loc.in_subject_uuid;
        switch(subjectType){
            case 'hof':
                this.$ngRedux.dispatch({
                    type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                    accDashboardLocationYear: {
                        headOffice: siteLocation,
                    }
                });
            break;

            case 'slo':
                this.$stateParams.slo = siteLocation;
                this.$ngRedux.dispatch({
                    type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                    accDashboardLocationYear: {
                        siteLocation: loc.in_subject_uuid,
                        year: this.fiscalYear,
                    }
                });
                this.resetAssignees();
                this.fetchSiteLocationDetails( siteLocation );
                this.fetchSiteLocationContacts( siteLocation );
                this.fetchStandardsTree(this.schemeCode, siteLocation, this.fiscalYear).then(()=>{
                    this.initStandardsInputSelects();
                })
            break;
        }
    };

    onLocationNotFound(event, location) {
        this.hideSelectSiteLocation = true;
        var user = this.AuthService.getUser();
        if(user.parentCount !== 1)
            return

        const ctrl = this;
        this.EmployeeService.getEmploymentLocations(user.uuid).then(siteLocations => {
            const siteLocation = get(siteLocations,'[0].uuid');
            this.$stateParams.slo = siteLocation;
            this.$ngRedux.dispatch({
                type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                accDashboardLocationYear: {
                    siteLocation,
                }
            });
            ctrl.fetchSiteLocationDetails( siteLocation );
            ctrl.fetchSiteLocationContacts( siteLocation );
            ctrl.fetchStandardsTree(ctrl.schemeCode, siteLocation, ctrl.fiscalYear).then(()=>{
                ctrl.initStandardsInputSelects();
            })
        });
    }

    onYearChange({item}) {
        this.$stateParams.year = item.value;
        if(this.fiscalYearStart !== undefined) {
            let maxYear = item.value;
            if(this.fiscalYearStart <= moment().month()) {
                maxYear++;
            }
            this.$ngRedux.dispatch({
                type: TYPES.SELECT_NEW_AUDIT_FISCAL_YEAR,
                maxDueDate: moment(`${maxYear}-01-01`).add(this.fiscalYearStart, 'months').subtract(1,'days').toDate()
            })
        }
    }

    onAddEvidenceProviderAutocomplete(assignee){
        if(!this.evidenceProviders.length) {
            this.addEvidenceProvider({
                ...pick(assignee,['employee_uuid','first_name','last_name','email']),
                assignee: `${assignee.first_name} ${assignee.last_name}`,
            });
        }
    }

    onAddExternalEvidenceProviderButton() {
        if(this.evidenceProviders && this.evidenceProviders.length)
            return;

        if(this.externalEvidenceProviderForm.$valid) {
            this.addEvidenceProvider({
                first_name: this.evidenceProviderFirstName,
                last_name:  this.evidenceProviderLastName,
                email:      this.evidenceProviderEmail,
                assignee:`${this.evidenceProviderFirstName} ${this.evidenceProviderLastName}`,
            });
            this.externalEvidenceProviderForm.$setPristine();
            this.externalEvidenceProviderForm.$setUntouched();
        }
    }

    onForwardSwitchChange() {
        if(this.isRequestForwarded === false) {
            this.$ngRedux.dispatch({
                type: TYPES.RESET_EVIDENCE_PROVIDERS
            });
        }
    }

    onAddReviewerButton(){
        if(this.reviewers.length >= this.MAX_QUALITY_MANAGERS){
            this.Notifier.error('COMMON.CLIENT_RESPONSE.UNABLE_TO_ADD_MORE_ITEMS');
            return;
        }
        if(this.reviewers.some(item => item.employee_uuid === this.selectedReviewer.employee_uuid)) {
            this.Notifier.error('COMMON.CLIENT_RESPONSE.ITEM_PRESENT_ALREADY');
            return;
        }

        if(this.selectedReviewer) {
            this.addReviewer({
                employee_uuid:  this.selectedReviewer.employee_uuid,
                first_name:     this.selectedReviewer.first_name,
                last_name:      this.selectedReviewer.last_name,
                email:          this.selectedReviewer.email,
                assignee:    `${this.selectedReviewer.first_name} ${this.selectedReviewer.last_name}`,
                username:       this.selectedReviewer.username,
                type_name:      this.selectedReviewer.subject_type_name,
            });

        }
    }

    onAddExternalQualityManagerButton() {
        if(this.reviewers.length >= this.MAX_QUALITY_MANAGERS){
            this.Notifier.error('COMMON.CLIENT_RESPONSE.UNABLE_TO_ADD_MORE_ITEMS');
            return;
        }
        if(this.reviewers.some(item => item.email === this.qualityManagerEmail)) {
            this.Notifier.error('COMMON.CLIENT_RESPONSE.ITEM_PRESENT_ALREADY');
            return;
        }
        if(this.externalQualityManagerForm.$valid) {
            this.addReviewer({
                first_name: this.qualityManagerFirstName,
                last_name:  this.qualityManagerLastName,
                email:      this.qualityManagerEmail,
                assignee:`${this.qualityManagerFirstName} ${this.qualityManagerLastName}`,
            });
            this.externalQualityManagerForm.$setPristine();
            this.externalQualityManagerForm.$setUntouched();
        }
    }

    onRemoveEvidenceProvider(item) {
        this.$ngRedux.dispatch({
            type: TYPES.REMOVE_AUDIT_ASSIGNEE,
            id: IDS.addAudit,
            path: 'details.evidenceProviders',
            item
        });
    }

    onRemoveQualityManager(item) {
        this.$ngRedux.dispatch({
            type: TYPES.REMOVE_AUDIT_ASSIGNEE,
            id: IDS.addAudit,
            path: 'details.reviewers',
            item
        });
    }

    async onEvidenceProviderAutocompleteKeywordQuery (keyword) {
        if(this.isQManagersSwitchOn) {
            return keyword ?
                this.reviewers.filter(this.getFilter(keyword))
                : this.reviewers
        }
        else
            return await this.fetchEvidenceProviderCandidates(keyword, this.siteLocation);
    }

    getFilter(keyword) {
        return item => {
            return `${item.first_name} ${item.last_name} ${item.username}`.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
        }
    }

    updateAutocompleteLabel() {
        const key = this.isQManagersSwitchOn ? 'QUALITY_MANAGERS' : 'EMPLOYEES';
        const addReviewer = this.$filter('translate')('ACCREDITATION.AUDIT.DETAILS.ADD_REVIEWER');
        const searchFrom = this.$filter('translate')(`ACCREDITATION.AUDIT.DETAILS.SEARCH_FROM_${key}`);
        this.autocompleteLabel = `${addReviewer} (${searchFrom})`;
    }

    onSubmit() {
        if(this.reviewers.length === 0) {
            this.Notifier.error('ACCREDITATION.AUDIT.DETAILS.MISSING_QUALITY_MANAGER');
            return;
        }
        if(!this.date) {
            this.Notifier.error('COMMON.CLIENT_RESPONSE.MISSING_DATE');
            return;
        }
        if(this.detailsForm.$valid) {
            this.detailsForm.$setPristine();
            this.detailsForm.$setUntouched();
            const reviewers = this.reviewers.map(item => item.employee_uuid);
            const evidenceAssignee = get(this, 'evidenceProviders[0].employee_uuid');
            const ctrl = this;
            this.addAudit(
                this.standardId,
                this.siteLocation,
                this.fiscalYear,
                this.name,
                this.description,
                formatDate(this.date, DATE_FORMAT.OUTPUT),
                reviewers,
                evidenceAssignee
            ).then(() => {
                ctrl.fetchStandardsTreeProgress(ctrl.schemeCode, ctrl.siteLocation, ctrl.fiscalYear).then(() => {
                    ctrl.selectStandardById(ctrl.standardId);
                });
            });
        }
        else
            this.Notifier.error('Please fill all required fields in the form!');
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeLocationChange();
        this.unsubscribeLocationNotFound();
        this.unsubscribeSelectCascadeLoaded();
    }
}

AuditAddController.$inject = [
    '$ngRedux',
    '$stateParams',
    '$location',
    '$scope',
    '$rootScope',
    '$filter',
    'Notifier',
    'DocumentManager',
    'AuthService',
    'EmployeeService',
    '$timeout']

export const accAuditAdd = {
    controller: AuditAddController,
    template: require('./add.html')
}
