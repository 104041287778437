/* @ngInject */
export function AccAssessmentItemFactory(ACC_ASSESSMENT_ITEM_TYPE) {
    return function AccAssessmentItem() {

        this.id = null;
        this.assessmentId = null;
        this.standardId = null;
        this.assessmentItemType = ACC_ASSESSMENT_ITEM_TYPE.NONE;
        this.request = null;
        this.assessmentItemDocumentType = null; //which document to generate for docg assessment items
        this.value = null;
    };
}
