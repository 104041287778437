
export const IDS = {

    // parent
    employees:                                  'employees',

    // module
    empImportSettings:                          'empImportSettings',

    // components
    empImportSettingsCentersList:               'empImportSettingsCentersList',
    empImportSettingsCentersListGrid:           'empImportSettingsCentersListGrid',
    empImportSettingsCentersDetails:            'empImportSettingsCentersDetails',
    // empImportSettingsCentersDetailsGrid:        'empImportSettingsCentersDetailsGrid',

    empImportSettingsOccupationsList:           'empImportSettingsOccupationsList',
    empImportSettingsOccupationsListGrid:       'empImportSettingsOccupationsListGrid',
    empImportSettingsOccupationsDetails:        'empImportSettingsOccupationsDetails',
    // empImportSettingsOccupationsDetailsGrid:    'empImportSettingsOccupationsDetailsGrid',

    empImportSettingsUsersRelocatedList:                 'empImportSettingsUsersRelocatedList',
    empImportSettingsUsersRelocatedListGrid:             'empImportSettingsUsersRelocatedListGrid',
    empImportSettingsUsersRelocatedDetails:              'empImportSettingsUsersRelocatedDetails',

    empImportSettingsUsersSkippedList:                 'empImportSettingsUsersSkippedList',
    empImportSettingsUsersSkippedListGrid:             'empImportSettingsUsersSkippedListGrid',
    empImportSettingsUsersSkippedDetails:              'empImportSettingsUsersSkippedDetails',
    // empImportSettingsUsersDetailsGrid:          'empImportSettingsUsersDetailsGrid',

    // tab states:
    app_employees_import_settings:                      'app.employees.import-settings',
    app_employees_import_settings_dashboard:            'app.employees.import-settings.dashboard',

    app_employees_import_settings_centers:              'app.employees.import-settings.centers',
    app_employees_import_settings_centers_list:         'app.employees.import-settings.centers.list',
    app_employees_import_settings_centers_details:      'app.employees.import-settings.centers.details',

    app_employees_import_settings_occupations:          'app.employees.import-settings.occupations',
    app_employees_import_settings_occupations_list:     'app.employees.import-settings.occupations.list',
    app_employees_import_settings_occupations_details:  'app.employees.import-settings.occupations.details',

    app_employees_import_settings_users:                    'app.employees.import-settings.users',
    app_employees_import_settings_users_relocated_list:     'app.employees.import-settings.users.relocated-list',
    app_employees_import_settings_users_relocated_details:  'app.employees.import-settings.users.relocated-details',
    app_employees_import_settings_users_skipped_list:       'app.employees.import-settings.users.skipped-list',
    app_employees_import_settings_users_skipped_details:    'app.employees.import-settings.users.skipped-details',
};
