import {TYPES} from './add-document-action-type.constants';
import {IDS} from './add-document-action-id.constants';
import * as TABS from '../../../common/tabs/tabs.constants';
import {TabsReducer} from '../../../common/tabs/tabs.reducer';
import * as FORM from '../../../common/form/input/input.constants';
import {GridReducer} from '../../../common/grid/grid.reducer';
import {GridFilterReducer} from '../../../common/grid/filter/grid-filter.reducer';
import {getAvailableYearsRange} from '../../../reducers/accreditation.reducer';
import * as GRID from '../../../common/grid/grid.constants';
import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import moment from 'moment'
import {INPUT_TEXT} from "../../../common/form/input/input.constants";

/**
 * @function AddDocumentReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function AddDocumentReducer(state, action) {
    const currentYear = new Date().getFullYear();
    if (typeof state === 'undefined') {
        const currentYear = parseInt(moment().format('YYYY'))
        return {
            siteLocationUuid: null,
            siteLocationName: null,
            fiscalYearStart: null,

            documentTypes: [
                { value: 'pol', label: 'Policy' },
                { value: 'pro', label: 'Procedure' },
                { value: 'gdo', label: 'Other' },
            ],
            documentType: 'pol',
            documentNumber: '',
            documentName: '',
            documentDescription: '',
            documentDate: null,
            documentDueDate: null,

            [IDS.addFromExistingGrid]: {
                ...GridReducer(),
                filter: {
                    ...GridFilterReducer(),
                    path:           IDS.accDocumentsGrid,
                    loc_uuid:       null,
                    standard_id:    null,
                    searched_column: 'name',
                    doc_type: null,
                }
            },
            searchedColumns: [
                { value: 'name', label: 'Document Name' },
                { value: 'number', label: 'Document Number' },
                { value: 'filename', label: 'Document Filename' },],
            selectedYear: currentYear,
            availableYears: getAvailableYearsRange(currentYear),
        }
    }

    switch (action.type) {
        // case TYPES.SET_CURRENT_FISCAL_YEAR:
        //     if (action.id === IDS.accScheme) {
        //         const currentYear = action.data;
        //         state = set({...state}, 'selectedYear', currentYear);
        //         return set( state,      'availableYears', getAvailableYearsRange(currentYear));
        //     }
        case TYPES.FETCH_SITE_LOCATION_DETAILS:
            if (action.id === IDS.accAddDocument) {
                return { ...state, ...action.data }
            }
        case TYPES.FETCH_STANDARD_DETAILS:
            return merge({...state},  action.data );
            // if(action.data.assessmentItem) {
            //     set(state, `${action.id}.assessmentItem`, action.data.assessmentItem)
//            }

        case TYPES.REFRESH_DOCUMENT_DETAILS:
            if (action.id === IDS.accAddDocument) {
                return {...state, ...action.data }
            }

        // case TYPES.INIT_REVIEW_STATUSES:
        //     if(action.id === IDS.accAddDocument) {
        //         return {...state, reviewStatuses: action.reviewStatuses };
        //     }

        /* GENERIC TYPES */

        case 'LOCATION_SELECTED':
            if(action.id === IDS.accAddDocument) { // slo select-cascade
                if(action.type_code === 'slo') {
                    return {...state, siteLocationUuid: action.uuid}
                }
                else return state;
            }
        case 'AUTOCOMPLETE_SELECTED':
            if (action.id === IDS.accAddDocument && action.item) {
                return set({...state}, action.path, action.item)
            }
        case FORM.INPUT_SELECT:
        case FORM.INPUT_TEXT:
        case FORM.INPUT_CHECKBOX:
        case FORM.INPUT_DATE:
        case FORM.INPUT_RADIO:
        case 'INPUT_EMAIL_CHANGE':
        case 'INPUT_PHONE_CHANGE':
        case 'INPUT_TEXT_AREA_CHANGE':
        case 'INPUT_SIGNATURE':
            if (action.id === IDS.accAddDocument) {
                const gridId = action.path.split('.')[0];
                if([IDS.addFromExistingGrid].includes(gridId)) {
                    const filter = {
                        [action.path.split('.').pop()]: action.input
                    };
                    return set({...state}, gridId,
                        GridReducer(get(state, gridId), { type: GRID.GRID_FILTER, filter })
                    )
                }
                else
                    return set({...state}, action.path, action.input)
            }
        case GRID.GRID_INIT:
        case GRID.GRID_FILTER:
        case GRID.GRID_FILTER_TOGGLE:
        case GRID.GRID_RESET_FILTER:
        case GRID.GRID_INIT_FILTER:
        case GRID.GRID_LOADING:
        case GRID.GRID_PAGINATE:
        case GRID.GRID_RELOAD:
        case GRID.GRID_FETCH:
        case GRID.GRID_SELECT:
        case GRID.GRID_SELECT_ALL:
        case GRID.GRID_DESELECT:
        case GRID.GRID_DESELECT_ALL:
            // if ([IDS.accTasksGrid, IDS.accMasterDocListAddGrid].includes(action.id)) {
            //     return { ...state, [action.id]: GridReducer( state[action.id], action) }
            // }
            if (action.id === IDS.accAddDocument) {
                return set({...state}, action.path,
                    GridReducer( get(state, action.path), action)
                )
            }
        case GRID.GRID_RESET:
            if (action.id === IDS.accAddDocument) {
                state = set({...state}, action.path,
                    GridReducer( get(state, action.path),{
                        type: GRID.GRID_RESET_FILTER,
                        exclude: action.exclude || (action.path === IDS.addFromExistingGrid ? ['siteLocationUuid','standardId'] : []),
                    })
                );
                return set(state, action.path, GridReducer( get(state, action.path), action))
            }
        default:
            return state;
    }
}

const index = (children, list = []) => {
    children && children.forEach(item => {
        if(item) {
            list[parseInt(item.id)] = item;
            index(item.children, list);
        }
    })
    return list;
};
