import { GridFilterReducer } from './filter/grid-filter.reducer';
import * as GRID from '../grid/grid.constants';
import _ from 'lodash';

/**
 * @function GridReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers.App
 */
export function GridReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            init: false,
            items: [],
            itemsCount: 0,
            itemsSelect: [],
            loading: false,
            filter: GridFilterReducer(),
            reset: false,
            reload: false,
            override: {
                columns: [],
                buttons: []
            }
        };
    }

    switch (action.type) {
        case GRID.GRID_INIT:
            return Object.assign({}, state, {
                init: true,
                filter: GridFilterReducer(state.filter, action)
            });
        case GRID.GRID_INIT_FILTER:
            return Object.assign({}, state, {
                filter: GridFilterReducer(state.filter, action)
            });
        case GRID.GRID_SHOW_LOADING:
            return Object.assign({}, state, {
                loading: true
            });
        case GRID.GRID_PAGINATE:
            return Object.assign({}, state, {
                filter: GridFilterReducer(state.filter, action),
                reload: state.filter.page === _.get(action, 'page', 1) ? !state.reload : state.reload
            });
        case GRID.GRID_FILTER:
            return Object.assign({}, state, {
                filter: GridFilterReducer(state.filter, {...action, type: 'PAGINATE'}),
                reload: state.filter.page === _.get(action, 'page', 1) ? !state.reload : state.reload,
                itemsSelect: []
            });
        case GRID.GRID_FILTER_TOGGLE:
            return Object.assign({}, state, {
                filterOpened: action.value,
                filter: GridFilterReducer(state.filter, {...action, type: 'PAGINATE'}),
                reload: action.reload ? !state.reload : state.reload,
                itemsSelect: []
            });
        case GRID.GRID_OVERRIDE:
            return Object.assign({}, state, {
                override: Object.assign({}, state.override, {
                    columns: _.isUndefined(action.columns) ? state.override.columns : action.columns,
                    buttons: _.isUndefined(action.buttons) ? state.override.buttons : action.buttons
                })
            });
        case GRID.GRID_RESET:
            return Object.assign({}, state, {
                filter: GridFilterReducer(state.filter, action),
                reset: !state.reset,
                reload: state.filter.page === 1 ? !state.reload : state.reload,
                itemsSelect: []
            });
        case GRID.GRID_RESET_FILTER:
            return Object.assign({}, state, {
                filter: GridFilterReducer(state.filter, action),
                itemsSelect: []
            });
        case GRID.GRID_RELOAD:
            return Object.assign({}, state, {
                reload: !state.reload
            });
        case GRID.GRID_FETCH:
            return Object.assign({}, state, {
                items: action.items,
                itemsCount: action.itemsCount || action.items_count,
                loading: false
            });
        case GRID.GRID_SELECT:
            return Object.assign({}, state, {
                itemsSelect: state.itemsSelect.concat(action.data)
            });
        case GRID.GRID_SELECT_ALL:
            return Object.assign({}, state, {
                itemsSelect: action.data
            });
        case GRID.GRID_DESELECT:
            return Object.assign({}, state, {
                itemsSelect: state.itemsSelect.filter(item => action.data !== item)
            });
        case GRID.GRID_DESELECT_ALL:
            return Object.assign({}, state, {
                itemsSelect: []
            });
        default:
            return state;
    }
}
