export function LocationDepartmentFactory() {
    return function LocationDepartment(siteLocationID) {
        return {
            in_dep_uuid: null,
            in_par_uuid: siteLocationID,
            in_dept_type_id: null,
            in_email: '',
            in_phone: '',
            in_drso_uuid: null,
            in_drso_username: null,
            in_drso_password: '',
            in_drso_first_name: '',
            in_drso_last_name: '',
            in_drso_email: '',
            in_drso_phone: '',
            in_drso_fax: '',
            in_drso_birthdate: null,
            in_drso_gender: null,

            set rso(obj) {
                this.in_drso_uuid = obj.in_uuid;
                this.in_drso_username = obj.in_username;
                this.in_drso_password = obj.in_password;
                this.in_drso_first_name = obj.in_first_name;
                this.in_drso_last_name = obj.in_last_name;
                this.in_drso_email = obj.in_email;
                this.in_drso_phone = obj.in_phone;
                this.in_drso_fax = obj.in_fax;
                this.in_drso_birthdate = obj.in_birthdate;
                this.in_drso_gender = obj.in_gender;
            }
        };
    };
}
