import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';
import {GRID_FILTER_TOGGLE} from '../../../../../common/grid/grid.constants';
import { IDS } from '../../settings-action-id.constants';
import * as Actions from '../default-documents.actions';

class DefaultDocumentsGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, AuthService, $filter, $mdDialog, $scope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
        this.AuthService = AuthService;
    }

    $onInit() {
        this.module = 'accreditation';
        this.identifier = IDS.accSettings;
        this.statePath = IDS.defaultDocumentsGrid;
        this.filterTemplate = './default-documents-grid-filter.html';
        // this.filterControls = './default-documents-grid-filter-controls.html';
        this.filterExpandButtonVisible = false;
        this.exportComponent = false;
        this.confirmDialogOnRemove = true;
        this.autoload = false;

        this.isAdmin = this.AuthService.getUser().type === 'Administrator';

        const prefix = 'ACCREDITATION.SETTINGS.DEFAULT_DOCUMENTS';
        this.columnsDefault = [
            {title: '#',                                                    mapper: null},
            {title: this.$filter('translate')(`${prefix}.TYPE`),            mapper: 'type_name'},
            {title: this.$filter('translate')(`COMMON.FIELD.NAME`),         mapper: 'name'},
            {title: this.$filter('translate')(`COMMON.FIELD.DESCRIPTION`),  mapper: 'description'},
            // {title: this.$filter('translate')(`COMMON.FIELD.HEAD_OFFICE`),  mapper: 'headOffices'},
            // {title: this.$filter('translate')('COMMON.FIELD.ORDER'),        mapper: 'orderChange', style:{ width:'40px' }},
        ];
        this.columns = this.columnsDefault.concat();

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), Actions)(this);
        // this.unsubscribeCustom = this.$ngRedux.connect(state => {
        //     const defaultDocumentsGrid = state.accreditation.accSettings.defaultDocumentsGrid;
        //     return {
        //         filterOpen:             defaultDocumentsGrid.filterOpened,
        //         selectedLocationUuid:   defaultDocumentsGrid.selectedLocationUuid,
        //         overrideColumns:        defaultDocumentsGrid.override.columns
        //     }
        // }, {})(this);
        // this.$scope.$on('grid-order-change', this.onGridOrderChangeEvent.bind(this));

        this.dataAction = this.fetchDefaultDocumentsGrid;
        this.init();
    }

    $onDestroy() {
        this.destroy();
        // if(this.unsubscribeCustom)
        //     this.unsubscribeCustom();
    }
}

DefaultDocumentsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'AuthService', '$filter', '$mdDialog', '$scope'];

export const defaultDocumentsGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onRemove: '&',
        onDetach: '&'
    },
    controller: DefaultDocumentsGridController,
    ...gridTpl
}
