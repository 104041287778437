import { GridReducer } from '../../../common/grid/grid.reducer';
import {GridFilterReducer} from '../../../common/grid/filter/grid-filter.reducer';
import * as GRID from '../../../common/grid/grid.constants';
import * as FORM from '../../../common/form/input/input.constants';
import { TYPES } from './import-action-type.constants';
import { IDS } from './import-action-id.constants';
import get from 'lodash/get';
import set from 'lodash/set';

/**
 * @function EmpImportReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function EmpImportReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            [IDS.empImportHistoryListGrid]: {
                ...GridReducer(),
                filter: {
                    ...GridFilterReducer(),
                    source: 'employee.imports.manual',
                }
            },
            [IDS.empImportHistoryDetails]: {

            },
            [IDS.empImportHistoryDetailsGrid]: {
                ...GridReducer(),
                filter: {
                    ...GridFilterReducer(),
                    import_status: 'added',
                },
            },
            empImportHistoryDetailsGridFilterStatusList: [
                {value: 'added',                        label: 'Added employees'},
                {value: 'updated',                      label: 'Updated employees'},
                {value: 'username updated',             label: 'Username updated'},
                {value: 'Skip List User',               label: 'Skipped User List'},
                {value: 'Rejected',                     label: 'Rejected'},
                {value: 'all',                          label: 'All'},
    ],

            [IDS.empImportNewImport]: {
                siteLocation: null,
                deleteMissingUsers: 1
            },

            [IDS.empImportNewResult]: {

            },
            [IDS.empImportNewResultGrid]: GridReducer(),
        };
    }

    switch (action.type) {
        case 'LOCATION_SELECTED':
            if(action.type_code === 'slo') {
                switch (action.id) {
                    case IDS.empImportNewImport:
                        return set({...state}, `${IDS.empImportNewImport}.siteLocation`, action.uuid)
                    case IDS.empImport:
                        return set({...state}, action.path,
                            GridReducer(get(state, action.path), {
                                type: GRID.GRID_FILTER,
                                filter: {
                                    slo_uuid: action.uuid
                                }
                            })
                        )
                }
            }

        case GRID.GRID_INIT:
        case GRID.GRID_FILTER:
        case GRID.GRID_FILTER_TOGGLE:
        case GRID.GRID_RESET_FILTER:
        case GRID.GRID_INIT_FILTER:
        case GRID.GRID_LOADING:
        case GRID.GRID_PAGINATE:
        case GRID.GRID_RELOAD:
        case GRID.GRID_FETCH:
        case GRID.GRID_SELECT:
        case GRID.GRID_SELECT_ALL:
        case GRID.GRID_DESELECT:
        case GRID.GRID_DESELECT_ALL:
            if (action.id === IDS.empImport) {
                return set({...state}, action.path,
                    GridReducer( get(state, action.path), action)
                )
            }
        case GRID.GRID_RESET:
            if (action.id === IDS.empImport) {
                if([IDS.empImportHistoryListGrid, IDS.empImportHistoryDetailsGrid].includes(action.path) ) {
                    state = set({...state}, `${action.path}.reset`, !get(state, `${action.path}.reset`));
                    state = set({...state}, `${action.path}.reload`, !get(state, `${action.path}.reload`));
                    return set(state, action.path,
                        GridReducer(get(state, action.path), {
                            type: GRID.GRID_RESET_FILTER,
                            exclude: action.exclude || ['source','gci_id'],
                        })
                    )
                }
                else
                    return set({...state}, action.path,
                        GridReducer( get(state, action.path),{ type: GRID.GRID_RESET })
                    )
            }

        case FORM.INPUT_SELECT:
        case FORM.INPUT_TEXT:
        case FORM.INPUT_TEXT_AREA:
        case FORM.INPUT_CHECKBOX:
        case FORM.INPUT_DATE:
        case FORM.INPUT_RADIO:
            return set({...state}, action.path, action.input)

        case TYPES.FETCH_COST_CENTERS: {
            state = set({...state}, `${IDS.empImportNewImport}.costCenterId`, action.costCenters.length && action.costCenters[0].value);
            return set(state, `${IDS.empImportNewImport}.costCenters`, action.costCenters)
        }

        case TYPES.RESET_CHECKBOX: {
            return set({...state}, `${action.id}.${action.path}`, action.defaultValue)
        }
        case TYPES.SELECT_IMPORT_ITEM: {
            return set({...state}, action.id, action.item)
        }

        default:
            return state;
        }
}
