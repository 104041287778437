import {TabsReducer} from '../../../common/tabs/tabs.reducer';
import {IDS} from './assessment-reports-action-id.constants';

const tabs = [
    {
        state: IDS.app_accreditation_scheme_assessment_reports_list,
        title: 'ACCREDITATION.ASSESSMENT_REPORTS.LIST.TAB',
        visible: true,
        disabled: false,
        loading: false,
    },
    {
        state: IDS.app_accreditation_scheme_assessment_reports_details,
        title: 'ACCREDITATION.ASSESSMENT_REPORTS.DETAILS.TAB',
        visible: false,
        disabled: false,
        loading: false
    },
    {
        state: IDS.app_accreditation_scheme_assessment_reports_add,
        title: 'ACCREDITATION.ASSESSMENT_REPORTS.ADD.TAB',
        visible: false,
        disabled: false,
        loading: false,
    },

];

const publicTabs = [
]

export const assessmentReportsTabs = {
    [IDS.app_accreditation_scheme_assessment_reports]: Object.assign(TabsReducer(), { tabs }),
    // [IDS.public_accreditation_scheme_assessment_reports]: Object.assign(TabsReducer(), { tabs: publicTabs })
}
