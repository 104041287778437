import { API_BASE, DATE_FORMAT, DOCUMENT_RESULTS, EQUIPMENT_STATUS } from '../../common/utils/util.constants';
import { formatDate } from '../../common/utils/util.date';
import { variant } from '../../bootstrap.json';
import { saveAs } from 'file-saver';
import moment from 'moment';
import isObject from 'lodash/isObject';
import get from 'lodash/get';

export default function EquipmentCalibrationActions($http, $q) {
    return {
        getNextId: (ety_id) => {
            return () => {
                return $http({
                    url: `${API_BASE}/equipment/next/${ety_id}`,
                    method: 'GET'
                }).then(({ data }) => {
                    return isObject(data) ? data.equipment_id : data;
                });
            }
        },
        getTypes: (id) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-calibration/types',
                    method: 'GET',
                }).then(response => {
                    dispatch({
                        type: 'EQUIPMENT_CALIBRATION_TYPES',
                        id: id,
                        items: response.data.map(item => {
                            return {
                                id: item.category_id,
                                value: item.category_id,
                                name: item.category_name,
                                label: item.category_name,
                                item: {
                                    type: item.category_id,
                                    typeName: item.category_name,
                                    typeCode: item.category_code
                                }
                            }
                        })
                    });
                });
            }
        },
        addType: (typeId, name) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-calibration/type/add',
                    method: 'POST',
                    data: {
                        ety_code: typeId,
                        category_name: name
                    }
                }).then(({ data }) => {
                    const params = isObject(data.params) ? data.params : JSON.parse(data.params);
                    dispatch({
                        type: 'EQUIPMENT_TYPE',
                        id: 'equipment',
                        data: {
                            type: String(params.category_id),
                            typeName: name
                        }
                    });
                });
            }
        },
        getManufacturers: () => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment/manufacturerslist',
                    method: 'GET',
                    params: {
                        offset: 0,
                        limit: -1,
                        equipmentTypeCode: 'cal'
                    }
                }).then(response => {
                    var rows = response.data.rows;
                    dispatch({
                        type: 'EQUIPMENT_MANUFACTURERS',
                        id: 'equipment',
                        items: rows.map(item => {
                            return {
                                value: item.man_id,
                                label: item.man_name,
                                item: {
                                    manufacturer: item.man_id,
                                    manufacturerName: item.man_name,
                                }
                            }
                        })
                    });
                });
            }
        },
        addManufacturer: (typeId, name) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment/manufacturer',
                    method: 'POST',
                    data: {
                        ety_code: typeId,
                        name: name,
                        has_myqmo: variant === 'myqmo' ? 1 : 0,
                        has_myrso: variant === 'myrso' ? 1 : 0
                    }
                }).then(({ data }) => {
                    dispatch({
                        type: 'EQUIPMENT_MANUFACTURER',
                        id: 'equipment',
                        data: {
                            manufacturer: String(data.hasOwnProperty('params') ? data.params.man_id : data.man_id),
                            manufacturerName: name
                        }
                    });
                });
            }
        },
        getModels: (id, type, manufacturer) => {
            if(isNaN(parseInt(manufacturer))) {
                return;
            }
            return dispatch => {
                return $http({
                    url: API_BASE + '/radioactive-materials/manufacturer/models',
                    method: 'GET',
                    params: {
                        ety_id: type.id ? type.id : type,
                        id: manufacturer
                    }
                }).then(response => {
                    dispatch({
                        type: 'EQUIPMENT_MODELS',
                        id: id,
                        items: response.data.items.map(item => {
                            return {
                                id: item.emo_id,
                                name: item.equipment_model
                            };
                        })
                    });
                });
            }
        },
        addModel: (id, data) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment/model',
                    method: 'POST',
                    data: {
                        man_id: data.manufacturer,
                        ety_id: data.type.id ? data.type.id : data.type,
                        name: data.name,
                        description: data.description
                    }
                }).then(response => {
                    const responseData = _.get(response.data, 'params', response.data);
                    dispatch({
                        type: 'EQUIPMENT_MODEL',
                        id: id,
                        model: {
                            id: responseData.emo_id,
                            name: data.name
                        }
                    });
                });
            }
        },
        getEquipmentDetails: (id) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-calibration/details',
                    method: 'GET',
                    params: {id: id}
                }).then(response => {
                    const equId = response.data[0][0].equipment_id;
                    dispatch({
                        type: 'EQUIPMENT_DETAILS',
                        id: 'equipment',
                        data: Object.assign(response.data[0][0], {
                            qrcode: `${API_BASE}/img/${localStorage.myrsoAuthToken}/qrcode/${equId}.png?id=${equId}&type=equipment`
                        }),
                        locations: response.data[1],
                        images: response.data[2].map(item => {
                            return {
                                id: parseInt(item.image_id),
                                url: API_BASE + '/img/' + localStorage.myrsoAuthToken + item.image_url,
                                width: item.width,
                                height: item.height
                            }
                        })
                    });
                });
            }
        },
        addEquipment: (data) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-calibration/add',
                    method: 'POST',
                    data: {
                        loc_uuid: data.location,
                        ety_id: parseInt(data.type.id),
                        emo_id: data.model.id,
                        equipment_id: data.visualId,
                        serial: data.modelSerial,
                        mobility: data.mobility,
                        installation_date: formatDate(data.installationDate),
                        cap_sens_exp_date: formatDate(data.expiryDate),
                        calibration_due_date: formatDate(data.nextCalibrationDueDate),
                        service_due_date: formatDate(data.nextServiceDueDate),
                        ele_tag_testing_due_date: formatDate(data.nextElectricalTagTestingDueDate),
                        estimated_useful_life: data.estimatedUsefulLife,
                        image_ids: data.gallery.length ? data.gallery.map(item => { return item.id }).join(',') : null
                    }
                }).then(({ data }) => {
                    const params = isObject(data.params) ? data.params : JSON.parse(data.params);
                    dispatch({
                        type: 'EQUIPMENT_ADD',
                        id: 'equipment',
                        data: params
                    });
                });
            }
        },
        editEquipment: (data) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-calibration/edit',
                    method: 'POST',
                    data: {
                        equ_id: data.id,
                        loc_uuid: data.location,
                        ety_id: parseInt(data.type.id),
                        emo_id: data.model.id,
                        equipment_id: data.visualId,
                        serial: data.modelSerial,
                        mobility: data.mobility,
                        installation_date: formatDate(data.installationDate, DATE_FORMAT.OUTPUT),
                        cap_sens_exp_date: formatDate(data.expiryDate, DATE_FORMAT.OUTPUT),
                        calibration_due_date: formatDate(data.nextCalibrationDueDate, DATE_FORMAT.OUTPUT),
                        service_due_date: formatDate(data.nextServiceDueDate, DATE_FORMAT.OUTPUT),
                        ele_tag_testing_due_date: formatDate(data.nextElectricalTagTestingDueDate, DATE_FORMAT.OUTPUT),
                        purchase_date: formatDate(data.purchaseDate, DATE_FORMAT.OUTPUT),
                        purchase_order_number: data.purchase_order_number,
                        warranty_period: data.warranty_period,
                        warranty_exp_date: formatDate(data.warranty_exp_date, DATE_FORMAT.OUTPUT),
                        asset_cost: data.assetCost,
                        act_type_code: data.act_type_code,
                        estimated_residual_value: data.estimated_residual_value,
                        depreciation_rate_per_year: data.depreciation_rate_per_year,
                        tax_estimated_useful_life: data.tax_estimated_useful_life,
                        depreciation_type: data.depreciation_type,
                        service_contract: data.service_contract,
                        comment: data.comment,
                        software_version: data.software_version,
                        man_eof_support_date: formatDate(data.man_eof_support_date, DATE_FORMAT.OUTPUT),
                        image_ids: data.gallery.length ? data.gallery.map(item => { return item.id }).join(',') : null
                    }
                }).then(({ data }) => {
                    const params = isObject(data.params) ? data.params : JSON.parse(data.params);
                    dispatch({
                        type: 'EQUIPMENT_EDIT',
                        id: 'equipment',
                        data: params
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'equipmentCalibrationGrid'
                    });
                });
            }
        },
        removeEquipment: (id) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment/dispose',
                    method: 'POST',
                    data: {
                        in_eqi_id: id,
                        in_dwa_id: null,
                        in_description: null,
                        in_doc_url: null,
                        dispose: 1
                    }
                }).then(() => {
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'equipmentCalibrationGrid'
                    });
                });
            }
        },
        storeEquipment: (id) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment/dispose',
                    method: 'POST',
                    data: {
                        in_eqi_id: id,
                        in_dwa_id: null,
                        in_description: null,
                        in_doc_url: null,
                        dispose: 0
                    }
                }).then(() => {
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'equipmentCalibrationGrid'
                    });
                });
            }
        },
        generateQRcode: (id) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-calibration/qrcode',
                    method: 'POST',
                    data: {
                        id: id
                    }
                }).then(response => {
                    dispatch({
                        type: 'QR_CODE',
                        id: 'equipment',
                        qrcode: API_BASE + '/img/' + localStorage.myrsoAuthToken + response.data
                    });
                });
            }
        },
        printQRcode: (equId, visualId, action) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-calibration/qrcode/print',
                    method: 'POST',
                    responseType: 'arraybuffer',
                    data: {
                        equId,
                        id: visualId,
                        action,
                    }
                }).then(response => {
                    saveAs(new Blob([response.data], { type: 'application/pdf' }), `${visualId}.pdf`);
                });
            }
        },
        getAssets: (filter) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-calibration/assets',
                    method: 'GET',
                    params: {
                        id: filter.equipmentId,
                        offset: filter.offset,
                        limit: filter.limit
                    }
                }).then(response => {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: filter.identifier,
                        items: response.data.map(item => {
                            return item;
                        }),
                        itemsCount: response.data.length
                    });
                });
            }
        },
        getAssetTypes: (equipmentType) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-calibration/asset/types',
                    method: 'GET',
                    params: {type: equipmentType}
                }).then(response => {
                    dispatch({
                        type: 'EQUIPMENT_ASSET_TYPES',
                        id: 'detector',
                        items: response.data.map(item => {
                            return {
                                value: item.asset_type_id,
                                label: item.asset_type_name,
                                item: {
                                    type: item.asset_type_id,
                                    typeName: item.asset_type_name,
                                }
                            }
                        })
                    });
                });
            }
        },
        getAssetCategories: (equipmentType) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-calibration/asset/categories',
                    method: 'GET',
                    params: {type: equipmentType}
                }).then(response => {
                    dispatch({
                        type: 'EQUIPMENT_ASSET_CATEGORIES',
                        id: 'detector',
                        items: response.data.map(item => {
                            return {
                                value: item.category_id,
                                label: item.category_name,
                                item: {
                                    category: item.category_id,
                                    categoryName: item.category_name,
                                }
                            }
                        })
                    });
                });
            }
        },
        getAssetRadiationTypes: (equipmentType) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-calibration/asset/radiation-types',
                    method: 'GET',
                    params: {type: equipmentType}
                }).then(response => {
                    dispatch({
                        type: 'EQUIPMENT_ASSET_RADIATION_TYPES',
                        id: 'detector',
                        items: response.data.map(item => {
                            return {
                                value: item.radiation_type_id,
                                label: item.radiation_type_name,
                                item: {
                                    radiationType: item.radiation_type_id,
                                    radiationTypeName: item.radiation_type_name,
                                }
                            }
                        })
                    });
                });
            }
        },
        addAsset: (data, grid) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-calibration/asset/add',
                    method: 'POST',
                    data: {
                        equ_id: data.equipmentId,
                        emo_id: data.model.id,
                        asset_type_id: data.type,
                        category_id: data.category,
                        radiation_type_id: data.radiationType,
                        serial: data.modelSerial,
                        due_date: formatDate(data.dueDate)
                    }
                }).then(({ data }) => {
                    const params = isObject(data.params) ? data.params : JSON.parse(data.params);
                    dispatch({
                        type: 'DETECTOR_ADD',
                        id: 'detector',
                        data: params
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: grid
                    });
                });
            }
        },
        editAsset: (data, grid) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-calibration/asset/edit',
                    method: 'POST',
                    data: {
                        asset_id: data.id,
                        emo_id: data.model.id,
                        asset_type_id: data.type,
                        category_id: data.category,
                        radiation_type_id: data.radiationType,
                        serial: data.modelSerial,
                        due_date: formatDate(data.dueDate, DATE_FORMAT.OUTPUT)
                    }
                }).then(() => {
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: grid
                    });
                });
            }
        },
        removeAsset: (id, grid) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-calibration/asset/remove',
                    method: 'GET',
                    params: { id }
                }).then(() => {
                    // TODO asset reset
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: grid
                    });
                });
            }
        },
        generateDepreciationReport: (equ_id, visualId) => {
            return () => {
                return $http({
                    url: API_BASE + '/equipment-calibration/asset/depreciation-report',
                    method: 'GET',
                    responseType: 'arraybuffer',
                    params: { equ_id, exportFormat: 'pdf' },
                }).then((res) => {
                    saveAs(new Blob([res.data], { type: 'application/pdf' }), `Depreciation-Report-${visualId}.pdf`);
                });
            }
        },
        getEquipmentDocumentTypes: (equipment_id) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment-document-types',
                    method: 'GET',
                    params: { equipment_id }
                }).then(response => {
                    dispatch({
                        type: 'EQUIPMENT_DOCUMENT_TYPES',
                        id: 'equipment',
                        items: response.data.map(item => {
                            return {
                                value: item.doc_type_id,
                                label: item.doc_type_name,
                                item: {
                                    documentTypeId: item.doc_type_id,
                                    documentTypeName: item.doc_type_name,
                                    documentTypeCode: item.doc_type_code,
                                    commentRequired: item.comment_required,
                                    documentNumberRequired: item.document_number_required,
                                    dueDateRequired: item.due_date_required,
                                    issueDateRequired: item.issue_date_required,
                                    resultRequired: item.result_required,
                                    servicedByRequired: item.serviced_by_required,
                                    inspectedByRequired: item.inspected_by_required
                                }
                            }
                        })
                    });
                });
            }
        },
        getDocumentInspectors: (equ_id, documentTypeId) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment/inspectors',
                    method: 'GET',
                    params: {
                        id: equ_id,
                        docTypeID: documentTypeId,
                    }
                }).then(response => {
                    dispatch({
                        type: 'EQUIPMENT_DOCUMENT_INSPECTORS',
                        id: 'document',
                        items: response.data.map(item => {
                            return {
                                value: item.uuid,
                                label: item.last_name + ' ' + item.first_name,
                                item: {
                                    inspectedBy: item.uuid,
                                    inspectorName: item.last_name + ' ' + item.first_name
                                }
                            }
                        })
                    });
                });
            }
        },
        getDocumentStatusList: () => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/document/statuses',
                    method: 'GET',
                }).then(response => {
                    dispatch({
                        type: 'EQUIPMENT_DOCUMENT_STATUS_LIST',
                        id: 'equipment',
                        items: response.data.map(item => {
                            return {
                                value: item.sta_id,
                                label: item.name,
                                item: {
                                    id: parseInt(item.sta_id),
                                    name: item.name,
                                    code: item.code
                                }
                            }
                        })
                    });
                });
            }
        },
        getDocumentResults: () => {
            return dispatch => {
                dispatch({
                    type: 'EQUIPMENT_DOCUMENT_RESULTS',
                    id: 'document',
                    items: DOCUMENT_RESULTS.map(item => {
                        return {
                            value: item.id,
                            label: item.title,
                            item: {
                                documentResultId: parseInt(item.id),
                                documentResultName: item.title
                            }
                        }
                    })
                });
            }
        },
        getDocuments: (filter) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/equipment/documents/list',
                    method: 'GET',
                    params: {
                        equipmentId:        filter.equipmentId,
                        documentTypeId:     filter.documentTypeId,
                        documentStatusId:   filter.documentStatusId,
                        offset:             filter.offset,
                        limit:              filter.limit
                    }
                }).then(response => {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'equipmentCalibrationDocumentsGrid',
                        items: response.data.list.map(item => {
                            return {
                                id: item.doc_id,
                                documentType: item.doc_type,
                                uri: item.doc_uri,
                                equipmentId: item.equ_id,
                                certificationDueDate: formatDate(item.certification_due_date, DATE_FORMAT.INPUT),
                                calibrationDueDate: formatDate(item.calibration_due_date, DATE_FORMAT.INPUT),
                                equipmentSerial: item.equipment_serial,
                                modelName: item.model_name,
                                manufacturerName: item.manufacturer_name,
                                dueDate: formatDate(item.due_date, DATE_FORMAT.INPUT),
                                status: item.status_name
                            }
                        }),
                        itemsCount: response.data.rows
                    });
                });
            };
        },
        saveDocument: (event, document, equipmentId) => {
            return dispatch => {
                return $http({
                    url: API_BASE+ "/equipment/" + equipmentId + "/document",
                    method: "POST",
                    data: {
                        documentType: document.documentTypeId,
                        issued: document.inspectionDate,
                        nextDueDate: formatDate(document.expiryDate),
                        documentUri: event[0].filePath,
                        inspected: formatDate(document.inspectionDate),
                        inspector: document.inspectedBy,
                        result: document.result,
                        comment: document.comment,
                        number: document.complianceCertificationNumber
                    }
                }).then(response => {
                    dispatch({
                        type: 'GRID_RESET',
                        id: 'equipmentCalibrationDocumentsGrid'
                    });
                    dispatch({
                        type: 'EQUIPMENT_DOCUMENT_RESET'
                    });
                })
            }
        },
        getVendorServiceTypes: (equ_id) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/administration/vendor/service-types',
                    method: 'GET',
                    params: {equipmentId: equ_id}
                }).then(response => {
                    dispatch({
                        type: 'VENDOR_SERVICE_TYPES',
                        items: response.data.map(item => {
                            return {
                                id: item.service_type_id,
                                code: item.service_type_code,
                                name: item.service_type_name,
                                mutable: item.is_mutable
                            }
                        })
                    });
                });
            }
        },
        getEquipment: (filter) =>  {
            return (dispatch) => {
                return $http({
                    url: API_BASE + '/equipment-calibration',
                    method: 'GET',
                    params: {
                        type:           filter.type,
                        status:         filter.status,
                        equipmentId:    filter.equipmentId,
                        serial:         filter.serial,
                        location:       filter.location,
                        category:       filter.category,
                        manufacturer:   filter.manufacturer,
                        loan:           filter.loan,
                        regDateFrom:    formatDate(filter.regDateFrom),
                        regDateTo:      formatDate(filter.regDateTo),
                        certDateFrom:   formatDate(filter.certDateFrom),
                        certDateTo:     formatDate(filter.certDateTo),
                        offset:         filter.offset,
                        limit:          filter.limit,
                        exportFormat:   filter.exportFormat,
                        exportColumns:  filter.exportColumns,
                        order_column:   get(filter, 'sort.column'),
                        order:          get(filter, 'sort.value')
                    }
                }).then((response) => {
                    if (filter.exportFormat) return response.data;
                    const activeCount = parseInt(response.data.totalActive);
                    const archiveCount = parseInt(response.data.totalArchive);
                    dispatch({
                        type: 'GRID_FETCH',
                        id: filter.identifier,
                        items: response.data.items.map((item) => {
                            return {
                                id: item.equ_id,
                                user_friendly_id: item.equipment_id,
                                equipment_id: item.equ_id,
                                eql_id: item.eql_id,
                                transaction_id: item.transaction_id,
                                transaction_type: item.transaction_type,
                                status: item.status,
                                account: { name: item.account_name },
                                department: { name: item.department },
                                room: { name: item.room_name },
                                manufacturer: { name: item.manufacturer},
                                serial: item.serial,
                                category: { name: item.category },
                                model: { name: item.model },
                                installation_date: formatDate(item.installation_date, DATE_FORMAT.INPUT),
                                in_cap_sens_exp_date: formatDate(item.in_cap_sens_exp_date, DATE_FORMAT.INPUT),
                                cal_due_date: formatDate(item.cal_due_date, DATE_FORMAT.INPUT),
                                ser_due_date: formatDate(item.ser_due_date, DATE_FORMAT.INPUT),
                                ele_tag_testing_due_date: formatDate(item.ele_tag_testing_due_date, DATE_FORMAT.INPUT),
                                loan_due_date: formatDate(item.loan_due_date, DATE_FORMAT.INPUT),
                                critical: item.critical,
                                warning: item.warning,
                                class: moment().isAfter(moment(item.loan_due_date, DATE_FORMAT.OUTPUT)) ? 'loanOverdue' : item.status === 'sto' ? 'orange' : ''
                            };
                        }),
                        itemsCount: filter.status === 'act' ? activeCount : archiveCount,
                        activeCount,
                        archiveCount
                    });
                });
            };
        },
        getEquipmentGrid: (filter) =>  {
            return (dispatch) => {
                return $http({
                    url: API_BASE + '/equipment-calibration-grid',
                    method: 'GET',
                    params: {
                        type:           filter.type,
                        status:         filter.status,
                        equipmentId:    filter.equipmentId,
                        serial:         filter.serial,
                        location:       filter.location,
                        category:       filter.category,
                        manufacturer:   filter.manufacturer,
                        loan:           filter.loan,
                        regDateFrom:    formatDate(filter.regDateFrom),
                        regDateTo:      formatDate(filter.regDateTo),
                        certDateFrom:   formatDate(filter.certDateFrom),
                        certDateTo:     formatDate(filter.certDateTo),
                        offset:         filter.offset,
                        limit:          filter.limit,
                        exportFormat:   filter.exportFormat,
                        exportColumns:  filter.exportColumns,
                        order_column:   get(filter, 'sort.column'),
                        order:          get(filter, 'sort.value')
                    }
                }).then((response) => {
                    if (filter.exportFormat) return response.data;
                    const activeCount = parseInt(response.data[1].find(item => item.status === 'act')?.count || 0);
                    const archiveCount = parseInt(response.data[1].find(item => item.status === 'dis')?.count || 0)
                                                + parseInt(response.data[1].find(item => item.status === 'sel')?.count || 0);
                    dispatch({
                        type: 'GRID_FETCH',
                        id: filter.identifier,
                        items: response.data[0].map((item) => {
                            return {
                                id: item.equ_id,
                                user_friendly_id: item.equipment_id,
                                equipment_id: item.equ_id,
                                eql_id: item.eql_id,
                                transaction_id: item.transaction_id,
                                transaction_type: item.transaction_type,
                                status: EQUIPMENT_STATUS[item.status],
                                account: { name: item.account_name },
                                department: { name: item.department },
                                room: { name: item.room_name },
                                manufacturer: { name: item.manufacturer},
                                serial: item.serial,
                                category: { name: item.category },
                                model: { name: item.model },
                                installation_date: formatDate(item.installation_date, DATE_FORMAT.INPUT),
                                in_cap_sens_exp_date: formatDate(item.in_cap_sens_exp_date, DATE_FORMAT.INPUT),
                                cal_due_date: formatDate(item.cal_due_date, DATE_FORMAT.INPUT),
                                ser_due_date: formatDate(item.ser_due_date, DATE_FORMAT.INPUT),
                                ele_tag_testing_due_date: formatDate(item.ele_tag_testing_due_date, DATE_FORMAT.INPUT),
                                loan_due_date: formatDate(item.loan_due_date, DATE_FORMAT.INPUT),
                                critical: item.critical,
                                warning: item.warning,
                                class: moment().isAfter(moment(item.loan_due_date, DATE_FORMAT.OUTPUT)) ? 'loanOverdue' : item.status === 'sto' ? 'orange' : ''
                            };
                        }),
                        itemsCount: filter.status === 'act' ? activeCount : archiveCount,
                        activeCount,
                        archiveCount
                    });
                });
            };
        },
        getDepreciationReport: (ety_id) => {
            return () => {
                return $http({
                    url: API_BASE + '/equipment-calibration/depreciation-report',
                    method: 'GET',
                    params: { ety_id, exportFormat: 'xlsx' },
                }).then(({ data }) => data);
            }
        },
        setLoanFilter: ({id, value}) => {
            return dispatch => {
                const deferred = $q.defer();
                dispatch({
                    type: 'EQUIPMENT_LOAN_FILTER',
                    id,
                    value,
                });
                dispatch({
                    type: 'GRID_RELOAD',
                    id,
                });
                deferred.resolve();
                return deferred.promise;
            };
        }
    }
}

EquipmentCalibrationActions.$inject = ['$http', '$q'];
