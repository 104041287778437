import { GridController } from '../../../../../common/grid/grid.controller';

/* @ngInject */
export function AdministrationAccountsQualityManagersGridController($controller, $ngRedux, $state, NavigationService, AdministrationActions) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'administration';
        ctrl.autoload = false;
        ctrl.filterComponent = false;
        ctrl.exportComponent = false;

        ctrl.columns = [
            {title: '#',            mapper: null},
            {title: 'First Name',   mapper: 'firstName'},
            {title: 'Last Name',    mapper: 'lastName'},
            {title: 'Username',     mapper: 'username'},
            {title: 'Phone',        mapper: 'phone'},
            {title: 'E-mail',       mapper: 'email'},
            {title: '',             mapper: 'password-change'}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), AdministrationActions)(ctrl);
        ctrl.dataAction = ctrl.getQualityManagers;
        ctrl.filter.identifier = ctrl.identifier;

        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
