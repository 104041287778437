export const ManufacturerFactory = () => {
    return function Manufacturer() {
        this.description = null;
        this.name = null;
        this.id = null;
        this.code = null;
        this.logo = null;
        this.equipmentType = null;
        this.equipmentTypeName = null;
        this.hasMyrso = 0;
        this.hasMyqmo = 0;
    };
};
