angular
    .module('components.accreditation')
    .component('accreditation', {
        template: require('./accreditation.html'),
        controller: 'AccreditationController'
    })
    .config(function($stateProvider) {
        $stateProvider
            .state('app.accreditation.DIAS.masterDocumentList', {
                url: '/master-document-list',
                template: require('./views/accreditation/quality-controll-documents/accreditation-master-doc-list.html'),
                controller: 'AccDiasMasterDocList as accDiasMasterDocList'
            })
            .state('app.accreditation.DIAS.trainingMatrix', {
                url: '/training-matrix',
                template: require('./views/accreditation/quality-controll-documents/accreditation-training-matrix.html'),
                controller: 'AccDiasQCDocsTrainingMatrix as accDiasTrainingMatrix'
            })
            .state('app.accreditation.DIAS.internalAudit', {
                abstract: true,
                url: '/internal-audit'
            })
            .state('app.accreditation.DIAS.internalAudit.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.accreditation.DIAS.internalAudit.auditSchedule', {
                url: '/audit-schedule',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-audit-schedule.html'),
                controller: 'AccIAAuditSchedule as accIAAuditSchedule'
            })
            .state('app.accreditation.DIAS.internalAudit.auditChecklist', {
                url: '/audit-checklist?sloc&year&standard',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-audit-checklist.html'),
                controller: 'AccIAAuditChecklist as accIAAuditChecklist'
            })
            .state('app.accreditation.DIAS.internalAudit.qualityImprovementRegister', {
                url: '/quality-improvement-register',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-quality-improvement-register.html'),
                controller: 'AccIAQualityImprovementReg as accIAQualityImprovementReg'
            })
            .state('app.accreditation.DIAS.internalAudit.auditReport', {
                url: '/audit-report',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-audit-report.html'),
                controller: 'AccIAAuditReport as accIAAuditReport'
            })
            .state('app.accreditation.DIAS.complaintsFeedback', {
                abstract: true,
                url: '/complaints-feedback'
            })
            .state('app.accreditation.DIAS.complaintsFeedback.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.accreditation.DIAS.complaintsFeedback.complainsFeedbackForm', {
                url: '/form',
                template: require('./views/accreditation/quality-controll-documents/complaints-and-feedback/accreditation-complains-feedback-form.html'),
                controller: 'AccComplaintFeedbackAdd'
            })
            .state('app.accreditation.DIAS.complaintsFeedback.complainsFeedbackRegister', {
                url: '/register',
                template: require('./views/accreditation/quality-controll-documents/complaints-and-feedback/accreditation-complains-feedback-register.html'),
                controller: 'AccComplaintsFeedbackReg as accComplaintsFeedbackReg'
            })
            .state('app.accreditation.DIAS.optimisedRadiationTechniqueCharts', {
                abstract: true,
                url: '/optimised-radiation-technique-charts'
            })
            .state('app.accreditation.DIAS.optimisedRadiationTechniqueCharts.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.accreditation.DIAS.optimisedRadiationTechniqueCharts.techniqueCharts', {
                url: '/technique-charts',
                template: require('./views/accreditation/quality-controll-documents/optimization-technique-charts/accreditation-technique-charts.html'),
                controller: 'AccTechniqueChartsController'
            })
            .state('app.accreditation.DIAS.optimisedRadiationTechniqueCharts.logScreenFluoroscopy', {
                url: '/log-screen-fluoroscopy',
                template: require('./views/accreditation/quality-controll-documents/optimization-technique-charts/accreditation-log-screen-fluoroscopy.html'),
                // controller: 'AccLogScreenFluoroscopy'
            })
            .state('app.accreditation.DIAS.optimisedRadiationTechniqueCharts.logScreenAngiography', {
                url: '/log-screen-angiography',
                template: require('./views/accreditation/quality-controll-documents/optimization-technique-charts/accreditation-log-screen-angiography.html'),
                // controller: 'AccLogScreenAngiography'
            })
            .state('app.accreditation.DIAS.referenceDocuments', {
                url: '/reference-documents',
                template: require('./views/accreditation/accreditation-reference-documents.html'),
                controller: 'AccReferenceDocList as accReferenceDocList'
            })
            .state('app.accreditation.DIAS.accreditationDocument', {
                url: '/accreditation-documents?sloc:stdid:standard:assItem:forward',
                template: require('./views/accreditation/accreditation-documents.html'),
                controller: 'AccreditationDocumentsModuleController as accDiasCtrl'
            })
            .state('app.accreditation.DIAS.approvalProcess', {
                url: '/approval-process',
                template: require('./views/accreditation/accreditation-approval-process.html'),
                controller: 'AccreditationApprovalProcessController as accApprovalCtrl'
            })
//*********************************************************************************************/
//********************* DENTAL ACCREDITATION ********************************/

            .state('app.accreditation.NSQHS', {
                abstract: true,
                url: '/nsqhs'
            })
            .state('app.accreditation.NSQHS.dashboard', {
                url: '',
                component: 'dashboard',
                params: {
                    minimizeSubmenu: true
                }
            })
            .state('app.accreditation.NSQHS.masterDocumentList', {
                url: '/master-document-list',
                template: require('./views/accreditation/quality-controll-documents/accreditation-master-doc-list.html'),
                controller: 'AccDiasMasterDocList as accDiasMasterDocList'
            })
            .state('app.accreditation.NSQHS.trainingMatrix', {
                url: '/training-matrix',
                template: require('./views/accreditation/quality-controll-documents/accreditation-training-matrix.html'),
                controller: 'AccDiasQCDocsTrainingMatrix as accDiasTrainingMatrix'
            })
            .state('app.accreditation.NSQHS.internalAudit', {
                abstract: true,
                url: '/internal-audit'
            })
            .state('app.accreditation.NSQHS.internalAudit.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.accreditation.NSQHS.internalAudit.auditSchedule', {
                url: '/audit-schedule',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-audit-schedule.html'),
                controller: 'AccIAAuditSchedule as accIAAuditSchedule'
            })
            .state('app.accreditation.NSQHS.internalAudit.auditChecklist', {
                url: '/audit-checklist?sloc&year&standard',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-audit-checklist.html'),
                controller: 'AccIAAuditChecklist as accIAAuditChecklist'
            })
            .state('app.accreditation.NSQHS.internalAudit.qualityImprovementRegister', {
                url: '/quality-improvement-register',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-quality-improvement-register.html'),
                controller: 'AccIAQualityImprovementReg as accIAQualityImprovementReg'
            })
            .state('app.accreditation.NSQHS.internalAudit.auditReport', {
                url: '/audit-report',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-audit-report.html'),
                controller: 'AccIAAuditReport as accIAAuditReport'
            })
            .state('app.accreditation.NSQHS.complaintsFeedback', {
                abstract: true,
                url: '/complaints-feedback'
            })
            .state('app.accreditation.NSQHS.complaintsFeedback.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.accreditation.NSQHS.complaintsFeedback.complainsFeedbackForm', {
                url: '/form',
                template: require('./views/accreditation/quality-controll-documents/complaints-and-feedback/accreditation-complains-feedback-form.html'),
                controller: 'AccComplaintFeedbackAdd'
            })
            .state('app.accreditation.NSQHS.complaintsFeedback.complainsFeedbackRegister', {
                url: '/register',
                template: require('./views/accreditation/quality-controll-documents/complaints-and-feedback/accreditation-complains-feedback-register.html'),
                controller: 'AccComplaintsFeedbackReg as accComplaintsFeedbackReg'
            })
            .state('app.accreditation.NSQHS.optimisedRadiationTechniqueCharts', {
                abstract: true,
                url: '/optimised-radiation-technique-charts'
            })
            .state('app.accreditation.NSQHS.optimisedRadiationTechniqueCharts.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.accreditation.NSQHS.optimisedRadiationTechniqueCharts.techniqueCharts', {
                url: '/technique-charts',
                template: require('./views/accreditation/quality-controll-documents/optimization-technique-charts/accreditation-technique-charts.html'),
                controller: 'AccTechniqueChartsController'
            })
            .state('app.accreditation.NSQHS.optimisedRadiationTechniqueCharts.logScreenFluoroscopy', {
                url: '/log-screen-fluoroscopy',
                template: require('./views/accreditation/quality-controll-documents/optimization-technique-charts/accreditation-log-screen-fluoroscopy.html'),
                // controller: 'AccLogScreenFluoroscopy'
            })
            .state('app.accreditation.NSQHS.optimisedRadiationTechniqueCharts.logScreenAngiography', {
                url: '/log-screen-angiography',
                template: require('./views/accreditation/quality-controll-documents/optimization-technique-charts/accreditation-log-screen-angiography.html'),
                // controller: 'AccLogScreenAngiography'
            })
            .state('app.accreditation.NSQHS.referenceDocuments', {
                url: '/reference-documents',
                template: require('./views/accreditation/accreditation-reference-documents.html'),
                controller: 'AccReferenceDocList as accReferenceDocList'
            })
            .state('app.accreditation.NSQHS.accreditationDocument', {
                url: '/documents?sloc:stdid:standard:assItem:forward',
                template: require('./views/accreditation/accreditation-documents.html'),
                controller: 'AccreditationDocumentsModuleController as accDiasCtrl'
            })
            .state('app.accreditation.NSQHS.approvalProcess', {
                url: '/approval-process',
                template: require('./views/accreditation/accreditation-approval-process.html'),
                controller: 'AccreditationApprovalProcessController as accApprovalCtrl'
            })
//*********************************************************************************************/
//********************* ACQS HOSPITAL ACCREDITATION ********************************/

            .state('app.accreditation.ACQS', {
                    abstract: true,
                    url: '/acqs'
                })
            .state('app.accreditation.ACQS.dashboard', {
                url: '',
                component: 'dashboard',
                params: {
                    minimizeSubmenu: true
                }
            })
            .state('app.accreditation.ACQS.masterDocumentList', {
                url: '/master-document-list',
                template: require('./views/accreditation/quality-controll-documents/accreditation-master-doc-list.html'),
                controller: 'AccDiasMasterDocList as accDiasMasterDocList'
            })
            .state('app.accreditation.ACQS.trainingMatrix', {
                url: '/training-matrix',
                template: require('./views/accreditation/quality-controll-documents/accreditation-training-matrix.html'),
                controller: 'AccDiasQCDocsTrainingMatrix as accDiasTrainingMatrix'
            })
            .state('app.accreditation.ACQS.internalAudit', {
                abstract: true,
                url: '/internal-audit'
            })
            .state('app.accreditation.ACQS.internalAudit.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.accreditation.ACQS.internalAudit.auditSchedule', {
                url: '/audit-schedule',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-audit-schedule.html'),
                controller: 'AccIAAuditSchedule as accIAAuditSchedule'
            })
            .state('app.accreditation.ACQS.internalAudit.auditChecklist', {
                url: '/audit-checklist?sloc&year&standard',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-audit-checklist.html'),
                controller: 'AccIAAuditChecklist as accIAAuditChecklist'
            })
            .state('app.accreditation.ACQS.internalAudit.qualityImprovementRegister', {
                url: '/quality-improvement-register',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-quality-improvement-register.html'),
                controller: 'AccIAQualityImprovementReg as accIAQualityImprovementReg'
            })
            .state('app.accreditation.ACQS.internalAudit.auditReport', {
                url: '/audit-report',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-audit-report.html'),
                controller: 'AccIAAuditReport as accIAAuditReport'
            })
            .state('app.accreditation.ACQS.complaintsFeedback', {
                abstract: true,
                url: '/complaints-feedback'
            })
            .state('app.accreditation.ACQS.complaintsFeedback.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.accreditation.ACQS.complaintsFeedback.complainsFeedbackForm', {
                url: '/form',
                template: require('./views/accreditation/quality-controll-documents/complaints-and-feedback/accreditation-complains-feedback-form.html'),
                controller: 'AccComplaintFeedbackAdd'
            })
            .state('app.accreditation.ACQS.complaintsFeedback.complainsFeedbackRegister', {
                url: '/register',
                template: require('./views/accreditation/quality-controll-documents/complaints-and-feedback/accreditation-complains-feedback-register.html'),
                controller: 'AccComplaintsFeedbackReg as accComplaintsFeedbackReg'
            })
            .state('app.accreditation.ACQS.optimisedRadiationTechniqueCharts', {
                abstract: true,
                url: '/optimised-radiation-technique-charts'
            })
            .state('app.accreditation.ACQS.optimisedRadiationTechniqueCharts.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.accreditation.ACQS.optimisedRadiationTechniqueCharts.techniqueCharts', {
                url: '/technique-charts',
                template: require('./views/accreditation/quality-controll-documents/optimization-technique-charts/accreditation-technique-charts.html'),
                controller: 'AccTechniqueChartsController'
            })
            .state('app.accreditation.ACQS.optimisedRadiationTechniqueCharts.logScreenFluoroscopy', {
                url: '/log-screen-fluoroscopy',
                template: require('./views/accreditation/quality-controll-documents/optimization-technique-charts/accreditation-log-screen-fluoroscopy.html'),
                // controller: 'AccLogScreenFluoroscopy'
            })
            .state('app.accreditation.ACQS.optimisedRadiationTechniqueCharts.logScreenAngiography', {
                url: '/log-screen-angiography',
                template: require('./views/accreditation/quality-controll-documents/optimization-technique-charts/accreditation-log-screen-angiography.html'),
                // controller: 'AccLogScreenAngiography'
            })
            .state('app.accreditation.ACQS.referenceDocuments', {
                url: '/reference-documents',
                template: require('./views/accreditation/accreditation-reference-documents.html'),
                controller: 'AccReferenceDocList as accReferenceDocList'
            })
            .state('app.accreditation.ACQS.accreditationDocument', {
                url: '/documents?sloc:stdid:standard:assItem:forward',
                template: require('./views/accreditation/accreditation-documents.html'),
                controller: 'AccreditationDocumentsModuleController as accDiasCtrl'
            })
            .state('app.accreditation.ACQS.approvalProcess', {
                url: '/approval-process',
                template: require('./views/accreditation/accreditation-approval-process.html'),
                controller: 'AccreditationApprovalProcessController as accApprovalCtrl'
            })
//*********************************************************************************************/
//********************* HOSPITAL ACCREDITATION ********************************/

            .state('app.accreditation.NSQHS2', {
                abstract: true,
                url: '/nsqhs2'
            })
            .state('app.accreditation.NSQHS2.dashboard', {
                url: '',
                component: 'dashboard',
                params: {
                    minimizeSubmenu: true
                }
            })
            .state('app.accreditation.NSQHS2.masterDocumentList', {
                url: '/master-document-list',
                template: require('./views/accreditation/quality-controll-documents/accreditation-master-doc-list.html'),
                controller: 'AccDiasMasterDocList as accDiasMasterDocList'
            })
            .state('app.accreditation.NSQHS2.trainingMatrix', {
                url: '/training-matrix',
                template: require('./views/accreditation/quality-controll-documents/accreditation-training-matrix.html'),
                controller: 'AccDiasQCDocsTrainingMatrix as accDiasTrainingMatrix'
            })
            .state('app.accreditation.NSQHS2.internalAudit', {
                abstract: true,
                url: '/internal-audit'
            })
            .state('app.accreditation.NSQHS2.internalAudit.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.accreditation.NSQHS2.internalAudit.auditSchedule', {
                url: '/audit-schedule',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-audit-schedule.html'),
                controller: 'AccIAAuditSchedule as accIAAuditSchedule'
            })
            .state('app.accreditation.NSQHS2.internalAudit.auditChecklist', {
                url: '/audit-checklist?sloc&year&standard',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-audit-checklist.html'),
                controller: 'AccIAAuditChecklist as accIAAuditChecklist'
            })
            .state('app.accreditation.NSQHS2.internalAudit.qualityImprovementRegister', {
                url: '/quality-improvement-register',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-quality-improvement-register.html'),
                controller: 'AccIAQualityImprovementReg as accIAQualityImprovementReg'
            })
            .state('app.accreditation.NSQHS2.internalAudit.auditReport', {
                url: '/audit-report',
                template: require('./views/accreditation/quality-controll-documents/internal-audit/accreditation-audit-report.html'),
                controller: 'AccIAAuditReport as accIAAuditReport'
            })
            .state('app.accreditation.NSQHS2.complaintsFeedback', {
                abstract: true,
                url: '/complaints-feedback'
            })
            .state('app.accreditation.NSQHS2.complaintsFeedback.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.accreditation.NSQHS2.complaintsFeedback.complainsFeedbackForm', {
                url: '/form',
                template: require('./views/accreditation/quality-controll-documents/complaints-and-feedback/accreditation-complains-feedback-form.html'),
                controller: 'AccComplaintFeedbackAdd'
            })
            .state('app.accreditation.NSQHS2.complaintsFeedback.complainsFeedbackRegister', {
                url: '/register',
                template: require('./views/accreditation/quality-controll-documents/complaints-and-feedback/accreditation-complains-feedback-register.html'),
                controller: 'AccComplaintsFeedbackReg as accComplaintsFeedbackReg'
            })
            .state('app.accreditation.NSQHS2.optimisedRadiationTechniqueCharts', {
                abstract: true,
                url: '/optimised-radiation-technique-charts'
            })
            .state('app.accreditation.NSQHS2.optimisedRadiationTechniqueCharts.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.accreditation.NSQHS2.optimisedRadiationTechniqueCharts.techniqueCharts', {
                url: '/technique-charts',
                template: require('./views/accreditation/quality-controll-documents/optimization-technique-charts/accreditation-technique-charts.html'),
                controller: 'AccTechniqueChartsController'
            })
            .state('app.accreditation.NSQHS2.optimisedRadiationTechniqueCharts.logScreenFluoroscopy', {
                url: '/log-screen-fluoroscopy',
                template: require('./views/accreditation/quality-controll-documents/optimization-technique-charts/accreditation-log-screen-fluoroscopy.html'),
                // controller: 'AccLogScreenFluoroscopy'
            })
            .state('app.accreditation.NSQHS2.optimisedRadiationTechniqueCharts.logScreenAngiography', {
                url: '/log-screen-angiography',
                template: require('./views/accreditation/quality-controll-documents/optimization-technique-charts/accreditation-log-screen-angiography.html'),
                // controller: 'AccLogScreenAngiography'
            })
            .state('app.accreditation.NSQHS2.referenceDocuments', {
                url: '/reference-documents',
                template: require('./views/accreditation/accreditation-reference-documents.html'),
                controller: 'AccReferenceDocList as accReferenceDocList'
            })
            .state('app.accreditation.NSQHS2.accreditationDocument', {
                url: '/documents?sloc:stdid:standard:assItem:forward',
                template: require('./views/accreditation/accreditation-documents.html'),
                controller: 'AccreditationDocumentsModuleController as accDiasCtrl'
            })
            .state('app.accreditation.NSQHS2.approvalProcess', {
                url: '/approval-process',
                template: require('./views/accreditation/accreditation-approval-process.html'),
                controller: 'AccreditationApprovalProcessController as accApprovalCtrl'
            });
    });
