import API from '../../common/http/api.fetch';
import {success, responseMessage} from '../../common/utils/util.notifier';
import {dashboardState} from './accreditation.states';
import {GRID_FETCH} from '../../common/grid/grid.constants';
import I18N from '../../common/utils/util.i18n';
import {IDS} from "./accreditation-action-id.constants";
import {TYPES} from "./accreditation-action-type.constants";
import * as GRID from "../../common/grid/grid.constants";

export const addScheme = (data, getConfigs, $state) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/scheme-add',
            method: 'POST',
            data:   {
                for_assessment_only: 1,
                code: data.code,
                name: data.name,
                description: data.description,
                level1_num_digits: parseInt(data.level1_num_digits),
                level2_num_digits: parseInt(data.level2_num_digits),
                level3_num_digits: parseInt(data.level3_num_digits)
            },
        }).then(response => {
            dispatch(getConfigs()).then(() => {
                $state.go(dashboardState.name);
            });
            success('ACCREDITATION.NOTIFICATIONS.SCHEME.ADD')
        }, error => {
            responseMessage(error);
        })
    }
}

export const fetchTasksGrid = (filter) => {
    return dispatch => {
        let emp_uuid =  filter.emp_uuid;
        if(!emp_uuid && filter.my_tasks_only === 1)
            emp_uuid =  filter.user_uuid;

        return API.fetch({
            url: '/accreditation/tasks-list',
            method: 'GET',
            params: {
                ...filter,
                emp_uuid,
                task_status: filter.task_status === 'isall' ? undefined : filter.task_status,
                scheme_code: filter.scheme_code || undefined,
                keyword:     filter.keyword     || undefined,
                identifier:     undefined,
                my_tasks_only:  undefined,
                user_uuid:      undefined,
            },
        }).then(response => {
            if (response.data.path)
                return response.data;
            else
                dispatch({
                    type:   GRID_FETCH,
                    id:     IDS.accTasksGrid,
                    ...response.data,
                    items: response.data.items.map(item => ({ ...item,
                        id: item.arq_id,
                        utaTypeName: I18N.translate(`ACCREDITATION.TASKS.TYPE.${item.uta_type.toUpperCase()}`),
                        status_name: I18N.translate(`ACCREDITATION.TASKS.STATUS.${item.status_code.toUpperCase()}`, item.status_name),
                        acc_code: item.acc_code.toUpperCase(),
                        assignee: `${item.assignee} (${item.username})`,
                        description: item.description && (item.description.length < 64 ? item.description : `${item.description.slice(0, 64)}...`),
                        }
                    ))
                })
        }, error => {
            responseMessage(error);
        })
    }
};

export const fetchTasksGridSchemes = () => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/schemes-list',
            method: 'GET',
            params: {
                for_assessment_only: 1,
                limit: -1,
                offset: 0,
            }
        }).then((response) => {
            dispatch({
                type: TYPES.FETCH_SCHEMES,
                id: IDS.accTasksGrid,
                items: [
                    {value: null, label: I18N.translate('COMMON.LABEL.ALL')},
                    ...response.data.map(item => ({
                        value: item.code,
                        label: item.name
                }))]
            })
        })
    }
}

export const localizeTasksGridFilter = (statuses, types) => {
    return dispatch => {
        dispatch({
            type: TYPES.LOCALIZE_TASKS_GRID_FILTER,
            id: IDS.accTasksGrid,
            taskStatuses: statuses.map(item => ({
                ...item,
                label:I18N.translate(`ACCREDITATION.TASKS.STATUS.${item.value.toUpperCase()}`)
            })),
            taskTypes: types.map(item => ({
                ...item,
                label:I18N.translate(`ACCREDITATION.TASKS.TYPE.${item.value.toUpperCase()}`)
            }))
        })
    }
}

/* APP HEADER - acc-location-search.component */

export const getSchemeDetails = (code) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/scheme-details',
            method: 'GET',
            params: { code }
        }).then(response => {
            return response.data.details
        }, error => {
            responseMessage(error);
        });
    };
}

export const listHeadOfficesByScheme = (asc_id, asc_code) => {
    return  dispatch => {
        return API.fetch({
            url: '/accreditation/scheme-locations',
            method: 'GET',
            params:{
                asc_id,
                limit: -1
            },
        }).then(response => {
            dispatch({
                type:   TYPES.FETCH_SCHEME_HEAD_OFFICES,
                id:     IDS.accDashboardLocationYear,
                schemeHeadOffices:   response.data.items.map(item => item.slo_uuid),
                asc_id,
                asc_code,
            })
        }, error => {
            responseMessage(error);
        })
    }
}

export const fetchStandardsTreeProgress = (scheme_code, loc_uuid, fiscal_year, id) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/standards-tree-progress',
            params: {loc_uuid, fiscal_year, scheme_code},
            method: 'GET'
        }).then( response => {
            const sectionsList = index(response.data);
            sectionsList.forEach(item => {
                item.statusName = I18N.translate(
                    `ACCREDITATION.EVIDENCE.STATUS.${item.status.toUpperCase()}`
                );
            })
            dispatch({
                type: TYPES.FETCH_STANDARDS_TREE_PROGRESS,
                id,
                data: response.data
            });
            return response.data;
        }, error => {
            responseMessage(error);
        });
    }
}

export const selectStandardById = (id) => {
    return dispatch => {
        return dispatch({
            type: TYPES.SELECT_STANDARD,
            standard_id: parseInt(id),
        });
    }
}

export const fetchStandardsTree = (scheme_code, slo_uuid = null, fiscal_year = null) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/standards-tree',
            method: 'GET',
            params: (slo_uuid && fiscal_year) ? { scheme_code, slo_uuid, fiscal_year } : { scheme_code },
        }).then(response => {
            dispatch({
                type:   TYPES.SECTIONS_TREE_FETCH,
                data:   response.data
            })
        }, error => {
            responseMessage(error);
        });
    };
}

export const isApprovalUser = (AuthService) => {
    return [
        'Administrator',
        'Quality Manager',
        'Head Radiation Safety Coordinator',
        'Site Radiation Safety Coordinator',
        'Site Assistant Radiation Safety Coordinator'
    ].includes(AuthService.getUser().type);
}

const index = (children, list = []) => {
    children && children.forEach(item => {
        if(item) {
            list[parseInt(item.id)] = item;
            index(item.children, list);
        }
    })
    return list;
};