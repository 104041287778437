import { GridReducer } from '../../../common/grid/grid.reducer';
import {GridFilterReducer} from '../../../common/grid/filter/grid-filter.reducer';
import * as GRID from '../../../common/grid/grid.constants';
import * as FORM from '../../../common/form/input/input.constants';
import { TYPES } from './acc-documents-action-type.constants';
import { IDS } from './acc-documents-action-id.constants';
import merge from 'lodash/merge';
import get from 'lodash/get';
import set from 'lodash/set';
import {getDocFileNameFromPath} from "../../../common/utils/util.path";

/**
 * @function AccDocumentsReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function AccDocumentsReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            selectCascadeStandard: {
                sectionsTree: [],
                level1Standards: [],
                level2Standards: [],
                level3Standards: [],
                level1StandardId: null,
                level2StandardId: null,
                level3StandardId: null,
            },
            schemeDetails: {},
            accDocuments: {
            },
            accDocumentsGrid: {
                ...GridReducer(),
                filter: {
                    ...GridFilterReducer(),
                    path:           IDS.accDocumentsGrid,
                    loc_uuid:       null,
                    standard_id:    null,
                    searched_column: 'name',
                    doc_type: 'all',
                }
            },
            accDocumentDetails: {
                document_type: 'pol', // in page add document, first item in drop down menu for document type is Policy
                filePath: null,
                docId: null,
            },
            gridFilterDocumentTypes: [
                { value: 'all', label: 'All' },
                { value: 'gdo', label: 'Generic'},
                { value: 'pol', label: 'Policy' },
                { value: 'pro', label: 'Procedure' },
                { value: 'for', label: 'Form' },
                { value: 'che', label: 'Checklist' },
            ],
            addDocumentDocumentTypes: [
                { value: 'gdo', label: 'Generic'},
                { value: 'pol', label: 'Policy' },
                { value: 'pro', label: 'Procedure' },
                { value: 'for', label: 'Form' },
                { value: 'che', label: 'Checklist' },
            ],
            searchedColumns: [
                { value: 'name', label: 'Document Name' },
                { value: 'number', label: 'Document Number' },
                { value: 'filename', label: 'Document Filename' },
            ],
        };
    }

    switch (action.type) {
        case TYPES.DOCUMENT_SAMPLE_DOC_CHANGED:
            if (action.id === IDS.accDocuments) {
                state = set({...state}, `${action.path}.templateFilename`, getDocFileNameFromPath(action.template));
                return set(state, `${action.path}.template`, action.template);
            }
        case 'LOCATION_SELECTED':
            if(action.id === IDS.accDocuments) { // select-cascade
                state = {...state};
                switch(action.path) {
                    case IDS.accDocumentDetails:
                        return set(state, `${action.path}.loc_uuid`, action.uuid);

                    case IDS.accDocumentsGrid:
                        set(state, action.path, GridReducer(state[action.path], set(action, 'type', 'PAGINATE')));
                        return set(state, `${action.path}.filter.loc_uuid`, action.uuid);
                    default:
                }
            }
        case TYPES.SECTION_DETAILS_REFRESH:
            if (action.id === IDS.accDocuments) {
                return {...state, sectionDetails: action.data};
            }
        case TYPES.SCHEME_DETAILS_REFRESH:
            if (action.id === IDS.accDocuments) {
                return {...state, schemeDetails: action.data}
            }
        case TYPES.DOC_DETAILS_REFRESH:
            return { ...state, accDocumentDetails: action.data };

        case TYPES.SELECT_CASCADE_STANDARD:
            if (action.id === IDS.accDocuments) {
                state = {...state};
                if(action.schemeCode)
                    set(state,'sectionDetails.schemeCode', action.schemeCode);
                if (action.level < 3) {
                    set(state,
                        `selectCascadeStandard.level${action.level + 1}Standards`,
                        action.standards.map(item => ({
                            value: item.id,
                            label: item.name,
                        }))
                    )
                }
                if (action.level === 1) {
                    set(state,
                        `selectCascadeStandard.level3Standards`,
                        action.level3Standards.length === 0 ? [{value:0,label:''}]: action.level3Standards.map(item => ({
                            value: item.id,
                            label: item.name,
                        }))
                    )
                }
                // remove reset value -1 which is avoiding drop down menu automatically pick first item
                if(state.selectCascadeStandard.level1StandardId === -1)
                    state = set(state, 'selectCascadeStandard.level1StandardId', null);
                if(state.selectCascadeStandard.level2StandardId === -1)
                    state = set(state, 'selectCascadeStandard.level2StandardId', null);
                if(state.selectCascadeStandard.level3StandardId === -1)
                    state = set(state, 'selectCascadeStandard.level3StandardId', null);

                return set(state, `selectCascadeStandard.level${action.level}StandardId`, action.standardId);
            }
        case TYPES.SELECT_CASCADE_STANDARD_RESET:
            if (action.id === IDS.accDocuments) {
                return {
                    ...state,
                    sectionDetails: null,
                    selectCascadeStandard: {
                        ...state.selectCascadeStandard,
                        level1StandardId: -1,
                        level2StandardId: -1,
                        level3StandardId: -1,
                    }
                }
            }
        case GRID.GRID_INIT:
        case GRID.GRID_FILTER:
        case GRID.GRID_FILTER_TOGGLE:
        case GRID.GRID_RESET_FILTER:
        case GRID.GRID_INIT_FILTER:
        case GRID.GRID_LOADING:
        case GRID.GRID_PAGINATE:
        case GRID.GRID_RELOAD:
        case GRID.GRID_FETCH:
        case GRID.GRID_SELECT:
        case GRID.GRID_SELECT_ALL:
        case GRID.GRID_DESELECT:
        case GRID.GRID_DESELECT_ALL:
            if(action.id === IDS.accDocuments) {
                state = set({...state}, action.path, GridReducer(get(state, action.path), action));
                return state;
            }
        case FORM.INPUT_SELECT:
            if (action.id === IDS.accDocuments) {
                state = {...state};
                const component = action.path.split('.')[0];
                switch(component) {
                    case IDS.accDocumentsGrid:
                        set (state, 'accDocumentDetails.documentName', action.item.label);
                        return set( state, component,
                            GridReducer(get(state, component),{
                                type:GRID.GRID_FILTER,
                                filter: {
                                    [action.path.split('.').pop()]: action.input
                                }
                            })
                        )
                }
            }
        case FORM.INPUT_TEXT:
        case FORM.INPUT_TEXT_AREA:
        case FORM.INPUT_CHECKBOX:
        case FORM.INPUT_DATE:
        case FORM.INPUT_RADIO:
            if (action.id === IDS.accDocuments) {
                state = set({...state}, action.path, action.input);
                if (action.path.startsWith('accDocumentsGrid.filter')) {
                    set(state, 'accDocumentsGrid.reload', !get(state, 'accDocumentsGrid.reload'))
                }
                return state
            }
        // case 'LOCATION_SELECTED': // slo select-cascade.js
            // if (action.id === IDS.accreditationSettingsHeadOffices) {
            //     return set({...state}, 'headOffices.selectedHeadOfficeUuid', action.uuid)
            // }
            // if(action.id === IDS.accDocumentsGrid && action.type_code === 'slo') {
            //     state = set({...state},  `${IDS.assessmentGrid}.selectedLocationUuid`, action.uuid);
            //     if(get(state, `${IDS.accDocumentsGrid}.filterOpened`))
            //         state = set(state, `${IDS.assessmentGrid}.filter.slo_uuid`, action.uuid);
            //     return set( state, IDS.accDocumentsGrid, GridReducer(get(state, IDS.accDocumentsGrid),{ type:GRID.GRID_RELOAD }))
            // }
        default:
            return state;
        }
}
