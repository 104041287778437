/* @ngInject */
export function DicomQualitycontrolTestFactory(MammoQCTesterCandidate, MammoQCEquipment) {
    return function DicomQCTest() {

        this.id = null;
        this.equipment = new MammoQCEquipment();
        this.mediumDensity = null;
        this.lowDensity = null;
        this.differenceDensity = null;
        this.testDate = null;
        //this.testerCandidate = new MammoQCTesterCandidate();

        this.getTestDate = function() {
            return moment(this.testDate).format('DD-MM-YYYY');
        };
    };
}
