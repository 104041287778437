/* @ngInject */
export function EmployeesGridSwitchController($ngRedux, $filter) {
    var ctrl = this;
    var unsubscribe = angular.noop;
    var $switch_filter = $filter

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, {})(ctrl);
        ctrl.label = $switch_filter('translate')('EMPLOYEES.LICENCES.GRID_INCLUDE');
    };

    ctrl.switchData = function() {
        $ngRedux.dispatch({
            type: 'EMPLOYEES_SWITCH',
            id: ctrl.identifier,
            licences: ctrl.licences
        });
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    function mapStateToThis(state) {
        return {
            licences: state.employees[ctrl.identifier].filter.licences,
        };
    }
}
