export const applicationsPermitsPublicState = {
    name: 'public.applicationsPermits',
    abstract: true,
    url: '/public/applications'
};

export const applicationsPermitsDashboardPublicState = {
    name: 'public.applicationsPermits.dashboard',
    url: '',
    component: 'dashboard'
};

export const apLaserPublicState = {
    name: 'public.applicationsPermits.laser',
    abstract: true,
    url: '/laser-permits'
};

export const apLaserDashboardPublicState = {
    name: 'public.applicationsPermits.laser.dashboard',
    url: '',
    component: 'dashboard'
};

export const apNuclearEnergyPublicState = {
    name: 'public.applicationsPermits.nuclearEnergy',
    abstract: true,
    url: '/nuclear-energy-permits'
};

export const apNuclearEnergyDashboardPublicState = {
    name: 'public.applicationsPermits.nuclearEnergy.dashboard',
    url: '',
    component: 'dashboard'
};
