import { LOADING } from '../../../common/navigation/navigation.actions.types';

export const manufacturers = {
    /* @ngInject */
    controller: function ($ngRedux, $uiRouterGlobals, ManufacturersActions) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(() => ({}), ManufacturersActions)(this);
            this.getManufacturerTypes();

            if ($uiRouterGlobals.params.id) {
                this.onSelect({ item: $uiRouterGlobals.params });
            }
        };

        /**
         * @function onSelect
         */
        this.onSelect = ({ item }) => {
            $ngRedux.dispatch({ type: LOADING, data: true });
            this.getManufacturerDetails(item.id).then((data) => {
                const id = data?.manufacturer?.id;
                if (id) {
                    $ngRedux.dispatch({
                        type: 'TAB_SHOW',
                        id: 'app.administration.manufacturers.manage',
                        state: [
                            'app.administration.manufacturers.manage.details',
                            'app.administration.manufacturers.manage.equipment',
                        ],
                        stateParams: { id },
                    });
                }
                $ngRedux.dispatch({ type: LOADING, data: false });
            });
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            this.unsubscribe();
            $ngRedux.dispatch({
                type: 'GRID_RESET',
                id: 'manufacturersGrid',
            });
        };
    },
    template: `
		<manufacturers-grid
			on-select="$ctrl.onSelect($event)">
		</manufacturers-grid>
	`,
};
