/**
 * @module Employees Licence
 */
import * as STATES                      from './licence.states.public';
import { empLicenceDetails }            from './details/emp-licence-details.component';

export const EMP_LICENCE_MODULE = angular.module(
    'migration.employees.licence',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values(STATES).map(state => stateRegistry.register(state));
}]).component({
    empLicenceDetails
}).run(['$templateCache', ({ put }) => {
    put('public.employees.licence.details', '<emp-licence-details></emp-licence-details>');
}]);
