export default function CountryStateActions(LocationService) {
    function getCountries() {
        return dispatch => {
            LocationService.getCountries().then(response => {
                dispatch({
                    type: 'COUNTRIES_FETCH',
                    id: 'countries',
                    items: response.data
                });
            });
        }
    }

    function getCountryStates(identifier, path, countryId) {
        return dispatch => {
            LocationService.getStates(countryId).then(response => {
                dispatch({
                    type: 'COUNTRY_STATES_FETCH',
                    id: identifier,
                    path: path,
                    items: response.data
                });
            });
        }
    }

    return {
        getCountries,
        getCountryStates
    }
}

CountryStateActions.$inject = ['LocationService'];
