/* @ngInject */
export function HelpController($log, NavigationService) {
    var ctrl = this;

    ctrl.$onInit = function() {
        $log.debug('Help::init');
        var navConfig = NavigationService.getNavConfig();
        ctrl.colorName = navConfig.colorName;
        ctrl.icon = navConfig.getIcon();
    };
}
