/* @ngInject */
export const EquipmentModelFactory = (Manufacturer) => {
    return function EquipmentModel() {
        this.id = null;
        this.type = null;
        this.name = null;
        this.description = null;
        this.manufacturer = new Manufacturer();
    };
};
