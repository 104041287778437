/*
*   List of Action IDs that should be passed from accreditation.reducer.js to audit.reducer.js
 */

export const IDS = {
    // module
    accAudit: 'accAudit',

    //paths
    addAudit: 'addAudit',

    // pages:
    app_accreditation_scheme_audit_add:                 'app.accreditation.scheme.audit.add',
    app_accreditation_scheme_audit_schedule:            'app.accreditation.scheme.audit.schedule',

    // tabs:
    app_accreditation_scheme_audit_schedule_table:      'app.accreditation.scheme.audit.schedule.table',
    app_accreditation_scheme_audit_schedule_details:    'app.accreditation.scheme.audit.schedule.details',
};
