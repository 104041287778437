import InputController from '../input.controller';
import { INPUT_CHECKBOX_LIST } from '../input.constants';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';

/**
 * Creates a new InputCheckboxListController.
 * @class
 * @extends module:root/common/form.InputController
 * @memberOf module:root/common/form
 * @see {@link https://docs.angularjs.org/guide/component|AngularJS Component}
 */
class InputCheckboxListController extends InputController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     */
    constructor($ngRedux, $filter) {
        super($ngRedux);
        this.$filter = $filter;
    }

    $onInit() {
        if(isUndefined(this.options))
            this.options = [];

        if(isUndefined(this.columnsCount))
            this.columnsCount = 1;

        if(isUndefined(this.trueValue))
            this.trueValue = 1;

        if(isUndefined(this.falseValue))
            this.falseValue = 0;

        if(isUndefined(this.propertyName))
            this.propertyName = 'selected';

        this.unsubscribeData = this.$ngRedux.connect(this.mapData.bind(this), {})(this);
        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
    }

    /**
     * @method onInputChange
     * @memberOf module:root/common/form.InputCheckboxListController
     */
    onInputChange(item) {
        const data = [].concat(...this.columns)
        this.$ngRedux.dispatch({
            type: INPUT_CHECKBOX_LIST,
            id: this.identifier,
            path: this.statePath,
            item: item,
            data: this.statePath ? {[this.statePath]: data} : data
        });

        if (this.onChange) {
            this.onChange({
                $event: {input: this.input}
            });
        }
    }

    /**
     * @method mapData
     * @param state {object} Redux Store
     * @return {object}
     * @memberof module:root/common/form.InputCheckboxListController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapData(state) {
        if(this.identifier) {
            const items = this.getDataValue(state);
            if (items.length) {
                const columnLength = Math.ceil(items.length / this.columnsCount);
                const columns = [];
                while (items.length)
                    columns.push(items.splice(0, columnLength));
                return {columns}
            }
        }
        return {}
    }
    /**
     * @method mapModule
     * @param state {object} Redux Store
     * @memberof module:root/common/form.InputCheckboxListController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapModule(state) {
        return {
            module: state.navigation.module,
        }
    }

    /**
     * @method getDataValue
     * @param state {object} Redux Store
     * @return {*}
     * @memberof module:root/common/form.InputCheckboxListController
     */
    getDataValue(state) {
        const path = [this.module, this.identifier];
        if(this.statePath)
            path.push(this.statePath)
        let dataValue = get(state, path.join('.'));
        if(dataValue && dataValue.length) {
            if (this.translateOptions === 'true') {
                dataValue = dataValue.map(item => {
                    return {
                        ...item,
                        name: this.$filter('translate')(item.name)
                    }
                });
            }
            return dataValue.concat();
        }
        return [];
    }

    /**
     * @method $onDestroy
     * @memberof module:root/common/form.InputCheckboxListController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.unsubscribeData();
        this.unsubscribeModule();
        this.destroy();
    }
}

InputCheckboxListController.$inject = ['$ngRedux', '$filter'];

export const inputCheckboxList = {
    bindings: {
        options: '<',
        columnsCount: '<',
        identifier: '@',
        statePath: '@',
        disabled: '@',
        propertyName: '@',
        trueValue: '@',
        falseValue: '@',
        translateOptions: '@',
        onChange: '&'
    },
    require : {
        /**
         * @member {*} module:root/common/form.InputCheckboxListController#form
         * @see {@link https://docs.angularjs.org/api/ng/type/form.FormController|FormController}
         */
        form : '^form'
    },
    controller: InputCheckboxListController,
    template: `
        <md-input-container class="md-block checkbox">
            <div layout-gt-sm="row">
                <md-list layout-padding ng-repeat="column in $ctrl.columns" flex style="margin-left: 20px; margin-right: 20px">
                    <md-list-item class="checkbox" ng-repeat="item in column track by item.id">
                        <span>{{item.name}}</span>
                        <md-checkbox 
                            class="md-secondary"
                            ng-model="item[$ctrl.propertyName]"
                            ng-change="$ctrl.onInputChange(item)"
                            ng-true-value="{{$ctrl.trueValue}}"
                            ng-false-value="{{$ctrl.falseValue}}"
                            aria-label="{{::item.name}}"
                            ng-disabled="$ctrl.disabled">
                        </md-checkbox>
                    </md-list-item>
                </md-list>
            </div>
        </md-input-container>
    `
};
