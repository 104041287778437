import { DATE_FORMAT } from '../../../../common/utils/util.constants';
import { formatDate } from '../../../../common/utils/util.date';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

/**
 * Creates a new EquipmentCalibrationLoanHistoryController.
 * @class
 */
class EquipmentCalibrationLoanHistoryController {
    constructor($ngRedux, $state, $mdDialog, NavigationService, EquipmentCalibrationLoanActions, Notifier) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.NavigationService = NavigationService;
        this.actions = EquipmentCalibrationLoanActions;
        this.Notifier = Notifier;
        this.dialog = null;
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);

        this.identifier = 'app.equipmentCalibration.manage';
        this.state = 'app.equipmentCalibration.manage.loanHistory';
    }

    onSelect(event) {
        const loanReceiptCtrl = ($scope) => {
            $scope.onGetReceiptPersons = async (evt) => {
                const location = find(this.equipment.locations, ['type_code', 'hof']);
                return await this.getReceiptPersons(evt, location.sub_uuid);
            };
            $scope.onNewReceiptPerson = (evt) => {
                $scope.receiptByDisable = !!evt.input;
                $scope.receiptPersonNew = evt.input;
                this.$ngRedux.dispatch({
                    type: 'EQUIPMENT_LOAN_RECEIPT_PERSON_NEW',
                    id: 'equipmentLoan',
                    new: evt.input
                });
            };
            $scope.save = () => {
                if ($scope.equipmentLoanHistoryForm.$valid) {
                    this.$ngRedux.dispatch({
                        type: 'EQUIPMENT_LOAN_HISTORY_SAVE',
                        id: 'equipmentLoan'
                    });
                    const { receipted_by } = this.loanHistory;
                    this.submitLoanReceipt({
                        eql_id: this.loanHistory.id,
                        receipted_emp_uuid: receipted_by ? receipted_by.subject_uuid : null,
                        return_date: formatDate(this.loanHistory.return_date, DATE_FORMAT.OUTPUT),
                        receipt_first_name: this.loanHistory.receipt_first_name,
                        receipt_last_name: this.loanHistory.receipt_last_name,
                        receipt_email: this.loanHistory.receipt_email,
                        receipt_phone: this.loanHistory.receipt_phone,
                        receipt_campus: this.loanHistory.receipt_campus,
                        receipt_department: this.loanHistory.receipt_department,
                        equipment_condition: this.loanHistory.equipment_condition
                    }).then(() => {
                        this.getLoanDetails(this.equipment.id);
                        this.Notifier.success('Loan receipt has been successfully submitted');
                        this.$mdDialog.hide();
                    });
                } else {
                    this.Notifier.error('Please fill in the form fields');
                }
            };
            $scope.cancel = () => {
                this.$mdDialog.cancel();
            };
        };
        loanReceiptCtrl.$inject = ['$scope'];

        this.dialog = this.$mdDialog.show({
            controller: loanReceiptCtrl,
            template: require('./dialogs/equipment-calibration-loan-history-dialog.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            onShowing: (scope) => {
                this.getLoanHistoryDetails(event.item.eql_id).then(res => {
                    if (res.receipted_by) {
                        scope.receiptPersonNew = 0;
                    } else {
                        if (isEmpty(res.receipted_by_first_name) && isEmpty(res.receipted_by_last_name)) {
                            scope.receiptPersonNew = 0;
                        } else {
                            scope.receiptPersonNew = 1;
                        }
                    }
                    scope.receiptByDisable = !!scope.receiptPersonNew;
                });
            }
        }).then(this.resetLoanHistory, this.resetLoanHistory);
    }

    mapState(state) {
        return {
            equipment: state.equipmentCalibration.equipment,
            loan: state.equipmentCalibration.equipmentLoan,
            loanHistory: state.equipmentCalibration.equipmentLoanHistory
        };
    }

    $onDestroy() {
        this.unsubscribe();
        if (this.dialog)
            this.$mdDialog.hide(this.dialog);
    }
}

EquipmentCalibrationLoanHistoryController.$inject = ['$ngRedux', '$state', '$mdDialog', 'NavigationService', 'EquipmentCalibrationLoanActions', 'Notifier'];

export const equipmentCalibrationLoanHistory = {
    controller: EquipmentCalibrationLoanHistoryController,
    template: `
        <equipment-calibration-loan-history-grid
            equipment-id="$ctrl.equipment.id"
            on-select="$ctrl.onSelect($event)">
        </equipment-calibration-loan-history-grid>
        <tabs-navigation
            identifier="{{$ctrl.identifier}}"
            state="{{$ctrl.state}}">
        </tabs-navigation>
    `
};
