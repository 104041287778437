/* @ngInject */
export function EquipmentController($log, NavigationService) {
    var ctrl = this;

    ctrl.$onInit = function() {
        $log.debug('ProtectiveEquipment::init');
        var navConfig = NavigationService.getNavConfig();
        ctrl.colorName = navConfig.colorName;
        ctrl.icon = navConfig.icon;
    };
}
