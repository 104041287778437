import { addAppendix, removeAppendix } from '../../../administration.actions';

/**
 * @const {object}
 * @memberOf module:Administration
 */
export const administrationAccountsSiteLocationLicenceAppendix = {
    /* @ngInject */
    controller: function ($ngRedux, $scope, $mdDialog, $uiRouterGlobals, Notifier) {
        this.unsubscribe = $ngRedux.connect(({ administration }) => ({
            administrationAccountsLicenceAppendix: administration.administrationAccountsLicenceAppendix,
            administrationAccountsLicenceAppendixGrid: administration.administrationAccountsLicenceAppendixGrid,
            stateParams: $uiRouterGlobals.params
        }), {
            addAppendix,
            removeAppendix
        })(this);

        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.identifier = 'app.administration.accounts.siteLocations';
            this.state = 'app.administration.accounts.siteLocations.licenceAppendix';
        }

        this.addLicenceAppendix = () => {
            $scope.$broadcast('show-errors-check-validity', '$ctrl.addAppendixForm');
            if (this.addAppendixForm.$valid) {
                this.addAppendix(this.administrationAccountsLicenceAppendix, this.stateParams.lic).then(() => {
                    Notifier.success('New appendix has been successfully added to licence');
                    this.addAppendixForm.$setPristine();
                    this.addAppendixForm.$setUntouched();
                }).catch((error) => {
                    Notifier.error(error.data);
                });
            } else {
                Notifier.error('Please fill in the Appendix Name');
            }
        }

        this.onRemove = ({ item }) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen appendix will be removed permanently from this license.')
                .ariaLabel('Remove appendix')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.removeAppendix(item.apx_id);
            }, angular.noop);
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./administration-accounts-site-location-licence-appendix.html')
};
