/* @ngInject */
export function AccreditationDocumentsService($http, API_BASE, Helpers,
                                       $q, TrainingMatrix,
                                       AccDocument, SafetyPlanService, Notifier, AccAuditScheduleItem,
                                       AccAuditCheckList, AccAuditCheckListItem, AccQualityImprovementItem,
                                       AccComplaintFeedbackItem, DATE_TYPE, $rootScope, $mdDialog, AccOptimizedChartDocument, $filter) {

    var _this = this;

    $rootScope.confirmationDialog = function confirmationDialog(message, okLabel, cancelLabel) {
        var textContent = "";
        var okText = "";
        var cancelText = "";

        if (typeof message === 'string')
            textContent = message;
        else
            textContent = $filter('translate')('ACCREDITATION.SERVICES.CONFIRM_MSG');

        if (typeof okLabel === 'string')
            okText = okLabel;
        else
            okText = $filter('translate')('ACCREDITATION.SERVICES.CONFIRM_OK');

        if (typeof cancelLabel === 'string')
            cancelText = cancelLabel;
        else
            cancelText = $filter('translate')('ACCREDITATION.SERVICES.CONFIRM_CANCEL');

        return $mdDialog.show(
            $mdDialog.confirm()
                .parent(angular.element(document.querySelector('body')))
                .clickOutsideToClose(false)
                .title($filter('translate')('ACCREDITATION.SERVICES.CONFIRM_CONFIRMATION'))
                .textContent(textContent)
                .ariaLabel($filter('translate')('ACCREDITATION.SERVICES.CONFIRM_CONFIRMATION'))
                .ok(okText)
                .cancel(cancelText)
        );
    };

    /** Retrieve all training matrices for one site location
     *
     * Method   - GET
     * Endpoint - /accreditation/<accreditation-code>/qcdocs-training-matrices
     * Params   -
     *      ** accreditation shema code (string)
     *      ** uuid of site location (string)
     * Response - Object (parsed JSON) with array of training matrices relevant for this site location
     *
     * */
    this.fetchAccQCDocsTrainingMatrices = function (accCode, siteLocationUuid) {
        var params = [];
        params['siteLocationUuid'] = siteLocationUuid;

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/qcdocs-training-matrices",
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                var arr = [];
                response.data.list.forEach(function (elem) {
                    var item = new TrainingMatrix();
                    item.id = elem.trm_id;
                    item.name = elem.name;
                    item.description = elem.description;
                    arr.push(item);
                });

                if (arr.length > 0) {
                    response.data.result = arr;
                } else {
                    response.data.result = null;
                }

                return response;
            },
            function errorCallback(errorResponse) {
                return $q.reject(errorResponse);
            });
    };

    /** Retrieve all accreditation documents for one training matrix
     *
     * Method   - GET
     * Endpoint - /accreditation/<accreditation-code>/qcdocs-trm-acc-documents
     * Params   -
     *      ** accreditation shema code (string)
     *      ** uuid of site location (string)
     *      ** training matrix id (int)
     * Response - Object (parsed JSON) with array of documents for one training matrix
     *
     * */
    this.fetchAccQCTrainingMatrixDocuments = function (accCode, siteLocationUuid, trainingMatrixId) {
        var params = [];
        params['siteLocationUuid'] = siteLocationUuid;
        params['trainingMatrixId'] = trainingMatrixId;


        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/qcdocs-trm-acc-documents",
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                var arr = [];
                response.data.list.forEach(function (elem) {
                    var item = new AccDocument();
                    item.id = elem.ado_id;
                    item.locationUuid = elem.sloc_uuid;
                    item.docDcrNumber = elem.doc_dcr_no;
                    item.title = elem.title;
                    item.version = elem.version;
                    item.writtenBy = elem.written_by;
                    if (elem.date_edited != null) {
                        item.dateEdited = Helpers.parseFromDbDate(elem.date_edited, DATE_TYPE.DATE);
                        item.dateEditedStr = Helpers.parseFromDbDate(elem.date_edited);
                    }
                    item.approvedBy = elem.approved_by;
                    if (elem.date_approved != null) {
                        item.dateApproved = Helpers.parseFromDbDate(elem.date_approved, DATE_TYPE.DATE);
                        item.dateApprovedStr = Helpers.parseFromDbDate(elem.date_approved);
                    }
                    item.trainingDocumentId = elem.rdo_id;
                    item.template = elem.template;
                    item.docVariant = elem.doc_variant;

                    arr.push(item);
                });

                if (arr.length > 0) {
                    response.data.result = arr;
                } else {
                    response.data.result = null;
                }

                return response;
            },
            function errorCallback(errorResponse) {
                return $q.reject(errorResponse);
            });
    };





    /***********************************************************/
    /************ GENERATE TRAINING MATRIX FUNCTIONS ***********/
    /***********************************************************/


    /** Retrieve all training matrices for one site location
     *
     * Method   - GET
     * Endpoint - /accreditation/<accreditation-code>/qcdocs-training-matrices
     * Params   -
     *      ** selectedYear(int) - year of training matrix
     *      ** uuid of site location (string)
     *      ** trainingMatrix - currently not used
     * Response - object with status and path of newly generated rspp document
     *
     * */
    this.fetchAccQCDocsRsppTrainingMatrix = function (selectedYear, siteLocationUuid, trainingMatrix) {
        return SafetyPlanService.getTrainingMatrix(selectedYear, siteLocationUuid, true)
            .then(function (data) {
                return data;
            }, function (error) {
                return $q.reject(error);
            });
    };


    /**
     * Method   - POST
     * Endpoint - /accreditation/acc-document
     * Params   -
     *      ** uuid of site location (string)
     *      ** accreditation document
     * Response - object with status and uploaded document dcr number
     *
     * */
    this.saveAccreditationDocument = function (siteLocationUuid, accreditationDocument, standardId) {
        var ldata = {
            siteLocationUuid: siteLocationUuid,
            title: accreditationDocument.title,
            version: accreditationDocument.version,
            writtenBy: accreditationDocument.writtenBy,
            dateEdited: accreditationDocument.dateEdited != null ?
                Helpers.formatDate(accreditationDocument.dateEdited) : null,
            approvedBy: accreditationDocument.approvedBy,
            dateApproved: accreditationDocument.dateApproved != null ?
                Helpers.formatDate(accreditationDocument.dateApproved) : null,
            validUntil: accreditationDocument.validUntil != null ?
                Helpers.formatDate(accreditationDocument.validUntil) : null,
            docPath: accreditationDocument.docPath,
            docNumber: accreditationDocument.docNumber,
            comment: accreditationDocument.comment,
            misc: JSON.stringify(accreditationDocument.misc),
            trainingType: accreditationDocument.trainingType,
            template: accreditationDocument.template,
            standardId: standardId,
            isGenerated: accreditationDocument.isGenerated
        };

        return $http({
            url: API_BASE + "/accreditation/acc-document",
            data: ldata,
            method: "POST"
        }).then(function(data) {
            return data;
        },function errorCallback(errorResponse) {
            Notifier.responseMessage(errorResponse.status);
            return $q.reject(errorResponse);
        });;
    };



    /** Retrieve all accreditation documents for one training matrix
     *
     * Method   - GET
     * Endpoint - /accreditation/<accreditation-code>/qcdocs-master-list-acc-documents
     * Params   -
     *      ** accreditation shema code (string)
     *      ** uuid of site location (string)
     *      ** filtering options (json)
     * Response - Object (parsed JSON) with array of master document list documents
     *
     * */
    this.fetchAccQCMasterListDocuments = function (accCode, siteLocationUuid, filterOptions, isPdf) {
        var params = [];
        params['siteLocationUuid'] = siteLocationUuid;

        var filter = {};
        if (filterOptions.documentName != null) {
            filter.documentName = filterOptions.documentName;
        }
        if (filterOptions.author != null) {
            filter.author = filterOptions.author;
        }
        if (filterOptions.approvedBy != null) {
            filter.approvedBy = filterOptions.approvedBy;
        }
        if (filterOptions.expireAfter != null) {
            filter.expireAfter = Helpers.formatDate(filterOptions.expireAfter);
        }

        params['filterOptions'] = filter;

        if (isPdf) {
            params['format'] = "pdfold";
        }

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/qcdocs-master-list-acc-documents",
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                if (!isPdf) {

                    var arr = [];
                    response.data.list.forEach(function (elem) {
                        var item = new AccDocument();
                        item.id = elem.ado_id;
                        item.locationUuid = elem.sloc_uuid;
                        item.docDcrNumber = elem.doc_dcr_no;
                        item.docNumber = elem.doc_no;
                        item.title = elem.title;
                        item.version = elem.version;
                        item.writtenBy = elem.written_by;
                        if (elem.date_edited != null) {
                            item.dateEdited = Helpers.parseFromDbDate(elem.date_edited, DATE_TYPE.DATE);
                            item.dateEditedStr = Helpers.parseFromDbDate(elem.date_edited, DATE_TYPE.STRING);
                        }
                        item.approvedBy = elem.approved_by;
                        if (elem.date_approved != null) {
                            item.dateApproved = Helpers.parseFromDbDate(elem.date_approved, DATE_TYPE.DATE);
                            item.dateApprovedStr = Helpers.parseFromDbDate(elem.date_approved);
                        }
                        if (elem.valid_until != null) {
                            item.validUntil = Helpers.parseFromDbDate(elem.valid_until, DATE_TYPE.DATE);
                            item.validUntilStr = Helpers.parseFromDbDate(elem.valid_until);
                        }
                        item.trainingDocumentId = elem.rdo_id;
                        item.docPath = elem.doc_path;
                        if (elem.misc != null) {
                            item.misc = JSON.parse(elem.misc);
                        }

                        arr.push(item);
                    });

                    if (arr.length > 0) {
                        response.data.result = arr;
                    } else {
                        response.data.result = null;
                    }
                } else {
                    response.data.result = response.data.path;
                }

                return response;
            },
            function errorCallback(errorResponse) {
                return $q.reject(errorResponse);
            });
    };



    /** Retrieve all accreditation documents trainig designators
     *
     * Method   - GET
     * Endpoint - /accreditation/acc-documents-training-types
     * Params   -
     *      ** accreditation shema code (string)
     *      ** filtering options (json)
     * Response - Object (parsed JSON) with array of documents for one training matrix
     *
     * */
    this.fetchAccDocumentsTrainingTypes = function (siteLocationUuid, standardId) {
        var params = [];
        params['siteLocationUuid'] = siteLocationUuid;
        params['standardId'] = standardId;

        return $http({
            url: API_BASE + "/accreditation/training-document-types",
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

            var arr = [];
            response.data.forEach(function (elem) {
                var item = {};
                item.id = elem.tdt_id;
                item.description = elem.description;
                arr.push(item);
            });

            if (arr.length > 0) {
                response.data.result = arr;
            } else {
                response.data.result = null;
            }

            return response;
        },
        function errorCallback(errorResponse) {
            return errorResponse;
        });
    };



    /**
     * Method   - PUT
     * Endpoint - /accreditation/edit-acc-document/{id}
     * Params   -
     *      ** uuid of site location (string)
     *      ** documentId of edited document(int)
     *      ** data object with fields that should ne edited on the document
     * Response - object with status and uploaded document dcr number
     *
     * */
    this.editAccreditationDocument = function (siteLocationUuid, documentId, data) {
        var newDocumentValues = {}

        if (data.approvedBy != null) {
            newDocumentValues.approvedBy = data.approvedBy;
        }
        if (data.dateApproved != null) {
            newDocumentValues.dateApproved = Helpers.formatDate(data.dateApproved);
        }
        if (data.misc != null) {
            newDocumentValues.misc = data.misc;
        }


        var ldata = {
            siteLocationUuid: siteLocationUuid,
            documentId: documentId,
            newDocumentData: JSON.stringify(newDocumentValues)
        };

        return $http({
            url: API_BASE + "/accreditation/edit-acc-document" + "/" + ldata.documentId,
            data: ldata,
            method: "PUT"
        }).then(function successCallback(response) {

                return response;
            },
            function errorCallback(errorResponse) {
                return errorResponse;
            });
    };


    /**
     * Method   - PUT
     * Endpoint - /accreditation/edit-acc-document-approval/{id}
     * Params   -
     *      ** accDocumentId - accreditaiton document id
     *      ** newStatus, of type ACC_ASSESSMENT_ITEM_APPROVAL_STATUS
     *      ** newStatusComment, explaining new document approval decision
     *
     * Response - object with status of operation
     * */
    this.editAccDocumentApprovalStatus = function (accDocumentId, newStatus, newStatusComment) {
        var ldata = {
            documentId: accDocumentId,
            status: newStatus,
            statusComment: newStatusComment
        };

        return $http({
            url: API_BASE + "/accreditation/acc-document-approval/" + ldata.documentId,
            data: ldata,
            method: "PUT"
        }).then(function successCallback(response) {
                return response;
            },
            function errorCallback(errorResponse) {
                return errorResponse;
            });
    };


    /** Fetch document approval status
     *
     * Method   - GET
     * Endpoint - /accreditation/fetch-acc-document-approval/{id}
     * Params   -
     *       ** accDocumentId - accreditation document id
     * Response - Object with result about status of approval of document
     *
     * */
    this.fetchAccDocumentApprovalStatus = function (accDocumentId) {
        var params = [];

        return $http({
            url: API_BASE + "/accreditation/acc-document-approval/"+accDocumentId,
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                response.data.result = {
                    documentId: response.data[0].id,
                    status: response.data[0].status,
                    statusComment: response.data[0].status_comment,
                };

                return response;
            },
            function errorCallback(errorResponse) {
                return errorResponse;
            });
    };


    /** Fetch document approval status
     *
     * Method   - GET
     * Endpoint - /accreditation/{accCode}/acc-document/generate/{documentType}
     * {documentType} is actually
     * Params   -
     *        ** uuid of site location (string)
     *        ** type of document that should be generated (string)
     *        ** export format, from global constant EXPORT_FORMATS
     * Response - Generated document
     *
     * */
    this.generateAccDocument = function(accCode, siteLocationUuid, assItemDocType, exportFormat, year) {
        var params = [];
        params['siteLocationUuid'] = siteLocationUuid;
        params['year'] = year;

        if (exportFormat == 'pdf') {
            params['format'] = "pdf";
        }

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-document/generate/"+assItemDocType,
            params: params,
            method: "GET"
        }).then(function successCallback(response) {
            response.data.result = response.data.path;
            return response;
        },
        function errorCallback(errorResponse) {
            return errorResponse;
        });
    };


    /** Fetch audit shedule items for site location
     *
     * Method   - GET
     * Endpoint - /accreditation/{acc_code}/acc-audit-review-shedule-items/{site_location}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** siteLocationUuid - accreditation document id
     * Response - Object with result about status of approval of document
     *
     * */
    this.fetchAccAuditScheduleItems = function (accCode, siteLocationUuid) {
        var params = [];

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-audit-review-shedule-items/" + siteLocationUuid,
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                var arr = [];
                response.data.list.forEach(function (elem) {
                    var item = new AccAuditScheduleItem();
                    item.id = elem.ash_id;
                    item.name = elem.name;
                    item.standard = elem.standard;
                    item.description = elem.description;
                    arr.push(item);
                });

                if (arr.length > 0) {
                    response.data.result = arr;
                } else {
                    response.data.result = null;
                }

                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };

    /** Fetch internal audit shedule for site location
     *
     * Method   - GET
     * Endpoint - /accreditation/{acc_code}/acc-audit-review-shedule/{site_location}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** siteLocationUuid - accreditation document id
     *       ** year - year for which shedule is requested
     * Response - Object with result about status of approval of document
     *
     * */
    this.fetchAccAuditSchedule = function (accCode, siteLocationUuid, year) {
        var params = [];
        params['year'] = year;

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-audit-review-shedule/" + siteLocationUuid,
            params: params,
            method: "GET"
        }).then(function successCallback(response) {
                if (response.data.list.length > 0) {
                    response.data.result = JSON.parse(response.data.list[0].schedule);
                } else {
                    response.data.result = null;
                }

                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };



    /**
     * Method   - PUT
     * Endpoint - /accreditation/{acc_code}/acc-audit-review-shedule/{site_location}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** siteLocationUuid - accreditation document id
     *       ** year - year for which shedule is requested
     *       ** schedule - schedule
     *       ** changedFields - array of changed fields, for system notification purposes
     *
     * Response - object with status of operation
     * */
    this.editAccAuditSchedule = function (accCode, siteLocationUuid, year, schedule, changedFields) {
        var ldata = {
            year: year,
            schedule: JSON.stringify(schedule),
            changedFields: JSON.stringify(changedFields)
        };

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-audit-review-shedule/" + siteLocationUuid,
            data: ldata,
            method: "PUT"
        }).then(function successCallback(response) {
                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };




    /** Fetch list of audit checklists
     *
     * Method   - GET
     * Endpoint - /accreditation/{acc_code}/acc-audit-checklists/{site_location}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** siteLocationUuid - accreditation document id
     *       ** year - for which year site location checklists should be fetched (year is used for progress calculation)
     * Response - Object with result about status of approval of document
     *
     * */
    this.fetchAccAuditCheckLists = function (accCode, siteLocationUuid, year) {
        var params = [];
        params['year'] = year;

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-audit-checklists/" + siteLocationUuid,
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                var arr = [];
                response.data.list.forEach(function (elem) {
                    var item = new AccAuditCheckList();
                    item.id = elem.aac_id;
                    item.standard = elem.standard;
                    item.progress = +elem.progress;

                    //Do a progress interpretation
                    if (elem.progress>=0 && elem.progress<=100)
                        item.progressStr = elem.progress + "%";
                    else if (elem.progress == -5)
                        item.progressStr = $filter('translate')('ACCREDITATION.SERVICES.NOT_APPLICABLE');
                    else if (elem.progress == -4)
                        item.progressStr = $filter('translate')('ACCREDITATION.SERVICES.NOT_SCHEDULED');
                    else if (elem.progress == -3)
                        item.progressStr = $filter('translate')('ACCREDITATION.SERVICES.OVERDUE');
                    else if (elem.progress == -2)
                        item.progressStr = $filter('translate')('ACCREDITATION.SERVICES.SCHEDULED');
                    else if (elem.progress == -1)
                        item.progressStr = $filter('translate')('ACCREDITATION.SERVICES.IN_PROGRESS');

                    arr.push(item);
                });

                if (arr.length > 0) {
                    response.data.result = arr;
                } else {
                    response.data.result = null;
                }

                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };


    /** Fetch list of audit checklists
     *
     * Method   - GET
     * Endpoint - /accreditation/{acc_code}/acc-audit-checklist-items/{site_location}/{checklist}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** siteLocationUuid - accreditation document id
     * Response - Object with result about status of approval of document
     *
     * */
    this.fetchAccAuditCheckListItems = function (accCode, siteLocationUuid, checklistId) {
        var params = [];

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-audit-checklist-items/" + siteLocationUuid+"/"+checklistId,
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                var arr = [];
                response.data.list.forEach(function (elem) {
                    var item = new AccAuditCheckListItem();
                    item.id = elem.aaci_id;
                    item.checklistId = elem.aac_id;
                    item.request = elem.request;
                    item.relatedStandards = elem.related_standards;
                    item.parentItem = elem.parent;
                    item.hasValue = elem.has_value == 1? true: false;
                    arr.push(item);
                });

                if (arr.length > 0) {
                    response.data.result = arr;
                } else {
                    response.data.result = null;
                }

                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };





    /**
     * Method   - PUT
     * Endpoint - /accreditation/{acc_code}/acc-audit-checklist-result/{siteLocationUuid}/{checklistId}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** siteLocationUuid - accreditation document id
     *       ** checklistId - indentifier of the checklist\
     *       ** evidenceRequestArray - array of checklist item ID for which evidence request is required
     *
     * Response - object with status of operation
     * */
    this.editAccAuditChecklistResult = function (accCode, siteLocationUuid, year, checklistId, checklistValue, evidenceRequestArray) {
        var ldata = {
            year: year,
            checklistValue: JSON.stringify(checklistValue),
            evidenceRequest: evidenceRequestArray
        };

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-audit-checklist-result/" + siteLocationUuid+"/"+checklistId,
            data: ldata,
            method: "PUT"
        }).then(function successCallback(response) {
                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };



    /** Fetch internal audit checklist result for one site location
     *
     * Method   - GET
     * Endpoint - /accreditation/{acc_code}/acc-audit-checklist-result/{siteLocationUuid}/{checklistId}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** siteLocationUuid - accreditation document id
     *       ** year - year for which shedule is requested
     *       ** checklistId - indentifier of the checklist
     * Response - Object with result about status of approval of document
     *
     * */
    this.fetchAccAuditChecklistResult = function (accCode, siteLocationUuid, year, checklistId, exportFormat) {
        var params = [];
        params['year'] = year;

        if (exportFormat == 'pdf') {
            params['format'] = "pdfphp";
        }

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-audit-checklist-result/" + siteLocationUuid + "/" + checklistId,
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                if (exportFormat == 'pdf') {
                    //return file path
                    response.data.result = response.data.path;
                } else {
                    //return json
                    if (response.data.list.length > 0) {
                        response.data.result = JSON.parse(response.data.list[0].value);
                    } else {
                        response.data.result = null;
                    }
                }

                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };

    /** Retrieve all accreditation reference documents
     *
     * Method   - GET
     * Endpoint - /accreditation/<accreditation-code>/reference-doc-list
     * Params   -
     *      ** accreditation shema code (string)
     *      ** uuid of site location (string)
     *      ** filtering options (json)
     * Response - Object (parsed JSON) with array of reference document list
     *
     * */
    this.fetchAccReferenceDocumentList = function (accCode, siteLocationUuid, filterOptions) {
        var params = [];
        params['siteLocationUuid'] = siteLocationUuid;

        var filter = {};
        if (filterOptions.documentName != null) {
            filter.documentName = filterOptions.documentName;
        }
        if (filterOptions.author != null) {
            filter.author = filterOptions.author;
        }
        if (filterOptions.approvedBy != null) {
            filter.approvedBy = filterOptions.approvedBy;
        }
        if (filterOptions.expireAfter != null) {
            filter.expireAfter = Helpers.formatDate(filterOptions.expireAfter);
        }

        params['filterOptions'] = filter;

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/reference-doc-list",
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                var arr = [];
                response.data.list.forEach(function (elem) {
                    var item = new AccDocument();
                    item.id = elem.ado_id;
                    item.locationUuid = elem.sloc_uuid;
                    item.docDcrNumber = elem.doc_dcr_no;
                    item.docNumber = elem.doc_no;
                    item.title = elem.title;
                    item.version = elem.version;
                    item.writtenBy = elem.written_by;
                    if (elem.date_edited != null) {
                        item.dateEdited = Helpers.parseFromDbDate(elem.date_edited, DATE_TYPE.DATE);
                        item.dateEditedStr = Helpers.parseFromDbDate(elem.date_edited, DATE_TYPE.STRING);
                    }
                    item.approvedBy = elem.approved_by;
                    if (elem.date_approved != null) {
                        item.dateApproved = Helpers.parseFromDbDate(elem.date_approved, DATE_TYPE.DATE);
                        item.dateApprovedStr = Helpers.parseFromDbDate(elem.date_approved);
                    }
                    if (elem.valid_until != null) {
                        item.validUntil = Helpers.parseFromDbDate(elem.valid_until, DATE_TYPE.DATE);
                        item.validUntilStr = Helpers.parseFromDbDate(elem.valid_until);
                    }
                    item.trainingDocumentId = elem.rdo_id;
                    item.docPath = elem.doc_path;
                    if (elem.misc != null) {
                        item.misc = JSON.parse(elem.misc);
                    }

                    arr.push(item);
                });

                if (arr.length > 0) {
                    response.data.result = arr;
                } else {
                    response.data.result = null;
                }

                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };



    /** Fetch internal audit report
     *
     * Method   - GET
     * Endpoint - /accreditation/{acc_code}/acc-audit-report/{siteLocationUuid}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** siteLocationUuid - accreditation document id
     *       ** year - year for which shedule is requested
     * Response - Object with result, document path
     *
     * */
    this.fetchAccAuditReport = function (accCode, siteLocationUuid, year, exportFormat) {
        var params = [];
        params['year'] = year;

        if (exportFormat == 'pdf') {
            params['format'] = "pdfphp";
        }

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-audit-report/" + siteLocationUuid,
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                if (exportFormat == 'pdf') {
                    //return file path
                    response.data.result = response.data.path;
                } else {
                    //return json
                    if (response.data.list.length > 0) {
                        response.data.result = JSON.parse(response.data.list[0].value);
                    } else {
                        response.data.result = null;
                    }
                }

                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };

    /** Fetch internal audit file upload report
     *
     * Method   - GET
     * Endpoint - /accreditation/{acc_code}/acc-audit-file-upload-report/{siteLocationUuid}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** siteLocationUuid - accreditation document id
     *       ** year - year for which shedule is requested
     * Response - Object with result, document path
     *
     * */
    this.fetchAccAuditFileUploadReport = function (accCode, siteLocationUuid, year, exportFormat) {
        var params = [];
        params['year'] = year;

        if (exportFormat == 'pdf') {
            params['format'] = "pdfphp";
        }

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-audit-file-upload-report/" + siteLocationUuid,
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                if (exportFormat == 'pdf') {
                    //return file path
                    response.data.result = response.data.path;
                } else {
                    //return json
                    if (response.data.list.length > 0) {
                        response.data.result = JSON.parse(response.data.list[0].value);
                    } else {
                        response.data.result = null;
                    }
                }

                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };

    /**
    * Method   - PUT
    * Endpoint - /accreditation/{accCode}/acc-evidence-request/{siteLocationUuid}/{standardId}
    * Params   -
    *       ** accCode - accreditation type code
    *       ** siteLocationUuid - accreditation document id
    *       ** standardId - substandard for which the evidence is requested
    *       ** assessmentItemId - particular item for which evidence upload is requested
    *       ** employeeUuid - id of the employee to which request is forwarded
    *       ** employeeEmail - email of the employee to which request is forwarded
    *
    * Response - object with status of operation
    * */
    this.createAccEvidenceRequest = function (accCode, siteLocationUuid, standardId, assessmentItemId, employeeUuid, employeeEmail, year) {
        var ldata = {
            employeeUuid: employeeUuid,
            employeeEmail: employeeEmail,
            assItemId: assessmentItemId,
            year: year
        };

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-evidence-request/" + siteLocationUuid + "/" + standardId,
            data: ldata,
            method: "POST"
        }).then(function successCallback(response) {
            return response;
        },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };

    /**
     * Method   - PUT
     * Endpoint - /accreditation/{accCode}/acc-evidence-request/{siteLocationUuid}/{standardId}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** siteLocationUuid - accreditation document id
     *       ** standardId - substandard for which the evidence is requested
     *       ** assessmentItemId - particular item for which evidence upload is requested
     *       ** customEmail - email of the person to which request is forwarded
     *       ** customFirstName - person first name
     *       ** customLastName - person last name
     *
     * Response - object with status of operation
     * */
    this.createAccEvidenceRequest3rdParty = function (accCode, siteLocationUuid, standardId, assessmentItemId,
                                                      customEmail, customFirstName, customLastName, year) {
        var ldata = {
            customEmail: customEmail,
            customFirstName: customFirstName,
            customLastName: customLastName,
            assItemId: assessmentItemId,
            year: year
        };

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-evidence-request/" + siteLocationUuid + "/" + standardId,
            data: ldata,
            method: "POST"
        }).then(function successCallback(response) {
                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };


     /**
     * Method   - POST
     * Endpoint - /acc-evidence-request/document
     * Params   -
     *      ** uuid of site location (string)
     *      ** accreditation document
     *      ** token
     * Response - object with status and uploaded document dcr number
     *
     * */
    this.saveAccreditationDocumentAnonymous = function (siteLocationUuid, accreditationDocument, token) {
        var ldata = {
            siteLocationUuid: siteLocationUuid,
            title: accreditationDocument.title,
            version: accreditationDocument.version,
            writtenBy: accreditationDocument.writtenBy,
            dateEdited: accreditationDocument.dateEdited != null ?
                Helpers.formatDate(accreditationDocument.dateEdited) : null,
            approvedBy: accreditationDocument.approvedBy,
            dateApproved: accreditationDocument.dateApproved != null ?
                Helpers.formatDate(accreditationDocument.dateApproved) : null,
            validUntil: accreditationDocument.validUntil != null ?
                Helpers.formatDate(accreditationDocument.validUntil) : null,
            docPath: accreditationDocument.docPath,
            docNumber: accreditationDocument.docNumber,
            comment: accreditationDocument.comment,
            misc: JSON.stringify(accreditationDocument.misc),
            trainingType: null,
            template: null,
            token: token,
            isGenerated: accreditationDocument.isGenerated
        };

        return $http({
            url: API_BASE + "/acc-evidence-request/document",
            data: ldata,
            method: "POST"
        }).then(function (response) {
            return response;
        }, function (errorResponse) {
            Notifier.responseMessage(errorResponse.status);
            return $q.reject(errorResponse);
        });
    };


    this.validateAccEvidenceRequest = function (token) {
        return $http({
            url: API_BASE + "/acc-evidence-request/validate",
            method: "GET",
            params: { token: token }
        }).then(function (response) {
            return response;
        }, function (errorResponse) {
            Notifier.responseMessage(errorResponse.status);
            return $q.reject(errorResponse);
        });

    };

    this.finishAccEvidenceRequest = function (token) {
        var ldata = {
            token: token
        };
        return $http({
            url: API_BASE + "/acc-evidence-request/finish",
            method: "POST",
            data: ldata
        }).then(function (response) {
            return response;
        }, function (errorResponse) {
            Notifier.responseMessage(errorResponse.status);
            return $q.reject(errorResponse);
        });

    };

     /** Fetch list of audit quality improvement items
     *
     * Method   - GET
     * Endpoint - /accreditation/{acc_code}/acc-quality-improvement/{site_location}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** siteLocationUuid - accreditation document id
     *       ** year - for which year site quality improvement items should be fetched
     *       ** filter - aditional parameter filter (for future use)
     * Response - Object with result about status of approval of document
     *
     * */
    this.fetchAccQualityImprovementItems = function (accCode, siteLocationUuid, year, filter, exportFormat) {
        var params = [];
        params['year'] = year;
        params['filter'] = filter;

        if (exportFormat == 'pdf') {
            params['format'] = "pdfphp";
        }

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-quality-improvement/" + siteLocationUuid,
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

            if (exportFormat == 'pdf') {
                //return file path
                response.data.result = response.data.path;
            } else {

                var arr = [];
                response.data.list.forEach(function (elem) {
                    var item = new AccQualityImprovementItem();
                    item.id = elem.aqi_id;
                    item.locationUuid = elem.location_uuid;
                    item.improvementActionItem = elem.improvement_action_item;
                    item.source = elem.source;
                    item.actionUndertaken = elem.action_undertaken;
                    item.itemNumber = elem.item_number;
                    item.resPerson = elem.res_person;
                    item.resPersonUuid = elem.res_person_uuid;
                    if (elem.creation_date != null) {
                        item.creationDate = Helpers.parseFromDbDate(elem.creation_date, DATE_TYPE.DATE);
                        item.creationDateStr = Helpers.parseFromDbDate(elem.creation_date, DATE_TYPE.STRING);
                    }

                    if (elem.creation_date != null) {
                        item.completionDate = Helpers.parseFromDbDate(elem.completion_date, DATE_TYPE.DATE);
                        item.completionDateStr = Helpers.parseFromDbDate(elem.completion_date, DATE_TYPE.STRING);
                    }

                    arr.push(item);
                });

                if (arr.length > 0) {
                    response.data.result = arr;
                } else {
                    response.data.result = null;
                }
            }

            return response;
        },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };


    /**
     * Method   - PUT
     * Endpoint - /accreditation/{accCode}/acc-quality-improvement/{siteLocationUuid}/{qualityImprovementItemId}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** siteLocationUuid - site location uuid
     *       ** qualImprItem - object of type AccQualityImprovementItem
     *
     * Response - object with status of operation
     * */
    this.createAccQualityImprovementItem = function (accCode, siteLocationUuid, qualImprItem) {
        var itemToSend = qualImprItem;
        itemToSend.creationDate = Helpers.formatDate(qualImprItem.creationDate);
        itemToSend.completionDate = Helpers.formatDate(qualImprItem.completionDate);
        var ldata = {
            qualImprItem: itemToSend
        };

        var restMethod;

        var qualityImprovementItemId = qualImprItem.id != null ? "/" + qualImprItem.id : "";
        if (qualityImprovementItemId) {
            restMethod = "PUT";
        } else {
            restMethod = "POST";
        }

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-quality-improvement/" + siteLocationUuid +  qualityImprovementItemId,
            data: ldata,
            method: restMethod
        }).then(function successCallback(response) {
            return response;
        }, function errorCallback(errorResponse) {
            Notifier.responseMessage(errorResponse.status);
            return $q.reject(errorResponse);
        });
    };



    /** Fetch list complain and feedback items
     *
     * Method   - GET
     * Endpoint - /accreditation/{acc_code}/acc-complaint-feedback/{site_location}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** siteLocationUuid - accreditation document id
     *       ** year - for which year site quality improvement items should be fetched
     *       ** filter - aditional parameter filter (for future use)
     * Response - Object with result about status of approval of document
     *
     * */
    this.fetchAccComplaintFeedbackItems = function (accCode, siteLocationUuid, year, filter, exportFormat) {
        var params = [];
        params['year'] = year;
        params['filter'] = filter;

        if (exportFormat == 'pdf') {
            params['format'] = "pdfphp";
        }

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-complaint-feedback/" + siteLocationUuid,
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                if (exportFormat == 'pdf') {
                    //return file path
                    response.data.result = response.data.path;
                } else {

                    var arr = [];
                    response.data.list.forEach(function (elem) {
                        var item = new AccComplaintFeedbackItem();
                        item.id = elem.acf_id;
                        item.locationUuid = elem.location_uuid;

                        item.complaintsNumber = elem.complaints_number;
                        item.customerDetails = elem.customer_details;
                        item.detailsCommunication = elem.details_communication;
                        item.detailsCorrection = elem.details_correction;
                        item.conclusion = elem.conclusion;
                        item.completedByUuid = elem.completed_by_uuid;
                        item.completedBy = elem.completed_by;
                        item.status = elem.status;


                        if (elem.date_received != null) {
                            item.dateReceived = Helpers.parseFromDbDate(elem.date_received, DATE_TYPE.DATE);
                            item.dateReceivedStr = Helpers.parseFromDbDate(elem.date_received, DATE_TYPE.STRING);
                        }

                        if (elem.close_date != null) {
                            item.closeDate = Helpers.parseFromDbDate(elem.close_date,  DATE_TYPE.DATE);
                            item.closeDateStr = Helpers.parseFromDbDate(elem.close_date, DATE_TYPE.STRING);
                        }


                        arr.push(item);
                    });

                    if (arr.length > 0) {
                        response.data.result = arr;
                    } else {
                        response.data.result = null;
                    }
                }

                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };






    /**
     * Method   - PUT
     * Endpoint - /accreditation/{accCode}/acc-complaint-feedback/{siteLocationUuid}/{qualityImprovementItemId}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** siteLocationUuid - site location uuid
     *       ** complaintFeedbackItem - object of type AccComplaintFeedbackItem
     *
     * Response - object with status of operation
     * */
    this.createAccComplaintFeedbackItem = function (accCode, siteLocationUuid, complaintFeedbackItem) {
        var itemToSend = complaintFeedbackItem;
        itemToSend.dateReceived = Helpers.formatDate(complaintFeedbackItem.dateReceived);
        itemToSend.closeDate = Helpers.formatDate(complaintFeedbackItem.closeDate);
        var ldata = {
            complaintFeedbackItem: itemToSend
        };

        var restMethod;

        var complaintFeedbackItemId = complaintFeedbackItem.id != null ? "/" + complaintFeedbackItem.id : "";
        if (complaintFeedbackItemId) {
            restMethod = "PUT";
        } else {
            restMethod = "POST";
        }

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-complaint-feedback/" + siteLocationUuid +  complaintFeedbackItemId,
            data: ldata,
            method: restMethod
        }).then(function successCallback(response) {
                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };



    /** Fetch list of optimized charts
     *
     * Method   - GET
     * Endpoint - /accreditation/{accCode}/acc-optimized-charts
     * Params   -
     *       ** accCode - accreditation type code
     *       ** equipmentIds - array of equipment IDS for which to get charts
     *       ** year - which year (default current year)
     *       ** filter - aditional parameter filter (for future use)
     * Response - Object with result about status of approval of document
     *
     * */
    this.fetchAccOptimizedCharts = function (accCode, equipmentIds, year, filter, exportFormat) {
        var params = [];

        params['equipmentIds'] = encodeURIComponent(JSON.stringify(equipmentIds));
        params['year'] = year;
        params['filter'] = filter;

        if (exportFormat == 'pdf') {
            params['format'] = "pdfphp";
        }

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-optimized-charts" ,
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                if (exportFormat == 'pdf') {
                    //return file path
                    response.data.result = response.data.path;
                } else {

                    var arr = [];
                    response.data.list.forEach(function (elem) {
                        var item = new AccOptimizedChartDocument();
                        item.id = elem.aoc_id;
                        item.equipmentId = elem.equ_id;
                        item.createdBy = elem.created_by;
                        item.approvedBy = elem.approved_by;
                        item.docPath = elem.doc_path;
                        item.siteLocationUuid = elem.slo_uuid;


                        if (elem.creation_date != null) {
                            item.creationDate = Helpers.parseFromDbDate(elem.creation_date, DATE_TYPE.DATE);
                            item.creationDateStr = Helpers.parseFromDbDate(elem.creation_date, DATE_TYPE.STRING);
                        }

                        if (elem.review_date != null) {
                            item.reviewDate = Helpers.parseFromDbDate(elem.review_date, DATE_TYPE.DATE);
                            item.reviewDateStr = Helpers.parseFromDbDate(elem.review_date, DATE_TYPE.STRING);
                        }

                        if (elem.next_review_date != null) {
                            item.nextReviewDate = Helpers.parseFromDbDate(elem.next_review_date, DATE_TYPE.DATE);
                            item.nextReviewDateStr = Helpers.parseFromDbDate(elem.next_review_date, DATE_TYPE.STRING);
                        }

                        if (elem.upload_date != null) {
                            item.uploadDate = Helpers.parseFromDbDate(elem.upload_date, DATE_TYPE.DATE);
                            item.uploadDateStr = Helpers.parseFromDbDate(elem.upload_date, DATE_TYPE.STRING);
                        }


                        arr.push(item);
                    });

                    if (arr.length > 0) {
                        response.data.result = arr;
                    } else {
                        response.data.result = null;
                    }
                }

                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };


    /**
     * Method   - PUT
     * Endpoint - /accreditation/{accCode}/acc-optimized-charts/{optimizedChartId}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** equipmentId - id of equipment
     *       ** optimizedChart - object of type AccOptimizedChartDocument
     *
     * Response - object with status of operation
     * */
    this.saveAccOptimizedChart = function (accCode, equipmentId, optimizedChartDocument) {

        var itemToSend = optimizedChartDocument;
        itemToSend.creationDate = Helpers.formatDate(optimizedChartDocument.creationDate);
        itemToSend.reviewDate = Helpers.formatDate(optimizedChartDocument.reviewDate);
        itemToSend.nextReviewDate = Helpers.formatDate(optimizedChartDocument.nextReviewDate);
        itemToSend.uploadDate = Helpers.formatDate(optimizedChartDocument.uploadDate);

        var ldata = {
            equipmentId: equipmentId,
            optimizedChartDocument: itemToSend
        };

        var restMethod;

        var optimizedChartDocumentId = optimizedChartDocument.id != null ? "/" + optimizedChartDocument.id : "";
        if (optimizedChartDocumentId) {
            restMethod = "PUT";
        } else {
            restMethod = "POST";
        }

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-optimized-charts" +  optimizedChartDocumentId,
            data: ldata,
            method: restMethod
        }).then(function successCallback(response) {
                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };


    /** Fetch accreditation optimized charts report for piece of equipment
     *
     * Method   - GET
     * Endpoint - /accreditation/{accCode}/acc-optimized-charts/report/{optimizedChartId}
     * Params   -
     *       ** accCode - accreditation type code
     *       ** equipmentId - id of the equipment for which report is asked
     *       ** exportFormat
     * Response - Object with result, document path
     *
     * */
    this.fetchAccOptimizedChartReport = function (accCode, optimizedChartDocumentId, year, exportFormat) {
        var params = [];

        params['year'] = year;

        if (exportFormat == 'pdf') {
            params['format'] = "pdfphp";
        }

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/acc-optimized-charts/report/" + optimizedChartDocumentId,
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                if (exportFormat == 'pdf') {
                    //return file path
                    response.data.result = response.data.path;
                } else {
                    Notifier.error($filter('translate')('ACCREDITATION.SERVICES.NOTIFIER_FORMAT_ERROR'));
                    return $q.reject({message: $filter('translate')('ACCREDITATION.SERVICES.REJECT_FORMAT')});
                }

                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };

    /** Check accreditation documents for standards
     *
     * Method   - GET
     * Endpoint - /accreditation/<accreditation-code>/check-acc-documents
     * Params   -
     *      ** accreditation shema code (string)
     *      ** uuid of site location (string)
     *      ** year
     *      ** standardId
     * Response - Object (parsed JSON) with array of list documents
     *
     * */
    this.checkAccDocuments = function (accCode, siteLocationUuid, year, standardId) {
        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/check-acc-documents",
            params: {
                siteLocationUuid: siteLocationUuid,
                year: year,
                standardId: standardId
            },
            method: "GET",
        }).then(function successCallback(response) {
                return response;
            },
            function errorCallback(errorResponse) {
                return $q.reject(errorResponse);
            });
    };

    // /** Retrieve all accreditation documents for standards
    //  *
    //  * Method   - GET
    //  * Endpoint - /accreditation/<accreditation-code>/acc-documents
    //  * Params   -
    //  *      ** accreditation shema code (string)
    //  *      ** uuid of site location (string)
    //  *      ** year
    //  *      ** standardId
    //  * Response - Object (parsed JSON) with array of list documents
    //  *
    //  * */
    // this.fetchAccDocuments = function (accCode, siteLocationUuid, year, standardId) {
    //     return $http({
    //         url: API_BASE + "/accreditation/" + accCode + "/acc-documents",
    //         params: {
    //             siteLocationUuid: siteLocationUuid,
    //             year: year,
    //             standardId: standardId
    //         },
    //         method: "GET",
    //         responseType: 'arraybuffer'
    //     }).then(function successCallback(response) {
    //         return response;
    //     },
    //     function errorCallback(errorResponse) {
    //         return $q.reject(errorResponse);
    //     });
    // };

    /** Retrieve all unfulfilled accreditation documents for standards
     *
     * Method   - GET
     * Endpoint - /accreditation/<accreditation-code>/acc-documents
     * Params   -
     *      ** accreditation shema code (string)
     *      ** uuid of site location (string)
     *      ** year
     *      ** standardId
     * Response - Object (parsed JSON) with array of list documents
     *
     * */
    this.generateUnfulfilledAccDocuments = function (accCode, siteLocationUuid, year, standardId) {
        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/generate-unfulfilled-acc-documents",
            params: {
                siteLocationUuid: siteLocationUuid,
                year: year,
                standardId: standardId
            },
            method: "GET",
        }).then(function successCallback(response) {
                return response;
            },
            function errorCallback(errorResponse) {
                return $q.reject(errorResponse);
            });
    };

    // /** Retrieve all unfulfilled accreditation documents for standards
    //  *
    //  * Method   - GET
    //  * Endpoint - /accreditation/<accreditation-code>/acc-documents
    //  * Params   -
    //  *      ** accreditation shema code (string)
    //  *      ** uuid of site location (string)
    //  *      ** year
    //  *      ** standardId
    //  * Response - Object (parsed JSON) with array of list documents
    //  *
    //  * */
    // this.generateAccDocuments = function (accCode, siteLocationUuid, year, standardId) {
    //     return $http({
    //         url: API_BASE + "/accreditation/" + accCode + "/generate-acc-documents",
    //         params: {
    //             siteLocationUuid: siteLocationUuid,
    //             year: year,
    //             standardId: standardId
    //         },
    //         method: "GET",
    //     }).then(function successCallback(response) {
    //             return response;
    //         },
    //         function errorCallback(errorResponse) {
    //             return $q.reject(errorResponse);
    //         });
    // };
}
