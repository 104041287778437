export function EquipmentPropertyFactory() {
    return function EquipmentProperty() {

        this.cpr_id = null;
        this.prop_code = null;
        this.prop_name = null;
        this.prop_type = null;
        this.is_mandatory = null;
        this.is_readonly = null;
    };
}
