import { API_BASE } from '../utils/util.constants';
import { NAVIGATION_CONFIGS } from './navigation.actions.types';
import i18n from '../utils/i18n';
import isNull from 'lodash/isNull';
import memoize from 'lodash/memoize';
import sortBy from 'lodash/sortBy';

export default function NavigationActions($http) {
    function getConfigs(par_url = null, slo_uuid = null) {
        return dispatch => {
            return $http({
                url: API_BASE + '/module/components',
                method: 'GET',
                params: { par_url, slo_uuid }
            }).then(response => {
                if(!response.data)
                    return null;
                let configs = response.data.map((item) => ({
                    id: item.menu_id,
                    parentId: item.menu_parent_code === 'das' ? null : item.menu_parent_id,
                    state: item.component_state,
                    selector: isNull(item.component_state) ? '' : item.component_state.replace(/\./g, '-'),
                    module: item.module_name,
                    color: item.color,
                    colorName: item.color_name,
                    order: parseInt(item.ord_num),
                    code: item.menu_code,
                    title: i18n.t(`modules.${item.menu_code}`, { defaultValue: item.menu_name }),
                    url: item.menu_url,
                    icon: item.png_url,
                    iconType: item.png_url.split('.').pop(),
                    iconSvg: './' + item.png_url,
                    name: i18n.t(`modules.${item.menu_code}`, { defaultValue: item.menu_name }),
                    desc: item.menu_desc,
                    warning: parseInt(item.warning),
                    critical: parseInt(item.critical),
                    operationId: item.func_id,
                    operationCode: item.func_code,
                    operationName: item.func_name,
                    operationDescription: item.func_desc
                }));

                if(par_url === null) {
                    configs = configs.filter((function(item) {
                        const parentId = item.parentId;
                        item.components = this(item.id);
                        return !(parentId && this(parentId).push(item));
                    }).bind(memoize(function() {return [];})));

                    dispatch({
                        type: NAVIGATION_CONFIGS,
                        configs: sortBy(configs, ['order'])
                    });
                }
                return sortBy(configs, ['order']);
            });
        }
    }

    return {
        getConfigs
    };
}

NavigationActions.$inject = ['$http'];
