/* @ngInject */
export function ServiceContractsManageController($controller, $ngRedux, $scope, $rootScope, $state, $q, $log, $mdDialog, ServiceContractService, ServiceContractManager, ServiceContract, EquipmentManager, Grid, Notifier, EquipmentFilter, EquipmentSparePart, AccountService, VendorService, VendorGridFilter, EQUIPMENT_TYPE) {

    angular.extend(this, $controller('ServiceContractsBaseController', {
        $scope: $scope,
        $rootScope: $rootScope,
        $log: $log,
        ServiceContractService: ServiceContractService,
        ServiceContractManager: ServiceContractManager,
        ServiceContract: ServiceContract,
        EquipmentManager: EquipmentManager,
        Grid: Grid,
        EquipmentFilter: EquipmentFilter,
        EquipmentSparePart: EquipmentSparePart,
        AccountService: AccountService,
        Notifier: Notifier,
        VendorService: VendorService,
        VendorGridFilter: VendorGridFilter
    }));

    $scope.MODULE_STATE = $scope.MODULE_STATE_UPDATE;
    $scope.CONTRACT_STATE = $scope.MODULE_STATE_UPDATE;

    $scope.tabs = _.cloneDeep(ServiceContractManager.tabs);

    $scope.contracts = [];
    $scope.equipmentCoverageSummary = [];
    $scope.equipmentSummary = [];
    $scope.visitsSummary = [];

    $scope.$watch('selectedTab', (function(newTab) {
        switch (_.get(newTab, 'id', null)) {
            case ServiceContractManager.TAB_CONTRACTS:
                _.find($scope.tabs, ['id', ServiceContractManager.TAB_CONTRACT_SUMMARY]).visible = false;
                this.disableTabs();
                break;
        }
    }).bind(this));

    _.extend(_.find($scope.tabs, ['id', ServiceContractManager.TAB_CONTRACTS]), {
        exec: (function() {
            var deferred = $q.defer();
            ServiceContractService.getContract($scope.contract.id).then((function(contract) {
                _.assign($scope.contract, contract);
                $scope.contract.initMonths();
                $scope.contractTypeSelected();
                this.enableTabs();
                deferred.resolve();
            }).bind(this));
            return deferred.promise;
        }).bind(this),
        post: function() {
            var deferred = $q.defer();
            var serviceList = [];
            if ($scope.contract.accounts.length) {
                serviceList.push(ServiceContractService.getEquipment($scope.contract, $scope.gridEquipment.filter).then(function(data) {
                    $scope.gridEquipment.totalItems = data.total;
                    $scope.gridEquipment.items =  data.items;
                }));
            } else {
                $scope.gridEquipment.items = [];
            }
            serviceList.push(ServiceContractService.getEquipmentTypesWithCategories($scope.contract.type.id, $scope.contract.id).then(function(data) {
                $scope.equipmentTypes = data;
                _.each($scope.equipmentTypes, function(equipmentType) {
                    _.each(equipmentType.categories, function(category) {
                        category.selected = _.isUndefined(_.find($scope.contract.equipmentCategories, ['id', category.id])) ? 0 : 1;
                    });
                });
                $scope.setSelectedEquipmentTypes();
            }));
            if ($scope.contract.accounts.length && $scope.contract.equipmentCategories.length) {
                serviceList.push(ServiceContractService.getSelectedEquipment($scope.contract).then(function(data) {
                    $scope.contract.equipment = data.items;
                    // TODO parse scheduled equipment
                    $scope.gridEquipmentForVisits.filter.page = 1;
                    $scope.gridEquipmentForVisits.filter.offset = 0;
                    $scope.gridEquipmentForVisits.filter.type = null;
                    $scope.gridEquipmentForVisits.filter.account = null;
                    $scope.gridEquipmentForVisits.filter.category = null;
                    $scope.gridEquipmentForVisits.items = $scope.contract.equipment;
                    //$scope.getEquipmentForVisits();
                }));
            } else {
                $scope.gridEquipmentForVisits.filter = [];
                $scope.gridEquipmentForVisits.items = [];
                $scope.contract.equipment = [];
            }
            serviceList.push(ServiceContractService.getEquipmentSpareParts().then(function(data) {
                $scope.equipmentSpareParts = data;
                _.each($scope.equipmentSpareParts, function(sparePart) {
                    sparePart.selected = _.isUndefined(_.find($scope.contract.equipmentSpareParts, ['id', sparePart.id])) ? 0 : 1;
                });
            }));
            $q.all(serviceList).then(function() {
                deferred.resolve();
            }, function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ServiceContractManager.TAB_CONTRACT_DETAILS]), {
        exec: function() {
            var deferred = $q.defer();
            ServiceContractService.updateContract($scope.contract).then(function(status) {
                if (status)
                    Notifier.error('System Error! Please try again latter');
                else {
                    var serviceList = [];
                    if ($scope.contract.accounts.length) {
                        serviceList.push(ServiceContractService.getEquipment($scope.contract, $scope.gridEquipment.filter).then(function(data) {
                            $scope.gridEquipment.totalItems = data.total;
                            $scope.gridEquipment.items = data.items;
                        }));
                    } else {
                        $scope.gridEquipment.items = [];
                    }
                    serviceList.push(ServiceContractService.getEquipmentTypesWithCategories($scope.contract.type.id, $scope.contract.id).then(function(data) {
                        $scope.equipmentTypes = data;
                        _.each($scope.equipmentTypes, function(equipmentType) {
                            _.each(equipmentType.categories, function(category) {
                                category.selected = _.isUndefined(_.find($scope.contract.equipmentCategories, ['id', category.id])) ? 0 : 1;
                            });
                        });
                        $scope.setSelectedEquipmentTypes();
                    }));
                    $ngRedux.dispatch({
                        type: 'GRID_RESET',
                        id: 'serviceContractsGrid'
                    });
                    Notifier.success('Contract details successfully updated');
                }
                deferred.resolve();
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ServiceContractManager.TAB_ACCOUNTS]), {
        init: function() {
            if ($scope.contract.accounts.length)
                this.formValidation = false;
        },
        exec: function() {
            var deferred = $q.defer();
            this.formValidation = true;
            $rootScope.$broadcast('SELECT_CASCADE_RESET');
            if ($scope.equipmentTypes.length)
                Notifier.success('Accounts are successfully updated');
            else
                Notifier.error('System Error! Please refresh page and try again');
            deferred.resolve();
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ServiceContractManager.TAB_EQUIPMENT_COVERED]), {
        init: function() {
            if ($scope.contract.equipmentCategories.length) {
                this.form.$setValidity('required', true);
            }
            else {
                this.form.$setValidity('required', false);
                this.form.$setDirty();
            }
        },
        exec: function() {
            var deferred = $q.defer();
            ServiceContractService.getEquipment($scope.contract, $scope.gridEquipment.filter).then(function(data) {
                $scope.gridEquipment.totalItems = data.total;
                $scope.gridEquipment.items =  data.items;
                deferred.resolve();
            }, function(error) {
                Notifier.error('You have to add at least one account from Accounts tab!');
            });
            $scope.nextTab();
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ServiceContractManager.TAB_EQUIPMENT]), {
        init: function() {
            if ($scope.contract.equipment.length) {
                this.form.$setValidity('required', true);
            }
            else {
                this.form.$setValidity('required', false);
                this.form.$setDirty();
            }
        },
        exec: function() {
            var deferred = $q.defer();
            Notifier.success('Equipment successfully updated');
            deferred.resolve();
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ServiceContractManager.TAB_SPARE_PARTS]), {
        exec: function() {
            var deferred = $q.defer();
            Notifier.success('Equipment Spare Parts successfully updated');
            deferred.resolve();
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ServiceContractManager.TAB_ESTIMATED_TIME_FRAMES]), {
        exec: function() {
            var deferred = $q.defer();
            $timeout(function() {
                $scope.getEquipmentForVisits();
                Notifier.info('Use filter to schedule visits for different Accounts');
                deferred.resolve();
            }, 200);
            return deferred.promise;
        }
    });

    $scope.onContractSelected = function(event) {
        _.assign($scope.contract, event.item);

        if ($scope.contract.status.code == 'act') {
            $scope.onContractSummary(event);
        }
        else
            $scope.executeTab();
    };

    $scope.onContractSummary = function(event) {

        ServiceContractService.getContract(event.item.id).then(function(contract) {
            $scope.contract = _.assign(event.item, contract);
            $scope.contract.initMonths();
            $scope.contract.navigateToDocuments = (equipment) => {
                const { equipment_id } = equipment;
                switch (parseInt(equipment.type.id)) {
                    case EQUIPMENT_TYPE.XRay:
                        $state.go('app.xray.manage', {id: `${equipment_id},3`});
                        break;
                    case EQUIPMENT_TYPE.laser:
                        $state.go('app.lasers.manage', {id: `${equipment_id},3`});
                        break;
                    case EQUIPMENT_TYPE.ultrasound:
                        $state.go('app.ultrasound.manage', {id: `${equipment_id},3`});
                        break;
                    case EQUIPMENT_TYPE.mammo:
                        $state.go('app.mammo.manage', {id: `${equipment_id},3`});
                        break;
                }
            };

            var serviceList = [];
            if ($scope.contract.accounts.length) {
                serviceList.push(ServiceContractService.getEquipment($scope.contract, $scope.gridEquipment.filter).then(function(data) {
                    $scope.gridEquipment.totalItems = data.total;
                    $scope.gridEquipment.items = data.items;
                }));
            } else {
                $scope.gridEquipment.items = [];
            }
            serviceList.push(ServiceContractService.getEquipmentTypesWithCategories($scope.contract.type.id, $scope.contract.id).then(function(data) {
                $scope.equipmentTypes = data;
                _.each($scope.equipmentTypes, function(equipmentType) {
                    _.each(equipmentType.categories, function(category) {
                        category.selected = _.isUndefined(_.find($scope.contract.equipmentCategories, ['id', category.id])) ? 0 : 1;
                    });
                });
                $scope.setSelectedEquipmentTypes();
            }));
            if ($scope.contract.accounts.length && $scope.contract.equipmentCategories.length) {
                serviceList.push(ServiceContractService.getSelectedEquipment($scope.contract).then(function(data) {
                    $scope.contract.equipment = data.items;
                    // TODO parse scheduled equipment
                    $scope.gridEquipmentForVisits.filter.page = 1;
                    $scope.gridEquipmentForVisits.filter.offset = 0;
                    $scope.gridEquipmentForVisits.filter.type = null;
                    $scope.gridEquipmentForVisits.filter.account = null;
                    $scope.gridEquipmentForVisits.filter.category = null;
                    $scope.gridEquipmentForVisits.items = $scope.contract.equipment;
                    //$scope.getEquipmentForVisits();
                }));
            } else {
                $scope.contract.equipment = [];
                $scope.gridEquipmentForVisits.filter = [];
                $scope.gridEquipmentForVisits.items = [];
            }
            serviceList.push(ServiceContractService.getEquipmentSpareParts().then(function(data) {
                $scope.equipmentSpareParts = data;
                _.each($scope.equipmentSpareParts, function(sparePart) {
                    sparePart.selected = _.isUndefined(_.find($scope.contract.equipmentSpareParts, ['id', sparePart.id])) ? 0 : 1;
                });
            }));

            $q.all([
                ServiceContractService.getEquipmentTypesWithCategories($scope.contract.type.id, $scope.contract.id).then(function(equipmentTypes) {
                    var equipmentCoverage = [];
                    _.each(equipmentTypes, function(equipmentType) {
                        var categories = [];
                        _.each($scope.contract.equipmentCategories, (function(category) {
                            if (!_.isUndefined(_.find(equipmentType.categories, ['id', category.id])))
                                this.push(category.name);
                        }).bind(categories));
                        equipmentCoverage.push({
                            equipmentType: equipmentType.name,
                            equipmentCategories: categories
                        });
                    });
                    $scope.equipmentCoverageSummary = equipmentCoverage.filter(function(item) {
                        return item.equipmentCategories.length;
                    }).map(function(item) {
                        return {
                            equipmentType: item.equipmentType,
                            equipmentCategories: item.equipmentCategories.join(', ')
                        };
                    });
                }),
                ServiceContractService.getSelectedEquipment($scope.contract).then(function(data) {
                    $scope.contract.equipment = data.items;
                    $scope.equipmentSummary = $scope.contract.getEquipmentSummary();
                    $scope.visitsSummary = $scope.contract.getVisitsSummary();
                })
            ]).then(function() {
                _.find($scope.tabs, ['id', ServiceContractManager.TAB_CONTRACT_SUMMARY]).disabled = false;
                _.find($scope.tabs, ['id', ServiceContractManager.TAB_CONTRACT_SUMMARY]).visible = true;
                _.find($scope.tabs, ['id', ServiceContractManager.TAB_CONTRACT_DETAILS]).disabled = false;
                _.find($scope.tabs, ['id', ServiceContractManager.TAB_ACCOUNTS]).disabled = false;
                _.find($scope.tabs, ['id', ServiceContractManager.TAB_EQUIPMENT_COVERED]).disabled = false;
                _.find($scope.tabs, ['id', ServiceContractManager.TAB_EQUIPMENT]).disabled = false;
                _.find($scope.tabs, ['id', ServiceContractManager.TAB_SPARE_PARTS]).disabled = false;
                _.find($scope.tabs, ['id', ServiceContractManager.TAB_ESTIMATED_TIME_FRAMES]).disabled = false;
                _.find($scope.tabs, ['id', ServiceContractManager.TAB_SCHEDULE_VISITS]).disabled = false;
                $scope.selectedTabIndex = ServiceContractManager.TAB_CONTRACT_SUMMARY;
            });
        });
    };

    $scope.finalizeContract = function() {
        var confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Finalizing contract will prevent possible changes.')
            .ariaLabel('Finalizing Contract')
            .ok('Yes')
            .cancel('No');

        $mdDialog.show(confirm).then(function() {
            ServiceContractService.finalizeContract($scope.contract.id).then(function(status) {
                $scope.contract.status = status;
                Notifier.success('Contract was finalized successfully');
            });
        }, angular.noop);
    };

    $scope.setExpiryDate = function() {
        if ($scope.contract.startDate) {
            var startDate = new Date($scope.contract.startDate);
            var endDate = startDate.setFullYear(startDate.getFullYear() + parseInt($scope.contract.numberOfYears));
            $scope.contract.expiryDate = new Date(endDate);
        }
    };

    $scope.exportContractSummary = function() {
        // TODO
    }
}
