import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';
import { getRequestTrainings } from '../../ap-laser-request.actions';

class ApplicationsPermitsLaserRequestTrainingsGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'applicationsPermits';
        this.identifier = 'apLaserRequestTrainingsGrid';
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                mapper: null},
            {title: 'Training Name',    mapper: 'presentation_name'},
            {title: 'Video Name',       mapper: 'video_name'},
            {title: 'Status',           mapper: 'status'},
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), { getRequestTrainings }
        )(this);

        this.dataAction = this.getRequestTrainings;
        this.exportService = this.getRequestTrainings;
        this.filter.identifier = this.identifier;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const apLaserRequestTrainingsGrid = {
    bindings: {
        title: '@',
    },
    controller: ApplicationsPermitsLaserRequestTrainingsGridController,
    ...gridTpl
};
