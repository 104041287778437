import { IDS } from '../acc-documents-action-id.constants';
import {TYPES} from "../acc-documents-action-type.constants";
import { TAB_SELECT, TAB_SHOW } from '../../../../common/tabs/tabs.constants'
import * as Actions from '../acc-documents.actions';
import {GRID_RELOAD} from "../../../../common/grid/grid.constants";
import get from "lodash/get";
import {formatDate} from "../../../../common/utils/util.date";
import {DATE_FORMAT} from "../../../../common/utils/util.constants";

/**
 * Creates a new AccDocumentAddController.
 * @class
 */
class AccDocumentAddController {
    constructor($ngRedux, $state, $stateParams, $scope, $rootScope, $filter, AuthService, Notifier) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.stateName = $state.$current.name;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.AuthService = AuthService;
        this.Notifier = Notifier;
    }

    $onInit() {
        this.identifier = IDS.accDocuments;
        this.parentState = IDS.app_accreditation_scheme_acc_documents;

        this.tabState = IDS.app_accreditation_scheme_acc_documents_add;
        this.unsubscribe = this.$ngRedux.connect(state => {
            const accDocuments = state.accreditation.accDocuments;
            return {
                document:       accDocuments.accDocumentDetails,
                schemeName:     accDocuments.schemeDetails.title,
                standardName:   accDocuments.sectionDetails.nodeName,
                standard_id:    accDocuments.sectionDetails.id,
            }
        }, {
            ...Actions,
        })(this);

        this.schemeCode = this.$stateParams.itemCode;
        this.uploadSaveBtnDisabled = false;
        this.$ngRedux.dispatch({
            type:   TYPES.DOC_DETAILS_REFRESH,
            id:     IDS.accDocuments,
            data:  {
                type_code: 'pol', // select first item in document types list
                doc_date: new Date(),
            }
        });
    }

    onUploadButtonSave(event) {
        this.$scope.$broadcast('show-errors-check-validity', '$ctrl.accDocumentAddForm');
        if (this.accDocumentAddForm.$invalid) {
            this.Notifier.error('COMMON.FIELDS_REQUIRED');
            return;
        }
        this.addAccStandardRequiredDoc({
            document_type:  this.document.type_code,
            name:           this.document.name,
            description:    this.document.description,
            loc_uuid:       this.document.loc_uuid,
            doc_date:       formatDate(this.document.doc_date, DATE_FORMAT.OUTPUT),
            due_date:       formatDate(this.document.due_date, DATE_FORMAT.OUTPUT),
            doc_num:        this.document.doc_num,
            ast_id:         this.standard_id,
            path:           event[0].filePath,
        }).then( () => {
            this.$ngRedux.dispatch({
                type: GRID_RELOAD,
                id: IDS.accDocuments,
                path: IDS.accDocumentsGrid,
            });
            this.$ngRedux.dispatch({
                type: TAB_SELECT,
                id: IDS.app_accreditation_scheme_acc_documents,
                state: IDS.app_accreditation_scheme_acc_documents_list,
            });
        })
    }

    $onDestroy() {
        this.unsubscribe();
    }

}

AccDocumentAddController.$inject = ['$ngRedux', '$state', '$stateParams', '$scope', '$rootScope', '$filter', 'AuthService', 'Notifier']

export const accDocumentAdd = {
    controller: AccDocumentAddController,
    template: require('./acc-document-add.html')
}
