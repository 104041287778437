/** @module root/legacy/components/safety-plan */
import { SafetyPlanCustomizeController } from './controllers/safety-plan-costumize-controller';
import { SafetyPlanManageEmployeeQuestionsController } from './controllers/safety-plan-manage-employee-questions-controller';
import { SafetyPlanManageReviewController } from './controllers/safety-plan-manage-review-controller';
import { SafetyPlanManageEmployeeTrainingMatrixController } from './controllers/safety-plan-manage-training-matrix-controller';
import { SafetyPlanReviewContactController } from './controllers/safety-plan-review-contact-controller';
import { SafetyPlanReviewEmployeeDocumentsController } from './controllers/safety-plan-review-employee-documents-controller';
import { SafetyPlanReviewHelpController } from './controllers/safety-plan-review-help-controller';
import { SafetyPlanReviewPresentationController } from './controllers/safety-plan-review-presentation-controller';
import { SafetyPlanReviewReportController } from './controllers/safety-plan-review-report-controller';
import { SafetyPlanWatchPresentationsController } from './controllers/safety-plan-rsc-watch-presentation-controller';
import { SafetyPlanService } from './services/safety-plan-service';
import { SafetyPlanCertificateFactory } from './factories/safety-plan-certificate-factory';
import { SafetyPlanPresentationFactory } from './factories/safety-plan-presentation-factory';
import { SafetyPlanQuestionFactory } from './factories/safety-plan-question-factory';
import { SafetyPlanReviewCandidateFactory } from './factories/safety-plan-review-candidate-factory';
import { SafetyPlanReviewFactory } from './factories/safety-plan-review-factory';
import { SafetyPlanTrainingDocumentFactory } from './factories/safety-plan-training-document-factory';
import { SafetyPlanTrainingMatrixRecordFactory } from './factories/safety-plan-training-matrix-record-factory';
import { SafetyPlanVideoFactory } from './factories/safety-plan-video-factory';

angular.module(
    'components.safety-plan',
    []
).controller({
    SafetyPlanCustomizeController,
    SafetyPlanManageEmployeeQuestionsController,
    SafetyPlanManageReviewController,
    SafetyPlanManageEmployeeTrainingMatrixController,
    SafetyPlanReviewContactController,
    SafetyPlanReviewEmployeeDocumentsController,
    SafetyPlanReviewHelpController,
    SafetyPlanReviewPresentationController,
    SafetyPlanReviewReportController,
    SafetyPlanWatchPresentationsController
}).service({
    SafetyPlanService
}).factory({
    SafetyPlanCertificate: SafetyPlanCertificateFactory,
    SafetyPlanPresentation: SafetyPlanPresentationFactory,
    SafetyPlanQuestion: SafetyPlanQuestionFactory,
    SafetyPlanReviewCandidate: SafetyPlanReviewCandidateFactory,
    SafetyPlanReview: SafetyPlanReviewFactory,
    SafetyPlanTrainingDocument: SafetyPlanTrainingDocumentFactory,
    SafetyPlanTrainingMatrixRecord: SafetyPlanTrainingMatrixRecordFactory,
    SafetyPlanVideo: SafetyPlanVideoFactory
}).run(function($templateCache) {
    $templateCache.put('./safety-plan-generate-training-matrix-dialog.html', require('./views/safety-plan-generate-training-matrix-dialog.html'));
    $templateCache.put('./safety-plan-review-confirmation-dialog.html', require('./views/safety-plan-review-confirmation-dialog.html'));
    $templateCache.put('./safety-plan-review-download-certificate-dialog.html', require('./views/safety-plan-review-download-certificate-dialog.html'));
    $templateCache.put('./safety-plan-review-intro-notification-dialog.html', require('./views/safety-plan-review-intro-notification-dialog.html'));
    $templateCache.put('./safety-plan-review-documents-filter.html', require('./views/review-documents/safety-plan-review-documents-filter.html'));
});
