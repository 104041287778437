import { get, merge } from 'lodash';

/**
 * Creates a new DashboardController.
 * @class
 */
class DashboardController {
    constructor($log, $ngRedux, $state, $transitions, $uiRouterGlobals, NavigationService, $timeout) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$transitions = $transitions;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.NavigationService = NavigationService;
        this.$timeout = $timeout;
    }

    $onInit() {
        this.$log.debug('Dashboard::init');
        this.display = true;
        this.unsubscribe = this.$ngRedux.connect(() => this.mapState.bind(this), {})(this);

        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        const module = navConfig ? navConfig.module : 'dashboard';
        const color = get(navConfig, 'color', null);

        if (module !== this.module) {
            this.$ngRedux.dispatch({
                type: 'INIT_MODULE',
                module,
                color,
            });
        }

        this.initComponents();

        this.onTransitionSuccess = this.$transitions.onSuccess({to: 'app.**'}, () => {
            // TODO check unnecessary calls if any
            this.initComponents();
        });
    }

    mapState(state) {
        const { dashboard, module } = state.navigation;
        dashboard && this.initComponents(dashboard);
        return {
            module,
            userType: state.app.user.typeCode,
            userTypeFilters: this.userTypeFilters || Object.entries(state).reduce((result,entry) => merge( result,
                get(entry[1],'navigationFilter') ? {[`app.${entry[0]}.dashboard`]: entry[1].navigationFilter} : {}
            ),{}),
        }
    }

    initComponents(configs) {
        this.display = false;
        if (configs && configs.length) {
            this.componentConfigs = configs;
        } else {
            const navConfig = this.NavigationService.getNavConfig();
            this.componentConfigs = (navConfig === undefined)
                ? this.navConfigs
                : navConfig.components.filter(item => item.order !== 0);
            this.componentConfigs = this.filterConfigsByUserType(this.componentConfigs);
        }
        this.$timeout(() => { this.display = true });
    }

    filterConfigsByUserType(configs) {
        const stateName = get(this.$state.$current, 'parent.self.component') === 'tabs' ? this.$state.$current.parent.name : this.$state.current.name;
        const enabledTiles = get(this.userTypeFilters, [stateName, (this.userType || 'default')]);
        if(enabledTiles) {
            configs = configs.filter(item => enabledTiles.includes(item.code));
        }
        return configs
    }

    /**
     * Get itemCode from tile definition to be optionally used as part of url.
     * After any state will save itemCode, all nested states will use that one.
     */
    navigateToState(item) {
        if (item.onClick) {
            item.onClick();
        } else {
            const params = {
                ...this.$uiRouterGlobals.params,
                ...(item.onNavigate && item.onNavigate()),
            };
            params.itemCode = params.itemCode || item.code;
            this.$state.go(item.state, params, { reload: this.$state.current.name });
        }
    }

    $onDestroy() {
        this.unsubscribe && this.unsubscribe();
        this.onTransitionSuccess && this.onTransitionSuccess();
    }
}

DashboardController.$inject = ['$log', '$ngRedux', '$state', '$transitions', '$uiRouterGlobals', 'NavigationService', '$timeout'];

export const dashboard = {
    bindings: {
        navConfigs: '<'
    },
    controller: DashboardController,
    template: require('./dashboard.html')
};
