/* @ngInject */
export function SafetyPlanReviewPresentationController(
    $scope,
    $state,
    $mdDialog,
    Notifier,
    $timeout,
    NetSpeedService,
    SAFETY_PLAN_REVIEW_NET_SPEED,
    SafetyTrainingService,
) {
    $scope.cursorState = 'wait';
    $scope.review = null;
    $scope.reviewVideos = null;
    $scope.selectedVideo = null;
    $scope.year = new Date().getFullYear();
    $scope.initializationInProgress = true;

    function showIntroDialog(scope) {
        $mdDialog.show({
            templateUrl: './safety-plan-review-intro-notification-dialog.html',
            controller: function ($scope, $mdDialog, color, title) {

                $scope.headerColor = color;
                $scope.headerTitle = title;
                $scope.numberOfVideos = $scope.reviewVideos.length;
                $scope.trainingDuration = $scope.reviewVideos.length * 8;

                $scope.submit = function(subject, message) {

                    $mdDialog.hide();
                };
            },
            scope: scope, // use parent scope in template
            preserveScope: true,
            locals: {
                color: $state.current.trainingColor,
                title: 'Safety Plan Review Intro'
            },
            clickOutsideToClose: false
        });
    }

    $scope.fetchReviewData = function () {

        $scope.cursorState = 'wait';

        SafetyTrainingService.getCandidateReview($state.current.trainingType, null, $scope.year, null)
            .then(function (data) {

                if (data.result != null) {
                    $scope.review = data.result;

                    $scope.cursorState = 'wait';
                    SafetyTrainingService.getPresentationVideos($state.current.trainingType, null, $scope.review.presentation.id)
                        .then(function (items) {

                            //  console.log("Presentation videos retrieved!");
                            $scope.reviewVideos = items.list;

                            if (Number($scope.review.nextSection) === 0) {
                                $scope.cursorState = 'default';
                                showIntroDialog($scope);
                            }

                            for (var i = 0; i < $scope.reviewVideos.length; i++) {
                                if ($scope.review.nextSection == $scope.reviewVideos[i].presentationOrder) {
                                    $scope.scheduledVideo = $scope.reviewVideos[i];
                                    $scope.selectedVideo = $scope.reviewVideos[i];
                                }
                            }
                            $scope.cursorState = 'default';
                            $scope.initializationInProgress = false;
                        }, function (error) {
                            Notifier.error("Error loading user presentation review data.");
                            $scope.cursorState = 'default';
                            $scope.initializationInProgress = false;
                        });

                } else {
                    //No sheduled review
                    $timeout(function () {
                        $scope.selectedVideo = null;
                        $scope.scheduledVideo = null;
                        $scope.reviewVideos = null;
                        Notifier.info("There are no more pending Safety Plan reviews");
                        $scope.initializationInProgress = false;
                        $scope.cursorState = 'default';
                    }, 300);
                }

            }, function (error) {
                $scope.selectedVideo = null;
                $scope.scheduledVideo = null;
                $scope.reviewVideos = null;
                Notifier.error("Error loading user presentation review data.");
                $scope.initializationInProgress = false;
                $scope.cursorState = 'default';
            });
    };

    $scope.checkNetSpeed = function () {
        NetSpeedService.testNetSpeed()
            .then(function (result) {
                Notifier.info("Measured net speed is:" + result + " Kbps.");
                //console.log("Measured net speed is:" + result + " kbs.");
                if (result < SAFETY_PLAN_REVIEW_NET_SPEED.BANDWIDTH_LIMIT) {
                    Notifier.warn("Your network connection seems to be slow. You may experience difficulties watching presentation video. In case of video stuttering please pause playback and wait a bit for video to buffer, then continue.");
                }
            }, function (error) {
                //console.log("Could not measure speed, error:" + error);
            });
    };

    $scope.videoPlaybackEnded = function (video) {
        //console.log("Video with uri="+video.uri+" and videoSectionNumber="+video.videoSectionNumber+"playback has ended!");
        var maxVideoPresentationOrder = $scope.reviewVideos.reduce(function (max, item) {
            return parseInt(item.presentationOrder) > max ? parseInt(item.presentationOrder) : max;
        }, 0);
        if (video.presentationOrder == maxVideoPresentationOrder) {
            //show confirmation dialog
            $scope.onSafetyPlanReviewFinished(video);
        } else {

            $scope.initializationInProgress = true;
            $scope.cursorState = 'wait';
            SafetyTrainingService.updateEmployeeReview($state.current.trainingType, $scope.review.id, video.presentationOrder)
                .then(function (items) {
                    $scope.fetchReviewData();
                    $scope.initializationInProgress = false;
                    $scope.cursorState = 'default';
                }, function (error) {
                    Notifier.error("Error updating user review.");
                    $scope.initializationInProgress = false;
                    $scope.cursorState = 'default';
                });
        }
    };

    $scope.onSafetyPlanReviewFinished = function (item) {
        $mdDialog.show({
            controller: SafetyPlanReviewConfirmationController,
            templateUrl: './safety-plan-review-confirmation-dialog.html',
            scope: $scope, // use parent scope in template
            preserveScope: true,
            locals: {
                color: $state.current.trainingColor,
                title: 'Safety Plan Review Acknowledgement',
                reviewName: 'Safety Plan Review',
                video: item
            },
            clickOutsideToClose: false
        });
    };

    function SafetyPlanReviewConfirmationController($scope, $mdDialog, color, title, reviewName, video) {
        $scope.headerColor = color;
        $scope.headerTitle = title;
        $scope.reviewName = reviewName;

        $scope.cancel = function () {
            $timeout(function () {
                $scope.fetchReviewData();
            }, 200);
            $mdDialog.cancel();
        };

        $scope.submit = function (subject, message) {
            var finishedReviewId = $scope.review.id;
            SafetyTrainingService.updateEmployeeReview($state.current.trainingType, finishedReviewId, video.presentationOrder)
                .then(function (items) {
                    $scope.fetchReviewData();
                    $mdDialog.hide();
                    $timeout(function () {
                        safetyPlanReviewGetCertificateDialog($scope, finishedReviewId, $scope.reviewName);
                    }, 200);
                }, function (error) {
                    Notifier.error("Error updating user review.");
                    $scope.cursorState = 'default';
                    $mdDialog.hide();
                });
        };

        $scope.askRso = function () {
            $state.go('app.safetyPlan.radiation.reviewHelp', {}, {reload: $state.current.name});
        };
    }

    function safetyPlanReviewGetCertificateDialog(scope, finishedReviewId, reviewName) {
        $mdDialog.show({
            controller: SafetyPlanReviewGetCertificate,
            templateUrl: './safety-plan-review-download-certificate-dialog.html',
            scope: scope, // use parent scope in template
            preserveScope: true,
            locals: {
                color: $state.current.trainingColor,
                title: 'Safety Plan Download Certificate',
                finishedReviewId: finishedReviewId,
                reviewName: reviewName
            },
            clickOutsideToClose: false
        });
    }

    function SafetyPlanReviewGetCertificate($scope, $mdDialog, color, title, finishedReviewId, reviewName) {
        $scope.headerColor = color;
        $scope.headerTitle = title;
        $scope.reviewName = reviewName;

        $scope.cancelDownload = function () {
            $timeout(function () {
                $scope.fetchReviewData();
            }, 200);
            $mdDialog.cancel();
        };

        $scope.download = () => {
            SafetyTrainingService.generateCertificate($state.current.trainingType, finishedReviewId).then(() => {
                $mdDialog.hide();
                $scope.fetchReviewData();
            }, () => {
                Notifier.error("Error generating certificate!");
                $mdDialog.hide();
                $scope.fetchReviewData();
            });
        };
    }

    $scope.checkNetSpeed();
    $scope.fetchReviewData();
}
