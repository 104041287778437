import moment from 'moment';
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';

/**
 * Creates a new EquipmentCalibrationAssetCostController.
 * @class
 */
class EquipmentCalibrationAssetCostController {
    constructor($ngRedux, $state, $scope, NavigationService, Notifier, EquipmentCalibrationActions) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$scope = $scope;
        this.NavigationService = NavigationService;
        this.Notifier = Notifier;
        this.actions = EquipmentCalibrationActions;
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);

        if (this.equipment.id === null) {
            this.identifier = 'app.equipmentCalibration.add';
            this.state = 'app.equipmentCalibration.add.assetCost';
        }
        else {
            this.identifier = 'app.equipmentCalibration.manage';
            this.state = 'app.equipmentCalibration.manage.assetCost';
        }
    }

    setWarrantyExpiryDate() {
        const { purchaseDate, warranty_period } = this.equipment;
        if (
            moment.isDate(purchaseDate) &&
            isNumber(warranty_period) &&
            !isNaN(warranty_period)
        ) {
            this.$ngRedux.dispatch({
                type: 'EQUIPMENT_ASSET_WARRANTY_EXPIRY_DATE',
                id: 'equipment',
                date: moment(purchaseDate).add(warranty_period, 'months').toDate(),
            });
        }
    }

    onReport() {
        this.$scope.$broadcast('show-errors-check-validity', '$ctrl.equipmentAssetCostForm');
        if (this.equipmentAssetCostForm.$valid) {
            this.Notifier.info('Please wait...', false);
            this.generateDepreciationReport(this.equipment.id, this.equipment.visualId)
                .then(() => this.Notifier.success('Success'))
                .catch(() => this.Notifier.error('System Error!'));
        } else {
            this.Notifier.error('Please fill in the form fields');
        }
    }

    manageEquipmentAssetCost() {
        this.$scope.$broadcast('show-errors-check-validity', '$ctrl.equipmentAssetCostForm');
        if (this.equipmentAssetCostForm.$valid) {
            if (this.equipment.id) {
                if (this.equipment.estimated_residual_value < this.equipment.assetCost) {
                    this.editEquipment(this.equipment).then(() => {
                        this.Notifier.success('Asset cost has been successfully saved');
                    });
                } else {
                    this.Notifier.error('The estimated residual value cannot be higher than the cost of the asset');
                }
            }
        } else {
            this.Notifier.error('Please fill in the form fields');
        }
    }

    mapState(state) {
        return {
            equipment: state.equipmentCalibration.equipment
        }
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

EquipmentCalibrationAssetCostController.$inject = ['$ngRedux', '$state', '$scope', 'NavigationService', 'Notifier', 'EquipmentCalibrationActions'];

export const equipmentCalibrationAssetCost = {
    controller: EquipmentCalibrationAssetCostController,
    template: require('./equipment-calibration-asset-cost.html')
};
