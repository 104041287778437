import get from 'lodash/get';

/**
 * Creates a new AuthPasswordSaveController.
 * @class
 */
class AuthPasswordSaveController {
    constructor($log, $ngRedux, $state, RecoveryService, ResponseStatusService) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.RecoveryService = RecoveryService;
        this.ResponseStatusService = ResponseStatusService;
    }

    $onInit() {
        this.$log.debug('PasswordSave::init');
        this.initHeadline();
        this.password = null;
        this.passwordVerify = null;

        if (this.tokenStatus) {
            const responseStatus = this.ResponseStatusService.find(this.tokenStatus);
            this.$state.transitionTo('recovery.passwordReset', {message: get(responseStatus, 'msg', 'System Error!')});
        }
    }

    savePassword() {
        this.initHeadline();
        if (this.recoveryForm.$valid) {
            this.$ngRedux.dispatch({type: 'LOADING_START'});
            this.RecoveryService.resetPasswordExec(this.password).then(() => {
                this.$ngRedux.dispatch({type: 'LOADING_END'});
                this.$state.transitionTo('login.auth', {message: 'Your password has been changed successfully. Please Login.'});
            }, response => {
                this.$ngRedux.dispatch({type: 'LOADING_END'});
                const responseStatus = this.ResponseStatusService.find(response.data.status);
                if (responseStatus)
                    this.$state.transitionTo('recovery.passwordReset', {message: responseStatus.msg});
                else {
                    this.headlineClass = 'md-headline-error';
                    this.headline = 'System Error!';
                }
            });
        }
    }

    initHeadline() {
        this.headlineClass = 'md-headline';
        this.headline = this.$state.current.params.headline;
    }
}

AuthPasswordSaveController.$inject = ['$log', '$ngRedux', '$state', 'RecoveryService', 'ResponseStatusService'];

export const authPasswordSave = {
    bindings: {
        tokenStatus: '<'
    },
    controller: AuthPasswordSaveController,
    template: require('./auth-password-save.html')
};
