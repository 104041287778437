import * as PublicTasksActions from './public-task.actions'
import {IDS as SCHEME_IDS} from '../scheme/scheme-action-id.constants';
import {IDS as ACC_IDS} from '../accreditation-action-id.constants';
import {TYPES as ACC_TYPES} from "../accreditation-action-type.constants";
/**
 * Creates a new PublicTask.
 * @class
 */
class PublicTask {
    constructor($ngRedux,
                $state,
                $stateParams,
                $scope,
                $filter,
                $location,
                AuthService,
                EmployeeService

    ) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        this.$filter = $filter;
        this.$location = $location;
        this.AuthService = AuthService;
        this.EmployeeService = EmployeeService;
        this.title = $filter('translate')('ACCREDITATION.PUBLIC_TASK.TITLE');
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect( state => ({}), PublicTasksActions )(this);

        this.taskDetailsHidden = true;
        this.accredDetailsHidden = true;
        this.buttonContinueDisabled = true;

        this.AuthService.publicLogin(this.$stateParams.token).then(loginResponse => {
            if(!loginResponse.data.task)
                return;
            const {
                task_id,
                task_number,
                type,
                status,
                due_date,
                name,
                description,
                emp_uuid,
                username,
                first_name,
                last_name,
                email
            } = loginResponse.data.task;

            const taskTypeName = this.$filter('translate')(`ACCREDITATION.TASKS.TYPE.${type.toUpperCase()}`);
            this.title = `${task_number} - ${taskTypeName} - ${name}`;
            this.assignee = (first_name || last_name) ? `${first_name} ${last_name}` : '';
            if(username)
                this.assignee = `${this.assignee} (${username})`
            this.email = email;
            this.dueDate = due_date;
            this.statusName = this.$filter('translate')(`ACCREDITATION.TASKS.STATUS.${status.toUpperCase()}`);

            this.taskDetailsHidden = false;

            const userTokenStatus = loginResponse.data.userTokenStatus;
            if(userTokenStatus !== 'valid'){
                const warning = this.$filter('translate')(`ACCREDITATION.PUBLIC_TASK.TOKEN_STATUS_WARNING.${userTokenStatus.toUpperCase()}`);
                this.title = `${this.title} - [${warning.toUpperCase()}]`;
            }
            else {
                this.fetchAccreditationRequestDetails(task_id).then(response => {
                    const details = response.data;
                    this.fiscalYear = details.fiscal_year;
                    this.siteLocationName = details.slo_name;
                    this.nextStateParams = {
                        title: this.title,
                        itemCode: details.acc_code,
                        standard: details.ast_id,
                        year:     details.fiscal_year,
                        location: details.slo_uuid,
                        assessmentItem: details.aas_id,
                        task: {
                            uta_id:         task_id,
                            uta_type:       type,
                            status_code:    status,
                            employee_uuid:  emp_uuid,
                            assignee:       this.assignee,
                            task_number,
                            email,
                            name,
                            description,
                            due_date,
                            arq_id:         details.arq_id,
                            acc_code:       details.acc_code,
                            ast_id:         details.ast_id,
                            ast_level:      details.ast_level,
                            standard_name:  details.ast_name,
                            location_uuid:  details.slo_uuid,
                            fiscal_year:    details.fiscal_year,
                        },
                    }
                    this.buttonContinueDisabled = false;
                    this.accredDetailsHidden = false;
                });
            }
        })
    }
    onButtonContinue() {
        let nextState = SCHEME_IDS.app_accreditation_scheme_manage_progress;
        const isTaskTypeEvidence = this.nextStateParams.task.uta_type === 'utaevd';
        if(isTaskTypeEvidence) {
            nextState = ACC_IDS.app_accreditation_scheme_add_document;
            this.$ngRedux.dispatch({ // reset state.accreditation.accDocumentAdd.assessmentItem
                type: ACC_TYPES.SELECT_ASSESSMENT_ITEM,
                id: ACC_IDS.accMasterDocListAdd,
                data: { assessmentItem: null },
            });
        }
        else if(this.AuthService.isVisitor()) {
            nextState = SCHEME_IDS.public_accreditation_scheme_progress;
        }
        this.$state.go(nextState, this.nextStateParams);
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

PublicTask.$inject = [
    '$ngRedux',
    '$state',
    '$stateParams',
    '$scope',
    '$filter',
    '$location',
    'AuthService',
    'EmployeeService'
]

export const publicTask = {
    bindings: {
    },
    controller: PublicTask,
    template: require('./public-task.html')
}
