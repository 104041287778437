/**
 * @module SafetyTraining
 */
import 'angular-media-events';
import 'oclazyload';

import * as STATES_PUBLIC from '../../../components/safety-training/safety-training.states.public';

import { SafetyTrainingManageReviewController } from './controllers/safety-training-manage-review-controller';
import { SafetyTrainingReviewAnimationController } from './controllers/safety-training-review-animation-controller';
import { SafetyTrainingReviewPresentationController } from './controllers/safety-training-review-presentation-controller';
import { SafetyTrainingPresentationFactory } from './animations/safety-training-animation.factory';
import { SafetyTrainingPresentationReviewFactory } from './review/safety-training-review.factory';
import { SafetyTrainingVideoFactory } from './video/safety-training-video.factory';
import { SafetyTrainingService } from './safety-training.service';
import { stVideo } from '../../../components/safety-training/video/st-video.component';
import { stVideoAcknowledgement } from '../../../components/safety-training/acknowledgement/st-video-acknowledgement.component';
import { stVideoConfirmation } from '../../../components/safety-training/confirmation/st-video-confirmation.component';

angular.module(
    'components.safety-training',
    ['media-events', 'oc.lazyLoad']
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values({
        ...STATES_PUBLIC,
    }).map(state => stateRegistry.register(state));
}]).component({
    stVideo,
    stVideoAcknowledgement,
    stVideoConfirmation
}).controller({
    SafetyTrainingManageReviewController,
    SafetyTrainingReviewAnimationController,
    SafetyTrainingReviewPresentationController,
}).factory({
    SafetyTrainingPresentation: SafetyTrainingPresentationFactory,
    SafetyTrainingPresentationReview: SafetyTrainingPresentationReviewFactory,
    SafetyTrainingVideo: SafetyTrainingVideoFactory
}).service({
    SafetyTrainingService
}).run(['$templateCache', ({ put }) => {
    put('public.safetyTraining.laser.video.player', '<st-video></st-video>');
    put('public.safetyTraining.laser.video.acknowledgement', '<st-video-acknowledgement></st-video-acknowledgement>');
    put('public.safetyTraining.laser.video.confirmation', '<st-video-confirmation></st-video-confirmation>');
    put('./safety-training-review-confirmation-dialog.html', require('./views/safety-training-review-confirmation-dialog.html'));
    put('./safety-training-review-download-certificate-dialog.html', require('./views/safety-training-review-download-certificate-dialog.html'));
    put('./safety-training-set-review-due-date-dialog.html', require('./views/safety-training-set-review-due-date-dialog.html'));
}]);
