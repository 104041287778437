/**
 * @const INIT_MODULE
 * @type {string}
 */
export const INIT_MODULE = 'INIT_MODULE';

/**
 * @const NAVIGATION_STATE
 * @type {string}
 */
export const NAVIGATION_STATE = 'NAVIGATION_STATE';

/**
 * @const NAVIGATION_CONFIGS
 * @type {string}
 */
export const NAVIGATION_CONFIGS = 'NAVIGATION_CONFIGS';

/**
 * @const INIT_DASHBOARD
 * @type {string}
 */
export const INIT_DASHBOARD = 'INIT_DASHBOARD';

/**
 * @const LOADING
 * @type {string}
 */
export const LOADING = 'LOADING';
