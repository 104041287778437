/* @ngInject */
export function SelectEquipmentDocumentStatusController($ngRedux, DocumentService) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, {})(this);
        ctrl.options = [];

        DocumentService.fetchEquipmentDocumentStatuses().then(function(items) {
            ctrl.options = items;
        });
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            $ngRedux.dispatch({
                type: 'EQUIPMENT_DOCUMENT_STATUS_SELECTED',
                id: 'equipmentDocumentsGrid',
                status: ctrl.selectedOption
            });
        }
    };

    function mapStateToThis(state) {
        return {
            selectedOption: state.equipment[ctrl.identifier].filter.documentStatusId,
        };
    }
}
