import API from '../../../common/http/api.fetch';
import * as TYPE from '../radioactive-materials.actions.types';
import { formatDate } from '../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../common/utils/util.constants';
import get from 'lodash/get';
import {saveAs} from "file-saver";

export const getTests = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/tests`,
            method: 'GET',
            params: {
                status: filter.status,
                location: filter.location,
                type: filter.type,
                isotope: filter.isotope,
                activityFrom: filter.activityFrom && filter.activityFrom !== '' ? filter.activityFrom : null,
                activityTo: filter.activityTo && filter.activityTo !== '' ? filter.activityTo : null,
                unit: filter.unit,
                sealed: filter.sealed,
                wipeTest: filter.wipeTest,
                wipeTestFrom: formatDate(filter.wipeTestFrom, DATE_FORMAT.OUTPUT),
                wipeTestTo: formatDate(filter.wipeTestTo, DATE_FORMAT.OUTPUT),
                serviceFrom: formatDate(filter.serviceFrom, DATE_FORMAT.OUTPUT),
                serviceTo: formatDate(filter.serviceTo, DATE_FORMAT.OUTPUT),
                manufacturer: filter.manufacturer,
                model: get(filter.model, 'id'),
                permitLicenseNo: filter.permitLicenseNo !== '' ? filter.permitLicenseNo : null,
                offset: filter.offset,
                limit: filter.limit,
                exportFormat: filter.exportFormat,
                exportColumns: filter.exportColumns,
                order_column:   get(filter, 'sort.column'),
                order:          get(filter, 'sort.value')
            }
        }).then(({ data }) => {
            if (filter.exportFormat) return data;
            const activeCount = parseInt(data.totalActive);
            const archiveCount = parseInt(data.totalArchived);
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.items.map((item) => ({
                    ...item,
                    class: item.status_code === 'dis' ? 'orange' : null
                })),
                itemsCount: filter.status === 'act' ? activeCount : archiveCount,
                activeCount,
                archiveCount
            })
        });
    }
}

export const saveTest = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/test/save`,
            method: 'POST',
            data: {
                id: data.id,
                location: data.location,
                visualId: data.visualId,
                testType: data.testType,
                isotope: data.isotope,
                atomicNum: data.atomicNum,
                sealed: data.sealed,
                activityDate: formatDate(data.activityDate, DATE_FORMAT.OUTPUT),
                activity: data.activity,
                unit: data.unit,
                exemptionQuantity: data.exemptionQuantity,
                wipeTesting: data.wipeTesting,
                wipeTestingDueDate: formatDate(data.wipeTestingDueDate, DATE_FORMAT.OUTPUT),
                calibrationAndService: data.calibrationAndService,
                calibrationDueDate: formatDate(data.calibrationDueDate, DATE_FORMAT.OUTPUT),
                serviceDueDate: formatDate(data.serviceDueDate, DATE_FORMAT.OUTPUT),
                assetId: data.assetId,
                manufacturer: data.manufacturer,
                model: get(data.model, 'id'),
                modelSerial: data.modelSerial,
                //permitLicenseNo: data.permitLicenseNo,
                useType: data.useType,
                locationLicence: data.locationLicence,
                manufacturerOfRadiationDevice: data.manufacturerOfRadiationDevice,
                modelOfRadiationDevice: get(data.modelOfRadiationDevice, 'id'),
                modelSerialOfRadiationDevice: data.modelSerialOfRadiationDevice,
                gallery: data.gallery.length ? data.gallery.map((item) => item.id).join(',') : null
            }
        }).then(() => {
            if (data.id) {
                dispatch({
                    type: 'GRID_RELOAD',
                    id: 'rmTestGrid'
                })
            } else {
                dispatch({
                    type: TYPE.RM_TEST_RESET,
                    id: 'rmTest',
                })
            }
        });
    }
}

export const getTestDetails = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/test/details`,
            method: 'GET',
            params: { id }
        }).then(({ data }) => {
            dispatch({
                type: TYPE.RM_TEST_DETAILS,
                id: 'rmTest',
                data: { id, ...data[0][0] },
                locations: data[1],
                gallery: data[2].map(({ file_id, file_url, file_width, file_height }) => {
                    return {
                        id: parseInt(file_id),
                        url: API.img(file_url),
                        width: parseInt(file_width),
                        height: parseInt(file_height)
                    }
                })
            })
        });
    }
};

export const deleteTest = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/test/delete`,
            method: 'GET',
            params: { id }
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'rmTestGrid'
            })
        });
    }
};

export const getTestTypes = () => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/test/types`,
            method: 'GET',
            params: {}
        }).then(({ data }) => {
            dispatch({
                type: TYPE.RM_TEST_TYPES,
                id: 'rmTest',
                path: 'testTypes',
                items: data.map((item) => {
                    return {
                        value: item.type_id,
                        label: item.name
                    };
                }),
            })
        });
    }
}

export const saveTestType = (name) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/test/type/save`,
            method: 'POST',
            data: { name }
        }).then(({ data }) => {
            dispatch({
                type: 'INPUT_SELECT',
                id: 'rmTest',
                path: 'testType',
                input: String(data.params.id)
            })
        });
    }
}

export const getTestUseTypes = () => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/test/use-types`,
            method: 'GET',
            params: {}
        }).then(({ data }) => {
            dispatch({
                type: TYPE.RM_TEST_USE_TYPES,
                id: 'rmTest',
                path: 'useTypes',
                items: data.map((item) => {
                    return {
                        value: item.code,
                        label: item.name
                    };
                }),
            })
        });
    }
}

export const getCurrentActivity = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/test/activity`,
            method: 'POST',
            data
        }).then(({ data }) => {
            dispatch({
                type: TYPE.RM_TEST_ACTIVITY,
                id: 'rmTest',
                path: 'currentActivity',
                value: data.params.current_activity
            })
        });
    }
}

export const getTestManufacturers = () => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/manufacturers`,
            method: 'GET',
            params: {}
        }).then(({ data }) => {
            dispatch({
                type: TYPE.RM_TEST_MANUFACTURERS,
                id: 'rmTest',
                path: 'manufacturers',
                items: data.map((item) => {
                    return {
                        value: item.man_id,
                        label: item.man_name
                    };
                }),
            })
        });
    }
}

export const saveTestManufacturer = (name, path) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/manufacturer/save`,
            method: 'POST',
            data: { name }
        }).then(({ data }) => {
            dispatch({
                type: 'INPUT_SELECT',
                id: 'rmTest',
                path: path,
                input: String(data.params.man_id)
            })
        });
    }
}

export const getTestManufacturerModels = (id, path) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/manufacturer/models`,
            method: 'GET',
            params: { id }
        }).then(({ data }) => {
            dispatch({
                type: TYPE.RM_TEST_MANUFACTURER_MODELS,
                id: 'rmTest',
                path: path,
                items: data.items.map((item) => {
                    return {
                        id: item.emo_id,
                        name: item.equipment_model
                    };
                }),
            })
        });
    }
}

export const saveTestManufacturerModel = (man_id, sty_id, name, path) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/manufacturer/model/save`,
            method: 'POST',
            data: { man_id, sty_id, name }
        }).then(({ data }) => {
            dispatch({
                type: 'AUTOCOMPLETE_SELECTED',
                id: 'rmTest',
                path: path,
                item: {
                    id: String(data.params.emo_id),
                    name
                }
            })
        });
    }
}

export const getTestDisposalCompanies = () => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/test/disposal/companies`,
            method: 'GET',
            params: {}
        }).then(({ data }) => {
            dispatch({
                type: TYPE.RM_TEST_DISPOSAL_COMPANIES,
                id: 'rmTest',
                path: 'disposalCompanies',
                items: data.items.map((item) => {
                    return {
                        id: item.supplier_id,
                        name: item.supplier_name,
                        address: item.supplier_address,
                        email: item.supplier_email,
                        phone: item.supplier_phone
                    };
                }),
            })
        });
    }
}

export const disposeTest = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/test/dispose`,
            method: 'POST',
            data
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'rmTestGrid'
            })
        });
    }
}

export const saveDisposalCompany = (data) => {
    return () => {
        return API.fetch({
            url: `/radioactive-materials/test/disposal/company/save`,
            method: 'POST',
            data
        });
    }
}

export const getTestWipeTestDetails = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/test/wipe-test/details`,
            method: 'GET',
            params: { id }
        }).then(({ data }) => {
            if (data[1][0]) {
                dispatch({
                    type: TYPE.RM_TEST_WIPE_TEST_EFFICIENCY,
                    id: 'rmTestWipeTest',
                    data: {...data[1][0]}
                })
            }
        });
    }
}

export const saveTestWipeTest = (id, data) => {
    return () => {
        return API.fetch({
            url: `/radioactive-materials/test/wipe-test/save`,
            method: 'POST',
            data: {
                id: id,
                wearingLabcoat: data.wearingLabcoat,
                wearingGloves: data.wearingGloves,
                wearingTLDBadge: data.wearingTLDBadge,
                cottonBud: data.cottonBud,
                filterPaperWipes: data.filterPaperWipes,
                forceps: data.forceps,
                countingTime: data.countingTime,
                difference: data.difference,
                calculatedResult: data.calculatedResult,
                approvalDate: formatDate(data.approvalDate, DATE_FORMAT.OUTPUT),
                signature: data.signature,
                plasticBag: data.plasticBag,
                measuredActivityWithin20: data.measuredActivityWithin20,
                removedByBiomedicalEngineer: data.removedByBiomedicalEngineer,
                wipeTestSystem: data.wipeTestSystem,
                measureCalibrationSources: data.measureCalibrationSources,
                backgroundCount: data.backgroundCount,
                measuredCount: data.measuredCount,
                comment: data.comment,

                min30warmup: data.min30warmup,
                eu152Calibration: data.eu152Calibration,
                cs137Calibration: data.cs137Calibration,
                cs137ConstancyCheck: data.cs137ConstancyCheck,
                deviation: data.deviation,
                MCAButton: data.MCAButton
            }
        });
    }
}

export const printWipeTest = (data) => {
    return () => {
        return API.fetch({
            url: '/radioactive-materials/test/wipe-test/print-certificate',
            method: 'POST',
            responseType: 'arraybuffer',
            data: {
                id: data.id
            }
        //}).then((response) => {
        //    saveAs(new Blob([response.data], {type: 'application/pdf'}), `leak-test-certificate.pdf`);
        });
    }
}

export const getDocuments = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/test/documents`,
            method: 'GET',
            params: {
                testId:             filter.testId,
                documentTypeId:     filter.documentTypeId,
                documentStatusId:   filter.documentStatusId,
                offset:             filter.offset,
                limit:              filter.limit
            }
        }).then((response) => {
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: response.data.list.map(item => {
                    return {
                        id: item.doc_id,
                        documentType: item.doc_type_name,
                        uri: item.doc_url,
                        dueDate: formatDate(item.due_date, DATE_FORMAT.INPUT),
                        status: item.doc_status_name
                    }
                }),
                itemsCount: response.data.rows
            })
        });
    }
}

export const saveDocument = (event, document, testId) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/test/add-document`,
            method: "POST",
            data: {
                testId: parseInt(testId),
                documentType: parseInt(document.type),
                nextDueDate: formatDate(document.documentDueDate),
                documentUri: event[0].filePath
            }
        }).then(() => {
            dispatch({
                type: 'GRID_RESET',
                id: 'rmTestDocumentsGrid'
            });
            dispatch({
                type: 'REST_RM_TEST_DOCUMENT',
                id: 'rmTestDocument'
            });
        });
    }
}

export const invalidateDocument = (documentId) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/test/invalidate-document`,
            method: "POST",
            params: {
                documentId: documentId
            }
        }).then(() => {
            dispatch({
                type: 'GRID_RESET',
                id: 'rmTestDocumentsGrid'
            });
            dispatch({
                type: 'REST_RM_TEST_DOCUMENT',
                id: 'rmTestDocument'
            });
        });
    }
}
