/* @ngInject */
export function MultiSelectEquipmentCategoryController($ngRedux, EquipmentActions) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, EquipmentActions)(ctrl);
        ctrl.getEquipmentTypeTree(ctrl.identifier);
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOptions.length) {
            $ngRedux.dispatch({
                type: 'EQUIPMENT_CATEGORY_SELECTED',
                id: ctrl.identifier,
                categories: ctrl.selectedOptions
            });
        }
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    function mapStateToThis(state) {
        return {
            groups: _.get(state, getStateContainer('categories'), []),
            selectedOptions: _.get(state, getStateContainer('filter.categories'), [])
        };
    }

    function getStateContainer(statePath) {
        return [ctrl.module, ctrl.identifier].join('.') + '.' + statePath;
    }
}
