/* @ngInject */
export function DicomQCTestManager() {

    this.TAB_DICOM_QC_TEST = 0;
    this.TAB_DICOM_QC_TEST_PLOT_DIAGRAM = 1;

    this.tplDicomQCTest    = './dicom-quality-control-test-add.html';
    this.tplDicomQCTestPlotDiagram    = './dicom-quality-control-plot-diagram.html';

    this.tabs = [
        {
            id: this.TAB_DICOM_QC_TEST,
            title: 'New Test',
            content: this.tplDicomQCTest,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: false,
            stateless: true,
            navigation: true
        },
        {
            id: this.TAB_DICOM_QC_TEST_PLOT_DIAGRAM,
            title: 'Plot diagram',
            content: this.tplDicomQCTestPlotDiagram,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: false,
            stateless: true,
            navigation: true
        }
    ];
}
