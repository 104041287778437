import { GridReducer } from '../common/grid/grid.reducer';
import { administrationNotificationSettingsGrid } from '../components/administration/notification-settings/grid/administration-notification-settings-grid.component';
import merge from 'lodash/merge';
import set from 'lodash/set';
import update from 'lodash/update';

/**
 * @function administrationNotificationSettingsReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */

export function administrationNotificationSettingsReducer(state, action) {
    if (typeof state === 'undefined') {
        return {
            administrationNotificationSettingsGrid: merge(GridReducer(), {
                filter: {
                    location: null,
                    limit: null
                }
            }),
            administrationNotificationSettingsTimelineGrid: merge(GridReducer(), {
                filter: {
                    limit: null
                }
            }),
            administrationNotificationSettings: {
                notificationId: null,
                location: null,
                locationName: null,
                siteLocationName: null,
                headOfficeName: null,
                messageSubject: null,
                notificationTypeId: null,
                notificationTypeName: null,
                overriden: 0,
                suppressNotifications: null,
                suppressNotificationsData: [{value: 1, label: 'Inactive'}, {value: 0, label: 'Active'}],
                derived: 0
            },
            administrationNotificationSettingsTimeline: {
                repeatFrom: 0,
                repeat: 0
            }
        };
    }
    switch (action.type) {
        case 'LOCATION_SELECTED':
            if (action.id === 'administrationNotificationSettingsGrid') {
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.location', action.uuid)));
            }
        case 'GRID_INIT':
            if (action.id === 'administrationNotificationSettingsGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return Object.assign({}, filter, {
                        location: null,
                    });
                })));
            }
        case 'GRID_OVERRIDE':
        case 'SHOW_LOADING':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
            if (action.id === 'administrationNotificationSettingsGrid') {
                return Object.assign({}, state, {
                    administrationNotificationSettingsGrid: GridReducer(state.administrationNotificationSettingsGrid, action)
                });
            }
            if (action.id === 'administrationNotificationSettingsTimelineGrid') {
                return Object.assign({}, state, {
                    administrationNotificationSettingsTimelineGrid: GridReducer(state.administrationNotificationSettingsTimelineGrid, action)
                });
            }
        case 'GRID_RESET':
            if (action.id === 'administrationNotificationSettingsGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return Object.assign({}, filter, {
                        location: null
                    });
                })));
            }
        case 'INPUT_TEXT_CHANGE':
        case 'CHECKBOX_SELECTED':
        case 'INPUT_RADIO_CHANGE':
            if (action.id === 'administrationNotificationSettings') {
                return {
                    ...state,
                    administrationNotificationSettings: Object.assign({}, state.administrationNotificationSettings, action.data)
                }
            }
            if (action.id === 'administrationNotificationSettingsTimeline') {
                return {
                    ...state,
                    administrationNotificationSettingsTimeline: Object.assign({}, state.administrationNotificationSettingsTimeline, action.data)
                }
            }
        case 'INPUT_SELECT':
            if (action.id === 'administrationNotificationSettingsTimeline') {
                return {
                    ...state,
                    administrationNotificationSettingsTimeline: Object.assign({}, state.administrationNotificationSettingsTimeline, action.item)
                }
            }
        case 'NOTIFICATION_SETTINGS_TIMELINE_RESET':
            return {
                ...state,
                administrationNotificationSettingsTimeline: {
                    repeat: 0,
                    repeatFrequency: null,
                    repeatFrom: 0,
                    subjectTypeCode: null,
                    subjectTypeId: null,
                    subjectTypeName: null
                }
            };
        case 'NOTIFICATION_SETTINGS_SUBJECT_TYPES':
            if (action.id === 'administrationNotificationSettings') {
                return {
                    ...state,
                    administrationNotificationSettings: Object.assign({}, state.administrationNotificationSettings, {subjectTypes: action.items})
                }
            }
        case 'NOTIFICATION_DETAILS':
            if (action.id === 'administrationNotificationSettings') {
                return Object.assign({}, state, {
                    administrationNotificationSettings: Object.assign({}, state.administrationNotificationSettings, {
                        notificationId: action.data.nse_id,
                        location: action.data.loc_uuid,
                        locationName: action.data.location,
                        siteLocationName: action.data.sitelocation_name,
                        headOfficeName: action.data.headoffice_name,
                        messageSubject: action.data.message_subject,
                        notificationTypeId: action.data.nty_id,
                        notificationTypeName: action.data.nty_name,
                        overriden: parseInt(action.data.overriden),
                        suppressNotifications: parseInt(action.data.suppress_notifications),
                        derived: parseInt(action.data.derived)
                    })
                });
            }
        case 'COUNT_ITEMS':
            if (action.id === 'administrationNotificationSettingsGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return Object.assign({}, filter, {
                        limit: 20,
                    });
                })));
            }
            if (action.id === 'administrationNotificationSettingsTimelineGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return Object.assign({}, filter, {
                        limit: 20,
                    });
                })));
            }
        default:
            return state;
    }
}
