import { api } from '../../bootstrap.json';

/**
 * @function API
 * @type {{getInstance: (function(): *)}}
 */
const API = (() => {
    let instance;
    let fetch = angular.injector(['ng']).get('$http');
    return {
        getInstance: () => {
            if (!instance) {
                const token = localStorage.myrsoAuthToken;
                instance = {
                    init: (provider) => {
                        fetch = provider || angular.injector(['ng']).get('$http');
                    },
                    url: (path) => `${api}${path}`,
                    img: (path) => `${api}/img/${token}${path}`,
                    fetch: (config) => {
                        return fetch({
                            ...config,
                            url: `${api}${config.url}`,
                        });
                    },
                };
            }
            return instance;
        },
    };
})();

export default API.getInstance();
