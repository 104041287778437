/* @ngInject */
export function InputEquipmentSerialController($ngRedux) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, {})(this);
    };

    ctrl.onChange = function() {
        $ngRedux.dispatch({
            type: 'EQUIPMENT_SERIAL_CHANGE',
            id: ctrl.identifier,
            serial: ctrl.serial
        });
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    function mapStateToThis(state) {
        return {
            serial: _.get(state, getStateContainer('filter.serial'), null)
        };
    }

    function getStateContainer(statePath) {
        return [ctrl.module, ctrl.identifier].join('.') + '.' + statePath;
    }
}
