import { GridReducer } from '../../../common/grid/grid.reducer';
import {GridFilterReducer} from '../../../common/grid/filter/grid-filter.reducer';
import * as GRID from '../../../common/grid/grid.constants';
import * as FORM from '../../../common/form/input/input.constants';
import { TYPES } from './settings-action-type.constants';
import { IDS } from './settings-action-id.constants';
import merge from 'lodash/merge';
import get from 'lodash/get';
import set from 'lodash/set';
import {getDocFileNameFromPath} from "../../../common/utils/util.path";

/**
 * @function SettingsReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function SettingsReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            schemeDetails: {},
            leadingZerosOptions: [
                {value: '1', label: '1'},
                {value: '2', label: '2'},
                {value: '3', label: '3'},
                {value: '9', label: 'a'},
                {value: '8', label: 'A'},
            ],
            headOffices: {
                selectedHeadOfficeUuid: null,
                notEnabledHeadOffices: [],
            },
            headOfficesGrid: {
                ...GridReducer(),
                filter: {
                    ...GridFilterReducer(),
                    schemeId: null
                }
            },
            siteLocations: {
                headOfficeName: null,
            },
            siteLocationsGrid: {
                ...GridReducer(),
                filter: {
                    ...GridFilterReducer(),
                    schemeId: null
                }
            },
            sectionDetails: {
                id:         null,
                level:      null,
                nodeName:   null,
                prefix:     null, //number
                siblings:   null, //node.children.map(item => parseInt(item.number.split('.').pop())),
            },
            selectCascadeStandard: {
                sectionsTree: [],
                level1Standards: [],
                level2Standards: [],
                level3Standards: [],
                level1StandardId: null,
                level2StandardId: null,
                level3StandardId: null,
            },
            defaultDocumentsGrid: {
                ...GridReducer(),
                filter: {
                    ...GridFilterReducer(),
                    path:           IDS.defaultDocumentsGrid,
                    slo_uuid:       null,
                    standard_id:    null,
                }
            },
            defaultDocumentDetails: {
                document_type: 'gdo',  // in page add document, first item in drop down menu for document type is Generic Doc
                filePath: null,
                sampleDocFilename : null,
            },
            hofRequiredDocs: {
                enabledHeadOffices: [],
                enabledSiteLocations: [],
                selectedHeadOfficeUuid: null
            },
            hofRequiredDocsGrid: {
                ...GridReducer(),
                filter: {
                    ...GridFilterReducer(),
                    path:           IDS.hofRequiredDocsGrid,
                    loc_uuid:       null,
                    standard_id:    null,
                }
            },
            hofRequiredDocDetails: {
                type_code: 'gdo', // in page add document, first item in drop down menu for document type is Generic Doc
                sample_doc_uri: null,
                sampleDocFilename: null,
            },
            hofRequiredDocActiveHofsGrid: {
                ...GridReducer(),
                filter: {
                    ...GridFilterReducer(),
                    path: IDS.hofRequiredDocActiveHofsGrid,
                    ard_id: null,
                }
            },
            sloRequiredDocs: {
                enabledHeadOffices: [],
                enabledSiteLocations: [],
                selectedSiteLocationUuid: null
            },
            sloRequiredDocsGrid: {
                ...GridReducer(),
                filter: {
                    ...GridFilterReducer(),
                    path:           IDS.sloRequiredDocsGrid,
                    loc_uuid:       null,
                    standard_id:    null,
                }
            },
            sloRequiredDocDetails: {
                type_code: 'gdo', // in page add document, first item in drop down menu for document type is Generic Doc
                sample_doc_uri: null,
                sampleDocFilename: null,
            },
            sloRequiredDocActiveSitesGrid: {
                ...GridReducer(),
                filter: {
                    ...GridFilterReducer(),
                    path: IDS.sloRequiredDocActiveSitesGrid,
                    vdo_uuid: null,
                }
            },
            documentTypes: [
                { value: 'gdo', label: 'Generic Doc.' },
                { value: 'pol', label: 'Policy' },
                { value: 'pro', label: 'Procedure' },
                { value: 'coa', label: 'Contracts/Agreements' },
                { value: 'for', label: 'Form' },
                { value: 'che', label: 'Checklist' },

            ],
        };
    }

    switch (action.type) {
        case TYPES.SCHEME_DETAILS_REFRESH:
            if (action.id === IDS.accreditationSettingsSchemeDetails) {
                return merge({...state}, {
                    schemeDetails: {
                        ...action.data,
                        title: action.data.name,
                    },
                    headOfficesGrid: {
                        filter: { schemeId: action.data.id }
                    },
                    siteLocationsGrid: {
                        filter: { schemeId: action.data.id }
                    },
                })
            }
        case TYPES.SCHEME_STATUS_REFRESH:
            if (action.id === IDS.accreditationSettingsSchemeDetails) {
                return merge({...state}, { schemeDetails: action.data })
            }
        case TYPES.SECTION_DETAILS_REFRESH:
            if (action.id === IDS.accreditationSettingsSectionDetails) {
                state = {...state, sectionDetails:                 action.data};
                return set(state, 'assessmentGrid.filter.standard_id',  action.data.id);
            }

        case TYPES.SELECT_CASCADE_STANDARD:
            if (action.id === IDS.accSettings) {
                state = {...state};
                if(action.schemeCode)
                    set(state,'sectionDetails.schemeCode', action.schemeCode);
                if (action.level < 3) {
                    set(state,
                        `selectCascadeStandard.level${action.level + 1}Standards`,
                        action.standards.length === 0 ? [{value:0, label:''}] : action.standards.map(item => ({
                            value: item.id,
                            label: item.name,
                        }))
                    )
                }
                if (action.level === 1) {
                    set(state,
                        `selectCascadeStandard.level3Standards`,
                        action.level3Standards.length === 0 ? [{value:0, label:''}] : action.level3Standards.map(item => ({
                            value: item.id,
                            label: item.name,
                        }))
                    )
                }
                // remove reset value -1 which is avoiding drop down menu automatically pick first item
                if(state.selectCascadeStandard.level1StandardId === -1)
                    state = set(state, 'selectCascadeStandard.level1StandardId', null);
                if(state.selectCascadeStandard.level2StandardId === -1)
                    state = set(state, 'selectCascadeStandard.level2StandardId', null);
                if(state.selectCascadeStandard.level3StandardId === -1)
                    state = set(state, 'selectCascadeStandard.level3StandardId', null);

                return set(state, `selectCascadeStandard.level${action.level}StandardId`, action.standardId);
            }
        case TYPES.SELECT_CASCADE_STANDARD_RESET:
            if (action.id === IDS.accSettings) {
                return {
                    ...state,
                    sectionDetails: null,
                    selectCascadeStandard: {
                        ...state.selectCascadeStandard,
                        level1StandardId: -1,
                        level2StandardId: -1,
                        level3StandardId: -1,
                    }
                }
            }
        case TYPES.HOF_DOC_DETAILS_REFRESH:
            return {
                ...state,
                hofRequiredDocDetailsEmpty: state.hofRequiredDocDetailsEmpty || {...state.hofRequiredDocDetails},
                hofRequiredDocDetails: action.data
            };
        case TYPES.HOF_DOC_DETAILS_RESET:
            return {
                ...state,
                hofRequiredDocDetails: { ...state.hofRequiredDocDetailsEmpty }
            };
        case TYPES.SLO_DOC_DETAILS_REFRESH:
            return {
                ...state,
                sloRequiredDocDetailsEmpty: state.sloRequiredDocDetailsEmpty || {...state.sloRequiredDocDetails},
                sloRequiredDocDetails: action.data
            };
        case TYPES.SLO_DOC_DETAILS_RESET:
            return {
                ...state,
                sloRequiredDocDetails: { ...state.sloRequiredDocDetailsEmpty }
            };
        case TYPES.DEFAULT_DOCUMENT_DETAILS_REFRESH:
            if (action.id === IDS.accSettings) {
                state = {
                    ...state,
                    defaultDocumentDetails: {...state.defaultDocumentDetails, ...action.data }
                };
                return set(state, 'assessmentHeadOfficesGrid.filter.ard_id', action.data.id);
            }
        case TYPES.DOCUMENT_SAMPLE_DOC_CHANGED:
            if (action.id === IDS.accSettings) {
                state = set({...state}, `${action.path}.sampleDocFilename`, getDocFileNameFromPath(action.sample_doc_uri));
                return set(state, `${action.path}.sample_doc_uri`, action.sample_doc_uri);
            }
        case TYPES.FETCH_ASS_HOF_SCHEME_HEAD_OFFICES:
            if (action.id === IDS.accreditationSettingsAssessmentHeadOffice) {
                state = {...state};
                if(action.items.length) {
                    set(state, 'assessmentHeadOffice.headOffice',       action.items[0].value);
                    set(state, 'assessmentHeadOffice.headOfficeName',   action.items[0].label);
                }
                return set(state, 'assessmentHeadOffice.headOffices',   action.items)
            }
        case TYPES.ASS_HOF_SELECT_HEAD_OFFICE:
            if (action.id === IDS.accreditationSettingsAssessmentHeadOffice) {
                state = set({...state}, 'assessmentHeadOffice.headOfficeName', action.headOfficeName);
                return set(state, 'assessmentHeadOffice.headOffice', action.headOffice)
            }
        case TYPES.FETCH_NOT_ENABLED_HEAD_OFFICES:
            if (action.id === IDS.accreditationSettingsHeadOffices) {
                return set({...state}, 'headOffices.notEnabledHeadOffices', action.items)
            }
        case TYPES.SELECTED_HEAD_OFFICE_RESET:
            if (action.id === IDS.accreditationSettingsHeadOffices) {
                return set({...state}, 'headOffices.selectedHeadOfficeUuid', null)
            }
        case TYPES.SET_SITE_LOCATIONS_HEADER:
            if (action.id === IDS.accSettings) {
                return set({...state}, 'siteLocations', action.data)
            }
        case TYPES.FETCH_SCHEME_ENABLED_HEAD_OFFICES:
            if ([IDS.hofRequiredDocs, IDS.sloRequiredDocs].includes(action.id)) {
                state = set({...state}, 'schemeDetails.id', action.schemeId || state.schemeDetails.id);
                // if(action.items.length)
                //     set(state, `${action.id}.hof_uuid`, action.items[0].value)
                return set(state, `${action.id}.enabledHeadOffices`, action.items)
            }
        case TYPES.FETCH_SCHEME_ENABLED_SITE_LOCATIONS:
            if (action.id === IDS.sloRequiredDocs) {
                state = {...state};
                if(action.items.length) {
                    set(state, `sloRequiredDocs.slo_uuid`, action.items[0].value)
                    set( state, `sloRequiredDocsGrid`,
                        GridReducer(get(state, `sloRequiredDocsGrid`),{
                            type:GRID.GRID_FILTER,
                            filter: {
                                loc_uuid: action.items[0].value
                            }
                        })
                    )
                }

                return set(state, `sloRequiredDocs.enabledSiteLocations`, action.items)
            }
        case TYPES.SLO_DOCS_LOCATION_SELECTS_RESET:
            if (action.id === IDS.sloRequiredDocs) {
                state = set({...state}, 'sloRequiredDocs.slo_uuid', null)
                set(state, 'sloRequiredDocs.enabledSiteLocations', []);
                return set(state, 'sloRequiredDocs.hof_uuid', null)
            }
        case GRID.GRID_INIT:
        case GRID.GRID_FILTER:
        case GRID.GRID_FILTER_TOGGLE:
        case GRID.GRID_RESET_FILTER:
        case GRID.GRID_INIT_FILTER:
        case GRID.GRID_LOADING:
        case GRID.GRID_PAGINATE:
        case GRID.GRID_RELOAD:
        case GRID.GRID_FETCH:
        case GRID.GRID_SELECT:
        case GRID.GRID_SELECT_ALL:
        case GRID.GRID_DESELECT:
        case GRID.GRID_DESELECT_ALL:
            if(action.id === IDS.accSettings) {
                state = set({...state}, action.path, GridReducer(get(state, action.path), action));
                // if (action.path === IDS.headOfficesGrid) {
                //     set(state, 'headOffices.selectedHeadOfficeUuid', null);
                // }
                // // if grid data is coming from database then clean previously stored local list of head offices for operation "add assessment item"
                // else if (action.path === IDS.assessmentHeadOfficesGrid && action.type === GRID.GRID_FETCH && action.status === 0) {
                //     return set(state,'assessmentHeadOfficesGrid.filter.newAssessmentHeadOffices', null);
                // }
                return state;
            }
        case FORM.INPUT_SELECT:
            if (action.id === IDS.accSettings) {
                state = {...state};
                const component = action.path.split('.')[0];
                switch(component) {
                    // case IDS.defaultDocumentsGrid:
                    case IDS.hofRequiredDocs:
                    case IDS.sloRequiredDocs:
                        let details
                        switch(component) {
                            // case IDS.defaultDocumentsGrid: details = 'defaultDocumentDetails'; break;
                            case IDS.hofRequiredDocs: details = 'hofRequiredDocDetails'; break;
                            case IDS.sloRequiredDocs: details = 'sloRequiredDocDetails'; break;
                        }
                        let propertyName = action.path.split('.').pop();

                        state = {...state};
                        set (state, `${details}.headOfficeName`, action.item?.label);
                        if(propertyName !== 'hof_uuid') {
                            if(propertyName === 'slo_uuid')
                                propertyName = 'loc_uuid';
                            set(state, `${component}Grid`,
                                GridReducer(get(state, `${component}Grid`), {
                                    type: GRID.GRID_FILTER,
                                    filter: {
                                        [propertyName]: action.input
                                    }
                                })
                            )
                        }
                        return state;
                    case 'schemeDetails':
                    case 'sectionDetails':
                        return set(state, action.path, action.input);
                }
            }
        case FORM.INPUT_TEXT:
        case FORM.INPUT_TEXT_AREA:
        case FORM.INPUT_CHECKBOX:
        case FORM.INPUT_DATE:
        case FORM.INPUT_RADIO:
            if (action.id === IDS.accSettings) {
                return set({...state}, action.path, action.input)
            }
        case 'LOCATION_SELECTED': // slo select-cascade.js
            if (action.id === IDS.accreditationSettingsHeadOffices) {
                return set({...state}, 'headOffices.selectedHeadOfficeUuid', action.uuid)
            }
            if(action.id === IDS.accreditationSettingsAssessmentGrid && action.type_code === 'slo') {
                state = set({...state},  `${IDS.assessmentGrid}.selectedLocationUuid`, action.uuid);
                if(get(state, `${IDS.assessmentGrid}.filterOpened`))
                    state = set(state, `${IDS.assessmentGrid}.filter.slo_uuid`, action.uuid);
                return set( state, IDS.assessmentGrid, GridReducer(get(state, IDS.assessmentGrid),{ type:GRID.GRID_RELOAD }))
            }
        default:
            return state;
        }
}
