import { DATE_FORMAT } from '../../../../common/utils/util.constants';

/* @ngInject */
export function EquipmentServiceXray($http, API_BASE, $ngRedux, Xray, EquipmentCategory, Helpers) {

    this.addEquipment = function(data) {
        return $http({
            url: API_BASE + "/equipment/equipment-add",
            method: "POST",
            data: {
                equipment_id: data.user_friendly_id,
                loc_uuid: data.location.uuid,
                ety_id: parseInt(data.category.id),
                emo_id: data.model.id,
                serial: data.serial,
                installation_date: Helpers.formatDate(data.equipmentInstallationDate),
                cap_sens_exp_date: Helpers.formatDate(data.capitalSensitivityExpiryDate),

                reg_number: data.registrationData.number,
                reg_date: Helpers.formatDate(data.registrationData.date),
                reg_due_date: Helpers.formatDate(data.registrationData.dueDate),
                reg_details: data.registrationData.details,

                tube_housing_model_id: data.tubeHousing.model ? data.tubeHousing.model.id : null,
                tube_housing_serial: data.tubeHousing.serial,
                tube_insert_model_id: data.tubeInsert.model ? data.tubeInsert.model.id : null,
                tube_insert_serial: data.tubeInsert.serial,

                compliance_testing_required: data.complianceTestingRequired ? data.complianceTestingRequired : false,
                premises_certification_required: data.premisesCertificationRequired ? data.premisesCertificationRequired : false,
                servicing_required: data.servicingRequired ? data.servicingRequired : false,
                asset_id: data.assetID ? data.assetID : null,
                testing_frequency_required: data.testingFrequencyRequired ? data.testingFrequencyRequired : false,
                testing_frequency: data.testingFrequency ? data.testingFrequency : null
            }
        }).then(function(result) {
            let params = _.isObject(result.data.params) ? result.data.params : JSON.parse(result.data.params);
            return params.equ_id;
        }, function(error) {
            return error;
        });
    };

    this.getEquipmentDetails = function(id,filter) {
        var params = [];
        if (!_.isUndefined(filter)) params['filter'] = filter;
        return $http({
            url: API_BASE + "/equipment/" + id,
            method: "GET",
            params:params
        }).then(function(data) {
            let employeeLocations;
            let elemHousingArray = [];
            let elemInsertArray = [];
            if (data.data[1][1]) {
                employeeLocations = data.data[1];
            } else if (data.data[2][1]) {
                employeeLocations = data.data[2];
                if (data.data[1][0].equipment_hou_model_id) {
                    elemHousingArray = data.data[1][0];
                } else if (data.data[1][0].equipment_tub_model_id) {
                    elemInsertArray = data.data[1][0];
                }
            } else {
                if (data.data[1][0].equipment_hou_model_id) {
                    elemHousingArray = data.data[1][0];
                    if (data.data[2][0].equipment_tub_model_id) {
                        elemInsertArray = data.data[2][0];
                    }
                } else if (data.data[1][0].equipment_tub_model_id) {
                    elemInsertArray = data.data[1][0];
                    if (data.data[2][0].equipment_hou_model_id) {
                        elemHousingArray = data.data[2][0];
                    }
                } else {
                    elemHousingArray = data.data[1];
                    elemInsertArray = data.data[2];
                }
                employeeLocations = data.data[3];
            }

            var elemArray = data.data[0];
            var elem;
            var elemHousing;
            var elemInsert;

            if(elemArray!=null) {
                if (elemArray.length === 1) {
                    elem = elemArray[0];
                }
                else {
                    elem = elemArray;
                }
            }

            if(elemHousingArray!=null){
                if(elemHousingArray.length === 1)
                    elemHousing = elemHousingArray[0];
                else
                    elemHousing = elemHousingArray;
            }
            if(elemInsertArray!=null){
                if(elemInsertArray.length === 1)
                    elemInsert = elemInsertArray[0];
                else
                    elemInsert = elemInsertArray;
            }
            if(elem==null){
                return;
            }
            var item = new Xray();
            item.equipment_id = elem.equ_id;
            item.user_friendly_id = elem.equipment_id;
            item.barcode = _.isNull(elem.barcode) ? elem.equipment_id : elem.barcode;
            item.transaction_id = elem.transaction_id;
            item.account.id = elem.account_id;
            item.account.name = elem.account_name;
            item.account.uuid = elem.account_uuid;
            item.account.lspn = elem.lspn;
            item.barcode = elem.barcode;
            item.certificateData.expiryDate = Helpers.formatDate(elem.certification_expire_date);
            item.certificateData.certificate = elem.certification_number;
            item.equipmentInstallationDate =  Helpers.formatDate(elem.installation_date);
            item.capitalSensitivityExpiryDate = Helpers.formatDate(elem.cap_sens_exp_date);
            item.serviceDueDate = elem.due_date_service ? Helpers.formatDate(elem.due_date_service) : null;
            if (typeof elem.cap_sens_exp_date === 'undefined')
                item.capitalSensitivityExpiryDateShow = false;
            else
                item.capitalSensitivityExpiryDateShow = true;
            item.serial = elem.equ_serial;
            item.category.id = elem.equipment_category_id;
            item.category.code = elem.equipment_category_code;
            item.category.name = elem.equipment_category_name;
            item.category.isRegulated = elem.equipment_category_is_regulated;
            item.model.id = elem.equipment_model_id;
            item.model.name = elem.equipment_model_name;

            item.type.id = parseInt(elem.equipment_basic_category_id);
            item.type.code = elem.equipment_basic_category_code;
            item.type.name = elem.equipment_basic_category_name;

            item.manufacturer.code = elem.manufacturer_code;
            item.manufacturer.id = elem.manufacturer_id;
            item.manufacturer.name = elem.manufacturer_name;
            item.manufacturer.description = elem.manufacturer_description
            item.registrationData.number = elem.reg_number;
            item.registrationData.date = Helpers.formatDate(elem.reg_date);
            item.registrationData.dueDate = Helpers.formatDate(elem.reg_next_due_date);
            item.registrationData.details = elem.reg_details;
            item.status = elem.status;

            item.location.name = elem.dep_name;
            item.location.uuid = elem.dep_uuid;

            item.disposalDescription = elem.disposal_description;
            item.wayOfDisopsal = elem.disposal_way;
            item.disposal = elem.status === 'dis';
            if(elemHousing!=null){
                item.tubeHousing.id = elemHousing.equ_id;
                item.tubeHousing.type.id = parseInt(elemHousing.equipment_type_id);
                item.tubeHousing.type.code = elemHousing.equipment_type_code;
                item.tubeHousing.type.name = elemHousing.equipment_type_name;
                item.tubeHousing.model = {};
                item.tubeHousing.model.name = elemHousing.equipment_hou_model_name;
                item.tubeHousing.model.id = elemHousing.equipment_hou_model_id;
                item.tubeHousing.serial = elemHousing.equ_hou_serial;
                item.tubeHousing.room.uuid = elemHousing.room_name;
                item.tubeHousing.room.name = elemHousing.room_name;
                item.tubeHousing.department.uuid = elemHousing.dep_uuid;
                item.tubeHousing.department.name = elemHousing.dep_name;

                item.tubeHousing.manufacturer.id = elemHousing.manufacturer_hou_id;
                item.tubeHousing.manufacturer.code = elemHousing.manufacturer_hou_code;
                item.tubeHousing.manufacturer.name = elemHousing.manufacturer_hou_name;
            }
            if(elemInsert!=null){
                item.tubeInsert.id = elemInsert.equ_id;
                item.tubeInsert.type.id = parseInt(elemInsert.equipment_type_id);
                item.tubeInsert.type.code = elemInsert.equipment_type_code;
                item.tubeInsert.type.name = elemInsert.equipment_type_name;
                item.tubeInsert.model = {};
                item.tubeInsert.model.name = elemInsert.equipment_tub_model_name;
                item.tubeInsert.model.id = elemInsert.equipment_tub_model_id;
                item.tubeInsert.serial = elemInsert.equ_tub_serial;
                item.tubeInsert.room.uuid = elemInsert.room_name;
                item.tubeInsert.room.name = elemInsert.room_name;
                item.tubeInsert.department.uuid = elemInsert.dep_uuid;
                item.tubeInsert.department.name = elemInsert.dep_name;

                item.tubeInsert.manufacturer.id = elemInsert.manufacturer_tub_id;
                item.tubeInsert.manufacturer.code = elemInsert.manufacturer_tub_code;
                item.tubeInsert.manufacturer.name = elemInsert.manufacturer_tub_name;
            }

            item.complianceTestingRequired = parseInt(elem.compliance_required) !== 0;
            item.premisesCertificationRequired = parseInt(elem.premises_required) !== 0;
            item.servicingRequired = parseInt(elem.servicing_required) !== 0;
            item.assetID = elem.asset_id;
            item.testingFrequencyRequired = elem.testing_frequency_required ? parseInt(elem.testing_frequency_required) !== 0 : false;
            item.testingFrequency = parseInt(elem.testing_frequency);

            $ngRedux.dispatch({
                type: 'EQUIPMENT_DATA',
                id: 'equAssetCost',
                data: {
                    equAssetCost: {
                        equ_id: elem.equ_id,
                        accountId: elem.account_id,
                        visualId: elem.equipment_id,
                        cost_centre_code: elem.cost_centre_code,
                        cost_centre_name: elem.cost_centre_name,
                        purchaseDate: Helpers.formatDate(elem.purchase_date),
                        purchase_order_number: elem.purchase_order_number,
                        installation_date: Helpers.formatDate(elem.installation_date),
                        cap_sens_exp_date: Helpers.formatDate(elem.cap_sens_exp_date),
                        warranty_period: elem.warranty_period,
                        warranty_exp_date: elem.warranty_exp_date,
                        assetCost: elem.asset_cost,
                        act_type_code: elem.act_type_code,
                        estimated_residual_value: elem.estimated_residual_value,
                        tax_estimated_useful_life: elem.tax_estimated_useful_life,
                        depreciation_rate_per_year: elem.depreciation_rate_per_year,
                        depreciation_type: elem.depreciation_type,
                        service_contract: elem.service_contract,
                        comment: elem.comment,
                        software_version: elem.software_version,
                        man_eof_support_date: elem.man_eof_support_date,
                    },
                },
            });

            return {
                equipment: item,
                locations: employeeLocations,
            };
        }, function(error) {
            return error;
        });
    };

    this.updateEquipment = function(equipment) {
        const state = $ngRedux.getState();
        const { equAssetCost } = state.equipment;
        var ldata = {
            equ_id: equipment.equipment_id,
            loc_uuid: equipment.location.uuid,
            ety_id: equipment.category.id,
            emo_id: equipment.model.id,
            serial: equipment.serial,

            installation_date: Helpers.formatDate(equipment.equipmentInstallationDate),
            cap_sens_exp_date: Helpers.formatDate(equipment.capitalSensitivityExpiryDate),
            service_due_date: Helpers.formatDate(equipment.serviceDueDate),

            reg_number: equipment.registrationData.number,
            reg_date: Helpers.formatDate(equipment.registrationData.date),
            reg_due_date: Helpers.formatDate(equipment.registrationData.dueDate),
            reg_details: equipment.registrationData.details,

            tube_housing_model_id: equipment.tubeHousing.model ? equipment.tubeHousing.model.id : null,
            tube_housing_serial: equipment.tubeHousing.serial,
            tube_insert_model_id: equipment.tubeInsert.model ? equipment.tubeInsert.model.id : null,
            tube_insert_serial: equipment.tubeInsert.serial,

            compliance_testing_required: equipment.complianceTestingRequired ? equipment.complianceTestingRequired : false,
            premises_certification_required: equipment.premisesCertificationRequired ? equipment.premisesCertificationRequired : false,
            servicing_required: equipment.servicingRequired ? equipment.servicingRequired : false,
            asset_id: equipment.assetID,
            testing_frequency_required: equipment.testingFrequencyRequired ? equipment.testingFrequencyRequired : false,
            testing_frequency: equipment.testingFrequency ? equipment.testingFrequency : null,
            /**
             * ASSET COST
             */
            purchase_date: Helpers.formatDate(equAssetCost.purchaseDate, DATE_FORMAT.OUTPUT),
            purchase_order_number: equAssetCost.purchase_order_number,
            warranty_period: equAssetCost.warranty_period,
            warranty_exp_date: Helpers.formatDate(equAssetCost.warranty_exp_date, DATE_FORMAT.OUTPUT),
            asset_cost: equAssetCost.assetCost,
            act_type_code: equAssetCost.act_type_code,
            estimated_residual_value: equAssetCost.estimated_residual_value,
            depreciation_rate_per_year: equAssetCost.depreciation_rate_per_year,
            tax_estimated_useful_life: equAssetCost.tax_estimated_useful_life,
            depreciation_type: equAssetCost.depreciation_type,
            service_contract: equAssetCost.service_contract,
            comment: equAssetCost.comment,
            software_version: equAssetCost.software_version,
            man_eof_support_date: Helpers.formatDate(equAssetCost.man_eof_support_date, DATE_FORMAT.OUTPUT),
        };

        if(equipment.tubeInsert.name != null && typeof equipment.tubeInsert.name.id !== "undefined" && typeof equipment.tubeInsert.name.id !== null) {
            ldata.tubeInsertId = equipment.tubeInsert.name.id;
            ldata.tubeInsertName = equipment.tubeInsert.name.name;
        }

        if(equipment.tubeInsert.serial === null)
            ldata.tubeInsertSerial = 'n/a';


        if(equipment.tubeHousing.name !== null && typeof equipment.tubeHousing.name !== 'undefined') {
            if (typeof equipment.tubeHousing.name.id !== "undefined" && equipment.tubeHousing.name.id !== null) {
                ldata.tubeHousingId = equipment.tubeHousing.name.id;
                ldata.tubeHousingName = equipment.tubeHousing.name.name;
            }
        }

        if(equipment.tubeHousing.serial === null)
            ldata.tubeHousingSerial = 'n/a';

        return $http({
            url: API_BASE + "/equipment/edit",
            method: "POST",
            data: ldata
        }).then(function(result) {
            return _.isObject(result.data.params) ? result.data.params : JSON.parse(result.data.params);
        });
    };

    this.getCategories = function() {
        return $http({
            url: API_BASE + "/equipment/xray/categories",
            method: "GET"
        }).then(function(result) {
            var categories = [];
            result.data.forEach(function(item) {
                var category = new EquipmentCategory();
                category.id = item.category_id;
                category.name = item.category_name;
                categories.push(category);
            });
            return categories;
        }, function(error) {
            return error;
        });
    };
}
