import * as TYPES from '../../manufacturers.actions.types';
import { API_BASE } from '../../../../../common/utils/util.constants';
import watch from 'redux-watch';
import {
    createManufacturerEquModel,
    updateManufacturerEquModel,
} from '../manufacturer-equ.actions';

export const manufacturerEquipmentDetails = {
    /* @ngInject */
    controller: function($ngRedux, $state, $uiRouterGlobals, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(({ manufacturers }) => ({
                equModel: manufacturers.manufacturerEquModel,
            }), {
                createManufacturerEquModel,
                updateManufacturerEquModel,
            })(this);

            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.equipmentModel`;
            this.title = 'Equipment Model';

            const watchImg = watch($ngRedux.getState, 'manufacturers.manufacturerEquModel.image');
            this.onImageChange = $ngRedux.subscribe(watchImg((img) => {
                this.img = `${API_BASE}/img/${localStorage.myrsoAuthToken}${img}`;
            }));

            if (this.equModel.image) {
                this.img = `${API_BASE}/img/${localStorage.myrsoAuthToken}${this.equModel.image}`;
            }
        }

        /**
         * @function onUploadSave
         * @param {Object} event
         */
        this.onUploadSave = (event) => {
            Notifier.info('Please wait...');
            // TODO
        };

        /**
         * @function onSave
         */
        this.onSave = () => {
            if (this.manufacturerEquModelForm.$valid) {
                if (this.equModel.emo_id) {
                    this.updateManufacturerEquModel({
                        emo_id: this.equModel.emo_id,
                        name: this.equModel.equipment_model,
                        ety_id: this.equModel.ety_id,
                        image: this.equModel.image,
                    }).then(() => {
                        Notifier.success('Equipment model has been successfully updated');
                    });
                } else {
                    this.createManufacturerEquModel({
                        man_id: this.equModel.man_id,
                        ety_id: this.equModel.ety_id,
                        name: this.equModel.equipment_model,
                        image: this.equModel.image,
                    }).then(() => {
                        Notifier.success('Equipment model has been successfully created');
                        $ngRedux.dispatch({
                            type: 'TAB_SELECT',
                            id: 'app.administration.manufacturers.manage',
                            state: 'app.administration.manufacturers.manage.equipment',
                        });
                    });
                }
            } else {
                Notifier.error('Please fill in the form fields');
            }
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            if (this.unsubscribe) {
                this.unsubscribe();
                this.onImageChange();
                $ngRedux.dispatch({
                    type: TYPES.MANUFACTURER_EQU_MODEL_RESET,
                    id: 'manufacturer',
                });
            }
        };
    },
    template: require('./manufacturer-equ-details.html'),
};
