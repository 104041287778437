import { IDS } from '../assessment-reports-action-id.constants';
import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import * as DocumentListActions from './list.actions';
import {GRID_RELOAD} from "../../../../common/grid/grid.constants";

class AssessmentReportsGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter, $mdDialog, $scope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
    }

    $onInit() {
        this.module = 'accreditation';
        this.identifier = IDS.accAssessmentReports;
        this.statePath = IDS.accAssessmentReportsGrid;

        this.filterTemplate = './list-grid-filter.html';
        this.filterComponent = false;

        this.exportComponent = false;
        this.title = false;
        this.icon = false;
        this.confirmDialogOnRemove = true;
        this.autoload = false;

        this.columns = [
            {title: '#', mapper: null},
            {title: '',                                                         mapper: 'icon', style: { width: '35px' }},
            {title: 'Organisation',                                             mapper: 'accreditation_body'},
            {title: 'Location',                                                 mapper: 'location'},
            // {title: this.$filter('translate')('COMMON.FIELD.NAME'),             mapper: 'name'},
            {title: this.$filter('translate')('COMMON.FIELD.FILENAME'),         mapper: 'filename'},
            {title: this.$filter('translate')('COMMON.FIELD.UPLOADED_BY'),      mapper: 'uploaded_by'},
            {title: this.$filter('translate')('COMMON.DATE.ISSUE_DATE'),        mapper: 'doc_date'},
            {title: this.$filter('translate')('COMMON.DATE.DUE_DATE'),          mapper: 'due_date'},
        ];

        this.buttons = [
            {
                class: 'btnGridAction',
                label: 'Download',
                callback: this.onDownload,
            },
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), DocumentListActions)(this);

        this.$scope.$on('LOCATION_SUBJECT_SELECTED', this.onLocationSelected.bind(this));

        this.dataAction = this.fetchAssessmentReportsGrid;
        this.init();
    }

    onLocationSelected(event, location) {
        if(location.in_subject_basic_type_code === 'slo') {
            this.filter.slo_uuid = location.in_subject_uuid;
            this.$ngRedux.dispatch({
                type: GRID_RELOAD,
                id: IDS.accAssessmentReports,
                path: IDS.accAssessmentReportsGrid,
            })
        }
    }

    $onDestroy() {
        this.destroy();
    }

}

AssessmentReportsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter', '$mdDialog', '$scope'];

export const accAssessmentReportsGrid = {
    bindings: {
        onSelect: '&',
        onRemove: '&',
        onDownload: '&',

    },
    controller: AssessmentReportsGridController,
    ...gridTpl
}
