import {GridController} from "../../../../../common/grid/grid.controller";

/* @ngInject */
export function signalAndContractRatioGridController($controller, $ngRedux, $state, NavigationService, MammoQualityControlActions, MammoQCService) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'mammoQualityControl';
        ctrl.identifier = 'signalAndContractRatioGrid';
        ctrl.filterTemplate = './signal-and-contract-ratio-grid-filter.html';
        ctrl.exportService = MammoQCService.getMammoQCTests;
        ctrl.autoload = false;
        ctrl.message = 'Please fill required fields in filter form to see results';

        ctrl.columns = [
            {title: '#',                    mapper: null},
            {title: 'Date',                 mapper: 'testDate'},
            {title: 'Remarks',              mapper: 'remark'}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), MammoQualityControlActions)(ctrl);
        ctrl.dataAction = ctrl.getMammoQCTests;

        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
