/**
 * @const NEXT_ID
 * @type {string}
 */
export const NEXT_ID = 'NEXT_ID';

/**
 * @const RM_ISOTOPES
 * @type {string}
 */
export const RM_ISOTOPES = 'RM_ISOTOPES';

/**
 * @const RM_ATOMIC_NUMS
 * @type {string}
 */
export const RM_ATOMIC_NUMS = 'RM_ATOMIC_NUMS';

/**
 * @const RM_UNITS
 * @type {string}
 */
export const RM_UNITS = 'RM_UNITS';

/**
 * @const RM_HALF_LIFE
 * @type {string}
 */
export const RM_HALF_LIFE = 'RM_HALF_LIFE';

/**
 * @const RM_WASTE_DETAILS
 * @type {string}
 */
export const RM_WASTE_DETAILS = 'RM_WASTE_DETAILS';

/**
 * @const RM_WASTE_RESET
 * @type {string}
 */
export const RM_WASTE_RESET = 'RM_WASTE_RESET';

/**
 * @const RM_WASTE_TYPES
 * @type {string}
 */
export const RM_WASTE_TYPES = 'RM_WASTE_TYPES';

/**
 * @const RM_WASTE_DISPOSAL_REACH
 * @type {string}
 */
export const RM_WASTE_DISPOSAL_REACH = 'RM_WASTE_DISPOSAL_REACH';

/**
 * @const RM_WASTE_DISPOSAL_REACH_ESTIMATE
 * @type {string}
 */
export const RM_WASTE_DISPOSAL_REACH_ESTIMATE = 'RM_WASTE_DISPOSAL_REACH_ESTIMATE';

/**
 * @const RM_TEST_DETAILS
 * @type {string}
 */
export const RM_TEST_DETAILS = 'RM_TEST_DETAILS';

/**
 * @const RM_TEST_RESET
 * @type {string}
 */
export const RM_TEST_RESET = 'RM_TEST_RESET';

/**
 * @const RM_TEST_TYPES
 * @type {string}
 */
export const RM_TEST_TYPES = 'RM_TEST_TYPES';

/**
 * @const RM_TEST_USE_TYPES
 * @type {string}
 */
export const RM_TEST_USE_TYPES = 'RM_TEST_USE_TYPES';

/**
 * @const RM_TEST_USE_TYPE_SELECT
 * @type {string}
 */
export const RM_TEST_USE_TYPE_SELECT = 'RM_TEST_USE_TYPE_SELECT';

/**
 * @const RM_TEST_ACTIVITY
 * @type {string}
 */
export const RM_TEST_ACTIVITY = 'RM_TEST_ACTIVITY';

/**
 * @const RM_TEST_MANUFACTURERS
 * @type {string}
 */
export const RM_TEST_MANUFACTURERS = 'RM_TEST_MANUFACTURERS';

/**
 * @const RM_TEST_MANUFACTURER_MODELS
 * @type {string}
 */
export const RM_TEST_MANUFACTURER_MODELS = 'RM_TEST_MANUFACTURER_MODELS';

/**
 * @const RM_TEST_DISPOSAL_COMPANIES
 * @type {string}
 */
export const RM_TEST_DISPOSAL_COMPANIES = 'RM_TEST_DISPOSAL_COMPANIES';

/**
 * @const RM_TEST_WIPE_TEST_DETAILS
 * @type {string}
 */
export const RM_TEST_WIPE_TEST_DETAILS = 'RM_TEST_WIPE_TEST_DETAILS';

/**
 * @const RM_TEST_WIPE_TEST_EFFICIENCY
 * @type {string}
 */
export const RM_TEST_WIPE_TEST_EFFICIENCY = 'RM_TEST_WIPE_TEST_EFFICIENCY';

/**
 * @const RM_TEST_WIPE_TEST_RESET
 * @type {string}
 */
export const RM_TEST_WIPE_TEST_RESET = 'RM_TEST_WIPE_TEST_RESET';

/**
 * @const RM_RESET_TEST_LICENCE
 * @type {string}
 */
export const RM_RESET_TEST_LICENCE = 'RM_RESET_TEST_LICENCE';

/**
 * @const RM_RESET_TEST_LICENCE_LIST
 * @type {string}
 */
export const RM_RESET_TEST_LICENCE_LIST = 'RM_RESET_TEST_LICENCE_LIST';
