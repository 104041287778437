const initialState = {
    equipmentLoan: {
        id: null,
        loan_person: null,
        loan_person_first_name: null,
        loan_person_last_name: null,
        loan_person_email: null,
        loan_person_phone: null,
        loan_person_campus: null,
        loan_person_department: null,
        loanPersons: [],
        email: null,
        contact_number: null,
        campus: null,
        department: null,
        creation_date: null,
        return_date: null,
        comments: null,
        signature: null,
        signature_date: null
    }
};

export default (state = initialState, action) => {
    if (action) {
        switch (action.type) {
            case 'EQUIPMENT_LOAN_PERSONS':
                if (action.id === 'equipmentLoan') {
                    return {
                        ...state,
                        loanPersons: action.data.list
                    };
                }
            case 'EQUIPMENT_LOAN_PERSON_SAVE':
                if (action.id === 'equipmentLoan') {
                    const loanPerson = {
                        first_name: state.loan_person_first_name,
                        last_name: state.loan_person_last_name,
                        email: state.loan_person_email,
                        phone: state.loan_person_phone,
                        account_name: state.loan_person_campus,
                        department_name: state.loan_person_department
                    };
                    return {
                        ...state,
                        loan_person: loanPerson,
                        loanPersons: [loanPerson]
                    };
                }
            case 'EQUIPMENT_LOAN_PERSON_RESET':
                if (action.id === 'equipmentLoan') {
                    return {
                        ...state,
                        loan_person_first_name: null,
                        loan_person_last_name: null,
                        loan_person_email: null,
                        loan_person_phone: null,
                        loan_person_campus: null,
                        loan_person_department: null
                    };
                }
            case 'EQUIPMENT_LOAN_DETAILS':
                if (action.id === 'equipmentLoan') {
                    if (action.data.length) {
                        const loanPerson = {
                            subject_uuid: action.data[0].emp_uuid,
                            first_name: action.data[0].first_name,
                            last_name: action.data[0].last_name,
                            email: action.data[0].email,
                            phone: action.data[0].contact_number === 'null' ? null : action.data[0].contact_number,
                            account_name: action.data[0].campus,
                            department_name: action.data[0].department
                        };
                        return {
                            ...state,
                            id: action.data[0].eql_id,
                            loan_person: loanPerson,
                            loanPersons: [loanPerson],
                            creation_date: action.data[0].creation_date,
                            return_date: action.data[0].return_by_date,
                            comments: action.data[0].comment === 'null' ? null : action.data[0].comment,
                            signature: action.data[0].signature,
                            signature_date: action.data[0].date
                        };
                    }
                }
            case 'EQUIPMENT_LOAN_RESET':
                if (action.id === 'equipmentLoan') {
                    return initialState.equipmentLoan;
                }
            default:
                return state;
            }
    }
    return state;
};
