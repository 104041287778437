import { GridController, gridTpl } from '../../../../common/grid/grid.controller';

/**
 * Creates a new AdministrationAccountsContactsGridController.
 * @class
 * @memberOf module:root/legacy/components/administration
 */
class AdministrationAccountsContactsGridController extends GridController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param NavigationService {service} {@link module:root/common.NavigationService|NavigationService}
     * @param AdministrationAccountsActions {service}
     */
    constructor($ngRedux, $state, NavigationService, AdministrationAccountsActions) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.actions = AdministrationAccountsActions;
    }

    /**
     * @method $onInit
     * @memberof module:root/legacy/components/administration.AdministrationAccountsContactsGridController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.module = 'administration';
        this.autoload = false;
        this.filterComponent = false;

        this.columns = [
            {title: '#',                mapper: null},
            {title: 'Contact Type',     mapper: 'contactType'},
            {title: 'First Name',       mapper: 'first_name'},
            {title: 'Last Name',        mapper: 'last_name'},
            {title: 'Username',         mapper: 'username'},
            {title: 'Phone',            mapper: 'phone'},
            {title: 'Fax',              mapper: 'fax'},
            {title: 'E-mail',           mapper: 'email'},
            {title: '',                 mapper: 'password-change'}
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.actions)(this);
        this.dataAction = this.getContactsList;
        this.exportService = this.getContactsList;
        this.filter.identifier = this.identifier;

        this.init();
    }
}

AdministrationAccountsContactsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'AdministrationAccountsActions'];

export const administrationAccountsContactsGrid = {
    bindings: {
        identifier: '@',
        title: '<',
        onSelect: '&',
        onRemove: '&'
    },
    controller: AdministrationAccountsContactsGridController,
    ...gridTpl
};

export const administrationAccountsContactsGridFilter = {
    uuid: null
};
