import { addIsotope, removeIsotope } from '../../../administration.actions';
import { getAtomicNums, getUnits, getIsotopes, getLocationLicences } from '../../../../radioactive-materials/radioactive-materials.actions';

/**
 * @const {object}
 * @memberOf module:Administration
 */
export const administrationAccountsSiteLocationLicenceIsotopes = {
    /* @ngInject */
    controller: function ($ngRedux, $scope, $mdDialog, $uiRouterGlobals, Notifier) {
        const ADM_LIC_ISOTOPE = 'administrationAccountsLicenceIsotope';
        this.unsubscribe = $ngRedux.connect(({ administration }) => ({
            administrationAccountsLicenceIsotope: administration.administrationAccountsLicenceIsotope,
            administrationAccountsLicenceIsotopesGrid: administration.administrationAccountsLicenceIsotopesGrid,
            stateParams: $uiRouterGlobals.params
        }), {
            addIsotope,
            getIsotopes,
            getUnits,
            getAtomicNums,
            removeIsotope,
            getLocationLicences
        })(this);

        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.identifier = 'app.administration.accounts.siteLocations';
            this.state = 'app.administration.accounts.siteLocations.licenceIsotopes';

            this.getIsotopes(ADM_LIC_ISOTOPE);
            this.getUnits(ADM_LIC_ISOTOPE, this.administrationAccountsLicenceIsotope.unitType, 'units');
        }

        this.onIsotope = ({ input }) => {
            this.getAtomicNums(ADM_LIC_ISOTOPE, input, true);
        };

        this.onUnit = ({ input }) => {
            this.getUnits(ADM_LIC_ISOTOPE, input, 'units');
        };

        this.addLicenceIsotope = () => {
            $scope.$broadcast('show-errors-check-validity', '$ctrl.addIsotopeForm');
            if (this.addIsotopeForm.$valid) {
                this.addIsotope(this.administrationAccountsLicenceIsotope, this.stateParams.lic).then(() => {
                    Notifier.success('New isotope has been successfully added to licence');
                    this.addIsotopeForm.$setPristine();
                    this.addIsotopeForm.$setUntouched();
                }).catch((error) => {
                    Notifier.error(error.data);
                });
            } else {
                Notifier.error('Please fill in the Isotope and the Maximum Quantity form fields');
            }
        }

        this.onRemove = ({ item }) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen isotope will be removed permanently from this license.')
                .ariaLabel('Remove isotope')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.removeIsotope(item.lti_id);
            }, angular.noop);
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./administration-accounts-site-location-licence-isotopes.html')
};
