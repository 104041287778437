import API from '../../../common/http/api.fetch'

export const getAlertReceivers = (filter) => {

    return (dispatch, getState) => {
        const state = getState();

        return API.fetch({
            url: `/event/alert-escalate/receivers`,
            method: 'GET',
            params: {
                iev_id: state.events.eventItem.id,
                offset: filter.offset,
                limit: filter.limit
            }
        }).then(response => {
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: response.data.items.map((item) => {
                    return {
                        ...item,
                        id: item.ntp_id
                    };
                }),
                itemsCount: parseInt(response.data.items_count)
            });
        });
    };
};

export const setAlertReceivers = () => {
    return (dispatch, getState) => {
        const state = getState();
        const data = state.events.eventsAlertReceiversGrid.items.map(item => {
            return item.ntp_id.toString();
        });
        dispatch({
            type: 'GRID_SELECT_ALL',
            id: 'eventsAlertReceiversGrid',
            data,
        });
        return Promise.resolve(data.length);
    };
};

export const getAlertNotifications = (filter) => {
    return (dispatch, getState) => {
        const state = getState();
        return API.fetch({
            url: `/event/alert-escalate/notifications`,
            method: 'GET',
            params: {
                iev_id: state.events.eventItem.id,
                offset: filter.offset,
                limit: filter.limit
            }
        }).then(response => {
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: response.data.items.map((item) => {
                    return {
                        ...item,
                        icon: 'email-gray.svg',
                        dateTime: true
                    };
                }),
                itemsCount: parseInt(response.data.items_count)
            });
        });
    };
};

export const getEmployees = (id, keyword) => {
    return (dispatch) => {
        return API.fetch({
            url: `/employees`,
            method: 'GET',
            params: {
                keyword,
                status: 'act',
                licences: false,
                offset: 0,
                limit: 20,
            }
        }).then(response => {
            dispatch({
                id,
                type: 'EVENT_ALERT_EMPLOYEES',
                data: response.data
            });
        });
    };
};

export const addReceiver = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: `/event/alert-escalate/receivers/add`,
            method: 'POST',
            data
        }).then(response => {
            dispatch({
                type: 'EVENT_RECEIVER_ADD',
                id: 'eventAlertEscalate'
            });
            dispatch({
                type: 'GRID_RELOAD',
                id: 'eventsAlertReceiversGrid'
            });
        });
    };
};

export const removeReceiver = (iev_id, ntp_id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/event/alert-escalate/receivers/remove`,
            method: 'GET',
            params: {
                iev_id,
                ntp_id
            }
        }).then(response => {
            dispatch({
                type: 'EVENT_RECEIVER_REMOVE',
                id: 'eventAlertEscalate'
            });
            dispatch({
                type: 'GRID_RELOAD',
                id: 'eventsAlertReceiversGrid'
            });
        });
    };
};

export const resetReceiver = () => {
    return (dispatch) => {
        dispatch({
            type: 'EVENT_RECEIVER_RESET',
            id: 'eventAlertEscalate'
        });
    };
};

export const getNotification = (notification) => {
    return dispatch => {
        dispatch({
            type: 'EVENT_NOTIFICATION_DETAILS',
            id: 'eventAlertEscalate',
            notification
        });
    };
};

export const sendNotification = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: `/event/alert-escalate/notifications/send`,
            method: 'POST',
            data
        }).then(response => {
            dispatch({
                type: 'EVENT_NOTIFICATION_SEND',
                id: 'eventAlertEscalate'
            });
            dispatch({
                type: 'GRID_RELOAD',
                id: 'eventsAlertNotificationsGrid'
            });
        });
    };
};

export const resetNotification = (filter) => {
    return dispatch => {
        dispatch({
            type: 'EVENT_NOTIFICATION_RESET',
            id: 'eventAlertEscalate'
        });
    };
};
