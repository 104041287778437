/* @ngInject */
export function ProtectiveEquipmentActionsFactory(ProtectiveEquipmentService, ProtectiveEquipmentTestService) {
    return {
        getProtectiveEquipment: function(filter) {
            return function(dispatch) {
                ProtectiveEquipmentService.getPersonalEquipment(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'protectiveEquipmentGrid',
                        items: data.items,
                        itemsCount: data.total
                    });
                });
            };
        },
        getProtectiveEquipmentHistory: function(filter) {
            return function(dispatch) {
                ProtectiveEquipmentService.getEquipmentHistory(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'protectiveEquipmentHistoryGrid',
                        items: data.items,
                        itemsCount: data.total
                    });
                });
            };
        },
        getProtectiveEquipmentTests: function(filter) {
            return function(dispatch) {
                ProtectiveEquipmentTestService.getPersonalEquipmentTests(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'protectiveEquipmentTestsGrid',
                        items: data.items,
                        itemsCount: data.total
                    });
                });
            };
        },
        getProtectiveEquipmentTestReports: function(filter) {
            return function(dispatch) {
                ProtectiveEquipmentTestService.getPersonalEquipmentTests(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'protectiveEquipmentTestReportsGrid',
                        items: data.items,
                        itemsCount: data.total
                    });
                });
            };
        }
    };
}
