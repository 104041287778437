export function ContactFactory() {
    return function Contact() {
        this.id = null;
        this.uuid = null;
        this.firstName = null;
        this.lastName = null;
        this.email = null;
        this.fax = null;
        this.username = null;
        this.phone = null;
    };
}
