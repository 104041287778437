/* @ngInject */
export function EquipmentActionsFactory(EquipmentService, DocumentService, EquipmentManager, AccreditationDocumentsService, ACC_ACCREDITATION_CODE) {
    return {
        getEquipmentTypes: function(identifier) {
            return function(dispatch) {
                EquipmentService.fetchTypes(null, true).then(function(data) {
                    dispatch({
                        type: 'EQUIPMENT_TYPES',
                        id: identifier,
                        items: data
                    });
                });
            };
        },
        getEquipmentTypeTree: function(identifier) {
            return function(dispatch) {
                EquipmentService.fetchTypes(null, null, true).then(function(data) {
                    dispatch({
                        type: 'EQUIPMENT_TYPES',
                        id: identifier,
                        items: data
                    });
                });
            };
        },
        getEquipmentCategories: function(identifier, type) {
            return function(dispatch) {
                EquipmentService.getCategories(type).then(function(data) {
                    dispatch({
                        type: 'EQUIPMENT_CATEGORIES',
                        id: identifier,
                        items: data
                    });
                });
            };
        },
        getRequirementsDaysRangeList: function(identifier) {
            var requirementsDaysRangeList = {
                0: {
                    id: 1,
                    name: 'Today'
                },
                1: {
                    id: 30,
                    name: '30 Days'
                },
                2: {
                    id: 60,
                    name: '60 Days'
                },
                3: {
                    id: 90,
                    name: '90 Days'
                }
            };
            return function(dispatch) {
                dispatch({
                    type: 'EQUIPMENT_REQUIREMENTS_DAYS_RANGE_LIST',
                    id: identifier,
                    items: requirementsDaysRangeList
                });
            };
        },
        getRequirementTypes: function(identifier, type) {
            return function(dispatch) {
                DocumentService.fetchEquipmentDocumentTypes(null, type, true).then(function(data) {
                    dispatch({
                        type: 'EQUIPMENT_REQUIREMENT_TYPES',
                        id: identifier,
                        items: data
                    });
                });
            };
        },

        getEquipmentGrid: function(filter) {
            return function(dispatch) {
                EquipmentService.getEquipmentGrid(filter).then(function (data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: filter.identifier,
                        items: data.items.map((item) => ({
                            ...item,
                            class: item.status === 'sto' ? 'orange' : null
                        })),
                        itemsCount: filter.status === 'act' ? data.totalActive : data.totalArchive,
                        activeCount: data.totalActive,
                        archiveCount: data.totalArchive
                    });
                });
            }
        },
        getEquipment: function(filter) {
            return function(dispatch) {
                EquipmentService.getEquipment(filter).then(function (data) {

                    if (filter.identifier === "equipmentTechniqueChartsGrid") {
                        var equipmentIdArray = [];
                        var optimizedChartsYear = new Date().getFullYear();
                        //get all equipment ids
                        data.items.forEach(function (item) {
                            equipmentIdArray.push(Number(item.equipment_id));
                        });


                        AccreditationDocumentsService.fetchAccOptimizedCharts(ACC_ACCREDITATION_CODE.ANY, equipmentIdArray,
                            optimizedChartsYear, null, null)
                            .then(function (response) {

                                if (response.data.result != null) {
                                    var optimizedCharts = {};

                                    response.data.result.forEach(function(chartItem) {
                                        optimizedCharts[chartItem.equipmentId] = chartItem;
                                    });

                                    data.items.forEach(function(equipmentItem) {
                                        equipmentItem.optimizedChart = optimizedCharts[equipmentItem.equipment_id]
                                    });
                                }

                                dispatch({
                                    type: 'GRID_FETCH',
                                    id: filter.identifier,
                                    items: data.items,
                                    itemsCount: filter.status === 'act' ? data.totalActive : data.totalArchive,
                                    activeCount: data.totalActive,
                                    archiveCount: data.totalArchive
                                });

                            });
                    } else {
                        dispatch({
                            type: 'GRID_FETCH',
                            id: filter.identifier,
                            //items: data.items,
                            items: data.items.map((item) => ({
                                ...item,
                                class: item.status === 'sto' ? 'orange' : null
                            })),
                            itemsCount: filter.status === 'act' ? data.totalActive : data.totalArchive,
                            activeCount: data.totalActive,
                            archiveCount: data.totalArchive
                        });
                    }

                });
            };
        },
        getOptimizedCharts: function(accCode, equipmentIds, year, filter, exportFormat) {
            return function(dispatch) {
                AccreditationDocumentsService.fetchAccOptimizedCharts(accCode, equipmentIds, year, filter,  exportFormat).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: filter.identifier,
                        items: data.items
                    });
                });
            };
        },
        getEquipmentDocuments: function(filter) {
            return function(dispatch) {
                DocumentService.fetchEquipmentDocumentsList(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'equipmentDocumentsGrid',
                        items: data.items,
                        itemsCount: data.total
                    });
                });
            };
        }
    };
}
