import { IDS } from '../acc-documents-action-id.constants';
import {TYPES} from "../acc-documents-action-type.constants";
import * as AccDocumentsActions from '../acc-documents.actions';
import { TAB_SELECT, TAB_SHOW } from '../../../../common/tabs/tabs.constants'
import {GRID_FILTER} from "../../../../common/grid/grid.constants";
import get from "lodash/get";
import {formatDate} from "../../../../common/utils/util.date";
import {DATE_FORMAT} from "../../../../common/utils/util.constants";

/**
 * Creates a new AccDocumentDetailsController.
 * @class
 */
export const accDocumentDetails = {
    /* @ngInject */
    controller: function($ngRedux, $state, $stateParams, $scope, $rootScope, $filter, NavigationService, AuthService, Notifier, FileActions) {
        this.$onInit = async () => {
            this.identifier = IDS.accDocuments;
            this.parentState = IDS.app_accreditation_scheme_acc_documents;

            this.unsubscribe = $ngRedux.connect(state => {
                const accDocuments = state.accreditation.accDocuments;

                const document = accDocuments.accDocumentDetails;

                const textId = `COMMON.DOCUMENT.CODE.${document?.type_code.toUpperCase()}`;
                let typeName = $filter('translate')(textId);
                if(typeName === textId)
                    typeName = document.type_name;

                return {
                    typeName,
                    document,
                    schemeName: accDocuments.schemeDetails.title,
                }
            }, {
                ...FileActions,
                ...AccDocumentsActions,
            })(this);
            this.title = `${this.document.name}`;
            this.schemeCode = $stateParams.itemCode;

            // this.getSchemeDetails(this.schemeCode).then(scheme => {
            //     this.fetchEnabledHeadOffices(scheme.id).then(headOffices => {})
            // })
        }

        this.onTemplateUploadSave = (event) => {
            $ngRedux.dispatch({
                type: TYPES.DOCUMENT_SAMPLE_DOC_CHANGED,
                id: IDS.accDocuments,
                path: IDS.accDocumentDetails,
                template: event[0].filePath,
            });
        }

        this.onSaveChangesButton = () => {
            $scope.$broadcast('show-errors-check-validity', '$ctrl.accDocumentDetailsForm');

            if (this.accDocumentDetailsForm.$valid === false) {
                Notifier.error('COMMON.FIELDS_REQUIRED');
                return;
            }

            this.editAccStandardRequiredDocument({
                uuid:           this.document.id,
                name:           this.document.name,
                number:         this.document.doc_num,
                doc_date:       formatDate(this.document.doc_date, DATE_FORMAT.OUTPUT),
                due_date:       formatDate(this.document.due_date, DATE_FORMAT.OUTPUT),
                template:       this.document.template,
            }).then(() => {
                $ngRedux.dispatch({
                    type: TAB_SELECT,
                    id: this.parentState,
                    state: IDS.app_accreditation_scheme_acc_documents_list
                });
            });
        }

        this.$onDestroy = () => {
            this.unsubscribe();
        }

    },
    template: require('./acc-document-details.html')
}
