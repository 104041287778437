/**
 * @module Events
 */
import * as STATES from './events.states';
import * as SAFETY_RISK from './safety-risk/events-safety-risk.states';
import * as OPERATIONAL_RISK from './operational-risk/events-operational-risk.states';
import * as RADIATION_RISK from './radiation-risk/events-radiation-risk.states';
import { events } from './events.component';
import { eventsGrid } from './grid/events-grid.component';
import { eventsDetails } from './events-details.component';
import { eventsAssessmentHazard } from './assessment/events-assessment-hazard.component';
import { eventsAssessmentIncident } from './assessment/events-assessment-incident.component';
import { eventsAssessmentThreat } from './assessment/events-assessment-threat.component';
import { eventsAssessmentIssue } from './assessment/events-assessment-issue.component';
import { eventsAssessmentGrid } from './assessment/grid/events-assessment-grid.component';
import { eventsAssessmentHistoricalRecord } from './assessment/historical-record/events-assessment-historical-record.component';
import { eventsAlertEscalate } from './alert-escalate/events-alert-escalate.component';
import { eventsAlertReceiversGrid } from './alert-escalate/grid/events-alert-receivers-grid.component';
import { eventsAlertNotificationsGrid } from './alert-escalate/grid/events-alert-notifications-grid.component';
import { eventsActionPlan } from './action-plan/events-action-plan.component';
import { eventsActionPlanLibrary } from './action-plan/action-plan-library/action-plan-library.component';
import { eventsActionPlanLibraryGrid } from './action-plan/action-plan-library/grid/action-plan-library-grid.component';
import { eventsActionTaskGrid } from './action-plan/grid/events-action-task-grid.component';
import { eventsActionTaskLibrary } from './action-plan/action-task-library/action-task-library.component';
import { eventsActionTaskLibraryGrid } from './action-plan/action-task-library/grid/action-task-library-grid.component';

export const EVENTS_MODULE = angular.module(
    'migration.events',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values({
        ...STATES,
        ...SAFETY_RISK,
        ...OPERATIONAL_RISK,
        ...RADIATION_RISK,
    }).map(state => stateRegistry.register(state))
}]).component({
    events,
    eventsGrid,
    eventsDetails,
    eventsAssessmentHazard,
    eventsAssessmentIncident,
    eventsAssessmentThreat,
    eventsAssessmentIssue,
    eventsAssessmentGrid,
    eventsAssessmentHistoricalRecord,
    eventsAlertEscalate,
    eventsAlertReceiversGrid,
    eventsAlertNotificationsGrid,
    eventsActionPlan,
    eventsActionPlanLibrary,
    eventsActionPlanLibraryGrid,
    eventsActionTaskGrid,
    eventsActionTaskLibrary,
    eventsActionTaskLibraryGrid
}).run(['$templateCache', ({ put }) => {
    put('app.events.addSafetyRiskPeople.details', '<events-details type="srp" />');
    put('app.events.addSafetyRiskPeople.assessmentHazard', '<events-assessment-hazard type="srp" />');
    put('app.events.addSafetyRiskPeople.assessmentIncident', '<events-assessment-incident type="srp" />');
    put('app.events.addSafetyRiskPeople.assessmentHistoricalRecord', '<events-assessment-historical-record />');
    put('app.events.addSafetyRiskPeople.alertEscalate', '<events-alert-escalate />');
    put('app.events.addSafetyRiskPeople.actionPlan', '<events-action-plan />');
    put('app.events.addSafetyRiskPeople.actionPlanLibrary', '<events-action-plan-library />');
    put('app.events.addSafetyRiskPeople.actionTaskLibrary', '<events-action-task-library />');
    put('app.events.addOperationalRiskBusiness.details', '<events-details type="orb" />');
    put('app.events.addOperationalRiskBusiness.assessmentThreat', '<events-assessment-threat type="orb" />');
    put('app.events.addOperationalRiskBusiness.assessmentIssue', '<events-assessment-issue type="orb" />');
    put('app.events.addOperationalRiskBusiness.assessmentHistoricalRecord', '<events-assessment-historical-record />');
    put('app.events.addOperationalRiskBusiness.alertEscalate', '<events-alert-escalate />');
    put('app.events.addOperationalRiskBusiness.actionPlan', '<events-action-plan />');
    put('app.events.addOperationalRiskBusiness.actionPlanLibrary', '<events-action-plan-library />');
    put('app.events.addOperationalRiskBusiness.actionTaskLibrary', '<events-action-task-library />');
    put('app.events.addRadiationRisk.details', '<events-details type="arr" />');
    put('app.events.addRadiationRisk.assessmentHazard', '<events-assessment-hazard type="arr" />');
    put('app.events.addRadiationRisk.assessmentIncident', '<events-assessment-incident type="arr" />');
    put('app.events.addRadiationRisk.assessmentHistoricalRecord', '<events-assessment-historical-record />');
    put('app.events.addRadiationRisk.alertEscalate', '<events-alert-escalate />');
    put('app.events.addRadiationRisk.actionPlan', '<events-action-plan />');
    put('app.events.addRadiationRisk.actionPlanLibrary', '<events-action-plan-library />');
    put('app.events.addRadiationRisk.actionTaskLibrary', '<events-action-task-library />');
    put('app.events.manageRisksIncidents.listing', '<events />');
    put('app.events.manageRisksIncidents.details', '<events-details />');
    put('app.events.manageRisksIncidents.assessmentHazard', '<events-assessment-hazard />');
    put('app.events.manageRisksIncidents.assessmentIncident', '<events-assessment-incident />');
    put('app.events.manageRisksIncidents.assessmentThreat', '<events-assessment-threat />');
    put('app.events.manageRisksIncidents.assessmentIssue', '<events-assessment-issue />');
    put('app.events.manageRisksIncidents.assessmentHistoricalRecord', '<events-assessment-historical-record />');
    put('app.events.manageRisksIncidents.alertEscalate', '<events-alert-escalate />');
    put('app.events.manageRisksIncidents.actionPlan', '<events-action-plan />');
    put('app.events.manageRisksIncidents.actionPlanLibrary', '<events-action-plan-library />');
    put('app.events.manageRisksIncidents.actionTaskLibrary', '<events-action-task-library />');
    put('./events-grid-filter.html', require('./grid/events-grid-filter.html'));
    put('./events-alert-receivers-grid-filter-controls.html', require('./alert-escalate/grid/events-alert-receivers-grid-filter-controls.html'));
    put('./action-plan-library-grid-filter.html', require('./action-plan/action-plan-library/grid/action-plan-library-grid-filter.html'));
    put('./action-task-library-grid-filter.html', require('./action-plan/action-task-library/grid/action-task-library-grid-filter.html'));
}]);
