export function SettingsManager() {
    this.TAB_SETTINGS_PASSWORD = 0;
    this.tplSettingsPassword = './settings-password.html';
    this.tabs = [
        {
            id: this.TAB_SETTINGS_PASSWORD,
            title: 'Account Settings',
            content: this.tplSettingsPassword,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: false,
            stateless: true
        }
    ];
}
