import API from '../../../../common/http/api.fetch';
import {TYPES} from '../dms-import-action-type.constants';
import {IDS} from '../dms-import-action-id.constants';
import {success, responseMessage} from '../../../../common/utils/util.notifier';
import {GRID_FETCH, GRID_FILTER, GRID_RELOAD} from '../../../../common/grid/grid.constants';
import {TAB_ENABLE, TAB_SHOW} from "../../../../common/tabs/tabs.constants";
import camelCase from 'lodash/camelCase';
import I18N from "../../../../common/utils/util.i18n";

export const fetchPNPTemplatesListGrid = (filter) => {
    return dispatch => {
        return API.fetch({
            url: '/pnp/list-templates',
            method: 'GET',
            params: filter,
        }).then(response => {
            dispatch({
                type:   GRID_FETCH,
                id:     IDS.dmsManage,
                path:   IDS.dmsManageListGrid,
                ...response.data,
            })
        }, error => {
            responseMessage(error);
        })
    }
}

export const fetchDocumentsDetails = (id) => {
    return dispatch => {
        return API.fetch({
            url:    '/pnp/document-details',
            method: 'GET',
            params: { id }
        }).then( response => {
            const reviews = response.data[0]
                .map(item => camelCaseObject(item))
                .map(item => ({...item,
                    nameAndUsername: item.name + (item.username ?` (${item.username})` : ''),
                    statusName: I18N.translate(`ACCREDITATION.DOCUMENT_EVALUATION.STATUS.${item.statusCode.toUpperCase()}`)
                }))
            const review = reviews && reviews.find(item => item.authorised)
            response = camelCaseObject(JSON.parse(response.data[2][0]['@params']));
            const filename = response.url.split('/').pop().replace(/^[0-9]*-(.*)/, '$1');
            const limit = 128;
            dispatch({
                type: TYPES.FETCH_DOCUMENT_DETAILS,
                id: IDS.dmsManage,
                details: {
                    ...response,
                    filename:  filename.length > limit ? `${filename.substr(0, limit)}...` : filename,
                    filenameTooltip: filename,
                    review,
                    originalReview: review ? {...review} : null,
                },
                reviews,
            })
            return response
        },
        error => responseMessage(error) );
    };
}

export const invalidatePnpTemplate = (id, path) => {
    return dispatch => {
        return API.fetch({
            url: '/pnp/invalidate-template',
            method: 'POST',
            data: { id }
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_DELETED');
            dispatch({
                type:   GRID_RELOAD,
                id:     IDS.dmsManage,
                path,
            })
        }, error => {
            responseMessage(error);
        });
    };
}

export const switchToDetailsTab = ($timeout, stateParams) => {
    return dispatch => {
        dispatch({
            type: TAB_ENABLE,
            id: IDS.app_dms_manage,
            state: [ IDS.app_dms_manage_details]
        })

        $timeout(() => {
            dispatch({
                type: TAB_SHOW,
                id: IDS.app_dms_manage,
                state: IDS.app_dms_manage_details,
                stateParams,
            })
        })
    }
}

export const switchToAddDocumentTab = (stateParams) => {
    return dispatch => {
        dispatch({
            type: TAB_SHOW,
            id: IDS.app_dms_manage,
            state: IDS.app_dms_manage_add,
            stateParams,
        })
    }
}

// export const fetchSiteLocationDetails = (siteLocationUuid) => {
//     return dispatch => {
//         return API.fetch({
//             url: `/location/sitelocation/${siteLocationUuid}`,
//             method: 'GET',
//             params: {}
//         }).then(response => {
//             dispatch({
//                 type: TYPES.FETCH_SITE_LOCATION_DETAILS,
//                 id: IDS.accMasterDocList,
//                 data: {
//                     siteLocationUuid,
//                     fiscalYearStart: parseInt(  response.data[0][0].fiscal_year_start ) - 1,
//                     sloQualityManagers: response.data[1].map(item => ({...item, employee_uuid: item.subject_uuid})),
//                 }
//             })
//         })
//     }
// }

function camelCaseObject (obj) {
    return Object.entries(obj).reduce(
        (result, item) => ( {...result, [camelCase(item[0])]: item[1]} ), {}
    );
}