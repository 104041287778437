/**
 * @namespace Reducers.Navigation
 */
import * as TYPE from './navigation.actions.types';
import { product } from '../../bootstrap.json';

const initialState = {
    configs: [],
    module: null,
    state: null,
    dashboard: null,
    color: '#2980b9',
    loading: false,
    accountOptions: [
        { title: 'Settings', state: 'app.settings' },
        { title: `${product} System`, state: 'app.system.dashboard' },
        { title: 'Logout', state: 'logout' }
    ]
};

/**
 * @function navigationReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function navigationReducer(state = initialState, action) {
    switch (action.type) {
        case TYPE.INIT_MODULE:
            return {
                ...state,
                module: action.module,
                color: action.color || initialState.color,
            };
        case TYPE.NAVIGATION_STATE:
            return {
                ...state,
                state: action.state,
                dashboard: null,
            };
        case TYPE.NAVIGATION_CONFIGS:
            return {
                ...state,
                configs: action.configs,
            };
        case TYPE.INIT_DASHBOARD:
            return {
                ...state,
                dashboard: action.dashboard,
            };
        case TYPE.LOADING:
            return {
                ...state,
                loading: action.data,
            };
        default:
            return state;
    }
}
