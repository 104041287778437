import { product } from '../../../../bootstrap.json';
import { AP_REQUEST_TOKEN } from '../../applications-permits.actions.types';
import { getRequestLocations } from '../ap-laser-request.actions';
import i18n from '../../i18n/en.json';
import get from 'lodash/get';

/**
 * @const {object}
 * @memberOf module:ApplicationsPermits
 */
export const apLaserRequestIntro = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $location) {
        this.product = product;

        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.title = i18n[this.type].intro.title;
            this.p1 = i18n[this.type].intro.p1;
            this.p2 = i18n[this.type].intro.p2;
            this.li1 = i18n[this.type].intro.ul.li1;
            this.li2 = i18n[this.type].intro.ul.li2;
            this.processTitle = i18n[this.type].intro.process.title;
            this.processParagraph = i18n[this.type].intro.process.p;
            this.processOL = i18n[this.type].intro.process.ol;
            this.footnoteOL = i18n[this.type].intro.footnote.ol;

            this.unsubscribe = $ngRedux.connect(() => ({}), {
                getRequestLocations
            })(this);

            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.intro`;

            const token = $location.search().token;
            const invite = $location.search().invite;

            $ngRedux.dispatch({
                type: AP_REQUEST_TOKEN,
                id: 'apLaserRequest',
                token,
                invite
            });

            this.getRequestLocations({ token, id: invite }).then((result) => {
                const location = get(
                    result, 'rom_uuid', get(
                        result, 'dep_uuid', get(
                            result, 'slo_uuid', get(
                                result, 'hof_uuid'
                            )
                        )
                    )
                );
                $ngRedux.dispatch({
                    type: 'LOCATION_SELECTED',
                    id: 'apLaserRequestEquipmentGrid',
                    location
                });
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'apLaserRequestEquipmentGrid'
                });
            });
        }

        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: require('./ap-laser-request-intro.html')
};
