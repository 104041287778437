import { IDS } from '../master-doc-list-action-id.constants';
import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import * as DocumentListActions from './list.actions';
import {GRID_RELOAD} from "../../../../common/grid/grid.constants";

class MasterDocListGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter, $mdDialog, $scope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
    }

    $onInit() {
        this.module = 'accreditation';
        this.identifier = IDS.accMasterDocList;
        this.statePath = IDS.documentListGrid;

        // this.filterTemplate = './acc-documents-list-grid-filter.html';
        this.filterComponent = false;

        this.exportComponent = false;
        this.title = false;
        this.icon = false;
        this.confirmDialogOnRemove = true;
        this.autoload = false;

        this.columns = [
            {title: '#', mapper: null},
            {title: '',                                                         mapper: 'icon', style: { width: '35px' }},
            {title: this.$filter('translate')('COMMON.DOCUMENT.DCR_NUMBER'),    mapper: 'doc_num'},
            {title: this.$filter('translate')('ACCREDITATION.COMMON.STANDARD'), mapper: 'standard_name'},
            {title: this.$filter('translate')('COMMON.FIELD.NAME'),             mapper: 'filename'},
            {title: this.$filter('translate')('COMMON.FIELD.UPLOADED_BY'),      mapper: 'uploaded_by'},
            {title: this.$filter('translate')('COMMON.DATE.ISSUE_DATE'),        mapper: 'uploaded_at'},
            {title: this.$filter('translate')('COMMON.FIELD.APPROVED_BY'),      mapper: 'approved_by'},
            {title: this.$filter('translate')('COMMON.FIELD.APPROVAL_DATE'),    mapper: 'last_validation_at'},
            {title: this.$filter('translate')('COMMON.DATE.DUE_DATE'),          mapper: 'due_date'},
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), DocumentListActions)(this);

        this.$scope.$on('LOCATION_SUBJECT_SELECTED', this.onLocationSelected.bind(this));

        this.dataAction = this.fetchMasterDocListGrid;
        this.init();
    }

    onLocationSelected(event, location) {
        if(location.in_subject_basic_type_code === 'slo') {
            this.filter.slo_uuid = location.in_subject_uuid;
            this.$ngRedux.dispatch({
                type: GRID_RELOAD,
                id: IDS.accMasterDocList,
                path: IDS.documentListGrid,
            })
        }
    }

    $onDestroy() {
        this.destroy();
    }

}

MasterDocListGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter', '$mdDialog', '$scope'];

export const accMasterDocListGrid = {
    bindings: {
        onSelect: '&',
        onRemove: '&',

    },
    controller: MasterDocListGridController,
    ...gridTpl
}
