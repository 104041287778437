/**
 * Creates a new RadioactiveMaterialsWasteAlertsController.
 * @class
 */
import { SystemAlertsController } from '../../../system/alerts/system-alerts.component';

class RadioactiveMaterialsWasteAlertsController extends SystemAlertsController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.config = {
            filter: './rm-waste-alerts-grid-filter.html',
            columns: [
                {title: '#',                    mapper: null},
                {title: '',                     mapper: 'alert'},
                {title: 'Source ID',            mapper: null},
                {title: 'Bin No.',              mapper: null},
                {title: 'Isotope',              mapper: null},
                {title: 'Location',             mapper: null},
                {title: 'Document/Description', mapper: null},
                {title: 'Due Date',             mapper: null},
            ]
        };
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;
    }

    onSelect(event) {
        // this.$state.go(this.state, {id: event.item.equipmentId});
    }
}

export const rmWasteAlerts = {
    bindings: {
        state: '@'
    },
    controller: RadioactiveMaterialsWasteAlertsController,
    template: `
        <system-alerts-grid identifier="rmWasteAlertsGrid"
                           module-id="radioactiveMaterials"
                           icon="alerts.svg"
                           config="$ctrl.config"
                           on-select="$ctrl.onSelect($event)">
        </system-alerts-grid>
    `
};
