import {TAB_HIDE, TAB_DISABLE} from '../../../common/tabs/tabs.constants';
import {IDS} from './master-doc-list-action-id.constants';

const QUERY_PARAMS = '?ado&year';

export const tabsState = {
    name: IDS.app_accreditation_scheme_master_doc_list,
    redirectTo: IDS.app_accreditation_scheme_master_doc_list_list,
    url: `/:itemCode/master-doc-list${QUERY_PARAMS}`,
    component: 'tabs',
};

export const tabListState = {
    name: IDS.app_accreditation_scheme_master_doc_list_list,
    url: '/list',
    component: 'documentList',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: IDS.app_accreditation_scheme_master_doc_list,
            state: [ IDS.app_accreditation_scheme_master_doc_list_details ]
        })
    }
};
tabListState.onEnter.$inject = ['$ngRedux'];

export const tabDetailsState = {
    name: IDS.app_accreditation_scheme_master_doc_list_details,
    url: `/details`,
    component: 'documentDetails',
};

export const tabAddDocumentState = {
    name: IDS.app_accreditation_scheme_master_doc_list_add,
    url: `/add`,
    component: 'documentAdd',
};