import { GridReducer } from '../common/grid/grid.reducer';
import { administrationMonthlyReportSettingsGrid } from '../components/administration/monthly-report-settings/grid/administration-monthly-report-settings-grid.component';
import merge from 'lodash/merge';
import set from 'lodash/set';
import update from 'lodash/update';

/**
 * @function administrationMonthlyReportSettingsReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */

export function administrationMonthlyReportSettingsReducer(state, action) {
    if (typeof state === 'undefined') {
        return {
            administrationMonthlyReportSettingsGrid: merge(GridReducer(), {
                filter: {
                    limit: null
                }
            }),
            administrationMonthlyReportSettings: {
                location: null,
                locationName: null,
                generateCorporateReport: null,
                generateBySitesReport: null,
                generateCorporateReportTo: null,
                generateBySitesReportTo: null
            }
        };
    }
    switch (action.type) {
        case 'GRID_INIT':
            if (action.id === 'administrationMonthlyReportSettingsGrid') {
                return Object.assign({}, state, {
                    administrationMonthlyReportSettingsGrid: GridReducer(state.administrationMonthlyReportSettingsGrid, action)
                });
            }
        case 'GRID_OVERRIDE':
        case 'SHOW_LOADING':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
            if (action.id === 'administrationMonthlyReportSettingsGrid') {
                return Object.assign({}, state, {
                    administrationMonthlyReportSettingsGrid: GridReducer(state.administrationMonthlyReportSettingsGrid, action)
                });
            }
        case 'INPUT_TEXT_CHANGE':
        case 'CHECKBOX_SELECTED':
        case 'INPUT_RADIO_CHANGE':
            if (action.id === 'administrationMonthlyReportSettings') {
                return {
                    ...state,
                    administrationMonthlyReportSettings: Object.assign({}, state.administrationMonthlyReportSettings, action.data)
                }
            }
        case 'INPUT_EMAIL_CHANGE':
            if (action.id === 'administrationMonthlyReportSettings') {
                return {
                    ...state,
                    administrationMonthlyReportSettings: {
                        ...state.administrationMonthlyReportSettings,
                        [action.path]: action.input
                    }
                };
            }
        case 'MONTHLY_REPORT_DETAILS':
            if (action.id === 'administrationMonthlyReportSettings') {
                return Object.assign({}, state, {
                    administrationMonthlyReportSettings: Object.assign({}, state.administrationMonthlyReportSettings, {
                        location: action.data.loc_uuid,
                        locationName: action.data.loc_name,
                        generateCorporateReport: action.data.generate_corporate_report,
                        generateBySitesReport: action.data.generate_by_sits_report,
                        generateCorporateReportTo: action.data.generate_corporate_report_to,
                        generateBySitesReportTo: action.data.generate_by_sits_report_to
                    })
                });
            }
        case 'COUNT_ITEMS':
            if (action.id === 'administrationMonthlyReportSettingsGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return Object.assign({}, filter, {
                        limit: -1,
                    });
                })));
            }
        default:
            return state;
    }
}
