import API from '../../common/http/api.fetch';
import get from 'lodash/get';

export const getLicenceIsotopes = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: `/licence/isotopes`,
            method: 'GET',
            params: {
                licenceId: parseInt(filter.licenceId),
                offset: filter.offset,
                limit: filter.limit,
                order_column:   get(filter, 'sort.column'),
                order:          get(filter, 'sort.value')
            }
        }).then(({ data }) => {
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.items.map((item) => ({
                    ...item,
                    source_type: parseInt(item.source_type_id) === 1 ? 'Sealed' : parseInt(item.source_type_id) === 0 ? 'Unsealed' : '',
                })),
                itemsCount: parseInt(data.items_count)
            })
        });
    }
}

export const addIsotope = (data, licenceId) => {
    return (dispatch) => {
        return API.fetch({
            url: `/licence/isotope/add`,
            method: 'POST',
            data: {
                atomicNumberId: data.atomicNum,
                unitId: data.unit,
                licenceId: licenceId,
                isotopeId: data.isotope,
                maximumActivity: data.maximumActivity,
                sourceTypeId: data.sourceType
            }
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'administrationAccountsLicenceIsotopesGrid'
            }),
            dispatch({
                type: 'RESET_ISOTOPE_FORM',
                id: 'administrationAccountsLicenceIsotope'
            })
        });
    }
}

export const removeIsotope = (licIsoId) => {
    return (dispatch) => {
        return API.fetch({
            url: `/licence/isotope/remove`,
            method: 'GET',
            params: {
                licIsoId: licIsoId
            }
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'administrationAccountsLicenceIsotopesGrid'
            })
        });
    }
};

export const getLicenceAppendix = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: `/licence/appendix`,
            method: 'GET',
            params: {
                licenceId: parseInt(filter.licenceId),
                offset: filter.offset,
                limit: filter.limit,
                order_column:   get(filter, 'sort.column'),
                order:          get(filter, 'sort.value')
            }
        }).then(({ data }) => {
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.items.map((item) => ({
                    ...item,
                    uri: item.lic_uri
                })),
                itemsCount: parseInt(data.items_count)
            })
        });
    }
}

export const addAppendix = (data, licenceId) => {
    return (dispatch) => {
        return API.fetch({
            url: `/licence/appendix/add`,
            method: 'POST',
            data: {
                name: data.appendixName,
                doc_uri: data.document,
                lic_id: licenceId
            }
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'administrationAccountsLicenceAppendixGrid'
            }),
                dispatch({
                    type: 'RESET_APPENDIX_FORM',
                    id: 'administrationAccountsLicenceAppendix'
                })
        });
    }
}

export const removeAppendix = (appendixId) => {
    return (dispatch) => {
        return API.fetch({
            url: `/licence/appendix/remove`,
            method: 'GET',
            params: {
                licenceId: appendixId
            }
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'administrationAccountsLicenceAppendixGrid'
            })
        });
    }
};