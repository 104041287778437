import * as TABS from '../../common/tabs/tabs.constants';
import _ from 'lodash';

/**
 * @function TabsReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers.App
 */
export function TabsReducer(state = undefined, action= undefined) {

    if (_.isUndefined(state)) {
        return {
            tabs: [],
            selectedTabIndex: 0,
            selectedState: null,
            initParams: true,
            isTransitionDisabled: false,
            stateParams: {}
        };
    }

    let index, tabs;
    switch (action.type) {
        case TABS.TAB_INIT:
            return _.assign({}, state, {
                selectedTabIndex: undefined,
                stateParams: state.initParams ? {} : state.stateParams
            });
        case TABS.TAB_OVERRIDE:
            return _.assign({}, state, {
                tabs: state.tabs.map(tab => {
                    const item = _.assign({}, tab);
                    const override = _.find(action.override, ['state', tab.state]);
                    if (override) {
                        if (_.has(override, 'buttons.redirect', null)) item.buttons.redirect = override.buttons.redirect;
                        if (_.has(override, 'buttons.execute', null)) item.buttons.execute = override.buttons.execute;
                    }
                    return item;
                })
            });
        case TABS.TAB_PARAMS:
            return _.assign({}, state, {
                initParams: _.isUndefined(action.initParams) ? state.initParams : action.initParams,
                stateParams: action.stateParams
            });
        case TABS.TAB_SELECT:
            return _.assign({}, state, {
                selectedTabIndex: _.findIndex(state.tabs.filter(item => {
                    return item.visible;
                }), ['state', action.state]),
                stateParams: _.isUndefined(action.stateParams) ? state.stateParams : action.stateParams
            });
        case TABS.TAB_ENABLE:
            return _.assign({}, state, {
                tabs: state.tabs.map(item => {
                    if (_.includes(action.state, item.state)) {
                        item.disabled = false;
                    }
                    return item;
                })
            });
        case TABS.TAB_DISABLE:
            return _.assign({}, state, {
                tabs: state.tabs.map(item => {
                    if (_.includes(action.state, item.state)) {
                        item.disabled = true;
                    }
                    return item;
                })
            });
        case TABS.TAB_SHOW:
            return _.assign({}, state, {
                tabs: state.tabs.map(item => {
                    if (_.includes(action.state, item.state)) {
                        item.disabled = false;
                        item.visible = true;
                    }
                    return item;
                }),
                stateParams: action.stateParams
            });
        case TABS.TAB_HIDE:
            tabs = state.tabs.map(tab => {
                const item = _.assign({}, tab);
                if (_.includes(action.state, tab.state)) {
                    item.visible = false;
                }
                return item;
            });
            return _.assign({}, state, {
                tabs: tabs,
                selectedTabIndex: _.findIndex(tabs.filter(function(item) {
                    return item.visible;
                }), ['state', state.selectedState])
            });
        case TABS.TAB_LOADING:
            return _.assign({}, state, {
                tabs: state.tabs.map(item => {
                    if (action.state === item.state) {
                        item.loading = action.loading;
                    }
                    return item;
                })
            });
        case TABS.TAB_LABEL:
            index = _.findIndex(state.tabs,['state', action.state]);
            return _.set({...state}, `tabs[${index}].title`, action.label)
        case TABS.TAB_BUTTON_LABEL:
            index = _.findIndex(state.tabs,['state', action.state]);
            return _.set({...state}, `tabs[${index}].buttons.execute.label`, action.label)
        case TABS.TAB_TRANSITION_DISABLE:
            return _.set( {...state}, 'isTransitionDisabled', true );
        case TABS.TAB_TRANSITION_ENABLE:
            return _.set( {...state}, 'isTransitionDisabled', false );
        default:
            return state;
    }
}
