import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import { getLicenceAppendix } from '../../administration.actions';

class AdministrationAccountsLicenceAppendixGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'administration';
        this.identifier = 'administrationAccountsLicenceAppendixGrid';
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                    mapper: null},
            {title: 'Licence',              mapper: 'licence_type'},
            {title: 'Appendix',             mapper: 'name', sort: 'name'},
            {title: 'Document',             mapper: 'file-download'}
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), {
                getLicenceAppendix
            }
        )(this);

        this.dataAction = this.getLicenceAppendix;
        this.filter.identifier = this.identifier;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const administrationAccountsLicenceAppendixGrid = {
    bindings: {
        onRemove: '&'
    },
    controller: AdministrationAccountsLicenceAppendixGridController,
    ...gridTpl
};
