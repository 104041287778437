/**
 * Creates a new SelectBodyPartController.
 * @class
 */
class SelectBodyPartController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this.mapState, {})(this);
    }

    onSelect() {
        if (this.selectedOption) {
            this.$ngRedux.dispatch({
                type: 'BODY_PART_SELECTED',
                id: 'drlCtScannerDoseReport',
                bodyPart: this.selectedOption
            });
        }
    }

    $onDestroy() {
        this.unsubscribe();
    }

    mapState(state) {
        return {
            options: state.doseReferenceLevels.drlCtScannerDoseReport.bodyPartList,
            selectedOption: state.doseReferenceLevels.drlCtScannerDoseReport.bodyPart
        }
    }
}

SelectBodyPartController.$inject = ['$ngRedux'];

export const selectBodyPart = {
    bindings: {
        name: '@'
    },
    require : {
        form : '^form'
    },
    controller: SelectBodyPartController,
    template: `
        <md-input-container class="md-block" flex>
            <label>{{'DLR.CT_SCANNER.DOSE_REPORT.FORM_BODY_PART' | translate}}</label>
            <md-select id="{{$ctrl.name}}" name="{{$ctrl.name}}" ng-model="$ctrl.selectedOption" md-on-close="$ctrl.onSelect()" required>
                <md-option ng-value="item" ng-repeat="item in $ctrl.options">
                    <span class="md-body-2">{{item.name}}</span>
                </md-option>
            </md-select>
            <div ng-messages="$ctrl.form[$ctrl.name].$error" ng-show="$ctrl.form.$submitted || $ctrl.form[$ctrl.name].$touched">
                <div ng-message="required">{{'DLR.CT_SCANNER.DOSE_REPORT.FORM_FIELD_REQUIRED' | translate}}</div>
            </div>
        </md-input-container>
    `
};
