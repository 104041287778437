/* @ngInject */
export function SettingsService($http, API_BASE) {
    this.changePassword = function(user, uuid, password) {
        return $http({
            url: API_BASE + "/user/password",
            method: "POST",
            data: {
                sub_uuid:_.isUndefined(uuid) ? user.sub_uuid : uuid,
                passwordNew: _.isUndefined(password) ? user.passwordNew : password
            }
        }).then(function(response) {
            return response;
        });
    };
}
