/**
 * @module root/components/pnp/accred
 */
import * as STATES from './pnp.states';
import { PNP_ACCRED_MODULE } from './pnp-accred/pnp-accred.module';

export const POLICIES_AND_PROCEDURES_MODULE = angular.module(
    'migration.pnp',
    [
        PNP_ACCRED_MODULE.name,
    ]
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values(STATES).map(state => stateRegistry.register(state))
}]).component({
    // TODO register components
}).run(['$templateCache', ({ put }) => {
}]);
