import API          from '../../../../common/http/api.fetch';
import {success, responseMessage, error}
                    from '../../../../common/utils/util.notifier';
import { TYPES }    from '../audit-action-type.constants';
import { IDS }      from '../audit-action-id.constants';
import pick         from 'lodash/pick';
import get          from 'lodash/get';

export const resetFields = () => {
    return dispatch => {
        dispatch({
            type:   TYPES.RESET_ADD_AUDIT_FIELDS,
            id:     IDS.addAudit,
        })
    };
}

export const resetAssignees = () => {
    return dispatch => {
        dispatch({
            type:   TYPES.RESET_ADD_AUDIT_ASSIGNEES,
            id:     IDS.addAudit,
        })
    };
}

export const fetchEvidenceProviderCandidates = (keyword, location) => {
    return dispatch => {
        return API.fetch({
            url: `/employees`,
            method: 'GET',
            params: {
                keyword,
                location,
                status: 'act',
                //licences: false,
                offset: 0,
                limit: 20
            }
        }).then(response => {
            dispatch({
                id: IDS.accAudit,
                type: TYPES.FETCH_EVIDENCE_PROVIDER_CANDIDATES,
                data: response.data.list.map(item => ({
                    ...pick(item, ['first_name','last_name','email', 'subject_type_name']),
                    employee_uuid : item.subject_uuid
                    })
                )
            });
        });
    };
};

export const addAudit = (ast_id, slo_uuid, year, name, description, due_date, quality_managers, evidence_assignee) => {
    return dispatch => {
        return API.fetch({
            url: `/accreditation/add-audit`,
            method: 'POST',
            data: {
                ast_id,
                slo_uuid,
                year,
                name,
                description: description || undefined,
                due_date,
                quality_managers,
                evidence_assignee,
            }
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_CREATED');
        }, errorResponse => {
            if(errorResponse.data?.message_id === 21) {
                error(`Audit already exists for standard ${errorResponse.data.standard_name}`, 6);
            }
            else
                responseMessage(errorResponse);
        });
    };
};

export const addEvidenceProvider = (item) => {
    return dispatch => {
        return dispatch({
            type: TYPES.ADD_AUDIT_ASSIGNEE,
            id: IDS.accAudit,
            path: 'details.evidenceProviders',
            item
        });
    };
};

export const addReviewer = (item) => {
    return dispatch => {
        return dispatch({
            type: TYPES.ADD_AUDIT_ASSIGNEE,
            id: IDS.accAudit,
            path: 'details.reviewers',
            item
        });
    };
};
