import moment from 'moment';

/* @ngInject */
export const HelpersFactory = (DATE_FORMAT, DATE_TYPE) => {
    return {
        generateIdentifier: function() {
            return 'xxxx-xxxx-xxxx-xxxx-xxxx-xxxx-xxxx-xxxx'.replace(/x/g, function(c) {
                var r = Math.random() * 16 | 0;
                return r.toString(16);
            });
        },
        formatDate: function(date, outputFormat) {
            if (_.isString(date)) {
                if (_.isUndefined(outputFormat))
                    return moment(date, DATE_FORMAT.OUTPUT).toDate();
                else
                    return moment(date, DATE_FORMAT.OUTPUT).format(outputFormat);
            }

            if (_.isDate(date)) {
                if (_.isUndefined(outputFormat))
                    return moment(date).format(DATE_FORMAT.OUTPUT);
                else
                    return moment(date).format(outputFormat);
            }

            return null;
        },
        /**
         * Formats MySQL datetime string to one required to be shown
         * @param inputDbString - string in database format
         * @param outputFormat - output format of parsed date, by default string
         *
         */
        parseFromDbDate : function(inputDbString, outputFormat)
        {
            if (_.isEmpty(inputDbString)) {
                return null;
            }

            if (outputFormat === DATE_TYPE.DATE) {
                return moment(inputDbString, DATE_FORMAT.OUTPUT).toDate();
            } else { //by default returns DATE_TYPE.STRING
                return moment(inputDbString, DATE_FORMAT.OUTPUT).format(DATE_FORMAT.INPUT);
            }
        },
        formatDbDate: function(inputDate) {
            if (typeof inputDate == 'undefined' || inputDate == null || inputDate == '') return null;
            if (inputDate instanceof Date) return moment(inputDate).format(DATE_FORMAT.OUTPUT);
            else return moment(inputDate, DATE_FORMAT.INPUT).format(DATE_FORMAT.OUTPUT);
        }
    }
}
