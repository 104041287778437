import {TabsReducer} from '../../../common/tabs/tabs.reducer';
import {IDS} from './add-document-action-id.constants';

const tabs = [
    {
        state: IDS.app_accreditation_scheme_add_document_from_existing,
        title: 'ACCREDITATION.ADD_DOCUMENT.FROM_EXISTING.TAB',
        visible: true,
        disabled: false,
        loading: false
    },
    {
        state: IDS.app_accreditation_scheme_add_document_new_document,
        title: 'ACCREDITATION.ADD_DOCUMENT.NEW_DOCUMENT.TAB',
        visible: true,
        disabled: false,
        loading: false,
    },

];

export const addDocumentTabs = {
    [IDS.app_accreditation_scheme_add_document]: Object.assign(TabsReducer(), { tabs }),
}
