import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsSiteLocationRscAssistantDetailsController($ngRedux, $state, $uiRouterGlobals, NavigationService, AdministrationActions, Notifier) {
    var ctrl = this;
    var unsubscribe = angular.noop;
    var onAssistant = angular.noop;

    ctrl.$onInit = function() {
        ctrl.identifier = 'administrationAccountsSiteLocationRscAssistant';
        ctrl.checkboxFlex = 25;

        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);
        ctrl.assistantDetails = !_.isNull(ctrl.assistant.uuid);

        if (_.isUndefined(ctrl.stateParams.assistant))
            ctrl.getRscCandidates(ctrl.identifier, ctrl.stateParams.slo, 'aso');

        var watchAssistant = watch($ngRedux.getState, 'administration.administrationAccountsSiteLocationRscAssistant.data.uuid');
        onAssistant = $ngRedux.subscribe(watchAssistant(function(uuid) {
            ctrl.assistantDetails = !_.isNull(uuid);
        }));
    };

    ctrl.onAssistantDetails = function() {
        ctrl.siteLocationRscAssistantForm.$setPristine();
        ctrl.siteLocationRscAssistantForm.$setUntouched();
        ctrl.checkboxFlex = ctrl.assistantDetails ? 50 : 25;
    };

    ctrl.manageAssistant = function() {
        if (ctrl.siteLocationRscAssistantForm.$valid) {
            if (ctrl.assistant.uuid) {
                ctrl.updateRscDetails('administrationAccountsSiteLocationRscAssistant', ctrl.assistant, 'administrationAccountsSiteLocationRscAssistantsGrid');
            }
            else {
                if (ctrl.assistantDetails)
                    ctrl.createRscDetails(ctrl.assistant, $uiRouterGlobals.params.slo, true, 'administrationAccountsSiteLocationRscAssistant', 'administrationAccountsSiteLocationRscAssistantsGrid');
                else
                    ctrl.assignRsc(ctrl.assistantCandidate, $uiRouterGlobals.params.slo, 'administrationAccountsSiteLocationRscAssistant', 'administrationAccountsSiteLocationRscAssistantsGrid');
            }
        }
        else Notifier.error('Please fill in the form fields');
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onAssistant();
    };

    function mapStateToThis(state) {
        return {
            assistant: state.administration.administrationAccountsSiteLocationRscAssistant.data,
            assistantCandidate: state.administration.administrationAccountsSiteLocationRscAssistant.candidate,
            stateParams: state.administration['app.administration.accounts.siteLocations'].stateParams,
            loading: _.find(state.administration['app.administration.accounts.siteLocations'].tabs, ['state', 'app.administration.accounts.siteLocations.rscAssistantDetails']).loading,
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                state.administration.administrationAccountsSiteLocationRscAssistant.title
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
