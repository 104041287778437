/* @ngInject */
export const VendorHeadOfficeFactory = (VendorManagerUser) => {
    return function VendorHeadOffice() {
        this.id = null;
        this.uuid = null;
        this.name = null;
        this.subjectType = null;
        this.email = null;
        this.phone = null;
        this.manager = new VendorManagerUser();
        this.services = [];
        this.siteLocations = [];

        this.removeVendorService = function(vendorService) {
            var idx = _.findIndex(this.services, ['id', vendorService.id]);
            if (idx > -1) this.services.splice(idx, 1);
        };

        this.getSiteLocation = function(uuid) {
            var siteLocation = _.find(this.siteLocations, ['uuid', uuid]);
            return _.isUndefined(siteLocation) ? null : siteLocation;
        };

        this.addSiteLocation = function(siteLocation) {
            if (_.isNull(this.getSiteLocation(siteLocation.uuid)))
                this.siteLocations.push(siteLocation);
        };
    };
};
