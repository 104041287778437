import { GridReducer } from '../../common/grid/grid.reducer';
import { tabs } from './applications-permits.tabs';

export default {
    ...tabs,
    apInvite: {
        type: null,
        typeData: [
            { value: 'pio', label: 'Permit Holder' },
            { value: 'hod', label: 'Contact / Head of Dept' },
            { value: 'aus', label: 'Authorized User' }
        ],
        title: null,
        first_name: null,
        last_name: null,
        email: null,
        location: null,
        locations: [],
        position: null,
        permitHolders: [],
        permit_holder_uuid: null,
        equipment_training_required: 0
    },
    apRequest: {
        types: [],
        statuses: [],
        renewal30Days: 0,
        renewal60Days: 0,
        renewal90Days: 0,
        qualificationTypes: [],
        qualification: 'Qualification Name',
        qualifications: [],
        qualificationSubtypes1: [
            { value: 'fel', label: 'Fellow' },
            { value: 'reg', label: 'Registrar' },
        ],
        qualificationSubtypes2: [
            { value: 'nur', label: 'Nurse' },
            { value: 'hsc', label: 'Health Science' },
        ],
        training_completions: [
            { value: 1, label: 'YES' },
            { value: 0, label: 'NO' },
        ],
        modalityTrainingCategories: [],
        contactPersons: []
    },
    apLaserRequest: {
        token: null,
        invitation_id: null,
        id: null,
        title: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        phone_ext: null,
        position: null,
        qualification_type_code: null,
        qualification_code: null,
        qualification_code_subtype: null,
        qualification_title: null,
        qualification_doc_uri: null,
        training_completion: 1,
        training_title: null,
        training_doc_uri: null,
        applicant_signature: null,
        applicant_signature_date: null,
        equipment: [],
        modality_training_category: null,
        modality_training_date: null,
        modality_training_doc_uri: null,
        presentation_signature: null,
        presentation_end_date: null,
        approval_officer_signature: null,
        contact_person_uuid: null,
        invitation_type_code: null,
        approval_date: null
    },
    apLaserRequestGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0
    },
    apLaserRequestEquipmentGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0
    },
    apLaserRequestEquipmentAuthGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0
    },
    apLaserRequestTrainingsGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: undefined
    }
};
