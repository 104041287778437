import {TAB_SHOW} from '../../../../common/tabs/tabs.constants';
import {TYPES, TYPES as SETTINGS_TYPES} from '../settings-action-type.constants';
import {TYPES as ACC_TYPES} from "../../accreditation-action-type.constants";
import {IDS} from '../settings-action-id.constants';
import * as AssessmentActions from './slo-required-docs.actions';
import * as AccreditationActions from '../../accreditation.actions';
import * as SectionsActions from '../sections/sections.actions';
import watch from "redux-watch";
import get from "lodash/get";
import {GRID_FILTER, GRID_FILTER_TOGGLE} from '../../../../common/grid/grid.constants';
import {error} from '../../../../common/utils/util.notifier';

class SloRequiredDocsController {
    constructor($ngRedux, $uiRouterGlobals, $state, $stateParams, $scope, $rootScope, $filter, NavigationService, AuthService, EmployeeService, DocumentManager) {
        this.$ngRedux = $ngRedux;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.NavigationService = NavigationService;
        this.AuthService = AuthService;
        this.EmployeeService = EmployeeService;
        this.DocumentManager = DocumentManager;
    }

    $onInit() {
        this.identifier = IDS.accSettings;
        this.schemeCode = this.$stateParams.itemCode;
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.iconSvg.replace(/(\.\/)/g, '');

        this.unsubscribe = this.$ngRedux.connect(state => {
            const sectionDetails = state.accreditation.accSettings.sectionDetails;
            return {
                schemeId:           state.accreditation.accSettings.schemeDetails.id,
                headOffice:         state.accreditation.accSettings.sloRequiredDocsGrid.filter.hof_uuid,
                sectionsTree:       state.accreditation.simpleSectionsTree,

                standardSchemeCode: sectionDetails?.schemeCode,
                standardId:         sectionDetails?.id,
                standardParentId:   sectionDetails?.standardParentId,
                title:              sectionDetails?.nodeName || this.schemeName,
            };
        }, {...AccreditationActions, ...SectionsActions, ...AssessmentActions})(this);

        const ctrl = this;
        const watchSelectedState = watch(this.$ngRedux.getState, ['accreditation', IDS.app_accreditation_scheme_settings_slo_required_docs, 'selectedState']);
        this.unsubscribeWatch = this.$ngRedux.subscribe(watchSelectedState(selectedState => {
            if (selectedState === IDS.app_accreditation_scheme_settings_slo_required_docs_list) {
                this.$ngRedux.dispatch({
                    id: IDS.accSettings,
                    type: SETTINGS_TYPES.NEW_ASSESSMENT_ITEM_RESET,
                })
            }
        }));

        var user = this.AuthService.getUser();
        if(user.parentCount !== 1){
            ctrl.fetchStandardsTree(ctrl.schemeCode).then(()=>{
                ctrl.initStandardsInputSelects({});
            })
        }
        else{
            this.EmployeeService.getEmploymentLocations(user.uuid).then(siteLocations => {
                const siteLocation = get(siteLocations,'[0].uuid');
                this.$stateParams.slo = siteLocation;
                this.$ngRedux.dispatch({
                    type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                    accDashboardLocationYear: {
                        siteLocation,
                    }
                });
                ctrl.fetchStandardsTree(ctrl.schemeCode, siteLocation).then(()=>{
                    ctrl.initStandardsInputSelects({});
                })
            });
        }

        this.getSchemeDetails(this.schemeCode).then(scheme => {
            this.schemeName = scheme.name;
            this.fetchEnabledHeadOffices(scheme.id);
        })
    }

    initStandardsInputSelects({deselectAll}) {
        // insert standards to first standard select box
        this.$ngRedux.dispatch({
            type: SETTINGS_TYPES.SELECT_CASCADE_STANDARD,
            id: IDS.accSettings,
            level:0,
            standards: this.sectionsTree,
            standardId: null,
            standardParentId: null,
        });

        let selectedLevel1Id, selectedLevel2Id, selectedLevel3Id;
        if(this.standardId && this.standardParentId && this.standardSchemeCode === this.schemeCode){
            const standard2 = this.findChildStandardById(this.standardParentId, this.sectionsTree);
            selectedLevel1Id = standard2.standardParentId;
            selectedLevel2Id = this.standardParentId;
            selectedLevel3Id = this.standardId;
        }
        else{
            selectedLevel1Id = get(this.sectionsTree,'[0].id');
            selectedLevel2Id = get(this.sectionsTree,'[0].children[0].id');
            selectedLevel3Id = get(this.sectionsTree,'[0].children[0].children[0].id');
        }

        if(selectedLevel1Id) this.onSelectStandard({item:{value: selectedLevel1Id}, skipGridFetch: true, deselectAll});
        if(selectedLevel2Id) this.onSelectStandard({item:{value: selectedLevel2Id}, skipGridFetch: true, deselectAll});
        let level3Standard;
        if(selectedLevel3Id)
            level3Standard = this.onSelectStandard({item:{value: selectedLevel3Id}, skipGridFetch: !!deselectAll, deselectAll});

        if(level3Standard && deselectAll === false){
            this.getSectionDetails({
                ...level3Standard,
                schemeCode: this.schemeCode
            });
        }
        if(deselectAll)
            this.$ngRedux.dispatch({
                type:   SETTINGS_TYPES.SELECT_CASCADE_STANDARD_RESET,
                id:     IDS.accSettings,
            });
    }

    onSelectStandard({item, skipGridFetch = undefined, deselectAll = false}){
        const standard = this.findChildStandardById(item.value, this.sectionsTree);
        const level3Standards = standard.level === 1 && standard.children.length
            ? this.findChildStandardById(standard.children[0].id, this.sectionsTree).children
            : undefined;
        this.$ngRedux.dispatch({
            type:       SETTINGS_TYPES.SELECT_CASCADE_STANDARD,
            id:         IDS.accSettings,
            standardId: standard.id,
            level:      standard.level,
            standards:  standard.children,
            level3Standards,
            schemeCode: this.schemeCode
        });

        const level3Standard =
            level3Standards?.length && level3Standards[0]
            || standard.children?.length && standard.children[0]
            || standard;

        if(deselectAll === false) {
            this.$ngRedux.dispatch({
                type: TYPES.SECTION_DETAILS_REFRESH,
                id: IDS.accreditationSettingsSectionDetails,
                data: {
                    id: level3Standard.id,
                    standardParentId: level3Standard.standardParentId,
                    level: level3Standard.level,
                    nodeName: level3Standard.name,
                    prefix: level3Standard.number,
                    siblings: level3Standard.children.map(item => parseInt(item.number.split('.').pop())),
                    schemeCode: level3Standard.schemeCode,
                }
            });
        }

        this.$ngRedux.dispatch({
            type: skipGridFetch ? GRID_FILTER_TOGGLE : GRID_FILTER,
            id: IDS.accSettings,
            path: IDS.sloRequiredDocsGrid,
            filter: {
                standard_id: level3Standard.id,
                scheme_code: this.schemeCode
            }
        });
        return level3Standard
    }

    findChildStandardById(id, standards) {
        return standards.reduce((result, item) => {
            if(result)
                return result;
            if(item.id === id)
                return item
            else if (item.children.length)
                return this.findChildStandardById(id, item.children)
        }, null);
    }

    onSelectHeadOffice({item}){
        this.fetchEnabledSiteLocations(this.schemeId, item.value);
    }

    onResetSloFilterButton(){
        this.initStandardsInputSelects({deselectAll: true});
        this.$ngRedux.dispatch({
            type: TYPES.SLO_DOCS_LOCATION_SELECTS_RESET,
            id: IDS.sloRequiredDocs,
        });
        this.$ngRedux.dispatch({
            type:   GRID_FILTER,
            id:     IDS.accSettings,
            path:   IDS.sloRequiredDocsGrid,
            filter: {
                loc_uuid: null,
                standard_id: null
            }
        });
    }

    onSelect({item}) {
        this.$ngRedux.dispatch({
            type:   SETTINGS_TYPES.SLO_DOC_DETAILS_REFRESH,
            id:     IDS.accSettings,
            data:  {
                ...item,
                schemeId: this.schemeId,
            }
        });

        // this.$ngRedux.dispatch({
        //     type: GRID_FILTER,
        //     id: IDS.accSettings,
        //     path: IDS.sloRequiredDocActiveSitesGrid,
        //     filter: {
        //         ard_id: item.ard_id
        //     }
        // });
        this.$ngRedux.dispatch({
            type: TAB_SHOW,
            id: IDS.app_accreditation_scheme_settings_slo_required_docs,
            state: IDS.app_accreditation_scheme_settings_slo_required_docs_details,
        });
    };

    onRemove({item}) {
        this.invalidateRequiredDocument(item.ard_id, IDS.sloRequiredDocsGrid);
    };

    onDownloadButton({item}){
        this.DocumentManager.downloadFile(item.sample_doc_uri);
    }

    onButtonAdd(event) {
        // if no standard selected throw notification
        if(!this.standardId){
            error('Please select a standard first');
            return;
        }
        this.$ngRedux.dispatch({
            type: TYPES.SLO_DOC_DETAILS_RESET,
            id: IDS.accSettings,
        });
        this.$ngRedux.dispatch({
            type: TAB_SHOW,
            id:     IDS.app_accreditation_scheme_settings_slo_required_docs,
            state:  IDS.app_accreditation_scheme_settings_slo_required_docs_add,
        });
    };

    $onDestroy() {
        this.unsubscribe();
        if(this.unsubscribeWatch)
            this.unsubscribeWatch();
    }
}

SloRequiredDocsController.$inject = ['$ngRedux', '$uiRouterGlobals', '$state', '$stateParams', '$scope', '$rootScope', '$filter', 'NavigationService', 'AuthService','EmployeeService','DocumentManager']

export const sloRequiredDocs = {
    controller: SloRequiredDocsController,
    template: `
        <tabs-header title="{{$ctrl.title}}" icon="{{$ctrl.icon}}" color-name="{{$ctrl.colorName}}"></tabs-header>
        <form name="selectCascadeStandardForm" novalidate layout="row" style="margin-top: 20px">
            <div layout="column" flex>
                <div layout-gt-xs="row" flex>
                    <input-select flex label="Standard"
                                  identifier="{{$ctrl.identifier}}"
                                  state-path="selectCascadeStandard.level1StandardId"
                                  state-data="selectCascadeStandard.level1Standards"
                                  on-change="$ctrl.onSelectStandard($event)"
                                  select-first-item="true">
                    </input-select>
                    <input-select flex label="Standard"
                                  identifier="{{$ctrl.identifier}}"
                                  state-path="selectCascadeStandard.level2StandardId"
                                  state-data="selectCascadeStandard.level2Standards"
                                  on-change="$ctrl.onSelectStandard($event)"
                                  select-first-item="true">
                    </input-select>
                    <input-select flex label="Standard"
                                  identifier="{{$ctrl.identifier}}"
                                  state-path="selectCascadeStandard.level3StandardId"
                                  state-data="selectCascadeStandard.level3Standards"
                                  on-change="$ctrl.onSelectStandard($event)"
                                  select-first-item="true">
                    </input-select>
                </div>
                <div layout-gt-xs="row" layout-align="center center" flex>
                    <input-select flex
                                  label="{{ 'COMMON.FIELD.HEAD_OFFICE' | translate}}"
                                  identifier="{{$ctrl.identifier}}"
                                  on-change="$ctrl.onSelectHeadOffice($event)"
                                  state-data="sloRequiredDocs.enabledHeadOffices"
                                  state-path="sloRequiredDocs.hof_uuid">
                    </input-select>
                    <input-select flex
                                  label="{{ 'COMMON.FIELD.SITE_LOCATION' | translate}}"
                                  identifier="{{$ctrl.identifier}}"
                                  state-data="sloRequiredDocs.enabledSiteLocations"
                                  state-path="sloRequiredDocs.slo_uuid">
                    </input-select>
                    <button class="btnDefault" ng-click="$ctrl.onResetSloFilterButton()">{{'COMMON.BUTTON.RESET_FILTER' | translate}}</button>
                </div>
            </div>
        </form>

        <slo-required-docs-grid
                on-select="$ctrl.onSelect($event)"
                on-remove="$ctrl.onRemove($event)"
                on-download="$ctrl.onDownloadButton($event)">
        </slo-required-docs-grid>

        <div layout-xs="column" layout-gt-xs="row" layout-align="center center" class="tabs">
            <div flex></div>
            <button type="submit" class="md-button btnColor{{$ctrl.colorName}}" ng-click="$ctrl.onButtonAdd()" aria-label="Add Requirement">{{'ACCREDITATION.SETTINGS.SLO_REQUIRED_DOCUMENTS.LIST.ADD_BUTTON' | translate}}</button>
        </div>
`}
