/* @ngInject */
export const EmployeeModalityTrainingFactory = (Helpers, DATE_FORMAT) => {
    return function EmployeeModalityTraining() {

        this.id = null;
        this.categories = [];
        this.issueDate = null;
        this.uri = null;

        this.getIssueDate = function() {
            return Helpers.formatDate(this.issueDate, DATE_FORMAT.INPUT);
        };

        this.getCategories = function() {
            return this.categories.map(function(item) {
                return item.name;
            }).join(' / ');
        };
    };
};
