import { generateQRCode } from '../../../../../common/utils/util.qrcode';
import moment from 'moment/moment';
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';

/* @ngInject */
export function EquipmentMammoManageController($controller, $ngRedux, $state, $uiRouterGlobals, $scope, $rootScope, $q, $timeout, $mdDialog, API_BASE, NavigationService, Notifier, EquipmentServiceMammo, EquipmentManager, ManufacturerService, DocumentService, DocumentManager, EQUIPMENT_TYPE, EQUIPMENT_TYPE_CODE, VendorService, VendorHeadOffice, VendorGridFilter, AuthService, EquipmentService, $filter) {

    this.unsubscribe = $ngRedux.connect(({ equipment }) => ({
        equAssetCost: equipment.equAssetCost,
    }), {})(this);

    angular.extend(this, $controller('ApplicationModuleController', {
        $scope: $scope,
        $timeout: $timeout,
        Notifier: Notifier
    }));

    $scope.MODULE_STATE     = $scope.MODULE_STATE_UPDATE;
    $scope.EQUIPMENT_STATE  = $scope.MODULE_STATE_UPDATE;

    $scope.gridIdentifier = 'equipmentMammoGrid';

    $scope.templateFilter           = './equipment-mammo-filter.html';
    $scope.templateDetails          = './equipment-mammo-details.html';
    $scope.templateModify           = './equipment-mammo-add.html';
    $scope.templateCertification    = './equipment-certification.html';
    $scope.templateRegistration     = './equipment-registration.html';

    var navConfig = NavigationService.getNavConfig();
    $scope.moduleColor = navConfig.colorName;
    $scope.moduleIcon = navConfig.icon;

    $scope.userData = AuthService.getUser();
    $scope.headOfficeName = $scope.userData.hofName;
    $scope.isAdmin = $scope.userData.type === 'Administrator';

    if ($scope.userData.parentCount === 1) {
        if ($scope.userData.parents[0].basicType === 'slo' || $scope.userData.parents[0].basicType === 'dep') {
            $ngRedux.dispatch({
                type: 'CHECK_LOCATION',
                id: $scope.gridIdentifier,
                checkLocation: false
            });
        }
    }

    if ($scope.userData.parentCount > 1) {
        if ($scope.userData.parents[0].basicType === 'dep') {
            $ngRedux.dispatch({
                type: 'CHECK_LOCATION',
                id: $scope.gridIdentifier,
                checkLocation: false
            });
        }
    }

    $scope.locations = [];
    $scope.enableEquipmentRegistration = true;

    $scope.equipmentModels = [];
    $scope.equipmentTubeHousingModels = [];
    $scope.equipmentTubeInsertModels = [];
    $scope.minDocumentExpiryDate = new Date();
    $scope.maxServiceDocumentDate = new Date();
    $scope.maxEquipmentInstalationDate = new Date();
    $scope.maxRegistrationDate = new Date();
    $scope.minNextDueDate = new Date();

    // TODO create factory with reset method
    $scope.doc = {
        type: null,
        inspectionDate: null,
        inspector: null
    };

    $scope.onEquipmentGridReset = function() {
        $rootScope.$broadcast('SELECT_CASCADE_RESET', 'equipmentFilterForm');
    };

    $scope.onEquipmentSell = function(event) {
        EquipmentManager.onSell($scope.gridIdentifier, event.item);
    };

    $scope.onEquipmentDispose = function(event) {
        EquipmentManager.onDispose($scope.gridIdentifier, event.item);
    };

    $scope.onEquipmentReplace = function(event) {
        $state.go('app.mammo.add', {
            replace: event.item.equipment_id,
            transaction: event.item.transaction_id
        });
    };

    $scope.tabs = _.cloneDeep(EquipmentManager.tabs);
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]).visible = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]).content = $scope.templateDetails;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_MODIFY]).content = $scope.templateModify;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DOCUMENTS]).visible = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DOCUMENTS]).disabled = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_VENDORS]).visible = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_VENDORS]).disabled = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_TECHNIQUE_CHARTS]).visible = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_TECHNIQUE_CHARTS]).disabled = true;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_ASSET_COST]).visible = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_ASSET_COST]).disabled = false;

    $scope.$watch('selectedTabIndex', function(newTab) {
        if (newTab === EquipmentManager.TAB_EQUIPMENT_LISTING) {
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]).visible = false;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_MODIFY]).visible = false;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DOCUMENTS]).visible = false;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_VENDORS]).visible = false;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_ASSET_COST]).visible = false;
            $rootScope.$broadcast('SELECT_CASCADE_RESET', 'equipmentForm');
            $scope.locations = [];
        }
    });

    $scope.$on('LOCATION_SUBJECT_SELECTED', function(event, locationItem, formName) {
        if (locationItem.in_subject_basic_type_code === 'hof') {
            $scope.headOfficeName = locationItem.in_username_par;
        }

        if (formName == 'equipmentFilterForm') {
            $scope.location = locationItem.uuid;
        }
        else {
            $scope.equipment.location.uuid = locationItem.uuid;
            $scope.equipment.account_id = locationItem.in_account_id;
        }

        if ($scope.equipment) {
            EquipmentService.fetchEquipmentFlags(locationItem.uuid, EQUIPMENT_TYPE_CODE.XRay).then(function (flags) {
                if (flags.has_csd)
                    $scope.equipment.capitalSensitivityExpiryDateShow = true;
                else
                    $scope.equipment.capitalSensitivityExpiryDateShow = false;
            });

            if (locationItem.in_subject_basic_type_code === 'hof' && locationItem.in_username_par !== 'OTTAWA HOSPITAL') {
                $scope.equipment.testingFrequencyRequired = false;
            }
        }
    });

    $scope.selectItem = function(event) {
        var item = event.item;
        EquipmentServiceMammo.getEquipmentDetails(item.equipment_id).then(function(result) {
            $scope.equipment = result.equipment;
            $scope.locations = result.locations;
            $scope.manufacturerSelected(false);
            $scope.doc.expires = null;
            $scope.doc.type = null;
            $scope.doc.inspectionDate = null;

            $ngRedux.dispatch({
                type: 'EQUIPMENT_SELECTED',
                id: 'equipmentDocumentsGrid',
                equipmentId: item.equipment_id
            });

            DocumentService.fetchEquipmentDocumentTypes(item.equipment_id).then(function(items) {
                $scope.documentTypes = items;
            });

            if (!$scope.equipment.testingFrequency)
                $scope.equipment.testingFrequency = 6;

            if ($scope.equipment.tubeHousing.manufacturer.id)
                $scope.manufacturerHousingSelected(false);

            if ($scope.equipment.tubeInsert.manufacturer.id)
                $scope.manufacturerInsertSelected(false);

            if(!$scope.equipment.tubeInsert.type.id)
                $scope.equipment.tubeInsert.type.id = EQUIPMENT_TYPE.tubeInsert;

            if(!$scope.equipment.tubeHousing.type.id)
                $scope.equipment.tubeHousing.type.id = EQUIPMENT_TYPE.tubeHousing;

            $q.all([
                EquipmentManager.getEquipmentServices($scope.equipment.equipment_id),
                VendorService.getEquipmentVendors($scope.equipment.equipment_id)
            ]).then(function(result) {
                $scope.equipment.services = _.first(result);
                _.each(result[1], function(item) {
                    var serviceType = _.find($scope.equipment.services, ['id', item.service_type_id]);
                    if (_.isUndefined(serviceType))
                        throw new Error('Undefined ServiceType ' + item.service_type_name);
                    else {
                        var vendor = new VendorHeadOffice();
                        vendor.uuid = item.vendor_uuid;
                        vendor.name = item.vendor_name;
                        serviceType.selectedVendor = vendor;
                        serviceType.selected = 1;
                        serviceType.disable = item.is_mutable;
                        var vendorFilter = new VendorGridFilter();
                        vendorFilter.offset = null;
                        vendorFilter.limit = null;
                        vendorFilter.serviceType = serviceType.id;
                        VendorService.getVendorHeadOfficeList(vendorFilter).then(function(data) {
                            serviceType.vendors = data.items;
                        });
                    }
                });
            });

            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]).title = $scope.equipment.user_friendly_id;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]).visible = true;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_MODIFY]).visible = true;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DOCUMENTS]).visible = true;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_VENDORS]).visible = true;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_ASSET_COST]).visible = true;

            $timeout(() => {
                generateQRCode('info', {
                    id: $scope.equipment.user_friendly_id,
                    asset_id: $scope.equipment.assetID ? $scope.equipment.assetID : '',
                    action: 'equipment',
                });

                generateQRCode('qrcode', {
                    id: $scope.equipment.user_friendly_id,
                    asset_id: $scope.equipment.assetID ? $scope.equipment.assetID : '',
                    action: 'equipment',
                });

                let params;
                if ($uiRouterGlobals.params.id) {
                    params = $uiRouterGlobals.params.id.split(',');
                }
                $uiRouterGlobals.params.id = null;
                window.history.replaceState(null, null, window.location.pathname);
                if (params && params[1]) {
                    $scope.selectedTabIndex = parseInt(params[1]);
                } else {
                    $scope.selectedTabIndex = EquipmentManager.TAB_EQUIPMENT_DETAILS;
                }
            }, 200);
        });
    };

    $scope.showDocuments = function() {
        DocumentManager.showEquipmentDocuments($scope.equipment);
    };

    $scope.initUploadForm = function(form) {
        $scope.uploadForm = form;
    };

    $scope.selectDocumentTypeChange = function() {
        $scope.doc.inspector = null;
        $scope.notApplicable = false;
        $scope.showExpiryDate = true;
        EquipmentServiceMammo.getInspectors($scope.equipment.equipment_id, $scope.doc.type.id).then(function (result) {
            $scope.inspectors = result;
        });
    };

    $scope.setExpiryDate = function() {
        if ($scope.doc.inspectionDate && !$scope.doc.expires) {
            var startDate = new Date($scope.doc.inspectionDate);
            if ($scope.headOfficeName === 'OTTAWA HOSPITAL' && $scope.doc.type.code === 'ctp') {
                if ($scope.equipment.testingFrequencyRequired) {
                    $scope.doc.expires = new Date(startDate.setMonth(startDate.getMonth() + parseInt($scope.equipment.testingFrequency)));
                } else {
                    $scope.doc.expires = new Date(startDate.setMonth(startDate.getMonth() + 6));
                }
            } else {
                EquipmentService.getLicenceFrequency($scope.equipment.equipment_id, $scope.doc.inspectionDate, $scope.doc.type.id).then(function (result) {
                    if (result.expiration_date) {
                        $scope.doc.expires = new Date(result.expiration_date);
                    } else {
                        $scope.doc.expires = new Date(startDate.setFullYear(startDate.getFullYear() + 1));
                    }
                });
            }
        }
    };

    $scope.$on('FILE_UPLOAD_SUCCESS', function(event, filePath, uploader) {
        var doc = {
            id: $scope.equipment.equipment_id,
            type: $scope.doc.type.id,
            issued: "",
            expires: $scope.doc.expires,
            uri: filePath
        };

        if ($scope.doc.type.code == 'ctp') {
            doc.inspected = $scope.doc.inspectionDate;
            doc.inspector = $scope.doc.inspector;
            doc.result = $scope.doc.result;
            doc.comment = $scope.doc.comment;
            doc.number = $scope.doc.number;
        }

        if ($scope.doc.type.code == 'ser') {
            doc.inspected = $scope.doc.inspectionDate;
            doc.inspector = $scope.doc.inspector;
        }

        if ($scope.doc.type.code == 'cal' || $scope.doc.type.code == 'rsr') {
            doc.serviceDate = $scope.doc.inspectionDate;
        }

        DocumentManager.saveDocument(doc).then(function() {
            $scope.uploadForm.$setPristine();
            $scope.uploadForm.$setUntouched();
            DocumentManager.clearUploader(uploader, $scope.doc);
            $ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: 'equipmentDocumentsGrid',
                equipmentId: $scope.equipment.equipment_id
            });
            $ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: $scope.gridIdentifier
            });
        }, function(error) {
            DocumentManager.clearUploader(uploader, $scope.doc);
            Notifier.responseMessage(error);
        });
    });

    $scope.generateBarcode = function() {
        EquipmentManager.generateBarcode($scope.equipment.barcode);
    };

    $scope.manageEquipmentService = function(serviceType) {
        EquipmentManager.manageEquipmentService($scope.equipment.equipment_id, serviceType);
    };

    $scope.onEquipmentServiceVendor = function(serviceType) {
        EquipmentManager.equipmentServiceVendorSelected($scope.equipment.equipment_id, serviceType);
    };

    if ($uiRouterGlobals.params.id) {
        const params = $uiRouterGlobals.params.id.split(',');
        $scope.selectItem({
            item: {equipment_id: params[0]}
        });
    }

    EquipmentServiceMammo.fetchCategories().then(function(items) {
        $scope.categories = items;
    });

    ManufacturerService.fetchManufacturersByEquipmentType(EQUIPMENT_TYPE_CODE.mammo).then(function(items) {
        $scope.manufacturers = items;
    });

    $scope.manufacturerSelected = function(resetSelectedModel) {
        if (_.isUndefined(resetSelectedModel)){
            $scope.equipment.model = null;
        }
        ManufacturerService.fetchEquipmentModels($scope.equipment.manufacturer.id, $scope.equipment.category.id).then(function(items) {
            $scope.equipmentModels = items;
        });
    };

    $scope.manufacturerHousingSelected = function(resetSelectedModel) {
        if (_.isUndefined(resetSelectedModel)){
            $scope.equipment.tubeHousing.model = null;
        }
        ManufacturerService.fetchEquipmentModels($scope.equipment.tubeHousing.manufacturer.id, $scope.equipment.tubeHousing.type.id).then(function(items) {
            $scope.equipmentTubeHousingModels = items;
        });
    };

    $scope.manufacturerInsertSelected = function(resetSelectedModel) {
        if (_.isUndefined(resetSelectedModel)){
            $scope.equipment.tubeInsert.model = null;
        }
        ManufacturerService.fetchEquipmentModels($scope.equipment.tubeInsert.manufacturer.id, $scope.equipment.tubeInsert.type.id).then(function(items) {
            $scope.equipmentTubeInsertModels = items;
        });
    };

    $scope.createEquipmentModel = function(equipment) {
        EquipmentManager.createNewModel(equipment);
    };

    $scope.$on(EquipmentManager.CREATE_NEW_MODEL_COMPLETE, function(event, model) {
        switch (model.type) {
            case EQUIPMENT_TYPE.mammo:
                $scope.equipmentModels.push(model);
                $scope.equipment.model = model;
                break;
            case EQUIPMENT_TYPE.tubeHousing:
                $scope.equipmentTubeHousingModels.push(model);
                $scope.equipment.tubeHousing.model = model;
                break;
            case EQUIPMENT_TYPE.tubeInsert:
                $scope.equipmentTubeInsertModels.push(model);
                $scope.equipment.tubeInsert.model = model;
                break;
        }
    });

    $scope.$on(EquipmentManager.CREATE_NEW_MODEL_COMPLETE, function(event, data) {
        //$scope.models.push(data);
        $scope.equipment.model = data;
    });

    _.extend(_.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_MODIFY]), {
        exec: function() {
            var deferred = $q.defer();
            EquipmentServiceMammo.updateEquipment($scope.equipment).then(function() {
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: $scope.gridIdentifier
                });
                generateQRCode('qrcode', {
                    id: $scope.equipment.user_friendly_id,
                    asset_id: $scope.equipment.assetID ? $scope.equipment.assetID : '',
                    action: 'equipment',
                });
                Notifier.success('Equipment details successfully updated');
                deferred.resolve();
            }, function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }
    });

    $scope.manageEquipmentRegistration = function() {
        $scope.enableEquipmentRegistration = !$scope.enableEquipmentRegistration;
        if (!$scope.enableEquipmentRegistration) {
            $scope.equipment.registrationData.number = null;
            $scope.equipment.registrationData.date = null;
            $scope.equipment.registrationData.dueDate = null;
            $scope.equipment.registrationData.details = null;
        }
    };

    $scope.onEquipmentDocumentInvalidate = function(event) {
        var confirm = $mdDialog.confirm()
            .title($filter('translate')('EQUIPMENT.TABS.DOCUMENTS.REMOVE_MESSAGE'))
            .textContent($filter('translate')('EQUIPMENT.TABS.DOCUMENTS.REMOVE_DIALOG_CONTENT'))
            .ariaLabel($filter('translate')('EQUIPMENT.TABS.DOCUMENTS.REMOVE_LABEL'))
            .ok($filter('translate')('EQUIPMENT.TABS.DOCUMENTS.REMOVE_OK'))
            .cancel($filter('translate')('EQUIPMENT.TABS.DOCUMENTS.REMOVE_CANCEL'));

        $mdDialog.show(confirm).then(function() {
            EquipmentService.invalidateDocument(event.item.id).then(function() {
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'equipmentDocumentsGrid'
                });
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: $scope.gridIdentifier
                });
                Notifier.success($filter('translate')('EQUIPMENT.TABS.DOCUMENTS.NOTIFIER_REMOVE_SUCCESS'));
            }, function(error) {
                Notifier.responseMessage(error);
            });
        }, angular.noop);
    };

    $scope.onPrintQRcode = () => {
        EquipmentService.printQRcode(
            $scope.equipment.equipment_id,
            $scope.equipment.user_friendly_id,
            'equipment',
        );
    };

    $scope.onChangeDocumentApplicable = function (notApplicable) {
        $scope.showExpiryDate = !notApplicable;
    }

    /**
     * ASSET COST
     */
    $scope.icon = 'mammo.svg';
    $scope.color = 'colorMammo';
    $scope.bgColor = 'mammo';
    $scope.btnColor = 'btnColorMammo';
    $scope.depreciation_type = this.equAssetCost.depreciation_type;

    $scope.initAssetCostForm = (form) => {
        $scope.equAssetCostForm = form;
    };

    $scope.setWarrantyExpiryDate = () => {
        const { purchaseDate, warranty_period } = this.equAssetCost;
        if (
            moment.isDate(purchaseDate) &&
            isNumber(warranty_period) &&
            !isNaN(warranty_period)
        ) {
            $ngRedux.dispatch({
                type: 'EQUIPMENT_WARRANTY_EXPIRY_DATE',
                id: 'equAssetCost',
                date: moment(purchaseDate).add(warranty_period, 'months').toDate(),
            });
        }
    };

    $scope.onDepreciationType = () => {
        $scope.depreciation_type = this.equAssetCost.depreciation_type;
    };

    $scope.genDepreciationReport = () => {
        if ($scope.equAssetCostForm.$valid) {
            if (this.equAssetCost.depreciation_type) {
                Notifier.info('Please wait...', false);
                EquipmentService.generateDepreciationReport(
                    'mam',
                    this.equAssetCost.equ_id,
                    this.equAssetCost.visualId,
                )
                .then(() => Notifier.success('Success'))
                .catch(() => Notifier.error('System Error!'));
            } else {
                Notifier.error('Please save asset cost');
            }
        } else {
            Notifier.error('Please fill in the form fields');
        }
    };

    $scope.saveAssetCost = () => {
        if ($scope.equAssetCostForm.$valid) {
            if (this.equAssetCost.equ_id) {
                if (this.equAssetCost.estimated_residual_value < this.equAssetCost.assetCost) {
                    EquipmentServiceMammo.updateEquipment($scope.equipment).then(() => {
                        Notifier.success('Asset cost has been successfully saved');
                    });
                } else {
                    Notifier.error('The estimated residual value cannot be higher than the cost of the asset');
                }
            }
        } else {
            Notifier.error('Please fill in the form fields');
        }
    };

    $scope.$on('$destroy', () => {
        $ngRedux.dispatch({
            type: 'EQUIPMENT_RESET',
            id: 'equAssetCost'
        });
        this.unsubscribe();
    });
}
