/*
*   List of Actions with TYPE that should be passed from reporting.reducer.js to rep-monthly.reducer.js
 */
export const PREFIX = 'REP_MONTHLY';

export const TYPES = {

    FETCH_RUNS:             `${PREFIX}_FETCH_RUNS`,
    FETCH_RUN_HOFS:         `${PREFIX}_FETCH_RUN_HOFS`,
    SHOW_DETAILS:           `${PREFIX}_SHOW_DETAILS`,
};
