/* @ngInject */
export function SelectServiceContractTypeController($ngRedux, ServiceContractsActions) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, ServiceContractsActions)(ctrl);
        ctrl.getServiceContractTypes();
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            $ngRedux.dispatch({
                type: 'CONTRACT_TYPE_SELECTED',
                id: ctrl.identifier,
                contractType: ctrl.selectedOption
            });
        }
    };

    function mapStateToThis(state) {
        return {
            options: _.get(state, getStateContainer('types'), []),
            selectedOption: _.get(state, getStateContainer('filter.type'), null)
        };
    }

    function getStateContainer(statePath) {
        return [ctrl.module, ctrl.identifier].join('.') + '.' + statePath;
    }
}
