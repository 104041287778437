export const reportingEquipmentInfoState = {
    name: 'app.reporting.equipmentInfo',
    redirectTo: 'app.reporting.equipmentInfo.listing',
    url: '/equipment-info',
    component: 'tabs'
};

export const reportingEquipmentInfoListingState = {
    name: 'app.reporting.equipmentInfo.listing',
    url: '',
    component: 'reportingEquipmentInfo',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: 'TAB_DISABLE',
            id: 'app.reporting.equipmentInfo',
            state: [
                'app.reporting.equipmentInfo.details',
                'app.reporting.equipmentInfo.serviceDetails',
                'app.reporting.equipmentInfo.serviceHistory',
                'app.reporting.equipmentInfo.inspections',
                'app.reporting.equipmentInfo.history'
            ]
        });
    }
};
reportingEquipmentInfoListingState.onEnter.$inject = ['$ngRedux'];

export const reportingEquipmentInfoDetailsState = {
    name: 'app.reporting.equipmentInfo.details',
    url: '/details?id',
    component: 'reportingEquipmentInfoDetails'
};

export const reportingEquipmentInfoServiceDetailsState = {
    name: 'app.reporting.equipmentInfo.serviceDetails',
    url: '/service-details?id',
    component: 'reportingEquipmentInfoServiceDetails'
};

export const reportingEquipmentInfoServiceHistoryState = {
    name: 'app.reporting.equipmentInfo.serviceHistory',
    url: '/service-history?id',
    component: 'reportingEquipmentInfoServiceHistory'
};

export const reportingEquipmentInfoInspectionsState = {
    name: 'app.reporting.equipmentInfo.inspections',
    url: '/inspections?id',
    component: 'reportingEquipmentInfoInspections'
};

export const reportingEquipmentInfoHistoryState = {
    name: 'app.reporting.equipmentInfo.history',
    url: '/history?id',
    component: 'reportingEquipmentInfoHistory'
};
