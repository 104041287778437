import { TAB_SHOW } from '../../../common/tabs/tabs.constants';
import { getWasteDetails, deleteWaste } from './rm-waste.actions';

/**
 * @const {object}
 * @memberOf module:RadioactiveMaterials
 */
export const rmWaste = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $mdDialog, $rootScope) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(() => ({}), {
                getWasteDetails,
                deleteWaste
            })(this);
        }

        this.onSelect = ({ item }) => {
            this.getWasteDetails(item.rmw_id).then(() => {
                $ngRedux.dispatch({
                    type: TAB_SHOW,
                    id: 'app.radioactiveMaterials.waste.manage',
                    state: ['app.radioactiveMaterials.waste.manage.details'],
                    stateParams: { id: item.rmw_id }
                });
            });
        };

        this.onRemove = ({ item }) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen waste will be permanently deleted.')
                .ariaLabel('Delete waste')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.deleteWaste(item.rmw_id);
            }, angular.noop);
        };

        this.onReset = (event) => {
            $rootScope.$broadcast('SELECT_CASCADE_RESET', 'rmWasteFilterForm');
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: `
        <rm-waste-grid
            on-select="$ctrl.onSelect($event)" 
            on-remove="$ctrl.onRemove($event)"
            on-reset="$ctrl.onReset($event)">
        </rm-waste-grid>
    `
};
