import { INIT_APP, INIT_MODULE } from './app.constants';
import { NAVIGATION_STATE } from './navigation/navigation.actions.types';
import get from 'lodash/get';

/**
 * Creates a new AppController.
 * @class
 */
class AppController {
    /**
     * @constructor
     * @param $ngRedux
     * @param $state
     * @param NavigationService {service}
     * @param AuthService {service}
     */
    constructor($ngRedux, $state, Session, NavigationService, AuthService) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.Session = Session;
        this.NavigationService = NavigationService;
        this.AuthService = AuthService;
    }

    /**
     * @method $onInit
     * @memberOf AppController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this.mapState, {})(this);
        window.addEventListener('storage', this.onStorageChange.bind(this));

        this.$ngRedux.dispatch({
            type: INIT_APP,
            user: this.Session.getUser()
        });

        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.$ngRedux.dispatch({
            type: INIT_MODULE,
            module: navConfig ? navConfig.module : 'dashboard',
            color: get(navConfig, 'color', null),
        });
        this.$ngRedux.dispatch({
            type: NAVIGATION_STATE,
            state: this.$state.$current.name,
        });
    }

    /**
     * @method onStorageChange
     * @memberOf AppController
     */
    onStorageChange() {
        if (this.Session.getRecovery() == null) {
            if (this.Session.getUser() == null) {
                this.Session.setRecovery();
                location.replace(location.origin);
                return;
            }
            if (this.Session.getUser().uuid !== this.user.uuid) {
                this.AuthService.logout(true).then(() => {
                    location.replace(location.origin);
                });
            }
        }
    }

    /**
     * @method $onDestroy
     * @memberOf AppController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.unsubscribe();
        window.removeEventListener('storage', this.onStorageChange);
    }

    /**
     * @method mapState
     * @param state {object} Redux Store
     * @memberOf AppController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapState(state) {
        return {
            /** @member {object} module:root/common.AppController#user */
            user: state.app.user,
            /** @member {object[]} module:root/common.AppController#configs */
            configs: state.navigation.configs
        }
    }
}

AppController.$inject = ['$ngRedux', '$state', 'Session', 'NavigationService', 'AuthService'];

export const app = {
    bindings: {
        navConfigs: '<'
    },
    controller: AppController,
    template: require('./app.html')
};

export const appState = {
    name: 'app',
    redirectTo: 'app.dashboard',
    template: '<app flex layout="column" nav-configs="$resolve.navConfigs"></app>',
    resolve: {
        navConfigs: ['$ngRedux', 'NavigationActions', ($ngRedux, NavigationActions) => $ngRedux.dispatch(NavigationActions.getConfigs())]
    }
};
