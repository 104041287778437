/* @ngInject */
export const LicenceFactory = (Helpers, Country, DATE_FORMAT) => {
    return function Licence() {

        this.id = null;
        this.regIdNo = null;
        this.type = null;
        this.typeId = null;
        this.equipmentType = null;
        this.issued = null;
        this.expires = null;
        this.uri = null;
        this.subject = null;
        this.number = null;
        this.country = new Country();

        this.getIssuedDate = function() {
            return Helpers.formatDate(this.issued, DATE_FORMAT.INPUT);
        };

        this.getExpiryDate = function() {
            return Helpers.formatDate(this.expires, DATE_FORMAT.INPUT);
        };

        this.isValid = function() {
            var required = [this.regIdNo, this.type, this.expires, this.uri];
            for (var index in required) {
                if (_.isNull(required[index])) return false;
            }
            return true;
        };
    };
};
