import watch from 'redux-watch';

/* @ngInject */
export function ApplicationModuleController($ngRedux, $scope, $timeout, Notifier) {

    $scope.MODULE_STATE_CREATE = 'create';
    $scope.MODULE_STATE_UPDATE = 'update';

    $scope.selectedTabIndex = null;
    $scope.selectedTab = null;

    this.$onInit = () => {
        $scope.loading = false;
        const watchLoading = watch($ngRedux.getState, 'app.loading');
        $ngRedux.subscribe(watchLoading((loading) => {
            $scope.loading = loading;
        }));
    };

    $scope.initForm = function(tab, form) {
        var tabIndex = $scope.tabs.indexOf(tab);
        if (_.isNull($scope.tabs[tabIndex].form))
            $scope.tabs[tabIndex].form = form;
    };

    $scope.onTabSelected = function(tab) {
        $scope.selectedTab = tab;
    };

    $scope.executeTab = function() {
        if ($scope.selectedTab.hasOwnProperty('init'))
            $scope.selectedTab.init();

        if ($scope.selectedTab.form && $scope.selectedTab.formValidation) {
            $scope.$broadcast('show-errors-check-validity', $scope.selectedTab.form.$name);
            if ($scope.selectedTab.form.$invalid) {
                if ($scope.selectedTab.hasOwnProperty('formValidationMsg'))
                    Notifier.error($scope.selectedTab.formValidationMsg);
                return;
            }
        }

        if ($scope.selectedTab.hasOwnProperty('exec')) {
            $scope.selectedTab.exec().then(function(tabId) {
                $scope.tabId = null;
                if ($scope.selectedTab.form) {
                    $scope.selectedTab.form.$setPristine();
                    $scope.selectedTab.form.$setUntouched();
                }
                if ($scope.selectedTab.hasOwnProperty('post')) {
                    $scope.selectedTab.post().then(function() {
                        redirectTab(tabId);
                    }, function(error) {
                        Notifier.responseMessage(error);
                    });
                }
                else redirectTab(tabId);
            }, function(error) {
                Notifier.responseMessage(error);
            });
        }
    };

    function redirectTab(tabId) {
        if (_.isUndefined(tabId)) {
            if ($scope.selectedTab.stateless || $scope.MODULE_STATE == $scope.MODULE_STATE_CREATE) {
                $scope.nextTab();
            }
        }
        else {
            var tab = _.find($scope.tabs, ['id', tabId]);
            tab.disabled = false;
            tab.visible = true;
            $timeout(function() {
                $scope.selectedTabIndex = _.findIndex($scope.tabs, ['id', tabId]);
            }, 100);
        }
    }

    $scope.previousTab = function() {
        var previousTab = $scope.selectedTabIndex - 1;
        if (previousTab >= 0)
            $scope.selectedTabIndex = previousTab;
    };

    $scope.nextTab = function() {
        var tabsLength = $scope.tabs.filter(function(item) {
            return item.visible;
        }).length;

        var nextTab = $scope.selectedTabIndex + 1;
        if (nextTab < tabsLength) {
            $scope.tabs[nextTab].disabled = false;
            $timeout(function() {
                $scope.selectedTabIndex = nextTab;
            }, 100);
        }
    };

    this.enableTabs = function(idList) {
        if (_.isUndefined(idList)) {
            _.each($scope.tabs, function(tab) {
                tab.disabled = false;
            });
        }
        else {
            _.each(idList, function(tabId) {
                _.find($scope.tabs, ['id', tabId]).disabled = false;
            });
        }
    };

    this.disableTabs = function(idList) {
        if (_.isUndefined(idList)) {
            for (var i = $scope.selectedTabIndex + 1; i < $scope.tabs.length; i++) {
                $scope.tabs[i].disabled = true;
            }
        }
        else {
            _.each(idList, function(tabId) {
                _.find($scope.tabs, ['id', tabId]).disabled = true;
            });
        }
    };

    this.hideTabs = function(idList) {
        _.each(idList, function(tabId) {
            _.find($scope.tabs, ['id', tabId]).visible = false;
            _.find($scope.tabs, ['id', tabId]).disabled = true;
        });
    };
}
