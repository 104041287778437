import { GridReducer } from '../common/grid/grid.reducer';
import merge from 'lodash/merge';
import set from 'lodash/set';

/**
 * @function administrationAccountsRoomsReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function administrationAccountsRoomsReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            administrationAccountsRoomsGrid: merge(GridReducer(), {
                filter: {uuid: null}
            }),
            administrationAccountsRoom: {
                title: 'New Room',
                roomTypes: [],
                data: {
                    uuid: null,
                    accountId: null,
                    parentUuid: null,
                    name: null,
                    building: null,
                    level: null,
                    number: null,
                    created: null,
                    roomType: 37
                }
            }
        };
    }

    switch (action.type) {
        case 'ROOM_FETCH':
            if (action.id === 'administrationAccountsRoom') {
                return Object.assign({}, state, {
                    administrationAccountsRoom: Object.assign({}, state.administrationAccountsRoom, {
                        title: 'Room Data',
                        data: {
                            uuid: action.data.uuid,
                            accountId: action.data.accountId,
                            parentUuid: action.data.parentUuid,
                            name: action.data.name,
                            building: action.data.building,
                            level: action.data.level,
                            number: action.data.number,
                            created: action.data.created,
                            roomType: action.data.roomType
                        }
                    })
                });
            }
        case 'ROOM_RESET':
            if (action.id === 'administrationAccountsRoom') {
                return Object.assign({}, state, {
                    administrationAccountsRoom: Object.assign({}, state.administrationAccountsRoom, {
                        title: 'New Room',
                        data: {
                            uuid: null,
                            accountId: null,
                            parentUuid: null,
                            name: null,
                            building: null,
                            level: null,
                            number: null,
                            created: null,
                            roomType: 37
                        }
                    })
                });
            }
        case 'LOCATION_SELECTED':
            if (action.id === 'administrationAccountsRoomsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsRoomsGrid: set(GridReducer(state.administrationAccountsRoomsGrid, {type: 'PAGINATE'}), 'filter.uuid', action.dep)
                });
            }
        case 'GRID_OVERRIDE':
        case 'SHOW_LOADING':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
            if (action.id === 'administrationAccountsRoomsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsRoomsGrid: GridReducer(state.administrationAccountsRoomsGrid, action)
                });
            }
        case 'ROOM_TYPES':
            if (action.id === 'administrationAccountsRoom') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        roomTypes: action.items
                    }
                }
            }
        case 'INPUT_SELECT':
            if (action.id === 'administrationAccountsRoom') {
                return set({...state}, `${action.id}.${action.path}`, action.input)
            }
        default:
            return state;
    }
}
