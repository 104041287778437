/* @ngInject */
export function selectCountry($rootScope, LocationService, Notifier, COUNTRY_SELECT_EVENT) {
    return {
        restrict: 'E',
        require: '^form',
        scope: {
            disable: '=?',
            behaviour: '=?',
            validate: '=',
            identifier: '=',
            country: '=',
            state: '='
        },
        template: require('./select-country.html'),
        link: function(scope, element, attrs, formCtrl) {

            if (_.isUndefined(scope.disable)) scope.disable = false;
            if (_.isUndefined(scope.behaviour)) scope.behaviour = null;
            if (_.isUndefined(scope.validate)) scope.validate = false;

            var formName = _.isUndefined(scope.identifier) ? formCtrl.$name : scope.identifier;

            scope.selectForm = formCtrl;
            scope.uid = _.uniqueId();

            scope.states = [];

            LocationService.getCountries().then(function(result) {
                scope.countries = result.data;
                scope.countryDir = findLocation(scope.countries,scope.country);

                // TODO not sure this makes any sens
                if (scope.validate) {
                    formCtrl['selectCountries'+scope.uid].$setValidity('required', true);
                    formCtrl['selectStates'+scope.uid].$setValidity('required', true);
                }

                if(!_.isUndefined(scope.countryDir))
                {
                    scope.onCountrySelect();
                    return;
                }

                if (scope.behaviour === null)
                    return;

                switch (scope.behaviour) {
                    case 'selectDefault':
                        scope.countryDir = _.first(scope.countries);
                        scope.onCountrySelect();
                        break;
                }
            });

            $rootScope.$on(COUNTRY_SELECT_EVENT.RESET, function(event) {
                scope.stateDir = {};
                scope.countryDir = {};
            });

            scope.onCountrySelect = function() {
                if (_.has(scope.countryDir, 'id')) {
                    scope.country.id = scope.countryDir.id;
                    scope.country.name = scope.countryDir.name;

                    if (scope.validate) {
                        formCtrl['selectCountries'+scope.uid].$setValidity('required', true);
                    }

                    LocationService.getStates(scope.country.id).then(function(result) {
                        scope.states = result.data;
                        scope.stateDir = findLocation(scope.states,scope.state);

                        if (!_.isUndefined(scope.stateDir)) {
                            scope.onStateSelect();
                            return;
                        }

                        if (scope.behaviour == null)
                            return;

                        switch (scope.behaviour) {
                            case 'selectDefault':
                                scope.stateDir = _.first(scope.states);
                                break;
                        }
                    }, function(error) {
                        Notifier.responseMessage(error);
                    });
                }
            };

            scope.onStateSelect = function() {
                scope.country       = _.cloneDeep(scope.countryDir);
                scope.country.state = scope.stateDir;
                scope.state         = scope.stateDir;

                if (scope.validate) {
                    formCtrl['selectStates'+scope.uid].$setValidity('required', true);
                }
            };

            var validationListener = scope.$on('show-errors-check-validity', function(event, formIdentifier) {
                if (typeof formIdentifier !== 'undefined')
                    if (formName != formIdentifier) return;

                if (scope.validate && _.isNull(_.get(scope.countryDir, 'id', null))) {
                    formCtrl['selectCountries' + scope.uid].$setValidity('required', false);
                    formCtrl['selectCountries' + scope.uid].$setDirty();
                    formCtrl['selectCountries' + scope.uid].$setTouched();
                }

                if (scope.validate && _.isNull(_.get(scope.stateDir, 'id', null))) {
                    formCtrl['selectStates' + scope.uid].$setValidity('required', false);
                    formCtrl['selectStates' + scope.uid].$setDirty();
                    formCtrl['selectStates' + scope.uid].$setTouched();
                }
            });

            function findLocation(haystack, needle) {
                if (needle === null)
                    return undefined;

                var countryId = false;

                if (typeof needle === 'object' && !_.isUndefined(needle.id))
                    countryId = ""+ needle.id;
                else
                    countryId = ""+needle;

                return _.find(haystack,['id',countryId]);
            }

            element.on('$destroy', function() {
                validationListener();
            });
        }
    };
}
