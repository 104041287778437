import { TabsReducer } from '../../common/tabs/tabs.reducer';

export const tabs = {
    'app.applicationsPermits.laser.request': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.applicationsPermits.laser.request.invitation',
                title: 'Initial Application',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Send' }
                }
            }
        ]
    },
    'app.applicationsPermits.laser.manage': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.applicationsPermits.laser.manage.listing',
                title: 'Applications',
                visible: true,
                disabled: false,
                loading: false,
                buttons: []
            },
            {
                state: 'app.applicationsPermits.laser.manage.details',
                title: 'Applicant Details',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'app.applicationsPermits.laser.manage.trainings',
                title: 'Trainings Completed',
                visible: false,
                disabled: false,
                loading: false,
                buttons: []
            },
            {
                state: 'app.applicationsPermits.laser.manage.safetyPlan',
                title: 'Laser Safety Plan Acknowledged',
                visible: false,
                disabled: false,
                loading: false,
                buttons: []
            },
            {
                state: 'app.applicationsPermits.laser.manage.equipment',
                title: 'Authorized Equipment',
                visible: false,
                disabled: false,
                loading: false,
                buttons: []
            }
        ]
    },
    'app.applicationsPermits.laser.alerts': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.applicationsPermits.laser.alerts.listing',
                title: 'Alerts & Warnings',
                visible: true,
                disabled: false,
                loading: false,
                buttons: []
            }
        ]
    },
    'public.applicationsPermits.laser.request': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'public.applicationsPermits.laser.request.intro',
                title: 'Introduction',
                visible: true,
                disabled: false,
                loading: false,
                buttons: []
            },
            {
                state: 'public.applicationsPermits.laser.request.details',
                title: 'Applicant Information',
                visible: true,
                disabled: false,
                loading: false,
                buttons: []
            },
            {
                state: 'public.applicationsPermits.laser.request.equipment',
                title: 'Equipment',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Submit' }
                }
            },
            {
                state: 'public.applicationsPermits.laser.request.confirmation',
                title: 'Application Confirmation',
                visible: false,
                disabled: false,
                loading: false,
                buttons: []
            },
        ]
    },
    'app.applicationsPermits.nuclearEnergy.request': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.applicationsPermits.nuclearEnergy.request.invitation',
                title: 'Initial Application',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Send' }
                }
            }
        ]
    },
    'app.applicationsPermits.nuclearEnergy.manage': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.applicationsPermits.nuclearEnergy.manage.listing',
                title: 'Applications',
                visible: true,
                disabled: false,
                loading: false,
                buttons: []
            },
            {
                state: 'app.applicationsPermits.nuclearEnergy.manage.details',
                title: 'Applicant Details',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'app.applicationsPermits.nuclearEnergy.manage.trainings',
                title: 'Trainings Completed',
                visible: false,
                disabled: false,
                loading: false,
                buttons: []
            },
            {
                state: 'app.applicationsPermits.nuclearEnergy.manage.safetyPlan',
                title: 'Nuclear Energy Safety Plan Acknowledged',
                visible: false,
                disabled: false,
                loading: false,
                buttons: []
            },
            {
                state: 'app.applicationsPermits.nuclearEnergy.manage.equipment',
                title: 'Authorized Equipment',
                visible: false,
                disabled: false,
                loading: false,
                buttons: []
            }
        ]
    },
    'app.applicationsPermits.nuclearEnergy.alerts': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.applicationsPermits.nuclearEnergy.alerts.listing',
                title: 'Alerts & Warnings',
                visible: true,
                disabled: false,
                loading: false,
                buttons: []
            }
        ]
    },
    'public.applicationsPermits.nuclearEnergy.request': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'public.applicationsPermits.nuclearEnergy.request.intro',
                title: 'Introduction',
                visible: true,
                disabled: false,
                loading: false,
                buttons: []
            },
            {
                state: 'public.applicationsPermits.nuclearEnergy.request.details',
                title: 'Applicant Information',
                visible: true,
                disabled: false,
                loading: false,
                buttons: []
            },
            {
                state: 'public.applicationsPermits.nuclearEnergy.request.equipment',
                title: 'Equipment',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Submit' }
                }
            },
            {
                state: 'public.applicationsPermits.nuclearEnergy.request.confirmation',
                title: 'Application Confirmation',
                visible: false,
                disabled: false,
                loading: false,
                buttons: []
            },
        ]
    }
};
