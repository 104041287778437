/*
*   List of Action IDs that should be passed from accreditation.reducer.js to document.reducer.js
 */

export const IDS = {
    /* module */
    accAssessmentReports: 'accAssessmentReports',

    /* components: */
    accAssessmentReportsGrid:      'accAssessmentReportsGrid',
    accAssessmentReportsDetails:   'accAssessmentReportsDetails',
    accAssessmentReportsAdd:       'accAssessmentReportsAdd',

    /* paths: */
    documentDetails:                    'documentDetails',
    documentReviewsGrid:                'documentReviewsGrid',
    documentAddGrid:                    'documentAddGrid',

    /* tab states: */
    app_accreditation_scheme_assessment_reports:          'app.accreditation.scheme.assessment-reports',
    app_accreditation_scheme_assessment_reports_list:     'app.accreditation.scheme.assessment-reports.list',
    app_accreditation_scheme_assessment_reports_details:  'app.accreditation.scheme.assessment-reports.details',
    app_accreditation_scheme_assessment_reports_add:      'app.accreditation.scheme.assessment-reports.add',

    /* public tab states */
    public_accreditation_scheme_assessment_reports:       'public.accreditation.scheme.assessment-reports',
};
