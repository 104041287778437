/** @namespace Reducers.Reporting */

import { TabsReducer } from '../common/tabs/tabs.reducer';
import { GridReducer } from '../common/grid/grid.reducer';
import merge from 'lodash/merge';
import set from 'lodash/set';
import update from 'lodash/update';
import {
    RM_ISOTOPES,
    RM_ATOMIC_NUMS,
    RM_UNITS,
} from '../components/radioactive-materials/radioactive-materials.actions.types';

import { IDS as REP_MONTHLY_ACTION_IDS }            from '../components/reporting/monthly/rep-monthly-action-id.constants';
import { PREFIX as REP_MONTHLY_ACTION_PREFIX }      from '../components/reporting/monthly/rep-monthly-action-type.constants';
import { RepMonthlyReducer }                        from '../components/reporting/monthly/rep-monthly.reducer';
import { repMonthlyTabs }                           from '../components/reporting/monthly/rep-monthly-tabs';
import * as TABS from "../common/tabs/tabs.constants";

/**
 * @function reportingReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function reportingReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            'app.reporting.equipmentInfo': Object.assign(TabsReducer(), {
                tabs: [
                    {
                        state: 'app.reporting.equipmentInfo.listing',
                        title: 'Find Equipment',
                        visible: true,
                        disabled: false,
                        loading: false,
                    },
                    {
                        state: 'app.reporting.equipmentInfo.details',
                        title: 'Equipment Details',
                        visible: true,
                        disabled: true,
                        loading: false,
                    },
                    {
                        state: 'app.reporting.equipmentInfo.serviceDetails',
                        title: 'Service Details',
                        visible: true,
                        disabled: true,
                        loading: false,
                    },
                    {
                        state: 'app.reporting.equipmentInfo.serviceHistory',
                        title: 'Service History',
                        visible: true,
                        disabled: true,
                        loading: false,
                    },
                    {
                        state: 'app.reporting.equipmentInfo.inspections',
                        title: 'Previous Inspections',
                        visible: true,
                        disabled: true,
                        loading: false,
                    },
                    {
                        state: 'app.reporting.equipmentInfo.history',
                        title: 'Equipment History',
                        visible: true,
                        disabled: true,
                        loading: false,
                    }
                ]
            }),
            'app.reporting.equipmentType': Object.assign(TabsReducer(), {
                tabs: [{
                    state: 'app.reporting.equipmentType.listing',
                    title: 'Equipment History',
                    visible: true,
                    disabled: false,
                    loading: false,
                }]
            }),
            'app.reporting.inspection': Object.assign(TabsReducer(), {
                tabs: [{
                    state: 'app.reporting.inspection.listing',
                    title: 'Inspected Equipment',
                    visible: true,
                    disabled: false,
                    loading: false,
                }]
            }),
            'app.reporting.summary': Object.assign(TabsReducer(), {
                tabs: [{
                    state: 'app.reporting.summary.listing',
                    title: 'Equipment Summary',
                    visible: true,
                    disabled: false,
                    loading: false,
                }]
            }),
            'app.reporting.cnsc': Object.assign(TabsReducer(), {
                tabs: [{
                    state: 'app.reporting.cnsc.listing',
                    title: 'Licence List',
                    visible: true,
                    disabled: false,
                    loading: false,
                }]
            }),
            'app.reporting.isotopesActivity': Object.assign(TabsReducer(), {
                tabs: [{
                    state: 'app.reporting.isotopesActivity.listing',
                    title: 'Isotopes List',
                    visible: true,
                    disabled: false,
                    loading: false,
                }]
            }),
            reportingEquipmentGrid: merge(GridReducer(), {types: []}, {
                filter: {
                    status: 'act',
                    equipmentId: null,
                    serial: null,
                    location: null,
                    manufacturer: null,
                    type: null,
                    regDateFrom: null,
                    regDateTo: null,
                    certDateFrom: null,
                    certDateTo: null
                }
            }),
            reportingEquipmentDetails: {
                id: null
            },
            reportingEquipmentServiceDetails: {
                frequency: null,
                installationDate: null,
                lastInspection: null,
                nextInspection: null,
                retirementDate: null
            },
            reportingEquipmentInfoServiceHistoryGrid: set(GridReducer(), 'itemsCount', undefined),
            reportingEquipmentInfoInspectionsGrid: set(GridReducer(), 'itemsCount', undefined),
            reportingEquipmentInfoHistoryGrid: set(GridReducer(), 'itemsCount', undefined),
            reportingEquipmentInspectionGrid: merge(GridReducer(), {
                statusList: [{name: 'All', value: null}, {name: 'Passed', value: 0}, {name: 'Failed', value: 1}],
                categories: [],
                dateRange: [
                    {value: 7,      name: '7 days'},
                    {value: 15,     name: '15 days'},
                    {value: 30,     name: '1 month'},
                    {value: 90,     name: '3 months'},
                    {value: 180,    name: '6 months'},
                    {value: 365,    name: '1 year'},
                    {value: 1095,   name: '3 years'}]
            }, {
                filter: {
                    location: null,
                    manufacturer: null,
                    status: null,
                    categories: [],
                    inspectionDateFrom: null,
                    inspectionDateTo: null,
                    expiryDateFrom: null,
                    expiryDateTo: null,
                    expiryDateRange: null
                }
            }),
            reportingEquipmentSummaryGrid: merge(set(GridReducer(), 'itemsCount', undefined), {
                filter: {
                    location: null,
                    status: null,
                    categories: [],
                    groupByDepartment: false
                }
            }),
            reportingEquipmentHistoryGrid: merge(set(GridReducer(), 'itemsCount', undefined), {types: []}, {
                filter: {
                    location: null,
                    type: null,
                    dateFrom: null,
                    dateTo: null
                }
            }),
            reportingCnscGrid: merge(GridReducer(), {
                filter: {
                    location: null
                }
            }),
            reportingIsotopesActivityGrid: merge(GridReducer(), {
                isotopes: [],
                atomicNums: [],
                sealedData: [
                    {value: 1, label: 'Sealed'},
                    {value: 0, label: 'Unsealed'}
                ],
                filter: {
                    location: null,
                    isotopeId: null,
                    atomicNoId: null,
                    licenceNo: null,
                    sealed: null
                }
            }),

            repMonthly: RepMonthlyReducer(),
            ...repMonthlyTabs,
        };
    }

    switch (action.type) {
        case TABS.TAB_INIT:
        case TABS.TAB_OVERRIDE:
        case TABS.TAB_PARAMS:
        case TABS.TAB_ENABLE:
        case TABS.TAB_DISABLE:
        case TABS.TAB_SHOW:
        case TABS.TAB_HIDE:
        case TABS.TAB_LOADING:
        case TABS.TAB_SELECT:
        case TABS.TAB_LABEL:
        case TABS.TAB_BUTTON_LABEL:
        case TABS.TAB_TRANSITION_DISABLE:
        case TABS.TAB_TRANSITION_ENABLE:
            if (action.id === 'app.reporting.monthly'){
                return {
                    ...state,
                    [action.id]: TabsReducer(state[action.id], action)
                }
            }
        case TABS.TAB_SWITCH:
            if (action.id === 'app.reporting.monthly'){
                return {
                    ...state,
                    [action.id]: Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                }
            }

        case 'TAB_SELECT':
            if (action.id === 'app.reporting.equipmentInfo') {
                return Object.assign({}, state, {
                    'app.reporting.equipmentInfo': TabsReducer(state[action.id], action)
                });
            }
        case 'TAB_INIT':
        case 'TAB_OVERRIDE':
        case 'TAB_PARAMS':
        case 'TAB_ENABLE':
        case 'TAB_DISABLE':
        case 'TAB_SHOW':
        case 'TAB_HIDE':
        case 'TAB_LOADING':
            if (action.id === 'app.reporting.equipmentInfo') {
                return Object.assign({}, state, {
                    'app.reporting.equipmentInfo': TabsReducer(state[action.id], action)
                });
            }
        case 'TAB_SWITCH':
            if (action.id === 'app.reporting.equipmentInfo') {
                return Object.assign({}, state, {
                    'app.reporting.equipmentInfo': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                });
            }
        case 'EQUIPMENT_TYPES':
            if (action.id === 'reportingEquipmentGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentGrid: Object.assign({}, state.reportingEquipmentGrid, {types: action.items})
                });
            }
            if (action.id === 'reportingEquipmentInspectionGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentInspectionGrid: Object.assign({}, state.reportingEquipmentInspectionGrid, {categories: action.items})
                });
            }
            if (action.id === 'reportingEquipmentSummaryGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentSummaryGrid: Object.assign({}, state.reportingEquipmentSummaryGrid, {categories: action.items})
                });
            }
            if (action.id === 'reportingEquipmentHistoryGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentHistoryGrid: Object.assign({}, state.reportingEquipmentHistoryGrid, {types: action.items})
                });
            }
        case 'EQUIPMENT_ID_CHANGE':
            if (action.id === 'reportingEquipmentGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentGrid: set(GridReducer(state.reportingEquipmentGrid, {type: 'PAGINATE'}), 'filter.equipmentId', action.equipmentId)
                });
            }
        case 'EQUIPMENT_SERIAL_CHANGE':
            if (action.id === 'reportingEquipmentGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentGrid: set(GridReducer(state.reportingEquipmentGrid, {type: 'PAGINATE'}), 'filter.serial', action.serial)
                });
            }
        case 'LOCATION_SELECTED':
            if (action.id === 'reportingEquipmentGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentGrid: set(GridReducer(state.reportingEquipmentGrid, {type: 'PAGINATE'}), 'filter.location', action.uuid)
                });
            }
            if (action.id === 'reportingEquipmentInspectionGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentInspectionGrid: set(GridReducer(state.reportingEquipmentInspectionGrid, {type: 'PAGINATE'}), 'filter.location', action.uuid)
                });
            }
            if (action.id === 'reportingEquipmentSummaryGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentSummaryGrid: set(GridReducer(state.reportingEquipmentSummaryGrid, {type: 'PAGINATE'}), 'filter.location', action.uuid)
                });
            }
            if (action.id === 'reportingEquipmentHistoryGrid') {
                if (action.formValid)
                    return Object.assign({}, state, set({}, action.id, set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.location', action.uuid)));
                else
                    return Object.assign({}, state, set({}, action.id, set(Object.assign({}, state[action.id]), 'filter.location', action.uuid)));
            }
            if (action.id === 'reportingCnscGrid') {
                return Object.assign({}, state, set({}, action.id, set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.location', action.uuid)));
            }
            if (action.id === 'reportingIsotopesActivityGrid') {
                return Object.assign({}, state, set({}, action.id, set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.location', action.uuid)));
            }
        case 'MANUFACTURER_SELECTED':
            if (action.id === 'reportingEquipmentGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentGrid: set(GridReducer(state.reportingEquipmentGrid, {type: 'PAGINATE'}), 'filter.manufacturer', action.manufacturer)
                });
            }
            if (action.id === 'reportingEquipmentInspectionGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentInspectionGrid: set(GridReducer(state.reportingEquipmentInspectionGrid, {type: 'PAGINATE'}), 'filter.manufacturer', action.manufacturer)
                });
            }
        case 'EQUIPMENT_TYPE_SELECTED':
            if (action.id === 'reportingEquipmentGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentGrid: set(GridReducer(state.reportingEquipmentGrid, {type: 'PAGINATE'}), 'filter.type', action.equipmentType)
                });
            }
            if (action.id === 'reportingEquipmentHistoryGrid') {
                if (action.formValid)
                    return Object.assign({}, state, set({}, action.id, set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.type', action.equipmentType)));
                else
                    return Object.assign({}, state, set({}, action.id, set(Object.assign({}, state[action.id]), 'filter.type', action.equipmentType)));
            }
        case 'DATE_SELECTED':
            if (action.id === 'reportingEquipmentGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentGrid: set(GridReducer(state.reportingEquipmentGrid, {type: 'PAGINATE'}), action.path, action.input)
                });
            }
            if (action.id === 'reportingEquipmentInspectionGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentInspectionGrid: set(GridReducer(state.reportingEquipmentInspectionGrid, {type: 'PAGINATE'}), action.path, action.input)
                });
            }
            if (action.id === 'reportingEquipmentHistoryGrid') {
                if (action.formValid)
                    return Object.assign({}, state, set({}, action.id, set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)));
                else
                    return Object.assign({}, state, set({}, action.id, set(Object.assign({}, state[action.id]), action.path, action.input)));
            }
        case 'DATE_RANGE_SELECTED':
            if (action.id === 'reportingEquipmentInspectionGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentInspectionGrid: update(GridReducer(state.reportingEquipmentInspectionGrid, {type: 'PAGINATE'}), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            expiryDateFrom: new Date(),
                            expiryDateTo: new Date(new Date().getTime()+(action.dateRange * 24 * 60 * 60 *1000)),
                            expiryDateRange: action.dateRange
                        });
                    })
                });
            }
        case 'EQUIPMENT_TEST_STATUS_SELECTED':
            if (action.id === 'reportingEquipmentSummaryGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentSummaryGrid: set(GridReducer(state.reportingEquipmentSummaryGrid, {type: 'PAGINATE'}), 'filter.status', action.status)
                });
            }
            if (action.id === 'reportingEquipmentInspectionGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentInspectionGrid: set(GridReducer(state.reportingEquipmentInspectionGrid, {type: 'PAGINATE'}), 'filter.status', action.status)
                });
            }
        case 'EQUIPMENT_CATEGORY_SELECTED':
            if (action.id === 'reportingEquipmentSummaryGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentSummaryGrid: set(GridReducer(state.reportingEquipmentSummaryGrid, {type: 'PAGINATE'}), 'filter.categories', action.categories)
                });
            }
            if (action.id === 'reportingEquipmentInspectionGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentInspectionGrid: set(GridReducer(state.reportingEquipmentInspectionGrid, {type: 'PAGINATE'}), 'filter.categories', action.categories)
                });
            }
        case 'CHECKBOX_SELECTED':
            if (action.id === 'reportingEquipmentSummaryGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentSummaryGrid: set(GridReducer(state.reportingEquipmentSummaryGrid, {type: 'PAGINATE'}), 'filter.groupByDepartment', action.checkbox)
                });
            }
        case 'GRID_INIT':
        case 'GRID_RESET':
            if (action.id === 'reportingEquipmentGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentGrid: update(GridReducer(state.reportingEquipmentGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            equipmentId: null,
                            serial: null,
                            location: null,
                            manufacturer: null,
                            type: null,
                            regDateFrom: null,
                            regDateTo: null,
                            certDateFrom: null,
                            certDateTo: null
                        });
                    })
                });
            }
            if (action.id === 'reportingEquipmentInspectionGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentInspectionGrid: update(GridReducer(state.reportingEquipmentInspectionGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            location: null,
                            manufacturer: null,
                            status: null,
                            categories: [],
                            inspectionDateFrom: null,
                            inspectionDateTo: null,
                            expiryDateFrom: null,
                            expiryDateTo: null,
                            expiryDateRange: null
                        });
                    })
                });
            }
            if (action.id === 'reportingEquipmentSummaryGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentSummaryGrid: update(GridReducer(state.reportingEquipmentSummaryGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            location: null,
                            status: null,
                            categories: [],
                            groupByDepartment: false
                        });
                    })
                });
            }
            if (action.id === 'reportingEquipmentHistoryGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentHistoryGrid: update(GridReducer(state.reportingEquipmentHistoryGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            location: null,
                            type: null,
                            dateFrom: null,
                            dateTo: null
                        });
                    })
                });
            }
            if (action.id === 'reportingCnscGrid') {
                return Object.assign({}, state, {
                    reportingCnscGrid: update(GridReducer(state.reportingCnscGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            location: null
                        });
                    })
                });
            }
            if (action.id === 'reportingIsotopesActivityGrid') {
                return Object.assign({}, state, {
                    reportingIsotopesActivityGrid: update(GridReducer(state.reportingIsotopesActivityGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            location: null,
                            isotopeId: null,
                            sealed: null,
                            atomicNoId: null,
                            licenceNo: null
                        });
                    })
                });
            }
        case 'SHOW_LOADING':
        case 'GRID_RESET_FILTER':
        case 'PAGINATE':
        case 'GRID_OVERRIDE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
            if (action.id === 'reportingEquipmentGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentGrid: GridReducer(state.reportingEquipmentGrid, action)
                });
            }
            if (action.id === 'reportingEquipmentInfoServiceHistoryGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentInfoServiceHistoryGrid: GridReducer(state.reportingEquipmentInfoServiceHistoryGrid, action)
                });
            }
            if (action.id === 'reportingEquipmentInfoInspectionsGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentInfoInspectionsGrid: GridReducer(state.reportingEquipmentInfoInspectionsGrid, action)
                });
            }
            if (action.id === 'reportingEquipmentInfoHistoryGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentInfoHistoryGrid: GridReducer(state.reportingEquipmentInfoHistoryGrid, action)
                });
            }
            if (action.id === 'reportingEquipmentInspectionGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentInspectionGrid: GridReducer(state.reportingEquipmentInspectionGrid, action)
                });
            }
            if (action.id === 'reportingEquipmentSummaryGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentSummaryGrid: GridReducer(state.reportingEquipmentSummaryGrid, action)
                });
            }
            if (action.id === 'reportingEquipmentHistoryGrid') {
                return Object.assign({}, state, {
                    reportingEquipmentHistoryGrid: GridReducer(state.reportingEquipmentHistoryGrid, action)
                });
            }
            if (action.id === 'reportingCnscGrid') {
                return Object.assign({}, state, {
                    reportingCnscGrid: GridReducer(state.reportingCnscGrid, action)
                });
            }
            if (action.id === 'reportingIsotopesActivityGrid') {
                return Object.assign({}, state, {
                    reportingIsotopesActivityGrid: GridReducer(state.reportingIsotopesActivityGrid, action)
                });
            }
        case 'EQUIPMENT_FETCH':
            if (action.id === 'reportingEquipmentInfo') {
                return Object.assign({}, state, {
                    reportingEquipmentDetails: action.equipment,
                    reportingEquipmentServiceDetails: action.service
                });
            }
        case RM_ISOTOPES:
        case RM_ATOMIC_NUMS:
            if (action.id === 'reportingIsotopesActivityGrid') {
                const reset = action.reset || {};
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.items,
                        ...reset
                    }
                }
            }
        case 'INPUT_SELECT':
        case 'INPUT_RADIO_CHANGE':
        case 'INPUT_TEXT_CHANGE':
            if (action.id === 'reportingIsotopesActivityGrid') {
                return {
                    ...state,
                    [action.id]: set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)
                }
            }
        case 'RESET_ATOMIC_NUMBER':
            if (action.id === 'reportingIsotopesActivityGrid') {
                return {
                    ...state,
                    [action.id]: set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)
                }
            }
    }

    if(action.type.startsWith(REP_MONTHLY_ACTION_PREFIX)
        || action.id && REP_MONTHLY_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, repMonthly: RepMonthlyReducer(state.repMonthly, action) }
    }

    return state;
}
