import watch from 'redux-watch';
import pick from 'lodash/pick';

export const personalDosimetrySettingsDetails = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $uiRouterGlobals, $scope, $mdDialog, PersonalDosimetryActions, Notifier) {
        this.$onInit = () => {
            this.tabsState = $state.$current.parent.name;
            this.currentState = 'app.personalDosimetry.settings.details';
            this.unsubscribe = $ngRedux.connect(({ personalDosimetry }) => ({
                data: personalDosimetry.personalDosimetrySettings
            }), PersonalDosimetryActions)(this);

            this.title = this.data.pdl_id
                ? 'Threshold and Absolute Limit Details'
                : 'Add New Threshold and Absolute Limit';

            // for limit details
            this.setDoseUnit(this.data.provider_code);

            // for provider dropdown
            const watchProviderCode = watch($ngRedux.getState, 'personalDosimetry.personalDosimetrySettings.provider_code');
            this.onProviderCode = $ngRedux.subscribe(watchProviderCode((provider_code) => {
                this.setDoseUnit(provider_code);
            }));
        };

        this.setDoseUnit = (provider_code) => {
            if(provider_code === 'pdoarp')
                this.dose_unit = 'μSv';
            else if(provider_code !== 'pdoarp')
                this.dose_unit = 'mSv';
            else
                this.dose_unit = null;
        };

        this.onSave = () => {
            $scope.$broadcast('show-errors-check-validity', '$ctrl.pdSettingsForm');
            if (this.pdSettingsForm.$valid) {
                if (this.data.pdl_id) {
                    this.updateSettingsLimit(pick(this.data, [
                        'pdl_id',
                        'provider_code',
                        'bty_code',
                        'loc_uuid',
                        'sty_id',
                        'th_limit_monthly',
                        'th_limit_2_monthly',
                        'th_limit_3_monthly',
                        'th_limit_9_monthly',
                        'th_limit_yearly',
                        'al_limit_monthly',
                        'al_limit_2_monthly',
                        'al_limit_3_monthly',
                        'al_limit_9_monthly',
                        'al_limit_yearly',
                    ])).then(({ data }) => {
                        if (data.status === 0) {
                            Notifier.success('Chosen Limit has been successfully updated');
                        } else {
                            Notifier.error('System Error!');
                        }
                    });
                } else {
                    this.createSettingsLimit(pick(this.data, [
                        'provider_code',
                        'bty_code',
                        'loc_uuid',
                        'sty_id',
                        'th_limit_monthly',
                        'th_limit_2_monthly',
                        'th_limit_3_monthly',
                        'th_limit_9_monthly',
                        'th_limit_yearly',
                        'al_limit_monthly',
                        'al_limit_2_monthly',
                        'al_limit_3_monthly',
                        'al_limit_9_monthly',
                        'al_limit_yearly',
                    ])).then(({ data }) => {
                        if (data.status === 0) {
                            $ngRedux.dispatch({
                                type: 'TAB_SELECT',
                                id: 'app.personalDosimetry.settings',
                                state: 'app.personalDosimetry.settings.listing',
                            });
                            Notifier.success('New Limit has been successfully added');
                        } else {
                            Notifier.error('System Error!');
                        }
                    });
                }
            } else {
                Notifier.error('Please fill in the form fields');
            }
        };

        this.setDefaults = () => {
            if (
                this.pdSettingsForm.provider_code.$valid &&
                this.pdSettingsForm.bty_code.$valid
            ) {
                const confirm = $mdDialog.confirm()
                    .title('Are you sure?')
                    .textContent('Set default limits for selected Service Provider and Badge Type.')
                    .ariaLabel('Set Defaults')
                    .ok('Yes')
                    .cancel('No');
                this.dialog = $mdDialog.show(confirm).then(() => {
                    this.getSettings({
                        default_dose_limits_only: 1,
                        provider_code: this.data.provider_code,
                        bty_code: this.data.bty_code,
                        offset: 0,
                        limit: -1,
                    }).then((data) => {
                        if (data.items[0]) {
                            $ngRedux.dispatch({
                                type: 'PD_LIMIT_DEFAULTS',
                                id: 'personalDosimetry',
                                data: pick(data.items[0], [
                                    'th_limit_monthly',
                                    'th_limit_2_monthly',
                                    'th_limit_3_monthly',
                                    'th_limit_9_monthly',
                                    'th_limit_yearly',
                                    'al_limit_monthly',
                                    'al_limit_2_monthly',
                                    'al_limit_3_monthly',
                                    'al_limit_9_monthly',
                                    'al_limit_yearly',
                                ]),
                            });
                        } else {
                            Notifier.error('No default limits!');
                        }
                    });
                }, angular.noop);
            } else {
                this.pdSettingsForm.provider_code.$setDirty();
                this.pdSettingsForm.provider_code.$setTouched();
                this.pdSettingsForm.bty_code.$setDirty();
                this.pdSettingsForm.bty_code.$setTouched();
                Notifier.error('Please select Service Provider and Badge Type');
            }
        };

        this.$onDestroy = () => {
            this.unsubscribe && this.unsubscribe();
            this.onProviderCode();
            this.dialog && $mdDialog.hide(this.dialog);
        };
    },
    template: require('./pd-settings-details.html')
};