import watch from 'redux-watch';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';

class GridFilterSwitchSelectController {
    constructor($ngRedux, Notifier) {
        this.$ngRedux = $ngRedux;
        this.Notifier = Notifier;
    }

    $onInit() {
        if (!isFunction(this.action))
            throw new Error('Missing action definition');

        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);

        const watchList = watch(this.$ngRedux.getState, this.getStateContainer('itemsSelect'));
        this.onListChange = this.$ngRedux.subscribe(watchList(items => {
            if (items.length)
                this.value = items.length === parseInt(this.itemsCount);
            else
                this.value = false;
        }));
    }

    onChange() {
        if (this.value) {
            this.action(this.identifier).then(data => {
                if (!data) {
                    this.Notifier.error('Nothing to select!');
                    this.value = false;
                }
            });
        }
        else {
            this.$ngRedux.dispatch({
                type: 'GRID_DESELECT_ALL',
                id: this.identifier,
                path: this.statePath
            });
        }
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeModule();
        this.onListChange();
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        }
    }

    getStateContainer(endPath) {
        if(this.statePath)
            endPath = `${this.statePath}.${endPath}`;
        return [this.module, this.identifier, endPath].join('.');
    }

    mapState(state) {
        return {
            itemsCount: get(state,this.getStateContainer('itemsCount'))
        }
    }
}

GridFilterSwitchSelectController.$inject = ['$ngRedux', 'Notifier'];

export const gridFilterSwitchSelect = {
    bindings: {
        identifier: '@',
        statePath: '@',
        label: '@',
        action: '<'
    },
    controller: GridFilterSwitchSelectController,
    template: `
        <md-switch ng-model="$ctrl.value" aria-label="$ctrl.label" ng-change="$ctrl.onChange()">{{$ctrl.label}}</md-switch>
    `
};
