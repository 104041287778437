import { TabsReducer } from '../../common/tabs/tabs.reducer';

export const tabs = {
    'app.credentialing.vendors.invitation': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.credentialing.vendors.invitation.request',
                title: 'Initial Applicant Invitation',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Send' }
                }
            }
        ]
    },
    'app.credentialing.vendors.manage': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.credentialing.vendors.manage.listing',
                title: 'Applications',
                visible: true,
                disabled: false,
                loading: false,
                buttons: []
            },
            {
                state: 'app.credentialing.vendors.manage.details',
                title: 'Applicant Details',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'app.credentialing.vendors.manage.accounts',
                title: 'Accounts',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'app.credentialing.vendors.manage.finance',
                title: 'Finance',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'app.credentialing.vendors.manage.insurance',
                title: 'Insurance',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'app.credentialing.vendors.manage.accreditationStandards',
                title: 'Accreditation/Standards',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'app.credentialing.vendors.manage.goods',
                title: 'Goods',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'app.credentialing.vendors.manage.warranty',
                title: 'Warranty',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'app.credentialing.vendors.manage.services',
                title: 'Services',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
        ]
    },
    'app.credentialing.vendors.alerts': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.credentialing.vendors.alerts.listing',
                title: 'Alerts & Warnings',
                visible: true,
                disabled: false,
                loading: false,
                buttons: []
            }
        ]
    },
    'public.credentialing.vendors.request': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'public.credentialing.vendors.request.intro',
                title: 'Introduction',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Submit' }
                }
            },
            {
                state: 'public.credentialing.vendors.request.details',
                title: 'Applicant Details',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'public.credentialing.vendors.request.accounts',
                title: 'Accounts',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'public.credentialing.vendors.request.finance',
                title: 'Finance',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'public.credentialing.vendors.request.insurance',
                title: 'Insurance',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'public.credentialing.vendors.request.accreditationStandards',
                title: 'Accreditation/Standards',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'public.credentialing.vendors.request.goods',
                title: 'Goods',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'public.credentialing.vendors.request.warranty',
                title: 'Warranty',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
            {
                state: 'public.credentialing.vendors.request.services',
                title: 'Services',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                }
            },
        ]
    },
};
