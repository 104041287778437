import { IDS } from '../standard-action-id.constants';
import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import * as Actions from '../standard.actions';
import get from 'lodash/get';

class AccStandardDocumentListGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter, $mdDialog, $scope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
    }

    $onInit() {
        this.filterComponent = false;
        this.exportComponent = false;
        this.title = false;
        this.icon = false;
        this.confirmDialogOnRemove = true;

        this.columns = [
            {title: '#',                                                mapper: null},
            {title: 'Number',                                           mapper: 'doc_num'},
            {title: 'Type',                                             mapper: 'levelAndType'},
            {title: this.$filter('translate')('COMMON.FIELD.NAME'),     mapper: 'name'},
            {title: this.$filter('translate')('COMMON.FIELD.CREATED'),  mapper: 'doc_date'},
            {title: this.$filter('translate')('COMMON.DATE.DUE_DATE'),  mapper: 'due_date'},
            {title: this.$filter('translate')('COMMON.FIELD.STATUS'),   mapper: 'status_name'},

            // {title: this.$filter('translate')('COMMON.FIELD.UPLOADED_BY'),  mapper: 'uploaded_by'},
            // {title: this.$filter('translate')('COMMON.FIELD.UPLOADED_AT'),  mapper: 'uploaded_at'},
//          {title: this.$filter('translate')('COMMON.FIELD.DOCUMENT'),     mapper: 'file-download'}
        ];
        this.columnsDefault = this.columns.concat();
        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), Actions)(this);
        this.unsubscribeCustom = this.$ngRedux.connect(state => {
            const selectedSection = state.accreditation.selectedSection;
            const standardStatus = selectedSection && selectedSection.status;
            if(['pen','sub'].includes(standardStatus)) {
                // if (this.buttons.length < 2) // recreate buttons only if open or delete button is missing
                    this.buttons = this.createButtons();
            }
            else
                this.buttons = this.buttons.filter(item => item.class !== 'btnGridRemove');

            const reviewers = get(state.accreditation.accAssessment, `${this.statePath}.items[0].reviewers`);
            let columns = this.columns;

            // refresh reviewer columns only if changed in redux
            if(reviewers && reviewers.length
            && columns.every((column, index) => column.title === reviewers[index].name) === false) {
                columns = this.columnsDefault.concat(reviewers.map((reviewer, index) => ({
                    title: reviewer.name,
                    mapper: `reviewer${index + 1}`
                })));
            }
            return { columns }

        }, {})(this);

        this.dataAction = this.fetchRequiredDocProgressGrid;
        this.buttons = this.createButtons();
        this.init();
    }

    isDocumentShared(document) {
        const result = ['Default','Head Office'].includes(document.level);
        return result;
    }

    createButtons() {
        return [
            {
                class: 'btnGridAction',
                label: 'Details',
                callback: this.onDetails,
                filter: item => {
                    return this.isDocumentShared(item) === false || item.is_enabled
                },
            },
            // {
            //     class: 'btnGridAction',
            //     label: 'Download',
            //     callback: event => {
            //         this.onDownload(event)
            //     }
            // },
            {
                class: 'btnGridRemove',
                label: 'Remove',
                callback: event => {
                    this.onRemoveWithConfirm(event)
                },
                filter: item => {
                    return this.isDocumentShared(item) === false
                },
            },
            {
                class: 'btnGridDispose',
                label: 'Disable',
                callback: this.onNotApplicable,
                filter: item => {
                    return this.isDocumentShared(item) && item.is_enabled
                },
            },
            {
                class: 'btnGridModify',
                label: 'Enable',
                callback:  this.onApplicable,
                filter: item => {
                    return this.isDocumentShared(item) && item.is_enabled === false
                },
            }
        ];
    }

    $onDestroy() {
        if(this.unsubscribeCustom)
            this.unsubscribeCustom();

        this.destroy();
    }

}

AccStandardDocumentListGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter', '$mdDialog', '$scope'];

export const accStandardDocsGrid = {
    bindings: {
        module: "<",
        identifier: "<",
        statePath: "<",
        onDelete: "&",
        // onDownload: "&",
        onSelect: "&",
        onDetails: "&",
        onApplicable: "&",
        onNotApplicable: "&",
    },
    controller: AccStandardDocumentListGridController,
    ...gridTpl
}
