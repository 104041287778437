/* @ngInject */
export function TransducerFactory(Equipment) {
    return function Transducer() {

        Equipment.call(this);

        this.mhz_range_from = null;
        this.mhz_range_to = null;
    };
}
