/* @ngInject */
export const VendorManagerFactory = (UserCredentials) => {
    function VendorManagerUser() {

        UserCredentials.call(this);

        this.uuid = null;
        this.position = null;
        this.firstName = null;
        this.lastName = null;
        this.email = null;
        this.phone = null;
        this.fax = null;
        this.birthday = null;
        this.gender = null;

        this.reset = function() {
            UserCredentials.reset();
            _.forOwn(this, function(property) {
                property = null;
            });
        };
    }

    VendorManagerUser.prototype = Object.create(UserCredentials.prototype);

    return VendorManagerUser;
};
