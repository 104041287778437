import { gridTpl } from '../../../../../../common/grid/grid.controller';

export const administrationAccountsRoomsGrid = {
    bindings: {
        title: '<',
        onSelect: '&'
    },
    controller: 'AdministrationAccountsRoomsGridController',
    ...gridTpl
};
