import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';
import { getActionTaskLibrary } from '../../events-action-plan.actions';

class EventsActionTaskLibraryGridController extends GridController {
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'events';
        this.identifier = 'eventsActionTaskLibraryGrid';
        this.filterTemplate = './action-task-library-grid-filter.html';
        this.exportComponent = false;

        this.columns = [
            {title: '#',                    mapper: null},
            {title: 'Action Task ID No.',   mapper: 'tsk_no'},
            {title: 'Name',                 mapper: 'task_name'},
            {title: 'Details Of Task',      mapper: 'task_details'}
        ];

        this.buttons = [{label: 'Modify', class: 'btnGridModify', callback: this.onSelect}];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this),
            { getActionTaskLibrary }
        )(this);

        this.dataAction = this.getActionTaskLibrary;
        this.exportService = this.getActionTaskLibrary;
        this.filter.identifier = this.identifier;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const eventsActionTaskLibraryGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onRemove: '&'
    },
    controller: EventsActionTaskLibraryGridController,
    ...gridTpl
};
