export const HeadOfficeFactory = () => {
    return function HeadOffice() {
        this.uuid = null;
        this.name = null;
        this.email = null;
        this.phone = null;
        this.country = null;
        this.fiscalYearStart = 1;
        this.rsc = [];
        this.rso_username = '';
        this.rso_password = '';
        this.rso_password_verify = '';
        this.rso_first_name = '';
        this.rso_last_name = '';
        this.rso_email = '';
        this.rso_phone = '';
        this.rso_fax = '';
        this.contract_date = '';
    };
};
