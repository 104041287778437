/** @module root/components/personal-dosimetry */

import { personalDosimetryState, personalDosimetryDashboardState } from './personal-dosimetry.states';
import { personalDosimetryImportState, personalDosimetryImportDocumentState, personalDosimetryImportDetailsState, personalDosimetryImportDetailsResultState } from './import/personal-dosimetry-import.states';
import { personalDosimetryReportsState, personalDosimetryReportsListingState, personalDosimetryReportsDetailsState, personalDosimetryReportsDetailsResultState } from './reports/personal-dosimetry-reports.states';
import { pdSettingsState, pdSettingsListingState, pdSettingsDetailsState } from './settings/pd-settings.states';
import { personalDosimetry } from './personal-dosimetry.component';
import { personalDosimetryImport } from './import/personal-dosimetry-import.component';
import { personalDosimetryReports } from './reports/personal-dosimetry-reports.component';
import { personalDosimetryReportsDetails } from './reports/details/personal-dosimetry-reports-details.component';
import { personalDosimetryReportsGrid } from './reports/grid/personal-dosimetry-reports-grid.component';
import { personalDosimetryReportsDetailsGrid } from './reports/details/grid/personal-dosimetry-reports-details-grid.component';
import { personalDosimetryReportsDetailsResult } from './reports/details/result/pesonal-dosimetry-reports-details-result.component';
import { personalDosimetrySettings } from './settings/pd-settings.component';
import { personalDosimetrySettingsGrid } from './settings/grid/pd-settings-grid.component';
import { personalDosimetrySettingsDetails } from './settings/details/pd-settings-details.component';
import PersonalDosimetryActions from './personal-dosimetry.actions';

export const PERSONAL_DOSIMETRY_MODULE = angular.module('migration.personal-dosimetry', []);

PERSONAL_DOSIMETRY_MODULE.config(['$uiRouterProvider', ($uiRouter) => {
    const $stateRegistry = $uiRouter.stateRegistry;
    $stateRegistry.register(personalDosimetryState);
    $stateRegistry.register(personalDosimetryDashboardState);
    $stateRegistry.register(personalDosimetryImportState);
    $stateRegistry.register(personalDosimetryImportDocumentState);
    $stateRegistry.register(personalDosimetryImportDetailsState);
    $stateRegistry.register(personalDosimetryImportDetailsResultState);
    $stateRegistry.register(personalDosimetryReportsState);
    $stateRegistry.register(personalDosimetryReportsListingState);
    $stateRegistry.register(personalDosimetryReportsDetailsState);
    $stateRegistry.register(personalDosimetryReportsDetailsResultState);
    $stateRegistry.register(pdSettingsState);
    $stateRegistry.register(pdSettingsListingState);
    $stateRegistry.register(pdSettingsDetailsState);
}]);

PERSONAL_DOSIMETRY_MODULE.service('PersonalDosimetryActions', PersonalDosimetryActions);

PERSONAL_DOSIMETRY_MODULE.component('personalDosimetry', personalDosimetry);
PERSONAL_DOSIMETRY_MODULE.component('personalDosimetryImport', personalDosimetryImport);
PERSONAL_DOSIMETRY_MODULE.component('personalDosimetryReports', personalDosimetryReports);
PERSONAL_DOSIMETRY_MODULE.component('personalDosimetryReportsGrid', personalDosimetryReportsGrid);
PERSONAL_DOSIMETRY_MODULE.component('personalDosimetryReportsDetails', personalDosimetryReportsDetails);
PERSONAL_DOSIMETRY_MODULE.component('personalDosimetryReportsDetailsGrid', personalDosimetryReportsDetailsGrid);
PERSONAL_DOSIMETRY_MODULE.component('personalDosimetryReportsDetailsResult', personalDosimetryReportsDetailsResult);
PERSONAL_DOSIMETRY_MODULE.component('personalDosimetrySettings', personalDosimetrySettings);
PERSONAL_DOSIMETRY_MODULE.component('personalDosimetrySettingsGrid', personalDosimetrySettingsGrid);
PERSONAL_DOSIMETRY_MODULE.component('personalDosimetrySettingsDetails', personalDosimetrySettingsDetails);

PERSONAL_DOSIMETRY_MODULE.run(['$templateCache', ($templateCache) => {
    $templateCache.put('app.personalDosimetry.import.document', '<personal-dosimetry-import></personal-dosimetry-import>');
    $templateCache.put('app.personalDosimetry.import.details', '<personal-dosimetry-reports-details></personal-dosimetry-reports-details>');
    $templateCache.put('app.personalDosimetry.import.detailsResult', '<personal-dosimetry-reports-details-result></personal-dosimetry-reports-details-result>');
    $templateCache.put('app.personalDosimetry.reports.listing', '<personal-dosimetry-reports></personal-dosimetry-reports>');
    $templateCache.put('app.personalDosimetry.reports.details', '<personal-dosimetry-reports-details></personal-dosimetry-reports-details>');
    $templateCache.put('app.personalDosimetry.reports.detailsResult', '<personal-dosimetry-reports-details-result></personal-dosimetry-reports-details-result>');
    $templateCache.put('app.personalDosimetry.settings.listing', '<personal-dosimetry-settings></personal-dosimetry-settings>');
    $templateCache.put('app.personalDosimetry.settings.details', '<personal-dosimetry-settings-details></personal-dosimetry-settings-details>');
    $templateCache.put('./personal-dosimetry-reports-grid-filter.html', require('./reports/grid/personal-dosimetry-reports-grid-filter.html'));
    $templateCache.put('./personal-dosimetry-reports-details-grid-filter.html', require('./reports/details/grid/personal-dosimetry-reports-details-grid-filter.html'));
    $templateCache.put('./personal-dosimetry-reports-details-grid-filter-controls.html', require('./reports/details/grid/personal-dosimetry-reports-details-grid-filter-controls.html'));
    $templateCache.put('./pd-settings-grid-filter.html', require('./settings/grid/pd-settings-grid-filter.html'));
    $templateCache.put('./pd-settings-grid-filter.html', require('./settings/grid/pd-settings-grid-filter.html'));
    $templateCache.put('pd-import', require('./import/presonal-dosimetry-import.html'));
    $templateCache.put('pd-reports', require('./reports/personal-dosimetry-reports.html'));
    $templateCache.put('pd-reports-details', require('./reports/details/personal-dosimetry-reports-details.html'));
    $templateCache.put('pd-settings', require('./settings/pd-settings.html'));
    $templateCache.put('pd-protected', require('./pd-protected.html'));
}]);
