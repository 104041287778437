/**
 * @module root/legacy/components/accreditation
 */
import TreeWidget from 'angular-tree-widget';
import 'angular-recursion';
import 'angular-uuid4';
import { AccreditationController } from './accreditation.controller';
import { AccDiasUploadDocumentCtrl } from './controllers/accreditation-upload-document-controller';
import { AccReferenceDocList } from './controllers/modules/accreditation-reference-doc-list';
import { AccreditationDocumentsModuleController } from './controllers/modules/accreditation-documents-controller';
import { AccreditationApprovalProcessController } from './controllers/modules/accreditation-approval-process';
import { AccDiasMasterDocList } from './controllers/modules/quality-controll-docs/accreditation-master-list';
import { AccDiasQCDocsTrainingMatrix } from './controllers/modules/quality-controll-docs/accreditation-training-matrix';
import { AccIAAuditSchedule } from './controllers/modules/quality-controll-docs/internal-audit/accreditation-audit-schedule';
import { AccIAAuditChecklist } from './controllers/modules/quality-controll-docs/internal-audit/accreditation-audit-checklist';
import { AccIAQualityImprovementReg } from './controllers/modules/quality-controll-docs/internal-audit/accreditation-audit-quality-improvement-reg';
import { AccIAQualityImprovementAddEdit } from './controllers/modules/quality-controll-docs/internal-audit/accreditation-audit-quality-improvement-add-edit';
import { AccIAAuditReport } from './controllers/modules/quality-controll-docs/internal-audit/accreditation-audit-report';
import { AccComplaintsFeedbackReg } from './controllers/modules/quality-controll-docs/complaints_and_feedback/accreditation-complain-feedback-register';
import { AccComplaintFeedbackAdd } from './controllers/modules/quality-controll-docs/complaints_and_feedback/accreditation-complaint-feedback-add';
import { AccComplaintFeedbackAddEdit } from './controllers/modules/quality-controll-docs/complaints_and_feedback/accreditation-complaint-feedback-add-edit';
import { AccTechniqueChartsController } from './controllers/modules/quality-controll-docs/optimization-technique-charts/technique-charts';
import { AccStandardsPanelController } from './controllers/modules/accreditation-standards-panel-controller';

import { AccreditationService } from './services/accreditation-service';
import { AccreditationDocumentsService } from './services/accreditation-documents-service';
import { AccDocumentFactory } from './factories/accreditation-document.factory';
import { TrainingMatrixFactory } from './factories/accreditation-training-matrix.factory';
import { AccAssessmentFactory } from './factories/accreditation-assessment.factory';
import { AccAssessmentItemFactory } from './factories/accreditation-assessment-item.factory';
import { AccAuditCheckListFactory } from './factories/accreditation-audit-schedule-checklist.factory';
import { AccAuditCheckListItemFactory } from './factories/accreditation-audit-schedule-checklist-item.factory';
import { AccAuditScheduleItemFactory } from './factories/accreditation-audit-schedule-item.factory';
import { AccComplaintFeedbackItemFactory } from './factories/accreditation-complaint-feedback.item';
import { AccOptimizedChartDocumentFactory } from './factories/accreditation-optimized-chart-document.factory';
import { AccQualityImprovementItemFactory } from './factories/accreditation-quality-improvement-item';
import { AccStandardFactory } from './factories/accreditation-standard.factory';

import { accApprovalForm } from './directives/acc-approval-form';
import { accAssessmentItemApprovalFormElement } from './directives/acc-assessment-item-approval-form-element';
import { accAssessmentItemFormElement } from './directives/acc-assessment-item-form-element';
import { accStandardsPanel } from './directives/acc-standards-panel';

angular.module(
    'components.accreditation',
    ['TreeWidget']
).controller({
    AccreditationController,
    AccDiasUploadDocumentCtrl,
    AccReferenceDocList,
    AccreditationDocumentsModuleController,
    AccreditationApprovalProcessController,
    AccDiasMasterDocList,
    AccDiasQCDocsTrainingMatrix,
    AccIAAuditSchedule,
    AccIAAuditChecklist,
    AccIAQualityImprovementReg,
    AccIAQualityImprovementAddEdit,
    AccIAAuditReport,
    AccComplaintsFeedbackReg,
    AccComplaintFeedbackAdd,
    AccComplaintFeedbackAddEdit,
    AccTechniqueChartsController,
    AccStandardsPanelController
}).service({
    AccreditationService,
    AccreditationDocumentsService
}).factory({
    AccDocument: AccDocumentFactory,
    TrainingMatrix: TrainingMatrixFactory,
    AccAssessment: AccAssessmentFactory,
    AccAssessmentItem: AccAssessmentItemFactory,
    AccAuditCheckList: AccAuditCheckListFactory,
    AccAuditCheckListItem: AccAuditCheckListItemFactory,
    AccAuditScheduleItem: AccAuditScheduleItemFactory,
    AccComplaintFeedbackItem: AccComplaintFeedbackItemFactory,
    AccOptimizedChartDocument: AccOptimizedChartDocumentFactory,
    AccQualityImprovementItem: AccQualityImprovementItemFactory,
    AccStandard: AccStandardFactory
}).directive({
    accApprovalForm,
    accAssessmentItemApprovalFormElement,
    accAssessmentItemFormElement,
    accStandardsPanel
}).config(['$mdThemingProvider', function ($mdThemingProvider) {
    $mdThemingProvider.theme('accGreen').primaryPalette('green').accentPalette('blue');
}]);
