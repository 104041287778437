/* @ngInject */
export function SelectEquipmentRequirementsTypeController($ngRedux, EquipmentActions) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, EquipmentActions)(this);
        ctrl.getRequirementTypes(ctrl.identifier, this.equipment_type);
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            $ngRedux.dispatch({
                type: 'EQUIPMENT_REQUIREMENT_TYPE_SELECTED',
                id: ctrl.identifier,
                requirementType: ctrl.selectedOption
            });
        }
    };

    function mapStateToThis(state) {
        return {
            equipment_type: state.equipment[ctrl.identifier].filter.type,
            options: state.equipment[ctrl.identifier].requirementTypes,
            selectedOption: state.equipment[ctrl.identifier].filter.requirementType
        };
    }
}
