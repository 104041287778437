import { AP_REQUEST_RESET } from '../applications-permits.actions.types';

export const apNuclearEnergyRequestPublicState = {
    name: 'public.applicationsPermits.nuclearEnergy.request',
    redirectTo: 'public.applicationsPermits.nuclearEnergy.request.details',
    url: '/request',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: AP_REQUEST_RESET,
            id: 'rmLaserRequest'
        });
    }
};

export const apNuclearEnergyRequestIntroPublicState = {
    name: 'public.applicationsPermits.nuclearEnergy.request.intro',
    url: '?token&invite',
    component: 'apLaserRequestIntro'
};
