angular
    .module('components.service-contracts')
    .component('serviceContracts', {
        template: require('./service-contracts.html'),
        controller: 'ServiceContractsController'
    })
    .config(function($stateProvider) {
        $stateProvider
            .state('app.serviceContracts', {
                abstract: true,
                url: '/service-contracts'
            })
            .state('app.serviceContracts.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.serviceContracts.add', {
                url: '/add',
                template: require('../../components.html'),
                controller: 'ServiceContractsAddController'
            })
            .state('app.serviceContracts.manage', {
                url: '/manage',
                template: require('../../components.html'),
                controller: 'ServiceContractsManageController'
            })
            .state('app.serviceContracts.vendors', {
                url: '/vendors',
                component: 'serviceContracts'
            })
            .state('app.serviceContracts.vendorsEngineersLicensing', {
                url: '/vendors-engineers-licensing',
                component: 'serviceContracts'
            })
            .state('app.serviceContracts.reports', {
                url: '/reports',
                component: 'serviceContracts'
            });
    });
