/* @ngInject */
export function AccreditationService($http, ACC_STANDARD_TYPE, API_BASE,
                              AccStandard, AccAssessment, AccAssessmentItem, $q, Helpers,
                              ACC_ACCREDITATION_CODE, ACC_ACCREDITATION_DOC_URI, $state) {

    var _this = this;

    function getStandardItem(standards, id) {
        for (var i = 0; i < standards.length; i++) {
            if (standards[i].standardId === id) {
                return standards[i];
            }
        }
        return null;
    }


    this.getCurrentAccreditationFromState = function() {
        var currentState = $state.current.name;
        var accreditation = ACC_ACCREDITATION_CODE.NONE;

        if (currentState.indexOf("accreditation.NSQHS2") >=0 ) {
            accreditation = ACC_ACCREDITATION_CODE.NSQHS2;
        } else if (currentState.indexOf("accreditation.NSQHS") >=0 ) {
            accreditation = ACC_ACCREDITATION_CODE.NSQHS;
        } else if (currentState.indexOf("accreditation.DIAS") >=0 ) {
            accreditation = ACC_ACCREDITATION_CODE.DIAS;
        } else if (currentState.indexOf("accreditation.ACQS") >=0 ) {
            accreditation = ACC_ACCREDITATION_CODE.ACQS;
        }

        return accreditation;
    }

    this.getCurrentAccreditationPathFromState = function() {
        var currentState = $state.current.name;
        var accreditationPath = ACC_ACCREDITATION_DOC_URI.NONE;

        if (currentState.indexOf("accreditation.NSQHS2") >=0 ) {
            accreditationPath = ACC_ACCREDITATION_DOC_URI.NSQHS2;
        } else if (currentState.indexOf("accreditation.NSQHS") >=0 ) {
            accreditationPath = ACC_ACCREDITATION_DOC_URI.NSQHS;
        } else if (currentState.indexOf("accreditation.DIAS") >=0 ) {
            accreditationPath = ACC_ACCREDITATION_DOC_URI.DIAS;
        } else if (currentState.indexOf("accreditation.ACQS") >=0 ) {
            accreditationPath = ACC_ACCREDITATION_DOC_URI.ACQS;
        }

        return accreditationPath;
    }


    /**
     * Method   - GET
     * Endpoint - /accreditation/<accreditation-code>/standards
     * Params   - accreditation shema code, uuid of site location
     * Response - Object where data.standards is the actual standards object tree
     *
     * */
    this.fetchAccShemaStandards = function (accCode, slocUuid) {
        var params = [];
        params['slocUuid'] = slocUuid;

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/standards",
            params: params,
            method: "GET"
        }).then(function successCallback(response) {
                var tempStandardsArray = [];
                var standards = {};
                response.data.list.forEach(function (item) {
                    var newStandard = new AccStandard();
                    newStandard.standardId = item.ast_id;
                    newStandard.accreditationId = item.acc_id;
                    newStandard.parentId = item.par_id;
                    newStandard.number = item.number;
                    newStandard.name = item.name;
                    newStandard.description = item.description;
                    newStandard.type = item.type;
                    if ((item.template_documents != null) && (item.template_documents != "")) {
                        newStandard.templateDocuments = item.template_documents.split(",");
                    }
                    tempStandardsArray.push(newStandard);
                });

                //Now, make a tree of standards/substandards and subsubstandards
                //If time, make this more beautiful with some recursion function
                tempStandardsArray.forEach(function (item) {
                    if (item.type == ACC_STANDARD_TYPE.STANDARD) {
                        standards[item.standardId] = item;
                    } else if (item.type == ACC_STANDARD_TYPE.SUB_STANDARD) {
                        if (standards[item.parentId] == null) {
                            standards[item.parentId] = getStandardItem(tempStandardsArray, item.parentId);
                        }
                        standards[item.parentId].children[item.standardId] = item;
                    } else if (item.type == ACC_STANDARD_TYPE.SUB_SUB_STANDARD) {
                        var parentSubStandard = getStandardItem(tempStandardsArray, item.parentId);
                        if (standards[parentSubStandard.parentId] == null) {
                            standards[parentSubStandard.parentId] = getStandardItem(tempStandardsArray, parentSubStandard.parentId);
                        }
                        if (standards[parentSubStandard.parentId].children[parentSubStandard.standardId] == null) {
                            standards[parentSubStandard.parentId].children[parentSubStandard.standardId] = item;
                        }
                        standards[parentSubStandard.parentId].children[parentSubStandard.standardId].children[item.standardId] = item;
                    };
                });
                response.data.standards = standards;
                return response;
            },
            function errorCallback(errorResponse) {
                return $q.reject(errorResponse);
            });
    };

    /**
     * Use standards object return with fetchAccShemaStandards,
     * create simplified tree of standards/substandards.
     * Result of this function is meant to be used in
     * TreeView component.
     *
     * */
    this.createAccreditationSchemaTree = function (accStd) {
        var treeNodes = [];

        for (var stdNum in accStd) {
            var currTreeNode = {};
            currTreeNode.standardId = accStd[stdNum].standardId;
            currTreeNode.name = accStd[stdNum].number + " " + accStd[stdNum].name;
            currTreeNode.number = accStd[stdNum].number;
            currTreeNode.expanded = false;
            if (!(angular.equals({}, accStd[stdNum].children))) {
                currTreeNode.children = this.createAccreditationSchemaTree(accStd[stdNum].children);
            } else {
                currTreeNode.children = null;
            }
            treeNodes.push(currTreeNode);
        }
        return treeNodes;
    }



    /**
     * Method   - GET
     * Endpoint - /accreditation/<accreditation-code>/assessments
     * Params   - accreditation shema code, uuid of site location
     * Response - Object where data.assessments for the site location is the actual assessments array list
     *
     * */
    this.fetchAccShemaAssessments = function (accCode, slocUuid, year) {
        var params = [];
        params['slocUuid'] = slocUuid;

        if (!year)
            year = new Date().getFullYear();

        params['year'] = year;

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/assessments",
            params: params,
            method: "GET"
        }).then(function successCallback(response) {
                var assessments = {};
                response.data.list.forEach(function (item) {
                    if (assessments[item.aas_id] == null) {
                        var newAssessment = new AccAssessment();
                        newAssessment.id = item.aas_id;
                        newAssessment.standardId = item.ast_id;
                        newAssessment.standardNumber = item.number;
                        assessments[newAssessment.id] = newAssessment;
                    }

                    var newAssessmentItem = new AccAssessmentItem();
                    newAssessmentItem.id = item.aai_id;
                    newAssessmentItem.assessmentId = item.aas_id;
                    newAssessmentItem.standardId = item.ast_id;
                    newAssessmentItem.assessmentItemType = item.aas_type;
                    newAssessmentItem.request = item.request;
                    newAssessmentItem.assessmentItemDocumentType = item.doc_type;

                    assessments[item.aas_id].assessmentItems.push(newAssessmentItem);
                });

                response.data.assessments = assessments;
                return response;
            },
            function errorCallback(errorResponse) {
                return $q.reject(errorResponse);
            });
    };


    /**
     * Method   - POST
     * Endpoint - /accreditation/<accreditation-code>/assessment
     * Params   -
     *      ** accreditation shema code (string)
     *      ** uuid of site location (string)
     *      ** id of sub-sub-standard (int)
     *      ** new assessment data/values for that sub-sub-standard (AccAssessment)
     * Response - object with status
     *
     * */
    this.saveAccreditationAssessment = function (accCode, siteLocationUuid, standardId, assessment, year, submit) {

        var result = {
            id: assessment.id,
            value: assessment.value,
            comment: assessment.comment,
            assessmentItems: []
        }

        for (var i = 0; i < assessment.assessmentItems.length; i++) {
            result.assessmentItems.push(
                {
                    id: assessment.assessmentItems[i].id,
                    assessmentItemType: assessment.assessmentItems[i].assessmentItemType,
                    value: assessment.assessmentItems[i].value
                });
        }

        if (!year)
            year = new Date().getFullYear();

        var ldata = {
            siteLocationUuid: siteLocationUuid,
            standardId: standardId,
            year: year,
            assessmentId: assessment.id,
            assessmentJson: JSON.stringify(result),
            submit: submit
        };

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/assessment",
            data: ldata,
            method: "POST"
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };


    /** Retrieve actual user provided data/documents for accreditation
     *
     * Method   - GET
     * Endpoint - /accreditation/<accreditation-code>/assessment-value
     * Params   -
     *      ** accreditation shema code (string)
     *      ** uuid of site location (string)
     *      ** id of sub-sub-standard assessment (int)
     * Response - Object (parsed JSON) with data saved with saveAccreditationAssessment
     *
     * */
    this.fetchAccShemaAssessmentValue = function (accCode, siteLocationUuid, assessmentId, year) {
        var params = [];
        params['siteLocationUuid'] = siteLocationUuid;
        params['assessmentId'] = assessmentId;

        if (!year)
            year = new Date().getFullYear();

        params['year'] = year;

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/assessment-value",
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                if ((response.data.list[0]) && (response.data.list[0].value != null)) {
                    response.data.result = JSON.parse(response.data.list[0].value);
                }

                return response;
            },
            function errorCallback(errorResponse) {
                return $q.reject(errorResponse);
            });
    };

    /** Retrieve progress for requested standard and its sub standards
     *
     * Method   - GET
     * Endpoint - /accreditation/<accreditation-code>/progress
     * Params   -
     *      ** accreditation shema code (string)
     *      ** uuid of site location (string)
     *      ** id of standard (int)
     * Response - Object (parsed JSON) with progress data for whole standard/sub-standard hierarchy
     *
     * */
    this.fetchAccShemaStandardProgress = function (accCode, siteLocationUuid, standardId, year) {
        var params = [];

        if (!year) {
            year = new Date().getFullYear();
        }

        params['siteLocationUuid'] = siteLocationUuid;
        params['standardId'] = standardId;
        params['year'] = year;

        return $http({
            url: API_BASE + "/accreditation/" + accCode + "/progress",
            params: params,
            method: "GET"
        }).then(function successCallback(response) {
                if ((response.data.json) && (response.data.json[0].out_json_progress != null)) {
                    response.data.result = JSON.parse(response.data.json[0].out_json_progress);
                }

                return response;
            },
            function errorCallback(errorResponse) {
                return $q.reject(errorResponse);
            });
    };

}
