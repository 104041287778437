import { GridController, gridTpl } from '../../../../common/grid/grid.controller';

class ReportingEquipmentInspectionGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, ReportingActions, EquipmentService, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.ReportingActions = ReportingActions;
        this.EquipmentService = EquipmentService;
        this.grid_filter = $filter
    }

    $onInit() {
        this.module = 'reporting';
        this.identifier = 'reportingEquipmentInspectionGrid';
        this.filterTemplate = './reporting-equipment-inspection-grid-filter.html';
        this.exportService = this.EquipmentService.filterInspections;

        this.columns = [
            {title: '#',              mapper: null},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_COL_SITE_LOCATION") ,  mapper: 'account'},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_COL_CATEGORY") ,       mapper: 'category'},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_COL_MANUFACTURER") ,   mapper: 'manufacturer'},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_COL_MODEL") ,          mapper: 'model'},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_COL_SERIAL_NUMBER") ,  mapper: 'serial'},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_COL_REPORT_TYPE") ,    mapper: 'file-download'},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_COL_INSPECTED") ,      mapper: 'getInspectedDate'},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_COL_RESULT") ,         mapper: 'state'},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_COL_EXPIRES") ,        mapper: 'getExpiryDate'},
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.ReportingActions)(this);
        this.dataAction = this.getEquipmentInspections;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

ReportingEquipmentInspectionGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'ReportingActions', 'EquipmentService', '$filter'];

export const reportingEquipmentInspectionGrid = {
    bindings: {
        identifier: '@',
        onReset: '&'
    },
    controller: ReportingEquipmentInspectionGridController,
    ...gridTpl
};
