import { TabsReducer } from '../../../common/tabs/tabs.reducer';
import {IDS} from './pnp-accred-action-id.constants';
export const pnpAccredTabs = {
    'app.pnp.accred': Object.assign(TabsReducer(), {
        tabs: [
            {
                state: IDS.app_pnp_accred_list,
                title: 'PNP.ACCRED.LIST.TAB',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Add New Document'}
                }
            },
            {
                state: IDS.app_pnp_accred_details,
                title: 'PNP.ACCRED.DETAILS.TAB',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: IDS.app_pnp_accred_add,
                title: 'PNP.ACCRED.DOCUMENT_ADD.TAB',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Add New Document'}
                }
            },
        ],
    }),
};
