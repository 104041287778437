/* @ngInject */
export function SelectEquipmentDocumentTypeController($ngRedux, DocumentService) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, {})(this);
        ctrl.options = [];

        DocumentService.fetchEquipmentDocumentTypes(ctrl.equipmentId).then(function(items) {
            ctrl.options = items;
        });
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            $ngRedux.dispatch({
                type: 'EQUIPMENT_DOCUMENT_TYPE_SELECTED',
                id: 'equipmentDocumentsGrid',
                documentType: ctrl.selectedOption
            });
        }
    };

    function mapStateToThis(state) {
        return {
            selectedOption: state.equipment[ctrl.identifier].filter.documentTypeId,
            equipmentId: state.equipment[ctrl.identifier].filter.equipmentId
        };
    }
}
