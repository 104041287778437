import * as RmActions from '../../radioactive-materials.actions';
import * as RmTestActions from '../rm-test.actions';
import moment from "moment/moment";

/**
 * @const {object}
 * @memberOf module:RadioactiveMaterials
 */
export const rmTestWipeTest = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $scope, $rootScope, $mdDialog, FileActions, Notifier) {
        const unsubscribe = $ngRedux.connect(({ radioactiveMaterials, app }) => ({
            rmTest: radioactiveMaterials.rmTest,
            rmTestWipeTest: radioactiveMaterials.rmTestWipeTest,
            user: app.user
        }), {
            ...FileActions,
            ...RmActions,
            ...RmTestActions
        })(this);

        /**
         * @function $onInit
         */
        this.$onInit = () => {
            // TODO
            this.identifier = 'app.radioactiveMaterials.test.manage';
            this.state = 'app.radioactiveMaterials.test.manage.wipeTest';

            //this.disabled = !!this.rmTestWipeTest.wipeTestId;
            this.disabled = false;

            this.showCalculationBlock();
            this.calculateResult();
        };

        this.onWipeTestSystem = () => {
            this.showCalculationBlock();
            this.backgroundCountResult = null;
            this.measuredCountReuslt = null;
            this.calculatedResult = null;
            this.calculatedDifference = null;
            this.formatedResult = null;
            if (this.rmTestWipeTest.wipeTestSystem === 0 && (this.rmTest.isotopeName === 'Gd' || this.rmTest.isotopeName === 'Ge' || this.rmTest.isotopeName === 'Sr')) {
                Notifier.warn('Capton 3000 device does not have a selected isotope, Please use Beckman coulter LSC.');
            }
            $ngRedux.dispatch({
                id: 'rmTestWipeTest',
                type: 'RM_RESET_WIPE_TEST_CALCULATION_INPUT'
            });
            $ngRedux.dispatch({
                id: 'rmTestWipeTest',
                type: 'RM_RESET_WIPE_TEST_CALCULATION'
            });
        };

        this.calculateResult = () => {
            this.calculatedResult = null;
            this.calculatedDifference = null;
            this.formatedResult = null;
            if (this.rmTestWipeTest.measuredCount) { this.measuredCountReuslt = this.rmTestWipeTest.measuredCount / 120; }
            if (this.rmTestWipeTest.backgroundCount) { this.backgroundCountResult = this.rmTestWipeTest.backgroundCount / 120; }
            if (this.rmTestWipeTest.measuredCount && this.rmTestWipeTest.backgroundCount) {
                if (this.rmTestWipeTest.wipeTestSystem == 0) {
                    this.calculatedResult = parseFloat(((this.rmTestWipeTest.measuredCount / 120) - (this.rmTestWipeTest.backgroundCount / 120)) / this.rmTestWipeTest.efficiency.efficiencyCaptus).toFixed(2);
                } else if (this.rmTestWipeTest.wipeTestSystem == 1) {
                    this.calculatedResult = parseFloat(((this.rmTestWipeTest.measuredCount / 120) - (this.rmTestWipeTest.backgroundCount / 120)) / this.rmTestWipeTest.efficiency.efficiencyLsc * 10).toFixed(2);
                }
                this.calculatedDifference = ((this.rmTestWipeTest.measuredCount / 120) - (this.rmTestWipeTest.backgroundCount / 120)).toFixed(3);
                this.formatedResult = parseFloat(this.calculatedResult / 100000000).toFixed(10);
                $ngRedux.dispatch({
                    id: 'rmTestWipeTest',
                    type: 'RM_WIPE_TEST_CALCULATE_RESULT',
                    path: 'calculatedResult',
                    input: this.calculatedResult
                });
                $ngRedux.dispatch({
                    id: 'rmTestWipeTest',
                    type: 'RM_WIPE_TEST_CALCULATE_DIFFERENCE',
                    path: 'difference',
                    input: this.calculatedDifference
                });
            } else {
                $ngRedux.dispatch({
                    id: 'rmTestWipeTest',
                    type: 'RM_RESET_WIPE_TEST_CALCULATION'
                });
            }
        };

        this.showCalculationBlock = () => {
            this.showCalculation = false;
            if ((this.rmTestWipeTest.wipeTestSystem == 0 && this.rmTestWipeTest.efficiency.efficiencyCaptus)
                || (this.rmTestWipeTest.wipeTestSystem == 1 && this.rmTestWipeTest.efficiency.efficiencyLsc)) {
                this.showCalculation = true;
            }
        };

        this.onMeasuredActivityWithin = ({ input }) => {
            if (input === 0) {
                const confirm = $mdDialog.confirm()
                    .title('Alert')
                    .textContent('Please check instrument contamination and notify radiation safety officer')
                    .ariaLabel('Alert')
                    .ok('OK');
                this.dialog = $mdDialog.show(confirm).then(() => {
                }, angular.noop);
            }
        };

        this.onPrintWipeTest = () => {
            this.printWipeTest(this.rmTest);
        };

        this.saveDetails = () => {
            $scope.$broadcast('show-errors-check-validity', '$ctrl.rmTestWipeTestForm');
            if (this.rmTestWipeTestForm.$valid) {
                this.saveTestWipeTest(this.rmTest.id, this.rmTestWipeTest).then(() => {
                    Notifier.success('Leak test has been successfully saved');
                    this.disabled = true;

                    let dueDate = null;
                    switch (this.rmTest.useType) {
                        case 'utpinu':
                            dueDate = moment(this.rmTestWipeTest.approvalDate).add(6, 'months').calendar();
                            break;
                        case 'utpind':
                            dueDate = moment(this.rmTestWipeTest.approvalDate).add(12, 'months').calendar();
                            break;
                        case 'utpins':
                            dueDate = moment(this.rmTestWipeTest.approvalDate).add(24, 'months').calendar();
                            break;
                    }
                    let dueDateObject = new Date(dueDate);
                    $ngRedux.dispatch({
                        id: 'rmTest',
                        type: 'DATE_SELECTED',
                        path: 'wipeTestingDueDate',
                        input: dueDateObject
                    });
                    this.saveTest(this.rmTest).then(() => {
                        this.printWipeTest(this.rmTest);
                    });
                }).catch(() => {
                    Notifier.error('System Error!');
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./rm-test-wipe-test.html')
};
