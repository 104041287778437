import { TAB_SHOW, TAB_HIDE } from '../../../../common/tabs/tabs.constants';
import { generateQRCode } from '../../../../common/utils/util.qrcode';
import moment from 'moment';

/**
 * Creates a new EquipmentCalibrationDetailsController.
 * @class
 */
class EquipmentCalibrationDetailsController {
    constructor($ngRedux, $state, $scope, $mdDialog, $timeout, NavigationService, Notifier, EquipmentCalibrationActions, FileActions) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        this.$timeout = $timeout;
        this.NavigationService = NavigationService;
        this.Notifier = Notifier;
        this.actions = {...EquipmentCalibrationActions, ...FileActions};
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);

        if (this.equipment.id === null) {
            this.identifier = 'app.equipmentCalibration.add';
            this.state = 'app.equipmentCalibration.add.details';
            this.getNextId(74).then((id) => {
                this.$ngRedux.dispatch({
                    type: 'EQUIPMENT_NEXT_ID',
                    id: 'equipment',
                    visualId: id
                });
                generateQRCode( 'qrcode', {
                    id,
                    action: 'equipment',
                });
            });
        }
        else {
            this.identifier = 'app.equipmentCalibration.manage';
            this.state = 'app.equipmentCalibration.manage.details';
            generateQRCode('qrcode', {
                id: this.equipment.visualId,
                action: 'equipment',
            });
        }

        if (this.equipment.nextServiceDueDate) {
            this.$ngRedux.dispatch({
                type: 'CHECKBOX_SELECTED',
                id: 'equipment',
                checkbox: 1,
                path: 'annualService',
                data: {annualService: 1}
            });
        }

        if (this.equipment.nextCalibrationDueDate) {
            this.$ngRedux.dispatch({
                type: 'CHECKBOX_SELECTED',
                id: 'equipment',
                checkbox: 1,
                path: 'annualCalibration',
                data: {annualCalibration: 1}
            });
        }

        if (this.equipment.nextElectricalTagTestingDueDate) {
            this.$ngRedux.dispatch({
                type: 'CHECKBOX_SELECTED',
                id: 'equipment',
                checkbox: 1,
                path: 'electricalTagTesting',
                data: {electricalTagTesting: 1}
            });
        }

        this.getTypes('equipment');
        this.getManufacturers();
    }

    createType() {
        const createTypeCtrl = ($scope) => {
            $scope.headerColor = this.colorName;
            $scope.save = () => {
                if ($scope.modelForm.$valid) {
                    this.addType('cal', $scope.typeName).then(() => {
                        this.getTypes('equipment');
                    }).catch((err) => {
                        this.Notifier.error('Existing Type, please select type from dropdown');
                    });
                    this.$mdDialog.hide();
                }
            };
            $scope.cancel = () => {
                this.$mdDialog.cancel();
            };
        };
        createTypeCtrl.$inject = ['$scope'];

        this.$mdDialog.show({
            controller: createTypeCtrl,
            template: require('./dialogs/equipment-calibration-type-dialog.html'),
            parent: angular.element(document.body),
            locals: {equipment: this.equipment},
            clickOutsideToClose: true
        }).then(() => {}, angular.noop);
    }

    onManufacturer = (event) => {
        this.getModels('equipment', this.equipment.type, this.equipment.manufacturer);
    };

    createManufacturer() {
        const createManufacturerCtrl = ($scope) => {
            $scope.headerColor = this.colorName;
            $scope.save = () => {
                if ($scope.modelForm.$valid) {
                    this.addManufacturer('cal', $scope.manufacturerName).then(() => {
                        this.getManufacturers();
                    }).catch((err) => {
                        this.Notifier.error('Existing Manufacturer, please select manufacturer from dropdown');
                    });
                    this.$mdDialog.hide();
                }
            };
            $scope.cancel = () => {
                this.$mdDialog.cancel();
            };
        };
        createManufacturerCtrl.$inject = ['$scope'];

        this.$mdDialog.show({
            controller: createManufacturerCtrl,
            template: require('./dialogs/equipment-calibration-manufacturer-dialog.html'),
            parent: angular.element(document.body),
            locals: {equipment: this.equipment},
            clickOutsideToClose: true
        }).then(() => {}, angular.noop);
    }

    createModel() {
        const createModelCtrl = ($scope) => {
            $scope.headerColor = this.colorName;
            $scope.save = () => {
                if ($scope.modelForm.$valid) {
                    this.addModel('equipment', {
                        manufacturer: this.equipment.manufacturer,
                        type: this.equipment.type,
                        name: $scope.model.name,
                        description: ''
                    }).catch((err) => {
                        this.Notifier.error('Existing Model, please select model from dropdown');
                    });
                    this.$mdDialog.hide();
                }
            };
            $scope.cancel = () => {
                this.$mdDialog.cancel();
            };
        };
        createModelCtrl.$inject = ['$scope'];

        this.$mdDialog.show({
            controller: createModelCtrl,
            template: require('./dialogs/equipment-calibration-model-dialog.html'),
            parent: angular.element(document.body),
            locals: {equipment: this.equipment},
            clickOutsideToClose: true
        }).then(() => {}, angular.noop);
    }

    onInstallationDate = (event) => {
        const {expiryDate} = this.equipment;
        if (expiryDate === null) {
            this.$ngRedux.dispatch({
                type: 'DATE_SELECTED',
                id: 'equipment',
                data: {expiryDate: moment(event.input).add(10, 'years').toDate()}
            });
        }
    };

    onAnnualService = (event) => {
        this.$ngRedux.dispatch({
            type: 'DATE_SELECTED',
            id: 'equipment',
            data: {
                nextServiceDueDate: event.input ? moment().add(12, 'months').toDate() : null,
            }
        });
    };

    onAnnualCalibration = (event) => {
        this.$ngRedux.dispatch({
            type: 'DATE_SELECTED',
            id: 'equipment',
            data: {
                nextCalibrationDueDate: event.input ? moment().add(12, 'months').toDate() : null
            }
        });
    };

    onElectricalTagTesting = (event) => {
        this.$ngRedux.dispatch({
            type: 'DATE_SELECTED',
            id: 'equipment',
            data: {
                nextElectricalTagTestingDueDate: event.input ? moment().add(12, 'months').toDate() : null
            }
        });
    };

    onDetector = (event) => {
        if (event.input == 1) {
            this.$ngRedux.dispatch({
                type: TAB_SHOW,
                id: 'app.equipmentCalibration.manage',
                state: 'app.equipmentCalibration.manage.detectorProbe'
            });
        } else {
            this.$ngRedux.dispatch({
                type: TAB_HIDE,
                id: 'app.equipmentCalibration.manage',
                state: 'app.equipmentCalibration.manage.detectorProbe'
            });
        }
    };

    manageEquipment() {
        this.$scope.$broadcast('show-errors-check-validity', '$ctrl.equipmentForm');
        if (this.equipmentForm.$valid) {
            if (this.equipment.id) {
                this.editEquipment(this.equipment).then(() => {
                    this.Notifier.success('Equipment changes has been successfully saved');
                });
            }
            else {
                this.addEquipment(this.equipment).then(() => {
                    this.Notifier.success('New Equipment has been successfully added');

                    let nextTab = 'app.equipmentCalibration.add.assetCost';
                    let tabs = [
                        'app.equipmentCalibration.add.assetCost',
                        'app.equipmentCalibration.add.documents',
                        'app.equipmentCalibration.add.vendors'
                    ];

                    switch (this.equipment.typeCode) {
                        case 'asm':
                            if (this.equipment.detector) {
                                nextTab = 'app.equipmentCalibration.add.detectorProbe';
                                tabs = [...tabs, nextTab];
                            }
                            break;
                        case 'mfm':
                        case 'dom':
                            nextTab = 'app.equipmentCalibration.add.detectors';
                            tabs = [...tabs, nextTab];
                            break;
                        case 'dca':
                        case 'wco':
                            nextTab = 'app.equipmentCalibration.add.chambers';
                            tabs = [...tabs, nextTab];
                            break;
                    }

                    this.$ngRedux.dispatch({
                        type: TAB_SHOW,
                        id: 'app.equipmentCalibration.add',
                        state: tabs
                    });
                    this.$timeout(() => {
                        this.$ngRedux.dispatch({
                            type: 'TAB_SELECT',
                            id: 'app.equipmentCalibration.add',
                            state: nextTab
                        });
                    }, 200);
                });
            }
        }
        else this.Notifier.error('Please fill in the form fields');
    }

    onPrintQRcode = () => {
        this.printQRcode(this.equipment.id, this.equipment.visualId, 'equipment');
    };

    onScanSuccess = (event) => {
        const scanCtrl = ($scope) => {
            $scope.headerColor = this.colorName;
            $scope.scan = event.value;

            $scope.cancel = () => {
                this.$mdDialog.cancel();
            };
        };
        scanCtrl.$inject = ['$scope'];

        this.$mdDialog.show({
            controller: scanCtrl,
            templateUrl: './equipment-calibration-scanner-dialog.html',
            parent: angular.element(document.body),
            clickOutsideToClose: true
        }).then(() => {}, angular.noop);
    };

    onSaveImage = async (event) => {
        const { filePath, width, height } = event[0];
        return await this.saveImage(filePath, width, height);
    };

    mapState(state) {
        return {
            equipment: state.equipmentCalibration.equipment
        }
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

EquipmentCalibrationDetailsController.$inject = ['$ngRedux', '$state', '$scope', '$mdDialog', '$timeout', 'NavigationService', 'Notifier', 'EquipmentCalibrationActions', 'FileActions'];

export const equipmentCalibrationDetails = {
    controller: EquipmentCalibrationDetailsController,
    template: require('./equipment-calibration-details.html')
};
