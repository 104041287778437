import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import { getLicences } from '../administration-accounts.actions';

class AdministrationAccountsLicencesGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'administration';
        this.autoload = false;
        this.filterComponent = false;

        this.columns = [
            {title: '#',                mapper: null},
            {title: '',                 mapper: 'alert'},
            {title: 'Head Office',      mapper: 'headOffice'},
            {title: 'Site Location',    mapper: 'username'},
            {title: 'Licence number',   mapper: 'regIdNo'},
            {title: 'Licence type',     mapper: 'type.name'},
            {title: 'Issued',           mapper: 'issued'},
            {title: 'Expires',          mapper: 'expires'},
            {title: 'Document',         mapper: 'file-download'}
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this),
            {getLicences}
        )(this);

        this.dataAction = this.getLicences;
        this.exportService = this.getLicences;
        this.filter.identifier = this.identifier;
        this.filter.searchDescendants = true;
        this.filter.licenceCode = 'loc';

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const administrationAccountsLicencesGrid = {
    bindings: {
        identifier: '@',
        title: '<'
    },
    controller: AdministrationAccountsLicencesGridController,
    ...gridTpl
};

export const administrationAccountsLicencesGridFilter = {
    uuid: null,
    licenceCode: null,
    searchDescendants: null
};
