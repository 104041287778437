import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import { getEmployeeTrainings } from '../employees-training.actions';

/**
 * Creates a new employeesTrainingsGridController.
 * @class
 * @memberOf module:root/legacy/components/employees
 */
class employeesTrainingsGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'employees';
        this.identifier = 'employeeTrainingsGrid';
        this.filterTemplate = './employees-trainings-grid-filter.html';
        this.exportComponent = false;

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), {
                getEmployeeTrainings
            }
        )(this);

        this.columns = [
            {title: '#',                mapper: null},
            {title: 'Review ID',        mapper: 'review_id'},
            {title: 'Review Name',      mapper: 'review_name'},
            {title: 'Start Date',       mapper: 'date_started'},
            {title: 'End Date',         mapper: 'date_finished'},
            {title: 'Next Section',     mapper: 'next_section'},
            {title: 'Due Date',         mapper: 'due_date'}
        ];

        this.dataAction = this.getEmployeeTrainings;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    };
}

export const employeesTrainingsGrid = {
    controller: employeesTrainingsGridController,
    ...gridTpl
};
