import InputController from '../../form/input/input.controller';
import { API_BASE } from '../../utils/util.constants';
import { FILE_UPLOAD } from '../file.constants';
import watch from 'redux-watch';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';
import get from 'lodash/get';
import set from 'lodash/set';

/**
 * Creates a new FileUploadController.
 * @class
 * @extends module:root/common/form.InputController
 * @memberOf module:root/common/form
 */
class FileUploadController extends InputController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $http {service} {@link https://docs.angularjs.org/api/ng/service/$http|AngularJS $http}
     * @param FileUploader {service} {@link https://github.com/nervgh/angular-file-upload|angular-file-upload}
     * @param FileActions {service}
     * @param Notifier {service} {@link Notifier}
     */
    constructor($ngRedux, $http, FileUploader, FileActions, Notifier) {
        super($ngRedux);
        this.$http = $http;
        this.FileUploader = FileUploader;
        this.actions = FileActions;
        this.Notifier = Notifier;
    }

    /**
     * @method $onInit
     * @memberof module:root/common/form.FileUploadController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.init();

        if (typeof this.inputId === 'undefined')
            throw new Error('Missing input-id attribute (' + this.name + ')');

        if (typeof this.label === 'undefined')
            this.label = 'Add Document';

        if (typeof this.btnClass === 'undefined')
            this.btnClass = 'btnDefault';

        if (typeof this.validate === 'undefined')
            this.validate = false;

        if (typeof this.validType === 'undefined')
            this.validType = null;

        if (typeof this.disabled === 'undefined')
            this.disabled = false;

        if (this.input)
            this.downloadBtn = true;

        const watchUpload = watch(this.$ngRedux.getState, this.getPath());
        this.onUpload = this.$ngRedux.subscribe(watchUpload(filePath => {
            if (filePath) {
                this.icon = this.iconDone;
                this.downloadBtn = true;
            }
            else {
                this.icon = this.iconUpload;
                this.downloadBtn = false;
            }
        }));

        this.saveDisabled = true;
        this.options = {
            url: API_BASE + '/file/stream',
            alias: 'upstream',
            queueLimit: 1,
            headers: {
                'X-Authorization': this.$http.defaults.headers.common['X-Authorization'],
                'X-Token': this.$http.defaults.headers.common['X-Token']
            }
        };

        this.uploader = new this.FileUploader({
            queueLimit: 1,
            autoUpload: true,
            onSuccessItem: (item, response, status, headers) => {
                item.filePath = response.path;
                item.type = get(response, 'type', null);
                item.width = get(response, 'width', null);
                item.height = get(response, 'height', null);
                angular.element(document.getElementById(this.inputId))[0].value = '';
                this.saveDisabled = false;
                this.input = item.filePath;
                this.width = item.width;
                this.height = item.height;
                this.downloadBtn = false;
            },
            onBeforeUploadItem: item => {
                item.filePath = null;
                item.type = null;
                item.width = null;
                item.height = null;
                this.saveDisabled = true;
            },
            onCancelItem: () => {
                this.saveDisabled = false;
                this.input = null;
                this.width = null;
                this.height = null;
            },
            onErrorItem: (item, response, status, headers) => {
                this.Notifier.error(response);
                item.filePath = null;
                item.type = null;
                item.width = null;
                item.height = null;
                this.saveDisabled = true;
                this.input = null;
                this.width = null;
                this.height = null;
            },
        });

        if (this.validType) {
            this.uploader.filters.push({
                name: 'typeFilter',
                fn: item => {
                    const types = this.validType.split(',');
                    if (types.indexOf(item.name.split('.').pop()) === -1) {
                        this.Notifier.error('Invalid document type!');
                        return false;
                    }
                    else return true;
                }
            });
        }
    }

    validation() {
        if (this.validate && this.form.$invalid) {
            each(this.form.$error, function(field) {
                each(field, errorField => {
                    errorField.$setTouched();
                });
            });
            this.Notifier.error('Please fill in the form fields');
            return false;
        }
        return true;
    };

    /**
     * @method uploadFile
     * @memberof module:root/common/form.FileUploadController
     */
    uploadFile() {
        if (this.uploader.queue.length > 0)
            return;

        if (this.validation()) {
            document.getElementById(this.inputId).click();
        }
    }

    /**
     * @method downloadFile
     * @memberof module:root/common/form.FileUploadController
     */
    downloadFile() {
        this.download(this.input);
    }

    /**
     * @method cancelFile
     * @param item {object}
     * @memberof module:root/common/form.FileUploadController
     */
    cancelFile(item) {
        item.cancel();
        this.saveDisabled = true;
    }

    /**
     * @method removeFile
     * @param item {object}
     * @memberof module:root/common/form.FileUploadController
     */
    removeFile(item) {
        item.remove();
        angular.element(document.getElementById(this.inputId))[0].value = '';
        this.saveDisabled = true;
    }

    /**
     * @method saveFile
     * @memberof module:root/common/form.FileUploadController
     */
    saveFile() {
        if (this.validation()) {
            if (isFunction(this.onSave)) {
                this.saveData(this.uploader.queue.map(item => {
                    return {
                        filePath: item.filePath,
                        type: item.type,
                        width: item.width,
                        height: item.height
                    }
                }));
            }
            this.uploader.clearQueue();
            this.uploader.cancelAll();
            this.downloadBtn = true;
        }
    }

    /**
     * @method saveData
     * @param event {object}
     * @memberof module:root/common/form.FileUploadController
     */
    saveData = async (event) => {
        let itemId = null;
        if (this.onSave) {
            itemId = await this.onSave({
                $event: event
            });
        }
        this.$ngRedux.dispatch({
            type: FILE_UPLOAD,
            id: this.identifier,
            path: this.statePath,
            index: this.stateIndex,
            prop: isEmpty(this.stateProp) ? undefined : this.stateProp,
            input: this.input,
            width: this.width,
            height: this.height,
            itemId: itemId
        });
    };

    /**
     * @method getClass
     * @return {object}
     * @memberof module:root/common/form.FileUploadController
     * @see {@link https://docs.angularjs.org/api/ng/directive/ngClass|AngularJS ngClass}
     */
    getClass() {
        if (this.form[this.inputId].$invalid && this.form.$submitted)
            return set({}, 'btnShortInvalid', true);
        else
            return set({}, this.btnClass, true);
    }

    /**
     * @method $onDestroy
     * @memberof module:root/common/form.FileUploadController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.onUpload();
        this.destroy();
    }
}

FileUploadController.$inject = ['$ngRedux', '$http', 'FileUploader', 'FileActions', 'Notifier'];

export const fileUpload = {
    bindings: {
        dropzone: '@',
        inputId: '@',
        identifier: '@',
        statePath: '@',
        stateIndex: '<',
        stateProp: '@',
        label: '@',
        btnClass: '@',
        validate: '<',
        validType: '@',
        onSave: '&',
        disabled: '<',
        required: '<',
        saveDisabled: '=',
        downloadBtn: '=',
    },
    require: {
        /**
         * @member {*} module:root/common/form.FileUploadController#form
         * @see {@link https://docs.angularjs.org/api/ng/type/form.FormController|FormController}
         */
        form: '^form'
    },
    controller: FileUploadController,
    template: require('./file-upload.html')
};
