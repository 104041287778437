/* @ngInject */
export function MammoQualityControlActionsFactory(MammoQCService, Notifier, ArtifactEvaluationService) {
    return {
        getEquipment: function(identifier, locationUuid) {
            return function(dispatch) {
                MammoQCService.getMammoQCEquipment(locationUuid).then(function(data) {
                    dispatch({
                        type: 'EQUIPMENT',
                        id: identifier,
                        items: data
                    });
                });
            };
        },
        getMammoQCTests: function(filter) {
            return function (dispatch) {
                MammoQCService.getMammoQCTests(filter).then(function (data) {

                    var gridItems = data.items.filter(function (item) {
                        return !_.isUndefined(item.remark);
                    });

                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'signalAndContractRatioGrid',
                        items: gridItems,
                        itemsCount: data.total
                    });
                });
            };
        },
        getMammoQCArtifactEvaluationList: function(filter) {
            return function (dispatch) {
                ArtifactEvaluationService.getArtifactEvaluationTests(filter).then(function (data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'mammoQualityControlArtifactEvaluationGrid',
                        items: data.items.map(function(item) {
                            switch (item.passFail) {
                                case 'Pass':
                                    item.class = 'colorGreen';
                                    break;
                                case 'Fail':
                                    item.class = 'colorRed';
                                    break;
                            }
                            return item;
                        }),
                        itemsCount: data.total
                    });
                });
            };
        },
        createArtifactEvaluationDetails: function(artifactEvaluation) {
            return function(dispatch) {
                return ArtifactEvaluationService.addArtifactEvaluation(artifactEvaluation).then(function(data) {
                    Notifier.success('New artifact evaluation test successfully created');
                    var dateFrom = new Date();
                    dateFrom.setMonth(new Date().getMonth() - 12);
                    dispatch({
                        type: 'DATE_RANGE_SELECTED',
                        id: 'mammoQualityControlArtifactEvaluationGrid',
                        dateRange: dateFrom
                    });
                    dispatch({
                        type: 'LOCATION_SELECTED',
                        id: 'mammoQualityControlArtifactEvaluationGrid',
                        uuid: artifactEvaluation.location
                    });
                    dispatch({
                        type: 'EQUIPMENT_SELECTED',
                        id: 'mammoQualityControlArtifactEvaluationGrid',
                        equipment: artifactEvaluation.equipment
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'mammoQualityControlArtifactEvaluationGrid'
                    });
                    dispatch({
                        type: 'TAB_SWITCH',
                        id: 'app.mammoQualityControl.artifactEvaluation',
                        selectedState: 'app.mammoQualityControl.artifactEvaluation.listing',
                        selectedTabIndex: 1
                    });
                });
            };
        },
    };
}
