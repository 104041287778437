angular
    .module('components.protective-equipment')
    .component('protectiveEquipment', {
        templateUrl: './protective-equipment.html',
        controller: 'ProtectiveEquipmentController'
    })
    .config(function($stateProvider) {
        $stateProvider
            .state('app.protectiveEquipment', {
                abstract: true,
                url: '/protective-equipment'
            })
            .state('app.protectiveEquipment.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.protectiveEquipment.add', {
                url: '/add',
                template: require('../../components.html'),
                controller: 'ProtectiveEquipmentAddController'
            })
            .state('app.protectiveEquipment.manage', {
                url: '/manage',
                template: require('../../components.html'),
                controller: 'ProtectiveEquipmentManageController'
            })
            .state('app.protectiveEquipment.testReports', {
                url: '/test-reports',
                template: require('../../components.html'),
                controller: 'ProtectiveEquipmentTestReportsController'
            });
    });
