import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';

/**
 * Creates a new InputFaxController.
 * @class
 * @extends InputController
 */
class InputFaxController {
    /**
     * @constructor
     * @param $ngRedux
     */
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
        this.input = null;
    }

    $onInit() {
        if (isUndefined(this.name))
            throw new Error('Missing input name attribute');
        if (isUndefined(this.identifier))
            throw new Error('Missing input ' + this.name + ' identifier attribute');
        if (isUndefined(this.label))
            this.label = 'Fax';

        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        if (this.statePath) this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);
    }

    onChange() {
        this.$ngRedux.dispatch({
            type: 'INPUT_FAX_CHANGE',
            id: this.identifier,
            path: this.statePath,
            input: this.model
        });
    }

    $onDestroy() {
        this.unsubscribe && this.unsubscribe();
        this.unsubscribeModule && this.unsubscribeModule();
    }

    mapModule(state) {
        return {
            module: state.navigation.module,
            regex: state.app.validation.phone,
            message: state.app.validationMessage.phone
        };
    }

    mapState(state) {
        return {
            model: get(state[this.module][this.identifier], this.statePath)
        }
    }
}

InputFaxController.$inject = ['$ngRedux'];

export const inputFax = {
    bindings: {
        identifier: '@',
        statePath: '@',
        name: '@',
        label: '@',
        required: '<',
        model: '=?'
    },
    require : {
        form : '^form'
    },
    controller: InputFaxController,
    template: `
        <md-input-container class="md-block md-icon-float md-icon-left" flex>
            <label>{{$ctrl.label}}</label>
            <asset src="phone.svg"></asset>
            <input id="{{$ctrl.name}}"
                   name="{{$ctrl.name}}"
                   ng-model="$ctrl.model"
                   ng-change="$ctrl.onChange()"
                   ng-required="$ctrl.required"
                   ng-pattern="$ctrl.regex">
            <div ng-messages="$ctrl.form[$ctrl.name].$error">
                <div ng-message="required">{{'COMMON.FIELD_REQUIRED' | translate}}</div>
                <div ng-message="pattern" class="newLineBreak">{{$ctrl.message}}</div>
            </div>
        </md-input-container>
    `
};
