/**
 * @const {object}
 * @memberOf module:Employees
 */
export const employeesTraining = {
    /* @ngInject */
    controller: function () {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.loading = false;
        }

        this.$onDestroy = () => {
            this.loading = false;
        }
    },
    template: require('./employees-training.html'),
    bindings: {
        userUuid: '@'
    },
};
