/* @ngInject */
export function userStatusButton($rootScope, $timeout, $mdDialog, NavigationService, UserManagementService, AuthService) {
    return {
        restrict: 'E',
        scope: {
            uuid: '=',
            par: '=',
            status: '=',
            title: '@',
            btnStyle: '=?',
            btnSize: '=?'
        },
        template: require('./user-status-button.html'),
        link: function(scope, element, attrs) {

            scope.headerTitle = scope.title;
            scope.class = _.isUndefined(attrs.btnClass) ? 'btnDefault' : attrs.btnClass;

            if (_.isUndefined(scope.btnStyle)) scope.btnStyle = 'btn-default';
            if (_.isUndefined(scope.btnSize)) scope.btnSize = 'btn-md';

            var labelActivate = 'ACTIVATE';
            var labelDeactivate = 'INACTIVATE';
            scope.label= scope.status ? labelDeactivate : labelActivate;

            scope.displayStatic = AuthService.getUser().basicTypeCode == 'emp';
            if (scope.displayStatic) {
                scope.label= scope.status ? 'Inactive' : 'Active';
                switch (scope.btnSize) {
                    case 'btn-xs':
                        scope.chipStyle = 'height:24px; line-height:24px; margin:0;';
                        break;
                    default:
                        scope.chipStyle = '';
                }
            }

            var btnNgEl;
            $timeout(function() {
                var btnEl = scope.displayStatic ? element[0].querySelector('md-chip') : element[0].querySelector('button');
                btnNgEl = angular.element(btnEl);
            }, 200);

            scope.$on('USER_STATUS_CHANGE', function(event, status, userUuid, locationUuid) {
                if (scope.uuid != userUuid) return;

                if (!scope.displayStatic)
                    if (scope.par != locationUuid) return;

                scope.status = status;
                if (scope.displayStatic)
                    btnNgEl.text(status ? 'Inactive' : 'Active');
                else {
                    btnNgEl.text(status ? labelDeactivate : labelActivate);
                    if (scope.class != 'btnDefault')
                        scope.class = status ? 'btnGridRemove' : 'btnGridModify';
                }
            });

            scope.onClick = function() {
                $mdDialog.show({
                    controller: StatusController,
                    template: require('./user-status-dialog.html'),
                    parent: angular.element(document.body),
                    locals: {
                        title: scope.headerTitle,
                        label: scope.label,
                        uuid: scope.uuid,
                        status: scope.status,
                        par: scope.par
                    },
                    clickOutsideToClose: true
                });
            };

            function StatusController($scope, title, label, uuid, status, par) {
                $scope.headerColor = NavigationService.getNavConfig().colorName;
                $scope.headerTitle = title;
                $scope.buttonLabel = label;
                $scope.uuid = uuid;
                $scope.status = status;
                $scope.par = par;

                $scope.confirm = function() {
                    UserManagementService.setUserStatus($scope.uuid, $scope.par, !$scope.status).then(function() {
                        $rootScope.$broadcast('USER_STATUS_CHANGE', !$scope.status, uuid, scope.par);
                        $mdDialog.cancel();
                    });
                };

                $scope.cancel = function() {
                    $mdDialog.cancel();
                };
            }
        }
    };
}
