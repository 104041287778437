/*
*   List of Actions with TYPE that should be passed from accreditation.reducer.js to scheme.reducer.js
 */

export const PREFIX = 'ACCREDITATION';

export const TYPES = {
    FETCH_SCHEMES:                  `${PREFIX}_FETCH_SCHEMES`,
    FETCH_STANDARDS_TREE_PROGRESS:  `${PREFIX}_FETCH_STANDARDS_TREE_PROGRESS`,
    SELECT_STANDARD:                `${PREFIX}_SELECT_STANDARD`,
    LOCALIZE_TASKS_GRID_FILTER:     `${PREFIX}_LOCALIZE_TASKS_GRID_FILTER`,
    FETCH_SITE_LOCATION_DETAILS:    `${PREFIX}_FETCH_SITE_LOCATION_DETAILS`,
    // FETCH_STANDARD_DETAILS:         `${PREFIX}_FETCH_STANDARD_DETAILS`,
    SELECT_ASSESSMENT_ITEM:         `${PREFIX}_SELECT_ASSESSMENT_ITEM`,
    LOCATION_SEARCH_BAR_CHANGE:     `${PREFIX}_LOCATION_SEARCH_BAR_CHANGE`,
    SCHEME_SECTIONS_RESET:         `${PREFIX}_STANDARDS_RESET`,
    STANDARDS_TREE_UNSELECT_ALL:    `${PREFIX}_STANDARDS_TREE_UNSELECT_ALL`,
    SIMPLE_STD_TREE_UNSELECT_ALL:   `${PREFIX}_SIMPLE_STD_TREE_UNSELECT_ALL`,
    SIMPLE_STD_TREE_SELECT:         `${PREFIX}_SIMPLE_STD_TREE_SELECT`,
    SECTIONS_TREE_FETCH:           `${PREFIX}_SECTIONS_TREE_FETCH`,
    // INSERT_TASKS_TO_STANDARDS_TREE: `${PREFIX}_INSERT_TASKS_TO_STANDARDS_TREE`,
};
