export const gridListItemDownload = {
    bindings: {
        data: '<',
    },
    /* @ngInject */
    controller: function($ngRedux, FileActions) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(() => ({}), FileActions)(this);
            this.path = this.data.uri.startsWith('/') ? this.data.uri : `/${this.data.uri}`;
        };

        this.onClick = () => {
            this.download(`storage${this.path}`);
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            this.unsubscribe();
        };
    },
    template: `
        <md-button class="md-icon-button"
                   aria-label="Download"
                   ng-click="$ctrl.onClick($event); $event.stopPropagation();">
            <asset src="download_black.svg"></asset>
        </md-button>
    `,
};
