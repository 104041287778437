import {
    getRequestStatuses,
    getRequestDetails,
    cancelRequest,
} from './cre-vendors.actions';
import { TAB_SHOW } from '../../../common/tabs/tabs.constants';

/**
 * @const {object}
 * @memberOf module:Credentialing
 */
export const creVendors = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $location, $mdDialog, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.stateId = $state.$current.parent.name;
            this.unsubscribe = $ngRedux.connect(() => ({}), {
                getRequestStatuses,
                getRequestDetails,
                cancelRequest,
            })(this);

            this.getRequestStatuses();

            const application_id = $location.search().id;
            application_id && this.onSelect({ item: { application_id } });
        }

        /**
         * @function $onSelect
         */
        this.onSelect = ({ item }) => {
            this.getRequestDetails({
                application_id: item.application_id
            }).then(() => {
                $ngRedux.dispatch({
                    type: TAB_SHOW,
                    id: this.stateId,
                    state: [
                        `${this.stateId}.details`,
                        `${this.stateId}.accounts`,
                        `${this.stateId}.finance`,
                        `${this.stateId}.insurance`,
                        `${this.stateId}.accreditationStandards`,
                        `${this.stateId}.goods`,
                        `${this.stateId}.warranty`,
                        `${this.stateId}.services`,
                    ],
                    stateParams: { id: item.application_id },
                });
            });
        };

        /**
         * @function $onCancel
         */
        this.onCancel = ({ item }) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen application will be canceled permanently.')
                .ariaLabel('Cancel application')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.cancelRequest({
                    purpose: 'venapp',
                    application_id: item.application_id,
                });
            }, angular.noop);
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: `
        <cre-vendors-grid
            type="{{$ctrl.type}}"
            on-select="$ctrl.onSelect($event)" 
            on-cancel="$ctrl.onCancel($event)">
        </cre-vendors-grid>
    `
};
