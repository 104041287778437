/* @ngInject */
export function EquipmentOwnerFactory(Helpers, DATE_FORMAT) {
    return function EquipmentOwner(equipmentID) {

        return {
            in_eqi_id: equipmentID,
            in_new_owner_business_name: '',
            in_new_owner_business_contact_name: '',
            in_new_owner_title: '',
            in_new_owner_first_name: '',
            in_new_owner_last_name: '',
            in_new_owner_phone: '',
            in_new_owner_mobile: '',
            in_new_owner_fax: '',
            in_new_owner_email: '',
            in_new_owner_man_lic_no: '',
            in_new_owner_man_lic_exp_date: null,
            in_description: '',
            in_new_owner_loc_state: null,
            in_new_owner_loc_postcode: '',
            in_new_owner_loc_building_name: '',
            in_new_owner_loc_unit_num: '',
            in_new_owner_loc_building_num: '',
            in_new_owner_loc_suburb: '',
            in_doc_url: '',

            get licenseExpDate() {
                return Helpers.formatDate(this.in_new_owner_man_lic_exp_date, DATE_FORMAT.INPUT);
            },

            countryObj: null,
            set country(obj) {
                this.countryObj = obj;
            },
            get country() { return this.countryObj; },

            stateObj: null,
            set state(obj) {
                this.stateObj = obj;
                this.in_new_owner_loc_state = obj.id;
            },
            get state() { return this.stateObj; }
        };
    };
}
