/* @ngInject */
export function SelectEquipmentCategoryController($ngRedux, EquipmentActions) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, EquipmentActions)(this);
        ctrl.getEquipmentCategories(ctrl.identifier, ctrl.equipmentType);
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            $ngRedux.dispatch({
                type: 'EQUIPMENT_CATEGORY_SELECTED',
                id: ctrl.identifier,
                category: ctrl.selectedOption
            });
        }
    };

    function mapStateToThis(state) {
        return {
            options: state.equipment[ctrl.identifier].categories,
            equipmentType: state.equipment[ctrl.identifier].filter.typeCode,
            selectedOption: state.equipment[ctrl.identifier].filter.category,
        };
    }
}
