/* @ngInject */
export function AdministrationAccountsSiteLocationLicenceDetailsController($ngRedux, $uiRouterGlobals, $mdDialog, AdministrationActions, Notifier, LicenceService) {
    var ctrl = this;
    var unsubscribe = angular.noop;
    var dialog = null;

    ctrl.$onInit = function() {
        ctrl.identifier = 'administrationAccountsSiteLocationLicence';
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);
    };

    ctrl.manageLicence = function(event) {
        if (ctrl.siteLocationLicenceForm.$valid) {
            if (ctrl.licence.id) {
                if (typeof event !== 'undefined') {
                    ctrl.licence.filePath = _.first(event).filePath;
                } else {
                    ctrl.licence.filePath = null;
                }
                ctrl.updateSiteLocationLicence(ctrl.licence);
            }
            else {
                if (typeof event !== 'undefined') {
                    ctrl.licence.slo = $uiRouterGlobals.params.slo;
                    ctrl.licence.filePath = _.first(event).filePath;
                    ctrl.createSiteLocationLicence(ctrl.licence);
                }
                else Notifier.error('Please add document and click the save button');
            }
        }
        else Notifier.error('Please fill in the form fields');
    };

    ctrl.assignAllSiteLocations = function() {
        var confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('This will assign all Site Locations in the grid to this Licence')
            .ariaLabel('Deactivate RSC')
            .ok('Yes')
            .cancel('No');

        dialog = $mdDialog.show(confirm).then(function() {
            LicenceService.assignSiteLocation('all').then(function() {
                Notifier.success('Location is assigned');

                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'administrationAccountsLicenceSiteLocationsGrid'
                });
            });
        }, angular.noop);
    };

    ctrl.resignAllSiteLocations = function() {
        var confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('This will resign all Site Locations in the grid from this Licence.')
            .ariaLabel('Deactivate RSC')
            .ok('Yes')
            .cancel('No');

        dialog = $mdDialog.show(confirm).then(function() {
            LicenceService.resignSiteLocation('all').then(function() {
                Notifier.success('Location is resigned');

                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'administrationAccountsLicenceSiteLocationsGrid'
                });
            });
        }, angular.noop);
    };

    ctrl.$onDestroy = function() {
        if (dialog)  $mdDialog.hide(dialog);
        unsubscribe();
    };

    function mapStateToThis(state) {
        return {
            licence: state.administration.administrationAccountsSiteLocationLicence.data,
            loading: _.find(state.administration['app.administration.accounts.siteLocations'].tabs, ['state', 'app.administration.accounts.siteLocations.licenceDetails']).loading,
            gridTitle: 'Licence Site Locations',
            maxLicenseIssueDate: new Date(),
            minLicenseExpiryDate: new Date(),
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                state.administration.administrationAccountsSiteLocationLicence.title
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
