import { TabsReducer } from '../../common/tabs/tabs.reducer';

export const tabs = {
    'app.radioactiveMaterials.waste.add': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.radioactiveMaterials.waste.add.details',
                title: 'Radioactive Material Details',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            }
        ]
    },
    'app.radioactiveMaterials.waste.manage': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.radioactiveMaterials.waste.manage.listing',
                title: 'Radioactive Materials',
                visible: true,
                disabled: false,
                loading: false,
            },
            {
                state: 'app.radioactiveMaterials.waste.manage.details',
                title: 'Radioactive Material Details',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            }
        ]
    },
    'app.radioactiveMaterials.waste.alerts': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.radioactiveMaterials.waste.alerts.listing',
                title: 'Alerts & Warnings',
                visible: true,
                disabled: false,
                loading: false,
            }
        ]
    },
    'app.radioactiveMaterials.test.add': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.radioactiveMaterials.test.add.details',
                title: 'Sources/Leak Test Details',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: 'app.radioactiveMaterials.test.add.documents',
                title: 'Documents',
                visible: false,
                disabled: false,
                loading: false,
            }
        ]
    },
    'app.radioactiveMaterials.test.manage': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.radioactiveMaterials.test.manage.listing',
                title: 'Sources/Leak Tests',
                visible: true,
                disabled: false,
                loading: false,
            },
            {
                state: 'app.radioactiveMaterials.test.manage.details',
                title: 'Sources/Leak Test Details',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: 'app.radioactiveMaterials.test.manage.documents',
                title: 'Documents',
                visible: false,
                disabled: false,
                loading: false,
            },
            {
                state: 'app.radioactiveMaterials.test.manage.wipeTest',
                title: 'Leak Test',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: 'app.radioactiveMaterials.test.manage.disposal',
                title: 'Source Disposal',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Dispose'}
                }
            }
        ]
    },
    'app.radioactiveMaterials.test.alerts': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.radioactiveMaterials.test.alerts.listing',
                title: 'Alerts & Warnings',
                visible: true,
                disabled: false,
                loading: false,
            }
        ]
    }
};
