import watch from 'redux-watch';
import * as echarts from 'echarts';
import {
    addDosimetryBadge,
    removeDosimetryBadge,
    genDosimetryReport,
} from './employees-dosimetry-badge.actions';
import { INIT_MODULE } from '../../../common/navigation/navigation.actions.types';
import moment from 'moment';
import get from 'lodash/get';

/**
 * @const {object}
 * @memberOf module:RadioactiveMaterials
 */
export const employeesDosimetryBadge = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $scope, $mdDialog, $uiRouterGlobals, $timeout, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.loading = false;
            this.unsubscribe = $ngRedux.connect(({ employees }) => ({
                employeeDosimetryBadge: employees.employeeDosimetryBadge,
                employeeDosimetryReports: employees.employeeDosimetryReportsGrid.items,
                stateParams: $uiRouterGlobals.params
            }), {
                addDosimetryBadge,
                removeDosimetryBadge,
                genDosimetryReport,
            })(this);

            // const watchBadges = watch($ngRedux.getState, 'employees.employeeDosimetryBadgesGrid.items');
            // this.onBadges = $ngRedux.subscribe(watchBadges((badges) => {
            //     if (badges.length) {
            //         $ngRedux.dispatch({
            //             type: 'INPUT_TEXT_CHANGE',
            //             id: 'employeeDosimetryBadge',
            //             path: 'participantNumber',
            //             input: get(badges[0], 'participant_number', null),
            //         });
            //     }
            // }));
            const watchReports = watch($ngRedux.getState, 'employees.employeeDosimetryReportsGrid.items');
            this.onReports = $ngRedux.subscribe(watchReports(() => {
                renderChart(this.employeeDosimetryBadge.doseReportsYear);
            }));
            const watchReportsYear = watch($ngRedux.getState, 'employees.employeeDosimetryBadge.doseReportsYear');
            this.onReportsYear = $ngRedux.subscribe(watchReportsYear((year) => {
                renderChart(year);
            }));
        }

        this.addEmployeeDosimetryBadge = () => {
            $scope.$broadcast('show-errors-check-validity', '$ctrl.addDosimetryBadgeForm');
            if (this.addDosimetryBadgeForm.$valid) {
                this.addDosimetryBadge(this.employeeDosimetryBadge, this.userUuid).then(() => {
                    Notifier.success('New badge has been successfully added to the employee');
                    this.addDosimetryBadgeForm.$setPristine();
                    this.addDosimetryBadgeForm.$setUntouched();
                }).catch((error) => {
                    Notifier.error(error.data);
                });
            } else {
                Notifier.error('Please fill all fields in the form!');
            }
        }

        this.onRemove = ({ item }) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen dosimetry badge will be removed permanently from this employee.')
                .ariaLabel('Remove Dosimetry Badge')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.removeDosimetryBadge(item.pdb_id);
            }, angular.noop);
        };

        this.onReport = ({ item }) => {
            $ngRedux.dispatch({ type: 'LOADING_START' });
            $timeout(() => {
                $ngRedux.dispatch({
                    type: INIT_MODULE,
                    module: 'personalDosimetry',
                    color: '3f51b5',
                });
                $state.go(
                    'app.personalDosimetry.reports.detailsResult',
                    { id: item.imp_id, measurement: item.dos_id },
                );
                $ngRedux.dispatch({ type: 'LOADING_END' });
            }, 200);
        };

        this.onDownload = ({ item }) => {
            this.genDosimetryReport(
                item.dos_id,
                item.participant_number,
                item.signature,
            );
        };

        const renderChart = (year) => {
            const { values, data } = resolveChartData(year);
            this.doseChart && this.doseChart.dispose();
            this.doseChart = echarts.init(
                document.getElementById('doseChart'),
                {},
                { width: 600, height: 400 });
            this.doseChart.setOption({
                xAxis: {
                    type: 'category',
                    name: year,
                    data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Avg', 'Sept', 'Oct', 'Nov', 'Dec']
                },
                yAxis: [{
                    type: 'value',
                    name: 'Dose (mSv)',
                    position: 'left',
                    min: 0,
                    max: parseInt(Math.max(...values)) + 2,
                    interval: 0.5,
                }],
                series: [
                    {
                        data,
                        type: 'bar',
                        itemStyle: {
                            color: 'rgba(255, 99, 132, 0.2)',
                            borderWidth: 1,
                            borderColor: 'rgb(255, 99, 132)',
                            borderType: 'solid',
                        },
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        label: {
                            show: true,
                            position: 'top',
                            formatter: (params) => {
                                return params.data.last ? params.value : '';
                            },
                        },
                    },
                ]
            });
        };

        const resolveChartData = (year) => {
            const values = Array(12).fill(0);
            const data = Array(12).fill(0);
            for (const report of this.employeeDosimetryReports) {
                const start = moment(report.period_begin_date, 'YYYY-MM-DD');
                const end = moment(report.period_end_date, 'YYYY-MM-DD');
                if (year >= start.year() && year <= end.year()) {
                    const periodValue = parseFloat(report.current_dde);
                    if (periodValue) {
                        for (let month = 0; month < 12; month++) {
                            const currMonthStart = moment(`${year}-${month + 1}-01`, 'YYYY-MM-DD');
                            if (currMonthStart.isBetween(start, end, 'month', '[]')) {
                                if (values[month] === 0) {
                                    const endMonthIdx = end.month();
                                    values[month] = periodValue;
                                    data[month] = {
                                        value: periodValue,
                                        last: month === 0 || month === endMonthIdx || month === 11,
                                    };
                                }
                            }
                        }
                    }
                }
            }
            return { values, data };
        };

        this.$onDestroy = () => {
            this.loading = false;
            this.unsubscribe();
            //this.onBadges();
            this.onReports();
            this.onReportsYear();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./employees-dosimetry-badge.html'),
    bindings: {
        userUuid: '@'
    },
};
