/* @ngInject */
export function SafetyPlanReviewContactController($scope, $rootScope, $q, $state, SafetyPlanService, AuthService, Notifier, AccountService) {

    $scope.myRSOContact = null;
    $scope.cursorState = 'wait';
    $scope.rscUserParentLocations = [];
    $scope.selectedRscParentLocation = "";
    $scope.trainingName = $state.current.trainingName;

    $scope.rscUser = AuthService.getUser();

    AccountService.retrieveParents($scope.rscUser.uuid).
    then(function(parents) {
        $scope.rscUserParentLocations = parents;
        if ($scope.rscUserParentLocations.length > 0) {
            $scope.selectedRscParentLocation = $scope.rscUserParentLocations[0].uuid;
        }
        $scope.cursorState = 'default';
    }, function(error) {
        $scope.cursorState = 'default';
        Notifier.error("Error fetching site location data");
    });

    $scope.$watch('selectedRscParentLocation', function() {
        if ($scope.selectedRscParentLocation != "") {
            $scope.fetchRSOData($scope.selectedRscParentLocation);
        }
    });

    $scope.fetchRSOData = function(locationUuid) {
        $scope.cursorState = 'wait';
        $q.all([
            SafetyPlanService.fetchRSOData(locationUuid)
                .then(function(data) {
                    $scope.cursorState = 'default';
                    $scope.myRSOContact = data.rso;
                }, function(error) {
                    $scope.cursorState = 'default';
                    Notifier.error("Error fetching safety cordinator contact.");
                })
        ]).then(function() {});
    };
}
