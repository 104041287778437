import { generateQRCode } from '../../../../../../common/utils/util.qrcode';

/* @ngInject */
export function AdministrationAccountsHeadOfficeDetailsController(
    $ngRedux,
    $state,
    $uiRouterGlobals,
    $transitions,
    $timeout,
    AdministrationActions,
    AccountService,
    AuthService,
    FileActions,
    Notifier,
) {
    const ctrl = this;
    let unsubscribe = angular.noop;
    let onEnter = angular.noop;
    let onBeforeExit = angular.noop;

    ctrl.$onInit = function() {
        ctrl.title = 'Client Data';

        unsubscribe = $ngRedux.connect(mapStateToThis, {
            ...AdministrationActions,
            ...FileActions,
        })(ctrl);

        ctrl.isAdmin = AuthService.getUser().typeCode === 'adm';

        if (_.has($uiRouterGlobals.params, 'hof')) {
            const hof = _.isUndefined($uiRouterGlobals.params.hof) ? ctrl.stateParams.hof : $uiRouterGlobals.params.hof;
            if (hof) {
                ctrl.getHeadOfficeDetails(hof, true);
            } else {
                if ($state.current.name === 'app.administration.accounts.headOfficesDetails') {
                    $ngRedux.dispatch({
                        type: 'TAB_ENABLE',
                        id: 'app.administration.accounts',
                        state: ['app.administration.accounts.headOfficesDetails']
                    });
                    $timeout(() => {
                        $ngRedux.dispatch({
                            type: 'TAB_SELECT',
                            id: 'app.administration.accounts',
                            state: 'app.administration.accounts.headOfficesDetails'
                        });
                    }, 200);
                }
            }
        }

        onBeforeExit = $transitions.onBefore({exiting: 'app.administration.accounts.headOfficesDetails'}, function() {
            ctrl.headOfficeForm.$setPristine();
            ctrl.headOfficeForm.$setUntouched();
        });
    };

    ctrl.initQRCode = () => {
        if (ctrl.headOffice.uuid) {
            generateQRCode('HOF-QRCode', {
                uuid: ctrl.headOffice.uuid,
                name: ctrl.headOffice.name,
                action: 'location',
            });
        }
    }

    ctrl.printQRCode = () => {
        AccountService.printQRcode(
            ctrl.headOffice.uuid,
            ctrl.headOffice.name,
            'location',
        );
    };

    ctrl.onMonthlyReport = () => {
        ctrl.getMonthlyReport({
            type: 'hof',
            hof: ctrl.headOffice.name,
        }).then(({ data }) => {
            if (data?.doc) {
                ctrl.download(`storage/${data.doc}`);
            } else {
                Notifier.error('Monthly Report is currently not available!');
            }
        });
    };

    ctrl.manageHeadOffice = function() {
        if (ctrl.headOfficeForm.$valid) {
            if (ctrl.headOffice.uuid) {
                ctrl.updateHeadOfficeDetails(ctrl.headOffice);
            }
            else {
                ctrl.createHeadOfficeDetails(ctrl.headOffice).then(function() {
                    $state.go($state.current.name, _.assign({}, $uiRouterGlobals.params, {hof: ctrl.headOffice.uuid}), {notify:false, reload:false});
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            }
        }
        else Notifier.error('Please fill in the form fields');
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onEnter();
        onBeforeExit();
    };

    function mapStateToThis(state) {
        return {
            headOffice: state.administration.administrationAccountsHeadOffice,
            stateParams: state.administration['app.administration.accounts'].stateParams,
            lock: _.isNull(_.find(state.administration['app.administration.accounts'].tabs, ['state', 'app.administration.accounts.headOfficesDetails']).buttons.execute)
        };
    }
}
