import { resolveTpl } from '../personal-dosimetry.utils';
import get from 'lodash/get';

export const personalDosimetrySettings = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $uiRouterGlobals, $scope, $rootScope, $timeout, $mdDialog, PersonalDosimetryActions) {
        this.template = resolveTpl('pd-settings');

        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect((state) => ({}), PersonalDosimetryActions)(this);
            this.getReportProviders('personalDosimetry');
            this.getEmployeeOccupations();
            if ($uiRouterGlobals.params.id) {
                this.onManage({ item: { pdl_id: $uiRouterGlobals.params.id } });
            }
        };

        this.onManage = async ({ item }) => {
            const id = get(item, 'pdl_id');
            id && await this.getSettingsLimit(id);
            $ngRedux.dispatch({
                type: 'TAB_SHOW',
                id: 'app.personalDosimetry.settings',
                state: ['app.personalDosimetry.settings.details'],
                ...(id && { stateParams: { id } }),
            });
            $timeout(() => {
                $ngRedux.dispatch({
                    type: 'TAB_SELECT',
                    id: 'app.personalDosimetry.settings',
                    state: 'app.personalDosimetry.settings.details',
                    ...(id && { stateParams: { id } }),
                });
            }, 200);
        }

        this.onRemove = ({ item }) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen Limit will be removed permanently.')
                .ariaLabel('Remove Limit')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.deleteSettingsLimit(item.pdl_id);
            }, angular.noop);
        }

        this.onReset = () => {
            $rootScope.$broadcast('SELECT_CASCADE_RESET', 'pdSettingsFilterForm');
        };

        this.$onDestroy = () => {
            this.unsubscribe && this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        };
    },
    template: '<div ng-include src="$ctrl.template"></div>'
};
