import { DATE_FORMAT } from './util.constants';
import moment from 'moment';
import isString from 'lodash/isString';
import isDate from 'lodash/isDate';

export function formatDate(date, format) {
    if (isString(date)) {
        if (typeof format === 'undefined')
            return moment(date, DATE_FORMAT.OUTPUT).toDate();
        else
            return moment(date, DATE_FORMAT.OUTPUT).format(format);
    }

    if (isDate(date)) {
        if (typeof format === 'undefined')
            return moment(date).format(DATE_FORMAT.OUTPUT);
        else
            return moment(date).format(format);
    }

    return null;
}
