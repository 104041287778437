import { List } from 'immutable';

export function AdministrationManager() {
    this.subjectColumns = new List([
        {title: '#',                mapper: false},
        {title: 'Account ID',       mapper: 'id'},
        {title: 'Facility',         mapper: 'name'},
        {title: 'Facility Type',    mapper: 'subjectType'},
        {title: 'E-mail',           mapper: 'email'},
        {title: 'Phone',            mapper: 'phone'}
    ]);
    this.siteLocationColumns = new List([
        {title: '#',                mapper: false},
        {title: 'Address',          mapper: 'address'},
        {title: 'Country',          mapper: 'country'},
        {title: 'State',            mapper: 'state'}
    ]);
    this.siteLocationManagerColumns = new List([
        {title: '#',                mapper: false},
        {title: 'First Name',       mapper: 'firstName'},
        {title: 'Last Name',        mapper: 'lastName'},
        {title: 'E-mail',           mapper: 'email'},
        {title: 'Phone',            mapper: 'phone'},
        {title: 'Fax',              mapper: 'fax'}
    ]);
}
