/* @ngInject */
export function SelectEquipmentTestStatusController($ngRedux) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, {})(this);
        if (_.isUndefined(ctrl.label)) ctrl.label = 'Status';
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    ctrl.onSelect = function() {
        $ngRedux.dispatch({
            type: 'EQUIPMENT_TEST_STATUS_SELECTED',
            id: ctrl.identifier,
            status: ctrl.selectedOption
        });
    };

    function mapStateToThis(state) {
        return {
            options: _.get(state, getStateContainer('statusList'), []),
            selectedOption: _.get(state, getStateContainer('filter.status'), null)
        };
    }

    function getStateContainer(statePath) {
        return [ctrl.module, ctrl.identifier].join('.') + '.' + statePath;
    }
}
