import { IDS } from '../add-document-action-id.constants';
import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import * as Actions from '../add-document.actions';

class AccAddFromExistingGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter, $mdDialog, $scope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
    }

    $onInit() {
        this.module = 'accreditation';
        this.identifier = IDS.accAddDocument;
        this.statePath = IDS.addFromExistingGrid;
        // this.filterComponent = false;
        this.filterTemplate = './from-existing-grid-filter.html';
        this.exportComponent = false;
        this.autoload = false;
        this.title = false;
        this.icon = false;
        this.confirmDialogOnRemove = true;

        this.columns = [
            {title: '#', mapper: null},
            {title: 'Number', mapper: 'doc_num'},
            {title: 'Type',  mapper: 'type'},
            {title: 'Location',  mapper: 'location'},
            {title: this.$filter('translate')('COMMON.FIELD.NAME'),         mapper: 'name'},
            {title: this.$filter('translate')('COMMON.FIELD.CREATED'),      mapper: 'uploaded_at'},
            {title: this.$filter('translate')('COMMON.DATE.DUE_DATE'),      mapper: 'due_date'},

            // {title: this.$filter('translate')('COMMON.FIELD.UPLOADED_BY'),  mapper: 'uploaded_by'},
            // {title: this.$filter('translate')('COMMON.FIELD.UPLOADED_AT'),  mapper: 'uploaded_at'},
//          {title: this.$filter('translate')('COMMON.FIELD.DOCUMENT'),     mapper: 'file-download'}
        ];
        this.columnsDefault = this.columns.concat();
        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), Actions)(this);
        this.unsubscribeCustom = this.$ngRedux.connect(state => {
            const accAddDocument = state.accreditation.accAddDocument;
            return {
                siteLocationUuid: accAddDocument.siteLocationUuid
            }
        }, {})(this);
        this.dataAction = this.fetchLocAssignableVersionedDocumentsGrid;
        this.filter.siteLocationUuid = this.filter.siteLocationUuid || this.$state.params.slo;
        this.filter.standardId = this.filter.standardId || this.$state.params.standard;
        this.buttons = this.createButtons();
        this.init();
    }
    createButtons() {
        return [
            {
                class: 'btnGridModify',
                label: 'Add',
                callback: this.onAddButton
            },
            {
                class: 'btnGridAction',
                label: 'Download',
                callback: event => {
                    this.onDownload(event)
                }
            },
        ];
    }

    $onDestroy() {
        if(this.unsubscribeCustom)
            this.unsubscribeCustom();

        this.destroy();
    }

}

AccAddFromExistingGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter', '$mdDialog', '$scope'];

export const accAddFromExistingGrid = {
    bindings: {
        onAddButton: "&",
        onDownload: '&'

    },
    controller: AccAddFromExistingGridController,
    ...gridTpl
}
