import i18n from '../../i18n/en.json';

/**
 * @const {object}
 * @memberOf module:ApplicationsPermits
 */
export const apLaserRequestConfirmation = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function () {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.icon = i18n[this.type].confirmation.icon;
            this.msg = i18n[this.type].confirmation.msg;
        }
    },
    template: `
        <div style="height: 400px">
            <div class="loader formHeader">
                <div style="margin-left: 8px;">
                    <asset class="colorApplicationsPermits" src="{{$ctrl.icon}}"></asset>
                </div>
                <div class="colorApplicationsPermits">Thank you</div>
                <div class="colorApplicationsPermits">{{$ctrl.msg}}</div>
            </div>
        </div>
    `
};
