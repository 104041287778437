import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import { DATE_FORMAT } from '../../../../common/utils/util.constants';
import moment from 'moment';

class EquipmentCalibrationGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, EquipmentCalibrationActions, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.actions = EquipmentCalibrationActions;
        this.$filter = $filter;
    }

    $onInit() {
        this.module = 'equipmentCalibration';
        this.identifier = 'equipmentCalibrationGrid';
        this.filterTemplate = './equipment-calibration-grid-filter.html';
        this.filterControls = './equipment-calibration-grid-filter-controls.html';
        this.customComponent = 'ecGridDepreciationReport';

        this.columns = [
            {title: '#',                                                                                mapper: null},
            {title: this.$filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_SITE_LOCATION'),    mapper: 'account.name', sort: 'account_name'},
            {title: 'Department',                                                                       mapper: 'department.name', sort: 'department'},
            {title: 'Room Name',                                                                        mapper: 'room.name', sort: 'room_name'},
            {title: this.$filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_EQUIPMENT_ID'),     mapper: 'user_friendly_id', sort: 'equipment_id'},
            {title: 'Type',                                                                             mapper: 'category.name', sort: 'category'},
            {title: this.$filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_MANUFACTURER'),     mapper: 'manufacturer.name', sort: 'manufacturer'},
            {title: this.$filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_MODEL'),            mapper: 'model.name', sort: 'model'},
            {title: this.$filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_SERIAL_NUMBER'),    mapper: 'serial', sort: 'serial'},
            {title: 'Installation Date',                                                                mapper: 'installation_date', sort: 'installation_date'},
            {title: 'Capital Sensitivity Expiry Date',                                                  mapper: 'in_cap_sens_exp_date', sort: 'in_cap_sens_exp_date'},
            {title: 'Calibration Due Date',                                                             mapper: 'cal_due_date', sort: 'cal_due_date'},
            {title: 'Service Due Date',                                                                 mapper: 'ser_due_date', sort: 'ser_due_date'},
            {title: 'Electrical Tag Testing Due Date',                                                  mapper: 'ele_tag_testing_due_date', sort: 'ele_tag_testing_due_date'},
            {title: 'Loan Due Date',                                                                    mapper: 'loan_due_date', sort: 'loan_due_date'},
            {title: 'Status',                                                                           mapper: 'status'},
            {title: '',                                                                                 mapper: 'warning'},
            {title: '',                                                                                 mapper: 'critical'}
        ];

        this.buttons = [
            {
                label: (item) => {
                    if (moment().isAfter(moment(item.loan_due_date, DATE_FORMAT.OUTPUT))) {
                        return 'Overdue';
                    }
                    return item.eql_id ? 'On Loan' : 'Loan';
                },
                class: (item) => {
                    if (moment().isAfter(moment(item.loan_due_date, DATE_FORMAT.OUTPUT))) {
                        return 'btnGridLoanOverdue';
                    }
                    return item.eql_id ? 'btnGridLoan' : 'btnGridModify';
                },
                callback: this.onLoan
            },
            {
                label: 'Dispose/Store',
                class: 'btnGridDispose',
                callback: this.onDispose
            }
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.actions)(this);
        this.dataAction = this.getEquipmentGrid;
        this.actionSelect = this.setLoanFilter;
        this.exportService = this.getEquipment;
        this.filter.identifier = this.identifier;

        this.init();
    }
}

EquipmentCalibrationGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'EquipmentCalibrationActions', '$filter'];

export const equipmentCalibrationGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onLoan: '&',
        onDispose: '&',
        onReset: '&',
        onStore: '&'
    },
    controller: EquipmentCalibrationGridController,
    ...gridTpl
};
