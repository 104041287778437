import API              from '../../../../../common/http/api.fetch';
import * as GRID        from '../../../../../common/grid/grid.constants';
import {responseMessage}from '../../../../../common/utils/util.notifier';
import { IDS }          from '../../settings-action-id.constants';

export const listSiteLocationsByHeadOffice = (filter) => {
    return  dispatch => {
        if(!filter || !filter.hof_uuid)
            return;
        return API.fetch({
            url: '/accreditation/scheme-locations',
            method: 'GET',
            params: {
                ...filter,
                asc_id:     filter.schemeId,
                schemeId:   undefined,
            }
        }).then(response => {
            dispatch({
                type:   GRID.GRID_FETCH,
                id:     IDS.accSettings,
                path:   IDS.siteLocationsGrid,
                items:  response.data.items.map(item => ({...item, id: item.slo_uuid, slo_uuid: undefined})),
                itemsCount: response.data.items_count,
            });
            dispatch({
                type: GRID.GRID_SELECT_ALL,
                id: IDS.accSettings,
                path: IDS.siteLocationsGrid,
                data: response.data.items.filter(item => item.enabled).map(item => item.slo_uuid)
            })
        }, error => {
            responseMessage(error);
        })
    }
}
