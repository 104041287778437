import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';

class EquipmentCalibrationChambersGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, EquipmentCalibrationActions, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.actions = EquipmentCalibrationActions;
        this.$filter = $filter;
    }

    $onInit() {
        this.module = 'equipmentCalibration';
        this.identifier = 'equipmentChambersGrid';
        this.autoload = true;
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                mapper: null},
            {title: 'Category',         mapper: 'category_name'},
            {title: 'Manufacturer',     mapper: 'manufacturer_name'},
            {title: 'Model',            mapper: 'model_name'},
            {title: 'Serial Number',    mapper: 'serial'},
            {title: 'Due Date',         mapper: 'due_date'}
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.actions)(this);
        this.dataAction = this.getAssets;

        this.init();
    }
}

EquipmentCalibrationChambersGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'EquipmentCalibrationActions', '$filter'];

export const equipmentCalibrationChambersGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onRemove: '&'
    },
    controller: EquipmentCalibrationChambersGridController,
    ...gridTpl
};
