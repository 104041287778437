/**
 * @module root/components/dms/import
 */
import * as STATES                  from './dms-import.states';
import { IDS }                      from './dms-import-action-id.constants';
import { dmsImportList}        from './list/list.component';
import { dmsImportListGrid}    from './list/list-grid.component';
import { dmsImportDetails}     from './details/details.component';

export const DMS_IMPORT_MODULE = angular.module(
    'migration.dms.import',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values(STATES).map(state => stateRegistry.register(state))
}]).component({
    dmsImportList,
    dmsImportListGrid,
    dmsImportDetails,
}).run(['$templateCache', ({ put }) => {
    put(IDS.app_dms_import_list,       '<dms-import-list></dms-import-list>');
    put(IDS.app_dms_import_details,    '<dms-import-details></dms-import-details>');
    put(IDS.app_dms_import_upload,     '<dms-import-upload></dms-import-upload>');
    put('./list-grid-filter.html', require('./list/list-grid-filter.html'));
}]);
