/* @ngInject */
export function SafetyTrainingPresentationReviewFactory(SafetyTrainingPresentation) {
    return function SafetyTrainingPresentationReview() {
        this.id = null;
        this.userUuid = null;
        this.presentation =  new SafetyTrainingPresentation();
        this.startDate = null;
        this.startDateStr = null;
        this.endDate = null;
        this.endDateStr = null;
        this.dueDate = null;
        this.dueDateStr = null;
        this.nextSection = null;
        this.firstName = null; //review employee first name
        this.lastName = null; //review employee last name
        this.userName = null; //review employee last name
        this.trainingName = null;
    };
}
