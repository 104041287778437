import API from '../../common/http/api.fetch';
import { API_BASE, DATE_FORMAT } from '../../common/utils/util.constants';
import { product } from '../../bootstrap.json';
import { formatDate } from '../../common/utils/util.date';
import { saveAs } from 'file-saver';
import first from 'lodash/first';
import pick from 'lodash/pick';
import isNull from 'lodash/isNull';

const STATUS_PROGRESS = {
    pen: 0,
    ntf: 2,
    ack: 4,
};

export default function PersonalDosimetryActions($http, Notifier, $filter) {
    function getReportProviders(id) {
        return (dispatch) => {
            return $http({
                url: API_BASE + '/personal-dosimetry/dose-report-providers',
                method: 'GET',
                params: {}
            }).then(({ data }) => {
                dispatch({
                    type: 'PD_SERVICE_PROVIDERS',
                    id,
                    providers: data.map((item) => ({
                        value: item.provider_code,
                        label: item.provider_name,
                    })),
                });
            })
        }
    }

    function importDoseReport(provider_code, dose_unit, data) {
        dose_unit = 'pdomsv';
        if (provider_code === 'pdoarp') dose_unit = 'pdousv';

        return dispatch => {
            return $http({
                url: API_BASE + '/personal-dosimetry/import/dose-report',
                method: 'POST',
                data: {
                    provider_code,
                    dose_unit: dose_unit,
                    document: first(data).filePath,
                }
            }).then(response => {
                dispatch({
                    type: 'REPORT_SELECTED',
                    id: 'personalDosimetryReportsDetailsGrid',
                    reportId: response.data.id
                });
                dispatch({
                    type: 'GRID_RELOAD',
                    id: 'personalDosimetryReportsDetailsGrid',
                });
                Notifier.success($filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.NOTIFY_IMPORT_REPORT_SUCCESS'));
            })
        }
    }

    function getReports(filter) {
        return dispatch => {
            return $http({
                url: API_BASE + '/personal-dosimetry/dose-reports',
                method: 'GET',
                params: {
                    provider_code:  filter.provider_code,
                    importDateFrom: formatDate(filter.importDateFrom),
                    importDateTo:   formatDate(filter.importDateTo),
                    loc_uuid:       filter.location,
                    offset:         filter.offset,
                    limit:          filter.limit,
                    exportFormat:   filter.exportFormat,
                    exportColumns:  filter.exportColumns
                }
            }).then((response) => {
                if (filter.exportFormat) return response.data;
                dispatch({
                    type: 'GRID_FETCH',
                    id: 'personalDosimetryReportsGrid',
                    items: response.data.items.map((item) => {
                        return {
                            id:                 item.imp_id,
                            uri:                item.document,
                            document:           item.document.split('/').pop(),
                            importDate:         formatDate(item.import_date, DATE_FORMAT.INPUT),
                            service_provider:   item.provider_name,
                            dose_unit:          item.dose_unit_name,
                            totalRecords:       item.total_records,
                            username:           item.username,
                        }
                    }),
                    itemsCount: response.data.total
                });
            })
        }
    }

    function getReportDetails(filter, grid = true) {
        return (dispatch) => {
            return $http({
                url: API_BASE + '/personal-dosimetry/dose-report-results',
                method: 'POST',
                data: {
                    reportId:   filter.reportId,
                    keyword:    filter.keyword,
                    location:   filter.location,
                    periodFrom: formatDate(filter.periodFrom),
                    periodTo:   formatDate(filter.periodTo),
                    doseLimits: filter.doseLimits ? 'critical' : null,
                    offset:     filter.offset,
                    limit:      filter.limit
                }
            }).then(({ data }) => {
                grid && dispatch({
                    type: 'GRID_FETCH',
                    id: 'personalDosimetryReportsDetailsGrid',
                    items: data.items.map(item => {
                        return {
                            id:                 item.dos_id,
                            uuid:               item.sub_uuid,
                            accountNum:         item.account_number,
                            accountName:        item.account_name,
                            participantNum:     item.participant_number,
                            idNum:              item.id_number,
                            participantName:    item.participant_name,
                            participantUuid:    item.employee_uuid,
                            participantEmail:   item.employee_email,
                            birthDate:          formatDate(item.date_of_birth, DATE_FORMAT.INPUT),
                            dosimeter:          item.dosimeter,
                            use:                item.dos_use,
                            periodFrom:         formatDate(item.period_begin_date, DATE_FORMAT.INPUT),
                            periodTo:           formatDate(item.period_end_date, DATE_FORMAT.INPUT),
                            inceptionDate:      isNull(item.inception_date) ? null : formatDate(item.inception_date.slice(0, -3), 'YYYY/MM'),
                            serialNum:          item.serial_number,
                            serviceProvider:    item.provider_name,
                            currentDDE:         item.current_dde,
                            currentLDE:         item.current_lde,
                            currentSDE:         item.current_sde,
                            quarterDDE:         item.quarter_dde,
                            quarterLDE:         item.quarter_lde,
                            quarterSDE:         item.quarter_sde,
                            yearDDE:            item.year_dde,
                            yearLDE:            item.year_lde,
                            yearSDE:            item.year_sde,
                            lifeDDE:            item.life_dde,
                            lifeLDE:            item.life_lde,
                            lifeSDE:            item.life_sde,
                            status:             item.status_name,
                            class:              item.measurement_result_type || '',
                            alertType:          item.measurement_result_type || 'none',
                            progress:           STATUS_PROGRESS[item.status_code],
                            progressLabel:      item.status_name,
                        }
                    }),
                    itemsCount: data.total
                });
                return data;
            })
        }
    }

    function getReportDetailsIdList() {
        return (dispatch, getState) => {
            const state = getState();
            const filter = state.personalDosimetry.personalDosimetryReportsDetailsGrid.filter;
            return $http({
                url: API_BASE + '/personal-dosimetry/dose-report-id-list',
                method: 'POST',
                data: {
                    reportId:   filter.reportId,
                    keyword:    filter.keyword,
                    location:   filter.location,
                    periodFrom: formatDate(filter.periodFrom),
                    periodTo:   formatDate(filter.periodTo)
                }
            }).then(response => {
                const data = _.get(response.data, 'items', response.data);
                dispatch({
                    type: 'GRID_SELECT_ALL',
                    id: 'personalDosimetryReportsDetailsGrid',
                    data: data.map(item => {
                        return String(item.dos_id);
                    })
                });
                return data.length;
            })
        }
    }

    function getIndividualDoseReport(state, id) {
        return dispatch => {
            return $http({
                url: API_BASE + '/personal-dosimetry/individual-dose-report',
                method: 'GET',
                params: {id: id}
            }).then(response => {
                const item = response.data[0];
                dispatch({
                    type: 'REPORT_RESULT_FETCH',
                    id: state,
                    data: {
                        imp_id:             item.imp_id,
                        id:                 item.dos_id,
                        uuid:               item.myrso_uuid,
                        accountNum:         item.account_number,
                        accountName:        item.account_name,
                        participantNum:     item.participant_number,
                        idNum:              item.id_number,
                        participantName:    item.participant_name,
                        participantUuid:    item.employee_uuid,
                        participantEmail:   item.employee_email,
                        signature:          item.participant_signature,
                        birthDate:          formatDate(item.date_of_birth, DATE_FORMAT.INPUT),
                        dosimeter:          item.dosimeter,
                        use:                item.use,
                        periodFrom:         formatDate(item.period_begin_date, DATE_FORMAT.INPUT),
                        periodTo:           formatDate(item.period_end_date, DATE_FORMAT.INPUT),
                        inceptionDate:      isNull(item.inception_date) ? null : formatDate(item.inception_date.slice(0, -3), 'YYYY/MM'),
                        serialNum:          item.serial_number,
                        serviceProvider:    item.provider_name,
                        providerCode:       item.provider_code,
                        currentDDE:         item.current_dde,
                        currentLDE:         item.current_lde,
                        currentSDE:         item.current_sde,
                        quarterDDE:         item.quarter_dde,
                        quarterLDE:         item.quarter_lde,
                        quarterSDE:         item.quarter_sde,
                        yearDDE:            item.year_dde,
                        yearLDE:            item.year_lde,
                        yearSDE:            item.year_sde,
                        lifeDDE:            item.life_dde,
                        lifeLDE:            item.life_lde,
                        lifeSDE:            item.life_sde,
                        monthlyThresholdLimit:   item.monthly_th_limit,
                        quarterlyThresholdLimit: item.quarterly_th_limit,
                        yearlyThresholdLimit:    item.yearly_th_limit,
                        monthlyAbsoluteLimit:    item.monthly_ab_limit,
                        quarterlyAbsoluteLimit:  item.quarterly_ab_limit,
                        yearlyAbsoluteLimit:     item.yearly_ab_limit,
                        status:                  item.status_name,
                        statusCode:              item.status_code,
                        progress:                STATUS_PROGRESS[item.status_code],
                        measurement_result:      item.measurement_result ? JSON.parse(item.measurement_result) : null,
                        measurement_result_type: item.measurement_result_type,
                    }
                });
            }, error => {
                Notifier.responseMessage(error);
            })
        }
    }

    function removeDoseReport(id) {
        return dispatch => {
            $http({
                url: API_BASE + '/personal-dosimetry/dose-report-remove',
                method: 'GET',
                params: {id: id}
            }).then(response => {
                dispatch({
                    type: 'GRID_RELOAD',
                    id: 'personalDosimetryReportsGrid',
                });
            }, error => {
                Notifier.responseMessage(error);
            })
        }
    }

    function signDoseReport(id, signature) {
        return (dispatch) => {
            return $http({
                url: API_BASE + '/personal-dosimetry/participant-signing',
                method: 'POST',
                data: {
                    dos_id: parseInt(id),
                    signature,
                }
            }).then(({ data }) => {
                dispatch({
                    type: 'GRID_RELOAD',
                    id: 'personalDosimetryReportsDetailsGrid',
                });
                if (data.status === 0) {
                    return true;
                } else {
                    Notifier.error('System Error!');
                    return false;
                }
            }, (err) => {
                Notifier.responseMessage(err);
                return false;
            });
        }
    }

    function generateParticipantReport(id, signature, num) {
        return dispatch => {
            dispatch({
                type: 'GENERATE_PDF',
                id: 'personalDosimetryReportsDetailsResult'
            });
            return $http({
                url: API_BASE + '/personal-dosimetry/generate/participant-report',
                method: 'POST',
                responseType: 'arraybuffer',
                data: { id, signature }
            }).then(response => {
                Notifier.success($filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.NOTIFY_DOWNLOAD_SUCCESS'));
                saveAs(new Blob([response.data], {type: 'application/pdf'}), `${product}-PersonalDosimetry-ParticipantReport-${num}.pdf`);
            })
        }
    }

    function searchEmployees(keyword, location) {
        return (dispatch) => {
            return API.fetch({
                url: `/employees`,
                method: 'GET',
                params: {
                    keyword,
                    status: 'act',
                    licences: false,
                    offset: 0,
                    limit: 20,
                    location,
                }
            }).then(response => {
                dispatch({
                    id: 'personalDosimetryParticipant',
                    type: 'PARTICIPANT_SEARCH',
                    data: response.data,
                })
            });
        }
    }

    function sendNotification(id, measurement, email) {
        return (dispatch) => {
            return $http({
                url: API_BASE + '/personal-dosimetry/send-notification',
                method: 'POST',
                params: { id, measurement, email }
            }).then(() => {
                Notifier.success($filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.RESULT.SEND_NOTIFICATION_SUCCESS'));
                dispatch({
                    type: 'GRID_RELOAD',
                    id: 'personalDosimetryReportsDetailsGrid',
                });
            }, (err) => {
                Notifier.responseMessage(err);
            })
        }
    }

    function sendNotificationGroup(data) {
        return (dispatch) => {
            return $http({
                url: API_BASE + '/personal-dosimetry/send-notification-group',
                method: 'POST',
                data,
            }).then(() => {
                Notifier.success($filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.RESULT.SEND_NOTIFICATION_SUCCESS'));
                dispatch({
                    type: 'GRID_RELOAD',
                    id: 'personalDosimetryReportsDetailsGrid',
                });
            }, (err) => {
                Notifier.responseMessage(err);
            })
        }
    }

    function getSettings(filter) {
        return (dispatch) => {
            return $http({
                url: API_BASE + '/personal-dosimetry/settings',
                method: 'GET',
                params: pick(filter, [
                    'default_dose_limits_only',
                    'loc_uuid',
                    'provider_code',
                    'bty_code',
                    'sty_id',
                    'offset',
                    'limit',
                ]),
            }).then(({ data }) => {
                if (!filter.default_dose_limits_only) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'personalDosimetrySettingsGrid',
                        items: data.items,
                        itemsCount: data.items_count
                    });
                }
                return data;
            });
        }
    }

    function getSettingsLimit(pdl_id) {
        return (dispatch) => {
            return $http({
                url: API_BASE + '/personal-dosimetry/settings/limit',
                method: 'GET',
                params: { pdl_id }
            }).then(({ data }) => {
                dispatch({
                    type: 'PD_LIMIT_DETAILS',
                    id: 'personalDosimetry',
                    data,
                });
            });
        }
    }

    function getEmployeeOccupations() {
        return (dispatch) => {
            return $http({
                url: API_BASE + '/occupations',
                method: 'GET',
                params: {}
            }).then(({ data }) => {
                dispatch({
                    type: 'PD_EMPLOYEE_OCCUPATIONS',
                    id: 'personalDosimetry',
                    occupations: data.map((item) => ({
                        value: item.ocu_id,
                        label: item.ocu_name,
                    })),
                });
            })
        }
    }

    function createSettingsLimit(data) {
        return (dispatch) => {
            return $http({
                url: API_BASE + '/personal-dosimetry/settings/limit/create',
                method: 'POST',
                data,
            }).then((res) => {
                dispatch({
                    type: 'GRID_RELOAD',
                    id: 'personalDosimetrySettingsGrid',
                });
                return res;
            });
        }
    }

    function updateSettingsLimit(data) {
        return (dispatch) => {
            return $http({
                url: API_BASE + '/personal-dosimetry/settings/limit/update',
                method: 'POST',
                data,
            }).then((res) => {
                dispatch({
                    type: 'GRID_RELOAD',
                    id: 'personalDosimetrySettingsGrid',
                });
                return res;
            });
        }
    }

    function deleteSettingsLimit(pdl_id) {
        return (dispatch) => {
            return $http({
                url: API_BASE + '/personal-dosimetry/settings/limit/delete',
                method: 'GET',
                params: { pdl_id }
            }).then(() => {
                dispatch({
                    type: 'GRID_RELOAD',
                    id: 'personalDosimetrySettingsGrid',
                });
            });
        }
    }

    return {
        getReportProviders,
        importDoseReport,
        getReports,
        getReportDetails,
        getReportDetailsIdList,
        getIndividualDoseReport,
        removeDoseReport,
        signDoseReport,
        generateParticipantReport,
        searchEmployees,
        sendNotification,
        sendNotificationGroup,
        getSettings,
        getSettingsLimit,
        getEmployeeOccupations,
        createSettingsLimit,
        updateSettingsLimit,
        deleteSettingsLimit,
    }
}

PersonalDosimetryActions.$inject = ['$http', 'Notifier', '$filter'];
