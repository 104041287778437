export class SystemAlertsController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.NavigationService = NavigationService;
        this.redirect = null;
        this.color = null;
    }

    $onInit() {
        this.unsubscribeModule = this.$ngRedux.connect((state) => ({
            module: state.navigation.module
        }), {})(this);

        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.moduleId = navConfig.id;

        switch (this.module) {
            case 'equipment':
                this.identifier = 'equipmentAlertsGrid';
                break;
            case 'employees':
                this.identifier = 'employeesAlertsGrid';
                break;
            case 'system':
                this.identifier = 'systemAlertsGrid';
                break;
        }
    }

    selectItem({ item }) {
        switch (item.category) {
            case 'emp':
                this.redirect = 'employees';
                this.color = 'e60084';
                this.$state.go('app.employees.manage', { uuid: item.employeeUuid });
                break;
            case 'xra':
                this.redirect = 'equipment';
                this.color = '4caf50';
                this.$state.go('app.xray.manage', { id: item.equipmentId });
                break;
            case 'mam':
                this.redirect = 'equipment';
                this.color = 'fd9cd7';
                this.$state.go('app.mammo.manage', { id: item.equipmentId });
                break;
            case 'uso':
                this.redirect = 'equipment';
                this.color = '00bcd4';
                this.$state.go('app.ultrasound.manage', { id: item.equipmentId });
                break;
            case 'las':
                this.redirect = 'equipment';
                this.color = 'f44336';
                this.$state.go('app.lasers.manage', { id: item.equipmentId });
                break;
        }
    }

    $onDestroy() {
        this.unsubscribeModule && this.unsubscribeModule();
        if (this.redirect) {
            this.$ngRedux.dispatch({
                type: 'INIT_MODULE',
                module: this.redirect,
                color: this.color,
            });
        }
    }
}

export const systemAlerts = {
    controller: SystemAlertsController,
    template: `
        <system-alerts-grid identifier="{{$ctrl.identifier}}"
                           module-id="$ctrl.moduleId"
                           icon="alerts.svg"
                           on-select="$ctrl.selectItem($event)">
        </system-alerts-grid>
    `
};
