/** @module root/components/accreditation/document */

import * as STATES                      from './add-document.states';
import { IDS }                          from './add-document-action-id.constants';
import { accAddNewDocument }            from './new-document/new-document.component';
import { accAddFromExisting }           from './from-existing/from-existing.component';
import { accAddFromExistingGrid }       from './from-existing/from-existing-grid.component';

export const ADD_DOCUMENT_MODULE = angular.module(
    'migration.accreditation.add-document',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
        Object.values({
            ...STATES,
        }).map(state => stateRegistry.register(state));
    }]
).component({
    accAddNewDocument,
    accAddFromExisting,
    accAddFromExistingGrid,

}).run(['$templateCache', ({ put }) => {
    put(IDS.app_accreditation_scheme_add_document_from_existing,    '<acc-add-from-existing></acc-add-from-existing>')
    put(IDS.app_accreditation_scheme_add_document_new_document,     '<acc-add-new-document></acc-add-new-document>')
    put('./from-existing-grid-filter.html',   require('./from-existing/from-existing-grid-filter.html'));
}]);
