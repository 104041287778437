/**
 * Creates a new AdministrationNotificationSettingsTimelineController.
 * @class
 * @memberOf module:root/legacy/components/administration
 */
class AdministrationNotificationSettingsTimelineController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param $timeout {service} {@link https://docs.angularjs.org/api/ng/service/$timeout|AngularJS $timeout}
     * @param NavigationService {service} {@link module:root/common.NavigationService|NavigationService}
     * @param Notifier {service} {@link Notifier}
     * @param $mdDialog {service}
     * @param AdministrationNotificationSettingsActions {service}
     */
    constructor($ngRedux, $state, $scope, $mdDialog, $timeout, NavigationService, Notifier, AdministrationNotificationSettingsActions) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        this.$timeout = $timeout;
        this.NavigationService = NavigationService;
        this.Notifier = Notifier;
        this.actions = AdministrationNotificationSettingsActions;
    }

    /**
     * @method $onInit
     * @memberof module:root/legacy/components/administration.AdministrationNotificationSettingsTimelineController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);

        this.identifier = 'app.administration.notificationSettings';
        this.state = 'app.administration.notificationSettings.timeline';
        this.repeat = 0;

        this.getSubjectTypes();
    }

    /**
     * @method saveNotificationSettingsTimeline
     * @memberOf module:root/components/administration.AdministrationNotificationSettingsTimelineController
     */
    saveNotificationSettingsTimeline = () => {
        this.$scope.$broadcast('show-errors-check-validity', '$ctrl.notificationSettingsTimelineForm');
        if (this.notificationSettingsTimelineForm.$valid) {
            this.addNotificationSettingsTimeline(this.timeline).then(() => {
                this.Notifier.success('New Timeline has been successfully added');

                this.$timeout(() => {
                    this.notificationSettingsTimelineForm.$setPristine();
                    this.notificationSettingsTimelineForm.$setUntouched();
                })
            }, error => {
                this.Notifier.responseMessage(error);
            });
        }
        else this.Notifier.error('Please fill in the form fields');
    };

    /**
     * @method onRemove
     * @param event {object}
     * @memberOf module:root/legacy/components/administration.AdministrationNotificationSettingsTimelineController
     */
    onRemove = (event) => {
        if (!this.notification.derived) {
            const confirm = this.$mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Timeline will be deleted.')
                .ariaLabel('Delete Timeline')
                .ok('Yes')
                .cancel('No');

            this.removeDialog = this.$mdDialog.show(confirm).then(() => {
                this.removeNotificationSettingsTimeline(event).then(() => {
                    this.Notifier.success('Timeline is removed!');
                });
            });
        } else {
            const alert = this.$mdDialog.alert()
                .title('Alert')
                .textContent('This timline is from derived notification settings and can`t be deleted.')
                .ok('Close');

            this.removeDialog = this.$mdDialog.show(alert);
        }
    };

    /**
     * @method mapState
     * @param state {object} Redux Store
     * @return {object}
     * @memberof module:root/components/administration.AdministrationNotificationSettingsTimelineController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapState(state) {
        return {
            notification: state.administration.administrationNotificationSettings,
            location: state.administration.administrationNotificationSettingsGrid.filter.location,
            title: state.administration.administrationNotificationSettings.locationName + ' - ' + state.administration.administrationNotificationSettings.notificationTypeName,
            timeline: state.administration.administrationNotificationSettingsTimeline
        }
    }

    /**
     * @method $onDestroy
     * @memberof module:root/components/administration.AdministrationNotificationSettingsTimelineController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.unsubscribe();
        if (this.removeDialog) this.$mdDialog.hide(this.removeDialog);
    }
}

AdministrationNotificationSettingsTimelineController.$inject = ['$ngRedux', '$state', '$scope', '$mdDialog', '$timeout', 'NavigationService', 'Notifier', 'AdministrationNotificationSettingsActions'];

export const administrationNotificationSettingsTimeline = {
    controller: AdministrationNotificationSettingsTimelineController,
    template: require('./administration-notification-settings-timeline.html')
};
