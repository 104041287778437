/* @ngInject */
export function LaserFactory(Equipment, Vendor, LaserMode, Location, LaserClass, LaserUnit, LaserType, LaserHazardEvaluation, LaserPermitApplication, RegistrationData, CertificateData, Helpers, DATE_FORMAT) {
    return function Laser() {

        Equipment.call(this);

        this.barcode = null;
        this.waveLength = null;
        this.status = null;
        this.vendor = new Vendor();
        this.location = new Location();
        this.class = new LaserClass();
        this.registrationData = new RegistrationData();
        this.certificateData = new CertificateData();
        this.mode = new LaserMode();
        this.equipmentInstallationDate = null;
        this.unit = new LaserUnit();
        this.laserType = new LaserType();
        this.hazardEvaluation = new LaserHazardEvaluation();

        this.formatEquipmentInstallationDate = function() {
            return Helpers.formatDate(this.equipmentInstallationDate, DATE_FORMAT.INPUT);
        };
    };
}
