import API from '../common/http/api.fetch';
import * as TYPE from '../components/radioactive-materials/radioactive-materials.actions.types';
import radioactiveMaterialsReducer from '../components/radioactive-materials/radioactive-materials.reducer';
import { tabs } from '../components/radioactive-materials/radioactive-materials.tabs';
import { TabsResolver } from '../common/tabs/tabs.resolver';
import { GridReducer } from '../common/grid/grid.reducer';
import { getGridSort } from '../common/grid/grid.utils';
import { RM_TEST_USE_TYPE_SELECT } from '../components/radioactive-materials/radioactive-materials.actions.types';
import { formatDate } from '../common/utils/util.date';
import { DATE_FORMAT } from '../common/utils/util.constants';
import get from 'lodash/get';
import set from 'lodash/set';
import { resolveSignature } from '../common/utils/util.data';

const initialState = {
    ...radioactiveMaterialsReducer
};

export default (state = initialState, action) => {
    if (Object.keys(tabs).includes(action.id)) {
        return TabsResolver(state, action);
    }
    switch (action.type) {
        case TYPE.NEXT_ID:
            if (action.id === 'rmWaste' || action.id === 'rmTest') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        visualId: action.visualId
                    }
                }
            }
        case 'LOCATION_SELECTED':
            if (action.id === 'rmWaste' || action.id === 'rmTest') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        location: action.uuid,
                        locationName: action.locationName,
                        accountId: action.accountId
                    }
                }
            }
            if (action.id === 'rmWasteGrid' || action.id === 'rmTestGrid') {
                return {
                    ...state,
                    [action.id]: set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.location', action.uuid)
                }
            }
        case 'QR_CODE':
            if (action.id === 'rmWaste' || action.id === 'rmTest') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        qrcode: action.qrcode
                    }
                }
            }
        case TYPE.RM_ISOTOPES:
        case TYPE.RM_ATOMIC_NUMS:
        case TYPE.RM_WASTE_TYPES:
        case TYPE.RM_WASTE_DISPOSAL_REACH:
        case TYPE.RM_TEST_TYPES:
        case TYPE.RM_TEST_USE_TYPES:
        case TYPE.RM_TEST_MANUFACTURERS:
        case TYPE.RM_TEST_MANUFACTURER_MODELS:
        case TYPE.RM_TEST_DISPOSAL_COMPANIES:
        case TYPE.RM_UNITS:
            if (action.id === 'rmWaste' || action.id === 'rmTest') {
                const reset = action.reset || {};
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.items,
                        ...reset
                    }
                }
            }
        case TYPE.RM_HALF_LIFE:
        case TYPE.RM_WASTE_DISPOSAL_REACH_ESTIMATE:
        case TYPE.RM_TEST_ACTIVITY:
            if (action.id === 'rmWaste' || action.id === 'rmTest') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.value
                    }
                }
            }
        case TYPE.RM_WASTE_DETAILS:
            if (action.id === 'rmWaste') {
                return {
                    ...state,
                    rmWaste: {
                        ...state.rmWaste,
                        id: action.data.id,
                        locations: action.locations,
                        visualId: action.data.radioactive_material_id,
                        wasteType: action.data.wty_id,
                        isotope: action.data.iso_id,
                        atomicNum: action.data.ato_id,
                        halfLife: action.data.half_life,
                        binNum: action.data.bin_no,
                        dateOfClosure: action.data.date_of_closure,
                        dateOfClosureDose: action.data.dose_measurement_closure,
                        closureUnitType: action.data.closure_si_non_si,
                        closureUnit: action.data.closure_unit_id,
                        disposalReach: action.data.dose_to_reach_id,
                        disposalReachDate: action.data.estimate_date_of_disposal,
                        dateOfDisposal: action.data.date_of_disposal,
                        dateOfDisposalDose: action.data.disposal_dose,
                        disposalUnitType: action.data.disposal_si_non_si,
                        disposalUnit: action.data.disposal_unit_id,
                        permitLicenseNo: action.data.license_no,
                        camera: null,
                        gallery: action.gallery,
                        qrcode: API.img(`/qrcode/${action.data.radioactive_material_id}.png`),
                        signature: resolveSignature(action.data.signature),
                        firstName: action.data.first_name,
                        lastName: action.data.last_name
                    }
                }
            }
        case TYPE.RM_WASTE_RESET:
            if (action.id === 'rmWaste') {
                return {
                    ...state,
                    rmWaste: {
                        ...state.rmWaste,
                        id: null,
                        location: null,
                        locationName: null,
                        locations: [],
                        accountId: null,
                        visualId: null,
                        wasteType: null,
                        isotope: null,
                        atomicNum: null,
                        halfLife: null,
                        binNum: null,
                        dateOfClosure: null,
                        dateOfClosureDose: null,
                        closureUnitType: 'siu',
                        closureUnit: null,
                        disposalReach: null,
                        disposalReachDate: null,
                        dateOfDisposal: null,
                        dateOfDisposalDose: null,
                        disposalUnitType: 'siu',
                        disposalUnit: null,
                        permitLicenseNo: null,
                        camera: null,
                        gallery: [],
                        qrcode: null,
                        signature: null,
                        firstName: null,
                        lastName: null
                    }
                }
            }
        case TYPE.RM_TEST_DETAILS:
            if (action.id === 'rmTest') {
                return {
                    ...state,
                    rmTest: {
                        ...state.rmTest,
                        id: action.data.id,
                        location: action.data.loc_uuid,
                        locations: action.locations,
                        visualId: action.data.radioactive_material_id,
                        isotope: action.data.iso_id,
                        isotopeName: action.data.isotope,
                        atomicNum: action.data.ato_id,
                        halfLife: action.data.half_life,
                        sealed: parseInt(action.data.sealed_unsealed),
                        testType: action.data.sty_id,
                        testTypeName: action.data.source_type,
                        activityDate: action.data.activity_date,
                        activityDateFormatted: formatDate(action.data.activity_date, DATE_FORMAT.INPUT),
                        activity: action.data.activity,
                        activityWithUnit: `${action.data.activity} ${action.data.unit_name}`,
                        unitType: action.data.si_non_si,
                        unit: action.data.unit_id,
                        useType: action.data.upt_code,
                        currentActivity: action.data.current_activity,
                        exemptionQuantity: parseInt(action.data.one_eq),
                        wipeTesting: parseInt(action.data.wipe_testing),
                        wipeTestingDueDate: action.data.next_wipe_testing_due_date,
                        calibrationAndService: parseInt(action.data.service_and_calibration),
                        calibrationDueDate: action.data.next_calibration_due_date,
                        serviceDueDate: action.data.next_service_due_date,
                        assetId: action.data.equipment_id,
                        manufacturer: action.data.man_id,
                        manufacturerName: action.data.manufacturer,
                        model: action.data.emo_id ? {
                            id: action.data.emo_id,
                            name: action.data.model
                        } : null,
                        modelSerial: action.data.serial_number,
                        permitLicenseNo: action.data.license_no,
                        camera: null,
                        gallery: action.gallery,
                        qrcode: API.img(`/qrcode/${action.data.radioactive_material_id}.png`),
                        disposalDate: action.data.disposal_date,
                        //disposalOffSite: action.data.disposal_off_site,
                        disposalOffSite: action.data.disposal_off_site ? action.data.disposal_off_site : 1,
                        disposalCompany: action.data.disposal_company_id ? {
                            id: action.data.disposal_company_id,
                            name: action.data.disposal_company_name
                        } : null,
                        disposalCompanyName: action.data.disposal_company_name,
                        disposalCompanyAddress: action.data.disposal_company_address,
                        disposalCompanyEmail: action.data.disposal_company_email,
                        disposalCompanyPhone: action.data.disposal_company_contact,
                        disposalComments: action.data.disposal_comment,
                        disposalDocument: action.data.disposal_doc_url,
                        status: action.data.status_code,
                        locationLicence: action.data.lic_id,
                        manufacturerOfRadiationDevice: action.data.radiation_device_manufacturer_id,
                        manufacturerNameOfRadiationDevice: action.data.radiation_device_manufacturer,
                        modelOfRadiationDevice: action.data.radiation_device_model_id ? {
                            id: action.data.radiation_device_model_id,
                            name: action.data.radiation_device_model_name
                        } : null,
                        modelSerialOfRadiationDevice: action.data.radiation_device_serial_number
                    },
                    rmTestDocumentsGrid: {
                        ...state.rmTestDocumentsGrid,
                        filter: {
                            ...state.rmTestDocumentsGrid.filter,
                            testId: action.data.id
                        }
                    }
                }
            }
        case TYPE.RM_TEST_RESET:
            if (action.id === 'rmTest') {
                return {
                    ...state,
                    rmTest: {
                        ...state.rmTest,
                        id: null,
                        location: null,
                        locationName: null,
                        locations: [],
                        accountId: null,
                        visualId: null,
                        isotope: null,
                        isotopeName: null,
                        atomicNum: null,
                        halfLife: null,
                        sealed: 1,
                        testType: null,
                        testTypeName: null,
                        activityDate: null,
                        activityDateFormatted: null,
                        activity: null,
                        activityWithUnit: null,
                        unitType: 'siu',
                        unit: null,
                        currentActivity: null,
                        useType: null,
                        exemptionQuantity: 0,
                        wipeTesting: 0,
                        wipeTestingDueDate: null,
                        calibrationAndService: 0,
                        calibrationDueDate: null,
                        serviceDueDate: null,
                        assetId: null,
                        manufacturer: null,
                        manufacturerName: null,
                        model: null,
                        models: [],
                        modelSerial: null,
                        permitLicenseNo: null,
                        camera: null,
                        gallery: [],
                        qrcode: null,
                        disposalDate: null,
                        disposalOffSite: 1,
                        disposalCompany: null,
                        disposalCompanies: [],
                        disposalCompanyName: null,
                        disposalCompanyAddress: null,
                        disposalCompanyEmail: null,
                        disposalCompanyPhone: null,
                        disposalComments: null,
                        disposalDocument: null,
                        status: null,
                        documentTypes: [],
                        documentStatusList: [],
                        locationLicence: null,
                        locationLicences: [],
                        manufacturerOfRadiationDevice: null,
                        manufacturerNameOfRadiationDevice: null,
                        modelOfRadiationDevice: null,
                        modelsOfRadiationDevice: [],
                        modelSerialOfRadiationDevice: null
                    }
                }
            }
        case RM_TEST_USE_TYPE_SELECT: {
            if (action.id === 'rmTest') {
                return {
                    ...state,
                    rmTest: {
                        ...state.rmTest,
                        wipeTesting: action.wipeTesting,
                        wipeTestingDueDate: action.dueDate
                    }
                };
            }
        }
        case 'REST_RM_TEST_DOCUMENT': {
            if (action.id === 'rmTestDocument') {
                return {
                    ...state,
                    rmTestDocument: {
                        ...state.rmTestDocument,
                        documentDueDate: null,
                        document: null,
                        type: null
                    }
                }
            }
        }
        case 'INPUT_SELECT':
        case 'INPUT_TEXT_CHANGE':
        case 'INPUT_TEXT_AREA_CHANGE':
        case 'INPUT_RADIO_CHANGE':
        case 'INPUT_EMAIL_CHANGE':
        case 'INPUT_PHONE_CHANGE':
        case 'DATE_SELECTED':
        case 'CHECKBOX_SELECTED':
        case 'RM_WIPE_TEST_CALCULATE_RESULT':
        case 'RM_WIPE_TEST_CALCULATE_DIFFERENCE':
        case 'INPUT_SIGNATURE':
            if (action.id === 'rmWaste' || action.id === 'rmTest' || action.id === 'rmTestWipeTest' || action.id === 'rmTestDocument') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.input
                    }
                }
            }
            if (action.id === 'rmWasteGrid' || action.id === 'rmTestGrid' || action.id === 'rmTestDocumentsGrid') {
                return {
                    ...state,
                    [action.id]: set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)
                }
            }
        case 'AUTOCOMPLETE_SELECTED':
            if (action.id === 'rmTest' || action.id === 'rmTestGrid') {
                if (action.path === 'filter.model') {
                    return {
                        ...state,
                        rmTestGrid: set(GridReducer(state.rmTestGrid, {type: 'PAGINATE'}), action.path, action.input)
                    }
                } else if (action.path === 'disposalCompany') {
                    return {
                        ...state,
                        [action.id]: {
                            ...state[action.id],
                            [action.path]: action.item,
                            disposalCompanyName: get(action.item, 'name', null),
                            disposalCompanyAddress: get(action.item, 'address', null),
                            disposalCompanyEmail: get(action.item, 'email', null),
                            disposalCompanyPhone: get(action.item, 'phone', null)
                        }
                    }
                } else {
                    return {
                        ...state,
                        [action.id]: {
                            ...state[action.id],
                            [action.path]: action.item
                        }
                    }
                }
            }
        case 'GRID_INIT':
        case 'GRID_RESET':
            if (action.id === 'rmWasteGrid') {
                const wasteGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...wasteGrid,
                        filter: {
                            ...wasteGrid.filter,
                            location: null,
                            isotope: null,
                            binNum: null,
                            dateOfClosureFrom: null,
                            dateOfClosureTo: null,
                            dateOfDisposalFrom: null,
                            dateOfDisposalTo: null
                        }
                    }
                }
            }
            if (action.id === 'rmTestGrid') {
                const testGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...testGrid,
                        activeCount: 0,
                        archiveCount: 0,
                        filter: {
                            ...testGrid.filter,
                            location: null,
                            type: null,
                            isotope: null,
                            activityFrom: null,
                            activityTo: null,
                            unit: null,
                            unitType: 'siu',
                            wipeTestFrom: null,
                            wipeTestTo: null,
                            serviceFrom: null,
                            serviceTo: null,
                            manufacturer: null,
                            model: null,
                            permitLicenseNo: null,
                            wipeTest: null,
                            sort: null,
                            sealed: null,
                            status: 'act'
                        }
                    },
                    rmTest: {
                        ...state.rmTest,
                        models: [],
                        modelsOfRadiationDevice: []
                    }
                }
            }
            if (action.id === 'rmTestDocumentsGrid') {
                const testDocumentsGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...testDocumentsGrid,
                        filter: {
                            ...testDocumentsGrid.filter,
                            documentTypeId: null,
                            documentStatusId: null
                        }
                    }
                }
            }
        case 'GRID_SORT':
            if (action.id === 'rmTestGrid') {
                const testGrid = GridReducer(state[action.id], action);
                const testGridSort = getGridSort(state[action.id].filter, action);
                return {
                    ...state,
                    [action.id]: {
                        ...testGrid,
                        filter: {
                            ...testGrid.filter,
                            sort: testGridSort
                        }
                    }
                }
            }
        case 'SHOW_LOADING':
        case 'GRID_FETCH':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_RESET_FILTER':
            if (action.id === 'rmWasteGrid' || action.id === 'rmTestDocumentsGrid') {
                return {
                    ...state,
                    [action.id]: GridReducer(state[action.id], action)
                }
            }
            if (action.id === 'rmTestGrid') {
                return Object.assign({}, state, set({}, action.id, Object.assign({}, GridReducer(state.rmTestGrid, action), {
                    activeCount: action.activeCount,
                    archiveCount: action.archiveCount
                })));
            }
        case 'EQUIPMENT_SWITCH':
            if (action.id === 'rmTestGrid') {
                return Object.assign({}, state, set({}, action.id, set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.status', action.status)));
            }
        case 'FILE_UPLOAD': {
            if (action.id === 'rmWaste' || action.id === 'rmTest') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        ...(action.path === 'disposalDocument'
                            ? { [action.path]: action.input }
                            : {
                                gallery: [...state[action.id].gallery, {
                                    id: parseInt(action.itemId),
                                    url: API.img(`${action.input}`),
                                    width: action.width,
                                    height: action.height
                                }]
                            }),
                    }
                }
            }
        }
        case 'RM_RESET_WIPE_TEST_CALCULATION_INPUT':
            if (action.id === 'rmTestWipeTest') {
                return {
                    ...state,
                    rmTestWipeTest: {
                        ...state.rmTestWipeTest,
                        measuredCount: null,
                        backgroundCount: null
                    }
                }
            }
        case 'RM_RESET_WIPE_TEST_CALCULATION':
            if (action.id === 'rmTestWipeTest') {
                return {
                    ...state,
                    rmTestWipeTest: {
                        ...state.rmTestWipeTest,
                        calculatedResult: null,
                        difference: null
                    }
                }
            }
        case TYPE.RM_TEST_WIPE_TEST_DETAILS:
            if (action.id === 'rmTestWipeTest') {
                return {
                    ...state,
                    rmTestWipeTest: {
                        ...state.rmTestWipeTest,
                        id: parseInt(action.data.id),
                        wipeTestId: parseInt(action.data.wpt_id),
                        wipeTestSystem: action.data.wt_system,
                        deviation: action.data.deviation,

                        wearingLabcoat: parseInt(action.data.labcoat),
                        wearingGloves: parseInt(action.data.gloves),
                        wearingTLDBadge: parseInt(action.data.tld_badge),
                        cottonBud: parseInt(action.data.cotton_bud),
                        filterPaperWipes: parseInt(action.data.filter_paper),
                        plasticBag: parseInt(action.data.container),
                        forceps: parseInt(action.data.forceps),
                        measureCalibrationSources: parseInt(action.data.measure_h3),
                        countingTime: parseInt(action.data.count_time),
                        eu152Calibration: parseInt(action.data.eu152_calibration),
                        cs137Calibration: parseInt(action.data.cs137_calibration),
                        cs137ConstancyCheck: parseInt(action.data.cs137_constancy_check),
                        MCAButton: parseInt(action.data.mca_button),

                        measuredActivityWithin20: parseInt(action.data.measured_activity_within20),
                        removedByBiomedicalEngineer: action.data.removed_by_biomedical_engineer,
                        min30warmup: parseInt(action.data.min30_warmup),

                        backgroundCount: action.data.background_count,
                        measuredCount: action.data.measured_count,
                        calculatedResult: action.data.calc_result,
                        difference: action.data.difference,
                        firstName: action.data.first_name,
                        lastName: action.data.last_name,
                        comment: action.data.comment,

                        signature: resolveSignature(action.data.signature),
                        approvalDate: action.data.aproval_date
                    }
                }
            }
        case TYPE.RM_TEST_WIPE_TEST_EFFICIENCY:
            if (action.id === 'rmTestWipeTest') {
                return {
                    ...state,
                    rmTestWipeTest: {
                        ...state.rmTestWipeTest,
                        efficiency: {
                            ...state.efficiency,
                            efficiencyLsc: action.data.efficiency_lcs,
                            efficiencyCaptus: action.data.effciency_captus
                        }
                    }
                }
            }
        case TYPE.RM_TEST_WIPE_TEST_RESET:
            if (action.id === 'rmTestWipeTest') {
                return {
                    ...state,
                    rmTestWipeTest: {
                        ...state.rmTestWipeTest,
                        backgroundCount: null,
                        measuredCount: null,
                        wipeTestId: null,
                        efficiencyLsc: null,
                        calculatedResult: null,
                        difference: null,
                        wearingLabcoat: 0,
                        wearingGloves: 0,
                        wearingTLDBadge: 0,
                        cottonBud: 0,
                        filterPaperWipes: 0,
                        plasticBag: 0,
                        forceps: 0,
                        removedByBiomedicalEngineer: null,
                        removedByBiomedicalEngineerData: [
                            {value: 1, label: 'Yes'},
                            {value: 0, label: 'No'}
                        ],
                        wipeTestSystem: null,
                        wipeTestSystemData: [
                            {value: 1, label: 'Beckman Coulter LSC '},
                            {value: 0, label: 'Captus 3000 Uptake system'}
                        ],
                        deviation: null,
                        deviationData: [
                            {value: 1, label: 'Yes'},
                            {value: 0, label: 'No'}
                        ],
                        measureCalibrationSources: null,
                        measuredActivityWithin20: null,
                        measuredActivityWithin20Data: [
                            {value: 1, label: 'Yes'},
                            {value: 0, label: 'No'}
                        ],
                        countingTime: null,
                        eu152Calibration: 0,
                        cs137Calibration: 0,
                        cs137ConstancyCheck: 0,
                        MCAButton: 0,
                        min30warmup: null,
                        signature: null,
                        approvalDate: null,
                        firstName: null,
                        lastName: null,
                        comment: null,
                        efficiency: {
                            ...state.efficiency,
                            efficiencyLsc: null,
                            efficiencyCaptus: null
                        }
                    }
                }
            }
        case 'WIPE_TEST_FILTER':
            if (action.id === 'rmTestGrid') {
                return {
                    ...state,
                    rmTestGrid: {
                        ...state.rmTestGrid,
                        filter: {
                            ...state.rmTestGrid.filter,
                            wipeTest: action.value || null
                        }
                    }
                };
            }
        case 'DEPARTMENT_TYPES':
            if (action.id === 'rmWaste' || action.id === 'rmTest') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        locationDepartments: action.items
                    }
                }
            }
        case 'DOCUMENT_TYPES':
            if (action.id === 'rmTest') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        documentTypes: action.items
                    }
                }
            }
        case 'DOCUMENT_STATUS_LIST':
            if (action.id === 'rmTest') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        documentStatusList: action.items
                    }
                }
            }
        case 'GET_LOCATION_LICENCES':
            if (action.id === 'rmTest' || action.id === 'administrationAccountsLicenceIsotope') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        locationLicences: action.items
                    }
                }
            }
        case 'RM_RESET_TEST_LICENCE_LIST':
            if (action.id === 'rmTest' || action.id === 'administrationAccountsLicenceIsotope') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        locationLicences: []
                    }
                }
            }
        case TYPE.RM_RESET_TEST_LICENCE:
            if (action.id === 'rmTest') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        locationLicence: null
                    }
                }
            }
        default:
            return state;
    }
}
