import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsDepartmentsController($ngRedux, $state, $uiRouterGlobals, $timeout, AdministrationActions) {
    var ctrl = this;
    var state = $state.current.name;
    var stateParams = _.clone($uiRouterGlobals.params);
    var unsubscribe = angular.noop;
    var onDepartment = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);

        var watchDepartment = watch($ngRedux.getState, 'administration.administrationAccountsDepartment.data.uuid');
        onDepartment = $ngRedux.subscribe(watchDepartment(function(uuid) {
            if (uuid) onDepartmentReady(uuid);
        }));

        if (_.get($uiRouterGlobals.params, 'hof', null)) {
            ctrl.getHeadOfficeDetails($uiRouterGlobals.params.hof);
        }

        if (_.get($uiRouterGlobals.params, 'slo', null)) {
            ctrl.getSiteLocationDetails($uiRouterGlobals.params.slo);
        }

        $ngRedux.dispatch({
            type: 'LOCATION_SELECTED',
            id: 'administrationAccountsDepartmentsGrid',
            slo: $uiRouterGlobals.params.slo
        });

        if (state === 'app.administration.accounts.departments.details' && ctrl.department.uuid)
            onDepartmentReady(ctrl.department.uuid);
    };

    ctrl.selectDepartment = function(event) {
        state = 'app.administration.accounts.departments.details';
        if (event.item.uuid === ctrl.department.uuid)
            onDepartmentReady(event.item.uuid);
        else
            ctrl.getDepartmentDetails(event.item.uuid, true);
    };

    ctrl.addDepartment = function() {
        state = 'app.administration.accounts.departments.details';
        ctrl.getDepartmentTypes('administrationAccountsDepartment', $uiRouterGlobals.params.slo);
        onDepartmentReady();
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onDepartment();
    };

    function onDepartmentReady(uuid) {
        if (uuid) {
            $ngRedux.dispatch({
                type: 'TAB_ENABLE',
                id: 'app.administration.accounts.departments',
                state: [
                    'app.administration.accounts.departments.details',
                    'app.administration.accounts.departments.contacts',
                    'app.administration.accounts.departments.equipment',
                    'app.administration.accounts.departments.employees'
                ]
            });
            if (state === 'app.administration.accounts.departments.contactDetails') {
                $ngRedux.dispatch({
                    type: 'TAB_SHOW',
                    id: 'app.administration.accounts.departments',
                    state: ['app.administration.accounts.departments.contactDetails'],
                    stateParams: {
                        hof: $uiRouterGlobals.params.hof,
                        slo: $uiRouterGlobals.params.slo,
                        dep: uuid,
                        rsc: stateParams.rsc
                    }
                });
                if (stateParams.rsc) {
                    $ngRedux.dispatch({
                        type: 'TAB_LOADING',
                        id: 'app.administration.accounts.departments',
                        state: 'app.administration.accounts.departments.contactDetails',
                        loading: true
                    });
                    ctrl.getRscDetails('administrationAccountsDepartmentRsc', stateParams.rsc);
                }
                return;
            }
        }
        else {
            $ngRedux.dispatch({
                type: 'TAB_ENABLE',
                id: 'app.administration.accounts.departments',
                state: ['app.administration.accounts.departments.details']
            });
        }

        $timeout(function() {
            $ngRedux.dispatch({
                type: 'TAB_SELECT',
                id: 'app.administration.accounts.departments',
                state: state,
                stateParams: {
                    hof: $uiRouterGlobals.params.hof,
                    slo: $uiRouterGlobals.params.slo,
                    dep: uuid
                }
            });
        }, 200);
    }

    function mapStateToThis(state) {
        return {
            department: state.administration.administrationAccountsDepartment.data,
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                'Departments'
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
