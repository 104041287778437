import { GridController } from "../../../../../common/grid/grid.controller";

/* @ngInject */
export function ProtectiveEquipmentTestsGridController($controller, $ngRedux, $state, NavigationService, ProtectiveEquipmentActions) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'protectiveEquipment';
        ctrl.identifier = 'protectiveEquipmentTestsGrid';
        ctrl.filterTemplate = './protective-equipment-tests-grid-filter.html';
        ctrl.autoload = false;
        ctrl.exportComponent = false;

        ctrl.columns = [
            {title: '#',                        mapper: null},
            {title: 'Equipment ID',             mapper: 'ppeEquipmentId'},
            {title: 'Location',                 mapper: 'location'},
            {title: 'Type',                     mapper: 'equipmentType'},
            {title: 'Lead Equivalence',         mapper: 'getEquipmentLeadEquivalence'},
            {title: 'Annual Visual Results',    mapper: 'annualVisualResults'},
            {title: 'Annual Screening Result',  mapper: 'annualScreeningResultDesc'},
            {title: 'Tested Date',              mapper: 'getTestDate'},
            {title: 'Due Date',                 mapper: 'getDueDate'},
            {title: 'Tested by',                mapper: 'tester.getFullName'}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), ProtectiveEquipmentActions)(ctrl);
        ctrl.dataAction = ctrl.getProtectiveEquipmentTests;
        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
