export const selectTargetFilter = {
    bindings: {
        identifier: '@',
        name: '@',
        statePath: '@',
        validate: '<',
        required: '<'
    },
    require : {
        form : '^form'
    },
    template: require('./select-target-filter.html'),
    controller: 'SelectTargetFilterController'
};
