export const credentialingState = {
    name: 'app.credentialing',
    abstract: true,
    url: '/credentialing'
};

export const credentialingDashboardState = {
    name: 'app.credentialing.dashboard',
    url: '',
    component: 'dashboard'
};

export const creVendorsState = {
    name: 'app.credentialing.vendors',
    abstract: true,
    url: '/vendors'
};

export const creVendorsDashboardState = {
    name: 'app.credentialing.vendors.dashboard',
    url: '',
    component: 'dashboard'
};
