import {TabsReducer} from "../../../../common/tabs/tabs.reducer";
import {IDS} from "../adm-categorisation-action-id.constants";

export const admCatOccupationsTabs = {

    [IDS.app_administration_categorisation_occupations]: Object.assign(TabsReducer(), {
        tabs: [
            {
                state: IDS.app_administration_categorisation_occupations_grid,
                title: 'Occupations',
                visible: true,
                disabled: false,
                loading: false,
            },
            {
                state: IDS.app_administration_categorisation_occupations_details,
                title: 'Details',
                visible: false,
                disabled: false,
                loading: false
            },
        ]
    }),
}