
export const IDS = {
    // modules
    empImport:                          'empImport',

    // components
    empImportHistoryList:               'empImportHistoryList',
    empImportHistoryListGrid:           'empImportHistoryListGrid',

    empImportHistoryDetails:            'empImportHistoryDetails',
    empImportHistoryDetailsGrid:        'empImportHistoryDetailsGrid',

    empImportNewImport:                 'empImportNewImport',

    empImportNewResult:                 'empImportNewResult',
    empImportNewResultGrid:             'empImportNewResultGrid',

    // tab states:
    app_employees_import:               'app.employees.import',
    app_employees_import_dashboard:     'app.employees.import.dashboard',

    app_employees_import_history:       'app.employees.import.history',
    app_employees_import_history_list:  'app.employees.import.history.list',
    app_employees_import_history_details:'app.employees.import.history.details',

    app_employees_import_new:            'app.employees.import.new',
    app_employees_import_new_import:     'app.employees.import.new.import',
    app_employees_import_new_result:     'app.employees.import.new.result',
    app_employees_import_settings:       'app.employees.import.settings',
    app_employees_import_settings_main:  'app.employees.import.settings.main',
};
