import moment from 'moment';

/* @ngInject */
export function MammoQualityControlMeasurementFactory(MammoQCTesterCandidate, MammoQCEquipment) {
    return function MammoQCMeasurement() {

        this.id = null;
        this.equipment = new MammoQCEquipment();
        this.measurementType = null;
        this.testDate = null;

        this.mediumDensity = null;
        this.lowDensity = null;
        this.differenceDensity = null;

        this.meanInsideDisk = null;
        this.meanBesideDisk = null;
        this.standardDeviationBesideDisk = null;
        this.kvp = null;
        this.mas = null;
        this.signalToNoiseRatioResultCalculation = null;
        this.contrastToNoiseRatioResultCalculation = null;
        this.contrastToNoiseRatioResultDifference = null;
        this.isBaseline = null;
        this.remark = null;
        this.baselineValue = null;

        this.getTestDate = function () {
            return moment(this.testDate).format('DD-MM-YYYY');
        };
    };
}
