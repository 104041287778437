import { loginState, loginAuthState, loginOtpState, loginOtpAlertState, recoveryState, passwordResetState, passwordSaveState } from './auth.states';
import { AuthService } from './auth.service';
import { RecoveryService } from './recovery/recovery.service';
import { auth } from './auth.component';
import { authLogin } from './login/auth-login.component';
import { authOTP } from './otp/auth-otp.component';
import { authPasswordReset } from './recovery/password-reset/auth-password-reset.component';
import { authPasswordSave } from './recovery/password-save/auth-password-save.component';

/** @module root/common/auth */
export const AUTH_MODULE = angular.module('common.auth', []);

AUTH_MODULE.config(['$uiRouterProvider', ({ stateRegistry }) => {
    stateRegistry.register(loginState);
    stateRegistry.register(loginAuthState);
    stateRegistry.register(loginOtpState);
    stateRegistry.register(loginOtpAlertState);
    stateRegistry.register(recoveryState);
    stateRegistry.register(passwordResetState);
    stateRegistry.register(passwordSaveState);
}]);

AUTH_MODULE.service('AuthService', AuthService);
AUTH_MODULE.service('RecoveryService', RecoveryService);

AUTH_MODULE.component('auth', auth);
AUTH_MODULE.component('authLogin', authLogin);
AUTH_MODULE.component('authOTP', authOTP);
AUTH_MODULE.component('authPasswordReset', authPasswordReset);
AUTH_MODULE.component('authPasswordSave', authPasswordSave);

AUTH_MODULE.run(['$transitions', 'AuthService', function($transitions, AuthService) {
    $transitions.onStart({
        to: 'login.**'
    }, function(trans) {
        if (AuthService.isAuthenticated())
            return trans.router.stateService.target('app');
    });
    $transitions.onStart({
        to: 'recovery.**'
    }, function(trans) {
        if (AuthService.isAuthenticated())
            return trans.router.stateService.target('app');
    });
    $transitions.onStart({
        to: 'public.**'
    }, function(trans) {
        if (AuthService.isAuthenticated() && !AuthService.isPublicLogin())
            return trans.router.stateService.target('app');
    });
    $transitions.onStart({
        to: 'app.**'
    }, function(trans) {
        if (!AuthService.isAuthenticated()) {
            AuthService.saveSession(trans.$to().name, trans.params('to'));
            return trans.router.stateService.target('login.auth', { reset: true });
        }
    });
    $transitions.onError({
        to: 'app.**'
    }, function(trans) {
        if (!AuthService.isAuthenticated()) {
            AuthService.saveSession(trans.$to().name, trans.params('to'));
        }
    });
}]);
