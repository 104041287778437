import { GridReducer } from '../../../common/grid/grid.reducer';
import {GridFilterReducer} from '../../../common/grid/filter/grid-filter.reducer';
import * as GRID from '../../../common/grid/grid.constants';
import * as FORM from '../../../common/form/input/input.constants';
import { TYPES } from './adm-categorisation-action-type.constants';
import { IDS } from './adm-categorisation-action-id.constants';
import merge from 'lodash/merge';
import get from 'lodash/get';
import set from 'lodash/set';
import update from "lodash/update";

/**
 * @function administrationCategorisationReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function administrationCategorisationReducer(state = undefined, action = undefined) {

    if (typeof state === 'undefined') {
        return {
            [IDS.admCatCentersGrid]: GridReducer(),
            [IDS.admCatCenterDetails]: {},

            [IDS.admCatDocumentsGrid]: GridReducer(),
            [IDS.admCatOccupationDetails]: {},

            [IDS.admCatOccupationsGrid]: GridReducer(),
            [IDS.admCatOccupationDetails]: {},
        };
    }

    switch (action.type) {
        case GRID.GRID_RESET:
            if (action.id === IDS.admCategorisation) {
                state = set({...state} ,`${action.path}.filter`,  GridFilterReducer());
            }

        case GRID.GRID_INIT:
        case GRID.GRID_FILTER:
        case GRID.GRID_FILTER_TOGGLE:
        case GRID.GRID_RESET_FILTER:
        case GRID.GRID_INIT_FILTER:
        case GRID.GRID_LOADING:
        case GRID.GRID_PAGINATE:
        case GRID.GRID_RELOAD:
        case GRID.GRID_FETCH:
        case GRID.GRID_SELECT:
        case GRID.GRID_SELECT_ALL:
        case GRID.GRID_DESELECT:
        case GRID.GRID_DESELECT_ALL:
            if(action.id === IDS.admCategorisation) {
                return {...state,
                    [action.path]: GridReducer(get(state, action.path), action)
                };
            }
        case FORM.INPUT_SELECT:
        case FORM.INPUT_TEXT:
        case FORM.INPUT_TEXT_AREA:
        case FORM.INPUT_CHECKBOX:
        case FORM.INPUT_DATE:
        case FORM.INPUT_RADIO:
            if (action.id === IDS.admCategorisation) {

                if(action.path.startsWith(IDS.admCatCentersGrid)) {
                    const filter = {
                        [action.path.split('.').pop()]: action.input
                    };
                    action.path = IDS.admCatCentersGrid;
                    return set({...state}, action.path,
                        GridReducer(get(state, action.path), { type: GRID.GRID_FILTER, filter })
                    )
                }
                return set({...state}, action.path, action.input)
            }
        case 'LOCATION_SELECTED': // slo select-cascade.js
            if (action.id === IDS.accreditationSettingsHeadOffices) {
                // return set({...state}, 'headOffices.selectedHeadOfficeUuid', action.uuid)
            }
            if(action.id === IDS.accreditationSettingsAssessmentGrid && action.type_code === 'slo') {
                // state = set({...state},  `${IDS.assessmentGrid}.selectedLocationUuid`, action.uuid);
                // if(get(state, `${IDS.assessmentGrid}.filterOpened`))
                //     state = set(state, `${IDS.assessmentGrid}.filter.slo_uuid`, action.uuid);
                // return set( state, IDS.assessmentGrid, GridReducer(get(state, IDS.assessmentGrid),{ type:GRID.GRID_RELOAD }))
            }
        default:
            return state;
        }
}
