import { gridTpl } from '../../../../../common/grid/grid.controller';

export const protectiveEquipmentGrid = {
    bindings: {
        onRemove: '&',
        onSelect: '&'
    },
    controller: 'ProtectiveEquipmentGridController',
    ...gridTpl
};
