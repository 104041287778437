import {IDS} from '../master-doc-list-action-id.constants';
import {IDS as IDS_ADD_DOCUMENT} from '../../add-document/add-document-action-id.constants';
import * as DocumentListActions from './list.actions';
import { saveAs } from 'file-saver';
import get from "lodash/get";
import {TYPES as ACC_TYPES} from "../../accreditation-action-type.constants";
import {GRID_FILTER} from "../../../../common/grid/grid.constants";

/**
 * Creates a new DocumentListController.
 * @class
 */
class DocumentListController {
    constructor($ngRedux,
                $uiRouterGlobals,
                $state,
                $stateParams,
                $scope,
                $rootScope,
                $filter,
                $timeout,
                $mdSidenav,
                $mdDialog,
                Notifier,
                DocumentManager,
                DocumentService,
                AccreditationService,
                NavigationService,
                AccreditationDocumentsService,
                EmployeeService,
                AuthService
    ) {
        this.$ngRedux = $ngRedux;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.$timeout = $timeout;
        this.$mdSidenav = $mdSidenav;
        this.$mdDialog = $mdDialog;
        this.Notifier = Notifier;
        this.DocumentManager = DocumentManager;
        this.DocumentService = DocumentService;
        this.AccreditationService = AccreditationService;
        this.NavigationService = NavigationService;
        this.AccreditationDocumentsService = AccreditationDocumentsService;
        this.EmployeeService = EmployeeService;
        this.AuthService = AuthService;

        this.isApprovalUser = [
            'Administrator',
            'Quality Manager',
            'Head Radiation Safety Coordinator',
            'Site Radiation Safety Coordinator',
            'Site Assistant Radiation Safety Coordinator'
        ].includes(AuthService.getUser().type);

        this.hideRemoveButton = (this.isApprovalUser === false);

        this.schemeCode = this.$stateParams.itemCode;

        this.identifier  = IDS.accMasterDocList;
        this.parentState = IDS.app_accreditation_scheme_master_doc_list;
        this.state       = IDS.app_accreditation_scheme_master_doc_list_list;
        this.title       = this.$filter('translate')('ACCREDITATION.MASTER_DOC_LIST.LIST.TAB');
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(state => {
            const accMasterDocList   = state.accreditation.accMasterDocList;
            const {siteLocation, headOffice, year} = state.accreditation.accDashboardLocationYear;
            return {
                fiscalYearStart:    accMasterDocList.fiscalYearStart,
                sloQualityManagers: accMasterDocList.sloQualityManagers,
                siteLocation:       siteLocation || accMasterDocList.siteLocationUuid,
                siteLocationName:   accMasterDocList.siteLocationName,
                headOffice,
                fiscalYear: year,
            }
        }, DocumentListActions)(this);

        const accDocumentId = this.$stateParams.ado;
        const fiscalYear = this.$stateParams.year;
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;

        const ctrl = this;
        this.fetchSchemeDetails(this.schemeCode).then(scheme => {
            ctrl.title = scheme.name;
            ctrl.schemeId = scheme.id;

            if(accDocumentId) {
                ctrl.fetchDocumentDetails(accDocumentId).then(documentDetails => {
// TODO: fix select cascade not selecting the site location
//                  ctrl.selectCascadeByQueryParams(ctrl.EmployeeService, siteLocation, ctrl.$rootScope);
                    ctrl.fetchSiteLocationDetails(documentDetails.siteLocation || documentDetails.siteLocationUuid);
                    ctrl.switchToDetailsTab(ctrl.$timeout, {
                            itemCode: ctrl.schemeCode,
                            ado: accDocumentId,
                            year: fiscalYear,
                        }
                    );
                });
            }
        });

        // var user = this.AuthService.getUser();
        // if(user.parentCount === 0) {
        //     this.EmployeeService.getEmploymentLocations(user.uuid).then(siteLocations => {
        //         const siteLocation = get(siteLocations,'[0]');
        //         if(siteLocation) {
        //             this.fetchSiteLocationDetails( siteLocation.uuid ).then(()=>{
        //                 // trigger refresh of grid
        //                 this.$rootScope.$broadcast('LOCATION_SUBJECT_SELECTED', {
        //                     in_subject_basic_type_code: 'slo',
        //                     in_subject_uuid: siteLocation.uuid
        //                 });
        //             });
        //         }
        //     });
        // }

        this.unsubscribeLocationChange = this.$scope.$on('LOCATION_SUBJECT_SELECTED', this.onLocationChange.bind(this));
        this.unsubscribeSelectCascadeLoaded = this.$scope.$on('SELECT_CASCADE_SUBJECTS_LOADED', this.onSelectCascadeLoaded.bind(this));
        this.unsubscribeLocationNotFound = this.$scope.$on('LOCATION_SUBJECT_NOT_FOUND', this.onLocationNotFound.bind(this));
    }

    onSelectCascadeLoaded() {
        if(this.headOffice && this.siteLocation) {
            this.$rootScope.$broadcast('FORCE_SELECT_PARENT', 0, this.headOffice, this.siteLocation);
        }
        else {
            if(this.$stateParams.slo) {
                const siteLocation = this.$stateParams.slo;
                this.EmployeeService.getEmploymentLocations(siteLocation).then(result => {
                    const headOffice = result[0].uuid;
                    this.$rootScope.$broadcast('FORCE_SELECT_PARENT', 0, headOffice, siteLocation );
                })
            }
            if(this.$stateParams.year){
                this.$ngRedux.dispatch({
                    type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                    accDashboardLocationYear: {
                        year: this.$stateParams.year,
                    }
                });
            }
        }
    }

    onLocationChange (evt, loc) {
        const subjectType = get(loc,'in_subject_basic_type_code');
        switch(subjectType){
            case 'hof':
                this.$ngRedux.dispatch({
                    type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                    accDashboardLocationYear: {
                        headOffice: loc.in_subject_uuid,
                    }
                });
                break;

            case 'slo':
                this.$stateParams.slo = loc.in_subject_uuid;
                this.$ngRedux.dispatch({
                    type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                    accDashboardLocationYear: {
                        siteLocation: loc.in_subject_uuid,
                        year: this.$stateParams.year,
                    }
                });
                this.fetchSiteLocationDetails( loc.in_subject_uuid );
                break;
        }
    };

    onLocationNotFound(event, location) {
        this.hideSelectSiteLocation = true;
        var user = this.AuthService.getUser();
        if(user.parentCount !== 1)
            return

        const ctrl = this;
        this.EmployeeService.getEmploymentLocations(user.uuid).then(siteLocations => {
            const siteLocation = get(siteLocations,'[0].uuid');
            this.$stateParams.slo = siteLocation;
            this.$ngRedux.dispatch({
                type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                accDashboardLocationYear: {
                    siteLocation,
                }
            });
            ctrl.fetchSiteLocationDetails( siteLocation ).then(()=>{
                ctrl.$rootScope.$broadcast('LOCATION_SUBJECT_SELECTED', {
                    in_subject_basic_type_code: 'slo',
                    in_subject_uuid: siteLocation
                });
            })
        });
    }

    onYearChange({item}) {
        const year = item.value;
        this.$stateParams.year = year;
        this.$ngRedux.dispatch({
            type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
            accDashboardLocationYear: { year }
        });
        this.$ngRedux.dispatch({
            type: GRID_FILTER,
            id: IDS.accMasterDocList,
            path: IDS.documentListGrid,
            filter: {fiscal_year: year}
        })
    }

    // selectCascadeByQueryParams(EmployeeService, siteLocation, $rootScope) {
    //     return EmployeeService.getEmploymentLocations(siteLocation).then(result => {
    //         const headOfficeUuid = result[0].uuid;
    //         $rootScope.$broadcast('FORCE_SELECT_PARENT', 0, headOfficeUuid, siteLocation );
    //     })
    // }

    onSelect({item}) {
        this.fetchDocumentDetails(item.id).then( () => {
            this.switchToDetailsTab(this.$timeout, {
                    itemCode: this.schemeCode,
                    slo: this.siteLocation,
                    ado: item.id,
                }
            );
        });
    };
    onRemove({item}) {
        // this.invalidateAccDocument(item.id, IDS.accMasterDocListGrid);
    }

    downloadDocuments (filter, documentCode, documentTypes) {
        const ctrl = this;
        const params = {
            acc_code:   this.schemeCode,
            loc_uuid:   this.siteLocation,
            year:       this.fiscalYear
        }
        this.DocumentService.fetchDocumentToken("ado", params).then(function (response) {
            ctrl.downloadButtonDisabled = true;
            ctrl.DocumentService.downloadZip(response.data.token).then(response => {
                const filename = [
                    ctrl.siteLocationName,
                    ctrl.schemeCode,
                    ctrl.fiscalYear,
                    Date.now()
                ].join('_');
                saveAs(new Blob([response.data], {type: "octet/stream"}), `${filename}.zip`);
                ctrl.Notifier.success('Download archive completed!');
                ctrl.downloadButtonDisabled = false;
            }, function (error) {
                ctrl.Notifier.error("Error downloading archive!");
                ctrl.downloadButtonDisabled = false;
            });
        }, function (error) {
            ctrl.$scope.cursorState = 'default';
            ctrl.Notifier.error("Error fetching documents list! Archive is too big!");
            ctrl.downloadButtonDisabled = false;
        });
    }

    addDocument() {
        this.$state.go(IDS_ADD_DOCUMENT.app_accreditation_scheme_add_document, {itemCode: this.schemeCode});
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeLocationChange();
        this.unsubscribeSelectCascadeLoaded();
        this.unsubscribeLocationNotFound();
    }

}

DocumentListController.$inject = [
    '$ngRedux',
    '$uiRouterGlobals',
    '$state',
    '$stateParams',
    '$scope',
    '$rootScope',
    '$filter',
    '$timeout',
    '$mdSidenav',
    '$mdDialog',
    'Notifier',
    'DocumentManager',
    'DocumentService',
    'AccreditationService',
    'NavigationService',
    'AccreditationDocumentsService',
    'EmployeeService',
    'AuthService',
]

export const accMasterDocList = {
    bindings: {},
    controller: DocumentListController,
    template: `
        <div layout-gt-xs="row" layout-align="center center">
            <div class="formHeader" flex>
                <h3>
                    <asset cls="colorAccreditations" src="accreditation_polices_procedures.svg"></asset>{{$ctrl.title}}
                </h3>
            </div>
            
        <md-button class="btnDefault" ng-disabled="$ctrl.downloadButtonDisabled" ng-click="$ctrl.downloadDocuments()">Download All Documents</md-button>
        </div>
        <form name="selectSiteLocationForm" novalidate>
            <div ng-if="$ctrl.schemeId"  ng-hide="$ctrl.hideSelectSiteLocation" layout-gt-sm="row" flex>           
                <div layout-gt-sm="row">
                    <select-cascade
                        identifier="{{$ctrl.identifier}}"
                        purpose="'acc'"
                        max="slo"
                        scheme="{{$ctrl.$stateParams.itemCode}}"
                        preselected
                        behaviour="'selectDefault'">
                    </select-cascade>
                </div>
                <input-select
                        flex-gt-sm="10"
                        label="{{'COMMON.DATE.YEAR' | translate}}"
                        identifier="{{$ctrl.identifier}}"
                        state-module="accreditation"
                        state-path="selectedYear"
                        state-data="availableYears"
                        on-change="$ctrl.onYearChange($event)">
                </input-select> 
            </div>
        </form>
        <acc-master-doc-list-grid
            ng-show="$ctrl.siteLocation"
            title="$ctrl.title"
            on-select="$ctrl.onSelect($event)"
            on-remove="$ctrl.onRemove($event)">
        </acc-master-doc-list-grid>
        <md-button class="btnDefault" ng-click="$ctrl.addDocument()">Add Document</md-button>
        <tabs-navigation
            identifier="{{$ctrl.parentState}}"
            state="{{$ctrl.state}}">
        </tabs-navigation>`,
}
