/**
 * Creates a new AdministrationController.
 * @class
 * @memberOf module:root/components/administration
 */
class AdministrationController {
    /**
     * @constructor
     * @param $log {service} {@link https://docs.angularjs.org/api/ng/service/$log|AngularJS $log}
     * @param NavigationService {service} {@link module:root/common.NavigationService|NavigationService}
     */
    constructor($log, NavigationService) {
        this.$log = $log;
        this.NavigationService = NavigationService;
    }

    /**
     * @method $onInit
     * @memberOf module:root/components/administration.AdministrationController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.$log.debug('Help::init');
        const navConfig = this.NavigationService.getNavConfig();
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;
    }
}

AdministrationController.$inject = ['$log', 'NavigationService'];

export const administration = {
    controller: AdministrationController,
    template: `
        <div class="loader formHeader">
            <asset cls="color{{$ctrl.colorName}}" src="{{$ctrl.icon}}"></asset>
            <div class="color{{$ctrl.colorName}}">Coming soon...</div>
        </div>
    `
};
