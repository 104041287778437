/* @ngInject */
export function rscManagement($mdDialog, Notifier, NavigationService, Helpers) {
    return {
        restrict: 'E',
        transclude: 'true',
        template: require('./rsc-manage-buttons.html'),
        scope: {
            model: '=',
            uuid: '=',
            type: '@',
            location: '=',
            status: '=',
            color: '=',
            title: '@',
            locationType: '@'
        },
        controller: ['$scope','$mdDialog','$rootScope', rscManagementBtns]
    };

    function rscManagementBtns($scope, $mdDialog, $rootScope) {
        var typeName        = "Radiation Safety Coordinator";
        $scope.newRsc       = "New RSC";
        $scope.showCreate   = (typeof $scope.uuid == 'undefined');


        if ($scope.type === 'arsc') {
            $scope.newRsc = "New ARSC";
            typeName = "Assistant of Radiation Safety Coordinator";
        }

        $scope.editRsc = function() {
            $mdDialog.show({
                controller: editRscController,
                template: require('./rsc-modify.html'),
                parent: angular.element(document.body),
                locals: {
                    model: $scope.model,
                    uuid: $scope.uuid,
                    status: $scope.status,
                    typeName: typeName,
                    title: $scope.title
                },
                clickOutsideToClose: true
            }).then(function(rso) {
                $rootScope.$broadcast('rsc-edited', rso, $scope.locationType);
                Notifier.success(typeName + " updated!");
            }, angular.noop);
        };

        $scope.createRsc = function () {
            $mdDialog.show({
                controller: createRsc,
                template: require('../views/accounts/dialog/dialog-site-rso.html'),
                parent: angular.element(document.body),
                locals: {
                    typeName: typeName,
                    type: $scope.type,
                    location: $scope.location,
                    title: $scope.title
                },
                clickOutsideToClose: true
            }).then(function(rso) {
                $rootScope.$broadcast('rsc-created', rso, $scope.locationType);
            }, angular.noop);
        };

        $scope.assignRsc = function () {
            $mdDialog.show({
                controller: assignRsc,
                template: require('./assign-rso-dialog.html'),
                parent: angular.element(document.body),
                locals: {
                    typeName: typeName,
                    type: $scope.type,
                    location: $scope.location,
                },
                clickOutsideToClose: true
            }).then(function(rso) {
                if(typeof rso !=='undefined')
                $rootScope.$broadcast('rsc-created',rso,$scope.locationType);
            }, angular.noop);
        }
    }

    function editRscController($scope, UserService, Notifier, uuid, status, typeName, title) {
        $scope.headerColor = NavigationService.getNavConfig().colorName;
        $scope.title = title;
        $scope.status = status;

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        UserService.fetchManagerDetails(uuid).then(function(rsc) {
            $scope.rsc = rsc;
        });

        $scope.saveRsc = function(rsc) {
            $scope.$broadcast('show-errors-check-validity');
            if ($scope.userForm.$invalid) return;

            UserService.saveRsc(rsc).then(function() {
                $mdDialog.hide(rsc);
            });
        }
    }

    function createRsc($scope, UserService, Notifier, DepartmentService, AccountService, typeName, type, RSC, location, title) {
        $scope.headerColor = NavigationService.getNavConfig().colorName;
        $scope.headerTitle = "Create " + typeName;

        $scope.cancel   = function () {
            $mdDialog.cancel();
        };

        $scope.genders = DepartmentService.genders;
        $scope.submitRSO = function () {

            $scope.$broadcast('show-errors-check-validity');
            if (this.rsoForm.$invalid) {
                return;
            }

            var rsc = new RSC();
            rsc.username   = $scope.rso.in_username;
            rsc.password   = $scope.rso.in_password;
            rsc.email      = _.isUndefined($scope.rso.in_email) ? null : $scope.rso.in_email.toLowerCase();
            rsc.phone      = $scope.rso.in_phone;
            rsc.fax        = $scope.rso.in_fax;
            rsc.firstName  = $scope.rso.in_first_name;
            rsc.lastName   = $scope.rso.in_last_name;
            rsc.first_name  = rsc.firstName;
            rsc.last_name   = rsc.lastName;
            rsc.birthdate   = Helpers.formatDate($scope.rso.in_birthdate);
            rsc.gender      = $scope.rso.in_gender.id;
            rsc.parent      = location;
            rsc.is_assistant = (type == 'arsc');


            AccountService.addRsc(rsc,rsc.parent,rsc.is_assistant).then(function (response)
            {
                rsc.uuid = response.data.uuid;

                $mdDialog.hide(rsc);
            }, function (response) {

                if(response.status == 200 && response.data.status == 0)
                {
                    Notifier.success(typeName+" updated!");
                    $mdDialog.hide(rsc);
                }
                else if(response.status == 200 && response.data.status != 0)
                {
                    Notifier.error("Problem with saving " + typeName+ ". Check information provided.");
                }
                else if(response.status == 403)
                {
                    Notifier.error("Not able to save " + typeName+ ". Insufficient access rights.");
                }
                else
                {
                    Notifier.error(typeName+" has not been saved.");
                }
            });
        };

    }

    function assignRsc($scope, $mdDialog, UserService, Notifier, AccountService, typeName, RSC, type, location) {
        $scope.headerColor = NavigationService.getNavConfig().colorName;
        $scope.typeName = typeName;

        $scope.cancel   = function () {
            $mdDialog.cancel();
        };

        $scope.candidates = [];
        AccountService.fetchRscCandidates(location)
            .then(function(data)
            {
                $scope.candidates = data;
            });

        $scope.candidateSearch = function (query) {
            var results = query ? $scope.candidates.filter(createFilterFor(query)) : $scope.candidates;
            return results;
        }

        $scope.candidateSearchTextChange = function (item) {
            if (item == "") $scope.oldPrimary = true;
        }

        $scope.indexOfWithNull = function(value,query)
        {
            return (value === null)?-1:value.toLowerCase().indexOf(query);
        }

        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(item) {


                return (($scope.indexOfWithNull(item.first_name,lowercaseQuery) !== -1
                || $scope.indexOfWithNull(item.last_name,lowercaseQuery) !== -1
                || $scope.indexOfWithNull(item.username,lowercaseQuery) !== -1) );//&& !alreadySelected(item)
            };
        }

        $scope.submitRSO = function() {
            // TODO validation
            AccountService.assignRSc($scope.candidateSelectedItem.sub_uuid,location).then(function(response) {
                var newRsc = new RSC();
                UserService.fetchManagerDetails($scope.candidateSelectedItem.sub_uuid).then(function(rso) {
                    Notifier.success(typeName + " assigned!");
                    newRsc.username     = rso.username;
                    newRsc.email        = _.isUndefined(rso.email) ? null : rso.email.toLowerCase();
                    newRsc.phone        = rso.phone;
                    newRsc.fax          = rso.fax;
                    newRsc.firstName    = rso.firstName;
                    newRsc.lastName     = rso.lastName;
                    newRsc.first_name   = rso.firstName;
                    newRsc.last_name    = rso.lastName;
                    newRsc.birthday     = Helpers.formatDate(rso.birthdate);
                    newRsc.gender       = rso.gender;
                    newRsc.parent       = location;

                    newRsc.uuid = rso.uuid;
                    $mdDialog.hide(newRsc);
                });
            }, function(error) {
                Notifier.responseMessage(error, 'Radiation Safety Coordinator has not been saved: Employee already assigned to the selected parent');
            });
        };
    }
}
