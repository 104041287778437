import { TAB_SHOW, TAB_LOADING } from '../../../../../common/tabs/tabs.constants';
import watch from 'redux-watch';

/**
 * Creates a new AdministrationAccountsHeadOfficeContactsController.
 * @class
 * @memberOf module:root/components/administration
 */
class AdministrationAccountsHeadOfficeContactsController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param $uiRouterGlobals {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/core.uirouterglobals.html|UI-Router UIRouterGlobals}
     * @param $timeout {service} {@link https://docs.angularjs.org/api/ng/service/$timeout|AngularJS $timeout}
     * @param Notifier {service} {@link Notifier}
     * @param $mdDialog {service}
     * @param AdministrationAccountsActions {service}
     */
    constructor($ngRedux, $state, $uiRouterGlobals, $timeout, Notifier, $mdDialog, AdministrationAccountsActions) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.$timeout = $timeout;
        this.Notifier = Notifier;
        this.$mdDialog = $mdDialog;
        this.actions = AdministrationAccountsActions;
    }

    /**
     * @method $onInit
     * @memberOf module:root/components/administration.AdministrationAccountsHeadOfficeContactsController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.tabsState = this.$state.$current.parent.name;
        this.currentState = 'app.administration.accounts.headOfficesContacts';
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);

        const watchContact = watch(this.$ngRedux.getState, 'administration.administrationAccountsHeadOfficeContact.data.uuid');
        this.onContact = this.$ngRedux.subscribe(watchContact(uuid => {
            if (uuid) this.onContactDetailsReady(uuid);
        }));
    }

    /**
     * @method onContactDetailsReady
     * @param id {number}
     * @memberOf module:root/components/administration.AdministrationAccountsHeadOfficeContactsController
     */
    onContactDetailsReady(uuid) {
        this.$ngRedux.dispatch({
            type: TAB_SHOW,
            id: 'app.administration.accounts',
            state: ['app.administration.accounts.headOfficesContactDetails'],
            stateParams: {
                hof: this.$uiRouterGlobals.params.hof,
                contact: uuid
            }
        });
        this.$ngRedux.dispatch({
            type: TAB_LOADING,
            id: 'app.administration.accounts',
            state: 'app.administration.accounts.headOfficesContactDetails',
            loading: false
        });
    }

    /**
     * @method addContact
     * @memberOf module:root/components/administration.AdministrationAccountsHeadOfficeContactsController
     */
    addContact() {
        if (this.contact.uuid) {
            this.$ngRedux.dispatch({
                type: 'CONTACT_RESET',
                id: 'administrationAccountsHeadOfficeContact'
            });
        }
        this.$ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: 'app.administration.accounts',
            state: ['app.administration.accounts.headOfficesContactDetails'],
            stateParams: this.$uiRouterGlobals.params
        });
    }

    /**
     * @method onSelect
     * @param event {object}
     * @memberOf module:root/components/administration.AdministrationAccountsHeadOfficeContactsController
     */
    onSelect(event) {
        if (this.contact.uuid === event.item.sub_uuid)
            this.onContactDetailsReady(event.item.sub_uuid);
        else
            this.getContactDetails('administrationAccountsHeadOfficeContact', event.item.sub_uuid);
    }

    /**
     * @method onRemove
     * @param event {object}
     * @memberOf module:root/components/administration.AdministrationAccountsHeadOfficeContactsController
     */
    onRemove(event) {
        const confirm = this.$mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Contact will be deactivated.')
            .ariaLabel('Deactivate Contact')
            .ok('Yes')
            .cancel('No');

        this.removeDialog = this.$mdDialog.show(confirm).then(() => {
            this.removeContact(event.item.sub_uuid, this.headOffice.uuid, event.item.sty_id, 'administrationAccountsHeadOfficeContact', 'administrationAccountsHeadOfficeContactsGrid');
        });
    }

    /**
     * @method $onDestroy
     * @memberof module:root/components/administration.AdministrationAccountsHeadOfficeContactsController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.onContact();
        this.unsubscribe();
        if (this.removeDialog) this.$mdDialog.hide(this.removeDialog);
    }

    /**
     * @method mapState
     * @param state {object} Redux Store
     * @return {object}
     * @memberof module:root/components/administration.AdministrationAccountsHeadOfficeContactsController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapState(state) {
        return {
            headOffice: state.administration.administrationAccountsHeadOffice,
            contact: state.administration.administrationAccountsHeadOfficeContact.data,
            title: state.administration.administrationAccountsHeadOffice.name + ' » Contacts'
        }
    }
}

AdministrationAccountsHeadOfficeContactsController.$inject = ['$ngRedux', '$state', '$uiRouterGlobals', '$timeout', 'Notifier', '$mdDialog', 'AdministrationAccountsActions'];

export const administrationAccountsHeadOfficeContacts = {
    controller: AdministrationAccountsHeadOfficeContactsController,
    template: `
        <administration-accounts-contacts-grid identifier="administrationAccountsHeadOfficeContactsGrid"
                                  title="$ctrl.title"
                                  on-select="$ctrl.onSelect($event)"
                                  on-remove="$ctrl.onRemove($event)">
        </administration-accounts-contacts-grid>
        
        <tabs-navigation identifier="app.administration.accounts"
                         state="app.administration.accounts.headOfficesContacts"
                         on-exec="$ctrl.addContact()">
        </tabs-navigation>
    `
};
