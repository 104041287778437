export const drlState = {
    name: 'app.doseReferenceLevels',
    abstract: true,
    url: '/dose-reference-levels'
};

export const drlDashboardState = {
    name: 'app.doseReferenceLevels.dashboard',
    url: '',
    component: 'dashboard'
};

export const drlCtScannerState = {
    name: 'app.doseReferenceLevels.ctScanner',
    abstract: true,
    url: '/ct-scanner'
};

export const drlCtScannerDashboardState = {
    name: 'app.doseReferenceLevels.ctScanner.dashboard',
    url: '',
    component: 'dashboard'
};

export const drlFluoroscopyAngiography = {
    name: 'app.doseReferenceLevels.fluoroscopyAngiography',
    url: '/fluoroscopy-angiography',
    component: 'doseReferenceLevels'
};
