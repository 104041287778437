/* @ngInject */
export function AdministrationAccountsDepartmentEquipmentController($ngRedux, $state, $uiRouterGlobals, NavigationService) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        var navConfig = NavigationService.getConfig($state.$current);
        ctrl.colorName = navConfig.colorName;
        ctrl.icon = navConfig.icon;

        unsubscribe = $ngRedux.connect(mapStateToThis, {})(ctrl);
    };

    ctrl.selectDepartments = function() {
        $ngRedux.dispatch({
            type: 'TAB_SELECT',
            id: 'app.administration.accounts.departments',
            state: 'app.administration.accounts.departments.list'
        });
    };

    ctrl.selectRooms = function() {
        $state.go('app.administration.accounts.rooms', $uiRouterGlobals.params, {reload: 'app.administration.accounts'});
    };

    ctrl.selectRSC = function() {
        $ngRedux.dispatch({
            type: 'TAB_SELECT',
            id: 'app.administration.accounts.departments',
            state: 'app.administration.accounts.departments.rsc',
            stateParams: $uiRouterGlobals.params
        });
    };

    ctrl.selectEmployees = function() {
        $ngRedux.dispatch({
            type: 'TAB_SELECT',
            id: 'app.administration.accounts.departments',
            state: 'app.administration.accounts.departments.employees',
            stateParams: $uiRouterGlobals.params
        });
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    function mapStateToThis(state) {
        return {
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                state.administration.administrationAccountsDepartment.data.typeName,
                'Equipment'
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
