/** @namespace Reducers.DoseReferenceLevels */

import { TabsReducer } from '../common/tabs/tabs.reducer';
import { GridReducer } from '../common/grid/grid.reducer';
import set from 'lodash/set';

/**
 * @function doseReferenceLevelsReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function doseReferenceLevelsReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            'app.doseReferenceLevels.ctScanner.doseReport': Object.assign(TabsReducer(), {
                tabs: [{
                    state: 'app.doseReferenceLevels.ctScanner.doseReport.generator',
                    title: 'Dose Report',
                    visible: true,
                    disabled: false,
                    loading: false,
                }]
            }),
            'app.doseReferenceLevels.ctScanner.contrastReport': Object.assign(TabsReducer(), {
                tabs: [{
                    state: 'app.doseReferenceLevels.ctScanner.contrastReport.generator',
                    title: 'Contrast Report',
                    visible: true,
                    disabled: false,
                    loading: false,
                }]
            }),
            'app.doseReferenceLevels.ctScanner.systemStatus': Object.assign(TabsReducer(), {
                tabs: [{
                    state: 'app.doseReferenceLevels.ctScanner.systemStatus.listing',
                    title: 'System Status',
                    visible: true,
                    disabled: false,
                    loading: false,
                }]
            }),
            drlCtScannerDoseReport: {
                dateFrom: null,
                dateTo: null,
                bodyPart: null,
                protocol: null,
                dlp: null,
                ctdivol: null,
                bodyPartList: [
                    {id: 0, name: 'Head (560xx)',               data: {protocol: '560xx',   dlp: 1000,  ctdivol: 60}},
                    {id: 1, name: 'Neck (561xx)',               data: {protocol: '561xx',   dlp: 600,   ctdivol: 30}},
                    {id: 2, name: 'Spine (562xx)',              data: {protocol: '562xx',   dlp: 900,   ctdivol: 40}},
                    {id: 3, name: 'Chest (563xx)',              data: {protocol: '563xx',   dlp: 450,   ctdivol: 15}},
                    {id: 4, name: 'Abdo or Pelvis (564xx)',     data: {protocol: '564xx',   dlp: 700,   ctdivol: 15}},
                    {id: 5, name: 'Abdo and Pelvis (565xx)',    data: {protocol: '565xx',   dlp: 1200,  ctdivol: 30}},
                    {id: 6, name: 'Extremities (566xx)',        data: {protocol: '566xx',   dlp: 1000,  ctdivol: 50}},
                    {id: 7, name: 'Any',                        data: {protocol: 'Any',     dlp: 1000,  ctdivol: 50}}
                ]
            },
            drlCtScannerContrastReport: {
                contrastList: [],
                contrast: null,
                dateFrom: null,
                dateTo: null,
                location: null
            },
            drlCtScannerSystemStatusGrid: GridReducer()
        };
    }

    switch (action.type) {
        case 'DOSE_REPORT_RESET':
            if (action.id === 'drlCtScannerDoseReport') {
                return Object.assign({}, state, {
                    drlCtScannerDoseReport: Object.assign({}, state.drlCtScannerDoseReport, {
                        dateFrom: null,
                        dateTo: null,
                        bodyPart: null,
                        protocol: null,
                        dlp: null,
                        ctdivol: null
                    })
                });
            }
        case 'CONTRAST_REPORT_RESET':
            if (action.id === 'drlCtScannerContrastReport') {
                return Object.assign({}, state, {
                    drlCtScannerContrastReport: Object.assign({}, state.drlCtScannerContrastReport, {
                        contrast: null,
                        dateFrom: null,
                        dateTo: null,
                        location: null
                    })
                });
            }
        case 'CONTRAST_FETCH':
            if (action.id === 'drlCtScannerContrastReport') {
                return Object.assign({}, state, {
                    drlCtScannerContrastReport: Object.assign({}, state.drlCtScannerContrastReport, {contrastList: action.items})
                });
            }
        case 'CONTRAST_SELECTED':
            if (action.id === 'drlCtScannerContrastReport') {
                return Object.assign({}, state, {
                    drlCtScannerContrastReport: Object.assign({}, state.drlCtScannerContrastReport, {contrast: action.contrast})
                });
            }
        case 'DATE_SELECTED':
            if (action.id === 'drlCtScannerDoseReport') {
                return Object.assign({}, state, {
                    drlCtScannerDoseReport: set(Object.assign({}, state.drlCtScannerDoseReport), action.path, action.input)
                });
            }
            if (action.id === 'drlCtScannerContrastReport') {
                return Object.assign({}, state, {
                    drlCtScannerContrastReport: set(Object.assign({}, state.drlCtScannerContrastReport), action.path, action.input)
                });
            }
        case 'BODY_PART_SELECTED':
            if (action.id === 'drlCtScannerDoseReport') {
                return Object.assign({}, state, {
                    drlCtScannerDoseReport: Object.assign({}, state.drlCtScannerDoseReport, {
                        bodyPart: action.bodyPart,
                        protocol: action.bodyPart.data.protocol,
                        dlp: action.bodyPart.data.dlp,
                        ctdivol: action.bodyPart.data.ctdivol
                    })
                });
            }
        case 'GRID_OVERRIDE':
        case 'SHOW_LOADING':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
            if (action.id === 'drlCtScannerSystemStatusGrid') {
                return Object.assign({}, state, {
                    drlCtScannerSystemStatusGrid: GridReducer(state.drlCtScannerSystemStatusGrid, action)
                });
            }
        default:
            return state;
    }
}
