import { GridController } from '../../../../common/grid/grid.controller';
import watch from 'redux-watch';
import {EXPORT_FORMAT} from "../../../../common/utils/util.constants";

/* @ngInject */
export function EmployeesGridController($controller, $ngRedux, $state, $rootScope, NavigationService, EmployeesActions, EmployeeService, EmployeeModalityTrainingService, DocumentService, AuthService, DOCUMENT_CODE, $filter, Notifier) {
    const ctrl = this;
    let unsubscribeLicenceStatus = angular.noop;
    let unsubscribeReset = angular.noop;
    let unsubscribeLocation = angular.noop;
    let unsubscribeOccupation = angular.noop;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.showCheckboxDisableRecordsPerEachSlo = true;
    ctrl.user = AuthService.getUser();
    ctrl.jobTitleLabel = 'Job Title';
    ctrl.headOfficeName = ctrl.user.hofName;
    if (ctrl.headOfficeName === 'OTTAWA HOSPITAL' || ctrl.headOfficeName === 'EMU HOSPITALS') {
        ctrl.jobTitleLabel = 'PACS Initials';
    }

    ctrl.$onInit = function() {
        ctrl.module = 'employees';
        ctrl.identifier = 'employeesGrid';
        ctrl.filterControls = './employees-grid-filter-controls.html';
        ctrl.filterTemplate = './employees-grid-filter.html';
        ctrl.validateExport = () => {
            if(ctrl.user.type !== 'Administrator' || ctrl.location || ctrl.occupation){
                return true
            }
            else {
                Notifier.error('Select Head Office or Occupation First');
                return false
            }
        };

        ctrl.exportService = EmployeeService.getEmployeesExportDocument;
        ctrl.exportFormats = [
            ...EXPORT_FORMAT,
            {format: 'export_employees_for_import',  name: 'For Import'}
        ];
        ctrl.fetchDocumentToken = DocumentService.fetchDocumentToken;
        ctrl.documentCode = DOCUMENT_CODE.EMPLOYEES;
        ctrl.documentsComponent = true;

        ctrl.columnsEmployees = [
            {title: '#',                                                    mapper: null},
            {title: $filter('translate')('EMPLOYEES.GRID.USERNAME'),        mapper: 'username', sort: 'username'},
            {title: $filter('translate')('EMPLOYEES.GRID.FIRST_NAME'),      mapper: 'firstName', sort: 'first_name'},
            {title: $filter('translate')('EMPLOYEES.GRID.LAST_NAME'),       mapper: 'lastName', sort: 'last_name'},
            {title: $filter('translate')('EMPLOYEES.GRID.OCCUPATION'),      mapper: 'occupation.name', sort: 'subject_type_name'},
            {title: ctrl.jobTitleLabel,                                     mapper: 'jobTitle'},
            {title: $filter('translate')('EMPLOYEES.GRID.SITE_LOCATION'),   mapper: 'siteLocation.name', sort: 'account_name'},
            {title: $filter('translate')('EMPLOYEES.GRID.EMAIL'),           mapper: 'email'},
            {title: 'Use Lic Req',                                          mapper: (obj) => obj.useLicenceRequired ? 'Yes' : 'No'},
            {title: 'ASAR Lic Req',                                         mapper: (obj) => obj.asarLicenceRequired ? 'Yes' : 'No'},
            {title: 'Ahpra / Board Reg Req',                                mapper: (obj) => obj.boardRegistrationRequired ? 'Yes' : 'No'},
            {title: '',                                                     mapper: 'warning'},
            {title: '',                                                     mapper: 'critical'}
        ];

        ctrl.columnsEmployeesWithLicences = [
            {title: '#',                                                    mapper: null},
            {title: $filter('translate')('EMPLOYEES.GRID.USERNAME'),        mapper: 'username'},
            {title: $filter('translate')('EMPLOYEES.GRID.FIRST_NAME'),      mapper: 'firstName'},
            {title: $filter('translate')('EMPLOYEES.GRID.LAST_NAME'),       mapper: 'lastName'},
            {title: $filter('translate')('EMPLOYEES.GRID.OCCUPATION'),      mapper: 'occupation.name'},
            {title: ctrl.jobTitleLabel,                                     mapper: 'jobTitle'},
            {title: $filter('translate')('EMPLOYEES.GRID.SITE_LOCATION'),   mapper: 'siteLocation.name'},
            {title: $filter('translate')('EMPLOYEES.GRID.EMAIL'),           mapper: 'email'},
            {title: $filter('translate')('EMPLOYEES.GRID.LICENCE_TYPE'),    mapper: 'licenceType'},
            {title: $filter('translate')('EMPLOYEES.GRID.LICENCE_NUMBER'),  mapper: 'licenceNumber'},
            {title: $filter('translate')('EMPLOYEES.GRID.EXPIRY_DATE'),     mapper: 'licenceExpiryDate'},
            {title: 'Use Lic Req',                                          mapper: (obj) => obj.useLicenceRequired ? 'Yes' : 'No'},
            {title: 'ASAR Lic Req',                                         mapper: (obj) => obj.asarLicenceRequired ? 'Yes' : 'No'},
            {title: 'Ahpra / Board Reg Req',                                mapper: (obj) => obj.boardRegistrationRequired ? 'Yes' : 'No'},
        ];

        ctrl.columns = ctrl.columnsEmployees;
        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), EmployeesActions)(ctrl);
        ctrl.dataAction = ctrl.getEmployees;
        ctrl.filter.identifier = ctrl.identifier;
        ctrl.filterActions.currentDate = new Date();

        const watchLicenceStatus = watch($ngRedux.getState, 'employees.' + ctrl.identifier + '.filter.licences_status');
        unsubscribeLicenceStatus = $ngRedux.subscribe(watchLicenceStatus(function(status) {
            ctrl.columns = status === 'null' ? ctrl.columnsEmployees : ctrl.columnsEmployeesWithLicences;
            ctrl.showCheckboxDisableRecordsPerEachSlo = status === 'null';
        }));
        ctrl.init.call(ctrl);
    };

    const watchReset = watch($ngRedux.getState, 'employees.employeesGrid.reset');
    unsubscribeReset = $ngRedux.subscribe(watchReset(function() {
        $rootScope.$broadcast('SELECT_CASCADE_RESET', 'employeesGridFilterForm');
    }));
    if(ctrl.user.type === 'Administrator') {
        const watchLocation = watch($ngRedux.getState, 'employees.employeesGrid.filter.location');
        unsubscribeLocation = $ngRedux.subscribe(watchLocation(function (location) {
            ctrl.location = location;
        }));

        const watchOccupation = watch($ngRedux.getState, 'employees.employeesGrid.filter.occupation');
        unsubscribeOccupation = $ngRedux.subscribe(watchOccupation(function (occupation) {
            ctrl.occupation = occupation;
        }));
    }
    ctrl.$onDestroy = function() {
        unsubscribeLicenceStatus();
        unsubscribeReset();
        unsubscribeLocation();
        unsubscribeOccupation();
        ctrl.destroy();
    };
}
