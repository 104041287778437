/* @ngInject */
export function VendorService($http, API_BASE, Notifier, VendorHeadOffice, VendorSiteLocation, VendorManagerUser, VendorServiceType, Vendor, VendorAgent, ProposalPurpose, Helpers, GENDERS) {

    this.fetchAgents = function() {
        return $http({
            url: API_BASE + "/vendor/agent",
            method: "GET"
        }).then(function(response) {
            return response.data.map(function(item) {
                var agent           = new VendorAgent();
                agent.vendor.name   = item.vendor;
                agent.vendor.uuid   = item.uuid;
                agent.username      = item.username;
                agent.firstName     = item.first_name;
                agent.lastName      = item.last_name;
                agent.phone         = item.phone;
                agent.fax           = item.fax;
                agent.address       = item.address;
                agent.email         = item.email;
                return agent;
            });
        }, function(error) {
            return error;
        });
    };

    this.getServices = function() {
        return $http({
            url: API_BASE + "/proposal/purposes",
            method: "GET"
        }).then(function(data) {
            return data.data.map(function(item) {
                var vendorService = new ProposalPurpose();
                vendorService.id = item.ppu_id;
                vendorService.name = item.name;
                return vendorService;
            });
        }, function(error) {
            return error;
        });
    };

    this.fetchInspectors = function() {
        return $http({
            url: API_BASE + "/inspectors",
            method: "GET"
        }).then(function(response) {
            return response.data.map(function(item) {
                var inspector   = new Vendor();
                inspector.uuid  = item.sub_uuid;
                inspector.name  = item.last_name + ' ' + item.first_name;
                return inspector;
            });
        }, function(error) {
            return error;
        });
    };

    this.getVendorContactPositions = function(contactCode) {
        return $http({
            url: API_BASE + "/administration/vendor/list-vendor-contact-positions",
            method: "GET",
            params: {
                contactCode: contactCode
            }
        }).then(function(response) {
            return response.data.map(function(item) {
                return {
                    id:          item.position_id,
                    name:        item.position_name,
                };
            });
        });
    };

    this.getVendorHeadOfficeList = function(filter) {
        return $http({
            url: API_BASE + "/administration/vendor/headoffices",
            method: "GET",
            params: {
                serviceType: filter.serviceType,
                offset: filter.offset,
                limit: filter.limit
            }
        }).then(function(result) {
            var items = result.data.rows.map(function(item) {
                var headOffice          = new VendorHeadOffice();
                headOffice.id           = item.account_id;
                headOffice.uuid         = item.vendor_uuid;
                headOffice.name         = item.vendor_name;
                headOffice.subjectType  = item.subject_type;
                headOffice.email        = item.email;
                headOffice.phone        = item.phone;
                return headOffice;
            });
            return {total: result.data.total, items: items};
        }, function(error) {
            // TODO implement NotificationsService.responseStatusMessage
        });
    };

    this.getVendorHeadOffice = function(uuid) {
        return $http({
            url: API_BASE + "/location/headoffice/" + uuid,
            method: "GET"
        }).then(function(result) {
            var itemHeadOffice  = _.first(result.data[0]);
            var headOffice      = new VendorHeadOffice();
            headOffice.id       = itemHeadOffice.account_id;
            headOffice.uuid     = itemHeadOffice.subject_uuid;
            headOffice.name     = itemHeadOffice.client_name;
            headOffice.email    = itemHeadOffice.email;
            headOffice.phone    = itemHeadOffice.phone;

            if (_.isUndefined(result.data[1]) || _.isEmpty(result.data[1]))
                return headOffice;
            else {
                var itemManager     = _.first(result.data[1]);
                var manager         = new VendorManagerUser();
                manager.uuid        = itemManager.subject_uuid;
                manager.username    = itemManager.username;
                manager.position    = {id: itemManager.subject_type_id};
                manager.firstName   = itemManager.first_name;
                manager.lastName    = itemManager.last_name;
                manager.email       = itemManager.email;
                manager.phone       = itemManager.phone;
                manager.fax         = itemManager.fax;
                manager.birthday    = Helpers.formatDate(itemManager.birthdate);
                manager.gender      = _.find(GENDERS, ['id', parseInt(itemManager.gender)]);
                headOffice.manager  = manager;
            }
            return headOffice;
        });
    };

    this.addVendorHeadOffice = function(headOffice) {
        return $http({
            url: API_BASE + "/administration/vendor/headoffice",
            method: "POST",
            data: {
                vendor:         headOffice.name,
                email:          headOffice.email,
                phone:          headOffice.phone,
                hrsoUsername:   headOffice.manager.username,
                hrsoPassword:   headOffice.manager.password,
                hrsoPositionID: parseInt(headOffice.manager.position.id),
                hrsoFirstName:  headOffice.manager.firstName,
                hrsoLastName:   headOffice.manager.lastName,
                hrsoEmail:      headOffice.manager.email,
                hrsoPhone:      headOffice.manager.phone,
                hrsoFax:        headOffice.manager.fax,
                hrsoBirthDate:  Helpers.formatDate(headOffice.manager.birthday),
                hrsoGender:     headOffice.manager.gender.id
            }
        }).then(function(result) {
            return typeof (result.data.params) === 'object' ? result.data.params : JSON.parse(result.data.params);
        });
    };

    this.editVendorHeadOffice = function(headOffice) {
        return $http({
            url: API_BASE + "/administration/vendor/headoffice/update",
            method: "POST",
            data: {
                hofUuid:        headOffice.uuid,
                name:           headOffice.name,
                email:          headOffice.email,
                phone:          headOffice.phone,
                hrsoUuid:       headOffice.manager.uuid,
                hrsoPositionID: parseInt(headOffice.manager.position.id),
                hrsoFirstName:  headOffice.manager.firstName,
                hrsoLastName:   headOffice.manager.lastName,
                hrsoEmail:      headOffice.manager.email,
                hrsoPhone:      headOffice.manager.phone,
                hrsoFax:        headOffice.manager.fax,
                hrsoBirthDate:  Helpers.formatDate(headOffice.manager.birthday),
                hrsoGender:     headOffice.manager.gender.id
            }
        }).then(function(result) {
            return result.data;
        }, function(error) {
            // TODO implement NotificationsService.responseStatusMessage
        });
    };

    this.getVendorSiteLocations = function(hofUuid) {
        return $http({
            url: API_BASE + "/administration/vendor/get-vendor-site-locations",
            method: "GET",
            params: {
                hofUuid: hofUuid,
                limit: -1
            }
        }).then(function(response) {
            return response.data.rows.map(function(item) {
                return {
                    uuid:           item.vsl_uuid,
                    address:        item.address,
                    country:        item.country,
                    state:          item.state,
                    vendorName:     item.vendor_name
                };
            });
        });
    };

    this.getVendorSiteLocation = function(sloUuid) {
        return $http({
            url: API_BASE + "/administration/vendor/sitelocation",
            method: "GET",
            params: {sloUuid: sloUuid}
        }).then(function(result) {
            var siteLocation = new VendorSiteLocation();
            var siteLocationItem = _.first(result.data[0]);
            siteLocation.uuid   = siteLocationItem.vsl_uuid;
            siteLocation.country.id = siteLocationItem.country_id;
            siteLocation.country.name = siteLocationItem.country;
            siteLocation.country.state.id = siteLocationItem.state_id;
            siteLocation.country.state.name = siteLocationItem.state;
            siteLocation.name   = siteLocationItem.name;
            siteLocation.address   = siteLocationItem.address;
            siteLocation.email  = siteLocationItem.email;
            siteLocation.phone  = siteLocationItem.phone;
            _.each(_.last(result.data), function(item) {
                var manager = new VendorManagerUser();
                manager.uuid        = item.subject_uuid;
                manager.username    = item.username;
                manager.position    = {id: item.subject_type_id};
                manager.firstName   = item.first_name;
                manager.lastName    = item.last_name;
                manager.email       = item.email;
                manager.phone       = item.phone;
                manager.fax         = item.fax;
                manager.birthday    = Helpers.formatDate(item.birthdate);
                manager.gender      = _.find(GENDERS, ['id', parseInt(item.gender)]);
                siteLocation.managers.push(manager);
            });
            return siteLocation;
        }, function(error) {
            // TODO implement NotificationsService.responseStatusMessage
        });
    };

    this.addVendorSiteLocation = function(hofUuid, siteLocation, manager) {
        var positionID = null;
        if (manager.position) positionID = manager.position.id;
        var genderID = null;
        if (manager.gender) genderID = manager.gender.id;
        return $http({
            url: API_BASE + "/administration/vendor/sitelocation",
            method: "POST",
            data: {
                hofUuid:        hofUuid,
                address:        siteLocation.address,
                stateId:        parseInt(siteLocation.country.state.id),
                email:          siteLocation.email,
                phone:          siteLocation.phone,
                vsmUuid:        manager.uuid,
                vsmUsername:    manager.username,
                vsmPassword:    manager.password,
                vsmPositionID:  parseInt(positionID),
                vsmFirstName:   manager.firstName,
                vsmLastName:    manager.lastName,
                vsmEmail:       manager.email,
                vsmPhone:       manager.phone,
                vsmFax:         manager.fax,
                vsmBirthDate:   Helpers.formatDate(manager.birthday),
                vsmGender:      genderID
            }
        }).then(function(result) {
            return typeof (result.data.params) === 'object' ? result.data.params : JSON.parse(result.data.params);
        });
    };

    this.editVendorSiteLocation = function(siteLocation) {
        return $http({
            url: API_BASE + "/administration/vendor/sitelocation/update",
            method: "POST",
            data: {
                sloUuid:    siteLocation.uuid,
                name:       siteLocation.address,
                stateId:    parseInt(siteLocation.country.state.id),
                email:      siteLocation.email,
                phone:      siteLocation.phone
            }
        }).then(function(result) {
            return result.data;
        }, function(error) {
            Notifier.responseMessage(error);
            return error;
        });
    };

    this.addVendorSiteLocationManager = function(sloUuid, manager) {
        var positionID = null;
        if(manager.position) positionID = manager.position.id;
        var genderID = null;
        if (manager.gender) genderID = manager.gender.id;
        return $http({
            url: API_BASE + "/administration/vendor/sitelocation/add-manager",
            method: "POST",
            data: {
                vslUuid:    sloUuid,
                username:   manager.username,
                password:   manager.password,
                positionID: parseInt(positionID),
                firstName:  manager.firstName,
                lastName:   manager.lastName,
                email:      manager.email,
                phone:      manager.phone,
                fax:        manager.fax,
                birthDate:  Helpers.formatDate(manager.birthday),
                gender:     genderID
            }
        }).then(function(result) {
            return typeof result.data.params === 'object' ? result.data.params : JSON.parse(result.data.params);
        });
    };

    this.editVendorSiteLocationManager = function(manager) {
        var positionID = null;
        if(manager.position) positionID = manager.position.id;
        var genderID = null;
        if (manager.gender) genderID = manager.gender.id;
        return $http({
            url: API_BASE + "/administration/vendor/sitelocation/update-manager",
            method: "POST",
            data: {
                uuid:       manager.uuid,
                positionID: parseInt(positionID),
                firstName:  manager.firstName,
                lastName:   manager.lastName,
                email:      manager.email,
                phone:      manager.phone,
                fax:        manager.fax,
                birthDate:  Helpers.formatDate(manager.birthday),
                gender:     genderID
            }
        }).then(function(result) {
            return result.data;
        });
    };

    this.getVendorSiteLocationManagerCandidates = function(hofUuid) {
        return $http({
            url: API_BASE + "/administration/vendor/sitelocation/manager-candidates",
            method: "GET",
            params: {hofUuid: hofUuid}
        }).then(function(result) {
            return result.data.map(function(item) {
                var manager = new VendorManagerUser();
                manager.uuid        = item.uuid;
                manager.username    = item.username;
                manager.firstName   = item.first_name;
                manager.lastName    = item.last_name;
                manager.email       = item.email;
                manager.phone       = item.phone;
                manager.fax         = item.fax;
                manager.birthday    = Helpers.formatDate(item.birthdate);
                manager.gender      = _.find(GENDERS,['id', parseInt(item.gender)]);
                return manager;
            });
        });
    };

    this.assignVendorSiteLocationManager = function(vslUuid, vsmUuid) {
        return $http({
            url: API_BASE + "/administration/vendor/sitelocation/assign-manager",
            method: "POST",
            data: {
                vslUuid: vslUuid,
                vsmUuid: vsmUuid
            }
        }).then(function(result) {
            return result.data;
        }, function(error) {
            // TODO implement NotificationsService.responseStatusMessage
        });
    };

    this.rejectVendorSiteLocationManager = function(vslUuid, vsmUuid) {
        return $http({
            url: API_BASE + "/user/status",
            method: "POST",
            data: {
                loc_uuid: vslUuid,
                uuid: vsmUuid,
                status: false
            }
        }).then(function(result) {
            return result.data;
        }, function(error) {
            // TODO implement NotificationsService.responseStatusMessage
        });
    };

    this.assignVendorServiceType = function(hofUuid, typeId) {
        return $http({
            url: API_BASE + "/administration/vendor/assign-service-type",
            method: "POST",
            data: {
                vendorHofUuid: hofUuid,
                serviceType: typeId
            }
        }).then(function(result) {
            return result.data.status;
        }, function(error) {
            // TODO implement NotificationsService.responseStatusMessage
        });
    };

    this.rejectVendorServiceType = function(hofUuid, typeId) {
        return $http({
            url: API_BASE + "/administration/vendor/reject-service-type",
            method: "POST",
            data: {
                vendorHofUuid: hofUuid,
                serviceType: typeId
            }
        }).then(function(result) {
            return result.data.status;
        }, function(error) {
            // TODO implement NotificationsService.responseStatusMessage
        });
    };

    this.getAssignedVendorServiceTypes = function(hofUuid) {
        return $http({
            url: API_BASE + "/administration/vendor/assigned-service-types",
            method: "GET",
            params: {hofUuid: hofUuid}
        }).then(function(result) {
            return result.data.map(function(item) {
                var vendorService   = new VendorServiceType();
                vendorService.id    = item.service_type_id;
                vendorService.code  = item.service_type_code;
                vendorService.name  = item.service_type_name;
                return vendorService;
            });
        });
    };

    this.getVendorServiceTypes = function(equ_id) {
        return $http({
            url: API_BASE + "/administration/vendor/service-types",
            method: "GET",
            params: {equipmentId: equ_id}
        }).then(function(result) {
            return result.data.map(function(item) {
                var vendorService   = new VendorServiceType();
                vendorService.id    = item.service_type_id;
                vendorService.code  = item.service_type_code;
                vendorService.name  = item.service_type_name;
                vendorService.mutable = item.is_mutable;
                return vendorService;
            });
        }, function(error) {
            // TODO implement NotificationsService.responseStatusMessage
        });
    };

    this.assignEquipmentVendor = function(equipmentId, serviceType) {
        return $http({
            url: API_BASE + "/administration/vendor/assign-equipment-vendor",
            method: "POST",
            data: {
                equipmentId:    equipmentId,
                serviceType:    serviceType.id,
                vendorHofUuid:  serviceType.selectedVendor.uuid
            }
        });
    };

    this.rejectEquipmentVendor = function(equipmentId, serviceType) {
        return $http({
            url: API_BASE + "/administration/vendor/reject-equipment-vendor",
            method: "POST",
            data: {
                equipmentId:    equipmentId,
                serviceType:    serviceType.id,
                vendorHofUuid:  serviceType.selectedVendor.uuid
            }
        });
    };

    this.getEquipmentVendors = function(equipmentId) {
        return $http({
            url: API_BASE + "/administration/vendor/equipment-vendors",
            method: "GET",
            params: {equipmentId: equipmentId}
        }).then(function(result) {
            return result.data;
        });
    };
}
