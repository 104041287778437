import { mode, api } from '../../bootstrap.json';
import _ from 'lodash';

/**
 * Creates a new Logger.
 * @class
 */
export class Logger {
    /**
     * @constructor
     * @param $window
     */
    constructor($window) {
        this.$window = $window;
    }

    log(exception) {
        if (mode !== 'production' && (localStorage.myrsoAuthToken && localStorage.myrsoJWT)) {
            var user = JSON.parse(localStorage.myrsoUser);
            var data = new FormData();
            data.append('errorUrl', this.$window.location.href);
            data.append('errorUser', _.get(user, 'type') + ': ' + _.get(user, 'name'));

            if (_.isString(exception))
                data.append('errorMessage', exception);

            if (_.isObject(exception) && _.has(exception, 'stack'))
                data.append('errorMessage', exception.stack);

            if (_.isObject(exception) && _.has(exception, 'xhrStatus'))
                data.append('errorMessage', JSON.stringify({
                    method:         exception.config.method,
                    url:            exception.config.url,
                    description:    exception.data,
                    status:         exception.status
                }));

            var xmlhttp = new XMLHttpRequest();
            xmlhttp.open('POST', api + '/log', true);
            xmlhttp.setRequestHeader('X-Authorization', localStorage.myrsoAuthToken);
            xmlhttp.setRequestHeader('X-Token', localStorage.myrsoJWT);
            xmlhttp.send(data);
        }
    }

    getLog(callback) {
        const xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function() {
            if (xmlhttp.readyState === 4 && xmlhttp.status === 200)
                callback(JSON.parse(xmlhttp.responseText));
        };
        xmlhttp.open('GET', api + '/log', true);
        xmlhttp.setRequestHeader('X-Authorization', localStorage.myrsoAuthToken);
        xmlhttp.setRequestHeader('X-Token', localStorage.myrsoJWT);
        xmlhttp.send();
    }
}

Logger.$inject = ['$window'];
