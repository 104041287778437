import { GridController, gridTpl } from '../../../../common/grid/grid.controller';

/**
 * Creates a new DrlCtScannerSystemStatusGridController.
 * @class
 */
class DrlCtScannerSystemStatusGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, DoseReferenceLevelsActions, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.DoseReferenceLevelsActions = DoseReferenceLevelsActions;
        this.grid_filter = $filter
    }

    $onInit() {
        this.module = 'doseReferenceLevels';
        this.identifier = 'drlCtScannerSystemStatusGrid';
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: this.grid_filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.GRID_COLUMN_ID'),           mapper: 'id'},
            {title: this.grid_filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.GRID_COLUMN_USER'),         mapper: 'user'},
            {title: this.grid_filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.GRID_COLUMN_NAME'),      mapper: 'name'},
            {title: this.grid_filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.GRID_COLUMN_ADDRESS'),      mapper: 'address'},
            {title: this.grid_filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.GRID_COLUMN_PHONE'),        mapper: 'phone'},
            {title: this.grid_filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.GRID_COLUMN_EMAIL'),       mapper: 'email'},
            {title: this.grid_filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.GRID_COLUMN_LAST_SEEN'),    mapper: 'last_seen'},
            {title: this.grid_filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.GRID_COLUMN_STATUS'),       mapper: 'status'}
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.DoseReferenceLevelsActions)(this);
        this.dataAction = this.getSystemStatus;
        this.filter.identifier = this.identifier;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

DrlCtScannerSystemStatusGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'DoseReferenceLevelsActions', '$filter'];

export const drlCtScannerSystemStatusGrid = {
    bindings: {
        title: '@'
    },
    controller: DrlCtScannerSystemStatusGridController,
    ...gridTpl
};
