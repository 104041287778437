import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import { getDosimetryBadges } from '../employees-dosimetry-badge.actions';

/**
 * Creates a new employeesDosimetryBadgesGridController.
 * @class
 * @memberOf module:root/legacy/components/employees
 */
class employeesDosimetryBadgesGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'employees';
        this.identifier = 'employeeDosimetryBadgesGrid';
        this.header = false;
        this.filterComponent = false;
        this.exportComponent = false;

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), {
                getDosimetryBadges
            }
        )(this);

        this.columns = [
            {title: '#',                mapper: null},
            {title: 'Participant No',   mapper: 'participant_number'},
            {title: 'Badge Type',       mapper: 'badge_type'},
            {title: 'Inception Date',   mapper: 'inception_date'},
            {title: 'Wear Period',      mapper: 'wear_period'},
        ];

        this.dataAction = this.getDosimetryBadges;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    };
}

export const employeesDosimetryBadgesGrid = {
    bindings: {
        title: '@',
        onRemove: '&'
    },
    controller: employeesDosimetryBadgesGridController,
    ...gridTpl
};
