import { tabs } from './manufacturers.tabs';
import { GridReducer } from '../../../common/grid/grid.reducer';
import merge from 'lodash/merge';
import set from 'lodash/set';

export default {
    ...tabs,
    manufacturer: {
        id: null,
        name: null,
        logo: null,
        description: null,
        equipmentType: null,
        equipmentTypeName: null,
        equipment_types: [],
    },
    manufacturerEquModel: {
        emo_id: null,
        equipment_model: null,
        man_id: null,
        manufacturer: null,
        ety_id: null,
        equipment_type: null,
        image: null,
    },
    manufacturerTypes: [],
    manufacturerTypesSelected: [],
    manufacturersGrid: merge(set(GridReducer(), 'itemsCount', 1), {
        filter: {
            keyword: null,
            all_app_variants: 0
        }
    }),
    manufacturerEquipmentGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0
    },
};
