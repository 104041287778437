import { IDS } from '../add-document-action-id.constants';
import { IDS as MASTER_DOC_LIST_IDS } from '../../master-doc-list/master-doc-list-action-id.constants';
import { IDS as STANDARD_IDS } from '../../standard/standard-action-id.constants';
import { TYPES } from '../add-document-action-type.constants';
import * as Actions from '../add-document.actions';
// import * as EvidenceActions from '../scheme/evidence/evidence.actions';
import get from 'lodash/get';
import pick from 'lodash/pick';
import I18N from "../../../../common/utils/util.i18n";
import {TAB_SELECT, TAB_TRANSITION_DISABLE} from "../../../../common/tabs/tabs.constants";
import moment from "moment/moment";

export const accAddNewDocument = {
    /* @ngInject */
    controller: function ($ngRedux,
                    $scope,
                    $state,
                    $stateParams,
                    $filter,
                    $timeout,
                    $location,
                    $mdDialog,
                    FileUploader,
                    Notifier,
                    FileActions) {
        this.identifier = IDS.accAddDocument;
        /**
         * @function $onInit
         * @returns {Promise<void>}
         */
        this.$onInit = async () => {
            this.isPublic = $state.$current.name.split('.')[0] === 'public';
            this.schemeCode = $stateParams.itemCode;
            this.showStandard = !!$stateParams.standard;

            this.minDueDate = new Date();
            this.maxDueDate = moment(this.minDueDate).add(1, 'years').subtract(1,'days').toDate();

            $ngRedux.dispatch({ type: 'INPUT_TEXT_AREA_CHANGE', id: IDS.accAddDocument, path: 'documentName',          input: '' });
            $ngRedux.dispatch({ type: 'INPUT_TEXT_AREA_CHANGE', id: IDS.accAddDocument, path: 'documentDescription',   input: '' });

            this.unsubscribe = $ngRedux.connect(state => {
                const accAddDocument    = state.accreditation.accAddDocument;
                const assessmentItem            = accAddDocument?.assessmentItem;
                const evidenceTaskStatusCode    = assessmentItem?.evidenceTask?.status_code;
                const accDashboardLocationYear = state.accreditation.accDashboardLocationYear;
                return {
                    ...pick(accAddDocument,[
                    'siteLocationName',
                    'siteLocationUuid',
                    'standardId',
                    'standardName',
                    'standardDescription',
                    'standardStatusName',
                    'standardStatusCode',
                    'documentTypes',
                    'documentType',
                    'documentName',
                    'documentDescription',
                    'documentDate',
                    'documentDueDate',
                    'documentNumber',
                    ]),
                    docGeneratingEnabled:   get(accAddDocument, 'assessmentItem.is_generated'),
                    evidenceTask:           assessmentItem?.evidenceTask,
                    evidenceTaskStatus:     evidenceTaskStatusCode && I18N.translate(`ACCREDITATION.EVIDENCE.STATUS.${evidenceTaskStatusCode.toUpperCase()}`),
                    isUserEmployee:         !state.app.user.typeCode,
                    sampleDocPath:          assessmentItem?.sample_doc_path,
                    sampleDocFilename:      assessmentItem?.sampleDocFilename,
                    fiscalYear:             accDashboardLocationYear.year,
                }
            }, {
                ...Actions,
                // ...EvidenceActions,
                ...FileActions,
            })(this);

            const { standard, slo } = $stateParams;
            if(standard && standard !== this.standardId) {
                this.getSectionDetails(standard);
            }
            if(slo && slo !== this.siteLocationUuid) {
                this.fetchSiteLocationDetails(slo);
            }
            // const ctrl = this
            // $timeout(() => {
            //     const path = ctrl.$location.path();
            //     const tab = path.split('/').pop();
            //     if(['default-disabled','master-doc-list'].includes(tab)) {
            //         ctrl.$ngRedux.dispatch({
            //             type: TAB_TRANSITION_DISABLE,
            //             id: IDS.app_accreditation_scheme_add_document
            //         });
            //         switch(tab) {
            //             case 'default-disabled': ctrl.$state.go(IDS.app_accreditation_scheme_add_document_default_disabled); break;
            //             case 'master-doc-list':  ctrl.$state.go(IDS.app_accreditation_scheme_add_document_master_doc_list);  break;
            //         }
            //     }
            // }, 200);

            $ngRedux.dispatch({
                type:   TYPES.REFRESH_DOCUMENT_DETAILS,
                id:     IDS.accAddDocument,
                data:  {
                    documentType: 'pol',
                    documentNumber: '',
                    documentName: '',
                    documentDescription: '',
                    documentDate: new Date(),
                    documentDueDate: null,
                }
            });
        }

        this.onUploadButtonSave = (event) => {
            $scope.$broadcast('show-errors-check-validity', '$ctrl.accStandardAddNewDocumentForm');
            if (this.accStandardAddNewDocumentForm.$invalid) {
                Notifier.error('COMMON.FIELDS_REQUIRED');
                return;
            }
            Notifier.info('Please wait...');
            this.addStandardRequiredDoc(
                this.siteLocationUuid,
                this.documentNumber,
                this.documentName,
                this.documentDescription,
                this.documentType,
                this.standardId,
                this.documentDate,
                this.documentDueDate,
                event[0].filePath
            ).then( (response) => {
                this.onButtonBack();
            });
        }

        // this.onButtonGenerateDocument = () => {
        //     this.generateAccDocument({
        //         doc_type:   this.assessmentItem.type_code,
        //         aas_id:     this.assessmentItem.id,
        //         slo_uuid:   this.siteLocationUuid,
        //         year:       this.fiscalYear
        //     }).then( () => {
        //
        //         this.fetchAssessmentItem(this.assessmentItem.id, this.standardId, this.siteLocationUuid, this.fiscalYear);
        //         this.getSectionDetails(this.standardId);
        //         this.fetchEvidenceAssessment(
        //             this.assessmentItem.ast_id,
        //             this.siteLocationUuid,
        //             this.fiscalYear
        //         ).then(()=>{
        //             // $ngRedux.dispatch({
        //             //     type: ACC_TYPES.SELECT_ASSESSMENT_ITEM,
        //             //     id: IDS.accMasterDocListAdd,
        //             //     data: {
        //             //         assessmentItem:     this.assessmentItem,
        //             //         standardStatusName: this.standardStatusName,
        //             //         standardStatusCode: this.standardStatusCode,
        //             //     }
        //             // });
        //             this.goToStandard();
        //         });
        //     });
        // }

        this.onButtonCancel = () => {
            this.uploader.cancelAll();
        }

        this.onButtonBack = () => {
            if(this.standardName)
                this.goToStandard();
            else
                this.goToMasterDocList();
        }

        this.goToStandard = () => {
            // if(this.isUserEmployee) {
            //     $state.go(this.isPublic ? 'public.accreditation.tasks' : 'app.accreditation.tasks');
            //     return;
            // }
            $state.go(STANDARD_IDS.app_accreditation_scheme_standards_documents, {
                itemCode:   this.schemeCode,
                standard:   this.standardId,
                year:       this.fiscalYear,
                slo:        this.siteLocationUuid,
            });
        }

        this.goToMasterDocList = () => {
            $state.go(MASTER_DOC_LIST_IDS.app_accreditation_scheme_master_doc_list, {
                itemCode: this.schemeCode,
            });
        }
        this.downloadSampleDoc = () => {
            let path = this.sampleDocPath;
            if(path) {
                if(path.includes('/') === false) // template with default path
                    path = `/acc_doc/reference/${path}`;
                this.download(`storage${path}`);
            }
        }

        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },

    template: require('./new-document.html')
}
