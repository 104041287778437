import { GridReducer } from '../../../common/grid/grid.reducer';
import {GridFilterReducer} from '../../../common/grid/filter/grid-filter.reducer';
import * as GRID from '../../../common/grid/grid.constants';
import * as FORM from '../../../common/form/input/input.constants';
import { TYPES } from './import-settings-action-type.constants';
import { IDS } from './import-settings-action-id.constants';
import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';

/**
 * @function EmpImportSettingsReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function EmpImportSettingsReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            [IDS.empImportSettingsCentersListGrid]:         GridReducer(),
            [IDS.empImportSettingsOccupationsListGrid]:     GridReducer(),
            [IDS.empImportSettingsUsersRelocatedListGrid]:  GridReducer(),
            [IDS.empImportSettingsUsersSkippedListGrid]:    GridReducer(),
            [IDS.empImportSettingsCentersDetails]: {
                siteLocation: null,
            }
        };
    }

    switch (action.type) {
        case TYPES.FETCH_COST_CENTER_DETAILS:
            return merge({...state}, {
                [IDS.empImportSettingsCentersDetails]: action.details
            });

        // common

        case 'LOCATION_SELECTED':
            if(action.id === IDS.empImportSettings) {
                return set({...state}, action.path,
                    GridReducer(get(state, action.path), {
                        type: GRID.GRID_FILTER,
                        filter: {
                            hof_uuid: action.uuid
                        }
                    })
                )
            }

        case GRID.GRID_INIT:
        case GRID.GRID_FILTER:
        case GRID.GRID_FILTER_TOGGLE:
        case GRID.GRID_RESET_FILTER:
        case GRID.GRID_INIT_FILTER:
        case GRID.GRID_LOADING:
        case GRID.GRID_PAGINATE:
        case GRID.GRID_RELOAD:
        case GRID.GRID_FETCH:
        case GRID.GRID_SELECT:
        case GRID.GRID_SELECT_ALL:
        case GRID.GRID_DESELECT:
        case GRID.GRID_DESELECT_ALL:
            if (action.id === IDS.empImportSettings) {
                return set({...state}, action.path,
                    GridReducer( get(state, action.path), action)
                )
            }
        case GRID.GRID_RESET:
            if (action.id === IDS.empImportSettings) {
                if([
                    IDS.empImportSettingsCentersListGrid,
                    IDS.empImportSettingsUsersSkippedListGrid,
                ].includes(action.path) ) {
                    state = set({...state}, `${action.path}.reset`, !get(state, `${action.path}.reset`));
                    state = set({...state}, `${action.path}.reload`, !get(state, `${action.path}.reload`));
                    return set(state, action.path,
                        GridReducer(get(state, action.path), {
                            type: GRID.GRID_RESET_FILTER,
                            exclude: action.exclude // || ['source','gci_id'],
                        })
                    )
                }
                else
                    return set({...state}, action.path,
                        GridReducer( get(state, action.path),{ type: GRID.GRID_RESET })
                    )
            }

        case FORM.INPUT_SELECT:
        case FORM.INPUT_TEXT:
        case FORM.INPUT_TEXT_AREA:
        case FORM.INPUT_CHECKBOX:
        case FORM.INPUT_DATE:
        case FORM.INPUT_RADIO:
            if (action.id === IDS.empImportSettings) {
                if(action.path.startsWith(IDS.empImportSettingsUsersSkippedListGrid)) {
                    const filter = {
                        [action.path.split('.').pop()]: action.input
                    };
                    action.path = IDS.empImportSettingsUsersSkippedListGrid;
                    return set({...state}, action.path,
                        GridReducer(get(state, action.path), { type: GRID.GRID_FILTER, filter })
                    )
                }
                else
                    return set({...state}, action.path, action.input)
            }
        default:
            return state;
        }
}
