import {IDS} from '../audit-action-id.constants';
import {TYPES} from '../audit-action-type.constants';
import {TYPES as ACC_TYPES} from '../../accreditation-action-type.constants'
import {TAB_SHOW} from '../../../../common/tabs/tabs.constants';
import * as AuditActions from '../audit.actions'
import * as AccActions from '../../accreditation.actions'
import moment from 'moment';
import isFunction from "lodash/isFunction";
import get from "lodash/get";

class AuditScheduleController {
    constructor($ngRedux,
                $stateParams,
                $scope,
                $rootScope,
                $filter,
                $mdDialog,
                Notifier,
                DocumentManager,
                AuthService,
                EmployeeService,
                $timeout) {
        this.$ngRedux = $ngRedux;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.$mdDialog = $mdDialog;
        this.Notifier = Notifier;
        this.DocumentManager = DocumentManager;
        this.AuthService = AuthService;
        this.EmployeeService = EmployeeService;
        this.$timeout = $timeout;
        this.identifier = IDS.accAudit;
        this.selectedFormat = null;

        this.exportFormat = [
            {format: 'pdf',     name: 'PDF'},
            {format: 'csv',     name: 'CSV'},
            {format: 'xls',     name: 'Excel 5'},
            {format: 'xlsx',    name: 'Excel 2007'}
        ]
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(state => {
            const {selectedSection, monthColumns, standardRows} = state.accreditation.accAudit.schedule;
            const {locationName, fiscalYearStart, autocompleteReviewers} = state.accreditation.accAudit.siteLocation;
            const {siteLocation, headOffice, year} = state.accreditation.accDashboardLocationYear;
                return {
                    fiscalYear: year,
                    fiscalYearRange: `${year} - ${parseInt(year) + 1}`,
                    fiscalYearStart,

                    headOffice,     // uuid
                    siteLocation,   // uuid
                    locationName,
                    autocompleteReviewers,

                    monthColumns,
                    standardRows,

                    standardId:                 selectedSection?.id,
                    standardLevel:              selectedSection?.level,
                    standardNumber:             selectedSection?.number,

                    showExportPanel:            Boolean(get(standardRows, 'length')),
                }
            }, {...AuditActions, ...AccActions})(this);

        this.schemeCode = this.$stateParams.itemCode;
        this.getSchemeDetails(this.schemeCode).then(({name, id}) => {
            this.title = name;
            // this.schemeId = id;
        });

        this.$scope.$on('LOCATION_SUBJECT_SELECTED', this.onLocationChange.bind(this));
        this.$scope.$on('LOCATION_SUBJECT_NOT_FOUND', this.onLocationNotFound.bind(this));
        this.$scope.$on('SELECT_CASCADE_SUBJECTS_LOADED', this.onSelectCascadeLoaded.bind(this));
    }

    onSelectCascadeLoaded() {
        if(this.headOffice && this.siteLocation) {
            this.$rootScope.$broadcast('FORCE_SELECT_PARENT', 0, this.headOffice, this.siteLocation);
        }
    }

    onLocationChange (evt, loc) {
        const subjectType = get(loc,'in_subject_basic_type_code');
        switch(subjectType){
            case 'hof':
                this.$ngRedux.dispatch({
                    type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                    accDashboardLocationYear: {
                        headOffice: get(loc, 'in_subject_uuid'),
                    }
                });
            break;

            case 'slo':
                this.$ngRedux.dispatch({
                    type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                    accDashboardLocationYear: {
                        siteLocation: get(loc, 'in_subject_uuid'),
                        year: this.year,
                    }
                });
                this.fetchSiteLocationDetails( loc.in_subject_uuid ).then( () => {
                    this.fetchAudits(
                        this.schemeCode,
                        this.siteLocation,
                        this.fiscalYear,
                        this.fiscalYearStart).then(() => {

                    });
                });
                this.fetchSiteLocationContacts( loc.in_subject_uuid );
            break;
        }
    };

    onLocationNotFound(event, location) {
        this.hideSelectSiteLocation = true;
        var user = this.AuthService.getUser();
        if(this.selectedLocationUuid || user.parentCount !== 1)
            return

        const ctrl = this;
        this.EmployeeService.getEmploymentLocations(user.uuid).then(siteLocations => {
            const selectedLocationUuid = get(siteLocations,'[0].uuid');
            if(selectedLocationUuid) {
                ctrl.selectedLocationUuid = selectedLocationUuid;
                this.fetchSiteLocationContacts( ctrl.selectedLocationUuid );
                this.fetchSiteLocationDetails( ctrl.selectedLocationUuid ).then( () => {
                    this.fetchAudits(this.schemeCode, this.selectedSiteLocation?.uuid, this.fiscalYear, this.fiscalYearStart)
                });
            }
        });
    }

    onYearChange({item}) {
        this.$stateParams.year = item.value;
        this.fetchSiteLocationDetails( this.siteLocation ).then( () => {
            this.fetchAudits(this.schemeCode, this.siteLocation, this.fiscalYear, this.fiscalYearStart);
        });
    }

    getCellStyle(auditItem, index) {
        if(auditItem.disabled)
            return 'table-info';
        else if(auditItem.cells[index]) {
            switch(auditItem.cells[index].status) {
                case 'don': return 'table-success';
                case 'sch': return 'table-warning';
                case 'ove': return 'table-danger';
            }
        }
        if(this.monthColumns[index]) {
            const [monthName, year] = this.monthColumns[index].split(' ');
            if (moment().year(year).month(monthName).isBefore(moment()))
                return "background-light-gray"
        }
    };

    onSelectScheduleCell(standardRow, index) {
        const cell = standardRow.cells[index];
        if(!cell)
            return;
        this.$ngRedux.dispatch({
            type: TYPES.REFRESH_AUDIT_DETAILS,
            id: IDS.accAudit,
            data: {
                auditId:            cell.auditId,
                standardName:       cell.standardName,
                statusCode:         cell.statusCode,
                date:               cell.date,
                reviewers:        [...cell.reviewers],
                sourceTab:          IDS.app_accreditation_scheme_audit_schedule_table,
            }
        });

        this.$ngRedux.dispatch({
            type: TAB_SHOW,
            id: IDS.app_accreditation_scheme_audit_schedule,
            state: [IDS.app_accreditation_scheme_audit_schedule_details],
            // stateParams: {
            //     auditRequestId: selectedCell.auditRequestId,
            // },
        });
    }

    onSelectStandardCell(standardRow) {
        this.selectStandardById(standardRow.id);
        if (this.standardLevel === 3) {
            this.fetchEvidenceAssessment(standardRow.id, this.selectedSiteLocation.uuid, this.fiscalYear).then(() => {
                this.switchToEvidenceTab(this.standardId, this.selectedSiteLocation.uuid, this.fiscalYear, this.$timeout);
                this.unselectAllTreeNodes();
            });
        } else {
            this.switchToProgressTab(this.standardId, this.selectedSiteLocation.uuid, this.fiscalYear, this.$timeout);
            this.unselectAllTreeNodes();
        }
    }

    findExistingReviewInSameFiscalYear(auditItem, selectedIndex) {
        let [selectedMonthName, selectedYear] = this.monthColumns[selectedIndex].split(' ');
        const selectedMonth = moment().month(selectedMonthName).month();
        if(selectedMonth < this.fiscalYearStart)
            selectedYear -= 1;
        const firstMonthOfFiscalYearLabel = this.monthColumns.find(item => {
            let [monthName, year] = item.split(' ');
            const month = moment().month(monthName).month();
            return (parseInt(year) === parseInt(selectedYear) && month === this.fiscalYearStart);
        });
        const firstMonthOfFiscalYearIndex = this.monthColumns.indexOf(firstMonthOfFiscalYearLabel);
        const fiscalYearCells = auditItem.cells.slice(firstMonthOfFiscalYearIndex, firstMonthOfFiscalYearIndex + 12);
        return fiscalYearCells.find(item => item);
    }

    onAuditItemCheckboxChange(auditItem) {
        const statusCode = auditItem.disabled ? 'inv' : 'act';
        this.submitReviewSchedule(auditItem.id, null, this.selectedSiteLocation, 0, 0, statusCode);
    }

    exportSchedule() {
        if (isFunction(this.fetchAudits)) {
            this.fetchAudits(
                this.schemeCode,
                this.selectedSiteLocation.uuid,
                this.fiscalYear,
                this.fiscalYearStart,
                'utaaud',
                this.selectedFormat
            ).then(response => {
                    this.selectedFormat = null;
                    this.DocumentManager.downloadFile('/' + response.data.path);
                    this.$mdDialog.hide();
                    this.Notifier.success('Export Complete!');
                });
        }
        else {
            throw new Error('Missing export action definition');
        }
    }

    $onDestroy() {
        this.$ngRedux.dispatch({
            type: TYPES.RESET_SCHEDULE,
            id: this.identifier
        });
        this.unsubscribe();
        if (this.dialog)
            this.$mdDialog.hide(this.dialog);
    }
}

AuditScheduleController.$inject = [
    '$ngRedux',
    '$stateParams',
    '$scope',
    '$rootScope',
    '$filter',
    '$mdDialog',
    'Notifier',
    'DocumentManager',
    'AuthService',
    'EmployeeService',
    '$timeout']

export const accAuditSchedule = {
    controller: AuditScheduleController,
    template: require('./schedule.html')
}
