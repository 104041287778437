/**
 * @module root/legacy/components/manufacturers
 */
import * as STATES from './manufacturers.states';
import { ManufacturersGridController } from './grid/manufacturers-grid.controller';
import { SelectManufacturerController } from './form/select-manufacturer.controller';
import { SelectManufacturerTypeController } from './form/select-manufacturer-type/select-manufacturer-type.controller';
import { ManufacturerService } from './manufacturers.service';
import { ManufacturersActionsFactory } from './manufacturers.actions';
import { ManufacturerTypeActionsFactory } from './form/select-manufacturer-type/select-manufacturer-type.actions';
import { ManufacturerFactory } from './manufacturer.factory';
import { manufacturers } from './manufacturers.component';
import { manufacturersGrid } from './grid/manufacturers-grid.component';
import { manufacturerDetails } from './details/manufacturer-details.component';
import { manufacturerLogo } from './details/header/manufacturer-logo.component';
import { manufacturerEquipment } from './equipment/manufacturer-equ.component';
import { manufacturerEquipmentGrid } from './equipment/grid/manufacturer-equ-grid.component';
import { manufacturerEquipmentGridModel } from './equipment/grid/manufacturer-equ-grid-model.component';
import { manufacturerEquipmentDetails } from './equipment/details/manufacturer-equ-details.component';
import { selectManufacturer } from './form/select-manufacturer.component';
import { selectManufacturerType } from './form/select-manufacturer-type/select-manufacturer-type.component';

angular.module(
    'components.manufacturers',
    []
)
.config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values({
        ...STATES,
    }).map(state => stateRegistry.register(state));
}]).controller({
    ManufacturersGridController,
    SelectManufacturerController,
    SelectManufacturerTypeController
}).service({
    ManufacturerService
}).factory({
    ManufacturersActions: ManufacturersActionsFactory,
    ManufacturerTypeActions: ManufacturerTypeActionsFactory,
    Manufacturer: ManufacturerFactory
}).component({
    manufacturers,
    manufacturersGrid,
    manufacturerDetails,
    manufacturerLogo,
    manufacturerEquipment,
    manufacturerEquipmentGrid,
    manufacturerEquipmentGridModel,
    manufacturerEquipmentDetails,
    selectManufacturer,
    selectManufacturerType
}).run(($templateCache) => {
    $templateCache.put('app.administration.manufacturers.add.details', '<manufacturer-details></manufacturer-details>');
    $templateCache.put('app.administration.manufacturers.manage.list', '<manufacturers></manufacturers>');
    $templateCache.put('app.administration.manufacturers.manage.details', '<manufacturer-details></manufacturer-details>');
    $templateCache.put('app.administration.manufacturers.manage.equipment', '<manufacturer-equipment></manufacturer-equipment>');
    $templateCache.put('app.administration.manufacturers.manage.equipmentModel', '<manufacturer-equipment-details></manufacturer-equipment-details>');
    $templateCache.put('./manufacturers-grid-filter.html', require('./grid/manufacturers-grid-filter.html'));
    $templateCache.put('./manufacturer-equ-grid-filter.html', require('./equipment/grid/manufacturer-equ-grid-filter.html'));
    $templateCache.put('manufacturerLogo', '<manufacturer-logo></manufacturer-logo>');
    $templateCache.put('manufacturerEquGridModel', '<manufacturer-equipment-grid-model></manufacturer-equipment-grid-model>');
});
