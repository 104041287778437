import { GridController } from "../../../../../common/grid/grid.controller";
import watch from 'redux-watch';

/* @ngInject */
export function mammoQualityControlArtifactEvaluationGridController($controller, $ngRedux, $state, $rootScope, NavigationService, MammoQualityControlActions, ArtifactEvaluationService, MammoQCService) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'mammoQualityControl';
        ctrl.identifier = 'mammoQualityControlArtifactEvaluationGrid';
        ctrl.filterTemplate = './mammo-quality-control-artifact-evaluation-grid-filter.html';
        ctrl.exportService = ArtifactEvaluationService.getArtifactEvaluationTests;
        ctrl.autoload = false;
        ctrl.message = 'Please fill required fields in filter form to see results';

        ctrl.columns = [
            {title: '#',                                        mapper: null},
            {title: 'Date',                                     mapper: 'testDate'},
            {title: 'Tech',                                     mapper: 'tech'},
            {title: 'Attenuator',                               mapper: 'attenuator'},
            {title: 'Target/Filter',                            mapper: 'targetFilter'},
            {title: 'kVp',                                      mapper: 'kvp'},
            {title: 'mAs',                                      mapper: 'mas'},
            {title: 'Artifacts',                                mapper: 'artifacts'},
            {title: 'Comments',                                 mapper: 'comments'},
            {title: 'Artifacts Phantom Position 2',             mapper: 'artifactsPhantomPosition2'},
            {title: 'Comments Phantom Position 2',              mapper: 'commentsPhantomPosition2'},
            {title: 'Pass/Fail',                                mapper: 'passFail'},
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), MammoQualityControlActions)(ctrl);
        ctrl.dataAction = ctrl.getMammoQCArtifactEvaluationList;

        ctrl.init.call(ctrl);
    };

    var watchReset = watch($ngRedux.getState, 'mammoQualityControl.mammoQualityControlArtifactEvaluationGrid.reset');
    var onReset = $ngRedux.subscribe(watchReset(function() {
        $rootScope.$broadcast('SELECT_CASCADE_RESET', 'artifactEvaluationFilterForm');
    }));

    ctrl.$onDestroy = function() {
        onReset();
        ctrl.destroy();
    };
}
