import {IDS} from "./dms-import-action-id.constants";
import {TAB_DISABLE, TAB_HIDE} from "../../../common/tabs/tabs.constants";

const QUERY_PARAMS = '?param1&param2';

export const dashboardState = {
    name: IDS.app_dms_import,
    url: '/import',
};

export const historyTabsState = {
    name: IDS.app_dms_import_history,
    redirectTo: IDS.app_dms_import_history_list,
    url: '/import',
};

export const tabHistoryListState = {
    name: IDS.app_dms_import_history_list,
    url: '/list',
    // component: 'tabs',
    component: 'dmsManageList',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: IDS.app_dms_import_history,
            state: [
                IDS.app_dms_import_history_details,
            ]
        })
    }
};
tabHistoryListState.onEnter.$inject = ['$ngRedux'];

export const tabHistoryDetailsState = {
    name: IDS.app_dms_import_history_details,
    url: '/details',
    component: 'dmsManageDetails',
};

export const tabNewAddState = {
    name: IDS.app_dms_import_upload,
    url: '/add-document',
    component: 'dmsManageDetails',
};