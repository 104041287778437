/* @ngInject */
export const TubeInsertFactory = (EquipmentType, Location, Room, Manufacturer, EquipmentModel) => {
    return function TubeInsert() {
        this.id = null;
        this.type = new EquipmentType();
        this.manufacturer = new Manufacturer();
        this.name = null;
        this.serial = null;
        this.description = null;
        this.room = new Room();
        this.department = new Location();
        this.model = new EquipmentModel();
    };
};
