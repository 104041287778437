/** @namespace Reducers.Help */

/**
 * @function helpReducer
 * @param state {object}
 * @param action {object}
 * @memberOf Reducers
 */
export function helpReducer(state, action) {

    if (typeof state === 'undefined') {
        return {};
    }

    switch (action.type) {
        default:
            return state;
    }
}
