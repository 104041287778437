import { gridTpl } from '../../../../../../../common/grid/grid.controller';

export const administrationAccountsSiteLocationLicencesGrid = {
    bindings: {
        title: '<',
        onSelect: '&',
        onRemove: '&',
        onIsotopes: '&',
        onAppendix: '&',
    },
    controller: 'AdministrationAccountsSiteLocationLicencesGridController',
    ...gridTpl
};
