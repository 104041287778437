/**
 * Creates a new GridFilterController.
 * @class
 */
class GridFilterController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.colorName  = this.navConfig.colorName;

        if (typeof this.icon === 'undefined')
            this.icon = this.navConfig.icon;

        if (typeof this.open === 'undefined')
            this.open = false;

        this.paginationId = `${this.identifier}${this.statePath || ''}`
    }

    paginate(page) {
        this.$ngRedux.dispatch({
            type: 'PAGINATE',
            id: this.identifier,
            path: this.statePath,
            page: page
        });
    }

    reset() {
        this.$ngRedux.dispatch({
            type: 'GRID_RESET',
            id: this.identifier,
            path: this.statePath
        });
    }
}

GridFilterController.$inject = ['$ngRedux'];

export const gridFilter = {
    bindings: {
        identifier: '<',
        statePath: '<',
        navConfig: '<',
        header: '<',
        icon: '<',
        title: '<',
        visible: '<',
        expandButtonVisible: '<',
        open: '<',
        controls: '<',
        template: '<',
        actionSelect: '<',
        actions: '<'
    },
    controller: GridFilterController,
    template: require('./grid-filter.html')
};
