/* @ngInject */
export const GridFactory = (Helpers, GridFilter, Notifier) => {
    return function Grid(filter) {

        this.id = Helpers.generateIdentifier();

        this.autoload = true;
        this.filterComponent = true;
        this.exportComponent = true;

        this.columns = [];
        this.dataProvider = null;
        this.items = [];
        this.totalItems = 0;
        this.loading = true;
        this.info = '';

        var filterConstructor = _.isUndefined(filter) ?  GridFilter : filter;
        this.filter = new filterConstructor();
        this.filter.id = this.id;

        this.filter.search = (function(page) {
            if (_.isFunction(this.dataProvider)) {
                showLoading();
                this.filter.init(page);
                this.dataProvider(this.filter).then((function(data) {
                    this.totalItems = data.total;
                    this.items = data.items;
                    hideLoading();
                }).bind(this), function(error) {
                    hideLoading();
                    Notifier.responseMessage(error);
                });
            }
            else throw new Error('Grid data provider not set');
        }).bind(this);

        this.itemSelected = angular.noop;

        this.columnFilter = function(column) {
            return _.isNull(column.mapper) === false;
        };

        this.renderValue = function(column, item) {
            return column.mapper ? this.getValue(column, item) : this.getItemOrderNumber(item);
        };

        this.getItemOrderNumber = function(item) {
            return _.indexOf(this.items, item) + 1 + this.filter.offset;
        };

        this.getValue = function(column, item) {
            var value = _.get(item, column.mapper, '');
            if (_.isFunction(value)) return value.call(item);
            else return value;
        };

        var showLoading = (function() {
            this.info = 'Please wait loading...';
            this.loading = true;
        }).bind(this);

        var hideLoading = (function() {
            if (this.items.length)
                this.loading = false;
            else
                this.info = 'There are no records';
        }).bind(this);
    };
};
