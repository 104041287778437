import {TAB_HIDE, TAB_DISABLE} from '../../../common/tabs/tabs.constants';
import {IDS} from './standard-action-id.constants';


export const accStandardMainState = {
    name: IDS.app_accreditation_scheme_standards,
    abstract: true,
    redirectTo: IDS.app_accreditation_scheme_standards_dashboard,
    url: '/:itemCode',
}

export const accStandardDashboardState = {
    name: IDS.app_accreditation_scheme_standards_dashboard,
    url: '/standards?standard&year&slo',
    component: 'dashboard',
};

export const accStandardTabsState = {
    name: IDS.app_accreditation_scheme_standards_documents,
    redirectTo: IDS.app_accreditation_scheme_standards_documents_list,
    url: '/standard?standard&year&slo&',
    component: 'tabs',
};

export const accStandardDocListState = {
    name: IDS.app_accreditation_scheme_standards_documents_list,
    url: '/list',
    component: 'accStandardDetails',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: IDS.app_accreditation_scheme_standards_documents,
            state: [ IDS.app_accreditation_scheme_standards_documents_details ]
        })
    }
};
accStandardDocListState.onEnter.$inject = ['$ngRedux'];


export const accStandardDocDetailsState = {
    name: IDS.app_accreditation_scheme_standards_documents_details,
    url: '/document',
    component: 'accStandardDocDetails',
};