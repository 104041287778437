/*
*   Actions which are passed from accreditation.reducer.js to improvement.reducer.js
 */

export const PREFIX = 'ACCREDITATION_IMPROVEMENT';

export const TYPES = {
    FETCH_SITE_LOCATION_DETAILS:    `${PREFIX}_FETCH_SITE_LOCATION_DETAILS`,
    FETCH_IMPROVEMENT_DETAILS:         `${PREFIX}_FETCH_IMPROVEMENT_DETAILS`,
    INIT_REVIEW_STATUSES:           `${PREFIX}_INIT_REVIEW_STATUSES`,
};
