import { generateQRCode } from '../../../../common/utils/util.qrcode';

/* @ngInject */
function selectCascade($ngRedux, $rootScope, $timeout, $compile, $mdDialog, Subject, AccountService, AuthService) {
    return {
        restrict: 'E',
        require: '^form',
        scope: {
            locations: '=',
            level: '=?',
            max: '@',
            purpose: '=?',
            uuid: '=?',
            parent: '=?',
            disable: '=?',
            behaviour: '=?',
            validate: '=?',
            preselected: '@',
            identifier: '@',
            statePath: '@',
            account: '@',
            creators: '=?',
            qrcode: '=?',
            moduleColor: '@',
            module: '@',
            scheme: '@',
            filterFunction: '=?',
            noVendors: '@',
            onlyVendors: '@',
            showSingleParent: '@',
        },
        template: require('./select-cascade.html'),
        link: function(scope, element, attrs, formCtrl) {

            scope.selectForm = formCtrl;
            scope.item = new Subject();
            scope.item.in_purpose = scope.purpose;
            scope.item.in_asc_code = scope.scheme;
            scope.item.in_no_vendors = scope.noVendors || null;
            scope.item.in_only_vendors = scope.onlyVendors || null;
            scope.item.in_show_single_parent = scope.showSingleParent
            scope.items = [];

            if (_.isUndefined(scope.level)) scope.level = 0;
            if (_.isUndefined(scope.max)) scope.max = null;
            if (_.isUndefined(scope.uuid) === false) scope.item.in_parent_token = scope.uuid;
            if (_.isUndefined(scope.disable)) scope.disable = false;
            if (_.isUndefined(scope.behaviour)) scope.behaviour = null;
            if (_.isUndefined(scope.identifier)) scope.identifier = null;
            if (_.isUndefined(scope.statePath)) scope.statePath = null;
            if (_.isUndefined(scope.account)) scope.account = null;
            if (_.isUndefined(scope.creators)) scope.creators = false;
            if (_.isUndefined(scope.qrcode)) scope.qrcode = false;
            if (_.isUndefined(scope.moduleColor)) scope.moduleColor = null;
            if (_.isUndefined(scope.module)) scope.module = null;
            if (_.isUndefined(scope.scheme)) scope.scheme = null;

            // validation hotfix
            var validate = _.isUndefined(scope.validate) ? false : scope.validate;
            var user = AuthService.getUser();

            switch (user.typeCode) {
                case 'sro':
                case 'drs':
                case 'aso':
                case 'qmn':
                case 'gen':
                case 'sqm':
                case 'slc':
                case 'rma':
                case 'arm':
                case 'who':
                case 'whc':
                case 'pos':
                case 'num':
                case 'clm':
                case 'prm':
                case 'prr':
                    validate = false;
                    break;
            }

            // hotfix
            scope.label = 'Department';
            if (scope.parent === 'dep') scope.label = 'Room';

            AccountService.getSelectCascadeSubjects(scope.item).then(function(result) {
                if (result.data.length) {
                    scope.items = scope.filterSubjects(result.data);
                    const first = _.first(scope.items);
                    scope.label = first.in_subject_basic_type_name;
                    scope.code = first.in_subject_basic_type_code;

                    if ((scope.preselected) && (scope.$parent.$parent.preselectedChildLocation)) {
                        scope.item = _.find(scope.items, _.matchesProperty('in_subject_uuid', scope.preselected));
                        //only do preselection of site location first time, when page is loaded
                        delete scope.$parent.$parent.preselectedChildLocation;
                        scope.onSelect();
                    } else if (scope.behaviour) {
                        switch (scope.behaviour) {
                            case 'selectDefault':
                                scope.item = _.first(scope.items);
                                scope.onSelect();
                                break;
                        }
                    }

                    if (scope.disable && scope.locations.length && scope.locations.length == scope.level)
                        element.remove();
                    if(!scope.uuid)
                        $rootScope.$broadcast('SELECT_CASCADE_SUBJECTS_LOADED', scope.identifier, scope.statePath, formCtrl.$name);
                } else {
                    $rootScope.$broadcast('LOCATION_SUBJECT_NOT_FOUND', scope.item, formCtrl.$name);
                    if (typeof scope.code === 'undefined') {
                        switch (scope.parent) {
                            case 'hof': scope.code = 'slo'; break;
                            case 'slo': scope.code = 'dep'; break;
                            case 'dep': scope.code = 'rom'; break;
                        }
                    }
                    if (scope.parent === 'hof' || scope.parent === 'rom') {
                        element.remove();
                    }
                }
            });

            $rootScope.$on('SELECT_CASCADE_RESET', function(event, formName) {
                if (!_.isUndefined(formName) && formName !== formCtrl.$name) return;
                if (_.gte(scope.level, 0) === true && _.isUndefined(scope.nextElement) == false)
                    scope.nextElement.remove();

                if (scope.level === 0) {
                    scope.item = new Subject();
                    scope.item.in_purpose = scope.purpose;
                    scope.accountID = null;
                    if(typeof formCtrl['selectCascade'+scope.level] !== 'undefined')
                        formCtrl['selectCascade'+scope.level].$setValidity('required', true);
                }
            });

            $rootScope.$on('SELECT_CASCADE_DISABLE', function(event, formName) {
                if (!_.isUndefined(formName) && formName !== formCtrl.$name) return;
                scope.disable = true;
            });

            scope.$on('LOCATION_PARENT_SELECTED', function(event, level) {
                if (_.gte(scope.level, level) === true && _.isUndefined(scope.nextElement) === false)
                    scope.nextElement.remove();

                if (scope.level === 0)
                    formCtrl['selectCascade'+scope.level].$setValidity('required', true);
            });

            scope.$on('FORCE_SELECT_PARENT', function (event, level, hofUuid, siteLocationUuid) {

                var homeLocation = _.find(scope.items, _.matchesProperty('in_subject_uuid', hofUuid));

                if (_.gte(scope.level, level) === true && _.isUndefined(scope.nextElement) === false)
                    scope.nextElement.remove();

                if (scope.level === 0) {
                    scope.selectForm['selectCascade' + scope.level].$setValidity('required', true);
                    scope.item = homeLocation;
                    scope.preselectedChildLocation = siteLocationUuid;
                    if(scope.item)
                        scope.onSelect();
                }

            });

            scope.filterSubjects = function (items) {
                if(scope.filterFunction)
                    items = scope.filterFunction(items);
                return items;
            }

            scope.onSelect = function() {
                if (scope.item.uuid) {
                    $rootScope.$broadcast('LOCATION_SUBJECT_SELECTED', scope.item, formCtrl.$name);

                    if (scope.identifier) {
                        $ngRedux.dispatch({
                            type: 'LOCATION_SELECTED',
                            id: scope.identifier,
                            path: scope.statePath,
                            uuid: scope.item.uuid,
                            type_code: scope.item.in_subject_basic_type_code,
                            accountId: scope.item.in_account_id,
                            locationName: scope.item.in_username_par,
                            formValid: scope.selectForm.$valid
                        });
                        scope.code = scope.item.in_subject_basic_type_code;
                    }

                    if (scope.nextElement)
                        $rootScope.$broadcast('LOCATION_PARENT_SELECTED', scope.level);

                    if (validate)
                        formCtrl['selectCascade'+scope.level].$setValidity('required', true);

                    if (scope.max && scope.code === scope.max)
                        return;

                    var newScope = scope.$new(true);
                    newScope._level = scope.level + 1;
                    newScope._max = scope.max;
                    newScope._purpose = scope.purpose;
                    newScope._uuid = scope.item.uuid;
                    newScope._parent = scope.code;
                    newScope._disable = scope.disable;
                    newScope._behaviour = scope.behaviour;
                    newScope._validate = validate && scope.code === 'hof';
                    newScope._preselected = scope.preselectedChildLocation;
                    newScope._identifier = scope.identifier;
                    newScope._statePath = scope.statePath;
                    newScope._creators = scope.creators;
                    newScope._qrcode = scope.qrcode;
                    newScope._moduleColor = scope.moduleColor;
                    newScope._module = scope.module;
                    newScope._scheme = scope.scheme;

                    let nextElement;
                    if (scope.locations) {
                        nextElement = angular.element('<select-cascade level="_level" scheme="{{_scheme}}" max="{{_max}}" purpose="_purpose" uuid="_uuid" parent="_parent" disable="_disable" behaviour="_behaviour" validate="_validate" locations="_locations" preselected="{{_preselected}}" identifier="{{_identifier}}" state-path="{{_statePath}}" creators="_creators" qrcode="_qrcode" module-color="{{_moduleColor}}" module="{{_module}}"></select-cascade>');
                        newScope._locations = scope.locations;
                    } else {
                        nextElement = angular.element('<select-cascade level="_level" scheme="{{_scheme}}" max="{{_max}}" purpose="_purpose" uuid="_uuid" parent="_parent" disable="_disable" behaviour="_behaviour" validate="_validate" preselected="{{_preselected}}" identifier="{{_identifier}}" state-path="{{_statePath}}" creators="_creators" qrcode="_qrcode" module-color="{{_moduleColor}}" module="{{_module}}"></select-cascade>');
                    }

                    element.parent().append(nextElement);
                    scope.nextElement = $compile(nextElement)(newScope);

                    if (scope.code === 'slo' && scope.creators) {
                        AccountService.getDepartmentTypes(scope.item.uuid).then(data => {
                            $ngRedux.dispatch({
                                type: 'DEPARTMENT_TYPES',
                                id: scope.identifier,
                                path: scope.statePath,
                                items: data
                            });
                        });
                    }

                    $timeout(() => {
                        if (document.getElementById('locationQRcode')) {
                            const state = $ngRedux.getState();
                            const location = state.equipmentCalibration.equipment.location;
                            const locationName = state.equipmentCalibration.equipment.locationName;
                            generateQRCode('locationQRcode', {
                                uuid: location,
                                name: locationName,
                                action: 'location',
                            });
                        }
                    }, 200);
                }
            };
            // scope.switchVendors = function() {
            //     const t = scope.vendorsSwitch;
            //     scope.noVendors = scope.noVendors === 'true' ?  'false' : 'true';
            //     scope.onlyVendors = scope.noVendors === 'true' ?  'false' : 'true';
            //     scope.item.in_no_vendors = scope.noVendors || null;
            //     scope.item.in_only_vendors = scope.onlyVendors || null;
            //     AccountService.getSelectCascadeSubjects(scope.item).then(function(result) {
            //         if (result.data.length) {
            //             scope.items = scope.filterSubjects(result.data);
            //         }
            //     })
            //     // $rootScope.$broadcast('SELECT_CASCADE_RESET', scope.identifier);
            // }

            scope.createDepartment = function() {
                const createDepartmentCtrl = (function($scope) {
                    $scope.identifier = scope.identifier;
                    $scope.statePath = scope.statePath;
                    $scope.moduleColor = scope.moduleColor;
                    $scope.save = () => {
                        if ($scope.departmentForm.$valid) {
                            const { locationDepartment } = $ngRedux.getState()[scope.module][scope.identifier];
                            AccountService.addDepartment(scope.uuid, locationDepartment).then(data => {
                                scope.item = new Subject();
                                scope.item.in_purpose = scope.purpose;
                                scope.item.in_parent_token = scope.uuid;
                                AccountService.getSelectCascadeSubjects(scope.item).then(result => {
                                    if (result.data.length) {
                                        scope.items = scope.filterSubjects(result.data);
                                        scope.item = _.find(scope.items, ['in_subject_uuid', data.uuid]);
                                        $ngRedux.dispatch({
                                            type: 'LOCATION_SELECTED',
                                            id: scope.identifier,
                                            path: scope.statePath,
                                            uuid: scope.item.uuid,
                                            accountId: scope.item.in_account_id,
                                            formValid: scope.selectForm.$valid
                                        });
                                        scope.onSelect();
                                    }
                                });
                                $mdDialog.cancel();
                            });
                        }
                    };
                    $scope.cancel = () => {
                        $mdDialog.cancel();
                    };
                }).bind(scope);
                createDepartmentCtrl.$inject = ['$scope'];

                $mdDialog.show({
                    controller: createDepartmentCtrl,
                    template: require('../../../../components/equipment-calibration/equipment/details/dialogs/equipment-calibration-department-dialog.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: true
                }).then(angular.noop, () => {
                    $ngRedux.dispatch({
                        type: 'INPUT_SELECT',
                        id: scope.identifier,
                        path: scope.statePath,
                        item: {locationDepartment: null}
                    });
                });
            };

            scope.createRoom = function() {
                const createRoomCtrl = (function($scope) {
                    $scope.name = null;
                    $scope.building = null;
                    $scope.level = null;
                    $scope.number = null;

                    $scope.moduleColor = scope.moduleColor;
                    $scope.save = () => {
                        if ($scope.roomForm.$valid) {
                            AccountService.addRoom(scope.uuid, $scope.name, $scope.building, $scope.level, $scope.number).then(data => {
                                scope.item = new Subject();
                                scope.item.in_purpose = scope.purpose;
                                scope.item.in_parent_token = scope.uuid;
                                AccountService.getSelectCascadeSubjects(scope.item).then(result => {
                                    if (result.data.length) {
                                        scope.items = scope.filterSubjects(result.data);
                                        scope.item = _.find(scope.items, ['in_subject_uuid', data.uuid]);
                                        $ngRedux.dispatch({
                                            type: 'LOCATION_SELECTED',
                                            id: scope.identifier,
                                            path: scope.statePath,
                                            uuid: scope.item.uuid,
                                            accountId: scope.item.in_account_id,
                                            formValid: scope.selectForm.$valid
                                        });
                                        scope.onSelect();
                                    }
                                });
                                $mdDialog.cancel();
                            });
                        }
                    };
                    $scope.cancel = () => {
                        $mdDialog.cancel();
                    };
                }).bind(scope);
                createRoomCtrl.$inject = ['$scope'];

                $mdDialog.show({
                    controller: createRoomCtrl,
                    template: require('../../../../components/equipment-calibration/equipment/details/dialogs/equipment-calibration-room-dialog.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: true
                }).then(angular.noop, angular.noop);
            };

            scope.downloadQRcode = function() {
                const state = $ngRedux.getState();
                AccountService.printQRcode(
                    state.equipmentCalibration.equipment.location,
                    state.equipmentCalibration.equipment.locationName,
                    'location',
                );
            };

            var validationListener = scope.$on('show-errors-check-validity', function(event, formName) {

                //Validate site location hack
                if( scope.code === 'slo' ) {
                    validate = true;
                }

                if( scope.code === 'dep' && user.typeCode === 'drs' ) {
                    validate = true;
                }

                if (typeof formName !== 'undefined')
                    if (formCtrl.$name !== formName) return;

                if (validate && _.isNull(scope.item.uuid)) {
                    formCtrl['selectCascade' + scope.level].$setValidity('required', false);
                    formCtrl['selectCascade' + scope.level].$setDirty();
                    formCtrl['selectCascade' + scope.level].$setTouched();
                }
            });

            var locationListener = angular.noop;
            if (scope.account) {
                locationListener = scope.$on('LOCATION_SUBJECT_SELECTED', function(event, locationItem, formName) {
                    if (formCtrl.$name === formName)
                        scope.accountID = locationItem.in_account_id;
                });
            }

            element.on('$destroy', function() {
                validationListener();
                locationListener();
            });
        }
    };
}

angular
    .module('components.common', [])
    .directive('selectCascade', selectCascade)
    .config(['$provide',
        function($provide) {
            $provide.decorator('selectCascadeDirective', ['$delegate', 'AuthService',
                function($delegate, AuthService) {

                    var directive = $delegate[0];
                    var link = directive.link;

                    directive.compile = function() {
                        return function Link(scope, element, attrs, ctrls) {

                            var locationsWatch = scope.$watch('locations', function(locations) {
                                if (_.isArray(locations)) {
                                    let typeCodes = [];
                                    const user = AuthService.getUser();
                                    const userType = user.typeCode;
                                    if(userType === 'adm' || user.allowedContainers.includes('hof')) {
                                        typeCodes = ['hof', 'slo', 'dep', 'rom'];
                                    } else if (['sro','aso'].includes(userType)) {
                                        typeCodes = ['slo', 'dep', 'rom'];
                                    }

                                    var itemsWatch = scope.$watch('items', function(items) {
                                        if (items.length) {
                                            for (var i = scope.level; i < typeCodes.length; i++) {
                                                var locationObj = _.find(locations, ['type_code', typeCodes[i]]);
                                                var subject = _.find(items, ['uuid', _.get(locationObj, 'sub_uuid')]);
                                                if (!_.isUndefined(subject)) {
                                                    scope.item = subject;
                                                    scope.onSelect();
                                                    break;
                                                }
                                            }
                                            itemsWatch();
                                        }
                                    });
                                    locationsWatch();
                                }
                            });
                            return link.apply(this, arguments);
                        };
                    };
                    return $delegate;
                }
            ]);
        }
    ]);
