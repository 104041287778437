export function VendorManager() {

    this.TAB_VENDORS                        = 0;
    this.TAB_VENDOR_DETAILS                 = 1;
    this.TAB_VENDOR_SERVICES                = 2;
    this.TAB_ADD_SITE_LOCATION              = 3;
    this.TAB_VENDOR_SITE_LOCATIONS          = 4;
    this.TAB_VENDOR_SITE_LOCATION_DETAILS   = 5;
    this.TAB_VENDOR_SITE_LOCATION_MANAGER   = 6;

    this.tplVendorListing                   = './vendors-listing.html';
    this.tplVendorAdd                       = './vendors-add.html';
    this.tplVendorServices                  = './vendors-services.html';
    this.tplVendorSiteLocationsAdd          = './vendors-site-locations-add.html';
    this.tplVendorSiteLocations             = './vendors-site-locations.html';
    this.tplVendorSiteLocationsDetails      = './vendors-site-locations-details.html';
    this.tplVendorSiteLocationsManager      = './vendors-site-locations-managers-details.html';

    this.tabs = [
        {
            id: this.TAB_VENDORS,
            title: 'Vendors',
            content: this.tplVendorListing,
            form: null,
            formValidation: false,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: false,
            stateless: true
        },
        {
            id: this.TAB_VENDOR_DETAILS,
            title: 'Vendor Details',
            content: this.tplVendorAdd,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: false,
            stateless: false
        },
        {
            id: this.TAB_VENDOR_SERVICES,
            title: 'Vendor Services',
            content: this.tplVendorServices,
            form: null,
            formValidation: true,
            formValidationMsg: 'You have to select at least on Service',
            visible: true,
            disabled: true,
            stateless: false
        },
        {
            id: this.TAB_ADD_SITE_LOCATION,
            title: 'New Vendor Location',
            content: this.tplVendorSiteLocationsAdd,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: true,
            stateless: false
        },
        {
            id: this.TAB_VENDOR_SITE_LOCATIONS,
            title: 'Vendor Locations',
            content: this.tplVendorSiteLocations,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: true,
            stateless: true
        },
        {
            id: this.TAB_VENDOR_SITE_LOCATION_DETAILS,
            title: 'Vendor Location Details',
            content: this.tplVendorSiteLocationsDetails,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: false,
            disabled: true,
            stateless: false
        },
        {
            id: this.TAB_VENDOR_SITE_LOCATION_MANAGER,
            title: 'Manager Details',
            content: this.tplVendorSiteLocationsManager,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: false,
            disabled: true,
            stateless: false
        }
    ];
}
