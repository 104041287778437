/**
 * @const CRE_INVITE_INIT
 * @type {string}
 */
export const CRE_INVITE_INIT = 'CRE_INVITE_INIT';

/**
 * @const CRE_CONTACT_POSITIONS
 * @type {string}
 */
export const CRE_CONTACT_POSITIONS = 'CRE_CONTACT_POSITIONS';

/**
 * @const CRE_COUNTRIES
 * @type {string}
 */
export const CRE_COUNTRIES = 'CRE_COUNTRIES';

/**
 * @const CRE_CURRENCIES
 * @type {string}
 */
export const CRE_CURRENCIES = 'CRE_CURRENCIES';

/**
 * @const CRE_VENDORS
 * @type {string}
 */
export const CRE_VENDORS = 'CRE_VENDORS';

/**
 * @const CRE_VENDOR_SELECT
 * @type {string}
 */
export const CRE_VENDOR_SELECT = 'CRE_VENDOR_SELECT';

/**
 * @const CRE_VENDOR_RESET
 * @type {string}
 */
export const CRE_VENDOR_RESET = 'CRE_VENDOR_RESET';

/**
 * @const CRE_CONTACT_CREATE
 * @type {string}
 */
export const CRE_CONTACT_CREATE = 'CRE_CONTACT_CREATE';

/**
 * @const CRE_CONTACT_DETAILS
 * @type {string}
 */
export const CRE_CONTACT_DETAILS = 'CRE_CONTACT_DETAILS';

/**
 * @const CRE_CONTACT_UPDATE
 * @type {string}
 */
export const CRE_CONTACT_UPDATE = 'CRE_CONTACT_UPDATE';

/**
 * @const CRE_CONTACT_DELETE
 * @type {string}
 */
export const CRE_CONTACT_DELETE = 'CRE_CONTACT_DELETE';

/**
 * @const CRE_CONTACT_RESET
 * @type {string}
 */
export const CRE_CONTACT_RESET = 'CRE_CONTACT_RESET';

/**
 * @const CRE_REQUEST_STATUSES
 * @type {string}
 */
export const CRE_REQUEST_STATUSES = 'CRE_REQUEST_STATUSES';

/**
 * @const CRE_REQUEST_DETAILS
 * @type {string}
 */
export const CRE_REQUEST_DETAILS = 'CRE_REQUEST_DETAILS';

/**
 * @const CRE_VENDOR_DOC_REMOVE
 * @type {string}
 */
export const CRE_VENDOR_DOC_REMOVE = 'CRE_VENDOR_DOC_REMOVE';

/**
 * @const CRE_VENDOR_ACC_SCHEMES
 * @type {string}
 */
export const CRE_VENDOR_ACC_SCHEMES = 'CRE_VENDOR_ACC_SCHEMES';

/**
 * @const CRE_VENDOR_ACC_SCHEME_SELECT
 * @type {string}
 */
export const CRE_VENDOR_ACC_SCHEME_SELECT = 'CRE_VENDOR_ACC_SCHEME_SELECT';

/**
 * @const CRE_VENDOR_ACC_SCHEME_RESET
 * @type {string}
 */
export const CRE_VENDOR_ACC_SCHEME_RESET = 'CRE_VENDOR_ACC_SCHEME_RESET';

/**
 * @const CRE_VENDOR_ACC_STANDARD_SELECT
 * @type {string}
 */
export const CRE_VENDOR_ACC_STANDARD_SELECT = 'CRE_VENDOR_ACC_STANDARD_SELECT';

/**
 * @const CRE_VENDOR_ACC_STANDARD_RESET
 * @type {string}
 */
export const CRE_VENDOR_ACC_STANDARD_RESET = 'CRE_VENDOR_ACC_STANDARD_RESET';

/**
 * @const CRE_VENDOR_GOODS_MANUFACTURERS
 * @type {string}
 */
export const CRE_VENDOR_GOODS_MANUFACTURERS = 'CRE_VENDOR_GOODS_MANUFACTURERS';

/**
 * @const CRE_VENDOR_GOODS_MANUFACTURER_SELECT
 * @type {string}
 */
export const CRE_VENDOR_GOODS_MANUFACTURER_SELECT = 'CRE_VENDOR_GOODS_MANUFACTURER_SELECT';

/**
 * @const CRE_VENDOR_GOODS_MANUFACTURER_RESET
 * @type {string}
 */
export const CRE_VENDOR_GOODS_MANUFACTURER_RESET = 'CRE_VENDOR_GOODS_MANUFACTURER_RESET';

/**
 * @const CRE_VENDOR_GOODS_SELECT
 * @type {string}
 */
export const CRE_VENDOR_GOODS_SELECT = 'CRE_VENDOR_GOODS_SELECT';

/**
 * @const CRE_VENDOR_GOODS_RESET
 * @type {string}
 */
export const CRE_VENDOR_GOODS_RESET = 'CRE_VENDOR_GOODS_RESET';

/**
 * @const CRE_VENDOR_WARRANTY_LICENCE_TYPES
 * @type {string}
 */
export const CRE_VENDOR_WARRANTY_LICENCE_TYPES = 'CRE_VENDOR_WARRANTY_LICENCE_TYPES';

/**
 * @const CRE_VENDOR_WARRANTY_SELECT
 * @type {string}
 */
export const CRE_VENDOR_WARRANTY_SELECT = 'CRE_VENDOR_WARRANTY_SELECT';

/**
 * @const CRE_VENDOR_WARRANTY_RESET
 * @type {string}
 */
export const CRE_VENDOR_WARRANTY_RESET = 'CRE_VENDOR_WARRANTY_RESET';

/**
 * @const CRE_VENDOR_SERVICE_LICENCE_TYPES
 * @type {string}
 */
export const CRE_VENDOR_SERVICE_LICENCE_TYPES = 'CRE_VENDOR_SERVICE_LICENCE_TYPES';

/**
 * @const CRE_VENDOR_SERVICE_SELECT
 * @type {string}
 */
export const CRE_VENDOR_SERVICE_SELECT = 'CRE_VENDOR_SERVICE_SELECT';

/**
 * @const CRE_VENDOR_SERVICE_RESET
 * @type {string}
 */
export const CRE_VENDOR_SERVICE_RESET = 'CRE_VENDOR_SERVICE_RESET';
