export const IDS = {

    /* module */
    accAddDocument: 'accAddDocument',

    /* paths */
    addFromExistingGrid:      'addFromExistingGrid',

    /* tab states: */
    app_accreditation_scheme_add_document:                  'app.accreditation.scheme.add-document',
    app_accreditation_scheme_add_document_from_existing:    'app.accreditation.scheme.add-document.from-existing',
    app_accreditation_scheme_add_document_new_document:     'app.accreditation.scheme.add-document.new-document',
};
