/* @ngInject */
export function accAssessmentItemApprovalFormElement($timeout, $http, ACC_ASSESSMENT_ITEM_TYPE, Notifier, $mdDialog, DocumentManager, DOCUMENT_TYPE, AccreditationDocumentsService, $filter) {
    return {
        restrict: 'E',
        template: require('./acc-assessment-item-approval-form-element.html'),
        scope: {
            assessmentItem: '=assessmentItem',
            locationUuid: '@locationUuid',
            locationName: '@locationName',
            isApprovalUser: '=isApprovalUser',
            dirty: '=dirty',
        },
        link: function (scope, element, attrs) {
            if ((scope.assessmentItem.assessmentItemType === ACC_ASSESSMENT_ITEM_TYPE.DOCUMENT) ||
                (scope.assessmentItem.assessmentItemType === ACC_ASSESSMENT_ITEM_TYPE.DOCUMENT_GENERATED)) {

                scope.$on('acc-schema-assessment-value-fetched', function (events, args) {
                    if (scope.assessmentItem.value != null) {
                        scope.assessmentItem.value.forEach(function (accDocument) {
                            if (accDocument.documentId != null) {
                                AccreditationDocumentsService.fetchAccDocumentApprovalStatus(accDocument.documentId)
                                    .then(function (response) {
                                        if ((response.status == 200) && (response.data.result != null)) {
                                            //all ok
                                            accDocument.status = response.data.result.status;
                                            accDocument.statusComment = response.data.result.statusComment;
                                        } else if (response.status == 400 && typeof response.data == 'string') {
                                            Notifier.error(response.data);
                                        } else if (response.status == 403) {
                                            Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_ACCESS_ERROR'));
                                        } else if (response.status == 404) {
                                            Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_DOC_NOT_FOUND'));
                                        } else if (response.status == 500) {
                                            Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_SERVER_ERROR'));
                                        } else if (response.status != 200) {
                                            Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_FETCH_ERROR'));
                                        } else if ((response.data == null) || (response.data.result == null)) {
                                            //No data for this item
                                        }
                                    }, function (error) {
                                        //Notifier.error("Error fetching accreditation documents training types");
                                        Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_FETCH_DATA_ERROR'));
                                    });
                            }
                        });
                    }
                });
            }
            scope.fetchData = function () {
                $scope.cursorState = 'wait';
                AccreditationDocumentsService.fetchAccQCMasterListDocuments(ctrl.accreditationSchemaCode, ctrl.selectedUserParentLocation,
                        ctrl.filter, false)
                    .then(function (response) {
                        $scope.cursorState = 'default';
                        if ((response.status >= 200) && (response.status < 300) &&
                            (response.data.result != null)) {
                            ctrl.masterDocumentsList = response.data.result;
                        } else if ((response.status >= 500) && (response.status < 600)) {
                            Notifier.error($filter('translate')("ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_FETCH_LIST_ERROR"));
                        } else if ((response.status >= 400) && (response.status < 500)) {
                            Notifier.error($filter('translate')("ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_PAGE_NOT_FOUND"));
                        } else if ((response.data == null) || (response.data.result == null)) {
                            //No data for this item
                        }
                    }, function (error) {
                        $scope.cursorState = 'default';
                        if ((typeof error === 'number') && (error >= 500) && (error < 600)) {
                            Notifier.error($filter('translate')("ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_FETCH_LIST_ERROR"));
                        } else if ((typeof error === 'number') && (error >= 400) && (error < 500)) {
                            Notifier.error($filter('translate')("ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_PAGE_NOT_FOUND"));
                        } else {
                            Notifier.error($filter('translate')("ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_FORM_ERROR_1"));
                        }
                    });
            };

            scope.downloadFile = function (item) {
                $timeout(
                    function () {
                        DocumentManager.downloadFile(item.filePath, DOCUMENT_TYPE.ACCREDITATION_DOCUMENT);
                    }
                );
            };
        }
    };
}
