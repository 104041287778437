import isEmpty from 'lodash/isEmpty';

const initialState = {
    equipmentLoanHistory: {
        id: null,
        loaned_to: null,
        loan_date: null,
        loan_due_date: null,
        receipted_by: null,
        receiptPersons: [],
        return_date: null,
        receiptPersonNew: 0,
        receipt_first_name: null,
        receipt_last_name: null,
        receipt_email: null,
        receipt_phone: null,
        receipt_campus: null,
        receipt_department: null,
        equipment_condition: null
    }
};

export default (state = initialState, action) => {
    if (action) {
        switch (action.type) {
            case 'EQUIPMENT_LOAN_HISTORY_DETAILS':
                if (action.id === 'equipmentLoan') {
                    if (action.data.length) {
                        const item = action.data[0];
                        let receiptPerson = null;
                        if (item.receipted_by) {
                            receiptPerson = {
                                subject_uuid: item.receipted_by_uuid,
                                first_name: item.receipted_by_first_name,
                                last_name: item.receipted_by_last_name
                            };
                        }
                        return {
                            ...state,
                            id: item.eql_id,
                            loaned_to: item.loaned_to,
                            loan_date: item.loan_date,
                            loan_due_date: item.loan_due_date,
                            receipted_by: receiptPerson,
                            receiptPersons: receiptPerson ? [receiptPerson] : [],
                            return_date: item.receipt_date,
                            receiptPersonNew: receiptPerson
                                ? 0
                                : isEmpty(item.receipted_by_first_name) && isEmpty(item.receipted_by_last_name)
                                    ? 0
                                    : 1,
                            receipt_first_name: item.receipted_by_first_name,
                            receipt_last_name: item.receipted_by_last_name,
                            receipt_email: item.receipted_by_email,
                            receipt_phone: item.receipted_by_phone,
                            receipt_campus: item.receipted_by_campus,
                            receipt_department: item.receipted_by_department,
                            equipment_condition: item.equipment_condition
                        };
                    }
                }
            case 'EQUIPMENT_LOAN_RECEIPT_PERSONS':
                if (action.id === 'equipmentLoan') {
                    return {
                        ...state,
                        receiptPersons: action.data
                    };
                }
            case 'EQUIPMENT_LOAN_RECEIPT_PERSON_NEW':
                if (action.id === 'equipmentLoan') {
                    if (action.new) {
                        return {
                            ...state,
                            receipted_by: null,
                            receiptPersons: [],
                            receipt_first_name: null,
                            receipt_last_name: null,
                            receipt_email: null,
                            receipt_phone: null,
                            receipt_campus: null,
                            receipt_department: null
                        };
                    }
                }
            case 'EQUIPMENT_LOAN_HISTORY_SAVE':
                if (action.id === 'equipmentLoan') {
                    return {
                        ...state
                    };
                }
            case 'EQUIPMENT_LOAN_HISTORY_RESET':
                if (action.id === 'equipmentLoan') {
                    return initialState.equipmentLoanHistory;
                }
            default:
                return state;
        }
    }
    return state;
};
