/*
*   List of Action IDs that should be passed from accreditation.reducer.js to improvement.reducer.js
 */

export const IDS = {
    // module
    accImprovement: 'accImprovement',

    // components:
    accreditationImprovementList:      'accreditationImprovementList',
    accreditationImprovementDetails:   'accreditationImprovementDetails',
    accreditationImprovementAdd:       'accreditationImprovementAdd',

    //paths
    improvementDetails:                    'improvementDetails',
    improvementReviewsGrid:                'improvementReviewsGrid',
    improvementList:                       'improvementList',
    improvementListGrid:                   'improvementListGrid',
    improvementAddGrid:                    'improvementAddGrid',

    // tab states:
    app_accreditation_scheme_improvement:          'app.accreditation.scheme.improvement',
    app_accreditation_scheme_improvement_list:     'app.accreditation.scheme.improvement.list',
    app_accreditation_scheme_improvement_details:  'app.accreditation.scheme.improvement.details',
    app_accreditation_scheme_improvement_add:      'app.accreditation.scheme.improvement.add',

    // public tab states
    // public_accreditation_scheme_improvement:       'public.accreditation.scheme.improvement',
};
