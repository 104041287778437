import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import { getApplicantRequests } from '../cre-vendors.actions';
import moment from 'moment';

class CredentialingVendorsGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'credentialing';
        this.identifier = 'creVendorsGrid';
        this.filterTemplate = './cre-vendors-grid-filter.html';

        this.columns = [
            {title: '#',                    mapper: null},
            {title: 'Company Name',			mapper: 'company_name'},
            {title: 'Contact Person',		mapper: 'contact_person'},
            {title: 'Application Type',     mapper: 'app_type'},
            {title: 'Invitation Date',      mapper: 'invitation_date'},
            {title: 'Submission Date',      mapper: 'request_date'},
            {title: 'Approval Date',        mapper: 'approval_date'},
            {title: 'Status',               mapper: 'progress-bar'},
            {title: '',                     mapper: 'request_status'},
        ];

        this.buttons = [
            { label: 'Cancel', class: 'btnGridRemove', callback: this.onCancel }
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), { getApplicantRequests }
        )(this);

        this.dataAction = this.getApplicantRequests;
        this.exportExclude = ['Completion Status'];
        this.exportService = this.getApplicantRequests;
        this.filter.identifier = this.identifier;
        this.filter.purpose = 'venapp';

        this.filterActions.onAppType = () => {
            this.$ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: this.identifier,
            });
        };

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const creVendorsGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onCancel: '&',
    },
    controller: CredentialingVendorsGridController,
    ...gridTpl
};
