/* @ngInject */
export function fileDownload(DocumentManager) {
    return {
        restrict: 'E',
        require: [],
        scope: {
            uri: '=href',
            type: '@type',
            licence: '@licence',
            hasIcon: '@icon',
            label: '=?'
        },
        template: require('./file-download.html'),
        link: function (scope, element, attrs, controllers) {

            if (typeof scope.type == 'undefined' || !scope.type)
                scope.type = 'link';

            var docType = 2;

            if (_.isUndefined(scope.licence) || scope.licence.toLowerCase() == 'no' || scope.licence.toLowerCase() == 'false')
                docType = 1;

            scope.icon = scope.hasIcon == 'yes';

            if (_.isUndefined(scope.label))
                scope.label = scope.uri;

            scope.download = function(uri) {
                DocumentManager.downloadFile(uri, docType)
            };

            var uriWatch = scope.$watch('uri', function(newUri, oldUri) {
                if (newUri != oldUri) scope.label = newUri;
            });

            element.on('$destroy', function () {
                uriWatch();
            });
        }
    }
}
