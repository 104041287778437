/* @ngInject */
export function ArtifactEvaluationService($http, API_BASE, Helpers, MammoQCMeasurement, DATE_FORMAT) {

    this.getArtifactEvaluationTests = function(filter) {
        return $http({
            url: API_BASE + "/mammo-qc/mammo-qc-test",
            method: "GET",
            params: {
                equipmentId:        filter.equipment,
                measurementType:    filter.measurementType,
                from:               Helpers.formatDate(filter.dateFrom),
                to:                 Helpers.formatDate(filter.dateTo),
                exportFormat:       filter.exportFormat,
                exportColumns:      filter.exportColumns
            }
        }).then(function(response) {
            if (filter.exportFormat) {
                return response.data;
            }
            else {
                var test = response.data.map(function (item) {
                    var artifactEvaluationTest = new MammoQCMeasurement();
                    artifactEvaluationTest.testDate = Helpers.formatDate(item.tested_date, DATE_FORMAT.INPUT);

                    var test_results = JSON.parse(item.test_results)

                    artifactEvaluationTest.kvp = test_results.kvp;
                    artifactEvaluationTest.mas = test_results.mas;
                    artifactEvaluationTest.attenuator = test_results.attenuator;
                    artifactEvaluationTest.targetFilter = test_results.targetFilter;

                    artifactEvaluationTest.artifacts = test_results.artifacts;
                    artifactEvaluationTest.passFail = test_results.passFail;
                    artifactEvaluationTest.comments = test_results.comments;

                    artifactEvaluationTest.artifactsPhantomPosition2 = test_results.artifactsPhantomPosition2;
                    artifactEvaluationTest.commentsPhantomPosition2 = test_results.commentsPhantomPosition2;

                    artifactEvaluationTest.testerUuid = item.tester_uuid;
                    artifactEvaluationTest.firstName = item.tester_first_name;
                    artifactEvaluationTest.lastName = item.tester_last_name;
                    artifactEvaluationTest.tech = item.tester_first_name + ' ' + item.tester_last_name;
                    return artifactEvaluationTest;
                });
                return {
                    items: test
                }
            }
        });
    };

    this.addArtifactEvaluation = function(artifactEvaluation) {
        return $http({
            url: API_BASE + "/mammo-qc/mammo-qc-measurement-add",
            method: "POST",
            data: {
                measurementType:                        artifactEvaluation.measurementType,
                equipmentId:                            artifactEvaluation.equipment,
                kvp:                                    artifactEvaluation.kvp,
                mas:                                    artifactEvaluation.mas,
                comments:                               artifactEvaluation.comments,
                attenuator:                             'Acrylic',
                targetFilter:                           artifactEvaluation.targetFilter,
                artifacts:                              artifactEvaluation.artifacts,
                artifactsPhantomPosition2:              artifactEvaluation.artifactsPhantomPosition2,
                commentsPhantomPosition2:               artifactEvaluation.commentsPhantomPosition2
            }
        }).then(function(response) {
            var params = JSON.parse(response.data.params);
            return params.mqm_id;
        });
    };
}
