import isUndefined from 'lodash/isUndefined';

/**
 * Creates a new TabsHeaderController.
 * @class
 */
class TabsHeaderController {
    constructor($state, NavigationService) {
        this.$state = $state;
        this.NavigationService = NavigationService;
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.color = navConfig.colorName;

        if (isUndefined(this.icon))
            this.icon = navConfig.icon;
    }
}

TabsHeaderController.$inject = ['$state', 'NavigationService'];

export const tabsHeader = {
    bindings: {
        title: '@',
        icon: '@',
        component: '@',
    },
    controller: TabsHeaderController,
    template: `
        <div layout-gt-xs="row" class="formHeader">
            <h3>
                <asset cls="color{{$ctrl.color}}" src="{{$ctrl.icon}}"></asset>
                <span id="tabsHeaderTitle">{{$ctrl.title}}</span>
            </h3>
            <div ng-if="$ctrl.component"
                 ng-include="$ctrl.component"
                 layout="row" flex
                 layout-align-xs="center center"
                 layout-align-gt-xs="end center">
            </div>
        </div>
    `
};
