import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import { getTests, getTestTypes, getTestManufacturers, getTestManufacturerModels } from '../rm-test.actions';
import { getIsotopes, getUnits } from '../../radioactive-materials.actions';

class RadioactiveMaterialsTestGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'radioactiveMaterials';
        this.identifier = 'rmTestGrid';
        this.filterControls = './rm-test-grid-filter-controls.html';
        this.filterTemplate = './rm-test-grid-filter.html';

        this.columns = [
            {title: '#',                    mapper: null},
            {title: 'Site Location',        mapper: 'location',             sort: 'location'},
            {title: 'Asset/Order ID',       mapper: 'equipment_id',         sort: 'equipment_id'},
            {title: 'Type',                 mapper: 'source_type',          sort: 'source_type'},
            {title: 'Manufacturer',         mapper: 'manufacturer',         sort: 'manufacturer'},
            {title: 'Model',                mapper: 'model',                sort: 'model'},
            {title: 'Serial No.',           mapper: 'serial_no',            sort: 'serial_no'},
            {title: 'License No.',          mapper: 'license_no',           sort: 'license_no'},
            {title: 'Isotope',              mapper: 'isotope',              sort: 'isotope'},
            {title: 'Init. Activity',       mapper: 'initial_activity',     sort: 'initial_activity'},
            {title: 'Current Activity',     mapper: 'current_activity',     sort: 'current_activity'},
            {title: 'Calibration Due Date', mapper: 'calibration_due_date', sort: 'calibration_due_date'},
            {title: 'Service Due Date',     mapper: 'service_due_date',     sort: 'service_due_date'},
            {title: 'Leak Test Due Date',   mapper: 'wipe_test_due_date',   sort: 'wipe_test_due_date'},
        ];

        this.buttons = [
            {
                label: 'Leak Test',
                class: (item) => { return 'btnGrid' },
                callback: this.onWipeTest
            },
            {
                label: (item) => item.status_code === 'dis' ? 'Disposed' : 'Dispose',
                class: (item) => item.status_code === 'dis' ? 'btnGridAction' : 'btnGrid',
                callback: this.onDispose
            }
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this),
            {
                getTests,
                getTestTypes,
                getIsotopes,
                getUnits,
                getTestManufacturers,
                getTestManufacturerModels
            }
        )(this);

        this.dataAction = this.getTests;
        this.exportService = this.getTests;
        this.filter.identifier = this.identifier;
        this.getTestTypes();
        this.getIsotopes('rmTest');
        this.getUnits('rmTest', 'siu', 'units');
        this.getTestManufacturers();

        this.filterActions.subjectToWipeTests = ({id, value}) => {
            this.$ngRedux.dispatch({
                type: 'WIPE_TEST_FILTER',
                id,
                value
            });
            this.$ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id
            });
        }

        this.filterActions.getUnits = ({ input }) => {
            this.getUnits('rmTest', input, 'units');
        }

        this.filterActions.getModels = ({ input }) => {
            this.getTestManufacturerModels(input, 'models');
        }

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const rmTestGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onRemove: '&',
        onReset: '&',
        onWipeTest: '&',
        onDispose: '&'
    },
    controller: RadioactiveMaterialsTestGridController,
    ...gridTpl
};
