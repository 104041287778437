import InputController from '../input.controller';
import { INPUT_CAMERA } from '../input.constants';
import { API_BASE } from '../../../utils/util.constants';
import watch from 'redux-watch';
import set from 'lodash/set';

/**
 * Creates a new InputCameraController.
 * @class
 * @extends module:root/common/form.InputController
 * @memberOf module:root/common/form
 */
class InputCameraController extends InputController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $http {service} {@link https://docs.angularjs.org/api/ng/service/$http|AngularJS $http}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param $mdDialog {service} {@link https://material.angularjs.org/latest/api/service/$mdDialog|AngularJS Material $mdDilaog}
     * @param NavigationService {service} {@link module:root/common.NavigationService|NavigationService}
     * @param Notifier {service} {@link Notifier}
     */
    constructor($ngRedux, $http, $state, $mdDialog, NavigationService, Notifier) {
        super($ngRedux);
        this.$http = $http;
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.NavigationService = NavigationService;
        this.Notifier = Notifier;
        this.inputCamera = null;
        this.photo = false;
    }

    /**
     * @method $onInit
     * @memberof module:root/common/form.InputCameraController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.init();
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;

        if (typeof this.inputId === 'undefined')
            throw new Error('Missing input-id attribute (' + this.name + ')');

        if (typeof this.label === 'undefined')
            this.label = this.mobile ? 'Scan Copy' : 'Upload';

        if (this.input)
            this.photo = true;

        const watchUpload = watch(this.$ngRedux.getState, this.getPath());
        this.onUpload = this.$ngRedux.subscribe(watchUpload(input => {
            if (input) this.photo = true;
        }));
    }

    /**
     * @method onCamera
     * @memberof module:root/common/form.InputCameraController
     */
    onCamera() {
        if (this.inputCamera === null) {
            this.inputCamera = document.getElementById(this.inputId);
            this.inputCamera.addEventListener('change', this.onInputChange.bind(this));
        }
        this.inputCamera.click();
    }

    /**
     * @method onInputChange
     * @memberof module:root/common/form.InputCameraController
     */
    onInputChange(evt) {
        if (evt.target.value.length) {
            const options = {
                controller: (scope, name) => {
                    scope.name = name;
                    scope.btnCancel = true;
                    scope.btnSave = true;

                    scope.save = () => {
                        this.$mdDialog.hide();
                        this.Notifier.info('Please Wait Uploading...', false);

                        const formData = new FormData();
                        formData.append('upstream', evt.target.files[0]);

                        this.$http({
                            url: API_BASE + '/file/upload',
                            method: 'POST',
                            headers: {'Content-Type': undefined},
                            data: formData
                        }).then(response => {
                            this.$ngRedux.dispatch({
                                type: INPUT_CAMERA,
                                id: this.identifier,
                                path: this.statePath,
                                index: this.stateIndex,
                                prop: this.stateProp,
                                input: Object.assign(response.data, {file: this.inputCamera.files[0]})
                            });
                            this.photo = true;
                            this.Notifier.success('Upload Success');
                        }, error => {
                            this.Notifier.responseMessage(error);
                        });
                    };

                    scope.cancel = () => {
                        this.$mdDialog.cancel();
                    };
                },
                template: require('./input-camera-dialog.html'),
                parent: angular.element(document.body),
                locals: {
                    name: this.name
                },
                clickOutsideToClose: true,
                onShowing: (scope, element) => {
                    const reader = new FileReader();
                    reader.onload = event => {
                        element.find('small').css('display', 'none');
                        element.find('img').attr('src', event.target.result);
                        angular.element(document.getElementById('cameraControls')).css('display', 'flex');
                    };
                    reader.readAsDataURL(this.inputCamera.files[0]);
                }
            };
            options.onShowing.$inject = ['$scope'];
            options.controller.$inject = ['$scope', 'name'];

            this.$mdDialog.show(options);
        }
    }

    /**
     * @method onPhoto
     * @memberof module:root/common/form.InputCameraController
     */
    onPhoto() {
        // TODO temporary
        const options = {
            controller: (scope, name) => {
                scope.name = name;
                scope.btnClose = true;
                scope.close = () => {
                    this.$mdDialog.cancel();
                }
            },
            template: require('./input-camera-dialog.html'),
            parent: angular.element(document.body),
            locals: {
                name: this.name
            },
            clickOutsideToClose: true,
            onShowing: (scope, element) => {
                const reader = new FileReader();
                reader.onload = event => {
                    element.find('small').css('display', 'none');
                    element.find('img').attr('src', event.target.result);
                    angular.element(document.getElementById('cameraControls')).css('display', 'flex');
                };
                reader.readAsDataURL(this.input.file);
            }
        };
        options.onShowing.$inject = ['$scope'];
        options.controller.$inject = ['$scope', 'name'];

        this.$mdDialog.show(options);
    }

    /**
     * @method $onDestroy
     * @memberof module:root/common/form.InputCameraController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.onUpload();
        this.destroy();
        if (this.inputCamera)
            this.inputCamera.removeEventListener('change', this.onInputChange);
    }

    /**
     * @method mapState
     * @param state {object} Redux Store
     * @return {object}
     * @memberof module:root/common/form.InputCameraController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapState(state) {
        return {
            input: this.getValue(state),
            /** @member {object} module:root/common/form.InputCameraController#mobile */
            mobile: state.app.mobile
        }
    }

    /**
     * @method getClass
     * @return {object}
     * @memberof module:root/common/form.InputCameraController
     * @see {@link https://docs.angularjs.org/api/ng/directive/ngClass|AngularJS ngClass}
     */
    getClass() {
        if (this.form[this.inputId].$invalid && this.form.$submitted)
            return set({}, 'btnCameraInvalid', true);
        else
            return set({}, 'btnCameraColor' + this.colorName, true);
    }
}

InputCameraController.$inject = ['$ngRedux', '$http', '$state', '$mdDialog', 'NavigationService', 'Notifier'];

export const inputCamera = {
    bindings: {
        inputId: '@',
        identifier: '@',
        statePath: '@',
        stateIndex: '<',
        stateProp: '@',
        name: '@',
        label: '@',
        required: '<'
    },
    require : {
        /**
         * @member {*} module:root/common/form.InputCameraController#form
         * @see {@link https://docs.angularjs.org/api/ng/type/form.FormController|FormController}
         */
        form : '^form'
    },
    controller: InputCameraController,
    template: `
        <div ng-if="$ctrl.mobile" layout="row" layout-align-xs="center center" layout-align-sm="center center">
            <input id="{{$ctrl.inputId}}" name="{{$ctrl.inputId}}" type="file" accept="image/*" ng-hide="true" ng-model="$ctrl.input" ng-required="$ctrl.required">
            <md-button ng-class="$ctrl.getClass()" ng-click="$ctrl.onCamera()" aria-label="Camera">
                <asset src="camera.svg"></asset> {{$ctrl.label}}
            </md-button>
            <md-button class="btnPhotoColor{{$ctrl.colorName}}" ng-click="$ctrl.onPhoto()" aria-label="Photo" ng-if="$ctrl.photo">
                <asset src="photo.svg"></asset>
            </md-button>
        </div>
        <file-upload ng-if="!$ctrl.mobile" 
                input-id="{{$ctrl.inputId}}-upload"
                identifier="{{$ctrl.identifier}}"
                state-path="{{$ctrl.statePath}}" 
                state-index="$ctrl.stateIndex" 
                state-prop="{{$ctrl.stateProp}}" 
                label="{{$ctrl.label}}" 
                btn-class="btnShortColor{{$ctrl.colorName}}" 
                required="$ctrl.required">
        </file-upload>
    `
};
