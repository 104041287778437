import MobileDetect from 'mobile-detect';
import I18N from './common/utils/util.i18n';

/**
 * Creates a new RootController.
 * @class
 * @memberOf module:root
 */
class RootController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $filter
     */
    constructor($ngRedux, $filter) {
        this.$ngRedux = $ngRedux;
        I18N.init($filter);

        const md = new MobileDetect(window.navigator.userAgent);
        this.$ngRedux.dispatch({
            type: 'MOBILE_DETECT',
            device: md.mobile()
        });
    }
}

RootController.$inject = ['$ngRedux','$filter'];

export const root = {
    controller: RootController,
    template: `
        <div layout="column" tabIndex="-1" role="main" flex ui-view></div>
    `
};
