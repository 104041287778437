/* @ngInject */
export function SafetyPlanTrainingDocumentFactory() {
    return function SafetyPlanTrainingDocument() {

        this.documentId = null; //ado_id from acc_documents
        this.docDcrNo = null; //ado_id from acc_documents
        this.trainingDocumentId = null; //rdo_id from training_documents
        this.documentName = null; //title from acc_documents
        this.documentVersion = null; //version from acc_documents
        this.dateEdited = null; //date_edited from acc_documents
        this.writtenBy = null; //written_by from acc_documents
        this.validUntil = null;
        this.documentVariant = {
            docVariantLocation: null,
            docVariantVersion: null,
            docVariantPath: null
        };
    };
}
