/**
 * @const {object}
 * @memberOf module:Employees
 */
export const employees = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $mdDialog, $rootScope, NavigationService) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            const navConfig = NavigationService.getConfig($state.$current);
            this.colorName = navConfig.colorName;
            this.icon = navConfig.icon;

            this.unsubscribe = () => {};
        }

        this.$onDestroy = () => {
            this.unsubscribe();
        };
    },
   template: `
        <div class="loader formHeader">
            <asset cls="color{{$ctrl.colorName}}" src="{{$ctrl.icon}}"></asset>
            <div class="color{{$ctrl.colorName}}">Coming soon...</div>
        </div>
    `
};
