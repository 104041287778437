/* @ngInject */
export function ServiceContractsActionsFactory(ServiceContractService) {
    return {
        getServiceContractTypes: function() {
            return function(dispatch) {
                ServiceContractService.getContractTypes().then(function(data) {
                    dispatch({
                        type: 'CONTRACT_TYPES',
                        id: 'serviceContractsGrid',
                        items: data
                    });
                });
            };
        },
        getServiceContracts: function(filter) {
            return function(dispatch) {
                ServiceContractService.getContracts(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'serviceContractsGrid',
                        items: data.items,
                        itemsCount: data.total
                    });
                });
            };
        }
    };
}
