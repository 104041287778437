import {TYPES} from './standard-action-type.constants';
import {TYPES as ACC_TYPES} from '../accreditation-action-type.constants';
import {IDS} from './standard-action-id.constants';
import API from '../../../common/http/api.fetch';
import I18N from '../../../common/utils/util.i18n';
import {TAB_SHOW} from '../../../common/tabs/tabs.constants';
import {GRID_FILTER, GRID_RELOAD, GRID_RESET} from '../../../common/grid/grid.constants';
import {responseMessage, success} from '../../../common/utils/util.notifier';
import pick from 'lodash/pick';
import {getDocFileNameFromPath} from "../../../common/utils/util.path";
import * as GRID from "../../../common/grid/grid.constants";
import {formatDate} from "../../../common/utils/util.date";
import {DATE_FORMAT} from "../../../common/utils/util.constants";

export const fetchSiteLocationDetails = (siteLocationUuid) => {
    return dispatch => {
        return API.fetch({
            url: `/location/sitelocation/${siteLocationUuid}`,
            method: 'GET',
            params: {}
        }).then(response => {
            const siteLocation = response.data[0][0];
            dispatch({
                type: TYPES.FETCH_SITE_LOCATION_DETAILS,
                id: IDS.accStandard,
                locationName: siteLocation.name,
                locationUuid: siteLocation.subject_uuid,
                fiscalYearStart: parseInt(siteLocation.fiscal_year_start) - 1,
                autocompleteQualityManagers: response.data[1].map(item => ({ ...item,
                    employee_uuid: item.subject_uuid
                })),
            })
        })
    }
}

export const fetchStandardAuditTasks = (ast_id, slo_uuid, year) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/tasks-list',
            method: 'GET',
            params: {
                ast_id,
                slo_uuid,
                year_start: year,
                year_end: year,
                task_type: 'utaaud',
                limit: -1,
            }
        }).then(response => {
            return response.data.items;
        })
    }
}

export const fetchRequiredDocProgressGrid = (filter) => {
    return dispatch => {
        if(!filter.standardId || !filter.siteLocationUuid)
            return
        return API.fetch({
            url: '/accreditation/req-doc-progress-list',
            method: 'GET',
            params: filter,
        }).then( ({data}) => {
            Array.isArray(data.items) && data.items.forEach(requiredDocument => {
                requiredDocument.sampleDocFilename = getDocFileNameFromPath(requiredDocument.sample_doc_path);
                // requiredDocument.reviewers.forEach((reviewer, index) => {
                //     reviewer.status_name = I18N.translate(`COMMON.STATUS.${reviewer.status_code.toUpperCase()}`);
                //     // requiredDocument[`reviewer${index + 1}`] = reviewer.status_name; // create grid column for each reviewer
                // })
            })
            return dispatch({
                type: GRID.GRID_FETCH,
                id: IDS.accStandard,
                path: IDS.standardDocumentsProgressGrid,
                ...data,
                items: data.items.map(item => ({
                    ...item,
                    is_enabled: !!item.is_enabled,
                    status_name: !item.is_enabled ? 'Not Applicable' : I18N.translate(`COMMON.STATUS.${item.status_code.toUpperCase()}`, item.status_name),
                    // class: ['accDocumentApproved','accDocumentRejected'][0],
                    levelAndType: `${item.level} ${item.doc_type}`,
                }))
            });
        }, error => {
            responseMessage(error);
        });
    }
}

export const fetchEvidenceAssigneeCandidates = (keyword, location) => {
    return dispatch => {
        return API.fetch({
            url: `/employees`,
            method: 'GET',
            params: {
                keyword,
                location,
                status: 'act',
                //licences: false,
                offset: 0,
                limit: 20
            }
        }).then(response => {
            dispatch({
                id: IDS.accStandard,
                type: TYPES.FETCH_EVIDENCE_ASSIGNEE_CANDIDATES,
                data: response.data.list.map(item => ({
                        ...pick(item, ['first_name','last_name','email', 'subject_type_name']),
                        employee_uuid : item.subject_uuid
                    })
                )
            });
        });
    };
};

export const invalidateRequiredDocument = (ard_id, slo_uuid, successMessage) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/invalidate-stand-required-doc',
            method: 'POST',
            data: {
                ard_id,
                slo_uuid,
            }
        }).then(response => {
            success(successMessage);
        }, error => {
            responseMessage(error);
        });
    };
}

export const reactivateRequiredDocument = (ard_id, slo_uuid) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/reactivate-stand-required-doc',
            method: 'POST',
            data: {
                ard_id,
                slo_uuid,
            }
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_ENABLED');
        }, error => {
            responseMessage(error);
        });
    };

}

export const addEvidenceRequest = (aas_id,
                                   ast_id,
                                   slo_uuid,
                                   year,
                                   name,
                                   description,
                                   employee_uuid,
                                   first_name,
                                   last_name,
                                   email) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/add-evidence-request',
            method: 'POST',
            data: {
                aas_id,
                ast_id,
                slo_uuid,
                year,
                name,
                description,
                employee_uuid: employee_uuid || undefined,
                first_name,
                last_name,
                email,
            }
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_ASSIGNED');
        },error => {
            responseMessage(error)
        });
    };
};

// export const updateStandardAssessmentStatus = (ast_id, slo_uuid, year, status_code) => {
//     return dispatch => {
//         return API.fetch({
//             url: '/accreditation/update-standard-assessment-status',
//             method: 'PUT',
//             data: {
//                 ast_id,
//                 slo_uuid,
//                 year,
//                 status_code,
//             }
//         }).then(response => {
//             success('COMMON.SERVER_RESPONSE.ITEM_UPDATED');
//         },error => {
//             responseMessage(error)
//         });
//     };
// };

export const setLocationFilter = (slo_uuid) => {
    return dispatch => {
        return dispatch({
            type:   GRID_FILTER,
            id:     IDS.accStandard,
            path:   IDS.documentAddGrid,
            filter: { slo_uuid }
        })
    }
}

// export const showTabRequestDocuments = (assessmentItem) => {
//     return dispatch => {
//         dispatch({
//             type: TYPES.SELECT_ASSESSMENT_ITEM,
//             id: IDS.accStandard,
//             assessmentItem,
//         });
//         return dispatch({
//             type: TAB_SHOW,
//             id: IDS.app_accreditation_scheme_manage,
//             state: [IDS.app_accreditation_scheme_manage_documentRequest]
//         })
//     }
// }

export const fetchAssessmentStandardsTreeProgress = (scheme_code, loc_uuid, fiscal_year) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/standards-tree-progress',
            params: {loc_uuid, fiscal_year, scheme_code},
            method: 'GET'
        }).then( response => {
            const sectionsList = index(response.data);
            sectionsList.forEach(item => {
                item.statusName = I18N.translate(
                    `ACCREDITATION.STANDARDS.STATUS.${item.status_code?.toUpperCase()}`,
                    item.status_name
                );
            })
            dispatch({
                type: ACC_TYPES.FETCH_STANDARDS_TREE_PROGRESS,
                id: IDS.accStandard,
                data: response.data
            });
            return response.data;
        }, error => {
            responseMessage(error);
        });
    }
}

const index = (children, list = []) => {
    children && children.forEach(item => {
        if(item) {
            list[parseInt(item.id)] = item;
            index(item.children, list);
        }
    })
    return list;
};
