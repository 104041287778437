/**
 * Creates a new AdministrationMonthlyReportSettingsDetailsController.
 * @class
 * @memberOf module:root/legacy/components/administration
 */
class AdministrationMonthlyReportSettingsDetailsController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param $mdDialog {service}
     * @param $timeout {service} {@link https://docs.angularjs.org/api/ng/service/$timeout|AngularJS $timeout}
     * @param NavigationService {service} {@link module:root/common.NavigationService|NavigationService}
     * @param Notifier {service} {@link Notifier}
     * @param AdministrationMonthlyReportSettingsActions {service}
     */
    constructor($ngRedux, $state, $scope, $mdDialog, $timeout, NavigationService, Notifier, AdministrationMonthlyReportSettingsActions) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        this.$timeout = $timeout;
        this.NavigationService = NavigationService;
        this.Notifier = Notifier;
        this.actions = AdministrationMonthlyReportSettingsActions;
    }

    /**
     * @method $onInit
     * @memberof module:root/legacy/components/administration.AdministrationMonthlyReportSettingsDetailsController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);

        this.identifier = 'app.administration.monthlyReportSettings';
        this.state = 'app.administration.monthlyReportSettings.details';
    }

    /**
     * @method manageMonthlyReportSettings
     * @memberOf module:root/components/administration.AdministrationMonthlyReportSettingsDetailsController
     */
    manageMonthlyReportSettings() {
        if (this.monthlyReportSettingsForm.$valid) {
            this.editMonthlyReportSettings(this.monthlyReport).then(() => {
                this.Notifier.success('Monthly Report has been successfully updated');
            });
        }
        else this.Notifier.error('Please fill in the form fields');
    }

    /**
     * @method mapState
     * @param state {object} Redux Store
     * @return {object}
     * @memberof module:root/components/administration.AdministrationMonthlyReportSettingsDetailsController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapState(state) {
        return {
            monthlyReport: state.administration.administrationMonthlyReportSettings
        }
    }

    /**
     * @method $onDestroy
     * @memberof module:root/components/administration.AdministrationMonthlyReportSettingsDetailsController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.unsubscribe();
        if (this.removeDialog) this.$mdDialog.hide(this.removeDialog);
        if (this.saveDialog) this.$mdDialog.hide(this.saveDialog);
    }
}

AdministrationMonthlyReportSettingsDetailsController.$inject = ['$ngRedux', '$state', '$scope', '$mdDialog', '$timeout', 'NavigationService', 'Notifier', 'AdministrationMonthlyReportSettingsActions'];

export const administrationMonthlyReportSettingsDetails = {
    controller: AdministrationMonthlyReportSettingsDetailsController,
    template: require('./administration-monthly-report-settings-details.html')
};
