export const resolveOtpParams = (status) => {
    const params = { status: 'verify' };
    switch (status) {
    case 2401:
        params.headline = 'COMMON.LOGIN.OTP_HEADLINE';
        params.message = null;
        break;
    case 2402:
        params.headline = 'COMMON.LOGIN.OTP_ERROR_2402';
        params.message = 'COMMON.LOGIN.OTP_ERROR_2402_MSG';
        params.class = 'error';
        break;
    case 2403:
        params.headline = 'COMMON.LOGIN.OTP_ERROR_2403';
        params.message = 'COMMON.LOGIN.OTP_ERROR_2403_MSG';
        params.class = 'error';
        break;
    case 2404:
        params.headline = 'COMMON.LOGIN.OTP_ERROR_2404';
        params.message = 'COMMON.LOGIN.OTP_ERROR_2404_MSG';
        params.class = 'error';
        break;
    case 2405:
        params.headline = 'COMMON.LOGIN.OTP_ERROR_2405';
        params.message = 'COMMON.LOGIN.OTP_ERROR_2405_MSG';
        params.class = 'error';
        break;
    case 2406:
        params.headline = 'COMMON.LOGIN.OTP_ERROR_2406';
        params.message = 'COMMON.LOGIN.OTP_ERROR_2406_MSG';
        params.class = 'error';
        break;
    }
    return params;
};
