/**
 * @module root/components/shielding
 */
import { shieldingState } from './shielding.states';
import { shielding } from './shielding.component';

export const SHIELDING_MODULE = angular.module(
    'migration.shielding',
    []
).config(['$uiRouterProvider', $uiRouter => {
    const $stateRegistry = $uiRouter.stateRegistry;
    $stateRegistry.register(shieldingState);
}]).component({
    shielding
});
