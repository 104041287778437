import { IDS } from '../../adm-categorisation-action-id.constants';
import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';
import * as CentersActions from '../adm-cat-centers.actions';
import {fetchCenterTypesGrid} from "../adm-cat-centers.actions";

class AdmCatCentersGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$filter = $filter;
        this.$state = $state;
    }

    $onInit() {
        this.filterOpen = true;
        this.filterExpandButtonVisible = false;
        this.filterTemplate = './adm-cat-centers-grid-filter.html';
        this.title = 'Center Types';
        this.exportComponent = false;
        this.columns = [
            {title: '#', mapper: null},
            {title: this.$filter('translate')('COMMON.FIELD.CENTER_TYPE'), mapper: 'name'},
            {title: 'AHPRA Required', mapper: 'ahpra_licence_required'},
            {title: 'Use license Required', mapper: 'use_licence_required'},
        ];
        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), CentersActions)(this);
        this.dataAction = this.fetchCenterTypesGrid;
        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

AdmCatCentersGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter']

export const admCatCentersGrid = {
    bindings: {
        module: '<',
        identifier: '<',
        statePath: '<',
        // onRemove: '&',
        onSelect: '&',
    },
    controller: AdmCatCentersGridController,
    ...gridTpl
}
