import API from '../../../common/http/api.fetch';
import isObject from 'lodash/isObject';

export const getAssessmentLikelihoodTypes = () => {
    return (dispatch) => {
        return API.fetch({
            url: `/events/categories/miscellaneous`,
            method: 'GET',
            params: { category_code: 'lhe' }
        }).then(response => {
            dispatch({
                id: 'eventAssessment',
                type: 'EVENT_ASSESSMENT_LIKELIHOODS',
                data: response.data,
            })
        });
    };
};

export const getAssessmentSeverityPotentialTypes = () => {
    return (dispatch) => {
        return API.fetch({
            url: `/events/categories/miscellaneous`,
            method: 'GET',
            params: {
                category_code: 'svp',
                order: 'desc'
            }
        }).then(response => {
            dispatch({
                id: 'eventAssessment',
                type: 'EVENT_ASSESSMENT_SEVERITY_POTENTIALS',
                data: response.data,
            })
        });
    };
};

export const getAssessment = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/event/assessment`,
            method: 'GET',
            params: { id }
        }).then(({ data }) => {
            dispatch({
                id: 'eventAssessment',
                type: 'EVENT_ASSESSMENT',
                data: data.items,
            });
            dispatch({
                type: 'GRID_RELOAD',
                id: 'eventsAssessmentGrid'
            });
        });
    };
};

export const getAssessmentScore = (data) => {
    return dispatch => {
        return API.fetch({
            url: `/event/assessment/score`,
            method: 'POST',
            data
        }).then(({ data }) => {
            const params = isObject(data.params) ? data.params : JSON.parse(data.params);
            const { rle_id, score, assessment_color, action, record } = params;
            dispatch({
                id: 'eventAssessment',
                type: 'EVENT_ASSESSMENT_SCORE',
                rle_id,
                score,
                assessment_color,
                action,
                record,
            });
            return assessment_color;
        });
    };
};

export const getRiskActions = (event_code) => {
    return dispatch => {
        return API.fetch({
            url: `/event/risk-actions`,
            method: 'GET',
            params: { event_code },
        }).then(({ data }) => {
            dispatch({
                id: 'eventAssessment',
                type: 'RISK_ACTIONS',
                data,
            });
        });
    };
};

export const getAssessmentHistoricalRecords = (filter) => {
    return dispatch => {
        return API.fetch({
            url: `/events`,
            method: 'GET',
            params: {
                risk_code: filter.code,
                type_id: filter.type_id,
                category_id: filter.category_id,
                subcategory_id: filter.subcategory_id,
                exclude_iev_id: filter.exclude_iev_id,
                offset: filter.offset,
                limit: filter.limit,
            }
        }).then(response => {
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: response.data.items,
                itemsCount: parseInt(response.data.items_count)
            });
        });
    };
};

export const getAssessmentHistoricalRecordDetails = (id) => {
    return dispatch => {
        return API.fetch({
            url: `/event`,
            method: 'GET',
            params: { id }
        }).then(response => {
            dispatch({
                id: 'eventAssessment',
                type: 'EVENT_ASSESSMENT_HISTORICAL_RECORD',
                data: response.data,
            });
        });
    };
};

export const saveAssessment = (data) => {
    return dispatch => {
        return API.fetch({
            url: `/event/assessment/save`,
            method: 'POST',
            data
        }).then(response => {
            dispatch({
                id: 'eventAssessment',
                type: 'EVENT_ASSESSMENT_SAVE',
                data: response.data
            });
            dispatch({
                type: 'GRID_RELOAD',
                id: 'eventsAlertReceiversGrid'
            });
        });
    };
};

export const saveAssessmentIncident = (data) => {
    return dispatch => {
        return API.fetch({
            url: `/event/assessment/incident/save`,
            method: 'POST',
            data
        }).then(response => {
            // dispatch({
            //     type: 'EVENT_ASSESSMENT_INCIDENT_DETAILS',
            //     id: 'eventAssessment',
            //     data: response.data.params
            // });
            if (response.data.params.length) {
                return response.data[0].sac_name;
            }
            return null;
        });
    };
};

export const getAssessmentIncident = (id) => {
    return dispatch => {
        return API.fetch({
            url: `/event/assessment/incident/details`,
            method: 'GET',
            params: { id }
        }).then(response => {
            const responseData = _.get(response.data, 'items', response.data)
            dispatch({
                type: 'EVENT_ASSESSMENT_INCIDENT_DETAILS',
                id: 'eventAssessment',
                data: responseData
            });
            if (responseData.length) {
                return responseData[0].sac_name;
            }
            return null;
        })
    };
};

export const getResponsibleOfficer = (keyword) => {
    return dispatch => {
        return API.fetch({
            url: `/employees`,
            method: 'GET',
            params: {
                keyword,
                status: 'act',
                licences: false,
                offset: 0,
                limit: 20,
            }
        }).then(response => {
            dispatch({
                id: 'eventAssessment',
                type: 'EVENT_ASSESSMENT_RESPONSIBLE_OFFICER',
                data: response.data
            });
        });
    };
};

export const getResponsiblePersons = (keyword) => {
    return dispatch => {
        return API.fetch({
            url: `/employees`,
            method: 'GET',
            params: {
                keyword,
                status: 'act',
                licences: false,
                offset: 0,
                limit: 20,
            }
        }).then(response => {
            dispatch({
                id: 'eventAssessment',
                type: 'EVENT_ASSESSMENT_RESPONSIBLE_PERSONS',
                data: response.data
            });
        });
    };
};

export const getAssessmentIncidentPersonTypes = () => {
    return dispatch => {
        return API.fetch({
            url: `/events/categories/miscellaneous`,
            method: 'GET',
            params: { category_code: 'iprivp' }
        }).then(response => {
            dispatch({
                type: 'EVENT_ASSESSMENT_INCIDENT_PERSON_TYPES',
                id: 'eventAssessment',
                data: response.data
            });
        });
    };
};

export const setAssessmentIncidentPersonType = () => {
    return (dispatch, getState) => {
        const state = getState();
        const { eventInvolvedPersons } = state.events.eventItem;
        if (eventInvolvedPersons.length) {
            dispatch({
                type: 'EVENT_ASSESSMENT_INCIDENT_PERSON_TYPE_SET',
                id: 'eventAssessment',
                data: String(eventInvolvedPersons[0].person_type_id)
            });
        }
    };
};

export const getAssessmentIncidentCodes = () => {
    return dispatch => {
        return API.fetch({
            url: `/events/categories/miscellaneous`,
            method: 'GET',
            params: { category_code: 'sac' }
        }).then(response => {
            dispatch({
                type: 'EVENT_ASSESSMENT_INCIDENT_CODES',
                id: 'eventAssessment',
                data: response.data
            });
        });
    };
};

export const getAssessmentIssueImpacts = (id) => {
    return dispatch => {
        return API.fetch({
            url: `/event/assessment/issue/impacts`,
            method: 'GET',
            params: { id }
        }).then(response => {
            dispatch({
                type: 'EVENT_ASSESSMENT_IMPACTS',
                id: 'eventAssessment',
                data: _.get(response.data, 'items', response.data)
            });
        });
    };
};

export const createAssessmentIssueImpact = (data) => {
    return dispatch => {
        return API.fetch({
            url: `/event/assessment/issue/impact/create`,
            method: 'POST',
            data
        }).then(response => {
            dispatch({
                type: 'EVENT_ASSESSMENT_IMPACT_CREATE',
                id: 'eventAssessment',
                data: response.data.params.imp_id ? response.data.params.imp_id : String(JSON.parse(response.data.params).imp_id)
            });
        });
    };
};

export const editImpact = (data) => {
    return dispatch => {
        dispatch({
            type: 'EVENT_ASSESSMENT_IMPACT_EDIT',
            id: 'eventAssessment',
            data
        });
    };
};

export const updateAssessmentIssueImpact = (data) => {
    return dispatch => {
        return API.fetch({
            url: `/event/assessment/issue/impact/update`,
            method: 'POST',
            data
        }).then(response => {
            dispatch({
                type: 'EVENT_ASSESSMENT_IMPACT_UPDATE',
                id: 'eventAssessment',
                data: response.data
            });
        });
    };
};

export const deleteAssessmentIssueImpact = (params) => {
    return dispatch => {
        return API.fetch({
            url: `/event/assessment/issue/impact/delete`,
            method: 'GET',
            params
        }).then(response => {
            dispatch({
                type: 'EVENT_ASSESSMENT_IMPACT_REMOVE',
                id: 'eventAssessment'
            });
        })
    };
};

export const resetImpact = () => {
    return dispatch => {
        dispatch({
            type: 'EVENT_ASSESSMENT_IMPACT_RESET',
            id: 'eventAssessment'
        });
    };
};

export const getAssessmentIssueCodes = () => {
    return dispatch => {
        return API.fetch({
            url: `/events/categories/miscellaneous`,
            method: 'GET',
            params: { category_code: 'iac' }
        }).then(response => {
            dispatch({
                type: 'EVENT_ASSESSMENT_ISSUE_CODES',
                id: 'eventAssessment',
                data: response.data
            });
        });
    };
};

export const saveAssessmentIssue = (data) => {
    return dispatch => {
        return API.fetch({
            url: `/event/assessment/issue/save`,
            method: 'POST',
            data
        }).then(response => {
            dispatch({
                type: 'EVENT_ASSESSMENT_ISSUE_SAVE',
                id: 'eventAssessment'
            });
        });
    };
};

export const getAssessmentIssue = (id) => {
    return dispatch => {
        return API.fetch({
            url: `/event/assessment/issue/details`,
            method: 'GET',
            params: { id }
        }).then(response => {
            dispatch({
                type: 'EVENT_ASSESSMENT_ISSUE_DETAILS',
                id: 'eventAssessment',
                data: response.data
            });
            if (response.data.length) {
                return response.data[0].iac;
            }
            return null;
        })
    };
};

export const getQualityManagers = (keyword) => {
    return dispatch => {
        return API.fetch({
            url: `/employees`,
            method: 'GET',
            params: {
                keyword,
                status: 'act',
                licences: false,
                offset: 0,
                limit: 20
            }
        }).then(response => {
            dispatch({
                id: 'eventAssessment',
                type: 'EVENT_ASSESSMENT_QUALITY_MANAGERS',
                data: response.data
            });
        });
    };
};

export const saveAssessmentReview = (data) => {
    return dispatch => {
        return API.fetch({
            url: `/event/assessment/review/save`,
            method: 'POST',
            data
        }).then(response => {
            dispatch({
                type: 'EVENT_ASSESSMENT_REVIEW_SAVE',
                id: 'eventAssessment'
            });
        });
    };
};
