export const credentialingPublicState = {
    name: 'public.credentialing',
    abstract: true,
    url: '/public/credentialing'
};

export const credentialingDashboardPublicState = {
    name: 'public.credentialing.dashboard',
    url: '',
    component: 'dashboard'
};

export const creVendorsPublicState = {
    name: 'public.credentialing.vendors',
    abstract: true,
    url: '/vendors'
};

export const creVendorsDashboardPublicState = {
    name: 'public.credentialing.vendors.dashboard',
    url: '',
    component: 'dashboard'
};
