import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';

class SelectGenderController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        if (isUndefined(this.validate))
            this.validate = false;

        if (this.validate && isUndefined(this.name))
            throw new Error('Missing select name attribute');

        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);
    }

    onSelect() {
        if (this.selectedOption) {
            this.$ngRedux.dispatch({
                type: 'GENDER_SELECTED',
                id: this.identifier,
                gender: this.selectedOption,
                path: this.statePath
            });
        }
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeModule();
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        }
    }

    mapState(state) {
        return {
            options: state.app.genders,
            selectedOption: get(state[this.module][this.identifier], this.statePath, null)
        }
    }
}

SelectGenderController.$inject = ['$ngRedux'];

export const selectGender = {
    bindings: {
        identifier: '@',
        name: '@',
        statePath: '@',
        validate: '<',
        required: '<'
    },
    require : {
        form : '^form'
    },
    controller: SelectGenderController,
    template: `
        <div layout-gt-xs="row">
            <md-input-container class="md-block" flex>
                <label>Gender</label>
                <md-select name="gender" ng-model="$ctrl.selectedOption" md-on-close="$ctrl.onSelect()" ng-required="$ctrl.required">
                    <md-option ng-value="item.id" ng-repeat="item in $ctrl.options">
                        <span class="md-body-2">{{item.title}}</span>
                    </md-option>
                </md-select>
                <div ng-messages="$ctrl.form.gender.$error">
                    <div ng-message="required">{{'COMMON.FIELD_REQUIRED' | translate}}</div>
                </div>
            </md-input-container>
        </div>
    `
};
