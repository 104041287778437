/**
 * @module root/components/inventory-scanner
 */
import { inventoryScannerState } from './inventory-scanner.states';
import { inventoryScanner } from './inventory-scanner.component';
import { scanner } from './scanner/scanner.component';
import InventoryScannerActions from './inventory-scanner.actions';

export const INVENTORY_SCANNER_MODULE = angular.module(
    'migration.inventory-scanner',
    []
).config(['$uiRouterProvider', $uiRouter => {
    $uiRouter.stateRegistry.register(inventoryScannerState);
}]).service({
    InventoryScannerActions
}).component({
    inventoryScanner,
    scanner
});
