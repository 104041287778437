import { IDS } from '../import-settings-action-id.constants';
import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import * as SettingsActions from '../import-settings.actions';
import watch from "redux-watch";

class CentersGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter, $mdDialog, $scope, $rootScope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
        this.$rootScope = $rootScope;
    }

    $onInit() {
        this.module = IDS.employees;
        this.identifier = IDS.empImportSettings;
        this.statePath = IDS.empImportSettingsCentersListGrid;
        this.filterTemplate = './centers-grid-filter.html';
        this.exportComponent = false;
        this.title = false;
        this.icon = false;
        this.confirmDialogOnRemove = true;
        this.autoload = false;
        this.filterOpen = true;
        this.filterExpandButtonVisible = false;
        this.filterActions.filterHeadOffices = this.filterHeadOffices;

        this.columns = [
            {title: '#',                mapper: null},
            {title: 'Number',           mapper: 'cost_center_number'},
            {title: 'State',            mapper: 'state_id'},
            {title: 'Cost Center',      mapper: 'cost_center_name'},
            {title: 'Site Location',    mapper: 'site_name'},
            {title: 'Auto Import Off',  mapper: 'auto_import_off'},
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), SettingsActions)(this);

        const $rootScope = this.$rootScope;
        const watchReset = watch(this.$ngRedux.getState, `${this.module}.${this.identifier}.${this.statePath}.reset`);
        this.unsubscribeOnReset = this.$ngRedux.subscribe(watchReset(function() {
            $rootScope.$broadcast('SELECT_CASCADE_RESET', 'empImportSettingsCentersGridFilterForm');
        }));

        this.dataAction = this.fetchCentersListGrid;
        this.init();
    }

    filterHeadOffices(items) {
        return items.filter(item => /^GREENCROSS.*/.test(item.in_username_par))
    }

    $onDestroy() {
        if(this.unsubscribeOnReset)
            this.unsubscribeOnReset()

        this.destroy();
    }

}

CentersGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter', '$mdDialog', '$scope', '$rootScope'];

export const empImportSettingsCentersListGrid = {
    bindings: {
        onSelect: '&',
        title: '@'
    },
    controller: CentersGridController,
    ...gridTpl
}
