import { INIT_MODULE } from '../../navigation/navigation.actions.types';

export const dashboardAlerts = {
    bindings: {
        module: '<',
        state: '<',
        color: '<',
        critical: '<',
        warning: '<',
        mark: '<',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state) {
        this.onSelect = (type) => {
            $ngRedux.dispatch({
                type: INIT_MODULE,
                module: this.module,
                color: this.color,
            });
            $state.go(
                this.state.replace('dashboard', 'alerts.listing'),
                { type: type }
            );
        }
    },
    template: `
        <div ng-if="$ctrl.critical"
             class="alertCritical"
             ng-click="$ctrl.onSelect('critical'); $event.stopPropagation();">
            <asset src="ic_warning_white_18px.svg" />({{$ctrl.critical}})
        </div>
        <div ng-if="$ctrl.warning"
             class="alertWarning"
             ng-click="$ctrl.onSelect('warning'); $event.stopPropagation();">
            <asset src="ic_notifications_white_18px.svg" />({{$ctrl.warning}})
        </div>
        <div ng-if="$ctrl.mark"
             class="alertMark"
             ng-click="$ctrl.onSelect('mark'); $event.stopPropagation();">
            <asset src="{{$ctrl.mark.icon}}" cls="colorAccreditations" />{{$ctrl.mark.text}}
        </div>
    `
};
