import { IDS } from '../../settings-action-id.constants';
import { TAB_SELECT, TAB_SHOW } from '../../../../../common/tabs/tabs.constants'
import * as SettingsActions from "../../settings.actions";
import * as SloRequiredDocsActions from '../slo-required-docs.actions';
import {TYPES} from "../../settings-action-type.constants";
import {GRID_FILTER} from "../../../../../common/grid/grid.constants";
import get from "lodash/get";
import {editSloStandardRequiredDocument} from "../slo-required-docs.actions";

/**
 * Creates a new SloRequiredDocDetailsController.
 * @class
 */
export const sloRequiredDocDetails = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $stateParams, $scope, $rootScope, $filter, NavigationService, AuthService, Notifier, FileActions) {
        /**
         * @function $onInit
         */
        this.$onInit = async () => {
            this.identifier = IDS.accSettings;
            this.parentState = IDS.app_accreditation_scheme_settings_hof_required_docs;

            this.unsubscribe = $ngRedux.connect(state => {
                const accSettings = state.accreditation.accSettings;

                const document = accSettings.sloRequiredDocDetails;

                const textId = `COMMON.DOCUMENT.CODE.${document?.type_code.toUpperCase()}`;
                let typeName = $filter('translate')(textId);
                if (typeName === textId)
                    typeName = document.type_name;

                return {
                    typeName,
                    document,
                    schemeName:                 accSettings.schemeDetails.title,
                    sample_doc_uri:             accSettings.sloRequiredDocDetails.sample_doc_uri,
                }
            }, {
                ...FileActions,
                ...SettingsActions,
                ...SloRequiredDocsActions,
            })(this);

            this.title = `${this.document.name}`;
            this.schemeCode = $stateParams.itemCode;


            this.getSchemeDetails(this.schemeCode).then(scheme => {
                this.fetchEnabledHeadOffices(scheme.id)
            })
        }

        this.onSampleDocUploadSave = (event) => {
            $ngRedux.dispatch({
                type: TYPES.DOCUMENT_SAMPLE_DOC_CHANGED,
                id: IDS.accSettings,
                path: IDS.sloRequiredDocDetails,
                sample_doc_uri: event[0].filePath,
            });
        }

        this.onDownloadSampleDoc = () => {
            this.download(`storage${this.sample_doc_uri}`);
        };

        this.onResetSampleDoc = () => {
            $ngRedux.dispatch({
                type: TYPES.DOCUMENT_SAMPLE_DOC_CHANGED,
                id: IDS.accSettings,
                path: IDS.sloRequiredDocDetails,
                sample_doc_uri: null,
            });
        }

        this.onSaveChangesButton = () => {
            $scope.$broadcast('show-errors-check-validity', '$ctrl.requiredDocumentDetailsForm');

            if (this.requiredDocumentDetailsForm.$valid === false) {
                Notifier.error('COMMON.FIELDS_REQUIRED');
                return;
            }
            this.editSloStandardRequiredDocument({
                ard_id: this.document.ard_id,
                doc_num: this.document.doc_num,
                name: this.document.name,
                description: this.document.description,
                sample_doc_uri: this.document.sample_doc_uri,
            }).then(() => {
                $ngRedux.dispatch({
                    type: TAB_SELECT,
                    id: this.parentState,
                    state: IDS.app_accreditation_scheme_settings_hof_required_docs_list,
                });

            })
        }

        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: require('./slo-required-doc-details.html')
}