import {IDS} from '../dms-manage-action-id.constants';
import * as DocumentDetailsActions from './details.actions';
import * as PnpAccredListActions from '../list/list.actions';
import {GRID_RELOAD} from "../../../../common/grid/grid.constants";
import get from 'lodash/get';
import moment from "moment";
class DmsManageDetailsController {
    constructor($ngRedux,
                $state,
                $stateParams,
                $filter,
                $timeout,
                Notifier,
                DocumentManager,
                EmployeeService,
                FileActions) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.$timeout = $timeout;
        this.Notifier = Notifier;
        this.DocumentManager = DocumentManager;
        this.EmployeeService = EmployeeService;
        this.FileActions = FileActions;
        this.standardNumber = 'Activity';
        this.identifier = IDS.accMasterDocList;
    }

    $onInit() {
        this.schemeCode         = this.$stateParams.itemCode;
        this.adoId              = this.$stateParams.ado;
        this.sloFiscalYear      = this.$stateParams.year;

        this.unsubscribe = this.$ngRedux.connect(state => {
            // const areChangesSaved   = review && originalReview
            //                         && review.statusCode === originalReview.statusCode
            //                         && review.reviewComment === originalReview.reviewComment
            return {
                // disableSubmitButton: areChangesSaved,
                // disableCommentField: areChangesSaved,
                // standardNumber:      get(documentDetails,'standardName','').split(' ')[0],
                // fiscalYearStart:     state.accreditation.accDocument.fiscalYearStart,
            }
        }, {
            ...DocumentDetailsActions,
            ...PnpAccredListActions,
            ...this.FileActions,
        })(this);

        // this.initReviewerStatuses(['pen','app','nap']);
    }

    onSubmit(){
        if(this.uploadForm.$valid) {
            // this.setDocumentResult({
            //     ado_id:         this.adoId,
            //     arq_id:         this.review.arqId,
            //     aar_id:         this.review.aarId          || undefined,
            //     status_code:    this.review.statusCode     || undefined,
            //     review_comment: this.review.reviewComment  || undefined,
            // }).then( () => {
            //     this.$ngRedux.dispatch({
            //         type: GRID_RELOAD,
            //         id: IDS.pnpAccredDetails,
            //         path: IDS.pnpAccredListGrid,
            //     });
            //     this.fetchDocumentDetails(this.adoId);
            // })
        }
    }

    getFiscalYear () {
        const fiscalYearStart = moment().month(this.fiscalYearStart).day(0);
        return moment().isAfter(fiscalYearStart) ? moment().year() : moment().year() - 1;
    }

    onDownloadButton() {
        this.download(`storage${this.url}`);
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

DmsManageDetailsController.$inject = [
    '$ngRedux',
    '$state',
    '$stateParams',
    '$filter',
    '$timeout',
    'Notifier',
    'DocumentManager',
    'EmployeeService',
    'FileActions']

export const dmsManageDetails = {
    controller: DmsManageDetailsController,
    template: require('./details.html')
}
