import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsRoomsController($ngRedux, $state, $uiRouterGlobals, $timeout, AdministrationActions) {
    var ctrl = this;
    var state = $state.current.name;
    var unsubscribe = angular.noop;
    var onRoom = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);

        var watchRoom = watch($ngRedux.getState, 'administration.administrationAccountsRoom');
        onRoom = $ngRedux.subscribe(watchRoom(function(room) {
            $timeout(function() {
                $ngRedux.dispatch({
                    type: 'TAB_SELECT',
                    id: 'app.administration.accounts.rooms',
                    state: state,
                    stateParams: {
                        hof: $uiRouterGlobals.params.hof,
                        slo: $uiRouterGlobals.params.slo,
                        dep: $uiRouterGlobals.params.dep,
                        room: room.data.uuid
                    }
                });
            }, 200);
        }));

        $ngRedux.dispatch({
            type: 'LOCATION_SELECTED',
            id: 'administrationAccountsRoomsGrid',
            dep: $uiRouterGlobals.params.dep
        });
    };

    ctrl.selectRoom = function(event) {
        state = 'app.administration.accounts.rooms.details';
        ctrl.getRoomDetails(event.item.uuid, true);
    };

    ctrl.addRoom = function() {
        $ngRedux.dispatch({
            type: 'ROOM_RESET',
            id: 'administrationAccountsRoom'
        });

        $ngRedux.dispatch({
            type: 'TAB_ENABLE',
            id: 'app.administration.accounts.rooms',
            state: ['app.administration.accounts.rooms.details']
        });

        $timeout(function() {
            $ngRedux.dispatch({
                type: 'TAB_SELECT',
                id: 'app.administration.accounts.rooms',
                state: 'app.administration.accounts.rooms.details'
            });
        }, 200);
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onRoom();
    };

    function mapStateToThis(state) {
        return {
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                state.administration.administrationAccountsDepartment.data.typeName,
                'Rooms'
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
