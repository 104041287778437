import { APPLICATIONS_PERMITS_MODULE } from './applications-permits/applications-permits.module';
import { INVENTORY_SCANNER_MODULE } from './inventory-scanner/inventory-scanner.module';
import { QC_TASKS_MODULE } from './qc-tasks/qc-tasks.module';
import { EQUIPMENT_CALIBRATION_MODULE } from './equipment-calibration/equipment-calibration.module';
import { EVENTS_MODULE } from './events/events.module';
import { SHIELDING_MODULE } from './shielding/shielding.module';
import { PERSONAL_DOSIMETRY_MODULE } from './personal-dosimetry/personal-dosimetry.module';
import { REPORTING_MODULE } from './reporting/reporting.module';
import { DOSE_REFERENCE_LEVELS_MODULE } from './dose-reference-levels/dose-reference-levels.module';
import { SYSTEM_MODULE } from './system/system.module';
import { ACCREDITATION_MODULE } from './accreditation/accreditation.module';
import { RADIOACTIVE_MATERIALS_MODULE } from './radioactive-materials/radioactive-materials.module';
import { ADMINISTRATION_MODULE } from './administration/administration.module';
import { EMPLOYEES_MODULE } from './employees/employees.module';
import { POLICIES_AND_PROCEDURES_MODULE } from './policies-and-procedures/pnp.module';
import { DOCUMENT_MANAGEMENT_MODULE } from './document-management/dms.module';
import { CREDENTIALING_MODULE } from './credentialing/credentialing.module';

export const APP_COMPONENTS = angular.module('migration', [
    APPLICATIONS_PERMITS_MODULE.name,
    INVENTORY_SCANNER_MODULE.name,
    QC_TASKS_MODULE.name,
    EQUIPMENT_CALIBRATION_MODULE.name,
    EVENTS_MODULE.name,
    SHIELDING_MODULE.name,
    PERSONAL_DOSIMETRY_MODULE.name,
    REPORTING_MODULE.name,
    DOSE_REFERENCE_LEVELS_MODULE.name,
    SYSTEM_MODULE.name,
    ACCREDITATION_MODULE.name,
    RADIOACTIVE_MATERIALS_MODULE.name,
    ADMINISTRATION_MODULE.name,
    EMPLOYEES_MODULE.name,
    POLICIES_AND_PROCEDURES_MODULE.name,
    DOCUMENT_MANAGEMENT_MODULE.name,
    CREDENTIALING_MODULE.name,
]);
