import { product } from '../../../bootstrap.json';
import { saveAs } from 'file-saver';
import get from 'lodash/get';

/* @ngInject */
export function SafetyTrainingService($http, $q, Notifier, SafetyTrainingPresentationReview, SafetyTrainingVideo, API_BASE, Helpers, DATE_TYPE) {

    var _this = this;

    /** Fetch user current training review data
     *
     * Method   - GET
     * Endpoint - /{type}/review/{site_location}
     * Params   -
     *       ** type - general type of review (e.g. "training", "rspp"
     *       ** siteLocationUuid - site location context for review data (for future use)
     *       ** year - year for review (optional), current year is default
     *       ** filter - aditional parameter filter (for future use)
     * Response - Object with result about status of approval of document
     *
     * */
    this.getCandidateReview = function (type, siteLocationUuid, year, filter) {
        var params = [];

        params['siteLocationUuid'] = siteLocationUuid;
        params['year'] = year !=null ? year : new Date().getFullYear();

        if (filter != null) {
            var strippedFilter = {};

            if (filter.firstName!= null) {
                strippedFilter.firstName = filter.firstName;
            }

            if (filter.lastName!= null) {
                strippedFilter.lastName = filter.lastName;
            }

            if (filter.occupation!= null) {
                strippedFilter.occupation = filter.occupation;
            }


            if (filter.dueDateAfter!= null) {
                strippedFilter.dueDateAfter = filter.dueDateAfter;
            }

            if (filter.dueDateBefore!= null) {
                strippedFilter.dueDateBefore = filter.dueDateBefore;
            }

            params['filter'] = strippedFilter;
        }

        return $http({
            url: API_BASE + "/" + type + "/review",
            params: params,
            method: "GET"
        }).then(({ data }) => {
            var arr = [];
            const candidateReviews = get(data, 'list', data);
            candidateReviews.forEach(function (elem) {
                var item = new SafetyTrainingPresentationReview();
                item.id = +elem.rre_id;
                item.userUuid = elem.sub_uuid;

                if (elem.due_date != null) {
                    item.dueDate = Helpers.parseFromDbDate(elem.due_date, DATE_TYPE.DATE);
                    item.dueDateStr = Helpers.parseFromDbDate(elem.due_date, DATE_TYPE.STRING);
                }

                if (elem.start_date != null) {
                    item.startDate = Helpers.parseFromDbDate(elem.start_date, DATE_TYPE.DATE);
                    item.startDateStr = Helpers.parseFromDbDate(elem.start_date, DATE_TYPE.STRING);
                }

                if (elem.end_date != null) {
                    item.endDate = Helpers.parseFromDbDate(elem.end_date, DATE_TYPE.DATE);
                    item.endDateStr = Helpers.parseFromDbDate(elem.end_date, DATE_TYPE.STRING);
                }

                item.nextSection = elem.next_section;
                item.presentation.id = elem.pre_id;

                arr.push(item);
            });

            if (arr.length > 0) {
                data.result = arr[0];
            } else {
                data.result = null;
            }

            return data;
        },
        function errorCallback(errorResponse) {
            Notifier.responseMessage(errorResponse.status);
            return $q.reject(errorResponse);
        });
    };

    this.getPresentationVideos = function(type, siteLocationUuid, presentationId) {
        var params = [];
        params['type'] = type;
        params['siteLocationUuid'] = siteLocationUuid;
        params['presentationId'] = presentationId;

        return $http({
            url: API_BASE + "/" + type + "/presentation/videos",
            params: params,
            method: "GET"
        }).then(({ data }) => {
            var arr = [];
            const presentationVideos = get(data, 'list', data);
            presentationVideos.forEach(function(elem) {
                var item = new SafetyTrainingVideo();
                item.id = elem.vid_id;
                item.presentation.id = elem.pre_id;
                item.presentationOrder = elem.presentation_order;
                item.uri = elem.uri;
                item.name = elem.name;
                item.description = elem.description;
                item.videoSectionNumber = +elem.video_section_number;
                arr.push(item);
            });
            return {
                list: arr,
                rows: data.rows
            };
        }, function errorCallback(errorResponse) {
            Notifier.responseMessage(errorResponse.status);
            return $q.reject(errorResponse);
        });
    };

    this.updateEmployeeReview = function (type, reviewId, watchedSection) {

        var ldata = {
            watchedSection: Number(watchedSection)
        };

        return $http({
            url: API_BASE + "/" + type + "/review/" + reviewId,
            method: "POST",
            data: ldata
        }).then(function successCallback(response) {
                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };

    /**
     * Method   - GET
     * Endpoint - /{type}/review/certificate
     * Response - Object
     *
     * Generate pdf certificate for employee particular review
     * Fields:
     *
     * */
    this.generateCertificate = (type, reviewId) => {
        const params = [];
        params['reviewId'] = reviewId;
        params['format'] = "pdf";
        return $http({
            url: API_BASE +  "/" + type + "/review/certificate",
            method: "GET",
            responseType: 'arraybuffer',
            params,
        }).then((response) => {
            saveAs(new Blob([response.data], { type: 'application/pdf' }), `${product}-Certificate.pdf`);
            return response;
        }, (error) => {
            Notifier.responseMessage(error.status);
            return $q.reject(error);
        });
    };

    /** Fetch user reviews for site location
     *
     * Method   - GET
     * Endpoint - /{type}/manage/{siteLocationUuid}/review
     * Params   -
     *       ** type - general type of review (e.g. "training", "rspp"
     *       ** siteLocationUuid - site location context for review data
     *       ** year - year for review (optional), current year is default
     *       ** filter - aditional parameter filter
     * Response - Object with result about status of approval of document
     *
     * */
    this.getEmployeeReviews = function (type, siteLocationUuid, year, filter) {
        var params = [];

        params['year'] = year !=null ? year : new Date().getFullYear();

        if (filter != null) {
            var strippedFilter = {};

            if (filter.firstName!= null) {
                strippedFilter.first_name = filter.firstName;
            }

            if (filter.lastName!= null) {
                strippedFilter.last_name = filter.lastName;
            }

            if (filter.occupations != null) {
                var result = filter.occupations.map(item => parseInt(item.id));
                strippedFilter.occupations = result;
            }

            if (filter.occupation != null) {
                strippedFilter.occupation = filter.occupation;
            }

            if (filter.excludedOccupations != null) {
                var result = filter.excludedOccupations.map(item => parseInt(item.id));
                strippedFilter.excluded_occupations = result;
            }

            if (filter.dueDateAfter!= null) {
                strippedFilter.due_date_after = Helpers.formatDbDate(filter.dueDateAfter);
            }

            if (filter.dueDateBefore!= null) {
                strippedFilter.due_date_before = Helpers.formatDbDate(filter.dueDateBefore);
            }

            params['filter'] = strippedFilter;
        }

        return $http({
            url: API_BASE + "/" + type + "/manage/"+siteLocationUuid+"/review",
            params: params,
            method: "GET"
        }).then(({ data }) => {
            var arr = [];
            const employeeReviews = get(data, 'list', data);
            employeeReviews.forEach(function (elem) {
                var item = new SafetyTrainingPresentationReview();
                if (elem.rre_id != null) {
                    item.id = +elem.rre_id;
                }
                item.userUuid = elem.sub_uuid;
                item.firstName = elem.first_name;
                item.lastName = elem.last_name;
                item.userName = elem.username;
                item.occupation = elem.occupation;
                item.trainingName = elem.training_name;

                if (elem.due_date != null) {
                    item.dueDate = Helpers.parseFromDbDate(elem.due_date, DATE_TYPE.DATE);
                    item.dueDateStr = Helpers.parseFromDbDate(elem.due_date, DATE_TYPE.STRING);
                }

                if (elem.start_date != null) {
                    item.startDate = Helpers.parseFromDbDate(elem.start_date, DATE_TYPE.DATE);
                    item.startDateStr = Helpers.parseFromDbDate(elem.start_date, DATE_TYPE.STRING);
                }

                if (elem.end_date != null) {
                    item.endDate = Helpers.parseFromDbDate(elem.end_date, DATE_TYPE.DATE);
                    item.endDateStr = Helpers.parseFromDbDate(elem.end_date, DATE_TYPE.STRING);
                }

                item.nextSection = elem.next_section;
                item.presentation.id = elem.pre_id;

                arr.push(item);
            });

            if (arr.length > 0) {
                data.result = arr;
            } else {
                data.result = null;
            }

            return data;
        },
        function errorCallback(errorResponse) {
            Notifier.responseMessage(errorResponse.status);
            return $q.reject(errorResponse);
        });
    };

    /** Fetch user reviews for site location
     *
     * Method   - POST
     * Endpoint - /{type}/manage/{siteLocationUuid}/review
     * Params   -
     *       ** type - general type of review (e.g. "training", "rspp"
     *       ** siteLocationUuid - site location context for review data
     *       ** reviewIds - array of review ids
     *       ** employeeUuids - array of employee uuids
     *       ** dueDate - new due date
     * Response - Object with result about status of approval of document
     *
     * */
    this.manageEmployeeReview = function (type, siteLocationUuid, dueDate, reviewIds, employeeUuids, trainingFinished) {

        var ldata = {};
        var url = null;

        url = API_BASE + "/" + type + "/manage/"+siteLocationUuid+"/review";

        if (Array.isArray(reviewIds)) {
            ldata.reviews = reviewIds;
        }

        if (Array.isArray(employeeUuids)) {
            ldata.employeeUuids = employeeUuids;
        }

        if (dueDate != null) {
            ldata.dueDate = Helpers.formatDbDate(dueDate);
        }

        trainingFinished ? ldata.finish_trainings = 1 : ldata.finish_trainings = 0;

        return $http({
            url: url,
            method: "POST",
            data: ldata
        }).then(function successCallback(response) {
                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };
}
