import { TabsReducer } from '../../../../common/tabs/tabs.reducer';
import {IDS} from "../settings-action-id.constants";

export const sloRequiredDocsTabs = {
    [IDS.app_accreditation_scheme_settings_slo_required_docs]: Object.assign(TabsReducer(), {
        tabs: [
            {
                state: IDS.app_accreditation_scheme_settings_slo_required_docs_list,
                title: 'ACCREDITATION.SETTINGS.SLO_REQUIRED_DOCUMENTS.LIST.TAB',
                visible: true,
                disabled: false,
                loading: false,
            },
            {
                state: IDS.app_accreditation_scheme_settings_slo_required_docs_details,
                title: 'ACCREDITATION.SETTINGS.SLO_REQUIRED_DOCUMENTS.DETAILS.TAB',
                visible: false,
                disabled: false,
                loading: false,
            },
            {
                state: IDS.app_accreditation_scheme_settings_slo_required_docs_add,
                title: 'ACCREDITATION.SETTINGS.SLO_REQUIRED_DOCUMENTS.ADD.TAB',
                visible: false,
                disabled: false,
                loading: false,
            },
        ],
    }),
};
