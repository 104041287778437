import * as TYPE from '../../cre-vendors.actions.types';
import {
    getVendorWarrantyLicenceTypes,
    getVendorWarrantyDetails,
    saveVendorWarranty,
    deleteVendorWarranty,
} from '../../cre-vendors.actions';
import findIndex from 'lodash/findIndex';
import isNull from 'lodash/isNull';

/**
 * @const {object}
 * @memberOf module:Credentialing
 */
export const creVendorsWarranty = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $location, $mdDialog, DocumentManager, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.title = 'Warranty Information';
            this.unsubscribe = $ngRedux.connect(({ credentialing }) => ({
                creVendor: credentialing.creVendor,
            }), {
                getVendorWarrantyLicenceTypes,
                getVendorWarrantyDetails,
                saveVendorWarranty,
                deleteVendorWarranty,
            })(this);
            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.warranty`;
            this.token = $location.search().token;

            this.getVendorWarrantyLicenceTypes({
                token: this.token,
                lty_code: 'lgalty',
            });
            this.getVendorWarrantyLicenceTypes({
                token: this.token,
                lty_code: 'eoolty',
            });
            this.getVendorWarrantyLicenceTypes({
                token: this.token,
                lty_code: 'latype',
            });
        };

        this.onDocDownload = ({ doc_url }) => {
            DocumentManager.downloadFile(doc_url, 4);
        };

        this.onDocRemove = (doc) => {
            $ngRedux.dispatch({
                type: TYPE.CRE_VENDOR_DOC_REMOVE,
                id: 'creVendor',
                path: 'warranty_docs',
                idx: findIndex(this.creVendor.warranty_docs, doc),
            });
        };

        this.onSelect = ({ item }) => {
            this.getVendorWarrantyDetails({
                token: this.token,
                caw_id: item.caw_id,
            });
        };

        this.onReset = () => {
            $ngRedux.dispatch({
                type: TYPE.CRE_VENDOR_WARRANTY_RESET,
                id: 'creVendor',
            });
            this.creVendorsWarrantyForm.$setPristine();
            this.creVendorsWarrantyForm.$setUntouched();
        };

        this.onRemove = ({ item }) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen warranty will be permanently removed.')
                .ariaLabel('Remove warranty')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.deleteVendorWarranty({
                    token: this.token,
                    caw_id: item.caw_id,
                });
            }, angular.noop);
        };

        this.onSave = () => {
            if (this.creVendorsWarrantyForm.$valid) {
                this.saveVendorWarranty({
                    token: this.token,
                    application_id: this.creVendor.application_id,
                    caw_id: this.creVendor.caw_id,
                    caw_description: this.creVendor.caw_description,
                    swp: this.creVendor.swp,
                    is_aw_available: this.creVendor.is_aw_available,
                    is_ac_involved: this.creVendor.is_ac_involved,
                    ac_per_year: this.creVendor.ac_per_year,
                    aca: this.creVendor.aca,
                    are_you_res_for_as: this.creVendor.are_you_res_for_as,
                    asr_com_details: this.creVendor.asr_com_details,
                    does_item_req_ins_by_ur_com: this.creVendor.does_item_req_ins_by_ur_com,
                    do_emp_req_lic_by_lra: this.creVendor.do_emp_req_lic_by_lra,
                    emp_lra_lic_type_id: this.creVendor.emp_lra_lic_type_id,
                    emp_lic_iss_by_lra_name: this.creVendor.emp_lic_iss_by_lra_name,
                    do_emp_req_eoo_lic: this.creVendor.do_emp_req_eoo_lic,
                    emp_eoo_lic_type_id: this.creVendor.emp_eoo_lic_type_id,
                    does_your_org_req_al_to_rtg: this.creVendor.does_your_org_req_al_to_rtg,
                    org_al_type_id: this.creVendor.org_al_type_id,
                    other_rtp_this_item: this.creVendor.other_rtp_this_item,
                    warranty_docs: this.creVendor.warranty_docs,
                }).then(() => {
                    isNull(this.creVendor.caw_id) && this.onReset();
                    Notifier.success('Warranty Information has been successfully saved');
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
        }

        this.$onDestroy = () => {
            this.onReset();
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./cre-vendors-warranty.html')
};
