import {TAB_HIDE, TAB_DISABLE} from '../../../common/tabs/tabs.constants';
import {IDS} from './improvement-action-id.constants';

const QUERY_PARAMS = '?ado&year';

export const tabsState = {
    name: IDS.app_accreditation_scheme_improvement,
    redirectTo: IDS.app_accreditation_scheme_improvement_list,
    url: `/:itemCode/improvement${QUERY_PARAMS}`,
    component: 'tabs',
};

export const tabListState = {
    name: IDS.app_accreditation_scheme_improvement_list,
    url: '/list',
    component: 'improvementList',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: IDS.app_accreditation_scheme_improvement,
            state: [ IDS.app_accreditation_scheme_improvement_details ]
        })
    }
};
tabListState.onEnter.$inject = ['$ngRedux'];

export const tabDetailsState = {
    name: IDS.app_accreditation_scheme_improvement_details,
    url: `/details`,
    component: 'improvementDetails',
};

export const tabAddDocumentState = {
    name: IDS.app_accreditation_scheme_improvement_add,
    url: `/add`,
    component: 'improvementAdd',
};