import watch from 'redux-watch';
import isNumber from 'lodash/isNumber'
import get from 'lodash/get';

class GridListItemCheckboxController {
    constructor($ngRedux, $scope) {
        this.$ngRedux = $ngRedux;
        this.$scope = $scope;
        this.itemsSelect = [];
    }

    $onInit() {
        if (typeof this.disabled === 'undefined')
            this.disabled = false;

        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);

        const watchList = watch(this.$ngRedux.getState, this.getStateContainer('itemsSelect'));
        this.onListChange = this.$ngRedux.subscribe(watchList(this.init.bind(this)));

        this.init(this.itemsSelect);
    }


    init(items) {
        if (items.length) {
            if (items.indexOf(this.data) !== -1) {
                this.checkboxValue = 1;
            }
        }
        else this.checkboxValue = 0;
    }

    onSelect() {
        this.$scope.$emit('grid-checkbox-change', this.data, this.checkboxValue);
        this.$ngRedux.dispatch({
            type: this.checkboxValue ? 'GRID_SELECT' : 'GRID_DESELECT',
            id: this.identifier,
            path: this.statePath,
            data: isNumber(this.data) ? parseInt(this.data) : this.data
        });
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeModule();
        this.onListChange();
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        }
    }

    getStateContainer(endPath) {
        if(this.statePath)
            endPath = `${this.statePath}.${endPath}`;
        return [this.module, this.identifier, endPath].join('.');
    }

    mapState(state) {
        return {
            itemsSelect: get(state, this.getStateContainer('itemsSelect'))
        }
    }
}

GridListItemCheckboxController.$inject = ['$ngRedux', '$scope'];

export const gridListItemCheckbox = {
    bindings: {
        module: '@',
        identifier: '@',
        statePath: '@',
        label: '@',
        disabled: '@',
        data: '@'
    },
    controller: GridListItemCheckboxController,
    template: `
        <md-input-container class="md-block checkbox">
            <md-checkbox ng-model="$ctrl.checkboxValue"
                         ng-true-value="1"
                         ng-false-value="0"
                         ng-change="$ctrl.onSelect()"
                         ng-disabled="$ctrl.disabled"
                         aria-label="$ctrl.label">
                <span>{{$ctrl.label}}</span>
            </md-checkbox>
        </md-input-container>
    `
};
