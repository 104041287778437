/*
*   List of Action IDs that should be passed from accreditation.reducer.js to document.reducer.js
 */

export const IDS = {
    /* module */
    accMasterDocList: 'accMasterDocList',

    /* components: */
    accMasterDocListGrid:      'accMasterDocListGrid',
    accMasterDocListDetails:   'accMasterDocListDetails',
    accMasterDocListAdd:       'accMasterDocListAdd',

    /* paths: */
    documentDetails:                    'documentDetails',
    documentReviewsGrid:                'documentReviewsGrid',
    documentList:                       'documentList',
    documentListGrid:                   'documentListGrid',
    documentAddGrid:                    'documentAddGrid',

    /* tab states: */
    app_accreditation_scheme_master_doc_list:          'app.accreditation.scheme.master-doc-list',
    app_accreditation_scheme_master_doc_list_list:     'app.accreditation.scheme.master-doc-list.list',
    app_accreditation_scheme_master_doc_list_details:  'app.accreditation.scheme.master-doc-list.details',
    app_accreditation_scheme_master_doc_list_add:      'app.accreditation.scheme.master-doc-list.add',

    /* public tab states */
    public_accreditation_scheme_master_doc_list:       'public.accreditation.scheme.master-doc-list',
};
