export const reportingIsotopesActivityState = {
    name: 'app.reporting.isotopesActivity',
    redirectTo: 'app.reporting.isotopesActivity.listing',
    url: '/isotopes-activity',
    component: 'tabs',
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'GRID_RESET_FILTER',
            id: 'reportingIsotopesActivityGrid',
        });
        $ngRedux.dispatch({
            type: 'SHOW_LOADING',
            id: 'reportingIsotopesActivityGrid',
        });
    }
};
reportingIsotopesActivityState.onEnter.$inject = ['$ngRedux'];

export const reportingIsotopesActivityListingState = {
    name: 'app.reporting.isotopesActivity.listing',
    url: '',
    component: 'reportingIsotopesActivity'
};