/* @ngInject */
export const UserFactory = (UserCredentials, Account, Licence, Occupation) => {
    function User() {

        UserCredentials.call(this);

        this.uuid = null;
        this.email = null;
        this.phone = null;
        this.firstName = null;
        this.lastName = null;
        this.account = new Account();
        this.occupation = new Occupation();
        this.licence = new Licence();
        this.type = null;
        this.gender = null;
        this.birthdate = null;

        this.reset = function() {
            UserCredentials.reset();
        };
    }

    User.prototype = Object.create(UserCredentials.prototype);

    return User;
};
