export function AccAuditCheckListItemFactory() {
    return function AccAuditCheckListItem() {

        this.id = null; //aaci_id
        this.checklistId = null; //aac_id
        this.request = null;
        this.relatedStandards = null;
        this.parentItem = null; //id of parent question/request
        this.hasValue = null; //if item is answerable
        this.status = null; //current status (ACC_AUDIT_CHECKLIST_ITEM_STATUS)
        this.actionRequired = null; //description of required action
    };
}
