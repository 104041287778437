/* @ngInject */
export function SubjectTypesService($http, API_BASE, SubjectType, LocationDepartmentType, Func) {

    function appendTransform(defaults, transform) {
        defaults = angular.isArray(defaults) ? defaults : [defaults];
        return defaults.concat(transform);
    }

    this.fetchSubjectTypes = function(parent_id, container_id, is_direct, offset, limit) {
        return $http({
            url: API_BASE + "/subject/types",
            method: "GET",
            params: {
                parent: parent_id,
                container: container_id,
                direct: is_direct,
                offset: offset,
                limit: limit
            }
        }).then(function(data) {
            var arr = [];
            data.data.forEach(function(elem) {
                var st = new SubjectType();
                st.id = elem.sty_id;
                st.code = elem.code;
                st.name = elem.name;
                st.par_id = elem.par_id;
                st.level = elem.level;
                st.abs_level = elem.abs_level;
                st.is_user = elem.is_user;
                arr.push(st);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    this.fetchFuncsForSubjectType = function(subjectTypeId) {
        return $http({
            url: API_BASE + "/subject/" + subjectTypeId + "/functions",
            method: "GET"
        }).then(function(data) {
            var arr = [];
            data.data.forEach(function(elem){
                var func = new Func();
                func.id = elem.fun_id;
                func.code = elem.code;
                func.name = elem.name;
                func.par_id = elem.par_id;
                func.desc = elem.description;
                arr.push(func);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    this.removeFunc = function(funcId, subjectTypeId) {
        return $http({
            url: API_BASE + "/functions/assignment",
            method: "DELETE",
            params: {
                function: funcId,
                type: subjectTypeId
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.assignFunc = function(funcId, subjectTypeId) {
        return $http({
            url: API_BASE + "/functions/assignment",
            method: "PUT",
            params: {
                function: funcId,
                type: subjectTypeId
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.addSubjectType = function(parId, containerId, name) {
        return $http({
            url: API_BASE + "/subject/type",
            method: "POST",
            data: {
                parent: parId,
                container: containerId,
                name: name
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.addCustomerOccupation = function(containerId, name) {
        return $http({
            url: API_BASE + "/subject/occupation",
            method: "POST",
            data: {
                container: containerId,
                name: name
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.fetchDepartmentTypes = function(uuid) {
        return $http({
            url: API_BASE + "/department/types?uuid=" + uuid,
            method: "GET"
        }).then(function(response) {
            return response.data.map(function(item) {
                var departmentType  = new LocationDepartmentType();
                departmentType.id   = item.sty_id;
                departmentType.name = item.name;
                return departmentType;
            });
        });
    };
}
