import * as TYPE from '../components/applications-permits/applications-permits.actions.types';
import applicationsPermitsReducer from '../components/applications-permits/applications-permits.reducer';
import { tabs } from '../components/applications-permits/applications-permits.tabs';
import { TabsResolver } from '../common/tabs/tabs.resolver';
import { GridReducer } from '../common/grid/grid.reducer';
import { resolveSignature } from '../common/utils/util.data';
import set from 'lodash/set';
import find from 'lodash/find';

const initialState = {
    ...applicationsPermitsReducer
};

export default (state = initialState, action) => {
    if (Object.keys(tabs).includes(action.id)) {
        return TabsResolver(state, action);
    }
    switch (action.type) {
        case 'INPUT_TEXT_CHANGE':
        case 'INPUT_EMAIL_CHANGE':
        case 'INPUT_PHONE_CHANGE':
        case 'INPUT_RADIO_CHANGE':
        case 'INPUT_SELECT':
        case 'DATE_SELECTED':
        case 'CHECKBOX_SELECTED':
        case 'FILE_UPLOAD':
        case 'INPUT_SIGNATURE':
            if (action.id === 'apInvite' || action.id === 'apLaserRequest') {
                const qualification = {
                    psm: 'Area of Speciality',
                    spl: 'Qualification',
                    dcp: 'Qualification',
                };
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.input,
                        ...(action.path === 'qualification_code'
                            ? { qualification_title: action.item.label, qualification_code_subtype: null }
                            : {})
                    },
                    apRequest: {
                        ...state.apRequest,
                        ...(action.path === 'qualification_type_code'
                            ? { qualification: qualification[action.input] }
                            : {}),
                    }
                }
            }
            if (action.id === 'apLaserRequestGrid') {
                return {
                    ...state,
                    [action.id]: set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)
                }
            }
        case 'LOCATION_SELECTED':
            if (action.id === 'apInvite') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        location: action.uuid
                    }
                }
            }
            if (action.id === 'apLaserRequestEquipmentGrid') {
                const equipmentGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...equipmentGrid,
                        filter: {
                            ...equipmentGrid.filter,
                            location: action.location
                        }
                    },
                }
            }
        case TYPE.AP_REQUEST_TOKEN:
            if (action.id === 'apLaserRequest') {
                return {
                    ...state,
                    apLaserRequest: {
                        ...state.apLaserRequest,
                        token: action.token,
                        invitation_id: action.invite
                    }
                }
            }
        case TYPE.AP_INVITATION_RESET:
            return {
                ...state,
                apInvite: initialState.apInvite
            }
        case TYPE.AP_REQUEST_TYPES:
        case TYPE.AP_PERMIT_HOLDERS:
        case TYPE.AP_CONTACT_PERSONS:
        case TYPE.AP_REQUEST_STATUSES:
            if (action.id === 'apRequest' || 'apInvite') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.items
                    }
                };
            }
        case TYPE.AP_APPLICANT_QUALIFICATION_TYPES:
        case TYPE.AP_APPLICANT_QUALIFICATIONS:
        case TYPE.AP_APPLICANT_SPECIALITIES:
            if (action.id === 'apRequest') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.items
                    },
                    apLaserRequest: {
                        ...state.apLaserRequest,
                        ...(!state.apLaserRequest.id && !state.apLaserRequest.pap_id && {
                            qualification_code: null,
                            qualification_code_subtype: null,
                            qualification_title: null
                        })
                    }
                };
            }
        case TYPE.AP_APPLICANT_SPECIALITY_ADD:
            if (action.id === 'apRequest') {
                return {
                    ...state,
                    apRequest: {
                        ...state.apRequest,
                        qualifications: [
                            ...state.apRequest.qualifications,
                            {
                                value: `new${state.apRequest.qualifications.length}`,
                                label: action.input,
                                subtype: 1
                            }
                        ]
                    },
                    apLaserRequest: {
                        ...state.apLaserRequest,
                        qualification_code: `new${state.apRequest.qualifications.length}`,
                        qualification_title: action.input
                    }
                };
            }
        case TYPE.AP_REQUEST_EQUIPMENT:
            if (action.id === 'apLaserRequestEquipmentGrid') {
                if (find(state.apLaserRequest.equipment, ['id', action.item.id])) {
                    return {
                        ...state,
                        apLaserRequest: {
                            ...state.apLaserRequest,
                            equipment: state.apLaserRequest.equipment.filter((item) => item.id !== action.item.id)
                        }
                    };
                } else {
                    return {
                        ...state,
                        apLaserRequest: {
                            ...state.apLaserRequest,
                            equipment: [...state.apLaserRequest.equipment, action.item]
                        }
                    };
                }
            }
        case TYPE.AP_REQUEST_RESET:
            return {
                ...state,
                apLaserRequest: initialState.apLaserRequest
            }
        case TYPE.AP_REQUEST_DETAILS:
            if (action.id === 'apLaserRequest') {
                return {
                    ...state,
                    apLaserRequest: {
                        token: null,
                        id: action.data.request_id,
                        title: action.data.title,
                        first_name: action.data.first_name,
                        last_name: action.data.last_name,
                        email: action.data.email,
                        phone: action.data.phone,
                        phone_ext: action.data.phone_ext,
                        qualification_type_code: action.data.qualification_type,
                        qualification_code: action.data.qualification_code,
                        qualification_code_subtype: action.data.qualification_code_subtype,
                        qualification_title: action.data.qualification_title,
                        qualification_doc_uri: action.data.qualification_doc_uri,
                        training_title: action.data.training_title,
                        training_doc_uri: action.data.training_doc_uri,
                        training_completion: action.data.training_doc_uri ? 1 : 0,
                        applicant_signature: resolveSignature(action.data.applicant_signature),
                        applicant_signature_date: action.data.applicant_signature_date,
                        presentation_signature: resolveSignature(action.data.presentation_signature),
                        presentation_end_date: action.data.presentation_end_date,
                        approval_officer_signature: resolveSignature(action.data.signature),
                        contact_person_uuid: action.data.contact_person_uuid,
                        approval_date: action.data.request_status_date
                    }
                }
            }
        case 'GRID_INIT':
        case 'GRID_RESET':
            if (action.id === 'apLaserRequestGrid') {
                const requestGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...requestGrid,
                        filter: {
                            ...requestGrid.filter,
                            keyword: null,
                            status: null,
                            renewalFrom: null,
                            renewalTo: null
                        }
                    },
                    apRequest: {
                        ...state.apRequest,
                        renewal30Days: 0,
                        renewal60Days: 0,
                        renewal90Days: 0
                    }
                }
            }
            if (action.id === 'apLaserRequestEquipmentGrid') {
                const requestGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...requestGrid,
                        filter: {
                            ...requestGrid.filter
                        }
                    },
                }
            }
            if (action.id === 'apLaserRequestEquipmentAuthGrid') {
                const equipmentGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...equipmentGrid,
                        filter: {
                            ...equipmentGrid.filter
                        }
                    },
                }
            }
            if (action.id === 'apLaserRequestTrainingsGrid') {
                const trainingsGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...trainingsGrid,
                        filter: {
                            ...trainingsGrid.filter
                        }
                    },
                }
            }
        case 'SHOW_LOADING':
        case 'GRID_FETCH':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_RESET_FILTER':
            if ([
                'apLaserRequestGrid',
                'apLaserRequestEquipmentGrid',
                'apLaserRequestEquipmentAuthGrid',
                'apLaserRequestTrainingsGrid',
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: GridReducer(state[action.id], action)
                }
            }
        case TYPE.AP_REQUEST_FILTER_RENEWAL:
            if (action.id === 'apRequest') {
                if (action.data.reset) {
                    return {
                        ...state,
                        apLaserRequestGrid: {
                            ...state.apLaserRequestGrid,
                            filter: {
                                ...state.apLaserRequestGrid.filter,
                                renewalFrom: null,
                                renewalTo: null
                            }
                        },
                        apRequest: {
                            ...state.apRequest,
                            renewal30Days: 0,
                            renewal60Days: 0,
                            renewal90Days: 0
                        }
                    };
                } else {
                    return {
                        ...state,
                        apLaserRequestGrid: {
                            ...state.apLaserRequestGrid,
                            filter: {
                                ...state.apLaserRequestGrid.filter,
                                ...action.filter
                            },
                        },
                        apRequest: {
                            ...state.apRequest,
                            ...action.data
                        }
                    };
                }
            }
        case TYPE.AP_INVITATION_DETAILS:
            if (action.id === 'apLaserRequest') {
                return {
                    ...state,
                    apLaserRequest: {
                        ...state.apLaserRequest,
                        invitation_type_code: action.data.invitation_type_code,
                        title: action.data.title,
                        first_name: action.data.first_name,
                        last_name: action.data.last_name,
                        email: action.data.email,
                        pap_id: action.data.pap_id,
                        sta_id: action.data.sta_id,
                        phone: action.data.phone,
                        phone_ext: action.data.phone_ext,
                        qualification_type_code: action.data.qualification_type,
                        input: action.data.qualification_type,
                        qualification_id: action.data.qua_id,
                        qualification_code: action.data.qualification_code,
                        qualification_code_subtype: action.data.qualification_code_subtype,
                        qualification_title: action.data.qualification_title,
                        qualification_doc_uri: action.data.qualification_doc_uri,
                        training_title: action.data.training_title,
                        training_doc_uri: action.data.training_doc_uri,
                        training_completion: action.data.training_doc_uri ? 1 : 0,
                        applicant_signature: resolveSignature(action.data.applicant_signature),
                        applicant_signature_date: action.data.applicant_signature_date,
                        presentation_signature: resolveSignature(action.data.presentation_signature),
                        presentation_end_date: action.data.presentation_end_date,
                        approval_officer_signature: resolveSignature(action.data.signature),
                        contact_person_uuid: action.data.contact_person_uuid
                    }
                }
            }
        case TYPE.AP_RESET_PERMIT_HOLDER:
            if (action.id === 'apInvite') {
                return {
                    ...state,
                    apInvite: {
                        ...state.apInvite,
                        permit_holder_uuid: null
                    }
                }
            }
        default:
            return state;
    }
}
