import {
    getApplicantQualificationTypes,
    getApplicantQualifications,
    getApplicantSpecialities,
    getApprovedContactPersons,
    getPermitInvitationDetails
} from '../../applications-permits.actions';
import { AP_APPLICANT_SPECIALITY_ADD } from '../../applications-permits.actions.types';
import { submitLaserRequest } from '../ap-laser-request.actions';
import { formatDate } from '../../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../../common/utils/util.constants';
import i18n from '../../i18n/en.json';

/**
 * @const {object}
 * @memberOf module:ApplicationsPermits
 */
export const apLaserRequestDetails = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $mdDialog, DocumentManager, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.details`;

            this.trainingTitle = i18n[this.type].details.trainingTitle;
            this.trainingCompletion = i18n[this.type].details.trainingCompletion;
            this.trainingInstructions = i18n[this.type].details.trainingInstructions;

            this.unsubscribe = $ngRedux.connect(({ applicationsPermits }) => ({
                apLaserRequest: applicationsPermits.apLaserRequest,
                applicant_qualification: applicationsPermits.apRequest.qualification,
                applicant_signature_date: formatDate(applicationsPermits.apLaserRequest.applicant_signature_date, DATE_FORMAT.OUTPUT)
            }), {
                getApplicantQualificationTypes,
                getApplicantQualifications,
                getApplicantSpecialities,
                submitLaserRequest,
                getApprovedContactPersons,
                getPermitInvitationDetails
            })(this);

            if (this.apLaserRequest.id) {
                this.getApprovedContactPersons(this.apLaserRequest.id);
            } else {
                this.getApprovedContactPersons();
                this.getPermitInvitationDetails().then(() => {
                    if (this.apLaserRequest.qualification_type_code) {
                        this.onQualificationType(this.apLaserRequest);
                    }
                });
            }
            this.qualificationSubtype = null;

            this.getApplicantQualificationTypes();
        }

        this.onQualificationType = ({ input }) => {
            this.qualificationSubtype = null;
            switch (input) {
                case 'psm':
                    this.getApplicantSpecialities();
                    break;
                case 'spl':
                    this.getApplicantQualifications();
                    break;
                case 'dcp':
                    this.getApplicantQualifications();
                    break;
            }
        };

        this.onQualification = ({ item }) => {
            this.qualificationSubtype = item.subtype;

            if (item.value === 'new') {
                this.dialog = $mdDialog.show({
                    /* @ngInject */
                    controller: ($scope) => {
                        $scope.save = () => {
                            if ($scope.dialogForm.$valid) {
                                $ngRedux.dispatch({
                                    type: AP_APPLICANT_SPECIALITY_ADD,
                                    id: 'apRequest',
                                    input: $scope.specialityName
                                });
                                $mdDialog.hide();
                            }
                        };
                        $scope.cancel = () => $mdDialog.cancel();
                    },
                    template: require('./dialogs/ap-laser-request-speciality-dialog.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: true
                }).then(() => {}, () => {
                    $ngRedux.dispatch({
                        type: 'INPUT_SELECT',
                        id: 'apLaserRequest',
                        path: 'qualification_code',
                        input: null,
                        item: { label: null }
                    });
                });
            }
        };

        this.download = (uri) => {
            DocumentManager.downloadFile(this.apLaserRequest[uri]);
        };

        this.onSubmit = () => {
            if (this.apLaserRequestDetails.$valid) {
                Notifier.error('Development in progress...');
            } else {
                Notifier.error('Please fill in the form fields');
            }
        }

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./ap-laser-request-details.html')
};
