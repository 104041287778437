import { IDS } from '../../settings-action-id.constants';
import { TAB_SELECT, TAB_SHOW } from '../../../../../common/tabs/tabs.constants'
import * as SettingsActions from "../../settings.actions";
import * as Actions from '../default-documents.actions';
import {TYPES} from "../../settings-action-type.constants";
import {GRID_RELOAD} from "../../../../../common/grid/grid.constants";
import get from "lodash/get";

/**
 * Creates a new DefaultDocumentAddController.
 * @class
 */
class DefaultDocumentAddController {
    constructor($ngRedux, $state, $stateParams, $scope, $rootScope, $filter, AuthService, Notifier) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.stateName = $state.$current.name;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.AuthService = AuthService;
        this.Notifier = Notifier;
    }

    $onInit() {
        this.identifier = IDS.accSettings;
        this.parentState = IDS.app_accreditation_scheme_settings_default_documents;

        this.tabState = IDS.app_accreditation_scheme_settings_default_documents_add;
        this.title = this.$filter('translate')(`ACCREDITATION.SETTINGS.DEFAULT_DOCUMENT_ADD.TAB`);
        this.unsubscribe = this.$ngRedux.connect(state => {
            return {
                document:                   state.accreditation.accSettings.defaultDocumentDetails,
                schemeName:                 state.accreditation.accSettings.schemeDetails.title,
                standardName:               state.accreditation.accSettings.sectionDetails.nodeName,
                standard_id:                state.accreditation.accSettings.sectionDetails.id,
            }
        }, {
            ...SettingsActions,
            ...Actions,
        })(this);

        this.schemeCode = this.$stateParams.itemCode;
        this.uploadSaveBtnDisabled = false;
    }

    onSubmit() {
        this.$scope.$broadcast('show-errors-check-validity', '$ctrl.defaultDocumentAddForm');
        if (this.defaultDocumentAddForm.$invalid) {
            this.Notifier.error('COMMON.FIELDS_REQUIRED');
            return;
        }
        this.addDefaultStandardRequiredDoc({
            document_type:  this.document.document_type,
            name:           this.document.name,
            description:    this.document.description,
            ast_id:         this.standard_id,
            // path:           event[0].filePath,
        }).then( () => {
            this.$ngRedux.dispatch({
                type: GRID_RELOAD,
                id: IDS.accSettings,
                path: IDS.defaultDocumentsGrid,
            });
            this.$ngRedux.dispatch({
                type: TAB_SELECT,
                id: IDS.app_accreditation_scheme_settings_default_documents,
                state: IDS.app_accreditation_scheme_settings_default_documents_list,
            });
        })
    }

    $onDestroy() {
        this.unsubscribe();
    }

}

DefaultDocumentAddController.$inject = ['$ngRedux', '$state', '$stateParams', '$scope', '$rootScope', '$filter', 'AuthService', 'Notifier']

export const defaultDocumentAdd = {
    controller: DefaultDocumentAddController,
    template: require('./default-document-add.html')
}
