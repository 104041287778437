/* @ngInject */
export function NetSpeedService($http, $q) {

    var testPictureUrl = "img/test-bandwidth.png";
    var downloadSize = 1630327; //bytes

    this.testNetSpeed = function() {

        var startTime, endTime;
        startTime = (new Date()).getTime();

        return $http.get(testPictureUrl, {
            responseType: 'blob'
        }).then(function(results) {
            var data = results.data;
            if (data.size !== downloadSize) {
                return $q.reject("There was error in the process of measuring net speed");
            }
            var blob = new Blob([data], {type: "image/png"});

            endTime = (new Date()).getTime();
            var duration = (endTime - startTime) / 1000;
            var bitsLoaded = downloadSize * 8;
            var speedBps = (bitsLoaded / duration).toFixed(2);
            var speedKbps = (speedBps / 1024).toFixed(2);
            return speedKbps;
        }, function(error) {
            return $q.reject(error);
        });
    };
}
