/** @namespace Reducers.SafetyTraining */
import * as TYPE from '../components/safety-training/safety-training.actions.types';
import safetyTrainingReducer from '../components/safety-training/safety-training.reducer';
import { tabs } from '../components/safety-training/safety-training.tabs';
import { TabsResolver } from '../common/tabs/tabs.resolver';

const initialState = {
    ...safetyTrainingReducer
};

/**
 * @function safetyTrainingReducer
 * @param state {object}
 * @param action {object}
 * @memberOf Reducers
 */
export default (state = initialState, action) => {
    if (Object.keys(tabs).includes(action.id)) {
        return TabsResolver(state, action);
    }
    switch (action.type) {
        case 'DATE_SELECTED':
        case 'INPUT_SIGNATURE':
            if (action.id === 'stVideo') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.input,
                    }
                }
            }
        case TYPE.ST_VIDEOS:
            if (action.id === 'stVideo') {
                return {
                    ...state,
                    stVideo: {
                        ...state.stVideo,
                        trainings: action.items
                    }
                };
            }
        case TYPE.ST_CANDIDATE_REVIEW:
            if (action.id === 'stVideo') {
                return {
                    ...state,
                    stVideo: {
                        ...state.stVideo,
                        candidateReview: action.item
                    }
                };
            }
        case TYPE.ST_VIDEO_PROGRESS:
            if (action.id === 'stVideo') {
                return {
                    ...state,
                    stVideo: {
                        ...state.stVideo,
                        completion: action.completion,
                        progress: action.progress,
                    }
                }
            }
        case TYPE.ST_REQUEST_TOKEN:
            if (action.id === 'stVideo') {
                return {
                    ...state,
                    stVideo: {
                        ...state.stVideo,
                        token: action.token,
                        training_id: action.training
                    }
                }
            }
        default:
            return state;
    }
}
