import API from '../../../../../common/http/api.fetch';
import {success, responseMessage} from '../../../../../common/utils/util.notifier';
import * as GRID from '../../../../../common/grid/grid.constants';
import I18N from '../../../../../common/utils/util.i18n';
import { IDS } from '../../settings-action-id.constants';
import { TYPES } from '../../settings-action-type.constants';
import {TAB_SHOW} from '../../../../../common/tabs/tabs.constants';

export const getNotEnabledHeadOffices = (schemeId) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/scheme-locations',
            method: 'GET',
            params: {
                asc_id: schemeId,
                only_not_enabled_hofs: 'true',
            }
        }).then(response => {
            dispatch({
                type: TYPES.FETCH_NOT_ENABLED_HEAD_OFFICES,
                id: IDS.accreditationSettingsHeadOffices,
                items: response.data.map(item => {
                    return {
                        value: item.hof_uuid,
                        label: item.hof_name,
                        item: {
                            name:           item.hof_name,
                            subject_uuid:   item.hof_uuid,
                        }
                    }
                })
            })
            dispatch({
                type: TYPES.SELECTED_HEAD_OFFICE_RESET,
                id: IDS.accreditationSettingsHeadOffices
            })
        })
    }
}

export const assignSchemeToSiteLocation = (schemeId, siteLocationUuid) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/assign-scheme-locations',
            method: 'POST',
            data: {
                loc_uuid: siteLocationUuid,
                asc_id: schemeId
            },
        }).then(response => {
            dispatch({
                type: GRID.GRID_RELOAD,
                id: IDS.accSettings,
                path: IDS.headOfficesGrid,
            });
            success(I18N.translate('COMMON.SERVER_RESPONSE.ITEM_ENABLED'));
        }, error => {
            responseMessage(error)
        })
    }
}

export const listHeadOfficesByScheme = (filter) => {
    return  dispatch => {
        return API.fetch({
            url: '/accreditation/scheme-locations',
            method: 'GET',
            params: {
                ...filter,
                asc_id: filter.schemeId,
                schemeId: undefined,
            }
        }).then(response => {
            dispatch({
                type:   GRID.GRID_FETCH,
                id:     IDS.accSettings,
                path:   IDS.headOfficesGrid,
                items:  response.data.items,
                itemsCount: response.data.items_count
            })
        }, error => {
            responseMessage(error);
        })
    }
}

export const detachSiteLocationFromScheme = (data) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/resign-scheme-locations',
            method: 'POST',
            data: {
                asc_id: data.schemeId,
                loc_uuid: data.slo_uuid
            }
        }).then(() => {
            dispatch({
                type: GRID.GRID_RELOAD,
                id: IDS.accSettings,
                path: IDS.headOfficesGrid,
            })
            success(I18N.translate('COMMON.SERVER_RESPONSE.ITEM_DISABLED'));
        }, error => {
            responseMessage(error);
        })
    }
}

export const showSiteLocationsTab = (headOffice) => {
    return dispatch => {
        dispatch({
            type: TYPES.SET_SITE_LOCATIONS_HEADER,
            id: IDS.accSettings,
            data: {
                headOfficeName: headOffice.slo_name
            }
        })
        dispatch({
            type: GRID.GRID_FILTER,
            id: IDS.accSettings,
            path: IDS.siteLocationsGrid,
            filter: {hof_uuid: headOffice.slo_uuid},
        });
        dispatch({
            type: TAB_SHOW,
            id: IDS.app_accreditation_scheme_settings_enabled,
            state: [IDS.app_accreditation_scheme_settings_enabled_site_locations],
        });
    }
}
