import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';
import { getRequestEquipment } from '../../ap-laser-request.actions';

class ApplicationsPermitsLaserRequestEquipmentGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'applicationsPermits';
        this.identifier = 'apLaserRequestEquipmentGrid';
        this.filterTemplate = './ap-laser-request-equipment-grid-filter.html';
        this.autoload = false;
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                mapper: null},
            {title: 'Equipment ID',     mapper: 'equipment_id'},
            {title: 'Type',             mapper: 'type'},
            {title: 'Manufacturer',     mapper: 'manufacturer'},
            {title: 'Model',            mapper: 'model'},
            {title: 'Serial Number',    mapper: 'serial'},
            {title: 'Class',            mapper: 'laser_class'},
            {title: 'Site Location',    mapper: 'site_location'},
            {title: 'Department',       mapper: 'department'},
            {title: 'Room Location',    mapper: 'room'},
        ];

        this.buttons = [
            {
                label: ({ selected }) => selected ? 'Unselect' : 'Select',
                class: ({ selected }) => selected ? 'btnGridRemove' : 'btnGridModify',
                callback: this.onSelect
            }
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), { getRequestEquipment }
        )(this);

        this.dataAction = this.getRequestEquipment;
        this.exportService = this.getRequestEquipment;
        this.filter.identifier = this.identifier;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const apLaserRequestEquipmentGrid = {
    bindings: {
        type: '@',
        title: '@',
        onSelect: '&'
    },
    controller: ApplicationsPermitsLaserRequestEquipmentGridController,
    ...gridTpl
};
