import { getRequestDetails, submitVendorApplication } from '../cre-vendors.actions';
import { TAB_SHOW, TAB_HIDE } from '../../../../common/tabs/tabs.constants';

/**
 * @const {object}
 * @memberOf module:Credentialing
 */
export const creVendorsIntro = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $location, $mdDialog, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.title = 'Credentialing - Vendor Application';
            this.unsubscribe = $ngRedux.connect(({ credentialing }) => ({
                creInvite: credentialing.creInvite,
                creVendor: credentialing.creVendor,
            }), {
                getRequestDetails,
                submitVendorApplication,
            })(this);
            this.identifier = $state.$current.parent.name;
            this.state = $state.$current.name;

            this.token = $location.search().token;
            this.invite = $location.search().invite;
            this.status = null;

            this.getRequestDetails({
                token: this.token,
                application_id: this.invite,
            }).then(({ token_status_code, request_status_code }) => {
                this.status = token_status_code || request_status_code;
                if (['sen', 'pro', 'rej'].includes(this.status)) {
                    this.status = 'ok';
                    $ngRedux.dispatch({
                        type: TAB_SHOW,
                        id: this.identifier,
                        state: [`${this.identifier}.intro`],
                        stateParams: {
                            token: this.token,
                            invite: this.invite,
                        },
                    });
                } else {
                    lockRequest();
                }
            }).catch(() => {
                // TODO
            });
        }

        this.onSubmit = () => {
            if (this.creSubmitForm.$valid) {
                const confirm = $mdDialog.confirm()
                    .title('Are you ready?')
                    .textContent('Before submitting the application please make sure all the sections are completed.')
                    .ariaLabel('Submit application')
                    .ok('Yes')
                    .cancel('No');
                this.dialog = $mdDialog.show(confirm).then(() => {
                    this.submitVendorApplication({
                        purpose: 'venapp',
                        application_id: this.creVendor.application_id,
                        applicant_signature: this.creInvite.applicant_signature,
                    }).then(() => {
                        this.status = 'sub';
                        Notifier.success('Vendor application has been successfully submitted!');
                        lockRequest();
                    });
                }, angular.noop);
            }
        };

        const lockRequest = () => {
            $ngRedux.dispatch({
                type: TAB_HIDE,
                id: this.identifier,
                state: [
                    `${this.identifier}.details`,
                    `${this.identifier}.accounts`,
                    `${this.identifier}.finance`,
                    `${this.identifier}.insurance`,
                    `${this.identifier}.accreditationStandards`,
                    `${this.identifier}.goods`,
                    `${this.identifier}.warranty`,
                    `${this.identifier}.services`,
                ],
            });
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./cre-vendors-intro.html')
};
