import InputController from '../input.controller';
import { INPUT_SELECT } from '../input.constants';
import { COLLECTION_ADD, COLLECTION_UPDATE } from '../../../component/component.constants';
import get from 'lodash/get';
import find from 'lodash/find';
import isNull from 'lodash/isNull';

/**
 * Creates a new InputSelectController.
 * @class
 * @extends module:root/common/form.InputController
 * @memberOf module:root/common/form
 * @see {@link https://docs.angularjs.org/guide/component|AngularJS Component}
 */
export class InputSelectController extends InputController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $element
     * @param $filter
     */
    constructor($ngRedux, $element, $filter) {
        super($ngRedux);
        this.$element = $element;
        this.$filter = $filter;
        this.ICON_RESET = 'remove.svg';
    }

    /**
     * @method $onInit
     * @memberof module:root/common/form.InputSelectController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.init();

        if (typeof this.stateData === 'undefined')
            throw new Error('Missing select state-data attribute');

        if (typeof this.stateDataModule === 'undefined')
            this.stateDataModule = this.module;

        if (typeof this.stateDataIdentifier === 'undefined')
            this.stateDataIdentifier = this.identifier;

        if (typeof this.statePath === 'undefined')
            this.statePath = this.statePathSingle;

        if (typeof this.reset === 'undefined')
            this.reset = false;

        if (typeof this.allowNull === 'undefined')
            this.allowNull = false;

        if (typeof this.validate === 'undefined')
            this.validate = false;

        if (typeof this.disabled === 'undefined')
            this.disabled = true;

        this.actionType = INPUT_SELECT;
        this.unsubscribeData = this.$ngRedux.connect(this.mapData.bind(this), {})(this);

        if (!this.reset && this.$element) {
            this.$element.find('md-input-container').removeClass('md-icon-right');
        }
    }

    /**
     * @method onInputChange
     * @memberof module:root/common/form.InputSelectController
     */
    onInputChange() {
        let dispatch = isNull(this.input) && this.allowNull;
        if (!dispatch) dispatch = this.input;
        if (dispatch) {
            const option = find(this.options, ['value', this.input]);
            this.$ngRedux.dispatch({
                type: this.getActionType(),
                id: this.identifier,
                path: this.statePath,
                index: this.stateIndex,
                prop: this.stateProp,
                input: this.input,
                data: {[this.statePath]: this.input},
                // TODO check if this is in use
                item: get(option, 'item', option),
                option
            });

            if (this.onChange) {
                this.onChange({
                    $event: {
                        input: this.input,
                        item: option
                    }
                });
            }
        }
    }

    /**
     * @method onInputReset
     * @memberof module:root/common/form.InputSelectController
     */
    onInputReset() {
        this.$ngRedux.dispatch({
            type: this.actionType,
            id: this.identifier,
            path: this.statePath,
            index: this.stateIndex,
            prop: this.stateProp,
            input: null
        });

        if (this.onReset) {
            this.onReset({
                $event: {input: this.input}
            });
        }
    }

    /**
     * @method getActionType
     * @memberof module:root/common/form.InputSelectController
     */
    getActionType() {
        if (this.collection) {
            if (typeof get(this.$ngRedux.getState(), this.getPath()) === 'undefined')
                this.actionType = COLLECTION_ADD;
            else
                this.actionType = COLLECTION_UPDATE;
        }
        return this.actionType;
    }

    /**
     * @method getClass
     * @return {object}
     * @memberof module:root/common/form.InputSelectController
     * @see {@link https://docs.angularjs.org/api/ng/directive/ngClass|AngularJS ngClass}
     */
    getClass() {
        return {
            'md-block': true,
            'md-icon-right-disable': !this.reset
        }
    }

    /**
     * @method $onDestroy
     * @memberof module:root/common/form.InputSelectController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.unsubscribeData();
        this.destroy();
    }

    /**
     * @method mapData
     * @param state {object} Redux Store
     * @return {object}
     * @memberof module:root/common/form.InputSelectController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapData(state) {
        return {
            /** @member {array} module:root/common/form.InputSelectController#options */
            options: this.getDataValue(state),
            input: this.getInput(state)
        }
    }

    /**
     * @method getDataValue
     * @param state {object} Redux Store
     * @return {*}
     * @memberof module:root/common/form.InputSelectController
     */
    getDataValue(state) {
        let dataValue = get(state, this.getDataPath());
        if(this.translateOptions === 'true') {
            dataValue = dataValue.map(option => {
                return {
                    ...option,
                    label: this.$filter('translate')(option.label)
                }
            });
        }
        if(this.selectFirstItem === 'true' && this.input !== -1 && dataValue && dataValue.length
        && dataValue.find(option => option.value === this.input) === undefined) {
            this.input = dataValue[0].value;
        }
        return dataValue;
    }

    getInput(state) {
        if(this.preselect === 'true')
            return get(state, [this.stateDataModule, this.identifier, this.statePath].join('.'));
        else
            return this.input;
    }

    /**
     * @method getDataPath
     * @return {string}
     * @memberof module:root/common/form.InputSelectController
     */
    getDataPath() {
        if (typeof this.stateDataProp !== 'undefined' && this.stateDataProp !== '')
            return [this.stateDataModule, this.stateDataIdentifier, this.stateData + `[${this.stateIndex}]`, this.stateDataProp].join('.');

        if (typeof this.stateDataIndex !== 'undefined')
            return [this.stateDataModule, this.stateDataIdentifier, this.stateData + `[${this.stateIndex}]`].join('.');

        return [this.stateDataModule, this.stateDataIdentifier, this.stateData].join('.');
    }
}

InputSelectController.$inject = ['$ngRedux', '$element', '$filter'];

export const inputSelect = {
    bindings: {
        identifier: '@',
        statePath: '@',
        statePathSingle: '@',
        stateIndex: '<',
        stateProp: '@',
        stateData: '@',
        stateDataProp: '@',
        stateDataModule: '@',
        stateDataIdentifier: '@',
        translateOptions: '@',
        preselect: '@',
        selectFirstItem: '@',
        label: '@',
        name: '@',
        reset: '@',
        collection: '@',
        allowNull: '@',
        validate: '<',
        required: '<',
        disabled: '<',
        onChange: '&',
        onReset: '&'
    },
    require: {
        /**
         * @member {*} module:root/common/form.InputSelectController#form
         * @see {@link https://docs.angularjs.org/api/ng/type/form.FormController|FormController}
         */
        form: '^form'
    },
    controller: InputSelectController,
    template: `
        <div layout-gt-xs="row">
            <md-input-container ng-class="$ctrl.getClass()" flex>
                <label>{{$ctrl.label}}</label>
                <md-select name="{{$ctrl.name}}"
                           ng-model="$ctrl.input"
                           md-on-close="$ctrl.onInputChange()"
                           ng-required="$ctrl.required"
                           ng-disabled="$ctrl.disabled">
                    <md-option ng-value="item.value" ng-repeat="item in $ctrl.options" ng-disabled="{{item.disabled}}">
                        <span class="md-body-2">{{item.label}}</span>
                    </md-option>
                </md-select>
                <asset ng-if="$ctrl.reset" src="{{$ctrl.ICON_RESET}}" ng-click="$ctrl.onInputReset()"></asset>
                <div ng-messages="$ctrl.form[$ctrl.name].$error">
                    <div ng-message="required">{{'COMMON.FIELD_REQUIRED' | translate}}</div>
                </div>
            </md-input-container>
        </div>
    `
};
