import * as RmActions from '../../radioactive-materials.actions';
import * as RmActionsWaste from '../rm-waste.actions';
import { generateQRCode } from '../../../../common/utils/util.qrcode';
import isNull from 'lodash/isNull';

/**
 * @const {object}
 * @memberOf module:RadioactiveMaterials
 */
export const rmWasteDetails = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $scope, $rootScope, FileActions, Notifier) {
        const RM_WASTE = 'rmWaste';
        const unsubscribe = $ngRedux.connect(({ radioactiveMaterials }) => ({
            rmWaste: radioactiveMaterials.rmWaste
        }), {
            ...FileActions,
            ...RmActions,
            ...RmActionsWaste
        })(this);

        /**
         * @function $onInit
         */
        this.$onInit = () => {
            if (this.rmWaste.id) {
                this.identifier = 'app.radioactiveMaterials.waste.manage';
                this.state = 'app.radioactiveMaterials.waste.manage.details';
                this.getAtomicNums(RM_WASTE, this.rmWaste.isotope);
                generateQRCode('qrcode', {
                    type: RM_WASTE,
                    id: this.rmWaste.id,
                    visualId: this.rmWaste.visualId,
                });
            }
            else {
                this.identifier = 'app.radioactiveMaterials.waste.add';
                this.state = 'app.radioactiveMaterials.waste.add.details';
                this.getNextId(RM_WASTE).then((id) => {
                    generateQRCode('qrcode', {
                        type: RM_WASTE,
                        visualId: id,
                    });
                });
            }

            this.getWasteTypes();
            this.getIsotopes(RM_WASTE);
            this.getWasteDisposalReach();
            initUnits();
        }

        const initUnits = () => {
            this.getUnits(RM_WASTE, this.rmWaste.closureUnitType, 'closureUnits');
            this.getUnits(RM_WASTE, this.rmWaste.disposalUnitType, 'disposalUnits');
        };

        this.onIsotope = ({ input }) => {
            this.getAtomicNums(RM_WASTE, input, true);
        };

        this.onAtomicNum = ({ item }) => {
            this.setHalfLife(RM_WASTE, 'halfLife', item.halfLife);
        };

        this.onClosureUnit = ({ input }) => {
            this.getUnits(RM_WASTE, input, 'closureUnits', { closureUnit: null });
        };

        this.onDisposalUnit = ({ input }) => {
            this.getUnits(RM_WASTE, input, 'disposalUnits', { disposalUnit: null });
        };

        this.estimateDisposalReach = () => {
            const { atomicNum, dateOfClosure, dateOfClosureDose, closureUnit, disposalReach } = this.rmWaste;
            // TODO validate dateOfClosureDose, has to be number
            const dose = dateOfClosureDose && dateOfClosureDose !== '';
            if (atomicNum && dateOfClosure && dose && closureUnit && disposalReach) {
                this.getWasteDisposalReachEstimate({
                    atomicNum,
                    dateOfClosure,
                    dateOfClosureDose,
                    closureUnit,
                    disposalReach
                });
            }
        };

        this.saveDetails = () => {
            $scope.$broadcast('show-errors-check-validity', '$ctrl.rmWasteForm');
            if (this.rmWasteForm.$valid) {
                this.saveWaste(this.rmWaste).then(() => {
                    Notifier.success('New radioactive material waste has been successfully saved');
                    isNull(this.rmWaste.id) && resetForm();
                }).catch(() => {
                    Notifier.error('System Error!');
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
        }

        const resetForm = () => {
            $rootScope.$broadcast('SELECT_CASCADE_RESET', '$ctrl.rmWasteForm');
            this.rmWasteForm.$setPristine();
            this.rmWasteForm.$setUntouched();
            this.getNextId(RM_WASTE).then((id) => {
                this.qrCodeGenerate(RM_WASTE, this.rmWaste);
            });
            initUnits();
        };

        this.onPrintQRcode = () => {
            this.qrCodePrint(RM_WASTE, this.rmWaste);
        };

        this.onSaveImage = async (event) => {
            const { filePath, width, height } = event[0];
            return await this.saveImage(filePath, width, height);
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            unsubscribe();
        }
    },
    template: require('./rm-waste-details.html')
};
