/**
 * @module root/legacy/components/service-contract
 */
import { ServiceContractsController } from './service-contracts.controller';
import { ServiceContractsBaseController } from './controllers/service-contracts-controller-base';
import { ServiceContractsAddController } from './controllers/service-contracts-controller-base-add';
import { ServiceContractsManageController } from './controllers/service-contracts-controller-base-manage';
import { ServiceContractsGridController } from './contract/grid/service-contracts-grid.controller';
import { InputServiceContractNumberController } from './form/input-service-contract-number/input-service-contract-number.controller';
import { SelectServiceContractTypeController } from './form/select-service-contract-type/select-service-contract-type.controller';
import { ServiceContractService } from './services/service-contract-service';
import { ServiceContractManager } from './services/service-contract-manager';
import { ServiceContractsActionsFactory } from './service-contracts.actions';
import { ServiceContractFactory } from './contract/service-contract.factory';
import { ServiceContractTypeFactory } from './contract/service-contract-type.factory';
import { ServiceContractStatusFactory } from './contract/service-contract-status.factory';
import { ServiceContractVisitFactory } from './contract/service-contract-visit.factory';
import { ServiceContractVisitScheduleFactory } from './contract/service-contract-visit-schedule.factory';
import { serviceContractsGrid } from './contract/grid/service-contracts-grid.component';
import { inputServiceContractNumber } from './form/input-service-contract-number/input-service-contract-number.component';
import { selectServiceContractType } from './form/select-service-contract-type/select-service-contract-type.component';

angular.module(
    'components.service-contracts',
    []
).controller({
    ServiceContractsController,
    ServiceContractsBaseController,
    ServiceContractsAddController,
    ServiceContractsManageController,
    ServiceContractsGridController,
    InputServiceContractNumberController,
    SelectServiceContractTypeController
}).service({
    ServiceContractService,
    ServiceContractManager
}).factory({
    ServiceContractsActions: ServiceContractsActionsFactory,
    ServiceContract: ServiceContractFactory,
    ServiceContractType: ServiceContractTypeFactory,
    ServiceContractStatus: ServiceContractStatusFactory,
    ServiceContractVisit: ServiceContractVisitFactory,
    ServiceContractVisitSchedule: ServiceContractVisitScheduleFactory,
}).component({
    serviceContractsGrid,
    inputServiceContractNumber,
    selectServiceContractType
}).run(function($templateCache) {
    $templateCache.put('./service-contracts-contract.html', require('./views/add/service-contracts-contract.html'));
    $templateCache.put('./service-contracts-accounts.html', require('./views/add/service-contracts-accounts.html'));
    $templateCache.put('./service-contracts-equipment-covered.html', require('./views/add/service-contracts-equipment-covered.html'));
    $templateCache.put('./service-contracts-equipment.html', require('./views/add/service-contracts-equipment.html'));
    $templateCache.put('./service-contracts-equipment-spare-parts.html', require('./views/add/service-contracts-equipment-spare-parts.html'));
    $templateCache.put('./service-contracts-equipment-grid.html', require('./views/add/equipment/service-contracts-equipment-grid.html'));
    $templateCache.put('./service-contracts-equipment-filter.html', require('./views/add/equipment/service-contracts-equipment-filter.html'));
    $templateCache.put('./service-contracts-visits.html', require('./views/add/service-contracts-visits.html'));
    $templateCache.put('./service-contracts-visits-schedule.html', require('./views/add/service-contracts-visits-schedule.html'));
    $templateCache.put('./service-contracts-visits-schedule-dialog.html', require('./views/add/visits-schedule/service-contracts-visits-schedule-dialog.html'));
    $templateCache.put('./service-contracts-visits-schedule-grid.html', require('./views/add/visits-schedule/service-contracts-visits-schedule-grid.html'));
    $templateCache.put('./service-contracts-visits-schedule-filter.html', require('./views/add/visits-schedule/service-contracts-visits-schedule-filter.html'));
    $templateCache.put('./service-contracts-listing.html', require('./views/manage/service-contracts-listing.html'));
    $templateCache.put('./service-contracts-summary.html', require('./views/manage/service-contracts-summary.html'));
    $templateCache.put('./service-contracts-grid-filter.html', require('./contract/grid/service-contracts-grid-filter.html'));
});
