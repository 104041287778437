import { product } from '../../../../bootstrap.json';

/* @ngInject */
export function accAssessmentItemFormElement($timeout, EXPORT_FORMAT_PDF, $http, $rootScope, ACC_ASSESSMENT_ITEM_TYPE, Notifier, $mdDialog, DocumentManager, DOCUMENT_TYPE, AccreditationDocumentsService, AuthService, AccDocument, $stateParams, Helpers, AccreditationService, $filter) {
    return {
        restrict: 'E',
        template: require('./acc-assessment-item-form-element.html'),
        scope: {
            assessmentItem: '=assessmentItem',
            assessment: '=assessment',
            locationUuid: '@locationUuid',
            locationName: '@locationName',
            dirty: '=dirty',
            accreditationSchemaCode: '@accreditationSchemaCode',
            forwardAssessmentItemEvRequest: '&',
            token: '@token',
            year: '@year',
        },
        link: function (scope, element, attrs) {

            scope.uploadedFileQueue = [];
            scope.submit = false;

            // If year is not set, work as if it is accreditation for currnet year
            if (scope.year == null)
                scope.year = new Date().getFullYear();

            if ((scope.assessmentItem.assessmentItemType === ACC_ASSESSMENT_ITEM_TYPE.DOCUMENT) ||
                (scope.assessmentItem.assessmentItemType === ACC_ASSESSMENT_ITEM_TYPE.DOCUMENT_GENERATED)) {

                scope.$on('acc-schema-assessment-value-fetched', function (events, args) {
                    scope.uploadedFileQueue = [];
                    if (scope.assessmentItem.value != null) {
                        scope.assessmentItem.value.forEach(function (queueItem) {
                            scope.uploadedFileQueue.push(queueItem);
                        });
                    }
                });
            }

            scope.uploadFile = function () {
                $mdDialog.show({
                    controller: 'AccDiasUploadDocumentCtrl',
                    template: require('../views/accreditation-upload-document-dialog.html'),
                    // scope: scope, // use parent scope in template
                    // preserveScope: true,
                    locals: {
                        color: '#a934e4',
                        title: 'Accreditation Upload Document',
                        locationUuid: scope.locationUuid,
                        locationName: scope.locationName,
                        templateDocument: false,
                        token: scope.token,
                        standardId: scope.assessmentItem.standardId
                    },
                    scope: scope.$new(),
                    preserveScope: false,
                    clickOutsideToClose: false
                });
            };

            scope.fetchDocumentsForSiteLocation = function () {
                scope.cursorState = 'wait';
                AccreditationDocumentsService.fetchAccQCMasterListDocuments(scope.accreditationSchemaCode, scope.locationUuid,
                    {documentName: null, author: null, approvedBy: null, expireAfter: null}, false)
                    .then(function (response) {
                        scope.cursorState = 'default';
                        if ((response.status >= 200) && (response.status < 300) &&
                            (response.data.result != null)) {
                            scope.documentList = response.data.result;
                        } else if ((response.status >= 500) && (response.status < 600)) {
                            Notifier.error($filter('translate')("ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_FETCH_LIST_ERROR"));
                        } else if ((response.status >= 400) && (response.status < 500)) {
                            Notifier.error($filter('translate')("ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_PAGE_NOT_FOUND"));
                        } else if ((response.data == null) || (response.data.result == null)) {
                            //No data for this item
                        }
                    }, function (error) {
                        scope.cursorState = 'default';
                        if ((typeof error === 'number') && (error >= 500) && (error < 600)) {
                            Notifier.error($filter('translate')("ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_FETCH_LIST_ERROR"));
                        } else if ((typeof error === 'number') && (error >= 400) && (error < 500)) {
                            Notifier.error($filter('translate')("ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_PAGE_NOT_FOUND"));
                        } else {
                            Notifier.error($filter('translate')("ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_FORM_ERROR_2"));
                        }
                    });
            };

            scope.onSelectExistingDocument = function () {
                $mdDialog.show({
                    controller: SelectExistingAccDocument,
                    template: require('../views/accreditation-select-document-dialog.html'),
                    scope: scope, // use parent scope in template
                    preserveScope: true,
                    locals: {
                        color: '#a934e4',
                        title: $filter('translate')("ACCREDITATION.TABS.DOCUMENTS.SELECT_DOCUMENT")
                    },
                    clickOutsideToClose: true
                });
            };

            function SelectExistingAccDocument($scope, $mdDialog, color, title) {
                $scope.headerColor = color;
                $scope.headerTitle = title;
                $scope.currentPage = 1;
                $scope.selectedDocumentItem = null;

                scope.fetchDocumentsForSiteLocation();

                $scope.changePage = function (newPageNumber) {
                    $scope.currentPage = newPageNumber;

                };

                $scope.selectItem = function (item) {
                    $scope.selectedDocumentItem = item;
                };

                $scope.cancel = function () {
                    $mdDialog.cancel();
                };

                $scope.submit = function (selectedDocument) {
                    if (selectedDocument.misc == null) {
                        selectedDocument.misc = {};
                    }

                    selectedDocument.misc.assessmentId = scope.assessmentItem.assessmentId;
                    var currentUser = AuthService.getUser();
                    selectedDocument.misc.uploaderUuid = currentUser.uuid;


                    //Update accreditation document in database,
                    //link it to this user
                    AccreditationDocumentsService.editAccreditationDocument(scope.locationUuid, selectedDocument.id, {
                        misc: selectedDocument.misc
                    }).then(function (response) {
                        if ((response.status == 200)) {
                            //all ok
                            var n = selectedDocument.docPath.lastIndexOf('/');
                            var fileName = selectedDocument.docPath.substring(n + 1);
                            var assessmentItemValueForDoc = {
                                documentId: selectedDocument.id,
                                filePath: selectedDocument.docPath,
                                isSuccess: true,
                                isUploaded: true,
                                file: {
                                    name: fileName,

                                },
                                misc: {
                                    assessmentId: scope.assessmentItem.assessmentId,
                                    uploaderUuid: selectedDocument.misc.uploaderUuid
                                },
                                isAlreadyOnServer: true,
                            };

                            if (scope.assessmentItem.value == null) {
                                scope.assessmentItem.value = [];
                            }
                            scope.assessmentItem.value.push(assessmentItemValueForDoc); //add uploaded document to assessment item array of documents
                            scope.uploadedFileQueue = [];
                            if (scope.assessmentItem.value != null) {
                                scope.assessmentItem.value.forEach(function (queueItem) {
                                    scope.uploadedFileQueue.push(queueItem);
                                });
                            }
                            scope.makeMeDirty();
                        } else if (response.status == 400 && typeof response.data == 'string') {
                            Notifier.error(response.data);
                        } else if (response.status == 403) {
                            Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_ACCESS_ERROR'));
                        } else if (response.status == 404) {
                            Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_DOC_NOT_FOUND'));
                        } else if (response.status == 500) {
                            Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_SERVER_ERROR'));
                        } else if (response.status != 200) {
                            Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_FETCH_ERROR'));
                        }

                    }, function (error) {
                        Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_UPDATE_ERROR') + error.data);
                    });

                    $mdDialog.hide();
                };
            }

            scope.$on('acc-document-uploaded-an', function (events, args) {
                if (args != null) {
                    if (scope.assessmentItem.value == null) {
                        scope.assessmentItem.value = [];
                    }

                    scope.assessmentItem.value.push(args); //add uploaded document to assessment item array of documents
                    scope.uploadedFileQueue = [];
                    if (scope.assessmentItem.value != null) {
                        scope.assessmentItem.value.forEach(function (queueItem) {
                            scope.uploadedFileQueue.push(queueItem);
                        });
                    }
                }
            });

            scope.$on('acc-document-uploaded', function (events, args) {
                if (args != null) {
                    if (scope.assessmentItem.value == null) {
                        scope.assessmentItem.value = [];
                    }

                    //Update accreditation document in database,
                    //link it as related to this assessmentId parent assessment
                    args.misc.assessmentId = scope.assessmentItem.assessmentId;
                    AccreditationDocumentsService.editAccreditationDocument(scope.locationUuid, args.documentId, {
                        misc: args.misc
                    })
                        .then(function (response) {
                            if ((response.status == 200) && (response.data.result != null)) {
                                //all ok
                            } else if (response.status == 400 && typeof response.data == 'string') {
                                Notifier.error(response.data);
                            } else if (response.status == 403) {
                                Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_ACCESS_ERROR'));
                            } else if (response.status == 404) {
                                Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_DOC_NOT_FOUND'));
                            } else if (response.status == 500) {
                                Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_SERVER_ERROR'));
                            } else if (response.status != 200) {
                                Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_FETCH_ERROR'));
                            }

                        }, function (error) {
                            Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_FETCH_DATA_ERROR') + error.data);
                        });

                    scope.assessmentItem.value.push(args); //add uploaded document to assessment item array of documents
                    scope.uploadedFileQueue = [];
                    if (scope.assessmentItem.value != null) {
                        scope.assessmentItem.value.forEach(function (queueItem) {
                            scope.uploadedFileQueue.push(queueItem);
                        });
                    }

                    AccreditationService.saveAccreditationAssessment(scope.accreditationSchemaCode, scope.locationUuid,
                        scope.assessmentItem.standardId, scope.assessment, scope.year, scope.submit)
                        .then(function (success) {
                            scope.assessment.dirty = false;
                            Notifier.success($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_SAVE_SUCCESS'));
                        }, function (error) {
                            Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_CHANGES_NOT_SAVED'));
                        });
                    scope.makeMeDirty();
                }
            });

            scope.removeFile = function (item) {
                $rootScope.confirmationDialog($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.CONFIRM_REMOVE'))
                    .then(function (result) {
                        scope.assessmentItem.value = scope.assessmentItem.value.filter(function (fileItem) {
                            return fileItem.documentId != item.documentId;
                        });

                        if (scope.assessmentItem.value.length === 0) {
                            scope.assessmentItem.value = null; //remove empty array, for easier manipulation and progress calculation
                        }

                        if (item.remove != null) {
                            item.remove();
                        } else {
                            //next time form is loaded, item is actually locally created mock of original FileUploader item
                            //just remove item from uplodaer queue array
                            scope.uploadedFileQueue = scope.uploadedFileQueue.filter(function (fileItem) {
                                return fileItem.filePath != item.filePath;
                            });
                        }

                        AccreditationService.saveAccreditationAssessment(scope.accreditationSchemaCode, scope.locationUuid,
                            scope.assessmentItem.standardId, scope.assessment, scope.year, scope.submit)
                            .then(function (success) {
                                scope.assessment.dirty = false;
                                Notifier.success($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_SAVE_SUCCESS'));
                            }, function (error) {
                                Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_CHANGES_NOT_SAVED'));
                            });

                        scope.makeMeDirty();
                    }, function (error) {
                        //Cancel
                    });
            };

            scope.generateFile = function (item) {

                //AccreditationDocumentsService.editAccreditationDocument(scope.locationUuid,
                AccreditationDocumentsService.generateAccDocument(scope.accreditationSchemaCode, scope.locationUuid, item.assessmentItemDocumentType, EXPORT_FORMAT_PDF)
                    .then(function (response) {
                        if (!Notifier.checkRestResponse(response.status, response.data,
                            $filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_GENERATING_ERROR'))) {
                            return;
                        }

                        if (!response.data.path) {
                            Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_GENERATING_ERROR'));
                            return;
                        }

                        Notifier.success($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_GENERATE_SUCCESS'));

                        //Here, save generated document to accreditation documents table
                        var newDocument = new AccDocument();
                        newDocument.docPath = "/" + response.data.path.replace(/^\//, "");
                        newDocument.title = $filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.GENERATEd_MSG')
                                            + item.assessmentItemDocumentType;
                        newDocument.locationUuid = scope.locationUuid;
                        var currentUser = AuthService.getUser();
                        newDocument.misc = {};
                        newDocument.misc.uploaderUuid = currentUser.uuid;
                        newDocument.misc.assessmentId = scope.assessmentItem.assessmentId;
                        newDocument.version = "1.0";
                        newDocument.writtenBy = `${product} generated`;
                        newDocument.dateEdited = new Date();
                        newDocument.isGenerated = true;

                        AccreditationDocumentsService.saveAccreditationDocument(newDocument.locationUuid, newDocument, scope.assessmentItem.standardId)
                            .then(function (success) {
                                newDocument.id = success.data.documentId;
                                if (scope.assessmentItem.value == null) {
                                    scope.assessmentItem.value = [];
                                }

                                var n = newDocument.docPath.lastIndexOf('/');
                                var fileName = newDocument.docPath.substring(n + 1);
                                var assessmentItemValueForDoc = {
                                    documentId: newDocument.id,
                                    filePath: newDocument.docPath,
                                    isSuccess: true,
                                    isUploaded: true,
                                    file: {
                                        name: fileName,
                                        uploader: success.data.uploader,
                                        uploadDate: Helpers.formatDate(new Date(), 'DD-MM-YYYY hh:mm a'),
                                        size: 0
                                    },
                                    misc: {
                                        assessmentId: scope.assessmentItem.assessmentId,
                                        uploaderUuid: newDocument.misc.uploaderUuid,

                                    },
                                    isAlreadyOnServer: true,
                                };

                                scope.assessmentItem.value.push(assessmentItemValueForDoc); //add uploaded document to assessment item array of documents
                                scope.uploadedFileQueue = [];
                                if (scope.assessmentItem.value != null) {
                                    scope.assessmentItem.value.forEach(function (queueItem) {
                                        scope.uploadedFileQueue.push(queueItem);
                                    });
                                }

                                AccreditationService.saveAccreditationAssessment(scope.accreditationSchemaCode, scope.locationUuid,
                                    scope.assessmentItem.standardId, scope.assessment, scope.year)
                                    .then(function (success) {
                                        scope.assessment.dirty = false;
                                        Notifier.success($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_SAVE_SUCCESS'));
                                    }, function (error) {
                                        Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_CHANGES_NOT_SAVED'));
                                    });

                                scope.makeMeDirty();
                            }, function (error) {
                                Notifier.error($filter('translate')('ACCREDITATION.TABS.APPROVAL_PROCESS.NOTIFIER_CHANGES_NOT_SAVED'));
                            });
                    });
            };

            scope.downloadFile = function (item) {
                $timeout(
                    function () {
                        DocumentManager.downloadFile(item.filePath, DOCUMENT_TYPE.ACCREDITATION_DOCUMENT);
                    }
                );
            };

            scope.makeMeDirty = function (assessment) {
                scope.dirty = true;
            };

            scope.onForwardRequest = function (item) {

                scope.forwardAssessmentItemEvRequest({assItemId: item.id});
            };

            scope.tokenValid = function () {
                return scope.token != null && scope.token != "";
            };

            if (($stateParams['assItem'] != null) && ($stateParams['forward'] != null)) {
                if ((scope.assessmentItem.id === $stateParams['assItem']) && ($stateParams['forward'] === "true")) {
                    scope.onForwardRequest(scope.assessmentItem);
                }
            }
        }
    };
}
