export class AppService {
    constructor($ngRedux, $stateRegistry, Session) {
        this.$ngRedux = $ngRedux;
        this.$stateRegistry = $stateRegistry;
        this.Session = Session;
    }

    moduleOverride() {
        const user = this.Session.getUser();
        const state = this.$stateRegistry.get('app.administration.accounts');
        state.redirectTo = 'app.administration.accounts.headOffices';

        switch (user.typeCode) {
            case 'hqm':
            case 'hrs':
            case 'apa':
            case 'ars':
            case 'dcr':
            case 'hlo':
            case 'hop':
            case 'scm':
            case 'spc':
                if (user.parentCount === 1) {
                    this.$ngRedux.dispatch({
                        type: 'TAB_HIDE',
                        id: 'app.administration.accounts',
                        state: ['app.administration.accounts.headOffices']
                    });
                    this.$ngRedux.dispatch({
                        type: 'TAB_PARAMS',
                        id: 'app.administration.accounts',
                        initParams: false,
                        stateParams: {hof: user.parents[0].uuid}
                    });
                    this.$ngRedux.dispatch({
                        type: 'TAB_ENABLE',
                        id: 'app.administration.accounts',
                        state: [
                            'app.administration.accounts.headOfficesDetails',
                            'app.administration.accounts.headOfficesContacts',
                            'app.administration.accounts.headOfficesContactDetails',
                            'app.administration.accounts.headOfficesLicences',
                            'app.administration.accounts.headOfficesEquipment',
                            'app.administration.accounts.headOfficesModules'
                        ]
                    });
                    state.redirectTo = 'app.administration.accounts.headOfficesDetails';
                }
                this.$ngRedux.dispatch({
                    type: 'TAB_OVERRIDE',
                    id: 'app.administration.accounts',
                    override: [
                        {state: 'app.administration.accounts.headOffices', buttons: {execute: null}}
                    ]
                });
                break;
            case 'gen':
            case 'sqm':
            case 'sro':
            case 'aso':
            case 'prm':
            case 'prr':
            case 'arm':
            case 'clm':
            case 'num':
            case 'pos':
            case 'rma':
            case 'slc':
            case 'whc':
            case 'who':
                if (user.parentCount === 1) {
                    this.$ngRedux.dispatch({
                        type: 'TAB_HIDE',
                        id: 'app.administration.accounts.siteLocations',
                        state: ['app.administration.accounts.siteLocations.list']
                    });
                    this.$ngRedux.dispatch({
                        type: 'TAB_PARAMS',
                        id: 'app.administration.accounts.siteLocations',
                        initParams: false,
                        stateParams: {
                            hof: null,
                            slo: user.parents[0].uuid
                        }
                    });
                    this.$ngRedux.dispatch({
                        type: 'TAB_ENABLE',
                        id: 'app.administration.accounts.siteLocations',
                        state: [
                            'app.administration.accounts.siteLocations.details',
                            'app.administration.accounts.siteLocations.address',
                            'app.administration.accounts.siteLocations.invoiceAddress',
                            'app.administration.accounts.siteLocations.contacts',
                            'app.administration.accounts.siteLocations.contactDetails',
                            'app.administration.accounts.siteLocations.licences',
                            'app.administration.accounts.siteLocations.licenceDetails',
                            'app.administration.accounts.siteLocations.licenceIsotopes',
                            'app.administration.accounts.siteLocations.licenceAppendix',
                            'app.administration.accounts.siteLocations.equipment',
                            'app.administration.accounts.siteLocations.employees',
                            'app.administration.accounts.siteLocations.modules'
                        ]
                    });
                    this.$ngRedux.dispatch({
                        type: 'GRID_INIT_FILTER',
                        id: 'employeesGrid',
                        path: 'location',
                        input: user.parents[0].uuid
                    });
                    state.redirectTo = 'app.administration.accounts.siteLocations.details';
                }
                else {
                    this.$ngRedux.dispatch({
                        type: 'TAB_PARAMS',
                        id: 'app.administration.accounts.siteLocations',
                        initParams: false,
                        stateParams: {hof: null}
                    });
                    state.redirectTo = 'app.administration.accounts.siteLocations.list';
                }
                this.$ngRedux.dispatch({
                    type: 'TAB_OVERRIDE',
                    id: 'app.administration.accounts.siteLocations',
                    override: [
                        {state: 'app.administration.accounts.siteLocations.list', buttons:{redirect: [], execute: null}},
                        {state: 'app.administration.accounts.siteLocations.contacts', buttons: {execute: null}},
                        {state: 'app.administration.accounts.siteLocations.contactDetails', buttons:{execute: null}}
                    ]
                });
                this.$ngRedux.dispatch({
                    type: 'GRID_OVERRIDE',
                    id: 'administrationAccountsSiteLocationContactsGrid',
                    columns: ['password-change'],
                    buttons: ['on-select', 'on-remove']
                });
                break;
            case 'drs':
                if (user.parentCount === 1) {
                    this.$ngRedux.dispatch({
                        type: 'TAB_HIDE',
                        id: 'app.administration.accounts.departments',
                        state: ['app.administration.accounts.departments.list']
                    });
                    this.$ngRedux.dispatch({
                        type: 'TAB_PARAMS',
                        id: 'app.administration.accounts.departments',
                        initParams: false,
                        stateParams: {
                            hof: null,
                            slo: null,
                            dep: user.parents[0].uuid
                        }
                    });
                    this.$ngRedux.dispatch({
                        type: 'TAB_ENABLE',
                        id: 'app.administration.accounts.departments',
                        state: [
                            'app.administration.accounts.departments.details',
                            'app.administration.accounts.departments.contacts',
                            'app.administration.accounts.departments.contactDetails',
                            'app.administration.accounts.departments.equipment',
                            'app.administration.accounts.departments.employees'
                        ]
                    });
                    this.$ngRedux.dispatch({
                        type: 'GRID_INIT_FILTER',
                        id: 'employeesGrid',
                        path: 'location',
                        input: user.parents[0].uuid
                    });
                    state.redirectTo = 'app.administration.accounts.departments.details';
                }
                else {
                    this.$ngRedux.dispatch({
                        type: 'TAB_PARAMS',
                        id: 'app.administration.accounts.departments',
                        initParams: false,
                        stateParams: {
                            hof: null,
                            slo: null
                        }
                    });
                    state.redirectTo = 'app.administration.accounts.departments.list';
                }
                this.$ngRedux.dispatch({
                    type: 'TAB_OVERRIDE',
                    id: 'app.administration.accounts.departments',
                    override: [
                        {state: 'app.administration.accounts.departments.list', buttons:{redirect: [], execute: null}},
                        {state: 'app.administration.accounts.departments.contacts', buttons: {execute: null}},
                        {state: 'app.administration.accounts.departments.contactDetails', buttons:{execute: null}}
                    ]
                });
                this.$ngRedux.dispatch({
                    type: 'GRID_OVERRIDE',
                    id: 'administrationAccountsDepartmentContactsGrid',
                    columns: ['password-change'],
                    buttons: ['on-select', 'on-remove']
                });
                break;
        }

        // TODO temporary solution, will be removed
        switch (user.type) {
            case 'Radiographer':
                this.$ngRedux.dispatch({
                    type: 'TAB_SHOW',
                    id: 'app.mammoQualityControl.artifactEvaluation',
                    state: ['app.mammoQualityControl.artifactEvaluation.details']
                });
                break;
        }
    }
}

AppService.$inject = ['$ngRedux', '$stateRegistry', 'Session'];
