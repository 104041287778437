import { TAB_HIDE } from "../../../common/tabs/tabs.constants";
import { IDS } from "./import-action-id.constants"
import { TYPES } from "./import-action-type.constants";
import {GRID_RELOAD} from "../../../common/grid/grid.constants";

export const abstractState = {
    name: IDS.app_employees_import,
    abstract: true,
    url: '/import',
};

export const dashboardState = {
    name: IDS.app_employees_import_dashboard,
    url: '',
    component: 'dashboard',
};

export const historyTabsState = {
    name: IDS.app_employees_import_history,
    redirectTo: IDS.app_employees_import_history_list,
    url: '/history',
    component: 'tabs',
};

export const historyListState = {
    name: IDS.app_employees_import_history_list,
    url: '/list',
    component: IDS.empImportHistoryList,
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: IDS.app_employees_import_history,
            state: [IDS.app_employees_import_history_details]
        })
    }
};
historyListState.onEnter.$inject = ['$ngRedux'];

export const historyDetailsState = {
    name: IDS.app_employees_import_history_details,
    url: '/details',
    component: IDS.empImportHistoryDetails,
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: GRID_RELOAD,
            id: IDS.empImport,
            path: IDS.empImportHistoryDetailsGrid,
        })
    }
};
historyDetailsState.onEnter.$inject = ['$ngRedux'];

export const newImportTabsState = {
    name: IDS.app_employees_import_new,
    redirectTo: IDS.app_employees_import_new_import,
    url: '/new',
    component: 'tabs',
};

export const newImportUploadState = {
    name: IDS.app_employees_import_new_import,
    url: '/upload',
    component: IDS.empImportNewImport,
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TYPES.RESET_CHECKBOX,
            id: IDS.empImportNewImport,
            path: 'deleteMissingUsers',
            defaultValue: 1,
        })
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: IDS.app_employees_import_new,
            state: [IDS.app_employees_import_new_result]
        })
    }
};
newImportUploadState.onEnter.$inject = ['$ngRedux'];

export const newImportResultState = {
    name: IDS.app_employees_import_new_result,
    url: `/result`,
    component: IDS.empImportNewResult,
};

export const settingsState = {
    name: IDS.app_employees_import_settings,
    redirectTo: IDS.app_employees_import_settings_main,
    url: '/settings',
    component: 'tabs',
};