import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import {GRID_FILTER_TOGGLE} from '../../../../common/grid/grid.constants';
import { IDS } from '../rep-monthly-action-id.constants';
import * as Actions from '../rep-monthly.actions';
import {fetchRunsHofsGrid} from "../rep-monthly.actions";
import watch from "redux-watch";

class RepMonthlyGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, AuthService, $filter, $mdDialog, $scope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
        this.AuthService = AuthService;
    }

    $onInit() {
        this.module = 'reporting';
        this.identifier = IDS.repMonthly;
        this.statePath = IDS.repMonthlyGrid;
        this.filterOpen = true;
        this.filterExpandButtonVisible = false;
        this.filterTemplate = './rep-monthly-grid-filter.html';
        this.exportComponent = false;
        this.confirmDialogOnRemove = true;
        this.autoload = false;
        this.defaultTitle = this.$filter('translate')('REPORTING.MONTHLY.LIST.TAB');
        this.title = this.defaultTitle;
        // this.header = false;

        this.filterActions.isAdmin = this.AuthService.getUser().type === 'Administrator';

        this.columnsDefault = [
            {title: '#',                mapper: null},
            {title: 'Head Office',      mapper: 'hof_name'},
            {title: 'Site Location',    mapper: 'slo_name'},
            {title: 'Status',           mapper: 'status_name'},
            {title: 'Document',         mapper: 'filename'},
        ];
        this.columns = this.columnsDefault.concat();

        if(this.AuthService.getUser().type !== 'Administrator') {
            this.columns.splice(3, 1); // remove Status column
        }

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), Actions)(this);

        this.unsubscribeCustom = this.$ngRedux.connect(
            state => ({
                mrpId:      state.reporting.repMonthly.repMonthlyGrid.filter.mrp_id,
                failedOnly: state.reporting.repMonthly.failedOnly
            })
       ,{})(this);

        const watchFailedOnly = watch(this.$ngRedux.getState, 'reporting.repMonthly.failedOnly');
        this.unsubscribeWatch = this.$ngRedux.subscribe(watchFailedOnly(failed_only => {
            this.fetchRunsHofsGrid({ mrp_id:this.mrpId, failed_only });
        }));

        this.dataAction = this.fetchRepMonthlyGrid;

        this.filterActions.onRunChange = ({input}) => {
            this.fetchRunsHofsGrid({ mrp_id:input, failed_only: this.failedOnly });
        };

        this.buttons =[ {
            class: 'btnGridAction',
            label: 'Download',
            callback: event => {
                this.onDownload(event)
            },
            filter: item => {
                return item.doc_uri
            },
        }];
        this.init();

        this.buttonsDefault = this.buttons.concat();
    }

    showAllColumns() {
        this.columns = this.columnsDefault.concat();
    }

    $onDestroy() {
        this.destroy();
        if(this.unsubscribeCustom) {
            this.unsubscribeCustom();
        }
        if(this.unsubscribeWatch) {
            this.unsubscribeWatch();
        }
    }
}

RepMonthlyGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'AuthService', '$filter', '$mdDialog', '$scope'];

export const repMonthlyGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onDownload: '&',
    },
    controller: RepMonthlyGridController,
    ...gridTpl
}
