import API                          from '../../../../common/http/api.fetch';
import {success, responseMessage}   from '../../../../common/utils/util.notifier';
import I18N                         from '../../../../common/utils/util.i18n';
import { TYPES }                    from '../dms-manage-action-type.constants';
import { IDS }                      from '../dms-manage-action-id.constants';

export const initReviewerStatuses = (statuses) => {
    return dispatch => {
        return dispatch({
            type:   TYPES.INIT_REVIEW_STATUSES,
            id:     IDS.accMasterDocList,
            reviewStatuses: statuses.map(value => ({
                value, label: I18N.translate(`ACCREDITATION.DOCUMENT_EVALUATION.STATUS.${value.toUpperCase()}`)
            }))
        })
    }
}

export const setDocumentResult = (data) => {
    return dispatch => {
        return API.fetch({
            url: `/accreditation/set-document-result`,
            method: 'POST',
            data,
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_UPDATED');
        },error => {
            responseMessage(error)
        });
    };
};
