/* @ngInject */
export function SafetyPlanCertificateFactory(User){
    return function SafetyPlanCertificate() {

        this.reviewId = null;
        this.user = new User();
        this.fullName = null;
        this.presentationId = null;
        this.presentationName = null;
        this.presentationDescription = null;
        this.startDate = null;
        this.endDate = null;
    };
}
