import { GridReducer } from '../../common/grid/grid.reducer';
import { tabs } from './radioactive-materials.tabs';

export default {
    ...tabs,
    rmWaste: {
        id: null,
        location: null,
        locationName: null,
        locations: [],
        locationDepartment: null,
        locationDepartments: [],
        accountId: null,
        visualId: null,
        wasteType: null,
        wasteTypes: [],
        isotope: null,
        isotopes: [],
        atomicNum: null,
        atomicNums: [],
        halfLife: null,
        binNum: null,
        dateOfClosure: null,
        dateOfClosureDose: null,
        closureUnitType: 'siu',
        closureUnit: null,
        closureUnits: [],
        disposalReach: null,
        disposalReachList: [],
        disposalReachDate: null,
        dateOfDisposal: null,
        dateOfDisposalDose: null,
        disposalUnitType: 'siu',
        disposalUnit: null,
        disposalUnits: [],
        permitLicenseNo: null,
        camera: null,
        gallery: [],
        qrcode: null,
        unitData: [
            {value: 'siu', label: 'SI Units'},
            {value: 'nsu', label: 'Non SI Units'}
        ],
        signature: null,
        firstName: null,
        lastName: null
    },
    rmWasteGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0
    },
    rmWasteAlertsGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0
    },
    rmTest: {
        id: null,
        location: null,
        locationName: null,
        locations: [],
        locationDepartment: null,
        locationDepartments: [],
        locationLicence: null,
        locationLicences: [],
        accountId: null,
        visualId: null,
        isotope: null,
        isotopes: [],
        isotopeName: null,
        atomicNum: null,
        atomicNums: [],
        halfLife: null,
        sealed: 1,
        sealedData: [
            {value: 1, label: 'Sealed'},
            {value: 0, label: 'Unsealed'}
        ],
        testType: null,
        testTypes: [],
        testTypeName: null,
        activityDate: null,
        activityDateFormatted: null,
        activity: null,
        activityWithUnit: null,
        unitType: 'siu',
        unitData: [
            {value: 'siu', label: 'SI Units'},
            {value: 'nsu', label: 'Non SI Units'}
        ],
        unit: null,
        units: [],
        currentActivity: null,
        useType: null,
        useTypes: [],
        exemptionQuantity: 0,
        wipeTesting: 0,
        wipeTestingDueDate: null,
        calibrationAndService: 0,
        calibrationDueDate: null,
        serviceDueDate: null,
        assetId: null,
        manufacturer: null,
        manufacturerName: null,
        manufacturers: [],
        model: null,
        models: [],
        modelSerial: null,
        permitLicenseNo: null,
        camera: null,
        gallery: [],
        qrcode: null,
        disposalDate: null,
        disposalOffSite: 1,
        disposalOffSiteData: [
            {value: 1, label: 'Yes'},
            {value: 0, label: 'No'}
        ],
        disposalCompany: null,
        disposalCompanies: [],
        disposalCompanyName: null,
        disposalCompanyAddress: null,
        disposalCompanyPhone: null,
        disposalCompanyEmail: null,
        disposalComments: null,
        disposalDocument: null,
        status: null,
        documentTypes: [],
        documentStatusList: [],
        manufacturerOfRadiationDevice: null,
        manufacturerNameOfRadiationDevice: null,
        modelsOfRadiationDevice: [],
        modelOfRadiationDevice: null,
        modelSerialOfRadiationDevice: null
    },
    rmTestWipeTest: {
        wipeTestId: null,
        backgroundCount: null,
        measuredCount: null,
        calculatedResult: null,
        difference: null,
        wearingLabcoat: 0,
        wearingGloves: 0,
        wearingTLDBadge: 0,
        cottonBud: 0,
        filterPaperWipes: 0,
        plasticBag: 0,
        forceps: 0,
        removedByBiomedicalEngineer: null,
        removedByBiomedicalEngineerData: [
            {value: 1, label: 'Yes'},
            {value: 0, label: 'No'}
        ],
        wipeTestSystem: null,
        wipeTestSystemData: [
            {value: 1, label: 'Beckman Coulter LSC '},
            {value: 0, label: 'Captus 3000 Uptake system'}
        ],
        deviation: null,
        deviationData: [
            {value: 1, label: 'Yes'},
            {value: 0, label: 'No'}
        ],
        measureCalibrationSources: null,
        measuredActivityWithin20: null,
        measuredActivityWithin20Data: [
            {value: 1, label: 'Yes'},
            {value: 0, label: 'No'}
        ],
        countingTime: null,
        eu152Calibration: 0,
        cs137Calibration: 0,
        cs137ConstancyCheck: 0,
        MCAButton: 0,
        min30warmup: null,
        signature: null,
        approvalDate: null,
        fistName: null,
        lastName: null,
        comment: null,
        efficiency: {
            efficiencyLsc: null,
            efficiencyCaptus: null
        }
    },
    rmTestGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0
    },
    rmTestDocument: {
        type: null,
        types: [],
        document: null,
        documentDueDate: null,
    },
    rmTestDocumentsGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0
    },
    rmTestAlertsGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0
    }
};
