/* @ngInject */
export function SafetyTrainingReviewAnimationController($scope, $rootScope, $state, $filter) {

    $rootScope.toolbarTitle = $filter('translate')("SAFETY_TRAINING.VIRTUAL_THEATRES.TITLE");

    $scope.cursorState = 'wait';
    $scope.review = null;
    $scope.reviewVideos = null;
    $scope.selectedVideo = null;
    $scope.year = new Date().getFullYear();
    $scope.initializationInProgress = true;

    if ($state.current.trainingType === 'fluoroscopy') {
        $scope.gameInstance = UnityLoader.instantiate("gameContainer", "./media/animation/training_radiation/fluoroscopy/Build/04072018_Build.json", {onProgress: UnityProgress});
    } else if ($state.current.trainingType === 'o-arm-cbvt') {
        $scope.gameInstance = UnityLoader.instantiate("gameContainer", "./media/animation/training_radiation/o-arm-cbvt/Build/12032019_02.json", {onProgress: UnityProgress});
    } else if ($state.current.trainingType === 'nuclear-medicine-facility') {
        $scope.gameInstance = UnityLoader.instantiate("gameContainer", "./media/animation/training_radiation/nuclear-medicine-facility/Build/Build30102020.json", {onProgress: UnityProgress});
    }
}
