import { TabsReducer } from '../../../common/tabs/tabs.reducer';
import {IDS} from './dms-import-action-id.constants';
export const dmsImportTabs = {
    'app.document-management.import': Object.assign(TabsReducer(), {
        tabs: [
            {
                state: IDS.app_dms_import_list,
                title: 'DOCUMENT_MANAGEMENT.IMPORT.LIST.TAB',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Add New Document'}
                }
            },
            {
                state: IDS.app_dms_import_details,
                title: 'DOCUMENT_MANAGEMENT.IMPORT.DETAILS.TAB',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: IDS.app_dms_import_upload,
                title: 'DOCUMENT_MANAGEMENT.IMPORT.DOCUMENT_ADD.TAB',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Add New Document'}
                }
            },
        ],
    }),
};
