import _ from 'lodash';

export class NavigationService {
    constructor($ngRedux, $state, $uiRouterGlobals) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);
    }

    mapState(state) {
        return {
            navConfigs: state.navigation.configs,
            userType: state.app.user.typeCode,
            userTypeFilters: this.userTypeFilters || Object.entries(state).reduce((result,entry) => _.merge( result,
                _.get(entry[1],'navigationFilter') ? {[`app.${entry[0]}.dashboard`]: entry[1].navigationFilter} : {}
            ),{}),
        }
    }

    getConfigs = function() {
        return this.navConfigs;
    };

    getConfig(state) {
        if (this.navConfigs.length) {
            if ([
                'app.dashboard',
                'app.analytics',
                'public.dashboard',
                'public',
            ].includes(state.name))
                return null;
            const config = _.find(this.flattenConfigs(this.navConfigs), ['state', state.name]);
            if (_.isUndefined(config))
                return this.getConfig(state.parent);
            else
                return config;
        } else {
            return {
                state: state.name,
                components:[],
                colorName: 'App',
                icon: 'account.svg',
                iconSvg: './account.svg'
            }
        }
    };

    getNavConfig(property, value) {
        return _.find(this.flattenConfigs(this.navConfigs), [
            (_.isUndefined(property) ? 'state' : property),
            (_.isUndefined(value) ? this.$state.current.name : value)
        ]);
    }

    flattenConfigs(configs) {
        return configs.reduce((result, next) => {
            result.push(next);
            if (next.components)
                result = result.concat(this.flattenConfigs(next.components));
            return result;
        }, []);
    }

    getNavigation() {
        const navigation = {};
        const navConfig = this.getConfig(this.$state.$current);
        navigation.color = _.isNull(navConfig) ? '' : navConfig.colorName;
        navigation.title = this.getNavTitle(navConfig);
        if (_.isNull(navConfig))
            navigation.subNavigation = [];
        else {
            const parentNavConfig = this.getNavConfig('id', navConfig.parentId);
            const backState = navConfig.state.includes('public') ? 'public' : 'app';
            navigation.backState = _.isUndefined(parentNavConfig) ? backState : parentNavConfig.state;
            navigation.itemCode = null;

            const state = _.get(this.$state.$current, 'parent.self.component') === 'tabs' ? this.$state.$current.parent.name : this.$state.current.name;
            let children = navConfig.components.filter(item => item.order !== 0);
            if (children.length) {
                navigation.subNavigation = this.filterConfigsByUserType(children, state);
            }
            else {
                if (!_.isUndefined(parentNavConfig)) {
                    navigation.subNavigation = parentNavConfig.components.filter(item => {
                        return state !== item.state && item.order !== 0;
                    });
                    if (navigation.subNavigation.length === 0) {
                        const parParNavConfig = this.getNavConfig('id', parentNavConfig.parentId);
                        if(parParNavConfig) {
                            navigation.subNavigation = parParNavConfig.components.filter(item => {
                                return state !== item.state && item.order !== 0;
                            });
                        }
                        else
                            navigation.enabled = true;
                    }
                    navigation.subNavigation = this.filterConfigsByUserType(navigation.subNavigation, state);
                }
            }
        }
        return navigation;
    };

    filterConfigsByUserType(configs, state) {
        const enabledTiles = _.get(this.userTypeFilters, [state, (this.userType || 'default')]);
        if(enabledTiles && enabledTiles.length) {
            configs = configs.filter(item => enabledTiles.includes(item.code));
        }
        return configs
    }

    getNavTitle(navConfig) {
        if (_.isUndefined(this.$state.params.title)) {
            const titles = [this.getConfigTitle(navConfig)];
            this.extractTitles(titles, navConfig.parentId);
            return titles.join(' » ');
        }
        else return this.$state.params.title;
    }

    extractTitles(titles, configId) {
        if (configId > 1) {
            const config = this.getNavConfig('id', configId);
            const title = this.getConfigTitle(config);
            if(title !== titles[0])
                titles.unshift(title);
            this.extractTitles(titles, config.parentId);
        }
    }

    getConfigTitle(navConfig) {
        if(navConfig.order === 0 && navConfig.components.length && this.$uiRouterGlobals.params.itemCode) {
            const config = _.find(this.flattenConfigs(this.navConfigs), ['code', this.$uiRouterGlobals.params.itemCode]);
            return config?.title;
        }
        else
            return navConfig.title;
    }
}

NavigationService.$inject = ['$ngRedux', '$state', '$uiRouterGlobals'];
