import get from 'lodash/get';

/**
 * Creates a new InventoryScannerController.
 * @class
 */
class InventoryScannerController {
    constructor($ngRedux, $state, NavigationService) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.NavigationService = NavigationService;
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.$ngRedux.dispatch({
            type: 'INIT_MODULE',
            module: 'inventoryScanner',
            color: get(navConfig, 'color', null),
        });
    }
}

InventoryScannerController.$inject = ['$ngRedux', '$state', 'NavigationService'];

export const inventoryScanner = {
    controller: InventoryScannerController,
    template: `
        <form id="scanner" name="scannerForm" layout-padding md-whiteframe="2" novalidate>
            <div class="formHeader">
                <h3>
                    <asset cls="color{{$ctrl.colorName}}" src="{{$ctrl.icon}}"></asset>Inventory Scanner
                </h3>
            </div>
            <div layout-gt-xs="row">
                <div layout="column" flex-gt-xs="80">
                    <select-cascade flex purpose="'aeq'"
                                    identifier="inventoryScanner"
                                    locations="$ctrl.scanner.locations"
                                    validate="true">
                    </select-cascade>
                </div>
                <input-text flex-gt-sm="20" name="accountId" label="Account ID" disabled="true"
                            identifier="scanner" state-path="accountId">
                </input-text>
            </div>
            <div layout="row" layout-align-xs="center center" layout-align-gt-xs="end end">
                <scanner></scanner>
            </div>
        </form>
    `
};
