import moment from 'moment';

/* @ngInject */
export function PersonalEquipmentTestFactory(PersonalEquipmentTester) {
    return function PersonalEquipmentTest() {

        this.id = null;
        this.equipmentId = null;
        this.equipmentType = null;
        this.equipmentLeadEquivalence = null;
        this.annualVisualResults = null;
        this.annualScreening = null;
        this.annualScreeningResult = null;
        this.annualScreeningResultDesc = null;
        this.tester = new PersonalEquipmentTester();
        this.testedDate = null;
        this.dueDate = null;
        this.comments = [];
        this.documents = [];
        this.testDateNotificationFrequency = 18;
        
        this.getTestDate = function() {
            return moment(this.testedDate).format('DD-MM-YYYY');
        };
        
        this. getDueDate = function() {
            return moment(this.dueDate).format('DD-MM-YYYY');
        };

        this.getComments = function() {
            if (this.comments.length)
                return this.comments.map(function(comment) {
                    return comment.text;
                }).join('|');
            else
                return this.commentsText;
        };
        
        this.assignComment = function(comment) {
            if (_.find(this.comments, ['id', comment.id]))
                return false;
            
            this.comments.unshift(comment);
            return true;
        };
        
        this.removeComment = function(commentId) {
            var idx = _.findIndex(this.comments, ['id', commentId]);
            if (idx > -1) this.comments.splice(idx, 1);
        };
        
        this.getCommentIdList = function() {
            return this.comments.map(function(comment) {
                return comment.id;
            }).join();
        };

        this.addDocument = function(document) {
            this.documents.unshift(_.cloneDeep(document));
        };
        
        this.removeDocument = function(documentId) {
            var idx = _.findIndex(this.documents, ['id', documentId]);
            if (idx > -1) this.documents.splice(idx, 1);
        };

        this.getDocumentIdList = function() {
            return this.documents.map(function(document) {
                return document.id;
            }).join();
        };

        this.getEquipmentLeadEquivalence = (function() {
            return this.equipmentLeadEquivalence + ' mm Pb';
        }).bind(this);
    };
}
