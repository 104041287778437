/**
 * @module Employees
 */
import { employees } from './employees.component';
import { employeesDosimetryBadge } from './dosimetry-badge/employees-dosimetry-badge.component';
import { employeesDosimetryBadgesGrid } from './dosimetry-badge/grid/employees-dosimetry-badges-grid.component';
import { employeesDosimetryReportsGrid } from './dosimetry-badge/grid/employees-dosimetry-reports-grid.component';
import { employeesTraining } from './training/employees-training.component';
import { employeesTrainingsGrid } from './training/grid/employees-trainings-grid.component';
import { EMP_IMPORT_MODULE } from "./import/import.module";
import { EMP_IMPORT_SETTINGS_MODULE } from "./import-settings/import-settings.module";
import { EMP_LICENCE_MODULE } from './licence/licence.module';

export const EMPLOYEES_MODULE = angular.module(
    'migration.employees',
    [
        EMP_IMPORT_MODULE.name,
        EMP_IMPORT_SETTINGS_MODULE.name,
        EMP_LICENCE_MODULE.name
    ]
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values({}).map(state => stateRegistry.register(state));
}]).component({
    employees,
    employeesDosimetryBadge,
    employeesDosimetryBadgesGrid,
    employeesDosimetryReportsGrid,
    employeesTraining,
    employeesTrainingsGrid
}).run(['$templateCache', ({ put }) => {
    put('app.employees.dosimetryBadges', '<employees-dosimetry-badges></employees-dosimetry-badges>');
    put('./employees-dosimetry-badge.html', require('./dosimetry-badge/employees-dosimetry-badge.html'));
    put('./employees-training.html', require('./training/employees-training.html'));
    put('./emp-pd-reports-grid-filter-controls.html', require('./dosimetry-badge/grid/emp-pd-reports-grid-filter-controls.html'));
    put('./employees-trainings-grid-filter.html', require('./training/grid/employees-trainings-grid-filter.html'));
}]);
