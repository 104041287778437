import { inputText } from './input/input-text/input-text.component';
import { inputTextArea } from './input/input-text-area/input-text-area.component';
import { inputDate } from './input/input-date/input-date.component';
import { inputDatePeriod } from './input/input-date-period/input-date-period.component';
import { inputEmail } from './input/input-email/input-email.component';
import { inputPhone } from './input/input-phone/input-phone.component';
import { inputMobile } from './input/input-mobile/input-mobile.component';
import { inputFax } from './input/input-fax/input-fax.component';
import { inputPassword } from './input/input-password/input-password.component';
import { inputPasswordStrength } from './input/input-password/input-password-strength.component';
import { inputRadioGroup } from './input/input-radio-group/input-radio-group.component';
import { inputRadioGrid } from './input/input-radio-grid/input-radio-grid.component';
import { inputCheckbox } from './input/input-checkbox/input-checkbox.component';
import { inputCheckboxList } from './input/input-checkbox-list/input-checkbox-list.component';
import { inputSelect } from './input/input-select/input-select.component';
import { inputSelectMultiple } from './input/input-select-multiple/input-select-multiple.component';
import { inputUsername } from './input/input-username/input-username.component';
import { inputCodeScanner } from './input/input-code-scanner/input-code-scanner.component';
import { inputCamera } from './input/input-camera/input-camera.component';
import { inputSignature } from './input/input-signature/input-signature.component';
import { inputDocuments } from './input/input-documents/input-documents.component';
import { autocomplete } from './autocomplete/autocomplete.component';
import { selectGender } from './select/select-gender/select-gender.component';
import { selectPersonalTitle } from './select/select-personal-title/select-personal-title.component';
import { selectCountryState } from './select/select-country-state/select-country-state.component';
import { buttonPasswordChange } from './button/button-password-change/button-password-change.component';
import CountryStateActions from './select/select-country-state/select-country-state.actions';

export const FORM_MODULE = angular.module('common.form', []);

FORM_MODULE.service('CountryStateActions', CountryStateActions);

FORM_MODULE.component('inputText', inputText);
FORM_MODULE.component('inputTextArea', inputTextArea);
FORM_MODULE.component('inputDate', inputDate);
FORM_MODULE.component('inputDatePeriod', inputDatePeriod);
FORM_MODULE.component('inputEmail', inputEmail);
FORM_MODULE.component('inputPhone', inputPhone);
FORM_MODULE.component('inputMobile', inputMobile);
FORM_MODULE.component('inputFax', inputFax);
FORM_MODULE.component('inputPassword', inputPassword);
FORM_MODULE.component('inputPasswordStrength', inputPasswordStrength);
FORM_MODULE.component('inputRadioGroup', inputRadioGroup);
FORM_MODULE.component('inputRadioGrid', inputRadioGrid);
FORM_MODULE.component('inputCheckbox', inputCheckbox);
FORM_MODULE.component('inputCheckboxList', inputCheckboxList);
FORM_MODULE.component('inputSelect', inputSelect);
FORM_MODULE.component('inputSelectMultiple', inputSelectMultiple);
FORM_MODULE.component('inputUsername', inputUsername);
FORM_MODULE.component('inputCodeScanner', inputCodeScanner);
FORM_MODULE.component('inputCamera', inputCamera);
FORM_MODULE.component('inputSignature', inputSignature);
FORM_MODULE.component('inputDocuments', inputDocuments);
FORM_MODULE.component('autocomplete', autocomplete);
FORM_MODULE.component('selectGender', selectGender);
FORM_MODULE.component('selectPersonalTitle', selectPersonalTitle);
FORM_MODULE.component('selectCountryState', selectCountryState);
FORM_MODULE.component('buttonPasswordChange', buttonPasswordChange);
