class ButtonPasswordChangeController {
    constructor($ngRedux, $state, $mdDialog, NavigationService, AdministrationActions, Notifier) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.NavigationService = NavigationService;
        this.AdministrationActions = AdministrationActions;
        this.Notifier = Notifier;
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;

        this.unsubscribe = this.$ngRedux.connect(this.mapState, this.AdministrationActions)(this);
    }

    onClick() {
        const options = {
            template: require('./button-password-change-dialog.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controller: $scope => {
                $scope.colorName = this.colorName;

                $scope.confirm = () => {
                    if ($scope.passwordChangeForm.$valid) {
                        this.changePassword(this.subject.uuid, this.password).then(() => {
                            this.$mdDialog.hide();
                        });
                    }
                    else this.Notifier.error('Please fill in the form fields');
                };

                $scope.cancel = () => {
                    this.$mdDialog.cancel();
                };
            }
        };
        options.controller.$inject = ['$scope'];

        this.dialog = this.$mdDialog.show(options).then(() => {
            this.Notifier.success('Password successfully changed');
        }, () => {
            if (this.password) {
                this.$ngRedux.dispatch({
                    type: 'PASSWORD_RESET',
                    id: 'administrationSettings'
                });
            }
        });
    }

    $onDestroy() {
        this.unsubscribe();
        if (this.dialog)
            this.$mdDialog.hide(this.dialog);
    }

    mapState(state) {
        return {
            password: state.administration.administrationSettings.password
        }
    }
}

ButtonPasswordChangeController.$inject = ['$ngRedux', '$state', '$mdDialog', 'NavigationService', 'AdministrationActions', 'Notifier'];

export const buttonPasswordChange = {
    bindings: {
        btnClass: '@',
        subject: '<'
    },
    controller: ButtonPasswordChangeController,
    template: `
        <button class="{{$ctrl.btnClass}}" ng-click="$ctrl.onClick(); $event.stopPropagation();">
            {{'COMMON.CHANGE_PASSWORD_DIALOG.BUTTON_CHANGE_PASS' | translate}}
        </button>
    `
};
