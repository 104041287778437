/**
 * @module Credentialing
 */
import * as STATES from './credentialing.states';
import * as STATES_PUBLIC from './credentialing.states.public';
import * as VENDORS from './cre-vendors/cre-vendors.states';
import * as VENDORS_PUBLIC from './cre-vendors/cre-vendors.states.public';
import { creVendors } from './cre-vendors/cre-vendors.component';
import { creVendorsIntro } from './cre-vendors/intro/cre-vendors-intro.component';
import { creVendorsDetails } from './cre-vendors/details/cre-vendors-details.component';
import { creVendorsAccounts } from './cre-vendors/details/accounts/cre-vendors-accounts.component';
import { creVendorsFinance } from './cre-vendors/details/finance/cre-vendors-finance.component';
import { creVendorsInsurance } from './cre-vendors/details/insurance/cre-vendors-insurance.component';
import { creVendorsAccStd } from './cre-vendors/details/accreditation-standards/cre-vendors-acc-std.component';
import { creVendorsAccStdGrid } from './cre-vendors/details/accreditation-standards/grid/cre-vendors-acc-std-grid.component';
import { creVendorsGoods } from './cre-vendors/details/goods/cre-vendors-goods.component';
import { creVendorsGoodsGrid } from './cre-vendors/details/goods/grid/cre-vendors-goods-grid.component';
import { creVendorsWarranty } from './cre-vendors/details/warranty/cre-vendors-warranty.component';
import { creVendorsWarrantiesGrid } from './cre-vendors/details/warranty/grid/cre-vendors-waranty-grid.component';
import { creVendorsServices } from './cre-vendors/details/services/cre-vendors-services.component';
import { creVendorsServicesGrid } from './cre-vendors/details/services/grid/cre-vendors-services-grid.component';
import { creVendorsInvitation } from './cre-vendors/invitation/cre-vendors-invitation.component';
import { creVendorsGrid } from './cre-vendors/grid/cre-vendors-grid.component';

export const CREDENTIALING_MODULE = angular.module(
    'migration.credentialing',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values({
        ...STATES,
        ...STATES_PUBLIC,
        ...VENDORS,
        ...VENDORS_PUBLIC,
    }).map(state => stateRegistry.register(state));
}]).component({
    creVendors,
    creVendorsIntro,
    creVendorsDetails,
    creVendorsAccounts,
    creVendorsFinance,
    creVendorsInsurance,
    creVendorsAccStd,
    creVendorsAccStdGrid,
    creVendorsGoods,
    creVendorsGoodsGrid,
    creVendorsWarranty,
    creVendorsWarrantiesGrid,
    creVendorsServices,
    creVendorsServicesGrid,
    creVendorsInvitation,
    creVendorsGrid,
}).run(['$templateCache', ({ put }) => {
    put('public.credentialing.vendors.request.intro', '<cre-vendors-intro></cre-vendors-intro>');
    put('public.credentialing.vendors.request.details', '<cre-vendors-details></cre-vendors-details>');
    put('public.credentialing.vendors.request.accounts', '<cre-vendors-accounts></cre-vendors-accounts>');
    put('public.credentialing.vendors.request.finance', '<cre-vendors-finance></cre-vendors-finance>');
    put('public.credentialing.vendors.request.insurance', '<cre-vendors-insurance></cre-vendors-insurance>');
    put('public.credentialing.vendors.request.accreditationStandards', '<cre-vendors-acc-std></cre-vendors-acc-std>');
    put('public.credentialing.vendors.request.goods', '<cre-vendors-goods></cre-vendors-goods>');
    put('public.credentialing.vendors.request.warranty', '<cre-vendors-warranty></cre-vendors-warranty>');
    put('public.credentialing.vendors.request.services', '<cre-vendors-services></cre-vendors-services>');
    put('app.credentialing.vendors.invitation.request', '<cre-vendors-invitation></cre-vendors-invitation>');
    put('app.credentialing.vendors.manage.listing', '<cre-vendors></cre-vendors>');
    put('app.credentialing.vendors.manage.details', '<cre-vendors-details></cre-vendors-details>');
    put('app.credentialing.vendors.manage.accounts', '<cre-vendors-accounts></cre-vendors-accounts>');
    put('app.credentialing.vendors.manage.finance', '<cre-vendors-finance></cre-vendors-finance>');
    put('app.credentialing.vendors.manage.insurance', '<cre-vendors-insurance></cre-vendors-insurance>');
    put('app.credentialing.vendors.manage.accreditationStandards', '<cre-vendors-acc-std></cre-vendors-acc-std>');
    put('app.credentialing.vendors.manage.goods', '<cre-vendors-goods></cre-vendors-goods>');
    put('app.credentialing.vendors.manage.warranty', '<cre-vendors-warranty></cre-vendors-warranty>');
    put('app.credentialing.vendors.manage.services', '<cre-vendors-services></cre-vendors-services>');
    put('app.credentialing.vendors.alerts.listing', '<cre-vendors></cre-vendors>');
    put('./cre-vendors-grid-filter.html', require('./cre-vendors/grid/cre-vendors-grid-filter.html'));
}]);
