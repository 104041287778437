import watch from 'redux-watch';

/* @ngInject */
export function SelectEquipmentController($ngRedux, MammoQualityControlActions) {
    var ctrl = this;
    var unsubscribe = angular.noop;
    var onChange = angular.noop;

    ctrl.$onInit = function() {

        if (_.isUndefined(ctrl.validate))
            ctrl.validate = false;
        if (_.isUndefined(ctrl.required))
            ctrl.required = false;

        if (ctrl.validate && _.isUndefined(ctrl.name))
            throw new Error('Missing input name attribute');

        unsubscribe = $ngRedux.connect(mapStateToThis, MammoQualityControlActions)(ctrl);

        var watchLocation = watch($ngRedux.getState, ['mammoQualityControl', ctrl.identifier, ctrl.statePath, 'location']);
        onChange = $ngRedux.subscribe(watchLocation(function(location) {
            ctrl.getEquipment(ctrl.identifier, location);
        }));
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onChange();
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            $ngRedux.dispatch({
                type: 'EQUIPMENT_SELECTED',
                id: ctrl.identifier,
                equipment: ctrl.selectedOption,
                formValid: ctrl.form.$valid
            });
        }
    };

    function mapStateToThis(state) {
        return {
            options: state.mammoQualityControl[ctrl.identifier].equipmentList,
            equipmentLocation: state.mammoQualityControl[ctrl.identifier][ctrl.statePath].location,
            selectedOption: state.mammoQualityControl[ctrl.identifier][ctrl.statePath].equipment
        };
    }
}
