import {TabsReducer} from '../../../common/tabs/tabs.reducer';
import {IDS} from './improvement-action-id.constants';

const tabs = [
    {
        state: IDS.app_accreditation_scheme_improvement_list,
        title: 'ACCREDITATION.IMPROVEMENT.LIST.TAB',
        visible: true,
        disabled: false,
        loading: false,
    },
    {
        state: IDS.app_accreditation_scheme_improvement_details,
        title: 'ACCREDITATION.IMPROVEMENT.DETAILS.TAB',
        visible: false,
        disabled: false,
        loading: false
    },
    {
        state: IDS.app_accreditation_scheme_improvement_add,
        title: 'ACCREDITATION.IMPROVEMENT_ADD.TAB',
        visible: false,
        disabled: false,
        loading: false,
    },

];

export const improvementTabs = {
    [IDS.app_accreditation_scheme_improvement]: Object.assign(TabsReducer(), { tabs }),
    // [IDS.public_accreditation_scheme_improvement]: Object.assign(TabsReducer(), { tabs: publicTabs })
}
