import { product } from '../../../../../bootstrap.json';

/* @ngInject */
export function AccEvidenceUploadController($scope, $location, $stateParams, $timeout,
                                     AccreditationDocumentsService, AuthService,
                                     AccAssessmentItem, Notifier,
                                     ResponseStatusService, DocumentManager, DOCUMENT_TYPE) {

    $scope.product = product;
    $scope.credentials = { token: null };
    $scope.accData = null;
    $scope.assItem = new AccAssessmentItem();
    $scope.year = new Date().getFullYear();
    $scope.errorMessage = null;

    if ($stateParams.token) {
        AccreditationDocumentsService.validateAccEvidenceRequest($stateParams.token).then(function (response) {
            if (parseInt(response.data.status) == 0) {
                $scope.credentials.token = $stateParams.token;
                $scope.accData = JSON.parse(response.data.out_param);
                $scope.assItem = new AccAssessmentItem();
                $scope.assItem.id = $scope.accData.aai_id;
                // $scope.assItem.assessmentId = item.aas_id;
                $scope.assItem.ast_id = $scope.accData.ast_id;
                $scope.assItem.assessmentItemType = 'docu';
                $scope.assItem.request = $scope.accData.acc_assessment_item_request;
                $scope.assItem.assessmentItemDocumentType = null;

                //Update data
            }
            else {
                showErrorMessage(response.data.status);
            }
        }, function (error) {
            showErrorMessage(error.data.status);
        });
    }
    else $location.url('login');

    function showInfoMessage(msg, redirect) {
        $scope.errorMessage = msg;
        $scope.accData = null;
        $scope.assItem = new AccAssessmentItem();

        if (redirect) {
            $timeout(function () {
                $scope.errorMessage = null;
                if (redirect) $location.url('login');
            }, 4000);
        }
    }

    function showErrorMessage(status) {
        switch (parseInt(status)) {
            case ResponseStatusService.recoveryTokenExpired.status:
                showInfoMessage('Request token expired. Please require new evidence upload request from your Radiation Safety Cordinator.', true);
                break;
            case ResponseStatusService.recoveryTokenUsed.status:
                showInfoMessage('Request token already used. Please require new evidence upload request from your Radiation Safety Cordinator.', true);
                break;
            case ResponseStatusService.recoveryTokenInvalid.status:
                showInfoMessage('Invalid request token. Please require new evidence upload request from your Radiation Safety Cordinator.', true);
                break;
        }
    }

    $scope.finishUpload = function () {
        if ($stateParams.token) {
            AccreditationDocumentsService.finishAccEvidenceRequest($stateParams.token).then(function (response) {
                Notifier.info('Evidence request closed');
                $scope.accData = null;
                $scope.errorMessage = null;
                $scope.assItem = new AccAssessmentItem();
            }, function(error){
                showErrorMessage(error.data.status);
            });
        }
        else {
            showErrorMessage(status);
        }
    }

    $scope.downloadTemplate = function (items) {
        var templateDocuments = items.split(",");

        templateDocuments.forEach(function(docPath) {
            DocumentManager.downloadFile('/'+docPath, DOCUMENT_TYPE.ACCREDITATION_DOCUMENT);
        });
    }
}
