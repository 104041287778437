import { IDS } from '../../settings-action-id.constants';
import * as SettingsActions from "../../settings.actions";
import * as Actions from '../slo-required-docs.actions';
import {TYPES} from "../../settings-action-type.constants";

/**
 * Creates a new SloRequiredDocAddController.
 * @class
 */
class SloRequiredDocAddController {
    constructor($ngRedux, $state, $stateParams, $scope, $rootScope, $filter, AuthService, Notifier) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.stateName = $state.$current.name;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.AuthService = AuthService;
        this.Notifier = Notifier;
    }

    $onInit() {
        this.identifier = IDS.accSettings;
        this.parentState = IDS.app_accreditation_scheme_settings_slo_required_docs;

        this.tabState = IDS.app_accreditation_scheme_settings_slo_required_docs_add;
        this.title = this.$filter('translate')(`ACCREDITATION.SETTINGS.SLO_REQUIRED_DOCUMENTS.ADD.TITLE`);
        this.unsubscribe = this.$ngRedux.connect(state => {
            const accSettings = state.accreditation.accSettings;
            return {
                document:           accSettings.sloRequiredDocDetails,
                schemeId:           accSettings.schemeDetails.id,
                schemeName:         accSettings.schemeDetails.title,
                standardName:       accSettings.sectionDetails.nodeName,
                standard_id:        accSettings.sectionDetails.id,
                slo_uuid:           accSettings.sloRequiredDocs.slo_uuid,
            }
        }, {
            ...SettingsActions,
            ...Actions,
        })(this);

        this.schemeCode = this.$stateParams.itemCode;

        this.$ngRedux.dispatch({
            type:   TYPES.SLO_DOC_DETAILS_REFRESH,
            id:     IDS.accSettings,
            data:  {
                type_code: 'gdo', // select first item in document types list
                // doc_date: new Date(),
                // due_date: null,
            }
        });
    }

    onSelectHeadOffice({item}){
        this.fetchEnabledSiteLocations(this.schemeId, item.value);
    }

    onSubmitButton(event) {
        this.$scope.$broadcast('show-errors-check-validity', '$ctrl.sloRequiredDocAddForm');
        if (this.sloRequiredDocAddForm.$invalid || !this.slo_uuid) {
            this.Notifier.error('COMMON.FIELDS_REQUIRED');
            return;
        }
        this.addSloRequiredDoc({
            document_type:  this.document.type_code,
            doc_num:        this.document.doc_num,
            name:           this.document.name,
            description:    this.document.description,
            loc_uuid:       this.slo_uuid,
            ast_id:         this.standard_id,
            sample_doc_path: event[0]?.filePath,
        })
    }

    $onDestroy() {
        this.unsubscribe();
    }

}

SloRequiredDocAddController.$inject = ['$ngRedux', '$state', '$stateParams', '$scope', '$rootScope', '$filter', 'AuthService', 'Notifier']

export const sloRequiredDocAdd = {
    controller: SloRequiredDocAddController,
    template: require('./slo-required-doc-add.html')
}
