/**
 * @const AP_INVITATION_RESET
 * @type {string}
 */
export const AP_INVITATION_RESET = 'AP_INVITATION_RESET';

/**
 * @const AP_REQUEST_TOKEN
 * @type {string}
 */
export const AP_REQUEST_TOKEN = 'AP_REQUEST_TOKEN';

/**
 * @const AP_REQUEST_EQUIPMENT
 * @type {string}
 */
export const AP_REQUEST_EQUIPMENT = 'AP_REQUEST_EQUIPMENT';

/**
 * @const AP_REQUEST_RESET
 * @type {string}
 */
export const AP_REQUEST_RESET = 'AP_REQUEST_RESET';

/**
 * @const AP_REQUEST_TYPES
 * @type {string}
 */
export const AP_REQUEST_TYPES = 'AP_REQUEST_TYPES';

/**
 * @const AP_REQUEST_STATUSES
 * @type {string}
 */
export const AP_REQUEST_STATUSES = 'AP_REQUEST_STATUSES';

/**
 * @const AP_REQUEST_FILTER_RENEWAL
 * @type {string}
 */
export const AP_REQUEST_FILTER_RENEWAL = 'AP_REQUEST_FILTER_RENEWAL';

/**
 * @const AP_REQUEST_DETAILS
 * @type {string}
 */
export const AP_REQUEST_DETAILS = 'AP_REQUEST_DETAILS';

/**
 * @const AP_APPLICANT_QUALIFICATION_TYPES
 * @type {string}
 */
export const AP_APPLICANT_QUALIFICATION_TYPES = 'AP_APPLICANT_QUALIFICATION_TYPES';

/**
 * @const AP_APPLICANT_QUALIFICATIONS
 * @type {string}
 */
export const AP_APPLICANT_QUALIFICATIONS = 'AP_APPLICANT_QUALIFICATIONS';

/**
 * @const AP_APPLICANT_SPECIALITIES
 * @type {string}
 */
export const AP_APPLICANT_SPECIALITIES = 'AP_APPLICANT_SPECIALITIES';

/**
 * @const AP_APPLICANT_SPECIALITY_ADD
 * @type {string}
 */
export const AP_APPLICANT_SPECIALITY_ADD = 'AP_APPLICANT_SPECIALITY_ADD';

/**
 * @const AP_PERMIT_HOLDERS
 * @type {string}
 */
export const AP_PERMIT_HOLDERS = 'AP_PERMIT_HOLDERS';

/**
 * @const AP_CONTACT_PERSONS
 * @type {string}
 */
export const AP_CONTACT_PERSONS = 'AP_CONTACT_PERSONS';

/**
 * @const AP_INVITATION_DETAILS
 * @type {string}
 */
export const AP_INVITATION_DETAILS = 'AP_INVITATION_DETAILS';

/**
 * @const AP_RESET_PERMIT_HOLDER
 * @type {string}
 */
export const AP_RESET_PERMIT_HOLDER = 'AP_RESET_PERMIT_HOLDER';
