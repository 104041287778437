/* @ngInject */
export function MammoQualityControlEquipmentFactory() {
    return function MammoQCEquipment() {

        this.equId = null;
        this.serial = null;
        this.typeName = null;
        this.manufacturer = null;
        this.modelName = null;
    };
}
