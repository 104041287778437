export function AccComplaintFeedbackItemFactory() {
    return function AccComplaintFeedbackItem() {

        this.id = null; //acf_id from database
        this.complaintsNumber = null;
        this.customerDetails = null;
        this.detailsCommunication = null;
        this.detailsCorrection = null;
        this.conclusion = null;
        this.completedByUuid = null;
        this.completedBy = null;
        this.dateReceived = null;
        this.dateReceivedStr = null;
        this.closeDate = null;
        this.closeDateStr = null;
        this.status = null;
    };
}
