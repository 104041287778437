import {IDS} from '../../audit-action-id.constants';
import {TYPES} from '../../audit-action-type.constants';
import * as DetailsActions from './details.actions';
import * as AuditActions from '../../audit.actions';
import {TAB_SELECT} from '../../../../../common/tabs/tabs.constants';
import {formatDate} from '../../../../../common/utils/util.date';
import {DATE_FORMAT} from '../../../../../common/utils/util.constants';
import pick from 'lodash/pick';
import get from 'lodash/get';
import {addReviewer} from "./details.actions";

class AuditDetailsController {
    constructor($ngRedux,
                $stateParams,
                $filter,
                Notifier,
                DocumentManager,
                EmployeeService) {
        this.$ngRedux = $ngRedux;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.Notifier = Notifier;
        this.DocumentManager = DocumentManager;
        this.EmployeeService = EmployeeService;
        this.identifier = IDS.accAudit;
        this.selectedFormat = null;
        this.MAX_QUALITY_MANAGERS = 4;

        this.exportFormat = [
            {format: 'pdf',     name: 'PDF'},
            {format: 'csv',     name: 'CSV'},
            {format: 'xls',     name: 'Excel 5'},
            {format: 'xlsx',    name: 'Excel 2007'}
        ]
    }

    $onInit() {
        this.schemeCode = this.$stateParams.itemCode;
        this.unsubscribe = this.$ngRedux.connect(state => {
            const { schedule, details } = state.accreditation.accAudit;
            const {locationName, fiscalYearStart} = state.accreditation.accAudit.siteLocation;
            const {siteLocation, headOffice, year} = state.accreditation.accDashboardLocationYear;

            return {
                standardId:             details.standardId,
                standardName:           details.standardName,
                standardLevel:          details.standardLevel,

                siteLocationName:       locationName,
                siteLocationUuid:       siteLocation,
                fiscalYearStart,
                fiscalYear: year,


                //review
                name:                   details.name,
                description:            details.description,
                date:                   details.date,
                reviewers:              details.reviewers,
                auditId:                details.auditId,
                sourceTab:              details.sourceTab,
                // selectedEvidenceProvider: details.selectedEvidenceProvider,
                selectedReviewer:       details.selectedReviewer,

                //external evidence provider
                // evidenceProviderFirstName:  details.evidenceProviderFirstName,
                // evidenceProviderLastName:   details.evidenceProviderLastName,
                // evidenceProviderEmail:      details.evidenceProviderEmail,

                //external quality manager
                // qualityManagerFirstName:    details.qualityManagerFirstName,
                // qualityManagerLastName:     details.qualityManagerLastName,
                // qualityManagerEmail:        details.qualityManagerEmail,
                // activities:                 details.activities,
            }
        }, { ...DetailsActions, ...AuditActions })(this);

        this.schemeCode = this.$stateParams.itemCode;

        this.title = this.$filter('translate')(`ACCREDITATION.AUDIT.${this.auditId ? 'DETAILS' : 'ADD'}.TAB`);
        this.updateAutocompleteLabel();
    }

    onAddEvidenceProviderAutocomplete(assignee){
        if(!this.evidenceProviders.length) {
            this.addEvidenceProvider({
                ...pick(assignee,['emp_uuid','first_name','last_name','email']),
                assignee: `${assignee.first_name} ${assignee.last_name}`,
            });
        }
    }

    onAddExternalEvidenceProviderButton() {
        if(this.evidenceProviders && this.evidenceProviders.length)
            return;

        if(this.externalEvidenceProviderForm.$valid) {
            this.addEvidenceProvider({
                first_name: this.evidenceProviderFirstName,
                last_name:  this.evidenceProviderLastName,
                email:      this.evidenceProviderEmail,
                assignee:`${this.evidenceProviderFirstName} ${this.evidenceProviderLastName}`,
            });
            this.externalEvidenceProviderForm.$setPristine();
            this.externalEvidenceProviderForm.$setUntouched();
        }
    }

    onAddReviewerButton(){
        if(this.reviewers.length >= this.MAX_QUALITY_MANAGERS){
            this.Notifier.error('COMMON.CLIENT_RESPONSE.UNABLE_TO_ADD_MORE_ITEMS');
            return;
        }
        if(this.reviewers.some(item => item.emp_uuid === this.selectedReviewer.employee_uuid)) {
            this.Notifier.error('COMMON.CLIENT_RESPONSE.ITEM_PRESENT_ALREADY');
            return;
        }

        if(this.selectedReviewer) {
            this.addReviewer({
                emp_uuid:       this.selectedReviewer.employee_uuid,
                first_name:     this.selectedReviewer.first_name,
                last_name:      this.selectedReviewer.last_name,
                email:          this.selectedReviewer.email,
                assignee:    `${this.selectedReviewer.first_name} ${this.selectedReviewer.last_name}`,
                username:       this.selectedReviewer.username,
                type_name:      this.selectedReviewer.subject_type_name,
            });

        }
    }

    onAddExternalQualityManagerButton() {
        if(this.reviewers.length >= this.MAX_QUALITY_MANAGERS){
            this.Notifier.error('COMMON.CLIENT_RESPONSE.UNABLE_TO_ADD_MORE_ITEMS');
            return;
        }
        if(this.reviewers.some(item => item.email === this.qualityManagerEmail)) {
            this.Notifier.error('COMMON.CLIENT_RESPONSE.ITEM_PRESENT_ALREADY');
            return;
        }
        if(this.externalQualityManagerForm.$valid) {
            this.addReviewer({
                first_name: this.qualityManagerFirstName,
                last_name:  this.qualityManagerLastName,
                email:      this.qualityManagerEmail,
                assignee:`${this.qualityManagerFirstName} ${this.qualityManagerLastName}`,
            });
            this.externalQualityManagerForm.$setPristine();
            this.externalQualityManagerForm.$setUntouched();
        }
    }

    onRemoveEvidenceProvider(item) {
        this.$ngRedux.dispatch({
            type: TYPES.REMOVE_AUDIT_ASSIGNEE,
            id: this.identifier,
            path: 'details.evidenceProviders',
            item
        });
    }

    onRemoveReviewer(item) {
        this.$ngRedux.dispatch({
            type: TYPES.REMOVE_AUDIT_ASSIGNEE,
            id: this.identifier,
            path: 'details.reviewers',
            item
        });
    }

    async onEvidenceProviderAutocompleteKeywordQuery (keyword) {
        if(this.isQManagersSwitchOn) {
            return keyword ?
                this.reviewers.filter(this.getFilter(keyword))
                : this.reviewers
        }
        else
            return await this.fetchEvidenceProviderCandidates(keyword, this.siteLocationUuid);
    }

    getFilter(keyword) {
        return item => {
            return `${item.first_name} ${item.last_name} ${item.username}`.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
        }
    }

    updateAutocompleteLabel() {
        const key = this.isQManagersSwitchOn ? 'QUALITY_MANAGERS' : 'EMPLOYEES';
        const addReviewer = this.$filter('translate')('ACCREDITATION.AUDIT.DETAILS.ADD_REVIEWER');
        const searchFrom = this.$filter('translate')(`ACCREDITATION.AUDIT.DETAILS.SEARCH_FROM_${key}`);
        this.autocompleteLabel = `${addReviewer} (${searchFrom})`;
    }

    onSubmit() {
        if(this.reviewers.length === 0) {
            this.Notifier.error('ACCREDITATION.AUDIT.DETAILS.MISSING_QUALITY_MANAGER');
            return;
        }

        if(this.detailsForm.$invalid) {
            this.Notifier.error('COMMON.FIELDS_REQUIRED');
            return;
        }

        this.updateAudit(
            this.auditId,
            formatDate(this.date, DATE_FORMAT.OUTPUT),
            this.reviewers.map(item => item.emp_uuid)
        ).then( () => {
            this.fetchAudits(this.schemeCode, this.siteLocationUuid, this.fiscalYear, this.fiscalYearStart).then(()=>{
                this.$ngRedux.dispatch({
                    type: TAB_SELECT,
                    id: IDS.app_accreditation_scheme_audit_schedule,
                    state: IDS.app_accreditation_scheme_audit_schedule_table,
                })
            });
        });
    }
    $onDestroy() {
        this.unsubscribe();
    }
}

AuditDetailsController.$inject = [
    '$ngRedux',
    '$stateParams',
    '$filter',
    'Notifier',
    'DocumentManager',
    'EmployeeService']

export const accAuditDetails = {
    controller: AuditDetailsController,
    template: require('./details.html')
}
