/**
 * Creates a new AdministrationNotificationSettingsDetailsController.
 * @class
 * @memberOf module:root/legacy/components/administration
 */
class AdministrationNotificationSettingsDetailsController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param $mdDialog {service}
     * @param $timeout {service} {@link https://docs.angularjs.org/api/ng/service/$timeout|AngularJS $timeout}
     * @param NavigationService {service} {@link module:root/common.NavigationService|NavigationService}
     * @param Notifier {service} {@link Notifier}
     * @param AdministrationNotificationSettingsActions {service}
     */
    constructor($ngRedux, $state, $scope, $mdDialog, $timeout, NavigationService, Notifier, AdministrationNotificationSettingsActions) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        this.$timeout = $timeout;
        this.NavigationService = NavigationService;
        this.Notifier = Notifier;
        this.actions = AdministrationNotificationSettingsActions;
    }

    /**
     * @method $onInit
     * @memberof module:root/legacy/components/administration.AdministrationNotificationSettingsDetailsController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);

        this.identifier = 'app.administration.notificationSettings';
        this.state = 'app.administration.notificationSettings.details';
    }

    /**
     * @method manageNotificationSettings
     * @memberOf module:root/components/administration.AdministrationNotificationSettingsDetailsController
     */
    manageNotificationSettings() {
        if (this.notificationSettingsForm.$valid) {
            if (!this.notification.derived) {
                this.editNotificationSettings(this.notification).then(() => {
                    this.Notifier.success('Notification changes has been successfully saved');
                });
            }
            else {
                const confirm = this.$mdDialog.confirm()
                    .title('Are you sure?')
                    .textContent('This Notification settings will override the parent settings and you will need to create new timelins.')
                    .ariaLabel('New Notification Settings')
                    .ok('Yes')
                    .cancel('No');

                this.removeDialog = this.$mdDialog.show(confirm).then(() => {
                    this.addNotificationSettings(this.notification, this.location).then(() => {
                        this.Notifier.success('New Notification has been successfully added');

                        let nextTab = 'app.administration.notificationSettings.timeline';

                        this.$timeout(() => {
                            this.$ngRedux.dispatch({
                                type: 'TAB_SELECT',
                                id: 'app.administration.notificationSettings',
                                state: nextTab
                            });
                        }, 200);
                    });
                });
            }
        }
        else this.Notifier.error('Please fill in the form fields');
    }

    /**
     * @method removeNotificationSettings
     * @memberOf module:root/components/administration.AdministrationNotificationSettingsDetailsController
     */
    removeNotificationSettings() {
        const confirm = this.$mdDialog.confirm()
            .title('Are you sure?')
            .textContent('This Notification settings will be removed and returned to the parent notification settings.')
            .ariaLabel('Notification Settings Remove')
            .ok('Yes')
            .cancel('No');

        this.removeDialog = this.$mdDialog.show(confirm).then(() => {
            this.removeNotification(this.notification).then(() => {
                this.Notifier.success('The notification was successfully reset to the default value');

                let prevTab = 'app.administration.notificationSettings.listing';

                this.$timeout(() => {
                    this.$ngRedux.dispatch({
                        type: 'TAB_SELECT',
                        id: 'app.administration.notificationSettings',
                        state: prevTab
                    });
                }, 200);
            });
        });
    }

    /**
     * @method sendNotificationSettingsSample
     * @memberOf module:root/components/administration.AdministrationNotificationSettingsDetailsController
     */
    sendNotificationSettingsSample() {
        this.sendNotificationSample(this.notification).then(() => {
            this.Notifier.success('Notification sample was sent successfully');
        });
    }

    /**
     * @method mapState
     * @param state {object} Redux Store
     * @return {object}
     * @memberof module:root/components/administration.AdministrationNotificationSettingsDetailsController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapState(state) {
        return {
            notification: state.administration.administrationNotificationSettings,
            location: state.administration.administrationNotificationSettingsGrid.filter.location
        }
    }

    /**
     * @method $onDestroy
     * @memberof module:root/components/administration.AdministrationNotificationSettingsDetailsController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.unsubscribe();
        if (this.removeDialog) this.$mdDialog.hide(this.removeDialog);
        if (this.saveDialog) this.$mdDialog.hide(this.saveDialog);
    }
}

AdministrationNotificationSettingsDetailsController.$inject = ['$ngRedux', '$state', '$scope', '$mdDialog', '$timeout', 'NavigationService', 'Notifier', 'AdministrationNotificationSettingsActions'];

export const administrationNotificationSettingsDetails = {
    controller: AdministrationNotificationSettingsDetailsController,
    template: require('./administration-notification-settings-details.html')
};
