import { product } from '../../bootstrap.json';

class SystemController {
    constructor($log, NavigationService) {
        this.$log = $log;
        this.NavigationService = NavigationService;
        this.product = product;
    }

    $onInit() {
        this.$log.debug('System::init');
        const navConfig = this.NavigationService.getNavConfig();
        this.colorName = navConfig.colorName;
    }
}

SystemController.$inject = ['$log', 'NavigationService'];

export const system = {
    controller: SystemController,
    template: `
        <div class="loader formHeader">
            <asset src="system.png" alt="{{$ctrl.product}}"></asset>
            <div class="color{{$ctrl.colorName}}">Coming soon...</div>
        </div>
    `
};
