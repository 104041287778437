/* @ngInject */
export function MammoQualityControlArtifactEvaluationDetailsController($ngRedux, $state, $uiRouterGlobals, $transitions, $timeout, MammoQualityControlActions, Notifier) {
    var ctrl = this;
    var unsubscribe = angular.noop;
    var onBeforeExit = angular.noop;

    ctrl.$onInit = function() {
        ctrl.identifier = 'mammoQualityControlArtifactEvaluationDetails';
        ctrl.title = 'New Artifact Evaluation Test';

        unsubscribe = $ngRedux.connect(mapStateToThis, MammoQualityControlActions)(ctrl);

        onBeforeExit = $transitions.onBefore({exiting: 'app.mammoQualityControl.artifactEvaluation.details'}, function() {
            ctrl.artifactEvaluationForm.$setPristine();
            ctrl.artifactEvaluationForm.$setUntouched();
        });
    };

    ctrl.manageArtifactEvaluation = function() {
        if (ctrl.artifactEvaluationForm.$valid) {
            ctrl.createArtifactEvaluationDetails(ctrl.artifactEvaluation).then(function() {
                //TODO
            }, function(error) {
                Notifier.responseMessage(error);
            });
        }
        else Notifier.error('Please fill in the form fields');
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onBeforeExit();
    };

    ctrl.selectArtifact = function() {
        if (ctrl.artifactEvaluation.artifacts == 1) {
            return true;
        } else {
            return false;
        }
    };

    ctrl.selectArtifactPhantomPosition2 = function() {
        if (ctrl.artifactEvaluation.artifactsPhantomPosition2 == 1) {
            return true;
        } else {
            return false;
        }
    };

    function mapStateToThis(state) {
        return {
            artifactEvaluation: state.mammoQualityControl.mammoQualityControlArtifactEvaluationDetails.data,
            stateParams: state.mammoQualityControl['app.mammoQualityControl.artifactEvaluation'].stateParams
        };
    }
}
