/**
 * @module root/components/dms
 */
import * as STATES                  from './dms.states';
import { DMS_IMPORT_MODULE }   from "./import/dms-import.module";
import { DMS_MANAGE_MODULE }   from './manage/dms-manage.module';

export const DOCUMENT_MANAGEMENT_MODULE = angular.module(
    'migration.document-management',
    [
        DMS_MANAGE_MODULE.name,
        // DMS_IMPORT_MODULE.name,
    ]
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values(STATES).map(state => stateRegistry.register(state))
}]).component({
}).run(['$templateCache', ({ put }) => {
}]);
