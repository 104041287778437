import API from '../../../common/http/api.fetch';
import {success, responseMessage} from '../../../common/utils/util.notifier';

export const fetchAccreditationRequestDetails = (uta_id) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/request-details',
            method: 'GET',
            params: { uta_id }
        }).then(response => {
            return response;
        },error => {
            responseMessage(error)
        });
    };
};
