/* @ngInject */
export function EmployeesGridSwitchBadgesController($ngRedux) {
    const ctrl = this;
    let unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, {})(ctrl);
        ctrl.label = 'Dosimetry Badges';
    };

    ctrl.switchData = function() {
        $ngRedux.dispatch({
            type: 'EMPLOYEES_SWITCH_BADGES',
            id: ctrl.identifier,
            badges: ctrl.badges || null,
        });
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    function mapStateToThis(state) {
        return {
            badges: state.employees[ctrl.identifier].filter.badges,
        };
    }
}
