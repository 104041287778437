/* @ngInject */
export function PersonalEquipmentTypeFactory() {
    return function PersonalEquipmentType() {

        this.id = null;
        this.parentId = null;
        this.level = null;
        this.name = null;
        this.testing = 0;
        this.children = [];
    };
}
