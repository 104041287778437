import { TAB_SHOW } from '../../common/tabs/tabs.constants';

/**
 * Creates a new EquipmentCalibrationController.
 * @class
 */
class EquipmentCalibrationController {
    constructor($ngRedux, $state, $mdDialog, $rootScope, NavigationService, EquipmentCalibrationActions, EquipmentManager) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.$rootScope = $rootScope;
        this.NavigationService = NavigationService;
        this.actions = EquipmentCalibrationActions;
        this.EquipmentManager = EquipmentManager;
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);

        this.getTypes('equipment');
        this.getManufacturers();
    }

    onEquipmentSelect = (event) => {
        this.getEquipmentDetails(event.item.id).then(() => {
            let tabs = [
                'app.equipmentCalibration.manage.info',
                'app.equipmentCalibration.manage.details',
                'app.equipmentCalibration.manage.assetCost',
                'app.equipmentCalibration.manage.documents',
                'app.equipmentCalibration.manage.vendors'
            ];

            switch (this.equipment.typeCode) {
                case 'asm':
                    if (this.equipment.detector) {
                        tabs = [...tabs, 'app.equipmentCalibration.manage.detectorProbe'];
                    }
                    break;
                case 'mfm':
                case 'dom':
                    tabs = [...tabs, 'app.equipmentCalibration.manage.detectors'];
                    break;
                case 'dca':
                case 'wco':
                    tabs = [...tabs, 'app.equipmentCalibration.manage.chambers'];
                    break;
            }

            this.$ngRedux.dispatch({
                type: TAB_SHOW,
                id: 'app.equipmentCalibration.manage',
                state: tabs
            });
        });
    };

    onEquipmentLoan = ({ item }) => {
        this.getEquipmentDetails(item.id).then(() => {
            this.$ngRedux.dispatch({
                type: TAB_SHOW,
                id: 'app.equipmentCalibration.manage',
                state: [
                    'app.equipmentCalibration.manage.loan',
                    'app.equipmentCalibration.manage.loanHistory'
                ]
            });
        });
    };

    onEquipmentRemove = (event) => {
        const confirm = this.$mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Chosen equipment will be removed permanently.')
            .ariaLabel('Remove equipment')
            .ok('Yes')
            .cancel('No');

        this.removeDialog = this.$mdDialog.show(confirm).then(() => {
            this.removeEquipment(event.item.id);
        });
    };

    onEquipmentDispose = function(event) {
        this.EquipmentManager.onDispose('equipmentCalibrationGrid', event.item, 'equipmentCalibration');
    };

    onEquipmentStore = (event) => {
        const confirm = this.$mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Chosen equipment will be stored permanently.')
            .ariaLabel('Store equipment')
            .ok('Yes')
            .cancel('No');

        this.storeDialog = this.$mdDialog.show(confirm).then(() => {
            this.storeEquipment(event.item.id);
        });
    };

    onGridReset = (event) => {
        this.$rootScope.$broadcast('SELECT_CASCADE_RESET', 'equipmentFilterForm');
    };

    mapState(state) {
        return {
            equipment: state.equipmentCalibration.equipment
        };
    }

    $onDestroy() {
        this.unsubscribe();
        if (this.removeDialog)
            this.$mdDialog.hide(this.removeDialog);
        if (this.storeDialog)
            this.$mdDialog.hide(this.storeDialog);
    }
}

EquipmentCalibrationController.$inject = ['$ngRedux', '$state', '$mdDialog', '$rootScope', 'NavigationService', 'EquipmentCalibrationActions', 'EquipmentManager'];

export const equipmentCalibration = {
    controller: EquipmentCalibrationController,
    template: `
        <equipment-calibration-grid 
            on-select="$ctrl.onEquipmentSelect($event)"
            on-loan="$ctrl.onEquipmentLoan($event)"
            on-dispose="$ctrl.onEquipmentDispose($event)"
            on-reset="$ctrl.onGridReset($event)"
            on-store="$ctrl.onEquipmentStore($event)">
        </equipment-calibration-grid>
    `
};
