import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsSiteLocationQualityManagersController($ngRedux, $uiRouterGlobals, $mdDialog, AdministrationActions) {
    var ctrl = this;
    var dialog = null;
    var unsubscribe = angular.noop;
    var onQualityManager = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);

        var watchQualityManager = watch($ngRedux.getState, 'administration.administrationAccountsSiteLocationQualityManager.data.uuid');
        onQualityManager = $ngRedux.subscribe(watchQualityManager(function(uuid) {
            onQualityManagerReady(uuid);
        }));
    };

    ctrl.newQualityManager = function() {
        if (ctrl.qualityManager.uuid) {
            $ngRedux.dispatch({
                type: 'QUALITY_MANAGER_RESET',
                id: 'administrationAccountsSiteLocationQualityManager'
            });
        }
        $ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: 'app.administration.accounts.siteLocations',
            state: ['app.administration.accounts.siteLocations.qualityManagerDetails'],
            stateParams: $uiRouterGlobals.params
        });
    };

    ctrl.selectQualityManager = function(event) {
        if (event.item.uuid === ctrl.qualityManager.uuid)
            onQualityManagerReady(event.item.uuid);
        else
            ctrl.getQualityManagerDetails('administrationAccountsSiteLocationQualityManager', event.item.uuid);
    };

    ctrl.removeQualityManager = function(event) {
        var confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Quality Manager will be deactivated.')
            .ariaLabel('Deactivate Quality Manager')
            .ok('Yes')
            .cancel('No');

        dialog = $mdDialog.show(confirm).then(function() {
            event.item.location = ctrl.siteLocation.uuid;
            ctrl.rejectQualityManager(event.item, 'administrationAccountsSiteLocationQualityManager', 'administrationAccountsSiteLocationQualityManagersGrid');
        }, angular.noop);
    };

    ctrl.$onDestroy = function() {
        if (dialog) $mdDialog.hide(dialog);
        unsubscribe();
        onQualityManager();
    };

    function onQualityManagerReady(uuid) {
        $ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: 'app.administration.accounts.siteLocations',
            state: ['app.administration.accounts.siteLocations.qualityManagerDetails'],
            stateParams: {
                hof: $uiRouterGlobals.params.hof,
                slo: $uiRouterGlobals.params.slo,
                qualityManager: uuid
            }
        });
        $ngRedux.dispatch({
            type: 'TAB_LOADING',
            id: 'app.administration.accounts.siteLocations',
            state: 'app.administration.accounts.siteLocations.qualityManagerDetails',
            loading: false
        });
    }

    function mapStateToThis(state) {
        return {
            siteLocation: state.administration.administrationAccountsSiteLocation.data,
            qualityManager: state.administration.administrationAccountsSiteLocationQualityManager.data,
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                'Quality Managers'
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
