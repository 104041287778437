/**
 * @module root/legacy/components
 */
import './common/utils/util.constants';
import './common/form/select/select-cascade';

import './components/administration/administration.module';
import './components/administration/administration.component';

import './components/manufacturers/manufacturers.module';
import './components/manufacturers/manufacturers.component';

import './components/equipment/equipment.module';
import './components/equipment/equipment.component';

import './components/mammo-quality-control/mammo-quality-control.module';
import './components/mammo-quality-control/mammo-quality-control.component';

import './components/accreditation/accreditation.module';
import './components/accreditation/accreditation.component';

import './components/employees/employees.module';
import './components/employees/employees.component';

import './components/safety-plan/safety-plan.module';
import './components/safety-plan/safety-plan.component';

import './components/service-contracts/service-contracts.module';
import './components/service-contracts/service-contracts.component';

import './components/protective-equipment/protective-equipment.module';
import './components/protective-equipment/protective-equipment.component';

import './components/safety-training/safety-training.module';
import './components/safety-training/safety-training.component';

import './components/help/help.module';
import './components/help/help.component';

import { ApplicationModuleController } from "./components.controller";
import { DownloadFileController } from './common/file/download/download-file-controller';
import { UploadController } from './common/file/upload/upload.controller';
import { AccEvidenceUploadController } from './common/file/upload/accreditation/accreditation-evidence-upload-controller';
import { DocumentService } from './common/file/document/document-service';
import { DocumentManager } from './common/file/document/document-manager';
import { NetSpeedService } from './common/http/net-speed-service';
import { HelpersFactory } from './common/utils/util.helpers.factory';
import { FileDocumentFactory } from './common/file/file-document.factory';
import { FileImageFactory } from './common/file/file-image.factory';
import { GridFactory } from './common/grid/grid.factory';
import { GridFilterFactory } from './common/grid/grid-filter.factory';
import { kdValidate } from './common/form/validation/validate-input';
import { kdValidateSelect } from './common/form/validation/validate-select';
import { kdValidateUsername } from './common/form/validation/validate-username';
import { kdValidatePassword } from './common/form/validation/validate-password';
import { datePicker } from './common/form/date-picker/date-picker';
import { timePicker } from './common/form/time-picker/time-picker';
import { selectCountry } from './common/form/select/select-country';
import { autocompleteEquipmentModel } from './common/form/autocomplete/autocomplete-equipment-model';
import { autocompleteEmployeeQualification } from './common/form/autocomplete/autocomplete-employee-qualification';
import { fileDownload } from './common/file/download/directives/file-download';
import { fileUploader } from './common/file/upload/directives/file-uploader';
import { upload } from './common/file/upload/upload.component';

export const APP_LEGACY = angular.module('components', [
    'components.constants',
    'components.common',
    'components.administration',
    'components.manufacturers',
    'components.equipment',
    'components.mammo-quality-control',
    'components.accreditation',
    'components.employees',
    'components.safety-plan',
    'components.service-contracts',
    'components.protective-equipment',
    'components.safety-training',
    'components.help',
]).config(($stateProvider) => {
    $stateProvider
        .state('app.download', {
            url: '/download/*document',
            template: require('./common/file/download/download-file.html'),
            controller: 'DownloadFileController'
        })
        .state('upload', {
            abstract: true,
            url: '/upload'
        })
        .state('upload.acc-evidence', {
            url: '/acc-evidence/:token',
            template: require('./common/file/upload/accreditation/accreditation-evidence-upload.html'),
            controller: 'AccEvidenceUploadController'
        });
}).controller({
    ApplicationModuleController,
    DownloadFileController,
    UploadController,
    AccEvidenceUploadController,
}).service({
    DocumentService,
    DocumentManager,
    NetSpeedService
}).factory({
    Helpers: HelpersFactory,
    FileDocument: FileDocumentFactory,
    FileImage: FileImageFactory,
    Grid: GridFactory,
    GridFilter: GridFilterFactory
}).component({
    upload
}).directive({
    kdValidate,
    kdValidateSelect,
    kdValidateUsername,
    kdValidatePassword,
    datePicker,
    timePicker,
    selectCountry,
    autocompleteEquipmentModel,
    autocompleteEmployeeQualification,
    fileDownload,
    fileUploader
});
