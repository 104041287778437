
import {IDS} from "./rep-monthly-action-id.constants";
import {TAB_HIDE, TAB_DISABLE} from '../../../common/tabs/tabs.constants';

const QUERY_PARAMS = '?id';

export const repMonthlyTabsState = {
    name: IDS.app_reporting_monthly,
    redirectTo: IDS.app_reporting_monthly_list,
    url: '/rep-monthly',
    component: 'tabs'
};

export const repMonthlyListState = {
    name: IDS.app_reporting_monthly_list,
    url: '/list',
    // component: 'repMonthlyList',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: IDS.app_reporting_monthly,
            state: [
                IDS.app_reporting_monthly_details,
            ]
        });
    }
};
repMonthlyListState.onEnter.$inject = ['$ngRedux'];

export const repMonthlyDetailState = {
    name: IDS.app_reporting_monthly_details,
    url: `/details${QUERY_PARAMS}`,
    // component: 'repMonthlyDetails',
};

