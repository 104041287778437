import { tabs } from './safety-training.tabs';

export default {
    ...tabs,
    stVideo: {
        token: null,
        completion: 0,
        progress: null,
        trainings: [],
        applicant_signature: null,
        applicant_signature_date: new Date(),
        candidateReview: null,
        training_id: null
    }
};
