import { TabsReducer } from '../../common/tabs/tabs.reducer';
import { GridReducer } from '../../common/grid/grid.reducer';

export default {
    'app.events.addSafetyRiskPeople': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.events.addSafetyRiskPeople.details',
                title: 'Identify',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Add Event'}
                }
            },
            {
                state: 'app.events.addSafetyRiskPeople.assessmentHazard',
                title: 'Assessment',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: 'app.events.addSafetyRiskPeople.assessmentIncident',
                title: 'Assessment',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: 'app.events.addSafetyRiskPeople.assessmentHistoricalRecord',
                title: 'Historical Record',
                visible: false,
                disabled: false,
                loading: false,
            },
            {
                state: 'app.events.addSafetyRiskPeople.alertEscalate',
                title: 'Alert/Escalate',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Send Notification'}
                }
            },
            {
                state: 'app.events.addSafetyRiskPeople.actionPlan',
                title: 'Action Plan',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save Action Plan'}
                }
            },
            {
                state: 'app.events.addSafetyRiskPeople.actionPlanLibrary',
                title: 'Action Plan Library',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'New Action Plan'}
                }
            },
            {
                state: 'app.events.addSafetyRiskPeople.actionTaskLibrary',
                title: 'Action Task Library',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'New Action Task'}
                }
            }
        ]
    },
    'app.events.addOperationalRiskBusiness': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.events.addOperationalRiskBusiness.details',
                title: 'Identify',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Add Event'}
                }
            },
            {
                state: 'app.events.addOperationalRiskBusiness.assessmentThreat',
                title: 'Assessment',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: 'app.events.addOperationalRiskBusiness.assessmentIssue',
                title: 'Assessment',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: 'app.events.addOperationalRiskBusiness.assessmentHistoricalRecord',
                title: 'Historical Record',
                visible: false,
                disabled: false,
                loading: false,
            },
            {
                state: 'app.events.addOperationalRiskBusiness.alertEscalate',
                title: 'Alert/Escalate',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Send Notification'}
                }
            },
            {
                state: 'app.events.addOperationalRiskBusiness.actionPlan',
                title: 'Action Plan',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save Action Plan'}
                }
            },
            {
                state: 'app.events.addOperationalRiskBusiness.actionPlanLibrary',
                title: 'Action Plan Library',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'New Action Plan'}
                }
            },
            {
                state: 'app.events.addOperationalRiskBusiness.actionTaskLibrary',
                title: 'Action Task Library',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'New Action Task'}
                }
            }
        ]
    },

    'app.events.addRadiationRisk': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.events.addRadiationRisk.details',
                title: 'Identify',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Add Event'}
                }
            },
            {
                state: 'app.events.addRadiationRisk.assessmentHazard',
                title: 'Assessment',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: 'app.events.addRadiationRisk.assessmentIncident',
                title: 'Assessment',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: 'app.events.addRadiationRisk.assessmentHistoricalRecord',
                title: 'Historical Record',
                visible: false,
                disabled: false,
                loading: false,
            },
            {
                state: 'app.events.addRadiationRisk.alertEscalate',
                title: 'Alert/Escalate',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Send Notification'}
                }
            },
            {
                state: 'app.events.addRadiationRisk.actionPlan',
                title: 'Action Plan',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save Action Plan'}
                }
            },
            {
                state: 'app.events.addRadiationRisk.actionPlanLibrary',
                title: 'Action Plan Library',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'New Action Plan'}
                }
            },
            {
                state: 'app.events.addRadiationRisk.actionTaskLibrary',
                title: 'Action Task Library',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'New Action Task'}
                }
            }
        ]
    },
    'app.events.manageRisksIncidents': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.events.manageRisksIncidents.listing',
                title: 'Manage Risks / Incidents',
                visible: true,
                disabled: false,
                loading: false
            },
            {
                state: 'app.events.manageRisksIncidents.details',
                title: 'Identify',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Modify'}
                }
            },
            {
                state: 'app.events.manageRisksIncidents.assessmentHazard',
                title: 'Assessment',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: 'app.events.manageRisksIncidents.assessmentIncident',
                title: 'Assessment',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: 'app.events.manageRisksIncidents.assessmentThreat',
                title: 'Assessment',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: 'app.events.manageRisksIncidents.assessmentIssue',
                title: 'Assessment',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: 'app.events.manageRisksIncidents.assessmentHistoricalRecord',
                title: 'Historical Record',
                visible: false,
                disabled: false,
                loading: false,
            },
            {
                state: 'app.events.manageRisksIncidents.alertEscalate',
                title: 'Alert/Escalate',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Send Notification'}
                }
            },
            {
                state: 'app.events.manageRisksIncidents.actionPlan',
                title: 'Action Plan',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save Action Plan'}
                }
            },
            {
                state: 'app.events.manageRisksIncidents.actionPlanLibrary',
                title: 'Action Plan Library',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'New Action Plan'}
                }
            },
            {
                state: 'app.events.manageRisksIncidents.actionTaskLibrary',
                title: 'Action Task Library',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'New Action Task'}
                }
            }
        ]
    },
    eventItem: {
        id: null,
        accountId: null,
        visualId: null,
        location: null,
        locationName: null,
        locationCode: null,
        locations: [],
        additional_area: null,
        date: null,
        event: null,
        risk_name: null,
        risk_code: null,
        riskTypes: [
            { value: 'srp', label: 'Safety Risk - People' },
            { value: 'orb', label: 'Operational Risk - Business' },
            { value: 'arr', label: 'Radiation Risk' }
        ],
        event_type: null,
        event_code: null,
        eventTypes: [],
        event_subtype: null,
        eventSubtypes: [],
        event_category: null,
        eventCategories: [],
        event_subcategory: null,
        eventSubcategories: [],
        consequence_level: null,
        consequenceLevels: [],
        likelihood_estimate: null,
        likelihoodEstimates: [],
        risk_rating: null,
        sac_score: null,
        followup_period: null,
        responsible_person: null,
        responsiblePersons: [],
        event_location: null,
        event_location_desc: null,
        eventLocations: [],
        event_harm: null,
        event_details: null,
        eventHarmData: [{value: 1, label: 'Yes'}, {value: 0, label: 'No'}],
        'eventInvolvedPerson.person_type_id': null,
        'eventInvolvedPerson.age_group_id': null,
        'eventInvolvedPerson.first_name': null,
        'eventInvolvedPerson.last_name': null,
        'eventInvolvedPerson.mrn': null,
        'eventInvolvedPerson.email': null,
        'eventInvolvedPerson.phone': null,
        eventInvolvedPersonTypes: [],
        eventInvolvedPersonAgeGroups: [],
        eventInvolvedPersons: [],
        camera: null,
        gallery: [],
        image_urls: [],
        document: null,
        document_urls: [],
        signature: null,
        signature_date: null,
    },
    eventsGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0
    }
};
