/* @ngInject */
export function SafetyPlanReviewReportController($scope, $q, $state, SafetyTrainingService, Notifier, SafetyPlanService) {

    $scope.currentPage = 1;
    $scope.pageSize = 10;

    $scope.selectedItem = null;
    $scope.certificates = null;

    $scope.trainingName = $state.current.trainingName;

    $scope.selectItem = function (item) {
        $scope.selectedItem = item;
    };

    switch ($state.current.trainingType) {
        case 'rspp':
            $scope.colorClass = 'colorRadiationSafetyPlan';
            break;
        case 'lspp':
            $scope.colorClass = 'colorLaserSafetyPlan';
            break;
        case 'training_radiation':
            $scope.colorClass = 'colorRadiationSafetyTraining';
            break;
        case 'training_laser':
            $scope.colorClass = 'colorLaserSafetyTraining';
            break;
        default:
            $scope.colorClass = 'colorRadiationSafetyPlan';
    }

    $scope.fetchData = function () {
        $scope.selectedItem = null;
        $q.all([
            SafetyPlanService.fetchEmployeeCertificates($state.current.trainingType)
                .then(function (data) {
                    $scope.certificates = data.list.reverse();
                })
        ]).then(function () {});
    };

    $scope.getCertificate = function (item) {
        SafetyTrainingService.generateCertificate($state.current.trainingType, item.reviewId).then(
            () => {},
            () => Notifier.error("Error generating certificate!"),
        );
    };

    $scope.fetchData();
}
