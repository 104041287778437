import { IDS } from '../adm-categorisation-action-id.constants';
import * as CentersActions from './adm-cat-centers.actions';
import * as GRID from "../../../../common/grid/grid.constants";

/**
 * Creates a new AdmCatCentersController.
 * @class
 */
class AdmCatCentersController {
    constructor($ngRedux, $state, $scope, $mdDialog, $uiRouterGlobals, Notifier, NavigationService) {
        this.$ngRedux           = $ngRedux;
        this.$state             = $state;
        this.$scope             = $scope;
        this.$uiRouterGlobals   = $uiRouterGlobals;
        this.$mdDialog          = $mdDialog;
        this.Notifier           = Notifier;
        this.NavigationService  = NavigationService;
    }

    $onInit() {
        this.module         = 'administration';
        this.identifier     = IDS.admCategorisation;
        this.statePath      = IDS.admCatCentersGrid;
        this.unsubscribe    = this.$ngRedux.connect(
            state => {
                const admCategorisation = state.administration.admCategorisation;

                return {
                    admCategorisation,
                }
            },
            CentersActions
        )(this);

        const navConfig = this.NavigationService.getConfig(this.$state.$current)
        this.colorName = navConfig.colorName;
    }

    onAssignButton() {
        if(this.selectedHofUsedAlready === false) {
            this.assignSchemeToSiteLocation(this.schemeId, this.selectedHeadOfficeUuid).then(() => {
                this.getNotEnabledHeadOffices(this.schemeId);
            });
        }
    }

    onSelect(event) {
        this.showSiteLocationsTab(event.item);
    }

    onRemove(event) {
        if(this.isSchemeStatusPending === false) {
            this.Notifier.warn('ACCREDITATION.SETTINGS.HEAD_OFFICES.UNABLE_TO_REMOVE_HEAD_OFFICE_AFTER_SCHEME_WAS_ACTIVATED');
            return
        }
        const confirm = this.$mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Chosen Head Office location will be removed from scheme.')
            .ariaLabel('Remove Head Office location')
            .ok('Yes')
            .cancel('No');
        this.dialog = this.$mdDialog.show(confirm).then(() => {
            this.detachSiteLocationFromScheme({...event.item, schemeId: this.schemeId}).then(() => {
                this.getNotEnabledHeadOffices(this.schemeId);
            });
        })
    }

    $onDestroy() {
        this.unsubscribe();
        if (this.dialog)
            this.$mdDialog.hide(this.dialog);
    }
}

AdmCatCentersController.$inject = ['$ngRedux', '$state', '$scope', '$mdDialog', '$uiRouterGlobals', 'Notifier', 'NavigationService']

export const admCatCenters = {
    controller: AdmCatCentersController,
    template: `
        <adm-cat-centers-grid
            module="$ctrl.module"
            identifier="$ctrl.identifier"
            state-path="$ctrl.statePath"
            on-select="$ctrl.onSelect($event)"
            on-remove="$ctrl.onRemove($event)">
        </adm-cat-centers-grid>`,
}
