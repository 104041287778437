export const creVendorsRequestPublicState = {
    name: 'public.credentialing.vendors.request',
    redirectTo: 'public.credentialing.vendors.request.intro',
    url: '/request',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        // TODO
    }
};

export const creVendorsRequestIntroPublicState = {
    name: 'public.credentialing.vendors.request.intro',
    url: '?token&invite',
    component: 'creVendorsIntro'
};

export const creVendorsRequestDetailsPublicState = {
    name: 'public.credentialing.vendors.request.details',
    url: '?token&invite',
    component: 'creVendorsDetails'
};

export const creVendorsRequestAccountsPublicState = {
    name: 'public.credentialing.vendors.request.accounts',
    url: '?token&invite',
    component: 'creVendorsAccounts'
};

export const creVendorsRequestFinancePublicState = {
    name: 'public.credentialing.vendors.request.finance',
    url: '?token&invite',
    component: 'creVendorsFinance'
};

export const creVendorsRequestInsurancePublicState = {
    name: 'public.credentialing.vendors.request.insurance',
    url: '?token&invite',
    component: 'creVendorsInsurance'
};

export const creVendorsRequestAccreditationStandardsPublicState = {
    name: 'public.credentialing.vendors.request.accreditationStandards',
    url: '?token&invite',
    component: 'creVendorsAccreditationStandards'
};

export const creVendorsRequestGoodsPublicState = {
    name: 'public.credentialing.vendors.request.goods',
    url: '?token&invite',
    component: 'creVendorsGoods'
};

export const creVendorsRequestWarrantyPublicState = {
    name: 'public.credentialing.vendors.request.warranty',
    url: '?token&invite',
    component: 'creVendorsWarranty'
};

export const creVendorsRequestServicesPublicState = {
    name: 'public.credentialing.vendors.request.services',
    url: '?token&invite',
    component: 'creVendorsServices'
};
