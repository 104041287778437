export const reportingEquipmentInspectionState = {
    name: 'app.reporting.inspection',
    redirectTo: 'app.reporting.inspection.listing',
    url: '/equipment-inspection',
    component: 'tabs'
};

export const reportingEquipmentInspectionListingState = {
    name: 'app.reporting.inspection.listing',
    url: '',
    component: 'reportingEquipmentInspection'
};
