import * as TYPE from './cre-vendors.actions.types';

export const creVendorsInvitationState = {
    name: 'app.credentialing.vendors.invitation',
    redirectTo: 'app.credentialing.vendors.invitation.request',
    url: '/request',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: TYPE.CRE_INVITE_INIT,
            id: 'creInvite',
        });
    }
};

export const creVendorsInvitationRequestState = {
    name: 'app.credentialing.vendors.invitation.request',
    url: '',
    component: 'creVendorsInvitation'
};

export const creVendorsManageState = {
    name: 'app.credentialing.vendors.manage',
    redirectTo: 'app.credentialing.vendors.manage.listing',
    url: '/manage',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        // TODO
    }
};

export const creVendorsManageListingState = {
    name: 'app.credentialing.vendors.manage.listing',
    url: '',
    component: 'creVendorsGrid',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.credentialing.vendors.manage',
            state: [
                'app.credentialing.vendors.manage.details',
                'app.credentialing.vendors.manage.accounts',
                'app.credentialing.vendors.manage.finance',
                'app.credentialing.vendors.manage.insurance',
                'app.credentialing.vendors.manage.accreditationStandards',
                'app.credentialing.vendors.manage.goods',
                'app.credentialing.vendors.manage.warranty',
                'app.credentialing.vendors.manage.services',
            ],
        });
    },
};

export const creVendorsManageRequestDetailsState = {
    name: 'app.credentialing.vendors.manage.details',
    url: '/details?id',
    component: 'creVendorsDetails'
};

export const creVendorsManageRequestAccountsState = {
    name: 'app.credentialing.vendors.manage.accounts',
    url: '/accounts?id',
    component: 'creVendorsAccounts'
};

export const creVendorsManageRequestFinanceState = {
    name: 'app.credentialing.vendors.manage.finance',
    url: '/finance?id',
    component: 'creVendorsFinance'
};

export const creVendorsManageRequestInsuranceState = {
    name: 'app.credentialing.vendors.manage.insurance',
    url: '/insurance?id',
    component: 'creVendorsInsurance'
};

export const creVendorsManageRequestAccStandardsState = {
    name: 'app.credentialing.vendors.manage.accreditationStandards',
    url: '/accreditation-standards?id',
    component: 'creVendorsAccreditationStandards'
};

export const creVendorsManageRequestGoodsState = {
    name: 'app.credentialing.vendors.manage.goods',
    url: '/goods?id',
    component: 'creVendorsGoods'
};

export const creVendorsManageRequestWarrantyState = {
    name: 'app.credentialing.vendors.manage.warranty',
    url: '/warranty?id',
    component: 'creVendorsWarranty'
};

export const creVendorsManageRequestServiceState = {
    name: 'app.credentialing.vendors.manage.services',
    url: '/services?id',
    component: 'creVendorsServices'
};

export const creVendorsAlertsState = {
    name: 'app.credentialing.vendors.alerts',
    redirectTo: 'app.credentialing.vendors.alerts.listing',
    url: '/manage',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        // TODO
    }
};

export const creVendorsAlertsListingState = {
    name: 'app.credentialing.vendors.alerts.listing',
    url: '',
    component: 'creVendors'
};
