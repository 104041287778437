import { product } from '../../bootstrap.json';
import { INIT_MODULE, NAVIGATION_STATE } from './navigation.actions.types';
import get from 'lodash/get';

class NavigationController {
    constructor($log, $ngRedux, $state, $uiRouterGlobals, $transitions, AuthService, NavigationService, Notifier) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.$transitions = $transitions;
        this.AuthService = AuthService;
        this.NavigationService = NavigationService;
        this.Notifier = Notifier;
        this.product = product;
    }

    $onInit() {
        this.$log.debug('Nav::init');
        this.unsubscribe = this.$ngRedux.connect(this.mapStateToThis, {})(this);

        this.userInfo = this.AuthService.getUserInfo();
        this.isUserAdmin = this.AuthService.getUser().typeCode === 'adm';
        this.navigation = this.NavigationService.getNavigation();
        this.minimizeSubmenu = this.$uiRouterGlobals.params.minimizeSubmenu;
        this.homeState = this.$state.$current.name.includes('public') ? 'public' : 'app';

        this.onAppTransitionSuccess = this.$transitions.onSuccess({to: 'app.**'}, (trans) => {
            // TODO consider refactoring init module
            if (
                trans.$from().name === 'app.personalDosimetry.reports.detailsResult' &&
                trans.$to().name === 'app.employees.manage'
            ) {
                this.$ngRedux.dispatch({
                    type: INIT_MODULE,
                    module: 'employees',
                    color: 'e60084',
                });
            }
            this.$ngRedux.dispatch({
                type: NAVIGATION_STATE,
                state: trans.router.globals.current.name,
            });
            this.navigation = this.NavigationService.getNavigation();
            this.minimizeSubmenu = this.$uiRouterGlobals.params.minimizeSubmenu;
        });
        this.onPublicTransitionSuccess = this.$transitions.onSuccess({to: 'public.**'}, (trans) => {
            this.$ngRedux.dispatch({
                type: NAVIGATION_STATE,
                state: trans.router.globals.current.name,
            });
            this.navigation = this.NavigationService.getNavigation();
            this.minimizeSubmenu = this.$uiRouterGlobals.params.minimizeSubmenu;
        });
    }

    /**
     * Get itemCode from tile definition to be optionally used as part of url.
     * After any state will save itemCode, all nested states will use that one.
     */
    navigateToState(state, itemCode = null) {
        if (state === 'logout') {
            this.AuthService.logout().then(() => {
                this.$state.go('login.auth', {reset: true});
            }, error => {
                this.Notifier.responseMessage(error);
            });
        }
        else {
            const params = {...this.$uiRouterGlobals.params};
            params.itemCode = params.itemCode || itemCode;
            this.$state.go(state, params, {reload: state});
        }
    }

    navigateToSubState(state, itemCode) {
        const params = {...this.$uiRouterGlobals.params};
        params.itemCode = params.itemCode || itemCode;
        this.$state.go(state, params, {reload: this.getReloadState(this.$state.$current)});
    }

    getReloadState(state) {
        if (state.name === 'app' || state.name === 'public')
            return this.$state.$current.parent.name;
        else {
            if (get(state.parent, 'self.component') === 'tabs')
                return state.parent.name;
            else
                return this.getReloadState(state.parent);
        }
    }

    $onDestroy() {
        this.unsubscribe();
        this.onAppTransitionSuccess();
        this.onPublicTransitionSuccess();
    }

    mapStateToThis(state) {
        return {
            accountOptions: state.navigation.accountOptions
        };
    }
}

NavigationController.$inject = ['$log', '$ngRedux', '$state', '$uiRouterGlobals', '$transitions', 'AuthService', 'NavigationService', 'Notifier'];

export const navigation = {
    bindings: {
        navConfigs: '<'
    },
    controller: NavigationController,
    template: require('./navigation.html')
};
