import { IDS } from '../import-settings-action-id.constants';
import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import * as SettingsActions from '../import-settings.actions';
import watch from "redux-watch";

class OccupationsGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter, $mdDialog, $scope, $rootScope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
        this.$rootScope = $rootScope;
    }

    $onInit() {
        this.module = IDS.employees;
        this.identifier = IDS.empImportSettings;
        this.statePath = IDS.empImportSettingsOccupationsListGrid;
        //this.filterTemplate = './occupations-grid-filter.html';
        this.exportComponent = false;
        this.title = false;
        this.icon = false;
        this.confirmDialogOnRemove = true;
        //this.autoload = false;
        this.filterOpen = true;
        this.filterExpandButtonVisible = false;

        this.columns = [
            {title: '#',                    mapper: null},
            {title: 'Import Occupation',    mapper: 'occupation'},
            {title: 'MyRSO Occupation',     mapper: 'occupation_name'},
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), SettingsActions)(this);

        const $rootScope = this.$rootScope;
        const watchReset = watch(this.$ngRedux.getState, `${this.module}.${this.identifier}.${this.statePath}.reset`);
        this.unsubscribeOnReset = this.$ngRedux.subscribe(watchReset(function() {
            $rootScope.$broadcast('SELECT_CASCADE_RESET', 'empImportSettingsOccupationsGridFilterForm');
        }));

        this.dataAction = this.fetchOccupationsListGrid;
        this.init();
    }

    $onDestroy() {
        if(this.unsubscribeOnReset)
            this.unsubscribeOnReset()

        this.destroy();
    }

}

OccupationsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter', '$mdDialog', '$scope', '$rootScope'];

export const empImportSettingsOccupationsListGrid = {
    bindings: {
        onSelect: '&',
        title: '@'
    },
    controller: OccupationsGridController,
    ...gridTpl
}
