import moment from 'moment';

/* @ngInject */
export const XrayFactory = (Equipment, Location, Vendor, TubeInsert, TubeHousing, RegistrationData, CertificateData, Helpers, DATE_FORMAT) => {
    return function Xray() {

        Equipment.call(this);

        this.barcode = null;
        this.waveLength = null;
        this.location = new Location();
        this.registrationData = new RegistrationData();
        this.certificateData = new CertificateData();
        this.tubeHousing = new TubeHousing();
        this.tubeInsert = new TubeInsert();
        this.vendor = new Vendor();
        this.class = null;
        this.transaction_type = '';
        this.capitalSensitivityExpiryDate = null;
        this.capitalSensitivityExpiryDateShow = false;

        var equipmentInstallationDate = null;
        Object.defineProperty(this, 'equipmentInstallationDate', {
            configurable: true,
            enumerable: true,
            get: function() { return equipmentInstallationDate; },
            set: function(value) {
                equipmentInstallationDate = value;
                if (this.capitalSensitivityExpiryDate == null && value != null)
                    this.capitalSensitivityExpiryDate = moment(value).add(10, 'years').toDate();
            }
        });

        this.formatEquipmentInstallationDate = function() {
            return Helpers.formatDate(this.equipmentInstallationDate, DATE_FORMAT.INPUT);
        };

        this.formatCapitalSensitivityExpiryDate = function() {
            return Helpers.formatDate(this.capitalSensitivityExpiryDate, DATE_FORMAT.INPUT);
        };
    }
};
