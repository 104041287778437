import get from 'lodash/get';

/* @ngInject */
export function ManufacturerService($http, API_BASE, Manufacturer, EquipmentModel) {

    this.addEquipmentModel = function(model) {
        return $http({
            url: API_BASE + "/equipment/model",
            method: "POST",
            data: {
                man_id: model.manufacturer.id,
                ety_id: model.type,
                name: model.name,
                description: model.description,
            }
        }).then(function(response) {
            const emo_id = get(response.data, 'params', response.data);
            return {data: emo_id};
        });
    };

    this.addManufacturer = function(manufacturer) {
        return $http({
            url: API_BASE + "/equipment/manufacturer",
            method: "POST",
            data: {
                name: manufacturer.name,
                logo: manufacturer.logo,
                description: manufacturer.description,
                has_myrso: manufacturer.hasMyrso || 0,
                has_myqmo: manufacturer.hasMyqmo || 0,
            }
        }).then(({ data }) => data.hasOwnProperty('params') ? data.params : data);
    };

    this.updateManufacturer = function(manufacturer) {
        return $http({
            url: API_BASE + "/equipment/manufacturer/" + manufacturer.id,
            method: "PUT",
            data: {
                name: manufacturer.name,
                logo: manufacturer.logo,
                description: manufacturer.description,
                has_myrso: manufacturer.hasMyrso || 0 ,
                has_myqmo: manufacturer.hasMyqmo || 0,
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.setEquipmentTypeToManufacturer = function(manufacturerId, equipmentTypeIds) {
        return $http({
            url: `${API_BASE}/equipment/manufacturer/${manufacturerId}/settypes`,
            method: 'POST',
            data: {
                ety_ids: equipmentTypeIds,
            },
        }).then(function(response) {
            return response.data;
        });
    }

    this.fetchManufacturers = function(filter) {
        if (_.isUndefined(filter)){
            return $http({
                url: API_BASE + "/equipment/manufacturers",
                method: "GET"
            }).then(function(response){
                return response.data.map(function(item){
                    var manufacturer = new Manufacturer();
                    manufacturer.id = item.man_id;
                    manufacturer.name = item.name;
                    manufacturer.description = item.description;
                    manufacturer.code = item.code;
                    manufacturer.hasMyrso = item.has_myrso;
                    manufacturer.hasMyqmo = item.has_myqmo;
                    return manufacturer;
                });
            });
        } else {
            return $http({
                url: API_BASE + "/equipment/manufacturerslist",
                method: "GET",
                params: {
                    keyword: filter.keyword,
                    all_app_variants: filter.all_app_variants,
                    offset: filter.offset,
                    limit: filter.limit,
                }
            }).then(function({data}) {
                return {
                    itemsCount: data.total,
                    items: data.rows.map(function(row){
                        var manufacturer = new Manufacturer();
                        manufacturer.id = row.man_id;
                        manufacturer.name = row.man_name;
                        manufacturer.description = row.description;
                        manufacturer.equipmentType = row.ety_id;
                        manufacturer.equipmentTypeName = row.ety_name;
                        manufacturer.hasMyrso = row.has_myrso;
                        manufacturer.hasMyqmo = row.has_myqmo;
                        return manufacturer;
                    }),
                };
            });
        }
    };

    this.fetchManufacturersByEquipmentType = function(equipmentTypeCode){
        return $http({
            url: API_BASE + "/equipment/manufacturerslist",
            method: "GET",
            params: {
                offset: 0,
                limit: -1,
                equipmentTypeCode: equipmentTypeCode
            }
        }).then(function(response) {
            var rows = response.data.rows;
            return rows.map(function(row){
                var manufacturer = new Manufacturer();
                manufacturer.id = row.man_id;
                manufacturer.name = row.man_name;
                manufacturer.description = row.description;
                manufacturer.equipmentType = row.ety_id;
                manufacturer.equipmentTypeName = row.ety_name;
                manufacturer.hasMyrso = row.has_myrso;
                manufacturer.hasMyqmo = row.has_myqmo;
                return manufacturer;
            });
        });
    };

    this.fetchManufacturerDetails = function(manufacturerId){
        return $http({
            url: API_BASE + `/equipment/manufacturers/${manufacturerId}`,
            method: "GET",
        }).then(function(response){
            var manuf = response.data[0][0];
            var types = response.data[1];
            var manufacturer = new Manufacturer();
            manufacturer.id = manuf.man_id;
            manufacturer.name = manuf.name;
            manufacturer.logo = manuf.logo;
            manufacturer.description = manuf.description;
            manufacturer.hasMyrso = manuf.has_myrso;
            manufacturer.hasMyqmo = manuf.has_myqmo;
            types = _.map(types, function(type){
                return type.ety_id;
            });
            return {
                manufacturer: manufacturer,
                equipmentTypes: types,
            };
        });
    }

    this.fetchTypes = function() {
        return $http({
            url: API_BASE + "/equipment/types",
            method: "GET",
            params: {
                base_types: 1,
            },
        }).then(function(response) {
            return response.data;
        })
    };

    this.fetchEquipmentModels = function(manufacturerId, typeId) {
        return $http({
            url: API_BASE + "/radioactive-materials/manufacturer/models",
            method: "GET",
            params: {
                id: manufacturerId,
                ety_id: typeId,
                //location: null
            }
        }).then(function(response) {
            return response.data.items.map(function(item) {
                var model   = new EquipmentModel();
                model.id    = item.emo_id;
                model.name  = item.equipment_model;
                return model;
            });
        });
    };
}
