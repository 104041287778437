import moment from 'moment';
import { product } from '../../../../../bootstrap.json';

/* @ngInject */
export function AccountService($rootScope, $http, API_BASE, Subject, SimpleSubject, Account,
                        AccountType, AccountParent, HeadOffice, RSC, Helpers, Notifier, Location) {

    function appendTransform(defaults, transform) {
        defaults = angular.isArray(defaults) ? defaults : [defaults];
        return defaults.concat(transform);
    }

    this.fetchAccounts = function() {
        return $http({
            url: API_BASE + "/accounts",
            method: "GET"
        }).then(function(data) {
            var arr = [];
            data.data.forEach(function(elem) {
                var acc = new Account();
                acc.id = elem.account_id;
                acc.name = elem.name;
                acc.uuid = elem.subject_uuid;
                acc.type = new AccountType();
                acc.type.name = elem.subject_type_name;
                acc.createDate = moment(elem.created).format('DD-MM-YYYY');
                acc.parent = new AccountParent();
                acc.parent.uuid = elem.parent_uuid;
                acc.parent.username = elem.parent_username;
                arr.push(acc);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    this.getSelectCascadeSubjects = function(subject) {
        return $http({
            url: API_BASE + "/account/subjects",
            data: subject,
            method: "POST",
            transformResponse: appendTransform($http.defaults.transformResponse, function(data) {
                return _.map(data, function(item) {
                    var subject = new Subject();
                    subject.in_subject_uuid = item.subject_uuid;
                    subject.in_username_par = item.username_par;
                    subject.in_subject_basic_type_code = item.subject_basic_type_code;
                    subject.in_subject_basic_type_name = item.basic_subject_type_name;
                    subject.in_account_id = item.account_id;
                    subject.in_lspn = item.lspn;
                    subject.in_permit = item.permit;
                    subject.in_employees_mutable = parseInt(item.employees_mutable);
                    if (item.subject_basic_type_code == 'dep')
                        subject.in_username_par = item.subject_type_name;
                    if (item.subject_basic_type_code == 'rom')
                        subject.in_username_par = item.display_name;
                    return subject;
                });
            })
        }).then(function(response) {
            return response;
        }, function(error) {
            return error;
        });
    };

    this.fetchAccount = function(uuid) {
        return $http({
            url: API_BASE + "/location/sitelocation/" + uuid,
            method: "GET"
        }).then(function(data) {
            var totalLength = data.data.length;
            var location = {};

            location.rso = [];
            location.arso = [];
            location.primary = [];
            location.secondary = [];

            var account = data.data[0][0];
            location.created = moment(account.created_time).format('DD-MM-YYYY');
            location.uuid = uuid;
            location.id = account.account_id;
            location.name = account.name;
            location.type = account.type_id;
            location.email = account.email;
            location.phone = account.phone;
            location.fax = account.fax;

            location.address1 = account.location_address_1;
            location.address2 = account.location_address_2;
            location.address3 = account.location_address_3;
            location.suburb = account.location_suburb;
            location.postcode = account.location_postcode;
            location.country = account.country_id;
            location.state = account.state_id;

            location.invoiceAddress1 = account.invoice_address_1;
            location.invoiceAddress2 = account.invoice_address_2;
            location.invoiceAddress3 = account.invoice_address_3;
            location.invoiceSuburb = account.invoice_suburb;
            location.invoicePostcode = account.invoice_postcode;
            location.invoiceCountry = account.invoice_country_id;
            location.invoiceState = account.invoice_state_id;
            location.accountNumber = account.dosimetry_account_number;
            location.costCentreCode = account.cost_centre_code;

            location.doseUnit = account.dose_unit;
            location.serviceProvider = account.service_provider;

            if(data.data.length > 3)
                location.activeModules = data.data[3];

            if (totalLength == 1)
                return location;

            if (data.data[1].length === 0)
                return location;

            var i = 1;

            for(i;i<totalLength;i++) {
                var contacts = data.data[i];
                var contactsCnt = contacts.length;
                var j = 0;

                for(j;j<contactsCnt;j++) {
                    var contact = contacts[j];

                    if(contact.subject_status_code != 'act')
                        continue;

                    contact.status = contact.subject_status_code;
                    contact.uuid = contact.subject_uuid;

                    if (contact.subject_type_code == 'sro')
                        location.rso.push(contact);
                    else if (contact.subject_type_code == 'aso')
                        location.arso.push(contact);
                    else if (contact.type == 'pri')
                        location.primary.push(contact);
                    else if (contact.type = 'sec')
                        location.secondary.push(contact);
                }
            }
            return location;
        }, function(error) {
            return error;
        });
    };

    this.retrieveParents = function(subjectUuid) {
        return $http({
            url: API_BASE + "/employee/"+subjectUuid+"/parents",
            method: "GET"
        }).then(function(response) {
            return parseSubjectLis(response.data);
        }, function(error) {
            return error;
        });
    };

    function parseSubjectLis(data) {
        var arr = [];
        data.forEach(function(elem) {
            var subj = new SimpleSubject();
            subj.id = elem.account_id;
            subj.name = elem.username;
            subj.uuid = elem.subject_uuid;
            subj.typeId = elem.subject_type_id;
            subj.typeName = elem.basic_subject_type_name;
            //subj.createDate = moment(elem.created).format('DD-MM-YYYY');
            subj.parentUuid = elem.parent_uuid;
            subj.parentUsername = elem.parent_username;
            subj.accountId = elem.account_id;
            subj.email = elem.email;
            subj.phone = elem.phone;
            subj.basicType = elem.basic_type;
            arr.push(subj);
        });
        return arr;
    }

    this.getRscList = function(filter) {
        return $http({
            url: API_BASE + '/location/rsc-list',
            method: "GET",
            params: {
                uuid: filter.uuid,
                assistant: filter.assistant
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.fetchRscCandidates = function(location, code) {
        var params = {}
        if (typeof code != 'undefined')
            params.locationcode = code;

        location = (typeof location != 'undefined' && location != null) ? '/' + location : '';
        return $http({
            url: API_BASE + "/location/rsc-candidates" + location,
            method: "GET",
            params: params
        }).then(function(response) {
            return response.data.map(function(item) {
                return {
                    uuid:       item.sub_uuid,
                    username:   item.username,
                    password:   null,
                    gender:     item.gender,
                    firstName:  item.first_name,
                    lastName:   item.last_name,
                    birthDate:  item.birthdate,
                    phone:      item.phone,
                    fax:        item.fax,
                    email:      item.email
                }
            });
        });
    };

    function createContact(data) {
        var contact = {
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            fax: null,
            uuid: null,
            status: null
        };

        if (typeof data != 'undefined') {
            contact.firstName = data.first_name;
            contact.lastName = data.last_name;
            contact.email = data.email;
            contact.phone = data.phone;
            contact.fax = data.fax;
            contact.uuid = data.subject_uuid;
        }
        return contact;
    }

    this.fetchCenterTypes = function() {
        return $http({
            url: API_BASE + "/account/types",
            method: "GET"
        }).then(function(data) {
            var arr = [];
            data.data.forEach(function (elem) {
                var acc = new AccountType();
                acc.id = elem.sty_id;
                acc.name = elem.sty_name;
                arr.push(acc);
            });
            return arr;
        });
    };

    this.addSiteLocation = function(siteLocation) {
        return $http({
            url: API_BASE + "/account",
            method: "POST",
            data: {
                location:   siteLocation.location,
                name:       siteLocation.name,
                type:       siteLocation.type,
                email:      siteLocation.email,
                phone:      siteLocation.phone,
                accountNumber: siteLocation.accountNumber,
                costCentreCode: siteLocation.costCentreCode,
                doseUnit:           siteLocation.doseUnit,
                serviceProvider:    siteLocation.serviceProvider
            }
        }).then(function(response) {
            return {
                id: response.data.id,
                uuid: response.data.uuid,
                created: moment().format('DD-MM-YYYY')
            };
        });
    };

    this.fetchHeadoffices = function() {
        return $http({
            url: API_BASE + "/locations/headoffices",
            method: "GET"
        }).then(function(response) {
            var arr = [];
            response.data.forEach(function(elem) {
                elem.email = _.isNull(elem.email) ? null : elem.email.toLowerCase();
                var headOfficeAccount = new Account();
                headOfficeAccount.id = elem.account_id;
                headOfficeAccount.uuid = elem.subject_uuid;
                headOfficeAccount.name = elem.name;
                headOfficeAccount.email = elem.email;
                headOfficeAccount.phone = elem.phone;
                headOfficeAccount.created = moment(elem.created).format('DD-MM-YYYY');
                arr.push(headOfficeAccount);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    this.addHeadOffice = function(headOffice) {
        return $http({
            url: API_BASE + '/location/headoffice',
            method: "POST",
            data: {
                name:   headOffice.name,
                email:  headOffice.email,
                phone:  headOffice.phone,
                fiscal_year_start:  headOffice.fiscalYearStart,
                location_address_1:   headOffice.address1,
                location_suburb:     headOffice.suburb,
                location_postcode:   headOffice.postcode,
                location_state_id:    headOffice.state,
                business_number:     headOffice.businessNumber,
                corporation_number:  headOffice.corporationNumber,
                enable_2fa: headOffice.enable2fa,
                product: product
            }
        }).then(function(response) {
            return {
                id: response.data.id,
                uuid: response.data.uuid,
                created: moment().format('DD-MM-YYYY')
            };
        });
    };

    this.fetchHeadOffice = function(uuid) {
        return $http({
            url: API_BASE + "/location/headoffice/" + uuid,
            method: "GET"
        }).then(function(data) {
            var headOffice = new HeadOffice();
            var subject = data.data[0][0];
            //var rso = data.data[1][0];

            headOffice.created = moment(subject.created_at).format('DD-MM-YYYY');
            headOffice.id = subject.account_id;
            headOffice.name = subject.client_name;
            headOffice.email = subject.email;
            headOffice.phone = subject.phone;
            headOffice.uuid = subject.subject_uuid;
            headOffice.fiscalYearStart =    subject.fiscal_year_start;

            headOffice.address1 = subject.location_address_1;
            headOffice.suburb = subject.location_suburb;
            headOffice.postcode = subject.location_postcode;
            headOffice.country = subject.country_id;
            headOffice.state = subject.state_id;
            headOffice.businessNumber = subject.business_number;
            headOffice.corporationNumber = subject.corporation_number;
            headOffice.enable2fa = subject.enable2fa;
            headOffice.disableCheckboxEnable2fa = subject.disable_checkbox_enable2fa;

            if(data.data.length > 2)
                headOffice.activeModules = data.data[2];

            if (typeof data.data[1] == 'undefined' || data.data[1].length === 0)
                return headOffice;

            data.data[1].forEach(function(elem) {
                var rsc = new RSC();

                if (typeof elem.first_name !== 'undefined')
                    rsc.first_name = elem.first_name;
                if (typeof elem.last_name !== 'undefined')
                    rsc.last_name = elem.last_name;
                if (typeof elem.email !== 'undefined')
                    rsc.email = elem.email;
                if (typeof elem.phone !== 'undefined')
                    rsc.phone = elem.phone;
                if (typeof elem.fax !== 'undefined')
                    rsc.fax = elem.fax;
                if (typeof elem.username !== 'undefined')
                    rsc.username = elem.username;
                if (typeof elem.subject_uuid !== 'undefined')
                    rsc.uuid = elem.subject_uuid;
                if (typeof elem.subject_status_code !== 'undefined')
                    rsc.status = elem.subject_status_code;

                rsc.birthday = Helpers.formatDate(elem.birthdate);

                headOffice.rsc.push(rsc);
            });
            return headOffice;
        }, function(error) {
            return error;
        });
    };

    this.updateHeadoffice = function(item) {
        item.email = _.isUndefined(item.email) ? null : item.email.toLowerCase();
        return $http({
            url: API_BASE + "/location/headoffice/" + item.uuid,
            method: "PUT",
            data: {
                username: item.name,
                email: item.email,
                phone: item.phone,
                fiscal_year_start: item.fiscalYearStart,
                locationAddress1:   item.address1,
                locationSuburb:     item.suburb,
                locationPostcode:   item.postcode,
                locationStateId:    item.state,
                businessNumber:     item.businessNumber,
                corporationNumber:  item.corporationNumber,
                enable2fa:          item.enable2fa,
            }
        }).then(function(data) {
            return data;
        });
    };

    this.updateSiteLocation = function(siteLocation) {
        return $http({
            url: API_BASE + "/location/sitelocation/" + siteLocation.uuid,
            method: "PUT",
            data: {
                name:               siteLocation.name,
                email:              siteLocation.email,
                phone:              siteLocation.phone,
                lspn:               siteLocation.lspn,
                accountNumber:      siteLocation.accountNumber,
                costCentreCode:     siteLocation.costCentreCode,
                locationAddress1:   siteLocation.address1,
                locationAddress2:   siteLocation.address2,
                locationAddress3:   siteLocation.address3,
                locationSuburb:     siteLocation.suburb,
                locationPostcode:   siteLocation.postcode,
                locationStateId:    siteLocation.state,
                invoiceAddress1:    siteLocation.invoiceAddress1,
                invoiceAddress2:    siteLocation.invoiceAddress2,
                invoiceAddress3:    siteLocation.invoiceAddress3,
                invoiceSuburb:      siteLocation.invoiceSuburb,
                invoicePostcode:    siteLocation.invoicePostcode,
                invoiceStateId:     siteLocation.invoiceState,
                doseUnit:           siteLocation.doseUnit,
                serviceProvider:    siteLocation.serviceProvider
            }
        }).then(function(data) {
            return data;
        });
    };

    this.updateDepartment = function(item) {
        return $http({
            url: API_BASE + "/location/department/" + item.uuid,
            method: "PUT",
            data: {
                typeId: item.typeId,
                phone: item.phone,
                email: item.building,
                accountNumber: item.accountNumber
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.updateRoom = function(item) {
        return $http({
            url: API_BASE + "/location/room/" + item.uuid,
            method: "PUT",
            data: {
                name: item.name,
                number: item.number,
                building: item.building,
                level: item.level,
                room_type_id: item.roomType
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.addRsc = function(rsc, parent, assistant) {
        return $http({
            url: API_BASE + "/location/" + parent + "/rsc",
            method: "POST",
            data: {
                username:       rsc.username,
                password:       rsc.password,
                gender:         rsc.gender,
                firstName:      rsc.firstName,
                lastName:       rsc.lastName,
                birthDate:      Helpers.formatDate(rsc.birthDate),
                phone:          rsc.phone,
                fax:            rsc.fax,
                email:          rsc.email.toLowerCase(),
                assistant:      _.isUndefined(assistant) ? false : assistant
            }
        }).then(function(response) {
            return response.data.uuid;
        });
    };

    this.assignRsc = function(uuid, location) {
        return $http({
            url: API_BASE + "/location/" + location + "/rsc",
            method: "PUT",
            data: {
                rsc: uuid
            }
        }).then(function(response) {
            return response;
        });
    };

    this.addSiteLocationRSO = function(rso) {
        rso.in_gender = rso.in_gender.id;
        rso.email = _.isUndefined(rso.email) ? null : rso.email.toLowerCase();
        rso.in_birthdate = formatDbDate(rso.in_birthdate);
        return $http({
            url: API_BASE + "/location/sitelocation/rso",
            method: "POST",
            data: rso
        }).then(function(response) {
            return response;
        }, function(error) {
            return error;
        });
    };

    this.addSiteLocationARSO = function(rso) {
        rso.in_gender = rso.in_gender.id;
        rso.in_birthdate = formatDbDate(rso.in_birthdate);
        rso.email = _.isUndefined(rso.email) ? null : rso.email.toLowerCase();
        return $http({
            url: API_BASE + "/location/sitelocation/arso",
            method: "POST",
            data: rso
        }).then(function(response) {
            return response;
        }, function(error) {
            return error;
        });
    };

    this.countries = function () {
        return $http({
            url: API_BASE + "/location/countries",
            method: "GET"
        }).then(function(response) {
            return response.data;
        }, function(error) {
            return error;
        });
    };

    this.getQualityManagers = function(filter) {
        return $http({
            url: API_BASE + '/location/quality-managers',
            method: "GET",
            params: {
                location: filter.uuid,
                inLocation: filter.inLocation
            }
        }).then(function(response) {
            return response.data.map(function(item) {
                return {
                    uuid:       item.subject_uuid,
                    username:   item.username,
                    gender:     item.gender,
                    firstName:  item.first_name,
                    lastName:   item.last_name,
                    birthDate:  Helpers.formatDate(item.birthdate),
                    phone:      item.phone,
                    fax:        item.fax,
                    email:      item.email
                };
            });
        });
    };

    this.getQualityManagerCandidates = function() {

    };

    this.getQualityManagerDetails = function() {

    };

    this.addQualityManager = function(qualityManager) {
        return $http({
            url: API_BASE + '/location/quality-manager-add',
            method: "POST",
            data: {
                location:   qualityManager.location,
                gender:     qualityManager.gender,
                firstName:  qualityManager.firstName,
                lastName:   qualityManager.lastName,
                birthDate:  Helpers.formatDate(qualityManager.birthDate),
                phone:      qualityManager.phone,
                fax:        qualityManager.fax,
                email:      qualityManager.email.toLowerCase()
            }
        }).then(function(response) {
            return response.data.uuid;
        });
    };

    this.updateQualityManager = function() {

    };

    this.assignQualityManager = function(qualityManager) {
        return $http({
            url: API_BASE + '/location/quality-manager-assign',
            method: "GET",
            params: {
                location: qualityManager.location,
                uuid: qualityManager.uuid
            }
        }).then(function(response) {
            return response;
        });
    };

    this.rejectQualityManager = function(qualityManager) {
        return $http({
            url: API_BASE + '/location/quality-manager-reject',
            method: "GET",
            params: {
                location: qualityManager.location,
                uuid: qualityManager.uuid
            }
        }).then(function(response) {
            return response;
        });
    };

    this.getDepartmentTypes = function(uuid) {
        return $http({
            url: API_BASE + "/department/types",
            method: "GET",
            params: {uuid: uuid}
        }).then(response => {
            return response.data.map(item => {
                return {
                    value: item.sty_id,
                    label: item.name,
                    item: {locationDepartment: item.sty_id}
                }
            });
        });
    };

    this.addDepartment = function(uuid, type, accountNumber) {
        return $http({
            url: API_BASE + '/location/department',
            method: 'POST',
            data: {
                uuid: uuid,
                type: type,
                accountNumber: accountNumber,
            }
        }).then(response => {
            return {
                id: response.data.id,
                uuid: response.data.uuid
            };
        });
    };

    this.addRoom = function(parent, name, building, level, number) {
        return $http({
            url: API_BASE + '/location/room',
            method: 'POST',
            data: {
                parent: parent,
                name: name,
                building: building,
                level: level,
                number: number
            }
        }).then(response => {
            return {
                id: response.data.params.account_id,
                uuid: response.data.params.sub_uuid
            };
        });
    };

    this.getAccounts = function(filter) {
        var params = [];
        params.parent = filter.uuid;
        params.keyword = filter.keyword;
        params.offset = filter.offset;
        params.limit = filter.limit;
        params.exportFormat = filter.exportFormat;
        params.exportColumns = filter.exportColumns;

        return $http({
            url: API_BASE + "/get-accounts",
            method: "GET",
            params: params
        }).then(function(response) {
            if (filter.exportFormat) {
                return response.data;
            }
            else {
                var account = response.data.items.map(function (item) {
                    var accountSubject = new Location();
                    accountSubject.uuid = item.subject_uuid;
                    accountSubject.type = item.subject_type_uuid;
                    accountSubject.name = item.display_name;
                    accountSubject.id = item.account_id;
                    accountSubject.basicType = item.basic_type;
                    accountSubject.typeName = item.basic_subject_type_name;
                    accountSubject.warning = item.warning;
                    accountSubject.critical = item.critical;
                    accountSubject.parentUuid = item.parent_uuid;
                    accountSubject.parentUsername = item.parent_name;
                    accountSubject.building = item.building;
                    accountSubject.level = item.room_level;
                    accountSubject.number = item.room_number;
                    accountSubject.accountNumber = item.dosimetry_account_number;
                    accountSubject.costCentreCode = item.cost_centre_code;
                    accountSubject.roomType = item.room_type_id;
                    return accountSubject;
                });
                return {
                    items: account,
                    total: response.data.items_count || response.data.total
                }
            }
        });
    };

    this.generateQRcode = function(uuid, name) {
        return $http({
            url: API_BASE + '/equipment-calibration/qrcode/location',
            method: 'POST',
            data: {
                uuid: uuid,
                name: name
            }
        }).then(response => {
            return API_BASE + '/img/' + localStorage.myrsoAuthToken + response.data;
        });
    };

    this.printQRcode = function(uuid, name, action) {
        return $http({
            url: API_BASE + '/location/qrcode/print',
            method: 'POST',
            responseType: 'arraybuffer',
            data: {
                uuid,
                name,
                action,
            }
        }).then(response => {
            saveAs(new Blob([response.data], { type: 'application/pdf' }), `${uuid}.pdf`);
        });
    };

    this.resendLoginInfo = function(subjectUuid) {
        return $http({
            url: API_BASE + "/login-info-resend/location",
            method: "POST",
            params: {
                loc_uuid:   subjectUuid
            }
        }).then(function(result) {
            return result;
        });
    };

    this.inactivateLocation = function(subjectUuid) {
        return $http({
            url: API_BASE + "/location/inactivate",
            method: "POST",
            params: {
                loc_uuid:   subjectUuid
            }
        }).then(function(result) {
            return result;
        });
    };

    this.getRoomTypes = function () {
        return $http({
            url: API_BASE + "/location/room-types",
            method: "GET"
        }).then(function(response) {
            return response.data;
        }, function(error) {
            return error;
        });
    };
}
