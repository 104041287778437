/* @ngInject */
export function AccComplaintsFeedbackReg($scope, Notifier, AccountService,
                                  AccreditationDocumentsService, ACC_ACCREDITATION_CODE, $mdDialog,
                                  AuthService, SUBJECT_TYPE, AccreditationService, $filter) {

    //todo, find out somehow here which accreditation is this (DIAS, DENTAL)

    $scope.cursorState = 'default';

    $scope.currentDocPage = 1;
    $scope.pageSize = 10;
    $scope.sortArrow = 'keyboard_arrow_down';

    var ctrl = this;
    ctrl.accreditationSchemaCode = AccreditationService.getCurrentAccreditationFromState();

    $scope.SORT_OPTIONS = {
        COMPLAINT_ID: 'id',
        COMPLAINT_NO: 'complaintsNumber',
        CUSTOMER_DETAILS: 'customerDetails',
        COMPLETED_BY: 'completedBy',
        DATE_RECEIVED: 'dateReceived',
        CLOSE_DATE: 'closeDate',
        STATUS: 'status',
    };
    ctrl.sortBy = $scope.SORT_OPTIONS.ITEM_ID;
    ctrl.sortReverse = false;

    ctrl.selectedUserParentLocation = ""; //uuid
    ctrl.selectedUserParentLocationName = ""; //location name

    ctrl.YEARS = [new Date().getFullYear(), new Date().getFullYear()-1, new Date().getFullYear()-2];
    ctrl.year = new Date().getFullYear();
    ctrl.complaintFeedbackItems = null;
    ctrl.selectedItem = null;

    var user = AuthService.getUser();

    ctrl.selectItem = function (item) {
        ctrl.selectedItem = item;
    };

    ctrl.fetchComplainstFeedbackItems = function () {
        AccreditationDocumentsService.fetchAccComplaintFeedbackItems(ctrl.accreditationSchemaCode,
            ctrl.selectedUserParentLocation, ctrl.year, null, null)
            .then(function (response) {
                ctrl.complaintFeedbackItems = response.data.result;
                ctrl.selectedItem = null;
            }, function (error) {
                Notifier.error($filter('translate')('ACCREDITATION.TABS.COMPLAINTS_AND_FEEDBACK.NOTIFY_LIST_ERROR'));
            });
    };

    ctrl.setSort = function (sortColumn) {
        if (sortColumn === ctrl.sortBy) {
            ctrl.sortReverse = !ctrl.sortReverse;
        } else {
            ctrl.sortBy = sortColumn;
        }
    };

    $scope.$on('LOCATION_SUBJECT_SELECTED', function (event, locationItem) {
        if (locationItem.in_subject_basic_type_code === "slo") {
            ctrl.selectedUserParentLocation = locationItem.in_subject_uuid;
            ctrl.selectedUserParentLocationName = locationItem.in_username_par;
        }
    });

    $scope.$watch('accComplaintsFeedbackReg.selectedUserParentLocation', function () {
        if (ctrl.selectedUserParentLocation != "") {
            ctrl.fetchComplainstFeedbackItems();
        }
    });

    $scope.$watch('accComplaintsFeedbackReg.selectedItem', function () {
        if (ctrl.selectedItem != null) {

        }
    });

    $scope.$watch('accComplaintsFeedbackReg.sortReverse', function (value) {
        if (value) {
            $scope.sortArrow = 'keyboard_arrow_up';
        } else {
            $scope.sortArrow = 'keyboard_arrow_down';
        }
    });

    $scope.$watch('accComplaintsFeedbackReg.year', function () {
        if (ctrl.selectedUserParentLocation != "") {
            ctrl.fetchComplainstFeedbackItems();
        }
    });

    ctrl.addEditComplaintFeedbackItem = function (item) {
            $mdDialog.show({
                controller: 'AccComplaintFeedbackAddEdit',
                template: require('../../../../views/accreditation/quality-controll-documents/complaints-and-feedback/accreditation-complaint-feedback-add-dialog.html'),
                locals: {
                    color: '#a934e4',
                    title: $filter('translate')('ACCREDITATION.TABS.COMPLAINTS_AND_FEEDBACK.NOTIFY_LOAD_LIST_ERROR'),
                    locationUuid: ctrl.selectedUserParentLocation,
                    locationName: ctrl.selectedUserParentLocationName,
                    accShemaCode: ctrl.accreditationSchemaCode,
                    complFeedbItem: item
                },
                scope: $scope.$new(),
                preserveScope: false,
                clickOutsideToClose: false
            });
    };

    $scope.$on('acc-complaint-and-feedback-item-added', function (events, args) {
        ctrl.fetchComplainstFeedbackItems();
    });

    // ctrl.downloadPdfItemList = function () {
    //      AccreditationDocumentsService.fetchAccQualityImprovementItems(ctrl.accreditationSchemaCode,
    //         ctrl.selectedUserParentLocation, ctrl.year, null, EXPORT_FORMAT_PDF)
    //         .then(function (response) {
    //             DocumentManager.downloadFile("/" + response.data.result, DOCUMENT_TYPE.ACCREDITATION_DOCUMENT);
    //         }, function (error) {
    //             Notifier.error("Error generating quality improvement item list.");
    //         });
    // };

    //Load data for parent site location if user belongs to only one site location
    if (user && (user.parentCount === 1) && (user.parents[user.parents.length-1])
        && (user.parents[user.parents.length-1].basicType == SUBJECT_TYPE.SITE_LOCATION)) {

        ctrl.selectedUserParentLocation = user.parents[user.parents.length-1].uuid;
        ctrl.selectedUserParentLocationName = user.parents[user.parents.length-1].name;
        ctrl.fetchComplainstFeedbackItems();
    }
}
