import API                          from '../../../common/http/api.fetch';
import {success, responseMessage}   from '../../../common/utils/util.notifier';
import I18N                         from '../../../common/utils/util.i18n';
import {GRID_FETCH, GRID_RELOAD} from '../../../common/grid/grid.constants';
import {TYPES} from './add-document-action-type.constants';
import {IDS} from './add-document-action-id.constants';
import {formatDate} from "../../../common/utils/util.date";
import {DATE_FORMAT} from "../../../common/utils/util.constants";
// import {getDocFileNameFromPath} from "../../../common/utils/util.path";

export const fetchSiteLocationDetails = (siteLocationUuid) => {
    return dispatch => {
        return API.fetch({
            url: `/location/sitelocation/${siteLocationUuid}`,
            method: 'GET',
            params: {}
        }).then(response => {
            const siteLocation = response.data[0][0];
            dispatch({
                type: TYPES.FETCH_SITE_LOCATION_DETAILS,
                id: IDS.accAddDocument,
                data: {
                    siteLocationUuid,
                    siteLocationName: siteLocation.name,
                    // fiscalYearStart: parseInt(  siteLocation.fiscal_year_start ) - 1,
                    // sloQualityManagers: response.data[1].map(item => ({...item, employee_uuid: item.subject_uuid})),
                }
            })
        })
    }
}

export const getSectionDetails = (id) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/section-details',
            method: 'GET',
            params: {id}
        }).then(response => {
            const { standard_name, description, status_name, status_code, scheme_name } = response.data.details
            dispatch({
                type:   TYPES.FETCH_STANDARD_DETAILS,
                id: IDS.accAddDocument,
                data:   {
                    standardId:             id,
                    standardName:           standard_name,
                    standardDescription:    description,
                    standardStatusName:     status_name,
                    standardStatusCode:     status_code,
                    schemeName:             scheme_name,
                }
            })
        }, error => {
            responseMessage(error);
        });
    };
}

export const fetchLocAssignableVersionedDocumentsGrid = (params) => {
    return dispatch => {
        if(!params.siteLocationUuid || !params.standardId)
            return;
        return API.fetch({
            url: '/accreditation/list-loc-assignable-version-documents',
            method: 'GET',
            params,
        }).then(({data}) => {
            return dispatch({
                type: GRID_FETCH,
                id: IDS.accAddDocument,
                path: IDS.addFromExistingGrid,
                ...data,
                items: data.items.map(item => ({
                    ...item,
                    id: item.vdo_uuid,
                    filename: item.doc_url.split('/').pop(),
                }))
            });
        }, error => {
            responseMessage(error);
        })
    }
}

export const assignVersionedDocToStandard = (vdo_uuid, ast_id, slo_uuid) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/assign-versioned-doc-to-standard',
            method: 'POST',
            data: {
                vdo_uuid,
                ast_id,
                slo_uuid,
            }
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_ASSIGNED');
            dispatch({
                type: GRID_RELOAD,
                id: IDS.accAddDocument,
                path: IDS.addFromDisabledGrid
            });
        },error => {
            responseMessage(error)
        });
    };
};

export const addStandardRequiredDoc = (loc_uuid, doc_num, name, description, document_type, ast_id, doc_date, due_date, path = null) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/add-required-doc',
            method: 'POST',
            data: {
                loc_uuid,
                doc_num,
                name,
                description,
                document_type,
                ast_id,
                doc_date: formatDate(doc_date, DATE_FORMAT.OUTPUT),
                due_date: formatDate(due_date, DATE_FORMAT.OUTPUT),
                path,
            }
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_ADDED');
            return response
        },error => {
            responseMessage(error)
        });
    };

}

export const addUploadedDocument = (aas_id, slo_uuid, year, arq_id, doc_uri) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/add-uploaded-document',
            method: 'POST',
            data: {
                aas_id,
                slo_uuid,
                year,
                arq_id : arq_id || undefined,
                doc_uri,
            }
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_ADDED');
        },error => {
            responseMessage(error)
        });
    };
};

export const generateAccDocument = (data) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/generate-document',
            method: 'POST',
            //responseType: 'arraybuffer',
            data
        }).then(response => {
            //const filename = response.headers('content-disposition').split('"')[1];
            //saveAs(new Blob([response.data], {type: 'application/pdf'}),filename);
        },error => {
            responseMessage(error)
        });
    };
};