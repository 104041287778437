export function AccOptimizedChartDocumentFactory() {
    return function AccComplaintFeedbackItem() {

        this.id = null; //aoc_id from database
        this.equipmentId = null;
        this.createdBy = null;
        this.creationDate = null;
        this.approvedBy = null;
        this.reviewDate = null;
        this.nextReviewDate = null;
        this.uploadDate = null;
        this.docPath = null;
        this.siteLocationUuid = null;
    };
};
