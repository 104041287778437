class ReportingEquipmentSummaryController {
    constructor($rootScope) {
        this.$rootScope = $rootScope;
    }

    onGridReset() {
        this.$rootScope.$broadcast('SELECT_CASCADE_RESET', 'reportingEquipmentSummaryFilterForm');
    }
}

ReportingEquipmentSummaryController.$inject = ['$rootScope'];

export const reportingEquipmentSummary = {
    controller: ReportingEquipmentSummaryController,
    template: `
        <reporting-equipment-summary-grid on-reset="$ctrl.onGridReset()"></reporting-equipment-summary-grid>
    `
};
