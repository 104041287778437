/* @ngInject */
export const AccountFactory = (Location, AccountType, AccountParent) => {
    return function Account() {
        this.id = null;
        this.uuid = null;
        this.name = null;
        this.location = new Location();
        this.type = new AccountType();
        this.parent = new AccountParent();
        this.department = null;
        this.purchaseNumber = null;
        this.purchaseOrderFile = "";
        this.acquireDate = null;
        this.createDate = null;
        this.phone = null;
        this.email = null;
        this.lspn = null;
    };
};
