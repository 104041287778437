import { API_BASE } from '../../common/utils/util.constants';
import { saveAs } from 'file-saver';

export default function InventoryScannerActions($http, EquipmentService) {
    return {
        getInventory: (uuid) => () => {
            return EquipmentService.getEquipmentGrid({
                status: 'act',
                location: uuid,
                offset: 0,
                limit: 1000,
            });
        },
        getScanDetails: (id) => () => {
            return $http({
                url: API_BASE + '/inventory-scanner/equipment',
                method: 'POST',
                data: { ids: id }
            }).then(({ data }) => data);
        },
        genScanReport: (data) => () => {
            return $http({
                url: API_BASE + '/inventory-scanner/generate-report',
                method: 'POST',
                responseType: 'arraybuffer',
                data: {
                    type: data.type,
                    uuid: data.location,
                    result: data.equipment.map(item => {
                        return `${item.id}|${item.scanStatus}`;
                    }).join(','),
                    misplaced: data.misplaced.map(item => {
                        return item.id;
                    }).join(',')
                }
            }).then(({ data }) => {
                saveAs(new Blob([data], { type: 'application/pdf' }), `inventory-report.pdf`);
            });
        }
    }
}

InventoryScannerActions.$inject = ['$http', 'EquipmentService'];
