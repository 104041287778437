/*
*   List of Action IDs that should be passed from accreditation.reducer.js to scheme.reducer.js
 */

export const IDS = {
    // module
    accScheme: 'accScheme',

    //paths
    documentEvaluationReviewsGrid:      'documentEvaluationReviewsGrid',
    documentEvaluationImprovementsGrid: 'documentEvaluationImprovementsGrid',

    // components:

    // tab states:
    app_accreditation_scheme_manage:                    'app.accreditation.scheme.manage',
    app_accreditation_scheme_manage_standards:          'app.accreditation.scheme.manage.standards',
    app_accreditation_scheme_manage_progress:           'app.accreditation.scheme.manage.progress',
    app_accreditation_scheme_manage_auditSchedule:      'app.accreditation.scheme.manage.auditSchedule',
    app_accreditation_scheme_manage_auditAdd:           'app.accreditation.scheme.manage.auditAdd',
    app_accreditation_scheme_manage_auditDetails:       'app.accreditation.scheme.manage.auditDetails',
    app_accreditation_scheme_manage_evidence:           'app.accreditation.scheme.manage.evidence',
    app_accreditation_scheme_manage_documentEvaluation: 'app.accreditation.scheme.manage.documentEvaluation',
    app_accreditation_scheme_manage_documentRequest:    'app.accreditation.scheme.manage.documentRequest',

    public_accreditation_task:                          'public.accreditation.task',
    public_accreditation_scheme:                        'public.accreditation.scheme',
    public_accreditation_scheme_tasks:                  'public.accreditation.scheme.tasks',
    public_accreditation_scheme_standards:              'public.accreditation.scheme.standards',
    public_accreditation_scheme_progress:               'public.accreditation.scheme.progress',
    public_accreditation_scheme_evidence:               'public.accreditation.scheme.evidence',
    public_accreditation_scheme_documentEvaluation:     'public.accreditation.scheme.documentEvaluation',
};
