/** @namespace Reducers.Administration */

import { RM_ISOTOPES, RM_ATOMIC_NUMS, RM_UNITS } from "../components/radioactive-materials/radioactive-materials.actions.types";
import { TabsReducer } from '../common/tabs/tabs.reducer';
import { GridReducer } from '../common/grid/grid.reducer';
import { getGridSort } from '../common/grid/grid.utils';
import { administrationAccountsTabs } from './administration-accounts-tabs';
import { administrationCategorisationReducer as CategorisationReducer } from '../components/administration/categorisation/adm-categorisation.reducer';
import { admCatCentersTabs } from '../components/administration/categorisation/centers/adm-cat-centers-tabs';
import { admCatOccupationsTabs } from '../components/administration/categorisation/occupations/adm-cat-occupations-tabs';
import { admCatDocumentsTabs } from '../components/administration/categorisation/documents/adm-cat-documents-tabs';
import { administrationAccountsHeadOfficesReducer as HeadOfficesReducer } from './administration-accounts-head-offices.reducer';
import { administrationAccountsSiteLocationsReducer as SiteLocationsReducer } from './administration-accounts-site-locations.reducer';
import { administrationAccountsDepartmentsReducer as DepartmentsReducer } from './administration-accounts-departments.reducer';
import { administrationAccountsRoomsReducer as RoomsReducer } from './administration-accounts-rooms.reducer';
import { administrationNotificationSettingsReducer as Notification } from './administration-notification-settings.reducer';
import { administrationMonthlyReportSettingsReducer as MonthlyReport } from './administration-monthly-report-settings.reducer';
import { TYPES as HEAD_OFFICE_ACTION_TYPES } from '../components/administration/accounts/head-offices/head-offices-action-type.constants';
import { IDS as HEAD_OFFICE_ACTION_IDS } from '../components/administration/accounts/head-offices/head-offices-action-id.constants';
import merge from 'lodash/merge';
import set from 'lodash/set';
import includes from 'lodash/includes';
import { PREFIX as CATEGORISATION_ACTION_PREFIX } from '../components/administration/categorisation/adm-categorisation-action-type.constants';
import {IDS as CATEGORISATION_ACTION_IDS} from "../components/administration/categorisation/adm-categorisation-action-id.constants";

/**
 * @function administrationReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function administrationReducer(state, action) {

    if (typeof state === 'undefined') {
        return Object.assign({},
            administrationAccountsTabs,
            HeadOfficesReducer(),
            SiteLocationsReducer(),
            DepartmentsReducer(),
            RoomsReducer(),
            Notification(),
            MonthlyReport(),
            {
                admCategorisation: CategorisationReducer(),
                ...admCatCentersTabs,
                ...admCatOccupationsTabs,
                ...admCatDocumentsTabs,

                'app.administration.notificationSettings': Object.assign(TabsReducer(), {
                    tabs: [
                        {
                            state: 'app.administration.notificationSettings.listing',
                            title: 'Notification Settings',
                            visible: true,
                            disabled: false,
                            loading: false,
                        },
                        {
                            state: 'app.administration.notificationSettings.details',
                            title: 'Notification Details',
                            visible: false,
                            disabled: false,
                            loading: false
                        },
                        {
                            state: 'app.administration.notificationSettings.timeline',
                            title: 'Notification Time Line',
                            visible: false,
                            disabled: false,
                            loading: false
                        },
                    ]
                }),
                'app.administration.monthlyReportSettings': Object.assign(TabsReducer(), {
                    tabs: [
                        {
                            state: 'app.administration.monthlyReportSettings.listing',
                            title: 'Monthly Report Settings',
                            visible: true,
                            disabled: false,
                            loading: false,
                        },
                        {
                            state: 'app.administration.monthlyReportSettings.details',
                            title: 'Monthly Report Details',
                            visible: false,
                            disabled: false,
                            loading: false
                        }
                    ]
                }),
                administrationAccountsEquipmentGrid: merge(GridReducer(), {
                    filter: {
                        type: null,
                        status: 'act',
                        location: null,
                    }
                }),
                administrationAccountsEmployeesGrid: merge(GridReducer(), {
                    filter: {
                        location: null,
                        status: 'act'
                    }
                }),
                administrationVendorsGrid: GridReducer(),
                administrationSettings: {
                    password: null
                },
                administrationAccountsLicenceIsotope: {
                    maximumActivity: null,
                    isotope: null,
                    isotopes: [],
                    atomicNum: null,
                    atomicNums: [],
                    unit: null,
                    units: [],
                    unitType: 'siu',
                    unitData: [
                        {value: 'siu', label: 'SI Units'},
                        {value: 'nsu', label: 'Non SI Units'}
                    ],
                    sourceType: 1,
                    sourceData: [
                        {value: 1, label: 'Sealed'},
                        {value: 0, label: 'Unsealed'}
                    ],
                },
                administrationAccountsLicenceIsotopesGrid: {
                    ...GridReducer(),
                    items: [],
                    itemsCount: 0
                },
                administrationAccountsLicenceAppendix: {
                    appendixName: null,
                    document: null,
                },
                administrationAccountsLicenceAppendixGrid: {
                    ...GridReducer(),
                    items: [],
                    itemsCount: 0
                }
            }
        );
    }

    if(HEAD_OFFICE_ACTION_TYPES.hasOwnProperty(action.type)
    || action.id && HEAD_OFFICE_ACTION_IDS.hasOwnProperty(action.id.replace(/\./g,'_'))) {
        return HeadOfficesReducer(state, action);
    }

    if(action.type.startsWith(CATEGORISATION_ACTION_PREFIX)
        || action.id && CATEGORISATION_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, admCategorisation: CategorisationReducer(state.admCategorisation, action) }
    }

    switch (action.type) {
        case 'TAB_SELECT':
            if (action.id === 'app.administration.accounts') {
                return Object.assign({}, state, {
                    'app.administration.accounts': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.administration.accounts.siteLocations') {
                return Object.assign({}, state, {
                    'app.administration.accounts.siteLocations': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.administration.accounts.departments') {
                return Object.assign({}, state, {
                    'app.administration.accounts.departments': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.administration.accounts.rooms') {
                return Object.assign({}, state, {
                    'app.administration.accounts.rooms': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.administration.notificationSettings') {
                return Object.assign({}, state, {
                    'app.administration.notificationSettings': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.administration.monthlyReportSettings') {
                return Object.assign({}, state, {
                    'app.administration.monthlyReportSettings': TabsReducer(state[action.id], action)
                });
            }
        case 'TAB_INIT':
        case 'TAB_OVERRIDE':
        case 'TAB_PARAMS':
        case 'TAB_ENABLE':
        case 'TAB_DISABLE':
        case 'TAB_SHOW':
        case 'TAB_HIDE':
        case 'TAB_LOADING':
            if (action.id === 'app.administration.accounts') {
                return Object.assign({}, state, {
                    'app.administration.accounts': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.administration.accounts.siteLocations') {
                return Object.assign({}, state, {
                    'app.administration.accounts.siteLocations': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.administration.accounts.departments') {
                return Object.assign({}, state, {
                    'app.administration.accounts.departments': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.administration.accounts.rooms') {
                return Object.assign({}, state, {
                    'app.administration.accounts.rooms': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.administration.notificationSettings') {
                return Object.assign({}, state, {
                    'app.administration.notificationSettings': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.administration.monthlyReportSettings') {
                return Object.assign({}, state, {
                    'app.administration.monthlyReportSettings': TabsReducer(state[action.id], action)
                });
            }
        case 'TAB_SWITCH':
            if (action.id === 'app.administration.accounts') {
                return Object.assign({}, state, {
                    'app.administration.accounts': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                });
            }
            if (action.id === 'app.administration.accounts.siteLocations') {
                return Object.assign({}, state, {
                    'app.administration.accounts.siteLocations': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                });
            }
            if (action.id === 'app.administration.accounts.departments') {
                return Object.assign({}, state, {
                    'app.administration.accounts.departments': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                });
            }
            if (action.id === 'app.administration.accounts.rooms') {
                return Object.assign({}, state, {
                    'app.administration.accounts.rooms': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                });
            }
            if (action.id === 'app.administration.notificationSettings') {
                return Object.assign({}, state, {
                    'app.administration.notificationSettings': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                });
            }
            if (action.id === 'app.administration.monthlyReportSettings') {
                return Object.assign({}, state, {
                    'app.administration.monthlyReportSettings': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                });
            }
        case 'HEAD_OFFICE_FETCH':
        case 'HEAD_OFFICE_RESET':
            return HeadOfficesReducer(state, action);
        case 'SITE_LOCATION_FETCH':
        case 'SITE_LOCATION_RESET':
        case 'SITE_LOCATION_CENTER_TYPES':
        case 'SITE_LOCATION_CENTER_TYPE_SELECTED':
        case 'SITE_LOCATION_LICENCE_FETCH':
        case 'SITE_LOCATION_LICENCE_RESET':
        case 'SITE_LOCATION_LICENCE_TYPES':
        case 'SITE_LOCATION_LICENCE_TYPE_SELECTED':
            return SiteLocationsReducer(state, action);
        case 'DEPARTMENT_FETCH':
        case 'DEPARTMENT_RESET':
        case 'DEPARTMENT_TYPES':
        case 'DEPARTMENT_TYPE_SELECTED':
            return DepartmentsReducer(state, action);
        case 'ROOM_FETCH':
        case 'ROOM_RESET':
        case 'ROOM_TYPES':
            return RoomsReducer(state, action);
        case 'CONTACT_CANDIDATES_FETCH':
        case 'CONTACT_FETCH':
        case 'CONTACT_RESET':
        case 'RSC_CANDIDATES_FETCH':
        case 'RSC_FETCH':
        case 'RSC_RESET':
        case 'QUALITY_MANAGER_CANDIDATES_FETCH':
        case 'QUALITY_MANAGER_FETCH':
        case 'QUALITY_MANAGER_RESET':
        case 'AUTOCOMPLETE_SELECTED':
        case 'VALIDATE_USERNAME':
        case 'GENDER_SELECTED':
        case 'DATE_SELECTED':
        case 'MANAGER_TYPES':
        case 'INPUT_SELECT':
        case 'INPUT_TEXT_CHANGE':
        case 'INPUT_EMAIL_CHANGE':
        case 'CHECKBOX_SELECTED':
        case 'INPUT_RADIO_CHANGE':
        case 'COUNTRY_SELECTED':
        case 'COUNTRY_STATES_FETCH':
        case 'COUNTRY_STATE_SELECTED':
        case 'FILE_UPLOAD':
            if (includes([
                    'administrationAccountsHeadOffice',
                    'administrationAccountsHeadOfficeContact',
                    'administrationAccountsHeadOfficesGrid',
                ], action.id)) {
                return HeadOfficesReducer(state, action);
            }
            if (includes([
                    'administrationAccountsSiteLocation',
                    'administrationAccountsSiteLocationContact',
                    'administrationAccountsSiteLocationsGrid'
                ], action.id)) {
                return SiteLocationsReducer(state, action);
            }
            if (includes([
                'administrationAccountsDepartment',
                'administrationAccountsDepartmentContact',
            ], action.id)) {
                return DepartmentsReducer(state, action);
            }
            if (action.id === 'administrationAccountsRoom') {
                return RoomsReducer(state, action);
            }
            if (action.id === 'administrationAccountsSiteLocationLicence') {
                return SiteLocationsReducer(state, action);
            }
            if (includes([
                    'administrationNotificationSettings',
                    'administrationNotificationSettingsTimeline'
                ], action.id)) {
                return Notification(state, action);
            }
            if (action.id === 'administrationMonthlyReportSettings') {
                return MonthlyReport(state, action);
            }
            if (action.id === 'administrationAccountsLicenceIsotope' || action.id === 'administrationAccountsLicenceAppendix') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.input
                    }
                }
            }
        case 'NOTIFICATION_SETTINGS_SUBJECT_TYPES':
            if (action.id === 'administrationNotificationSettings') {
                return Notification(state, action);
            }
        case 'NOTIFICATION_DETAILS':
            if (action.id === 'administrationNotificationSettings') {
                return Notification(state, action);
            }
        case 'MONTHLY_REPORT_DETAILS':
            if (action.id === 'administrationMonthlyReportSettings') {
                return MonthlyReport(state, action);
            }
        case 'NOTIFICATION_SETTINGS_TIMELINE_RESET':
                return Notification(state, action);
        case 'LOCATION_SELECTED':
            if (includes([
                    'administrationAccountsHeadOfficeContactsGrid',
                    'administrationAccountsHeadOfficeLicencesGrid'
                ], action.id)) {
                return HeadOfficesReducer(state, action);
            }
            if (includes([
                    'administrationAccountsSiteLocationsGrid',
                    'administrationAccountsSiteLocationContactsGrid',
                    'administrationAccountsSiteLocationLicencesGrid'
                ], action.id)) {
                return SiteLocationsReducer(state, action);
            }
            if (includes([
                    'administrationAccountsDepartmentsGrid',
                    'administrationAccountsDepartmentContactsGrid'
                ], action.id)) {
                return DepartmentsReducer(state, action);
            }
            if (action.id === 'administrationAccountsRoomsGrid') {
                return RoomsReducer(state, action);
            }
            if (action.id === 'administrationNotificationSettingsGrid') {
                return Notification(state, action);
            }
            if (action.id === 'administrationAccountsEquipmentGrid') {
                return Object.assign({}, state, {
                    administrationAccountsEquipmentGrid: set(GridReducer(state.administrationAccountsEquipmentGrid, {type: 'PAGINATE'}), 'filter.location', action.uuid)
                });
            }
            if (action.id === 'administrationAccountsEmployeesGrid') {
                return Object.assign({}, state, {
                    administrationAccountsEmployeesGrid: set(GridReducer(state.administrationAccountsEmployeesGrid, {type: 'PAGINATE'}), 'filter.location', action.uuid)
                });
            }
        case 'GRID_OVERRIDE':
        case 'SHOW_LOADING':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
        case 'GRID_SELECT':
        case 'GRID_SELECT_ALL':
        case 'GRID_DESELECT':
        case 'GRID_DESELECT_ALL':
        case 'GRID_RESET':
        case 'GRID_INIT':
            if (includes([
                    'administrationAccountsHeadOfficesGrid',
                    'administrationAccountsHeadOfficeContactsGrid',
                    'administrationAccountsHeadOfficeLicencesGrid'
                ], action.id)) {
                return HeadOfficesReducer(state, action);
            }
            if (includes([
                    'administrationAccountsSiteLocationsGrid',
                    'administrationAccountsSiteLocationContactsGrid',
                    'administrationAccountsSiteLocationLicencesGrid',
                    'administrationAccountsLicenceSiteLocationsGrid',
                ], action.id)) {
                return SiteLocationsReducer(state, action);
            }
            if (includes([
                    'administrationAccountsDepartmentsGrid',
                    'administrationAccountsDepartmentContactsGrid'
                ], action.id)) {
                return DepartmentsReducer(state, action);
            }
            if (action.id === 'administrationAccountsRoomsGrid') {
                return RoomsReducer(state, action);
            }
            if (includes([
                    'administrationNotificationSettingsGrid',
                    'administrationNotificationSettingsTimelineGrid'
                ], action.id)) {
                return Notification(state, action);
            }
            if (action.id === 'administrationMonthlyReportSettingsGrid') {
                return MonthlyReport(state, action);
            }
            if (action.id === 'administrationAccountsEquipmentGrid') {
                return Object.assign({}, state, {
                    administrationAccountsEquipmentGrid: GridReducer(state.administrationAccountsEquipmentGrid, action)
                });
            }
            if (action.id === 'administrationAccountsEmployeesGrid') {
                return Object.assign({}, state, {
                    administrationAccountsEmployeesGrid: GridReducer(state.administrationAccountsEmployeesGrid, action)
                });
            }
            if (action.id === 'administrationVendorsGrid') {
                return Object.assign({}, state, {
                    administrationVendorsGrid: GridReducer(state.administrationVendorsGrid, action)
                });
            }
            if (action.id === 'administrationAccountsLicenceIsotopesGrid') {
                const licenceIsotopeGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...licenceIsotopeGrid
                    }
                }
            }
            if (action.id === 'administrationAccountsLicenceAppendixGrid') {
                const licenceAppendixGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...licenceAppendixGrid
                    }
                }
            }
        case 'GRID_SORT':
            if (action.id === 'administrationAccountsLicenceIsotopesGrid') {
                const licenceIsotopeGrid = GridReducer(state[action.id], action);
                const licenceIsotopeGridSort = getGridSort(state[action.id].filter, action);
                return {
                    ...state,
                    [action.id]: {
                        ...licenceIsotopeGrid,
                        filter: {
                            ...licenceIsotopeGrid.filter,
                            sort: licenceIsotopeGridSort
                        }
                    }
                }
            }
            if (action.id === 'administrationAccountsLicenceAppendixGrid') {
                const licenceAppendixGrid = GridReducer(state[action.id], action);
                const licenceAppendixGridSort = getGridSort(state[action.id].filter, action);
                return {
                    ...state,
                    [action.id]: {
                        ...licenceAppendixGrid,
                        filter: {
                            ...licenceAppendixGrid.filter,
                            sort: licenceAppendixGridSort
                        }
                    }
                }
            }
        case 'COUNT_ITEMS':
            if (includes([
                    'administrationNotificationSettingsGrid',
                    'administrationNotificationSettingsTimelineGrid'
                ], action.id)) {
                return Notification(state, action);
            }
            if (action.id === 'administrationMonthlyReportSettingsGrid') {
                return MonthlyReport(state, action);
            }
        case 'PASSWORD_CHANGE':
            if (action.id === 'administrationSettings') {
                return Object.assign({}, state, {
                    administrationSettings: Object.assign({}, state.administrationSettings, {password: action.password})
                });
            }
            if (action.id === 'administrationAccountsHeadOfficeContact') {
                return HeadOfficesReducer(state, action);
            }
            if (action.id === 'administrationAccountsSiteLocationContact') {
                return SiteLocationsReducer(state, action);
            }
            if (action.id === 'administrationAccountsDepartmentContact') {
                return DepartmentsReducer(state, action);
            }
        case 'PASSWORD_RESET':
            if (action.id === 'administrationSettings') {
                return Object.assign({}, state, {
                    administrationSettings: Object.assign({}, state.administrationSettings, {password: null})
                });
            }
            if (action.id === 'administrationAccountsHeadOfficeContact') {
                return HeadOfficesReducer(state, action);
            }
            if (action.id === 'administrationAccountsSiteLocationContact') {
                return SiteLocationsReducer(state, action);
            }
            if (action.id === 'administrationAccountsDepartmentContact') {
                return DepartmentsReducer(state, action);
            }
        case 'SELECT_LICENCE':
            if (action.id === 'administrationAccountsLicenceIsotopesGrid') {
                return Object.assign({}, state, {
                    administrationAccountsLicenceIsotopesGrid: set(GridReducer(state.administrationAccountsLicenceIsotopesGrid, {type: 'PAGINATE'}), 'filter.licenceId', action.licenceId)
                });
            }
            if (action.id === 'administrationAccountsLicenceAppendixGrid') {
                return Object.assign({}, state, {
                    administrationAccountsLicenceAppendixGrid: set(GridReducer(state.administrationAccountsLicenceAppendixGrid, {type: 'PAGINATE'}), 'filter.licenceId', action.licenceId)
                });
            }
        case 'RESET_ISOTOPE_FORM':
            if (action.id === 'administrationAccountsLicenceIsotope') {
                return {
                    ...state,
                    administrationAccountsLicenceIsotope: {
                        ...state.administrationAccountsLicenceIsotope,
                        isotope: null,
                        maximumActivity: null,
                        atomicNum: null,
                        atomicNums: [],
                        unit: null,
                        unitType: 'siu'
                    }
                }
            }
        case 'RESET_APPENDIX_FORM':
            if (action.id === 'administrationAccountsLicenceAppendix') {
                return {
                    ...state,
                    administrationAccountsLicenceAppendix: {
                        ...state.administrationAccountsLicenceAppendix,
                        appendixName: null
                    }
                }
            }
        case 'PD_SERVICE_PROVIDERS':
            if (includes([
                'administrationAccountsSiteLocation',
                'administrationAccountsDepartment'
            ], action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        serviceProviders: action.providers,
                    }
                };
            }
        case RM_ISOTOPES:
        case RM_ATOMIC_NUMS:
        case RM_UNITS:
            if (action.id === 'administrationAccountsLicenceIsotope') {
                const reset = action.reset || {};
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.items,
                        ...reset
                    }
                }
            }
        default:
            return state;
    }
}
