import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';

class ReportingEquipmentInfoInspectionsGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.grid_filter = $filter
    }

    $onInit() {
        this.module = 'reporting';
        this.identifier = 'reportingEquipmentInfoInspectionsGrid';
        this.autoload = false;
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                mapper: null},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_ID"),               mapper: 'id'},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_REPORT_TYPE"),      mapper: 'report_type'},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_DATE"),             mapper: 'inspected'},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_DOCUMENT"),         mapper: 'file-download'},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_COMMENT"),          mapper: 'comment'},
            {title: this.grid_filter('translate')("REPORTING.TABS.INSPECTION_REPORT.GRID_RESULT"),           mapper: 'state'}
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), {})(this);
        this.dataAction = () => {};

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

ReportingEquipmentInfoInspectionsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter'];

export const reportingEquipmentInfoInspectionsGrid = {
    bindings: {
        title: '@'
    },
    controller: ReportingEquipmentInfoInspectionsGridController,
    ...gridTpl
};
