/* @ngInject */
export function EmployeesBaseController($controller, $ngRedux, $scope, $rootScope, $q, $timeout, $mdDialog, AuthService, NavigationService, EmployeeService, EmployeeQualificationService, EmployeeModalityTrainingService, EmployeeManager, LicenceService, LicenceManager, Notifier, AccountService, Grid, Employee, Licence, EmployeeQualification, EmployeeModalityTraining, Location, COUNTRY_SELECT_EVENT, GENDERS, $filter, EmployeesActions) {

    angular.extend(this, $controller('ApplicationModuleController', {
        $scope: $scope,
        $timeout: $timeout,
        Notifier: Notifier,
    }));

    // hotfix for displaying Add new location
    $scope.displayAddLocationComponent = AuthService.getUser().basicTypeCode != 'emp';

    var navConfig = NavigationService.getNavConfig();
    $scope.moduleIcon = navConfig.icon;
    $scope.moduleColor = navConfig.colorName;

    $scope.templateQualificationsGrid   = './employee-qualifications-grid.html';
    $scope.templateTrainingsGrid        = './employee-trainings-grid.html';

    $scope.employee = new Employee();
    $scope.licence = new Licence();
    $scope.qualification = new EmployeeQualification();
    $scope.training = new EmployeeModalityTraining();
    $scope.employmentLocation = new Location();
    $scope.genders = GENDERS;
    $scope.documentTypes = [];
    $scope.licenceStatuses = [];
    $scope.occupations = [];
    $scope.qualifications = [];
    $scope.trainingCategories = [];
    $scope.licenceCountry = {enable: false};
    $scope.isServiceEngineer = {status: false};

    $scope.gridQualifications = new Grid();
    $scope.gridQualifications.columns = EmployeeManager.qualificationColumns.toJS();
    $scope.gridQualifications.items = $scope.employee.qualifications;

    $scope.gridTrainings = new Grid();
    $scope.gridTrainings.columns = EmployeeManager.trainingColumns.toJS();
    $scope.gridTrainings.items = $scope.employee.trainings;

    $scope.maxLicenseIssueDate = new Date();
    $scope.minLicenseExpiryDate= new Date();

    EmployeeService.getOccupations().then(function(items) {
        $scope.occupations = items;
    });

    LicenceService.getLicenceStatuses().then(function(items) {
        $scope.licenceStatuses = items;
    });

    $scope.jobTitleLabel = 'Job Title';
    $scope.showPrincipalInvestigator = false;
    $scope.headOfficeName = AuthService.getUser().hofName;
    if ($scope.headOfficeName === 'OTTAWA HOSPITAL' || $scope.headOfficeName === 'EMU HOSPITALS') {
        $scope.jobTitleLabel = 'PACS Initials';
    }
    $scope.headOfficeName === 'OTTAWA HOSPITAL' ? $scope.showRadiationWork = true : $scope.showRadiationWork = false;

    $scope.$on('LOCATION_SUBJECT_SELECTED', function(event, location) {
        if (location.in_subject_basic_type_code === 'hof') {
            location.in_username_par === 'OTTAWA HOSPITAL' ? $scope.showRadiationWork = true : $scope.showRadiationWork = false;
        }

        switch ($scope.selectedTab.id) {
            case EmployeeManager.TAB_EMPLOYEE_DETAILS:
                // TODO not correct object, create EmploymentLocation
                $scope.employee.location = location;
                $scope.employee.location.employeeStatus = true;
                $scope.employee.accountId = location.in_account_id;
                break;
            case EmployeeManager.TAB_EMPLOYEE_LOCATIONS:
                $scope.employmentLocation = location;
                $scope.employmentLocation.accountId = location.in_account_id;
                break;
        }
        LicenceService.getLicenceTypes(location.uuid).then(function(items) {
            $scope.documentTypes = items;
        });
    });

    $scope.$on('FILE_UPLOAD_SUCCESS', function(event, filePath, uploader) {
        switch ($scope.selectedTab.id) {
            case EmployeeManager.TAB_EMPLOYEE_LICENCES:
                $scope.licence.uri = filePath;
                LicenceService.addLicence($scope.employee, $scope.licence).then(function(data) {
                    $scope.licence.id = data.id;
                    $scope.employee.addLicence($scope.licence);
                    $scope.licence = new Licence();
                    $scope.selectedTab.form.$setPristine();
                    $scope.selectedTab.form.$setUntouched();
                    $rootScope.$broadcast(COUNTRY_SELECT_EVENT.RESET);
                    $scope.licenceCountry.enable = false;
                    uploader.clearQueue();
                    $ngRedux.dispatch({
                        type: 'GRID_RELOAD',
                        id: 'employeeLicencesGrid'
                    });
                    const key = data.license_status === 'pen' ?
                        'NOTIFIER_LICENSE_UPDATE_PENDING_APPROVAL' :
                        'NOTIFIER_UPLOAD_SUCCESS';
                    Notifier.success($filter('translate')(`EMPLOYEES.BASE.${key}`));
                }, function(error) {
                    Notifier.responseMessage(error);
                });
                break;
            case EmployeeManager.TAB_EMPLOYEE_MODALITY_TRAININGS:
                $scope.training.document = filePath;
                $scope.addTraining();
                uploader.clearQueue();
                break;
        }
    });

    $scope.addTraining = function() {
        EmployeeModalityTrainingService.addTraining($scope.employee.uuid, $scope.training).then(function(data) {
            $scope.training.id = data.id;
            $scope.employee.addTraining($scope.training);
            $scope.training = new EmployeeModalityTraining();
            $scope.selectedTab.form.$setPristine();
            $scope.selectedTab.form.$setUntouched();
            Notifier.success($filter('translate')('EMPLOYEES.BASE.NOTIFIER_TRAINING_ADD_SUCCESS'));
        }, function(error) {
            Notifier.responseMessage(error);
        });
    }

    $scope.addLicence = function() {
        $scope.$broadcast('show-errors-check-validity');
        if ($scope.selectedTab.form.$invalid) {
            Notifier.error('Please fill in the form fields');
        } else {
            LicenceService.addLicence($scope.employee, $scope.licence).then(function(data) {
                $scope.licence.id = data.id;
                $scope.employee.addLicence($scope.licence);
                $scope.licence = new Licence();
                $scope.selectedTab.form.$setPristine();
                $scope.selectedTab.form.$setUntouched();
                $rootScope.$broadcast(COUNTRY_SELECT_EVENT.RESET);
                $scope.licenceCountry.enable = false;
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'employeeLicencesGrid'
                });
                const key = data.license_status === 'pen' ?
                    'NOTIFIER_LICENSE_UPDATE_PENDING_APPROVAL' :
                    'NOTIFIER_UPLOAD_SUCCESS';
                Notifier.success($filter('translate')(`EMPLOYEES.BASE.${key}`));
            }, function(error) {
                Notifier.responseMessage(error);
            });
        }
    };

    $scope.onLicenceTypeSelected = function() {
        if ($scope.licence.type) {
            if (EmployeeManager.USE_LICENCE === $scope.licence.type.code || EmployeeManager.RADIATION_USE_LICENCE === $scope.licence.type.code) {
                $scope.licenceCountry.enable = true;
            }
            else {
                $rootScope.$broadcast(COUNTRY_SELECT_EVENT.RESET);
                $scope.licence.country.id = null;
                $scope.licenceCountry.enable = false;
            }
        }
    };

    $scope.onEmployeeLicenceModify = function(event) {
        LicenceManager.modifyLicence(event.item, $scope.documentTypes).then(function() {
            $ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: 'employeeLicencesGrid'
            });
        }).catch(function(){

        });
    };

    $scope.onEmployeeLicenceRemove = function(event) {
        var confirm = $mdDialog.confirm()
            .title($filter('translate')('EMPLOYEES.BASE.CONFIRM_SURE'))
            .textContent($filter('translate')('EMPLOYEES.BASE.LICENCE_REMOVAL'))
            .ariaLabel($filter('translate')('EMPLOYEES.BASE.REMOVE_LICENCE_LABEL'))
            .ok($filter('translate')('EMPLOYEES.BASE.OK'))
            .cancel($filter('translate')('EMPLOYEES.BASE.CANCEL'));

        $mdDialog.show(confirm).then(function() {
            LicenceService.removeLicence(event.item).then(function() {
                $scope.employee.removeLicence(event.item);
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'employeeLicencesGrid'
                });
                Notifier.success($filter('translate')('EMPLOYEES.BASE.NOTIFIER_REMOVE_SUCCESS'));
            });
        }, angular.noop);
    };

    $scope.onEmployeeLicenceApprove = function(event) {
        LicenceService.approveLicence(event.item.id).then(function() {
            $ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: 'employeeLicencesGrid'
            });
            Notifier.success('Licence is approved.');
        });
    };

    $scope.manageQualification = function(qualification, subUuid) {
        if (_.isUndefined(qualification)) qualification = $scope.qualification;
        if (_.isUndefined(subUuid)) subUuid = $scope.employee.uuid;
        EmployeeManager.manageQualification(qualification, subUuid);
    };

    $scope.$on('CREATE_QUALIFICATION_COMPLETE', function(event) {
        $scope.qualifications.push($scope.qualification);
    });

    this.assignQualification = function() {
        var deferred = $q.defer();
        if (_.isNull($scope.qualification.id)) {
            Notifier.error($filter('translate')('EMPLOYEES.BASE.NOTIFIER_NON_EXISTING'));
            deferred.reject();
        }
        else if (_.isUndefined(_.find($scope.employee.qualifications, ['id', $scope.qualification.id]))) {
            EmployeeQualificationService.assignQualification($scope.employee.uuid, $scope.qualification.id).then(function() {
                $scope.employee.addQualification($scope.qualification, $scope.employee.uuid);
                $scope.qualification = new EmployeeQualification();
                $scope.$broadcast('RESET_AUTOCOMPLETE');
                Notifier.success($filter('translate')('EMPLOYEES.BASE.NOTIFIER_ASSIGN_SUCCESS'));
                deferred.resolve();
            });
        }
        else {
            Notifier.error($filter('translate')('EMPLOYEES.BASE.NOTIFIER_ALREADY_ASSIGNED'));
            deferred.reject();
        }
        return deferred.promise;
    };

    $scope.removeQualification = function(qualification) {
        EmployeeQualificationService.rejectQualification($scope.employee.uuid, qualification.id).then(function() {
            $scope.employee.removeQualification(qualification);
            Notifier.success($filter('translate')('EMPLOYEES.BASE.NOTIFIER_QUALIFICATION_REMOVED'));
        });
    };

    $scope.getTrainingCategories = function() {
        return _.differenceBy($scope.trainingCategories, $scope.employee.getTrainingCategories(), 'id');
    };

    $scope.removeTraining = function(training) {
        EmployeeModalityTrainingService.removeTraining(training).then(function() {
            $scope.employee.removeTraining(training);
            Notifier.success($filter('translate')('EMPLOYEES.BASE.NOTIFIER_TRAINING_REMOVED'));
        });
    };

    $scope.onOccupationSelected = () => {
        EmployeeService.getLicensesRequiredByOccupation($scope.employee.occupation.id).then(data => {
            $scope.employee.useLicenceRequired = !!data.use_licence_required;
            $scope.employee.boardRegistrationRequired = !!data.ahpra_licence_required;
            $scope.employee.showAsarLicenceRequired = !!data.asar_licence_required;
        });
        if ($scope.employee.occupation.ocuCode === 'res') {
            $scope.showPrincipalInvestigator = true;
        } else {
            $scope.showPrincipalInvestigator = false;
        }
    }

    EmployeeModalityTrainingService.getTrainingCategories().then(function(data) {
        let modalityTrainingCategories = data.categories.map(item => {
            return {
                value: item.id,
                label: item.name
            };
        });
        $ngRedux.dispatch({
            type: 'EMPLOYEES_MODALITY_TRAININGS',
            id: 'employeesGrid',
            items: modalityTrainingCategories
        });
    });

    this.addEmploymentLocation = function() {
        var deferred = $q.defer();
        EmployeeService.addEmploymentLocation($scope.employee.uuid, $scope.employmentLocation.uuid).then(function() {
            $scope.employmentLocation.employeeStatus = true;
            $scope.employee.addEmploymentLocation($scope.employmentLocation);
            $scope.employmentLocation = new Location();
            $ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: 'employeeLocationsGrid'
            });
            $rootScope.$broadcast('SELECT_CASCADE_RESET', _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_LOCATIONS]).form.$name);
            Notifier.success($filter('translate')('EMPLOYEES.BASE.NOTIFIER_LOCATION_ADD_SUCCESS'));
            deferred.resolve();
        }, function(error) {
            deferred.reject(error);
        });
        return deferred.promise;
    };
}
