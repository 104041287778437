import moment from 'moment';
import { DATE_FORMAT } from "../../../../common/utils/util.constants";

/* @ngInject */
export function SafetyPlanManageEmployeeTrainingMatrixController($scope, $state, $mdDialog, SafetyPlanService, AuthService, Notifier,
                                                                 AccountService, DocumentManager) {

    $scope.currentPage = 1;
    $scope.pageSize = 20;

    $scope.trainingDocuments = [];
    $scope.trainingMatrix = [];
    $scope.items = {};

    $scope.hideTable = false;
    $scope.cursorState = 'wait';

    var currentYear = new Date().getFullYear();
    $scope.matrixYears = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3];
    $scope.pdfMatrixYear = currentYear;

    $scope.selectedRscParentLocation = "";
    $scope.selectedRscParentLocationName = "";

    $scope.cursorState = 'wait';

    $scope.reset = function() {
        $scope.trainingDocuments = [];
        $scope.trainingMatrix = [];
        $scope.items = {};
    };

    $scope.isEmptyObject = function(obj) {
        return _.isEmpty(obj);
    };

    $scope.trainingName = $state.current.trainingName;

    $scope.fetchData = function () {
        $scope.answerSubject = null;
        $scope.answer = null;
        $scope.selectedItem = null;

        $scope.hideTable = true;
        $scope.cursorState = 'wait';

        function showTableValuePdf(value, isRsc) {
            if ((isRsc) && ((value == 0))) {
                return "";
            } else if (value == null) {
                return "";
            } else if ((typeof value === 'string') && (value === "")) {
                return "";
            } else if (value == 3) {
                return "F";
            } else if (value == 2) {
                return "P";
            } else if (value == 1) {
                return "X";
            } else if (value == 0) {
                return "O";
            }
        }

        function fillTrainingMatrixGridItems(trainingMatrix) {
            var userTable = {};
            trainingMatrix.forEach(function (item, i) {
                var userTraining = {};
                    userTraining.uuid = item.user.uuid;
                    userTraining.firstName = item.user.firstName;
                    userTraining.lastName = item.user.lastName;
                    userTraining.occupation = item.user.occupation;
                    userTraining.isRsc = item.user.isRsc == "1" ? true : false;
                    userTraining.accountId = item.accountId;
                    userTraining.siteLocation = item.siteLocation;
                    userTraining[item.documentId] = item.trainingStatus;
                    userTraining.trainingStatus = item.trainingStatus;
                    userTable[i+1] = userTraining;
                    userTraining.trainingName = item.trainingName;
                    userTraining.presentationName = item.presentationName;
                    userTraining.endDate = moment(item.endDate).format(DATE_FORMAT.INPUT);
                    userTraining.dueDate = moment(item.dueDate).format(DATE_FORMAT.INPUT);
                    userTraining.startDate = item.startDate ? moment(item.startDate).format(DATE_FORMAT.INPUT) : '';
                    userTraining.mainDocumentIdPdf = showTableValuePdf(item.trainingStatus, userTraining.isRsc); //redundancy to ease pdf generation
            });
            return userTable;
        }

        SafetyPlanService.fetchTrainingMatrixDocuments($state.current.trainingType, $scope.selectedRscParentLocation)
            .then(function (response) {
                if ((response.data.result)) {
                    $scope.trainingDocuments = response.data.result.reverse();
                    $scope.columnsDoc = [];
                    $scope.trainingDocuments.forEach(function (item) {
                        $scope.columnsDoc.push({
                            title: item.documentName,
                            mapper: item.documentId
                        });
                    });
                    $scope.hideTable = false;

                    SafetyPlanService.getTrainingMatrix($state.current.trainingType, $scope.selectedRscParentLocation,  new Date().getFullYear(), null, false)
                        .then(function (response) {
                            if (response.data.result) {
                                $scope.trainingMatrix = response.data.result.reverse();
                                $scope.items = fillTrainingMatrixGridItems($scope.trainingMatrix);
                                //$scope.items = $scope.trainingMatrix;
                                $scope.hideTable = false;
                            } else {
                                $scope.reset();
                            }
                        }, function (error) {
                            Notifier.error("Error fetching training matrix data!");
                            $scope.reset();

                        });
                } else {
                    $scope.reset();
                }
            }, function (error) {
                Notifier.error("Error fetching training matrix documents data!");
                $scope.reset();
            });
    };

    $scope.showTableValue = function (value, isRsc) {
        if ((isRsc) && ((value == 0))) {
            return null;
        }
        else if (value == null) {
            return "";
        } else if ((typeof value === 'string') && (value === "")) {
            return "";
        } else if (value == 3) {
            return "done";
        } else if (value == 2) {
            return "laptop";
        } else if (value == 1) {
            return "warning";
        } else if (value == 0) {
            return "autorenew";
        }
    };

    $scope.determineColour = function (item, columnMaper, isRsc) {
        var value = item.trainingStatus ? item.trainingStatus : null;
        if ((value == null) || (isRsc && (value == 0))) {
            return "";
        } else if (value == 3) {
            return "table-success";
        } else if (value == 2) {
            return "table-info";
        } else if (value == 1) {
            return "table-danger";
        } else if (value == 0) {
            return "table-warning";
        }
    };

    function GenerateTrainingMatrix($scope, $mdDialog, color, title, trainingMatrix, slocUuid) {
        $scope.headerColor = color;
        $scope.headerTitle = title;
        $scope.trainingMatrixItem = trainingMatrix;
        $scope.slocUuid = slocUuid;

        var currentYear = new Date().getFullYear();
        $scope.matrixYears = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3];
        $scope.pdfMatrixYear = currentYear;

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.submit = function (selectedYear) {
            $mdDialog.hide();

            SafetyPlanService.getTrainingMatrix($state.current.trainingType, $scope.slocUuid, selectedYear, null, true)
                .then(function (response) {
                    if (response.data.result) {
                        DocumentManager.downloadFile("/" + response.data.result, 3);
                    } else {

                    }
                }, function (error) {
                    Notifier.error("Error generating training matrix");
                });
        }
    };

    $scope.onGenerateTrainingMatrixPdf = function (item) {
        $mdDialog.show({
            controller: GenerateTrainingMatrix,
            templateUrl: './safety-plan-generate-training-matrix-dialog.html',
            scope:  $scope, // use parent scope in template
            preserveScope: true,
            locals: {
                color: $state.current.trainingColor,
                title: 'Download Training Matrix PDF',
                trainingMatrix: item,
                slocUuid: $scope.selectedRscParentLocation,
                reviewField: $state.current.trainingType
            },
            clickOutsideToClose: true
        });
    };

    $scope.$on('LOCATION_SUBJECT_SELECTED', function (event, locationItem) {
        $scope.cursorState = 'default';
        $scope.selectedRscParentLocation = locationItem.in_subject_uuid;
        $scope.selectedRscParentLocationName = locationItem.in_username_par;
    });


    $scope.$watch('selectedRscParentLocation', function () {
        if ($scope.selectedRscParentLocation != "") {
            $scope.fetchData();
        }
    });

    var user = AuthService.getUser();
    if (user.parentCount === 1) { //select current default location
        $scope.selectedRscParentLocation = user.parents[user.parents.length - 1].uuid;
    } else {
        $scope.cursorState = 'default';
    }
}
