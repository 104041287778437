
export const IDS = {
    // modules
    dmsManage:                  'dmsManage',

    // components
    dmsManageList:              'dmsManageList',
    dmsManageListGrid:          'dmsManageListGrid',
    dmsManageDetails:           'dmsManageDetails',
    dmsManageDetailsGrid:       'dmsManageDetailsGrid',
    dmsManageVersionsGrid:      'dmsManageVersionsGrid',
    dmsManageVersionDetails:    'dmsManageVersionDetails',

    // tab states:
    app_dms_manage:             'app.document-management.manage',
    app_dms_manage_list:        'app.document-management.manage.list',
    app_dms_manage_details:     'app.document-management.manage.details',
    app_dms_manage_add:         'app.document-management.manage.add',
};
