import {IDS} from '../dms-manage-action-id.constants';
import * as DmsManageListActions from './list.actions';
import {TYPES} from "../dms-manage-action-type.constants";
import {TAB_SHOW} from "../../../../common/tabs/tabs.constants";
import get from "lodash/get";

/**
 * Creates a new DmsManageListController.
 * @class
 */
class DmsManageListController {
    constructor($ngRedux,
                $uiRouterGlobals,
                $state,
                $stateParams,
                $scope,
                $rootScope,
                $filter,
                $timeout,
                $mdSidenav,
                $mdDialog,
                Notifier,
                DocumentManager,
                DocumentService,
                AccreditationService,
                NavigationService,
                AccreditationDocumentsService,
                EmployeeService,
                AuthService
    ) {
        this.$ngRedux = $ngRedux;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.$timeout = $timeout;
        this.$mdSidenav = $mdSidenav;
        this.$mdDialog = $mdDialog;
        this.Notifier = Notifier;
        this.DocumentManager = DocumentManager;
        this.DocumentService = DocumentService;
        this.AccreditationService = AccreditationService;
        this.NavigationService = NavigationService;
        this.AccreditationDocumentsService = AccreditationDocumentsService;
        this.EmployeeService = EmployeeService;
        this.AuthService = AuthService;

        this.isApprovalUser = [
            'Administrator',
            'Quality Manager',
            'Head Radiation Safety Coordinator',
            'Site Radiation Safety Coordinator',
            'Site Assistant Radiation Safety Coordinator'
        ].includes(AuthService.getUser().type);

        this.hideRemoveButton = (this.isApprovalUser === false);

        this.documentId = this.$stateParams.doc;

        this.identifier  = IDS.dmsManage;
        this.parentState = IDS.app_dms_manage;
        this.state       = IDS.app_dms_manage_list;
        this.title       = this.$filter('translate')('DOCUMENT_MANAGEMENT.MANAGE.LIST.TAB');
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(state => {
            return {
            }
        }, DmsManageListActions)(this);
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;

        if(this.documentId) {
            ctrl.fetchDocumentDetails(documentId).then(documentDetails => {
                ctrl.fetchSiteLocationDetails(documentDetails.siteLocationUuid );
                ctrl.switchToDetailsTab(ctrl.$timeout, {
                        itemCode: ctrl.schemeCode,
                        ado: documentId,
                        year: fiscalYear,
                    }
                );
            });
        }

        var user = this.AuthService.getUser();
        if(user.parentCount === 0) {
            this.EmployeeService.getEmploymentLocations(user.uuid).then(siteLocations => {
                const siteLocation = get(siteLocations,'[0]');
                if(siteLocation) {
                    this.fetchSiteLocationDetails( siteLocation.uuid ).then(()=>{
                        // trigger refresh of grid
                        this.$rootScope.$broadcast('LOCATION_SUBJECT_SELECTED', {
                            in_subject_basic_type_code: 'slo',
                            in_subject_uuid: siteLocation.uuid
                        });
                    });
                }
            });
        }
    }

    selectCascadeByQueryParams(EmployeeService, siteLocationUuid, $rootScope) {
        return EmployeeService.getEmploymentLocations(siteLocationUuid).then(result => {
            const headOfficeUuid = result[0].uuid;
            $rootScope.$broadcast('FORCE_SELECT_PARENT', 0, headOfficeUuid, siteLocationUuid );
        })
    }

    onSelect({item}) {
        this.fetchDocumentDetails(item.id).then( () => {
            this.switchToDetailsTab(this.$timeout, {
                    itemCode: this.schemeCode,
                    slo: this.siteLocationUuid,
                    ado: item.id,
                }
            );
        });
    };

    onAddDocumentButton() {
        this.switchToAddDocumentTab({
            hof:    this.headOfficeUuid,
            slo:    this.siteLocationUuid,
        })
    }

    onRemove(event) {
        this.invalidatePnpTemplate(event.item.id, IDS.dmsManageListGrid);
    };

    $onDestroy() {
        this.unsubscribe();
    }

}

DmsManageListController.$inject = [
    '$ngRedux',
    '$uiRouterGlobals',
    '$state',
    '$stateParams',
    '$scope',
    '$rootScope',
    '$filter',
    '$timeout',
    '$mdSidenav',
    '$mdDialog',
    'Notifier',
    'DocumentManager',
    'DocumentService',
    'AccreditationService',
    'NavigationService',
    'AccreditationDocumentsService',
    'EmployeeService',
    'AuthService',
]

export const dmsManageList = {
    bindings: {},
    controller: DmsManageListController,
    template: `
        <div layout-gt-xs="row" layout-align="center center">
            <div class="formHeader" flex>
                <h3>
                    <asset cls="colorAccreditations" src="accreditation_polices_procedures.svg"></asset>{{$ctrl.title}}
                </h3>
            </div>
        </div>

        <dms-manage-list-grid
            title="$ctrl.title"
            on-remove="$ctrl.onRemove($event)"
            on-select="$ctrl.onSelect($event)">
        </dms-manage-list-grid>

        <tabs-navigation
            identifier="{{$ctrl.parentState}}"
            state="{{$ctrl.state}}"
            on-exec="$ctrl.onAddDocumentButton()">
        </tabs-navigation>`,
}
