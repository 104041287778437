/**
 * @module Employees Import
 */
import * as STATES                      from './import.states';
import { empImportHistoryList }         from './history/history.component';
import { empImportHistoryListGrid }     from './history/history-grid.component';
import { empImportHistoryDetails }      from './history/details/details.component';
import { empImportHistoryDetailsGrid }  from './history/details/details-grid.component';
import { empImportNewImport }           from './new-import/new-import.component';
import { empImportNewResult }           from './new-import/result/result.component';
import { empImportNewResultGrid }       from './new-import/result/result-grid.component';
import {IDS}                            from "./import-action-id.constants";

export const EMP_IMPORT_MODULE = angular.module(
    'migration.employees.import',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values(STATES).map(state => stateRegistry.register(state));
}]).component({
    empImportHistoryList,
    empImportHistoryListGrid,

    empImportHistoryDetails,
    empImportHistoryDetailsGrid,

    empImportNewImport,
    empImportNewResult,
    empImportNewResultGrid

}).run(['$templateCache', ({ put }) => {
    put(IDS.app_employees_import_history_list,      '<emp-import-history-list></emp-import-history-list>');
    put(IDS.app_employees_import_history_details,   '<emp-import-history-details></emp-import-history-details>');
    put(IDS.app_employees_import_new_import,        '<emp-import-new-import></emp-import-new-import>');
    put(IDS.app_employees_import_new_result,        '<emp-import-new-result></emp-import-new-result>');

    put('./history-grid-filter.html', require('./history/history-grid-filter.html'));
    put('./details-grid-filter.html', require('./history/details/details-grid-filter.html'));
}]);
