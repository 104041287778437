/**
 * @module root/components/dms/manage
 */
import * as STATES              from './dms-manage.states';
import { IDS }                  from './dms-manage-action-id.constants';
import { dmsManageList}         from './list/list.component';
import { dmsManageListGrid}     from './list/list-grid.component';
import { dmsManageDetails}      from './details/details.component';
import { dmsManageDetailsGrid}  from './details/details-grid.component';

export const DMS_MANAGE_MODULE = angular.module(
    'migration.dms.manage',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values(STATES).map(state => stateRegistry.register(state))
}]).component({
    dmsManageList,
    dmsManageListGrid,

    dmsManageDetails,
    dmsManageDetailsGrid,
}).run(['$templateCache', ({ put }) => {
    put(IDS.app_dms_manage_list,         '<dms-manage-list></dms-manage-list>');
    put(IDS.app_dms_manage_details,      '<dms-manage-details></dms-manage-details>');
    put(IDS.app_dms_manage_add,          '<dms-manage-details></dms-manage-details>');
    put('./list-grid-filter.html', require('./list/list-grid-filter.html'));
}]);
