import { TabsReducer } from '../common/tabs/tabs.reducer';
import { TabsInit } from '../common/tabs/tabs.resolver';
import { GridReducer } from '../common/grid/grid.reducer';
import set from 'lodash/set';
import get from 'lodash/get';
import merge from 'lodash/merge';
import update from 'lodash/update';

export function personalDosimetryReducer(state, action) {
    if (typeof state === 'undefined') {
        return {
            'app.personalDosimetry.import': {
                ...TabsReducer(),
                ...TabsInit([
                    {
                        state: 'app.personalDosimetry.import.document',
                        title: 'Import Report Document',
                        visible: true,
                        disabled: false,
                        loading: false
                    },
                    {
                        state: 'app.personalDosimetry.import.details',
                        title: 'Report Details',
                        visible: true,
                        disabled: true,
                        loading: false,
                        buttons: {
                            execute: {label: 'Send Notifications'}
                        }
                    },
                    {
                        state: 'app.personalDosimetry.import.detailsResult',
                        title: 'Individual Result',
                        visible: false,
                        disabled: false,
                        loading: false,
                        buttons: {
                            execute: {label: 'Download'}
                        }
                    }
                ]),
            },
            'app.personalDosimetry.reports': {
                ...TabsReducer(),
                ...TabsInit([
                    {
                        state: 'app.personalDosimetry.reports.listing',
                        title: 'Reports',
                        visible: true,
                        disabled: false,
                        loading: false
                    },
                    {
                        state: 'app.personalDosimetry.reports.details',
                        title: 'Report Details',
                        visible: true,
                        disabled: true,
                        loading: false,
                        buttons: {
                            execute: {label: 'Send Notifications'}
                        }
                    },
                    {
                        state: 'app.personalDosimetry.reports.detailsResult',
                        title: 'Individual Result',
                        visible: false,
                        disabled: false,
                        loading: false,
                        buttons: {
                            execute: {label: 'Download'}
                        }
                    }
                ]),
            },
            'app.personalDosimetry.settings': {
                ...TabsReducer(),
                ...TabsInit([
                    {
                        state: 'app.personalDosimetry.settings.listing',
                        title: 'Settings',
                        visible: true,
                        disabled: false,
                        loading: false,
                    },
                    {
                        state: 'app.personalDosimetry.settings.details',
                        title: 'Limit Details',
                        visible: false,
                        disabled: false,
                        loading: false,
                        buttons: {
                            execute: {label: 'Save'}
                        }
                    },
                ]),
            },
            personalDosimetryImport: {
                provider: null,
                providers: [],
                dose_unit: null,
                dose_units: []
            },
            personalDosimetrySettings: {
                pdl_id: null,
                th_limit_monthly: null,
                th_limit_2_monthly: null,
                th_limit_3_monthly: null,
                th_limit_9_monthly: null,
                th_limit_yearly: null,
                al_limit_monthly: null,
                al_limit_2_monthly: null,
                al_limit_3_monthly: null,
                al_limit_9_monthly: null,
                al_limit_yearly: null,
                loc_name: null,
                loc_uuid: null,
                locations: [],
                provider_name: null,
                provider_code: null,
                providers: [],
                occupation: null,
                occupations: [],
                bty_code: null,
                badge_type: null,
                badge_type_code: null,
                badge_types: [
                    {value: 'whb', label: 'Whole Body'},
                    {value: 'ext', label: 'Extremity Badge'},
                    {value: 'ftb', label: 'Foetal Badge'}
                ],
            },
            personalDosimetrySettingsGrid: merge(GridReducer(), {
                filter: {
                    default_dose_limits_only: null,
                    loc_uuid: null,
                    provider_code: null,
                    bty_code: null,
                    sty_id: null,
                },
            }),
            personalDosimetryReportsGrid: merge(GridReducer(), {
                filter: {
                    provider_code: null,
                    importDateFrom: null,
                    importDateTo: null,
                    location: null
                }
            }),
            personalDosimetryReportsDetailsGrid: merge(GridReducer(), {
                filter: {
                    reportId: null,
                    keyword: null,
                    location: null,
                    periodFrom: null,
                    periodTo: null,
                    doseLimits: null,
                }
            }),
            personalDosimetryReportsDetailsResult: {
                id: null,
                uuid: null,
                accountNum: null,
                accountName: null,
                participantNum: null,
                idNum: null,
                participantName: null,
                birthDate: null,
                dosimeter: null,
                use: null,
                periodDateFrom: null,
                periodDateTo: null,
                inceptionDate: null,
                serialNum: null,
                serviceProvider: null,
                currentDDE: null,
                currentLDE: null,
                currentSDE: null,
                quarterDDE: null,
                quarterLDE: null,
                quarterSDE: null,
                yearDDE: null,
                yearLDE: null,
                yearSDE: null,
                lifeDDE: null,
                lifeLDE: null,
                lifeSDE: null
            },
            personalDosimetryParticipant: {
                participant: null,
                participantEmail: null,
                participants: [],
                participantList: {},
            },
        };
    }

    switch (action.type) {
        case 'TAB_SELECT':
            if (action.id === 'app.personalDosimetry.import') {
                return Object.assign({}, state, {
                    'app.personalDosimetry.import': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.personalDosimetry.reports') {
                return Object.assign({}, state, {
                    'app.personalDosimetry.reports': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.personalDosimetry.settings') {
                return Object.assign({}, state, {
                    'app.personalDosimetry.settings': TabsReducer(state[action.id], action)
                });
            }
        case 'TAB_INIT':
        case 'TAB_OVERRIDE':
        case 'TAB_PARAMS':
        case 'TAB_ENABLE':
        case 'TAB_DISABLE':
        case 'TAB_SHOW':
        case 'TAB_HIDE':
        case 'TAB_LOADING':
            if (action.id === 'app.personalDosimetry.import') {
                return Object.assign({}, state, {
                    'app.personalDosimetry.import': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.personalDosimetry.reports') {
                return Object.assign({}, state, {
                    'app.personalDosimetry.reports': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.personalDosimetry.settings') {
                return Object.assign({}, state, {
                    'app.personalDosimetry.settings': TabsReducer(state[action.id], action)
                });
            }
        case 'TAB_SWITCH':
            if (action.id === 'app.personalDosimetry.import') {
                return Object.assign({}, state, {
                    'app.personalDosimetry.import': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                });
            }
            if (action.id === 'app.personalDosimetry.reports') {
                return Object.assign({}, state, {
                    'app.personalDosimetry.reports': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                });
            }
            if (action.id === 'app.personalDosimetry.settings') {
                return Object.assign({}, state, {
                    'app.personalDosimetry.settings': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                });
            }
        case 'GRID_RESET':
            if (action.id === 'personalDosimetryReportsGrid') {
                const reportsGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...reportsGrid,
                        filter: {
                            ...reportsGrid.filter,
                            provider_code: null,
                            importDateFrom: null,
                            importDateTo: null,
                            location: null
                        },
                    },
                };
            }
            if (action.id === 'personalDosimetryReportsDetailsGrid') {
                const reportDetailsGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...reportDetailsGrid,
                        filter: {
                            ...reportDetailsGrid.filter,
                            keyword: null,
                            location: null,
                            periodFrom: null,
                            periodTo: null,
                            doseLimits: null,
                        },
                    },
                };
            }
            if (action.id === 'personalDosimetrySettingsGrid') {
                const settingsGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...settingsGrid,
                        filter: {
                            ...settingsGrid.filter,
                            default_dose_limits_only: null,
                            loc_uuid: null,
                            provider_code: null,
                            bty_code: null,
                            sty_id: null,
                        }
                    }
                };
            }
        case 'GRID_INIT':
        case 'GRID_RESET_FILTER':
        case 'GRID_OVERRIDE':
        case 'SHOW_LOADING':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
        case 'GRID_SELECT':
        case 'GRID_SELECT_ALL':
        case 'GRID_DESELECT':
        case 'GRID_DESELECT_ALL':
            if (action.id === 'personalDosimetryReportsGrid') {
                return Object.assign({}, state, {
                    personalDosimetryReportsGrid: GridReducer(state.personalDosimetryReportsGrid, action)
                });
            }
            if (action.id === 'personalDosimetryReportsDetailsGrid') {
                return Object.assign({}, state, {
                    personalDosimetryReportsDetailsGrid: GridReducer(state.personalDosimetryReportsDetailsGrid, action)
                });
            }
            if (action.id === 'personalDosimetrySettingsGrid') {
                return Object.assign({}, state, {
                    personalDosimetrySettingsGrid: GridReducer(state.personalDosimetrySettingsGrid, action)
                });
            }
        case 'REPORT_SELECTED':
            if (action.id === 'personalDosimetryReportsDetailsGrid') {
                return Object.assign({}, state, {
                    personalDosimetryReportsDetailsGrid: update(GridReducer(state.personalDosimetryReportsDetailsGrid, action), 'filter', filter => {
                        return Object.assign({}, filter, {
                            reportId: action.reportId
                        });
                    })
                });
            }
        case 'PARTICIPANT_LIMITS':
            if (action.id === 'personalDosimetryReportsDetailsGrid') {
                return {
                    ...state,
                    personalDosimetryReportsDetailsGrid: {
                        ...state.personalDosimetryReportsDetailsGrid,
                        filter: {
                            ...state.personalDosimetryReportsDetailsGrid.filter,
                            doseLimits: action.value || null,
                        }
                    }
                };
            }
        case 'INPUT_TEXT_CHANGE':
        case 'INPUT_EMAIL_CHANGE':
        case 'INPUT_SELECT':
        case 'DATE_SELECTED':
            if (action.id === 'personalDosimetryImport') {
                return {
                    ...state,
                    personalDosimetryImport: {
                        ...state.personalDosimetryImport,
                        [action.path]: action.input,
                        ...(action.path === 'provider' && action.input !== 'pdoarp' && { dose_unit: null }),
                    },
                };
            }
            if (action.id === 'personalDosimetrySettings') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.input
                    }
                }
            }
            if (action.id === 'personalDosimetryReportsGrid') {
                return Object.assign({}, state, {
                    personalDosimetryReportsGrid: GridReducer(state.personalDosimetryReportsGrid, set({...action, type: 'FILTER'}, action.path, action.input))
                });
            }
            if (action.id === 'personalDosimetryReportsDetailsGrid') {
                return Object.assign({}, state, {
                    personalDosimetryReportsDetailsGrid: GridReducer(state.personalDosimetryReportsDetailsGrid, set({...action, type: 'FILTER'}, action.path, action.input))
                });
            }
            if (action.id === 'personalDosimetrySettingsGrid') {
                return Object.assign({}, state, {
                    personalDosimetrySettingsGrid: GridReducer(state.personalDosimetrySettingsGrid, set({...action, type: 'FILTER'}, action.path, action.input))
                });
            }
            if (action.id === 'personalDosimetryParticipant') {
                if (action.index) {
                    return {
                        ...state,
                        personalDosimetryParticipant: {
                            ...state.personalDosimetryParticipant,
                            participantList: state.personalDosimetryParticipant.participantList.map((email, key) => {
                                if (parseInt(action.index) === key) {
                                    return action.input;
                                }
                                return email;
                            }),
                        },
                    };
                } else {
                    return {
                        ...state,
                        personalDosimetryParticipant: {
                            ...state.personalDosimetryParticipant,
                            participantEmail: action.input,
                        },
                    };
                }
            }
            if (action.id === 'personalDosimetryParticipantList') {
                const participantList = { ...state.personalDosimetryParticipant.participantList };
                if (action.input === '' && participantList[action.index]) {
                    delete participantList[action.index];
                } else {
                    participantList[action.index] = action.input;
                }
                return {
                    ...state,
                    personalDosimetryParticipant: {
                        ...state.personalDosimetryParticipant,
                        participantList,
                    },
                };
            }
        case 'AUTOCOMPLETE_SELECTED':
            if (action.id === 'personalDosimetryParticipant') {
                return {
                    ...state,
                    personalDosimetryParticipant: {
                        ...state.personalDosimetryParticipant,
                        [action.path]: action.item,
                        participantEmail: get(action.item, 'email', null),
                    }
                };
            }
        case 'PD_SERVICE_PROVIDERS':
            if (action.id === 'personalDosimetry') {
                return {
                    ...state,
                    personalDosimetryImport: {
                        ...state.personalDosimetryImport,
                        provider: null,
                        providers: action.providers,
                    },
                    personalDosimetrySettings: {
                        ...state.personalDosimetrySettings,
                        provider: null,
                        providers: action.providers,
                    }
                };
            }
        case 'PD_EMPLOYEE_OCCUPATIONS':
            if (action.id === 'personalDosimetry') {
                return {
                    ...state,
                    personalDosimetrySettings: {
                        ...state.personalDosimetrySettings,
                        occupation: null,
                        occupations: action.occupations,
                    }
                };
            }
        case 'PD_LIMIT_DETAILS':
            if (action.id === 'personalDosimetry') {
                return {
                    ...state,
                    personalDosimetrySettings: {
                        ...state.personalDosimetrySettings,
                        ...action.data[0][0],
                        bty_code: action.data[0][0].badge_type_code,
                        locations: action.data[1],
                    }
                };
            }
        case 'PD_LIMIT_DEFAULTS':
            if (action.id === 'personalDosimetry') {
                return {
                    ...state,
                    personalDosimetrySettings: {
                        ...state.personalDosimetrySettings,
                        ...action.data,
                    }
                };
            }
        case 'PD_LIMIT_RESET':
            if (action.id === 'personalDosimetry') {
                return {
                    ...state,
                    personalDosimetrySettings: {
                        ...state.personalDosimetrySettings,
                        pdl_id: null,
                        th_limit_monthly: null,
                        th_limit_2_monthly: null,
                        th_limit_3_monthly: null,
                        th_limit_9_monthly: null,
                        th_limit_yearly: null,
                        al_limit_monthly: null,
                        al_limit_2_monthly: null,
                        al_limit_3_monthly: null,
                        al_limit_9_monthly: null,
                        al_limit_yearly: null,
                        loc_name: null,
                        loc_uuid: null,
                        locations: [],
                        provider_name: null,
                        provider_code: null,
                        occupation: null,
                        bty_code: null,
                        badge_type: null,
                        badge_type_code: null,
                    }
                };
            }
        case 'IMPORT_RESET':
            if (action.id === 'personalDosimetryImport') {
                return {
                    ...state,
                    personalDosimetryImport: {
                        ...state.personalDosimetryImport,
                        provider: null,
                        dose_unit: null,
                    }
                };
            }
        case 'REPORT_RESULT_FETCH':
            if (action.id === 'app.personalDosimetry.import' || action.id === 'app.personalDosimetry.reports') {
                return Object.assign({}, state, {
                    'personalDosimetryReportsDetailsResult': action.data
                });
            }
        case 'LOCATION_SELECTED':
            if (action.id === 'personalDosimetrySettings') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        loc_uuid: action.uuid,
                    }
                }
            }
            if (action.id === 'personalDosimetryReportsGrid') {
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.location', action.uuid)));
            }
            if (action.id === 'personalDosimetrySettingsGrid') {
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.loc_uuid', action.uuid)));
            }
        case 'PARTICIPANT_LIST':
            if (action.id === 'personalDosimetryParticipantList') {
                return {
                    ...state,
                    personalDosimetryParticipant: {
                        ...state.personalDosimetryParticipant,
                        participantList: action.data,
                    },
                };
            }
        case 'PARTICIPANT_SEARCH':
            if (action.id === 'personalDosimetryParticipant') {
                return {
                    ...state,
                    personalDosimetryParticipant: {
                        ...state.personalDosimetryParticipant,
                        participants: action.data.list,
                    },
                };
            }
        case 'PARTICIPANT_EMAIL':
            if (action.id === 'personalDosimetryParticipant') {
                return {
                    ...state,
                    personalDosimetryParticipant: {
                        ...state.personalDosimetryParticipant,
                        participantEmail: action.email,
                    },
                };
            }
        case 'PARTICIPANT_RESET':
            return {
                ...state,
                personalDosimetryParticipant: {
                    participant: null,
                    participantEmail: null,
                    participants: [],
                    participantList: [],
                },
            };
        default:
            return state;
    }
}
