import { formatDate } from '../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../common/utils/util.constants';
import find from 'lodash/find';
import * as EventsAssessmentActions from "./events.assessment.actions";

/**
 * @const {object}
 * @memberOf module:Events
 */
export const eventsAssessmentIncident = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $scope, NavigationService, Notifier, $timeout, $mdDialog) {
        this.dialog = null;

        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(( state ) => ({
                eventID: state.events.eventItem.id,
                eventType: state.events.eventItem.event,
                eventAssessment: state.events.eventAssessment
            }), {
                ...EventsAssessmentActions
            })(this);

            const navConfig = NavigationService.getConfig($state.$current);
            this.colorName = navConfig.colorName;
            this.icon = navConfig.icon;

            this.identifier = $state.$current.parent.name;
            this.state = `${$state.$current.parent.name}.assessmentIncident`;

            this.getAssessmentIncident(this.eventID).then((code) => {
                if (code) {
                    this.assessmentCode = code;
                }
            });
            this.getAssessmentIncidentCodes();
            this.getAssessmentIncidentPersonTypes().then(() => {
                this.setAssessmentIncidentPersonType();
            });

            const evtType = this.eventType ? `${this.eventType} - ` : '';
            this.title = `${evtType}Assessment Review`;
            this.updateDateLimit = new Date();
        };

        this.onGetResponsiblePersons = async (event) => {
            return await this.getResponsiblePersons(event);
        };

        this.onGetQualityManagers = async (event) => {
            return await this.getQualityManagers(event);
        };

        this.onAssessmentCode = (event) => {
            const code = find(this.eventAssessment.incidentSeverityAssessments, ['value', event.input]);
            if (code) {
                this.assessmentCode = code.display;
            }
        };

        this.onSaveAssessment = () => {
            this.reviewRequired = false;
            $scope.assessmentReviewForm.$setPristine();
            $scope.assessmentReviewForm.$setUntouched();
            $timeout(() => {
                if (this.eventAssessmentForm.$valid) {
                    this.saveAssessmentIncident({
                        iev_id: this.eventID,
                        sac_id: this.eventAssessment.incident_severity_assessment,
                        created_by: this.eventAssessment.responsible_person.subject_uuid,
                        updated_on: formatDate(this.eventAssessment.assessment_update_date, DATE_FORMAT.OUTPUT)
                    }).then(() => {
                        Notifier.success('Event Assessment has been successfully saved');
                        const confirm = $mdDialog.confirm()
                            .title('Assessment Review')
                            .textContent('Would you like to submit the Assessment Review?')
                            .ariaLabel('Submit Review')
                            .ok('Yes')
                            .cancel('No');
                        this.dialog = $mdDialog.show(confirm).then(() => {
                            this.submitReview();
                        }, angular.noop);
                    });
                } else {
                    Notifier.error('Please fill in the form fields');
                }
            }, 100);
        }

        this.submitReview = () => {
            this.reviewRequired = true;
            const { assessmentReviewForm } = $scope;
            $timeout(async () => {
                if (assessmentReviewForm.$valid) {
                    if (this.eventAssessment.saved) {
                        this.reviewRequired = false;
                        this.saveAssessmentReview({
                            iev_id: this.eventID,
                            qmn_uuid: this.eventAssessment.quality_manager.subject_uuid,
                            review_comment: this.eventAssessment.quality_manager_review,
                            review_date: formatDate(this.eventAssessment.quality_manager_review_date, DATE_FORMAT.OUTPUT),
                            signature: this.eventAssessment.quality_manager_signature
                        }).then(() => {
                            assessmentReviewForm.$setPristine();
                            assessmentReviewForm.$setUntouched();
                            Notifier.success('Assessment review has been successfully submitted');
                        });
                    } else {
                        Notifier.error('You have to save Event Assessment first');
                    }
                } else {
                    for (const item of assessmentReviewForm.$$controls) {
                        if (item.$invalid) {
                            item.$setTouched();
                        }
                    }
                    Notifier.error('Please fill in the form fields');
                }
            }, 100);
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        };
    },
    template: require('./events-assessment-incident.html'),
    bindings: {
        type: '@'
    }
};
