/* @ngInject */
export function MammoQualityControlTesterCandidateFactory() {
    return function MammoQCTesterCandidate() {

        this.uuid = null;
        this.firstName = null;
        this.lastName = null;
        this.username = null;
        this.occupation = null;
    };
}
