import { IDS } from '../master-doc-list-action-id.constants';
import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import * as DocumentListActions from '../list/list.actions';
import * as DocumentDetailsActions from './details.actions'
import {GRID_RELOAD} from "../../../../common/grid/grid.constants";
class ReviewsGrid extends GridController {
    constructor($ngRedux, $state, NavigationService, AuthService, $filter, $mdDialog, $scope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
        this.AuthService = AuthService;
    }

    $onInit() {
        this.module = 'accreditation';
        this.identifier = IDS.accMasterDocList;
        this.statePath = IDS.documentReviewsGrid;
        this.autoload = false;
        this.filterControls = false;
        this.exportComponent = false;
        this.filterExpandButtonVisible = false
        this.title = this.$filter('translate')('COMMON.FIELD.REVIEWERS');
        this.icon = false;

        const user = this.AuthService.getUser();
        this.isAdmin = user.type === 'Administrator';
        this.basicTypeName = user.basicTypeName;
        this.columns = [
            {title: '#', mapper: null},
            {title: this.$filter('translate')('COMMON.FIELD.NAME'),         mapper: 'nameAndUsername'},
            {title: this.$filter('translate')('COMMON.FIELD.STATUS'),       mapper: 'statusName'},
            {title: this.$filter('translate')('COMMON.FIELD.LAST_CHANGE'),  mapper: 'lastChange'},
            {title: this.$filter('translate')('COMMON.FIELD.COMMENT'),      mapper: 'reviewComment'},
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), {
            ...DocumentListActions,
            ...DocumentDetailsActions
        })(this);

        this.buttons.push(
            {
                name: 'supervisor_approve',
                class: 'btnGridModify',
                label: this.$filter('translate')('COMMON.BUTTON.APPROVE'),
                callback: ({$event}) => {
                    const {arqId, aarId} = $event.item;
                    this.setDocumentResultStatusCode('app', arqId, aarId, `Approved by ${this.basicTypeName}`);
                },
                filter: item =>  item.statusCode === 'pen'
            },
            {
                name: 'supervisor_reject',
                class: 'btnGridRemove',
                label: this.$filter('translate')('COMMON.BUTTON.REJECT'),
                callback: ({$event}) => {
                    const {arqId, aarId} = $event.item;
                    this.setDocumentResultStatusCode('nap', arqId, aarId, `Rejected by ${this.basicTypeName}`);
                },
                filter: item =>  item.statusCode === 'pen'
            },
            {
                name: 'supervisor_reset',
                class: 'btnGrid',
                label: this.$filter('translate')('COMMON.BUTTON.RESET'),
                callback: ({$event}) => {
                    const {arqId, aarId} = $event.item;
                    this.setDocumentResultStatusCode('pen', arqId, aarId, `Reset by ${this.basicTypeName}`);
                },
                filter: item => ['app','nap'].includes(item.statusCode)
            }
        );
        this.init();

        if(this.isAdmin === false) {
            this.hideButtons([
                'supervisor_approve',
                'supervisor_reject',
                'supervisor_reset'
            ]);
        }
    }

    setDocumentResultStatusCode(status_code, arq_id, aar_id = undefined, review_comment = undefined) {
        this.setDocumentResult({
            ado_id: this.adoId,
            status_code,
            arq_id,
            aar_id: aar_id || undefined,
            review_comment,
        }).then( () => {
            this.$ngRedux.dispatch({
                type: GRID_RELOAD,
                id: IDS.accMasterDocList,
                path: IDS.documentListGrid,
            });
            this.fetchDocumentDetails(this.adoId);
        })
    }

    $onDestroy() {
        this.destroy();
    }
}

ReviewsGrid.$inject = ['$ngRedux', '$state', 'NavigationService', 'AuthService', '$filter', '$mdDialog', '$scope'];

export const accMasterDocReviewsGrid = {
    bindings: {
        adoId: '<',
    },
    controller: ReviewsGrid,
    ...gridTpl
}
