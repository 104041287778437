import get from 'lodash/get';

/* @ngInject */
export function EmployeeService($http, API_BASE, Helpers, Employee, Location, Occupation, GENDERS, DATE_FORMAT) {

    this.getEmployeesListGrid = function(filter) {
        return $http({
            url: API_BASE + "/employees-list-grid",
            method: "GET",
            params: {
                ...filter,
                searched_column: filter.keyword ? filter.searched_column : null,
                licences_status: filter.licences_status === 'null' ? null : filter.licences_status,
                status: filter.status === 'all' ? null : filter.status,
                order_column:   _.get(filter, 'sort.column'),
                order:          _.get(filter, 'sort.value'),
                identifier: null,
            }
        }).then(function(response) {
            if (filter.exportFormat) {
                return response.data;
            }
            else {
                var employees = response.data.items.map(function(item) {
                    const siteLocationsCount= parseInt(item.site_locations_count) > 1 ? ` (+${item.site_locations_count - 1})`:'';
                    const occupationsCount  = parseInt(item.occupations_count) > 1   ? ` (+${item.occupations_count - 1})`:'';

                    var employee                = new Employee();
                    employee.uuid               = item.subject_uuid;
                    employee.location.uuid      = item.parent_uuid;
                    employee.siteLocation.name  = `${item.account_name}${siteLocationsCount}`;
                    employee.occupation.id      = item.subject_type_id;
                    employee.occupation.name    = `${item.subject_type_name}${occupationsCount}`;
                    employee.jobTitle           = item.job_title;
                    employee.firstName          = item.first_name;
                    employee.lastName           = item.last_name;
                    employee.username           = item.username;
                    employee.email              = item.email;
                    employee.licenceType        = item.licence_type;
                    employee.licenceNumber      = item.licence_number;
                    employee.licenceExpiryDate  = Helpers.formatDate(item.expiry_date, DATE_FORMAT.INPUT);
                    employee.critical           = item.critical;
                    employee.warning            = item.warning;
                    employee.radiationWorkStatus = item.radiation_work_status;
                    employee.useLicenceRequired = !!item.use_licence_required;
                    employee.asarLicenceRequired = !!item.asar_licence_required;
                    employee.boardRegistrationRequired = !!item.board_registration_required;
                    return employee;
                });
                return {
                    total: response.data.items_count,
                    items: employees
                };
            }
        });
    };

    this.getEmployeesExportDocument = (filter) => {
        return $http({
            url: API_BASE + "/employees-export-document",
            method: "GET",
            params: {
                ...filter,
                occupation: filter.occupation ? parseInt(filter.occupation) : undefined
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.fetchEmployeeDetails = (emp_uuid, loc_uuid) => {
        return $http({
            url: `${API_BASE}/employee-details`,
            method: "GET",
            params: {
                occupation_base_type: 'emp',
                emp_uuid,
                loc_uuid,
            }
        }).then(function(response) {
            var employee = new Employee();
            var subject = _.first(response.data[0]);

            var occupation = new Occupation();
            occupation.id               = subject.type_id;
            occupation.name             = subject.occupation;
            occupation.ocuCode          = subject.occupation_code;

            employee.id                 = subject.account_id;
            employee.accountId          = subject.account_id;
            employee.uuid               = subject.uuid;
            employee.location.uuid      = subject.dep_uuid;
            employee.location.name      = subject.department_name;
            employee.locations          = response.data[1];
            employee.username           = subject.username;
            employee.name               = subject.client_name;
            employee.created            = Helpers.formatDate(subject.created_time, DATE_FORMAT.INPUT);
            employee.occupation         = occupation;
            employee.jobTitle           = subject.job_title;
            employee.gender             = _.find(GENDERS, ['id', parseInt(subject.gender)]);
            employee.firstName          = subject.first_name;
            employee.lastName           = subject.last_name;
            employee.birthday           = Helpers.formatDate(subject.birthdate);
            employee.phone              = subject.phone;
            employee.email              = subject.email;
            employee.statusName         = subject.status_name;
            employee.mutable            = parseInt(subject.is_mutable);
            employee.radiationWorkStatus = subject.radiation_work_status;
            employee.useLicenceRequired = !!subject.use_licence_required;
            employee.boardRegistrationRequired = !!subject.board_registration_required;
            employee.asarLicenceRequired = !!subject.asar_licence_required;
            employee.principalInvestigator = !!subject.principal_investigator;
            employee.uniqueIdentifier   = subject.unique_identifier;
            employee.importCode         = subject.import_code;
            employee.excludedFromImport = parseInt(subject.excluded_from_import) === 1;
            employee.disableCheckboxUseLicenceRequired = subject.disable_checkbox_use_licence_required;
            employee.managerOccupation = subject.moc_code;

            // TODO this should be status getter in employee factory
            switch(subject.status_id) {
                case '1':
                    employee.status = false;
                    employee.location.employeeStatus = false;
                    break;
                case '2':
                    employee.status = true;
                    employee.location.employeeStatus = true;
                    break;
            }
            return employee;
        });
    };

    this.addEmployee = function(employee) {
        return $http({
            url: API_BASE + "/employee",
            method: "POST",
            data: {
                in_loc_uuid:        employee.location.uuid,
                in_username:        employee.username,
                in_ocu_id:          employee.occupation.id,
                in_job_title:       employee.jobTitle,
                in_password:        employee.password,
                in_email:           employee.email.toLowerCase(),
                in_phone:           employee.phone || null,
                in_first_name:      employee.firstName,
                in_last_name:       employee.lastName,
                in_birthday:        Helpers.formatDate(employee.birthday),
                in_gender:          employee.gender.id,
                useLicenceRequired: employee.useLicenceRequired,
                board_registration_required: employee.boardRegistrationRequired,
                asar_licence_required: employee.showAsarLicenceRequired ? employee.asarLicenceRequired : false,
                radiationWorkStatus: employee.radiationWorkStatus,
                principalInvestigator: employee.principalInvestigator,
                uniqueIdentifier:   employee.uniqueIdentifier,
                moc_code:           employee.managerOccupation === 'null' ? null : employee.managerOccupation
            }
        }).then(function(result) {
            return result.data.params ? result.data.params.emp_uuid : result.data.uuid;
        });
    };

    this.updateEmployee = function(employee) {
        return $http({
            url: API_BASE + "/employee-update",
            method: "PUT",
            data: {
                emp_uuid:               employee.uuid,
                type:                   38,
                username:               employee.username,
                password:               employee.password,
                occ_id:                 employee.occupation.id,
                job_title:               employee.jobTitle,
                gender:                 get(employee, 'gender.id', null) || 1,
                first_name:             employee.firstName,
                last_name:              employee.lastName,
                birthdate:              Helpers.formatDate(employee.birthday),
                country:                1,
                phone:                  employee.phone || null,
                email:                  employee.email.toLowerCase(),
                use_licence_required:   employee.useLicenceRequired,
                asar_licence_required:  employee.showAsarLicenceRequired ? employee.asarLicenceRequired : false,
                board_registration_required: employee.boardRegistrationRequired,
                radiation_work_status:  employee.radiationWorkStatus,
                principal_investigator: employee.principalInvestigator,
                unique_identifier:      employee.uniqueIdentifier,
                excluded_from_import:   employee.excludedFromImport,
                moc_code:               employee.managerOccupation === 'null' ? null : employee.managerOccupation
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.addEmploymentLocation = function(employeeUuid, parentUuid) {
        return $http({
            url: API_BASE + "/employee/" + employeeUuid + "/assign",
            method: "PUT",
            data: {
                user: employeeUuid,
                parent: parentUuid
            }
        }).then(function(response) {
            return response;
        });
    };

    this.getEmploymentLocations = function(employeeUuid) {
        return $http({
            url: API_BASE + "/employee/" + employeeUuid + "/parents",
            method: "GET"
        }).then(function(response) {
            return response.data.map(function(item) {
                // TODO not correct object, create EmploymentLocation
                var location        = new Location();
                location.uuid       = item.parent_uuid;
                location.accountId  = item.account_id;
                location.name       = item.username;
                location.status     = item.status_name;
                location.pending_date = item.pending_date;
                // TODO this should be status getter in employee factory
                switch(item.status_id) {
                    case '1':
                    case 1:
                        location.employeeStatus = false;
                        break;
                    case '2':
                    case 2:
                        location.employeeStatus = true;
                        break;
                }
                return location;
            });
        });
    };

    this.getOccupations = function(uuid, type) {
        let location = null;
        let employee = null;
        if (type === 'slo') {
            location = uuid;
        } else if (type === 'emp') {
            employee = uuid;
        }
        return $http({
            url: API_BASE + "/occupations",
            method: "GET",
            params: {
                slo_uuid: location,
                emp_uuid: employee
            }
        }).then(function(response) {
            return response.data.map(function(item) {
                var occupation      = new Occupation();
                occupation.id       = item.ocu_id;
                occupation.name     = item.ocu_name;
                occupation.ocuCode  = item.ocu_code;
                occupation.refNum   = item.ocu_ref_no;
                return occupation;
            });
        });
    };

    this.resendLoginInfo = function(employeeUuid, username) {
        return $http({
            url: API_BASE + "/login-info-resend/employee",
            method: "POST",
            params: {
                usr_uuid:   employeeUuid,
                username:   username
            }
        }).then(function(result) {
            return result;
        });
    };

    this.setEmployeeLocationStatus = function(employeeUuid, locationUuid, pendingToDate, employeeStatus) {
        return $http({
            url: API_BASE + "/employee/set_location_status",
            method: "POST",
            params: {
                loc_uuid:   locationUuid,
                sub_uuid:   employeeUuid,
                status: employeeStatus,
                pending_to_date: Helpers.formatDate(pendingToDate)
            }
        }).then(function(result) {
            return result;
        });
    };

    this.getLicensesRequiredByOccupation = (occupation_id) => {
        return $http({
            url: API_BASE + "/employee/occupation/required-licenses",
            method: "GET",
            params: {
                occ_id: occupation_id
            }
        }).then(response => {
            return response.data
        });
    }
}
