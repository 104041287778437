import moment from 'moment';

/* @ngInject */
export function UltrasoundFactory(Equipment, Location, RegistrationData, CertificateData, Vendor, Helpers, DATE_FORMAT){
    return function Ultrasound() {

        Equipment.call(this);

        this.barcode = null;
        this.lspn = '';
        this.location = new Location();
        this.registrationData = new RegistrationData();
        this.certificateData = new CertificateData();
        this.transducers = [];
        this.vendor = new Vendor();
        this.description = '';
        this.capitalSensitivityExpiryDate = null;
        this.capitalSensitivityExpiryDateShow = false;

        var equipmentInstallationDate = null;
        Object.defineProperty(this, 'equipmentInstallationDate', {
            configurable: true,
            enumerable: true,
            get: function() { return equipmentInstallationDate; },
            set: function(value) {
                equipmentInstallationDate = value;
                if (this.capitalSensitivityExpiryDate == null && value != null)
                    this.capitalSensitivityExpiryDate = moment(value).add(10, 'years').toDate();
            }
        });

        this.formatEquipmentInstallationDate = function() {
            return Helpers.formatDate(this.equipmentInstallationDate, DATE_FORMAT.INPUT);
        };

        this.formatCapitalSensitivityExpiryDate = function() {
            return Helpers.formatDate(this.capitalSensitivityExpiryDate, DATE_FORMAT.INPUT);
        };
    };
}
