import watch from 'redux-watch';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isUndefined from 'lodash/isUndefined';
import isFunction from 'lodash/isFunction';

/**
 * Creates a new GridController.
 * @class
 */
export class GridController {
    constructor($ngRedux, $state, NavigationService, $filter, $mdDialog) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.NavigationService = NavigationService;
        this.header = true;
        this.autoload = true;
        this.filterComponent = true;
        this.filterExpandButtonVisible = true;
        this.exportComponent = true;
        this.customComponent = null;
        this.documentsComponent = false;
        this.importComponent = false;
        this.exportExclude = [];
        this.confirmDialogOnRemove = false;
        this.confirmDialogOnRemoveText = null;
        this.exportService = null;
        this.importService = null;
        this.dataAction = null;
        this.filter = null;
        this.filterActions = {};
        this.columns = [];
        this.buttons = [];
        this.navConfig = NavigationService.getConfig($state.$current);
        this.onGridReset = () => {};
        this.$filter = $filter;
        this.$mdDialog = $mdDialog;
    }

    init() {
        const watchReload = watch(this.$ngRedux.getState, this.getStateContainer.call(this, 'reload'));
        this.onReload = this.$ngRedux.subscribe(watchReload(() => {
            this.getItems();
        }));

        this.columns = this.columns.filter(item => {
            return !includes(this.override.columns, item.mapper);
        });

        if (isUndefined(this.onSelect) || includes(this.override.buttons, 'on-select'))
            this.onSelect = angular.noop;

        if (isFunction(this.onModify) && !includes(this.override.buttons, 'on-modify'))
            this.buttons.push({
                class: 'btnGridModify',
                label: 'Modify',
                callback: event => {
                    this.onModify(event);
                }
            });

        if (isFunction(this.onRemove) && !includes(this.override.buttons, 'on-remove'))
            this.buttons.push({
                class: 'btnGridRemove',
                label: 'Remove',
                callback: this.confirmDialogOnRemove ?
                    event => {
                        this.onRemoveWithConfirm(event)
                    } :
                    event => {
                        this.onRemove(event)
                    }
            });
        this.buttonsDefault = this.buttons.concat();

        if (isFunction(this.onReset)) {
            const watchReset = watch(this.$ngRedux.getState, this.getStateContainer.call(this, 'reset'));
            this.onGridReset = this.$ngRedux.subscribe(watchReset(() => {
                this.onReset();
            }));
        }
        else this.onReset = angular.noop;

        this.$ngRedux.dispatch({
            type: 'GRID_INIT',
            id: this.identifier,
            path: this.statePath
        });

        if (this.autoload)
            this.getItems();
    }

    selectItem(event) {
        this.onSelect({
            $event: event
        });
    };

    onRemoveWithConfirm(event) {
        const prefix = 'COMMON.CONFIRM_DIALOG';
        const txt = this.$filter('translate')([
            `${prefix}.ARE_YOU_SURE`,
            `${prefix}.DELETE_CONFIRM`,
            `${prefix}.REMOVE_ITEM`,
            `${prefix}.YES`,
            `${prefix}.NO`,
        ]);
        const confirm = this.$mdDialog.confirm()
            .textContent(this.confirmDialogOnRemoveText || txt[`${prefix}.DELETE_CONFIRM`])
            .title(     txt[`${prefix}.ARE_YOU_SURE`])
            .ariaLabel( txt[`${prefix}.REMOVE_ITEM`])
            .ok(        txt[`${prefix}.YES`])
            .cancel(    txt[`${prefix}.NO`]);

        this.dialog = this.$mdDialog.show(confirm).then(() => {
            if(this.onRemove)
                this.onRemove(event);
            else if(this.onDelete)
                this.onDelete(event);
        })
    };

    hideButtons(names) {
        names = Array.isArray(names) ? names : [names];
        this.buttons = this.buttonsDefault.concat().filter( item =>
            names.includes(item.name) === false
        );
    }

    destroy() {
        if (this.unsubscribe)
            this.unsubscribe();

        if (this.dialog)
            this.$mdDialog.hide(this.dialog);

        this.onReload && this.onReload();
        this.onGridReset();
    };

    getItems() {
        this.$ngRedux.dispatch({
            type: 'SHOW_LOADING',
            id: this.identifier,
            path: this.statePath
        });
        this.dataAction(this.filter);
    }

    mapState() {
        return function(state) {
            return {
                items:      get(state, this.getStateContainer('items')),
                itemsCount: get(state, this.getStateContainer('itemsCount')),
                loading:    get(state, this.getStateContainer('loading')),
                filter:     get(state, this.getStateContainer('filter')),
                override:   get(state, this.getStateContainer('override'))
            }
        }
    }

    getStateContainer(endPath) {
        if(this.statePath)
            endPath = `${this.statePath}.${endPath}`;
        return [this.module, this.identifier, endPath].join('.');
    }
}

GridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter', '$mdDialog'];

export const gridTpl = { template: require('./grid.html') };
