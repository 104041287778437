/* @ngInject */
export function InputEmployeeKeywordController($ngRedux, $filter, Notifier) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {

        ctrl.options = (typeof ctrl.debounce === 'undefined') ? {} : { debounce: ctrl.debounce };

        unsubscribe = $ngRedux.connect(mapStateToThis, {})(this);
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    ctrl.onChange = function() {
        ctrl.keyword = ctrl.keyword.replace(/[^a-zA-Z0-9._@-]/gi, '');
        $ngRedux.dispatch({
            type: 'KEYWORD_CHANGE',
            id: ctrl.identifier,
            keyword: ctrl.keyword
        });
        if (!ctrl.searchedColumn) {
            Notifier.error($filter('translate')('EMPLOYEES.GRID.SELECT_KEYWORD_COLUMN'));
        }
    };

    ctrl.alphaNumericOnly = function(event) {
        if (event.key) {
            const key = event.key.replace(/[^a-zA-Z0-9._@-]/gi, '');
            return !!key.length;
        } else {
            return false;
        }
    };

    function mapStateToThis(state) {
        return {
            keyword: state.employees[ctrl.identifier].filter.keyword,
            searchedColumn: state.employees[ctrl.identifier].filter.searched_column
        };
    }
}
