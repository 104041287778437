import { DOCUMENT_TYPE } from '../../../../common/utils/util.constants';
import {TAB_ENABLE, TAB_SELECT, TAB_SHOW} from '../../../../common/tabs/tabs.constants';
import {GRID_FILTER, GRID_RELOAD, GRID_RESET} from "../../../../common/grid/grid.constants";
import {IDS} from '../standard-action-id.constants';
import {IDS as IDS_ADD_DOCUMENT} from '../../add-document/add-document-action-id.constants';
import * as AssessmentActions from '../standard.actions';
import * as AccreditationActions from '../../accreditation.actions';
import watch from 'redux-watch';
import {TYPES} from '../standard-action-type.constants';
import moment from "moment";
import get from "lodash/get";
import {INIT_DASHBOARD} from "../../../../common/navigation/navigation.actions.types";
import {TYPES as ACC_TYPES} from "../../accreditation-action-type.constants";
/**
 * Creates a new StandardDocumentListController.
 * @class
 */
class StandardDocumentListController {
    constructor($ngRedux,
                $state,
                $stateParams,
                $scope,
                $rootScope,
                $filter,
                $timeout,
                $mdDialog,
                Notifier,
                AuthService,
                EmployeeService,
                DocumentManager
                ) {

        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.$timeout = $timeout;
        this.$mdDialog = $mdDialog;
        this.Notifier = Notifier;
        this.AuthService = AuthService;
        this.EmployeeService = EmployeeService;
        this.DocumentManager = DocumentManager;
    }

    $onInit() {
        this.isPublic = this.$state.$current.name.split('.')[0] === 'public';
        this.module = 'accreditation';
        this.identifier = IDS.accStandard
        this.gridStatePath = IDS.standardDocumentsProgressGrid;
        this.schemeCode = this.$stateParams.itemCode;
        this.unsubscribe = this.$ngRedux.connect(state => {
            const accDashboardLocationYear = state.accreditation.accDashboardLocationYear;
            const accStandard = state.accreditation.accStandard;
            const assessmentItems = accStandard.assessmentItems;
            const sectionsTree = state.accreditation.sectionsTree;
            const sectionsList = index(sectionsTree);
            const standard = state.accreditation.selectedSection /*|| sectionsList.find(item => item && item.id === this.$stateParams.standard);*/
            const standardParent = state.accreditation.selectedSectionParent || standard && sectionsList.find(item => item && item.id === standard.parentId);
            const standardStatus = get(standard, 'status');
            const standardStatusName = standardStatus &&
                this.$filter('translate')(`ACCREDITATION.STANDARDS.STATUS.${standardStatus.toUpperCase()}`);

            const hasAssessmentItems = get(standard, 'assessment_items_total_count') > 0;
            const qualityManagers = accStandard.qualityManagers.map(item => ({ ...item,
                label: standard.level === 3
                    ?    item.assignee
                    : `${item.assignee} (${item.assessment_items_count - item.assessment_items_unresolved}/${item.assessment_items_count}`
            }));
            const hasQualityManagers = get(this.qualityManagers, 'length');
            return {
                userUuid:               state.app.user.uuid,
                isUserEmployee:         !state.app.user.typeCode,
                standard,
                standardParent,
                standardId:             get(standard, 'id'),
                standardName:           get(standard, 'name'),
                activityDescription:    get(standard, 'description'),
                qualityManagers,
                hasQualityManagers,
                showAddDocumentButton: true,
                activityHasQualityManagers: get(standard, 'qualityManagers.length'),
                standardStatus,

                sectionsTree,
                siteLocationName:       get(accStandard, 'selectedSiteLocation.locationName'),
                siteLocationUuid:       get(accStandard, 'selectedSiteLocation.uuid'),
                fiscalYear:             accDashboardLocationYear.year,
                lastSiteLocation:       accDashboardLocationYear.siteLocation,
                lastHeadOffice:         accDashboardLocationYear.headOffice,


                standardStatusName,
                assessmentItems: (assessmentItems || []).filter(
                    item => item && ['asssta','asscon'].includes(item.type_code) === false // filter out standard and condition types
                )
            }
        }, {...AssessmentActions, ...AccreditationActions })(this);

        const {standard, slo, year, itemCode} = this.$stateParams;
        const standardId = standard || this.standardId;

        if((!this.lastHeadOffice || !this.lastSiteLocation) && this.$stateParams.slo) {
            this.EmployeeService.getEmploymentLocations(slo).then(result => {
                this.$ngRedux.dispatch({
                    type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                    accDashboardLocationYear: {
                        headOffice: result[0].uuid,
                        siteLocation: slo,
                        year,
                    }
                });

                this.$rootScope.$broadcast('FORCE_SELECT_PARENT', 0, result[0].uuid, slo);
            })
        }
        this.fetchSiteLocationDetails(slo);

        if(this.sectionsTree.length){
            this.postInit(this, standardId, slo, year);
        }
        else {
            const ctrl = this;
            this.fetchAssessmentStandardsTreeProgress(this.schemeCode, slo, year).then((data) => {
                ctrl.postInit(ctrl, standard, slo, year);
            });
        }
        // if(this.lastDashboard) {
        //     this.$timeout( () => {
        //         this.$ngRedux.dispatch({
        //             type: INIT_DASHBOARD,
        //             dashboard: this.lastDashboard,
        //         });
        //     },200)
        // }
    }

    async postInit(ctrl, standardId, siteLocationUuid, year) {
        ctrl.selectStandardById(standardId, siteLocationUuid, year, this.identifier);

        ctrl.$ngRedux.dispatch({
            type: GRID_FILTER,
            id: this.identifier,
            path: this.gridStatePath,
            filter: { standardId, siteLocationUuid, year }
        });

        let tasks = await ctrl.fetchStandardAuditTasks(standardId, siteLocationUuid, year);
        if(!tasks.length && ctrl.standardParent) {
            tasks = await ctrl.fetchStandardAuditTasks(ctrl.standardParent.id, siteLocationUuid, year);
        }
        ctrl.$ngRedux.dispatch({ type: TYPES.FETCH_STANDARD_AUDIT_TASKS, tasks });
    }

    onSelect({item}){
        if(item.is_enabled)
            this.onDetailsButton({item});
    }

    onDetailsButton({item}){
        this.$ngRedux.dispatch({
            type: TYPES.FETCH_DOCUMENT_DETAILS,
            item
        });

        this.$ngRedux.dispatch({
            type: TAB_SHOW,
            id: IDS.app_accreditation_scheme_standards_documents,
            state: IDS.app_accreditation_scheme_standards_documents_details
        });
    }

    onDeleteButton({item}){
        const successMessage = 'COMMON.SERVER_RESPONSE.ITEM_DELETED';
        this.invalidateRequiredDocument(item.ard_id, this.siteLocationUuid, successMessage).then( () => {
            this.$ngRedux.dispatch({
                type: GRID_RELOAD,
                id: this.identifier,
                path: this.gridStatePath,
            });
        });
    }

    onNotApplicableButton({item}){
        const successMessage = 'COMMON.SERVER_RESPONSE.ITEM_DISABLED';
        this.invalidateRequiredDocument(item.ard_id, this.siteLocationUuid, successMessage).then( () => {
            this.$ngRedux.dispatch({
                type: GRID_RELOAD,
                id: this.identifier,
                path: this.gridStatePath,
            });
        });
    }

    onApplicableButton({item}){
        this.reactivateRequiredDocument(item.ard_id, this.siteLocationUuid).then( () => {
            this.$ngRedux.dispatch({
                type: GRID_RELOAD,
                id: this.identifier,
                path: this.gridStatePath,
            });
        });
    }

    // onDownloadButton({item}){
    //     this.DocumentManager.downloadFile(item.doc_url);
    // }

    notAllAssessmentItemsUploaded(items) {
        // if any of assessment items contains no documents
        return !items
            || !items.length
            ||  items.some(item =>
                   !item.documents
                || !item.documents.items
                || !item.documents.items.length)
    }

    noDocumentPending(items) {
        // if no assessment item contains document that is waiting for evaluation
        return !(items.some(item =>
                   item.documents
                && item.documents.items
                && item.documents.items.some(document =>
                       document.reviewers
                    && document.reviewers.length
                    && document.reviewers.some(reviewer => reviewer.status_code === 'pen')
                )
            )
        )
    }
    onAddDocumentButton() {
        this.$state.go(IDS_ADD_DOCUMENT.app_accreditation_scheme_add_document_new_document, {
            standard: this.standardId,
            slo: this.siteLocationUuid,
            year: this.selectedYear,
            itemCode: this.schemeCode,
        });
    }

    onBackToParentButton() {
        this.hideContent = true;
        if(this.isUserEmployee) {
            this.$state.go(this.isPublic ? 'public.accreditation.tasks' : 'app.accreditation.tasks');
            return;
        }

        const parentId = this.selectParentStandard(this.standard.id, this.sectionsTree);

        this.$ngRedux.dispatch({
            type: TAB_ENABLE,
            id: this.isPublic ? IDS.public_accreditation_scheme : IDS.app_accreditation_scheme_manage,
            state: [ this.isPublic ? IDS.public_accreditation_scheme_progress : IDS.app_accreditation_scheme_manage_progress ]
        });
        this.$timeout( () => {
            this.$ngRedux.dispatch({
                type: TAB_SELECT,
                id: this.isPublic ? IDS.public_accreditation_scheme : IDS.app_accreditation_scheme_manage,
                state: this.isPublic ? IDS.public_accreditation_scheme_progress : IDS.app_accreditation_scheme_manage_progress
            });
            this.$timeout( () => {
                this.setQueryParamStandardId(parentId);
            }, 200);

        }, 200);
    }

    setQueryParamStandardId(standard) {
        this.$state.go('.', {...this.$stateParams, standard});
        // this.$state.transitionTo(
        //     IDS.app_accreditation_scheme_manage_progress,
        //     {...this.$stateParams, standard },
        //     { notify: false }
        // )
    }

    setDocumentApprovalStatus(documentList, index) {
        const ctrl = this;
        AccreditationDocumentsService.editAccDocumentApprovalStatus(documentList[index].documentId, documentList[index].status, documentList[index].statusComment)
            .then(function (success) {
                ctrl.standard.assessment.dirty = false;
                ctrl.Notifier.success(ctrl.$filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_SAVE_SUCCESS"));
                if (index < documentList.length-1) {
                    ctrl.setDocumentApprovalStatus(documentList, index + 1);
                }
            }, function (error) {
                ctrl.Notifier.error(ctrl.$filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_SAVE_FAILED"));
            });
    }

    $onDestroy() {
        this.unsubscribe();
        if(this.unsubscribeWatchTabSelected)
            this.unsubscribeWatchTabSelected();
        if (this.dialog)
            this.$mdDialog.hide(this.dialog);
    }
}

StandardDocumentListController.$inject = [
    '$ngRedux',
    '$state',
    '$stateParams',
    '$scope',
    '$rootScope',
    '$filter',
    '$timeout',
    '$mdDialog',
    'Notifier',
    'AuthService',
    'EmployeeService',
    'DocumentManager'
]

export const accStandardDocs = {
    bindings: {
    },
    controller: StandardDocumentListController,
    template: require('./standard-docs.html')
}

const index = (children, list = []) => {
    children && children.forEach(item => {
        if(item) {
            list[parseInt(item.id)] = item;
            index(item.children, list);
        }
    })
    return list;
};