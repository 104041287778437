import InputController from '../input.controller';
import { INPUT_RADIO } from '../input.constants';
import get from 'lodash/get';

/**
 * Creates a new InputRadioGroup.
 * @class
 * @extends module:root/common/form.InputController
 * @memberOf module:root/common/form
 * @see {@link https://docs.angularjs.org/guide/component|AngularJS Component}
 */
class InputRadioGroup extends InputController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     */
    constructor($ngRedux) {
        super($ngRedux);
    }

    /**
     * @method $onInit
     * @memberof module:root/common/form.InputRadioGroup
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.init();

        if (this.layout === 'undefined')
            this.layout = 'row';

        if (this.stateData === 'undefined')
            throw new Error('Missing select state-data attribute');

        if (typeof this.stateDataIdentifier === 'undefined')
            this.stateDataIdentifier = this.identifier;

        if (this.disabled === 'undefined')
            this.disabled = false;

        this.unsubscribeData = this.$ngRedux.connect(this.mapData.bind(this), {})(this);
    }

    /**
     * @method onInputChange
     * @memberof module:root/common/form.InputRadioGroup
     */
    onInputChange() {
        this.$ngRedux.dispatch({
            type: INPUT_RADIO,
            id: this.identifier,
            path: this.statePath,
            index: this.stateIndex,
            prop: this.stateProp,
            input: this.input,
            data: {[this.statePath]: this.input}
        });

        if (this.onChange) {
            this.onChange({
                $event: {input: this.input}
            });
        }
    }

    /**
     * @method $onDestroy
     * @memberof module:root/common/form.InputRadioGroup
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.unsubscribeData();
        this.destroy();
    }

    /**
     * @method mapData
     * @param state {object} Redux Store
     * @return {object}
     * @memberof module:root/common/form.InputRadioGroup
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapData(state) {
        return {
            /** @member {array} module:root/common/form.InputRadioGroup#options */
            options: get(state[this.module][this.stateDataIdentifier], this.stateData)
        }
    }

    /**
     * @method getClass
     * @return {object}
     * @memberof module:root/common/form.InputRadioGroup
     * @see {@link https://docs.angularjs.org/api/ng/directive/ngClass|AngularJS ngClass}
     */
    getClass() {
        return {
            'md-input-invalid': this.form[this.name].$invalid && (this.form[this.name].$touched || this.form.$submitted),
            'column': this.layout === 'column'
        }
    }
}

InputRadioGroup.$inject = ['$ngRedux'];

export const inputRadioGroup = {
    bindings: {
        layout: '@',
        identifier: '@',
        statePath: '@',
        stateIndex: '<',
        stateProp: '@',
        stateData: '@',
        stateDataIdentifier: '@',
        label: '@',
        data: '<',
        required: '<',
        disabled: '<',
        onChange: '&'
    },
    require : {
        /**
         * @member {*} module:root/common/form.InputRadioGroup#form
         * @see {@link https://docs.angularjs.org/api/ng/type/form.FormController|FormController}
         */
        form : '^form'
    },
    controller: InputRadioGroup,
    template: `
        <md-input-container class="md-input-has-value" ng-class="$ctrl.getClass()">
            <label ng-messages="$ctrl.form[$ctrl.name].$error">
                <span ng-message="required">Field is required</span>
            </label>
            <md-radio-group name="{{$ctrl.name}}" ng-model="$ctrl.input" ng-change="$ctrl.onInputChange()" ng-required="$ctrl.required">
                <md-radio-button
                    ng-repeat="item in $ctrl.options"
                    ng-value="item.value"
                    ng-disabled="$ctrl.disabled">{{item.label}}
                </md-radio-button>
            </md-radio-group>
        </md-input-container>
    `
};
