import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsDepartmentRscDetailsController($ngRedux, $uiRouterGlobals, AdministrationActions, Notifier) {
    var ctrl = this;
    var unsubscribe = angular.noop;
    var onRsc = angular.noop;

    ctrl.$onInit = function() {
        ctrl.identifier = 'administrationAccountsDepartmentRsc';
        ctrl.checkboxFlex = 25;

        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);
        ctrl.rscDetails = !_.isNull(ctrl.rsc.uuid);

        if (_.isUndefined(ctrl.stateParams.rsc))
            ctrl.getRscCandidates(ctrl.identifier, ctrl.stateParams.dep);

        var watchRsc = watch($ngRedux.getState, 'administration.administrationAccountsDepartmentRsc.data.uuid');
        onRsc = $ngRedux.subscribe(watchRsc(function(uuid) {
            ctrl.rscDetails = !_.isNull(uuid);
        }));
    };

    ctrl.onRscDetails = function() {
        ctrl.departmentRscForm.$setPristine();
        ctrl.departmentRscForm.$setUntouched();
        ctrl.checkboxFlex = ctrl.rscDetails ? 50 : 25;
    };

    ctrl.manageRsc = function() {
        if (ctrl.departmentRscForm.$valid) {
            if (ctrl.rsc.uuid) {
                ctrl.updateRscDetails('administrationAccountsDepartmentRsc', ctrl.rsc, 'administrationAccountsDepartmentRscGrid');
            }
            else {
                if (ctrl.rscDetails)
                    ctrl.createRscDetails(ctrl.rsc, $uiRouterGlobals.params.dep, false, 'administrationAccountsDepartmentRsc', 'administrationAccountsDepartmentRscGrid');
                else
                    ctrl.assignRsc(ctrl.rscCandidate, $uiRouterGlobals.params.dep, 'administrationAccountsDepartmentRsc', 'administrationAccountsDepartmentRscGrid');
            }
        }
        else Notifier.error('Please fill in the form fields');
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onRsc();
    };

    function mapStateToThis(state) {
        return {
            rsc: state.administration.administrationAccountsDepartmentRsc.data,
            rscCandidate: state.administration.administrationAccountsDepartmentRsc.candidate,
            stateParams: state.administration['app.administration.accounts.departments'].stateParams,
            loading: _.find(state.administration['app.administration.accounts.departments'].tabs, ['state', 'app.administration.accounts.departments.rscDetails']).loading,
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                state.administration.administrationAccountsDepartment.data.typeName,
                state.administration.administrationAccountsDepartmentRsc.title
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
