/* @ngInject */
export const EmployeeFactory = (UserCredentials, Country, Location, Occupation, Account) => {
    function Employee() {

        UserCredentials.call(this);

        this.id = null;
        this.uuid = null;
        this.accountId = null;
        this.location = new Location();
        this.locations = [];
        this.gender = null;
        this.firstName = null;
        this.lastName = null;
        this.birthday = null;
        this.phone = null;
        this.email = null;
        this.occupation = new Occupation();
        this.department = new Location();
        this.country = new Country();
        this.siteLocation = new Account();
        this.status = null;
        this.statusName = null;
        this.licences = [];
        this.employmentLocations = [];
        this.qualifications = [];
        this.trainings = [];
        this.mutable = null;
        this.useLicenceRequired = false;
        this.principalInvestigator = false;
        this.radiationWorkStatus = null;
        this.uniqueIdentifier = null;
        this.disableCheckboxUseLicenceRequired = false;
        this.asarLicenceRequired = false;
        this.showAsarLicenceRequired = false;

        this.addLicence = function(licence) {
            this.licences.push(_.cloneDeep(licence));
        };

        this.removeLicence = function(licence) {
            var idx = _.findIndex(this.licences, ['id', licence.id]);
            if (idx > -1) this.licences.splice(idx, 1);
        };

        this.addEmploymentLocation = function(location) {
            if (_.isUndefined(_.find(this.employmentLocations, ['uuid', location.uuid])))
                this.employmentLocations.push(_.cloneDeep(location));
        };

        this.removeEmploymentLocation = function(location) {
            var idx = _.findIndex(this.employmentLocations, ['uuid', location.uuid]);
            if (idx > -1) this.employmentLocations.splice(idx, 1);
        };

        this.addQualification = function(qualification) {
            if (_.isUndefined(_.find(this.qualifications, ['id', qualification.id])))
                this.qualifications.push(_.cloneDeep(qualification));
        };

        this.removeQualification = function(qualification) {
            var idx = _.findIndex(this.qualifications, ['id', qualification.id]);
            if (idx > -1) this.qualifications.splice(idx, 1);
        };

        this.getTrainingCategories = function() {
            return this.trainings.reduce(function(result, item) {
                result = _.concat(result, item.categories, 'id');
                return _.uniqBy(result, 'id');
            }, []);
        };

        this.addTraining = function(training) {
            if (_.isUndefined(_.find(this.trainings, ['id', training.id])))
                this.trainings.push(_.cloneDeep(training));
        };

        this.removeTraining = function(training) {
            var idx = _.findIndex(this.trainings, ['id', training.id]);
            if (idx > -1) this.trainings.splice(idx, 1);
        };

        this.reset = function() {
            UserCredentials.reset();
            // _.forOwn(this, function(property) {
            //     property = null;
            // });
        };
    }

    Employee.prototype = Object.create(UserCredentials.prototype);

    return Employee;
};
