export const loginState = {
    name: 'login',
    abstract: true,
    url: '/login',
    template: '<auth flex layout="column" layout-fill layout-align="center center"></auth>'
};

export const loginAuthState = {
    name: 'login.auth',
    url: '',
    component: 'authLogin',
    params: {
        headline: 'COMMON.LOGIN.MESSAGE',
        message: null,
        reset: false
    }
};

export const loginOtpState = {
    name: 'login.otp',
    url: '/otp?token',
    component: 'authOTP',
    params: {
        headline: 'COMMON.LOGIN.OTP_HEADLINE',
        message: null,
        status: 'verify',
        class: null,
        token: null,
    }
};

export const loginOtpAlertState = {
    name: 'login.otpAlert',
    url: '/otp-alert?token',
    component: 'authOTP',
    params: {
        headline: 'COMMON.LOGIN.OTP_ALERT',
        message: null,
        status: 'alert',
        class: null,
        token: null,
    }
};

export const recoveryState = {
    name: 'recovery',
    abstract: true,
    url: '/password-recovery',
    template: '<auth flex layout="column" layout-fill layout-align="center center"></auth>'
};

export const passwordResetState = {
    name: 'recovery.passwordReset',
    url: '',
    component: 'authPasswordReset',
    params: {
        headline: 'COMMON.LOGIN.PASSWORD_RECOVERY',
        message: null
    }
};

export const passwordSaveState = {
    name: 'recovery.passwordSave',
    url: '?token',
    component: 'authPasswordSave',
    resolve: {
        tokenStatus: ['$transition$', 'RecoveryService', ($transition$, RecoveryService) => RecoveryService.validatePasswordToken($transition$.params().token)]
    },
    params: {
        headline: 'COMMON.LOGIN.PASSWORD_RECOVERY'
    }
};
