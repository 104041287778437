import { api, build } from '../../bootstrap.json';
import { version } from '../../../package.json';

/** @constant {string} */
export const MyRSO = 'myrso';

/** @constant {string} */
export const MyQMO = 'myqmo';

/** @constant {string} */
export const VERSION = `${version} ${build}`.trim();

/** @constant {string} */
export const API_BASE = api;

/** @constant {string} */
export const LOCALE = '<LOCALE>';

/** @constant {object} */
export const EVENT = {
    UNAUTHORIZED: 'unauthorized'
};

/**
 * @constant {object}
 * @description Used as type of notification
 * @see {@link Notifier} for further information.
 */
export const NOTIFICATION = {
    INFO:       'none',
    SUCCESS:    'success',
    WARN:       'warning',
    ERROR:      'failure'
};

/** @constant {object} */
export const DATE_FORMAT = {
    INPUT: 'DD-MM-YYYY',
    INPUT_TIME: 'DD-MM-YYYY HH:mm:ss',
    OUTPUT: 'YYYY-MM-DD',
    OUTPUT_TIME: 'YYYY-MM-DD HH:mm:ss'
};

/** @constant {array} */
export const EXPORT_FORMAT = [
    {format: 'pdf',     name: 'PDF'},
    {format: 'csv',     name: 'CSV'},
    {format: 'xls',     name: 'Excel 5'},
    {format: 'xlsx',    name: 'Excel 2007'}
];

export const DOCUMENT_TYPE = {
    EQUIPMENT_DOCUMENT: 1,
    LICENSE_DOCUMENT: 2,
    RSPP_DOCUMENT: 3,
    ACCREDITATION_DOCUMENT: 3,
};

export const SUBJECT_TYPE = {
    HEAD_OFFICE:        'hof',
    SITE_LOCATION:      'slo',
    DEPARTMENT:         'dep',
    ROOM:               'rom',
    EMPLOYEE:           'emp',
    VENDOR:             'ven',
    SELLER:             'sel',
    SERVICE_PROVIDER:   'spr',
    VENDOR_AGENT:       'vag',
    USER:               'usr',
    HOF_RSC:            'hrs',
    SITE_RSC:           'sro',
    SITE_ARSC:          'aso',
    DEPT_RSC:           'drs'

};

/** @constant {object} */
export const VALIDATION = {
    aus: {
        email: '([a-zA-Z0-9_\'\\.-]+)@([\\da-zA-Z\\.-]+)\\.([a-zA-Z\\.]{2,15})',
        phone: '(\\({0,1}((0|\\+61)(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{3})|(^(((\\+?64\\s*[-\\.]?[3-9]|\\(?0[3-9]\\)?)\\s*[-\\.]?\\d{3}\\s*[-\\.]?\\d{4})|((\\+?64\\s*[-\\.\\(]?2\\d{1}[-\\.\\)]?|\\(?02\\d{1}\\)?)\\s*[-\\.]?\\d{3}\\s*[-\\.]?\\d{3,5})|((\\+?64\\s*[-\\.]?[-\\.\\(]?800[-\\.\\)]?|[-\\.\\(]?0800[-\\.\\)]?)\\s*[-\\.]?\\d{3}\\s*[-\\.]?(\\d{3}|\\d{6})))$)'
    },
    usa: {
        email: '([a-zA-Z0-9_\'\\.-]+)@([\\da-zA-Z\\.-]+)\\.([a-zA-Z\\.]{2,15})',
        phone: '(\\({0,1}((001|191|1|\\+1)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{3}(\\ |-){0,1}[0-9]{3}(\\ |-){0,1}[0-9]{4})|(\\({0,1}[0-9]{3}\\){0,1}(\\ |-){0,1}[0-9]{3}(\\ |-){0,1}[0-9]{4})|([0-9]{3}(\\ |-){0,1}[0-9]{4})|([0-9]{3}(\\ |-)[0-9]{3}(\\ |-)[0-9]{4})(\\ |-)[0-9]{5}'
    },
    uk: {
        email: '([a-zA-Z0-9_\'\\.-]+)@([\\da-zA-Z\\.-]+)\\.([a-zA-Z\\.]{2,15})',
        phone: '(((\\+44\\s?\\d{4}|\\(?0\\d{4}\\)?)\\s?\\d{3}\\s?\\d{3})|((\\+44\\s?\\d{3}|\\(?0\\d{3}\\)?)\\s?\\d{3}\\s?\\d{4})|((\\+44\\s?\\d{2}|\\(?0\\d{2}\\)?)\\s?\\d{4}\\s?\\d{4}))(\\s?\\#(\\d{4}|\\d{3}))?$'
    }
};

/** @constant {object} */
export const VALIDATION_MESSAGES = {
    aus: {
        phone: ['Valid Australian number formats', 'ex1. 0411 234 567', 'ex2. (02) 3892 1111', 'ex3. 38921111', 'ex4. 0411234567']
    },
    usa: {
        phone: ['Valid USA number formats', 'ex1. 754-3010', 'ex2. (541) 754-3010', 'ex3. +1-541-754-3010', 'ex4. 1-541-754-3010', 'ex5. 001-541-754-3010']
    },
    uk: {
        phone: ['Valid UK number formats', 'ex1. +442071234567']
    }
};

/** @constant {array} */
export const PERSONAL_TITLES = [
    {id: 1, title: 'MR'},
    {id: 2, title: 'MRS'},
    {id: 3, title: 'MS'},
    {id: 4, title: 'MISS'}
];

/** @constant {array} */
export const DOCUMENT_RESULTS = [
    {id: 1, title: 'Passed'},
    {id: 2, title: 'Failed'}
];

/** @constant {number} */
export const BANDWIDTH_LIMIT = 2000;

/** @constant {object} */
export const EQUIPMENT_STATUS = {
    act: 'Active',
    sel: 'Sold',
    dis: 'Disposed',
    sto: 'Stored'
};
