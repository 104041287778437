/* @ngInject */
export function ServiceContractsAddController($controller, $scope, $rootScope, $q, $log, $timeout, ServiceContract, ServiceContractService, ServiceContractManager, EquipmentManager, Grid, EquipmentFilter, EquipmentSparePart, AccountService, Notifier, VendorService, VendorGridFilter) {

    angular.extend(this, $controller('ServiceContractsBaseController', {
        $scope: $scope,
        $rootScope: $rootScope,
        $log: $log,
        ServiceContractService: ServiceContractService,
        ServiceContractManager: ServiceContractManager,
        ServiceContract: ServiceContract,
        EquipmentManager: EquipmentManager,
        Grid: Grid,
        EquipmentFilter: EquipmentFilter,
        EquipmentSparePart: EquipmentSparePart,
        AccountService: AccountService,
        Notifier: Notifier,
        VendorService: VendorService,
        VendorGridFilter: VendorGridFilter
    }));

    $scope.MODULE_STATE = $scope.MODULE_STATE_CREATE;
    $scope.CONTRACT_STATE = $scope.MODULE_STATE_CREATE;

    $scope.tabs = _.cloneDeep(ServiceContractManager.tabs);
    $scope.tabs[ServiceContractManager.TAB_CONTRACT_DETAILS].disabled = false;
    $scope.tabs.shift();

    $scope.setExpiryDate = function() {
        if ($scope.contract.startDate) {
            var startDate = new Date($scope.contract.startDate);
            var endDate = startDate.setFullYear(startDate.getFullYear() + parseInt($scope.contract.numberOfYears));
            $scope.contract.expiryDate = new Date(endDate);
        }
    };

    _.extend(_.find($scope.tabs, ['id', ServiceContractManager.TAB_CONTRACT_DETAILS]), {
        exec: function() {
            var deferred = $q.defer();
            switch ($scope.CONTRACT_STATE) {
                case $scope.MODULE_STATE_CREATE:
                    ServiceContractService.createContract($scope.contract).then(({ id }) => {
                        $scope.contract.id = id;
                        $scope.CONTRACT_STATE = $scope.MODULE_STATE_UPDATE;
                        Notifier.success('Contract details successfully added');
                        deferred.resolve();
                    }, function(error) {
                        deferred.reject(error);
                    });
                    break;
                case $scope.MODULE_STATE_UPDATE:
                    ServiceContractService.updateContract($scope.contract).then(function(status) {
                        if (status)
                            Notifier.error('System Error! Please try again latter');
                        else
                            Notifier.success('Contract details successfully updated');
                        deferred.resolve();
                    });
                    break;
            }
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ServiceContractManager.TAB_ACCOUNTS]), {
        init: function() {
            if ($scope.contract.accounts.length)
                this.formValidation = false;
        },
        exec: function() {
            var deferred = $q.defer();
            this.formValidation = true;
            $rootScope.$broadcast('SELECT_CASCADE_RESET');
            if ($scope.equipmentTypes.length) {
                $timeout(function() {
                    deferred.resolve();
                }, 200);
            }
            else {
                ServiceContractService.getEquipmentTypesWithCategories($scope.contract.type.id, $scope.contract.id).then(function(data) {
                    $scope.equipmentTypes = data;
                    deferred.resolve();
                });
            }
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ServiceContractManager.TAB_EQUIPMENT_COVERED]), {
        init: function() {
            if ($scope.contract.equipmentCategories.length) {
                this.form.$setValidity('required', true);
            }
            else {
                this.form.$setValidity('required', false);
                this.form.$setDirty();
            }
        },
        exec: function() {
            var deferred = $q.defer();
            ServiceContractService.getEquipment($scope.contract, $scope.gridEquipment.filter).then(function(data) {
                $scope.gridEquipment.totalItems = data.total;
                $scope.gridEquipment.items =  data.items;
                deferred.resolve();
            }, function(error) {
                Notifier.error('You have to add at least one account from Accounts tab!');
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ServiceContractManager.TAB_EQUIPMENT]), {
        init: function() {
            if ($scope.contract.equipment.length) {
                this.form.$setValidity('required', true);
            }
            else {
                this.form.$setValidity('required', false);
                this.form.$setDirty();
            }
        },
        exec: function() {
            var deferred = $q.defer();
            ServiceContractService.getEquipmentSpareParts().then(function(data) {
                $scope.equipmentSpareParts = data;
                deferred.resolve();
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ServiceContractManager.TAB_SPARE_PARTS]), {
        exec: function() {
            var deferred = $q.defer();
            $timeout(function() {
                $scope.contract.initMonths();
                deferred.resolve();
            }, 200);
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ServiceContractManager.TAB_ESTIMATED_TIME_FRAMES]), {
        exec: function() {
            var deferred = $q.defer();
            $timeout(function() {
                $scope.getEquipmentForVisits();
                Notifier.info('Use filter to schedule visits for different Accounts');
                deferred.resolve();
            }, 200);
            return deferred.promise;
        }
    });
}
