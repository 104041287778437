/* @ngInject */
export function autocompleteEquipmentModel(EquipmentManager, Helpers) {
    return {
        restrict: 'E',
        require: '^form',
        scope: {
            equipment: '=',
            data: '=',
            validate: '=?'
        },
        template: require('./autocomplete-equipment-model.html'),
        link: function(scope, element, attrs, formCtrl) {

            scope.selectForm = formCtrl;
            scope.identifier = Helpers.generateIdentifier();

            if (_.isUndefined(scope.validate)) scope.validate = false;

            var autoChild = $('md-autocomplete').children().first();
            var el = angular.element(autoChild);

            scope.$watch('equipment.manufacturer.id', function() {
                scope.item = null;
                scope.searchText = null;
                scope.disabled = _.isNull(scope.equipment.manufacturer.id);
            });

            scope.createNewModel = function() {
                el.scope().$mdAutocompleteCtrl.listLeave();
                EquipmentManager.createNewModel(scope.equipment);
                angular.element(document.querySelector('#autocomplete')).blur();
            };

            scope.modelSearch = function(query) {
                return query ? scope.data.filter(createFilterFor(query)) : scope.data;
            };

            function createFilterFor(query) {
                var lowercaseQuery = query.toLowerCase();
                return function filterFn(item) {
                    if (item.name != null) {
                        return ((item.name.toLowerCase().indexOf(lowercaseQuery) !== -1));
                    }
                    return false;
                };
            }

            scope.$on('RESET_EQUIPMENT_MODEL', function(event, typeID) {
                if (scope.equipment.type.id == typeID && scope.equipment.model) {
                    scope.equipment.model = null;
                    scope.searchText = '';
                }
            });

            scope.$on('show-errors-check-validity', function() {
                if (scope.validate && _.isNull(scope.equipment.model)) {
                    formCtrl[scope.identifier].$setDirty();
                }
            });
        }
    };
}
