import API from '../../../common/http/api.fetch';
import * as TYPE from './cre-vendors.actions.types';
import { product } from '../../../bootstrap.json';
import { resolveCredentialingProgress } from '../credentialing.utils';
import find from 'lodash/find';
import get from 'lodash/get';

/**
 * @function sendInvite
 * @param {Object} data
 */
export const sendInvite = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/invite',
            method: 'POST',
            data,
        }).then(() => {
            dispatch({
                type: TYPE.CRE_INVITE_INIT,
                id: 'creInvite',
            });
        });
    }
}

/**
 * @function getVendors
 * @param {string} keyword
 */
export const getVendors = (keyword) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendors',
            method: 'GET',
            params: { keyword, offset: 0, limit: 50 },
        }).then(({ data }) => {
            dispatch({
                type: TYPE.CRE_VENDORS,
                id: 'creInvite',
                data: data.rows.map(({ vendor_uuid, vendor_name }) => ({
                    vendor_uuid,
                    vendor_name,
                })),
            });
        });
    }
}

/**
 * @function getContactPositions
 * @param {Object} params
 * @param {string} [params.token]
 * @param {string} params.contact_code
 */
export const getContactPositions = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/contact-positions',
            method: 'GET',
            params,
        }).then(({ data }) => {
            dispatch({
                type: TYPE.CRE_CONTACT_POSITIONS,
                id: 'creInvite',
                data: data.map((item) => ({
                    value: item.position_id,
                    label: item.position_name,
                })),
            });
        });
    }
}

/**
 * @function getCountries
 * @param {Object} params
 * @param {string} [params.token]
 */
export const getCountries = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/countries',
            method: 'GET',
            params,
        }).then(({ data }) => {
            dispatch({
                type: TYPE.CRE_COUNTRIES,
                id: 'creVendor',
                items: data.map((item) => ({
                    value: item.cou_id,
                    label: item.name,
                })),
            });
        });
    }
}

/**
 * @function getCurrencies
 * @param {Object} params
 * @param {string} [params.token]
 */
export const getCurrencies = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/currencies',
            method: 'GET',
            params,
        }).then(({ data }) => {
            dispatch({
                type: TYPE.CRE_CURRENCIES,
                id: 'creVendor',
                items: data.map((item) => ({
                    value: item.cur_id,
                    label: item.name,
                })),
            });
        });
    }
}

/**
 * @function getApplicantRequests
 * @param {Object} filter
 */
export const getApplicantRequests = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/requests',
            method: 'GET',
            params: {
                purpose: filter.purpose,
                keyword: filter.keyword,
                type: filter.type,
                status: filter.status,
                offset: filter.offset,
                limit: filter.limit,
                exportFormat: filter.exportFormat,
                exportColumns: filter.exportColumns
            }
        }).then(({ data }) => {
            if (filter.exportFormat) {
                return data;
            }
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.items.map((item) => ({
                    ...item,
                    progress: resolveCredentialingProgress(item.request_status_code)
                })),
                itemsCount: parseInt(data.items_count),
            });
        });
    }
}

/**
 * @function getRequestStatuses
 */
export const getRequestStatuses = () => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/request/statuses',
            method: 'GET',
            params: {},
        }).then(({ data }) => {
            dispatch({
                type: TYPE.CRE_REQUEST_STATUSES,
                id: 'creVendor',
                items: data.map((item) => ({
                    value: item.code,
                    label: item.name,
                })),
            });
        });
    }
}

/**
 * @function getRequestDetails
 * @param {Object} params
 * @param {string} [params.token]
 * @param {number} params.application_id
 */
export const getRequestDetails = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/details',
            method: 'GET',
            params,
        }).then(({ data }) => {
            const info = data[0][0];
            const contacts = data[1];
            const contactPerson = find(contacts, ['is_additional_contact', 0]);
            const finance = data[2][0];
            const insurance = data[3][0];
            const insurance_docs = data[4];
            dispatch({
                type: TYPE.CRE_REQUEST_DETAILS,
                id: 'creVendor',
                creInvite: {
                    type: info.app_type_code,
                    supplyHealthcare: info.healthcare,
                    supplyNonHealthcare: info.non_healthcare,
                    gst: info.gst_reg_status,
                    vendor_uuid: info.vendor_uuid,
                    vendor_name: info.company_name,
                    abn_num: info.abn_number,
                    acn_num: info.account_number,
                    address_billing: info.billing_address,
                    address_delivery: info.delivery_address,
                    position: get(contactPerson, 'sty_id', null),
                    title: get(contactPerson, 'title', null),
                    first_name: get(contactPerson, 'first_name', null),
                    last_name: get(contactPerson, 'last_name', null),
                    email: get(contactPerson, 'email', null),
                    contacts: contacts.filter((item) => item.is_additional_contact),
                    applicant_signature: info.applicant_signature,
                    applicant_signature_date: info.applicant_signature_date,
                    hof_name: info.hof_name || product,
                    hof_contact_name: info.hof_contact_name,
                    hof_contact_phone: info.hof_contact_phone,
                    hof_contact_email: info.hof_contact_email,
                    status: info.request_status_code,
                },
                creVendor: {
                    application_id: info.application_id,
                    company_local: finance.local_company,
                    bank_acc_name: finance.bank_acc_name,
                    bank_bsb_code: finance.bsb_code,
                    bank_acc_no: finance.account_number,
                    bank_pay_id: finance.pay_id,
                    company_international: finance.international_company,
                    beneficiary_acc_name: finance.ben_acc_name,
                    beneficiary_acc_num_iban: finance.acc_number_iban,
                    beneficiary_country: finance.ben_cou_id,
                    beneficiary_address: finance.ben_address,
                    beneficiary_swift_bic_code: finance.ben_swift_bic_code,
                    beneficiary_bank_address: finance.ben_bank_address,
                    beneficiary_bank_country: finance.ben_bank_cou_id,
                    currency: finance.cur_of_pay_id,
                    wcp_number: insurance.wcp_number,
                    wcp_expiry_date: insurance.wcp_expiry_date,
                    plp_number: insurance.plp_number,
                    plp_expiry_date: insurance.plp_expiry_date,
                    pip_number: insurance.pip_number,
                    pip_expiry_date: insurance.pip_expiry_date,
                    insurance_docs,
                },
            });
            return JSON.parse(data[6][0]['@params']);
        });
    }
}

/**
 * @function approveRequest
 * @param {Object} data
 * @param {string} data.purpose
 * @param {number} data.application_id
 * @param {string} data.reviewer_signature
 */
export const approveRequest = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/request/approve',
            method: 'POST',
            data,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsGrid'
            });
        });
    }
}

/**
 * @function rejectRequest
 * @param {Object} data
 * @param {string} data.purpose
 * @param {number} data.application_id
 * @param {string} data.reviewer_signature
 * @param {string} [data.comment]
 */
export const rejectRequest = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/request/reject',
            method: 'POST',
            data,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsGrid'
            });
        });
    }
}

/**
 * @function cancelRequest
 * @param {Object} params
 * @param {string} params.purpose
 * @param {number} params.application_id
 */
export const cancelRequest = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/request/cancel',
            method: 'GET',
            params,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsGrid'
            });
        });
    }
}

/**
 * @function getVendorAccSchemes
 * @param {Object} params
 * @param {string} [params.token]
 */
export const getVendorAccSchemes = ({ token }) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/accreditation-schemes',
            method: 'GET',
            params: {
                token,
                for_assessment_only: 0,
                offset: 0,
                limit: -1,
            },
        }).then(({ data }) => {
            dispatch({
                type: TYPE.CRE_VENDOR_ACC_SCHEMES,
                id: 'creVendor',
                items: data.items.map((item) => ({
                    value: item.id,
                    label: item.name,
                })),
            });
        });
    }
}

/**
 * @function getVendorAccStandards
 * @param {Object} filter
 * @param {string} [filter.token]
 * @param {string} filter.identifier
 * @param {number} filter.application_id
 */
export const getVendorAccStandards = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/accreditation-standards',
            method: 'GET',
            params: {
                token: filter.token,
                application_id: filter.application_id,
                offset: 0,
                limit: -1,
            },
        }).then(({ data }) => {
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.items.map((item) => ({
                    ...item,
                    uri: item.doc_url,
                })),
                itemsCount: undefined,
            });
        });
    }
}

/**
 * @function getVendorAccStandardDetails
 * @param {Object} params
 * @param {string} [params.token]
 * @param {number} params.caa_id
 */
export const getVendorAccStandardDetails = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/accreditation-standard-details',
            method: 'GET',
            params,
        }).then(({ data }) => {
            dispatch({
                type: TYPE.CRE_VENDOR_ACC_STANDARD_SELECT,
                id: 'creVendor',
                data: {
                    acc_id: data[0][0].caa_id,
                    acc_scheme: data[0][0].asc_id,
                    acc_std_next_audit_date: data[0][0].next_audit_date,
                    acc_docs: data[1],
                },
            });
        });
    }
}

/**
 * @function getVendorGoodsManufacturers
 * @param {Object} params
 * @param {string} [params.token]
 */
export const getVendorGoodsManufacturers = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/goods-manufacturers',
            method: 'GET',
            params,
        }).then(({ data }) => {
            dispatch({
                type: TYPE.CRE_VENDOR_GOODS_MANUFACTURERS,
                id: 'creVendor',
                items: data.map((item) => ({
                    value: item.cam_id,
                    label: item.name,
                })),
            });
        });
    }
}

/**
 * @function getVendorGoods
 * @param {Object} filter
 * @param {string} [filter.token]
 * @param {string} filter.identifier
 * @param {number} filter.application_id
 */
export const getVendorGoods = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/goods',
            method: 'GET',
            params: {
                token: filter.token,
                application_id: filter.application_id,
                offset: 0,
                limit: -1,
            },
        }).then(({ data }) => {
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.items,
                itemsCount: undefined,
            });
        });
    }
}

/**
 * @function getVendorGoodsDetails
 * @param {Object} params
 * @param {string} [params.token]
 * @param {number} params.cag_id
 */
export const getVendorGoodsDetails = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/goods-details',
            method: 'GET',
            params,
        }).then(({ data }) => {
            dispatch({
                type: TYPE.CRE_VENDOR_GOODS_SELECT,
                id: 'creVendor',
                data: {
                    cag_id: data[0][0].cag_id,
                    gty_healthcare: data[0][0].healthcare,
                    gty_non_healthcare: data[0][0].non_healthcare,
                    gty_description: data[0][0].description,
                    man_id: data[0][0].cam_id,
                    model_number: data[0][0].model_number,
                    cou_of_origin_id: data[0][0].cou_of_origin_id,
                    tda_artg_fda_ce: data[0][0].tda_artg_fda_ce,
                    pog_ex_tax: data[0][0].pog_ex_tax,
                    pog_in_tax: data[0][0].pog_in_tax,
                    price_is_valid_for: data[0][0].price_is_valid_for,
                    is_price_sub_to_roe: data[0][0].is_price_sub_to_roe,
                    roe_from: data[0][0].roe_from,
                    roe_to: data[0][0].roe_to,
                    roe_cur_id: data[0][0].roe_cur_id,
                    perc_of_price_sub_to_roe: data[0][0].perc_of_price_sub_to_roe,
                    goods_docs: data[1],
                },
            });
        });
    }
}

/**
 * @function getVendorWarrantyLicenceTypes
 * @param {Object} params
 * @param {string} [params.token]
 * @param {string} params.lty_code
 */
export const getVendorWarrantyLicenceTypes = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/warranty-licence-types',
            method: 'GET',
            params,
        }).then(({ data }) => {
            dispatch({
                type: TYPE.CRE_VENDOR_WARRANTY_LICENCE_TYPES,
                id: 'creVendor',
                code: params.lty_code,
                items: data.map((item) => ({
                    value: item.wlt_id,
                    label: item.name,
                })),
            });
        });
    }
}

/**
 * @function getVendorWarranties
 * @param {Object} filter
 * @param {string} [filter.token]
 * @param {string} filter.identifier
 * @param {number} filter.application_id
 */
export const getVendorWarranties = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/warranties',
            method: 'GET',
            params: {
                token: filter.token,
                application_id: filter.application_id,
                offset: 0,
                limit: -1,
            },
        }).then(({ data }) => {
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.items,
                itemsCount: undefined,
            });
        });
    }
}

/**
 * @function getVendorWarrantyDetails
 * @param {Object} params
 * @param {string} [params.token]
 * @param {number} params.caw_id
 */
export const getVendorWarrantyDetails = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/warranty-details',
            method: 'GET',
            params,
        }).then(({ data }) => {
            const warranty = data[0][0];
            const warranty_docs = data[1];
            dispatch({
                type: TYPE.CRE_VENDOR_WARRANTY_SELECT,
                id: 'creVendor',
                data: {
                    ...warranty,
                    warranty_docs,
                },
            });
        });
    }
}

/**
 * @function getVendorServiceLicenceTypes
 * @param {Object} params
 * @param {string} [params.token]
 * @param {string} params.lty_code
 */
export const getVendorServiceLicenceTypes = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/service-licence-types',
            method: 'GET',
            params,
        }).then(({ data }) => {
            dispatch({
                type: TYPE.CRE_VENDOR_SERVICE_LICENCE_TYPES,
                id: 'creVendor',
                code: params.lty_code,
                items: data.map((item) => ({
                    value: item.slt_id,
                    label: item.name,
                })),
            });
        });
    }
}

/**
 * @function getVendorServices
 * @param {Object} filter
 * @param {string} [filter.token]
 * @param {string} filter.identifier
 * @param {number} filter.application_id
 */
export const getVendorServices = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/services',
            method: 'GET',
            params: {
                token: filter.token,
                application_id: filter.application_id,
                offset: 0,
                limit: -1,
            },
        }).then(({ data }) => {
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.items,
                itemsCount: undefined,
            });
        });
    }
}

/**
 * @function getVendorServiceDetails
 * @param {Object} params
 * @param {string} [params.token]
 * @param {number} params.cas_id
 */
export const getVendorServiceDetails = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/service-details',
            method: 'GET',
            params,
        }).then(({ data }) => {
            const service = data[0][0];
            const service_docs = data[1];
            dispatch({
                type: TYPE.CRE_VENDOR_SERVICE_SELECT,
                id: 'creVendor',
                data: {
                    ...service,
                    service_docs,
                },
            });
        });
    }
}

/**
 * @function saveVendorInfo
 * @param {Object} data
 */
export const saveVendorInfo = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/save-info',
            method: 'POST',
            data,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsGrid'
            });
        });
    }
}

/**
 * @function saveVendorAccounts
 * @param {Object} data
 */
export const saveVendorAccounts = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/save-accounts',
            method: 'POST',
            data,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsGrid'
            });
        });
    }
}

/**
 * @function saveVendorFinance
 * @param {Object} data
 */
export const saveVendorFinance = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/save-finance',
            method: 'POST',
            data,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsGrid'
            });
        });
    }
}

/**
 * @function saveVendorInsurance
 * @param {Object} data
 */
export const saveVendorInsurance = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/save-insurance',
            method: 'POST',
            data,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsGrid'
            });
        });
    }
}

/**
 * @function saveVendorAccScheme
 * @param {Object} data
 */
export const saveVendorAccScheme = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/save-acc-scheme',
            method: 'POST',
            data,
        }).then(({ data }) => {
            dispatch({
                type: TYPE.CRE_VENDOR_ACC_SCHEME_SELECT,
                id: 'creVendor',
                schemeId: parseInt(data.params.id),
            });
        });
    }
}

/**
 * @function saveVendorAccStandards
 * @param {Object} data
 */
export const saveVendorAccStandards = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/save-acc-standards',
            method: 'POST',
            data,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsAccStdGrid'
            });
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsGrid'
            });
        });
    }
}

/**
 * @function saveVendorGoodsManufacturer
 * @param {Object} data
 */
export const saveVendorGoodsManufacturer = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/save-goods-manufacturer',
            method: 'POST',
            data,
        }).then(({ data }) => {
            dispatch({
                type: TYPE.CRE_VENDOR_GOODS_MANUFACTURER_SELECT,
                id: 'creVendor',
                manId: data.params.man_id,
            });
        });
    }
}

/**
 * @function saveVendorGoods
 * @param {Object} data
 */
export const saveVendorGoods = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/save-goods',
            method: 'POST',
            data,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsGoodsGrid'
            });
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsGrid'
            });
        });
    }
}

/**
 * @function saveVendorWarranty
 * @param {Object} data
 */
export const saveVendorWarranty = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/save-warranty',
            method: 'POST',
            data,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsWarrantiesGrid'
            });
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsGrid'
            });
        });
    }
}

/**
 * @function saveVendorService
 * @param {Object} data
 */
export const saveVendorService = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/save-services',
            method: 'POST',
            data,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsServicesGrid'
            });
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsGrid'
            });
        });
    }
}

/**
 * @function deleteVendorAccStandard
 * @param {Object} params
 */
export const deleteVendorAccStandard = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/delete-acc-standard',
            method: 'GET',
            params,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsAccStdGrid'
            });
        });
    }
}

/**
 * @function deleteVendorGoods
 * @param {Object} params
 */
export const deleteVendorGoods = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/delete-goods',
            method: 'GET',
            params,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsGoodsGrid'
            });
        });
    }
}

/**
 * @function deleteVendorWarranty
 * @param {Object} params
 */
export const deleteVendorWarranty = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/delete-warranty',
            method: 'GET',
            params,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsWarrantiesGrid'
            });
        });
    }
}

/**
 * @function deleteVendorService
 * @param {Object} params
 */
export const deleteVendorService = (params) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/delete-service',
            method: 'GET',
            params,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'creVendorsServicesGrid'
            });
        });
    }
}

/**
 * @function submitVendorApplication
 * @param {Object} data
 */
export const submitVendorApplication = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/credentialing/vendor/submit',
            method: 'POST',
            data,
        }).then(() => {
            // TODO
        });
    }
}
