export const SubjectFactory = () => {
    return function Subject() {
        return {
            in_parent_token: null,
            in_username: null,
            in_sty_id: null,
            in_org_user: 1,
            in_purpose: null,
            in_subject_uuid: null,
            in_parent_uuid: null,
            in_parent_username: null,
            in_creator_uuid: null,
            in_subject_type_id: null,
            in_subject_type_name: 'subject_type_name',
            in_occupation_id: null,
            in_country_id: null,
            in_username_par: 'username_par',
            in_email: null,
            in_phone: null,
            in_created: null,
            in_first_name: null,
            in_last_name: null,
            in_birthdate: null,
            in_account_id: 'account_id',
            in_subject_basic_type_code: 'subject_basic_type_code',
            in_subject_basic_type_name: null,
            in_offset: null,
            in_limit: null,
            in_lspn: null,
            in_permit: null,
            in_employees_mutable: null,

            get uuid() { return this.in_subject_uuid; },
            get name() { return this.in_username_par; }
        };
    };
};
