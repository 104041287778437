/* @ngInject */
export function VendorManageController($controller, $ngRedux, $scope, $rootScope, $q, $timeout, Notifier, VendorService, VendorManager, VendorHeadOffice, VendorSiteLocation, VendorManagerUser, Grid, GENDERS, COUNTRY_SELECT_EVENT) {

    angular.extend(this, $controller('VendorBaseController', {
        $scope: $scope,
        $rootScope: $rootScope,
        $q: $q,
        $timeout: $timeout,
        Notifier: Notifier,
        VendorService: VendorService,
        VendorManager: VendorManager,
        VendorHeadOffice: VendorHeadOffice,
        VendorSiteLocation: VendorSiteLocation,
        VendorManagerUser: VendorManagerUser,
        Grid: Grid,
        GENDERS: GENDERS,
        COUNTRY_SELECT_EVENT: COUNTRY_SELECT_EVENT
    }));

    $scope.MODULE_STATE = $scope.MODULE_STATE_UPDATE;
    $scope.VENDOR_STATE = $scope.MODULE_STATE_UPDATE;

    $scope.tabs = _.cloneDeep(VendorManager.tabs);

    $scope.onVendorSelected = function(event) {
        _.assign($scope.headOffice, event.item);
        $scope.executeTab();
    };

    _.extend(_.find($scope.tabs, ['id', VendorManager.TAB_VENDORS]), {
        exec: (function() {
            var deferred = $q.defer();
            VendorService.getVendorHeadOffice($scope.headOffice.uuid).then((function(headOffice) {
                $scope.headOffice = headOffice;
                $q.all([
                    VendorService.getAssignedVendorServiceTypes($scope.headOffice.uuid).then(function(vendorServices) {
                        selectVendorServices(vendorServices);
                    }),
                    VendorService.getVendorSiteLocations($scope.headOffice.uuid).then(function(siteLocations) {
                        $scope.headOffice.siteLocations = siteLocations;
                        $scope.gridSiteLocations.items = $scope.headOffice.siteLocations;
                    }),
                    VendorService.getVendorSiteLocationManagerCandidates($scope.headOffice.uuid).then(function(managerCandidates) {
                        $scope.siteLocationManagers = managerCandidates;
                    })
                ]).then((function() {
                    this.enableTabs();
                    deferred.resolve();
                }).bind(this), function(error) {
                    deferred.reject(error);
                });
            }).bind(this), function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }).bind(this)
    });

    _.extend(_.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_DETAILS]), {
        exec: function() {
            var deferred = $q.defer();
            VendorService.editVendorHeadOffice($scope.headOffice).then(function() {
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'administrationVendorsGrid'
                });
                Notifier.success('Vendor details successfully updated');
                deferred.resolve();
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SERVICES]), {
        exec: function() {
            var deferred = $q.defer();
            if ($scope.headOffice.services.length)
                Notifier.success('Vendor Services are successfully updated');
            else
                Notifier.error('Please select at least one Vendor Service');
            deferred.resolve();
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', VendorManager.TAB_ADD_SITE_LOCATION]), {
        exec: (function() {
            var deferred = $q.defer();
            if ($scope.manager.siteLocationManagerExisting.uuid) $scope.manager.siteLocationManager = $scope.manager.siteLocationManagerExisting;
            VendorService.addVendorSiteLocation($scope.headOffice.uuid, $scope.siteLocation, $scope.manager.siteLocationManager).then((function(data) {
                this.addSiteLocation(data);
                $scope.selectedTab.form.$setPristine();
                $scope.selectedTab.form.$setUntouched();
                deferred.resolve(VendorManager.TAB_VENDOR_SITE_LOCATIONS);
            }).bind(this), function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }).bind(this)
    });

    _.extend(_.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATIONS]), {
        exec: function() {
            var deferred = $q.defer();
            deferred.resolve();
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_DETAILS]), {
        exec: (function() {
            var deferred = $q.defer();
            this.updateSiteLocation().then((function() {
                deferred.resolve();
            }, function(error) {
                deferred.reject(error);
            }).bind(this));
            return deferred.promise;
        }).bind(this)
    });

    _.extend(_.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_MANAGER]), {
        exec: (function() {
            var deferred = $q.defer();
            switch ($scope.MANAGER_STATE) {
                case $scope.MODULE_STATE_CREATE:
                    if ($scope.manager.newManager) {
                        VendorService.addVendorSiteLocationManager($scope.siteLocation.uuid, $scope.manager.siteLocationManager).then((function(data) {
                            $scope.manager.siteLocationManager.uuid = data.vsm_uuid;
                            $scope.manager.siteLocationManager.username = data.vsm_username;
                            this.addManagerCandidate(_.cloneDeep($scope.manager.siteLocationManager, $scope.siteLocation.uuid));
                            $scope.siteLocation.addManager(_.cloneDeep($scope.manager.siteLocationManager));
                            $scope.manager.siteLocationManager = new VendorManagerUser();
                            $scope.manager.siteLocationManagerExisting = new VendorManagerUser();
                            deferred.resolve(VendorManager.TAB_VENDOR_SITE_LOCATION_DETAILS);
                        }).bind(this));
                    }
                    else {
                        if ($scope.manager.siteLocationManagerExisting.uuid) $scope.manager.siteLocationManager = $scope.manager.siteLocationManagerExisting;
                        $scope.manageSiteLocationManager($scope.manager.siteLocationManager).then(function() {
                            $scope.manager.siteLocationManager = new VendorManagerUser();
                            $scope.manager.siteLocationManagerExisting = new VendorManagerUser();
                            deferred.resolve(VendorManager.TAB_VENDOR_SITE_LOCATION_DETAILS);
                        });
                    }
                    break;
                case $scope.MODULE_STATE_UPDATE:
                    VendorService.editVendorSiteLocationManager($scope.manager.siteLocationManager).then(function() {
                        // TODO encapsulate this in separate function
                        var candidateIndex = _.indexOf($scope.siteLocationManagers, _.find($scope.siteLocationManagers, ['uuid', $scope.manager.siteLocationManager.uuid]));
                        $scope.siteLocationManagers.splice(candidateIndex, 1, $scope.manager.siteLocationManager);
                        Notifier.success('Vendor Site Location Manager successfully updated');
                        deferred.resolve();
                    });
                    break;
            }
            return deferred.promise;
        }).bind(this)
    });

    VendorService.getVendorServiceTypes().then(function(data) {
        $scope.vendorServices = data;
    });

    function selectVendorServices(vendorServices) {
        _.each($scope.vendorServices, function(item) {
            item.selected = 0;
        });
        _.each(vendorServices, function(item) {
            var service = _.find($scope.vendorServices, ['id', item.id]);
            if (!_.isUndefined(service)) {
                service.selected = 1;
                $scope.headOffice.services.push(service);
            }
        });
    }
}
