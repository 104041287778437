import { GridController } from '../../../../../../../../common/grid/grid.controller';
import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsLicenceSiteLocationsGridController($controller, $ngRedux, $state, NavigationService, Notifier, AdministrationActions, LicenceService) {
    var ctrl = this;
    var onSwitch = angular.noop;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'administration';
        ctrl.identifier = 'administrationAccountsLicenceSiteLocationsGrid';
        ctrl.filterComponent = false;
        ctrl.exportComponent = false;

        ctrl.columns = [
            {title: '#',                      mapper: null},
            {title: 'Assign/Resign',          mapper: 'checkbox', identifier: this.identifier},
            {title: 'Site Location',          mapper: 'sloName'}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), AdministrationActions)(ctrl);
        ctrl.dataAction = ctrl.getLicenceSiteLocations;

        var watchGrid = watch($ngRedux.getState, 'administration.' + ctrl.identifier + '.changedItem');
        onSwitch = $ngRedux.subscribe(watchGrid(function(item) {
            if (item.checked == 1) {
                LicenceService.assignSiteLocation(item.id);
                Notifier.success('Location is assigned');
            } else {
                LicenceService.resignSiteLocation(item.id);
                Notifier.success('Location is resigned');
            }
        }));

        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        onSwitch();
        ctrl.destroy();
    };
}
