import get from 'lodash/get';

class GridFilterSwitchController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.unsubscribeModule = this.$ngRedux.connect(state => ({ module: state.navigation.module }),  {})(this);
        if(this.synchronize === 'true')
            this.unsubscribeValue  = this.$ngRedux.connect(state => ({ value: get(state, this.getPath()) }),{})(this);
    }

    onSwitchChange() {
        const action = {
            id:     this.identifier,
            path:   this.statePath,
            field:  this.gridPath,
            value:  this.value,
        }
        if(this.onChange)
            this.onChange(action);

        if(this.actionType)
            this.$ngRedux.dispatch({ ...action, type: this.actionType })
    }

    getPath() {
        const pathSegments = [this.module, this.identifier];
        if(this.statePath)
            pathSegments.push(this.statePath);
        if(this.gridPath)
            pathSegments.push(this.gridPath);
        return pathSegments.join('.');
    }

    $onDestroy() {
        if(this.unsubscribeValue)
            this.unsubscribeValue();

        this.unsubscribeModule();
    }
}

GridFilterSwitchController.$inject = ['$ngRedux', 'Notifier'];

export const gridFilterSwitch = {
    bindings: {
        identifier: '@',
        statePath: '@',
        gridPath: '@',
        actionType: '@',
        label: '@',
        onChange: '<',
        synchronize: '@'
    },
    controller: GridFilterSwitchController,
    template: `
        <md-switch ng-model="$ctrl.value" aria-label="$ctrl.label" ng-change="$ctrl.onSwitchChange()">{{$ctrl.label}}</md-switch>
    `
};
