/* @ngInject */
export function kdValidateUsername($http, $q, UserManagementService) {
    return {
        require: '^form',
        scope: {'kdValidateUsername':'@','kdMatch':'@'},
        link: function (scope, elm, attrs, formCtrl) {

            // TODO not good solution
            if (scope.kdValidateUsername != 'false') {
                // find the text box element, which has the 'name' attribute
                var inputEl = elm[0].querySelector("[name]");
                // convert the native text box element to an angular element
                var inputNgEl = angular.element(inputEl);
                // get the name on the text box
                var inputName = inputNgEl.attr('name');

                var pristineValue = '';
                var invalidUsername = true;

                formCtrl[inputName].$asyncValidators.unique = function(modelValue, viewValue)
                {
                    if(formCtrl[inputName].$pristine)
                    {
                        pristineValue = modelValue;
                        return $q.resolve();
                    }

                    if(modelValue === pristineValue)
                        return $q.resolve();

                    //return $http.get(API_BASE+"/account/available/username/"+modelValue)
                    return UserManagementService.isUsernameAvailableCrossCheck(modelValue,inputName)
                        .then(function rejected() {
                            //username does not exist, therefore this validation passes
                            invalidUsername = false;
                            formCtrl[inputName].$setValidity('unique',true);
                            return true;
                        },function resolved() {
                            //username exists, this means validation fails
                            formCtrl[inputName].$setTouched();
                            formCtrl[inputName].$setValidity('unique',false);
                            return $q.reject('exists');
                        } );
                };

                scope.$on('show-errors-check-validity', function(e, tabName) {
                    if (invalidUsername) {
                        formCtrl[inputName].$validate();
                        if (formCtrl[inputName].$invalid) {
                            formCtrl[inputName].$setTouched();
                        }
                        elm.toggleClass('has-error', formCtrl[inputName].$invalid);
                    }
                });
            }
        }
    };
}
