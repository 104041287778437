import { TAB_SHOW } from '../../../common/tabs/tabs.constants';
import { formatDate } from '../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../common/utils/util.constants';
import * as EventsAssessmentActions from './events.assessment.actions';

/**
 * @const {object}
 * @memberOf module:Events
 */
export const eventsAssessmentHazard = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $scope, NavigationService, Notifier, $timeout, $mdDialog) {
        this.dialog = null;

        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(( state ) => ({
                eventItem: state.events.eventItem,
                eventID: state.events.eventItem.id,
                eventType: state.events.eventItem.event,
                eventAssessment: state.events.eventAssessment
            }), {
                ...EventsAssessmentActions
            })(this);

            const navConfig = NavigationService.getConfig($state.$current);
            this.colorName = navConfig.colorName;
            this.icon = navConfig.icon;

            this.identifier = $state.$current.parent.name;
            this.state = `${$state.$current.parent.name}.assessmentHazard`;

            this.getAssessment(this.eventID);
            this.getAssessmentLikelihoodTypes();
            this.getAssessmentSeverityPotentialTypes();

            this.filter = {
                code: this.type || this.eventItem.risk_code,
                event_id: this.eventItem.event_type,
                type_id: this.eventItem.event_subtype,
                category_id: this.eventItem.event_category,
                subcategory_id: this.eventItem.event_subcategory,
                exclude_iev_id: this.eventID
            };

            const evtType = this.eventType ? `${this.eventType} - ` : '';
            this.title = `${evtType}Assessment Review`;
            this.updateDateLimit = new Date();
            this.scoreStyle = {};
        };

        this.onGetResponsiblePersons = async (event) => {
            return await this.getResponsiblePersons(event);
        };

        this.onGetQualityManagers = async (event) => {
            return await this.getQualityManagers(event);
        };

        this.onAssessmentTypeChange  = () => {
            const { likelihood_id, severity_potential_id } = this.eventAssessment;
            if (likelihood_id && severity_potential_id) {
                this.getAssessmentScore({
                    iev_id: this.eventID,
                    likelihood_id,
                    severity_potential_id
                }).then((color) => {
                    this.scoreStyle = { 'background-color': color };
                });
            }
        };

        this.onHistoricalRecord = ({ item }) => {
            this.getAssessmentHistoricalRecordDetails(item.iev_id).then(() => {
                $ngRedux.dispatch({
                    type: TAB_SHOW,
                    id: this.identifier,
                    state: [`${$state.$current.parent.name}.assessmentHistoricalRecord`],
                    stateParams: { id: item.iev_id }
                });
            });
        };

        this.onSaveAssessment = () => {
            this.reviewRequired = false;
            $scope.assessmentReviewForm.$setPristine();
            $scope.assessmentReviewForm.$setUntouched();
            $timeout(() => {
                if (this.eventAssessmentForm.$valid) {
                    const { likelihood_id, severity_potential_id } = this.eventAssessment;
                    this.saveAssessment({
                        iev_id: this.eventID,
                        likelihood_id,
                        severity_potential_id,
                        created_by: this.eventAssessment.responsible_person.subject_uuid,
                        updated_on: formatDate(this.eventAssessment.assessment_update_date, DATE_FORMAT.OUTPUT)
                    }).then(() => {
                        Notifier.success('Event Assessment has been successfully saved');
                        const confirm = $mdDialog.confirm()
                            .title('Assessment Review')
                            .textContent('Would you like to submit the Assessment Review?')
                            .ariaLabel('Submit Review')
                            .ok('Yes')
                            .cancel('No');
                        this.dialog = $mdDialog.show(confirm).then(() => {
                            this.submitReview();
                        }, angular.noop);
                    });
                } else Notifier.error('Please fill in the form fields');
            }, 100);
        };

        this.submitReview = () => {
            this.reviewRequired = true;
            const { assessmentReviewForm } = $scope;
            $timeout(async () => {
                if (assessmentReviewForm.$valid) {
                    if (this.eventAssessment.saved) {
                        this.reviewRequired = false;
                        this.saveAssessmentReview({
                            iev_id: this.eventID,
                            qmn_uuid: this.eventAssessment.quality_manager.subject_uuid,
                            review_comment: this.eventAssessment.quality_manager_review,
                            review_date: formatDate(this.eventAssessment.quality_manager_review_date, DATE_FORMAT.OUTPUT),
                            signature: this.eventAssessment.quality_manager_signature
                        }).then(() => {
                            assessmentReviewForm.$setPristine();
                            assessmentReviewForm.$setUntouched();
                            Notifier.success('Assessment review has been successfully submitted');
                        });
                    } else {
                        Notifier.error('You have to save Event Assessment first');
                    }
                } else {
                    for (const item of assessmentReviewForm.$$controls) {
                        if (item.$invalid) {
                            item.$setTouched();
                        }
                    }
                    Notifier.error('Please fill in the form fields');
                }
            }, 100);
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        };
    },
    template: require('./events-assessment-hazard.html'),
    bindings: {
        type: '@'
    },
};
