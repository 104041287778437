import API from '../../../common/http/api.fetch';
import {success, responseMessage}   from '../../../common/utils/util.notifier';
import {GRID_FETCH, GRID_RELOAD} from '../../../common/grid/grid.constants';
import I18N                         from '../../../common/utils/util.i18n';
import {TYPES} from './import-action-type.constants'
import {IDS} from "./import-action-id.constants";
import orderBy from 'lodash/orderBy';
import moment from "moment";

export const importUploadedFile = (uploadedFiles, slo_uuid, deleteMissingUsers) => {
    return dispatch => {
        return API.fetch({
            url: `/employees/import`,
            method: 'POST',
            data: {
                slo_uuid,
                file_path: uploadedFiles[0].filePath,
                source: 'myrso',
                append_mode: deleteMissingUsers ? 0 : 1,
            },
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.IMPORT_SUCCESSFUL');
        },error => {
            responseMessage(error)
        });
    };
};

export const fetchImportHistoryListGrid = (filter) => {
    return dispatch => {
        return API.fetch({
            url: '/employees/import-history',
            method: 'GET',
            params: {
                ...filter,
            },
        }).then(({data}) => {
            dispatch({
                type:   GRID_FETCH,
                id:     IDS.empImport,
                path:   IDS.empImportHistoryListGrid,
                items:  data.items.map(item => ({
                    ...item,
                    source: item.source.replace('.imports',''),
                    append_mode: item.append_mode ? 'Append' : '-',
                    time: moment(item.started).format('HH:mm:ss'),
                })),
                itemsCount: parseInt(data.items_count),
            })
        }, error => {
            responseMessage(error);
        })
    }
};

export const fetchImportHistoryDetailsGrid = (params) => {
    return dispatch => {
        return API.fetch({
            url: '/employees/import-history-details',
            method: 'GET',
            params,
        }).then(({data}) => {
            dispatch({
                type:   GRID_FETCH,
                id:     IDS.empImport,
                path:   IDS.empImportHistoryDetailsGrid,
                items:  data.items.map(item => ({
                    ...item,
                    id:item.gih_id,
                    cost_center_code: item.cost_center_code && item.cost_center_code.length > 3 ? item.cost_center_code.slice(5,8) : item.cost_center_code,
                    action: item.generated_password ? `${item.action} ${item.generated_password}` : item.action,
                    name: `${item.first_name} ${item.last_name}`
                })),
                itemsCount: parseInt(data.items_count),
            })
        }, error => {
            responseMessage(error);
        })
    }
};

export const fetchCostCenters = (hof_uuid) => {
    return dispatch => {
        return API.fetch({
            url: '/employees/import-settings/list-cost-centers',
            method: 'GET',
            params: {
                hof_uuid,
                only_active_slo: 1
            },
        }).then(({data}) => {
            dispatch({
                type:   TYPES.FETCH_COST_CENTERS,
                id:     IDS.empImportNewImport,
                costCenters: orderBy(data.items, 'site_name').map(item => ({
                    value: item.cost_center_number,
                    label: item.cost_center_name,
                })),
            })
        }, error => {
            responseMessage(error);
        })
    }
};
