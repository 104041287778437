/* @ngInject */
export function ProtectiveEquipmentManager($rootScope, $mdDialog, NavigationService, PersonalEquipmentType, PersonalLeadEquivalence, PersonalEquipmentTester, ProtectiveEquipmentService, ProtectiveEquipmentTestService, Notifier) {

    // Tab Definitions
    this.TAB_PERSONAL_EQUIPMENT = 0;
    this.TAB_PERSONAL_EQUIPMENT_DETAILS = 1;
    this.TAB_PERSONAL_EQUIPMENT_TEST_CREATE = 2;
    this.TAB_PERSONAL_EQUIPMENT_TESTS = 3;
    this.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS = 4;
    this.TAB_PERSONAL_EQUIPMENT_HISTORY = 5;
    this.TAB_PERSONAL_EQUIPMENT_HISTORY_DETAILS = 6;

    // Tab Templates
    this.tplEquipmentListing        = './personal-equipment-listing.html';
    this.tplEquipmentDetails        = './personal-equipment-details.html';
    this.tplEquipmentTests          = './personal-equipment-tests.html';
    this.tplEquipmentTestDetails    = './personal-equipment-test-details.html';
    this.tplEquipmentTestReports    = './personal-equipment-test-reports-listing.html';
    this.tplEquipmentHistory        = './personal-equipment-history.html';
    this.tplEquipmentHistoryDetails = './personal-equipment-history-details.html';

    // Dialog Templates
    this.tplEqipmentType                = require('../views/personal-equipment/details/personal-equipment-type-dialog.html');
    this.tplEquipmentLeadEquivalence    = require('../views/personal-equipment/details/personal-equipment-lead-equivalence-dialog.html');
    this.tplEqipmentTester              = require('../views/personal-equipment/test/personal-equipment-tester-dialog.html');
    this.tplEqipmentScreeningResult     = require('../views/personal-equipment/test/personal-equipment-screening-result-dialog.html');

    // Events
    this.ADD_EQUIPMENT_TYPE_COMPLETE    = 'add_equipment_type_complete';
    this.ADD_LEAD_EQUIVALENCE_COMPLETE  = 'add_lead_equivalence_complete';
    this.ADD_EQUIPMENT_TESTER_COMPLETE  = 'add_equipment_tester_complete';

    this.tabs = [
        {
            id: this.TAB_PERSONAL_EQUIPMENT,
            title: 'Equipment Listing',
            content: this.tplEquipmentListing,
            form: null,
            formValidation: false,
            formValidationMsg: '',
            visible: true,
            disabled: false,
            stateless: true,
            navigation: true
        },
        {
            id: this.TAB_PERSONAL_EQUIPMENT_DETAILS,
            title: 'Equipment Details',
            content: this.tplEquipmentDetails,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: true,
            stateless: false,
            navigation: true
        },
        {
            id: this.TAB_PERSONAL_EQUIPMENT_TEST_CREATE,
            title: 'New Test',
            content: this.tplEquipmentTestDetails,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: true,
            stateless: false,
            navigation: true
        },
        {
            id: this.TAB_PERSONAL_EQUIPMENT_TESTS,
            title: 'Tests',
            content: this.tplEquipmentTests,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: true,
            stateless: true,
            navigation: true
        },
        {
            id: this.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS,
            title: 'Test Details',
            content: this.tplEquipmentTestDetails,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: false,
            disabled: true,
            stateless: false,
            navigation: true
        },
        {
            id: this.TAB_PERSONAL_EQUIPMENT_HISTORY,
            title: 'Equipment History',
            content: this.tplEquipmentHistory,
            form: null,
            formValidation: false,
            formValidationMsg: '',
            visible: true,
            disabled: true,
            stateless: true,
            navigation: true
        },
        {
            id: this.TAB_PERSONAL_EQUIPMENT_HISTORY_DETAILS,
            title: 'History Details',
            content: this.tplEquipmentHistoryDetails,
            form: null,
            formValidation: false,
            formValidationMsg: '',
            visible: true,
            disabled: true,
            stateless: false,
            navigation: true
        }
    ];

    this.getTestReportsTabs = function() {
        var tabs = _.cloneDeep(this.tabs);
        var tabTests = _.find(tabs, ['id', this.TAB_PERSONAL_EQUIPMENT_TESTS]);
        tabTests.content = this.tplEquipmentTestReports;
        tabTests.disabled = false;
        tabTests.visible = true;

        var tabTestDetails = _.find(tabs, ['id', this.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS]);
        tabTestDetails.visible = true;

        return [tabTests, tabTestDetails];
    };

    this.addEquipmentType = function(typeList) {
        $mdDialog.show({
            controller: EquipmentTypeController,
            template: this.tplEqipmentType,
            parent: angular.element(document.body),
            locals: {
                typeList: typeList
            },
            clickOutsideToClose: true
        }).then((function(equipmentType) {
            $rootScope.$broadcast(this.ADD_EQUIPMENT_TYPE_COMPLETE, equipmentType);
        }).bind(this), angular.noop);

        function EquipmentTypeController($scope, typeList) {
            $scope.headerColor = NavigationService.getNavConfig().colorName;
            $scope.equipmentTypeList = typeList;
            $scope.equipmentType = new PersonalEquipmentType();

            $scope.save = function() {
                $scope.$broadcast('show-errors-check-validity', 'equipmentTypeForm');
                if ($scope.equipmentTypeForm.$invalid) return;

                ProtectiveEquipmentService.addPersonalEquipmentType($scope.equipmentType).then(function(id) {
                    $scope.equipmentType.id = id;
                    $mdDialog.hide($scope.equipmentType);
                    Notifier.success('Lead Equivalence was successfully added');
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };

            $scope.cancel = function() {
                $mdDialog.cancel();
            };
        }
    };

    this.addLeadEquivalence = function() {
        $mdDialog.show({
            controller: LeadEquivalenceController,
            template: this.tplEquipmentLeadEquivalence,
            parent: angular.element(document.body),
            locals: {
            },
            clickOutsideToClose: true
        }).then((function(leadEquivalence) {
            $rootScope.$broadcast(this.ADD_LEAD_EQUIVALENCE_COMPLETE, leadEquivalence);
        }).bind(this), angular.noop);

        function LeadEquivalenceController($scope) {
            $scope.headerColor = NavigationService.getNavConfig().colorName;
            $scope.leadEquivalence = new PersonalLeadEquivalence();

            $scope.save = function() {
                $scope.$broadcast('show-errors-check-validity', 'equipmentLeadEquivalenceForm');
                if ($scope.equipmentLeadEquivalenceForm.$invalid) return;

                ProtectiveEquipmentService.addPersonalLeadEquivalence($scope.leadEquivalence).then(function(id) {
                    $scope.leadEquivalence.id = id;
                    $mdDialog.hide($scope.leadEquivalence);
                    Notifier.success('Lead Equivalence was successfully added');
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };

            $scope.cancel = function() {
                $mdDialog.cancel();
            };
        }
    };

    this.addEquipmentTester = function(locationUuid) {
        $mdDialog.show({
            controller: EquipmentTesterController,
            template: this.tplEqipmentTester,
            parent: angular.element(document.body),
            locals: {
                locationUuid: locationUuid
            },
            clickOutsideToClose: true
        }).then((function(equipmentTester) {
            $rootScope.$broadcast(this.ADD_EQUIPMENT_TESTER_COMPLETE, equipmentTester);
        }).bind(this), angular.noop);

        function EquipmentTesterController($scope, locationUuid) {
            $scope.headerColor = NavigationService.getNavConfig().colorName;
            $scope.equipmentTester = new PersonalEquipmentTester();

            $scope.save = function() {
                $scope.$broadcast('show-errors-check-validity', 'equipmentTesterForm');
                if ($scope.equipmentTesterForm.$invalid) return;

                ProtectiveEquipmentTestService.addTesterContractor(locationUuid, $scope.equipmentTester).then(function(uuid) {
                    $scope.equipmentTester.uuid = uuid;
                    $mdDialog.hide($scope.equipmentTester);
                    Notifier.success('Tester/Contractor was successfully added');
                });
            };

            $scope.cancel = function() {
                $mdDialog.cancel();
            };
        }
    };

    this.selectScreeningResult = function(test) {
        $mdDialog.show({
            controller: EquipmentScreeningResultController,
            template: this.tplEqipmentScreeningResult,
            parent: angular.element(document.body),
            locals: {
                test: test
            },
            clickOutsideToClose: false
        });

        function EquipmentScreeningResultController($scope, test) {
            $scope.headerColor = NavigationService.getNavConfig().colorName;
            $scope.inquiry = 'mainQuestion';
            $scope.confirm = 'confirmQuestion';
            $scope.reject = 'rejectQuestion';
            $scope.actions = true;

            $scope.action = function(result) {
                switch (result) {
                    case 'confirmQuestion':
                        $scope.inquiry = result;
                        $scope.confirm = 'f-cr-x';
                        $scope.reject = 'f-cr-n';
                        break;
                    case 'rejectQuestion':
                        $scope.inquiry = result;
                        $scope.confirm = 'f-nc-x';
                        $scope.reject = 'f-nc-n';
                        break;
                    case 'f-cr-x':
                        $scope.inquiry = 'answer2';
                        $scope.actions = false;
                        test.annualScreeningResult = result;
                        break;
                    case 'f-cr-n':
                        $scope.inquiry = 'answer1';
                        $scope.actions = false;
                        test.annualScreeningResult = result;
                        break;
                    case 'f-nc-n':
                        $scope.inquiry = 'answer1';
                        $scope.actions = false;
                        test.annualScreeningResult = result;
                        break;
                    case 'f-nc-x':
                        $scope.inquiry = 'answer2';
                        $scope.actions = false;
                        test.annualScreeningResult = result;
                        break;
                }
            };

            $scope.finish = function() {
                $mdDialog.hide();
            };

            $scope.cancel = function() {
                test.annualScreening = null;
                $mdDialog.cancel();
            };
        }
    };
}
