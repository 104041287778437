import { TabsReducer } from '../../../common/tabs/tabs.reducer';

export const tabs = {
    'app.administration.manufacturers.add': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.administration.manufacturers.add.details',
                title: 'Manufacturer Details',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Add Manufacturer' },
                },
            },
        ],
    },
    'app.administration.manufacturers.manage': {
        ...TabsReducer(),
        tabs: [
            {
                state: 'app.administration.manufacturers.manage.list',
                title: 'Manufacturers',
                visible: true,
                disabled: false,
                loading: false,
            },
            {
                state: 'app.administration.manufacturers.manage.details',
                title: 'Manufacturer Details',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Modify Manufacturer' },
                },
            },
            {
                state: 'app.administration.manufacturers.manage.equipment',
                title: 'Equipment',
                visible: false,
                disabled: false,
                loading: false,
            },
            {
                state: 'app.administration.manufacturers.manage.equipmentModel',
                title: 'Equipment Details',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: { label: 'Save' }
                },
            },
        ],
    },
};
