import {IDS} from "./dms-manage-action-id.constants";
import {TAB_DISABLE, TAB_HIDE} from "../../../common/tabs/tabs.constants";

const QUERY_PARAMS = '?param1&param2';

export const tabsState = {
    name: IDS.app_dms_manage,
    redirectTo: IDS.app_dms_manage_list,
    url: '/manage',
};

export const tabListState = {
    name: IDS.app_dms_manage_list,
    url: '/list',
    component: 'tabs',
    // component: 'pnpAccredList',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: IDS.app_dms_manage_list,
            state: [
                IDS.app_dms_manage_details,
            ]
        })
    }
};
tabListState.onEnter.$inject = ['$ngRedux'];

export const tabDetailsState = {
    name: IDS.app_dms_manage_details,
    url: '/details',
    component: 'pnpAccredDetails',
};