/**
 * Creates a new EquipmentCalibrationDetectorsController.
 * @class
 */
class EquipmentCalibrationDetectorsController {
    constructor($ngRedux, $state, $mdDialog, NavigationService, EquipmentCalibrationActions, Notifier) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.NavigationService = NavigationService;
        this.actions = EquipmentCalibrationActions;
        this.Notifier = Notifier;
        this.grid = 'equipmentDetectorsGrid';
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);

        if (this.equipment.id) {
            this.identifier = 'app.equipmentCalibration.manage';
            this.state = 'app.equipmentCalibration.manage.detectors';
        }
        else {
            this.identifier = 'app.equipmentCalibration.add';
            this.state = 'app.equipmentCalibration.add.detectors';
        }

        this.getAssetCategories(this.equipment.type.id);
    }

    loadAssetModels = (event) => {
        this.getModels('detector', this.detector.category, this.detector.manufacturer);
    };

    createModel() {
        const createModelCtrl = ($scope) => {
            $scope.headerColor = this.colorName;
            $scope.addModel = () => {
                if ($scope.modelForm.$valid) {
                    this.addModel('detector', {
                        manufacturer: this.detector.manufacturer,
                        type: this.detector.category,
                        name: $scope.model.name,
                        description: ''
                    });
                    this.$mdDialog.hide();
                }
            };
            $scope.cancel = () => {
                this.$mdDialog.cancel();
            };
        };
        createModelCtrl.$inject = ['$scope'];

        this.$mdDialog.show({
            controller: createModelCtrl,
            template: require('../dialogs/equipment-model-dialog.html'),
            parent: angular.element(document.body),
            locals: {equipment: this.equipment},
            clickOutsideToClose: true
        }).then(() => {}, angular.noop);
    }

    manageAsset() {
        if (this.assetForm.$valid) {
            if (this.detector.id) {
                this.editAsset(this.detector, this.grid).then(() => {
                    this.Notifier.success('Detector changes has been successfully saved');
                });
            }
            else {
                this.addAsset(this.detector, this.grid).then(() => {
                    this.assetForm.$setPristine();
                    this.assetForm.$setUntouched();
                    this.Notifier.success('New Detector has been successfully added');
                });
            }
        }
        else this.Notifier.error('Please fill in the form fields');
    }

    onSelectAsset = (event) => {
        this.$ngRedux.dispatch({
            type: 'ASSET_DETAILS',
            id: 'detector',
            data: event.item
        });
    };

    onResetAsset = () => {
        this.$ngRedux.dispatch({
            type: 'ASSET_RESET',
            id: 'detector'
        });
        this.assetForm.$setPristine();
        this.assetForm.$setUntouched();
    };

    onRemoveAsset = (event) => {
        const confirm = this.$mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Chosen asset will be removed permanently.')
            .ariaLabel('Remove asset')
            .ok('Yes')
            .cancel('No');

        this.removeDialog = this.$mdDialog.show(confirm).then(() => {
            this.removeAsset(event.item.equ_id, this.grid);
        });
    };

    mapState(state) {
        return {
            equipment: state.equipmentCalibration.equipment,
            detector: state.equipmentCalibration.detector
        }
    }

    $onDestroy() {
        this.unsubscribe();
        if (this.removeDialog)
            this.$mdDialog.hide(this.removeDialog);
    }
}

EquipmentCalibrationDetectorsController.$inject = ['$ngRedux', '$state', '$mdDialog', 'NavigationService', 'EquipmentCalibrationActions', 'Notifier'];

export const equipmentCalibrationDetectors = {
    controller: EquipmentCalibrationDetectorsController,
    template: require('./equipment-calibration-detectors.html')
};
