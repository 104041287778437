import * as CatCentersActions from '../adm-cat-centers.actions'
import { IDS } from '../../adm-categorisation-action-id.constants'
/**
 * AdmCatCenterDetailsController.
 * @class
 * @memberOf Administration
 */
class AdmCatCenterDetailsController {
    constructor($ngRedux, $state, $scope, Notifier, NavigationActions) {
        this.$ngRedux           = $ngRedux;
        this.$state             = $state;
        this.$scope             = $scope;
        this.Notifier           = Notifier;
        this.NavigationActions  = NavigationActions;
    }

    $onInit() {
        this.identifier = IDS.admCatCenterDetails;
        this.unsubscribe = this.$ngRedux.connect(
            state => {
                return {
                    formData: state.administration.admCategorisation.admCatCenterDetails,
                }
            },
            CatCentersActions
        )(this);
    }

    onSubmit() {
        this.$scope.$broadcast('show-errors-check-validity', '$ctrl.detailsForm');
        if (this.detailsForm.$valid)
            this.addScheme(this.formData, this.NavigationActions.getConfigs, this.$state);
        else
            this.Notifier.error('COMMON.FIELDS_REQUIRED');
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

AdmCatCenterDetailsController.$inject = ['$ngRedux', '$state', '$scope', 'Notifier', 'NavigationActions']

export const admCatCenterDetails = {
    controller: AdmCatCenterDetailsController,

    template: `
        <tabs-header title="{{'ACCREDITATION.ADD_SCHEME.TITLE' | translate}}"></tabs-header>
        
        <form id="detailsForm" name="$ctrl.detailsForm" noValidate>
            <div layout-gt-xs="row">
                <input-text flex-gt-md="60"
                    identifier="{{$ctrl.identifier}}"
                    label="{{'ACCREDITATION.SETTINGS.SCHEME.TILE_CODE' | translate}}"
                    name="code"
                    state-path="code"
                    required="true"
                    max-length="16"></input-text>
            </div>
            <div layout-gt-xs="row">
                <input-text-area flex class="noMarginBottom"
                    identifier="{{$ctrl.identifier}}"
                    label="{{'ACCREDITATION.SETTINGS.SCHEME.TILE_NAME' | translate}}"
                    name="name"
                    state-path="name"
                    required="true"
                    rows="1"
                    max-length="45"></input-text-area>
            </div>
            <div layout-gt-md="row">
                <input-text-area flex class="noMarginBottom"
                    identifier="{{$ctrl.identifier}}"
                    label="{{'ACCREDITATION.SETTINGS.SCHEME.NAME' | translate}}"
                    name="description"
                    state-path="description"
                    required="true"
                    rows="2"
                    max-length="120"></input-text-area>
            </div>    
            
            <h4>{{'ACCREDITATION.SETTINGS.SCHEME.DIGITS_HEADER' | translate}}</h4>
            <div layout-gt-xs="row">
                <input-select flex-gt-md="10"
                    identifier="{{$ctrl.identifier}}"
                    label="{{'ACCREDITATION.SETTINGS.SCHEME.CATEGORY' | translate}}"
                    name="level1_num_digits"
                    state-path="level1_num_digits"
                    state-data="leadingZerosOptions"
            ></input-select>
                <input-select flex-gt-md="10"
                    identifier="{{$ctrl.identifier}}"
                    label="{{'ACCREDITATION.SETTINGS.SCHEME.STANDARD' | translate}}"
                    name="level2_num_digits"
                    state-path="level2_num_digits"
                    state-data="leadingZerosOptions"
            ></input-select>
                <input-select flex-gt-md="10"
                    identifier="{{$ctrl.identifier}}"
                    label="{{'ACCREDITATION.SETTINGS.SCHEME.ACTIVITY' | translate}}"
                    name="level3_num_digits"
                    state-path="level3_num_digits"
                    state-data="leadingZerosOptions"
            ></input-select>
            </div>
            
            <div layout-xs="column"
                layout-sm="column"
                layout-gt-sm="row"
                flex-gt-md="60"
                layout-align="center center">
                    <div flex hide-xs hide-sm></div>
                    <md-button class="btnColorAccreditations" 
                        name="btnSubmit" 
                        aria-label="Add Scheme" 
                        ng-click="$ctrl.onSubmit()">{{'COMMON.BUTTON.ADD'| translate}}</md-button>
            </div>
        </form>`
}
