export function kdValidatePassword() {
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, model) {

            if (!attrs.kdValidatePassword) {
                console.error('kdValidatePassword expects a model as an argument!');
                return;
            }

            scope.$watch(attrs.kdValidatePassword, function (value) {
                // Only compare values if the second ctrl has a value.
                if (model.$viewValue !== undefined && model.$viewValue !== '' && model.$viewValue !== null) {
                    model.$setValidity('kdValidatePassword', value === model.$viewValue);
                }
            });

            model.$parsers.push(function (value) {
                // Mute the kdValidatePassword error if the second ctrl is empty.
                if (value === undefined || value === '') {
                    model.$setValidity('kdValidatePassword', true);
                    return value;
                }
                var isValid = value === scope.$eval(attrs.kdValidatePassword);
                model.$setValidity('kdValidatePassword', isValid);
                return isValid ? value : undefined;
            });
        }
    };
}
