/**
 * @module root/components/qc-tasks
 */
import { qcTasksState } from './qc-tasks.states';
import { qcTasks } from './qc-tasks.component';

export const QC_TASKS_MODULE = angular.module(
    'migration.qc-tasks',
    []
).config(['$uiRouterProvider', $uiRouter => {
    $uiRouter.stateRegistry.register(qcTasksState);
}]).component({
    qcTasks
});
