/* @ngInject */
export function SelectRequirementsDaysRangeController($ngRedux, EquipmentActions) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, EquipmentActions)(this);
        ctrl.getRequirementsDaysRangeList(ctrl.identifier);
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            $ngRedux.dispatch({
                type: 'EQUIPMENT_REQUIREMENTS_DAYS_RANGE_SELECTED',
                id: ctrl.identifier,
                requirementsDaysRange: ctrl.selectedOption
            });
        }
    };

    function mapStateToThis(state) {
        return {
            options: state.equipment[ctrl.identifier].requirementsDaysRangeList,
            selectedOption: state.equipment[ctrl.identifier].filter.requirementsDaysRange
        };
    }
}
