import API from '../../../common/http/api.fetch';
// import I18N from '../../../../common/utils/util.i18n';
import {success, responseMessage} from '../../../common/utils/util.notifier';
import { GRID_FETCH, GRID_RELOAD, GRID_FILTER, GRID_SELECT_ALL } from '../../../common/grid/grid.constants';
import { TYPES } from './acc-documents-action-type.constants';
import { IDS } from './acc-documents-action-id.constants';
import { getDocFileNameFromPath } from "../../../common/utils/util.path";
import truncate from 'lodash/truncate';
import {formatDate} from "../../../common/utils/util.date";
import {DATE_FORMAT} from "../../../common/utils/util.constants";

// const localizeType = (code, defaultName) => {
//     const textId = `DOCUMENT_MANAGEMENT.TYPES.${code.toUpperCase()}`;
//     const translation = I18N.translate(textId);
//     return translation === textId ? defaultName : translation
// };

export const fetchAccDocumentsGrid = (filter) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/versioned-document-list',
            method: 'GET',
            params: {
                ...filter,
                // scope_subject_types: 'cli,hof', or 'gam', or 'slo',
                due_date_to: formatDate(filter.due_date_to, DATE_FORMAT.OUTPUT),
                due_date_from: formatDate(filter.due_date_from, DATE_FORMAT.OUTPUT),
                path: undefined
            }
        }).then(({data}) => {

            // parse json string to array of objects
            const items = data.items.map( item => ({
                ...item,
                required_documents: JSON.parse(item.required_documents)
            }));

            dispatch({
                type:   GRID_FETCH,
                id:     IDS.accDocuments,
                path:   filter.path,
                ...data,
                items: items.map( item => {
                    const standards = item.required_documents.length === 1
                      ? item.required_documents[0].standard
                      : item.required_documents.map(doc => doc.standard.split(' ')[0]).join(' ');

                    return {
                        ...item,
                        type: item.type_name,
                        typeColumn: `${item.level} ${item.type_name}`,
                        filename: getDocFileNameFromPath(item.doc_uri) || '',
                        templateFilename: getDocFileNameFromPath(item.template),
                        // location: item.required_documents[0].location,
                        standards,
                        standardsColumn: truncate(standards,{ 'length': 50}),
                    }
                })
            })
        }, error => {
            responseMessage(error);
        });
    };
}

export const addAccStandardRequiredDoc = (data) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/add-required-doc',
            method: 'POST',
            data
        }).then(response => {
            dispatch({
                type:   GRID_RELOAD,
                id:     IDS.accDocuments,
                path:   IDS.accDocumentsGrid,
            })
            success('COMMON.SERVER_RESPONSE.ITEM_ADDED');
        }, error => {
            responseMessage(error);
        });
    };
}

export const editAccStandardRequiredDocument = (data) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/edit-versioned-document',
            method: 'POST',
            data,
        }).then(response => {
            dispatch({
                type:   GRID_RELOAD,
                id:     IDS.accDocuments,
                path:   IDS.accDocumentsGrid,
            })
            success('COMMON.SERVER_RESPONSE.ITEM_UPDATED');
        }, error => {
            responseMessage(error);
        });
    };
}

export const invalidateRequiredDocument = (ard_id, path) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/invalidate-stand-required-doc',
            method: 'POST',
            data: { ard_id }
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_DELETED');
            dispatch({
                type:   GRID_RELOAD,
                id:     IDS.accDocuments,
                path,
            });
        }, error => {
            responseMessage(error);
        });
    };
}

export const fetchSiteLocationDetails = (siteLocationUuid) => {
    return dispatch => {
        return API.fetch({
            url: `/location/sitelocation/${siteLocationUuid}`,
            method: 'GET',
            params: {}
        }).then(response => {
            return response.data[0][0];
        })
    }
}