import { NOTIFICATION } from '../utils/util.constants';

const SUCCESS           = {status: 200, authorized: true,  reject: false, autoNotify: false, type: NOTIFICATION.SUCCESS, msg: 'Success'};
const BAD_REQUEST       = {status: 400, authorized: true,  reject: true,  autoNotify: false, type: NOTIFICATION.ERROR,   msg: 'Bad Request'};
const UNAUTHORIZED      = {status: 401, authorized: false, reject: true,  autoNotify: false, type: NOTIFICATION.ERROR,   msg: 'Unauthorized'};
const FORBIDDEN         = {status: 403, authorized: false, reject: true,  autoNotify: false, type: NOTIFICATION.ERROR,   msg: 'Forbidden'};
const NOT_FOUND         = {status: 404, authorized: true,  reject: true,  autoNotify: false, type: NOTIFICATION.ERROR,   msg: 'Not Found'};
const CONFLICT          = {status: 409, authorized: true,  reject: true,  autoNotify: false, type: NOTIFICATION.ERROR,   msg: 'Conflict'};
const SESSION_TIMEOUT   = {status: 419, authorized: false, reject: true,  autoNotify: false, type: NOTIFICATION.ERROR,   msg: 'Session Timeout'};
const SESSION_EXPIRED   = {status: 440, authorized: false, reject: true,  autoNotify: false, type: NOTIFICATION.ERROR,   msg: 'Token Expired'};
const SERVER_ERROR      = {status: 500, authorized: true,  reject: true,  autoNotify: true,  type: NOTIFICATION.ERROR,   msg: 'Internal Server Error'};
const TOKEN_EXPIRED     = {status: 600, authorized: true,  reject: false, autoNotify: false, type: NOTIFICATION.ERROR,   msg: 'Request token expired. Please repeat reset password procedure.'};
const TOKEN_USED        = {status: 601, authorized: true,  reject: false, autoNotify: false, type: NOTIFICATION.ERROR,   msg: 'Request token already used. Please repeat reset password procedure.'};
const TOKEN_INVALID     = {status: 602, authorized: true,  reject: false, autoNotify: false, type: NOTIFICATION.ERROR,   msg: 'Invalid request token. Please repeat reset password procedure.'};
const SYSTEM_ERROR      = {status: -1,  authorized: true,  reject: true,  autoNotify: true,  type: NOTIFICATION.ERROR,   msg: 'System Error'};

const RESPONSES = [SUCCESS, BAD_REQUEST, UNAUTHORIZED, FORBIDDEN, NOT_FOUND, CONFLICT, SESSION_TIMEOUT, SESSION_EXPIRED, SERVER_ERROR, TOKEN_EXPIRED, TOKEN_USED, TOKEN_INVALID, SYSTEM_ERROR];

export {SUCCESS, BAD_REQUEST, UNAUTHORIZED, FORBIDDEN, NOT_FOUND, CONFLICT, SESSION_TIMEOUT, SESSION_EXPIRED, SERVER_ERROR, TOKEN_EXPIRED, TOKEN_USED, TOKEN_INVALID, SYSTEM_ERROR, RESPONSES};
