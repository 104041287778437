export const equipmentCalibrationAddState = {
    name: 'app.equipmentCalibration.add',
    redirectTo: 'app.equipmentCalibration.add.details',
    url: '/add',
    component: 'tabs',
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({type: 'EQUIPMENT_CALIBRATION_RESET'});
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.equipmentCalibration.add',
            state: [
                'app.equipmentCalibration.add.assetCost',
                'app.equipmentCalibration.add.detectorProbe',
                'app.equipmentCalibration.add.detectors',
                'app.equipmentCalibration.add.chambers',
                'app.equipmentCalibration.add.documents',
                'app.equipmentCalibration.add.vendors'
            ]
        });
    }
};
equipmentCalibrationAddState.onEnter.$inject = ['$ngRedux'];

export const equipmentCalibrationAddDetailsState = {
    name: 'app.equipmentCalibration.add.details',
    url: '',
    component: 'equipmentCalibrationDetails'
};

export const equipmentCalibrationAddAssetCostState = {
    name: 'app.equipmentCalibration.add.assetCost',
    url: 'asset-cost',
    component: 'equipmentCalibrationAssetCost'
};

export const equipmentCalibrationAddDocumentsState = {
    name: 'app.equipmentCalibration.add.documents',
    url: '/documents',
    component: 'equipmentCalibrationDocuments'
};

export const equipmentCalibrationAddDetectorProbeState = {
    name: 'app.equipmentCalibration.add.detectorProbe',
    url: '/detector-probe',
    component: 'equipmentCalibrationDetectorProbe'
};

export const equipmentCalibrationAddDetectorsState = {
    name: 'app.equipmentCalibration.add.detectors',
    url: '/detectors',
    component: 'equipmentCalibrationDetectors'
};

export const equipmentCalibrationAddChambersState = {
    name: 'app.equipmentCalibration.add.chambers',
    url: '/chambers',
    component: 'equipmentCalibrationChambers'
};

export const equipmentCalibrationAddVendorsState = {
    name: 'app.equipmentCalibration.add.vendors',
    url: '/vendors',
    component: 'equipmentCalibrationVendors'
};

export const equipmentCalibrationManageState = {
    name: 'app.equipmentCalibration.manage',
    redirectTo: 'app.equipmentCalibration.manage.listing',
    url: '/manage',
    component: 'tabs'
};

export const equipmentCalibrationManageListingState = {
    name: 'app.equipmentCalibration.manage.listing',
    url: '',
    component: 'equipmentCalibrationGrid',
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({type: 'EQUIPMENT_CALIBRATION_RESET'});
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.equipmentCalibration.manage',
            state: [
                'app.equipmentCalibration.manage.loan',
                'app.equipmentCalibration.manage.loanHistory',
                'app.equipmentCalibration.manage.info',
                'app.equipmentCalibration.manage.details',
                'app.equipmentCalibration.manage.assetCost',
                'app.equipmentCalibration.manage.detectorProbe',
                'app.equipmentCalibration.manage.detectors',
                'app.equipmentCalibration.manage.chambers',
                'app.equipmentCalibration.manage.documents',
                'app.equipmentCalibration.manage.vendors'
            ]
        });
    }
};
equipmentCalibrationManageListingState.onEnter.$inject = ['$ngRedux'];

export const equipmentCalibrationManageLoanState = {
    name: 'app.equipmentCalibration.manage.loan',
    url: '/loan',
    component: 'equipmentCalibrationLoan'
};

export const equipmentCalibrationManageLoanHistoryState = {
    name: 'app.equipmentCalibration.manage.loanHistory',
    url: '/loan-history',
    component: 'equipmentCalibrationLoanHistory'
};

export const equipmentCalibrationManageInfoState = {
    name: 'app.equipmentCalibration.manage.info',
    url: '/info',
    component: 'equipmentCalibrationInfo'
};

export const equipmentCalibrationManageDetailsState = {
    name: 'app.equipmentCalibration.manage.details',
    url: '/details',
    component: 'equipmentCalibrationDetails'
};

export const equipmentCalibrationManageAssetCostState = {
    name: 'app.equipmentCalibration.manage.assetCost',
    url: '/asset-cost',
    component: 'equipmentCalibrationAssetCost'
};

export const equipmentCalibrationManageDocumentsState = {
    name: 'app.equipmentCalibration.manage.documents',
    url: '/documents',
    component: 'equipmentCalibrationDocuments'
};

export const equipmentCalibrationManageDetectorProbeState = {
    name: 'app.equipmentCalibration.manage.detectorProbe',
    url: '/detector-probe',
    component: 'equipmentCalibrationDetectorProbe'
};

export const equipmentCalibrationManageDetectorsState = {
    name: 'app.equipmentCalibration.manage.detectors',
    url: '/detectors',
    component: 'equipmentCalibrationDetectors'
};

export const equipmentCalibrationManageChambersState = {
    name: 'app.equipmentCalibration.manage.chambers',
    url: '/chambers',
    component: 'equipmentCalibrationChambers'
};

export const equipmentCalibrationManageVendorsState = {
    name: 'app.equipmentCalibration.manage.vendors',
    url: '/vendors',
    component: 'equipmentCalibrationVendors'
};

export const equipmentCalibrationAlertsState = {
    name: 'app.equipmentCalibration.alerts',
    redirectTo: 'app.equipmentCalibration.alerts.listing',
    url: '/alerts',
    component: 'tabs'
};

export const equipmentCalibrationAlertsListingState = {
    name: 'app.equipmentCalibration.alerts.listing',
    url: '',
    component: 'equipmentCalibrationAlerts'
};
