class ResultController {
    constructor($ngRedux, $transitions, $timeout, PersonalDosimetryActions, Notifier, $filter) {
        this.$ngRedux = $ngRedux;
        this.$transitions = $transitions;
        this.$timeout = $timeout;
        this.PersonalDosimetryActions = PersonalDosimetryActions;
        this.Notifier = Notifier;
        this.$filter = $filter;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this.mapState, this.PersonalDosimetryActions)(this);

        this.onBeforeExit = this.$transitions.onBefore({exiting: 'app.personalDosimetry.import.document'}, () => {
            this.importForm.$setPristine();
            this.importForm.$setUntouched();
        });
    }

    onSave(event) {
        this.Notifier.info(this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.IMPORT_REPORT_DOCUMENT.NOTIFIER_WAIT'));
        this.importDoseReport(event).then(response => {
            this.$ngRedux.dispatch({
                type: 'TAB_ENABLE',
                id: 'app.personalDosimetry.import',
                state: ['app.personalDosimetry.import.details']
            });
            this.$timeout(() => {
                this.$ngRedux.dispatch({
                    type: 'TAB_SELECT',
                    id: 'app.personalDosimetry.import',
                    state: 'app.personalDosimetry.import.details',
                    stateParams: {}
                });
            }, 200);
        }, error => {
            this.Notifier.responseMessage(error);
        });
    }

    $onDestroy() {
        this.unsubscribe();
        this.onBeforeExit();
    }

    mapState(state) {
        return {
            //wearPeriodFrom: state.personalDosimetry.personalDosimetryImport.wearPeriodFrom,
            //wearPeriodTo: state.personalDosimetry.personalDosimetryImport.wearPeriodTo
        }
    }
}

ResultController.$inject = ['$ngRedux', '$transitions', '$timeout', 'PersonalDosimetryActions', 'Notifier', '$filter'];

export const empImportNewResult = {
    controller: ResultController,
    template: `
        <tabs-header title="{{'PERSONAL_DOSIMETRY.TABS.IMPORT_REPORT_DOCUMENT.TAB_TITLE' | translate}}"></tabs-header>
    
        <form id="importForm" name="$ctrl.importForm" novalidate>
            <div layout="column" flex>
                <upload dropzone="true"
                        input-id="uploader"
                        label="{{'PERSONAL_DOSIMETRY.TABS.IMPORT_REPORT_DOCUMENT.BUTTON_UPLOAD' | translate}}"
                        validate="true"
                        valid-type="xlsx,csv"
                        identifier="personalDosimetryImport"
                        on-save="$ctrl.onSave($event)">
                </upload>
            </div>
        </form>
`
};
