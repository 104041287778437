import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';
import { getRequestEquipmentForAuthorization } from '../../ap-laser-request.actions';

class ApplicationsPermitsLaserRequestEquipmentAuthGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'applicationsPermits';
        this.identifier = 'apLaserRequestEquipmentAuthGrid';
        this.header = false;
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                mapper: null},
            {title: 'Equipment ID',     mapper: 'equipment_id'},
            {title: 'Type',             mapper: 'type'},
            {title: 'Manufacturer',     mapper: 'manufacturer'},
            {title: 'Model',            mapper: 'model'},
            {title: 'Serial Number',    mapper: 'serial'},
            {title: 'Class',            mapper: 'laser_class'},
            {title: 'Site Location',    mapper: 'site_location'},
            {title: 'Department',       mapper: 'department'},
            {title: 'Room Location',    mapper: 'room_location'}
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), { getRequestEquipmentForAuthorization }
        )(this);

        this.dataAction = this.getRequestEquipmentForAuthorization;
        this.exportService = this.getRequestEquipmentForAuthorization;
        this.filter.identifier = this.identifier;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const apLaserRequestEquipmentAuthGrid = {
    bindings: {
        type: '@',
        title: '@',
        onApprove: '&',
        onReject: '&'
    },
    controller: ApplicationsPermitsLaserRequestEquipmentAuthGridController,
    ...gridTpl
};
