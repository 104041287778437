import {TAB_HIDE, TAB_DISABLE} from '../../../common/tabs/tabs.constants';
import {IDS} from './assessment-reports-action-id.constants';

const QUERY_PARAMS = '?ado&year';

export const tabsState = {
    name: IDS.app_accreditation_scheme_assessment_reports,
    redirectTo: IDS.app_accreditation_scheme_assessment_reports_list,
    url: `/:itemCode/assessment-reports${QUERY_PARAMS}`,
    component: 'tabs',
};

export const tabListState = {
    name: IDS.app_accreditation_scheme_assessment_reports_list,
    url: '/list',
    component: 'accAssessmentReports',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: IDS.app_accreditation_scheme_assessment_reports,
            state: [
                IDS.app_accreditation_scheme_assessment_reports_details,
                IDS.app_accreditation_scheme_assessment_reports_add
            ]
        })
    }
};
tabListState.onEnter.$inject = ['$ngRedux'];

export const tabDetailsState = {
    name: IDS.app_accreditation_scheme_assessment_reports_details,
    url: `/details`,
    component: 'documentDetails',
};

export const tabAddDocumentState = {
    name: IDS.app_accreditation_scheme_assessment_reports_add,
    url: `/add`,
    component: 'documentAdd',
};