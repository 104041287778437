import { gridTpl } from '../../../../common/grid/grid.controller';

export const employeeLicencesGrid = {
    bindings: {
        onModifyLicence: '&',
        onRemoveLicence: '&',
        onApprove: '&',
        editLicence: '<'
    },
    controller: 'EmployeeLicencesGridController',
    ...gridTpl
};
