/**
 * @module root/components/administration
 */
import * as STATES from './administration.states';
import * as STATES_ACCOUNT from './accounts/administration-accounts.states';
import { CATEGORISATION_MODULE }  from './categorisation/adm-categorisation.module';
import { administration } from './administration.component';
import { administrationAccountsContactsGrid } from './accounts/grid/administration-accounts-contacts-grid.component';
import { administrationAccountsLicencesGrid } from './accounts/grid/administration-accounts-licences-grid.component';
import { administrationAccountsLicenceIsotopesGrid } from './accounts/grid/administration-accounts-licence-isotopes-grid.component';
import { administrationAccountsLicenceAppendixGrid } from './accounts/grid/administration-accounts-licence-appendix-grid.component';
import { administrationAccountsHeadOfficeContacts } from './accounts/head-offices/contacts/administration-accounts-head-office-contacts.component';
import { administrationAccountsHeadOfficeContactDetails } from './accounts/head-offices/contacts/details/administration-accounts-head-office-contact-details.component';
import { administrationAccountsHeadOfficeLicences } from './accounts/head-offices/licences/admininstration-accounts-head-office-licences.component';
import { administrationAccountsHeadOfficeModules } from './accounts/head-offices/modules/administration-accounts-head-office-modules.component';
import { administrationAccountsSiteLocationContacts } from './accounts/site-locations/contacts/administration-accounts-site-location-contacts.component';
import { administrationAccountsSiteLocationContactDetails } from './accounts/site-locations/contacts/details/administration-accounts-site-location-contact-details.component';
import { administrationAccountsSiteLocationLicenceIsotopes } from './accounts/site-locations/isotopes/administration-accounts-site-location-licence-isotopes.component';
import { administrationAccountsSiteLocationLicenceAppendix } from './accounts/site-locations/appendix/administration-accounts-site-location-licence-appendix.component';
import { administrationAccountsSiteLocationModules } from './accounts/site-locations/modules/administration-accounts-site-location-modules.component';
import { administrationAccountsDepartmentContacts } from './accounts/departments/contacts/administration-accounts-department-contacts.component';
import { administrationAccountsDepartmentContactDetails } from './accounts/departments/contacts/details/administration-accounts-department-contact-details.component';
import { administrationNotificationSettings } from './notification-settings/administration-notification-settings.component';
import { administrationNotificationSettingsGrid } from './notification-settings/grid/administration-notification-settings-grid.component';
import { administrationNotificationSettingsDetails } from './notification-settings/details/administration-notification-settings-details.component';
import { administrationNotificationSettingsTimeline } from './notification-settings/timeline/administration-notification-settings-timeline.component';
import { administrationNotificationSettingsTimelineGrid } from './notification-settings/timeline/grid/administration-notification-settings-timeline-grid.component';
import { administrationMonthlyReportSettings } from './monthly-report-settings/administration-monthly-report-settings.component';
import { administrationMonthlyReportSettingsGrid } from './monthly-report-settings/grid/administration-monthly-report-settings-grid.component';
import { administrationMonthlyReportSettingsDetails } from './monthly-report-settings/details/administration-monthly-report-settings-details.component';
import AdministrationAccountsActions from './accounts/administration-accounts.actions';
import AdministrationNotificationSettingsActions from "./notification-settings/administration-notification-settings.actions";
import AdministrationMonthlyReportSettingsActions from "./monthly-report-settings/administration-monthly-report-settings.actions";

export const ADMINISTRATION_MODULE = angular.module(
    'migration.administration',
    [
        CATEGORISATION_MODULE.name,
    ]
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values({
        ...STATES,
        ...STATES_ACCOUNT
    }).map(state => stateRegistry.register(state));
}]).service({
    AdministrationAccountsActions,
    AdministrationNotificationSettingsActions,
    AdministrationMonthlyReportSettingsActions
}).component({
    administration,
    administrationAccountsContactsGrid,
    administrationAccountsLicencesGrid,
    administrationAccountsLicenceIsotopesGrid,
    administrationAccountsLicenceAppendixGrid,
    administrationAccountsHeadOfficeContacts,
    administrationAccountsHeadOfficeContactDetails,
    administrationAccountsHeadOfficeLicences,
    administrationAccountsHeadOfficeModules,
    administrationAccountsSiteLocationContacts,
    administrationAccountsSiteLocationContactDetails,
    administrationAccountsSiteLocationLicenceIsotopes,
    administrationAccountsSiteLocationLicenceAppendix,
    administrationAccountsSiteLocationModules,
    administrationAccountsDepartmentContacts,
    administrationAccountsDepartmentContactDetails,
    administrationNotificationSettings,
    administrationNotificationSettingsGrid,
    administrationNotificationSettingsDetails,
    administrationNotificationSettingsTimeline,
    administrationNotificationSettingsTimelineGrid,
    administrationMonthlyReportSettings,
    administrationMonthlyReportSettingsGrid,
    administrationMonthlyReportSettingsDetails
}).run(['$templateCache', ({ put }) => {
    put('app.administration.accounts.headOfficesContacts', '<administration-accounts-head-office-contacts></administration-accounts-head-office-contacts>');
    put('app.administration.accounts.headOfficesContactDetails', '<administration-accounts-head-office-contact-details></administration-accounts-head-office-contact-details>');
    put('app.administration.accounts.headOfficesLicences', '<administration-accounts-head-office-licences></administration-accounts-head-office-licences>');
    put('app.administration.accounts.headOfficesModules', '<administration-accounts-head-office-modules></administration-accounts-head-office-modules>');
    put('app.administration.accounts.siteLocations.contacts', '<administration-accounts-site-location-contacts></administration-accounts-site-location-contacts>');
    put('app.administration.accounts.siteLocations.contactDetails', '<administration-accounts-site-location-contact-details></administration-accounts-site-location-contact-details>');
    put('app.administration.accounts.siteLocations.licenceIsotopes', '<administration-accounts-site-location-licence-isotopes></administration-accounts-site-location-licence-isotopes>');
    put('app.administration.accounts.siteLocations.licenceAppendix', '<administration-accounts-site-location-licence-appendix></administration-accounts-site-location-licence-appendix>');
    put('app.administration.accounts.siteLocations.modules', '<administration-accounts-site-location-modules></administration-accounts-site-location-modules>');
    put('app.administration.accounts.departments.contacts', '<administration-accounts-department-contacts></administration-accounts-department-contacts>');
    put('app.administration.accounts.departments.contactDetails', '<administration-accounts-department-contact-details></administration-accounts-department-contact-details>');
    put('app.administration.notificationSettings.listing', '<administration-notification-settings></administration-notification-settings>');
    put('app.administration.notificationSettings.details', '<administration-notification-settings-details></administration-notification-settings-details>');
    put('app.administration.notificationSettings.timeline', '<administration-notification-settings-timeline></administration-notification-settings-timeline>');
    put('app.administration.monthlyReportSettings.listing', '<administration-monthly-report-settings></administration-monthly-report-settings>');
    put('app.administration.monthlyReportSettings.details', '<administration-monthly-report-settings-details></administration-monthly-report-settings-details>');
    put('./administration-notification-settings-grid-filter.html', require('./notification-settings/grid/administration-notification-settings-grid-filter.html'));
}]);
