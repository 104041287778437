class ReportingEquipmentHistoryController {
    constructor($rootScope) {
        this.$rootScope = $rootScope;
    }

    onHistoryReset() {
        this.$rootScope.$broadcast('SELECT_CASCADE_RESET', 'reportingEquipmentHistoryFilterForm');
    }
}

ReportingEquipmentHistoryController.$inject = ['$rootScope'];

export const reportingEquipmentHistory = {
    controller: ReportingEquipmentHistoryController,
    template: `
        <reporting-equipment-history-grid on-reset="$ctrl.onHistoryReset()"></reporting-equipment-history-grid>
    `
};
