/** @namespace Reducers.Employees */

import { TabsReducer } from '../common/tabs/tabs.reducer';
import { GridReducer } from '../common/grid/grid.reducer';
import { getGridSort } from '../common/grid/grid.utils';
import merge from 'lodash/merge';
import has from 'lodash/has';
import set from 'lodash/set';
import update from 'lodash/update';
import omit from 'lodash/omit';

const initialState = {
    equ: {
        category: null,
        categories: [],
    },
    equAssetCost: {
        equ_id: null,
        accountId: null,
        visualId: null,
        cost_centre_code: null,
        cost_centre_name: null,
        purchaseDate: null,
        purchase_order_number: null,
        installation_date: null,
        cap_sens_exp_date: null,
        warranty_period: null,
        warranty_exp_date: null,
        assetCost: null,
        act_type_code: null,
        act_type_code_data: [
            { value: 'exctax', label: 'Excludes Tax' },
            { value: 'inctax', label: 'Includes Tax' },
        ],
        estimated_residual_value: null,
        depreciation_rate_per_year: null,
        tax_estimated_useful_life: null,
        depreciation_type: null,
        depreciation_type_data: [
            { value: 'sldm', label: 'Straight Line Depreciation' },
            { value: 'dbdm', label: 'Diminishing Depreciation' },
        ],
        service_contract: null,
        service_contract_data: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        comment: null,
        software_version: null,
        man_eof_support_date: null,
    }
};

/**
 * @function equipmentReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function equipmentReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            'app.xray.alerts': Object.assign(TabsReducer(), {
                tabs: [{
                    state: 'app.xray.alerts.listing',
                    title: 'Alerts & Warnings',
                    visible: true,
                    disabled: false,
                    loading: false,
                }]
            }),
            'app.lasers.alerts': Object.assign(TabsReducer(), {
                tabs: [{
                    state: 'app.lasers.alerts.listing',
                    title: 'Alerts & Warnings',
                    visible: true,
                    disabled: false,
                    loading: false,
                }]
            }),
            'app.ultrasound.alerts': Object.assign(TabsReducer(), {
                tabs: [{
                    state: 'app.ultrasound.alerts.listing',
                    title: 'Alerts & Warnings',
                    visible: true,
                    disabled: false,
                    loading: false,
                }]
            }),
            'app.mammo.alerts': Object.assign(TabsReducer(), {
                tabs: [{
                    state: 'app.mammo.alerts.listing',
                    title: 'Alerts & Warnings',
                    visible: true,
                    disabled: false,
                    loading: false,
                }]
            }),
            equipmentXrayGrid: merge(GridReducer(), {categories: [], requirementsDaysRangeList: [], requirementTypes: [],  activeCount: 0, archiveCount: 0}, {
                filter: {
                    type: 1,
                    typeCode: 'xra',
                    status: 'act',
                    location: null,
                    checkLocation: true,
                    manufacturer: null,
                    category: null,
                    allRequirementsDue: false,
                    serial: null,
                    dueDateFrom: null,
                    dueDateTo: null,
                    requirementsDaysRange: null,
                    requirementType: null
                }
            }),
            equipmentLasersGrid: merge(GridReducer(), {categories: [], requirementsDaysRangeList: [], requirementTypes: [], activeCount: 0, archiveCount: 0}, {
                filter: {
                    type: 8,
                    typeCode: 'las',
                    status: 'act',
                    location: null,
                    checkLocation: true,
                    manufacturer: null,
                    category: null,
                    allRequirementsDue: false,
                    serial: null,
                    dueDateFrom: null,
                    dueDateTo: null,
                    requirementsDaysRange: null,
                    requirementType: null
                }
            }),
            equipmentUltrasoundGrid: merge(GridReducer(), {categories: [], requirementsDaysRangeList: [], requirementTypes: [], activeCount: 0, archiveCount: 0}, {
                filter: {
                    type: 16,
                    typeCode: 'uso',
                    status: 'act',
                    location: null,
                    checkLocation: true,
                    manufacturer: null,
                    category: null,
                    allRequirementsDue: false,
                    serial: null,
                    dueDateFrom: null,
                    dueDateTo: null,
                    requirementsDaysRange: null,
                    requirementType: null
                }
            }),
            equipmentMammoGrid: merge(GridReducer(), {categories: [], requirementsDaysRangeList: [], requirementTypes: [], activeCount: 0, archiveCount: 0}, {
                filter: {
                    type: 18,
                    typeCode: 'mam',
                    status: 'act',
                    location: null,
                    checkLocation: true,
                    manufacturer: null,
                    category: null,
                    allRequirementsDue: false,
                    serial: null,
                    dueDateFrom: null,
                    dueDateTo: null,
                    requirementsDaysRange: null,
                    requirementType: null
                }
            }),
            equipmentAlertsGrid: merge(GridReducer(), {
                filter: {
                    moduleId: null,
                    alertType: null,
                    keyword: null,
                    equipmentId: null,
                    employeeUuid: null
                }
            }),
            equipmentTechniqueChartsGrid: merge(GridReducer(), {categories: [], activeCount: 0, archiveCount: 0}, {
                filter: {
                    type: 1,
                    status: 'act',
                    location: null,
                    manufacturer: null,
                    category: null,
                    regDateFrom: null,
                    regDateTo: null,
                    certDateFrom: null,
                    certDateTo: null,
                },
                fileManagement: 'chr'
            }),
            equipmentDocumentsGrid: merge(GridReducer(), {
                filter: {
                    equipmentId: null,
                    documentTypeId: null,
                    documentStatusId: null
                }
            }),
            ...initialState,
        };
    }

    if (has(state, action.id)) {
        switch (action.type) {
            case 'EQUIPMENT_CATEGORIES':
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        categories: action.items,
                    },
                    equ: {
                        ...state.equ,
                        categories: action.items.map((item) => ({
                            value: item.id,
                            label: item.name,
                        })),
                    },
                };
            case 'EQUIPMENT_REQUIREMENTS_DAYS_RANGE_LIST':
                return Object.assign({}, state, set({}, action.id, Object.assign(state[action.id], {requirementsDaysRangeList: action.items})));
            case 'EQUIPMENT_REQUIREMENT_TYPES':
                return Object.assign({}, state, set({}, action.id, Object.assign(state[action.id], {requirementTypes: action.items})));
            case 'LOCATION_SELECTED':
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.location', action.uuid)));
            case 'CHECK_LOCATION':
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.checkLocation', action.checkLocation)));
            case 'MANUFACTURER_SELECTED':
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.manufacturer', action.manufacturer)));
            case 'EQUIPMENT_CATEGORY_SELECTED':
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.category', action.category)));
            case 'DATE_SELECTED':
                if (action.id === 'equAssetCost') {
                    return ({
                        ...state,
                        [action.id]: {
                            ...state[action.id],
                            [action.path]: action.input,
                        },
                    });
                }
                return Object.assign({}, state, set({}, action.id, set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)));
            case 'EQUIPMENT_SWITCH':
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.status', action.status)));
            case 'EQUIPMENT_REQUIREMENTS_DAYS_RANGE_SELECTED':
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.requirementsDaysRange', action.requirementsDaysRange)));
            case 'EQUIPMENT_REQUIREMENT_TYPE_SELECTED':
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.requirementType', action.requirementType)));
            case 'INPUT_TEXT_CHANGE':
            case 'INPUT_TEXT_AREA_CHANGE':
            case 'INPUT_SELECT':
            case 'INPUT_RADIO_CHANGE':
                if (['equ', 'equAssetCost'].includes(action.id)) {
                    return ({
                        ...state,
                        [action.id]: {
                            ...state[action.id],
                            [action.path]: action.input,
                        },
                    });
                }
                if (action.id === 'equipmentAlertsGrid') {
                    return Object.assign({}, state, {
                        equipmentAlertsGrid: set(GridReducer(state.equipmentAlertsGrid, set(action, 'type', 'PAGINATE')), 'filter.keyword', action.input)
                    });
                }
            case 'EQUIPMENT_SELECTED':
                if (action.id === 'equipmentDocumentsGrid') {
                    return Object.assign({}, state, {
                        equipmentDocumentsGrid: update(GridReducer(state.equipmentDocumentsGrid, set(action, 'type', 'GRID_RESET')), 'filter', function (filter) {
                            return Object.assign({}, filter, {
                                equipmentId: action.equipmentId
                            });
                        })
                    });
                }
            case 'CHECKBOX_SELECTED':
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.data[action.path])));
            case 'GRID_INIT':
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', function (filter) {
                    return Object.assign({}, filter, {
                        status: 'act',
                        location: null,
                        manufacturer: null,
                        category: null,
                        allRequirementsDue: false,
                        serial: null,
                        dueDateFrom: null,
                        dueDateTo: null,
                        requirementsDaysRange: null,
                        requirementType: null
                    });
                })));
            case 'EQUIPMENT_DOCUMENT_TYPE_SELECTED':
                if (action.id === 'equipmentDocumentsGrid') {
                    return Object.assign({}, state, {
                        equipmentDocumentsGrid: set(GridReducer(state.equipmentDocumentsGrid, set(action, 'type', 'PAGINATE')), 'filter.documentTypeId', action.documentType)
                    });
                }
            case 'EQUIPMENT_DOCUMENT_STATUS_SELECTED':
                if (action.id === 'equipmentDocumentsGrid') {
                    return Object.assign({}, state, {
                        equipmentDocumentsGrid: set(GridReducer(state.equipmentDocumentsGrid, set(action, 'type', 'PAGINATE')), 'filter.documentStatusId', action.status)
                    });
                }
            case 'GRID_SORT':
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return Object.assign({}, filter, {
                        sort: getGridSort(filter, action)
                    });
                })));
            case 'GRID_RESET':
                if (action.id === 'equipmentAlertsGrid') {
                    return Object.assign({}, state, {
                        equipmentAlertsGrid: update(GridReducer(state.equipmentAlertsGrid, action), 'filter', function(filter) {
                            return Object.assign({}, filter, {
                                alertType: null,
                                keyword: null,
                                equipmentId: null,
                                employeeUuid: null
                            });
                        })
                    });
                }
                if (action.id === 'equipmentDocumentsGrid') {
                    return Object.assign({}, state, {
                        equipmentDocumentsGrid: update(GridReducer(state.equipmentDocumentsGrid, action), 'filter', function(filter) {
                            return Object.assign({}, filter, {
                                documentStatusId: null,
                                documentTypeId: null
                            });
                        })
                    });
                }
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return Object.assign({}, omit(filter, 'sort'), {
                        location: null,
                        manufacturer: null,
                        category: null,
                        allRequirementsDue: false,
                        serial: null,
                        dueDateFrom: null,
                        dueDateTo: null,
                        requirementsDaysRange: null,
                        requirementType: null
                    });
                })));
            case 'GRID_RESET_FILTER':
                if (action.id === 'equipmentAlertsGrid') {
                    return Object.assign({}, state, {
                        equipmentAlertsGrid: GridReducer(state.equipmentAlertsGrid, action)
                    });
                }
            case 'SHOW_LOADING':
            case 'PAGINATE':
                if (action.id === 'equipmentAlertsGrid') {
                    return Object.assign({}, state, {
                        equipmentAlertsGrid: GridReducer(state.equipmentAlertsGrid, action)
                    });
                }
                if (action.id === 'equipmentDocumentsGrid') {
                    return Object.assign({}, state, {
                        equipmentDocumentsGrid: GridReducer(state.equipmentDocumentsGrid, action)
                    });
                }
                return Object.assign({}, state, set({}, action.id, GridReducer(state[action.id], action)));
            case 'GRID_RELOAD':
            case 'GRID_FETCH':
                if (action.id === 'equipmentAlertsGrid') {
                    return Object.assign({}, state, {
                        equipmentAlertsGrid: GridReducer(state.equipmentAlertsGrid, action)
                    });
                }
                if (action.id === 'equipmentDocumentsGrid') {
                    return Object.assign({}, state, {
                        equipmentDocumentsGrid: GridReducer(state.equipmentDocumentsGrid, action)
                    });
                }
                return Object.assign({}, state, set({}, action.id, Object.assign({}, GridReducer(state[action.id], action), {
                    activeCount: action.activeCount,
                    archiveCount: action.archiveCount
                })));
            case 'EQUIPMENT_DOCUMENT_FILE_MANAGEMENT':
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'GRID_FILE_MANAGEMENT'}), 'fileManagement', action.fileManagement)));
            case 'EQUIPMENT_WARRANTY_EXPIRY_DATE':
                return {
                    ...state,
                    ...(action.id === 'equAssetCost' && {
                        equAssetCost: {
                            ...state.equAssetCost,
                            warranty_exp_date: action.date,
                        }
                    }),
                };
            case 'EQUIPMENT_DATA':
                return {
                    ...state,
                    equAssetCost: {
                        ...state.equAssetCost,
                        ...action.data.equAssetCost,
                    },
                };
            case 'EQUIPMENT_RESET':
                return {
                    ...state,
                    ...initialState,
                };
            default:
                return state;
        }
    }
    else return state;
}
