import * as echarts from 'echarts';
import { chart1 } from './chart1';
import { chart2 } from './chart2';
import { chart3 } from './chart3';
import { chart4 } from './chart4';

const CHARTS = {
    'chart1': chart1,
    'chart2': chart2,
    'chart3': chart3,
    'chart4': chart4,
};

export const dashboardAnalyticsChart = {
    bindings: {
        id: '@',
        data: '<'
    },
    /* @ngInject */
    controller: function($timeout) {
        /**
         * @function $postLink
         */
        this.$onInit = () => {
            // TODO
            $timeout(() => {
                this.chart && this.chart.dispose();
                this.chart = echarts.init(
                    document.getElementById(this.id),
                    {},
                    {  width: 400, height: 400 });
                this.chart.setOption(CHARTS[this.id]);
            }, 0);
        }

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            // TODO
        };
    },
    template: `
        <div id="{{$ctrl.id}}" layout="row" layout-align="center center"></div>
    `,
};
