/* @ngInject */
export function AccDiasMasterDocList($scope, $rootScope, Notifier,
                              AccountService, AccreditationDocumentsService, ACC_ACCREDITATION_CODE, $mdDialog,
                              $timeout, DOCUMENT_TYPE, DocumentManager, AuthService, SUBJECT_TYPE, AccreditationService,$filter) {

    //todo, find out somehow here which accreditation is this (DIAS, DENTAL)

    $scope.cursorState = 'wait';

    $scope.currentDocPage = 1;
    $scope.pageSize = 10;
    $scope.sortArrow = 'keyboard_arrow_down';

    $scope.SORT_OPTIONS = {
        DOC_DCR: 'docDcrNumber',
        DOC_NUMBER: 'docNumber',
        DOC_NAME: 'title',
        DOC_VERSION: 'version',
        DOC_AUTHOR: 'writtenBy',
        DOC_ISSUE_DATE: 'dateEdited',
        DOC_APPROVED_BY: 'approvedBy',
        DOC_APPROVAL_DATE: 'dateApproved',
        DOC_VALID_UNTIL: 'validUntil'
    };

    $scope.showFilterSection = false;

    var ctrl = this;
    ctrl.accreditationSchemaCode = AccreditationService.getCurrentAccreditationFromState();

    ctrl.selectedUserParentLocation = ""; //uuid
    ctrl.selectedUserParentLocationName = ""; //location name
    ctrl.masterDocumentsList = [];

    ctrl.sortBy = $scope.SORT_OPTIONS.DOC_DCR;
    ctrl.sortReverse = false;

    ctrl.filter = {
        documentName: null,
        author: null,
        approvedBy: null,
        expireAfter: null
    };

	var user = AuthService.getUser();

    ctrl.resetFilter = function () {
        ctrl.filter = {
            documentName: null,
            author: null,
            approvedBy: null,
            expireAfter: null
        };
        $scope.filterDate = null;
    };

    ctrl.filterList = function () {
        ctrl.masterDocumentsList = [];
        $timeout(
            function () {
                ctrl.fetchData();
            }
        );
    };

    ctrl.fetchData = function () {
        $scope.cursorState = 'wait';
        AccreditationDocumentsService.fetchAccQCMasterListDocuments(ctrl.accreditationSchemaCode, ctrl.selectedUserParentLocation,
            ctrl.filter, false)
            .then(function (response) {
                $scope.cursorState = 'default';
                if ((response.status >= 200) && (response.status < 300) &&
                    (response.data.result != null)) {
                    ctrl.masterDocumentsList = response.data.result;
                } else if ((response.status >= 500) && (response.status < 600)) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.MASTER_LIST.NOTIFY_INTERNAL_SERVER_ERROR'));
                } else if ((response.status >= 400) && (response.status < 500)) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.MASTER_LIST.NOTIFY_PAGE_ERROR'));
                } else if ((response.data == null) || (response.data.result == null)) {
                    //No data for this item
                }
            }, function (error) {
                $scope.cursorState = 'default';
                if ((typeof error === 'number') && (error >= 500) && (error < 600)) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.MASTER_LIST.NOTIFY_INTERNAL_SERVER_ERROR'));
                } else if ((typeof error === 'number') && (error >= 400) && (error < 500)) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.MASTER_LIST.NOTIFY_PAGE_ERROR'));
                } else {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.MASTER_LIST.NOTIFY_ERROR_FETCHING_LIST'));
                }
            });
    };

    ctrl.downloadPdfList = function () {
        $scope.cursorState = 'wait';
        AccreditationDocumentsService.fetchAccQCMasterListDocuments(ctrl.accreditationSchemaCode,
            ctrl.selectedUserParentLocation, ctrl.filter, true)
            .then(function (response) {
                $scope.cursorState = 'default';
                if ((response.status == 200) && (response.data.result != null)) {
                    DocumentManager.downloadFile("/"+response.data.result, DOCUMENT_TYPE.ACCREDITATION_DOCUMENT);
                } else if (response.status == 400 && typeof response.data == 'string') {
                    Notifier.error(response.data);
                } else if (response.status == 403) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.MASTER_LIST.NOTIFY_ERROR_FETCHING_LIST'));
                } else if (response.status == 404) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.MASTER_LIST.NOTIFY_DOC_NOT_FOUND'));
                } else if (response.status == 500) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.MASTER_LIST.NOTIFY_SERVER_ERROR'));
                } else if (response.status != 200) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.MASTER_LIST.NOTIFY_ERROR_FETCHING_LIST_MASTER'));
                }
            }, function (error) {
                $scope.cursorState = 'default';
                Notifier.error("Error fetching master documents list!" + error.data)
            });
    };

    ctrl.resetList = function () {
        ctrl.resetFilter();
        ctrl.fetchData();
    };

    $scope.$on('LOCATION_SUBJECT_SELECTED', function (event, locationItem) {
        if (locationItem.in_subject_basic_type_code === "slo") {
            $scope.cursorState = 'default';
            ctrl.selectedUserParentLocation = locationItem.in_subject_uuid;
            ctrl.selectedUserParentLocationName = locationItem.in_username_par;
        }
    });

    $scope.$watch('accDiasMasterDocList.sortReverse', function (value) {
        if (value) {
            $scope.sortArrow = 'keyboard_arrow_up';
        } else {
            $scope.sortArrow = 'keyboard_arrow_down';
        }
    });

    $scope.$watch('accDiasMasterDocList.selectedUserParentLocation', function () {
        $scope.cursorState = 'wait';
        if (ctrl.selectedUserParentLocation != "") {
            ctrl.fetchData();
        }
    });

    ctrl.setSort = function (sortColumn) {
        if (sortColumn === ctrl.sortBy) {
            ctrl.sortReverse = !ctrl.sortReverse;
        } else {
            ctrl.sortBy = sortColumn;
        }

        console.log(ctrl.sortBy);
    };

    ctrl.addDocument = function () {

        $mdDialog.show({
            controller: 'AccDiasUploadDocumentCtrl',
            template: require('../../../views/accreditation-upload-document-dialog.html'),
            // scope: scope, // use parent scope in template
            // preserveScope: true,
            locals: {
                color: '#a934e4',
                title: $filter('translate')('ACCREDITATION.TABS.MASTER_LIST.TITLE_UPLOAD'),
                locationUuid: ctrl.selectedUserParentLocation,
                locationName: ctrl.selectedUserParentLocationName,
                templateDocument: false,
                token: null
            },
            scope: $scope.$new(),
            preserveScope: false,
            clickOutsideToClose: false
        });
    };

    $scope.$on('acc-document-uploaded', function (events, args) {
        ctrl.fetchData();
    });

    ctrl.downloadFile = function (item) {
        $timeout(
            function () {
                DocumentManager.downloadFile('/'+item.docPath, DOCUMENT_TYPE.ACCREDITATION_DOCUMENT);
            }
        );
    };

    function DocumentAsApprovedDialog($scope, $mdDialog, color, title, accDocument, slocUuid) {
        $scope.headerColor = color;
        $scope.headerTitle = title;
        $scope.accDocumentItem = accDocument;
        $scope.slocUuid = slocUuid;

        $scope.cancel = function () {
            $scope.accDocumentItem.approvedBy = null;
            $scope.accDocumentItem.dateApproved = null;
            $mdDialog.cancel();
        };

        $scope.submit = function () {

            var documentItemData = {};
            if ($scope.accDocumentItem.approvedBy != null)
                documentItemData.approvedBy = $scope.accDocumentItem.approvedBy;
            if ($scope.accDocumentItem.dateApproved != null)
                documentItemData.dateApproved = $scope.accDocumentItem.dateApproved;

            AccreditationDocumentsService.editAccreditationDocument($scope.slocUuid, $scope.accDocumentItem.id, documentItemData)
                .then(function (response) {
                    if ((response.status == 200) && (response.data.result != null)) {
                        //all ok
                    } else if (response.status == 400 && typeof response.data == 'string') {
                        Notifier.error(response.data);
                    } else if (response.status == 403) {
                        Notifier.error($filter('translate')('ACCREDITATION.TABS.MASTER_LIST.NOTIFY_ERROR_FETCHING_LIST'));
                    } else if (response.status == 404) {
                        Notifier.error($filter('translate')('ACCREDITATION.TABS.MASTER_LIST.NOTIFY_ACCESS_ERROR'));
                    } else if (response.status == 500) {
                        Notifier.error($filter('translate')('ACCREDITATION.TABS.MASTER_LIST.NOTIFY_DOC_NOT_FOUND'));
                    } else if (response.status != 200) {
                        Notifier.error($filter('translate')('ACCREDITATION.TABS.MASTER_LIST.NOTIFY_ERROR_FETCHING_LIST_MASTER'));
                    }

                    $timeout(
                        function () {
                            ctrl.fetchData();
                        }
                    );

                    $mdDialog.hide();

                }, function (error) {
                    Notifier.error("Error updating accreditation document: " + error.data);
                    $timeout(
                        function () {
                            ctrl.fetchData();
                        }
                    );
                    $mdDialog.hide();
                });

        };
    }

    ctrl.approveItem = function (item) {

        $mdDialog.show({
            controller: DocumentAsApprovedDialog,
            template: require('../../../views/accreditation/accreditation-qcd-set-approved-dialog.html'),
            scope: $scope, // use parent scope in template
            preserveScope: true,
            locals: {
                color: '#a934e4',
                title: $filter('translate')('ACCREDITATION.TABS.MASTER_LIST.TITLE_APPROVAL_STATUS'),
                accDocument: item,
                slocUuid: ctrl.selectedUserParentLocation
            },
            clickOutsideToClose: false
        });
    };

    //Load data for parent site location if user belongs to only one site location
    if (user && (user.parentCount === 1) && (user.parents[user.parents.length-1])
        && (user.parents[user.parents.length-1].basicType == SUBJECT_TYPE.SITE_LOCATION)) {

        ctrl.selectedUserParentLocation = user.parents[user.parents.length-1].uuid;
        ctrl.selectedUserParentLocationName = user.parents[user.parents.length-1].name;
        ctrl.fetchData();
    }
}
