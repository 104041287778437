import { GridController, gridTpl } from '../../../common/grid/grid.controller';
import {GRID_FILTER_TOGGLE} from '../../../common/grid/grid.constants';
import { IDS } from './acc-documents-action-id.constants';
import * as Actions from './acc-documents.actions';

class AccDocumentsGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, AuthService, $filter, $mdDialog, $scope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
        this.AuthService = AuthService;
    }

    $onInit() {
        this.module = 'accreditation';
        this.identifier = IDS.accDocuments;
        this.statePath = IDS.accDocumentsGrid;
        this.filterOpen = true;
        this.filterExpandButtonVisible = false;
        this.exportComponent = false;
        this.confirmDialogOnRemove = true;
        this.autoload = false;
        this.defaultTitle = this.$filter('translate')('ACCREDITATION.ACC_DOCUMENTS.LIST.TAB');
        this.title = this.defaultTitle;
        // this.header = false;

        this.isAdmin = this.AuthService.getUser().type === 'Administrator';

        this.columnsDefault = [
            {title: '#',                                                            mapper: null},
            {title: this.$filter('translate')(`COMMON.DOCUMENT.NUMBER`),            mapper: 'doc_num'},
            {title: this.$filter('translate')(`COMMON.FIELD.TYPE`),                 mapper: 'typeColumn'},
            {title: 'Location',                                                     mapper: 'location'},
            {title: this.$filter('translate')(`COMMON.FIELD.NAME`),                 mapper: 'name'},
            {title: this.$filter('translate')(`COMMON.DATE.ISSUE_DATE`),            mapper: 'doc_date'},
            {title: this.$filter('translate')(`COMMON.DATE.DUE_DATE`),              mapper: 'due_date'},
            {title: this.$filter('translate')(`ACCREDITATION.COMMON.STANDARDS`),    mapper: 'standardsColumn'},
        ];
        this.columns = this.columnsDefault.concat();

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), Actions)(this);
        this.unsubscribeCustom = this.$ngRedux.connect(state => {
            // const accDocumentsGrid = state.accreditation.accDocuments.accDocumentsGrid;
            const sectionDetails = state.accreditation.accDocuments.sectionDetails;
            return {
                title: sectionDetails?.nodeName || this.defaultTitle,
                // selectedHeadOfficeUuid:   accDocumentsGrid.selectedHeadOfficeUuid,
                // overrideColumns:          accDocumentsGrid.override.columns
            }
        }, {})(this);

        this.dataAction = this.fetchAccDocumentsGrid;

        this.buttons =[ {
                class: 'btnGridAction',
                label: 'Download',
                callback: event => {
                    this.onDownload(event)
                }
            }];
        this.init();

        this.buttonsDefault = this.buttons.concat();
    }

    showAllColumns() {
        this.columns = this.columnsDefault.concat();
    }

    $onDestroy() {
        this.destroy();
        if(this.unsubscribeCustom)
            this.unsubscribeCustom();
    }
}

AccDocumentsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'AuthService', '$filter', '$mdDialog', '$scope'];

export const accDocumentsGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onDownload: '&',
        // onRemove: '&',
        // onDetach: '&',
    },
    controller: AccDocumentsGridController,
    ...gridTpl
}
