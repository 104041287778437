/* @ngInject */
export function AccDiasQCDocsTrainingMatrix($scope, Notifier, AuthService, AccountService,
                                    AccreditationDocumentsService, ACC_ACCREDITATION_CODE, $mdDialog, DocumentManager,
                                    SafetyPlanService, AccreditationService, $filter) {

    $scope.cursorState = 'wait';
    $scope.hideTable = false;
    $scope.currentTrmPage = 1;
    $scope.currentDocPage = 1;
    $scope.pageSize = 10;

    var ctrl = this;
    ctrl.accreditationSchemaCode = AccreditationService.getCurrentAccreditationFromState();

    ctrl.user = {};
    ctrl.selectedRscParentLocation = "";
    ctrl.selectedRscParentLocationName = "";
    ctrl.selectedRscParentLocationTrainingMatrices = [];
    $scope.reviewField = "rspp";

    ctrl.selectedTrainingMatrixItem = null;

    ctrl.selectItem = function (item) {
        ctrl.selectedTrainingMatrixItem = item;
    };

    function GenerateTrainingMatrix($scope, $mdDialog, color, title, trainingMatrix, slocUuid, reviewField) {
        $scope.headerColor = color;
        $scope.headerTitle = title;
        $scope.trainingMatrixItem = trainingMatrix;
        $scope.slocUuid = slocUuid;
        $scope.reviewField = reviewField;

        var currentYear = new Date().getFullYear();
        $scope.matrixYears = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3];
        $scope.pdfMatrixYear = currentYear;

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.submit = function (selectedYear) {
            var pdfTrainingMatrix = null;

            //Get RSPP training Matrix
            if (($scope.trainingMatrixItem != null) &&
                ($scope.trainingMatrixItem.name != null)) {

                $mdDialog.hide();

                SafetyPlanService.getTrainingMatrix($scope.reviewField, $scope.slocUuid,  new Date().getFullYear(), null, true)
                    .then(function (response) {
                        if (response.data.result) {
                            DocumentManager.downloadFile("/" + response.data.result, 3);
                        } else {

                        }
                    }, function (error) {
                        Notifier.error($filter('translate')('ACCREDITATION.TABS.TRAINING_MATRIX.NOTIFY_GENERATE_ERROR'));
                    });
            }
        };
    };

    ctrl.onGenerateTrainingMatrixPdf = function (item) {
        $mdDialog.show({
            controller: GenerateTrainingMatrix,
            template: require('../../../views/accreditation/quality-controll-documents/accreditation-generate-training-matrix-dialog.html'),
            scope:  $scope, // use parent scope in template
            preserveScope: true,
            locals: {
                color: '#a934e4',
                title: $filter('translate')('ACCREDITATION.TABS.TRAINING_MATRIX.TITLE_DOWNLOAD_PDF'),
                trainingMatrix: item,
                slocUuid: ctrl.selectedRscParentLocation,
                reviewField: $scope.reviewField
            },
            clickOutsideToClose: true
        });
    };

    $scope.$watch('accDiasTrainingMatrix.selectedRscParentLocation', function () {
        if (ctrl.selectedRscParentLocation != "") {

            ctrl.selectedTrainingMatrixItem = null;

            //here comes magic
            AccreditationDocumentsService.fetchAccQCDocsTrainingMatrices(ctrl.accreditationSchemaCode,
                ctrl.selectedRscParentLocation)
                .then(function (response) {
                    if ((response.status >= 200) && (response.status < 300) &&
                        (response.data.result != null)) {
                        //training matrix data successfully acquired
                        ctrl.selectedRscParentLocationTrainingMatrices = response.data.result;

                        //Fetch documents for those training matrices
                        ctrl.selectedRscParentLocationTrainingMatrices.forEach(function (trmItem) {

                            AccreditationDocumentsService.fetchAccQCTrainingMatrixDocuments(ctrl.accreditationSchemaCode,
                                ctrl.selectedRscParentLocation, trmItem.id)
                                .then(function (response) {
                                    if ((response.status >= 200) && (response.status < 300) &&
                                        (response.data.result != null)) {
                                        trmItem.documents = response.data.result;
                                    }
                                    else if ((response.status >= 500) && (response.status < 600)) {
                                        Notifier.error($filter('translate')('ACCREDITATION.TABS.TRAINING_MATRIX.NOTIFY_INTERNAL_SERVER_ERROR'));
                                    } else if ((response.status >= 400) && (response.status < 500)) {
                                        Notifier.error($filter('translate')('ACCREDITATION.TABS.TRAINING_MATRIX.NOTIFY_PAGE_ERROR'));
                                    } else if ((response.data == null) || (response.data.result == null)) {
                                        //No data for this item
                                    }

                                });
                        });


                    } else if ((response.status >= 500) && (response.status < 600)) {
                        Notifier.error($filter('translate')('ACCREDITATION.TABS.TRAINING_MATRIX.NOTIFY_INTERNAL_SERVER_ERROR'));
                    } else if ((response.status >= 400) && (response.status < 500)) {
                        Notifier.error($filter('translate')('ACCREDITATION.TABS.TRAINING_MATRIX.NOTIFY_PAGE_ERROR'));
                    } else if ((response.data == null) || (response.data.result == null)) {
                        //No data for this item
                    }
                }, function (error) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.TRAINING_MATRIX.NOTIFY_FETCH_ERROR'));
                });
        }
    });

    $scope.$on('LOCATION_SUBJECT_SELECTED', function (event, locationItem) {
        $scope.cursorState = 'default';
        ctrl.selectedRscParentLocation = locationItem.in_subject_uuid;
        ctrl.selectedRscParentLocationName = locationItem.in_username_par;
    });

    var user = AuthService.getUser();
    if (user.parentCount === 1) { //select current default location
        ctrl.selectedRscParentLocation = user.parents[user.parents.length - 1].uuid;
    } else {
        $scope.cursorState = 'default';
    }
}
