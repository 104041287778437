import { reportingState, reportingDashboardState } from './reporting.states';
import { reportingEquipmentInfoState, reportingEquipmentInfoListingState, reportingEquipmentInfoDetailsState } from './equipment-info/reporting-equipment-info.states';
import { reportingEquipmentInfoServiceDetailsState, reportingEquipmentInfoServiceHistoryState } from './equipment-info/reporting-equipment-info.states';
import { reportingEquipmentInfoInspectionsState, reportingEquipmentInfoHistoryState } from './equipment-info/reporting-equipment-info.states';
import { reportingEquipmentHistoryState, reportingEquipmentHistoryListingState } from './equipment-history/reporting-equipment-history.states';
import { reportingEquipmentInspectionState, reportingEquipmentInspectionListingState } from './equipment-inspection/reporting-equipment-inspection.states';
import { reportingEquipmentSummaryState, reportingEquipmentSummaryListingState } from './equipment-summary/reporting-equipment-summary.states';
import { reportingHelpState } from './help/reporting-help.states';
import { reportingCnscState, reportingCnscListingState } from './cnsc/reporting-cnsc.states';
import { reportingIsotopesActivityState, reportingIsotopesActivityListingState } from './isotopes-activity/reporting-isotopes-activity.states';
import { reporting } from './reporting.component';
import { reportingEquipmentInfo } from './equipment-info/reporting-equipment-info.component';
import { reportingEquipmentInfoGrid } from './equipment-info/grid/reporting-equipment-info-grid.component';
import { reportingEquipmentInfoDetails } from './equipment-info/details/reporting-equipment-info-details.component';
import { reportingEquipmentInfoServiceDetails } from './equipment-info/service-details/reporting-equipment-info-service-details.component';
import { reportingEquipmentInfoServiceHistory } from './equipment-info/service-history/reporting-equipment-info-service-history.component';
import { reportingEquipmentInfoServiceHistoryGrid } from './equipment-info/service-history/grid/reporting-equipment-info-service-history-grid.component';
import { reportingEquipmentInfoInspections } from './equipment-info/inspections/reporting-equipment-info-inspections.component';
import { reportingEquipmentInfoInspectionsGrid } from './equipment-info/inspections/grid/reporting-equipment-info-inspections-grid.component';
import { reportingEquipmentInfoHistory } from './equipment-info/history/reporting-equipment-info-history.component';
import { reportingEquipmentInfoHistoryGrid } from './equipment-info/history/grid/reporting-equipment-info-history-grid.component';
import { reportingEquipmentHistory } from './equipment-history/reporting-equipment-history.component';
import { reportingEquipmentHistoryGrid } from './equipment-history/grid/reporting-equipment-history-grid.component';
import { reportingEquipmentInspection } from './equipment-inspection/reporting-equipment-inspection.component';
import { reportingEquipmentInspectionGrid } from './equipment-inspection/grid/reporting-equipment-inspection-grid.component';
import { reportingEquipmentSummary } from './equipment-summary/reporting-equipment-summary.component';
import { reportingEquipmentSummaryGrid } from './equipment-summary/grid/reporting-equipment-summary-grid.component';
import { selectReportingDateRange } from './equipment-inspection/form/select-reporting-date-range/select-reporting-date-range.component';
import { reportingCnsc } from './cnsc/reporting-cnsc.component';
import { reportingCnscGrid } from './cnsc/grid/reporting-cnsc-grid.component';
import { reportingIsotopesActivity } from './isotopes-activity/reporting-isotopes-activity.component';
import { reportingIsotopesActivityGrid } from './isotopes-activity/grid/reporting-isotopes-activity-grid.component';
import ReportingActions from './reporting.actions';

import { REP_MONTHLY_MODULE } from './monthly/rep-monthly.module';

export const REPORTING_MODULE = angular.module(
    'migration.reporting',
    [
        REP_MONTHLY_MODULE.name,
    ]
).config(['$uiRouterProvider', $uiRouter => {
    const $stateRegistry = $uiRouter.stateRegistry;
    $stateRegistry.register(reportingState);
    $stateRegistry.register(reportingDashboardState);
    $stateRegistry.register(reportingEquipmentInfoState);
    $stateRegistry.register(reportingEquipmentInfoListingState);
    $stateRegistry.register(reportingEquipmentInfoDetailsState);
    $stateRegistry.register(reportingEquipmentInfoServiceDetailsState);
    $stateRegistry.register(reportingEquipmentInfoServiceHistoryState);
    $stateRegistry.register(reportingEquipmentInfoInspectionsState);
    $stateRegistry.register(reportingEquipmentInfoHistoryState);
    $stateRegistry.register(reportingEquipmentHistoryState);
    $stateRegistry.register(reportingEquipmentHistoryListingState);
    $stateRegistry.register(reportingEquipmentInspectionState);
    $stateRegistry.register(reportingEquipmentInspectionListingState);
    $stateRegistry.register(reportingEquipmentSummaryState);
    $stateRegistry.register(reportingEquipmentSummaryListingState);
    $stateRegistry.register(reportingHelpState);
    $stateRegistry.register(reportingCnscState);
    $stateRegistry.register(reportingCnscListingState);
    $stateRegistry.register(reportingIsotopesActivityState);
    $stateRegistry.register(reportingIsotopesActivityListingState);
}]).service({
    ReportingActions
}).component({
    reporting,
    reportingEquipmentInfo,
    reportingEquipmentInfoGrid,
    reportingEquipmentInfoDetails,
    reportingEquipmentInfoServiceDetails,
    reportingEquipmentInfoServiceHistory,
    reportingEquipmentInfoServiceHistoryGrid,
    reportingEquipmentInfoInspections,
    reportingEquipmentInfoInspectionsGrid,
    reportingEquipmentInfoHistory,
    reportingEquipmentInfoHistoryGrid,
    reportingEquipmentHistory,
    reportingEquipmentHistoryGrid,
    reportingEquipmentInspection,
    reportingEquipmentInspectionGrid,
    reportingEquipmentSummary,
    reportingEquipmentSummaryGrid,
    selectReportingDateRange,
    reportingCnsc,
    reportingCnscGrid,
    reportingIsotopesActivity,
    reportingIsotopesActivityGrid
}).run(['$templateCache', $templateCache => {
    $templateCache.put('app.reporting.equipmentInfo.listing', '<reporting-equipment-info></reporting-equipment-info>');
    $templateCache.put('app.reporting.equipmentInfo.details', '<reporting-equipment-info-details></reporting-equipment-info-details>');
    $templateCache.put('app.reporting.equipmentInfo.serviceDetails', '<reporting-equipment-info-service-details></reporting-equipment-info-service-details>');
    $templateCache.put('app.reporting.equipmentInfo.serviceHistory', '<reporting-equipment-info-service-history></reporting-equipment-info-service-history>');
    $templateCache.put('app.reporting.equipmentInfo.inspections', '<reporting-equipment-info-inspections></reporting-equipment-info-inspections>');
    $templateCache.put('app.reporting.equipmentInfo.history', '<reporting-equipment-info-history></reporting-equipment-info-history>');
    $templateCache.put('app.reporting.equipmentType.listing', '<reporting-equipment-history></reporting-equipment-history>');
    $templateCache.put('app.reporting.inspection.listing', '<reporting-equipment-inspection></reporting-equipment-inspection>');
    $templateCache.put('app.reporting.summary.listing', '<reporting-equipment-summary></reporting-equipment-summary>');
    $templateCache.put('app.reporting.cnsc.listing', '<reporting-cnsc></reporting-cnsc>');
    $templateCache.put('app.reporting.isotopesActivity.listing', '<reporting-isotopes-activity></reporting-isotopes-activity>');
    $templateCache.put('./reporting-equipment-summary-grid-filter.html', require('./equipment-summary/grid/reporting-equipment-summary-grid-filter.html'));
    $templateCache.put('./reporting-equipment-inspection-grid-filter.html', require('./equipment-inspection/grid/reporting-equipment-inspection-grid-filter.html'));
    $templateCache.put('./reporting-equipment-info-grid-filter.html', require('./equipment-info/grid/reporting-equipment-info-grid-filter.html'));
    $templateCache.put('./reporting-equipment-history-grid-filter.html', require('./equipment-history/grid/reporting-equipment-history-grid-filter.html'));
    $templateCache.put('./reporting-cnsc-grid-filter.html', require('./cnsc/grid/reporting-cnsc-grid-filter.html'));
    $templateCache.put('./reporting-isotopes-activity-grid-filter.html', require('./isotopes-activity/grid/reporting-isotopes-activity-grid-filter.html'));
}]);
