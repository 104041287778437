import moment from 'moment';
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';

/* @ngInject */
export function EquipmentUltrasoundAddController($controller, $ngRedux, $scope, $rootScope, $state, $uiRouterGlobals, $q, $location, $timeout, API_BASE, NavigationService, EquipmentService, EquipmentServiceUltrasound, EquipmentManager, VendorService, AuthService, VendorGridFilter, ManufacturerService, Ultrasound, Manufacturer, Notifier, EQUIPMENT_TYPE, EQUIPMENT_TYPE_CODE) {

    this.unsubscribe = $ngRedux.connect(({ equipment }) => ({
        equAssetCost: equipment.equAssetCost,
    }), {})(this);

    angular.extend(this, $controller('EquipmentBaseController', {
        $scope: $scope,
        $state: $state,
        $location: $location,
        $timeout: $timeout,
        NavigationService: NavigationService,
        EquipmentManager: EquipmentManager,
        VendorService: VendorService,
        Notifier: Notifier,
        VendorGridFilter: VendorGridFilter,
        EquipmentService: EquipmentService
    }));

    $scope.MODULE_STATE = $scope.MODULE_STATE_CREATE;
    $scope.EQUIPMENT_STATE = $scope.MODULE_STATE_CREATE;

    $scope.templateCertification = './equipment-certification.html';
    $scope.templateRegistration = './equipment-registration.html';

    $scope.tabs = EquipmentManager.getEquipmentAddTabs();
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]).content = './equipment-ultrasound-add.html';

    $scope.equipment = new Ultrasound();
    $scope.equipment.model = null;

    $scope.replacableEquipment = [];

    EquipmentManager.approvalToAcquire();

    $scope.userData = AuthService.getUser();
    $scope.headOfficeName = $scope.userData.hofName;

    if ($uiRouterGlobals.params.replace && $uiRouterGlobals.params.transaction) {
        $scope.replaceEquipment = true;
        $rootScope.toolbarTitle = "Ultrasound | REPLACE EQUIPMENT";

        EquipmentServiceUltrasound.getEquipmentDetails($uiRouterGlobals.params.replace).then(function(item) {
            $scope.locations = item.locations;
            $scope.replacableItem = item.equipment;
            $scope.replacableItem.transaction_id = $uiRouterGlobals.params.transaction;
            $scope.equipment.category = item.equipment.category;
            $scope.categories = [item.equipment.category];
        });
    }
    else {
        $scope.replaceEquipment = false;
        EquipmentServiceUltrasound.getCategories().then(function(items) {
            $scope.categories = items;
        });
    }

    EquipmentServiceUltrasound.fetchNextId(EQUIPMENT_TYPE.ultrasound).then(function(data) {
        $scope.equipment.user_friendly_id = data;
    });

    ManufacturerService.fetchManufacturersByEquipmentType(EQUIPMENT_TYPE_CODE.ultrasound).then(function(items) {
        $scope.manufacturers = items;
    });

    EquipmentManager.getEquipmentServices().then(function(data) {
        $scope.equipment.services = data;
    });

    $scope.$on('LOCATION_SUBJECT_SELECTED', function(event, locationItem) {
        $scope.equipment.location.uuid = locationItem.uuid;
        $scope.equipment.account_id = locationItem.in_account_id;
        if (locationItem.in_subject_basic_type_code == 'slo') {
            $scope.equipment.account.lspn = locationItem.in_lspn;
        }
        if (locationItem.in_subject_basic_type_code == 'hof') {
            $scope.equipment.account.lspn = null;
        }

        EquipmentService.fetchEquipmentFlags(locationItem.uuid, EQUIPMENT_TYPE_CODE.XRay).then(function(flags) {
            if (flags.has_csd)
                $scope.equipment.capitalSensitivityExpiryDateShow = true;
            else
                $scope.equipment.capitalSensitivityExpiryDateShow = false;
        });
    });

    $scope.categorySelected = function() {
        $scope.replacableItem = null;
        const { id } = $scope.equipment.category;
        if ( id ) {
            EquipmentServiceUltrasound.fetchReplaceableEquipment({
                account: null,
                category: { id },
                offset: 0,
                limit: 100
            }, true).then(function (result) {
                $scope.replacableEquipment = result.data.list;
            });
        }

        $scope.$broadcast('RESET_EQUIPMENT_MODEL', $scope.equipment.type.id);
        $scope.equipment.manufacturer = new Manufacturer();
    };

    $scope.manufacturerSelected = function() {
        ManufacturerService.fetchEquipmentModels($scope.equipment.manufacturer.id, $scope.equipment.category.id).then(function(items) {
            $scope.models = items;
        });
    };

    $scope.createEquipmentModel = function(equipment) {
        EquipmentManager.createNewModel(equipment);
    };

    $scope.$on(EquipmentManager.CREATE_NEW_MODEL_COMPLETE, function(event, model) {

        $scope.models.push(model);
        $scope.equipment.model = model;
    });

    $scope.showReplacableItemDetails = function(item) {
        EquipmentManager.showDetails(item);
    };

    _.extend(_.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]), {
        exec: (function() {
            var deferred = $q.defer();
            switch ($scope.EQUIPMENT_STATE) {
                case $scope.MODULE_STATE_CREATE:
                    if (_.get($scope.replacableItem, 'transaction_id', null)) {
                        $scope.equipment.transaction_id = $scope.replacableItem.transaction_id;
                    }
                    EquipmentServiceUltrasound.addEquipment($scope.equipment).then((function(id) {
                        $scope.equipment.equipment_id = id;
                        EquipmentManager.getEquipmentServices(id).then(function(data) {
                            $scope.equipment.services = data;
                        });
                        $scope.EQUIPMENT_STATE = $scope.MODULE_STATE_UPDATE;
                        this.enableTabs();
                        Notifier.success('New piece of equipment was successfully added');
                        deferred.resolve();
                    }).bind(this), function(error) {
                        deferred.reject(error);
                    });
                    break;
                case $scope.MODULE_STATE_UPDATE:
                    EquipmentServiceUltrasound.updateEquipment($scope.equipment).then(function() {
                        Notifier.success('Equipment details successfully updated');
                        deferred.resolve();
                    }, function(error) {
                        deferred.reject(error);
                    });
                    break;
            }
            return deferred.promise;
        }).bind(this)
    });

    /**
     * ASSET COST
     */
    $scope.icon = 'ultrasound.svg';
    $scope.color = 'colorUltrasound';
    $scope.bgColor = 'ultrasound';
    $scope.btnColor = 'btnColorUltrasound';
    $scope.depreciation_type = this.equAssetCost.depreciation_type;

    $scope.initAssetCostForm = (form) => {
        $scope.equAssetCostForm = form;
    };

    $scope.setWarrantyExpiryDate = () => {
        const { purchaseDate, warranty_period } = this.equAssetCost;
        if (
            moment.isDate(purchaseDate) &&
            isNumber(warranty_period) &&
            !isNaN(warranty_period)
        ) {
            $ngRedux.dispatch({
                type: 'EQUIPMENT_WARRANTY_EXPIRY_DATE',
                id: 'equAssetCost',
                date: moment(purchaseDate).add(warranty_period, 'months').toDate(),
            });
        }
    };

    $scope.onDepreciationType = () => {
        $scope.depreciation_type = this.equAssetCost.depreciation_type;
    };

    $scope.genDepreciationReport = () => {
        if ($scope.equAssetCostForm.$valid) {
            if (this.equAssetCost.depreciation_type) {
                Notifier.info('Please wait...', false);
                EquipmentService.generateDepreciationReport(
                    'uso',
                    $scope.equipment.equipment_id,
                    $scope.equipment.user_friendly_id,
                )
                .then(() => Notifier.success('Success'))
                .catch(() => Notifier.error('System Error!'));
            } else {
                Notifier.error('Please save asset cost');
            }
        } else {
            Notifier.error('Please fill in the form fields');
        }
    };

    $scope.saveAssetCost = () => {
        if ($scope.equAssetCostForm.$valid) {
            if ($scope.equipment.equipment_id) {
                if (this.equAssetCost.estimated_residual_value < this.equAssetCost.assetCost) {
                    EquipmentServiceUltrasound.updateEquipment($scope.equipment).then(() => {
                        Notifier.success('Asset cost has been successfully saved');
                    });
                } else {
                    Notifier.error('The estimated residual value cannot be higher than the cost of the asset');
                }
            }
        } else {
            Notifier.error('Please fill in the form fields');
        }
    };

    $scope.$on('$destroy', () => {
        $ngRedux.dispatch({
            type: 'EQUIPMENT_RESET',
            id: 'equAssetCost'
        });
        this.unsubscribe();
    });
}
