import { GridController, gridTpl } from '../../../common/grid/grid.controller';
import { EXPORT_FORMAT } from '../../../common/utils/util.constants';
import { IDS } from '../accreditation-action-id.constants'
import * as AccreditationActions from '../accreditation.actions';
import watch from "redux-watch";
import {APPROVAL_USERS} from "../accreditation.constants";

class TasksGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter, $mdDialog, $scope, $rootScope, AuthService) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.AuthService = AuthService;
    }

    $onInit() {
        this.identifier = IDS.accTasksGrid;
        this.module = 'accreditation';
        this.filterTemplate = './tasks-grid-filter.html';
        this.title = this.$filter('translate')('ACCREDITATION.TASKS.TITLE');

        this.columns = [
            {title: '#', mapper: null},
            {title: this.$filter('translate')('COMMON.FIELD.TASK_NUMBER'),      mapper: 'task_number'},
            {title: this.$filter('translate')('COMMON.FIELD.TYPE'),             mapper: 'utaTypeName'},
            {title: this.$filter('translate')('ACCREDITATION.COMMON.SCHEME'),   mapper: 'acc_code'},
            {title: this.$filter('translate')('COMMON.FIELD.ASSIGNEE'),         mapper: 'assignee'},
            // {title: this.$filter('translate')('COMMON.LOGIN.USERNAME'),         mapper: 'username'},
            {title: this.$filter('translate')('ACCREDITATION.COMMON.STANDARD'), mapper: 'standard_name'},
            {title: this.$filter('translate')('COMMON.DATE.DUE_DATE'),          mapper: 'due_date'},
            {title: this.$filter('translate')('COMMON.FIELD.STATUS'),           mapper: 'status_name'},
        ];
        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), AccreditationActions)(this);
        const columnsDefault = this.columns.concat();
        this.unsubscribeCustom = this.$ngRedux.connect(state => {
            const isApprovalUser = APPROVAL_USERS.includes(state.app.user.typeCode);
            if(!isApprovalUser) {
                const columns = columnsDefault.filter(column => !['utaTypeName', 'assignee', 'username'].includes(column.mapper));
                columns.splice(4,0,{title: this.$filter('translate')('ACCREDITATION.COMMON.DOCUMENT'), mapper: 'description'});
                return {
                    columns,
                    filter: {...this.filter,
                        task_type:'utaccr',
                        task_status: 'isall',
                    }
                }
            }
            else {
                return {
                    filterActions: {...this.filterActions, isApprovalUser},
                }
            }
        }, {})(this);
        const $rootScope = this.$rootScope;
        const watchReset = watch(this.$ngRedux.getState, 'accreditation.accTasksGrid.reset');
        this.unsubscribeOnReset = this.$ngRedux.subscribe(watchReset(function() {
            $rootScope.$broadcast('SELECT_CASCADE_RESET', 'accreditationTasksFilterForm');
        }));

        this.filter.identifier = this.identifier;
        this.dataAction = this.fetchTasksGrid;
        this.exportService = this.fetchTasksGrid;
        this.exportFormats = [EXPORT_FORMAT.find(item => item.format === 'pdf')];

        this.buttons.unshift({
            class: 'btnGridModify',
            label: 'Open',
            callback: this.onSelect
        });

        this.buttons.unshift({
            class: 'btnGridInfo',
            label: 'Link',
            callback: this.onLinkDialog
        });
        this.fetchTasksGridSchemes().then( () => {
            this.init();
        });
    }

    $onDestroy() {
        if(this.unsubscribeCustom)
            this.unsubscribeCustom();

        if(this.unsubscribeOnReset)
            this.unsubscribeOnReset();

        this.destroy();
    }

}

TasksGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter', '$mdDialog', '$scope', '$rootScope'];

export const accTasksGrid = {
    bindings: {
        identifier: '<',
        onSelect: '&',
        onLinkDialog: '&',
        onReset: '&',
        isApprovalUser: '<'
    },
    controller: TasksGridController,
    ...gridTpl
}
