import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';

/**
 * Creates a new InputUsernameController.
 * @class
 * @extends InputController
 */
class InputUsernameController {
    /**
     * @constructor
     * @param $ngRedux
     */
    constructor($ngRedux, $q, UserManagementService) {
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.UserManagementService = UserManagementService;
        this.unsubscribe = () => {};
    }
    $onInit() {
        if (isUndefined(this.name))
            throw new Error('Missing input name attribute');
        if (isUndefined(this.identifier))
            throw new Error('Missing input ' + this.name + ' identifier attribute');
        if (isUndefined(this.label))
            this.label = 'Username';
        if (isUndefined(this.disable))
            this.disable = false;

        this.options = (typeof this.debounce === 'undefined') ? {} : { debounce: this.debounce };

        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        if (this.statePath) this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);
    }

    $postLink() {
        let username = null;
        this.form[this.name].$asyncValidators.unique = (modelValue) => {
            const def = this.$q.defer();
            if (username !== modelValue && !this.disable) {
                this.UserManagementService.isUsernameAvailable(modelValue).then(() => {
                    username = modelValue;
                    this.$ngRedux.dispatch({
                        type: 'VALIDATE_USERNAME',
                        id: this.identifier,
                        path: this.statePath,
                        username: modelValue,
                        valid: true
                    });
                    def.resolve();
                }, () => {
                    username = modelValue;
                    this.$ngRedux.dispatch({
                        type: 'VALIDATE_USERNAME',
                        id: this.identifier,
                        path: this.statePath,
                        username: modelValue,
                        valid: false
                    });
                    def.reject();
                });
            }
            return def.promise;
        };
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeModule();
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        };
    }

    mapState(state) {
        return {
            model: get(state[this.module][this.identifier], this.statePath)
        };
    }
}

InputUsernameController.$inject = ['$ngRedux', '$q', 'UserManagementService'];

export const inputUsername = {
    bindings: {
        identifier: '@',
        statePath: '@',
        name: '@',
        label: '@',
        debounce: '@',
        required: '<',
        disable: '<',
        model: '=?'
    },
    require: {
        form: '^form'
    },
    controller: InputUsernameController,
    template: `
        <md-input-container class="md-block" flex>
            <label>{{$ctrl.label}}</label>
            <input name="{{$ctrl.name}}" ng-model="$ctrl.model" ng-required="$ctrl.required" ng-disabled="$ctrl.disable" ng-pattern="/^([\.a-z 0-9_-])+$/i" ng-model-options="$ctrl.options">

            <div ng-messages="$ctrl.form[$ctrl.name].$error">
                <div ng-message="required">{{'COMMON.FIELD_REQUIRED' | translate}}</div>
                <div ng-message="pattern">{{'COMMON.USERNAME.ALLOWED_CHARS_ERROR' | translate}}</div>
                <div ng-message="unique">{{'COMMON.USERNAME.UNIQUE_NAME_ERROR' | translate}}</div>
            </div>
            <div ng-messages="$ctrl.form[$ctrl.name].$pending">
                <div ng-message="unique">{{'COMMON.USERNAME.ALLOWED_CHARS_ERROR' | translate}}</div>
            </div>
        </md-input-container>

    `
};