/* @ngInject */
export function PersonalEquipmentFactory(Location, PersonalEquipmentType, PersonalBodyProtection, PersonalLeadEquivalence) {
    return function PersonalEquipment() {

        this.id = null;
        this.location = new Location();
        this.locationList = [];
        this.type = new PersonalEquipmentType();
        this.bodyProtection = new PersonalBodyProtection();
        this.leadEquivalence = new PersonalLeadEquivalence();
        this.description = null;
        this.equipmentId = null;
        this.barcode = null;
        this.images = [];
        this.tests = [];
        
        this.addImage = function(image) {
            this.images.push(_.cloneDeep(image));
        };
        
        this.getImages = function() {
            return this.images.length ? this.images.map(function(img) {
                return img.id;
            }).join() : null;
        };
        
        this.addTest = function(test) {
            this.tests.push(_.cloneDeep(test));
        };
    };
}
