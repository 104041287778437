import watch from 'redux-watch';

const DASHBOARD = 'app.dashboard';
const ANALYTICS = 'app.analytics';

const ICON_DASHBOARD = 'dashboard';
const ICON_ANALYTICS = 'query_stats';

export const dashboardSwitch = {
    /* @ngInject */
    controller: function($ngRedux, $state) {
        this.icon = ICON_ANALYTICS;

        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(({ navigation }) => ({
                layout: navigation.state,
            }), {})(this);

            const watchLayout = watch($ngRedux.getState, 'navigation.state');
            this.onLayoutChange = $ngRedux.subscribe(watchLayout((state) => {
                this.layout = state;
                this.icon = state === DASHBOARD ? ICON_ANALYTICS : ICON_DASHBOARD;
            }));
        }

        this.onSwitch = () => {
            $state.go(this.layout === DASHBOARD ? ANALYTICS : DASHBOARD);
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            this.unsubscribe();
            this.onLayoutChange();
        };
    },
    template: `
        <md-button class="md-icon-button dashboard-switch" aria-label="Dashboard Switch" ng-click="$ctrl.onSwitch()">
            <md-icon md-font-set="material-icons">{{$ctrl.icon}}</md-icon>  
        </md-button>
    `,
};
