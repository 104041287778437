import * as TYPE from '../../cre-vendors.actions.types';
import {
    getVendorAccSchemes,
    getVendorAccStandardDetails,
    saveVendorAccStandards,
    saveVendorAccScheme,
    deleteVendorAccStandard,
} from '../../cre-vendors.actions';
import { formatDate } from '../../../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../../../common/utils/util.constants';
import findIndex from 'lodash/findIndex';
import isNull from 'lodash/isNull';

/**
 * @const {object}
 * @memberOf module:Credentialing
 */
export const creVendorsAccStd = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $location, $mdDialog, DocumentManager, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.title = 'Accreditation/Standards Information';
            this.unsubscribe = $ngRedux.connect(({ credentialing }) => ({
                creVendor: credentialing.creVendor,
            }), {
                getVendorAccSchemes,
                getVendorAccStandardDetails,
                saveVendorAccStandards,
                saveVendorAccScheme,
                deleteVendorAccStandard,
            })(this);
            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.accreditationStandards`;
            this.token = $location.search().token;

            this.getVendorAccSchemes({ token: this.token });
        };

        this.onCreateAccScheme = () => {
            this.dialog = $mdDialog.show({
                /* @ngInject */
                controller: ($scope) => {
                    $scope.save = () => {
                        if ($scope.dialogForm.$valid) {
                            this.saveVendorAccScheme({
                                token: this.token,
                                for_assessment_only: 0,
                                name: this.creVendor.acc_scheme_name,
                            }).then(() => {
                                this.getVendorAccSchemes({ token: this.token });
                                $mdDialog.hide();
                            });
                        }
                    };
                    $scope.cancel = () => $mdDialog.cancel();
                },
                template: require('./dialogs/cre-vendors-acc-scheme-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
            }).then(accSchemeReset, accSchemeReset);
        };

        const accSchemeReset = () => {
            $ngRedux.dispatch({
                type: TYPE.CRE_VENDOR_ACC_SCHEME_RESET,
                id: 'creVendor',
            });
        };

        this.onDocDownload = ({ doc_url }) => {
            DocumentManager.downloadFile(doc_url, 4);
        };

        this.onDocRemove = (doc) => {
            $ngRedux.dispatch({
                type: TYPE.CRE_VENDOR_DOC_REMOVE,
                id: 'creVendor',
                path: 'acc_docs',
                idx: findIndex(this.creVendor.acc_docs, doc),
            });
        };

        this.onSelect = ({ item }) => {
            this.getVendorAccStandardDetails({
                token: this.token,
                caa_id: item.caa_id,
            });
        };

        this.onReset = () => {
            $ngRedux.dispatch({
                type: TYPE.CRE_VENDOR_ACC_STANDARD_RESET,
                id: 'creVendor',
            });
            this.creVendorsAccStdForm.$setPristine();
            this.creVendorsAccStdForm.$setUntouched();
        };

        this.onRemove = ({ item }) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen Accreditation Standard will be permanently removed.')
                .ariaLabel('Remove Accreditation Standard')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.deleteVendorAccStandard({
                    token: this.token,
                    caa_id: item.caa_id,
            });
            }, angular.noop);
        };

        this.onSave = () => {
            if (this.creVendorsAccStdForm.$valid) {
                this.saveVendorAccStandards({
                    token: this.token,
                    application_id: this.creVendor.application_id,
                    is_standards_applicable: 1,
                    caa_id: this.creVendor.acc_id,
                    asc_id: this.creVendor.acc_scheme,
                    next_audit_date: formatDate(this.creVendor.acc_std_next_audit_date, DATE_FORMAT.OUTPUT),
                    acc_docs: this.creVendor.acc_docs,
                }).then(() => {
                    isNull(this.creVendor.acc_id) && this.onReset();
                    Notifier.success('Accreditation/Standards Information has been successfully saved');
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
        };

        this.$onDestroy = () => {
            this.onReset();
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        };
    },
    template: require('./cre-vendors-acc-std.html')
};
