import { API_BASE, DATE_FORMAT } from '../../../common/utils/util.constants';
import { formatDate } from '../../../common/utils/util.date';
import isNull from 'lodash/isNull';

export default function SystemAlertsActions($http, Notifier) {
    function getAlerts(filter) {
        return function(dispatch) {
            const params = {
                module:         filter.moduleId,
                offset:         filter.offset,
                limit:          filter.limit,
                exportFormat:   filter.exportFormat,
                exportColumns:  filter.exportColumns
            };

            if (filter.alertType)
                params.type = filter.alertType;
            if (filter.keyword)
                params.keyword = filter.keyword;

            return $http({
                url: API_BASE + '/system/alerts',
                method: 'GET',
                params: params
            }).then(response => {
                if (filter.exportFormat) {
                    return response.data;
                }
                else dispatch({
                    type: 'GRID_FETCH',
                    id: filter.identifier,
                    items: response.data.items.map(item => {
                        const alert = {
                            alertType:          item.alert_type,
                            category:           item.subject_category,
                            location:           item.location,
                            docId:              item.doc_id,
                            docNum:             item.document_number,
                            docType:            item.document_type,
                            docDueDate:         formatDate(item.document_due_date, DATE_FORMAT.INPUT),
                            equipmentId:        item.equ_id,
                            equipmentUserId:    item.equipment_id,
                            equipmentSerial:    item.serial,
                            equipmentCategory:  item.category,
                            employeeUuid:       item.sub_uuid,
                            employeeFirstName:  isNull(item.first_name) ? '' : item.first_name,
                            employeeLastName:   isNull(item.last_name) ? '' : item.last_name,
                            employeeUsername:   item.username,
                            employeeOccupation: item.occupation
                        };
                        if (item.equ_id) {
                            alert.description = 'Equipment: ' + alert.equipmentCategory;
                        }
                        if (item.sub_uuid) {
                            alert.description = 'Employee: ' + alert.employeeFirstName + ' ' + alert.employeeLastName;
                        }
                        return alert;
                    }),
                    itemsCount: response.data.total
                });
            })
        }
    }

    return {
        getAlerts
    }
}

SystemAlertsActions.$inject = ['$http', 'Notifier'];
