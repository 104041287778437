/* @ngInject */
export function RequirementsDueController($ngRedux, EquipmentActions) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, EquipmentActions)(this);
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    function mapStateToThis(state) {
        return {
            allRequirementsDue: state.equipment[ctrl.identifier].filter.allRequirementsDue,
            requirementType: !!(state.equipment[ctrl.identifier].filter.requirementType)
        };
    }
}
