/* @ngInject */
export function ProposalRequestFactory(Location) {
    return function ProposalRequest() {
        this.id = null;
        this.uuid = null;
        this.account = new Location();
        this.deadline = null;
        this.sentAt = null;
        this.vendors = [];
        this.equipment = [];
        this.proposalStatus = null;
        this.proposalPurpose = null;
    };
}
