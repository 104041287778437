import { resolveTpl } from '../personal-dosimetry.utils';

class PersonalDosimetryImportController {
    /* @ngInject */
    constructor($ngRedux, $transitions, $timeout, PersonalDosimetryActions, Notifier, $filter) {
        this.$ngRedux = $ngRedux;
        this.$transitions = $transitions;
        this.$timeout = $timeout;
        this.PersonalDosimetryActions = PersonalDosimetryActions;
        this.Notifier = Notifier;
        this.$filter = $filter;
        this.template = resolveTpl('pd-import');
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(({ personalDosimetry }) => ({
            provider_code: personalDosimetry.personalDosimetryImport.provider,
            dose_unit: personalDosimetry.personalDosimetryImport.dose_unit,
        }), this.PersonalDosimetryActions)(this);
        this.getReportProviders('personalDosimetry');
        this.onBeforeExit = this.$transitions.onBefore({
            exiting: 'app.personalDosimetry.import.document'
        }, () => {
            if (this.importForm) {
                this.importForm.$setPristine();
                this.importForm.$setUntouched();
            }
        });
    }

    onSave(event) {
        this.Notifier.info(this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.IMPORT_REPORT_DOCUMENT.NOTIFIER_WAIT'));
        this.importDoseReport(this.provider_code, this.dose_unit, event).then(() => {
            this.$ngRedux.dispatch({
                type: 'TAB_ENABLE',
                id: 'app.personalDosimetry.import',
                state: ['app.personalDosimetry.import.details']
            });
            this.$timeout(() => {
                this.$ngRedux.dispatch({
                    type: 'TAB_SELECT',
                    id: 'app.personalDosimetry.import',
                    state: 'app.personalDosimetry.import.details',
                    stateParams: {}
                });
            }, 200);
        }, error => {
            this.Notifier.responseMessage(error);
        });
    }

    $onDestroy() {
        this.unsubscribe();
        this.onBeforeExit();
    }
}

export const personalDosimetryImport = {
    controller: PersonalDosimetryImportController,
    template: '<div ng-include src="$ctrl.template"></div>'
};
