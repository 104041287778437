import has from 'lodash/has';
import indexOf from 'lodash/indexOf';
import isFunction from 'lodash/isFunction';

/**
 * Creates a new GridListController.
 * @class
 */
class GridListController {
    constructor($ngRedux, $filter) {
        this.$ngRedux = $ngRedux;
        this.$filter = $filter;
        this.infoLoading = this.$filter('translate')('COMMON.GRID.LIST_PLEASE_WAIT');
        this.infoNoRecords = this.$filter('translate')('COMMON.GRID.LIST_NO_RECORDS');
    }

    $onInit() {
        if (this.message) this.info = this.message;
        this.colorName = this.navConfig.colorName;

        if (typeof this.icon === 'undefined' || this.icon === false)
            this.icon = this.navConfig.icon;

        this.paginationId = `${this.identifier}${this.statePath || ''}`
        this.initColspan();
    }

    $onChanges(changes) {
        if (has(changes, 'loading'))
            this.info = changes.loading.currentValue ? this.infoLoading : this.infoNoRecords;

        if (has(changes, 'columns') || has(changes, 'buttons'))
            this.initColspan();
    }

    getOrderNum(item) {
        return indexOf(this.items, item) + 1 + this.filter.offset;
    }

    sort(column) {
        if (column.hasOwnProperty('sort')) {
            this.$ngRedux.dispatch({
                type: 'GRID_SORT',
                id: this.identifier,
                path: this.statePath,
                sort: {
                    column: column.sort,
                    value: 'asc'
                }
            });
            this.$ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: this.identifier,
                path: this.statePath,
            });
        }
    }

    selectItem(item) {
        this.onSelect({
            $event: {
                item,
                idx: indexOf(this.items, item) + this.filter.offset
            }
        });
    }

    callbackItem(callback, item) {
        callback({
            $event: {
                item,
                idx: indexOf(this.items, item) + this.filter.offset
            }
        });
    }

    initColspan() {
        this.colspan = this.columns.length;
        if (this.buttons.length)
            this.colspan++;
    }

    getButtonClass(button, item) {
        if (has(button, 'class')) {
            if (isFunction(button.class)) {
                return button.class(item);
            }
            return button.class;
        }
        return '';
    }

    getButtonLabel(button, item) {
        if (has(button, 'label')) {
            if (isFunction(button.label)) {
                return button.label(item);
            }
            return button.label;
        }
        return '';
    }

    // refactor ASAP
    checkIfButtonAvailable(button, item) {
        if (this.identifier === "equipmentTechniqueChartsGrid") {
            if ((button.label === "Download") || (button.label === "Report")) {
                //Show download buttons only to those items with uploaded chart documents
                if ((item.optimizedChart != null) && (item.optimizedChart.docPath != null)) {
                    return true;
                } else {
                    return false;
                }
            }
        }

        if(button.filter)
            return button.filter(item);

        return true;
    }
}

GridListController.$inject = ['$ngRedux', '$filter'];

export const gridList = {
    bindings: {
        identifier: '<',
        statePath: '<',
        navConfig: '<',
        columns: '<',
        buttons: '<',
        items: '<',
        itemsCount: '<',
        loading: '<',
        filter: '<',
        onSelect: '&',
        icon: '<',
        message: '<'
    },
    controller: GridListController,
    template: require('./grid-list.html')
};
