import { GridController, gridTpl } from '../../../../common/grid/grid.controller';

class ReportingEquipmentInfoGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, ReportingActions, EquipmentService, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.ReportingActions = ReportingActions;
        this.EquipmentService = EquipmentService;
        this.grid_filter = $filter;
    }

    $onInit() {
        this.module = 'reporting';
        this.identifier = 'reportingEquipmentGrid';
        this.filterTemplate = './reporting-equipment-info-grid-filter.html';
        this.exportService = this.EquipmentService.getEquipment;

        this.columns = [
            {title: '#',                    mapper: null},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_INFO.GRID_COL_EQUIP_ID"),            mapper: 'user_friendly_id'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_INFO.GRID_COL_CATEGORY"),            mapper: 'category.name'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_INFO.GRID_COL_MANUFACTURER"),        mapper: 'manufacturer.name'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_INFO.GRID_COL_MODEL"),               mapper: 'model.name'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_INFO.GRID_COL_SERIAL_NO"),           mapper: 'serial'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_INFO.GRID_COL_SITE_LOCATION"),       mapper: 'account.name'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_INFO.GRID_COL_CERT_DUE_DATE"),       mapper: 'certificateDueDate'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_INFO.GRID_COL_CERT_NO"),             mapper: 'certificateNumber'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_INFO.GRID_COL_SERVICE_DUE_DATE"),    mapper: 'serviceDueDate'},
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.ReportingActions)(this);
        this.dataAction = this.getEquipment;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

ReportingEquipmentInfoGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'ReportingActions', 'EquipmentService', '$filter'];

export const reportingEquipmentInfoGrid = {
    bindings: {
        identifier: '@',
        onSelect: '&',
        onReset: '&'
    },
    controller: ReportingEquipmentInfoGridController,
    ...gridTpl
};
