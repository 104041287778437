import { API_BASE } from '../../utils/util.constants';

export class RecoveryService {
    constructor($http, $state) {
        this.$http = $http;
        this.$state = $state;
    }

    resetPasswordInit(credential) {
        return this.$http({
            url: API_BASE + '/password-recovery/init',
            method: 'POST',
            data: {credential: credential}
        }).then(response => {
            return parseInt(response.data.status);
        });
    };

    resetPasswordExec(password) {
        return this.$http({
            url: API_BASE + '/password-recovery/exec',
            method: 'POST',
            data: {
                token:      this.$state.params.token,
                password:   password
            }
        }).then(response => {
            return parseInt(response.data.status);
        });
    };

    validatePasswordToken(token) {
        return this.$http({
            url: API_BASE + '/password-recovery/validate',
            method: 'GET',
            params: {token: token}
        }).then(response => {
            return parseInt(response.data.status);
        });
    };
}

RecoveryService.$inject = ['$http', '$state'];
