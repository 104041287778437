class ReportingEquipmentInfoServiceDetailsController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this.mapState, {})(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    mapState(state) {
        return {
            equipment: state.reporting.reportingEquipmentDetails,
            service: state.reporting.reportingEquipmentServiceDetails
        }
    }
}

ReportingEquipmentInfoServiceDetailsController.$inject = ['$ngRedux'];

export const reportingEquipmentInfoServiceDetails = {
    controller: ReportingEquipmentInfoServiceDetailsController,
    template: require('./reporting-equipment-info-service-details.html')
};
