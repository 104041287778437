/* @ngInject */
export function AdministrationAddHeadOfficeController($scope, $rootScope, $document, $timeout, $location, HeadOffice, AccountService) {

    $rootScope.toolbarTitle = "Administration | ADD HEAD OFFICE";
    $rootScope.submenu = [];
    $rootScope.toolbarColor = "#795548";

    $rootScope.submenu = [
        {
            title: 'Accounts',
            icon: 'accounts.svg',
            color: '795548',
            url: 'administration/accounts'
        },
        {
            title: 'Global user management',
            icon: 'clients.svg',
            color: '795548',
            url: 'administration/clients'
        },
        {
            title: 'Add Main Account',
            icon: 'accounts.svg',
            color: '795548',
            url: 'administration/accounts/add'
        },
        {
            title: 'Add Site Location',
            icon: 'accounts.svg',
            color: '795548',
            url: 'administration/accounts/add'
        },
        {
            title: 'Manage Site Locations',
            icon: 'accounts.svg',
            color: '795548',
            url: 'administration/accounts/manage'
        }
    ];

    $rootScope.headOffice = new HeadOffice();

    $scope.addHeadOffice = function() {

        $scope.$broadcast('show-errors-check-validity');
        if ($scope.userForm.$invalid) { return; }

        AccountService.addHeadoffice($rootScope.headOffice).then(function(data) {
            var url = "#/administration/clients/";
            $(location).attr('href', url);
        });
    };
}
