/* @ngInject */
export function AccreditationDocumentsModuleController($scope, $rootScope, Notifier,
ACC_ACCREDITATION_CODE, AccreditationService, AuthService,
AccountService, $q, $stateParams, $timeout, $mdSidenav, SUBJECT_TYPE, EmployeeService, AccreditationDocumentsService, DocumentManager, DocumentService, DOCUMENT_TYPE, $filter) {

    $scope.treeViewOptions = {
        showIcon: true,
        expandOnClick: false
    };
    $scope.cursorState = 'wait';

    var ctrl = this;
    ctrl.accreditationSchemaCode = AccreditationService.getCurrentAccreditationFromState();
    ctrl.accStandards = {}; //Accreditation standards hierarchy
    ctrl.basicTree = []; //treeView representation of standard hierarchy
    ctrl.selectedStandard = null; //Currently selected standard in hierarchy or null if nothing is selected

    ctrl.user = {};
    ctrl.selectedUserParentLocation = ""; //uuid
    ctrl.selectedUserParentLocationName = ""; //location name
    ctrl.YEARS = [new Date().getFullYear() - 3,new Date().getFullYear() - 2, new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1, new Date().getFullYear() + 2];
    ctrl.year = new Date().getFullYear();

    ctrl.preselectedHomeLocation = void(0);
    ctrl.preselectedSiteLocation = void(0);

    var user = AuthService.getUser();

    //Find acc standard object in hierarchy based on id of standard in system or number, e.g. "1.2.1"
    //Return standard or null if it is not found
    ctrl.findStandardById = function findStandardById(id, hierarchy) {

        if (hierarchy == null) {
            return null;
        }

        try {
            for (var prop in hierarchy) {
                if (hierarchy.hasOwnProperty(prop)) {
                    if ((hierarchy[prop] != null) && (hierarchy[prop].standardId != null) &&
                        ((hierarchy[prop].standardId == id) || (hierarchy[prop].number == id))) {
                        return hierarchy[prop];
                    }
                    if ((hierarchy[prop].children != null) && (Object.keys(hierarchy[prop].children).length > 0)) {
                        var result = findStandardById(id, hierarchy[prop].children);
                        if (result != null) {
                            return result;
                        }
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }

        return null; //nothing is found
    };

    //Function to fetch standards for this accreditation
    ctrl.fetchAccShemaStandards = function (slocUuid) {
        return AccreditationService.fetchAccShemaStandards(ctrl.accreditationSchemaCode, slocUuid)
            .then(function (response) {
                if ((response.status >= 200) && (response.status < 300) &&
                    (response.data.standards != null)) {
                    ctrl.accStandards = response.data.standards;
                    ctrl.basicTree = AccreditationService.createAccreditationSchemaTree(ctrl.accStandards);
                    //Preselect standard if passed as url query parameter
                    if ($stateParams['standard'] != null) {
                        $timeout(
                            function () {
                                var std = ctrl.findStandardById($stateParams['standard'], ctrl.accStandards);
                                ctrl.selectNewStandard(std.standardId);
                            }
                        );
                    } else if ($stateParams['stdid'] != null) {
                        $timeout(
                            function () {
                                var std = ctrl.findStandardById($stateParams['stdid'], ctrl.accStandards);
                                ctrl.selectNewStandard(std.standardId);
                            }
                        );
                    }
                    return 0;
                } else if ((response.status >= 400) && (response.status < 600)) {
                    return $q.reject(response.status);
                } else if ((response.data == null) || (response.data.standards == null)) {
                    return $q.reject(null);
                }
            }, function (error) {
                return $q.reject(error);
            });
    };

    ctrl.fetchAccStandardData = function () {
        $scope.cursorState = 'wait';
        ctrl.fetchAccShemaStandards(ctrl.selectedUserParentLocation)
            .then(function (success) {
                ctrl.selectNewStandard(ctrl.selectedUserParentLocation);
                return ctrl.fetchAccStandardAssesmentItems(ctrl.selectedUserParentLocation, ctrl.year);
            }, function (error) {
                if ((typeof error === 'number') && (error >= 500) && (error < 600)) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_FETCH_SCHEMA_ERROR'));
                } else if ((typeof error === 'number') && (error >= 400) && (error < 500)) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_PAGE_NOT_FOUND'));
                } else {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_FETCH_DATA_ERROR'));
                }
            })
            .then(function (success) {
                $scope.cursorState = 'default';
                return 0;
            }, function (error) {
                $scope.cursorState = 'default';
                if ((typeof error === 'number') && (error >= 500) && (error < 600)) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_FETCH_SCHEMA_ERROR'));
                } else if ((typeof error === 'number') && (error >= 400) && (error < 500)) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_PAGE_NOT_FOUND'));
                } else {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_FETCH_DATA_ERROR'));
                }
            });
    };

    //Function to fetch assesments for this this accreditation schema and site location
    ctrl.fetchAccStandardAssesmentItems = function (slocUuid, year) {
        return AccreditationService.fetchAccShemaAssessments(ctrl.accreditationSchemaCode, slocUuid, year)
            .then(function (response) {
                if ((response.status >= 200) && (response.status < 300) &&
                    (response.data.assessments != null)) {

                    for (var assessment in response.data.assessments) {
                        if (response.data.assessments.hasOwnProperty(assessment)) {
                            var std = ctrl.findStandardById(response.data.assessments[assessment].standardId, ctrl.accStandards);
                            if (std != null) {
                                std.assessment = response.data.assessments[assessment];
                            }
                        }
                    }

                    return 0;
                } else if ((response.status >= 400) && (response.status < 600)) {
                    return $q.reject(response.status); //todo handle null
                } else if ((response.data == null) || (response.data.assessments == null)) {
                    return $q.reject(null);
                }
            }, function (error) {
                return $q.reject(error);
            });
    };

    //Changed selected standard based on new id
    //Return true if new item is selected, otherwise false
    ctrl.selectNewStandard = function (id) {
        if (id == null) {
            return false;
        }
        if ((ctrl.selectedStandard != null) && (ctrl.selectedStandard.standardId == id)) {
            return false;
        } else {
            ctrl.selectedStandard = ctrl.findStandardById(id, ctrl.accStandards);
            return true;
        }
    };

    //Callback from acc-standards-panel, that new standard is selected
    ctrl.newStandardSelected = function (id) {

        //deselect old node in tree view
        if ((ctrl.selectedStandard != null) && (ctrl.selectedStandard.standardId != null)) {
            var tmpNode = ctrl.findStandardById(ctrl.selectedStandard.standardId, ctrl.basicTree);
            if (tmpNode != null) {
                tmpNode.selected = false;
            }
        }

        //select new node
        var tmpNode = ctrl.findStandardById(id, ctrl.basicTree);
        if (tmpNode != null) {
            tmpNode.selected = true;
        }
    };

    //New standard/sub-standard/sub-sub-standard selected in treeview
    $scope.$on('selection-changed', function (e, node) {
        //node - selected node in tree

        //check if dirty, issue warning
        if ((ctrl.selectedStandard != null) && (ctrl.selectedStandard.standardId != null)
            && (ctrl.selectedStandard.assessment != null) && (ctrl.selectedStandard.assessment.dirty)) {

            $rootScope.confirmationDialog($filter('translate')('ACCREDITATION.TABS.DOCUMENTS.CONFIRM_UNSAVED'))
                .then(function (result) {
                    //Confirm, continue
                    ctrl.selectNewStandard(node.standardId);
                }, function (error) {
                    return;
                });
        } else {
            ctrl.selectNewStandard(node.standardId);
        }
    });

    //Standard expanded in tree view
    $scope.$on('expanded-state-changed', function (e, node) {
        // node - the node on which the expanded state changed
        // to see the current state check the expanded property
        //ctrl.selectNewStandard(node.standardId);
        //console.log("expanded-state-changed:"+node.name+" expanded:"+node.expanded);
    });

    $scope.$on('LOCATION_SUBJECT_SELECTED', function (event, locationItem) {

        if ((ctrl.preselectedHomeLocation) && (ctrl.preselectedSiteLocation)) {
            //Some default hof and site location are set when page was loaded
            //Here we are sure that select cascade hsa been initialized, and data loaded
            //Call force select parent on select cascade
            $rootScope.$broadcast('FORCE_SELECT_PARENT', 0, ctrl.preselectedHomeLocation, ctrl.preselectedSiteLocation);
            ctrl.preselectedHomeLocation = null;
            ctrl.preselectedSiteLocation = null;
            return;
        }

        if ((locationItem.in_subject_basic_type_code === "slo") &&
            (ctrl.selectedUserParentLocation !== locationItem.in_subject_uuid)) {
            $scope.cursorState = 'default';
            ctrl.selectedUserParentLocation = locationItem.in_subject_uuid;
            ctrl.selectedUserParentLocationName = locationItem.in_username_par;
        }
    });

    $scope.$watch('accDiasCtrl.selectedUserParentLocation', function () {
        if (ctrl.selectedUserParentLocation != "") {
            ctrl.fetchAccStandardData();
        }
    });

    $scope.$watch('accDiasCtrl.year', function (newYear, oldYear) {

        if ((ctrl.selectedUserParentLocation != "") && (ctrl.year != null)) {
            ctrl.fetchAccStandardData();
        }
    });


    // Side Navigation
    $scope.toggleLeft = buildDelayedToggler('left');

    function debounce(func, wait, context) {
        var timer;

        return function debounced() {
            var context = $scope,
                args = Array.prototype.slice.call(arguments);
            $timeout.cancel(timer);
            timer = $timeout(function() {
                timer = undefined;
                func.apply(context, args);
            }, wait || 10);
        };
    }

    function buildDelayedToggler(navID) {
        return debounce(function() {
            $mdSidenav(navID).toggle();
        }, 200);
    }

    $scope.closeSideNav = function() {
        $mdSidenav('left').close();
    };

    $scope.downloadDocuments = function() {
        $scope.cursorState = 'wait';

        var standardId = null;

        if (ctrl.selectedStandard) {
            standardId = ctrl.selectedStandard.standardId;
        }

        var unfulfilledStandardCount = null;

        AccreditationDocumentsService.checkAccDocuments(ctrl.accreditationSchemaCode, ctrl.selectedUserParentLocation, ctrl.year, standardId)
            .then(function (response) {
                unfulfilledStandardCount = response.data.unfulfilledStandardCount;

                if (unfulfilledStandardCount === 0) {
                    Notifier.info('Download archive is in progress!', true);
                    var filter = {
                        'accCode': ctrl.accreditationSchemaCode,
                        'year': ctrl.year,
                        'location': ctrl.selectedUserParentLocation,
                    };
                    DocumentService.fetchDocumentToken("ado", filter, null).then(function (response) {
                        $scope.disabled = true;
                        DocumentService.downloadZip(response.data.token).then(response => {
                            saveAs(new Blob([response.data], {type: "octet/stream"}), 'myrso-documents-'+Date.now()+'.zip');
                            Notifier.success('Download archive completed!');
                            $scope.disabled = false;
                        }, function (error) {
                            Notifier.error("Error downloading archive!");
                            $scope.disabled = false;
                        });
                    }, function (error) {
                        $scope.cursorState = 'default';
                        Notifier.error("Error fetching documents list! Archive is too big!");
                        $scope.disabled = false;
                    });
                } else {
                    $rootScope.confirmationDialog($filter('translate')('ACCREDITATION.TABS.DOCUMENTS.CONFIRM_MISSING'),
                                                    $filter('translate')('ACCREDITATION.TABS.DOCUMENTS.CONFIRM_YES'),
                                                    $filter('translate')('ACCREDITATION.TABS.DOCUMENTS.CONFIRM_NO')
                                                 )
                        .then(function () {
                            AccreditationDocumentsService.generateUnfulfilledAccDocuments(ctrl.accreditationSchemaCode, ctrl.selectedUserParentLocation, ctrl.year, standardId)
                                .then(function (response) {
                                    DocumentManager.downloadFile('/'+response.data.path, DOCUMENT_TYPE.ACCREDITATION_DOCUMENT);
                                }, function (error) {
                                    Notifier.error($filter('translate')('ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_ERROR_GENERATING'));
                                });
                        }, function (error) {
                            //Cancel
                        });
                }
            }, function (error) {
                $scope.cursorState = 'default';
                Notifier.error($filter('translate')('ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_ERROR_CHECKING_LIST'))
            });
    };

    if ($stateParams['sloc'] != null) {
        //Here, select appropriate site location in select cascade
        ctrl.preselectedSiteLocation=$stateParams['sloc'];
        EmployeeService.getEmploymentLocations(ctrl.preselectedSiteLocation)
            .then(function (result) {
                ctrl.preselectedHomeLocation = result[0].uuid;
            }, function (error){
                ctrl.preselectedHomeLocation = null;
            })
    }
    else if (user && (user.parentCount === 1) && (user.parents[user.parents.length - 1])
        && (user.parents[user.parents.length - 1].basicType == SUBJECT_TYPE.SITE_LOCATION)) {
        //Load data for parent site location if user belongs to only one site location
        ctrl.selectedUserParentLocation = user.parents[user.parents.length - 1].uuid;
        ctrl.selectedUserParentLocationName = user.parents[user.parents.length - 1].name;
        ctrl.fetchAccStandardData();
    }
}
