/* @ngInject */
export function ProposalService($http, API_BASE, ProposalRequest, Laser, VendorAgent, ProposalResponse) {

    this.openProposal = function() {
        return $http({
            //change this in the future (this 4 thingy)
            url: API_BASE + "/proposal/create/4",
            method: "POST"
        }).then(function(response) {
            return response.data.proposal;
        }, function(error) {
            return error;
        });
    };

    this.addVendor = function (proposalId, vendorUUID) {
        return $http({
            url: API_BASE + "/proposal/" + proposalId + "/vendor",
            method: "PUT",
            data: {
                vendorToken: vendorToken
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.addEquipment = function(proposalId, equipmentId) {
        return $http({
            url: API_BASE + "/proposal/" + proposalId + "/equipment",
            method: "PUT",
            data: {
                equipment: equipmentId
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.removeEquipment = function(proposalId, equipmentId) {
        return $http({
            url: API_BASE + "/proposal/" + proposalId + "/equipment/" + equipmentId,
            method: "DELETE",
            data: {
                equipment: equipmentId
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.removeVendor = function(proposalId, vendorUUID) {
        return $http({
            url: API_BASE + "/proposal/" + proposalId + "/vendor/" + vendorToken,
            method: "DELETE"
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.addProposalRequest = function(request) {
        return $http({
            url: API_BASE + "/proposal/" + request.id,
            method: "POST",
            data: {
                purpose: request.purpose.id,
                deadline: request.deadline,
                vendors: request.vendors,
                equipment: request.equipment
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.fetchProposalRequests = function() {
        return $http({
            url: API_BASE + "/proposals",
            method: "GET"
        }).then(function(data) {
            var arr = [];
            data.data.forEach(function(elem){
                var pp = new ProposalRequest();
                pp.id = elem.proposal_id;
                pp.vendor = elem.vendor;
                pp.deadline = (moment(elem.deadline).isValid())?moment(elem.deadline).format('DD-MM-YYYY'):'';
                pp.sentAt = moment(elem.sent_at).format('DD-MM-YYYY');
                pp.proposalStatus = elem.proposal_status;
                pp.proposalStatusCode = elem.proposal_status_code;
                pp.purpose = elem.purpose;
                arr.push(pp);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    this.fetchProposalResponsesByRequestId = function(id) {
        return $http({
            url: API_BASE + "/proposal/" + id + "/responses",
            method: "GET"
        }).then(function(data) {
            var arr = [];
            data.data.forEach(function(elem){
                var pr = new ProposalResponse();
                /*
                 category: null
                 comments: null
                 doc_uri: null
                 equ_id: null
                 equipment_id: null
                 equipment_serial: null
                 is_total: "1"
                 manufacturer: null
                 model: null
                 price: "100.00"
                 price_unit: "aud"
                 request_id: "2"
                 response_id: "1"
                 status: null
                 ven_uuid: "3e995e2f-a28b-11e5-bb66-5254a2010cde"
                 vendor_first_name: "Clode"
                 vendor_last_name: "Mone"
                 vendor_username: "cloderns"
                 */
                pr.equipment = {};
                pr.equipment.id = elem.equipment_id;
                pr.equipment.serial = elem.equipment_serial;
                pr.equipment.manufacturer = elem.manufacturer;
                pr.equipment.model = elem.model;
                pr.response_id = elem.response_id;
                pr.price = elem.price;
                pr.price_unit = elem.price_unit;
                pr.status = elem.status;
                pr.status_code = elem.status_code;
                pr.vendor = elem.vendor_username;
                pr.comments = elem.comments;
                pr.doc_uri = elem.doc_uri;
                pr.is_total = elem.is_total=="1";
                //
                //pr.is_total = pr.is_total;
                //pr.manufacturer
                //pr.id = elem.response_id;
                //pr.uuid = elem.id;
                //pr.account = elem.id;
                //pr.deadline = elem.id;
                //pr.vendor = elem.id;
                //pr.equipment = elem.id;
                //pr.proposalStatus = elem.id;
                //pr.proposalPurpose = elem.id;
                arr.push(pr);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    this.fetchProposalRequestVendors = function(requestID) {
        return $http({
            url: API_BASE + "/proposal/" + requestID + "/vendors",
            method: "GET"
        }).then(function(data) {
            var arr = [];
            data.data.forEach(function (elem) {
                var agent = new VendorAgent();
                agent.vendor.name = elem.vendor;
                agent.firstName = elem.first_name;
                agent.lastName = elem.last_name;
                agent.username = elem.agent;
                agent.phone = elem.phone;
                agent.email = elem.email;
              //  agent.response = [];
                agent.vendorToken = elem.vendorToken;
                agent.status = elem.status;
                arr.push(agent);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    this.fetchProposalRequestEquipment = function(requestID) {
        return $http({
            url: API_BASE + "/proposal/" + requestID + "/equipment",
            method: "GET"
        }).then(function(data) {
            var arr = [];
            data.data.forEach(function (elem) {
                var item = new Laser();
                item.id = elem.equ_id;
                item.equipment_id = elem.equipment_id;
                item.account.name = elem.account;
                item.manufacturer.name = elem.manufacturer;
                item.serial = elem.equ_serial;
                item.category.name = elem.category;
                item.model.name = elem.model;
                item.status = elem.equipment_status;
                arr.push(item);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    this.fetchProposalRequestTemplate = function(id) {
        return $http({
            url: API_BASE + "/proposal/" + id,
            method: "GET",
            headers:{
                Accept: "text/html"
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.acceptProposalResponse = function(response) {
        return $http({
            url: API_BASE + "/proposal/response/accept",
            method: "POST",
            data: {
                proposalId: response.response_id,
                documentURI: "/",
                comment: response.comment
            }
        }).then(function(data) {
            return response;
        }, function(error) {
            return error;
        });
    };

    this.scheduleDates = function (proposalId, dates) {
        return $http({
            url: API_BASE + "/proposal/" + proposalId + "/proposeSchedulingDate",
            method: "POST",
            data: {
                date1: dates[0],
                date2: dates[1],
                date3: dates[2]
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.rejectProposalResponse = function(response) {
        return $http({
            url: API_BASE + "/proposal/response/reject",
            method: "POST",
            data: {
                proposalId: response.response_id,
                comment: response.comment
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };
}
