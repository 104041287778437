/* @ngInject */
export function AdministrationActionsFactory($http, API_BASE, AccountService, SettingsService, VendorService, DepartmentService, SubjectTypesService, UserManagementService, UserService, LicenceService, Notifier) {
    return {
        getHeadOffices: function(filter) {
            return function(dispatch) {
                AccountService.getAccounts(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'administrationAccountsHeadOfficesGrid',
                        items: data.items,
                        itemsCount: data.total
                    });
                });
            };
        },
        getHeadOfficeDetails: function(uuid, dependencies) {
            return function(dispatch) {
                AccountService.fetchHeadOffice(uuid).then(function(data) {
                    dispatch({
                        type: 'HEAD_OFFICE_FETCH',
                        id: 'administrationAccountsHeadOffice',
                        data: data
                    });
                    if (dependencies) {
                        dispatch({
                            type: 'LOCATION_SELECTED',
                            id: 'administrationAccountsHeadOfficeContactsGrid',
                            hof: uuid
                        });
                        dispatch({
                            type: 'LOCATION_SELECTED',
                            id: 'administrationAccountsHeadOfficeLicencesGrid',
                            hof: uuid
                        });
                        dispatch({
                            type: 'LOCATION_SELECTED',
                            id: 'administrationAccountsEquipmentGrid',
                            uuid: uuid
                        });
                    }
                });
            };
        },
        createHeadOfficeDetails: function(headOffice) {
            return function(dispatch) {
                return AccountService.addHeadOffice(headOffice).then(function(data) {
                    Notifier.success('New head office successfully created');
                    dispatch({
                        type: 'HEAD_OFFICE_FETCH',
                        id: 'administrationAccountsHeadOffice',
                        data: _.assign({}, headOffice, {
                            id: data.id,
                            uuid: data.uuid,
                            created: data.created
                        })
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationAccountsHeadOfficesGrid'
                    });
                    dispatch({
                        type: 'LOCATION_SELECTED',
                        id: 'administrationAccountsHeadOfficeContactsGrid',
                        hof: data.uuid
                    });
                    dispatch({
                        type: 'LOCATION_SELECTED',
                        id: 'administrationAccountsEquipmentGrid',
                        uuid: data.uuid
                    });
                });
            };
        },
        updateHeadOfficeDetails: function(headOffice) {
            return function(dispatch) {
                AccountService.updateHeadoffice(headOffice).then(function() {
                    Notifier.success('Client data successfully updated');
                    dispatch({
                        type: 'HEAD_OFFICE_UPDATE',
                        id: 'administrationAccountsHeadOffice',
                        data: headOffice
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationAccountsHeadOfficesGrid'
                    });
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };
        },
        getRscList: function(filter) {
            return function(dispatch) {
                AccountService.getRscList(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: filter.identifier,
                        items: data.map(item => {
                            item.uuid = item.subject_uuid;
                            return item;
                        })
                    });
                });
            };
        },
        getRscCandidates: function(identifier, uuid, type) {
            return function(dispatch) {
                AccountService.fetchRscCandidates(uuid, type).then(function(data) {
                    dispatch({
                        type: 'RSC_CANDIDATES_FETCH',
                        id: identifier,
                        items: data
                    });
                });
            };
        },
        getRscDetails: function(identifier, uuid) {
            return function(dispatch) {
                UserService.fetchManagerDetails(uuid).then(function(data) {
                    dispatch({
                        type: 'RSC_FETCH',
                        id: identifier,
                        data: data
                    });
                });
            };
        },
        createRscDetails: function(rsc, parent, assistant, identifier, grid) {
            return function(dispatch) {
                AccountService.addRsc(rsc, parent, assistant).then(function(uuid) {
                    Notifier.success('New user have been saved into the system and a notification of his login details has been sent to him.');
                    dispatch({
                        type: 'RSC_FETCH',
                        id: identifier,
                        data: _.set(rsc, 'uuid', uuid)
                    });
                    dispatch({
                        type: 'PASSWORD_RESET',
                        id: identifier,
                        path: 'data.password',
                        uuid: uuid
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: grid
                    });
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };
        },
        updateRscDetails: function(identifier, rsc, grid) {
            return function(dispatch) {
                UserService.saveRsc(rsc).then(function() {
                    Notifier.success('Success');
                    dispatch({
                        type: 'RSC_UPDATE',
                        id: identifier,
                        data: rsc
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: grid
                    });
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };
        },
        assignRsc: function(rsc, location, identifier, grid) {
            return function(dispatch) {
                AccountService.assignRsc(rsc.uuid, location).then(function() {
                    Notifier.success('Success');
                    dispatch({
                        type: 'RSC_FETCH',
                        id: identifier,
                        data: rsc
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: grid
                    });
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };
        },
        removeRsc: function(uuid, location, identifier, grid) {
            return function(dispatch) {
                UserManagementService.setUserStatus(uuid, location, false).then(function() {
                    Notifier.success('Success');
                    dispatch({
                        type: 'RSC_REMOVE',
                        id: identifier
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: grid
                    });
                });
            };
        },
        getQualityManagers: function(filter) {
            return function(dispatch) {
                AccountService.getQualityManagers(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: filter.identifier,
                        items: data
                    });
                });
            };
        },
        getQualityManagerCandidates: function(location, identifier) {
            return function(dispatch) {
                AccountService.getQualityManagers({uuid: location, inLocation: 1}).then(function(data) {
                    dispatch({
                        type: 'QUALITY_MANAGER_CANDIDATES_FETCH',
                        id: identifier,
                        items: data
                    });
                });
            };
        },
        getQualityManagerDetails: function(identifier, uuid) {
            return function(dispatch) {
                UserService.fetchManagerDetails(uuid).then(function(data) {
                    dispatch({
                        type: 'QUALITY_MANAGER_FETCH',
                        id: identifier,
                        data: data
                    });
                });
            };
        },
        createQualityManagerDetails: function(qualityManager, identifier, grid) {
            return function(dispatch) {
                AccountService.addQualityManager(qualityManager).then(function(uuid) {
                    Notifier.success('New user have been saved into the system and a notification of his login details has been sent to him.');
                    dispatch({
                        type: 'QUALITY_MANAGER_FETCH',
                        id: identifier,
                        data: _.set(qualityManager, 'uuid', uuid)
                    });
                    dispatch({
                        type: 'PASSWORD_RESET',
                        id: identifier,
                        path: 'data.password',
                        uuid: uuid
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: grid
                    });
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };
        },
        updateQualityManagerDetails: function(identifier, qualityManager, grid) {
            return function(dispatch) {
                UserService.saveRsc(qualityManager).then(function() {
                    Notifier.success('Success');
                    dispatch({
                        type: 'QUALITY_MANAGER_UPDATE',
                        id: identifier,
                        data: qualityManager
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: grid
                    });
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };
        },
        assignQualityManager: function(qualityManager, identifier, grid) {
            return function(dispatch) {
                AccountService.assignQualityManager(qualityManager).then(function() {
                    Notifier.success('Success');
                    dispatch({
                        type: 'QUALITY_MANAGER_FETCH',
                        id: identifier,
                        data: qualityManager
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: grid
                    });
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };
        },
        rejectQualityManager: function(qualityManager, identifier, grid) {
            return function(dispatch) {
                AccountService.rejectQualityManager(qualityManager).then(function() {
                    Notifier.success('Success');
                    dispatch({
                        type: 'QUALITY_MANAGER_REMOVE',
                        id: identifier
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: grid
                    });
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };
        },
        getSiteLocations: function(filter) {
            return function(dispatch) {
                AccountService.getAccounts(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'administrationAccountsSiteLocationsGrid',
                        items: data.items,
                        itemsCount: data.total
                    });
                });
            };
        },
        getSiteLocationCenterTypes: function(identifier) {
            return function(dispatch) {
                AccountService.fetchCenterTypes().then(function(data) {
                    dispatch({
                        type: 'SITE_LOCATION_CENTER_TYPES',
                        id: identifier,
                        items: data
                    });
                });
            };
        },
        getSiteLocationDetails: function(uuid, dependencies) {
            return function(dispatch) {
                AccountService.fetchAccount(uuid).then(function(data) {
                    dispatch({
                        type: 'SITE_LOCATION_FETCH',
                        id: 'administrationAccountsSiteLocation',
                        data: data
                    });
                    if (dependencies) {
                        dispatch({
                            type: 'LOCATION_SELECTED',
                            id: 'administrationAccountsSiteLocationContactsGrid',
                            slo: uuid
                        });
                        dispatch({
                            type: 'LOCATION_SELECTED',
                            id: 'administrationAccountsSiteLocationLicencesGrid',
                            slo: uuid
                        });
                        dispatch({
                            type: 'LOCATION_SELECTED',
                            id: 'administrationAccountsEquipmentGrid',
                            uuid: uuid
                        });
                        dispatch({
                            type: 'LOCATION_SELECTED',
                            id: 'administrationAccountsEmployeesGrid',
                            uuid: uuid
                        });
                    }
                });
            };
        },
        createSiteLocationDetails: function(siteLocation) {
            return function(dispatch) {
                return AccountService.addSiteLocation(siteLocation).then(function(data) {
                    Notifier.success('New site location successfully created');
                    dispatch({
                        type: 'SITE_LOCATION_FETCH',
                        id: 'administrationAccountsSiteLocation',
                        data: _.assign({}, siteLocation, {
                            id: data.id,
                            uuid: data.uuid,
                            created: data.created
                        })
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationAccountsSiteLocationsGrid'
                    });
                    dispatch({
                        type: 'LOCATION_SELECTED',
                        id: 'administrationAccountsSiteLocationContactsGrid',
                        slo: data.uuid
                    });
                    dispatch({
                        type: 'LOCATION_SELECTED',
                        id: 'administrationAccountsSiteLocationLicencesGrid',
                        slo: data.uuid
                    });
                    dispatch({
                        type: 'LOCATION_SELECTED',
                        id: 'administrationAccountsEquipmentGrid',
                        uuid: data.uuid
                    });
                    dispatch({
                        type: 'LOCATION_SELECTED',
                        id: 'administrationAccountsEmployeesGrid',
                        uuid: data.uuid
                    });
                });
            };
        },
        updateSiteLocationDetails: function(siteLocation) {
            return function(dispatch) {
                AccountService.updateSiteLocation(siteLocation).then(function() {
                    Notifier.success('Location data successfully updated');
                    dispatch({
                        type: 'SITE_LOCATION_UPDATE',
                        id: 'administrationAccountsSiteLocation',
                        data: siteLocation
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationAccountsSiteLocationsGrid'
                    });
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };
        },
        getSiteLocationLicences: function(filter) {
            return function(dispatch) {
                LicenceService.subjectLicences(filter.uuid, 'loc', filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'administrationAccountsSiteLocationLicencesGrid',
                        items: data.items,
                        itemsCount: data.total
                    });
                });
            };
        },
        getSiteLocationLicenceTypes: function(location) {
            return function(dispatch) {
                LicenceService.getLicenceTypes(location, 'loc').then(function(data) {
                    dispatch({
                        type: 'SITE_LOCATION_LICENCE_TYPES',
                        id: 'administrationAccountsSiteLocationLicence',
                        items: data
                    });
                });
            };
        },
        getSiteLocationLicence: function(id) {
            return function(dispatch) {
                LicenceService.getLicence(id).then(function(data) {
                    dispatch({
                        type: 'SITE_LOCATION_LICENCE_FETCH',
                        id: 'administrationAccountsSiteLocationLicence',
                        data: data
                    });
                    dispatch({
                        type: 'TAB_LOADING',
                        id: 'app.administration.accounts.siteLocations',
                        state: 'app.administration.accounts.siteLocations.licenceDetails',
                        loading: false
                    });
                });
            }
        },
        createSiteLocationLicence: function(licence) {
            return function(dispatch) {
                LicenceService.addManagementLicence(licence).then(function(id) {
                    Notifier.success('New licence successfully saved');
                    dispatch({
                        type: 'SITE_LOCATION_LICENCE_FETCH',
                        id: 'administrationAccountsSiteLocationLicence',
                        data: _.set(licence, 'id', id)
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationAccountsSiteLocationLicencesGrid'
                    });
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };
        },
        updateSiteLocationLicence: function(licence) {
            return function(dispatch) {
                LicenceService.updateLicence(licence).then(function() {
                    Notifier.success('Licence data successfully updated');
                    dispatch({
                        type: 'SITE_LOCATION_LICENCE_UPDATE',
                        id: 'administrationAccountsSiteLocationLicence',
                        data: licence
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationAccountsSiteLocationLicencesGrid'
                    });
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            }
        },
        removeSiteLocationLicence: function(licence) {
            return function(dispatch) {
                Notifier.success('Licence was successfully removed');
                LicenceService.removeLicence(licence).then(function() {
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationAccountsSiteLocationLicencesGrid'
                    });
                });
            };
        },
        getLicenceSiteLocations: function(filter) {
            return function(dispatch) {
                LicenceService.getSiteLocationsForManagementLicence(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'administrationAccountsLicenceSiteLocationsGrid',
                        items: data.map(item => {
                            return {
                                id:                 item.sloUuid,
                                sloName:            item.sloName,
                                isSet:              item.isSet
                            };
                        })
                    });
                    var selectedItems = [];
                    data.map(item => {
                        if (item.isSet == 1) {
                            selectedItems.push(item.sloUuid);
                        }
                    });
                    dispatch({
                        type: 'GRID_SELECT_ALL',
                        id: 'administrationAccountsLicenceSiteLocationsGrid',
                        data: selectedItems
                    });
                });
            };
        },
        getDepartments: function(filter) {
            return function(dispatch) {
                AccountService.getAccounts(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'administrationAccountsDepartmentsGrid',
                        items: data.items,
                        itemsCount: data.total
                    });
                });
            };
        },
        getDepartmentTypes: function(identifier, uuid, preselectType) {
            return function(dispatch) {
                SubjectTypesService.fetchDepartmentTypes(uuid).then(function(data) {
                    if (!_.isUndefined(preselectType)) {
                        data.unshift(preselectType);
                    }
                    dispatch({
                        type: 'DEPARTMENT_TYPES',
                        id: identifier,
                        items: data
                    });
                });
            };
        },
        getDepartmentDetails: function(uuid, dependencies) {
            return function(dispatch) {
                DepartmentService.getDepartment(uuid).then(function(data) {
                    dispatch({
                        type: 'DEPARTMENT_FETCH',
                        id: 'administrationAccountsDepartment',
                        data: data
                    });
                    if (dependencies) {
                        dispatch({
                            type: 'LOCATION_SELECTED',
                            id: 'administrationAccountsDepartmentContactsGrid',
                            uuid: uuid
                        });
                        dispatch({
                            type: 'LOCATION_SELECTED',
                            id: 'administrationAccountsEquipmentGrid',
                            uuid: uuid
                        });
                        dispatch({
                            type: 'LOCATION_SELECTED',
                            id: 'administrationAccountsEmployeesGrid',
                            uuid: uuid
                        });
                    }
                });
            };
        },
        createDepartmentDetails: function(department) {
            return function(dispatch) {
                return DepartmentService.addDepartment(department).then(function(data) {
                    Notifier.success('New department successfully created');
                    dispatch({
                        type: 'DEPARTMENT_FETCH',
                        id: 'administrationAccountsDepartment',
                        data: _.assign({}, department, {
                            uuid: data.uuid,
                            accountId: data.id,
                            created: data.created
                        })
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationAccountsDepartmentsGrid'
                    });
                    dispatch({
                        type: 'LOCATION_SELECTED',
                        id: 'administrationAccountsDepartmentContactsGrid',
                        uuid: data.uuid
                    });
                    dispatch({
                        type: 'LOCATION_SELECTED',
                        id: 'administrationAccountsEquipmentGrid',
                        uuid: data.uuid
                    });
                    dispatch({
                        type: 'LOCATION_SELECTED',
                        id: 'administrationAccountsEmployeesGrid',
                        uuid: data.uuid
                    });
                });
            };
        },
        updateDepartmentDetails: function(department) {
            return function(dispatch) {
                DepartmentService.updateDepartment(department).then(function() {
                    Notifier.success('Department data successfully updated');
                    dispatch({
                        type: 'DEPARTMENT_UPDATE',
                        id: 'administrationAccountsDepartment',
                        data: department
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationAccountsDepartmentsGrid'
                    });
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };
        },
        getRooms: function(filter) {
            return function(dispatch) {
                AccountService.getAccounts(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'administrationAccountsRoomsGrid',
                        items: data.items,
                        itemsCount: data.total
                    });
                });
            };
        },
        getRoomDetails: function(uuid, dependencies) {
            return function(dispatch) {
                DepartmentService.getRoom(uuid).then(function(data) {
                    dispatch({
                        type: 'ROOM_FETCH',
                        id: 'administrationAccountsRoom',
                        data: data
                    });
                    if (dependencies) {
                        dispatch({
                            type: 'TAB_ENABLE',
                            id: 'app.administration.accounts.rooms',
                            state: [
                                'app.administration.accounts.rooms.details',
                                'app.administration.accounts.rooms.equipment'
                            ]
                        });
                        dispatch({
                            type: 'LOCATION_SELECTED',
                            id: 'administrationAccountsEquipmentGrid',
                            uuid: uuid
                        });
                    }
                });
            };
        },
        createRoomDetails: function(room) {
            return function(dispatch) {
                return DepartmentService.addRoom(room).then(function(uuid) {
                    Notifier.success('New room successfully created');
                    dispatch({
                        type: 'ROOM_FETCH',
                        id: 'administrationAccountsRoom',
                        data: _.set(room, 'uuid', uuid)
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationAccountsRoomsGrid'
                    });
                    dispatch({
                        type: 'TAB_ENABLE',
                        id: 'app.administration.accounts.rooms',
                        state: ['app.administration.accounts.rooms.equipment']
                    });
                    dispatch({
                        type: 'LOCATION_SELECTED',
                        id: 'administrationAccountsEquipmentGrid',
                        uuid: uuid
                    });
                });
            };
        },
        updateRoomDetails: function(room) {
            return function(dispatch) {
                AccountService.updateRoom(room).then(function() {
                    Notifier.success('Room data successfully updated');
                    dispatch({
                        type: 'ROOM_UPDATE',
                        id: 'administrationAccountsRoom',
                        data: room
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationAccountsRoomsGrid'
                    });
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };
        },
        getVendors: function(filter) {
            return function(dispatch) {
                VendorService.getVendorHeadOfficeList(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'administrationVendorsGrid',
                        items: data.items,
                        itemsCount: data.total
                    });
                });
            };
        },
        changePassword: function(uuid, password) {
            return function(dispatch) {
                return SettingsService.changePassword({}, uuid, password).then(function() {
                    dispatch({
                        type: 'PASSWORD_RESET',
                        id: 'administrationSettings',
                        uuid: uuid
                    });
                });
            };
        },
        resendLoginInfo: function(subjectUuid) {
            return function(dispatch) {
                Notifier.success('New Login info was successfully sent to all employees');
                AccountService.resendLoginInfo(subjectUuid).then(function() {
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationAccountsEmployeesGrid'
                    });
                });
            };
        },
        inactivateLocation: function(event) {
            return function(dispatch) {
                AccountService.inactivateLocation(event.item.uuid).then(function() {
                    Notifier.success('Location was successfully inactivated');
                    if (event.item.basicType === 'hof') {
                        dispatch({
                            type: 'GRID_RELOAD',
                            id: 'administrationAccountsHeadOfficesGrid'
                        });
                    } else if (event.item.basicType === 'slo') {
                        dispatch({
                            type: 'GRID_RELOAD',
                            id: 'administrationAccountsSiteLocationsGrid'
                        });
                    }
                });
            };
        },
        getRoomTypes: function() {
            return function(dispatch) {
                AccountService.getRoomTypes().then(function(data) {
                    let roomTypes = data.map(item => {
                        return {
                            value: item.id,
                            label: item.name
                        };
                    });
                    dispatch({
                        type: 'ROOM_TYPES',
                        id: 'administrationAccountsRoom',
                        items: roomTypes
                    });
                });
            };
        },
        addNewDepartmentType: (name) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/department/add-type',
                    method: 'POST',
                    data: {
                        name:   name
                    }
                }).then(response => {
                    dispatch({
                        type: 'DEPARTMENT_TYPE_SELECTED',
                        id: 'administrationAccountsDepartment',
                        departmentName: name,
                        departmentType: response.data.params.dep_id
                    });
                });
            }
        },
        getMonthlyReport: (data) => {
            return () => {
                return $http({
                    url: API_BASE + '/reporting/monthly-report',
                    method: 'POST',
                    data,
                }).then((res) => res);
            };
        },
    };
}
