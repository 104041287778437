import { API_BASE } from '../utils/util.constants';
import { FILE_DOWNLOAD } from './file.constants';
import { saveAs } from 'file-saver';

export default function FileActions($http, Notifier) {
    function download(path, params = {}) {
        return dispatch => {
            $http({
                url: API_BASE + '/file/download',
                method: 'POST',
                responseType: 'blob',
                data: { path },
            }).then(response => {
                dispatch({
                    type: FILE_DOWNLOAD
                });
                const disposition = response.headers('content-disposition');
                let fileName = disposition && disposition.match(/filename="(.*?)"/).pop();
                if(params.removeDocIdPrefix)
                    fileName = fileName.split('-').pop();
                const fileType = response.headers('content-type');
                saveAs(new Blob([response.data], {type: fileType}), fileName);
            }, () => {
                Notifier.error('System Error!');
            });
        }
    }

    function saveImage(image_url, width, height) {
        return (dispatch) => {
            return $http({
                url: API_BASE + '/img/save',
                method: 'POST',
                data: { image_url, width, height },
            }).then(({ data }) => {
                dispatch({ type: 'IMAGE_SAVE' });
                return data.params.image_id;
            }, () => {
                Notifier.error('System Error!');
            });
        }
    }

    function deleteImage(id) {
        return (dispatch) => {
            $http({
                url: API_BASE + '/img/delete',
                method: 'POST',
                data: { image_id: id },
            }).then(() => {
                dispatch({ type: 'IMAGE_DELETE' });
            }, () => {
                Notifier.error('System Error!');
            });
        }
    }

    return {
        download,
        saveImage,
        deleteImage
    }
}

FileActions.$inject = ['$http', 'Notifier'];
