/* @ngInject */
export function PersonalEquipmentTesterFactory() {
    return function PersonalEquipmentTester() {

        this.uuid = null;
        this.firstName = null;
        this.lastName = null;
        
        this.getFullName = (function() {
            return this.firstName + ' ' + this.lastName;
        }).bind(this);
    };
}
