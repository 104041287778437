/* @ngInject */
export function EquipmentBaseController($controller, $scope, $state, $timeout, NavigationService, EquipmentManager, Notifier, VendorGridFilter) {

    angular.extend(this, $controller('ApplicationModuleController', {
        $scope: $scope,
        $timeout: $timeout,
        Notifier: Notifier
    }));

    $scope.enableEquipmentRegistration = true;
    $scope.vendorServices = [];

    $scope.maxEquipmentInstalationDate = new Date();
    $scope.maxRegistrationDate = new Date();
    $scope.minNextDueDate = new Date();

    this.vendorFilter = new VendorGridFilter();
    this.vendorFilter.offset = null;
    this.vendorFilter.limit = null;

    var navConfig = NavigationService.getNavConfig();
    $scope.moduleColor = navConfig.colorName;
    $scope.moduleIcon = navConfig.icon;

    $scope.manageEquipmentService = function(serviceType) {
        EquipmentManager.manageEquipmentService($scope.equipment.equipment_id, serviceType);
    };

    $scope.onEquipmentServiceVendor = function(serviceType) {
        EquipmentManager.equipmentServiceVendorSelected($scope.equipment.equipment_id, serviceType);
    };

    $scope.manageEquipmentRegistration = function() {
        $scope.enableEquipmentRegistration = !$scope.enableEquipmentRegistration;
        if (!$scope.enableEquipmentRegistration) {
            $scope.equipment.registrationData.number = null;
            $scope.equipment.registrationData.date = null;
            $scope.equipment.registrationData.dueDate = null;
            $scope.equipment.registrationData.details = null;
        }
    };

    $scope.onPrintQRcode = () => {
        EquipmentService.printQRcode(
            $scope.equipment.equipment_id,
            $scope.equipment.user_friendly_id,
            'equipment',
        );
    };
}
