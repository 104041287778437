import { TAB_SHOW } from '../../../common/tabs/tabs.constants';
import { getTestDetails, deleteTest, getTestWipeTestDetails } from './rm-test.actions';
import { getUnits } from '../radioactive-materials.actions';

/**
 * @const {object}
 * @memberOf module:RadioactiveMaterials
 */
export const rmTest = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $mdDialog, $rootScope, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(() => ({}), {
                getTestDetails, getUnits, deleteTest, getTestWipeTestDetails
            })(this);
        }

        this.onSelect = ({ item }) => {
            this.getTestDetails(item.rms_id).then(() => {
                $ngRedux.dispatch({
                    type: TAB_SHOW,
                    id: 'app.radioactiveMaterials.test.manage',
                    state: [
                        'app.radioactiveMaterials.test.manage.details',
                        'app.radioactiveMaterials.test.manage.documents'
                    ],
                    stateParams: { id: item.rms_id }
                });
            });
        };

        this.onWipeTest = ({ item }) => {
            if(parseInt(item.sealed)) {
                this.getTestDetails(item.rms_id).then(() => {
                    this.getTestWipeTestDetails(item.rms_id).then(() => {
                        $ngRedux.dispatch({
                            type: TAB_SHOW,
                            id: 'app.radioactiveMaterials.test.manage',
                            state: ['app.radioactiveMaterials.test.manage.wipeTest'],
                            stateParams: {id: item.rms_id}
                        });
                    });
                });
            } else {
                Notifier.warn("You have an unsealed isotope, leak test not required.");
            }
        };

        this.onDispose = ({ item }) => {
            this.getTestDetails(item.rms_id).then(() => {
                $ngRedux.dispatch({
                    type: TAB_SHOW,
                    id: 'app.radioactiveMaterials.test.manage',
                    state: ['app.radioactiveMaterials.test.manage.disposal'],
                    stateParams: { id: item.rms_id }
                });
            });
        };

        this.onRemove = ({ item }) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen test will be removed permanently.')
                .ariaLabel('Remove test')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.deleteTest(item.rms_id);
            }, angular.noop);
        };

        this.onReset = (event) => {
            $rootScope.$broadcast('SELECT_CASCADE_RESET', 'rmTestFilterForm');
            this.getUnits('rmTest', 'siu', 'units');
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: `
        <rm-test-grid
            on-select="$ctrl.onSelect($event)" 
            on-wipe-test="$ctrl.onWipeTest($event)"
            on-dispose="$ctrl.onDispose($event)"
            on-remove="$ctrl.onRemove($event)"
            on-reset="$ctrl.onReset($event)">
        </rm-test-grid>
    `
};
