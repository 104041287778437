/* @ngInject */
export function AccDiasUploadDocumentCtrl($scope, API_BASE, $mdDialog, color, title, locationUuid, locationName,
                                   templateDocument, token, standardId, Notifier, AccreditationDocumentsService,
                                   ACC_ACCREDITATION_CODE, FileUploader, $http, $timeout, AccDocument, DocumentManager,
                                   DOCUMENT_TYPE, AuthService, AccreditationService, Helpers, DATE_FORMAT, $filter) {

    $scope.headerColor = color;
    $scope.headerTitle = title;
    $scope.cursorState = 'default';

    $scope.trainingDocumentTypesList = null;

    $scope.templateDocument = templateDocument;
    if (templateDocument) {
        $scope.locationName = null;
        $scope.locationUuid = 0;
    } else {
        $scope.locationName = locationName;
        $scope.locationUuid = locationUuid;
    }

    $scope.token = token || null;

    $scope.accreditationSchemaCode = AccreditationService.getCurrentAccreditationFromState();
    $scope.misc = {}; //here we will additional info about document, keep in database as JSON
    $scope.uploadResult = null; //format with info about uploaded document that is result of this dialog
    $scope.newDocument = new AccDocument();

    $scope.newDocument.submitted = false; //If this document has tried to be submitted
    $scope.newDocument.template = $scope.templateDocument;

    if ($scope.token) {
        $scope.options = {
            url: API_BASE + "/file/streamann",
            alias: "upstream",
            queueLimit: 1,
            headers: {
                "D-Token": $scope.token,
            }
        };
    } else {
        $scope.options = {
            url: API_BASE + "/file/stream",
            alias: "upstream",
            queueLimit: 1,
            headers: {
                "X-Authorization": $http.defaults.headers.common['X-Authorization'],
                "X-Token": $http.defaults.headers.common['X-Token'],
            }
        };
    }

    $scope.uploader = new FileUploader({
        onSuccessItem: function (item, response, status, headers) {
            item.filePath = response.path;
            item.isAlreadyOnServer = true;
            angular.element("#flu")[0].value = '';

            if (item.filePath === '' || item.filePath === null) {
                Notifier.error($filter('translate')('ACCREDITATION.TABS.UPLOAD_DOCUMENTS_DIALOG.NOTIFIER_INVALID_PATH'));
                return;
            } else {
                $scope.uploadResult = {
                    filePath: item.filePath,
                    isSuccess: item.isSuccess,
                    isUploaded: item.isUploaded,
                    file: {
                        name: item.file.name,
                        lastModifiedDate: item.file.lastModifiedDate,
                        size: item.file.size,
                        type: item.file.type,
                    },
                    isAlreadyOnServer: true
                };
            }
        },
        autoUpload: true
    });

    $scope.uploadFile = function () {
        if ($scope.validate) {
            // $rootScope.$broadcast('show-errors-check-validity', $scope.validate.$name);
            if ($scope.validate.$invalid) {
                return;
            }
        }

        //Prevent inprog error, double digest cycle
        $timeout(
            function () {
                angular.element("#flu").trigger("click");
            }
        );
    };

    $scope.removeFile = function (item) {
        $scope.uploadResult = null;

        if (item.remove != null) {
            item.remove();
        } else {
            //next time form is loaded, item is actually locally created mock of original FileUploader item
            //just remove item from uplodaer queue array
            $scope.uploader.queue = $scope.uploader.queue.filter(function (fileItem) {
                return fileItem.filePath != item.filePath;
            });
        }

        angular.element("#flu")[0].value = '';
    };

    $scope.downloadFile = function (item) {
        $timeout(
            function () {
                DocumentManager.downloadFile(item.filePath, DOCUMENT_TYPE.ACCREDITATION_DOCUMENT);
            }
        );
    };

    $scope.cancelFile = function (item) {
        item.cancel();
    };

    //Dialog cancel button
    $scope.cancel = function () {
        $mdDialog.cancel();
    };

    $scope.submitAccDocument = function () {

        $scope.newDocument.submitted = true;
        $scope.newDocument.isGenerated = false;

        if (($scope.uploader.queue[0] != null) && ($scope.uploader.queue[0].isUploading)) {
            Notifier.info($filter('translate')('ACCREDITATION.TABS.UPLOAD_DOCUMENTS_DIALOG.NOTIFIER_UPLOAD_IN_PROGRESS'));
            return;
        }

        if (($scope.uploader.queue[0] == null) || ($scope.uploadResult == null) || ($scope.uploader.queue[0].filePath == null)) {
            Notifier.info($filter('translate')('ACCREDITATION.TABS.UPLOAD_DOCUMENTS_DIALOG.NOTIFIER_NOT_UPLOADED'));
            return;
        }

        if ((!$scope.documentUploadForm.$valid)) {
            Notifier.info($filter('translate')('ACCREDITATION.TABS.UPLOAD_DOCUMENTS_DIALOG.NOTIFIER_FIELDS_REQUIRED'));
            return;
        }

        $scope.newDocument.docPath = $scope.uploader.queue[0].filePath;
        $scope.newDocument.locationUuid = $scope.locationUuid;
        if ($scope.newDocument.misc == null) {
            $scope.newDocument.misc = {};
        }

        $scope.cursorState = 'wait';
        //save current form values to database
        if ($scope.token) {
            AccreditationDocumentsService.saveAccreditationDocumentAnonymous($scope.newDocument.locationUuid,
            $scope.newDocument, $scope.token)
                .then(function (success) {
                    $scope.cursorState = 'default';
                    Notifier.success($filter('translate')('ACCREDITATION.TABS.UPLOAD_DOCUMENTS_DIALOG.NOTIFIER_SAVE_SUCCESS'));
                    //Update return value(uploadResult) with docId of document
                    $scope.uploadResult.documentId = success.data.documentId;
                    $scope.uploadResult.file.uploader = success.data.uploader;
                    $scope.uploadResult.file.uploadDate = Helpers.formatDate(new Date(), 'DD-MM-YYYY');
                    $scope.uploadResult.misc = {
                        uploaderUuid: $scope.newDocument.misc.uploaderUuid
                    };
                    $scope.$emit('acc-document-uploaded-an', $scope.uploadResult);
                    $mdDialog.hide();
                }, function (error) {
                    $scope.cursorState = 'default';
                    Notifier.info($filter('translate')('ACCREDITATION.TABS.UPLOAD_DOCUMENTS_DIALOG.NOTIFIER_SAVE_FAILED'));
                });
        } else {
                var currentUser = AuthService.getUser();
                $scope.newDocument.misc.uploaderUuid = currentUser.uuid;
                AccreditationDocumentsService.saveAccreditationDocument($scope.newDocument.locationUuid, $scope.newDocument, standardId)
                .then(function (success) {
                    $scope.cursorState = 'default';
                    Notifier.success($filter('translate')('ACCREDITATION.TABS.UPLOAD_DOCUMENTS_DIALOG.NOTIFIER_SAVE_SUCCESS'));
                    //Update return value(uploadResult) with docId of document
                    $scope.uploadResult.documentId = success.data.documentId;
                    $scope.uploadResult.file.uploader = success.data.uploader;
                    $scope.uploadResult.file.uploadDate = Helpers.formatDate(new Date(), 'DD-MM-YYYY');
                    $scope.uploadResult.misc = {
                        uploaderUuid: $scope.newDocument.misc.uploaderUuid
                    };

                    $scope.$emit('acc-document-uploaded', $scope.uploadResult);
                    $mdDialog.hide();
                }, function (error) {
                    $scope.cursorState = 'default';
                    Notifier.info($filter('translate')('ACCREDITATION.TABS.UPLOAD_DOCUMENTS_DIALOG.NOTIFIER_SAVE_FAILED'));
                });
        }
    };

    if (!$scope.token) {

        $scope.trainingDocumentTypesList = null;

        AccreditationDocumentsService.fetchAccDocumentsTrainingTypes($scope.locationUuid, standardId)
            .then(function (response) {
                if ((response.status == 200) && (response.data.result != null)) {
                    $scope.trainingDocumentTypesList = response.data.result;
                } else if (response.status == 400 && typeof response.data == 'string') {
                    Notifier.error(response.data);
                } else if (response.status == 403) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.UPLOAD_DOCUMENTS_DIALOG.NOTIFIER_ACCESS_ERROR'));
                } else if (response.status == 404) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.UPLOAD_DOCUMENTS_DIALOG.NOTIFIER_DOC_NOT_FOUND'));
                } else if (response.status == 500) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.UPLOAD_DOCUMENTS_DIALOG.NOTIFIER_SERVER_ERROR'));
                } else if (response.status != 200) {
                    error
                } else if ((response.data == null) || (response.data.result == null)) {
                    //No data for this item
                    $scope.trainingDocumentTypesList = null;
                }
            }, function (error) {
                Notifier.success($filter('translate')('ACCREDITATION.TABS.UPLOAD_DOCUMENTS_DIALOG.NOTIFIER_FETCH_TRAINING'))
            });
    }
}
