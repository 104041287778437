/** @namespace Reducers.ServiceContracts */

import { GridReducer } from '../common/grid/grid.reducer';
import merge from 'lodash/merge';
import set from 'lodash/set';
import update from 'lodash/update';

/**
 * @function serviceContractsReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function serviceContractsReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            serviceContractsGrid: merge(GridReducer(), {types: []}, {
                filter: {
                    number: null,
                    type: null
                }
            })
        };
    }

    switch (action.type) {
        case 'CONTRACT_TYPES':
            if (action.id === 'serviceContractsGrid') {
                return Object.assign({}, state, {
                    serviceContractsGrid: Object.assign({}, state.serviceContractsGrid, {types: action.items})
                });
            }
        case 'CONTRACT_NUMBER_CHANGE':
            if (action.id === 'serviceContractsGrid') {
                return Object.assign({}, state, {
                    serviceContractsGrid: set(GridReducer(state.serviceContractsGrid, {type: 'PAGINATE'}), 'filter.number', action.number)
                });
            }
        case 'CONTRACT_TYPE_SELECTED':
            if (action.id === 'serviceContractsGrid') {
                return Object.assign({}, state, {
                    serviceContractsGrid: set(GridReducer(state.serviceContractsGrid, {type: 'PAGINATE'}), 'filter.type', action.contractType)
                });
            }
        case 'LOCATION_SELECTED':
            return Object.assign({}, state, {
                serviceContractsGrid: set(GridReducer(state.serviceContractsGrid, {type: 'PAGINATE'}), 'filter.location', action.uuid)
            });
        case 'GRID_INIT':
        case 'GRID_RESET':
            if (action.id === 'serviceContractsGrid') {
                return Object.assign({}, state, {
                    serviceContractsGrid: update(GridReducer(state.serviceContractsGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            number: null,
                            type: null
                        });
                    })
                });
            }
        case 'SHOW_LOADING':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
            if (action.id === 'serviceContractsGrid') {
                return Object.assign({}, state, {
                    serviceContractsGrid: GridReducer(state.serviceContractsGrid, action)
                });
            }
        default:
            return state;
    }
}
