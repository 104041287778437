import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsSiteLocationRscController($ngRedux, $uiRouterGlobals, $mdDialog, AdministrationActions) {
    var ctrl = this;
    var dialog = null;
    var unsubscribe = angular.noop;
    var onRsc = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);

        var watchRsc = watch($ngRedux.getState, 'administration.administrationAccountsSiteLocationRsc.data.uuid');
        onRsc = $ngRedux.subscribe(watchRsc(function(uuid) {
            onRscReady(uuid);
        }));
    };

    ctrl.newRsc = function() {
        if (ctrl.rsc.uuid) {
            $ngRedux.dispatch({
                type: 'RSC_RESET',
                id: 'administrationAccountsSiteLocationRsc'
            });
        }
        $ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: 'app.administration.accounts.siteLocations',
            state: ['app.administration.accounts.siteLocations.rscDetails'],
            stateParams: $uiRouterGlobals.params
        });
    };

    ctrl.selectRSC = function(event) {
        if (event.item.subject_uuid === ctrl.rsc.uuid)
            onRscReady(event.item.subject_uuid);
        else
            ctrl.getRscDetails('administrationAccountsSiteLocationRsc', event.item.subject_uuid);
    };

    ctrl.removeRSC = function(event) {
        var confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Radiation Safety Coordinator will be deactivated.')
            .ariaLabel('Deactivate RSC')
            .ok('Yes')
            .cancel('No');

        dialog = $mdDialog.show(confirm).then(function() {
            ctrl.removeRsc(event.item.subject_uuid, ctrl.siteLocation.uuid, 'administrationAccountsSiteLocationRsc', 'administrationAccountsSiteLocationRscGrid');
        }, angular.noop);
    };

    ctrl.$onDestroy = function() {
        if (dialog) $mdDialog.hide(dialog);
        unsubscribe();
        onRsc();
    };

    function onRscReady(uuid) {
        $ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: 'app.administration.accounts.siteLocations',
            state: ['app.administration.accounts.siteLocations.rscDetails'],
            stateParams: {
                hof: $uiRouterGlobals.params.hof,
                slo: $uiRouterGlobals.params.slo,
                rsc: uuid
            }
        });
        $ngRedux.dispatch({
            type: 'TAB_LOADING',
            id: 'app.administration.accounts.siteLocations',
            state: 'app.administration.accounts.siteLocations.rscDetails',
            loading: false
        });
    }

    function mapStateToThis(state) {
        return {
            siteLocation: state.administration.administrationAccountsSiteLocation.data,
            rsc: state.administration.administrationAccountsSiteLocationRsc.data,
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                'RSC'
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
