/* @ngInject */
export const RegistrationDataFactory = (Helpers, DATE_FORMAT) => {
    return function RegistrationData() {

        this.number = null;
        this.date = null;
        this.dueDate = null;
        this.details = null;

        this.formatDate = function() {
            return Helpers.formatDate(this.date, DATE_FORMAT.INPUT);
        };

        this.formatDueDate = function() {
            return Helpers.formatDate(this.dueDate, DATE_FORMAT.INPUT);
        };
    };
};
