import watch from 'redux-watch';
import _ from 'lodash';

/**
 * Creates a new TabsNavigationController.
 * @class
 */
class TabsNavigationController {
    constructor($ngRedux, $state, $uiRouterGlobals, NavigationService) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.NavigationService = NavigationService;
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.color = navConfig.colorName;

        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);

        this.initButtons();

        const watchTabs = watch(this.$ngRedux.getState, [this.module, this.identifier, 'tabs']);
        this.onTabs = this.$ngRedux.subscribe(watchTabs(() => {
            this.initButtons();
        }));
    }

    $onChanges(changes) {
        if (_.has(changes, 'stateProp'))
            this.manageButtons(changes.stateProp.currentValue);
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeModule();
        this.onTabs();
    }

    initButtons() {
        this.redirectButtons = [];
        this.execButton = null;
        this.prevButton = null;
        this.nextButton = null;

        const index = _.findIndex(this.tabs, ['state', this.state]);

        if (_.has(this.tabs[index], 'buttons.redirect', null)) {
            for (let i = 0; i < this.tabs[index].buttons.redirect.length; i++) {
                const redirectButton = _.assign({}, this.tabs[index].buttons.redirect[i]);
                redirectButton.name = 'btnRedirect';
                redirectButton.visible = true;
                redirectButton.onClick = () => {
                    this.$state.go(redirectButton.state, this.$uiRouterGlobals.params, {reload: redirectButton.reload});
                };
                this.redirectButtons.push(redirectButton);
            }
        }

        if (_.has(this.tabs[index], 'buttons.execute', null)) {
            if (this.tabs[index].buttons.execute) {
                this.execButton = _.assign({}, this.tabs[index].buttons.execute);
                this.execButton.name = 'btnExecute';
                this.execButton.onClick = this.onExec;
                if (this.stateProp) this.execButton.label = 'Modify';
            }
        }

        let prevIdx = index;
        while (prevIdx) {
            const prevTab = this.tabs[prevIdx - 1];
            if (prevTab.visible) {
                this.prevButton = _.assign({}, prevTab);
                this.prevButton.label = 'Back';
                this.prevButton.name = 'btnBack';
                this.prevButton.onClick = () => {
                    this.$ngRedux.dispatch({
                        type: 'TAB_SELECT',
                        id: this.identifier,
                        state: prevTab.state,
                        stateParams: this.$uiRouterGlobals.params
                    });
                };
                break;
            }
            prevIdx--;
        }

        let nextIdx = index;
        while (nextIdx < this.tabs.length - 1) {
            const nextTab = this.tabs[nextIdx + 1];
            if (nextTab.visible && !nextTab.disabled) {
                this.nextButton = _.assign({}, nextTab);
                this.nextButton.label = 'Next';
                this.nextButton.name = 'btnNext';
                this.nextButton.onClick = () => {
                    this.$ngRedux.dispatch({
                        type: 'TAB_SELECT',
                        id: this.identifier,
                        state: nextTab.state,
                        stateParams: this.$uiRouterGlobals.params
                    });
                };
                break;
            }
            nextIdx++;
        }

        this.manageButtons(this.stateProp);
    }

    manageButtons(stateProp) {
        _.each(this.redirectButtons, button => {
            if (_.has(button, 'stateProp'))
                button.visible = !_.isNull(stateProp);
        });

        if (this.execButton && stateProp)
            this.execButton.label = 'Modify';

        if (this.hideExec)
            this.execButton = null;
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        };
    }

    mapState(state) {
        return {
            tabs: state[this.module][this.identifier].tabs
        };
    }
}

TabsNavigationController.$inject = ['$ngRedux', '$state', '$uiRouterGlobals', 'NavigationService'];

export const tabsNavigation = {
    bindings: {
        identifier: '@',
        state: '@',
        stateProp: '<',
        onExec: '&',
        hideExec: '<'
    },
    controller: TabsNavigationController,
    template: require('./tabs-navigation.html')
};
