import myrso from '../../../img/myrso.png';
import home from '../../../img/svg/home.svg';
import homeGray from '../../../img/svg/home-gray.svg';
import menuWhite from '../../../img/svg/menu-white.svg';
import account from '../../../img/svg/account.svg';
import accountGray from '../../../img/svg/account-gray.svg';
import back from '../../../img/svg/back.svg';
import add from '../../../img/svg/add.svg'
import tileXray from '../../../img/svg/xray-compliance.svg';
import tileLasers from '../../../img/svg/lasers.svg';
import tileUltrasound from '../../../img/svg/ultrasound.svg';
import tileMammo from '../../../img/svg/mammo.svg';
import tileAddEquipment from '../../../img/svg/add-equipment.svg';
import tileManageEquipment from '../../../img/svg/manage-equipment.svg';
import tileAlerts from '../../../img/svg/alerts.svg';
import tileEquipmentCalibration from '../../../img/svg/equipment-calibration.svg';
import tileMammoQC from '../../../img/svg/mammo-qc.svg';
import tileInventoryScanner from '../../../img/svg/inventory-scanner.svg';
import tileShielding from '../../../img/svg/shielding.svg';
import tilePersonalDosimetry from '../../../img/svg/personal-dosimetry.svg';
import tilePersonalDosimetrySettings from '../../../img/svg/personal-dosimetry-settings.svg';
import tileDocuments from '../../../img/svg/documents.svg';
import tileEmployees from '../../../img/svg/accounts_employees.svg';
import tileEmployeesAdd from '../../../img/svg/accounts_employees_add.svg';
import tileEmployeesManage from '../../../img/svg/accounts_employees_manage.svg';
import tileApplicationsPermits from '../../../img/svg/applications-permits.svg';
import tileApplicationsPermitsLaser from '../../../img/svg/applications-permits-laser.svg';
import tileApplicationsPermitsLaserRequest from '../../../img/svg/applications-permits-laser.svg';
import tileApplicationsPermitsLaserManage from '../../../img/svg/applications-permits-laser.svg';
import tileApplicationsPermitsLaserAlerts from '../../../img/svg/applications-permits-laser-alerts.svg';
import tileApplicationsPermitsNuclearEnergy from '../../../img/svg/nuclear-energy-permits.svg';
import tileApplicationsPermitsNuclearEnergyRequest from '../../../img/svg/nuclear-energy-permits-request.svg';
import tileApplicationsPermitsNuclearEnergyManage from '../../../img/svg/nuclear-energy-permits-manage.svg';
import tileApplicationsPermitsNuclearEnergyAlerts from '../../../img/svg/nuclear-energy-permits-alerts.svg';
import tileCredentialing from '../../../img/svg/credentialing.svg';
import tileCredentialingVendors from '../../../img/svg/credentialing-vendors.svg';
import tileCredentialingVendorsInvitation from '../../../img/svg/credentialing-vendors-invitation.svg';
import tileCredentialingVendorsManage from '../../../img/svg/credentialing-vendors-manage.svg';
import tileCredentialingVendorsAlerts from '../../../img/svg/credentialing-vendors-alerts.svg';
import tileReporting from '../../../img/svg/reporting.svg';
import tileEquipmentInfo from '../../../img/svg/equipment-info.svg';
import tileEquipmentTypeHistory from '../../../img/svg/inspection-type-report.svg';
import tileInspectionReport from '../../../img/svg/inspection-report.svg';
import tileSummaryReport from '../../../img/svg/summary-report.svg';
import tileReportingHelp from '../../../img/svg/reporting-help.svg';
import tileRadiationSafetyPlan from '../../../img/svg/radiation_safety_plan.svg';
import tileSafetyPlanManageEmployeeReview from '../../../img/svg/radiation_safety_plan_manage_employee.svg';
import tileSafetyPlanCostumize from '../../../img/svg/radiation_safety_plan_customize.svg';
import tileSafetyPlanDocuments from '../../../img/svg/radiation_safety_plan_review_documents.svg';
import tileSafetyPlanTrainingMatrixReview from '../../../img/svg/radiation_safety_plan_training_matrix.svg';
import tileSafetyPlanReviewReport from '../../../img/svg/radiation_safety_plan_review_report.svg';
import tileSafetyPlanReviewPresentation from '../../../img/svg/radiation_safety_plan_review_presentation.svg';
import tileSafetyPlanEmployeeQuestions from '../../../img/svg/radiation_safety_plan_manage_employee_questions.svg';
import tileSafetyPlanReviewContacts from '../../../img/svg/radiation_safety_plan_review_contacts.svg';
import tileSafetyPlanReviewHelp from '../../../img/svg/radiation_safety_plan_review_help.svg';
import tileTraining from '../../../img/svg/training.svg';
import tileTrainingVideoReview from '../../../img/svg/training-video-review.svg';
import tileTrainingReviewManage from '../../../img/svg/training-review-manage.svg';
import tileTrainingAnimations from '../../../img/svg/training-animations.svg';
import tileServiceContracts from '../../../img/svg/service-contract.svg';
import tileServiceContractAdd from '../../../img/svg/service-contract-add.svg';
import tileServiceContractManage from '../../../img/svg/service-contract-manage.svg';
import tileServiceContractReport from '../../../img/svg/service-contract-report.svg';
import tileServiceContractVendor from '../../../img/svg/service-contract-vendor.svg';
import tileServiceContractVendorEngineerLicensing from '../../../img/svg/service-contract-vendor-engineer-licensing.svg';
import tileAccreditations from '../../../img/svg/accreditation_polices_procedures.svg';
import tileAccreditationDIAS from '../../../img/svg/dias_accreditation_polices_procedures.svg';
import tileAccreditationHospital from '../../../img/svg/hospital_accreditation.svg';
import tileAccreditationAddScheme from '../../../img/svg/accreditation_add_scheme.svg';
import tileAccreditationSettings from '../../../img/svg/accreditation_settings.svg';
import tilePersonalProtectiveEquipment from '../../../img/svg/personal_protective_equipment.svg';
import tilePersonalProtectiveEquipmentAdd from '../../../img/svg/personal-protective-equipment-add.svg';
import tilePersonalProtectiveEquipmentManage from '../../../img/svg/personal-protective-equipment-manage.svg';
import tilePersonalProtectiveEquipmentTestReports from '../../../img/svg/personal-protective-equipment-test-reports.svg';
import tileEvents from '../../../img/svg/events.svg';
import tileEventsAddSafetyRiskPeople from '../../../img/svg/events-add-safety-risk-people.svg';
import tileEventsAddOperationalRiskBusiness from '../../../img/svg/events-add-operational-risk-business.svg';
import tileEventsAddRadiationRisk from '../../../img/svg/events-add-radiation-risk.svg';
import tileEventsManage from '../../../img/svg/events-manage.svg';
import tileDoseReferenceLevels from '../../../img/svg/dose-reference-levels.svg';
import tileDoseReferenceLevelsCTScanner from '../../../img/svg/dose-reference-levels-ct-scanner.svg';
import tileDoseReferenceLevelsFlouroscopyAngiography from '../../../img/svg/dose-reference-levels-fluoroscopy-angiography.svg';
import tileRadioactiveMaterials from '../../../img/svg/radioactive-materials.svg';
import tileRadioactiveMaterialsWaste from '../../../img/svg/radioactive-materials.svg';
import tileRadioactiveMaterialsWasteAdd from '../../../img/svg/radioactive-materials-waste-add.svg';
import tileRadioactiveMaterialsWasteManage from '../../../img/svg/radioactive-materials-waste-manage.svg';
import tileRadioactiveMaterialsTest from '../../../img/svg/radioactive-materials.svg';
import tileRadioactiveMaterialsTestAdd from '../../../img/svg/radioactive-materials-test-add.svg';
import tileRadioactiveMaterialsTestManage from '../../../img/svg/radioactive-materials-test-manage.svg';
import tileAdministration from '../../../img/svg/administration.svg';
import tileAccounts from '../../../img/svg/accounts.svg';
import tileVendors from '../../../img/svg/vendors.svg';
import tileVendorsAdd from '../../../img/svg/accounts_vendors_add.svg';
import tileVendorsManage from '../../../img/svg/accounts_vendors_manage.svg';
import tileAccess from '../../../img/svg/administration-access.svg';
import tileManufacturers from '../../../img/svg/manufacturers.svg';
import tileHelp from '../../../img/svg/myrso-help.svg';
import tileAccount from '../../../img/svg/my-account.svg';
import tileSystem from '../../../img/system.png';
import user from '../../../img/svg/user.svg';
import userGray from '../../../img/svg/user-gray.svg';
import userGreen from '../../../img/svg/user-green.svg';
import userRed from '../../../img/svg/user-red.svg';
import userYellow from '../../../img/svg/user-yellow.svg';
import usd from '../../../img/svg/usd.svg';
import search from '../../../img/svg/search.svg';
import filter from '../../../img/svg/filter.svg';
import signature from '../../../img/svg/signature.svg';
import remove from '../../../img/svg/remove.svg';
import qrcodeScan from '../../../img/svg/qrcode-scan.svg';
import plus from '../../../img/svg/plus.svg';
import plusGray from '../../../img/svg/plus-gray.svg';
import phone from '../../../img/svg/phone.svg';
import percent from '../../../img/svg/percent.svg';
import warningWhite from '../../../img/svg/ic_warning_white_18px.svg';
import warningRed from '../../../img/svg/ic_warning_red_18px.svg';
import actionPlan from '../../../img/svg/action-plan.svg';
import actionTask from '../../../img/svg/action-task.svg';
import addAccreditationDocuments from '../../../img/svg/add_accreditation_documents.svg'
import addEmployees from '../../../img/svg/add-employees.svg';
import addManufacturers from '../../../img/svg/add-manufacturers.svg';
import addProposalRequests from '../../../img/svg/add-proposal-requests.svg';
import addVendors from '../../../img/svg/add-vendors.svg'
import addDocuments from '../../../img/svg/add_documents.svg';
import administrationAccounts from '../../../img/svg/administration_accounts.svg';
import administrationAccountsAdd from '../../../img/svg/administration_accounts_add.svg';
import administrationAccountsManage from '../../../img/svg/administration_accounts_manage.svg';
import administrationClients from '../../../img/svg/administration_clients.svg';
import administrationHeadOfficeAdd from '../../../img/svg/administration_head-office_add.svg';
import administrationMainAccountsAdd from '../../../img/svg/administration_main-accounts_add.svg';
import administrationVendors from '../../../img/svg/administration_vendors.svg';
import approval from '../../../img/svg/approval.svg';
import arrowLeft from '../../../img/svg/arrow_left.svg';
import arrowRight from '../../../img/svg/arrow_right.svg';
import baselineCheckboxGreen from '../../../img/svg/baseline-check_box-24px-green.svg';
import calendar from '../../../img/svg/calendar.svg';
import checkIn from '../../../img/svg/check-in.svg';
import close from '../../../img/svg/close.svg';
import commentGray from '../../../img/svg/comment-gray.svg';
import contractGray from '../../../img/svg/contract-gray.svg';
import denial from '../../../img/svg/denial.svg';
import documentsBlack from '../../../img/svg/documents-black.svg';
import documentsGray from '../../../img/svg/documents-gray.svg';
import download from '../../../img/svg/download.svg';
import downloadBlack from '../../../img/svg/download_black.svg';
import edu from '../../../img/svg/edu.svg';
import edu2 from '../../../img/svg/edu_2.svg';
import email from '../../../img/svg/email.svg';
import emailGray from '../../../img/svg/email-gray.svg';
import employees from '../../../img/svg/employees.svg';
import equipment from '../../../img/svg/equipment.svg';
import equipmentOld from '../../../img/svg/equipment-old.svg';
import equipmentTypeReport from '../../../img/svg/equipment-type-report.svg';
import equipmentBlack from '../../../img/svg/equipment_black.svg';
import exportGray from '../../../img/svg/export-gray.svg';
import faq from '../../../img/svg/faq.svg';
import fileGray from '../../../img/svg/file-gray.svg';
import fileRed from '../../../img/svg/file-red.svg';
import galleryGray from '../../../img/svg/gallery-gray.svg';
import globalUserManagement from '../../../img/svg/global_user_management.svg';
import notificationsOrange from '../../../img/svg/ic_notifications_orange_18px.svg';
import notificationsWhite from '../../../img/svg/ic_notifications_white_18px.svg';
import pictureAsPdfWhite from '../../../img/svg/ic_picture_as_pdf_white_18px.svg';
import inputModify from '../../../img/svg/input-modify.svg';
import inputRemove from '../../../img/svg/input-remove.svg';
import manageEmployees from '../../../img/svg/manage-employees.svg';
import manageManufacturers from '../../../img/svg/manage-manufacturers.svg';
import manageProposalRequests from '../../../img/svg/manage-proposal-requests.svg';
import manageVendors from '../../../img/svg/manage-vendors.svg';
import manageDocuments from '../../../img/svg/manage_documents.svg';
import manufacturersOld from '../../../img/svg/manufacturers-old.svg';
import moreVert from '../../../img/svg/more_vert.svg';
import proposalRequests from '../../../img/svg/proposal-requests.svg';
import qaAccreditationPolicesProcedures from '../../../img/svg/qa_accreditation_polices_procedures.svg';
import qcTasks from '../../../img/svg/qc-tasks.svg';
import radiologyQA from '../../../img/svg/radiology_q&a.svg';
import radiopharma from '../../../img/svg/radiopharma.svg';
import schedule from '../../../img/svg/schedule.svg';
import survey from '../../../img/svg/survey.svg';
import unfinished from '../../../img/svg/unfinished.svg';
import figureLoader from '../../../img/figure-loader.png';

const assets = {
    'myrso.png': myrso,
    'home.svg': home,
    'home-gray.svg': homeGray,
    'menu-white.svg': menuWhite,
    'account.svg': account,
    'account-gray.svg': accountGray,
    'back.svg': back,
    'add.svg': add,
    'xray-compliance.svg': tileXray,
    'lasers.svg': tileLasers,
    'ultrasound.svg': tileUltrasound,
    'mammo.svg': tileMammo,
    'add-equipment.svg': tileAddEquipment,
    'manage-equipment.svg': tileManageEquipment,
    'alerts.svg': tileAlerts,
    'equipment-calibration.svg': tileEquipmentCalibration,
    'mammo-qc.svg': tileMammoQC,
    'inventory-scanner.svg': tileInventoryScanner,
    'shielding.svg': tileShielding,
    'personal-dosimetry.svg': tilePersonalDosimetry,
    'personal-dosimetry-settings.svg': tilePersonalDosimetrySettings,
    'accounts_employees.svg': tileEmployees,
    'accounts_employees_add.svg': tileEmployeesAdd,
    'accounts_employees_manage.svg': tileEmployeesManage,
    'applications-permits.svg': tileApplicationsPermits,
    'applications-permits-laser.svg': tileApplicationsPermitsLaser,
    'applications-permits-laser-request.svg': tileApplicationsPermitsLaser,
    'applications-permits-laser-manage.svg': tileApplicationsPermitsLaser,
    'applications-permits-laser-alerts.svg': tileApplicationsPermitsLaserAlerts,
    'nuclear-energy-permits.svg': tileApplicationsPermitsNuclearEnergy,
    'nuclear-energy-permits-request.svg': tileApplicationsPermitsNuclearEnergyRequest,
    'nuclear-energy-permits-manage.svg': tileApplicationsPermitsNuclearEnergyManage,
    'nuclear-energy-permits-alerts.svg': tileApplicationsPermitsNuclearEnergyAlerts,
    'credentialing.svg': tileCredentialing,
    'credentialing-vendors.svg': tileCredentialingVendors,
    'credentialing-vendors-invitation.svg': tileCredentialingVendorsInvitation,
    'credentialing-vendors-manage.svg': tileCredentialingVendorsManage,
    'credentialing-vendors-alerts.svg': tileCredentialingVendorsAlerts,
    'reporting.svg': tileReporting,
    'documents.svg': tileDocuments,
    'equipment-info.svg': tileEquipmentInfo,
    'inspection-type-report.svg': tileEquipmentTypeHistory,
    'inspection-report.svg': tileInspectionReport,
    'summary-report.svg': tileSummaryReport,
    'reporting-help.svg': tileReportingHelp,
    'radiation_safety_plan.svg': tileRadiationSafetyPlan,
    'radiation_safety_plan_manage_employee.svg': tileSafetyPlanManageEmployeeReview,
    'radiation_safety_plan_review_documents.svg': tileSafetyPlanDocuments,
    'radiation_safety_plan_customize.svg': tileSafetyPlanCostumize,
    'radiation_safety_plan_training_matrix.svg': tileSafetyPlanTrainingMatrixReview,
    'radiation_safety_plan_review_report.svg': tileSafetyPlanReviewReport,
    'radiation_safety_plan_review_presentation.svg': tileSafetyPlanReviewPresentation,
    'radiation_safety_plan_manage_employee_questions.svg': tileSafetyPlanEmployeeQuestions,
    'radiation_safety_plan_review_contacts.svg': tileSafetyPlanReviewContacts,
    'radiation_safety_plan_review_help.svg': tileSafetyPlanReviewHelp,
    'training.svg': tileTraining,
    'training-video-review.svg': tileTrainingVideoReview,
    'training-review-manage.svg': tileTrainingReviewManage,
    'training-animations.svg': tileTrainingAnimations,
    'service-contract.svg': tileServiceContracts,
    'service-contract-add.svg': tileServiceContractAdd,
    'service-contract-manage.svg': tileServiceContractManage,
    'service-contract-report.svg': tileServiceContractReport,
    'service-contract-vendor.svg': tileServiceContractVendor,
    'service-contract-vendor-engineer-licensing.svg': tileServiceContractVendorEngineerLicensing,
    'accreditation_add_scheme.svg': tileAccreditationAddScheme,
    'accreditation_settings.svg': tileAccreditationSettings,
    'accreditation_polices_procedures.svg': tileAccreditations,
    'dias_accreditation_polices_procedures.svg': tileAccreditationDIAS,
    'hospital_accreditation.svg': tileAccreditationHospital,
    'personal_protective_equipment.svg': tilePersonalProtectiveEquipment,
    'personal-protective-equipment-add.svg': tilePersonalProtectiveEquipmentAdd,
    'personal-protective-equipment-manage.svg': tilePersonalProtectiveEquipmentManage,
    'personal-protective-equipment-test-reports.svg': tilePersonalProtectiveEquipmentTestReports,
    'events.svg': tileEvents,
    'events-add-safety-risk-people.svg': tileEventsAddSafetyRiskPeople,
    'events-add-operational-risk-business.svg': tileEventsAddOperationalRiskBusiness,
    'events-add-radiation-risk.svg': tileEventsAddRadiationRisk,
    'events-manage.svg': tileEventsManage,
    'dose-reference-levels.svg': tileDoseReferenceLevels,
    'dose-reference-levels-ct-scanner.svg': tileDoseReferenceLevelsCTScanner,
    'dose-reference-levels-fluoroscopy-angiography.svg': tileDoseReferenceLevelsFlouroscopyAngiography,
    'radioactive-materials.svg': tileRadioactiveMaterials,
    'radioactive-materials-waste.svg': tileRadioactiveMaterialsWaste,
    'radioactive-materials-waste-add.svg': tileRadioactiveMaterialsWasteAdd,
    'radioactive-materials-waste-manage.svg': tileRadioactiveMaterialsWasteManage,
    'radioactive-materials-test.svg': tileRadioactiveMaterialsTest,
    'radioactive-materials-test-add.svg': tileRadioactiveMaterialsTestAdd,
    'radioactive-materials-test-manage.svg': tileRadioactiveMaterialsTestManage,
    'administration.svg': tileAdministration,
    'accounts.svg': tileAccounts,
    'accounts_vendors.svg': tileVendors,
    'accounts_vendors_add.svg': tileVendorsAdd,
    'accounts_vendors_manage.svg': tileVendorsManage,
    'administration-access.svg': tileAccess,
    'manufacturers.svg': tileManufacturers,
    'myrso-help.svg': tileHelp,
    'my-account.svg': tileAccount,
    'system.png': tileSystem,
    'user.svg': user,
    'user-gray.svg': userGray,
    'user-green.svg': userGreen,
    'user-red.svg': userRed,
    'user-yellow.svg': userYellow,
    'usd.svg': usd,
    'search.svg': search,
    'filter.svg': filter,
    'signature.svg': signature,
    'remove.svg': remove,
    'qrcode-scan.svg': qrcodeScan,
    'plus.svg': plus,
    'plus-gray.svg': plusGray,
    'phone.svg': phone,
    'percent.svg': percent,
    'ic_warning_white_18px.svg': warningWhite,
    'ic_warning_red_18px.svg': warningRed,
    'action-plan.svg': actionPlan,
    'action-task.svg': actionTask,
    'add-employees.svg': addEmployees,
    'add-manufacturers.svg': addManufacturers,
    'add-proposal-requests.svg': addProposalRequests,
    'add-vendors.svg': addVendors,
    'add_documents.svg': addDocuments,
    'administration_accounts.svg': administrationAccounts,
    'administration_accounts_add.svg': administrationAccountsAdd,
    'administration_accounts_manage.svg': administrationAccountsManage,
    'administration_clients.svg': administrationClients,
    'administration_head-office_add.svg': administrationHeadOfficeAdd,
    'administration_main-accounts_add.svg': administrationMainAccountsAdd,
    'administration_vendors.svg': administrationVendors,
    'approval.svg': approval,
    'arrow_left.svg': arrowLeft,
    'arrow_right.svg': arrowRight,
    'baseline-check_box-24px-green.svg': baselineCheckboxGreen,
    'calendar.svg': calendar,
    'check-in.svg': checkIn,
    'close.svg': close,
    'comment-gray.svg': commentGray,
    'contract-gray.svg': contractGray,
    'denial.svg': denial,
    'documents-black.svg': documentsBlack,
    'documents-gray.svg': documentsGray,
    'download.svg': download,
    'download_black.svg': downloadBlack,
    'edu.svg': edu,
    'edu_2.svg': edu2,
    'email.svg': email,
    'email-gray.svg': emailGray,
    'employees.svg': employees,
    'equipment.svg': equipment,
    'equipment-old.svg': equipmentOld,
    'equipment-type-report.svg': equipmentTypeReport,
    'equipment_black.svg': equipmentBlack,
    'export-gray.svg': exportGray,
    'faq.svg': faq,
    'file-gray.svg': fileGray,
    'file-red.svg': fileRed,
    'gallery-gray.svg': galleryGray,
    'global_user_management.svg': globalUserManagement,
    'ic_notifications_orange_18px.svg': notificationsOrange,
    'ic_notifications_white_18px.svg': notificationsWhite,
    'ic_picture_as_pdf_white_18px.svg': pictureAsPdfWhite,
    'input-modify.svg': inputModify,
    'input-remove.svg': inputRemove,
    'manage-employees.svg': manageEmployees,
    'manage-manufacturers.svg': manageManufacturers,
    'manage-proposal-requests.svg': manageProposalRequests,
    'manage-vendors.svg': manageVendors,
    'manage_documents.svg': manageDocuments,
    'manufacturers-old.svg': manufacturersOld,
    'more_vert.svg': moreVert,
    'proposal-requests.svg': proposalRequests,
    'qa_accreditation_polices_procedures.svg': qaAccreditationPolicesProcedures,
    'qc-tasks.svg': qcTasks,
    'radiology_q&a.svg': radiologyQA,
    'radiopharma.svg': radiopharma,
    'schedule.svg': schedule,
    'survey.svg': survey,
    'unfinished.svg': unfinished,
    'figure-loader.png': figureLoader,
    'add_accreditation_documents.svg': addAccreditationDocuments
};

export const getAsset = (key) => {
    return {
        type: key.split('.').pop(),
        src: assets[key]
    };
};
