import { reportingEquipmentInfoDetailsState } from './reporting-equipment-info.states';
import watch from 'redux-watch';
import has from 'lodash/has';

class ReportingEquipmentInfoController {
    constructor($ngRedux, $state, $uiRouterGlobals, $rootScope, $timeout, ReportingActions) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.ReportingActions = ReportingActions;
        this.equipment = null;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this.mapState, this.ReportingActions)(this);
        this.stateName = this.$state.current.name;

        const watchEquipment = watch(this.$ngRedux.getState, 'reporting.reportingEquipmentDetails.id');
        this.onEquipment = this.$ngRedux.subscribe(watchEquipment(id => {
            if (id) this.onEquipmentReady(id);
        }));

        if (has(this.$uiRouterGlobals.params, 'id'))
            this.getEquipmentInfo(this.$uiRouterGlobals.params.id);
    }

    onEquipmentSelect(event) {
        this.stateName = reportingEquipmentInfoDetailsState.name;
        if (event.item.id === this.equipment.id)
            this.onEquipmentReady(event.item.id);
        else
            this.getEquipmentInfo(event.item.equipment_id);
    }

    onGridReset() {
        this.$rootScope.$broadcast('SELECT_CASCADE_RESET', 'reportingEquipmentFilterForm');
    }

    onEquipmentReady(id) {
        if (id) {
            this.$ngRedux.dispatch({
                type: 'TAB_ENABLE',
                id: 'app.reporting.equipmentInfo',
                state: [
                    'app.reporting.equipmentInfo.details',
                    'app.reporting.equipmentInfo.serviceDetails',
                    'app.reporting.equipmentInfo.serviceHistory',
                    'app.reporting.equipmentInfo.inspections',
                    'app.reporting.equipmentInfo.history'
                ]
            });
            this.$timeout(() => {
                this.$ngRedux.dispatch({
                    type: 'TAB_SELECT',
                    id: 'app.reporting.equipmentInfo',
                    state: this.stateName,
                    stateParams: {id: id}
                });
            }, 200);
        }
    }

    $onDestroy() {
        this.unsubscribe();
        this.onEquipment();
    }

    mapState(state) {
        return {
            equipment: state.reporting.reportingEquipmentDetails
        }
    }
}

ReportingEquipmentInfoController.$inject = ['$ngRedux', '$state', '$uiRouterGlobals', '$rootScope', '$timeout', 'ReportingActions'];

export const reportingEquipmentInfo = {
    controller: ReportingEquipmentInfoController,
    template: `
        <reporting-equipment-info-grid on-select="$ctrl.onEquipmentSelect($event)" on-reset="$ctrl.onGridReset()"></reporting-equipment-info-grid>
    `
};
