export const radioactiveMaterialsState = {
    name: 'app.radioactiveMaterials',
    abstract: true,
    url: '/radioactive-materials'
};

export const radioactiveMaterialsDashboardState = {
    name: 'app.radioactiveMaterials.dashboard',
    url: '',
    component: 'dashboard'
};

export const rmWasteState = {
    name: 'app.radioactiveMaterials.waste',
    abstract: true,
    url: '/waste'
};

export const rmWasteDashboardState = {
    name: 'app.radioactiveMaterials.waste.dashboard',
    url: '',
    component: 'dashboard'
};

export const rmTestState = {
    name: 'app.radioactiveMaterials.test',
    abstract: true,
    url: '/test'
};

export const rmTestDashboardState = {
    name: 'app.radioactiveMaterials.test.dashboard',
    url: '',
    component: 'dashboard'
};
