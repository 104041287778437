/*
*   List of Actions with TYPE that should be passed from employees.reducer.js to import.reducer.js
 */

export const PREFIX = 'EMPLOYEES_IMPORT';

export const TYPES = {
     RESET_CHECKBOX:               `${PREFIX}_RESET_CHECKBOX`,
     FETCH_COST_CENTERS:           `${PREFIX}_FETCH_COST_CENTERS`,
     SELECT_IMPORT_ITEM:           `${PREFIX}_SELECT_IMPORT_ITEM`,
};
