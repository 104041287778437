/* @ngInject */
export function ProposalResponseService($http, API_BASE, ProposalResponse, ProposedDate, Laser) {

    this.fetchProposalResponseByVendor = function(vendorToken) {
        return $http({
            url: API_BASE + "/response/vendor/" + vendorToken,
            method: "GET"
        }).then(function(data) {
            var requestData = data.data;
            var equipmentData = data.data.equipment;
            var response = new ProposalResponse();

            response.id = requestData.proposal_request_id;
            response.account = requestData.account_name;
            response.proposalPurpose = requestData.purpose;
            response.deadline = requestData.deadline;

            equipmentData.forEach(function(elem) {
                var item = new Laser();
                item.id = elem.pre_id;
                item.equipment_id = elem.epr_id;
                item.documentUri = "";
                item.price = 0;
                item.account.name = elem.account;
                item.manufacturer.name = elem.manufacturer;
                item.serial = elem.serial_no;
                item.category.name = elem.equipment_category;
                item.model.name = elem.model;
                response.equipment.push(item);
            });
            return response;
        }, function(error) {
            return error;
        });
    };

    this.fetchProposalResponseByVendorAndResponseId = function(vendorToken, responseId) {
        return $http({
            url: API_BASE + "/response/vendor/" + vendorToken,
            method: "GET"
        }).then(function(data) {
            var requestData = data.data;
            var equipmentData = data.data.equipment;
            var response = new ProposalResponse();

            response.id = requestData.proposal_request_id;
            response.account = requestData.account_name;
            response.proposalPurpose = requestData.purpose;
            response.deadline = requestData.deadline;

            equipmentData.forEach(function(elem) {
                if (elem.pre_id === responseId) {
                    var item = new Laser();
                    item.user_friendly_id = elem.equipment_id;
                    item.id = elem.pre_id;
                    item.equipment_id = elem.epr_id;
                    item.documentUri = "";
                    item.price = 0;
                    item.account.name = elem.account;
                    item.manufacturer.name = elem.manufacturer;
                    item.serial = elem.serial_no;
                    item.category.name = elem.equipment_category;
                    item.model.name = elem.model;
                    response.equipment.push(item);
                }
            });
            return response;
        }, function(error) {
            return error;
        });
    };

    this.addProposalResponseByEquipment = function(eq, vendorToken) {
        return $http({
            url: API_BASE + "/proposal/response/byequipment",
            method: "POST",
            headers: {
                'X-Authorization': "Bearer " + vendorToken
            },
            data: {
                proposalEquipmentId: eq.id,
                price: eq.price,
                documentUri: eq.documentUri,
                comment: ""
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.addProposalResponseTotal = function(proposal, totalPrice, vendorToken) {
        return $http({
            url: API_BASE + "/proposal/response",
            method: "POST",
            headers: {
                'X-Authorization': "Bearer " + vendorToken
            },
            data: {
                proposalId: proposal.id,
                price: totalPrice,
                documentUri: proposal.documentUri,
                comment: ""
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.addProposalResponseAttachment = function(proposal, vendorToken) {
        return $http({
            url: API_BASE + "/proposal/response/bydocument",
            method: "POST",
            headers: {
                'X-Authorization': "Bearer " + vendorToken
            },
            data: {
                proposalId: proposal.id,
                documentUri: proposal.documentUri,
                comment: ""
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.proposeSchedulingDate = function(proposalId, vendorToken,dates) {
        return $http({
            url: API_BASE + "/proposal/" + proposalId + "/proposeSchedulingDate",
            method: "POST",
            headers: {
                'X-Authorization': "Bearer " + vendorToken
            },
            data: {
                vendorToken: vendorToken,
                date1: dates[1],
                date2: dates[2],
                date3: dates[3]
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.acceptProposedSchedulingDate = function(vendorToken, proposedDateId) {
        return $http({
            url: API_BASE + "/proposal/" + 5 + "/acceptSchedulingDate",
            method: "POST",
            data: {
                vendorToken: vendorToken,
                dateId: proposedDateId
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.fetchProposedSchedulingDates = function(proposalResponseId) {
        return $http({
            url: API_BASE + "/proposal/" + proposalResponseId + "/listSchedulingDates",
            method: "GET"
        }).then(function(data) {
            var arr = [];
            data.data.forEach(function(elem) {
                var propDates = new ProposedDate();
                propDates.proposedDateId = elem.dat_id;
                propDates.vendorUuid = elem.vendor_uuid;
                propDates.vendorUsername = elem.vendor_username;
                propDates.vendorFirstName = elem.vendor_first_name;
                propDates.vendorLastName = elem.vendor_last_name;
                propDates.genUuid = elem.subject_uuid;
                propDates.genUsername = elem.subject_username;
                propDates.genFirstName = elem.subject_first_name;
                propDates.genLastName = elem.subject_last_name;
                propDates.proposedDate = moment(elem.proposed_date).format('DD-MM-YYYY');
                propDates.statusCode = elem.status_code;
                propDates.statusName = elem.status_name;
                propDates.isCallerDoselUser = elem.is_caller_dosel_user;
                arr.push(propDates);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    this.addResponseReport = function(responseID, reportURI, reportDate, nextDueDate) {
        return $http({
            url: API_BASE + "/proposal/report",
            method: "POST",
            data: {
                in_pre_id: responseID,
                in_doc_uri: reportURI,
                in_return_date: reportDate,
                in_next_due_date: nextDueDate
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };
}
