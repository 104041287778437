import isUndefined from 'lodash/isUndefined';

/**
 * Creates a new TabsLoaderController.
 * @class
 */
class TabsLoaderController {
    constructor($filter) {
        // TODO
        this.$filter = $filter;
    }

    $onInit() {
        if (isUndefined(this.img))
            this.img = 'figure-loader.png';
        if (isUndefined(this.message))
            this.message = this.$filter('translate')("COMMON.GRID.LIST_PLEASE_WAIT");
    }
}

TabsLoaderController.$inject = ["$filter"];

export const tabsLoader = {
    bindings: {
        img: '@',
        icon: '@',
        message: '@'
    },
    controller: TabsLoaderController,
    template: `
        <div class="loaderContainer">
            <div class="loader">
                <asset ng-if="$ctrl.img" src="{{$ctrl.img}}" alt="Loading">
                <div>{{$ctrl.message}}</div>
            </div>
        </div>
    `
};
