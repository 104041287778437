export const selectLicenceType = {
    bindings: {
        module: '@',
        identifier: '@',
        disable: '<'
    },
    require : {
        form : '^form'
    },
    template: require('./select-licence-type.html'),
    controller: 'SelectLicenceTypeController'
};
