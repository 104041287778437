export const safetyTrainingPublicState = {
    name: 'public.safetyTraining',
    abstract: true,
    url: '/public/safety-training'
};

export const safetyTrainingDashboardPublicState = {
    name: 'public.safetyTraining.dashboard',
    url: '',
    component: 'dashboard'
};

export const safetyTrainingLaserPublicState = {
    name: 'public.safetyTraining.laser',
    abstract: true,
    url: '/laser'
};

export const safetyTrainingLaserDashboardPublicState = {
    name: 'public.safetyTraining.laser.dashboard',
    url: '',
    component: 'dashboard'
};

export const safetyTrainingLaserVideoPublicState = {
    name: 'public.safetyTraining.laser.video',
    redirectTo: 'public.safetyTraining.laser.video.player',
    url: '/video?token&presentation_review_id',
    component: 'tabs'
};

export const safetyTrainingLaserVideoPlayerPublicState = {
    name: 'public.safetyTraining.laser.video.player',
    url: '',
};
