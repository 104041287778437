/* @ngInject */
export function SelectEmployeeStatusController($ngRedux, SUBJECT_FILTER_STATUS) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, {})(this);
        ctrl.options = SUBJECT_FILTER_STATUS;
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    ctrl.onSelect = function() {
        $ngRedux.dispatch({
            type: 'EMPLOYEE_STATUS_SELECTED',
            id: ctrl.identifier,
            status: ctrl.selectedOption
        });
    };

    function mapStateToThis(state) {
        return {
            selectedOption: state.employees[ctrl.identifier].filter.status,
        };
    }
}
