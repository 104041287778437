import * as ImportActions from '../../import-settings.actions';
import get from 'lodash/get';
import {IDS} from "../../import-settings-action-id.constants";
import {fetchCostCenterDetails} from "../../import-settings.actions";
import watch from "redux-watch";
import isNull from "lodash/isNull";

class DetailsController {
    constructor($ngRedux, $stateParams, $timeout, Notifier, $filter) {
        this.$ngRedux = $ngRedux;
        this.$stateParams = $stateParams;
        this.$timeout = $timeout;
        this.Notifier = Notifier;
        this.$filter = $filter;
        this.identifier = IDS.empImportSettings;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(state => {
            const details = get(state.employees,`${IDS.empImportSettings}.${IDS.empImportSettingsCentersDetails}`);
            return {
                ...details,
            }
        }, ImportActions)(this);

        this.fetchCostCenterDetails(this.siteLocation);
    }

    onSubmit() {
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

DetailsController.$inject = ['$ngRedux', '$stateParams', '$timeout', 'Notifier', '$filter'];

export const empImportSettingsCentersDetails = {
    controller: DetailsController,
    template: require('./details.html')
};
