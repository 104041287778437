class ReportingController {
    constructor($log, NavigationService) {
        this.$log = $log;
        this.NavigationService = NavigationService;
    }

    $onInit() {
        this.$log.debug('Reporting::init');
        const navConfig = this.NavigationService.getNavConfig();
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;
    }
}

ReportingController.$inject = ['$log', 'NavigationService'];

export const reporting = {
    controller: ReportingController,
    template: `
        <div class="loader formHeader">
            <asset cls="color{{$ctrl.colorName}}" src="{{$ctrl.icon}}"></asset>
            <div class="color{{$ctrl.colorName}}">Coming soon...</div>
        </div>
    `
};
