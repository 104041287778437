import has from 'lodash/has';
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

/**
 * Creates a new InputPasswordStrengthController.
 * @class
 */
class InputPasswordStrengthController {
    constructor($element) {
        this.$element = $element;
        this.colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];
        this.hierarchy = ['very weak', 'weak', 'good', 'strong', 'very strong'];
    }

    $onChanges(changes) {
        if (has(changes, 'password')) {
            if (changes.password.currentValue === '' ||
                isNull(changes.password.currentValue) ||
                isUndefined(changes.password.currentValue)) {
                this.$element.css({"display": "none"});
            }
            else {
                const c = this.getColor(this.measureStrength(changes.password.currentValue));
                this.$element.css({"display": "inline"});
                this.$element.find("ul").children("li").css({"background": "#DDD"});
                angular.forEach(this.$element.find("ul").children("li"), (item, key) => {
                    if (key < c.idx) angular.element(item).css({"background": c.col});
                });
                this.label = c.desc;
            }
        }
    }

    measureStrength(p) {
        let _force = 0;
        const _regex = /[$-/:-?{-~!"^_`\[\]]/g;

        const _lowerLetters = /[a-z]+/.test(p);
        const _upperLetters = /[A-Z]+/.test(p);
        const _numbers = /[0-9]+/.test(p);
        const _symbols = _regex.test(p);

        const _flags = [_lowerLetters, _upperLetters, _numbers, _symbols];
        const _passedMatches = filter(_flags, el => { return el === true; }).length;

        _force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
        _force += _passedMatches * 10;

        // penality (short password)
        _force = (p.length <= 6) ? Math.min(_force, 10) : _force;

        // penality (poor variety of characters)
        _force = (_passedMatches === 1) ? Math.min(_force, 10) : _force;
        _force = (_passedMatches === 2) ? Math.min(_force, 20) : _force;
        _force = (_passedMatches === 3) ? Math.min(_force, 40) : _force;

        return _force;
    }

    getColor(s) {
        let idx = 0;
        if (s <= 10) { idx = 0; }
        else if (s <= 20) { idx = 1; }
        else if (s <= 30) { idx = 2; }
        else if (s <= 40) { idx = 3; }
        else { idx = 4; }

        return { idx: idx + 1, col: this.colors[idx], desc: this.hierarchy[idx] };
    }
}

InputPasswordStrengthController.$inject = ['$element'];

export const inputPasswordStrength = {
    bindings: {
        password: '<'
    },
    controller: InputPasswordStrengthController,
    template: `
        <div>
            <ul id="strength">
                <li class="point"></li>
                <li class="point"></li>
                <li class="point"></li>
                <li class="point"></li>
                <li class="point"></li>
            </ul>
            <span class="pointDesc">{{$ctrl.label}} password</span>
        </div>
    `
};
