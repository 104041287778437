/* @ngInject */
export function ProtectiveEquipmentManageController($controller, $ngRedux, $scope, $rootScope, $q, $timeout, $mdDialog, Notifier, ProtectiveEquipmentService, ProtectiveEquipmentTestService, ProtectiveEquipmentManager, EquipmentManager, EquipmentService, PersonalEquipment, PersonalEquipmentTest, PersonalEquipmentTestComment, Grid, FileImage, FileDocument) {

    angular.extend(this, $controller('ProtectiveEquipmentBaseController', {
        $ngRedux: $ngRedux,
        $scope: $scope,
        $rootScope: $rootScope,
        $q: $q,
        $timeout: $timeout,
        Notifier: Notifier,
        ProtectiveEquipmentService: ProtectiveEquipmentService,
        ProtectiveEquipmentTestService: ProtectiveEquipmentTestService,
        ProtectiveEquipmentManager: ProtectiveEquipmentManager,
        EquipmentManager: EquipmentManager,
        PersonalEquipment: PersonalEquipment,
        PersonalEquipmentTest: PersonalEquipmentTest,
        PersonalEquipmentTestComment: PersonalEquipmentTestComment,
        Grid: Grid,
        FileImage: FileImage,
        FileDocument: FileDocument
    }));

    $scope.MODULE_STATE = $scope.MODULE_STATE_UPDATE;
    $scope.EQUIPMENT_STATE = $scope.MODULE_STATE_UPDATE;

    let removeDialog = null;
    let unsubscribe = angular.noop;

    $scope.tabs = _.cloneDeep(ProtectiveEquipmentManager.tabs);

    $scope.onEquipmentSelected = function(event) {
        _.assign($scope.protectiveEquipment, event.item);
        $scope.executeTab();
    };

    $scope.onEquipmentRemove = (event) => {
        const confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Chosen equipment will be removed permanently.')
            .ariaLabel('Remove equipment')
            .ok('Yes')
            .cancel('No');

        removeDialog = $mdDialog.show(confirm).then(() => {
            EquipmentService.disposeEquipment(event.item.id).then(function() {
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'protectiveEquipmentGrid'
                });
                $mdDialog.cancel();
            }, function(error) {
                Notifier.error('System error! Please contact your admin.');
            });
        });
    };

    $scope.$onDestroy = function() {
        if (removeDialog) $mdDialog.hide(removeDialog);
        unsubscribe();
    };

    _.extend(_.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT]), {
        exec: (function() {
            var deferred = $q.defer();
            ProtectiveEquipmentService.getPersonalEquipmentDetails($scope.protectiveEquipment.id).then((function(equipment) {
                _.assign($scope.protectiveEquipment, equipment);
                this.enableTabs();
                _.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_HISTORY_DETAILS]).disabled = true;
                $scope.generateQRCode();

                ProtectiveEquipmentTestService.getComments($scope.protectiveEquipment.id).then(function(data) {
                    $scope.equipmentTestCommentList = data;
                });

                deferred.resolve();
            }).bind(this));
            return deferred.promise;
        }).bind(this),
        post: function() {
            var deferred = $q.defer();
            $ngRedux.dispatch({
                type: 'GRID_FILTER',
                id: 'protectiveEquipmentTestsGrid',
                equipmentId: $scope.protectiveEquipment.id
            });
            ProtectiveEquipmentTestService.getTesterCandidates($scope.protectiveEquipment.id).then(function(data) {
                $scope.equipmentTesters = data;
                deferred.resolve();
            }, function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_DETAILS]), {
        exec: function() {
            var deferred = $q.defer();
            ProtectiveEquipmentService.updatePersonalEquipment($scope.protectiveEquipment).then(function() {
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'protectiveEquipmentGrid'
                });
                Notifier.success('Equipment details successfully updated');
                deferred.resolve();
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_CREATE]), {
        exec: function() {
            var deferred = $q.defer();
            if ($scope.equipmentTest.comments.length > 0) {
                ProtectiveEquipmentTestService.addPersonalEquipmentTest($scope.protectiveEquipment.id, $scope.equipmentTest).then(function(id) {
                    $scope.equipmentTest.id = id;
                    $scope.protectiveEquipment.addTest($scope.equipmentTest);
                    Notifier.success('New Test was successfully added');
                    deferred.resolve(ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TESTS);
                }, function(error) {
                    deferred.reject(error);
                });
            } else {
                Notifier.error('First assign comment.');
            }
            return deferred.promise;
        },
        post: function() {
            var deferred = $q.defer();
            $ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: 'protectiveEquipmentTestsGrid'
            });
            deferred.resolve();
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TESTS]), {
        exec: function() {
            var deferred = $q.defer();
            ProtectiveEquipmentTestService.getPersonalEquipmentTestDetails($scope.equipmentTest.id).then(function(test) {
                $scope.equipmentTest = test;
                _.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS]).disabled = false;
                _.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS]).visible = true;
                deferred.resolve();
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS]), {
        exec: function() {
            var deferred = $q.defer();
            ProtectiveEquipmentTestService.updatePersonalEquipmentTest($scope.equipmentTest).then(function() {
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'protectiveEquipmentTestsGrid'
                });
                Notifier.success('Test was successfully updated');
                deferred.resolve();
            }, function(error) {
                Notifier.responseMessage(error);
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_HISTORY]), {
        exec: function() {
            var deferred = $q.defer();
            _.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_HISTORY_DETAILS]).disabled = false;
            deferred.resolve();
            return deferred.promise;
        }
    });
}
