import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import { getWaste } from '../rm-waste.actions';
import { getIsotopes } from '../../radioactive-materials.actions';

class RadioactiveMaterialsWasteGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'radioactiveMaterials';
        this.identifier = 'rmWasteGrid';
        this.filterTemplate = './rm-waste-grid-filter.html';

        this.columns = [
            {title: '#',                            mapper: null},
            {title: 'Site Location',                mapper: 'location'},
            {title: 'Bin ID',                       mapper: 'bin_no'},
            {title: 'Waste Type',                   mapper: 'waste_type'},
            {title: 'Isotope',                      mapper: 'isotope'},
            {title: 'Atomic Num',                   mapper: 'atomic_no'},
            {title: 'Date of Closure',              mapper: 'date_of_closure'},
            {title: 'Dose rate at Date of Closure', mapper: 'closure_dose'},
            {title: 'Est. Date of Disposal',        mapper: 'estimate_date_of_disposal'},
            {title: 'Date of Disposal',             mapper: 'date_of_disposal'},
            {title: 'Disposal Dose Rate',           mapper: 'disposal_dose'},
            {title: 'Meets Limit',                  mapper: ({ meets_limit }) => parseInt(meets_limit) ? 'Yes' : 'No'},
            {title: 'Disposed',                     mapper: ({ disposed }) => parseInt(disposed) ? 'Yes' : 'No'},
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this),
            { getWaste, getIsotopes }
        )(this);

        this.dataAction = this.getWaste;
        this.exportService = this.getWaste;
        this.filter.identifier = this.identifier;
        this.getIsotopes('rmWaste');
        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const rmWasteGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onRemove: '&',
        onReset: '&'
    },
    controller: RadioactiveMaterialsWasteGridController,
    ...gridTpl
};
