import { generateQRCode } from '../../../../common/utils/util.qrcode';

/* @ngInject */
export function ProtectiveEquipmentBaseController($controller, $ngRedux, $scope, $rootScope, $q, $timeout, Notifier, ProtectiveEquipmentService, ProtectiveEquipmentTestService, ProtectiveEquipmentManager, EquipmentManager, PersonalEquipment, PersonalEquipmentTest, PersonalEquipmentTestComment, Grid, FileImage, FileDocument) {

    $scope.protectiveEquipment = new PersonalEquipment();

    angular.extend(this, $controller('ApplicationModuleController', {
        $scope: $scope,
        $timeout: $timeout,
        Notifier: Notifier
    }));

    angular.extend(this, $controller('ProtectiveEquipmentBaseTestController', {
        $scope: $scope,
        Notifier: Notifier,
        ProtectiveEquipmentTestService: ProtectiveEquipmentTestService,
        ProtectiveEquipmentManager: ProtectiveEquipmentManager,
        PersonalEquipmentTest: PersonalEquipmentTest,
        PersonalEquipmentTestComment: PersonalEquipmentTestComment,
        Grid: Grid,
        FileDocument: FileDocument
    }));

    $scope.equipmentTypes = [];
    $scope.bodyProtection = [];
    $scope.leadEquivalences = [];
    $scope.equipmentHistory = [];
    $scope.equipmentHistoryActivity = {};

    $scope.onEquipmentHistorySelected = function(event) {
        $scope.equipmentHistoryActivity = event.item;
        $scope.executeTab();
    };

    $scope.$watch('selectedTab', (function(newTab) {
        switch (_.get(newTab, 'id', null)) {
            case ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT:
                this.disableTabs();
                this.hideTabs([ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS]);
                $rootScope.$broadcast('SELECT_CASCADE_RESET', 'equipmentForm');
                break;
            case ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_CREATE:
                $scope.TEST_STATE = $scope.MODULE_STATE_CREATE;
                this.hideTabs([ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS]);
                $scope.equipmentTest = new PersonalEquipmentTest();
                break;
            case ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TESTS:
                this.hideTabs([ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS]);
                break;
            case ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_HISTORY:
                this.disableTabs([ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_HISTORY_DETAILS]);
                $ngRedux.dispatch({
                    type: 'EQUIPMENT_HISTORY_SELECTED',
                    id: 'protectiveEquipmentHistoryGrid',
                    equipmentId: $scope.protectiveEquipment.id
                });
                break;
        }
    }).bind(this));

    $q.all([
        ProtectiveEquipmentService.getPersonalEquipmentTypes().then(function(data) {
            $scope.equipmentTypes = data;
        }),
        ProtectiveEquipmentService.getBodyProtection().then(function(data) {
            $scope.bodyProtection = data;
        }),
        ProtectiveEquipmentService.getPersonalLeadEquivalences().then(function(data) {
            $scope.leadEquivalences = data;
        })
    ]);

    $scope.$on('LOCATION_SUBJECT_SELECTED', function(event, locationItem) {
        $scope.protectiveEquipment.location.uuid = locationItem.uuid;
    });

    $scope.$watch('protectiveEquipment.equipmentId', function(newValue, oldValue) {
        if (oldValue === $scope.protectiveEquipment.barcode) {
            $scope.protectiveEquipment.barcode = newValue;
            $scope.generateQRCode();
        }
    });

    $scope.generateQRCode = () => {
        const { equipmentId, barcode } = $scope.protectiveEquipment;
        generateQRCode('PPE-QRCode', {
            id: barcode || equipmentId,
            action: 'equipment',
        });
    };

    $scope.printQRCode = () => {
        const { id, equipmentId, barcode } = $scope.protectiveEquipment;
        ProtectiveEquipmentService.printQRCode(id, barcode || equipmentId, 'equipment');
    };

    $scope.addEquipmentType = function() {
        ProtectiveEquipmentManager.addEquipmentType($scope.equipmentTypes);
    };
    $scope.$on(ProtectiveEquipmentManager.ADD_EQUIPMENT_TYPE_COMPLETE, function(event, equipmentType) {
        ProtectiveEquipmentService.getPersonalEquipmentTypes().then(function(data) {
            $scope.equipmentTypes = data;
            $scope.protectiveEquipment.type = _.find($scope.equipmentTypes, ['id', equipmentType.id]);
        });
    });

    $scope.addLeadEquivalence = function() {
        ProtectiveEquipmentManager.addLeadEquivalence();
    };
    $scope.$on(ProtectiveEquipmentManager.ADD_LEAD_EQUIVALENCE_COMPLETE, function(event, leadEquivalence) {
        $scope.leadEquivalences.push(leadEquivalence);
        $scope.protectiveEquipment.leadEquivalence = leadEquivalence;
    });

    $scope.addEquipmentTester = function() {
        ProtectiveEquipmentManager.addEquipmentTester($scope.protectiveEquipment.location.uuid);
    };
    $scope.$on(ProtectiveEquipmentManager.ADD_EQUIPMENT_TESTER_COMPLETE, function(event, equipmentTester) {
        $scope.equipmentTesters.push(equipmentTester);
        $scope.equipmentTest.tester = equipmentTester;
    });

    $scope.$on('FILE_UPLOAD_SUCCESS', function(event, filePath, uploader) {
        if ($scope.selectedTab.id == ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_DETAILS) {
            var imgObj = _.first(uploader.queue);
            var image = new FileImage();
            image.url = imgObj.filePath;
            image.type = imgObj.type;
            image.width = imgObj.width;
            image.height = imgObj.height;
            ProtectiveEquipmentService.addPersonalEquipmentImage($scope.protectiveEquipment.id, image).then(function(id) {
                image.id = id;
                $scope.protectiveEquipment.addImage(image);
                uploader.clearQueue();
                uploader.cancelAll();
                Notifier.success('Equipment image was successfully added');
            }, function(error) {
                Notifier.responseMessage(error);
            });
        }
    });
}
