export function licenceListDialog() {
    return {
        restrict: 'E',
        transclude: 'true',
        template: require('./licence-listing-dialog.html'),
        scope: {
            'subjectLocation': '@',
            'slo': '='
        },
        controller: ['$rootScope', '$scope', '$mdDialog', 'AuthService', 'NavigationService', 'Notifier', 'UserService','$http','Licence','LicenceService', licenceListController]
    };

    function licenceListController($rootScope, $scope, $mdDialog, AuthService, NavigationService, Notifier, UserService, $http, Licence, LicenceService) {
        UserService.licenceTypes('mng').then(function(items) {
            $scope.documentTypes = items;
        });

        $scope.viewLicence = function() {
            //TODO: Dupli poziv, sredi nekako
            if ($scope.subjectLocation !== null) {
                LicenceService.subjectLicences($scope.subjectLocation, 'loc').then(function(result){
                    var isAdmin = AuthService.getUser().type == 'Administrator';
                    var template = require('../../employees/views/licences/subject-licences-dialog.html');

                    if (isAdmin) template = require('../../employees/views/licences/subject-licences-edit-dialog.html');

                    $mdDialog.show({
                        controller: ListSubjectLicences,
                        template: template,
                        parent: angular.element(document.body),
                        locals: {
                            subject: "subject name",
                            items: result.items,
                            isAdmin: isAdmin,
                            types: $scope.documentTypes
                        },
                        clickOutsideToClose: true
                    })
                });
            }
        };

        function ListSubjectLicences($scope, subject, items, isAdmin, types) {
            $scope.headerColor = NavigationService.getNavConfig().colorName;
            $scope.items = items;
            $scope.isAdmin = isAdmin;
            $scope.documentTypes = types;
            $scope.subject = subject;
            $scope.inEdit = -1;

            $scope.cancel = function() {
                $mdDialog.cancel();
            };

            $scope.modifyLicence = function(index) {
                $scope.inEdit = index;
            };

            $scope.cancelLicence = function() {
                $scope.inEdit = -1;
            };

            $scope.saveLicence = function(index, item) {
                LicenceService.editLicence(item).then(function(data) {
                    if (data.status == 200 && !(data.data.lic_id == null || data.data.lic_ide == "null"  )) {
                        Notifier.success('Licence saved!');
                        $scope.inEdit   = -1;
                    }
                    else if (data.status == 200 && (data.data.lic_id == null || data.data.lic_id == "null"  )) {
                        Notifier.error('Problem with saving licence. Check expiry date or licence type.');
                    }
                    else if (data.status == 403) {
                        Notifier.error('Not able to save licence. Insufficient access rights.');
                        $scope.inEdit = -1;
                    }
                    else {
                        Notifier('Licence has not been saved.');
                        $scope.inEdit = -1;
                    }
                });
            }
        }
    }
}
