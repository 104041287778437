import { TAB_HIDE } from '../../../common/tabs/tabs.constants';

export const eventsOperationalRiskAddState = {
    name: 'app.events.addOperationalRiskBusiness',
    redirectTo: 'app.events.addOperationalRiskBusiness.details',
    url: '/add',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            id: 'eventItem',
            type: 'EVENT_RESET'
        });
        $ngRedux.dispatch({
            id: 'app.events.addOperationalRiskBusiness',
            type: TAB_HIDE,
            state: [
                'app.events.addOperationalRiskBusiness.assessmentThreat',
                'app.events.addOperationalRiskBusiness.assessmentIssue',
                'app.events.addOperationalRiskBusiness.assessmentHistoricalRecord',
                'app.events.addOperationalRiskBusiness.alertEscalate',
                'app.events.addOperationalRiskBusiness.actionPlan',
                'app.events.addOperationalRiskBusiness.actionPlanLibrary',
                'app.events.addOperationalRiskBusiness.actionTaskLibrary'
            ]
        });
    }
};

export const eventsOperationalRiskAddDetailsState = {
    name: 'app.events.addOperationalRiskBusiness.details',
    url: '?id',
};

export const eventsOperationalRiskAddAssessmentThreatState = {
    name: 'app.events.addOperationalRiskBusiness.assessmentThreat',
    url: '/assessment?id',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            id: 'app.events.addOperationalRiskBusiness',
            type: TAB_HIDE,
            state: ['app.events.addOperationalRiskBusiness.assessmentHistoricalRecord']
        });
    }
};

export const eventsOperationalRiskAddAssessmentHistoricalRecordState = {
    name: 'app.events.addOperationalRiskBusiness.assessmentHistoricalRecord',
    url: '/assessment/historical-record?id',
};

export const eventsOperationalRiskAddAssessmentIssueState = {
    name: 'app.events.addOperationalRiskBusiness.assessmentIssue',
    url: '/assessment?id',
};

export const eventsOperationalRiskAddAlertEscalateState = {
    name: 'app.events.addOperationalRiskBusiness.alertEscalate',
    url: '/alert-escalate?id',
};

export const eventsOperationalRiskAddActionPlanState = {
    name: 'app.events.addOperationalRiskBusiness.ActionPlan',
    url: '/action-plan?id',
};

export const eventsOperationalRiskAddActionPlanLibraryState = {
    name: 'app.events.addOperationalRiskBusiness.ActionPlanLibrary',
    url: '/action-plan-library',
    /* @ngInject */
    onExit: ($ngRedux) => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: 'app.events.addOperationalRiskBusiness',
            state: ['app.events.addOperationalRiskBusiness.actionPlanLibrary']
        });
    }
};

export const eventsOperationalRiskAddActionTaskLibraryState = {
    name: 'app.events.addOperationalRiskBusiness.ActionTaskLibrary',
    url: '/action-task-library',
    /* @ngInject */
    onExit: ($ngRedux) => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: 'app.events.addOperationalRiskBusiness',
            state: ['app.events.addOperationalRiskBusiness.ActionTaskLibrary']
        });
    }
};
