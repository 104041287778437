import API from '../../../../common/http/api.fetch';
import {success, responseMessage} from '../../../../common/utils/util.notifier';
import * as GRID from '../../../../common/grid/grid.constants';
import { TYPES } from '../adm-categorisation-action-type.constants';
import { IDS } from '../adm-categorisation-action-id.constants';
import I18N from '../../../../common/utils/util.i18n';
import {GRID_FETCH} from "../../../../common/grid/grid.constants";

export const fetchCenterTypesGrid = (filter) => {
    return dispatch => {
        return API.fetch({
            url: '/categorisation/center-types-grid',
            method: 'GET',
            params: {
                ...filter,
            }
        }).then(response => {
            response.data.items = response.data.items.map((item) => ({
                ...item,
                ahpra_licence_required: item.ahpra_licence_required ? 'Yes' : 'No',
                use_licence_required: item.use_licence_required ? 'Yes' : 'No',
            }));

            dispatch({
                type:   GRID_FETCH,
                id:     IDS.admCategorisation,
                path:   IDS.admCatCentersGrid,
                ...response.data,
            })
        }, error => {
            responseMessage(error);
        })
    }
}

export const getCenterTypeDetails = (typeId) => {
    return dispatch => {
        return API.fetch({
            url:    '/categorisation/center-type-details',
            method: 'GET',
            params: { typeId }
        }).then(response => {
            const key = `ACCREDITATION.SETTINGS.SCHEME.STATUS_${response.data.details.status_code.toUpperCase()}`
            let status_name = I18N.translate(key);
            if(status_name === key)
                status_name = response.data.details.status_name;
            dispatch({
                type:   TYPES.CENTER_DETAILS_REFRESH,
                id:     IDS.accreditationSettingsSchemeDetails,
                data: {
                    ...response.data.details,
                    status_name,
                }
            });
            return response.data.details
        }, error => {
            responseMessage(error);
        });
    };
}
