export function LocationDepartmentRsoFactory() {
    return function LocationDepartmentRso() {
        return {
            in_uuid: null,
            in_dep_uuid: null,
            in_username: null,
            in_password: '',
            in_first_name: '',
            in_last_name: '',
            in_email: '',
            in_phone: '',
            in_fax: '',
            in_birthdate: null,
            in_gender: null
        };
    };
}
