/**
 * @module root
 */
import uiRouter from '@uirouter/angularjs';
import ngMaterial from 'angular-material';
import ngSanitize from 'angular-sanitize';
import ngMessages from 'angular-messages';
import ngRedux from 'ng-redux';
import thunk from 'redux-thunk';
import reduxReset from 'redux-reset';
import logger from 'redux-logger'
import dirPagination from 'angular-utils-pagination';
import angularFileUpload from 'angular-file-upload';
import 'angular-translate';
import { APP_MODULE } from './common/app.module';
import { APP_COMPONENTS } from './components/migration.module';
import { APP_LEGACY } from './legacy/components.module';
import { initLocale } from './common/utils/util.i18n';
import { root } from './root.component';
import { rootReducer } from './root.reducer';
import * as Sentry from '@sentry/browser';
import { Angular as AngularIntegration } from '@sentry/integrations';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { mode, app, env, build } from './bootstrap.json';
import { version } from '../package.json';

const ROOT_MODULE = angular.module(
    'root',
    [
        uiRouter,
        ngMaterial,
        ngSanitize,
        ngMessages,
        ngRedux,
        dirPagination,
        'angularFileUpload',
        'pascalprecht.translate',
        APP_MODULE.name,
        APP_COMPONENTS.name,
        APP_LEGACY.name,
    ]
).config(['$locationProvider', '$translateProvider', '$ngReduxProvider', '$logProvider',
    ($locationProvider, $translateProvider, $ngReduxProvider, $logProvider) => {
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
        initLocale($translateProvider);
        const middleware = [thunk];
        if (mode !== 'production') middleware.push(logger);
        $ngReduxProvider.createStoreWith(rootReducer, middleware, [reduxReset()]);
        $logProvider.debugEnabled(mode !== 'production');
    }
]).component({ root });

if (mode === 'production') {
    Sentry.init({
        dsn: 'https://7f3c3f8c7b2147b9a253c81dbd382d32@o818352.ingest.sentry.io/5807853',
        release: `${app}@${version} ${build}`,
        environment: env,
        autoSessionTracking: true,
        integrations: [
            new AngularIntegration(),
            new TracingIntegrations.BrowserTracing(),
        ],
        tracesSampleRate: 0.2,
    });
    ROOT_MODULE.requires.push('ngSentry');
}

export default ROOT_MODULE;
