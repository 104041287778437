import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';

class ReportingEquipmentInfoServiceHistoryGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.grid_filter = $filter;
    }

    $onInit() {
        this.module = 'reporting';
        this.identifier = 'reportingEquipmentInfoServiceHistoryGrid';
        this.autoload = false;
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',            mapper: null},
            {title: this.grid_filter('translate')('REPORTING.TABS.SERVICE_HISTORY.GRID_DATE_ADDED'),   mapper: 'time'},
            {title: this.grid_filter('translate')('REPORTING.TABS.SERVICE_HISTORY.GRID_ISSUED_BY'),    mapper: 'issuerName'},
            {title: this.grid_filter('translate')('REPORTING.TABS.SERVICE_HISTORY.GRID_LOCATION'),     mapper: 'location.name'},
            {title: this.grid_filter('translate')('REPORTING.TABS.SERVICE_HISTORY.GRID_EXPIRES'),      mapper: 'expires'},
            {title: this.grid_filter('translate')('REPORTING.TABS.SERVICE_HISTORY.GRID_DOCUMENT'),     mapper: 'file-download'}
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), {})(this);
        this.dataAction = () => {};

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

ReportingEquipmentInfoServiceHistoryGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter'];

export const reportingEquipmentInfoServiceHistoryGrid = {
    bindings: {
        title: '@'
    },
    controller: ReportingEquipmentInfoServiceHistoryGridController,
    ...gridTpl
};
