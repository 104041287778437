/*
*   List of Action IDs that should be passed from accreditation.reducer.js to acc-documents.reducer.js
 */

export const IDS = {
    accDocuments: 'accDocuments',

    //paths
    accDocumentsGrid:            'accDocumentsGrid',
    accDocumentDetails:          'accDocumentDetails',
    accDocumentVersions:         'accDocumentVersions',
    accDocumentVersionsGrid:     'accDocumentVersionsGrid',

    // states
    app_accreditation_scheme_acc_documents:         'app.accreditation.scheme.acc-documents',

    // tab states:
    app_accreditation_scheme_acc_documents_list:    'app.accreditation.scheme.acc-documents.list',
    app_accreditation_scheme_acc_documents_add:     'app.accreditation.scheme.acc-documents.add',
    app_accreditation_scheme_acc_documents_details: 'app.accreditation.scheme.acc-documents.details',
    app_accreditation_scheme_acc_documents_versions:'app.accreditation.scheme.acc-documents.versions',
};