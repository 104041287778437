import * as EventsActionPlanActions from '../events-action-plan.actions'

/**
 * @const {object}
 * @memberOf module:Events
 */
export const eventsActionTaskLibrary = {
    /* @ngInject */
    controller: function($ngRedux, $state, $rootScope, $scope, NavigationService, $mdDialog, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(({ events }) => ({
                actionPlan: events.eventActionPlan
            }), {
                ...EventsActionPlanActions
            })(this);

            this.identifier = $state.$current.parent.name;
            this.state = `${$state.$current.parent.name}.actionTaskLibrary`;
        }

        this.onManage = (event) => {
            const actionTaskCtrl = ($scope) => {
                $scope.save = () => {
                    if ($scope.actionTaskDialogForm.$valid) {
                        this.manageActionTask({
                            tsk_id: this.actionPlan['taskLib.tsk_id'],
                            task_name: this.actionPlan['taskLib.task_name'],
                            task_details: this.actionPlan['taskLib.details']
                        }).then(() => {
                            $mdDialog.hide();
                        }).catch((err) => {
                            $scope.actionTaskDialogForm.action_task_details_name.$setValidity('unique', false);
                            Notifier.error(err.data);
                        });
                    }
                };
                $scope.cancel = () => {
                    $mdDialog.cancel();
                };
            };

            this.dialog = $mdDialog.show({
                controller: actionTaskCtrl,
                template: require('./dialogs/events-action-task-library-details-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                onShowing: () => {
                    if (event) this.setActionTaskLibraryDetails(event.item);
                }
            }).then(this.resetActionTaskLibraryDetails, this.resetActionTaskLibraryDetails);
        };

        this.onRemove = (event) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen Action Task will be removed permanently.')
                .ariaLabel('Remove Action Task')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.deleteActionTask(event.item.tsk_id);
            }, angular.noop);
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            if (this.dialog)
                $mdDialog.hide(this.dialog);
        };
    },
    template: `
        <events-action-task-library-grid
            on-select="$ctrl.onManage($event)" 
            on-remove="$ctrl.onRemove($event)">
        </events-action-task-library-grid>
        <tabs-navigation 
            identifier="{{$ctrl.identifier}}"
            state="{{$ctrl.state}}"
            on-exec="$ctrl.onManage()">
        </tabs-navigation>
        `
};
