import { IDS } from '../../import-action-id.constants';
import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';
import * as ImportActions from '../../import.actions';
import {GRID_FILTER} from "../../../../../common/grid/grid.constants";
import watch from "redux-watch";

class DetailsGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter, $mdDialog, $scope, $rootScope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
        this.$rootScope = $rootScope;
    }

    $onInit() {
        this.module = 'employees';
        this.identifier = IDS.empImport;
        this.statePath = IDS.empImportHistoryDetailsGrid;
        this.filterTemplate = './details-grid-filter.html';
        this.exportComponent = false;
        this.title = false;
        this.icon = false;
        this.confirmDialogOnRemove = true;
        //this.autoload = false;
        this.filterOpen = true;
        this.filterExpandButtonVisible = false;

        this.columns = [
            {title: '#', mapper: null},
            // {title: '#', mapper:'id'},
            //{title: 'Company Name', mapper:'company_name'},
            //{title: 'Cost Center', mapper:'cost_center_code'},
            {title: this.$filter('translate')('COMMON.FIELD.SITE_LOCATION'), mapper:'slo_name'},
            //{title: this.$filter('translate')('COMMON.FIELD.TITLE'), mapper:'title'},
            //{title: this.$filter('translate')('COMMON.FIELD.FIRST_NAME'), mapper:'first_name'},
            //{title: this.$filter('translate')('COMMON.FIELD.LAST_NAME'), mapper:'last_name'},
            {title: this.$filter('translate')('COMMON.FIELD.NAME'), mapper:'name'},
            {title: this.$filter('translate')('COMMON.FIELD.OCCUPATION'), mapper:'occupation'},
            {title: this.$filter('translate')('COMMON.FIELD.EMAIL'), mapper:'email'},
            //{title: this.$filter('translate')('COMMON.FIELD.DESCRIPTION'), mapper:'description'},
            // {title: 'Imported', mapper:'imported'},
            {title: 'User Id', mapper:'usr_id'},
            {title: 'Action', mapper:'action'},
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), ImportActions)(this);

        const $rootScope = this.$rootScope;
        const watchReset = watch(this.$ngRedux.getState, `${this.module}.${this.identifier}.${this.statePath}.reset`);
        this.unsubscribeOnReset = this.$ngRedux.subscribe(watchReset(function() {
            $rootScope.$broadcast('SELECT_CASCADE_RESET', 'empImportHistoryDetailsGridFilterForm');
        }));

        this.dataAction = this.fetchImportHistoryDetailsGrid;

        this.filterActions.onChange = ({input}) => {
            this.$ngRedux.dispatch({
                type: GRID_FILTER,
                id: this.identifier,
                path: this.statePath,
                filter: {
                    import_status: input === 'all' ? null : input
                }
            });
        }
        this.init();
    }

    $onDestroy() {
        if(this.unsubscribeOnReset)
            this.unsubscribeOnReset()

        this.destroy();
    }

}

DetailsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter', '$mdDialog', '$scope', '$rootScope'];

export const empImportHistoryDetailsGrid = {
    bindings: {
        onSelect: '&',
    },
    controller: DetailsGridController,
    ...gridTpl
}
