import watch from 'redux-watch';
import { resolveTpl, verifyAccess } from '../../personal-dosimetry.utils';
import has from 'lodash/has';

export const personalDosimetryReportsDetails = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $uiRouterGlobals, $mdDialog, PersonalDosimetryActions, Notifier, $filter) {
        this.template = resolveTpl('pd-reports-details');

        this.$onInit = () => {
            this.tabsState = $state.$current.parent.name;
            switch (this.tabsState) {
                case 'app.personalDosimetry.import':
                    this.currentState = 'app.personalDosimetry.import.details';
                    break;
                case 'app.personalDosimetry.reports':
                    this.currentState = 'app.personalDosimetry.reports.details';
                    break;
            }

            this.unsubscribe = $ngRedux.connect(({ personalDosimetry }) => ({
                itemsSelect: personalDosimetry.personalDosimetryReportsDetailsGrid.itemsSelect,
                filter: personalDosimetry.personalDosimetryReportsDetailsGrid.filter,
                participantList: personalDosimetry.personalDosimetryParticipant.participantList,
            }), PersonalDosimetryActions)(this);

            if (has($uiRouterGlobals.params, 'id')) {
                if (verifyAccess()) {
                    const watchGridInit = watch($ngRedux.getState, 'personalDosimetry.personalDosimetryReportsDetailsGrid.init');
                    this.onGridInit = $ngRedux.subscribe(watchGridInit((init) => {
                        if (init) {
                            $ngRedux.dispatch({
                                type: 'REPORT_SELECTED',
                                id: 'personalDosimetryReportsDetailsGrid',
                                reportId: $uiRouterGlobals.params.id,
                            });
                            this.onGridInit();
                        }
                    }));
                } else {
                    $ngRedux.dispatch({
                        type: 'REPORT_SELECTED',
                        id: 'personalDosimetryReportsDetailsGrid',
                        reportId: $uiRouterGlobals.params.id,
                    });
                }
            }
        };

        this.onSelect = ({ item }) => {
            this.getIndividualDoseReport($state.$current.parent.name, item.id).then(() => {
                $ngRedux.dispatch({
                    type: 'TAB_SHOW',
                    id: $state.$current.parent.name,
                    state: [$state.current.name + 'Result'],
                    stateParams: {
                        id: $uiRouterGlobals.params.id,
                        measurement: item.id,
                    },
                    data: item,
                });
            });
        }

        this.onNotify = () => {
            if (this.itemsSelect.length) {
                this.dialog = $mdDialog.show({
                    controller: function (scope, ctrl) {
                        scope.loading = true;
                        ctrl.getReportDetails({
                            ...ctrl.filter,
                            limit: -1,
                        }, false).then(({ items }) => {
                            scope.data = items.filter((i) => ctrl.itemsSelect.includes(String(i.dos_id)));
                            $ngRedux.dispatch({
                                type: 'PARTICIPANT_LIST',
                                id: 'personalDosimetryParticipantList',
                                data: scope.data.map((i) => i.employee_email),
                            });
                            scope.loading = false;
                        });
                        scope.send = () => {
                            if (Object.keys(ctrl.participantList).length) {
                                if (scope.pdParticipantNotifyForm.$valid) {
                                    ctrl.sendNotificationGroup(scope.data.map((i, key) => ({
                                        id: i.imp_id,
                                        measurement: i.dos_id,
                                        email: ctrl.participantList[key],
                                    })));
                                    $mdDialog.cancel();
                                }
                            } else {
                                Notifier.error('Please enter at least one email');
                            }
                        };
                        scope.cancel = $mdDialog.cancel;
                    },
                    locals: { ctrl: this },
                    template: require('./dialogs/pd-notifications-dialog.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                }).then(
                    () => $ngRedux.dispatch({ type: 'PARTICIPANT_RESET' }),
                    () => $ngRedux.dispatch({ type: 'PARTICIPANT_RESET' }),
                );
            }
            else {
                Notifier.error($filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.NOTIFY_SEND_ERROR'));
            }
        }

        this.$onDestroy = () => {
            this.unsubscribe && this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        };
    },
    template: '<div ng-include src="$ctrl.template"></div>'
};
