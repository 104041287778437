import API from '../../../common/http/api.fetch';
import * as TYPE from '../radioactive-materials.actions.types';
import { formatDate } from '../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../common/utils/util.constants';

export const getWaste = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/waste`,
            method: 'GET',
            params: {
                location: filter.location,
                isotope: filter.isotope,
                binNum: filter.binNum,
                dateOfClosureFrom: formatDate(filter.dateOfClosureFrom, DATE_FORMAT.OUTPUT),
                dateOfClosureTo: formatDate(filter.dateOfClosureTo, DATE_FORMAT.OUTPUT),
                dateOfDisposalFrom: formatDate(filter.dateOfDisposalFrom, DATE_FORMAT.OUTPUT),
                dateOfDisposalTo: formatDate(filter.dateOfDisposalTo, DATE_FORMAT.OUTPUT),
                offset: filter.offset,
                limit: filter.limit,
                exportFormat: filter.exportFormat,
                exportColumns: filter.exportColumns
            }
        }).then(({ data }) => {
            if (filter.exportFormat) {
                return data;
            }
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.items,
                itemsCount: parseInt(data.items_count)
            })
        });
    }
}

export const saveWaste = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/waste/save`,
            method: 'POST',
            data: {
                id: data.id,
                location: data.location,
                visualId: data.visualId,
                wasteType: data.wasteType,
                isotope: data.isotope,
                atomicNum: data.atomicNum,
                binNum: data.binNum,
                dateOfClosure: formatDate(data.dateOfClosure, DATE_FORMAT.OUTPUT),
                dateOfClosureDose: data.dateOfClosureDose,
                closureUnit: data.closureUnit,
                disposalReach: data.disposalReach,
                disposalReachDate: formatDate(data.disposalReachDate, DATE_FORMAT.OUTPUT),
                dateOfDisposal: formatDate(data.dateOfDisposal, DATE_FORMAT.OUTPUT),
                dateOfDisposalDose: data.dateOfDisposalDose,
                disposalUnit: data.disposalUnit,
                permitLicenseNo: data.permitLicenseNo,
                signature: data.signature,
                gallery: data.gallery.length ? data.gallery.map((item) => item.id).join(',') : null
            }
        }).then(() => {
            if (data.id) {
                dispatch({
                    type: 'GRID_RELOAD',
                    id: 'rmWasteGrid'
                })
            } else {
                dispatch({
                    type: TYPE.RM_WASTE_RESET,
                    id: 'rmWaste',
                })
            }
        });
    }
}

export const getWasteDetails = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/waste/details`,
            method: 'GET',
            params: { id }
        }).then(({ data }) => {
            dispatch({
                type: TYPE.RM_WASTE_DETAILS,
                id: 'rmWaste',
                data: { id, ...data[0][0] },
                locations: data[1],
                gallery: data[2].map(({ file_id, file_url, file_width, file_height }) => {
                    return {
                        id: parseInt(file_id),
                        url: API.img(file_url),
                        width: parseInt(file_width),
                        height: parseInt(file_height)
                    }
                })
            })
        });
    }
};

export const deleteWaste = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/waste/delete`,
            method: 'GET',
            params: { id }
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'rmWasteGrid'
            })
        });
    }
};

export const getWasteTypes = () => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/waste/types`,
            method: 'GET',
            params: {}
        }).then(({ data }) => {
            dispatch({
                type: TYPE.RM_WASTE_TYPES,
                id: 'rmWaste',
                path: 'wasteTypes',
                items: data.map((item) => {
                    return {
                        value: item.type_id,
                        label: item.name
                    };
                }),
            })
        });
    }
}

export const getWasteDisposalReach = () => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/waste/disposal-reach`,
            method: 'GET',
            params: {}
        }).then(({ data }) => {
            dispatch({
                type: TYPE.RM_WASTE_DISPOSAL_REACH,
                id: 'rmWaste',
                path: 'disposalReachList',
                items: data.map((item) => {
                    return {
                        value: item.type_id,
                        label: item.name
                    };
                }),
            })
        });
    }
}

export const getWasteDisposalReachEstimate = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/waste/disposal-reach-estimate`,
            method: 'POST',
            data: {
                ...data,
                dateOfClosure: formatDate(data.dateOfClosure, DATE_FORMAT.OUTPUT)
            }
        }).then(({ data: { params } }) => {
            dispatch({
                type: TYPE.RM_WASTE_DISPOSAL_REACH_ESTIMATE,
                id: 'rmWaste',
                path: 'disposalReachDate',
                value: formatDate(params.esitmate_date_of_disposal, DATE_FORMAT.OUTPUT)
            })
        });
    }
}
