/* @ngInject */
export function AccTechniqueChartsController($controller, $ngRedux, $state, $uiRouterGlobals, $scope, $rootScope,
                                      $q, $timeout, NavigationService, Notifier, EquipmentServiceXray,
                                      EquipmentManager, ManufacturerService, DocumentService, DocumentManager, VendorService,
                                      AccreditationDocumentsService) {

    angular.extend(this, $controller('ApplicationModuleController', {
        $scope: $scope,
        $timeout: $timeout,
        Notifier: Notifier
    }));

    $scope.MODULE_STATE     = $scope.MODULE_STATE_UPDATE;
    $scope.EQUIPMENT_STATE  = $scope.MODULE_STATE_UPDATE;

    $scope.gridIdentifier = 'equipmentTechniqueChartsGrid';

    $scope.templateFilter           = './equipment-optimized-charts-filter.html';
    $scope.templateDetails          = './equipment-optimized-charts-details.html';
    // $scope.templateUpload           = './equipment-document-upload.html';

    var navConfig = NavigationService.getNavConfig();
    $scope.moduleColor = navConfig.colorName;
    $scope.moduleIcon = navConfig.icon;

    $scope.locations = [];
    $scope.enableEquipmentRegistration = false;
    $scope.equipmentModels = [];
    $scope.xrayTubeHousingModels = [];
    $scope.xrayTubeInsertModels = [];

    // TODO create factory with reset method
    $scope.doc = {
        type: null,
        inspectionDate: null
    };

    $scope.onEquipmentGridReset = function() {
        $rootScope.$broadcast('SELECT_CASCADE_RESET', 'equipmentFilterForm');
    };

    $scope.tabs = _.cloneDeep(EquipmentManager.tabs);
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_TECHNIQUE_CHARTS]).visible = true;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_TECHNIQUE_CHARTS]).disabled = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_LISTING]).visible = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_LISTING]).disabled = true;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]).visible = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]).disabled = true;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_MODIFY]).visible = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_MODIFY]).disabled = true;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_VENDORS]).visible = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_VENDORS]).disabled = true;

    $scope.$watch('selectedTabIndex', function(newTab) {
        //there is only one tab EQUIPMENT_DOCUMENT_FILE_MANAGEMENT
        $ngRedux.dispatch({
            type: 'EQUIPMENT_DOCUMENT_FILE_MANAGEMENT',
            id: $scope.gridIdentifier,
            fileManagement: 'chr' //from charts
        });
        $rootScope.$broadcast('SELECT_CASCADE_RESET', 'equipmentForm');
        $scope.locations = [];
    });

    $scope.$on('LOCATION_SUBJECT_SELECTED', function(event, locationItem, formName) {

        $ngRedux.dispatch({
            type: 'EQUIPMENT_DOCUMENT_FILE_MANAGEMENT',
            id: $scope.gridIdentifier,
            fileManagement: 'chr' //from charts
        });

        if (formName == 'equipmentFilterForm') {
            $scope.location = locationItem.uuid;
        }
        else {
            $scope.equipment.location.uuid = locationItem.uuid;
            $scope.equipment.account_id = locationItem.in_account_id;
        }
    });

    $scope.showDocuments = function() {
        DocumentManager.showEquipmentDocuments($scope.equipment);
    };

    $scope.initUploadForm = function(form) {
        $scope.uploadForm = form;
    };

    $scope.$on('FILE_UPLOAD_SUCCESS', function(event, filePath, uploader) {
        var doc = {
            id: $scope.equipment.equipment_id,
            type: $scope.doc.type.id,
            issued: "",
            expires: $scope.doc.expires,
            uri: filePath,
            inspected: null,
            inspector: null,
            passed: null,
            comment: null
        };

        if ($scope.doc.type.id == 4) {
            doc.inspected = $scope.doc.inspectionDate;
            doc.inspector = $scope.doc.inspector.uuid;
            doc.result = $scope.doc.result;
            doc.comment = $scope.doc.comment;
            doc.number = $scope.doc.number;
        }

        DocumentManager.saveDocument(doc).then(function() {
            $scope.uploadForm.$setPristine();
            $scope.uploadForm.$setUntouched();
            DocumentManager.clearUploader(uploader, $scope.doc);
            $scope.showDocuments();
        }, function(error) {
            DocumentManager.clearUploader(uploader, $scope.doc);
            Notifier.responseMessage(error);
        });
    });

    $scope.manageEquipmentService = function(serviceType) {
        EquipmentManager.manageEquipmentService($scope.equipment.equipment_id, serviceType);
    };

    $scope.onEquipmentServiceVendor = function(serviceType) {
        EquipmentManager.equipmentServiceVendorSelected($scope.equipment.equipment_id, serviceType);
    };

    if ($uiRouterGlobals.params.id)
        $scope.selectItem({equipment_id: $uiRouterGlobals.params.id});

    EquipmentServiceXray.getCategories().then(function(items) {
        $scope.categories = items;
    });

    ManufacturerService.fetchManufacturers().then(function(items) {
        $scope.manufacturers = items;
    });

    DocumentService.fetchEquipmentDocumentTypes().then(function(items) {
        $scope.documentTypes = items;
    });

    VendorService.fetchInspectors().then(function(items) {
        $scope.inspectors = items;
    });

    $scope.manufacturerSelected = function() {
        ManufacturerService.fetchEquipmentModels($scope.equipment.manufacturer.id, $scope.equipment.category.id).then(function(items) {
            $scope.equipmentModels = items;
        });
    };

    $scope.manufacturerHousingSelected = function() {
        ManufacturerService.fetchEquipmentModels($scope.equipment.tubeHousing.manufacturer.id, $scope.equipment.tubeHousing.type.id).then(function(items) {
            $scope.xrayTubeHousingModels = items;
        });
    };

    $scope.manufacturerInsertSelected = function() {
        ManufacturerService.fetchEquipmentModels($scope.equipment.tubeInsert.manufacturer.id, $scope.equipment.tubeInsert.type.id).then(function(items) {
            $scope.xrayTubeInsertModels = items;
        });
    };

    $scope.onEquipmentDocUpload = function(event) {
        EquipmentManager.onDocUpload($scope.gridIdentifier, event.item);
    };

    $scope.onEquipmentDocDownload = function(event) {
        EquipmentManager.onDocDownload($scope.gridIdentifier, event.item);
    };

    $scope.onEquipmentGenerateReport = function(event) {
        EquipmentManager.onGenerateReport($scope.gridIdentifier, event.item);
    };

}
