export const resolveCredentialingProgress = (status) => {
    switch (status) {
        case 'rej':
            return 0;
        case 'sen':
        case 'pen':
            return 1;
        case 'pro':
            return 2;
        case 'sub':
            return 3;
        case 'app':
            return 4;
        default:
            return null;
    }
}
