import ComponentBaseController from '../../component/component.base.controller';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import has from 'lodash/has';

/**
 * Creates a new InputController.
 * @class
 * @extends module:root/components.ComponentBaseController
 * @memberOf module:root/common/form
 * @see {@link https://docs.angularjs.org/guide/component|AngularJS Component}
 */
class InputController extends ComponentBaseController {
    /**
     * @constructs
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     */
    constructor($ngRedux) {
        super($ngRedux);
        /** @member {*} module:root/common/form.InputController#actions */
        this.actions = {};
    }

    /**
     * @method $onInit
     * @memberof module:root/common/form.InputController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.init();
    }

    /**
     * @method init
     * @memberof module:root/common/form.InputController
     */
    init() {
        this.initBase();

        if (typeof this.identifier === 'undefined') {
            const formId = this.form.$$element.attr('id');
            this.identifier = (typeof formId === 'undefined') ? this.form.$$parentForm.$$element.attr('id') : formId;
            if (typeof this.identifier === 'undefined')
                throw new Error('Missing form id or input identifier attribute');
        }

        if (typeof this.statePath === 'undefined')
            throw new Error('Missing input state-path attribute');

        if (typeof this.required === 'undefined')
            this.required = false;

        if (typeof this.disabled === 'undefined')
            this.disabled = false;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);
        if (this.statePath && isUndefined(this.name)) {
            this.name = this.getPath().replace(/\./g, '-');
        }
    }

    /**
     * @method $onChanges
     * @param changes {object}
     * @memberof module:root/common/form.InputController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onChanges(changes) {
        this.onChanges(changes);
    }

    /**
     * @method onChanges
     * @param changes {object}
     * @memberof module:root/common/form.InputController
     */
    onChanges(changes) {
        if (has(changes, 'stateIndex')) {
            if (!changes.stateIndex.isFirstChange()) {
                this.unsubscribe();
                this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);
            }
        }
    }

    /**
     * @method $onDestroy
     * @memberof module:root/common/form.InputController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.destroy();
    }

    /**
     * @method destroy
     * @memberof module:root/common/form.InputController
     */
    destroy() {
        if(this.unsubscribe)
            this.unsubscribe();
        this.destroyBase();
    }

    /**
     * @method mapState
     * @param state {object} Redux Store
     * @return {object}
     * @memberof module:root/common/form.InputController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapState(state) {
        return {
            /** @member {*} module:root/common/form.InputController#input */
            input: this.getValue(state)
        }
    }

    /**
     * @method getValue
     * @param state {object} Redux Store
     * @return {*}
     * @memberof module:root/common/form.InputController
     */
    getValue(state) {
        if ((typeof this.stateProp !== 'undefined' && this.stateProp !== '')
        || (typeof this.stateIndex !== 'undefined')) {
            return get(state, this.getPath());
        }
        return get(state, [this.module, this.identifier, ...this.statePath.split('.')]);
    }

    /**
     * @method getPath
     * @return {string}
     * @memberof module:root/common/form.InputController
     */
    getPath() {
        if (typeof this.stateProp !== 'undefined' && this.stateProp !== '')
            return [this.module, this.identifier, this.statePath + `[${this.stateIndex}]`, this.stateProp].join('.');

        if (typeof this.stateIndex !== 'undefined')
            return [this.module, this.identifier, this.statePath + `[${this.stateIndex}]`].join('.');

        return [this.module, this.identifier, this.statePath].join('.');
    }
}

InputController.$inject = ['$ngRedux'];

export default InputController;
