export const reportingState = {
    name: 'app.reporting',
    abstract: true,
    url: '/reporting'
};

export const reportingDashboardState = {
    name: 'app.reporting.dashboard',
    url: '',
    component: 'dashboard'
};
