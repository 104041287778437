/* @ngInject */
export function MammoQCPlotDiagramFilterFactory(Location, MammoQCEquipment) {
    function MammoQCPlotDiagramFilter() {

        this.visible = false;
        this.dateFrom = null;
        this.dateTo = null;
        this.mammoQCEquipment = new MammoQCEquipment();

        this.reset = function () {
            this.visible = false;
            this.equipmentId = null;
            this.dateFrom = null;
            this.dateTo = null;
            this.location = new Location();
            this.mammoQCEquipment = new MammoQCEquipment();
        };
    }

    return MammoQCPlotDiagramFilter;
}
