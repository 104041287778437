import watch from 'redux-watch';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

class SelectCountryStateController {
    constructor($ngRedux, CountryStateActions, $filter) {
        this.$ngRedux = $ngRedux;
        this.CountryStateActions = CountryStateActions;
        this.$filter = $filter
    }

    $onInit() {
        this.labelCountry = this.$filter('translate')("COMMON.SELECT.COUNTRY");
        this.labelState = this.$filter('translate')("COMMON.SELECT.STATE");

        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.CountryStateActions)(this);

        const watchChange = watch(this.$ngRedux.getState, this.module + '.' + this.identifier + '.' + this.countryPath);
        this.onCountry = this.$ngRedux.subscribe(watchChange(newValue => {
            this.country = newValue;
            if (newValue) this.getCountryStates(this.identifier, this.path, newValue);
        }));

        if (isUndefined(this.required))
            this.required = true;

        this.country = this.countryModel;
        this.getCountries();
    }

    onCountrySelect() {
        if (this.country && this.country !== this.countryModel) {
            this.$ngRedux.dispatch({
                type: 'COUNTRY_SELECTED',
                id: this.identifier,
                path: this.path,
                country: this.country
            });
            this.$ngRedux.dispatch({
                type: 'COUNTRY_STATE_SELECTED',
                id: this.identifier,
                path: this.path,
                countryState: null
            });
        }
    }

    onStateSelect() {
        if (this.stateModel) {
            this.$ngRedux.dispatch({
                type: 'COUNTRY_STATE_SELECTED',
                id: this.identifier,
                path: this.path,
                countryState: this.stateModel
            });
        }
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeModule();
        this.onCountry();
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        }
    }

    mapState(state) {
        return {
            countries: state.app.countries,
            countryStates: state[this.module][this.identifier][this.path],
            countryModel: get(state[this.module][this.identifier], this.countryPath, null),
            stateModel: get(state[this.module][this.identifier], this.statePath, null)
        }
    }
}

SelectCountryStateController.$inject = ['$ngRedux', 'CountryStateActions', '$filter'];

export const selectCountryState = {
    bindings: {
        identifier: '@',
        countryPath: '@',
        statePath: '@',
        path: '@',
        required: '<'
    },
    require : {
        form : '^form'
    },
    controller: SelectCountryStateController,
    template: require('./select-country-state.html')
};
