/* @ngInject */
export function SelectSiteLocationCenterTypeController($ngRedux, AdministrationActions) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);
        ctrl.getSiteLocationCenterTypes('administrationAccountsSiteLocation');
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            $ngRedux.dispatch({
                type: 'SITE_LOCATION_CENTER_TYPE_SELECTED',
                id: 'administrationAccountsSiteLocation',
                centerType: ctrl.selectedOption
            });
        }
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    function mapStateToThis(state) {
        return {
            options: state.administration.administrationAccountsSiteLocation.centerTypes,
            selectedOption: state.administration.administrationAccountsSiteLocation.data.type,
            siteLocationUuid: state.administration.administrationAccountsSiteLocation.data.uuid
        };
    }
}
