import { saveVendorInsurance } from '../../cre-vendors.actions';
import { CRE_VENDOR_DOC_REMOVE } from '../../cre-vendors.actions.types';
import { formatDate } from '../../../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../../../common/utils/util.constants';
import findIndex from 'lodash/findIndex';

/**
 * @const {object}
 * @memberOf module:Credentialing
 */
export const creVendorsInsurance = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $location, DocumentManager, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.title = 'Insurance Information';
            this.unsubscribe = $ngRedux.connect(({ credentialing }) => ({
                creVendor: credentialing.creVendor,
            }), { saveVendorInsurance })(this);
            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.insurance`;
            this.token = $location.search().token;
        }

        this.onDocDownload = ({ doc_url }) => {
            DocumentManager.downloadFile(doc_url, 4);
        };

        this.onDocRemove = (doc) => {
            $ngRedux.dispatch({
                type: CRE_VENDOR_DOC_REMOVE,
                id: 'creVendor',
                path: 'insurance_docs',
                idx: findIndex(this.creVendor.insurance_docs, doc),
            });
        };

        this.onSave = () => {
            if (this.creVendorsInsuranceForm.$valid) {
                this.saveVendorInsurance({
                    token: this.token,
                    application_id: this.creVendor.application_id,
                    wcp_number: this.creVendor.wcp_number,
                    wcp_expiry_date: formatDate(this.creVendor.wcp_expiry_date, DATE_FORMAT.OUTPUT),
                    plp_number: this.creVendor.plp_number,
                    plp_expiry_date: formatDate(this.creVendor.plp_expiry_date, DATE_FORMAT.OUTPUT),
                    pip_number: this.creVendor.pip_number,
                    pip_expiry_date: formatDate(this.creVendor.pip_expiry_date, DATE_FORMAT.OUTPUT),
                    insurance_docs: this.creVendor.insurance_docs,
                }).then(() => {
                    Notifier.success('Insurance Information has been successfully saved');
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
        }

        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: require('./cre-vendors-insurance.html')
};
