export const PUBLIC_CONFIGS = [
    {
        id: 10,
        parentId: null,
        code: 'ape',
        module: 'applicationsPermits',
        name: 'Applications / Permits',
        title: 'Applications / Permits',
        desc: 'Applications / Permits',
        state: 'public.applicationsPermits.dashboard',
        selector: 'public-applicationsPermits-dashboard',
        url: 'applications',
        color: 'e60084',
        colorName: 'ApplicationsPermits',
        icon: 'applications-permits.svg',
        iconSvg: './applications-permits.svg',
        iconType: 'svg',
        order: 1,
        warning: 0,
        critical: 0,
        components: [
            {
                id: 20,
                parentId: 10,
                code: 'lpe',
                module: 'applicationsPermits',
                name: 'Laser Permits',
                title: 'Laser Permits',
                desc: 'Laser Permits',
                state: 'public.applicationsPermits.laser.dashboard',
                selector: 'public-applicationsPermits-laser-dashboard',
                url: 'applications/laser-permits',
                color: 'e60084',
                colorName: 'ApplicationsPermits',
                icon: 'applications-permits-laser.svg',
                iconSvg: './applications-permits-laser.svg',
                iconType: 'svg',
                order: 1,
                warning: 0,
                critical: 0,
                components: [
                    {
                        id: 30,
                        parentId: 20,
                        code: 'iap',
                        module: 'applicationsPermits',
                        name: 'Initial Application',
                        title: 'Initial Application',
                        desc: 'Initial Application',
                        state: 'public.applicationsPermits.laser.request',
                        selector: 'public-applicationsPermits-laser-request',
                        url: 'applications/laser-permits/request',
                        color: 'e60084',
                        colorName: 'ApplicationsPermits',
                        icon: 'applications-permits-laser-request.svg',
                        iconSvg: './applications-permits-laser-request.svg',
                        iconType: 'svg',
                        order: 1,
                        warning: 0,
                        critical: 0,
                        components: [],
                    },
                    {
                        id: 31,
                        parentId: 20,
                        code: 'nepiap',
                        module: 'applicationsPermits',
                        name: 'Initial Application',
                        title: 'Initial Application',
                        desc: 'Initial Application',
                        state: 'public.applicationsPermits.nuclearEnergy.request',
                        selector: 'public-applicationsPermits-nuclearEnergy-request',
                        url: 'applications/nuclear-energy-permits/request',
                        color: 'e60084',
                        colorName: 'ApplicationsPermits',
                        icon: 'nuclear-energy-permits-request.svg',
                        iconSvg: './nuclear-energy-permits-request.svg',
                        iconType: 'svg',
                        order: 1,
                        warning: 0,
                        critical: 0,
                        components: [],
                    }
                ]
            }
        ],
    },
    {
        id: 100,
        parentId: null,
        code: 'creden',
        module: 'credentialing',
        name: 'Credentialing',
        title: 'Credentialing',
        desc: 'Credentialing',
        state: 'public.credentialing.dashboard',
        selector: 'public-credentialing-dashboard',
        url: 'credentialing',
        color: '007399',
        colorName: 'Credentialing',
        icon: 'credentialing.svg',
        iconSvg: './credentialing.svg',
        iconType: 'svg',
        order: 1,
        warning: 0,
        critical: 0,
        components: [
            {
                id: 110,
                parentId: 100,
                code: 'vencre',
                module: 'credentialing',
                name: 'Vendors',
                title: 'Vendors',
                desc: 'Vendors',
                state: 'public.credentialing.vendors.dashboard',
                selector: 'public-credentialing-vendors-dashboard',
                url: 'credentialing/vendors',
                color: '007399',
                colorName: 'Credentialing',
                icon: 'credentialing-vendors.svg',
                iconSvg: './credentialing-vendors.svg',
                iconType: 'svg',
                order: 1,
                warning: 0,
                critical: 0,
                components: [
                    {
                        id: 120,
                        parentId: 110,
                        code: 'vcriai',
                        module: 'credentialing',
                        name: 'Initial Applicant Invitation',
                        title: 'Initial Applicant Invitation',
                        desc: 'Initial Applicant Invitation',
                        state: 'public.credentialing.vendors.request',
                        selector: 'public-credentialing-vendors-request',
                        url: 'credentialing/vendors/request',
                        color: '007399',
                        colorName: 'Credentialing',
                        icon: 'credentialing-vendors-invitation.svg',
                        iconSvg: './credentialing-vendors-invitation.svg',
                        iconType: 'svg',
                        order: 1,
                        warning: 0,
                        critical: 0,
                        components: [],
                    },
                ],
            },
        ],
    },
    {
        id: 221,
        parentId: null,
        code: 'lst',
        module: 'safetyTraining',
        name: 'Laser Safety Training',
        title: 'Laser Safety Training',
        desc: 'Laser Safety Training',
        state: 'public.safetyTraining.laser.dashboard',
        selector: 'public-safetyTraining-laser-dashboard',
        url: 'safety-training/laser',
        color: 'ff355e',
        colorName: 'LaserSafetyTraining',
        icon: 'training.svg',
        iconSvg: './training.svg',
        iconType: 'svg',
        order: 140,
        warning: 0,
        critical: 0,
        components: [
            {
                id: 222,
                parentId: 221,
                code: 'stl',
                module: 'safetyTraining',
                name: 'Training Video Review',
                title: 'Training Video Review',
                desc: 'Training Video Review',
                state: 'public.safetyTraining.laser.video',
                selector: 'public-safetyTraining-laser-video',
                url: 'safety-training/laser/video',
                color: 'ff355e',
                colorName: 'LaserSafetyTraining',
                icon: 'training-video-review.svg',
                iconSvg: './training-video-review.svg',
                iconType: 'svg',
                order: 1,
                warning: 0,
                critical: 0,
                components: []
            }
        ]
    },
    {
        id: 65,
        parentId: null,
        state: 'public.accreditation',
        selector: 'public-accreditation-dashboard',
        module: 'accreditation',
        color: 'a934e4',
        colorName: 'Accreditations',
        order: 160,
        code: 'acc',
        title: 'Accreditation',
        url: 'accreditation',
        icon: 'accreditation_polices_procedures.svg',
        iconType: 'svg',
        iconSvg: './accreditation_polices_procedures.svg',
        name: 'Accreditation',
        desc: 'Accreditation Polices and Procedures',
        warning: 0,
        critical: 0,
        components: [
            {
                id: 354,
                parentId: 65,
                state: 'public.accreditation.tasks',
                selector: 'public-accreditation-tasks',
                module: 'accreditation',
                color: 'a934e4',
                colorName: 'Accreditations',
                order: 1,
                code: 'actask',
                title: 'Accreditation Tasks',
                url: 'accreditation/tasks',
                icon: 'accreditation_polices_procedures.svg',
                iconType: '',
                iconSvg: './',
                name: 'Accreditation Tasks',
                desc: 'Tasks',
                warning: 0,
                critical: 0,
                components: [],
            },
        ],
    },
    {
        id: 400,
        parentId: null,
        code: 'emp',
        module: 'employees',
        name: 'Employees',
        title: 'Employees',
        desc: 'Employees',
        state: 'public.employees.dashboard',
        selector: 'public-employees-dashboard',
        url: 'employees',
        color: 'e60084',
        colorName: 'Employees',
        icon: 'employees.svg',
        iconSvg: './employees.svg',
        iconType: 'svg',
        order: 1,
        warning: 0,
        critical: 0,
        components: [
            {
                id: 401,
                parentId: 400,
                code: 'eml',
                module: 'employees',
                name: 'Employee Licence',
                title: 'Employee Licence',
                desc: 'Employee Licence',
                state: 'public.employees.licence',
                selector: 'public-employees-licence',
                url: 'employees/licence',
                color: 'e60084',
                colorName: 'Employees',
                icon: 'employees.svg',
                iconSvg: './employees.svg',
                iconType: 'svg',
                order: 1,
                warning: 0,
                critical: 0,
                components: [],
            },
        ],
    },
];



