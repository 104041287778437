/* @ngInject */
export function VendorBaseController($controller, $scope, $rootScope, $q, $timeout, Notifier, VendorService, VendorManager, VendorHeadOffice, VendorSiteLocation, VendorManagerUser, Grid, GENDERS, COUNTRY_SELECT_EVENT) {

    angular.extend(this, $controller('ApplicationModuleController', {
        $scope: $scope,
        $timeout: $timeout,
        Notifier: Notifier
    }));

    $scope.templateSiteLocationsFilter          = './vendors-site-locations-filter.html';
    $scope.templateSiteLocationsGrid            = './vendors-site-locations-grid.html';
    $scope.templateSiteLocationManagersFilter   = './vendors-site-locations-managers-filter.html';
    $scope.templateSiteLocationManagersGrid     = './vendors-site-locations-managers-grid.html';

    $scope.headOffice = new VendorHeadOffice();
    $scope.siteLocation = new VendorSiteLocation();
    $scope.siteLocationManagers = [];
    $scope.vendorServices = [];
    $scope.genders = GENDERS;

    $scope.manager = {
        newManager: false,
        grantAccess: false,
        siteLocationManager: new VendorManagerUser()
    };

    $scope.gridSiteLocations = new Grid();
    $scope.gridSiteLocations.columns = VendorManager.siteLocationColumns.toJS();

    $scope.gridSiteLocationManagers = new Grid();
    $scope.gridSiteLocationManagers.columns = VendorManager.siteLocationManagerColumns.toJS();

    $scope.$watch('selectedTab', (function(newTab) {
        switch (_.get(newTab, 'id', null)) {
            case VendorManager.TAB_VENDORS:
                _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_DETAILS]).visible = false;
                _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_MANAGER]).visible = false;
                VendorService.getVendorContactPositions('vhm').then(function(positions) {
                    $scope.positions = positions;
                });
                this.disableTabs();
                break;
            case VendorManager.TAB_VENDOR_DETAILS:
                _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_DETAILS]).visible = false;
                _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_MANAGER]).visible = false;
                VendorService.getVendorContactPositions('vhm').then(function(positions) {
                    $scope.positions = positions;
                });
                break;
            case VendorManager.TAB_VENDOR_SERVICES:
                _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_DETAILS]).visible = false;
                _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_MANAGER]).visible = false;
                break;
            case VendorManager.TAB_ADD_SITE_LOCATION:
                _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_DETAILS]).visible = false;
                _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_MANAGER]).visible = false;
                VendorService.getVendorContactPositions('vsm').then(function(positions) {
                    $scope.positions = positions;
                });
                $scope.siteLocation = new VendorSiteLocation();
                $scope.manager.newManager = false;
                $scope.manager.grantAccess = false;
                $scope.manager.siteLocationManager = new VendorManagerUser();
                $scope.manager.siteLocationManagerExisting = new VendorManagerUser();
                $rootScope.$broadcast(COUNTRY_SELECT_EVENT.RESET);
                break;
            case VendorManager.TAB_VENDOR_SITE_LOCATIONS:
                _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_DETAILS]).visible = false;
                _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_MANAGER]).visible = false;
                VendorService.getVendorContactPositions('vsm').then(function(positions) {
                    $scope.positions = positions;
                });
                break;
            case VendorManager.TAB_VENDOR_SITE_LOCATION_DETAILS:
                _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_MANAGER]).visible = false;
                VendorService.getVendorContactPositions('vsm').then(function(positions) {
                    $scope.positions = positions;
                });
                break;
            case VendorManager.TAB_VENDOR_SITE_LOCATION_MANAGER:
                VendorService.getVendorContactPositions('vsm').then(function(positions) {
                    $scope.positions = positions;
                });
                $scope.manager.newManager = false;
                $scope.manager.grantAccess = false;
                break;
        }
    }).bind(this));

    $scope.manageVendorServices = function(item) {
        if (_.isUndefined(_.find($scope.headOffice.services, ['id', item.id]))) {
            VendorService.assignVendorServiceType($scope.headOffice.uuid, item.id).then(function() {
                $scope.headOffice.services.push(item);
            });
        }
        else {
            VendorService.rejectVendorServiceType($scope.headOffice.uuid, item.id).then(function() {
                $scope.headOffice.removeVendorService(item);
            });
        }
    };

    $scope.manageSiteLocationManager = function(item) {
        if ($scope.siteLocation.getManager(item.uuid)) {
            VendorService.rejectVendorSiteLocationManager($scope.siteLocation.uuid, item.uuid).then(function() {
                $scope.siteLocation.removeManager(item);
                Notifier.success('Site Location Manager successfully removed');
            });
        }
        else {
            var deferred = $q.defer();
            if (item.uuid) {
                VendorService.assignVendorSiteLocationManager($scope.siteLocation.uuid, item.uuid).then(function () {
                    $scope.siteLocation.addManager(_.cloneDeep(item));
                    Notifier.success('Site Location Manager successfully assigned');
                    deferred.resolve();
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
            return deferred.promise;
        }
    };

    $scope.onSiteLocationSelected = function(item) {
        VendorService.getVendorSiteLocation(item.uuid).then(function(siteLocation) {
            $scope.siteLocation = siteLocation;
            $scope.gridSiteLocationManagers.items = $scope.siteLocation.managers;
            _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_DETAILS]).disabled = false;
            _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_DETAILS]).visible = true;
        });
    };

    $scope.onManagerSelected = function(state, manager) {
        $scope.MANAGER_STATE = state;
        $scope.manager.siteLocationManager = new VendorManagerUser();
        $scope.manager.siteLocationManagerExisting = new VendorManagerUser();
        _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_MANAGER]).disabled = false;
        _.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_MANAGER]).visible = true;
        if (!_.isUndefined(manager)) {
            $timeout(function() {
                $scope.manager.siteLocationManager = manager;
            }, 100);
        }
    };

    this.addSiteLocation = function(data) {
        $scope.siteLocation.hofUuid = $scope.headOffice.uuid;
        $scope.siteLocation.hofName = $scope.headOffice.name;
        $scope.siteLocation.country = data.vsl_country_name;
        $scope.siteLocation.state = data.vsl_state_name;
        $scope.siteLocation.uuid = data.vsl_uuid;
        $scope.siteLocation.username = data.vsm_username;
        $scope.manager.siteLocationManager.uuid = data.vsm_uuid;
        $scope.siteLocation.addManager(_.cloneDeep($scope.manager.siteLocationManager));
        $scope.headOffice.addSiteLocation(_.cloneDeep($scope.siteLocation));
        this.addManagerCandidate(_.cloneDeep($scope.manager.siteLocationManager));
        // TODO consider to move this onTabSelected
        $scope.siteLocation = new VendorSiteLocation();
        $scope.manager.siteLocationManager = new VendorManagerUser();
        $scope.manager.siteLocationManagerExisting = new VendorManagerUser();
        $scope.manager.newManager = false;
        $scope.manager.grantAccess = false;
        $scope.manager.newManager = false;
        Notifier.success('New Vendor Site Location successfully added');
    };

    this.updateSiteLocation = function() {
        var deferred = $q.defer();
        VendorService.editVendorSiteLocation($scope.siteLocation).then(function() {
            $scope.siteLocation.state = $scope.siteLocation.country.state.name;
            $scope.siteLocation.country = $scope.siteLocation.country.name;
            var candidateIndex = _.indexOf($scope.headOffice.siteLocations, _.find($scope.headOffice.siteLocations, ['uuid', $scope.siteLocation.uuid]));
            $scope.headOffice.siteLocations.splice(candidateIndex, 1, $scope.siteLocation);
            Notifier.success('Vendor Site Location successfully updated');
            deferred.resolve();
        }, function(error) {

        });
        return deferred.promise;
    };

    this.addManagerCandidate = function(manager) {
        if (_.isUndefined(_.find($scope.siteLocationManagers, ['uuid', manager.uuid]))) {
            $scope.siteLocationManagers.push(manager);
        }
    };

    $scope.getManagerCandidates = function() {
        return _.differenceBy($scope.siteLocationManagers, $scope.siteLocation.managers, 'uuid');
    };
}
