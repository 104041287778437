import API from '../../../common/http/api.fetch';
import {success, responseMessage} from '../../../common/utils/util.notifier';
import * as GRID from '../../../common/grid/grid.constants';
import { TYPES } from './settings-action-type.constants';
import { IDS } from './settings-action-id.constants';
import I18N from '../../../common/utils/util.i18n';
export const getSchemeDetails = (code) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/scheme-details',
            method: 'GET',
            params: { code }
        }).then(response => {
            const key = `ACCREDITATION.SETTINGS.SCHEME.STATUS_${response.data.details.status_code.toUpperCase()}`
            let status_name = I18N.translate(key);
            if(status_name === key)
                status_name = response.data.details.status_name;
            dispatch({
                type:   TYPES.SCHEME_DETAILS_REFRESH,
                id:     IDS.accreditationSettingsSchemeDetails,
                data: {
                    ...response.data.details,
                    status_name,
                }
            });
            return response.data.details
        }, error => {
            responseMessage(error);
        });
    };
}

export const getSchemeStatus = (id) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/scheme-details',
            method: 'GET',
            params: {id: id}
        }).then(response => {
            const key = `ACCREDITATION.SETTINGS.SCHEME.STATUS_${response.data.details.status_code.toUpperCase()}`
            let status_name = I18N.translate(key);
            if(status_name === key)
                status_name = response.data.details.status_name;
            dispatch({
                type:   TYPES.SCHEME_STATUS_REFRESH,
                id:     IDS.accreditationSettingsSchemeDetails,
                data: {
                    ...response.data.details,
                    status_name,
                }
            })
        }, error => {
            responseMessage(error);
        });
    };
}

export const editScheme = (data) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/scheme-edit',
            method: 'POST',
            data:   data,
        }).then(response => {
            dispatch({ // update accreditationEditorSchemeDetails.title
                type:   TYPES.SCHEME_DETAILS_REFRESH,
                id:     IDS.accreditationSettingsSchemeDetails,
                data:   data
            })
            success('ACCREDITATION.NOTIFICATIONS.SCHEME.EDIT')
        }, error => {
            responseMessage(error);
        })
    }
}

export const activateScheme = (schemeId) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/scheme-set-status',
            method: 'POST',
            data: {
                id: schemeId,
                code: 'act'
            },
        }).then(response => {
            success('ACCREDITATION.NOTIFICATIONS.SCHEME.ACTIVATE')
        }, error => {
            responseMessage(error);
        })
    }
}

export const deactivateScheme = (schemeId) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/scheme-set-status',
            method: 'POST',
            data: {
                id: schemeId,
                code: 'pen'
            },
        }).then(response => {
            success('ACCREDITATION.NOTIFICATIONS.SCHEME.DEACTIVATE')
        }, error => {
            responseMessage(error);
        })
    }
}
