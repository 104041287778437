export function AccQualityImprovementItemFactory() {
    return function AccQualityImprovementItem() {

        this.id = null; //aqi_id from database
        this.locationUuid = null; //site location to which this document is relevant
        this.improvementActionItem = null;
        this.source = null;
        this.actionUndertaken = null;
        this.resPerson = null;
        this.resPersonUuid = null;
        this.creationDate = null;
        this.creationDateStr = null;
        this.completionDate = null;
        this.completionDateStr = null;
        this.itemNumber = null;
    };
}
