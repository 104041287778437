/* @ngInject */
export function AccComplaintFeedbackAddEdit($scope, $mdDialog, color, title, locationUuid, locationName, accShemaCode, complFeedbItem, Notifier, AccreditationDocumentsService, AccComplaintFeedbackItem, EmployeeService, $filter) {

    $scope.headerColor = color;
    $scope.headerTitle = title;
    $scope.cursorState = 'default';

    $scope.accreditationSchemaCode = accShemaCode;
    $scope.locationName = locationName;
    $scope.locationUuid = locationUuid;
    $scope.selectedEmployee = null;
    $scope.employeeList = null;

    $scope.maxTestDate = new Date();

    if (complFeedbItem == null) {
        $scope.complaintFeedbackItem = new AccComplaintFeedbackItem();
    } else {
        $scope.complaintFeedbackItem = complFeedbItem;
        $scope.searchText = $scope.complaintFeedbackItem.completedBy;
    }

    /**
  * Create filter function for a query string */
    function createFilterFor(query) {
        var lowercaseQuery = query.toLowerCase();
        return function filterFn(employee) {
            return ((employee.firstName + ' ' + employee.lastName).toLowerCase().indexOf(lowercaseQuery) === 0);
        };
    }

    $scope.querySearch = function (query) {
        var results = $scope.employeeList.filter(createFilterFor(query));
        return results;
    };

    $scope.searchTextChange = function (text) { };
    $scope.selectedItemChange = function (selectedEmployee) {
        $scope.complaintFeedbackItem.completedBy = selectedEmployee && (selectedEmployee.firstName+" "+selectedEmployee.lastName);
        $scope.complaintFeedbackItem.completedByUuid = selectedEmployee && (selectedEmployee.uuid);

    };

    //Get Active employees for currently selected site location
    $scope.cursorState = 'wait';
    EmployeeService.getEmployees(
    {
            status: 'act', /* Active employees, hardcoded id*/
            location: { uuid: $scope.locationUuid }
    }
    ).then(function (employeeList) {
       $scope.employeeList = employeeList.items;
       $scope.cursorState = 'default';
    }, function(error){
        $scope.cursorState = 'default';
    });

    //Dialog cancel button
    $scope.cancel = function () {
        $mdDialog.cancel();
    };

    $scope.submitComplaintFeedbackItem = function () {
        $scope.$broadcast('show-errors-check-validity', 'complFeedbacktemAddForm');
        if ($scope.complaintFeedbackItem.dateReceived && $scope.complaintFeedbackItem.customerDetails && $scope.complaintFeedbackItem.detailsCommunication) {
            $scope.complaintFeedbackItem.locationUuid = $scope.locationUuid;
            AccreditationDocumentsService.createAccComplaintFeedbackItem($scope.accreditationSchemaCode,
                $scope.locationUuid, $scope.complaintFeedbackItem)
                .then(function (response) {
                    Notifier.success($filter('translate')('ACCREDITATION.TABS.COMPLAINTS_AND_FEEDBACK.NOTIFY_SAVE_SUCCESS'));
                }, function (error) {
                    Notifier.error($filter('translate')('ACCREDITATION.TABS.COMPLAINTS_AND_FEEDBACK.NOTIFY_COMPLAINT_ERROR'));
                }).then(function () {
                $scope.$emit('acc-complaint-and-feedback-item-added', $scope.uploadResult);
                $mdDialog.hide();
            });
        } else {
            Notifier.error($filter('translate')('ACCREDITATION.TABS.COMPLAINTS_AND_FEEDBACK.NOTIFY_INPUT_ERROR'));
        }
    };
}
