/* @ngInject */
export function ManufacturersActionsFactory(ManufacturerService, Notifier) {
    return {
        getManufacturers: function(filter) {
            return function(dispatch) {
                return ManufacturerService.fetchManufacturers(filter).then(function(data) {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'manufacturersGrid',
                        items: data.items,
                        itemsCount: data.itemsCount,
                    });
                });
            };
        },
        getManufacturersList: function(equipmentType) {
            return function(dispatch) {
                return ManufacturerService.fetchManufacturersByEquipmentType(equipmentType).then(function(data) {
                    dispatch({
                        type: 'MANUFACTURERS_FETCH',
                        id: 'manufacturersList',
                        items: data,
                    });
                });
            };
        },
        getManufacturerDetails: function(id) {
            return function(dispatch) {
                return ManufacturerService.fetchManufacturerDetails(id).then(function(data){
                    dispatch({
                        type: 'MANUFACTURER_SELECTED',
                        id: 'manufacturer',
                        manufacturer: data.manufacturer,
                        manufacturerTypesSelected: data.equipmentTypes,
                    });
                    return data;
                });
            };
        },
        addManufacturer: function(manufacturer, equipmentTypes) {
            return function(dispatch) {
                return ManufacturerService.addManufacturer(manufacturer).then(function(data){
                    manufacturer.id = data.man_id;
                    return ManufacturerService.setEquipmentTypeToManufacturer(manufacturer.id, equipmentTypes).then(function(data){
                        Notifier.success('Manufacturer successfully created');
                        dispatch({
                            type: 'GRID_RELOAD',
                            id: 'manufacturersGrid'
                        });
                    });
                });
            };
        },
        updateManufacturer: function(manufacturer, equipmentTypes) {
            return function(dispatch) {
                return ManufacturerService.updateManufacturer(manufacturer).then(function(data){
                    if (data.status !== 0){
                        // TODO something failed
                    } else {
                        return ManufacturerService.setEquipmentTypeToManufacturer(manufacturer.id, equipmentTypes).then(function(data){
                            Notifier.success('Manufacturer successfully updated');
                            dispatch({
                                type: 'GRID_RELOAD',
                                id: 'manufacturersGrid'
                            });
                        });
                    }
                });
            };
        },
        getManufacturerTypes: function() {
            return function(dispatch) {
                return ManufacturerService.fetchTypes().then(function(data){
                    dispatch({
                        type: 'MANUFACTURER_TYPES_FETCH',
                        id: 'app.administration.manufacturers',
                        equipmentTypes: data,
                    });
                });
            };
        },
    };
}
