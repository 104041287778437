/**
 * @module root/legacy/components/help
 */
import { HelpController } from './help.controller';
import { HelpTutorialsController } from './tutorials/help-tutorials.controller';
import { HelpService } from './help.service';
import { HelpActions } from './help.actions';

angular.module(
    'components.help',
    []
).controller({
    HelpController,
    HelpTutorialsController
}).service({
    HelpService
}).factory({
    HelpActions
});
