import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsHeadOfficeQualityManagersController($ngRedux, $uiRouterGlobals, $mdDialog, AdministrationActions) {
    var ctrl = this;
    var dialog = null;
    var unsubscribe = angular.noop;
    var onQualityManager = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);

        var watchQualityManager = watch($ngRedux.getState, 'administration.administrationAccountsHeadOfficeQualityManager.data.uuid');
        onQualityManager = $ngRedux.subscribe(watchQualityManager(function(uuid) {
            onQualityManagerReady(uuid);
        }));
    };

    ctrl.newQualityManager = function() {
        if (ctrl.qualityManager.uuid) {
            $ngRedux.dispatch({
                type: 'QUALITY_MANAGER_RESET',
                id: 'administrationAccountsHeadOfficeQualityManager'
            });
        }
        $ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: 'app.administration.accounts',
            state: ['app.administration.accounts.headOfficesQualityManagerDetails'],
            stateParams: $uiRouterGlobals.params
        });
    };

    ctrl.selectQualityManager = function(event) {
        if (event.item.uuid === ctrl.qualityManager.uuid)
            onQualityManagerReady(event.item.uuid);
        else
            ctrl.getQualityManagerDetails('administrationAccountsHeadOfficeQualityManager', event.item.uuid);
    };

    ctrl.removeQualityManager = function(event) {
        var confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Quality Manager will be deactivated.')
            .ariaLabel('Deactivate Quality Manager')
            .ok('Yes')
            .cancel('No');

        dialog = $mdDialog.show(confirm).then(function() {
            event.item.location = ctrl.headOffice.uuid;
            ctrl.rejectQualityManager(event.item, 'administrationAccountsHeadOfficeQualityManager', 'administrationAccountsHeadOfficeQualityManagersGrid');
        }, angular.noop);
    };

    ctrl.$onDestroy = function() {
        if (dialog) $mdDialog.hide(dialog);
        unsubscribe();
        onQualityManager();
    };

    function onQualityManagerReady(uuid) {
        $ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: 'app.administration.accounts',
            state: ['app.administration.accounts.headOfficesQualityManagerDetails'],
            stateParams: {
                hof: $uiRouterGlobals.params.hof,
                qualityManager: uuid
            }
        });
        $ngRedux.dispatch({
            type: 'TAB_LOADING',
            id: 'app.administration.accounts',
            state: 'app.administration.accounts.headOfficesQualityManagerDetails',
            loading: false
        });
    }

    function mapStateToThis(state) {
        return {
            headOffice: state.administration.administrationAccountsHeadOffice,
            qualityManager: state.administration.administrationAccountsHeadOfficeQualityManager.data,
            title: state.administration.administrationAccountsHeadOffice.name + ' » Quality Managers'
        };
    }
}
