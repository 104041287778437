import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsHeadOfficeRscDetailsController($ngRedux, $uiRouterGlobals, AdministrationActions, Notifier) {
    var ctrl = this;
    var unsubscribe = angular.noop;
    var onRsc = angular.noop;

    ctrl.$onInit = function() {
        ctrl.identifier = 'administrationAccountsHeadOfficeRsc';
        ctrl.checkboxFlex = 25;

        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);
        ctrl.rscDetails = !_.isNull(ctrl.rsc.uuid);

        if (_.isUndefined(ctrl.stateParams.rsc))
            ctrl.getRscCandidates(ctrl.identifier, ctrl.stateParams.hof);

        var watchRsc = watch($ngRedux.getState, 'administration.administrationAccountsHeadOfficeRsc.data.uuid');
        onRsc = $ngRedux.subscribe(watchRsc(function(uuid) {
            ctrl.rscDetails = !_.isNull(uuid);
        }));
    };

    ctrl.onRscDetails = function() {
        ctrl.headOfficeRscForm.$setPristine();
        ctrl.headOfficeRscForm.$setUntouched();
        ctrl.checkboxFlex = ctrl.rscDetails ? 50 : 25;
    };

    ctrl.manageRsc = function() {
        if (ctrl.headOfficeRscForm.$valid) {
            if (ctrl.rsc.uuid) {
                ctrl.updateRscDetails('administrationAccountsHeadOfficeRsc', ctrl.rsc, 'administrationAccountsHeadOfficeRscGrid');
            }
            else {
                if (ctrl.rscDetails)
                    ctrl.createRscDetails(ctrl.rsc, $uiRouterGlobals.params.hof, false, 'administrationAccountsHeadOfficeRsc', 'administrationAccountsHeadOfficeRscGrid');
                else
                    ctrl.assignRsc(ctrl.rscCandidate, $uiRouterGlobals.params.hof, 'administrationAccountsHeadOfficeRsc', 'administrationAccountsHeadOfficeRscGrid');
            }
        }
        else Notifier.error('Please fill in the form fields');
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onRsc();
    };

    function mapStateToThis(state) {
        return {
            rsc: state.administration.administrationAccountsHeadOfficeRsc.data,
            rscCandidate: state.administration.administrationAccountsHeadOfficeRsc.candidate,
            stateParams: state.administration['app.administration.accounts'].stateParams,
            loading: _.find(state.administration['app.administration.accounts'].tabs, ['state', 'app.administration.accounts.headOfficesRscDetails']).loading,
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsHeadOfficeRsc.title
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
