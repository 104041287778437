import { GridReducer } from '../common/grid/grid.reducer';
import { administrationAccountsContactsGridFilter } from '../components/administration/accounts/grid/administration-accounts-contacts-grid.component';
import merge from 'lodash/merge';
import set from 'lodash/set';
import update from "lodash/update";
import * as FORM from '../common/form/input/input.constants';
import { IDS } from '../components/administration/accounts/site-locations/site-locations-action-id.constants';

/**
 * @function administrationAccountsSiteLocationsReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function administrationAccountsSiteLocationsReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            administrationAccountsSiteLocationsGrid: merge(GridReducer(), {
                filter: {
                    uuid: null,
                    keyword: null
                }
            }),
            administrationAccountsSiteLocation: {
                title: 'New Site Location',
                centerTypes: [],
                addressStates: [],
                invoiceAddressStates: [],
                data: {
                    uuid: null,
                    id: null,
                    name: null,
                    type: null,
                    email: null,
                    phone: null,
                    created: null,
                    address1: null,
                    address2: null,
                    address3: null,
                    suburb: null,
                    postcode: null,
                    country: null,
                    state: null,
                    invoiceAddress1: null,
                    invoiceAddress2: null,
                    invoiceAddress3: null,
                    invoiceSuburb: null,
                    invoicePostcode: null,
                    invoiceCountry: null,
                    invoiceState: null,
                    accountNumber: null,
                    costCentreCode: null,
                    doseUnit: null,
                    serviceProvider: null
                },
                doseUnits: [
                    { value: 'pdomsv', label: 'mSv (millisievert)' },
                    { value: 'pdousv', label: 'µSv (microsievert)' },
                ],
                serviceProviders: []
            },
            administrationAccountsSiteLocationActiveModules: [],
            administrationAccountsSiteLocationContactsGrid: merge(set(GridReducer(), 'itemsCount', undefined), {filter: administrationAccountsContactsGridFilter}),
            administrationAccountsSiteLocationContact: {
                title: 'New Contact',
                contactCandidates: [],
                candidate: null,
                data: {
                    uuid: null,
                    username: null,
                    password: null,
                    managerType: null,
                    gender: null,
                    firstName: null,
                    lastName: null,
                    birthDate: null,
                    phone: null,
                    fax: null,
                    email: null
                }
            },
            administrationAccountsSiteLocationLicencesGrid: merge(GridReducer(), {
                filter: {
                    uuid: null,
                    type: null,
                    status: 'act'
                }
            }),
            administrationAccountsSiteLocationLicence: {
                title: 'New Licence',
                types: [],
                data: {
                    id: null,
                    slo: null,
                    issued: null,
                    expires: null,
                    type: null,
                    number: null,
                    filePath: null,
                    typeCode: null
                }
            },
            administrationAccountsLicenceSiteLocationsGrid: merge(set(GridReducer(), 'itemsCount', undefined), {
                filter: {
                    limit: 100
                }
            }),
        };
    }

    switch (action.type) {
        case 'SITE_LOCATION_FETCH':
            if (action.id === 'administrationAccountsSiteLocation') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocation: Object.assign({}, state.administrationAccountsSiteLocation, {
                        title: 'Site Location Data',
                        data: {
                            uuid: action.data.uuid,
                            id: action.data.id,
                            name: action.data.name,
                            type: action.data.type,
                            email: action.data.email,
                            phone: action.data.phone,
                            created: action.data.created,
                            address1: action.data.address1,
                            address2: action.data.address2,
                            address3: action.data.address3,
                            suburb: action.data.suburb,
                            postcode: action.data.postcode,
                            country: action.data.country,
                            state: action.data.state,
                            invoiceAddress1: action.data.invoiceAddress1,
                            invoiceAddress2: action.data.invoiceAddress2,
                            invoiceAddress3: action.data.invoiceAddress3,
                            invoiceSuburb: action.data.invoiceSuburb,
                            invoicePostcode: action.data.invoicePostcode,
                            invoiceCountry: action.data.invoiceCountry,
                            invoiceState: action.data.invoiceState,
                            accountNumber: action.data.accountNumber,
                            costCentreCode: action.data.costCentreCode,
                            doseUnit: action.data.doseUnit,
                            serviceProvider: action.data.serviceProvider
                        },
                    }),
                    administrationAccountsSiteLocationActiveModules: action.data.activeModules
                });
            }
        case 'SITE_LOCATION_RESET':
            if (action.id === 'administrationAccountsSiteLocation') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocation: Object.assign({}, state.administrationAccountsSiteLocation, {
                        title: 'New Site Location',
                        addressStates: [],
                        invoiceAddressStates: [],
                        data: {
                            uuid: null,
                            id: null,
                            name: null,
                            type: null,
                            email: null,
                            phone: null,
                            created: null,
                            address1: null,
                            address2: null,
                            address3: null,
                            suburb: null,
                            postcode: null,
                            country: null,
                            state: null,
                            invoiceAddress1: null,
                            invoiceAddress2: null,
                            invoiceAddress3: null,
                            invoiceSuburb: null,
                            invoicePostcode: null,
                            invoiceCountry: null,
                            invoiceState: null,
                            doseUnit: null,
                            serviceProvider: null
                        },
                        administrationAccountsSiteLocationActiveModules: []
                    })
                });
            }
        case 'SITE_LOCATION_CENTER_TYPES':
            if (action.id === 'administrationAccountsSiteLocation') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocation: Object.assign({}, state.administrationAccountsSiteLocation, {centerTypes: action.items})
                });
            }
        case 'SITE_LOCATION_CENTER_TYPE_SELECTED':
            if (action.id === 'administrationAccountsSiteLocation') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocation: set(Object.assign({}, state.administrationAccountsSiteLocation), 'data.type', action.centerType)
                });
            }
        case 'COUNTRY_SELECTED':
            if (action.id === 'administrationAccountsSiteLocation' && action.path === 'addressStates') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocation: set(Object.assign({}, state.administrationAccountsSiteLocation), 'data.country', action.country)
                });
            }
            if (action.id === 'administrationAccountsSiteLocation' && action.path === 'invoiceAddressStates') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocation: set(Object.assign({}, state.administrationAccountsSiteLocation), 'data.invoiceCountry', action.country)
                });
            }
        case 'COUNTRY_STATES_FETCH':
            if (action.id === 'administrationAccountsSiteLocation') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocation: set(Object.assign({}, state.administrationAccountsSiteLocation), action.path, action.items)
                });
            }
        case 'COUNTRY_STATE_SELECTED':
            if (action.id === 'administrationAccountsSiteLocation' && action.path === 'addressStates') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocation: set(Object.assign({}, state.administrationAccountsSiteLocation), 'data.state', action.countryState)
                });
            }
            if (action.id === 'administrationAccountsSiteLocation' && action.path === 'invoiceAddressStates') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocation: set(Object.assign({}, state.administrationAccountsSiteLocation), 'data.invoiceState', action.countryState)
                });
            }
        case 'CONTACT_CANDIDATES_FETCH':
            if (action.id === 'administrationAccountsSiteLocationContact') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationContact: Object.assign({}, state.administrationAccountsSiteLocationContact, {contactCandidates: action.items})
                });
            }
        case 'CONTACT_FETCH':
            if (action.id === 'administrationAccountsSiteLocationContact') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationContact: Object.assign({}, state.administrationAccountsSiteLocationContact, {
                        title: 'Contact Data',
                        data: {
                            uuid: action.data.uuid,
                            username: action.data.username,
                            password: action.data.password,
                            gender: action.data.gender,
                            firstName: action.data.firstName,
                            lastName: action.data.lastName,
                            birthDate: action.data.birthDate,
                            phone: action.data.phone,
                            fax: action.data.fax,
                            email: action.data.email,
                            managerType: action.data.managerType
                        }
                    })
                });
            }
        case 'CONTACT_RESET':
            if (action.id === 'administrationAccountsSiteLocationContact') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationContact: Object.assign({}, state.administrationAccountsSiteLocationContact, {
                        title: 'New Contact',
                        candidate: null,
                        data: {
                            uuid: null,
                            username: null,
                            password: null,
                            gender: null,
                            firstName: null,
                            lastName: null,
                            birthDate: null,
                            phone: null,
                            fax: null,
                            email: null,
                            managerType: null
                        }
                    })
                });
            }
        case 'AUTOCOMPLETE_SELECTED':
            if (action.id === 'administrationAccountsSiteLocationContact') {
                const administrationAccountsSiteLocationContact = {...state.administrationAccountsSiteLocationContact};
                if (action.item) {
                    set(administrationAccountsSiteLocationContact, 'data.managerType', action.item.type_id);
                }
                return { ...state, administrationAccountsSiteLocationContact: set(administrationAccountsSiteLocationContact, action.path, action.item) };
            }
        case 'VALIDATE_USERNAME':
            if (action.id === 'administrationAccountsSiteLocationContact' && action.valid) {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationContact: set(Object.assign({}, state.administrationAccountsSiteLocationContact), action.path, action.username)
                });
            }
        case 'GENDER_SELECTED':
            if (action.id === 'administrationAccountsSiteLocationContact') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationContact: set(Object.assign({}, state.administrationAccountsSiteLocationContact), action.path, action.gender)
                });
            }
        case 'PASSWORD_CHANGE':
            if (action.id === 'administrationAccountsSiteLocationContact') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationContact: set(Object.assign({}, state.administrationAccountsSiteLocationContact), action.path, action.password)
                });
            }
        case 'PASSWORD_RESET':
            if (action.id === 'administrationAccountsSiteLocationContact') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationContact: set(Object.assign({}, state.administrationAccountsSiteLocationContact), action.path, null)
                });
            }
        case 'SITE_LOCATION_LICENCE_FETCH':
            if (action.id === 'administrationAccountsSiteLocationLicence') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationLicence: Object.assign({}, state.administrationAccountsSiteLocationLicence, {
                        title: 'Licence Data',
                        data: {
                            id: action.data.id,
                            slo: action.data.slo,
                            issued: action.data.issued,
                            expires: action.data.expires,
                            type: action.data.type,
                            number: action.data.number,
                            filePath: action.data.filePath,
                            typeCode: action.data.typeCode
                        }
                    })
                });
            }
        case 'SITE_LOCATION_LICENCE_RESET':
            if (action.id === 'administrationAccountsSiteLocationLicence') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationLicence: Object.assign({}, state.administrationAccountsSiteLocationLicence, {
                        title: 'New Licence',
                        data: {
                            id: null,
                            slo: null,
                            issued: null,
                            expires: null,
                            type: null,
                            number: null,
                            filePath: null,
                            typeCode: null
                        }
                    })
                });
            }
        case 'SITE_LOCATION_LICENCE_TYPES':
            if (action.id === 'administrationAccountsSiteLocationLicence') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationLicence: Object.assign({}, state.administrationAccountsSiteLocationLicence, {types: action.items})
                });
            }
        case 'SITE_LOCATION_LICENCE_TYPE_SELECTED':
            if (action.id === 'administrationAccountsSiteLocationLicence') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationLicence: update(Object.assign({}, state.administrationAccountsSiteLocationLicence), 'data', function(data) {
                        return Object.assign({}, data, {
                            type: action.licenceType,
                            typeCode: action.licenceCode,
                            expires: null
                        });
                    })
                });
            }
        case 'DATE_SELECTED':
            if (action.id === 'administrationAccountsSiteLocationContact') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationContact: set(Object.assign({}, state.administrationAccountsSiteLocationContact), action.path, action.input)
                });
            }
            if (action.id === 'administrationAccountsSiteLocationLicence') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationLicence: set(Object.assign({}, state.administrationAccountsSiteLocationLicence), action.path, action.input)
                });
            }
        case 'INPUT_SELECT':
            if (action.id === 'administrationAccountsSiteLocationContact') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationContact: set(Object.assign({}, state.administrationAccountsSiteLocationContact), action.path, action.input)
                });
            }
            if (action.id === 'administrationAccountsSiteLocation') {
                return set({...state}, `${action.id}.${action.path}`, action.input)
            }
        case 'INPUT_TEXT_CHANGE':
            if (action.id === 'administrationAccountsSiteLocationsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationsGrid: set(GridReducer(state.administrationAccountsSiteLocationsGrid, set(action, 'type', 'PAGINATE')), 'filter.keyword', action.input)
                });
            }
        case 'LOCATION_SELECTED':
            if (action.id === 'administrationAccountsSiteLocationsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationsGrid: set(GridReducer(state.administrationAccountsSiteLocationsGrid, {type: 'PAGINATE'}), 'filter.uuid', action.hof)
                });
            }
            if (action.id === 'administrationAccountsSiteLocationContactsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationContactsGrid: set(GridReducer(state.administrationAccountsSiteLocationContactsGrid, {type: 'PAGINATE'}), 'filter.uuid', action.slo)
                });
            }
            if (action.id === 'administrationAccountsSiteLocationLicencesGrid') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationLicencesGrid: set(GridReducer(state.administrationAccountsSiteLocationLicencesGrid, {type: 'PAGINATE'}), 'filter.uuid', action.slo)
                });
            }
        case 'MANAGER_TYPES':
            if (action.id === 'administrationAccountsSiteLocationContact') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationContact: set(Object.assign({}, state.administrationAccountsSiteLocationContact), action.path, action.items)
                });
            }
        case 'GRID_OVERRIDE':
        case 'SHOW_LOADING':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
            if (action.id === 'administrationAccountsSiteLocationsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationsGrid: GridReducer(state.administrationAccountsSiteLocationsGrid, action)
                });
            }
            if (action.id === 'administrationAccountsSiteLocationContactsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationContactsGrid: GridReducer(state.administrationAccountsSiteLocationContactsGrid, action)
                });
            }
            if (action.id === 'administrationAccountsSiteLocationLicencesGrid') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationLicencesGrid: GridReducer(state.administrationAccountsSiteLocationLicencesGrid, action)
                });
            }
            if (action.id === 'administrationAccountsLicenceSiteLocationsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsLicenceSiteLocationsGrid: GridReducer(state.administrationAccountsLicenceSiteLocationsGrid, action)
                });
            }
        case 'GRID_RESET':
            if (action.id === 'administrationAccountsSiteLocationsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsSiteLocationsGrid: update(GridReducer(state.administrationAccountsSiteLocationsGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            keyword: null
                        });
                    })
                });
            }
        case 'GRID_SELECT_ALL':
        case 'GRID_DESELECT_ALL':
            if (action.id === 'administrationAccountsLicenceSiteLocationsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsLicenceSiteLocationsGrid: GridReducer(state.administrationAccountsLicenceSiteLocationsGrid, action)
                });
            }
        case 'GRID_SELECT':
            if (action.id === 'administrationAccountsLicenceSiteLocationsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsLicenceSiteLocationsGrid: set(GridReducer(state.administrationAccountsLicenceSiteLocationsGrid, action),
                        'changedItem', {
                            id: action.data,
                            checked: 1
                        }
                    )
                });
            }
        case 'GRID_DESELECT':
            if (action.id === 'administrationAccountsLicenceSiteLocationsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsLicenceSiteLocationsGrid: set(GridReducer(state.administrationAccountsLicenceSiteLocationsGrid, action),
                        'changedItem', {
                            id: action.data,
                            checked: 0
                        }
                    )
                });
            }
        case FORM.INPUT_CHECKBOX_LIST:
            if(action.id === IDS.administrationAccountsSiteLocationActiveModules) {
                return {
                    ...state,
                    administrationAccountsSiteLocationActiveModules: action.data,
                }
            }
        default:
            return state;
    }
}
