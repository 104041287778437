import { TabsReducer } from '../../../../common/tabs/tabs.reducer';

import {IDS} from "../settings-action-id.constants";
export const enabledAccountsTabs = {
    [IDS.app_accreditation_scheme_settings_enabled]: Object.assign(TabsReducer(), {
        tabs: [
            {
                state: IDS.app_accreditation_scheme_settings_enabled_head_offices,
                title: 'ACCREDITATION.SETTINGS.HEAD_OFFICES.TAB',
                visible: true,
                disabled: false,
                loading: false
            },
            {
                state: IDS.app_accreditation_scheme_settings_enabled_site_locations,
                title: 'ACCREDITATION.SETTINGS.SITE_LOCATIONS.TAB',
                visible: false,
                disabled: false,
                loading: false
            },
        ],
    }),
};
