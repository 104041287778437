import {IDS} from '../assessment-reports-action-id.constants';
import {IDS as ASSESSMENT_IDS} from '../../standard/standard-action-id.constants';
import * as Actions from './add.actions';
import * as DocumentListActions from '../list/list.actions';
import {GRID_RELOAD} from "../../../../common/grid/grid.constants";
import moment from "moment";
import {formatDate} from "../../../../common/utils/util.date";
import {DATE_FORMAT} from "../../../../common/utils/util.constants";
import {TAB_SELECT} from "../../../../common/tabs/tabs.constants";
class documentDetailsController {
    constructor($ngRedux,
                $state,
                $scope,
                $stateParams,
                $filter,
                $timeout,
                Notifier,
                DocumentManager,
                EmployeeService,
                FileActions) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.$timeout = $timeout;
        this.Notifier = Notifier;
        this.DocumentManager = DocumentManager;
        this.EmployeeService = EmployeeService;
        this.FileActions = FileActions;
        this.standardNumber = 'Activity';
        this.identifier = IDS.accAssessmentReports;
    }

    $onInit() {
        this.schemeCode         = this.$stateParams.itemCode;
        this.adoId              = this.$stateParams.ado;
        this.sloFiscalYear      = this.$stateParams.year;

        this.unsubscribe = this.$ngRedux.connect(state => {
            const accAssessmentReports = state.accreditation.accAssessmentReports;
            return {
                schemeId:           accAssessmentReports.schemeDetails.id,
                schemeName:         accAssessmentReports.schemeDetails.name,
                siteLocationName:   accAssessmentReports.siteLocationName,
                siteLocationUuid:   accAssessmentReports.siteLocationUuid,
                document:           accAssessmentReports.documentAdd,
            }
        }, {
            ...Actions,
            ...DocumentListActions,
            ...this.FileActions,
        })(this);

        this.initAccreditationBodiesOptions(['acbhda','acbqip','acbnat']);
    }

    onUploadButtonSave(event) {
        this.$scope.$broadcast('show-errors-check-validity', '$ctrl.accAssessmentReportAddForm');
        if (this.accAssessmentReportAddForm.$invalid) {
            this.Notifier.error('COMMON.FIELDS_REQUIRED');
            return;
        }
        this.addAssessmentReport({
            document_type:  this.document.type_code,
            name:           this.document.name,
            description:    this.document.description,
            acc_body_type:  this.document.acc_body_type,
            asc_id:         this.schemeId,
            loc_uuid:       this.siteLocationUuid,
            issue_date:     formatDate(this.document.doc_date, DATE_FORMAT.OUTPUT),
            due_date:       formatDate(this.document.due_date, DATE_FORMAT.OUTPUT),
            doc_num:        this.document.doc_num,
            ast_id:         this.standard_id,
            doc_url:        event[0].filePath,
        }).then( () => {
            this.$ngRedux.dispatch({
                type: GRID_RELOAD,
                id: IDS.accAssessmentReports,
                path: IDS.accAssessmentReportsGrid,
            });
            this.$ngRedux.dispatch({
                type: TAB_SELECT,
                id: IDS.app_accreditation_scheme_assessment_reports,
                state: IDS.app_accreditation_scheme_assessment_reports_list,
            });
        })
    }

    onBackButton() {
        const docFiscalYear = this.fiscalYear;
        this.$state.go(ASSESSMENT_IDS.app_accreditation_scheme_standards_documents, {
            itemCode:   this.schemeCode,
            standard:   this.standardId,
            year:       this.sloFiscalYear || this.docFiscalYear || this.getFiscalYear(),
            slo:        this.siteLocationUuid,
        });
    }

    getFiscalYear () {
        const fiscalYearStart = moment().month(this.fiscalYearStart).day(0);
        return moment().isAfter(fiscalYearStart) ? moment().year() : moment().year() - 1;
    }

    onDownloadButton() {
        this.download(`storage${this.url}`);
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

documentDetailsController.$inject = [
    '$ngRedux',
    '$state',
    '$scope',
    '$stateParams',
    '$filter',
    '$timeout',
    'Notifier',
    'DocumentManager',
    'EmployeeService',
    'FileActions']

export const accAssessmentReportAdd = {
    controller: documentDetailsController,
    template: require('./add.html')
}
