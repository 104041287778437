import { VERSION } from '../utils/util.constants';
import { INIT_MODULE } from '../app.constants';
import { NAVIGATION_STATE } from '../navigation/navigation.actions.types';
import get from 'lodash/get';

/**
 * @const {object}
 * @memberOf module:Public
 */
export const publicApp = {
    bindings: {
        navConfigs: '<'
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, NavigationService) {
        this.year = new Date().getFullYear();
        this.version = VERSION;
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(({ navigation }) => ({
                configs: navigation.configs
            }), {})(this);

            const navConfig = NavigationService.getConfig($state.$current);
            $ngRedux.dispatch({
                type: INIT_MODULE,
                module: navConfig ? navConfig.module : 'dashboard',
                color: get(navConfig, 'color', null),
            });
            this.$ngRedux.dispatch({
                type: NAVIGATION_STATE,
                state: $state.$current.name,
            });
        }

        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: require('../app.html')
};
