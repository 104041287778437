/**
 * Creates a new ShieldingController.
 * @class
 */
class ShieldingController {
    constructor($log, NavigationService) {
        this.$log = $log;
        this.NavigationService = NavigationService;
    }

    $onInit() {
        this.$log.debug('Shielding::init');
        const navConfig = this.NavigationService.getNavConfig();
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;
    }
}

ShieldingController.$inject = ['$log', 'NavigationService'];

export const shielding = {
    controller: ShieldingController,
    template: `
        <div class="loader formHeader">
            <asset class="color{{$ctrl.colorName}}" src="{{$ctrl.icon}}"></asset>
            <div class="color{{$ctrl.colorName}}">Coming soon...</div>
        </div>
    `
};
