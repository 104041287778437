import watch from 'redux-watch';
import * as EventsAssessmentActions from "../events.assessment.actions";
import * as EventsActions from "../../events.actions"

/**
 * @const {object}
 * @memberOf module:Events
 */
export const eventsAssessmentHistoricalRecord = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $mdDialog, NavigationService, $scope) {
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(( state ) => ({
                eventItem: state.events.eventItem,
                eventID: state.events.eventItem.id,
                eventAssessment: state.events.eventAssessment
            }), {
                ...EventsAssessmentActions,
                ...EventsActions
            })(this);

            const navConfig = NavigationService.getConfig($state.$current);
            this.colorName = navConfig.colorName;
            this.icon = navConfig.icon;

            const watchLocation = watch($ngRedux.getState, 'events.eventAssessmentHistoricalRecord.locationCode');
            this.onLocation = $ngRedux.subscribe(watchLocation((code) => {
                this.addLocation = code === 'dep';
            }));

            this.title = 'Event General Info - ' + this.eventItem.event + ' - ' + this.eventItem.visualId;
            this.identifier = $state.$current.parent.name;
            this.state = `${$state.$current.parent.name}.assessmentHistoricalRecord`;

            this.typeTitle = 'Event Type';
            this.categoryTitle = 'Event Category';

            this.getEventTypes('eventAssessmentHistoricalRecord', this.eventItem.risk_code);
            this.getEventSubtypes('eventAssessmentHistoricalRecord', this.eventItem.risk_code);
            this.getConsequenceLevels('eventAssessmentHistoricalRecord');
            this.getLikelihoodEstimations('eventAssessmentHistoricalRecord');
            this.getEventLocations('eventAssessmentHistoricalRecord');
            this.getInvolvedPersonTypes('eventAssessmentHistoricalRecord');
            this.getInvolvedPersonAgeGroups('eventAssessmentHistoricalRecord');

            if (this.eventItem.event_category) {
                this.getEventCategories(
                    'eventAssessmentHistoricalRecord',
                    this.eventItem.risk_code,
                    this.eventItem.event_type,
                    this.eventItem.event_subtype
                );
            }

            if (this.eventItem.event_subcategory) {
                this.getEventSubcategories(
                    'eventAssessmentHistoricalRecord',
                    this.eventItem.risk_code,
                    this.eventItem.event_type,
                    this.eventItem.event_subtype,
                    this.eventItem.event_category
                );
            }
        };

        this.manageInvolvedPerson = (idx) => {
            const personCtrl = ($scope) => {
                $scope.disabled = true;
                $scope.identifier = 'eventAssessmentHistoricalRecord';
                $scope.cancel = () => {
                    $mdDialog.cancel();
                };
            };

            this.dialog = $mdDialog.show({
                controller: personCtrl,
                template: require('../../dialogs/events-involved-person-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                onShowing: () => {
                    if (typeof idx !== 'undefined') {
                        $ngRedux.dispatch({
                            id: 'eventAssessmentHistoricalRecord',
                            type: 'EVENT_PERSON_INVOLVED_DETAILS',
                            idx,
                        });
                    }
                }
            }).then(angular.noop, () => {
                $ngRedux.dispatch({
                    id: 'eventAssessmentHistoricalRecord',
                    type: 'EVENT_PERSON_INVOLVED_RESET'
                });
            });
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            this.onLocation();
            if (this.dialog)
                $mdDialog.hide(this.dialog);
        }
    },
    template: require('./events-assessment-historical-record.html')
};
