/* @ngInject */
export function SafetyPlanQuestionFactory(User) {
    return function SafetyPlanQuestion() {

        this.questionId = null;
        this.user = new User();
        this.fullName = null;
        this.questionTime = null;
        this.questionSubject = null;
        this.question = null;
        this.answerTime = null;
        this.answer = null;
        this.answeredBy = null;
    };
}
