import { GridReducer } from '../common/grid/grid.reducer';
import { administrationAccountsContactsGridFilter } from '../components/administration/accounts/grid/administration-accounts-contacts-grid.component';
import merge from 'lodash/merge';
import set from 'lodash/set';
import update from 'lodash/update';

/**
 * @function administrationAccountsDepartmentsReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function administrationAccountsDepartmentsReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            administrationAccountsDepartmentsGrid: merge(GridReducer(), {
                filter: {uuid: null}
            }),
            administrationAccountsDepartment: {
                title: 'New Department',
                types: [],
                data: {
                    uuid: null,
                    parentUuid: null,
                    accountId: null,
                    type: null,
                    typeName: null,
                    email: null,
                    phone: null,
                    created: null,
                    accountNumber: null,
                    doseUnit: null,
                    serviceProvider: null
                },
                doseUnits: [
                    { value: 'pdomsv', label: 'mSv (millisievert)' },
                    { value: 'pdousv', label: 'µSv (microsievert)' },
                ],
                serviceProviders: []
            },
            administrationAccountsDepartmentContactsGrid: merge(set(GridReducer(), 'itemsCount', undefined), {filter: administrationAccountsContactsGridFilter}),
            administrationAccountsDepartmentContact: {
                title: 'New Contact',
                rscCandidates: [],
                candidate: null,
                data: {
                    uuid: null,
                    username: null,
                    password: null,
                    gender: null,
                    firstName: null,
                    lastName: null,
                    birthDate: null,
                    phone: null,
                    fax: null,
                    email: null
                }
            }
        };
    }

    switch (action.type) {
        case 'DEPARTMENT_FETCH':
            if (action.id === 'administrationAccountsDepartment') {
                return Object.assign({}, state, {
                    administrationAccountsDepartment: Object.assign({}, state.administrationAccountsDepartment, {
                        title: 'Department Data',
                        data: {
                            uuid: action.data.uuid,
                            parentUuid: action.data.parentUuid,
                            accountId: action.data.accountId,
                            type: action.data.type,
                            typeName: action.data.typeName,
                            email: action.data.email,
                            phone: action.data.phone,
                            created: action.data.created,
                            accountNumber: action.data.accountNumber,
                            doseUnit: action.data.doseUnit,
                            serviceProvider: action.data.serviceProvider
                        }
                    })
                });
            }
        case 'DEPARTMENT_RESET':
            if (action.id === 'administrationAccountsDepartment') {
                return Object.assign({}, state, {
                    administrationAccountsDepartment: Object.assign({}, state.administrationAccountsDepartment, {
                        title: 'New Department',
                        data: {
                            uuid: null,
                            parentUuid: null,
                            accountId: null,
                            type: null,
                            typeName: null,
                            email: null,
                            phone: null,
                            created: null,
                            doseUnit: null,
                            serviceProvider: null
                        }
                    })
                });
            }
        case 'DEPARTMENT_TYPES':
            if (action.id === 'administrationAccountsDepartment') {
                return Object.assign({}, state, {
                    administrationAccountsDepartment: Object.assign({}, state.administrationAccountsDepartment, {types: action.items})
                });
            }
        case 'DEPARTMENT_TYPE_SELECTED':
            if (action.id === 'administrationAccountsDepartment') {
                return Object.assign({}, state, {
                    administrationAccountsDepartment: update(Object.assign({}, state.administrationAccountsDepartment), 'data', function(data) {
                        return Object.assign({}, data, {
                            type: action.departmentType,
                            typeName: action.departmentName
                        });
                    })
                });
            }
        case 'CONTACT_CANDIDATES_FETCH':
            if (action.id === 'administrationAccountsDepartmentContact') {
                return Object.assign({}, state, {
                    administrationAccountsDepartmentContact: Object.assign({}, state.administrationAccountsDepartmentContact, {contactCandidates: action.items})
                });
            }
        case 'CONTACT_FETCH':
            if (action.id === 'administrationAccountsDepartmentContact') {
                return Object.assign({}, state, {
                    administrationAccountsDepartmentContact: Object.assign({}, state.administrationAccountsDepartmentContact, {
                        title: 'Contact Data',
                        data: {
                            uuid: action.data.uuid,
                            username: action.data.username,
                            password: action.data.password,
                            gender: action.data.gender,
                            firstName: action.data.firstName,
                            lastName: action.data.lastName,
                            birthDate: action.data.birthDate,
                            phone: action.data.phone,
                            fax: action.data.fax,
                            email: action.data.email
                        }
                    })
                });
            }
        case 'CONTACT_RESET':
            if (action.id === 'administrationAccountsDepartmentContact') {
                return Object.assign({}, state, {
                    administrationAccountsDepartmentContact: Object.assign({}, state.administrationAccountsDepartmentContact, {
                        title: 'New Contact',
                        candidate: null,
                        data: {
                            uuid: null,
                            username: null,
                            password: null,
                            gender: null,
                            firstName: null,
                            lastName: null,
                            birthDate: null,
                            phone: null,
                            fax: null,
                            email: null
                        }
                    })
                });
            }
        case 'AUTOCOMPLETE_SELECTED':
            if (action.id === 'administrationAccountsDepartmentContact') {
                const administrationAccountsDepartmentContact = {...state.administrationAccountsDepartmentContact};
                if (action.item) {
                    set(administrationAccountsDepartmentContact, 'data.managerType', action.item.type_id);
                }
                return { ...state, administrationAccountsDepartmentContact: set(administrationAccountsDepartmentContact, action.path, action.item) };
            }
        case 'VALIDATE_USERNAME':
            if (action.id === 'administrationAccountsDepartmentContact' && action.valid) {
                return Object.assign({}, state, {
                    administrationAccountsDepartmentContact: set(Object.assign({}, state.administrationAccountsDepartmentContact), action.path, action.username)
                });
            }
        case 'DATE_SELECTED':
            if (action.id === 'administrationAccountsDepartmentContact') {
                return Object.assign({}, state, {
                    administrationAccountsDepartmentContact: set(Object.assign({}, state.administrationAccountsDepartmentContact), action.path, action.input)
                });
            }
        case 'GENDER_SELECTED':
            if (action.id === 'administrationAccountsDepartmentContact') {
                return Object.assign({}, state, {
                    administrationAccountsDepartmentContact: set(Object.assign({}, state.administrationAccountsDepartmentContact), action.path, action.gender)
                });
            }
        case 'PASSWORD_CHANGE':
            if (action.id === 'administrationAccountsDepartmentContact') {
                return Object.assign({}, state, {
                    administrationAccountsDepartmentContact: set(Object.assign({}, state.administrationAccountsDepartmentContact), action.path, action.password)
                });
            }
        case 'PASSWORD_RESET':
            if (action.id === 'administrationAccountsDepartmentContact') {
                return Object.assign({}, state, {
                    administrationAccountsDepartmentContact: set(Object.assign({}, state.administrationAccountsDepartmentContact), action.path, null)
                });
            }
        case 'INPUT_SELECT':
            if (action.id === 'administrationAccountsDepartmentContact') {
                return Object.assign({}, state, {
                    administrationAccountsDepartmentContact: set(Object.assign({}, state.administrationAccountsDepartmentContact), action.path, action.input)
                });
            }
            if (action.id === 'administrationAccountsDepartment') {
                return set({...state}, `${action.id}.${action.path}`, action.input)
            }
        case 'LOCATION_SELECTED':
            if (action.id === 'administrationAccountsDepartmentsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsDepartmentsGrid: set(GridReducer(state.administrationAccountsDepartmentsGrid, {type: 'PAGINATE'}), 'filter.uuid', action.slo)
                });
            }
            if (action.id === 'administrationAccountsDepartmentContactsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsDepartmentContactsGrid: set(GridReducer(state.administrationAccountsDepartmentContactsGrid, {type: 'PAGINATE'}), 'filter.uuid', action.uuid)
                });
            }
        case 'MANAGER_TYPES':
            if (action.id === 'administrationAccountsDepartmentContact') {
                return Object.assign({}, state, {
                    administrationAccountsDepartmentContact: set(Object.assign({}, state.administrationAccountsDepartmentContact), action.path, action.items)
                });
            }
        case 'GRID_OVERRIDE':
        case 'SHOW_LOADING':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
            if (action.id === 'administrationAccountsDepartmentsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsDepartmentsGrid: GridReducer(state.administrationAccountsDepartmentsGrid, action)
                });
            }
            if (action.id === 'administrationAccountsDepartmentContactsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsDepartmentContactsGrid: GridReducer(state.administrationAccountsDepartmentContactsGrid, action)
                });
            }
        default:
            return state;
    }
}
