import { IDS } from '../../settings-action-id.constants';
import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';
import * as SiteLocationsActions from './site-locations.actions';
import * as HeadOfficesActions from '../head-offices/head-offices.actions';
class SiteLocationsGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter, $scope) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$filter = $filter;
        this.$state = $state;
        this.$scope = $scope;
    }

    $onInit() {
        this.module = 'accreditation';
        this.identifier = IDS.accSettings;
        this.statePath = IDS.siteLocationsGrid;
        this.exportComponent = false;
        this.icon = false;
        this.filterExpandButtonVisible = false;
        this.columns = [
            {title: '#', mapper: null},
            {title: this.$filter('translate')('COMMON.FIELD.SITE_LOCATION'), mapper: 'slo_name'},
            {title: this.$filter('translate')('COMMON.FIELD.ENABLED'), mapper: 'checkbox',
                identifier: this.identifier,
                statePath: this.statePath,
                style: { width: '35px' }},
        ];
        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), SiteLocationsActions)(this);
        this.unsubscribeCustom = this.$ngRedux.connect(
            state => ({ schemeId: state.accreditation.accSettings.schemeDetails.id }),
            HeadOfficesActions
        )(this);
        this.dataAction = this.listSiteLocationsByHeadOffice;
        this.$scope.$on('grid-checkbox-change', this.onCheckboxChange.bind(this));
        this.init();
    }

    onCheckboxChange(event, slo_uuid, isSelected) {
        if(isSelected)
            this.assignSchemeToSiteLocation(this.schemeId, slo_uuid);
        else
            this.detachSiteLocationFromScheme({
                schemeId: this.schemeId,
                slo_uuid,
            });
    }

    $onDestroy() {
        if(this.unsubscribeCustom)
            this.unsubscribeCustom();
        this.destroy();
    }
}

SiteLocationsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter', '$scope']

export const siteLocationsGrid = {
    bindings: {
    },
    controller: SiteLocationsGridController,
    ...gridTpl
}
