import { API_BASE } from '../../../common/utils/util.constants';
import get from 'lodash/get';

export default function AdministrationMonthlyReportSettingsActions($http) {
    return {
        getMonthlyReportSettings: () => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/administration/monthly-report/monthly-report-list',
                    method: 'GET'
                }).then(({ data }) => {
                    const items = get(data, 'items', data);
                    dispatch({
                        type: 'COUNT_ITEMS',
                        id: 'administrationMonthlyReportSettingsGrid',
                        itemsCount: items.length,
                    });
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'administrationMonthlyReportSettingsGrid',
                        items: items.map((item) => ({
                            ...item,
                            generate_corporate_report: item.generate_corporate_report === 1 ? 'Yes' : 'No',
                            generate_by_sits_report: item.generate_by_sits_report === 1 ? 'Yes' : 'No',
                        })),
                    });
                });
            }
        },
        editMonthlyReportSettings: (data) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/administration/monthly-report/monthly-report-edit',
                    method: 'POST',
                    data: {
                        location: data.location,
                        generateBySitesReport: parseInt(data.generateBySitesReport),
                        generateBySitesReportTo: data.generateBySitesReportTo,
                        generateCorporateReport: parseInt(data.generateCorporateReport),
                        generateCorporateReportTo: data.generateCorporateReportTo
                    }
                }).then(response => {
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationMonthlyReportSettingsGrid'
                    });
                });
            }
        }
    };
}

AdministrationMonthlyReportSettingsActions.$inject = ['$http', 'Notifier'];
