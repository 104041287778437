import {TAB_SHOW, TAB_HIDE, TAB_SELECT} from "../../../common/tabs/tabs.constants";

/**
 * Creates a new AdministrationNotificationSettingsController.
 * @class
 * @memberOf module:root/legacy/components/administration
 */
class AdministrationNotificationSettingsController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param $mdDialog {service}
     * @param $rootScope {service}
     * @param NavigationService {service} {@link module:root/common.NavigationService|NavigationService}
     * @param AdministrationNotificationSettingsActions {service}
     */
    constructor($ngRedux, $state, $mdDialog, $rootScope, NavigationService, AdministrationNotificationSettingsActions) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.$rootScope = $rootScope;
        this.NavigationService = NavigationService;
        this.actions = AdministrationNotificationSettingsActions;
    }

    /**
     * @method $onInit
     * @memberOf module:root/components/administration.AdministrationNotificationSettingsController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);
    }

    /**
     * @method onNotificationSelect
     * @param event {object}
     * @memberOf module:root/components/administration.AdministrationNotificationSettingsController
     */
    onNotificationSelect = (event) => {
        this.getNotificationSettingsDetails(event.item.nse_id, this. location).then(() => {
            let tabs = [
                'app.administration.notificationSettings.details',
                'app.administration.notificationSettings.timeline'
            ];

            this.$ngRedux.dispatch({
                type: TAB_SHOW,
                id: 'app.administration.notificationSettings',
                state: tabs
            });

            this.$ngRedux.dispatch({
                type: TAB_SELECT,
                id: 'app.administration.notificationSettings',
                state: 'app.administration.notificationSettings.details'
            });
        });
    };

    /**
     * @method onNotificationSelect
     * @param event {object}
     * @memberOf module:root/components/administration.AdministrationNotificationSettingsController
     */
    onGridReset = (event) => {
        this.$rootScope.$broadcast('SELECT_CASCADE_RESET', 'notificationFilterForm');
    };

    /**
     * @method mapState
     * @param state {object} Redux Store
     * @return {object}
     * @memberof module:root/components/administration.AdministrationNotificationSettingsController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapState(state) {
        return {
            notification: state.administrationNotificationSettings,
            location: state.administration.administrationNotificationSettingsGrid.filter.location
        }
    }
}

AdministrationNotificationSettingsController.$inject = ['$ngRedux', '$state', '$mdDialog', '$rootScope', 'NavigationService', 'AdministrationNotificationSettingsActions'];

export const administrationNotificationSettings = {
    controller: AdministrationNotificationSettingsController,
    template: `
        <administration-notification-settings-grid 
            title="Notification Settings" 
            identifier="administrationNotificationSettingsGrid"
            on-select="$ctrl.onNotificationSelect($event)" 
            on-reset="$ctrl.onGridReset($event)">
        </administration-notification-settings-grid>
    `
};
