export const upload = {
    bindings: {
        dropzone: '@',
        inputId: '@',
        identifier: '@',
        label: '@',
        validate: '<',
        validType: '@',
        onSave: '&',
    },
    require : {
        form : '^form'
    },
    template: require('../../../../common/file/upload/file-upload.html'),
    controller: 'UploadController'
};
