/** @namespace Reducers.Manufacturers */

import * as TYPES from '../legacy/components/manufacturers/manufacturers.actions.types';
import manufacturersReducer from '../legacy/components/manufacturers/manufacturers.reducer';
import { tabs } from '../legacy/components/manufacturers/manufacturers.tabs';
import { TabsResolver } from '../common/tabs/tabs.resolver';
import { GridReducer } from '../common/grid/grid.reducer';
import update from 'lodash/update';
import set from 'lodash/set';

const initState = {
    ...manufacturersReducer,
};

export default (state = initState, action) => {
    if (Object.keys(tabs).includes(action.id)) {
        return TabsResolver(state, action);
    }
    switch (action.type) {
        case 'GRID_OVERRIDE':
        case 'SHOW_LOADING':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
            if (['manufacturersGrid', 'manufacturerEquipmentGrid'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: GridReducer(state[action.id], action),
                };
            }
        case 'GRID_RESET':
            if (action.id === 'manufacturersGrid') {
                return Object.assign({}, state, {
                    manufacturersGrid: update(GridReducer(state.manufacturersGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            keyword: null
                        });
                    })
                });
            }
            if (['manufacturerEquipmentGrid'].includes(action.id)) {
                const equGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...equGrid,
                        filter: {
                            ...equGrid.filter,
                            keyword: null,
                            ety_id: null,
                        },
                    },
                };
            }
        case TYPES.MANUFACTURER_SELECTED:
            if (action.id === 'manufacturer') {
                return {
                    ...state,
                    manufacturer: action.manufacturer,
                    manufacturerTypesSelected: action.manufacturerTypesSelected,
                    manufacturerEquipmentGrid: {
                        ...state.manufacturerEquipmentGrid,
                        filter: {
                            ...state.manufacturerEquipmentGrid.filter,
                            man_id: action.manufacturer.id,
                        },
                    },
                };
            }
        case TYPES.MANUFACTURER_TYPES_FETCH:
            if (action.id === 'app.administration.manufacturers'){
                return {
                    ...state,
                    manufacturerTypes: action.equipmentTypes,
                };
            }
        case TYPES.MANUFACTURERS_FETCH:
            if (action.id === 'manufacturersList'){
                return {
                    ...state,
                    manufacturersList: action.items,
                };
            }
        case TYPES.MANUFACTURER_TYPES_SELECT:
            if (action.id === 'app.administration.manufacturers'){
                return {
                    ...state,
                    manufacturerTypesSelected: action.manufacturerTypesSelected,
                };
            }
        case TYPES.MANUFACTURER_RESET:
            if (action.id === 'manufacturer') {
                return {
                    ...state,
                    manufacturer: initState.manufacturer,
                    manufacturerTypesSelected: [],
                    manufacturerEquipmentGrid: {
                        ...state.manufacturerEquipmentGrid,
                        filter: {
                            ...state.manufacturerEquipmentGrid.filter,
                            man_id: null,
                        },
                    },
                };
            }
        case TYPES.MANUFACTURER_EQU_TYPES:
            if (action.id === 'manufacturer') {
                return {
                    ...state,
                    manufacturer: {
                        ...state.manufacturer,
                        equipment_types: action.data,
                    },
                };
            }
        case TYPES.MANUFACTURER_EQU_MODEL:
            if (action.id === 'manufacturer') {
                return {
                    ...state,
                    manufacturerEquModel: action.data,
                };
            }
        case TYPES.MANUFACTURER_EQU_MODEL_CREATE:
            if (action.id === 'manufacturer') {
                return {
                    ...state,
                    manufacturerEquModel: {
                        ...state.manufacturerEquModel,
                        man_id: state.manufacturer.id,
                        manufacturer: state.manufacturer.name,
                    },
                };
            }
        case TYPES.MANUFACTURER_EQU_MODEL_RESET:
            if (action.id === 'manufacturer') {
                return {
                    ...state,
                    manufacturerEquModel: initState.manufacturerEquModel,
                };
            }
        case 'INPUT_SELECT':
        case 'INPUT_TEXT_CHANGE':
            if (['manufacturersGrid', 'manufacturerEquipmentGrid'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: set(GridReducer(state[action.id], { type: 'PAGINATE' }), action.path, action.input)
                };
            }
            if (['manufacturerEquModel'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.input,
                    },
                };
            }
        case 'CHECKBOX_SELECTED':
            switch (action.id) {
                case 'manufacturer':
                    return set({...state}, `manufacturer.${action.path}`, action.input);
                case 'manufacturersGrid':
                    return {
                        ...state,
                        manufacturersGrid: GridReducer(state.manufacturersGrid, set({...action, type: 'FILTER'}, action.path, action.input)),
                    };
            }
        case 'FILE_UPLOAD':
            if (['manufacturer', 'manufacturerEquModel'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.input,
                    },
                };
            }
        default:
            return state;
    }
}
