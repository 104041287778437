import { IDS } from '../../settings-action-id.constants';
import * as HeadOfficesActions from '../head-offices/head-offices.actions';

/**
 * Creates a new SiteLocationsController.
 * @class
 */
class SiteLocationsController {
    constructor($ngRedux, $state, $scope, $mdDialog, Notifier, NavigationService) {
        this.$ngRedux   = $ngRedux;
        this.$state     = $state;
        this.$scope     = $scope;
        this.$mdDialog  = $mdDialog;
        this.Notifier   = Notifier;
        this.NavigationService = NavigationService;
    }

    $onInit() {
        this.dataIdentifier = IDS.accSettings;
        this.identifier     = IDS.accreditationSettingsSiteLocations;
        this.parentState    = IDS.app_accreditation_scheme_settings_enabled;
        this.state          = IDS.app_accreditation_scheme_settings_enabled_site_locations;
        this.unsubscribe    = this.$ngRedux.connect(
            state => {
                const schemeDetails = state.accreditation.accSettings.schemeDetails;
                const siteLocations = state.accreditation.accSettings.siteLocations;
                return {
                    title:          schemeDetails.title,
                    headOfficeName: siteLocations.headOfficeName,
                }
            },
            HeadOfficesActions
        )(this);

        const navConfig = this.NavigationService.getConfig(this.$state.$current)
        this.colorName = navConfig.colorName;
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

SiteLocationsController.$inject = ['$ngRedux', '$state', '$scope', '$mdDialog', 'Notifier', 'NavigationService']

export const siteLocations = {
    controller: SiteLocationsController,
    template: `
        <tabs-header title="{{$ctrl.title}}"></tabs-header>
        <div layout="row" flex style="margin: 20px">
            <div layout="column">
                <div class="accreditation-table-item md-body-1">{{'COMMON.FIELD.HEAD_OFFICE' | translate}}:</div>
            </div>
            <span flex="5"></span>
            <div layout="column">
                <div class="accreditation-table-item md-body-1">{{$ctrl.headOfficeName}}</div>
            </div>
        </div>
        <site-locations-grid></site-locations-grid>
    `,
}
