/**
 * @namespace Reducers
 * @see {@link https://redux.js.org/basics/reducers|Designing the State Shape}
 */
import { combineReducers } from 'redux';
import { appReducer as app } from './common/app.reducer';
import { navigationReducer as navigation } from './common/navigation/navigation.reducer';
import { equipmentReducer as equipment } from './reducers/equipment.reducer';
import { mammoQualityControlReducer as mammoQualityControl } from './reducers/mammo-quality-control.reducer';
import equipmentCalibration from './reducers/equipment-calibration.reducer';
import events from './reducers/events.reducer';
import inventoryScanner from './reducers/inventory-scanner.reducer';
import { personalDosimetryReducer as personalDosimetry } from './reducers/personal-dosimetry.reducer';
import { reportingReducer as reporting } from './reducers/reporting.reducer';
import { employeesReducer as employees } from './reducers/employees.reducer';
import applicationsPermits from './reducers/applications-permits.reducer';
import credentialing from './reducers/credentialing.reducer';
import { safetyPlanReducer as safetyPlan } from './reducers/safety-plan.reducer';
import { serviceContractsReducer as serviceContracts } from './reducers/service-contracts.reducer';
import { accreditationReducer as accreditation } from './reducers/accreditation.reducer';
import { policiesAndProceduresReducer as pnp } from './reducers/policies-and-procedure.reducer';
import { documentManagementReducer as documentManagement } from './reducers/document-management.reducer';
import { protectiveEquipmentReducer as protectiveEquipment } from './reducers/protective-equipment.reducer';
import { doseReferenceLevelsReducer as doseReferenceLevels } from './reducers/dose-reference-levels.reducer';
import safetyTraining from './reducers/safety-training.reducer';
import radioactiveMaterials from './reducers/radioactive-materials.reducer';
import { administrationReducer as administration } from './reducers/administration.reducer';
import manufacturers from './reducers/manufacturers.reducer';
import { helpReducer as help } from './reducers/help.reducer';
import { systemReducer as system } from './reducers/system.reducer';

export const rootReducer = combineReducers({
    app,
    navigation,
    equipment,
    mammoQualityControl,
    equipmentCalibration,
    events,
    inventoryScanner,
    personalDosimetry,
    reporting,
    employees,
    applicationsPermits,
    credentialing,
    safetyPlan,
    serviceContracts,
    accreditation,
    pnp,
    documentManagement,
    protectiveEquipment,
    doseReferenceLevels,
    safetyTraining,
    radioactiveMaterials,
    administration,
    manufacturers,
    help,
    system
});
