export function FilterFactory() {
    return function Filter() {
        this.id = null;
        this.manufacturer = null;
        this.accountUuid = null;
        this.departmentUuid = null;
        this.roomUuid = null;
        this.categoryId = null;
        this.registrationDateFrom = null;
        this.registrationDateTo = null;
        this.certDateFrom = null;
        this.certDateFrom = null;
        this.equipmentType = null;
    };
}
