export const SimpleSubjectFactory = () => {
    return function SimpleSubject() {
        this.accountId = null;
        this.email = null;
        this.phone = null;
        this.parentUsername = null;
        this.parentUuid = null;
        this.type = null;
        this.name = null;
        this.id = null;
        this.uuid = null;
        this.typeId = null;
        this.typeName = null;
        this.created = null;
        this.basicType = null;
    };
};
