/** @module root/common/grid */

import { grid } from './grid.component';
import { gridList } from './list/grid-list.component';
import { gridListItem } from './list/item/grid-list-item.component';
import { gridListItemCheckbox } from './list/item/checkbox/grid-list-item-checkbox.component';
import { gridListItemDownload } from './list/item/download/grid-list-item-download.component';
import { gridListItemOrder } from './list/item/order/grid-list-item-order.component';
import { gridFilter } from './filter/grid-filter.component';
import { gridFilterSwitch } from './filter/controls/grid-filter-switch.component';
import { gridFilterSwitchSelect } from './filter/controls/grid-filter-switch-select.component';
import { gridExport } from './export/grid-export.component';
import { gridDocuments } from './documents/grid-documents.component';
import { gridImport } from './import/grid-import.component';

export const GRID_MODULE = angular.module('common.grid', []);

GRID_MODULE.component('grid', grid);
GRID_MODULE.component('gridList', gridList);
GRID_MODULE.component('gridListItem', gridListItem);
GRID_MODULE.component('gridListItemCheckbox', gridListItemCheckbox);
GRID_MODULE.component('gridListItemDownload', gridListItemDownload);
GRID_MODULE.component('gridListItemOrder', gridListItemOrder);
GRID_MODULE.component('gridFilter', gridFilter);
GRID_MODULE.component('gridFilterSwitch', gridFilterSwitch);
GRID_MODULE.component('gridFilterSwitchSelect', gridFilterSwitchSelect);
GRID_MODULE.component('gridExport', gridExport);
GRID_MODULE.component('gridDocuments', gridDocuments);
GRID_MODULE.component('gridImport', gridImport);
