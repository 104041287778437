/* @ngInject */
export function SelectEquipmentTypeController($ngRedux, EquipmentActions) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, EquipmentActions)(this);

        if (_.isUndefined(ctrl.validate))
            ctrl.validate = false;
        if (_.isUndefined(ctrl.required))
            ctrl.required = false;

        if (ctrl.validate && _.isUndefined(ctrl.name))
            throw new Error('Missing select name attribute');

        ctrl.getEquipmentTypes(ctrl.identifier);
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            $ngRedux.dispatch({
                type: 'EQUIPMENT_TYPE_SELECTED',
                id: ctrl.identifier,
                equipmentType: ctrl.selectedOption,
                formValid: ctrl.form.$valid
            });
        }
    };

    function mapStateToThis(state) {
        return {
            options: _.get(state, getStateContainer('types'), []),
            selectedOption: _.get(state, getStateContainer('filter.type'), null)
        };
    }

    function getStateContainer(statePath) {
        return [ctrl.module, ctrl.identifier].join('.') + '.' + statePath;
    }
}
