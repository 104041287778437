import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';
import { IDS } from '../../settings-action-id.constants';
import * as AssessmentActions from '../hof-required-docs.actions';

class HofRequiredDocActiveHofsGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter, $mdDialog, $scope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
    }

    $onInit() {
        this.module = 'accreditation';
        this.identifier = IDS.accSettings;
        this.statePath = IDS.hofRequiredDocActiveHofsGrid;
        this.filterComponent = false;
        this.exportComponent = false;
        this.confirmDialogOnRemove = true;
        // this.autoload = false;

        this.columns = [
            {title: '#',                                                        mapper: null},
            {title: this.$filter('translate')(`COMMON.FIELD.HEAD_OFFICE`),      mapper: 'hof_name'},
            {title: 'Applicable',                                               mapper: 'checkbox', identifier:this.identifier, statePath: this.statePath, style:{width:'35px'}},
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), AssessmentActions)(this);
        this.dataAction = this.fetchHofRequiredDocHofsGrid;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

HofRequiredDocActiveHofsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter', '$mdDialog', '$scope'];

export const hofRequiredDocActiveHofsGrid = {
    bindings: {
        title: '@',
    },
    controller: HofRequiredDocActiveHofsGridController,
    ...gridTpl
}
