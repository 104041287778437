export const manufacturersState = {
    name: 'app.administration.manufacturers',
    abstract: true,
    url: '/manufacturers',
};

export const manufacturersDashboardState = {
    name: 'app.administration.manufacturers.dashboard',
    url: '',
    component: 'dashboard',
};

export const manufacturersAddState = {
    name: 'app.administration.manufacturers.add',
    redirectTo: 'app.administration.manufacturers.add.details',
    url: '/add',
    component: 'tabs',
};

export const manufacturersAddDetailsState = {
    name: 'app.administration.manufacturers.add.details',
    url: '',
    component: 'manufacturerDetails',
};

export const manufacturersManageState = {
    name: 'app.administration.manufacturers.manage',
    redirectTo: 'app.administration.manufacturers.manage.list',
    url: '/manage',
    component: 'tabs',
};

export const manufacturersManageListingState = {
    name: 'app.administration.manufacturers.manage.list',
    url: '',
    component: 'manufacturersManage',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.administration.manufacturers.manage',
            state: [
                'app.administration.manufacturers.manage.details',
                'app.administration.manufacturers.manage.equipment',
                'app.administration.manufacturers.manage.equipmentModel',
            ],
        });
    },
};

export const manufacturersManageDetailsState = {
    name: 'app.administration.manufacturers.manage.details',
    url: '/details?id',
    component: 'manufacturerDetails',
};

export const manufacturersManageEquipmentState = {
    name: 'app.administration.manufacturers.manage.equipment',
    url: '/equipment?id',
    component: 'manufacturerEquipment',
};

export const manufacturersManageEquipmentModelState = {
    name: 'app.administration.manufacturers.manage.equipmentModel',
    url: '/equipment-model?id&model',
    component: 'manufacturerEquipmentDetails',
    /* @ngInject */
    onExit: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.administration.manufacturers.manage',
            state: ['app.administration.manufacturers.manage.equipmentModel'],
        });
    },
};
