/**
 * @module root/common
 */
import { AUTH_MODULE } from './auth/auth.module';
import { PUBLIC_MODULE } from './public/public.module';
import { TABS_MODULE } from './tabs/tabs.module';
import { FORM_MODULE } from './form/form.module';
import { GRID_MODULE } from './grid/grid.module';
import { DASHBOARD_MODULE } from './dashboard/dashboard.module';
import { ResponseErrorInterceptor } from './http/response-error.interceptor';
import { ResponseStatusInterceptor } from './http/response-status.interceptor';
import { Session } from './utils/util.session';
import { Logger } from './utils/util.logger';
import { Notifier } from './utils/util.notifier';
import { ResponseStatusService } from './http/response-status.service';
import { AppService } from './app.service';
import { NavigationService } from './navigation/navigation.service';
import NavigationActions from './navigation/navigation.actions';
import FileActions from './file/file.actions';
import { app, appState } from './app.component';
import { navigation } from './navigation/navigation.component';
import { appHeader } from './appHeader/appHeader.component';
import { footer } from './layout/footer/footer.component';
import { asset } from './asset/asset.component';
import { loader } from './loader/loader.component';
import { fileUpload } from './file/upload/file-upload.component';
import { gallery } from './gallery/gallery.component';
import { progressBar } from './miscellaneous/progress-bar.component';
import { EVENT } from './utils/util.constants';
import i18n from './utils/i18n';

export const APP_MODULE = angular.module('common', [
    AUTH_MODULE.name,
    PUBLIC_MODULE.name,
    TABS_MODULE.name,
    FORM_MODULE.name,
    GRID_MODULE.name,
    DASHBOARD_MODULE.name
]);

APP_MODULE.config(['$provide', '$httpProvider', '$uiRouterProvider',
    ($provide, $httpProvider, $uiRouter) => {
        $provide.decorator('$exceptionHandler', ['$delegate', 'Logger', ($delegate, Logger) => {
            return (exception, cause) => {
                if (!!(localStorage.myrsoAuthToken && localStorage.myrsoJWT)) {
                    $delegate(exception, cause);
                    Logger.log(exception);
                }
            }
        }]);

        $provide.decorator('$controller', ['$delegate', ($delegate) => {
            return (constructor, locals, later, ident) => {
                // Inject i18n into $scope of every controller
                locals.$scope.i18n = i18n;
                return $delegate(constructor, locals, later, ident);
            };
        }]);

        $httpProvider.interceptors.push('ResponseErrorInterceptor');
        $httpProvider.interceptors.push('ResponseStatusInterceptor');

        const $urlService = $uiRouter.urlService;
        $urlService.rules.otherwise({state: 'login.auth'});

        const $stateRegistry = $uiRouter.stateRegistry;
        $stateRegistry.register(appState);
    }
]);

APP_MODULE.factory('ResponseErrorInterceptor', ResponseErrorInterceptor);
APP_MODULE.factory('ResponseStatusInterceptor', ResponseStatusInterceptor);

APP_MODULE.service('Session', Session);
APP_MODULE.service('Logger', Logger);
APP_MODULE.service('Notifier', Notifier);
APP_MODULE.service('ResponseStatusService', ResponseStatusService);
APP_MODULE.service('AppService', AppService);
APP_MODULE.service('NavigationService', NavigationService);
APP_MODULE.service('NavigationActions', NavigationActions);
APP_MODULE.service('FileActions', FileActions);

APP_MODULE.component({
    app,
    navigation,
    appHeader,
    footer,
    asset,
    loader,
    fileUpload,
    gallery,
    progressBar
});

APP_MODULE.run(['$rootScope', '$state', '$exceptionHandler', 'AuthService', 'AppService',
    ($rootScope, $state, $exceptionHandler, AuthService, AppService) => {
        $rootScope.$on(EVENT.UNAUTHORIZED, () => {
            AuthService.destroySession(true);
            $state.go('login.auth', {reset: true});
        });

        $state.defaultErrorHandler(error => {
            $exceptionHandler(error.detail);
        });

        if (AuthService.isAuthenticated())
            AppService.moduleOverride();
    }
]);
