import watch from 'redux-watch';
import { resolveTpl } from '../personal-dosimetry.utils';

class PersonalDosimetryReportsController {
    /* @ngInject */
    constructor($ngRedux, $state, $uiRouterGlobals, $timeout, $mdDialog, PersonalDosimetryActions, $filter) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.$timeout = $timeout;
        this.$mdDialog = $mdDialog;
        this.PersonalDosimetryActions = PersonalDosimetryActions;
        this.$filter = $filter;
        this.template = resolveTpl('pd-reports');
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(() => ({}), this.PersonalDosimetryActions)(this);

        const watchReport = watch(this.$ngRedux.getState, 'personalDosimetry.personalDosimetryReportsDetailsGrid.filter.reportId');
        this.onReport = this.$ngRedux.subscribe(watchReport((reportId) => {
            reportId && this.onReportReady(reportId);
        }));

        this.stateName = this.$state.current.name;
        this.stateParams = Object.assign({}, this.$uiRouterGlobals.params);
        this.getReportProviders('personalDosimetry');
    }

    onSelect({ item }) {
        this.stateName = 'app.personalDosimetry.reports.details';
        this.$ngRedux.dispatch({
            type: 'REPORT_SELECTED',
            id: 'personalDosimetryReportsDetailsGrid',
            reportId: item.id
        });
    }

    onLimits(event) {
        this.$ngRedux.dispatch({
            type: 'PARTICIPANT_LIMITS',
            id: 'personalDosimetryReportsDetailsGrid',
            value: true,
        });
        this.onSelect(event);
    }

    onRemove(event) {
        const confirm = this.$mdDialog.confirm()
            .title(this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.MANAGE_REPORTS.NOTIFY_REMOVE_TITLE'))
            .textContent(this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.MANAGE_REPORTS.NOTIFY_REMOVE_CONTENT'))
            .ariaLabel(this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.MANAGE_REPORTS.NOTIFY_REMOVE_ARIA_LABEL'))
            .ok(this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.MANAGE_REPORTS.NOTIFY_REMOVE_OK'))
            .cancel(this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.MANAGE_REPORTS.NOTIFY_REMOVE_CANCEL'));

        this.removeDialog = this.$mdDialog.show(confirm).then(() => {
            this.removeDoseReport(event.item.id);
        });
    }

    onReportReady(id) {
        if (id) {
            this.$ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: 'personalDosimetryReportsDetailsGrid'
            });
            this.$ngRedux.dispatch({
                type: 'TAB_ENABLE',
                id: 'app.personalDosimetry.reports',
                state: ['app.personalDosimetry.reports.details']
            });

            if (this.stateName === 'app.personalDosimetry.reports.detailsResult') {
                this.$ngRedux.dispatch({
                    type: 'TAB_SHOW',
                    id: 'app.personalDosimetry.reports',
                    state: 'app.personalDosimetry.reports.detailsResult'
                });
                this.getIndividualDoseReport('app.personalDosimetry.reports', this.stateParams.measurement).then(response => {
                    this.$ngRedux.dispatch({
                        type: 'TAB_SELECT',
                        id: 'app.personalDosimetry.reports',
                        state: 'app.personalDosimetry.reports.detailsResult',
                        stateParams: {
                            id: this.stateParams.id,
                            measurement: this.stateParams.measurement
                        }
                    });
                });
            }
            else {
                this.$timeout(() => {
                    this.$ngRedux.dispatch({
                        type: 'TAB_SELECT',
                        id: 'app.personalDosimetry.reports',
                        state: 'app.personalDosimetry.reports.details',
                        stateParams: {
                            id: id
                        }
                    });
                }, 200);
            }
        }
    }

    $onDestroy() {
        this.unsubscribe();
        this.onReport();
        this.notifyDialog && this.$mdDialog.hide(this.notifyDialog);
        this.removeDialog && this.$mdDialog.hide(this.removeDialog);
    }
}

export const personalDosimetryReports = {
    controller: PersonalDosimetryReportsController,
    template: '<div ng-include src="$ctrl.template"></div>'
};
