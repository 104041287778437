import i18n from '../../i18n/en.json';
import { approveLicence, getEmpLicenceDetails, disapproveLicence } from '../../employees.actions';
import { EMP_LICENCE_TOKEN } from '../../employees.actions.types';

/**
 * @const {object}
 * @memberOf module:Employees
 */
export const empLicenceDetails = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $mdDialog, $location, Notifier) {
        /**
         * @function $onInit
         */
        /* @ngInject */
        this.$onInit = () => {
            this.title = i18n.intro.title;
            this.p1 = i18n.intro.p1;
            this.processTitle = i18n.intro.process.title;
            this.processParagraph = i18n.intro.process.p;
            this.processOL = i18n.intro.process.ol;

            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.details`;

            const token = $location.search().token;
            const licence = $location.search().licence;

            $ngRedux.dispatch({
                type: EMP_LICENCE_TOKEN,
                id: 'empLicence',
                token,
                licence
            });

            this.lic_id = licence;

            this.unsubscribe = $ngRedux.connect(({ employees }) => ({
                empLicence: employees.empLicence,
            }), {
                getEmpLicenceDetails,
                approveLicence,
                disapproveLicence
            })(this);

            this.getEmpLicenceDetails(licence);
        }

        this.onSubmit = (lic_id) => {
            this.approveLicence(lic_id).then(() => {
                Notifier.success('Licence is approved.');
            });
        }

        this.onDisapprove = (lic_id) => {
            this.dialog = $mdDialog.show({
                /* @ngInject */
                controller: ($scope) => {
                    $scope.save = () => {
                        if ($scope.dialogForm.$valid && (this.empLicence.comment)) {
                            $mdDialog.hide();
                            this.disapproveLicence(lic_id, this.empLicence.comment);
                            Notifier.success('Licence Disapproved');
                        } else {
                            Notifier.error('Input Reject reason or select one option form checkboxes');
                        }
                    };
                    $scope.cancel = () => $mdDialog.cancel();
                },
                template: require('./dialogs/emp-licence-disaprove-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        }

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./emp-licence-details.html')
};
