import API from '../../../../common/http/api.fetch';
import {TYPES} from '../improvement-action-type.constants';
import {IDS} from '../improvement-action-id.constants';
import {success, responseMessage} from '../../../../common/utils/util.notifier';
import {GRID_FETCH, GRID_FILTER} from '../../../../common/grid/grid.constants';
import {TAB_ENABLE, TAB_SHOW} from "../../../../common/tabs/tabs.constants";
import camelCase from 'lodash/camelCase';
import I18N from "../../../../common/utils/util.i18n";

export const fetchAssessedImprovementListGrid = (filter) => {
    return dispatch => {
        let emp_uuid =  filter.emp_uuid;
        if(!emp_uuid && filter.my_tasks_only === 1)
            emp_uuid =  filter.user_uuid;

        return API.fetch({
            url: '/accreditation/tasks-list',
            method: 'GET',
            params: {
                ...filter,
                emp_uuid,
                task_type: 'utaimp',
                task_status: filter.task_status === 'isall' ? undefined : filter.task_status,
                scheme_code: filter.scheme_code || undefined,
                keyword:     filter.keyword     || undefined,
                identifier:     undefined,
                my_tasks_only:  undefined,
                user_uuid:      undefined,
            },
        }).then(response => {
            if (response.data.path)
                return response.data;
            else
                dispatch({
                    type:   GRID_FETCH,
                    id:     IDS.accImprovement,
                    path: IDS.improvementListGrid,
                    ...response.data,
                    items: response.data.items.map(item => ({ ...item,
                            id: item.arq_id,
                            assignee: `${item.assignee} (${item.username})`,
                            // utaTypeName: I18N.translate(`ACCREDITATION.TASKS.TYPE.${item.uta_type.toUpperCase()}`),
                            status_name: I18N.translate(`ACCREDITATION.TASKS.STATUS.${item.status_code.toUpperCase()}`, item.status_name),
                            // acc_code: item.acc_code.toUpperCase(),
                            description: item.description && (item.description.length < 64 ? item.description : `${item.description.slice(0, 64)}...`),
                        }
                    ))
                })
        }, error => {
            responseMessage(error);
        })
    }
}

// export const fetchSiteLocationDetails = (siteLocationUuid) => {
//     return dispatch => {
//         return API.fetch({
//             url: `/location/sitelocation/${siteLocationUuid}`,
//             method: 'GET',
//             params: {}
//         }).then(response => {
//             dispatch({
//                 type: TYPES.FETCH_SITE_LOCATION_DETAILS,
//                 id: IDS.accMasterDocList,
//                 data: {
//                     siteLocationUuid,
//                     fiscalYearStart: parseInt(  response.data[0][0].fiscal_year_start ) - 1,
//                     sloQualityManagers: response.data[1].map(item => ({...item, employee_uuid: item.subject_uuid})),
//                 }
//             })
//         })
//     }
// }
//
// export const fetchDocumentDetails = (id) => {
//     return dispatch => {
//         return API.fetch({
//             url:    '/accreditation/improvement-details',
//             method: 'GET',
//             params: { id }
//         }).then( response => {
//             const reviews = response.data[0]
//                 .map(item => camelCaseObject(item))
//                 .map(item => ({...item,
//                     nameAndUsername: item.name + (item.username ?` (${item.username})` : ''),
//                     statusName: I18N.translate(`ACCREDITATION.DOCUMENT_EVALUATION.STATUS.${item.statusCode.toUpperCase()}`)
//                 }))
//             const review = reviews && reviews.find(item => item.authorised)
//             response = camelCaseObject(JSON.parse(response.data[2][0]['@params']));
//             const filename = response.url.split('/').pop();
//             const limit = 128;
//             dispatch({
//                 type: TYPES.FETCH_DOCUMENT_DETAILS,
//                 id: IDS.accMasterDocList,
//                 details: {
//                     ...response,
//                     filename:  filename.length > limit ? `${filename.substr(0, limit)}...` : filename,
//                     filenameTooltip: filename,
//                     review,
//                     originalReview: review ? {...review} : null,
//                 },
//                 reviews,
//             })
//             return response
//         },
//         error => responseMessage(error) );
//     };
// }
//
// export const fetchSchemeDetails = (code) => {
//     return dispatch => {
//         return API.fetch({
//             url:    '/accreditation/scheme-details',
//             method: 'GET',
//             params: { code }
//         }).then(response => {
//             return response.data.details
//         }, error => {
//             responseMessage(error);
//         });
//     };
// }
//
// export const switchToDetailsTab = ($timeout, stateParams) => {
//     return dispatch => {
//         dispatch({
//             type: TAB_ENABLE,
//             id: IDS.app_accreditation_scheme_improvement,
//             state: [ IDS.app_accreditation_scheme_improvement_details]
//         })
//
//         $timeout(() => {
//             dispatch({
//                 type: TAB_SHOW,
//                 id: IDS.app_accreditation_scheme_improvement,
//                 state: IDS.app_accreditation_scheme_improvement_details,
//                 stateParams,
//             })
//         })
//     }
// }

function camelCaseObject (obj) {
    return Object.entries(obj).reduce(
        (result, item) => ( {...result, [camelCase(item[0])]: item[1]} ), {}
    );
}