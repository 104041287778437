import { GridController } from '../../../../common/grid/grid.controller';

/* @ngInject */
export function EmployeeLocationsGridController($controller, $ngRedux, $state, NavigationService, EmployeesActions, $filter) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'employees';
        ctrl.identifier = 'employeeLocationsGrid';
        ctrl.autoload = false;
        ctrl.filterComponent = false;
        ctrl.exportComponent = false;

        ctrl.columns = [
            {title: '#',        mapper: null},
            {title: $filter('translate')('EMPLOYEES.LOCATIONS.GRID_LOCATION_NAME'), mapper: 'name'},
            {title: 'Status', mapper: 'status'},
            {title: 'Pending Until', mapper: 'pending_date'},
            //{title: '',         mapper: 'user-status-button'}
        ];

        ctrl.buttons = [
            {label: 'Set Status', class: 'btnGrid', callback: ctrl.onStatus}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), EmployeesActions)(ctrl);
        ctrl.dataAction = ctrl.getEmployeeLocations;
        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
