/* @ngInject */
export function MammoSNRCNRManager() {

    this.TAB_MAMMO_SNR_CNR_MEASUREMENT = 0;
    this.TAB_MAMMO_SNR_CNR_PLOT_DIAGRAM = 1;
    this.TAB_MAMMO_SNR_CNR_GRID = 2;

    this.tplMammoSNRCNRMeasurement = './signal-and-contract-ratio-measurement-add.html';
    this.tplMammoSNRCNRPlotDiagram = './signal-and-contract-ratio-measurement-plot-diagram.html';
    this.tplMammoSNRCNRGrid = './signal-and-contract-ratio-measurement-grid.html';

    this.tabs = [
        {
            id: this.TAB_MAMMO_SNR_CNR_MEASUREMENT,
            title: 'New Test',
            content: this.tplMammoSNRCNRMeasurement,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: false,
            stateless: true,
            navigation: true
        },
        {
            id: this.TAB_MAMMO_SNR_CNR_PLOT_DIAGRAM,
            title: 'Plot diagram',
            content: this.tplMammoSNRCNRPlotDiagram,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: false,
            stateless: true,
            navigation: true
        },
        {
            id: this.TAB_MAMMO_SNR_CNR_GRID,
            title: 'Remarks',
            content: this.tplMammoSNRCNRGrid,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: false,
            stateless: true,
            navigation: true
        }
    ];
}
