/**
 * @module Public
 */
import * as STATES from './public.states';
import { publicApp } from './public.component';

export const PUBLIC_MODULE = angular.module(
    'common.public',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values({
        ...STATES
    }).map(state => stateRegistry.register(state));
}]).component({
    publicApp
});
