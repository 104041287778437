export const EmployeeSubjectFactory = () => {
    return function EmployeeSubject() {

        this.in_loc_uuid = null;
        this.in_username = null;
        this.in_ocu_id = null;
        this.in_password = null;
        this.in_email = null;
        this.in_phone = null;
        this.in_first_name = '';
        this.in_last_name = '';
        this.in_birthdate = null;
        this.in_gender = null;
        this.password_verify = null;
        this.uuid = null;
        this.licences = [];

        this.addLicence = function(licence) {
            this.licences.push(_.cloneDeep(licence));
        };

        this.removeLicence = function(licence) {
            let idx = _.findIndex(this.licences, ['id', licence.id]);
            if (idx > -1) this.licences.splice(idx, 1);
        };
    };
};
