import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import { getAlertNotifications } from '../events-alert-escalate.actions'

class EventsAlertNotificationsGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService, $uiRouterGlobals) {
        super($ngRedux, $state, NavigationService, $uiRouterGlobals);
        this.$ngRedux = $ngRedux;
        this.$uiRouterGlobals = $uiRouterGlobals;
    }

    $onInit() {
        this.module = 'events';
        this.identifier = 'eventsAlertNotificationsGrid';
        this.icon = false;
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',            mapper: null, style: { width: '20px' }},
            {title: '',             mapper: 'icon', style: { width: '35px' }},
            {title: 'Date/Time',    mapper: 'date'},
            {title: 'Sender',       mapper: 'sender'},
            {title: 'Subject',      mapper: 'subject'},
            {title: 'Receivers',    mapper: 'receivers'},
            {title: 'Status',       mapper: 'status'}
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this),
            { getAlertNotifications }
        )(this);
        this.dataAction = this.getAlertNotifications;
        this.filter.identifier = this.identifier;
        this.filter.iev_id = this.$uiRouterGlobals.params.id;

        this.buttons = [{label: 'Details', class: 'btnGridModify', callback: this.onSelect}];

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const eventsAlertNotificationsGrid = {
    bindings: {
        title: '@',
        eventId: '<',
        onSelect: '&'
    },
    controller: EventsAlertNotificationsGridController,
    ...gridTpl
};
