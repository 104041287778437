/* @ngInject */
export function PersonalLeadEquivalenceFactory() {
    return function PersonalLeadEquivalence() {

        this.id = null;
        this.value = null;
        
        this.getValue = (function() {
            return this.value + ' mm Pb';
        }).bind(this);
    };
}
