import API          from '../../../../../common/http/api.fetch';
import {success, responseMessage}
                    from '../../../../../common/utils/util.notifier';
import I18N         from '../../../../../common/utils/util.i18n';
import { TYPES }    from '../../audit-action-type.constants';
import { IDS }      from '../../audit-action-id.constants';
import pick         from 'lodash/pick';

export const fetchEvidenceProviderCandidates = (keyword, location) => {
    return dispatch => {
        return API.fetch({
            url: `/employees`,
            method: 'GET',
            params: {
                keyword,
                location,
                status: 'act',
                //licences: false,
                offset: 0,
                limit: 20
            }
        }).then(response => {
            dispatch({
                id: IDS.accAudit,
                type: TYPES.FETCH_EVIDENCE_PROVIDER_CANDIDATES,
                data: response.data.list.map(item => ({
                    ...pick(item, ['first_name','last_name','email', 'subject_type_name']),
                    employee_uuid : item.subject_uuid
                    })
                )
            });
        });
    };
};

// export const addAudit = (ast_id, slo_uuid, year, name, description, due_date, qualityManagers, evidenceAssignee, evidenceActivities) => {
//     return dispatch => {
//         return API.fetch({
//             url: `/accreditation/add-audit`,
//             method: 'POST',
//             data: {
//                 ast_id,
//                 slo_uuid,
//                 year,
//                 name,
//                 description: description || undefined,
//                 due_date,
//                 qualityManagers,
//                 evidenceAssignee,
//                 evidenceActivities,
//             }
//         }).then(response => {
//             success('COMMON.SERVER_RESPONSE.ITEM_CREATED');
//         },error => {
//             responseMessage(error)
//         });
//     };
// };

export const updateAudit = (asa_id, due_date, reviewers) => {
    return dispatch => {
        return API.fetch({
            url: `/accreditation/update-audit`,
            method: 'PUT',
            data: {
                asa_id,
                due_date,
                reviewers,
            }
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_UPDATED');
        },error => {
            responseMessage(error)
        });
    };
};

export const addEvidenceProvider = (item) => {
    return dispatch => {
        return dispatch({
            type: TYPES.ADD_AUDIT_ASSIGNEE,
            id: IDS.accAudit,
            path: 'details.evidenceProviders',
            item
        });
    };
};

export const addReviewer = (item) => {
    return dispatch => {
        return dispatch({
            type: TYPES.ADD_AUDIT_ASSIGNEE,
            id: IDS.accAudit,
            path: 'details.reviewers',
            item
        });
    };
};
