import watch from 'redux-watch';
import isNull from 'lodash/isNull';
import find from 'lodash/find';
import isString from 'lodash/isString';

/**
 * Creates a new AdministrationAccountsHeadOfficeContactDetailsController.
 * @class
 * @memberOf module:root/administration/AdministrationAccountsHeadOfficeContactDetailsController
 */
class AdministrationAccountsHeadOfficeContactDetailsController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param $uiRouterGlobals {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/core.uirouterglobals.html|UI-Router UIRouterGlobals}
     * @param Notifier {service} {@link Notifier}
     * @param AdministrationAccountsActions {service}
     */
    constructor($ngRedux, $state, $uiRouterGlobals, AdministrationAccountsActions, Notifier) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.actions = AdministrationAccountsActions;
        this.Notifier = Notifier;
    }

    /**
     * @method $onInit
     * @memberOf module:root/components/administration.AdministrationAccountsHeadOfficeContactDetailsController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.identifier = 'administrationAccountsHeadOfficeContact';
        this.checkboxFlex = 25;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);
        this.contactDetails = !isNull(this.contact.uuid);
        this.grantAccess = false;

        this.getManagerTypesList(this.identifier, this.stateParams.hof);
        this.getContactCandidates(this.identifier, this.stateParams.hof);

        var watchContact = watch(this.$ngRedux.getState, 'administration.administrationAccountsHeadOfficeContact.data.uuid');
        this.onContact = this.$ngRedux.subscribe(watchContact(uuid => {
            this.contactDetails = !isNull(uuid);
        }));
    }

    /**
     * @method onContactDetails
     * @param id {number}
     * @memberOf module:root/components/administration.AdministrationAccountsHeadOfficeContactDetailsController
     */
    onContactDetails() {
        this.administrationAccountsHeadOfficeContact.$setPristine();
        this.administrationAccountsHeadOfficeContact.$setUntouched();
        this.checkboxFlex = this.contactDetails ? 50 : 25;
    }

    /**
     * @method manageContact
     * @param id {number}
     * @memberOf module:root/components/administration.AdministrationAccountsHeadOfficeContactDetailsController
     */
    manageContact() {
        if (this.administrationAccountsHeadOfficeContact.$valid) {
            if (this.contact.uuid) {
                this.updateContactDetails('administrationAccountsHeadOfficeContact', this.contact, 'administrationAccountsHeadOfficeContactsGrid');
            }
            else {
                if (this.contactDetails)
                    this.createContactDetails(this.contact, this.$uiRouterGlobals.params.hof, false, 'administrationAccountsHeadOfficeContact', 'administrationAccountsHeadOfficeContactsGrid');
                else
                    this.assignContact(this.contactCandidate, this.$uiRouterGlobals.params.hof, 'administrationAccountsHeadOfficeContact', 'administrationAccountsHeadOfficeContactsGrid');
            }
        }
        else this.Notifier.error('Please fill in the form fields');
    }

    /**
     * @method $onDestroy
     * @memberof module:root/components/administration.AdministrationAccountsHeadOfficeContactDetailsController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.unsubscribe();
        this.onContact();
    }

    /**
     * @method mapState
     * @param state {object} Redux Store
     * @return {object}
     * @memberof module:root/components/administration.AdministrationAccountsHeadOfficeContactDetailsController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapState(state) {
        return {
            contact: state.administration.administrationAccountsHeadOfficeContact.data,
            contactCandidate: state.administration.administrationAccountsHeadOfficeContact.candidate,
            stateParams: state.administration['app.administration.accounts'].stateParams,
            loading: find(state.administration['app.administration.accounts'].tabs, ['state', 'app.administration.accounts.headOfficesContactDetails']).loading,
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsHeadOfficeContact.title
            ].filter(function(item) {
                return isString(item);
            }).join(' » ')
        };
    }
}

AdministrationAccountsHeadOfficeContactDetailsController.$inject = ['$ngRedux', '$state', '$uiRouterGlobals', 'AdministrationAccountsActions', 'Notifier'];

export const administrationAccountsHeadOfficeContactDetails = {
    controller: AdministrationAccountsHeadOfficeContactDetailsController,
    template: require('./administration-accounts-head-office-contact-details.html')
};
