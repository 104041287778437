angular
    .module('components.equipment')
    .component('equipment', {
        template: require('../../components.html'),
        controller: 'EquipmentController'
    })
    .config(function($stateProvider) {
        $stateProvider
            .state('app.xray', {
                abstract: true,
                url: '/xray-compliance'
            })
            .state('app.xray.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.xray.add', {
                url: '/add?replace&transaction',
                template: require('../../components.html'),
                controller: 'EquipmentXrayAddController'
            })
            .state('app.xray.manage', {
                url: '/manage?id',
                template: require('../../components.html'),
                controller: 'EquipmentXrayManageController'
            })
            .state('app.xray.alerts', {
                redirectTo: 'app.xray.alerts.listing',
                url: '/alerts',
                component: 'tabs'
            })
            .state('app.xray.alerts.listing', {
                url: '?type',
                component: 'equipmentAlerts',
                onExit: function($ngRedux) {
                    $ngRedux.dispatch({
                        type: 'GRID_RESET_FILTER',
                        id: 'equipmentAlertsGrid'
                    });
                }
            })
            .state('app.lasers', {
                abstract: true,
                url: '/lasers'
            })
            .state('app.lasers.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.lasers.add', {
                url: '/add?replace&transaction',
                template: require('../../components.html'),
                controller: 'EquipmentLaserAddController'
            })
            .state('app.lasers.manage', {
                url: '/manage?id',
                template: require('../../components.html'),
                controller: 'EquipmentLaserManageController'
            })
            .state('app.lasers.alerts', {
                redirectTo: 'app.lasers.alerts.listing',
                url: '/alerts',
                component: 'tabs'
            })
            .state('app.lasers.alerts.listing', {
                url: '?type',
                component: 'equipmentAlerts',
                onExit: function($ngRedux) {
                    $ngRedux.dispatch({
                        type: 'GRID_RESET_FILTER',
                        id: 'equipmentAlertsGrid'
                    });
                }
            })
            .state('app.ultrasound', {
                abstract: true,
                url: '/ultrasound'
            })
            .state('app.ultrasound.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.ultrasound.add', {
                url: '/add?replace&transaction',
                template: require('../../components.html'),
                controller: 'EquipmentUltrasoundAddController'
            })
            .state('app.ultrasound.manage', {
                url: '/manage?id',
                template: require('../../components.html'),
                controller: 'EquipmentUltrasoundManageController'
            })
            .state('app.ultrasound.alerts', {
                redirectTo: 'app.ultrasound.alerts.listing',
                url: '/alerts',
                component: 'tabs'
            })
            .state('app.ultrasound.alerts.listing', {
                url: '?type',
                component: 'equipmentAlerts',
                onExit: function($ngRedux) {
                    $ngRedux.dispatch({
                        type: 'GRID_RESET_FILTER',
                        id: 'equipmentAlertsGrid'
                    });
                }
            })
            .state('app.mammo', {
                abstract: true,
                url: '/mammo'
            })
            .state('app.mammo.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.mammo.add', {
                url: '/add?replace&transaction',
                template: require('../../components.html'),
                controller: 'EquipmentMammoAddController'
            })
            .state('app.mammo.manage', {
                url: '/manage?id',
                template: require('../../components.html'),
                controller: 'EquipmentMammoManageController'
            })
            .state('app.mammo.alerts', {
                redirectTo: 'app.mammo.alerts.listing',
                url: '/alerts',
                component: 'tabs'
            })
            .state('app.mammo.alerts.listing', {
                url: '?type',
                component: 'equipmentAlerts',
                onExit: function($ngRedux) {
                    $ngRedux.dispatch({
                        type: 'GRID_RESET_FILTER',
                        id: 'equipmentAlertsGrid'
                    });
                }
            });
    });
