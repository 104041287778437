import { BANDWIDTH_LIMIT } from '../../../common/utils/util.constants';
import { ST_REQUEST_TOKEN } from '../safety-training.actions.types';
import {TAB_DISABLE, TAB_ENABLE, TAB_SELECT, TAB_SHOW} from '../../../common/tabs/tabs.constants';
import {getApplicantTrainings, getCandidateReview, updateCandidateReview} from '../safety-training.actions';

/**
 * @const {object}
 * @memberOf module:SafetyTraining
 */
export const stVideo = {
    /* @ngInject */
    controller: function ($ngRedux, $location, NetSpeedService, $filter, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(({ safetyTraining }) => ({
                completion: safetyTraining.stVideo.completion,
                progress: safetyTraining.stVideo.progress,
                trainings: safetyTraining.stVideo.trainings,
                candidateReview: safetyTraining.stVideo.candidateReview
            }), {
                getApplicantTrainings,
                getCandidateReview,
                updateCandidateReview
            })(this);
            this.identifier = 'public.safetyTraining.laser.video';
            this.state = 'public.safetyTraining.laser.video.player';
            this.title = 'Laser Trainings';
            this.video = null;
            this.info = 'Please wait...'

            $ngRedux.dispatch({
                type: ST_REQUEST_TOKEN,
                id: 'stVideo',
                path: 'token',
                token: $location.search().token,
                training: $location.search().presentation_review_id
            });

            NetSpeedService.testNetSpeed().then((result) => {
                Notifier.info($filter('translate')('SAFETY_TRAINING.TRAINING_VIDEO_REVIEW.INFO_MEASURE_SPEED') + result + ' Kbps.');
                if (result < BANDWIDTH_LIMIT) {
                    Notifier.warn($filter('translate')('SAFETY_TRAINING.TRAINING_VIDEO_REVIEW.WARN_SLOW_NETWORK'));
                } else {
                    this.getApplicantTrainings().then(() => {
                        this.getCandidateVideo();
                    }).catch((error) => {
                        Notifier.error(error.data);
                    });
                }
            }, (error) => {
                // TODO error
            });
        }

        this.getCandidateVideo = () => {
            this.getCandidateReview().then(() => {
                this.info = null;
                this.video = null;

                this.trainings.forEach(item => {
                    if( item.section_number == this.candidateReview.nextSection) {
                        this.video = item;
                    }
                });
                this.numberOfVideos = this.trainings.length;

                if (this.candidateReview.nextSection === -1) {
                    $ngRedux.dispatch({
                        type: TAB_DISABLE,
                        id: 'public.safetyTraining.laser.video',
                        state: [
                            'public.safetyTraining.laser.video.player',
                            'public.safetyTraining.laser.video.acknowledgement'
                        ],
                    });
                    $ngRedux.dispatch({
                        type: TAB_SHOW,
                        id: 'public.safetyTraining.laser.video',
                        state: 'public.safetyTraining.laser.video.confirmation'
                    });
                }
            }).catch((error) => {
                Notifier.error(error.data);
            });
        }

        this.onVideoComplete = (video) => {
            if (parseInt(video.section_number) !== this.trainings.length) {
                this.updateCandidateReview({
                    ...this.candidateReview,
                    watchedSection: parseInt(video.section_number)
                }).then(() => {
                    this.getCandidateVideo();
                }).catch(() => {
                    Notifier.error('You are not authorized. Please contact your Laser Safety Officer');
                });
            } else {
                $ngRedux.dispatch({
                    type: TAB_ENABLE,
                    id: 'public.safetyTraining.laser.video',
                    state: 'public.safetyTraining.laser.video.acknowledgement'
                });
                setTimeout(() => {
                    $ngRedux.dispatch({
                        type: TAB_SELECT,
                        id: 'public.safetyTraining.laser.video',
                        state: 'public.safetyTraining.laser.video.acknowledgement'
                    });
                }, 200);
            }
        }

        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: require('./st-video.html')
};
