import { NAVIGATION_CONFIGS } from '../navigation/navigation.actions.types';
import { PUBLIC_CONFIGS } from './public.configs';

export const publicState = {
    name: 'public',
    redirectTo: 'public.dashboard',
    template: '<public-app flex layout="column" nav-configs="$resolve.navConfigs"></public-app>',
    resolve: {
        navConfigs: ['$ngRedux', ($ngRedux) => {
            $ngRedux.dispatch({
                type: NAVIGATION_CONFIGS,
                configs: PUBLIC_CONFIGS,
            });
            return PUBLIC_CONFIGS;
        }]
    }
};

export const publicDashboardState = {
    name: 'public.dashboard',
    component: 'dashboard',
    url: '/public',
    params: {
        title: 'Dashboard'
    }
};
