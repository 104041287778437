import { EXPORT_FORMAT } from '../../utils/util.constants';
import has from 'lodash/has';
import capitalize from 'lodash/capitalize';
import isFunction from 'lodash/isFunction';
import isUndefined from 'lodash/isUndefined';

/**
 * Creates a new GridExportController.
 * @class
 */
class GridExportController {
    /* @ngInject */
    constructor($state, $mdDialog, NavigationService, Notifier, DocumentManager) {
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.NavigationService = NavigationService;
        this.Notifier = Notifier;
        this.DocumentManager = DocumentManager;
        this.selectedFormat = null;
    }

    $onInit() {
        if (isUndefined(this.exportFormats)) {
            this.exportFormats = EXPORT_FORMAT;
        }
        if (this.exportFormats.length) {
            this.selectedFormat = this.exportFormats[0].format;
        }
    }

    $onChanges(changes) {
        if (has(changes, 'columns')) {
            const gridColumns = changes.columns.currentValue
                .filter(item => item.title && item.mapper)
                .map(item => ({
                    title: item.title,
                    selected: true
                }));
            const exportExtraColumns = (this.exportExtraColumns || []).map(item => ({
                title: item,
                selected: false
            }));
            this.exportColumns = [...gridColumns, ...exportExtraColumns];
        }
    }

    export() {
        if (this.validateExport && this.validateExport() === false) {
            return;
        }

        const options = {
            template: require('./grid-export-dialog.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            /* @ngInject */
            controller: ($scope) => {
                $scope.headerColor = this.NavigationService.getConfig(this.$state.$current).colorName;
                $scope.headerTitle = 'Export ' + this.selectedFormat.toUpperCase() + ' format';
                if (this.selectedFormat?.includes('_')) {
                    $scope.headerTitle = capitalize(this.selectedFormat.replace(/_/g,' '));
                }
                $scope.columns = this.exportColumns.filter(({ title }) => !this.exportExclude.includes(title));
                $scope.showCheckboxDisableRecordsPerEachSlo = this.showCheckboxDisableRecordsPerEachSlo;
                $scope.disableRecordsPerEachSlo = false;
                $scope.export = () => {
                    if ($scope.columns.filter(item => item.selected === true).length > 10 && this.selectedFormat === 'pdf') {
                        this.Notifier.error('Select maximum 10 columns for pdf export');
                    } else {

                        if (isFunction(this.exportService)) {
                            this.$mdDialog.hide();
                            this.Notifier.info('Please wait...', false);
                            this.exportService(Object.assign({}, this.filter, {
                                offset: 0,
                                limit: this.itemsCount,
                                exportFormat: this.selectedFormat,
                                disableRecordsPerEachSlo: $scope.disableRecordsPerEachSlo,
                                exportColumns: $scope.columns.reduce((result, item) => {
                                    if (item.selected) {
                                        result.push(item.title.replace(/ /g, '-').toLowerCase());
                                    }
                                    return result;
                                }, []).join()
                            })).then(data => {
                                this.DocumentManager.downloadFile('/' + data.path);
                                this.Notifier.success('Export Complete!');
                            }).catch(() => {
                                this.Notifier.error('System Error!');
                            });
                        } else throw new Error('Missing export action definition');
                    }
                };

                $scope.onCheckboxChange = (value) => {
                    $scope.disableRecordsPerEachSlo = value;
                }

                $scope.cancel = () => {
                    this.$mdDialog.cancel();
                }
            }
        };
        this.dialog = this.$mdDialog.show(options);
    }

    $onDestroy() {
        this.dialog && this.$mdDialog.hide(this.dialog);
    }
}

export const gridExport = {
    bindings: {
        columns: '<',
        filter: '<',
        itemsCount: '<',
        exportExclude: '<',
        exportService: '<',
        exportFormats: '<',
        exportExtraColumns: '<',
        validateExport: '<',
        showCheckboxDisableRecordsPerEachSlo: '<',
    },
    controller: GridExportController,
    template: require('./grid-export.html')
};
