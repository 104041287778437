/* @ngInject */
export function SafetyPlanCustomizeController($scope, $state, $mdDialog, $rootScope, $q, $timeout, $location, SafetyPlanService, Notifier, SAFETY_PLAN_REVIEW_SETTINGS, $filter, DocumentManager, SafetyPlanTrainingDocument, AuthService) {

    $scope.currentPage = 1;
    $scope.pageSize = 10;

    $scope.selectedItem = null;
    $scope.documents = null;
    $scope.trainingName = $state.current.trainingName;
    $scope.locationUuid = null;
    $scope.document = new SafetyPlanTrainingDocument();
    $scope.currentDate = new Date();
    $scope.showForm = null;
    $scope.locationUuid = null;
    var dialog = null;

    $scope.userData = AuthService.getUser();
    if ($scope.userData.parentCount === 1) {
        $scope.showForm = true;
    }

    $scope.$on('LOCATION_SUBJECT_SELECTED', function (event, locationItem) {
        $scope.locationUuid = locationItem.in_subject_uuid;
        $scope.showForm = true;

        $scope.documents = [];
        $scope.selectedItem = null;
        $timeout(
            function () {
                $scope.fetchData();
            }
        );
    });

    $scope.$on('FILE_UPLOAD_SUCCESS', function(event, filePath, uploader) {
        $scope.document.uri = filePath;
        $scope.document.locationUuid = $scope.locationUuid;
        SafetyPlanService.addDocument($scope.document, $state.current.trainingType).then(function(data) {
            $scope.document = new SafetyPlanTrainingDocument();
            uploader.clearQueue();
            $timeout(
                function () {
                    $scope.fetchData();
                }
            );
            Notifier.success('Document added successfully');
        }, function(error) {
            Notifier.responseMessage(error);
        });
    });

    $scope.resetFilter = function () {
        $rootScope.$broadcast('SELECT_CASCADE_RESET', 'selectLocationForm');
        $scope.locationUuid = null;

        if ($scope.userData.parentCount === 1)
            $scope.showForm = true;
        else
            $scope.showForm = false;
    };

    $scope.resetList = function () {
        $scope.documents = [];
        $scope.selectedItem = null;
        $scope.resetFilter();
        $timeout(
            function () {
                $scope.fetchData();
            }
        );
    };

    $scope.selectItem = function(item) {
        $scope.selectedItem = item;
    };

    $scope.fetchData = function() {
        $scope.selectedItem = null;

        $q.all([
            SafetyPlanService.fetchEmployeeSafetyPlanDownloadDocuments($state.current.trainingType, $scope.locationUuid)
                .then(function(data) {
                    $scope.documents = data.list.reverse();
                    $scope.documents.forEach(function (item) {
                        if (item.docDateEdited != null) {
                            item.docDateEditedStr = $filter('date')(item.docDateEdited, SAFETY_PLAN_REVIEW_SETTINGS.DATE_FORMAT);
                        }
                        if (item.validUntil != null) {
                            item.validUntilStr = $filter('date')(item.validUntil, SAFETY_PLAN_REVIEW_SETTINGS.DATE_FORMAT);
                        }
                    });
                }, function(error) {
                    Notifier.error($filter('translate')("SAFETY_PLAN.ERROR_FETCH_SAFETY_PLAN_DOCUMENTS"));
                })
        ]).then(function(){});
    };

    $scope.downloadDocument = function(item) {
        DocumentManager.downloadFile("/"+item.documentVariant.docVariantPath, 3);
    };

    $scope.removeDocument = function(item) {
        var confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Document will be removed.')
            .ariaLabel('Remove Document')
            .ok('Yes')
            .cancel('No');

        dialog = $mdDialog.show(confirm).then(function() {
            SafetyPlanService.removeDocument(item);
            $scope.fetchData();
        }, angular.noop);
    };

    $scope.fetchData();
}
