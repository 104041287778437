export const mainState = {
    name: 'app.document-management',
    abstract: true,
    url: '/dms'
};

export const dashboardState = {
    name: 'app.document-management.dashboard',
    url: '',
    component: 'dashboard'
};