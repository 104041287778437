export const selectSiteLocationCenterType = {
    bindings: {
        module: '@',
        identifier: '@'
    },
    require : {
        form : '^form'
    },
    template: require('./select-site-location-center-type.html'),
    controller: 'SelectSiteLocationCenterTypeController'
};
