import API from '../../../../common/http/api.fetch';
import * as TYPES from '../manufacturers.actions.types';

/**
 * @function getManufacturerEquModels
 * @param {Object} filter
 */
export const getManufacturerEquModels = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: '/manufacturer/equipment/models',
            method: 'GET',
            params: {
                man_id: filter.man_id,
                ety_id: filter.ety_id,
                keyword: filter.keyword,
                offset: filter.offset,
                limit: filter.limit,
                exportFormat: filter.exportFormat,
                exportColumns: filter.exportColumns
            },
        }).then(({ data }) => {
            if (filter.exportFormat) {
                return data;
            }
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.items,
                itemsCount: parseInt(data.items_count),
            });
        });
    }
}

/**
 * @function createManufacturerEquModel
 */
export const getManufacturerEquTypes = () => {
    return (dispatch) => {
        return API.fetch({
            url: '/equipment/types',
            method: 'GET',
            params: {},
        }).then(({ data }) => {
            dispatch({
                type: TYPES.MANUFACTURER_EQU_TYPES,
                id: 'manufacturer',
                data: data.map((item) => ({
                    value: item.ety_id,
                    label: item.name,
                })),
            });
        });
    }
}

/**
 * @function createManufacturerEquModel
 * @param {Object} data
 * @param {number} data.man_id
 * @param {number} data.ety_id
 * @param {string} data.name
 */
export const createManufacturerEquModel = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/manufacturer/equipment/model-create',
            method: 'POST',
            data,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'manufacturerEquipmentGrid'
            });
        });
    }
}

/**
 * @function updateManufacturerEquModel
 * @param {Object} data
 * @param {number} data.emo_id
 * @param {string} data.name
 * @param {number} data.ety_id
 */
export const updateManufacturerEquModel = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/manufacturer/equipment/model-update',
            method: 'POST',
            data,
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'manufacturerEquipmentGrid'
            });
        });
    }
}

/**
 * @function getManufacturerEquModelDetails
 * @param {number} emo_id
 */
export const getManufacturerEquModelDetails = (emo_id) => {
    return (dispatch) => {
        return API.fetch({
            url: '/manufacturer/equipment/model-details',
            method: 'GET',
            params: { emo_id },
        }).then(({ data }) => {
            dispatch({
                type: TYPES.MANUFACTURER_EQU_MODEL,
                id: 'manufacturer',
                data: data[0][0],
            });
        });
    }
}
