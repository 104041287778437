import { GridController, gridTpl } from '../../../../../../common/grid/grid.controller';
import { getVendorGoods } from '../../../cre-vendors.actions';

class CredentialingVendorsGoodsGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, $location, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$location = $location;
    }

    $onInit() {
        this.module = 'credentialing';
        this.identifier = 'creVendorsGoodsGrid';
        this.header = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                mapper: null},
            {title: 'Type',             mapper: 'type'},
            {title: 'Manufacturer',     mapper: 'manufacturer'},
            {title: 'Model No.',        mapper: 'model_number'},
            {title: 'Price ex tax',     mapper: 'pog_ex_tax'},
            {title: 'Price inc tax',    mapper: 'pog_in_tax'},
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), { getVendorGoods }
        )(this);

        this.dataAction = this.getVendorGoods;
        this.filter.token = this.$location.search().token;
        this.filter.identifier = this.identifier;
        this.filter.application_id = this.$location.search().invite || this.$ngRedux.getState().credentialing.creVendor.application_id;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const creVendorsGoodsGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onRemove: '&'
    },
    controller: CredentialingVendorsGoodsGridController,
    ...gridTpl
};
