import { MANUFACTURER_EQU_MODEL_CREATE } from '../../manufacturers.actions.types';
import { TAB_SHOW } from '../../../../../common/tabs/tabs.constants';

export const manufacturerEquipmentGridModel = {
    bindings: {},
    /* @ngInject */
    controller: function ($ngRedux, $state, $uiRouterGlobals) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.stateId = $state.$current.parent.name;
            this.unsubscribe = $ngRedux.connect(() => ({}), {})(this);
        }

        /**
         * @function onAdd
         */
        this.onAdd = () => {
            $ngRedux.dispatch({
                type: MANUFACTURER_EQU_MODEL_CREATE,
                id: 'manufacturer',
            });
            $ngRedux.dispatch({
                type: TAB_SHOW,
                id: this.stateId,
                state: [`${this.stateId}.equipmentModel`],
                stateParams: $uiRouterGlobals.params,
            });
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: `
        <div layout="row"
             layout-align-xs="center center"
             layout-align-sm="center center">
            <div flex hide-xs hide-sm></div>
            <md-button type="submit" class="btnDefault btnColorAdministration" ng-click="$ctrl.onAdd()">
                Add Model
            </md-button>
        </div>
    `,
};
