import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import { getLicenceIsotopes } from '../../administration.actions';

class AdministrationAccountsLicenceIsotopesGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'administration';
        this.identifier = 'administrationAccountsLicenceIsotopesGrid';
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                    mapper: null},
            {title: 'Licence',              mapper: 'lic_name'},
            {title: 'Isotope',              mapper: 'isotope_name', sort: 'isotope_name'},
            {title: 'Atomic Number',        mapper: 'atomic_no'},
            {title: 'Source Type',          mapper: 'source_type'},
            {title: 'Unit',                 mapper: 'unit_name'},
            {title: 'Maximum Quantity',     mapper: 'maximum_activity'}
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), {
                getLicenceIsotopes
            }
        )(this);

        this.dataAction = this.getLicenceIsotopes;
        this.filter.identifier = this.identifier;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const administrationAccountsLicenceIsotopesGrid = {
    bindings: {
        onRemove: '&'
    },
    controller: AdministrationAccountsLicenceIsotopesGridController,
    ...gridTpl
};
