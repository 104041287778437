/*
*   Actions which are passed from accreditation.reducer.js to assessment-reports.reducer.js
 */

export const PREFIX = 'ACC_ASSESSMENT_REPORTS';

export const TYPES = {
    SET_CURRENT_FISCAL_YEAR:        `${PREFIX}_SET_CURRENT_FISCAL_YEAR`,
    FETCH_SITE_LOCATION_DETAILS:    `${PREFIX}_FETCH_SITE_LOCATION_DETAILS`,
    FETCH_DOCUMENT_DETAILS:         `${PREFIX}_FETCH_DOCUMENT_DETAILS`,
    FETCH_SCHEME_DETAILS:           `${PREFIX}_FETCH_SCHEME_DETAILS`,
    FETCH_ACC_BODIES_OPTIONS:        `${PREFIX}_FETCH_ACC_BODIES_OPTIONS`,
};
