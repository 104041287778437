/**
 * @module root/components/dose-reference-levels
 */
import { drlState, drlDashboardState, drlCtScannerState, drlCtScannerDashboardState, drlFluoroscopyAngiography } from './dose-reference-levels.states';
import { drlCtScannerDoseReportState, drlCtScannerDoseReportGeneratorState } from './dose-report/drl-ct-scanner-dose-report.states';
import { drlCtScannerContrastReportState, drlCtScannerContrastReportGeneratorState } from './contrast-report/drl-ct-scanner-contrast-report.states';
import { drlCtScannerSystemStatusState, drlCtScannerSystemStatusListingState } from './system-status/drl-ct-scanner-system-status.states';
import { doseReferenceLevels } from './dose-reference-levels.component';
import { drlCtScannerDoseReport } from './dose-report/drl-ct-scanner-dose-report.component';
import { drlCtScannerContrastReport } from './contrast-report/drl-ct-scanner-contrast-report.component';
import { drlCtScannerSystemStatus } from './system-status/drl-ct-scanner-system-status.component';
import { selectContrast } from './contrast-report/form/select-contrast/select-contrast.component';
import { selectBodyPart } from './dose-report/form/select-body-part/select-body-part.component';
import { drlCtScannerSystemStatusGrid } from './system-status/grid/drl-ct-scanner-system-status-grid.component';
import DoseReferenceLevelsActions from './dose-reference-levels.actions';

export const DOSE_REFERENCE_LEVELS_MODULE = angular.module(
    'migration.dose-reference-levels',
    []
).config(['$uiRouterProvider', $uiRouter => {
    const $stateRegistry = $uiRouter.stateRegistry;
    $stateRegistry.register(drlState);
    $stateRegistry.register(drlDashboardState);
    $stateRegistry.register(drlCtScannerState);
    $stateRegistry.register(drlCtScannerDashboardState);
    $stateRegistry.register(drlCtScannerDoseReportState);
    $stateRegistry.register(drlCtScannerDoseReportGeneratorState);
    $stateRegistry.register(drlCtScannerContrastReportState);
    $stateRegistry.register(drlCtScannerContrastReportGeneratorState);
    $stateRegistry.register(drlCtScannerSystemStatusState);
    $stateRegistry.register(drlCtScannerSystemStatusListingState);
    $stateRegistry.register(drlFluoroscopyAngiography);
}]).service({
    DoseReferenceLevelsActions
}).component({
    doseReferenceLevels,
    drlCtScannerDoseReport,
    drlCtScannerContrastReport,
    drlCtScannerSystemStatus,
    selectContrast,
    selectBodyPart,
    drlCtScannerSystemStatusGrid
}).run(['$templateCache', $templateCache => {
    $templateCache.put('app.doseReferenceLevels.ctScanner.doseReport.generator', '<drl-ct-scanner-dose-report></drl-ct-scanner-dose-report>');
    $templateCache.put('app.doseReferenceLevels.ctScanner.contrastReport.generator', '<drl-ct-scanner-contrast-report></drl-ct-scanner-contrast-report>');
    $templateCache.put('app.doseReferenceLevels.ctScanner.systemStatus.listing', '<drl-ct-scanner-system-status></drl-ct-scanner-system-status>');
}]);
