import isObject from 'lodash/isObject';

/* @ngInject */
export function ProtectiveEquipmentTestService($http, API_BASE, Helpers, PersonalEquipmentTest, PersonalEquipmentTester, PersonalEquipmentTestComment, FileDocument) {

    this.getPersonalEquipmentTests = function(filter) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/tests",
            method: "GET",
            params: {
                equipmentId:        filter.equipmentId,
                locationUuid:       filter.location,
                equipmentTypeId:    filter.type,
                offset:             filter.offset,
                limit:              filter.limit,
                status:             filter.status,
                exportFormat:       filter.exportFormat,
                exportColumns:      filter.exportColumns
            }
        }).then(function(response) {
            if (filter.exportFormat) {
                return response.data;
            }
            else {
                var tests = response.data.rows.map(function (item) {
                    var equipmentTest = new PersonalEquipmentTest();
                    equipmentTest.id = item.pte_id;
                    equipmentTest.equipmentId = item.equ_id;
                    equipmentTest.ppeEquipmentId = item.equipment_id;
                    equipmentTest.equipmentType = item.equ_type;
                    equipmentTest.equipmentLeadEquivalence = item.lead_equivalence;
                    equipmentTest.annualVisualResults = item.annual_visual_results;
                    equipmentTest.annualScreening = item.annual_screening_result_code == 'passed' ? 1 : 0;
                    equipmentTest.annualScreeningResult = item.annual_screening_result_code;
                    equipmentTest.annualScreeningResultDesc = item.annual_screening_result;
                    equipmentTest.tester.uuid = item.tester_uuid;
                    equipmentTest.tester.firstName = item.tester_first_name;
                    equipmentTest.tester.lastName = item.tester_last_name;
                    equipmentTest.testedDate = Helpers.formatDate(item.date_of_test);
                    equipmentTest.dueDate = Helpers.formatDate(item.due_date);
                    equipmentTest.location = item.location_name;
                    return equipmentTest;
                });
                return {
                    items: tests,
                    total: Number.isInteger(response.data.total)
                        ? response.data.total
                        : JSON.parse(response.data.total).total_records
                }
            }
        });
    };

    this.getPersonalEquipmentTestDetails = function(testId) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/test-details",
            method: "GET",
            params: {
                testId: testId
            }
        }).then(function(response) {
            var item = _.first(response.data[0]);
            var equipmentTest = new PersonalEquipmentTest();
            equipmentTest.id                        = item.pte_id;
            equipmentTest.equipmentId               = item.equ_id;
            equipmentTest.equipmentType             = item.equ_type_name;
            equipmentTest.equipmentLeadEquivalence  = item.lead_equivalence;
            equipmentTest.annualVisualResults       = item.annual_visual_results;
            equipmentTest.annualScreening           = item.annual_screening_result_code == 'passed' ? 1 : 0;
            equipmentTest.annualScreeningResult     = item.annual_screening_result_code;
            equipmentTest.annualScreeningResultDesc = item.annual_screening_result;
            equipmentTest.tester.uuid               = item.tester_uuid;
            equipmentTest.tester.firstName          = item.tester_first_name;
            equipmentTest.tester.lastName           = item.tester_last_name;
            equipmentTest.testedDate                = Helpers.formatDate(item.date_of_test);
            equipmentTest.dueDate                   = Helpers.formatDate(item.due_date);
            equipmentTest.testDateNotificationFrequency = item.test_notification_frequency ? item.test_notification_frequency : 18;

            _.each(response.data[1], function(obj) {
                var comment = new PersonalEquipmentTestComment();
                comment.id      = obj.pco_id;
                comment.text    = obj.annual_visual_result;
                equipmentTest.comments.push(comment);
            });

            _.each(response.data[2], function(obj) {
                var document = new FileDocument();
                document.id             = obj.fil_id;
                document.url            = obj.url;
                document.description    = obj.description;
                equipmentTest.documents.push(document);
            });
            return equipmentTest;
        }, function(error) {
            return error;
        });
    };

    this.addPersonalEquipmentTest = function(equipmentId, equipmentTest) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/test-add",
            method: "POST",
            data: {
                equipmentId:            equipmentId,
                annualVisualResults:    equipmentTest.getCommentIdList(),
                annualScreeningResult:  equipmentTest.annualScreeningResult,
                testerUuid:             equipmentTest.tester.uuid,
                testDate:               Helpers.formatDate(equipmentTest.testedDate),
                testDueDate:            Helpers.formatDate(equipmentTest.dueDate),
                documentIdList:         equipmentTest.getDocumentIdList(),
                testDateNotificationFrequency: equipmentTest.testDateNotificationFrequency
            }
        }).then(({ data }) => {
            const params = isObject(data.params) ? data.params : JSON.parse(data.params);
            return params.pte_id;
        });
    };

    this.updatePersonalEquipmentTest = function(equipmentTest) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/test-update",
            method: "POST",
            data: {
                testId:                 equipmentTest.id,
                annualScreeningResult:  equipmentTest.annualScreeningResult,
                testerUuid:             equipmentTest.tester.uuid,
                testDate:               Helpers.formatDate(equipmentTest.testedDate),
                testDueDate:            Helpers.formatDate(equipmentTest.dueDate),
                testDateNotificationFrequency: equipmentTest.testDateNotificationFrequency
            }
        }).then(({ data }) => data.status);
    };

    this.addPersonalEquipmentTestDocument = function(document) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/test-document-add",
            method: "POST",
            data: {
                url:            document.url,
                description:    document.description
            }
        }).then(({ data }) => {
            const params = isObject(data.params) ? data.params : JSON.parse(data.params);
            return params.fil_id;
        });
    };

    this.assignPersonalEquipmentTestDocument = function(testId, documentId) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/test-document-assign",
            method: "POST",
            data: {
                testId:     testId,
                documentId: documentId
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.removePersonalEquipmentTestDocument = function(testId, documentId) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/test-document-remove",
            method: "POST",
            data: {
                testId:     testId,
                documentId: documentId
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.getComments = function(ppeId) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/test-comments",
            method: "GET",
            params: {
                ppe_id: _.isUndefined(ppeId) ? null : ppeId
            }
        }).then(function(response) {
            return response.data.map(function(item) {
                var comment     = new PersonalEquipmentTestComment();
                comment.id      = item.pco_id;
                comment.text    = item.comment_text;
                return comment;
            });
        }, function(error) {
            return error;
        });
    };

    this.addComment = function(comment, ppeId) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/test-comment-add",
            method: "POST",
            data: {
                commentText: comment.text,
                ppe_id: ppeId
            }
        }).then(({ data }) => {
            const params = isObject(data.params) ? data.params : JSON.parse(data.params);
            return params.pco_id;
        });
    };

    this.updateComment = function() {
        return $http({
            url: API_BASE + "/protective-equipment/personal/test-comment-update",
            method: "POST",
            data: {
            }
        }).then(function(response) {
            return response.data;
        }, function(error) {
            return error;
        });
    };

    this.assignComment = function(testId, commentId) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/test-comment-assign",
            method: "POST",
            data: {
                testId:     testId,
                commentId:  commentId
            }
        }).then(function(response) {
            return response.data.status;
        });
    };

    this.removeComment = function(testId, commentId) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/test-comment-remove",
            method: "POST",
            data: {
                testId:     testId,
                commentId:  commentId
            }
        }).then(function(response) {
            return response.data.status;
        });
    };

    this.deleteComment = function(commentId) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/test-comment-delete",
            method: "POST",
            data: {
                commentId: commentId
            }
        }).then(function(response) {
            return response.data.status;
        });
    };

    this.getTesterCandidates = function(equipmentId) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/tester-candidates",
            method: "GET",
            params: {
                equipmentId : equipmentId
            }
        }).then(function(response) {
            return response.data.map(function(item) {
                var tester = new PersonalEquipmentTester();
                tester.uuid         = item.sub_uuid;
                tester.firstName    = item.first_name;
                tester.lastName     = item.last_name;
                return tester;
            });
        }, function(error) {
            return error;
        });
    };

    this.addTesterContractor = function(locationUuid, equipmentTester) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/tester-contractor-add",
            method: "POST",
            data: {
                locationUuid:   locationUuid,
                firstName:      equipmentTester.firstName,
                lastName:       equipmentTester.lastName
            }
        }).then(({ data }) => {
            const params = isObject(data.params) ? data.params : JSON.parse(data.params);
            return params.ctr_uuid;
        }, function(error) {
            return error;
        });
    };

    this.getTestStatuses = function() {
        return $http({
            //url: API_BASE + "/licence/statuses",
            url: API_BASE + "/protective-equipment/personal/test-statuses",
            method: "GET"
        }).then(function(response) {
            return response.data.map(function(item) {
                return {
                    id: item.sta_id,
                    code: item.status_code,
                    name: item.status_name
                }
            });
        });
    }

    this.disposeEquipmentTest = function(testID) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/test-remove",
            method: "POST",
            data: {
                pte_id: testID
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    }
}
