import moment from 'moment';

/* @ngInject */
export function UserService($http, API_BASE, Employee, User, Occupation, Helpers) {

    this.fetchOccupationsSiteLocation = function(parentUuid) {
        var qString = '';
        if (typeof parentUuid === 'undefined' || parentUuid === undefined)
            qString = '';
        else
            qString = "?parent=" + parentUuid;

        return $http({
            url: API_BASE + "/occupations" + qString,
            method: "GET"
        }).then(function(response) {
            var arr = [];
            response.data.forEach(function(elem) {
                var item = new Occupation();
                item.id = elem.ocu_id;
                item.name = elem.ocu_name;
                item.ref_no = elem.ocu_ref_no;
                arr.push(item);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    /* Get occupations for parent locations of current user.
    User location is retrieved from token */
    this.fetchOccupationsParentOffice = function() {
        return $http({
            url: API_BASE + "/occupations/parent",
            method: "GET"
        }).then(function(response) {
            var arr = [];
            response.data.forEach(function(elem){
                var item = new Occupation();
                item.id = elem.ocu_id;
                item.name = elem.ocu_name;
                item.ref_no = elem.ocu_ref_no;
                arr.push(item);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    this.addEmployee = function(employee) {
        employee.in_gender = employee.in_gender.id;
        employee.in_birthdate = formatDbDate(employee.in_birthdate);
        employee.in_ocu_id = _.get(employee.in_ocu_id, 'id', null);
        employee.in_email = _.isUndefined(employee.in_email) ? null : employee.in_email.toLowerCase();
        return $http({
            url: API_BASE + "/employee",
            method: "POST",
            data: employee
        }).then(function(response) {
            return response;
        }, function(error) {
            return error;
        });
    };

    this.assignParent = function(employeeUuid, parentUuid) {
        return $http({
            url: API_BASE + "/employee/" + employeeUuid + "/assign",
            method: "PUT",
            data: {
                parent: parentUuid,
                user: employeeUuid
            }
        }).then(function(response) {
            return response;
        }, function(error) {
            return error;
        });
    };

    this.retrieveParents = function(employeeUuid) {
        return $http({
            url: API_BASE + "/employee/" + employeeUuid + "/parents",
            method: "GET"
        }).then(function(response) {
            return response;
        }, function(error) {
            return error;
        });
    };

    this.saveRsc = function(rsc) {
        return $http({
            url: API_BASE + "/rsc/" + rsc.uuid,
            method: "PUT",
            data: {
                gender:     rsc.gender,
                firstName:  rsc.firstName,
                lastName:   rsc.lastName,
                birthday:   Helpers.formatDate(rsc.birthDate),
                phone:      rsc.phone,
                email:      rsc.email.toLowerCase(),
                fax:        rsc.fax
            }
        }).then(function(data) {
            return data;
        });
    };

    this.addLicence = function(user, licence) {
        return $http({
            url: API_BASE+ "/licence/user/" + user.uuid,
            method: "POST",
            data: {
                regIdNo: licence.regIdNo,
                type: licence.type.id,
                equipmentType: licence.equipmentType,
                issued: licence.issued,
                expires: formatDbDate(licence.expires),
                licencePath: licence.uri
            }
        }).then(function(response) {
            return response;
        });
    };

    this.fetchManagerDetails = (emp_uuid, loc_uuid, occupation_base_type = 'man') => {
        return $http({
            url: API_BASE + "/employee-details",
            method: "GET",
            params: {
                occupation_base_type,
                emp_uuid,
                loc_uuid,
            }
        }).then(function(response) {
            var employee = new Employee();
            var subject = _.first(response.data[0]);

            var occupation = new Occupation();
            occupation.id = subject.type_id;
            occupation.name = subject.occupation;

            employee.id = subject.account_id;
            employee.username = subject.username;
            employee.name = subject.client_name;
            employee.email = subject.email;
            employee.phone  = subject.phone;
            employee.fax  = subject.fax;
            employee.uuid = subject.uuid;
            employee.created = moment(subject.created_time).format('DD-MM-YYYY');
            employee.department.uuid = subject.dep_uuid;
            employee.department.name = subject.department_name;
            employee.occupation = occupation;
            employee.firstName = subject.first_name;
            employee.lastName =  subject.last_name;
            employee.birthday = Helpers.formatDate(subject.birthdate);
            employee.birthDate = Helpers.formatDate(subject.birthdate);
            employee.gender = subject.gender;
            employee.statusName = subject.status_name;

            // TODO this should be status getter in employee factory
            switch (subject.status_id) {
                case '1':
                    employee.status = false;
                    break;
                case '2':
                    employee.status = true;
                    break;
            }
            return employee;
        }, function(error) {
            return error;
        });
    };

    this.fetchUsers = function(parentUuid) {
        return $http({
            url: API_BASE + "/users/" + parentUuid,
            method: "GET"
        }).then(function(response) {
            var arr = [];
            response.data.forEach(function(elem) {
                var user = new User();
                if(elem.name){
                    user.account.name = elem.name;
                }
                if(elem.subject_uuid){
                    user.account.uuid = elem.subject_uuid;
                }
                if(elem.subject_type_id){
                    user.account.type.id = elem.subject_type_id;
                }
                if(elem.subject_type_name){
                    user.account.type.name = elem.subject_type_name;
                }
                if(elem.created){
                    user.account.createDate = moment(elem.created).format('DD-MM-YYYY');
                }
                if(elem.parent_uuid){
                    user.account.parent.uuid = elem.parent_uuid;
                }
                if(elem.parent_username){
                    user.account.parent.username = elem.parent_username;
                }
                if(elem.first_name){
                    user.firstName = elem.first_name;
                }
                if(elem.last_name){
                    user.lastName = elem.last_name;
                }
                if(elem.email){
                    user.email = elem.email;
                }
                if(elem.phone){
                    user.phone = elem.phone;
                }
                arr.push(user);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    this.fetchUsersAvailable = function() {
        return $http({
            url: API_BASE + "/users",
            method: "GET"
        }).then(function(response) {
            var arr = [];
            response.data.forEach(function(elem) {
                var user = new User();
                if(elem.name){
                    user.account.name = elem.name;
                }
                if(elem.subject_uuid){
                    user.account.uuid = elem.subject_uuid;
                }
                if(elem.subject_type_id){
                    user.account.type.id = elem.subject_type_id;
                }
                if(elem.subject_type_name){
                    user.account.type.name = elem.subject_type_name;
                }
                if(elem.created){
                    user.account.createDate = moment(elem.created).format('DD-MM-YYYY');
                }
                if(elem.parent_uuid){
                    user.account.parent.uuid = elem.parent_uuid;
                }
                if(elem.parent_username){
                    user.account.parent.username = elem.parent_username;
                }
                if(elem.first_name){
                    user.firstName = elem.first_name;
                }
                if(elem.last_name){
                    user.lastName = elem.last_name;
                }
                if(elem.email){
                    user.email = elem.email;
                }
                if(elem.phone){
                    user.phone = elem.phone;
                }
                arr.push(user);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    this.updateUser = function(item) {
        return $http({
            url: API_BASE + "/user/" + item.account.uuid,
            method: "PUT",
            data: {
                name: item.account.name,
                email: _.isUndefined(item.email) ? null : item.email.toLowerCase(),
                phone: item.phone,
                firstName: item.firstName,
                lastName: item.lastName
            }
        }).then(function(data) {
            var user = new User();
            if (elem.username) {
                user.username = elem.username;
            }
            if (elem.first_name) {
                user.firstName = elem.first_name;
            }
            if (elem.last_name) {
                user.lastName = elem.last_name;
            }
            if (elem.email) {
                user.email = elem.email;
            }
            if (elem.phone) {
                user.phone = elem.phone;
            }
            if (elem.birthdate) {
                user.birthdate = elem.birthdate;
            }
            return user;
        }, function(error) {
            return error;
        });
    };

    this.licenceTypes = function(location, type) {
        if (typeof type === 'undefined') type = 'uli';
        return $http({
            url: API_BASE+ "/licence/types/" + type,
            method: "GET",
            params: {
                location: location
            }
        }).then(function(response) {
            var arr = [];
            response.data.forEach(function(elem) {
                var item = {};
                item.id = elem.lty_id;
                item.name = elem.name;
                arr.push(item);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    this.getUserAllowedActions = function(menuId) {
        return $http({
            url: API_BASE + "/subject/allowed-actions",
            method: "GET",
            params: {
                menuId: _.isUndefined(menuId) ? null : menuId
            }
        }).then(function(response) {

            var result = JSON.parse(response.data.result);

            return result;

        }, function(error) {
            return error;
        });
    };
}
