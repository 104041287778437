import { IDS } from '../../settings-action-id.constants';
import {TYPES} from '../../settings-action-type.constants';
import {TYPES as ACC_TYPES} from '../../../accreditation-action-type.constants';
import {TAB_DISABLE} from '../../../../../common/tabs/tabs.constants';
import * as Actions from '../sections.actions';
import * as AccreditationActions from '../../../accreditation.actions';

/**
 * Creates a new SectionDetailsController.
 * @class
 */
class SectionDetailsController {
    constructor($ngRedux, $state, $scope, $mdDialog, $filter, NavigationService, Notifier) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        this.$filter = $filter;
        this.NavigationService = NavigationService;
        this.Notifier = Notifier;
    }

    $onInit() {
        this.identifier     = IDS.accSettings;
        this.statePath      = '';
        this.unsubscribe    = this.$ngRedux.connect( state => {
            const sectionDetails = state.accreditation.accSettings.sectionDetails;
            const schemeDetails = state.accreditation.accSettings.schemeDetails;
            return {
                sectionDetails,
                title:              sectionDetails?.nodeName,
                treeLevel:          sectionDetails?.level,
                standardId:         sectionDetails?.id,
                standardName:       sectionDetails?.name,
                standardPrefix:     sectionDetails?.prefix,
                standardSiblings:   sectionDetails?.siblings,
                isAddingNewNode:    !!sectionDetails?.numberInputSelect,

                schemeId:   schemeDetails?.id,
                schemeCode: schemeDetails?.code,

                digits: {
                    level1_num_digits: schemeDetails?.level1_num_digits,
                    level2_num_digits: schemeDetails?.level2_num_digits,
                    level3_num_digits: schemeDetails?.level3_num_digits,
                }
            };
        }, { ...AccreditationActions, ...Actions })(this);
    }

    onCreateChildNodeBtn() {
        let key;
        switch(this.treeLevel) {
            case 1: key = 'ADD_NEW_STANDARD_TO_CATEGORY'; break;
            case 2: key = 'ADD_NEW_ACTIVITY_TO_STANDARD'; break;
        }
        this.title = this.$filter('translate')(`ACCREDITATION.SETTINGS.SECTIONS.${key}`)
        const numberInputSelect = [];
        for(let i = 1; i < 100; i++) {
            if(this.standardSiblings.includes(i) === false) {
                numberInputSelect.push({
                    label: `${this.standardPrefix}.${i}`,
                    value: i,
                });
            }
        }
        const formattedNumberInputSelect = numberInputSelect.map(item => {
            const standardsArr = item.label.split('.')
            const digitsArr = Object.values(this.digits)
            const formattedNumber = standardsArr.map((number, index) => {
                return number.padStart(digitsArr[index], '0')
            })
            return {
                value: item.value,
                label: formattedNumber.join('.')
            }
        })
        this.$ngRedux.dispatch({
            type:   TYPES.SECTION_DETAILS_REFRESH,
            id:     IDS.accreditationSettingsSectionDetails,
            data:   {
                id:                 this.standardId,
                number:             numberInputSelect[0].value,
                numberInputSelect:  formattedNumberInputSelect,
                schemeCode:         this.schemeCode,
            }
        });
    }

    onSubmit() {
        this.$scope.$broadcast('show-errors-check-validity', '$ctrl.detailsForm');
        if (this.detailsForm.$valid) {
            if (this.isAddingNewNode) {
                this.addSection({
                    ...this.sectionDetails,
                    numberInputSelect: undefined,
                    scheme_id: this.schemeId,
                    parent_id: this.standardId,
                }).then(() => {
                    this.fetchStandardsTree(this.schemeCode);
                    this.$ngRedux.dispatch({
                        type: ACC_TYPES.SIMPLE_STD_TREE_UNSELECT_ALL,
                    });
                });
            } else {
                this.updateSectionDetails({
                    ...this.sectionDetails,
                    numberInputSelect: undefined,
                }).then(() => {
                    this.title = `${this.standardPrefix} ${this.standardName}`;
                    this.fetchStandardsTree(this.schemeCode);
                })
            }
        }
        else this.Notifier.error('COMMON.FIELDS_REQUIRED');
    }

    onDeleteBtn() {
        const confirm = this.$mdDialog.confirm()
            .title(this.$filter('translate')('COMMON.CONFIRM_DIALOG.ARE_YOU_SURE'))
            .textContent(this.$filter('translate')('ACCREDITATION.SETTINGS.SECTIONS.CONFIRM_DIALOG_CONTENT'))
            .ariaLabel(this.$filter('translate')('ACCREDITATION.SETTINGS.SECTIONS.CONFIRM_DIALOG_LABEL'))
            .ok(this.$filter('translate')('COMMON.CONFIRM_DIALOG.YES'))
            .cancel(this.$filter('translate')('COMMON.CONFIRM_DIALOG.NO'));

        this.dialog = this.$mdDialog.show(confirm).then(() => {
            this.deleteSection(this.standardId).then( () => {
                this.fetchStandardsTree(this.schemeCode).then(() => {
                    this.$ngRedux.dispatch({ // clear sectionDetails to hide details
                        type: ACC_TYPES.SIMPLE_STD_TREE_UNSELECT_ALL,
                    });
                    this.$ngRedux.dispatch({
                        type: TAB_DISABLE,
                        id: IDS.app_accreditation_scheme_settings_default_documents,
                        state: [IDS.app_accreditation_scheme_settings_default_documents_list],
                    })
                })
            })
        })
    }

    $onDestroy() {
        this.unsubscribe();
        if (this.dialog)
            this.$mdDialog.hide(this.dialog);
    }
}

SectionDetailsController.$inject = ['$ngRedux', '$state', '$scope', '$mdDialog', '$filter', 'NavigationService', 'Notifier']

export const sectionDetails = {
    bindings: {
        title: '<',
        tab: '<',
    },
    controller: SectionDetailsController,
    template: require('./section-details.html')
}
