export const LocationFactory = () => {
    return function Location() {
        this.accountId = null;
        this.email = null;
        this.phone = null;
        this.parentUsername = null;
        this.parentUuid = null;
        this.type = null;
        this.name = null;
        this.id = null;
        this.uuid = null;
        this.typeId = null;
        this.typeName = null;
        this.created = null;
        this.rso = null;
        this.basicType = null;
        this.warning = null;
        this.critical = null;
        this.building = null;
        this.level = null;
        this.number = null;
        this.accountNumber = null;
        this.costCentreCode = null;
        this.roomType = null;
    };
};
