import { AP_INVITATION_RESET } from '../applications-permits.actions.types';

export const apLaserRequestState = {
    name: 'app.applicationsPermits.laser.request',
    redirectTo: 'app.applicationsPermits.laser.request.invitation',
    url: '/request',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({ type: AP_INVITATION_RESET });
    }
};

export const apLaserRequestInvitationState = {
    name: 'app.applicationsPermits.laser.request.invitation',
    url: '',
    component: 'apLaserRequestInvitation'
};

export const apLaserRequestEquipmentState = {
    name: 'app.applicationsPermits.laser.request.equipment',
    url: '',
    component: 'apLaserRequestEquipment'
};

export const apLaserManageState = {
    name: 'app.applicationsPermits.laser.manage',
    redirectTo: 'app.applicationsPermits.laser.manage.listing',
    url: '/manage',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        // $ngRedux.dispatch({ type: AP_INVITATION_RESET });
    }
};

export const apLaserManageListingState = {
    name: 'app.applicationsPermits.laser.manage.listing',
    url: '',
    component: 'apLaserRequestGrid',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.applicationsPermits.laser.manage',
            state: [
                'app.applicationsPermits.laser.manage.details',
                'app.applicationsPermits.laser.manage.equipment',
                'app.applicationsPermits.laser.manage.trainings',
                'app.applicationsPermits.laser.manage.safetyPlan'
            ]
        });
    }
};

export const apLaserManageRequestDetailsState = {
    name: 'app.applicationsPermits.laser.manage.details',
    url: '/details?id',
    component: 'apLaserRequestDetails'
};

export const apLaserManageRequestEquipmentState = {
    name: 'app.applicationsPermits.laser.manage.equipment',
    url: '/equipment?id',
    component: 'apLaserRequestEquipment'
};

export const apLaserManageRequestTrainingsState = {
    name: 'app.applicationsPermits.laser.manage.trainings',
    url: '/trainings?id',
    component: 'apLaserRequestTrainings'
};

export const apLaserManageRequestSafetyPlanState = {
    name: 'app.applicationsPermits.laser.manage.safetyPlan',
    url: '/safety-plan?id',
    component: 'apLaserRequestSafetyPlan'
};

export const apLaserAlertsState = {
    name: 'app.applicationsPermits.laser.alerts',
    redirectTo: 'app.applicationsPermits.laser.alerts.listing',
    url: '/manage',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        // $ngRedux.dispatch({ type: AP_INVITATION_RESET });
    }
};

export const apLaserAlertsListingState = {
    name: 'app.applicationsPermits.laser.alerts.listing',
    url: '',
    component: 'apLaserRequest'
};
