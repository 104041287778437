/* @ngInject */
export const VendorFactory = (VendorHeadOffice, Country) => {
    return function Vendor() {
        this.name = null;
        this.email = null;
        this.headOffice = new VendorHeadOffice();

        // TODO remove this
        this.id = null;
        this.uuid = null;
        this.name = null;
        this.password = null;
        this.password_verify = null;
        this.country = new Country();
        this.services = [];
    };
};
