/* @ngInject */
export function SafetyPlanReviewFactory(SafetyPlanPresentation) {
    return function SafetyPlanReview() {

        this.id = null;
        this.userUuid = null;
        this.presentation =  new SafetyPlanPresentation();
        this.startDate = null;
        this.endDate = null;
        this.dueDate = null;
        this.nextSection = null;
    };
}
