import watch from 'redux-watch';
import { TAB_SHOW, TAB_SELECT } from '../../common/tabs/tabs.constants';
import * as EventActions from './events.actions'
import * as FileActions from '../../common/file/file.actions'
import isObject from 'lodash/isObject';

/**
 * @const {object}
 * @memberOf module:Events
 */
export const eventsDetails = {
    /* @ngInject */
    controller: function($ngRedux, $state, $rootScope, $scope, $timeout, NavigationService, $mdDialog, Notifier) {

        this.unsubscribe = $ngRedux.connect((state) => ({
            eventItem: state.events.eventItem,
            user: state.app.user
        }), {
            ...EventActions,
            ...FileActions
        })(this);

        /**
         * @function $onInit
         */
        this.$onInit = () => {
            const navConfig = NavigationService.getConfig($state.$current);
            this.colorName = navConfig.colorName;
            this.icon = navConfig.icon;

            const watchLocation = watch($ngRedux.getState, 'events.eventItem.locationCode');
            this.onLocation = $ngRedux.subscribe(watchLocation((code) => {
                this.addLocation = code === 'dep';
            }));

            if (this.eventItem.id === null) {
                this.title = 'Event General Info - New Event';
                this.identifier = $state.$current.parent.name;
                this.state = `${$state.$current.parent.name}.details`;
                this.getResponsiblePersonDetails(this.user.uuid);
                this.eventSelection();
            } else {
                this.title = 'Event General Info - ' + this.eventItem.event + ' - ' + this.eventItem.visualId;
                this.identifier = $state.$current.parent.name;
                this.state = `${$state.$current.parent.name}.details`;
                this.type = this.eventItem.risk_code;
            }

            this.typeTitle = 'Event Type';
            this.categoryTitle = 'Event Category';
            this.gridId = 'eventsGrid';

            this.getEventTypes('eventItem', this.type);
            this.getEventSubtypes('eventItem', this.type);
            this.getEventLocations('eventItem');
            this.getInvolvedPersonTypes('eventItem');
            this.getInvolvedPersonAgeGroups('eventItem');

            if (this.eventItem.event_category) {
                this.getEventCategories(
                    'eventItem',
                    this.type,
                    this.eventItem.event_type,
                    this.eventItem.event_subtype
                );
            }

            if (this.eventItem.event_subcategory) {
                this.getEventSubcategories(
                    'eventItem',
                    this.type,
                    this.eventItem.event_type,
                    this.eventItem.event_subtype,
                    this.eventItem.event_category
                );
            }
        };

        this.eventSelection = () => {
            const eventSelectionCtrl = function ($scope, ctrl) {
                let eventType = null;
                $scope.save = () => {
                    if ($scope.eventSelectionForm.$valid) {
                        $mdDialog.cancel();
                    }
                };
                $scope.onEventTypeChange = ({ input }) => {
                    eventType = input;
                    $ngRedux.dispatch({
                        type: 'EVENT_SUBTYPE_RESET',
                        id: 'eventItem',
                    });
                    ctrl.getEventSubtypes('eventItem', ctrl.type, eventType);
                };
                $scope.onEventSubtypeChange = ({ input }) => {
                    ctrl.getEventCategories('eventItem', ctrl.type, eventType, input);
                };
                $scope.cancel = () => {
                    $mdDialog.cancel();
                    $state.go(`${$state.$current.parent.parent.name}.dashboard`);
                };
            };

            this.dialog = $mdDialog.show({
                controller: eventSelectionCtrl,
                locals: { ctrl: this },
                template: require('./dialogs/events-selection-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: false
            }).then(angular.noop, angular.noop);
        };

        this.onEventTypeChange = ({ input }) => {
            $ngRedux.dispatch({
                type: 'EVENT_SUBTYPE_RESET',
                id: 'eventItem',
            });
            this.getEventSubtypes('eventItem', this.type, input);
        };

        this.onEventSubtypeChange = ({ input }) => {
            $ngRedux.dispatch({
                type: 'EVENT_CATEGORY_RESET',
                id: 'eventItem',
            });
            this.getEventCategories('eventItem', this.type, this.eventItem.event_type, input);
        };

        this.onEventCategoryChange = (event) => {
            $ngRedux.dispatch({
                type: 'EVENT_SUBCATEGORY_RESET',
                id: 'eventItem',
            });
            this.getEventSubcategories('eventItem', this.type, this.eventItem.event_type, this.eventItem.event_subtype, event.input);
        };

        this.createCategory = () => {
            const categoryCtrl = function ($scope, ctrl) {
                $scope.save = () => {
                    if ($scope.categoryForm.$valid) {
                        const { event_type, event_subtype } = ctrl.eventItem;
                        ctrl.addEventCategory({
                            risk_code: ctrl.type,
                            event_type,
                            event_subtype,
                            sub_category_name: $scope.sub_category_name
                        }).then(() => {
                            ctrl.getEventCategories('eventItem', ctrl.type, event_type, event_subtype);
                        });
                        $mdDialog.hide();
                        Notifier.success('New Category has been successfully added');
                    }
                };
                $scope.cancel = () => {
                    $mdDialog.cancel();
                };
            };

            this.dialog = $mdDialog.show({
                controller: categoryCtrl,
                locals: { ctrl: this },
                template: require('./dialogs/events-category-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true
            }).then(angular.noop, angular.noop);
        };

        this.createSubcategory = () => {
            const subcategoryCtrl = function ($scope, ctrl) {
                $scope.save = () => {
                    if ($scope.subcategoryForm.$valid) {
                        const { event_type, event_subtype, event_category } = ctrl.eventItem;
                        ctrl.addEventSubcategory({
                            risk_code: ctrl.type,
                            event_type,
                            event_subtype,
                            category_id: event_category,
                            sub_category_name: $scope.sub_category_name
                        }).then(() => {
                            ctrl.getEventSubcategories('eventItem', ctrl.type, event_type, event_subtype, event_category);
                        });
                        $mdDialog.hide();
                        Notifier.success('New Subcategory has been successfully added');
                    }
                };
                $scope.cancel = () => {
                    $mdDialog.cancel();
                };
            };

            this.dialog = $mdDialog.show({
                controller: subcategoryCtrl,
                locals: { ctrl: this },
                template: require('./dialogs/events-subcategory-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true
            }).then(angular.noop, angular.noop);
        }

        this.manageInvolvedPerson = (idx) => {
            const personCtrl = function ($scope) {
                $scope.disabled = false;
                $scope.identifier = 'eventItem';
                $scope.save = () => {
                    if ($scope.involvedPersonForm.$valid) {
                        if (typeof idx !== 'undefined') {
                            $ngRedux.dispatch({
                                id: 'eventItem',
                                type: 'EVENT_PERSON_INVOLVED_MODIFY',
                                idx
                            });
                            Notifier.success('Involved person has been successfully modified');
                        } else {
                            $ngRedux.dispatch({
                                id: 'eventItem',
                                type: 'EVENT_PERSON_INVOLVED_ADD'
                            });
                            Notifier.success('New involved person has been successfully added');
                        }
                        $mdDialog.cancel();
                    } else {
                        Notifier.error('Please fill in the form fields');
                    }
                };
                $scope.cancel = () => {
                    $mdDialog.cancel();
                };
            };

            this.dialog = $mdDialog.show({
                controller: personCtrl,
                template: require('./dialogs/events-involved-person-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                onShowing: () => {
                    if (typeof idx !== 'undefined') {
                        $ngRedux.dispatch({
                            id: 'eventItem',
                            type: 'EVENT_PERSON_INVOLVED_DETAILS',
                            idx,
                        });
                    }
                }
            }).then(angular.noop, () => {
                $ngRedux.dispatch({
                    id: 'eventItem',
                    type: 'EVENT_PERSON_INVOLVED_RESET'
                });
            });
        };

        this.manageEvent = () => {
            $scope.$broadcast('show-errors-check-validity', '$ctrl.eventForm');
            if (this.eventForm.$valid) {
                if (this.eventItem.id) {
                    this.updateEvent(this.eventItem.id, this.type, this.gridId).then(() => {
                        Notifier.success('Event changes has been successfully saved');
                    });
                } else {
                    const eventType = {
                        srp: { evnhzd: 'Hazard', evninc: 'Incident' },
                        arr: { evnrme: 'Hazard', evnxry: 'Hazard', evnlas: 'Hazard' },
                        orb: { evnhzd: 'Threat', evninc: 'Issue' },
                    }[this.type][this.eventItem.event_code];

                    this.addEvent(this.type).then( data => {
                        const params = isObject(data.params) ? data.params : JSON.parse(data.params);
                        Notifier.success('New Event has been successfully added');
                        $ngRedux.dispatch({
                            type: TAB_SHOW,
                            id: $state.$current.parent.name,
                            state: [
                                `${$state.$current.parent.name}.assessment${eventType}`,
                                `${$state.$current.parent.name}.alertEscalate`,
                                `${$state.$current.parent.name}.actionPlan`
                            ]
                        });
                        $timeout(() => {
                            $ngRedux.dispatch({
                                type: TAB_SELECT,
                                id: $state.$current.parent.name,
                                state: `${$state.$current.parent.name}.assessment${eventType}`,
                                stateParams: { id: params.iev_id }
                            });
                        }, 200);
                    });
                }
            }
            else Notifier.error('Please fill in the form fields');
        };

        this.onGetResponsiblePersons = async (event) => {
            const location = this.eventItem && this.eventItem.locationUuid;
            return await this.getResponsiblePersons(event, location);
        };

        this.removeInvolvedPerson = (idx) => {
            $ngRedux.dispatch({
                id: 'eventItem',
                type: 'EVENT_PERSON_INVOLVED_REMOVE',
                idx,
            });
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            this.onLocation();
            this.dialog && $mdDialog.hide(this.dialog);
        };
    },
    template: require('./events-details.html'),
    bindings: {
        type: '@'
    }
};
