/* @ngInject */
export function EquipmentDocumentFactory(FileDocument, Helpers, DATE_FORMAT) {
    return function EquipmentDocument() {

        FileDocument.call(this);

        this.type = null;
        this.dueDate = null;

        this.getDueDate = function() {
            return Helpers.formatDate(this.dueDate, DATE_FORMAT.INPUT);
        };
    };
}
