import {TYPES} from './standard-action-type.constants';
import {IDS} from './standard-action-id.constants';
import * as TABS from '../../../common/tabs/tabs.constants';
import {TabsReducer} from '../../../common/tabs/tabs.reducer';
import * as FORM from '../../../common/form/input/input.constants';
import {GridReducer} from '../../../common/grid/grid.reducer';
import {GridFilterReducer} from '../../../common/grid/filter/grid-filter.reducer';
import * as GRID from '../../../common/grid/grid.constants';
import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';
import pick from 'lodash/pick';

/**
 * @function StandardReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function StandardReducer(state, action) {

    if (typeof state === 'undefined') {
        const currentYear = new Date().getFullYear();
        return {
            qualityManagers: [],
            evidenceRequestStatusTypes: [
                {label:'Pending', value:'pen'},
                {label:'Completed', value:'cpl'}
            ],
            assignee: {
                autocompleteCandidates: [],
                autocompleteSelectedItem: null,
                firstName: null,
                lastName: null,
                email: null,
            },
            documentDetails: {},
            // documentEvaluation: {
            //     assessmentItem: null,
            //     document:       null,
            //     review:         null,
            //     reviewStatuses: [],
            // },

            [IDS.standardDocumentsProgressGrid]: GridReducer(),
            [IDS.standardDocumentReviewsGrid]: GridReducer(),

            documentAdd: {
                gridFilterRadioButtons: [
                    {value: 'ado',  label: 'Accreditation Documents'},
                    {value: '',     label: 'All Documents'}
                ],
            },
        }
    }

    switch (action.type) {
        // case TYPES.FETCH_EVIDENCE_PROVIDER_CANDIDATES:
        //     if (action.id === IDS.accStandard) {
        //         return set({...state}, 'scheduleItem.autocompleteEvidenceProviders', action.data)
        //     }
        // case TYPES.FETCH_EVIDENCE_ASSIGNEE_CANDIDATES:
        //     if (action.id === IDS.accStandard) {
        //         return set({...state}, 'assignee.autocompleteCandidates', action.data)
        //     }
        // case TYPES.RESET_EVIDENCE_ASSIGNEE:
        //     if (action.id === IDS.accStandard) {
        //         state = set({...state}, 'assignee.autocompleteSelectedItem',    null);
        //                 set(state,      'assignee.firstName',                   null);
        //                 set(state,      'assignee.lastName',                    null);
        //         return  set(state,      'assignee.email',                       null);
        //     }
        //
        // case TYPES.FETCH_EVIDENCE_ASSESSMENT:
        //     if(action.id === IDS.accStandard) {
        //         return set({...state}, 'assessmentItems',
        //             Array.isArray(action.items) === false ? [] : itemsCount.map(item => {
        //                     const emptyGrid = GridReducer()
        //                     item.documents = {
        //                         ...emptyGrid,
        //                         items: item.documents,
        //                         itemsCount: item.documents.length
        //                     }
        //                 return item;
        //             })
        //         )
        //     }

        case TYPES.FETCH_DOCUMENT_DETAILS:
            return set({...state}, 'documentDetails', action.item)

        case TYPES.FETCH_SITE_LOCATION_DETAILS:
            if (action.id === IDS.accStandard) {
                state = set({...state}, 'selectedSiteLocation.uuid',  action.locationUuid);
                set(    state,  'selectedSiteLocation.locationName',  action.locationName);
                set(    state,  'autocompleteQualityManagers',        action.autocompleteQualityManagers);
                return  set(    state,  'fiscalYearStart',            action.fiscalYearStart);
            }

        case TYPES.FETCH_STANDARD_AUDIT_TASKS:
            return set({...state}, 'qualityManagers', action.tasks)

        /* GENERIC TYPES */

        case 'LOCATION_SELECTED':
            if(action.id === IDS.accStandard) { // slo select-cascade
                return set({...state}, 'selectedSiteLocation', action)
            }
        case 'AUTOCOMPLETE_SELECTED':
            if (action.id === IDS.accStandard && action.item) {
                return set({...state}, action.path, action.item)
            }
        case FORM.INPUT_SELECT:
        case FORM.INPUT_TEXT:
        case FORM.INPUT_CHECKBOX:
        case FORM.INPUT_DATE:
        case FORM.INPUT_RADIO:
        case 'INPUT_EMAIL_CHANGE':
        case 'INPUT_PHONE_CHANGE':
        case 'INPUT_TEXT_AREA_CHANGE':
        case 'INPUT_SIGNATURE':
            if (action.id === IDS.accStandard) { //selectedYear
                if(action.path.startsWith(IDS.documentAddGrid)) {
                    const filter = {
                        [action.path.split('.').pop()]: action.input
                    };
                    action.path = IDS.documentAddGrid;
                    return set({...state}, action.path,
                        GridReducer(get(state, action.path), { type: GRID.GRID_FILTER, filter })
                    )
                }
                else
                    return set({...state}, action.path, action.input)
            }
        case GRID.GRID_INIT:
        case GRID.GRID_FILTER:
        case GRID.GRID_FILTER_TOGGLE:
        case GRID.GRID_RESET_FILTER:
        case GRID.GRID_INIT_FILTER:
        case GRID.GRID_LOADING:
        case GRID.GRID_PAGINATE:
        case GRID.GRID_RELOAD:
        case GRID.GRID_FETCH:
        case GRID.GRID_SELECT:
        case GRID.GRID_SELECT_ALL:
        case GRID.GRID_DESELECT:
        case GRID.GRID_DESELECT_ALL:
            if (action.id === IDS.accStandard) {
                return set({...state}, action.path,
                    GridReducer( get(state, action.path), action)
                )
            }
        case GRID.GRID_RESET:
            if (action.id === IDS.accStandard) {
                return set({...state}, action.path,
                    GridReducer( get(state, action.path),{
                        type: GRID.GRID_RESET_FILTER,
                        exclude: action.exclude || (action.path === IDS.documentAddGrid ? ['slo_uuid','doc_type_code','assignedDocIds'] : []),
                    })
                )
            }
        default:
            return state;
    }
}

const index = (children, list = []) => {
    children && children.forEach(item => {
        if(item) {
            list[parseInt(item.id)] = item;
            index(item.children, list);
        }
    })
    return list;
};
