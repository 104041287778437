import * as RmTestActions from '../rm-test.actions';
import { TAB_SELECT } from '../../../../common/tabs/tabs.constants';
import { formatDate } from '../../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../../common/utils/util.constants';
import get from 'lodash/get';

/**
 * @const {object}
 * @memberOf module:RadioactiveMaterials
 */
export const rmTestDisposal = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $scope, $rootScope, $mdDialog, FileActions, Notifier) {
        const unsubscribe = $ngRedux.connect(({ radioactiveMaterials }) => ({
            rmTest: radioactiveMaterials.rmTest
        }), {
            ...FileActions,
            ...RmTestActions
        })(this);

        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.identifier = 'app.radioactiveMaterials.test.manage';
            this.state = 'app.radioactiveMaterials.test.manage.disposal';
            this.disabled = this.rmTest.status === 'dis';

            this.getTestDisposalCompanies();
        }

        this.saveCompany = () => {
            if (validateCompany()) {
                this.saveDisposalCompany({
                    disposalCompany: get(this.rmTest.disposalCompany, 'id'),
                    disposalCompanyName: this.rmTest.disposalCompanyName,
                    disposalCompanyAddress: this.rmTest.disposalCompanyAddress,
                    disposalCompanyEmail: this.rmTest.disposalCompanyEmail,
                    disposalCompanyPhone: this.rmTest.disposalCompanyPhone,
                }).then(() => {
                    Notifier.success('Disposal Company changes has been successfully saved');
                    this.getTestDisposalCompanies();
                }).catch(() => {
                    Notifier.error('System Error!');
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
        };

        const validateCompany = () => {
            let valid = true;
            const { disposalCompanyName, disposalCompanyAddress, disposalCompanyEmail } = this.rmTest;
            if (!disposalCompanyName || disposalCompanyName.trim() === '') {
                valid = false;
            }
            if (!disposalCompanyAddress || disposalCompanyAddress.trim() === '') {
                valid = false;
            }
            if (!disposalCompanyEmail || disposalCompanyEmail.trim() === '') {
                valid = false;
            }
            return valid;
        };

        this.dispose = () => {
            if (this.rmTestDisposeForm.$valid) {
                const confirm = $mdDialog.confirm()
                    .title('Are you sure?')
                    .textContent('Chosen test will be disposed permanently.')
                    .ariaLabel('Dispose test')
                    .ok('Yes')
                    .cancel('No');
                this.dialog = $mdDialog.show(confirm).then(() => {
                    this.disposeTest({
                        id: this.rmTest.id,
                        disposalDate: formatDate(this.rmTest.disposalDate, DATE_FORMAT.OUTPUT),
                        disposalOffSite: this.rmTest.disposalOffSite,
                        disposalCompany: get(this.rmTest.disposalCompany, 'id'),
                        disposalCompanyName: this.rmTest.disposalCompanyName,
                        disposalCompanyAddress: this.rmTest.disposalCompanyAddress,
                        disposalCompanyEmail: this.rmTest.disposalCompanyEmail,
                        disposalCompanyPhone: this.rmTest.disposalCompanyPhone,
                        disposalComments: this.rmTest.disposalComments,
                        disposalDocument: this.rmTest.disposalDocument
                    }).then(() => {
                        Notifier.success('Source/Leak test has been successfully disposed');
                        $ngRedux.dispatch({
                            type: TAB_SELECT,
                            id: 'app.radioactiveMaterials.test.manage',
                            state: 'app.radioactiveMaterials.test.manage.listing'
                        });
                    }).catch(() => {
                        Notifier.error('System Error!');
                    });
                }, angular.noop);
            } else {
                Notifier.error('Please fill in the form fields');
            }
        }

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./rm-test-disposal.html')
};
