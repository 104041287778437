import { generateQRCode } from '../../../../../../common/utils/util.qrcode';

/* @ngInject */
export function AdministrationAccountsRoomDetailsController($ngRedux, $state, $uiRouterGlobals, $transitions, $timeout, AdministrationActions, AccountService, Notifier) {
    const ctrl = this;
    let unsubscribe = angular.noop;
    let onEnter = angular.noop;
    let onBeforeExit = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);

        if (_.get($uiRouterGlobals.params, 'hof', null)) {
            ctrl.getHeadOfficeDetails($uiRouterGlobals.params.hof);
        }

        if (_.get($uiRouterGlobals.params, 'slo', null)) {
            ctrl.getSiteLocationDetails($uiRouterGlobals.params.slo);
        }

        if (_.has($uiRouterGlobals.params, 'dep')) {
            ctrl.getDepartmentDetails($uiRouterGlobals.params.dep);
        }

        if (_.isUndefined($uiRouterGlobals.params.room)) {
            if ($state.current.name === 'app.administration.accounts.rooms.details') {
                $ngRedux.dispatch({
                    type: 'TAB_ENABLE',
                    id: 'app.administration.accounts.rooms',
                    state: ['app.administration.accounts.rooms.details']
                });

                $timeout(function() {
                    $ngRedux.dispatch({
                        type: 'TAB_SELECT',
                        id: 'app.administration.accounts.rooms',
                        state: 'app.administration.accounts.rooms.details'
                    });
                }, 200);
            }
        }
        else ctrl.getRoomDetails($uiRouterGlobals.params.room, true);

        ctrl.getRoomTypes();

        onBeforeExit = $transitions.onBefore({exiting: 'app.administration.accounts.rooms.details'}, function() {
            ctrl.roomForm.$setPristine();
            ctrl.roomForm.$setUntouched();
        });
    };

    ctrl.initQRCode = () => {
        if (ctrl.room.uuid) {
            generateQRCode('ROM-QRCode', {
                uuid: ctrl.room.uuid,
                name: ctrl.room.name,
                action: 'location',
            });
        }
    }

    ctrl.printQRCode = () => {
        AccountService.printQRcode(
            ctrl.room.uuid,
            ctrl.room.name,
            'location',
        );
    };

    ctrl.manageRoom = function() {
        if (ctrl.roomForm.$valid) {
            if (ctrl.room.uuid) {
                ctrl.updateRoomDetails(ctrl.room);
            }
            else {
                ctrl.room.parentUuid = $uiRouterGlobals.params.dep;
                ctrl.createRoomDetails(ctrl.room).then(function() {
                    $state.go($state.current.name, _.assign({}, $uiRouterGlobals.params, {room: ctrl.room.uuid}), {notify:false, reload:false});
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            }
        }
        else Notifier.error('Please fill in the form fields');
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onEnter();
        onBeforeExit();
    };

    function mapStateToThis(state) {
        return {
            room: state.administration.administrationAccountsRoom.data,
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                state.administration.administrationAccountsDepartment.data.typeName,
                state.administration.administrationAccountsRoom.title
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
