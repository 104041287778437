import * as TYPE from '../../cre-vendors.actions.types';
import {
    getVendorServiceLicenceTypes,
    getVendorServiceDetails,
    saveVendorService,
    deleteVendorService,
} from '../../cre-vendors.actions';
import findIndex from 'lodash/findIndex';
import isNull from 'lodash/isNull';

/**
 * @const {object}
 * @memberOf module:Credentialing
 */
export const creVendorsServices = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $location, $mdDialog, DocumentManager, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.title = 'Services Information';
            this.unsubscribe = $ngRedux.connect(({ credentialing }) => ({
                creVendor: credentialing.creVendor,
            }), {
                getVendorServiceLicenceTypes,
                getVendorServiceDetails,
                saveVendorService,
                deleteVendorService,
            })(this);
            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.services`;
            this.token = $location.search().token;

            this.getVendorServiceLicenceTypes({
                token: this.token,
                lty_code: 'lgalty',
            });
            this.getVendorServiceLicenceTypes({
                token: this.token,
                lty_code: 'eoolty',
            });
        };

        this.onDocDownload = ({ doc_url }) => {
            DocumentManager.downloadFile(doc_url, 4);
        };

        this.onDocRemove = (doc) => {
            $ngRedux.dispatch({
                type: TYPE.CRE_VENDOR_DOC_REMOVE,
                id: 'creVendor',
                path: 'services_docs',
                idx: findIndex(this.creVendor.service_docs, doc),
            });
        };

        this.onSelect = ({ item }) => {
            this.getVendorServiceDetails({
                token: this.token,
                cas_id: item.cas_id,
            });
        };

        this.onReset = () => {
            $ngRedux.dispatch({
                type: TYPE.CRE_VENDOR_SERVICE_RESET,
                id: 'creVendor',
            });
            this.creVendorsServicesForm.$setPristine();
            this.creVendorsServicesForm.$setUntouched();
        };

        this.onRemove = ({ item }) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen service will be permanently removed.')
                .ariaLabel('Remove service')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.deleteVendorService({
                    token: this.token,
                    cas_id: item.cas_id,
                });
            }, angular.noop);
        };

        this.onSave = () => {
            if (this.creVendorsServicesForm.$valid) {
                this.saveVendorService({
                    token: this.token,
                    application_id: this.creVendor.application_id,
                    cas_id: this.creVendor.cas_id,
                    cas_healthcare: this.creVendor.cas_healthcare,
                    cas_non_healthcare: this.creVendor.cas_non_healthcare,
                    cas_description: this.creVendor.cas_description,
                    cas_do_emp_req_lic_by_lra: this.creVendor.cas_do_emp_req_lic_by_lra,
                    cas_emp_lra_lic_type_id: this.creVendor.cas_emp_lra_lic_type_id,
                    cas_emp_lic_iss_by_lra_name: this.creVendor.cas_emp_lic_iss_by_lra_name,
                    cas_do_emp_req_eoo_lic: this.creVendor.cas_do_emp_req_eoo_lic,
                    cas_emp_eoo_lic_type_id: this.creVendor.cas_emp_eoo_lic_type_id,
                    fixed_rate: this.creVendor.fixed_rate,
                    hourly_rate: this.creVendor.hourly_rate,
                    pos_ex_tax: parseFloat(this.creVendor.pos_ex_tax),
                    pos_in_tax: parseFloat(this.creVendor.pos_in_tax),
                    cas_price_is_valid_for: this.creVendor.cas_price_is_valid_for,
                    hos_from_am: parseFloat(this.creVendor.hos_from_am),
                    hos_from_pm: parseFloat(this.creVendor.hos_from_pm),
                    cos_poh: parseFloat(this.creVendor.cos_poh),
                    pos_poh_ex_tax: parseFloat(this.creVendor.pos_poh_ex_tax),
                    pos_poh_in_tax: parseFloat(this.creVendor.pos_poh_in_tax),
                    service_docs: this.creVendor.service_docs,
                }).then(() => {
                    isNull(this.creVendor.cas_id) && this.onReset();
                    Notifier.success('Service Information has been successfully saved');
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
        }

        this.$onDestroy = () => {
            this.onReset();
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./cre-vendors-services.html')
};
