import { TAB_ENABLE, TAB_DISABLE } from '../../../../common/tabs/tabs.constants';
import { GRID_RELOAD } from '../../../../common/grid/grid.constants';
import { TYPES } from '../settings-action-type.constants';
import { TYPES as ACC_TYPES } from '../../accreditation-action-type.constants';
import { IDS } from '../settings-action-id.constants';
import * as AccreditationActions from '../../accreditation.actions'
import * as SettingsActions from '../settings.actions'

import * as Actions from './sections.actions';

class SectionsController {
    constructor($ngRedux, $state, $filter, $scope, $uiRouterGlobals, NavigationService) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$filter = $filter;
        this.$scope = $scope;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.NavigationService = NavigationService;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect( state => {
            const schemeDetails = state.accreditation.accSettings.schemeDetails
            const standardDetails = state.accreditation.accSettings.sectionDetails
            return {
                schemeDetails,
                treeData:               state.accreditation.simpleSectionsTree,
                showNodeDetails:        standardDetails?.id || standardDetails?.number,
                digits: {
                    level1_num_digits:  schemeDetails.level1_num_digits,
                    level2_num_digits:  schemeDetails.level2_num_digits,
                    level3_num_digits:  schemeDetails.level3_num_digits,
                }
            };
        }, {...AccreditationActions, ...SettingsActions, ...Actions})(this);
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.iconSvg;

        this.treeViewOptions = {
            showIcon: true,
            expandOnClick: false
        };
        this.$scope.$on('selection-changed', this.onTreeSelectionChange.bind(this));
        this.$ngRedux.dispatch({
            type: ACC_TYPES.SIMPLE_STD_TREE_UNSELECT_ALL,
        });
        this.schemeCode = this.$uiRouterGlobals.params.itemCode;
        this.getSchemeDetails(this.schemeCode);
        this.fetchStandardsTree(this.schemeCode);
    }

    onTreeSelectionChange(event, node) {
        node.schemeCode = this.schemeCode;
        this.getSectionDetails(node).then(() => {

            if(node.level === 3) { // is activity
                this.$ngRedux.dispatch({
                    type: GRID_RELOAD,
                    id:   IDS.accSettings,
                    path: IDS.assessmentGrid,
                })
            }
        });
    };

    onAddCategoryBtn(event) {
        this.detailsTitle = this.$filter('translate')('ACCREDITATION.SETTINGS.SECTIONS.ADD_NEW_CATEGORY');
        this.$ngRedux.dispatch({
            type: ACC_TYPES.SIMPLE_STD_TREE_UNSELECT_ALL,
        });
        const siblings = this.treeData.map(item => parseInt(item.number));
        const numberInputSelect = [];
        for(let i = 1; i < 100; i++) {
            if(siblings.includes(i) === false) {
                numberInputSelect.push({
                    label: String(i),
                    value: i,
                });
            }
        }
        const formattedNumberInputSelect = numberInputSelect.map(item => {
            const standardsArr = item.label.split('.')
            const digitsArr = Object.values(this.digits)
            const formattedNumber = standardsArr.map((number, index) => {
                return number.padStart(digitsArr[index], '0')
            })
            return {
                value: item.value,
                label: formattedNumber.join('.')
            }
        })
        this.$ngRedux.dispatch({
            type:   TYPES.SECTION_DETAILS_REFRESH,
            id:     IDS.accreditationSettingsSectionDetails,
            data:   {
                number: numberInputSelect[0].value,
                numberInputSelect: formattedNumberInputSelect,
            },
        });

        this.$ngRedux.dispatch({
            type: TAB_DISABLE,
            id: IDS.app_accreditation_scheme_settings_default_documents,
            state: [
                IDS.app_accreditation_scheme_settings_default_documents_list,
            ],
        });
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

SectionsController.$inject = ['$ngRedux', '$state', '$filter', '$scope', '$uiRouterGlobals', 'NavigationService']

export const sections = {
    controller: SectionsController,
    template: `
        <tabs-header title="{{$ctrl.schemeDetails.title}}"></tabs-header>
        <div layout-gt-xs="row">
            <div layout="column" flex="40">
                <tree nodes='$ctrl.treeData' options='$ctrl.treeViewOptions' flex></tree>
                <div layout="row" layout-align="start center">
                    <md-button class="btnDefault" ng-click="$ctrl.onAddCategoryBtn()">
                        {{'ACCREDITATION.SETTINGS.SECTIONS.BTN_CREATE_CATEGORY' | translate}}
                    </md-button>
                </div>
            </div>
            <section-details flex
                    ng-show="$ctrl.showNodeDetails"
                    title="$ctrl.detailsTitle">
            </section-details>
        </div>`
}
