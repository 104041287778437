import { RESPONSES } from './response-status.constants';
import forEach from 'lodash/forEach';

export class ResponseStatusService {
    constructor($exceptionHandler) {
        this.$exceptionHandler = $exceptionHandler;
    }

    find(status) {
        let responseStatus = null;
        forEach(RESPONSES, value => {
            if (parseInt(status) === value.status) {
                responseStatus = value;
                return false;
            }
        });
        return responseStatus;
    }

    isAuthorized(response) {
        const responseStatus = this.find(response.status);
        return responseStatus ? responseStatus.authorized : false;
    }

    rejectStatus(status) {
        const responseStatus = this.find(status);
        if (responseStatus)
            return responseStatus.reject;
        else {
            this.$exceptionHandler('Undefined ResponseStatus. Create status definition in ResponseStatusService.');
            return true;
        }
    }
}

ResponseStatusService.$inject = ['$exceptionHandler'];
