import * as TYPE from '../components/credentialing/cre-vendors/cre-vendors.actions.types';
import credentialingReducer from '../components/credentialing/credentialing.reducer';
import { tabs } from '../components/credentialing/credentialing.tabs';
import { TabsResolver } from '../common/tabs/tabs.resolver';
import { GridReducer } from '../common/grid/grid.reducer';
import set from 'lodash/set';

const initialState = {
    ...credentialingReducer,
};

export default (state = initialState, action) => {
    if (Object.keys(tabs).includes(action.id)) {
        return TabsResolver(state, action);
    }
    switch (action.type) {
        case 'INPUT_TEXT_CHANGE':
        case 'INPUT_TEXT_AREA_CHANGE':
        case 'INPUT_EMAIL_CHANGE':
        case 'INPUT_PHONE_CHANGE':
        case 'INPUT_RADIO_CHANGE':
        case 'INPUT_SELECT':
        case 'DATE_SELECTED':
		case 'CHECKBOX_SELECTED':
        case 'INPUT_SIGNATURE':
            if (['creInvite', 'creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.input,
                        ...(action.path === 'vendor_name' && { vendor_uuid: null }),
                    },
                };
            }
            if (['creVendorsGrid'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)
                };
            }
        case 'AUTOCOMPLETE_SELECTED':
            if (['creInvite', 'creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.item,
                    },
                };
            }
        case 'FILE_UPLOAD':
            if (['creVendor'].includes(action.id)) {
                if (['wcp_doc', 'plp_doc', 'pip_doc'].includes(action.path)) {
                    return {
                        ...state,
                        [action.id]: {
                            ...state[action.id],
                            insurance_docs: [...state[action.id].insurance_docs, {
                                doc_type: action.path.substring(0, 3).toUpperCase(),
                                doc_url: action.input,
                            }],
                        },
                    };
                } else if (['gcol_doc', 'gquo_doc'].includes(action.path)) {
                    return {
                        ...state,
                        [action.id]: {
                            ...state[action.id],
                            goods_docs: [...state[action.id].goods_docs, {
                                doc_type: action.path.substring(0, 4).toUpperCase(),
                                doc_url: action.input,
                            }],
                        },
                    };
                } else if (['acc_std_doc'].includes(action.path)) {
                    return {
                        ...state,
                        [action.id]: {
                            ...state[action.id],
                            acc_docs: [...state[action.id].acc_docs, {
                                doc_url: action.input,
                            }],
                        },
                    };
                } else if (['lgalty_doc', 'eoolty_doc'].includes(action.path)) {
                    return {
                        ...state,
                        [action.id]: {
                            ...state[action.id],
                            warranty_docs: [...state[action.id].warranty_docs, {
                                doc_type: action.path.substring(0, 6),
                                doc_url: action.input,
                            }],
                        },
                    };
                } else if ([
                    'lgalty_service_doc',
                    'eoolty_service_doc',
                    'serquo_service_doc',
                ].includes(action.path)) {
                    return {
                        ...state,
                        [action.id]: {
                            ...state[action.id],
                            service_docs: [...state[action.id].service_docs, {
                                doc_type: action.path.substring(0, 6),
                                doc_url: action.input,
                            }],
                        },
                    };
                }
            }
        case 'GRID_INIT':
        case 'GRID_RESET':
            if (['creVendorsGrid'].includes(action.id)) {
                const vendorsGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...vendorsGrid,
                        filter: {
                            ...vendorsGrid.filter,
                            keyword: null,
                            type: null,
                            status: null,
                        },
                    },
                };
            }
        case 'SHOW_LOADING':
        case 'GRID_FETCH':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_RESET_FILTER':
            if ([
                'creVendorsGrid',
                'creVendorsAccStdGrid',
                'creVendorsGoodsGrid',
                'creVendorsWarrantiesGrid',
                'creVendorsServicesGrid',
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: GridReducer(state[action.id], action)
                };
            }
        case TYPE.CRE_INVITE_INIT:
            if (['creInvite'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        type: null,
                        supplyHealthcare: 0,
                        supplyNonHealthcare: 0,
                        gst: null,
                        vendor_uuid: null,
                        vendor_name: null,
                        position: null,
                        title: null,
                        first_name: null,
                        last_name: null,
                        email: null,
                        contacts: [],
                        applicant_signature: null,
                        applicant_signature_date: null,
                        hof_name: null,
                        hof_contact_name: null,
                        hof_contact_phone: null,
                        hof_contact_email: null,
                        approval_signature: null,
                        status: null,
                    },
                };
            }
        case TYPE.CRE_VENDORS:
            if (['creInvite', 'creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        vendors: action.data,
                    },
                };
            }
        case TYPE.CRE_VENDOR_SELECT:
            if (['creInvite', 'creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        vendor_uuid: action.uuid,
                        vendor_name: action.name,
                    },
                };
            }
        case TYPE.CRE_VENDOR_RESET:
            if (['creInvite', 'creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        vendor: null,
                    },
                };
            }
    	case TYPE.CRE_CONTACT_POSITIONS:
            if (['creInvite', 'creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        positions: action.data,
                    },
                };
            }
        case TYPE.CRE_CONTACT_CREATE:
            if (['creInvite', 'creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        contacts: [...state[action.id].contacts, action.data],
                    },
                };
            }
        case TYPE.CRE_CONTACT_DETAILS:
            if (['creInvite', 'creVendor'].includes(action.id)) {
                const contact = state[action.id].contacts[action.idx];
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        contact_position: contact.sty_id,
                        contact_title: contact.title,
                        contact_first_name: contact.first_name,
                        contact_last_name: contact.last_name,
                        contact_email: contact.email,
                    },
                };
            }
        case TYPE.CRE_CONTACT_UPDATE:
            if (['creInvite', 'creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        contacts: state[action.id].contacts.map((item, idx) => {
                            return idx === action.idx ? action.data : item;
                        }),
                    },
                };
            }
        case TYPE.CRE_CONTACT_DELETE:
            if (['creInvite', 'creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        contacts: state[action.id].contacts.filter(({ email }) => email !== action.email ),
                    },
                };
            }
        case TYPE.CRE_CONTACT_RESET:
            if (['creInvite', 'creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        contact_position: null,
                        contact_title: null,
                        contact_first_name: null,
                        contact_last_name: null,
                        contact_email: null,
                    },
                };
            }
        case TYPE.CRE_REQUEST_STATUSES:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        statuses: action.items,
                    },
                };
            }
        case TYPE.CRE_REQUEST_DETAILS:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    creInvite: {
                        ...state.creInvite,
                        ...action.creInvite,
                    },
                    creVendor: {
                        ...state.creVendor,
                        ...action.creVendor,
                    },
                };
            }
        case TYPE.CRE_COUNTRIES:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        countries: action.items,
                    },
                };
            }
        case TYPE.CRE_CURRENCIES:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        currencies: action.items,
                    },
                };
            }
        case TYPE.CRE_VENDOR_DOC_REMOVE:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: state[action.id][action.path].filter(
                            (val, idx) => idx !== action.idx,
                        ),
                    },
                };
            }
        case TYPE.CRE_VENDOR_ACC_SCHEMES:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        acc_scheme_data: action.items,
                    },
                };
            }
        case TYPE.CRE_VENDOR_ACC_SCHEME_SELECT:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        acc_scheme: action.schemeId,
                    },
                };
            }
        case TYPE.CRE_VENDOR_ACC_SCHEME_RESET:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        acc_scheme_name: null,
                    },
                };
            }
        case TYPE.CRE_VENDOR_ACC_STANDARD_SELECT:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        ...action.data,
                    },
                };
            }
        case TYPE.CRE_VENDOR_ACC_STANDARD_RESET:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        acc_id: null,
                        acc_scheme: null,
                        acc_scheme_name: null,
                        acc_std_next_audit_date: null,
                        acc_std_doc: null,
                        acc_docs: [],
                    },
                };
            }
        case TYPE.CRE_VENDOR_GOODS_MANUFACTURERS:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        man_data: action.items,
                    },
                };
            }
        case TYPE.CRE_VENDOR_GOODS_MANUFACTURER_SELECT:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        man_id: action.manId,
                    },
                };
            }
        case TYPE.CRE_VENDOR_GOODS_MANUFACTURER_RESET:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        man_name: null,
                    },
                };
            }
        case TYPE.CRE_VENDOR_GOODS_SELECT:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        ...action.data,
                    },
                };
            }
        case TYPE.CRE_VENDOR_GOODS_RESET:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        cag_id: null,
                        gty_healthcare: 0,
                        gty_non_healthcare: 0,
                        gty_description: null,
                        man_id: null,
                        man_name: null,
                        model_number: null,
                        cou_of_origin_id: null,
                        tda_artg_fda_ce: null,
                        pog_ex_tax: null,
                        pog_in_tax: null,
                        price_is_valid_for: null,
                        is_price_sub_to_roe: null,
                        roe_from: null,
                        roe_to: null,
                        roe_cur_id: null,
                        perc_of_price_sub_to_roe: null,
                        goods_docs: [],
                    },
                };
            }
        case TYPE.CRE_VENDOR_WARRANTY_LICENCE_TYPES:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        ...(action.code === 'lgalty' && {
                            emp_lra_lic_type_data: action.items,
                        }),
                        ...(action.code === 'eoolty' && {
                            emp_eoo_lic_type_id_data: action.items,
                        }),
                        ...(action.code === 'latype' && {
                            org_al_type_data: action.items,
                        }),
                    },
                };
            }
        case TYPE.CRE_VENDOR_WARRANTY_SELECT:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        ...action.data,
                    },
                };
            }
        case TYPE.CRE_VENDOR_WARRANTY_RESET:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        caw_id: null,
                        caw_description: null,
                        swp: null,
                        is_aw_available: null,
                        is_ac_involved: null,
                        ac_per_year: null,
                        aca: null,
                        are_you_res_for_as: null,
                        asr_com_details: null,
                        does_item_req_ins_by_ur_com: null,
                        do_emp_req_lic_by_lra: null,
                        emp_lra_lic_type_id: null,
                        emp_lic_iss_by_lra_name: null,
                        do_emp_req_eoo_lic: null,
                        emp_eoo_lic_type_id: null,
                        does_your_org_req_al_to_rtg: null,
                        org_al_type_id: null,
                        other_rtp_this_item: null,
                        warranty_docs: [],
                    },
                };
            }
        case TYPE.CRE_VENDOR_SERVICE_LICENCE_TYPES:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        ...(action.code === 'lgalty' && {
                            cas_emp_lra_lic_type_data: action.items,
                        }),
                        ...(action.code === 'eoolty' && {
                            cas_emp_eoo_lic_type_data: action.items,
                        }),
                    },
                };
            }
        case TYPE.CRE_VENDOR_SERVICE_SELECT:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        ...action.data,
                    },
                };
            }
        case TYPE.CRE_VENDOR_SERVICE_RESET:
            if (['creVendor'].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        cas_id: null,
                        cas_healthcare: 0,
                        cas_non_healthcare: 0,
                        cas_description: null,
                        cas_do_emp_req_lic_by_lra: null,
                        cas_emp_lra_lic_type_id: null,
                        cas_emp_lic_iss_by_lra_name: null,
                        cas_do_emp_req_eoo_lic: null,
                        cas_emp_eoo_lic_type_id: null,
                        fixed_rate: 0,
                        hourly_rate: 0,
                        pos_ex_tax: null,
                        pos_in_tax: null,
                        cas_price_is_valid_for: null,
                        hos_from_am: null,
                        hos_from_pm: null,
                        cos_poh: null,
                        pos_poh_ex_tax: null,
                        pos_poh_in_tax: null,
                        service_docs: [],
                    },
                };
            }
        default:
            return state;
    }
}
