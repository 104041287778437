import { GridController } from '../../../../../common/grid/grid.controller';

/* @ngInject */
export function ServiceContractsGridController($controller, $state, $ngRedux, NavigationService, ServiceContractsActions, ServiceContractService) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'serviceContracts';
        ctrl.identifier = 'serviceContractsGrid';
        ctrl.filterTemplate = './service-contracts-grid-filter.html';
        ctrl.exportService = ServiceContractService.getContracts;

        ctrl.columns = [
            {title: '#',                mapper: null},
            {title: 'Contract Number',  mapper: 'number'},
            {title: 'Type',             mapper: 'type.name'},
            {title: 'Vendor',           mapper: 'vendor.name'},
            {title: 'Start Date',       mapper: 'getStartDate'},
            {title: 'Expiry Date',      mapper: 'getExpiryDate'},
            {title: 'Cost',             mapper: 'getCosts'},
            {title: 'Status',           mapper: 'status.name'}
        ];

        ctrl.buttons = [{label: 'Summary', class: 'btnGridModify', callback: ctrl.onSummary}];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), ServiceContractsActions)(ctrl);
        ctrl.dataAction = ctrl.getServiceContracts;
        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
