import { GridController } from '../../../../../../common/grid/grid.controller';

/* @ngInject */
export function AdministrationAccountsRoomsGridController($controller, $ngRedux, $state, NavigationService, AdministrationActions) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'administration';
        ctrl.identifier = 'administrationAccountsRoomsGrid';
        ctrl.filterComponent = false;
        ctrl.exportComponent = false;

        ctrl.columns = [
            {title: '#',        mapper: null},
            {title: 'ID',       mapper: 'id'},
            {title: 'Name',     mapper: 'name'},
            {title: 'Level',    mapper: 'level'},
            {title: 'Building', mapper: 'building'},
            {title: 'Number',   mapper: 'number'},
            {title: '',         mapper: 'warning'},
            {title: '',         mapper: 'critical'}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), AdministrationActions)(ctrl);
        ctrl.dataAction = ctrl.getRooms;

        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
