/* @ngInject */
export function accStandardsPanel($mdDialog, Notifier, AccreditationService, AuthService, EmployeeService, AccreditationDocumentsService, $filter) {
    return {
        restrict: 'E',
        template: require('./acc-standards-panel.html'),
        replace: true,
        scope: {
            accStandards: '=accStandards', //expected array of AccStandard
            selectedStandard: '=selectedStandard',
            itemSelected: '&itemSelected', //callback when selected standard has changed
            searchForStandard: '&searchForStandard',
            fetchAccStandardAssesmentItems: '&fetchAccStandardAssesmentItems', //get assesment items for sub-sub standard
            locationUuid: '=locationUuid',
            accreditationSchemaCode: '=accreditationSchemaCode',
            locationName: '@locationName',
            panelMode: '@panelMode', //should be "EDIT" or "APPROVAL",
            year: '@year'
        },
        link: function (scope, element, attribute, ctrl) {
            var currentUser = AuthService.getUser();
            ctrl.isApprovalUser = (currentUser.type == 'Administrator' || currentUser.type == 'Quality Manager' || currentUser.type == 'Head Radiation Safety Coordinator' || currentUser.type == 'Site Radiation Safety Coordinator' || currentUser.type == 'Site Assistant Radiation Safety Coordinator');

            scope.$watch('ctrl.accStandards', function (val) {
                ctrl.currentLevel = val;

                //Fetch progress for standards/sub-standards
                if (ctrl.selectedStandard == null) {
                    for (var stdId in ctrl.currentLevel) {
                        if (ctrl.currentLevel.hasOwnProperty(stdId)) {
                            ctrl.fetchStandardProgress(stdId);
                        }
                    }
                }
            });

            scope.$watch('ctrl.selectedStandard', function (val, oldVal) {

                //Check if previous item on form is change/dirty, and ask user to confirm dropping changes
                if ((oldVal != null) && (oldVal.assessment)
                    && (oldVal.assessment.dirty === true)) {
                    //Some changes dropped
                    Notifier.info($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_LAST_CHANGES")
                                  + oldVal.number
                                  + $filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_DROPPED"));
                    oldVal.assessment.value = null;
                    oldVal.assessment.comment = null;
                    oldVal.assessment.assessmentItems.forEach(function (assItem) {
                        assItem.value = null;
                    });
                    oldVal.assessment.dirty = false;
                }

                //check if selectedStandard is changed outside of component
                if ((!((val == null) && (ctrl.currentLevel == ctrl.accStandards)))
                    && (!((val != null) && (ctrl.currentLevel == val.children)))) {
                    //create history for back button in panel
                    var tempStd = val;
                    ctrl.history = [];
                    while (tempStd = ctrl.searchForStandard({ id: tempStd.parentId, hierarchy: ctrl.accStandards })) {
                        ctrl.history.unshift(tempStd);
                    }
                    ctrl.history.unshift(null);
                    //set new current panel view
                    ctrl.currentLevel = val != null ? val.children : ctrl.accStandards;
                }

                //Fetch assessment data/values/documents previously provided from user
                if ((ctrl.selectedStandard != null) && (ctrl.selectedStandard.type === ctrl.SUB_SUB_STANDARD)
                        && (ctrl.selectedStandard.assessment != null)) {
                    AccreditationService.fetchAccShemaAssessmentValue(ctrl.accreditationSchemaCode,
                        ctrl.locationUuid, ctrl.selectedStandard.assessment.id, ctrl.year)
                        .then(function (response) {
                            if ((response.status >= 200) && (response.status < 300) &&
                                (response.data.result != null)) {
                                //all ok, parse values and assing to object
                                ctrl.selectedStandard.assessment.value = response.data.result.value;
                                ctrl.selectedStandard.assessment.comment = response.data.result.comment;
                                for (var i = 0; i < response.data.result.assessmentItems.length; i++) {
                                    var localAssessmentId = response.data.result.assessmentItems[i].id;
                                    ctrl.selectedStandard.assessment.assessmentItems.forEach(function (assItem) {
                                        if (assItem.id === localAssessmentId) {
                                            assItem.value = response.data.result.assessmentItems[i].value;
                                        }
                                    });
                                }
                                scope.$broadcast('acc-schema-assessment-value-fetched', {});

                                //Watch if assessment value has been changed, if it is dirty
                                ctrl.selectedStandard.assessment.dirty = false;

                            } else if ((response.status >= 500) && (response.status < 600)) {
                                Notifier.error($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_FETCH_SCHEMA_ERROR"));
                            } else if ((response.status >= 400) && (response.status < 500)) {
                                Notifier.error($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_PAGE_NOT_FOUND"));
                            } else if ((response.data == null) || (response.data.result == null)) {
                                //No data for this item
                            }
                        }, function (error) {
                            Notifier.error($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_FETCH_DATA_ERROR"));
                        });
                }

                if ((ctrl.selectedStandard != null) && (ctrl.selectedStandard.standardId != null)) {
                    ctrl.fetchStandardProgress(ctrl.selectedStandard.standardId);
                } else if (ctrl.selectedStandard == null) {
                    for (var stdId in ctrl.currentLevel) {
                        if (ctrl.currentLevel.hasOwnProperty(stdId)) {
                            ctrl.fetchStandardProgress(stdId);
                        }
                    }
                }
            });

            function ForwardEvidenceRequest($scope, $mdDialog, color, title, myStandardId, myAssessmentItemId, slocUuid, employees) {
                $scope.headerColor = color;
                $scope.headerTitle = title;
                $scope.myStandardId = myStandardId;
                $scope.myAssessmentItemId = myAssessmentItemId;
                $scope.slocUuid = slocUuid;
                $scope.employeeList = employees;
                $scope.searchText = "";
                $scope.selectedEmployee = null;
                $scope.selectedEmail = null;
                $scope.selectedFirstName = null;
                $scope.selectedLastName = null;
                $scope.selectedTab = 0;

                /**
                  * Create filter function for a query string */
                $scope.createFilterFor = function(query) {
                    var lowercaseQuery = angular.$$lowercase(query);
                    return function filterFn(employee) {
                        return ( angular.$$lowercase(employee.firstName+' '+employee.lastName).indexOf(lowercaseQuery) === 0);
                    };
                };

                $scope.querySearch = function (query) {
                    var results = $scope.employeeList.filter($scope.createFilterFor(query));
                    return results;
                };

                $scope.searchTextChange = function(text) {};
                $scope.selectedItemChange = function(text) {};

                $scope.cancel = function () {
                    $mdDialog.cancel();
                };

                $scope.submit = function (employee, customEmail, customFirstName, customLastName) {

                    if ($scope.selectedTab == 0) {
                        var uuid = employee.uuid;
                        $scope.cursorState = 'wait';
                        AccreditationDocumentsService.createAccEvidenceRequest(ctrl.accreditationSchemaCode,
                            $scope.slocUuid, $scope.myStandardId,  $scope.myAssessmentItemId, uuid, null, ctrl.year)
                            .then(function (response) {
                                $scope.cursorState = 'default';
                                Notifier.info($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_EVIDENCE_SCHEDULED"));
                                $mdDialog.hide();
                            }, function (error) {
                                $scope.cursorState = 'default';
                                Notifier.error($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_EVIDENCE_FAILED"));
                                //$mdDialog.hide();
                            });
                    } else if ($scope.selectedTab == 1) {
                        var email = customEmail;
                        var firstName = customFirstName;
                        var lastName = customLastName;
                        AccreditationDocumentsService.createAccEvidenceRequest3rdParty(ctrl.accreditationSchemaCode,
                            $scope.slocUuid, $scope.myStandardId,  $scope.myAssessmentItemId, email, firstName, lastName, ctrl.year)
                            .then(function (response) {
                                $scope.cursorState = 'default';
                                Notifier.info($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_EVIDENCE_SCHEDULED"));
                                $mdDialog.hide();
                            }, function (error) {
                                $scope.cursorState = 'default';
                                Notifier.error($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_EVIDENCE_FAILED"));
                                //$mdDialog.hide();
                            });
                    }
                };
            }

            ctrl.forwardAssessmentItemEvRequest = function (assItemId) {
                //Get Active employees for currently selected site location
                EmployeeService.getEmployees({
                    status: 'act', /* Active employees, hardcoded id*/
                    location: ctrl.locationUuid
                }).then(function (employeeList) {
                    $mdDialog.show({
                        controller: ForwardEvidenceRequest,
                        template: require('../views/accreditation/accreditation-forward-evidence-request-dialog.html'),
                        scope: scope, // use parent scope in template
                        preserveScope: true,
                        locals: {
                            color: '#a934e4',
                            title: $filter('translate')("ACCREDITATION.TABS.DOCUMENTS.TITLE_EVIDENCE"),
                            myStandardId: ctrl.selectedStandard.standardId,
                            myAssessmentItemId: assItemId,
                            slocUuid: ctrl.locationUuid,
                            employees: employeeList.items
                        },
                        clickOutsideToClose: true
                    });
                });
            }
        },
        controller: 'AccStandardsPanelController',
        controllerAs: 'ctrl',
        bindToController: true
    };
}
