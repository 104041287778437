import isFunction from 'lodash/isFunction';
import { saveAs } from 'file-saver';

/**
 * Creates a new GridDocumentsController.
 * @class
 * @memberOf module:root/common/grid
 * @see {@link https://docs.angularjs.org/guide/component|AngularJS Component}
 */
class GridDocumentsController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param Notifier {service} {@link Notifier}
     * @param NavigationService {service} {@link module:root/common.NavigationService|NavigationService}
     * @param DocumentService {service}
     *
     */
    constructor($state, $scope, Notifier, NavigationService, DocumentService) {
        this.$state = $state;
        this.$scope = $scope;
        this.Notifier = Notifier;
        this.NavigationService = NavigationService;
        this.selectedTypes = null;
        this.DocumentService = DocumentService;
        this.documentTypes = null;
        this.disabled = false;
    }

    /**
     * @method $onInit
     * @memberof module:root/common/grid.GridDocumentsController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.documentTypes = null;
        switch (this.documentCode){
            case 'edo':
                this.DocumentService.fetchEquipmentDocumentTypes(null, this.filter.type).then(items => {
                    this.documentTypes = items;
                }); break;

            case 'uli':
                this.unsubscribeLocationChange = this.$scope.$on('LOCATION_SUBJECT_SELECTED', this.onLocationChange.bind(this));
                break;
        }
    }

    onLocationChange = (evt, loc) => {
        this.DocumentService.fetchEmployeeDocumentTypes(loc.in_subject_uuid).then(items => {
            this.documentTypes = items;
        });
    }

    /**
     * @method downloadDocuments
     * @memberof module:root/common/grid.GridDocumentsController
     */
    downloadDocuments() {
        if(this.filter.checkLocation && !this.filter.location /*|| !this.selectedTypes?.length*/) {
            this.Notifier.error('First select location from filter');
            return
        }

        if (isFunction(this.fetchDocumentToken) === false) {
            throw new Error('Missing download documents action definition');
            return
        }

        this.Notifier.info('Download archive is in progress!', false);
        this.fetchDocumentToken(this.documentCode, this.filter, this.selectedTypes).then(response => {
            this.selectedTypes = null;
            this.disabled = true;
            this.DocumentService.downloadZip(response.data.token).then(response => {
                saveAs(new Blob([response.data], {type: "octet/stream"}), 'myrso-documents-'+Date.now()+'.zip');
                this.Notifier.success('Download archive completed!');
                this.disabled = false;
            }, error => {
                if(error.status === 404) { // fallback from downloading archive to downloading pdf in case of dev environment
                    this.DocumentService.downloadFile(`/${response.data.path}`, 1).then((data) => {
                        if(data.status === 200) {
                            this.Notifier.success('Download archive completed!');
                        } else {
                            this.Notifier.error("Error downloading archive!");
                            this.disabled = false;
                        }
                    })
                }
            });
        }, function (error) {
            this.Notifier.error("Error fetching documents list! Archive is too big!");
            this.disabled = false;
        });
    }

    $onDestroy() {
        if (this.unsubscribeLocationChange)
            this.unsubscribeLocationChange();
    }
}

GridDocumentsController.$inject = ['$state', '$scope', 'Notifier', 'NavigationService', 'DocumentService'];

export const gridDocuments = {
    bindings: {
        filter: '<',
        fetchDocumentToken: '<',
        documentCode: '<'
    },
    controller: GridDocumentsController,
    template: require('./grid-documents.html')
};
