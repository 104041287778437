/**
 * @module Employees Import Settings
 */
import * as STATES                      from './import-settings.states';
import {IDS}                            from "./import-settings-action-id.constants";

import { empImportSettingsCentersList }         from './centers/centers.component';
import { empImportSettingsCentersListGrid }     from './centers/centers-grid.component';
import { empImportSettingsCentersDetails }      from './centers/details/details.component';

import { empImportSettingsOccupationsList }         from './occupations/occupations.component';
import { empImportSettingsOccupationsListGrid }     from './occupations/occupations-grid.component';
import { empImportSettingsOccupationsDetails }      from './occupations/details/details.component';

import { empImportSettingsUsersRelocatedList }         from './users/relocated/users-relocated.component';
import { empImportSettingsUsersRelocatedListGrid }     from './users/relocated/users-relocated-grid.component';

import { empImportSettingsUsersSkippedList }         from './users/skipped/users-skipped.component';
import { empImportSettingsUsersSkippedListGrid }     from './users/skipped/users-skipped-grid.component';

import { empImportSettingsUsersDetails }             from './users/details/details.component';

export const EMP_IMPORT_SETTINGS_MODULE = angular.module(
    'migration.employees.import-settings',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values(STATES).map(state => stateRegistry.register(state));
}]).component({
    empImportSettingsCentersList,
    empImportSettingsCentersListGrid,
    empImportSettingsCentersDetails,
    // empImportSettingsCentersDetailsGrid,

    empImportSettingsOccupationsList,
    empImportSettingsOccupationsListGrid,
    empImportSettingsOccupationsDetails,
    // empImportSettingsOccupationsDetailsGrid,

    empImportSettingsUsersRelocatedList,
    empImportSettingsUsersRelocatedListGrid,

    empImportSettingsUsersSkippedList,
    empImportSettingsUsersSkippedListGrid,
    empImportSettingsUsersDetails,
    // empImportSettingsUsersDetailsGrid,

}).run(['$templateCache', ({ put }) => {
    put(IDS.app_employees_import_settings_centers_list,      '<emp-import-settings-centers-list></emp-import-settings-centers-list>');
    put(IDS.app_employees_import_settings_centers_details,   '<emp-import-settings-centers-details></emp-import-settings-centers-details>');

    put(IDS.app_employees_import_settings_occupations_list,      '<emp-import-settings-occupations-list></emp-import-settings-occupations-list>');
    put(IDS.app_employees_import_settings_occupations_details,   '<emp-import-settings-occupations-details></emp-import-settings-occupations-details>');

    put(IDS.app_employees_import_settings_users_relocated_list,      '<emp-import-settings-users-relocated-list></emp-import-settings-users-relocated-list>');
    put(IDS.app_employees_import_settings_users_relocated_details,   '<emp-import-settings-users-relocated-details></emp-import-settings-users-relocated-details>');

    put(IDS.app_employees_import_settings_users_skipped_list,      '<emp-import-settings-users-skipped-list></emp-import-settings-users-skipped-list>');
    put(IDS.app_employees_import_settings_users_skipped_details,   '<emp-import-settings-users-skipped-details></emp-import-settings-users-skipped-details>');

    put('./centers-grid-filter.html', require('./centers/centers-grid-filter.html'));
    put('./occupations-grid-filter.html', require('./occupations/occupations-grid-filter.html'));
    put('./users-relocated-grid-filter.html', require('./users/relocated/users-relocated-grid-filter.html'));
    put('./users-skipped-grid-filter.html', require('./users/skipped/users-skipped-grid-filter.html'));
}]);
