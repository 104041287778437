/**
 * @module ApplicationsPermits
 */
import * as STATES from './applications-permits.states';
import * as STATES_PUBLIC from './applications-permits.states.public';
import * as LAS_REQUEST from './ap-laser-request/ap-laser-request.states';
import * as LAS_REQUEST_PUBLIC from './ap-laser-request/ap-laser-request.states.public';
import * as NUC_REQUEST from './ap-nuclear-energy/ap-nuclear-energy-request.states';
import * as NUC_REQUEST_PUBLIC from './ap-nuclear-energy/ap-nuclear-energy-request.states.public';
import { apLaserRequest } from './ap-laser-request/ap-laser-request.component';
import { apLaserRequestIntro } from './ap-laser-request/intro/ap-laser-request-intro.component';
import { apLaserRequestDetails } from './ap-laser-request/details/ap-laser-request-details.component';
import { apLaserRequestEquipment } from './ap-laser-request/equipment/ap-laser-request-equipment.component';
import { apLaserRequestEquipmentAuth } from './ap-laser-request/equipment/ap-laser-request-equipment-auth.component';
import { apLaserRequestEquipmentGrid } from './ap-laser-request/equipment/grid/ap-laser-request-equipment-grid.component';
import { apLaserRequestEquipmentAuthGrid } from './ap-laser-request/equipment/grid/ap-laser-request-equipment-auth-grid.component';
import { apLaserRequestTrainings } from './ap-laser-request/trainings/ap-laser-request-trainings.component';
import { apLaserRequestTrainingsGrid } from './ap-laser-request/trainings/grid/ap-laser-request-trainings-grid.component';
import { apLaserRequestSafetyPlan } from './ap-laser-request/safety-plan/ap-laser-request-safety-plan.component';
import { apLaserRequestInvitation } from './ap-laser-request/invitation/ap-laser-request-invitation.component';
import { apLaserRequestConfirmation } from './ap-laser-request/confirmation/ap-laser-request-confirmation.component';
import { apLaserRequestGrid } from './ap-laser-request/grid/ap-laser-request-grid.component';

export const APPLICATIONS_PERMITS_MODULE = angular.module(
    'migration.applications-permits',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values({
        ...STATES,
        ...STATES_PUBLIC,
        ...LAS_REQUEST,
        ...LAS_REQUEST_PUBLIC,
        ...NUC_REQUEST,
        ...NUC_REQUEST_PUBLIC
    }).map(state => stateRegistry.register(state));
}]).component({
    apLaserRequest,
    apLaserRequestIntro,
    apLaserRequestDetails,
    apLaserRequestEquipment,
    apLaserRequestEquipmentAuth,
    apLaserRequestEquipmentGrid,
    apLaserRequestEquipmentAuthGrid,
    apLaserRequestTrainings,
    apLaserRequestTrainingsGrid,
    apLaserRequestSafetyPlan,
    apLaserRequestInvitation,
    apLaserRequestConfirmation,
    apLaserRequestGrid
}).run(['$templateCache', ({ put }) => {
    put('public.applicationsPermits.laser.request.intro', '<ap-laser-request-intro type="lasper"></ap-laser-request-intro>');
    put('public.applicationsPermits.laser.request.details', '<ap-laser-request-details type="lasper"></ap-laser-request-details>');
    put('public.applicationsPermits.laser.request.equipment', '<ap-laser-request-equipment type="lasper"></ap-laser-request-equipment>');
    put('public.applicationsPermits.laser.request.confirmation', '<ap-laser-request-confirmation type="lasper"></ap-laser-request-confirmation>');
    put('app.applicationsPermits.laser.request.invitation', '<ap-laser-request-invitation type="lasper"></ap-laser-request-invitation>');
    put('app.applicationsPermits.laser.manage.listing', '<ap-laser-request type="lasper"></ap-laser-request>');
    put('app.applicationsPermits.laser.manage.details', '<ap-laser-request-details type="lasper"></ap-laser-request-details>');
    put('app.applicationsPermits.laser.manage.equipment', '<ap-laser-request-equipment-auth type="lasper"></ap-laser-request-equipment-auth>');
    put('app.applicationsPermits.laser.manage.trainings', '<ap-laser-request-trainings type="lasper"></ap-laser-request-trainings>');
    put('app.applicationsPermits.laser.manage.safetyPlan', '<ap-laser-request-safety-plan type="lasper"></ap-laser-request-safety-plan>');
    put('app.applicationsPermits.laser.alerts.listing', '<ap-laser-request type="lasper"></ap-laser-request>');
    put('public.applicationsPermits.nuclearEnergy.request.intro', '<ap-laser-request-intro type="nucper"></ap-laser-request-intro>');
    put('public.applicationsPermits.nuclearEnergy.request.details', '<ap-laser-request-details type="nucper"></ap-laser-request-details>');
    put('public.applicationsPermits.nuclearEnergy.request.equipment', '<ap-laser-request-equipment type="nucper"></ap-laser-request-equipment>');
    put('public.applicationsPermits.nuclearEnergy.request.confirmation', '<ap-laser-request-confirmation type="nucper"></ap-laser-request-confirmation>');
    put('app.applicationsPermits.nuclearEnergy.request.invitation', '<ap-laser-request-invitation type="nucper"></ap-laser-request-invitation>');
    put('app.applicationsPermits.nuclearEnergy.manage.listing', '<ap-laser-request type="nucper"></ap-laser-request>');
    put('app.applicationsPermits.nuclearEnergy.manage.details', '<ap-laser-request-details type="nucper"></ap-laser-request-details>');
    put('app.applicationsPermits.nuclearEnergy.manage.equipment', '<ap-laser-request-equipment-auth type="nucper"></ap-laser-request-equipment-auth>');
    put('app.applicationsPermits.nuclearEnergy.manage.trainings', '<ap-laser-request-trainings type="nucper"></ap-laser-request-trainings>');
    put('app.applicationsPermits.nuclearEnergy.manage.safetyPlan', '<ap-laser-request-safety-plan type="nucper"></ap-laser-request-safety-plan>');
    put('app.applicationsPermits.nuclearEnergy.alerts.listing', '<ap-laser-request type="nucper"></ap-laser-request>');
    put('./ap-laser-request-grid-filter.html', require('./ap-laser-request/grid/ap-laser-request-grid-filter.html'));
    put('./ap-laser-request-equipment-grid-filter.html', require('./ap-laser-request/equipment/grid/ap-laser-request-equipment-grid-filter.html'));
}]);
