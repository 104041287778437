import { TabsReducer } from '../../../common/tabs/tabs.reducer';
import {IDS} from "./rep-monthly-action-id.constants";

export const repMonthlyTabs = {
    [IDS.app_reporting_monthly]: Object.assign(TabsReducer(), {
        tabs: [
            {
                state: IDS.app_reporting_monthly_list,
                title: 'REPORTING.MONTHLY.LIST.TAB',
                visible: true,
                disabled: false,
                loading: false,
            },
            {
                state: IDS.app_reporting_monthly_details,
                title: 'REPORTING.MONTHLY.DETAILS.TAB',
                visible: false,
                disabled: false,
                loading: false,
            },
        ],
    }),
};
