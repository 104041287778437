/**
 * @module root/components/pnp/accred
 */
import * as STATES                  from './pnp-accred.states';
import { IDS }                      from './pnp-accred-action-id.constants';
import { pnpAccredList}             from './list/list.component';
import { pnpAccredListGrid}         from './list/list-grid.component';
import { pnpAccredDetails}          from './details/details.component';
import { pnpAccredDetailsGrid}      from './details/details-grid.component';

export const PNP_ACCRED_MODULE = angular.module(
    'migration.pnp.accred',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values(STATES).map(state => stateRegistry.register(state))
}]).component({
    pnpAccredList,
    pnpAccredListGrid,

    pnpAccredDetails,
    pnpAccredDetailsGrid,
}).run(['$templateCache', ({ put }) => {
    put(IDS.app_pnp_accred_list,         '<pnp-accred-list></pnp-accred-list>');
    put(IDS.app_pnp_accred_details,      '<pnp-accred-details></pnp-accred-details>');
    put(IDS.app_pnp_accred_add,          '<pnp-accred-details></pnp-accred-details>');
    put('./list-grid-filter.html', require('./list/list-grid-filter.html'));
}]);
