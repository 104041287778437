import isUndefined from 'lodash/isUndefined';
import has from 'lodash/has';
import get from 'lodash/get';

class AutocompleteController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        if (isUndefined(this.validate))
            this.validate = false;
        if (isUndefined(this.required))
            this.required = false;
        if (isUndefined(this.disabled))
            this.disabled = false;

        if (typeof this.stateDataIdentifier === 'undefined')
            this.stateDataIdentifier = this.identifier;

        if (this.validate && isUndefined(this.name))
            throw new Error('Missing input name attribute');

        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);
    }

    onSelect() {
        if (this.item) {
            this.$ngRedux.dispatch({
                type: 'AUTOCOMPLETE_SELECTED',
                id: this.identifier,
                path: this.statePath,
                item: this.item,
                input: this.item
            });
        }
        if (this.callback) {
            this.callback({
                $event: this.item
            });
        }
    }

    onChange(keyword) {
        if (!keyword) {
            this.$ngRedux.dispatch({
                type: 'AUTOCOMPLETE_SELECTED',
                id: this.identifier,
                path: this.statePath,
                item: null,
                input: null,
            });
        }
    }

    search = (keyword) => {
        if (this.action) {
            if (keyword) {
                this.$ngRedux.dispatch({
                    type: 'AUTOCOMPLETE_SELECTED',
                    id: this.identifier,
                    path: this.statePath,
                    item: null,
                    input: null,
                });
                return this.action({
                    $event: keyword
                }).then(() => {
                    return this.provider;
                })
            } else {
                this.$ngRedux.dispatch({
                    type: 'AUTOCOMPLETE_SELECTED',
                    id: this.identifier,
                    path: this.statePath,
                    item: null,
                    input: null,
                });
                return [];
            }
        } else {
            return keyword ? this.provider.filter(this.filterProvider(keyword)) : this.provider;
        }
    }

    filterProvider(keyword) {
        return item => {
            return this.getItemText(item).toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
        }
    }

    getItemText(item) {
        return this.itemProps.reduce((result, prop) => {
            if (has(item, prop)) result.push(item[prop]);
            else result.push(prop);
            return result;
        }, []).join(' ');
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeModule();
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        }
    }

    mapState(state) {
        return {
            provider: get(state[this.module][this.stateDataIdentifier], this.stateProvider, []),
            item: get(state[this.module][this.identifier], this.statePath, null)
        }
    }
}

AutocompleteController.$inject = ['$ngRedux'];

export const autocomplete = {
    bindings: {
        identifier: '@',
        name: '@',
        label: '@',
        statePath: '@',
        stateProvider: '@',
        stateDataIdentifier: '@',
        action: '&?',
        callback: '&?',
        itemIcon: '@',
        itemProps: '<',
        validate: '<',
        required: '<',
        disabled: '<'
    },
    require : {
        form : '^form'
    },
    controller: AutocompleteController,
    template: `
        <md-autocomplete md-input-id="{{$ctrl.name}}"
                 md-input-name="{{$ctrl.name}}"
                 md-floating-label="{{$ctrl.label}}"
                 md-no-cache="true"
                 md-selected-item="$ctrl.item"
                 md-selected-item-change="$ctrl.onSelect()"
                 md-search-text="keyword"
                 md-search-text-change="$ctrl.onChange(keyword)"
                 md-items="item in $ctrl.search(keyword)"
                 md-item-text="$ctrl.getItemText(item)"
                 md-min-length="0"
                 md-menu-class="autocompleteItem"
                 md-require-match=true
                 md-clear-button="!$ctrl.disabled"
                 required="{{$ctrl.required}}"
                 ng-required="$ctrl.required"
                 ng-disabled="$ctrl.disabled">
            <md-item-template>
                <asset ng-if="$ctrl.itemIcon" src="{{$ctrl.itemIcon}}"></asset>
                <span md-highlight-text="keyword" md-highlight-flags="^i">{{$ctrl.getItemText(item)}}</span>
            </md-item-template>
            <md-not-found>{{'COMMON.AUTOCOMPLETE.NO_RESULT' | translate}}</md-not-found>
            <div ng-messages="$ctrl.form[$ctrl.name].$error">
                <div ng-message="required">{{'COMMON.AUTOCOMPLETE.FIELD_REQUIRED' | translate}}</div>
            </div>
        </md-autocomplete>
    `
};
