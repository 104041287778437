import { API_BASE } from '../common/utils/util.constants';
import { TabsReducer } from '../common/tabs/tabs.reducer';
import { GridReducer } from '../common/grid/grid.reducer';
import eventsReducer from '../components/events/events.reducer';
import eventsAssessmentReducer from '../components/events/assessment/events-assessment.reducer';
import eventsAlertEscalateReducer from '../components/events/alert-escalate/events-alert-escalate.reducer';
import eventsActionPlanReducer from '../components/events/action-plan/events-action-plan.reducer';
import * as GRID from '../common/grid/grid.constants';
import moment from 'moment';
import set from 'lodash/set';
import update from 'lodash/update';
import { DATE_FORMAT } from '../common/utils/util.constants';
import { resolveSignature } from '../common/utils/util.data';

const initialState = {
    ...eventsReducer,
    ...eventsAssessmentReducer(),
    ...eventsAlertEscalateReducer(),
    ...eventsActionPlanReducer()
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'TAB_INIT':
        case 'TAB_OVERRIDE':
        case 'TAB_PARAMS':
        case 'TAB_ENABLE':
        case 'TAB_DISABLE':
        case 'TAB_SHOW':
        case 'TAB_HIDE':
        case 'TAB_LOADING':
        case 'TAB_SELECT':
            if ([
                'app.events.addSafetyRiskPeople',
                'app.events.addOperationalRiskBusiness',
                'app.events.addRadiationRisk',
                'app.events.manageRisksIncidents',
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: TabsReducer(state[action.id], action)
                }
            }
        case 'TAB_SWITCH':
            if ([
                'app.events.addSafetyRiskPeople',
                'app.events.addOperationalRiskBusiness',
                'app.events.addRadiationRisk',
                'app.events.manageRisksIncidents',
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        selectedTabIndex: action.selectedTabIndex,
                        selectedState: action.selectedState
                    }
                }
            }
        case 'GRID_INIT':
        case 'GRID_RESET':
            if (action.id === 'eventsGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return {
                        ...filter,
                        code: null,
                        event_id: null,
                        type_id: null,
                        category_id: null,
                        subcategory_id: null,
                        date_from: null,
                        date_to: null,
                    };
                })));
            }
            if (action.id === 'eventsAssessmentGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return {
                        ...filter,
                    };
                })));
            }
            if (action.id === 'eventsAlertReceiversGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return {
                        ...filter,
                    };
                })));
            }
            if (action.id === 'eventsAlertNotificationsGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return {
                        ...filter,
                    };
                })));
            }
            if (action.id === 'eventsActionTaskGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return {
                        ...filter,
                    };
                })));
            }
            if (action.id === 'eventsActionPlanLibraryGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return {
                        ...filter,
                        keyword: null
                    };
                })));
            }
            if (action.id === 'eventsActionTaskLibraryGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return {
                        ...filter,
                        keyword: null
                    };
                })));
            }
        case 'SHOW_LOADING':
        case 'GRID_FETCH':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_RESET_FILTER':
        case GRID.GRID_SELECT:
        case GRID.GRID_SELECT_ALL:
        case GRID.GRID_DESELECT:
        case GRID.GRID_DESELECT_ALL:
            if (action.id === 'eventsGrid') {
                return {
                    ...state,
                    eventsGrid: GridReducer(state.eventsGrid, action)
                };
            }
            if (action.id === 'eventsAssessmentGrid') {
                return {
                    ...state,
                    eventsAssessmentGrid: GridReducer(state.eventsAssessmentGrid, action)
                };
            }
            if (action.id === 'eventsAlertReceiversGrid') {
                return {
                    ...state,
                    eventsAlertReceiversGrid: GridReducer(state.eventsAlertReceiversGrid, action)
                };
            }
            if (action.id === 'eventsAlertNotificationsGrid') {
                return {
                    ...state,
                    eventsAlertNotificationsGrid: GridReducer(state.eventsAlertNotificationsGrid, action)
                };
            }
            if (action.id === 'eventsActionTaskGrid') {
                return {
                    ...state,
                    eventsActionTaskGrid: GridReducer(state.eventsActionTaskGrid, action)
                };
            }
            if (action.id === 'eventsActionPlanLibraryGrid') {
                return {
                    ...state,
                    eventsActionPlanLibraryGrid: GridReducer(state.eventsActionPlanLibraryGrid, action)
                };
            }
            if (action.id === 'eventsActionTaskLibraryGrid') {
                return {
                    ...state,
                    eventsActionTaskLibraryGrid: GridReducer(state.eventsActionTaskLibraryGrid, action)
                };
            }
        case 'EVENT_DETAILS':
            if (action.id === 'eventItem') {
                const responsiblePerson = {
                    subject_uuid: action.data[1][0].rsp_uuid,
                    first_name: action.data[1][0].rsp_first_name,
                    last_name: action.data[1][0].rsp_last_name
                };
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        id: action.data[0][0].iev_id,
                        location: action.data[0][0].location_uuid,
                        locations: action.data[4],
                        additional_area: action.data[0][0].additional_area,
                        accountId: action.data[0][0].account_id,
                        visualId: action.data[0][0].print_event_id,
                        risk_name: action.data[0][0].risk_name,
                        risk_code: action.data[0][0].risk_code,
                        event: action.data[0][0].event,
                        event_type: action.data[0][0].event_id,
                        event_code: action.data[0][0].event_code,
                        event_subtype: action.data[0][0].type_id,
                        date: moment(action.data[0][0].event_date, DATE_FORMAT.OUTPUT_TIME).toDate(),
                        event_category: action.data[0][0].category_id,
                        event_subcategory: action.data[0][0].sub_category_id,
                        consequence_level: action.data[0][0].consequence_level_id,
                        likelihood_estimate: action.data[0][0].likelihood_estimation_id,
                        risk_rating: action.data[0][0].risk_rating,
                        gallery: action.data[3].length ? action.data[3].reduce((result, item) => {
                            if (item.type === 'image') {
                                result.push({
                                    id: item.fil_id,
                                    url: API_BASE + '/img/' + localStorage.myrsoAuthToken + item.url,
                                    width: item.width,
                                    height: item.height
                                });
                            }
                            return result;
                        }, []) : [],
                        image_urls: action.data[3].length ? action.data[3].reduce((result, item) => {
                            if (item.type === 'image') {
                                result.push({
                                    url: item.url,
                                    description: '',
                                    width: item.width,
                                    height: item.height
                                });
                            }
                            return result;
                        }, []) : [],
                        document_urls: action.data[3].length ? action.data[3].reduce((result, item) => {
                            if (item.type === 'doc') {
                                result.push({
                                    url: item.url,
                                    description: ''
                                });
                            }
                            return result;
                        }, []) : [],
                        responsible_person: responsiblePerson,
                        responsiblePersons: [responsiblePerson],
                        event_location: action.data[0][0].event_location_code,
                        event_location_desc: action.data[0][0].other_location,
                        event_harm: parseInt(action.data[0][0].did_incident_result_in_harm),
                        event_details: action.data[0][0].details,
                        eventInvolvedPersons: action.data[2].length ? action.data[2].map((item) => {
                            return {
                                person_type_id: parseInt(item.ivp_person_type_id),
                                age_group_id: parseInt(item.age_group_id),
                                first_name: item.ivp_first_name,
                                last_name: item.ivp_last_name,
                                mrn: item.ivp_medical_number,
                                email: item.ivp_email,
                                phone: item.ivp_phone,
                            };
                        }) : [],
                        signature: resolveSignature(action.data[0][0].signature),
                        signature_date: moment(action.data[0][0].signature_date, DATE_FORMAT.OUTPUT_TIME).toDate(),
                    }
                }
            }
        case 'EVENT_TYPES':
            if ([
                'eventItem',
                'eventAssessmentHistoricalRecord'
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        eventTypes: action.data.map((item) => {
                            return { code: item.code, value: item.event_id, label: item.name }
                        })
                    }
                };
            }
        case 'EVENT_SUBTYPES':
            if ([
                'eventItem',
                'eventAssessmentHistoricalRecord'
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        eventSubtypes: action.data.map((item) => {
                            return { code: item.code, value: item.type_id, label: item.name }
                        })
                    }
                }
            }
        case 'EVENT_SUBTYPE_RESET':
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        event_subtype: null,
                        event_category: null,
                        event_subcategory: null,
                    }
                }
            }
        case 'EVENT_CATEGORIES':
            if ([
                'eventItem',
                'eventAssessmentHistoricalRecord'
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        eventCategories: action.data.map((item) => {
                            return { code: item.code, value: item.type_id, label: item.name }
                        })
                    }
                }
            }
        case 'EVENT_CATEGORY_RESET':
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        event_category: null,
                        event_subcategory: null,
                    }
                }
            }
        case 'EVENT_SUBCATEGORIES':
            if ([
                'eventItem',
                'eventAssessmentHistoricalRecord'
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        eventSubcategories: action.data.map((item) => {
                            return { code: item.code, value: item.type_id, label: item.name }
                        })
                    }
                }
            }
        case 'EVENT_CATEGORY':
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        event_category: action.data.sub_category_id
                    }
                }
            }
        case 'EVENT_SUBCATEGORY':
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        event_subcategory: action.data.sub_category_id
                    }
                }
            }
        case 'EVENT_SUBCATEGORY_RESET':
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        event_subcategory: null
                    }
                }
            }
        case 'EVENT_CONSEQUENCE_LEVELS':
            if ([
                'eventItem',
                'eventAssessmentHistoricalRecord'
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        consequenceLevels: action.data.map((item) => {
                            return { value: item.category_id, label: item.category_name }
                        })
                    }
                }
            }
        case 'EVENT_LIKELIHOOD_ESTIMATIONS':
            if ([
                'eventItem',
                'eventAssessmentHistoricalRecord'
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        likelihoodEstimates: action.data.map((item) => {
                            return { value: item.category_id, label: item.category_name }
                        })
                    }
                }
            }
        case 'RISK_RATING':
            if (action.id === 'eventItem') {
                const params = JSON.parse(action.data.params);
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        risk_rating: params.risk_rating,
                        sac_score: params.sac_score,
                        followup_period: params.followup_period,
                    }
                }
            }
        case 'RISK_ACTIONS':
            if (action.id === 'eventAssessment') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        racActions: action.data.map((item) => ({
                            value: item.rac_id,
                            label: item.rac_type
                        })),
                    }
                }
            }
        case 'EVENT_RESPONSIBLE_PERSON_DETAILS':
            if (action.id === 'eventItem') {
                if (action.data[0].length) {
                    const responsiblePerson = {
                        ...action.data[0][0],
                        subject_uuid: action.data[0][0].uuid
                    };
                    return {
                        ...state,
                        eventItem: {
                            ...state.eventItem,
                            responsible_person: responsiblePerson,
                            responsiblePersons: [responsiblePerson]
                        },
                        eventAssessment: eventsAssessmentReducer(state.eventAssessment, action)
                    };
                }
            }
        case 'EVENT_RESPONSIBLE_PERSONS':
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        responsiblePersons: action.data.list,
                    }
                };
            }
            if (action.id === 'eventActionPlan') {
                return {
                    ...state,
                    eventActionPlan: {
                        ...state.eventActionPlan,
                        responsiblePersons: action.data.list,
                    }
                };
            }
            if (action.id === 'eventActionTask') {
                return {
                    ...state,
                    eventActionPlan: {
                        ...state.eventActionPlan,
                        actionTaskResponsiblePersons: action.data.list,
                    }
                };
            }
        case 'EVENT_LOCATIONS':
            if ([
                'eventItem',
                'eventAssessmentHistoricalRecord'
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        eventLocations: action.data.map((item) => {
                            return { id: item.category_id, value: item.category_code, label: item.category_name }
                        })
                    }
                }
            }
        case 'EVENT_INVOLVED_PERSON_TYPES': {
            if ([
                'eventItem',
                'eventAssessmentHistoricalRecord'
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        eventInvolvedPersonTypes: action.data.map((item) => {
                            return { value: parseInt(item.category_id), label: item.category_name }
                        })
                    }
                }
            }
        }
        case 'EVENT_INVOLVED_PERSON_AGE_GROUPS': {
            if ([
                'eventItem',
                'eventAssessmentHistoricalRecord'
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        eventInvolvedPersonAgeGroups: action.data.map((item) => {
                            return { value: parseInt(item.category_id), label: item.category_name }
                        })
                    }
                }
            }
        }
        case 'EVENT_PERSON_INVOLVED_ADD': {
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        eventInvolvedPersons: [
                            ...state.eventItem.eventInvolvedPersons,
                            {
                                person_type_id: state.eventItem['eventInvolvedPerson.person_type_id'],
                                age_group_id: state.eventItem['eventInvolvedPerson.age_group_id'],
                                first_name: state.eventItem['eventInvolvedPerson.first_name'],
                                last_name: state.eventItem['eventInvolvedPerson.last_name'],
                                mrn: state.eventItem['eventInvolvedPerson.mrn'],
                                email: state.eventItem['eventInvolvedPerson.email'],
                                phone: state.eventItem['eventInvolvedPerson.phone'],
                            }
                        ]
                    }
                }
            }
        }
        case 'EVENT_PERSON_INVOLVED_DETAILS': {
            if ([
                'eventItem',
                'eventAssessmentHistoricalRecord'
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        'eventInvolvedPerson.person_type_id': state[action.id].eventInvolvedPersons[action.idx].person_type_id,
                        'eventInvolvedPerson.first_name': state[action.id].eventInvolvedPersons[action.idx].first_name,
                        'eventInvolvedPerson.age_group_id': state[action.id].eventInvolvedPersons[action.idx].age_group_id,
                        'eventInvolvedPerson.last_name': state[action.id].eventInvolvedPersons[action.idx].last_name,
                        'eventInvolvedPerson.mrn': state[action.id].eventInvolvedPersons[action.idx].mrn,
                        'eventInvolvedPerson.email': state[action.id].eventInvolvedPersons[action.idx].email,
                        'eventInvolvedPerson.phone': state[action.id].eventInvolvedPersons[action.idx].phone,
                    }
                }
            }
        }
        case 'EVENT_PERSON_INVOLVED_MODIFY': {
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        eventInvolvedPersons: state.eventItem.eventInvolvedPersons.map((item, idx) => {
                            if (idx !== action.idx) {
                                return item
                            }
                            return {
                                person_type_id: state.eventItem['eventInvolvedPerson.person_type_id'],
                                first_name: state.eventItem['eventInvolvedPerson.first_name'],
                                age_group_id: state.eventItem['eventInvolvedPerson.age_group_id'],
                                last_name: state.eventItem['eventInvolvedPerson.last_name'],
                                mrn: state.eventItem['eventInvolvedPerson.mrn'],
                                email: state.eventItem['eventInvolvedPerson.email'],
                                phone: state.eventItem['eventInvolvedPerson.phone'],
                            }
                        })
                    }
                }
            }
        }
        case 'EVENT_PERSON_INVOLVED_REMOVE': {
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        eventInvolvedPersons: state.eventItem.eventInvolvedPersons.filter(
                            (item, idx) => idx !== action.idx
                        )
                    }
                }
            }
        }
        case 'EVENT_PERSON_INVOLVED_RESET': {
            if ([
                'eventItem',
                'eventAssessmentHistoricalRecord'
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        'eventInvolvedPerson.person_type_id': null,
                        'eventInvolvedPerson.age_group_id': null,
                        'eventInvolvedPerson.first_name': null,
                        'eventInvolvedPerson.last_name': null,
                        'eventInvolvedPerson.mrn': null,
                        'eventInvolvedPerson.email': null,
                        'eventInvolvedPerson.phone': null,
                    }
                }
            }
        }
        case 'EVENT_ADD':
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        id: action.iev_id,
                    }
                };
            }
        case 'EVENT_ASSESSMENT_LIKELIHOODS':
            if (action.id === 'eventAssessment') {
                return {
                    ...state,
                    eventAssessment: {
                        ...state.eventAssessment,
                        likelihoodTypes: action.data.map((item) => {
                            return { value: parseInt(item.category_id), label: item.category_name }
                        })
                    }
                }
            }
        case 'EVENT_ASSESSMENT_SEVERITY_POTENTIALS':
            if (action.id === 'eventAssessment') {
                return {
                    ...state,
                    eventAssessment: {
                        ...state.eventAssessment,
                        severityPotentialTypes: action.data.map((item) => {
                            return { value: parseInt(item.category_id), label: item.category_name }
                        })
                    }
                }
            }
        case 'EVENT_ASSESSMENT':
            if (action.id === 'eventAssessment') {
                if (action.data.length) {
                    let racOfficer = null;
                    let responsiblePerson = null;
                    if (action.data[0].rof_uuid) {
                        racOfficer = {
                            subject_uuid: action.data[0].rof_uuid,
                            first_name: action.data[0].rof_first_name,
                            last_name: action.data[0].rof_last_name
                        };
                    }
                    if (action.data[0].created_by_uuid) {
                        responsiblePerson = {
                            subject_uuid: action.data[0].created_by_uuid,
                            first_name: action.data[0].created_by_first_name,
                            last_name: action.data[0].created_by_last_name
                        };
                    }
                    let qualityManager = null;
                    if (action.data[0].quality_manager_uuid) {
                        qualityManager = {
                            subject_uuid: action.data[0].quality_manager_uuid,
                            first_name: action.data[0].quality_manager_first_name,
                            last_name: action.data[0].quality_manager_last_name
                        };
                    }
                    return {
                        ...state,
                        eventAssessment: {
                            ...state.eventAssessment,
                            saved: action.data[0].assessment_saved === 'true',
                            likelihood_id: action.data[0].likelihood_id,
                            severity_potential_id: action.data[0].severity_potential_id,
                            assessment_score: action.data[0].assessment_score,
                            assessment_color: action.data[0].assessment_color,
                            assessment_action: action.data[0].action,
                            rle_id: action.data[0].rle_id,
                            rac_id: action.data[0].rac_id,
                            rac_officer: racOfficer,
                            racOfficers: racOfficer ? [racOfficer] : [],
                            rac_record: action.data[0].record,
                            rac_cause: action.data[0].root_cause,
                            rac_responses: action.data[0].potential_responses,
                            responsible_person: responsiblePerson,
                            responsiblePersons: responsiblePerson ? [responsiblePerson] : [],
                            assessment_update_date: action.data[0].updated_on,
                            quality_manager: qualityManager,
                            qualityManagers: qualityManager ? [qualityManager] : [],
                            quality_manager_review: action.data[0].ass_review_comment,
                            quality_manager_signature: resolveSignature(action.data[0].quality_manager_signature),
                            quality_manager_review_date: action.data[0].ass_review_date
                        }
                    };
                }
            }
        case 'EVENT_ASSESSMENT_SCORE':
            if (action.id === 'eventAssessment') {
                return {
                    ...state,
                    eventAssessment: {
                        ...state.eventAssessment,
                        rle_id: action.rle_id,
                        assessment_score: action.score,
                        assessment_color: action.assessment_color,
                        assessment_action: action.action,
                    }
                }
            }
        case 'EVENT_ASSESSMENT_HISTORICAL_RECORD':
            if (action.id === 'eventAssessment') {
                const responsiblePerson = {
                    subject_uuid: action.data[1][0].rsp_uuid,
                    first_name: action.data[1][0].rsp_first_name,
                    last_name: action.data[1][0].rsp_last_name
                };
                return {
                    ...state,
                    eventAssessmentHistoricalRecord: {
                        ...state.eventAssessmentHistoricalRecord,
                        id: action.data[0][0].iev_id,
                        location: action.data[0][0].location_uuid,
                        locations: action.data[4],
                        additional_area: action.data[0][0].additional_area,
                        accountId: action.data[0][0].account_id,
                        visualId: action.data[0][0].print_event_id,
                        risk_name: action.data[0][0].risk_name,
                        risk_code: action.data[0][0].risk_code,
                        event: action.data[0][0].event,
                        event_type: action.data[0][0].event_id,
                        event_code: action.data[0][0].event_code,
                        event_subtype: action.data[0][0].type_id,
                        date: moment(action.data[0][0].event_date, DATE_FORMAT.OUTPUT_TIME).toDate(),
                        event_category: action.data[0][0].category_id,
                        event_subcategory: action.data[0][0].sub_category_id,
                        consequence_level: action.data[0][0].consequence_level_id,
                        likelihood_estimate: action.data[0][0].likelihood_estimation_id,
                        risk_rating: action.data[0][0].risk_rating,
                        gallery: action.data[3].length ? action.data[3].reduce((result, item) => {
                            if (item.type === 'image') {
                                result.push({
                                    id: item.fil_id,
                                    url: API_BASE + '/img/' + localStorage.myrsoAuthToken + item.url,
                                    width: item.width,
                                    height: item.height
                                });
                            }
                            return result;
                        }, []) : [],
                        image_urls: action.data[3].length ? action.data[3].reduce((result, item) => {
                            if (item.type === 'image') {
                                result.push({
                                    url: item.url,
                                    description: '',
                                    width: item.width,
                                    height: item.height
                                });
                            }
                            return result;
                        }, []) : [],
                        document_urls: action.data[3].length ? action.data[3].reduce((result, item) => {
                            if (item.type === 'doc') {
                                result.push({
                                    url: item.url,
                                    description: ''
                                });
                            }
                            return result;
                        }, []) : [],
                        responsible_person: responsiblePerson,
                        responsiblePersons: [responsiblePerson],
                        event_location: action.data[0][0].event_location_code,
                        event_location_desc: action.data[0][0].other_location,
                        event_harm: parseInt(action.data[0][0].did_incident_result_in_harm),
                        event_details: action.data[0][0].details,
                        eventInvolvedPersons: action.data[2].length ? action.data[2].map((item) => {
                            return {
                                person_type_id: parseInt(item.ivp_person_type_id),
                                age_group_id: parseInt(item.age_group_id),
                                first_name: item.ivp_first_name,
                                last_name: item.ivp_last_name,
                                mrn: item.ivp_medical_number,
                                email: item.ivp_email,
                                phone: item.ivp_phone,
                            };
                        }) : [],
                        signature: resolveSignature(action.data[0][0].signature),
                        signature_date: moment(action.data[0][0].signature_date, DATE_FORMAT.OUTPUT_TIME).toDate(),
                    }
                }
            }
        case 'LOCATION_SELECTED':
            if (action.id === 'eventItem') {
                let locations = [];
                switch (action.type_code) {
                    case 'hof':
                        locations.push({sub_uuid: action.uuid, name: action.locationName, type_code: 'hof'});
                        break;
                    case 'slo':
                        locations = state.eventItem.locations.reduce((result, item) => {
                            if (item.type_code === 'hof') result.push(item);
                            return result;
                        }, []);
                        locations.push({sub_uuid: action.uuid, name: action.locationName, type_code: 'slo'});
                        break;
                    case 'dep':
                        locations = state.eventItem.locations.reduce((result, item) => {
                            if (item.type_code === 'hof') result.push(item);
                            if (item.type_code === 'slo') result.push(item);
                            return result;
                        }, []);
                        locations.push({sub_uuid: action.uuid, name: action.locationName, type_code: 'dep'});
                        break;
                    case 'rom':
                        locations = state.eventItem.locations.reduce((result, item) => {
                            if (item.type_code !== 'rom') result.push(item);
                            return result;
                        }, []);
                        locations.push({sub_uuid: action.uuid, name: action.locationName, type_code: 'rom'});
                        break;
                }
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        location: action.uuid,
                        locationName: action.locationName,
                        locationCode: action.type_code,
                        locationUuid: action.uuid,
                        accountId: action.accountId,
                        locations,
                    }
                }
            }
        case 'INPUT_TEXT_CHANGE':
        case 'INPUT_RADIO_CHANGE':
        case 'DATE_SELECTED':
        case 'CHECKBOX_SELECTED':
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        ...action.data
                    }
                };
            }
            if (action.id === 'eventAlertEscalate') {
                return {
                    ...state,
                    eventAlertEscalate: {
                        ...state.eventAlertEscalate,
                        ...action.data
                    }
                };
            }
            if (action.id === 'eventActionPlan') {
                return {
                    ...state,
                    eventActionPlan: {
                        ...state.eventActionPlan,
                        ...action.data
                    }
                };
            }
            if ([
                'eventsGrid',
                'eventsActionPlanLibraryGrid',
                'eventsActionTaskLibraryGrid'
            ].includes(action.id)) {
                return {
                    ...state,
                    [action.id]: set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)
                };
            }
        case 'INPUT_SELECT':
        case 'INPUT_EMAIL_CHANGE':
        case 'INPUT_TEXT_AREA_CHANGE':
        case 'INPUT_SIGNATURE':
            if (action.id === 'eventItem') {
                const eventItemProps = {};
                if (action.path === 'event_type') {
                    eventItemProps.event_code = action.option.code;
                }
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        [action.path]: action.input,
                        ...eventItemProps
                    }
                }
            }
            if (action.id === 'eventAssessment') {
                return {
                    ...state,
                    eventAssessment: {
                        ...state.eventAssessment,
                        [action.path]: action.input
                    }
                }
            }
            if (action.id === 'eventAlertEscalate') {
                return {
                    ...state,
                    eventAlertEscalate: {
                        ...state.eventAlertEscalate,
                        [action.path]: action.input
                    }
                }
            }
            if (action.id === 'eventActionPlan') {
                return {
                    ...state,
                    eventActionPlan: {
                        ...state.eventActionPlan,
                        [action.path]: action.input
                    }
                }
            }
            if (action.id === 'eventsGrid') {
                return {
                    ...state,
                    [action.id]: set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)
                }
            }
        case 'AUTOCOMPLETE_SELECTED':
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        [action.path]: action.item,
                    }
                };
            }
            if (action.id === 'eventAssessment') {
                return {
                    ...state,
                    eventAssessment: {
                        ...state.eventAssessment,
                        [action.path]: action.item
                    }
                };
            }
            if (action.id === 'eventAlertEscalate') {
                return {
                    ...state,
                    eventAlertEscalate: {
                        ...state.eventAlertEscalate,
                        [action.path]: action.item,
                        receiver_uuid: action.item ? action.item.subject_uuid : null,
                        receiver_first_name: action.item ? action.item.first_name : null,
                        receiver_last_name: action.item ? action.item.last_name : null,
                        receiver_email: action.item ? action.item.email : null,
                    }
                };
            }
            if (action.id === 'eventActionPlan') {
                return {
                    ...state,
                    eventActionPlan: {
                        ...state.eventActionPlan,
                        [action.path]: action.item,
                    }
                };
            }
        case 'EVENT_RESET':
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {...initialState.eventItem},
                    eventAssessment: {...initialState.eventAssessment},
                    eventAssessmentHistoricalRecord: {...initialState.eventAssessmentHistoricalRecord},
                    eventActionPlan: {...initialState.eventActionPlan}
                };
            }
        case 'FILE_UPLOAD': {
            if (action.id === 'eventItemGallery') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        gallery: [...state.eventItem.gallery, {
                            id: parseInt(action.itemId),
                            url: API_BASE + '/img/' + localStorage.myrsoAuthToken + action.input,
                            width: action.width,
                            height: action.height
                        }],
                        image_urls: [...state.eventItem.image_urls, {
                            url: action.input,
                            description: '',
                            width: action.width,
                            height: action.height
                        }]
                    }
                }
            }
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        document_urls: [...state.eventItem.document_urls, {
                            url: action.input,
                            description: ''
                        }]
                    }
                }
            }
        }
        case 'INPUT_DOCUMENTS_REMOVE':
            if (action.id === 'eventItem') {
                return {
                    ...state,
                    eventItem: {
                        ...state.eventItem,
                        [action.statePath]: state.eventItem[action.statePath].filter(
                            (item, idx) => idx !== action.idx
                        )
                    }
                }
            }
        case 'EVENT_ASSESSMENT_SAVE':
        case 'EVENT_ASSESSMENT_RESPONSIBLE_OFFICER':
        case 'EVENT_ASSESSMENT_RESPONSIBLE_PERSONS':
        case 'EVENT_ASSESSMENT_INCIDENT_PERSON_TYPES':
        case 'EVENT_ASSESSMENT_INCIDENT_PERSON_TYPE_SET':
        case 'EVENT_ASSESSMENT_INCIDENT_CODES':
        case 'EVENT_ASSESSMENT_INCIDENT_SAVE':
        case 'EVENT_ASSESSMENT_INCIDENT_DETAILS':
        case 'EVENT_ASSESSMENT_IMPACTS':
        case 'EVENT_ASSESSMENT_IMPACT_CREATE':
        case 'EVENT_ASSESSMENT_IMPACT_EDIT':
        case 'EVENT_ASSESSMENT_IMPACT_REMOVE':
        case 'EVENT_ASSESSMENT_IMPACT_RESET':
        case 'EVENT_ASSESSMENT_ISSUE_CODES':
        case 'EVENT_ASSESSMENT_ISSUE_DETAILS':
        case 'EVENT_ASSESSMENT_QUALITY_MANAGERS':
        case 'EVENT_ASSESSMENT_REVIEW_SAVE':
            return {
                ...state,
                eventAssessment: eventsAssessmentReducer(state.eventAssessment, action)
            };
        case 'EVENT_ACTION_PLAN_DETAILS':
        case 'EVENT_ACTION_PLAN_LIST':
        case 'EVENT_ACTION_PLAN_LIST_SELECT':
        case 'EVENT_ACTION_PLAN_LIST_RESET':
        case 'EVENT_ACTION_PLAN_LIBRARY_DETAILS':
        case 'EVENT_ACTION_PLAN_LIBRARY_RESET':
        case 'EVENT_ACTION_TASK_LIST':
        case 'EVENT_ACTION_TASK_LIST_SELECT':
        case 'EVENT_ACTION_TASK_LIST_RESET':
        case 'EVENT_ACTION_TASK_ASSIGN':
        case 'EVENT_ACTION_TASK_UPDATE':
        case 'EVENT_ACTION_TASK_REMOVE':
        case 'EVENT_ACTION_TASK_RESET':
        case 'EVENT_ACTION_TASK_DETAILS':
        case 'EVENT_ACTION_TASK_DETAILS_RESET':
        case 'EVENT_ACTION_TASK_STATUS_LIST':
        case 'EVENT_ACTION_TASK_LIBRARY_DETAILS':
        case 'EVENT_ACTION_TASK_LIBRARY_RESET':
            return {
                ...state,
                eventActionPlan: eventsActionPlanReducer(state.eventActionPlan, action)
            };
        case 'EVENT_ALERT_EMPLOYEES':
        case 'EVENT_RECEIVER_ADD':
        case 'EVENT_RECEIVER_REMOVE':
        case 'EVENT_RECEIVER_RESET':
        case 'EVENT_NOTIFICATION_DETAILS':
        case 'EVENT_NOTIFICATION_RESET':
            return {
                ...state,
                eventAlertEscalate: eventsAlertEscalateReducer(state.eventAlertEscalate, action)
            };
        default:
            return state;
    }
};
