/** @module root/components/administration/settings */

import * as STATES                  from './adm-categorisation.states';

import { admCatCenters }                from './centers/adm-cat-centers.component'
import { admCatCentersGrid }            from './centers/grid/adm-cat-centers-grid.component'
import { admCatCenterDetails }          from './centers/details/adm-cat-center-details.component'

import { admCatCenterLicenses }         from './centers/licenses/adm-cat-center-licenses.component'
import { admCatCenterLicensesGrid }     from './centers/licenses/adm-cat-center-licenses-grid.component'
import { admCatCenterLicenseDetails }   from './centers/licenses/adm-cat-center-license-details.component'

import { admCatDocuments }              from './documents/adm-cat-documents.component'
import { admCatDocumentsGrid }          from './documents/grid/adm-cat-documents-grid.component'
import { admCatDocumentDetails }        from './documents/details/adm-cat-document-details.component'

import { admCatOccupations }            from './occupations/adm-cat-occupations.component'
import { admCatOccupationsGrid }        from './occupations/grid/adm-cat-occupations-grid.component'
import { admCatOccupationDetails }      from './occupations/details/adm-cat-occupatio-details.component'


export const CATEGORISATION_MODULE = angular.module(
    'migration.administration.categorisation',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values(STATES).map(
        state => stateRegistry.register(state)
    );
}]).component({
    admCatCenters,
    admCatCentersGrid,
    admCatCenterDetails,
    
    admCatCenterLicenses,
    admCatCenterLicensesGrid,
    admCatCenterLicenseDetails,

    admCatDocuments,
    admCatDocumentsGrid,
    admCatDocumentDetails,
    
    admCatOccupations,
    admCatOccupationsGrid,
    admCatOccupationDetails,

}).run(['$templateCache', ({ put }) => {
    put('app.administration.categorisation.centers.grid',           '<adm-cat-centers></adm-cat-centers>');
    put('app.administration.categorisation.centers.details',        '<adm-cat-center-details></adm-cat-center-details>');
    
    put('app.administration.categorisation.documents.grid',         '<adm-cat-documents></adm-cat-documents>');
    put('app.administration.categorisation.documents.details',      '<adm-cat-center-details></adm-cat-center-details>');
    
    put('app.administration.categorisation.occupations.grid',       '<adm-cat-occupations></adm-cat-occupations>');
    put('app.administration.categorisation.occupation.details',     '<adm-cat-center-details></adm-cat-center-details>');
    
    put('./adm-cat-centers-grid-filter.html',       require('./centers/grid/adm-cat-centers-grid-filter.html'));
    put('./adm-cat-documents-grid-filter.html',     require('./documents/grid/adm-cat-documents-grid-filter.html'));
    put('./adm-cat-occupations-grid-filter.html',   require('./occupations/grid/adm-cat-occupations-grid-filter.html'));
}]);
