import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';
import { getDocuments } from '../../rm-test.actions';

class RadioactiveMaterialsTestDocumentsGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$filter = $filter;
    }

    $onInit() {
        this.module = 'radioactiveMaterials';
        this.identifier = 'rmTestDocumentsGrid';
        this.filterTemplate = './rm-test-documents-grid-filter.html';
        this.autoload = true;
        this.exportComponent = false;

        this.columns = [
            {title: '#',        mapper: null},
            {title: 'Type',     mapper: 'documentType'},
            {title: 'Date', mapper: 'dueDate'},
            {title: 'Document', mapper: 'file-download'},
            {title: 'Status',   mapper: 'status'}
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this),
            { getDocuments }
        )(this);

        this.dataAction = this.getDocuments;
        this.filter.identifier = this.identifier;
        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const rmTestDocumentsGrid = {
    bindings: {
        title: '@',
        onRemove: '&'
    },
    controller: RadioactiveMaterialsTestDocumentsGridController,
    ...gridTpl
};
