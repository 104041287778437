import { getDocumentTypes, getDocumentStatusList } from '../../radioactive-materials.actions';
import { saveDocument, invalidateDocument } from '../rm-test.actions';

/**
 * @const {object}
 * @memberOf module:RadioactiveMaterials
 */

export const rmTestDocuments = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $mdDialog, $rootScope, NavigationService, Notifier, EquipmentService) {
        const unsubscribe = $ngRedux.connect((state) => ({
            rmTestId: state.radioactiveMaterials.rmTest.id,
            rmTestDocument: state.radioactiveMaterials.rmTestDocument
        }), {
            getDocumentTypes,
            getDocumentStatusList,
            saveDocument,
            invalidateDocument
        })(this);

        /**
         * @function $onInit
         */
        this.$onInit = () => {
            const navConfig = NavigationService.getConfig($state.$current);
            this.colorName = navConfig.colorName;
            this.icon = navConfig.icon;

            if (this.rmTestId) {
                this.identifier = 'app.radioactiveMaterials.test.manage';
                this.state = 'app.radioactiveMaterials.test.manage.documents';
            }
            else {
                this.identifier = 'app.radioactiveMaterials.test.add';
                this.state = 'app.radioactiveMaterials.test.add.documents';
            }

            this.getDocumentTypes('srl', this.rmTestId);
            this.getDocumentStatusList();
        }

        this.onSave = (event) => {
            Notifier.info('Please wait...');
            this.saveDocument(event, this.rmTestDocument, this.rmTestId).then(() => {
                this.rmTestDocumentForm.$setPristine();
                this.rmTestDocumentForm.$setUntouched();
                Notifier.success('Import completed!');
            }).catch(() => {
                Notifier.error('System Error!');
            })
        }

        this.onDocumentType = (event) => {
            switch (event.item.item.documentTypeCode) {
                case 'src':
                    this.dateLabel = 'Certificate date';
                    break;
                case 'clc':
                    this.dateLabel = 'Certificate Due date';
                    break;
                case 'srr':
                    this.dateLabel = 'Date of service';
                    break;
                case 'prc':
                    this.dateLabel = 'Date of purchase';
                    break;
                case 'ddc':
                    this.dateLabel = 'Date of disposal';
                    break;
                default:
                    this.dateLabel = null;
            }
        }

        this.onRemove = (event) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen document will be removed permanently.')
                .ariaLabel('Remove test')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.invalidateDocument(event.item.id).then(() => {
                    $ngRedux.dispatch({
                        type: 'GRID_RELOAD',
                        id: 'rmTestDocumentsGrid'
                    });
                    Notifier.success('Success');
                }, (error) => {
                    Notifier.responseMessage(error);
                });
            }, angular.noop);
        };

        this.$onDestroy = () => {
            unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./rm-test-documents.html')
};
