/* @ngInject */
export function ProtectiveEquipmentAddController($controller, $ngRedux, $scope, $rootScope, $q, $timeout, Notifier, ProtectiveEquipmentService, ProtectiveEquipmentTestService, ProtectiveEquipmentManager, EquipmentManager, PersonalEquipment, PersonalEquipmentTest, PersonalEquipmentTestComment, Grid, FileImage, FileDocument) {

    angular.extend(this, $controller('ProtectiveEquipmentBaseController', {
        $ngRedux: $ngRedux,
        $scope: $scope,
        $rootScope: $rootScope,
        $q: $q,
        $timeout: $timeout,
        Notifier: Notifier,
        ProtectiveEquipmentService: ProtectiveEquipmentService,
        ProtectiveEquipmentTestService: ProtectiveEquipmentTestService,
        ProtectiveEquipmentManager: ProtectiveEquipmentManager,
        EquipmentManager: EquipmentManager,
        PersonalEquipment: PersonalEquipment,
        PersonalEquipmentTest: PersonalEquipmentTest,
        PersonalEquipmentTestComment: PersonalEquipmentTestComment,
        Grid: Grid,
        FileImage: FileImage,
        FileDocument: FileDocument
    }));

    $scope.MODULE_STATE = $scope.MODULE_STATE_CREATE;
    $scope.EQUIPMENT_STATE = $scope.MODULE_STATE_CREATE;

    $scope.tabs = _.cloneDeep(ProtectiveEquipmentManager.tabs);
    $scope.tabs[ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_DETAILS].disabled = false;
    $scope.tabs.shift();

    _.extend(_.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_DETAILS]), {
        exec: function() {
            var deferred = $q.defer();
            switch ($scope.EQUIPMENT_STATE) {
                case $scope.MODULE_STATE_CREATE:
                    ProtectiveEquipmentService.addPersonalEquipment($scope.protectiveEquipment).then(function(id) {
                        $scope.protectiveEquipment.id = id;
                        ProtectiveEquipmentTestService.getComments($scope.protectiveEquipment.id).then(function(data) {
                            $scope.equipmentTestCommentList = data;
                        });
                        $scope.EQUIPMENT_STATE = $scope.MODULE_STATE_UPDATE;
                        $ngRedux.dispatch({
                            type: 'GRID_FILTER',
                            id: 'protectiveEquipmentTestsGrid',
                            equipmentId: $scope.protectiveEquipment.id
                        });
                        Notifier.success('Equipment was successfully added');
                        _.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_CREATE]).disabled = false;
                        _.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_HISTORY]).disabled = false;
                        deferred.resolve();
                    }, function(error) {
                        deferred.reject(error);
                    });
                    break;
                case $scope.MODULE_STATE_UPDATE:
                    ProtectiveEquipmentService.updatePersonalEquipment($scope.protectiveEquipment).then(function() {
                        Notifier.success('Equipment details successfully updated');
                        deferred.resolve();
                    });
                    break;
            }
            return deferred.promise;
        },
        post: function() {
            var deferred = $q.defer();
            ProtectiveEquipmentTestService.getTesterCandidates($scope.protectiveEquipment.id).then(function(data) {
                $scope.equipmentTesters = data;
                deferred.resolve();
            }, function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_CREATE]), {
        exec: function() {
            var deferred = $q.defer();
            if ($scope.equipmentTest.comments.length > 0) {
                ProtectiveEquipmentTestService.addPersonalEquipmentTest($scope.protectiveEquipment.id, $scope.equipmentTest).then(function(id) {
                    $scope.equipmentTest.id = id;
                    $scope.protectiveEquipment.addTest($scope.equipmentTest);
                    $scope.equipmentTest = new PersonalEquipmentTest();
                    Notifier.success('New test was successfully added');
                    deferred.resolve();
                }, function(error) {
                    deferred.reject(error);
                });
            } else {
                Notifier.error('First assign comment.');
            }
            return deferred.promise;
        },
        post: function() {
            var deferred = $q.defer();
            $ngRedux.dispatch({
                type: 'GRID_RESET',
                id: 'protectiveEquipmentTestsGrid'
            });
            deferred.resolve();
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TESTS]), {
        exec: function() {
            var deferred = $q.defer();
            ProtectiveEquipmentTestService.getPersonalEquipmentTestDetails($scope.equipmentTest.id).then(function(test) {
                $scope.equipmentTest = test;
                _.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS]).disabled = false;
                _.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS]).visible = true;
                deferred.resolve();
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS]), {
        exec: function() {
            var deferred = $q.defer();
            ProtectiveEquipmentTestService.updatePersonalEquipmentTest($scope.equipmentTest).then(function() {
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'protectiveEquipmentTestsGrid'
                });
                Notifier.success('Test was successfully updated');
                deferred.resolve(ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS);
            }, function(error) {
                Notifier.responseMessage(error);
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_HISTORY]), {
        exec: function() {
            var deferred = $q.defer();
            deferred.resolve(ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_HISTORY_DETAILS);
            return deferred.promise;
        }
    });
}
