import { GridController, gridTpl } from '../../../../common/grid/grid.controller';

/**
 * Creates a new AdministrationMonthlyReportSettingsGridController.
 * @class
 * @memberOf module:root/legacy/components/administration
 */
class AdministrationMonthlyReportSettingsGridController extends GridController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param NavigationService {service} {@link module:root/common.NavigationService|NavigationService}
     * @param AdministrationMonthlyReportSettingsActions {service}
     */
    constructor($ngRedux, $state, NavigationService, AdministrationMonthlyReportSettingsActions) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.actions = AdministrationMonthlyReportSettingsActions;
    }

    /**
     * @method $onInit
     * @memberof module:root/legacy/components/administration.AdministrationMonthlyReportSettingsGridController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.module = 'administration';
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                            mapper: null},
            {title: 'Location',                     mapper: 'loc_name'},
            {title: 'Generate Corporate Report',    mapper: 'generate_corporate_report'},
            {title: 'Generate By Sites Report',     mapper: 'generate_by_sits_report'},
            {title: 'Generate Corporate Report To', mapper: 'generate_corporate_report_to'},
            {title: 'Generate By Sites Report To',  mapper: 'generate_by_sits_report_to'}
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.actions)(this);
        this.dataAction = this.getMonthlyReportSettings;

        this.init();
    }

    /**
     * @method $onDestroy
     * @memberof module:root/components/administration.AdministrationMonthlyReportSettingsGridController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.destroy();
    };
}

AdministrationMonthlyReportSettingsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'AdministrationMonthlyReportSettingsActions'];

export const administrationMonthlyReportSettingsGrid = {
    bindings: {
        identifier: '@',
        title: '@',
        onSelect: '&'
    },
    controller: AdministrationMonthlyReportSettingsGridController,
    ...gridTpl
};
