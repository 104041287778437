/* @ngInject */
export function EquipmentGridFilterFactory(GridFilter) {
    function EquipmentFilter() {

        GridFilter.call(this);

        this.equipment_id = null;
        this.type = null;
        this.manufacturer = null;
        this.account = null;
        this.department = null;
        this.room = null;
        this.category = null;
        this.rdt = null;
        this.rdf = null;
        this.cedf = null;
        this.cedt = null;
        this.mode = null;

        this.reset = function() {
            this.equipment_id = null;
            this.manufacturer = null;
            this.type = null;
            this.account = null;
            this.department = null;
            this.room = null;
            this.category = null;
            this.rdt = null;
            this.rdf = null;
            this.cedf = null;
            this.cedt = null;
            this.mode = null;
            GridFilter.prototype.reset();
        };
    };

    EquipmentFilter.prototype = Object.create(GridFilter.prototype);

    return EquipmentFilter;
}
