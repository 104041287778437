export const selectEquipmentType = {
    bindings: {
        module: '@',
        identifier: '@',
        name: '@',
        validate: '<',
        required: '<'
    },
    require : {
        form : '^form'
    },
    template: require('./select-equipment-type.html'),
    controller: 'SelectEquipmentTypeController'
};
