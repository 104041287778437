export const personalDosimetryReportsState = {
    name: 'app.personalDosimetry.reports',
    redirectTo: 'app.personalDosimetry.reports.listing',
    url: '/reports',
    component: 'tabs'
};

export const personalDosimetryReportsListingState = {
    name: 'app.personalDosimetry.reports.listing',
    url: '/listing',
    component: 'personalDosimetryReports',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: 'TAB_DISABLE',
            id: 'app.personalDosimetry.reports',
            state: ['app.personalDosimetry.reports.details']
        });
        $ngRedux.dispatch({
            type: 'GRID_RESET_FILTER',
            id: 'personalDosimetryReportsDetailsGrid'
        });
        $ngRedux.dispatch({
            type: 'GRID_RESET_FILTER',
            id: 'personalDosimetryReportsGrid'
        });
    }
};
personalDosimetryReportsListingState.onEnter.$inject = ['$ngRedux'];

export const personalDosimetryReportsDetailsState = {
    name: 'app.personalDosimetry.reports.details',
    url: '/details?id',
    component: 'personalDosimetryReportsDetails'
};

export const personalDosimetryReportsDetailsResultState = {
    name: 'app.personalDosimetry.reports.detailsResult',
    url: '/result?id&measurement',
    component: 'personalDosimetryReportsDetailsResult',
    onExit: $ngRedux => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.personalDosimetry.reports',
            state: ['app.personalDosimetry.reports.detailsResult']
        });
    }
};
personalDosimetryReportsDetailsResultState.onExit.$inject = ['$ngRedux'];
