import { TabsReducer } from '../../../../common/tabs/tabs.reducer';
import {IDS} from '../import-action-id.constants';
export const EmpImportNewTabs = {
    [IDS.app_employees_import_new]: Object.assign(TabsReducer(), {
        tabs: [{
            state: IDS.app_employees_import_new_import,
            title: 'New Import',
            visible: true,
            disabled: false,
            loading: false
        },
        {
            state: IDS.app_employees_import_new_result,
            title: 'Import Result',
            visible: false,
            disabled: false,
            loading: false,
            buttons: {
                execute: {label: 'Send Notifications'}
            }
        }]
    }),
};
