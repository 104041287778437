import watch from 'redux-watch';

/* @ngInject */
export function SelectManufacturerTypeController($ngRedux, ManufacturerTypeActions) {
    var ctrl = this;
    var unsubscribe = angular.noop;
    var onManufacturerChange = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, ManufacturerTypeActions)(ctrl);
        var watchManufacturer = watch($ngRedux.getState, 'manufacturers.manufacturerTypesSelected');
		onManufacturerChange = $ngRedux.subscribe(watchManufacturer(function(newValue) {
            ctrl.selectedTypes = _.slice(newValue);
		}));
        ctrl.selectedTypes = ctrl.manufacturerTypesSelected;
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onManufacturerChange();
    };

    ctrl.onSelectTypes = function() {
        $ngRedux.dispatch({
            type: 'MANUFACTURER_TYPES_SELECT',
            id: 'app.administration.manufacturers',
            manufacturerTypesSelected: _.isUndefined(ctrl.selectedTypes) ? [] : ctrl.selectedTypes,
        });
    };

    function mapStateToThis(state) {
        return {
            types: state.manufacturers.manufacturerTypes,
            manufacturer: state.manufacturers.manufacturer,
            manufacturerTypesSelected: state.manufacturers.manufacturerTypesSelected,
        }
    }

}
