/**
 * @const ST_VIDEOS
 * @type {string}
 */
export const ST_VIDEOS = 'ST_VIDEOS';

/**
 * @const ST_VIDEO_PROGRESS
 * @type {string}
 */
export const ST_VIDEO_PROGRESS = 'ST_VIDEO_PROGRESS';

/**
 * @const ST_CANDIDATE_REVIEW
 * @type {string}
 */
export const ST_CANDIDATE_REVIEW = 'ST_CANDIDATE_REVIEW';

/**
 * @const ST_REQUEST_TOKEN
 * @type {string}
 */
export const ST_REQUEST_TOKEN = 'ST_REQUEST_TOKEN';

// /**
//  * @const ST_UPDATE_CANDIDATE_REVIEW
//  * @type {string}
//  */
// export const ST_UPDATE_CANDIDATE_REVIEW = 'ST_UPDATE_CANDIDATE_REVIEW';
