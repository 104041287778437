import { product } from '../../bootstrap.json';
import { DATE_FORMAT } from '../../common/utils/util.constants';
import { formatDate } from '../../common/utils/util.date';
import find from 'lodash/find';
import isObject from 'lodash/isObject';
import API from '../../common/http/api.fetch';
import { saveAs } from 'file-saver';

export const getEvents = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: `/events`,
            method: 'GET',
            params: {
                risk_code: filter.code,
                event_id: filter.event_id,
                type_id: filter.type_id,
                category_id: filter.category_id,
                subcategory_id: filter.subcategory_id,
                date_from: filter.date_from ? formatDate(filter.date_from, DATE_FORMAT.OUTPUT) : null,
                date_to: filter.date_to ? formatDate(filter.date_to, DATE_FORMAT.OUTPUT) : null,
                offset: filter.offset,
                limit: filter.limit || -1,
                exportFormat: filter.exportFormat,
                exportColumns: filter.exportColumns
            }
        }).then(response => {
            if (filter.exportFormat) return response.data;
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: response.data.items,
                itemsCount: parseInt(response.data.items_count)
            })
        });
    }
};

export const getEventTypes = (id, risk_code) => {
  return (dispatch) => {
      return API.fetch({
          url: `/events/types`,
          method: 'GET',
          params: { risk_code }
      }).then(response => {
          dispatch({
              id,
              type: 'EVENT_TYPES',
              data: response.data,
          })
      });
  }
};

export const getEventSubtypes = (id, risk_code, event_id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/events/categories`,
            method: 'GET',
            params: { risk_code, event_id }
        }).then(response => {
            dispatch({
                id,
                type: 'EVENT_SUBTYPES',
                data: response.data,
            })
        });
    }
};

export const getEventCategories = (id, risk_code, event_id, type_id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/events/categories`,
            method: 'GET',
            params: {
                risk_code,
                event_id,
                type_id,
            }
        }).then(response => {
            dispatch({
                id,
                type: 'EVENT_CATEGORIES',
                data: response.data,
            })
        });
    }
};

export const getEventSubcategories = (id, risk_code, event_id, type_id, category_id) => {
    return (dispatch) => {
        return API.fetch({
                url: `/events/categories`,
                method: 'GET',
                params: {
                    risk_code,
                    event_id,
                    type_id,
                    category_id,
                }
        }).then(response => {
            dispatch({
                id,
                type: 'EVENT_SUBCATEGORIES',
                data: response.data,
            })
        });
    }
};

export const addEventCategory = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: `/events/categories/add`,
            method: 'POST',
            data
        }).then(({ data }) => {
            dispatch({
                id: 'eventItem',
                type: 'EVENT_CATEGORY',
                data: isObject(data.params) ? data.params : JSON.parse(data.params),
            });
        });
    }
};

export const addEventSubcategory = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: `/events/categories/add`,
            method: 'POST',
            data
        }).then(({ data }) => {
            dispatch({
                id: 'eventItem',
                type: 'EVENT_SUBCATEGORY',
                data: isObject(data.params) ? data.params : JSON.parse(data.params),
            });
        });
    }
};

export const getConsequenceLevels = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/events/categories/miscellaneous`,
            method: 'GET',
            params: { category_code: 'svp' }
        }).then(response => {
            dispatch({
                id,
                type: 'EVENT_CONSEQUENCE_LEVELS',
                data: response.data,
            })
        });
    }
};

export const getLikelihoodEstimations = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/events/categories/miscellaneous`,
            method: 'GET',
            params: { category_code: 'lhe' }
        }).then(response => {
            dispatch({
                id,
                type: 'EVENT_LIKELIHOOD_ESTIMATIONS',
                data: response.data,
            })
        });
    }
};

export const getRiskRating = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: `/event/risk-rating`,
            method: 'POST',
            data,
        }).then(response => {
            dispatch({
                id: 'eventItem',
                type: 'RISK_RATING',
                data: response.data,
            })
        });
    }
};

export const getEventLocations = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/events/categories/miscellaneous`,
            method: 'GET',
            params: { category_code: 'ilt' }
        }).then(response => {
            dispatch({
                id,
                type: 'EVENT_LOCATIONS',
                data: response.data,
            })
        });
    }
};

export const getEventDetails = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/event`,
            method: 'GET',
            params: { id }
        }).then(response => {
            dispatch({
                id: 'eventItem',
                type: 'EVENT_DETAILS',
                data: response.data,
            })
        });
    }
};

export const getEventReport = (id, evtId) => {
    return () => {
        return API.fetch({
            url: `/event`,
            method: 'GET',
            responseType: 'arraybuffer',
            params: { id, exportFormat: 'pdf' }
        }).then(({ data }) => {
            const doc = `${product}-risk-incident-report-${evtId}.pdf`;
            saveAs(new Blob([data], { type: 'application/pdf' }), doc);
        });
    }
};

export const addEvent = (type) => {
    return (dispatch, getState) => {
        const state = getState();
        const { eventItem } = state.events;
        return API.fetch({
            url: `/event/add`,
            method: 'POST',
            data: {
                type: type,
                event_type: eventItem.event_type,
                event_subtype: eventItem.event_subtype,
                event_date: formatDate(eventItem.date, DATE_FORMAT.OUTPUT),
                category_id: eventItem.event_category,
                sub_category_id: eventItem.event_subcategory,
                hof_uuid: eventItem.location,
                additional_area: eventItem.additional_area,
                did_incident_result_in_harm: eventItem.event_harm,
                details: eventItem.event_details,
                incident_location_id: find(eventItem.eventLocations, ['value', eventItem.event_location]).id,
                other_location: eventItem.event_location_desc,
                responsible_persons_uuids: [eventItem.responsible_person.subject_uuid],
                involved_persons: eventItem.eventInvolvedPersons,
                image_urls: eventItem.image_urls,
                document_urls: eventItem.document_urls,
                signature: eventItem.signature,
                signature_date: formatDate(eventItem.signature_date, DATE_FORMAT.OUTPUT),
            }
        }).then(({ data }) => {
            const { iev_id } = isObject(data.params) ? data.params : JSON.parse(data.params);
            dispatch({
                id: 'eventItem',
                type: 'EVENT_ADD',
                iev_id,
            });
            return data;
        });
    }
};

export const updateEvent = (id, type, gridId) => {
    return (dispatch, getState) => {
        const state = getState();
        const { eventItem } = state.events;
        return API.fetch({
            url: `/event/update`,
            method: 'POST',
            data: {
                id,
                type: type,
                event_type: eventItem.event_type,
                event_subtype: eventItem.event_subtype,
                event_date: formatDate(eventItem.date, DATE_FORMAT.OUTPUT),
                category_id: eventItem.event_category,
                sub_category_id: eventItem.event_subcategory,
                hof_uuid: eventItem.location,
                additional_area: eventItem.additional_area,
                did_incident_result_in_harm: eventItem.event_harm,
                details: eventItem.event_details,
                incident_location_id: find(eventItem.eventLocations, ['value', eventItem.event_location]).id,
                other_location: eventItem.event_location_desc,
                responsible_persons_uuids: [eventItem.responsible_person.subject_uuid],
                involved_persons: eventItem.eventInvolvedPersons,
                image_urls: eventItem.image_urls,
                document_urls: eventItem.document_urls,
                signature: eventItem.signature,
                signature_date: formatDate(eventItem.signature_date, DATE_FORMAT.OUTPUT),
            }
        }).then(response => {
            dispatch({
                id: 'eventItem',
                type: 'EVENT_UPDATE',
                data: response.data,
            })
            dispatch({
                type: 'GRID_RELOAD',
                id: gridId
            })
        });
    }
};

export const removeEvent = (id, identifier) => {
    return (dispatch) => {
        return API.fetch({
            url: `/event/delete`,
            method: 'POST',
            data: { id }
        }).then(response => {
            dispatch({
                id: 'eventItem',
                type: 'EVENT_REMOVE',
                eventId: id
            })
            dispatch({
                type: 'GRID_RELOAD',
                id: identifier
            })
        });
    }
};

export const getResponsiblePersonDetails = (uuid) => {
    return (dispatch) => {
        return API.fetch({
            url: `/employee/${uuid}`,
            method: 'GET',
        }).then(response => {
            dispatch({
                id: 'eventItem',
                type: 'EVENT_RESPONSIBLE_PERSON_DETAILS',
                data: response.data,
            });
        });
    }
};

export const getResponsiblePersons = (keyword, location = undefined) => {
    return (dispatch) => {
        return API.fetch({
            url: `/employees`,
            method: 'GET',
            params: {
                keyword,
                status: 'act',
                licences: false,
                offset: 0,
                limit: 20,
                location,
            }
        }).then(response => {
            dispatch({
                id: 'eventItem',
                type: 'EVENT_RESPONSIBLE_PERSONS',
                data: response.data,
            })
        });
    }
};

export const getEventDocuments = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/event/documents`,
            method: 'GET',
            params: { id }
        }).then(response => {
            dispatch({
                id: 'eventItem',
                type: 'EVENT_DOCUMENTS',
                data: response.data,
            })
        });
    }
};

export const getInvolvedPersonTypes = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/events/categories/miscellaneous`,
            method: 'GET',
            params: { category_code: 'iprivp' }
        }).then(response => {
            dispatch({
                id,
                type: 'EVENT_INVOLVED_PERSON_TYPES',
                data: response.data,
            })
        });
    }
};

export const getInvolvedPersonAgeGroups = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/events/categories/miscellaneous`,
            method: 'GET',
            params: { category_code: 'agg' }
        }).then(response => {
            dispatch({
                id,
                type: 'EVENT_INVOLVED_PERSON_AGE_GROUPS',
                data: response.data,
            })
        });
    }
};
