import i18n from '../../i18n/en.json';

/**
 * @const {object}
 * @memberOf module:ApplicationsPermits
 */
export const apLaserRequestTrainings = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(({ applicationsPermits }) => ({
                apLaserRequest: applicationsPermits.apLaserRequest,
            }), {})(this);

            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.trainings`;

            const { title, first_name, last_name } = this.apLaserRequest;
            this.title = `${title} ${first_name} ${last_name} - ${i18n[this.type].trainings.title}`;
        }

        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: require('./ap-laser-request-trainings.html')
};
