/* @ngInject */
export function EquipmentServiceTransducer($http, API_BASE, Transducer, EquipmentCategory) {

    this.addEquipment = function(equipmentID, transducer) {
        return $http({
            url: API_BASE + "/equipment/transducer",
            method: "POST",
            data: {
                id: equipmentID,
                model: transducer.model.id,
                category: transducer.category.id,
                serial: transducer.serial,
                mhzRange: transducer.mhz_range_from + '-' + transducer.mhz_range_to
            }
        }).then(function(result) {
            return result.data.equipment;
        });
    };

    this.removeEquipment = function(transducer) {
        return $http({
            url: API_BASE + "/equipment/transducer/remove",
            method: "POST",
            data: {id: transducer.equipment_id}
        }).then(function(result) {
            return result.data.status;
        });
    };

    this.updateEquipment = function(transducer) {
        return $http({
            url: API_BASE + "/equipment/transducer",
            method: "PUT",
            data: {
                id: transducer.equipment_id,
                model: transducer.model.id,
                category: transducer.category.id,
                serial: transducer.serial,
                mhzRange: transducer.mhz_range_from + '-' + transducer.mhz_range_to
            }
        }).then(function(result) {
            return result.data.status;
        });
    };

    this.getCategories = function() {
        return $http({
            url: API_BASE + "/equipment/transducer/categories",
            method: "GET"
        }).then(function(result) {
            var categories = [];
            result.data.forEach(function(item) {
                var category = new EquipmentCategory();
                category.id = item.category_id;
                category.name = item.category_name;
                category.code = item.category_code;
                category.isRegulated = item.category_is_regulated;
                categories.push(category);
            });
            return categories;
        });
    };
}
