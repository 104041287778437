/*
*   List of Actions with TYPE that should be passed from accreditation.reducer.js to scheme.reducer.js
 */

export const PREFIX = 'ACCREDITATION_SCHEME';

export const TYPES = {
    FETCH_AUDIT_USER_DEAILS:        `${PREFIX}_FETCH_USER_DEAILS`,
    SCHEME_DETAILS_REFRESH:         `${PREFIX}_SCHEME_DETAILS_REFRESH`,
    SET_CURRENT_FISCAL_YEAR:        `${PREFIX}_SET_CURRENT_FISCAL_YEAR`,
    FETCH_EVIDENCE_ASSESSMENT:      `${PREFIX}_FETCH_EVIDENCE_ASSESSMENT`,
    FETCH_SITE_LOCATION_DETAILS:    `${PREFIX}_FETCH_SITE_LOCATION_DETAILS`,
    FETCH_SCHEDULE:                 `${PREFIX}_FETCH_SCHEDULE`,
    RESET_SCHEDULE:                 `${PREFIX}_RESET_SCHEDULE`,
    FETCH_EVIDENCE_PROVIDER_CANDIDATES: `${PREFIX}_FETCH_EVIDENCE_PROVIDER_CANDIDATES`,
    FETCH_EVIDENCE_ASSIGNEE_CANDIDATES: `${PREFIX}_FETCH_EVIDENCE_ASSIGNEE_CANDIDATES`,
    RESET_EVIDENCE_ASSIGNEE:        `${PREFIX}_RESET_EVIDENCE_ASSIGNEE`,
    REFRESH_AUDIT_DETAILS:          `${PREFIX}_REFRESH_AUDIT_DETAILS`,
    ADD_AUDIT_ASSIGNEE:             `${PREFIX}_ADD_AUDIT_ASSIGNEE`,
    REMOVE_AUDIT_ASSIGNEE:          `${PREFIX}_REMOVE_AUDIT_ASSIGNEE`,
    PRESELECT_YEAR_AND_LOCATION:    `${PREFIX}_PRESELECT_YEAR_AND_LOCATION`,
    SELECT_ACC_TASK:                `${PREFIX}_SELECT_ACC_TASK`,
    HIDE_SCHEME_TILES_FOR_EMPLOYEES:`${PREFIX}_HIDE_SCHEME_TILES_FOR_EMPLOYEES`,
};
