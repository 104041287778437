import { VERSION } from '../utils/util.constants';
import { product } from '../../bootstrap.json';

/**
 * Creates a new AuthController.
 * @class
 */
class AuthController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
        this.product = product;
        this.version = VERSION;
        this.year = new Date().getFullYear();
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this.mapStateToThis, {})(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    mapStateToThis(state) {
        return {
            loading: state.app.loading
        };
    }
}

AuthController.$inject = ['$ngRedux'];

export const auth =  {
    controller: AuthController,
    template: require('./auth.html')
};
