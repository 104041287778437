import * as SettingsActions from '../../import-settings.actions';
import { GRID_FILTER } from "../../../../../common/grid/grid.constants";
import {IDS} from "../../import-settings-action-id.constants";
import {TYPES} from "../../import-settings-action-type.constants";

class UsersSkippedController {
    constructor($ngRedux, $scope, $timeout, Notifier, $filter) {
        this.$ngRedux = $ngRedux;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.Notifier = Notifier;
        this.$filter = $filter;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(state => {
            return {}
        }, SettingsActions)(this);
    }

    onSelect({item}) {
        // this.$ngRedux.dispatch({
        //     type:   TYPES.SELECT_IMPORT_ITEM,
        //     id:     IDS.empImportSettingsUsersDetails,
        //     item
        // });
        // this.$ngRedux.dispatch({
        //     type:   GRID_FILTER,
        //     id:     IDS.empImportSettings,
        //     path:   IDS.empImportSettingsUsersDetailsGrid,
        //     filter: { gci_id: item.id }
        // });
        // this.$ngRedux.dispatch({
        //     type: 'TAB_SHOW',
        //     id: IDS.app_employees_import_settings_centers,
        //     state: [IDS.app_employees_import_settings_centers_details]
        // });
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

UsersSkippedController.$inject = ['$ngRedux', '$scope', '$timeout', 'Notifier', '$filter'];

export const empImportSettingsUsersSkippedList = {
    controller: UsersSkippedController,
    template: `
        <emp-import-settings-users-skipped-list-grid 
            title="{{'EMPLOYEES.IMPORT_SETTINGS.USERS.TABS.SKIPPED' | translate}}"
            on-select="$ctrl.onSelect($event)">
        </emp-import-settings-users-skipped-list-grid>
`
};
