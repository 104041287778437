export const drlCtScannerContrastReportState = {
    name: 'app.doseReferenceLevels.ctScanner.contrastReport',
    redirectTo: 'app.doseReferenceLevels.ctScanner.contrastReport.generator',
    url: '/contrast-report',
    component: 'tabs'
};

export const drlCtScannerContrastReportGeneratorState = {
    name: 'app.doseReferenceLevels.ctScanner.contrastReport.generator',
    url: '',
    component: 'drlCtScannerContrastReport',
    onExit: $ngRedux => {
        $ngRedux.dispatch({
            type: 'CONTRAST_REPORT_RESET',
            id: 'drlCtScannerContrastReport'
        });
    }
};
drlCtScannerContrastReportGeneratorState.onExit.$inject = ['$ngRedux'];
