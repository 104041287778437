import * as msal from '@azure/msal-browser';
import { msalClientId, msalAuthority, msalRedirectUri } from '../../../bootstrap.json';
import { loginWithEntraID } from '../auth.actions';
import { resolveOtpParams } from '../auth.utils';
import {LOADING} from '../../navigation/navigation.actions.types';

const msalObj = new msal.PublicClientApplication({
    auth: {
        clientId: msalClientId,
        authority: msalAuthority,
        redirectUri: msalRedirectUri,
    },
});

/**
 * Creates a new AuthLoginController.
 * @class
 */
class AuthLoginController {
    constructor($log, $ngRedux, $state, $filter, $uiRouterGlobals, AuthService, AppService) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$filter = $filter;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.AuthService = AuthService;
        this.AppService = AppService;
    }

    $onInit() {
        this.$log.debug('Login::init');
        this.username = null;
        this.password = null;

        if (this.$state.params.message) {
            this.headlineClass = 'md-headline-success';
            this.headline = this.$state.current.params.headline;
        }
        else this.initHeadline();

        if (this.$uiRouterGlobals.params.reset)
            this.$ngRedux.dispatch({ type: 'RESET' });
    }

    onEntraID() {
        this.initHeadline();
        this.$ngRedux.dispatch({ type: LOADING, data: true });
        msalObj.loginPopup({
            scopes: ['user.read', 'email'],
        }).then(({ idToken }) => {
            this.AuthService.loginWithEntraID(idToken).then(() => {
                this.$ngRedux.dispatch({ type: LOADING, data: false });
                this.AppService.moduleOverride();
                this.AuthService.recoverSession();
            }).catch(this.resolveError.bind(this));
        }).catch(this.resolveError.bind(this));
    }

    signIn() {
        this.initHeadline();
        if (this.loginForm.$valid) {
            this.$ngRedux.dispatch({ type: 'LOADING_START' });
            this.AuthService.login(this.username, this.password).then(() => {
                this.$ngRedux.dispatch({ type: 'LOADING_END' });
                this.AppService.moduleOverride();
                this.AuthService.recoverSession();
            }, ({ status, data }) => {
                this.$ngRedux.dispatch({ type: 'LOADING_END' });
                this.headlineClass = 'md-headline-error';
                switch (status) {
                    case 428:
                        /** Precondition Required */
                        this.$state.go('login.otp', {
                            token: data.token,
                            ...resolveOtpParams(data.status),
                        });
                        break;
                    case 429:
                        /** Too Many Requests */
                        this.headline = `COMMON.LOGIN.YOU_HAVE_HIT_MAX_NUM_OF_LOGIN_ATTEMPTS_ALLOWED`;
                        this.headlineDetail = this.$filter('translate')('COMMON.LOGIN.TRY_AGAIN_IN', { time: data });
                        break;
                    default:
                        this.headline = `COMMON.LOGIN.${data.status === 106 ? 'APP_URL_CHANGED_TO' : 'NO_MATCH'}`;
                        this.headlineDetail = (data?.response_detail || '').toUpperCase();
                }
            });
        }
    }

    initHeadline() {
        this.headlineClass = 'md-headline';
        this.headline = this.$state.current.params.headline;
        this.headlineDetail = '';
    }

    resolveError(err) {
        this.$ngRedux.dispatch({ type: LOADING, data: false });
        this.headlineClass = 'md-headline-error';
        if (err.status === 401) {
            this.headline = 'Unauthorized Access';
            this.headlineDetail = 'Please check your credentials';
        } else {
            this.headline = 'System Error';
            this.headlineDetail = 'Please try again later';
        }
    };
}

AuthLoginController.$inject = ['$log', '$ngRedux', '$state', '$filter', '$uiRouterGlobals', 'AuthService', 'AppService'];

export const authLogin =  {
    controller: AuthLoginController,
    template: require('./auth-login.html')
};
