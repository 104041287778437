import { API_BASE } from '../common/utils/util.constants';
import * as FORM from '../common/form/input/input.constants';
import { TabsReducer } from '../common/tabs/tabs.reducer';
import { GridReducer } from '../common/grid/grid.reducer';
import { getGridSort } from '../common/grid/grid.utils';
import equipmentCalibrationLoanReducer from '../components/equipment-calibration/equipment/loan/equipment-calibration-loan.reducer';
import equipmentCalibrationLoanHistoryReducer from '../components/equipment-calibration/equipment/loan-history/equipment-calibration-loan-history.reducer';
import { formatDate } from '../common/utils/util.date';
import merge from 'lodash/merge';
import set from 'lodash/set';
import get from 'lodash/get';
import update from 'lodash/update';
import last from 'lodash/last';
import omit from 'lodash/omit';

const initialState = {
    'app.equipmentCalibration.add': Object.assign(TabsReducer(), {
        tabs: [
            {
                state: 'app.equipmentCalibration.add.details',
                title: 'Equipment Details',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Add Equipment'}
                }
            },
            {
                state: 'app.equipmentCalibration.add.assetCost',
                title: 'Asset Cost',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save Asset Cost'}
                }
            },
            {
                state: 'app.equipmentCalibration.add.detectorProbe',
                title: 'Detector / Probe',
                visible: false,
                disabled: false,
                loading: false
            },
            {
                state: 'app.equipmentCalibration.add.detectors',
                title: 'Detectors',
                visible: false,
                disabled: false,
                loading: false
            },
            {
                state: 'app.equipmentCalibration.add.chambers',
                title: 'Chambers',
                visible: false,
                disabled: false,
                loading: false
            },
            {
                state: 'app.equipmentCalibration.add.documents',
                title: 'Equipment Documents',
                visible: false,
                disabled: false,
                loading: false
            },
            {
                state: 'app.equipmentCalibration.add.vendors',
                title: 'Vendors',
                visible: false,
                disabled: false,
                loading: false
            }
        ]
    }),
    'app.equipmentCalibration.manage': Object.assign(TabsReducer(), {
        tabs: [
            {
                state: 'app.equipmentCalibration.manage.listing',
                title: 'Equipment',
                visible: true,
                disabled: false,
                loading: false
            },
            {
                state: 'app.equipmentCalibration.manage.loan',
                title: 'Loan Equipment',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Submit'}
                }
            },
            {
                state: 'app.equipmentCalibration.manage.loanHistory',
                title: 'Loan History',
                visible: false,
                disabled: false,
                loading: false,
            },
            {
                state: 'app.equipmentCalibration.manage.info',
                title: 'Equipment Info',
                visible: false,
                disabled: false,
                loading: false
            },
            {
                state: 'app.equipmentCalibration.manage.details',
                title: 'Equipment Details',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Modify'}
                }
            },
            {
                state: 'app.equipmentCalibration.manage.assetCost',
                title: 'Asset Cost',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save Asset Cost'}
                }
            },
            {
                state: 'app.equipmentCalibration.manage.detectorProbe',
                title: 'Detector / Probe',
                visible: false,
                disabled: false,
                loading: false
            },
            {
                state: 'app.equipmentCalibration.manage.detectors',
                title: 'Detectors',
                visible: false,
                disabled: false,
                loading: false
            },
            {
                state: 'app.equipmentCalibration.manage.chambers',
                title: 'Chambers',
                visible: false,
                disabled: false,
                loading: false
            },
            {
                state: 'app.equipmentCalibration.manage.documents',
                title: 'Equipment Documents',
                visible: false,
                disabled: false,
                loading: false
            },
            {
                state: 'app.equipmentCalibration.manage.vendors',
                title: 'Vendors',
                visible: false,
                disabled: false,
                loading: false
            }
        ]
    }),
    'app.equipmentCalibration.alerts': Object.assign(TabsReducer(), {
        tabs: [{
            state: 'app.equipmentCalibration.alerts.listing',
            title: 'Alerts & Warnings',
            visible: true,
            disabled: false,
            loading: false,
        }]
    }),
    equipmentCalibrationGrid: merge(GridReducer(), {categories: [], activeCount: 0, archiveCount: 0}, {
        filter: {
            type: 74,
            status: 'act',
            location: null,
            checkLocation: true,
            manufacturer: null,
            category: null
        }
    }),
    equipmentDetectorProbeGrid: merge(GridReducer(), {
        filter: {
            identifier: 'equipmentDetectorProbeGrid',
            equipmentId: null
        }
    }),
    equipmentDetectorsGrid: merge(GridReducer(), {
        filter: {
            identifier: 'equipmentDetectorsGrid',
            equipmentId: null
        }
    }),
    equipmentChambersGrid: merge(GridReducer(), {
        filter: {
            identifier: 'equipmentChambersGrid',
            equipmentId: null
        }
    }),
    equipmentCalibrationDocumentsGrid: merge(GridReducer(), {
        filter: {
            equipmentId: null,
            documentTypeId: null,
            documentStatusId: null
        }
    }),
    equipmentCalibrationLoanHistoryGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0,
    },
    equipmentAlertsGrid: merge(GridReducer(), {
        filter: {
            moduleId: null,
            alertType: null,
            keyword: null,
            equipmentId: null,
            employeeUuid: null
        }
    }),
    equipment: {
        id: null,
        visualId: null,
        location: null,
        locationName: null,
        accountName: null,
        department: null,
        roomName: null,
        locations: [],
        locationDepartment: null,
        locationDepartments: [],
        locationQRcode: null,
        accountId: null,
        type: null,
        typeName: null,
        typeCode: null,
        types: [],
        documentTypes: [],
        documentStatusList: [],
        manufacturer: null,
        manufacturerName: null,
        manufacturers: [],
        mobility: null,
        mobilityName: null,
        mobilityData: [{value: 1, label: 'Mobile'}, {value: 0, label: 'Fixed'}],
        model: null,
        modelSerial: null,
        models: [],
        installationDate: null,
        expiryDate: null,
        annualService: 0,
        annualCalibration: 0,
        electricalTagTesting: 0,
        nextCalibrationDueDate: null,
        nextServiceDueDate: null,
        nextElectricalTagTestingDueDate: null,
        detector: 0,
        qrcode: null,
        camera: null,
        gallery: [],
        cost_centre_code: null,
        cost_centre_name: null,
        purchaseDate: null,
        purchase_order_number: null,
        warranty_period: null,
        warranty_exp_date: null,
        assetCost: null,
        act_type_code: null,
        act_type_code_data: [
            { value: 'exctax', label: 'Excludes Tax' },
            { value: 'inctax', label: 'Includes Tax' },
        ],
        estimated_residual_value: null,
        depreciation_rate_per_year: null,
        tax_estimated_useful_life: null,
        depreciation_type: null,
        depreciation_type_data: [
            { value: 'sldm', label: 'Straight Line Depreciation' },
            { value: 'dbdm', label: 'Diminishing Depreciation' },
        ],
        service_contract: null,
        service_contract_data: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ],
        comment: null,
        software_version: null,
        man_eof_support_date: null,
    },
    detector: {
        id: null,
        equipmentId: null,
        type: null,
        typeName: null,
        types: [],
        category: null,
        categoryName: null,
        categories: [],
        chambers: [],
        manufacturer: null,
        manufacturerName: null,
        manufacturers: [],
        model: null,
        modelSerial: null,
        models: [],
        radiationType: null,
        radiationTypeName: null,
        radiationTypes: [],
        dueDate: null
    },
    document: {
        id: null,
        equipmentId: null,
        documentTypeId: null,
        documentStatusId: null,
        documentResultId: null,
        expiryDate: null,
        inspectionDate: null,
        inspectedBy: null,
        servicedBy: null,
        comment: null,
        result: null,
        complianceCertificationNumber: null,
        documentInspectors: [],
        documentResults: []
    },
    serviceTypes: [],
    ...equipmentCalibrationLoanReducer(),
    ...equipmentCalibrationLoanHistoryReducer()
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'TAB_INIT':
        case 'TAB_OVERRIDE':
        case 'TAB_PARAMS':
        case 'TAB_ENABLE':
        case 'TAB_DISABLE':
        case 'TAB_SHOW':
        case 'TAB_HIDE':
        case 'TAB_LOADING':
        case 'TAB_SELECT':
            if (action.id === 'app.equipmentCalibration.add') {
                return Object.assign({}, state, {
                    'app.equipmentCalibration.add': TabsReducer(state[action.id], action)
                });
            }
            if (action.id === 'app.equipmentCalibration.manage') {
                return Object.assign({}, state, {
                    'app.equipmentCalibration.manage': TabsReducer(state[action.id], action)
                });
            }
        case 'TAB_SWITCH':
            if (action.id === 'app.equipmentCalibration.add') {
                return Object.assign({}, state, {
                    'app.equipmentCalibration.add': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                });
            }
            if (action.id === 'app.equipmentCalibration.manage') {
                return Object.assign({}, state, {
                    'app.equipmentCalibration.manage': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                });
            }
        case 'EQUIPMENT_SWITCH':
            if (action.id === 'equipmentCalibrationGrid') {
                return Object.assign({}, state, set({}, action.id, set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.status', action.status)));
            }
        case 'EQUIPMENT_NEXT_ID':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: Object.assign({}, state.equipment, {visualId: action.visualId})
                }
            }
        case 'QR_CODE':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: Object.assign({}, state.equipment, {qrcode: action.qrcode})
                }
            }
        case 'LOCATION_SELECTED':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: {
                        ...state.equipment,
                        location: action.uuid,
                        locationName: action.locationName,
                        accountId: action.accountId
                    }
                }
            }
            if (action.id === 'equipmentCalibrationGrid') {
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.location', action.uuid)));
            }
        case 'DEPARTMENT_TYPES':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: {
                        ...state.equipment,
                        locationDepartments: action.items
                    }
                }
            }
        case 'EQUIPMENT_CALIBRATION_TYPES':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: Object.assign({}, state.equipment, {types: action.items})
                }
            }
        case 'EQUIPMENT_ASSET_TYPES': {
            return {
                ...state,
                detector: Object.assign({}, state.detector, {types: action.items})
            }
        }
        case 'EQUIPMENT_ASSET_CATEGORIES': {
            return {
                ...state,
                detector: Object.assign({}, state.detector, {categories: action.items})
            }
        }
        case 'EQUIPMENT_ASSET_RADIATION_TYPES': {
            return {
                ...state,
                detector: Object.assign({}, state.detector, {radiationTypes: action.items})
            }
        }
        case 'EQUIPMENT_MANUFACTURERS':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: Object.assign({}, state.equipment, {manufacturers: action.items}),
                    detector: Object.assign({}, state.detector, {manufacturers: action.items})
                }
            }
        case 'EQUIPMENT_MODELS':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: Object.assign({}, state.equipment, {models: action.items, model: null})
                }
            }
            if (action.id === 'detector') {
                return {
                    ...state,
                    detector: Object.assign({}, state.detector, {models: action.items, model: null})
                }
            }
        case 'INPUT_TEXT_CHANGE':
        case 'CHECKBOX_SELECTED':
        case 'DATE_SELECTED':
        case FORM.INPUT_RADIO:
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: {
                        ...state.equipment,
                        ...action.data,
                        ...(action.path === 'depreciation_type' && action.input === 'sldm' && { depreciation_rate_per_year: null }),
                    },
                }
            }
            if (action.id === 'detector') {
                return {
                    ...state,
                    detector: Object.assign({}, state.detector, action.data)
                }
            }
            if (action.id === 'document') {
                return {
                    ...state,
                    document: Object.assign({}, state.document, action.data)
                }
            }
            if (action.id === 'equipmentDocument') {
                return {
                    ...state,
                    equipment: Object.assign({}, state.equipment, action.data)
                }
            }
            if (action.id === 'equipmentLoan') {
                return {
                    ...state,
                    equipmentLoan: {
                        ...state.equipmentLoan,
                        ...action.data
                    }
                };
            }
            if (action.id === 'equipmentLoanHistory') {
                return {
                    ...state,
                    equipmentLoanHistory: {
                        ...state.equipmentLoanHistory,
                        ...action.data
                    }
                };
            }
            if (action.id === 'equipmentCalibrationGrid') {
                return Object.assign({}, state, set({}, action.id, set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)));
            }
            if (action.id === 'equipmentCalibrationLoanHistoryGrid') {
                return Object.assign({}, state, set({}, action.id, set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)));
            }
        case 'INPUT_SELECT':
        case 'INPUT_EMAIL_CHANGE':
        case 'INPUT_PHONE_CHANGE':
        case 'INPUT_TEXT_AREA_CHANGE':
        case 'INPUT_SIGNATURE':
            if (action.id === 'equipment') {
                if (['act_type_code', 'comment'].includes(action.path)) {
                    return {
                        ...state,
                        equipment: {
                            ...state.equipment,
                            [action.path]: action.input,
                        },
                    };
                } else {
                    return {
                        ...state,
                        equipment: Object.assign({}, state.equipment, action.item),
                        detector: action.path === 'manufacturer' ? Object.assign({}, state.detector, action.item) : state.detector
                    };
                }
            }
            if (action.id === 'detector') {
                return {
                    ...state,
                    detector: Object.assign({}, state.detector, action.item)
                }
            }
            if (action.id === 'document') {
                return {
                    ...state,
                    document: Object.assign({}, state.document, action.item)
                }
            }
            if (action.id === 'equipmentLoan') {
                return {
                    ...state,
                    equipmentLoan: {
                        ...state.equipmentLoan,
                        [action.path]: action.input
                    }
                };
            }
            if (action.id === 'equipmentLoanHistory') {
                return {
                    ...state,
                    equipmentLoanHistory: {
                        ...state.equipmentLoanHistory,
                        [action.path]: action.input
                    }
                };
            }
            if (action.id === 'equipmentCalibrationGrid') {
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)));
            }
            if (action.id === 'equipmentCalibrationDocumentsGrid') {
                return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)));
            }
        case 'AUTOCOMPLETE_SELECTED':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: {
                        ...state.equipment,
                        [action.path]: action.item,
                        ...(action.path === 'type' && { typeCode: get(action, 'item.item.typeCode', null) }),
                        ...(action.path === 'type' && { typeName: get(action, 'item.item.typeName', null) }),
                    }
                };
            }
            if (action.id === 'detector') {
                return {
                    ...state,
                    detector: Object.assign({}, state.detector, {model: action.item})
                }
            }
            if (action.id === 'equipmentLoan') {
                if (action.item) {
                    return {
                        ...state,
                        equipmentLoan: {
                            ...state.equipmentLoan,
                            [action.path]: action.item,
                            email: action.item.email,
                            contact_number: action.item.phone,
                            campus: action.item.account_name,
                            department: action.item.department_name
                        }
                    };
                } else {
                    return {
                        ...state,
                        equipmentLoan: {
                            ...state.equipmentLoan,
                            loan_person: null,
                            email: null,
                            contact_number: null,
                            campus: null,
                            department: null
                        }
                    };
                }
            }
            if (action.id === 'equipmentLoanHistory') {
                if (action.item) {
                    return {
                        ...state,
                        equipmentLoanHistory: {
                            ...state.equipmentLoanHistory,
                            [action.path]: action.item
                        }
                    };
                } else {
                    return {
                        ...state,
                        equipmentLoanHistory: {
                            ...state.equipmentLoanHistory,
                            receipted_by: null,
                            receiptPersons: []
                        }
                    };
                }
            }
        case 'EQUIPMENT_TYPE':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: {
                        ...state.equipment,
                        type: {
                            id: action.data.type,
                            name: action.data.typeName
                        },
                        typeName: action.data.typeName
                    }
                };
            }
        case 'EQUIPMENT_MANUFACTURER':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: {
                        ...state.equipment,
                        manufacturer: action.data.manufacturer,
                        manufacturerName: action.data.manufacturerName
                    }
                }
            }
        case 'FILE_UPLOAD': {
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: {
                        ...state.equipment,
                        gallery: [...state.equipment.gallery, {
                            id: parseInt(action.itemId),
                            url: API_BASE + '/img/' + localStorage.myrsoAuthToken + action.input,
                            width: action.width,
                            height: action.height
                        }]
                    }
                }
            }
        }
        case 'EQUIPMENT_DOCUMENT_TYPES':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: Object.assign({}, state.equipment, {documentTypes: action.items})
                }
            }
        case 'EQUIPMENT_DOCUMENT_STATUS_LIST':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: Object.assign({}, state.equipment, {documentStatusList: action.items})
                }
            }
        case 'EQUIPMENT_DOCUMENT_INSPECTORS':
            if (action.id === 'document') {
                return {
                    ...state,
                    document: Object.assign({}, state.document, {documentInspectors: action.items})
                }
            }
        case 'EQUIPMENT_DOCUMENT_RESULTS':
            if (action.id === 'document') {
                return {
                    ...state,
                    document: Object.assign({}, state.document, {documentResults: action.items})
                }
            }
        case 'EQUIPMENT_MODEL':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: Object.assign({}, state.equipment, {model: action.model})
                }
            }
            if (action.id === 'detector') {
                return {
                    ...state,
                    detector: Object.assign({}, state.detector, {model: action.model})
                }
            }
        case 'EQUIPMENT_ADD': {
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: Object.assign({}, state.equipment, {id: action.data.equ_id}),
                    detector: Object.assign({}, state.detector, {equipmentId: action.data.equ_id}),
                    equipmentDetectorProbeGrid: {
                        ...state.equipmentDetectorProbeGrid,
                        filter: {
                            ...state.equipmentDetectorProbeGrid.filter,
                            equipmentId: action.data.equ_id
                        }
                    },
                    equipmentDetectorsGrid: {
                        ...state.equipmentDetectorsGrid,
                        filter: {
                            ...state.equipmentDetectorsGrid.filter,
                            equipmentId: action.data.equ_id
                        }
                    },
                    equipmentChambersGrid: {
                        ...state.equipmentChambersGrid,
                        filter: {
                            ...state.equipmentChambersGrid.filter,
                            equipmentId: action.data.equ_id
                        }
                    },
                    equipmentCalibrationDocumentsGrid: {
                        ...state.equipmentCalibrationDocumentsGrid,
                        filter: {
                            ...state.equipmentCalibrationDocumentsGrid.filter,
                            equipmentId: action.data.equ_id
                        }
                    }
                }
            }
        }
        case 'EQUIPMENT_DETAILS':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: {
                        ...state.equipment,
                        id: action.data.equ_id,
                        visualId: action.data.equipment_id,
                        location: last(action.locations).sub_uuid,
                        locationName: last(action.locations).name,
                        accountName: action.data.account_name,
                        department: action.data.dep_name,
                        roomName: action.data.room_name,
                        locations: action.locations,
                        accountId: action.data.account_id,
                        type: {
                            id: action.data.category_id,
                            name: action.data.category_name
                        },
                        typeName: action.data.category_name,
                        typeCode: action.data.category_code,
                        manufacturer: action.data.manufacturer_id,
                        manufacturerName: action.data.manufacturer_name,
                        mobility: action.data.mobility,
                        model: {id: action.data.model_id, name: action.data.model_name},
                        modelSerial: action.data.equ_serial,
                        installationDate: action.data.installation_date,
                        expiryDate: action.data.in_cap_sens_exp_date,
                        annualService: 0,
                        annualCalibration: 0,
                        nextCalibrationDueDate: action.data.calibration_due_date,
                        nextServiceDueDate: action.data.service_due_date,
                        nextElectricalTagTestingDueDate: action.data.ele_tag_testing_due_date,
                        detector: parseInt(action.data.has_assets),
                        hasAssets: parseInt(action.data.has_assets),
                        qrcode: action.data.qrcode,
                        gallery: action.images,
                        cost_centre_code: action.data.cost_centre_code,
                        cost_centre_name: action.data.cost_centre_name,
                        purchaseDate:formatDate(action.data.purchase_date),
                        purchase_order_number: action.data.purchase_order_number,
                        warranty_period: action.data.warranty_period,
                        warranty_exp_date: action.data.warranty_exp_date,
                        assetCost: action.data.asset_cost,
                        act_type_code: action.data.act_type_code,
                        estimated_residual_value: action.data.estimated_residual_value,
                        tax_estimated_useful_life: action.data.tax_estimated_useful_life,
                        depreciation_rate_per_year: action.data.depreciation_rate_per_year,
                        depreciation_type: action.data.depreciation_type,
                        service_contract: action.data.service_contract,
                        comment: action.data.comment,
                        software_version: action.data.software_version,
                        man_eof_support_date: action.data.man_eof_support_date,
                    },
                    detector: {
                        ...state.detector,
                        equipmentId: action.data.equ_id
                    },
                    equipmentDetectorProbeGrid: {
                        ...state.equipmentDetectorProbeGrid,
                        filter: {
                            ...state.equipmentDetectorProbeGrid.filter,
                            equipmentId: action.data.equ_id
                        }
                    },
                    equipmentDetectorsGrid: {
                        ...state.equipmentDetectorsGrid,
                        filter: {
                            ...state.equipmentDetectorsGrid.filter,
                            equipmentId: action.data.equ_id
                        }
                    },
                    equipmentChambersGrid: {
                        ...state.equipmentChambersGrid,
                        filter: {
                            ...state.equipmentChambersGrid.filter,
                            equipmentId: action.data.equ_id
                        }
                    },
                    equipmentCalibrationDocumentsGrid: {
                        ...state.equipmentCalibrationDocumentsGrid,
                        filter: {
                            ...state.equipmentCalibrationDocumentsGrid.filter,
                            equipmentId: action.data.equ_id
                        }
                    }
                }
            }
        case 'EQUIPMENT_ASSET_WARRANTY_EXPIRY_DATE':
            if (action.id === 'equipment') {
                return {
                    ...state,
                    equipment: {
                        ...state.equipment,
                        warranty_exp_date: action.date,
                    }
                };
            }
        case 'DETECTOR_ADD': {
            if (action.id === 'detector') {
                return {
                    ...state,
                    detector: Object.assign({}, state.detector, {
                        id: null,
                        type: null,
                        typeName: null,
                        category: null,
                        categoryName: null,
                        manufacturer: null,
                        manufacturerName: null,
                        model: null,
                        modelSerial: null,
                        radiationType: null,
                        radiationTypeName: null,
                        dueDate: null
                    })
                }
            }
        }
        case 'SET_DETECTOR':
            if (action.id === 'detector') {
                return {
                    ...state,
                    equipment: {
                        ...state.equipment,
                        hasAssets: action.data.hasAssets,
                        detector: action.data.detector
                    }
                }
            }
        case 'DOCUMENT_ADD': {
            if (action.id === 'document') {
                return {
                    ...state,
                    document: Object.assign({}, state.document, {
                        id: null
                    })
                }
            }
        }
        case 'ASSET_DETAILS':
            if (action.id === 'detector') {
                return {
                    ...state,
                    detector: {
                        ...state.detector,
                        id: action.data.equ_id,
                        equipmentId: action.data.equipment_id,
                        type: action.data.asset_type_id,
                        typeName: action.data.asset_type_id,
                        category: action.data.category_id,
                        categoryName: action.data.category_name,
                        manufacturer: action.data.manufacturer_id,
                        manufacturerName: action.data.manufacturer_name,
                        model: {id: action.data.model_id, name: action.data.model_name},
                        modelSerial: action.data.serial,
                        radiationType: action.data.radiation_type_id,
                        radiationTypeName: action.data.radiation_type_name,
                        dueDate: action.data.due_date
                    }
                }
            }
        case 'ASSET_RESET':
            if (action.id === 'detector') {
                return {
                    ...state,
                    detector: {
                        ...state.detector,
                        id: null,
                        type: null,
                        typeName: null,
                        category: null,
                        categoryName: null,
                        manufacturer: null,
                        manufacturerName: null,
                        model: null,
                        modelSerial: null,
                        radiationType: null,
                        radiationTypeName: null,
                        dueDate: null
                    }
                }
            }
        case 'VENDOR_SERVICE_TYPES':
            return {
                ...state,
                serviceTypes: action.items
            };
        case 'EQUIPMENT_CALIBRATION_RESET':
            return {
                ...state,
                equipment: {
                    ...initialState.equipment,
                    types: state.equipment.types
                },
                detector: initialState.detector,
                document: initialState.document,
                equipmentCalibrationDocumentsGrid: initialState.equipmentCalibrationDocumentsGrid
            };
        case 'EQUIPMENT_DOCUMENT_RESET':
            return {
                ...state,
                document: initialState.document
            };
        case 'EQUIPMENT_LOAN_FILTER':
            if (action.id === 'equipmentCalibrationGrid') {
                return {
                    ...state,
                    equipmentCalibrationGrid: {
                        ...state.equipmentCalibrationGrid,
                        filter: {
                            ...state.equipmentCalibrationGrid.filter,
                            loan: action.value || null
                        }
                    }
                };
            }
        case 'EQUIPMENT_LOAN_CREATE':
        case 'EQUIPMENT_LOAN_DETAILS':
        case 'EQUIPMENT_LOAN_PERSONS':
        case 'EQUIPMENT_LOAN_PERSON_SAVE':
        case 'EQUIPMENT_LOAN_PERSON_RESET':
        case 'EQUIPMENT_LOAN_RESET':
            return {
                ...state,
                equipmentLoan: equipmentCalibrationLoanReducer(state.equipmentLoan, action)
            };
        case 'EQUIPMENT_LOAN_HISTORY_DETAILS':
        case 'EQUIPMENT_LOAN_RECEIPT_PERSONS':
        case 'EQUIPMENT_LOAN_RECEIPT_PERSON_NEW':
        case 'EQUIPMENT_LOAN_HISTORY_SAVE':
        case 'EQUIPMENT_LOAN_HISTORY_RESET':
            return {
                ...state,
                equipmentLoanHistory: equipmentCalibrationLoanHistoryReducer(state.equipmentLoanHistory, action)
            };
        case 'GRID_INIT':
            if (action.id === 'equipmentCalibrationGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', function (filter) {
                    return Object.assign({}, omit(filter, 'sort'), {
                        status: 'act',
                        location: null,
                        manufacturer: null,
                        category: null
                    });
                })));
            }
        case 'SHOW_LOADING':
        case 'GRID_FETCH':
        case 'PAGINATE':
        case 'GRID_RELOAD':
            if (action.id === 'equipmentCalibrationGrid') {
                return Object.assign({}, state, set({}, action.id, Object.assign({}, GridReducer(state.equipmentCalibrationGrid, action), {
                    activeCount: action.activeCount,
                    archiveCount: action.archiveCount
                })));
            }
            if (action.id === 'equipmentDetectorProbeGrid') {
                return Object.assign({}, state, {
                    equipmentDetectorProbeGrid: GridReducer(state.equipmentDetectorProbeGrid, action)
                });
            }
            if (action.id === 'equipmentDetectorsGrid') {
                return Object.assign({}, state, {
                    equipmentDetectorsGrid: GridReducer(state.equipmentDetectorsGrid, action)
                });
            }
            if (action.id === 'equipmentChambersGrid') {
                return Object.assign({}, state, {
                    equipmentChambersGrid: GridReducer(state.equipmentChambersGrid, action)
                });
            }
            if (action.id === 'equipmentCalibrationDocumentsGrid') {
                return Object.assign({}, state, {
                    equipmentCalibrationDocumentsGrid: GridReducer(state.equipmentCalibrationDocumentsGrid, action)
                });
            }
            if (action.id === 'equipmentCalibrationLoanHistoryGrid') {
                return Object.assign({}, state, {
                    equipmentCalibrationLoanHistoryGrid: GridReducer(state.equipmentCalibrationLoanHistoryGrid, action)
                });
            }
            if (action.id === 'equipmentAlertsGrid') {
                return Object.assign({}, state, {
                    equipmentAlertsGrid: GridReducer(state.equipmentAlertsGrid, action)
                });
            }
        case 'GRID_SORT':
            if (action.id === 'equipmentCalibrationGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return Object.assign({}, filter, {
                        sort: getGridSort(filter, action)
                    });
                })));
            }
        case 'GRID_RESET':
            if (action.id === 'equipmentCalibrationGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return Object.assign({}, omit(filter, 'sort'), {
                        location: null,
                        manufacturer: null,
                        category: null,
                    });
                })));
            }
            if (action.id === 'equipmentCalibrationDocumentsGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return Object.assign({}, filter, {
                        documentTypeId: null,
                        documentStatusId: null
                    });
                })));
            }
            if (action.id === 'equipmentCalibrationLoanHistoryGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return {
                        ...filter,
                    };
                })));
            }
        default:
            return state;
    }
};
