/* @ngInject */
export function SelectTargetFilterController($ngRedux) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        if (_.isUndefined(ctrl.validate))
            ctrl.validate = false;

        if (ctrl.validate && _.isUndefined(ctrl.name))
            throw new Error('Missing select name attribute');

        unsubscribe = $ngRedux.connect(mapStateToThis, {})(ctrl);
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            $ngRedux.dispatch({
                type: 'TARGET_FILTER_SELECTED',
                id: ctrl.identifier,
                targetFilter: ctrl.selectedOption,
                path: ctrl.statePath
            });
        }
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    function mapStateToThis(state) {
        return {
            options: state.mammoQualityControl[ctrl.identifier].targetsFilters,
            selectedOption: _.get(state.mammoQualityControl[ctrl.identifier], ctrl.statePath, null)
        };
    }
}
