import * as TYPE from '../../cre-vendors.actions.types';
import {
    getVendorGoodsManufacturers,
    getVendorGoodsDetails,
    saveVendorGoodsManufacturer,
    saveVendorGoods,
    deleteVendorGoods,
} from '../../cre-vendors.actions';
import findIndex from 'lodash/findIndex';
import isNull from 'lodash/isNull';

/**
 * @const {object}
 * @memberOf module:Credentialing
 */
export const creVendorsGoods = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $location, $mdDialog, DocumentManager, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.title = 'Goods Information';
            this.unsubscribe = $ngRedux.connect(({ credentialing }) => ({
                creVendor: credentialing.creVendor,
            }), {
                getVendorGoodsManufacturers,
                getVendorGoodsDetails,
                saveVendorGoodsManufacturer,
                saveVendorGoods,
                deleteVendorGoods,
            })(this);
            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.goods`;
            this.token = $location.search().token;

            this.getVendorGoodsManufacturers({ token: this.token });
        };

        this.onCreateManufacturer = () => {
            this.dialog = $mdDialog.show({
                /* @ngInject */
                controller: ($scope) => {
                    $scope.save = () => {
                        if ($scope.dialogForm.$valid) {
                            const { man_data, man_name } = this.creVendor;
                            if (findIndex(man_data, ['label', man_name]) === -1) {
                                this.saveVendorGoodsManufacturer({
                                    token: this.token,
                                    name: this.creVendor.man_name,
                                }).then(() => {
                                    this.getVendorGoodsManufacturers({ token: this.token });
                                    $mdDialog.hide();
                                });
                            } else {
                                Notifier.error('Manufacturer already exists');
                            }
                        }
                    };
                    $scope.cancel = () => $mdDialog.cancel();
                },
                template: require('./dialogs/cre-vendors-goods-manufacturer-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
            }).then(manufacturerReset, manufacturerReset);
        };

        const manufacturerReset = () => {
            $ngRedux.dispatch({
                type: TYPE.CRE_VENDOR_GOODS_MANUFACTURER_RESET,
                id: 'creVendor',
            });
        };

        this.onDocDownload = ({ doc_url }) => {
            DocumentManager.downloadFile(doc_url, 4);
        };

        this.onDocRemove = (doc) => {
            $ngRedux.dispatch({
                type: TYPE.CRE_VENDOR_DOC_REMOVE,
                id: 'creVendor',
                path: 'goods_docs',
                idx: findIndex(this.creVendor.goods_docs, doc),
            });
        };

        this.onSelect = ({ item }) => {
            this.getVendorGoodsDetails({
                token: this.token,
                cag_id: item.cag_id,
            });
        };

        this.onReset = () => {
            $ngRedux.dispatch({
                type: TYPE.CRE_VENDOR_GOODS_RESET,
                id: 'creVendor',
            });
            this.creVendorsGoodsForm.$setPristine();
            this.creVendorsGoodsForm.$setUntouched();
        };

        this.onRemove = ({ item }) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen product will be permanently removed.')
                .ariaLabel('Remove product')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.deleteVendorGoods({
                    token: this.token,
                    cag_id: item.cag_id,
                });
            }, angular.noop);
        };

        this.onSave = () => {
            if (this.creVendorsGoodsForm.$valid) {
                this.saveVendorGoods({
                    token: this.token,
                    application_id: this.creVendor.application_id,
                    cag_id: this.creVendor.cag_id,
                    healthcare: this.creVendor.gty_healthcare,
                    non_healthcare: this.creVendor.gty_non_healthcare,
                    description: this.creVendor.gty_description,
                    cam_id: this.creVendor.man_id,
                    model_number: this.creVendor.model_number,
                    cou_of_origin_id: this.creVendor.cou_of_origin_id,
                    tda_artg_fda_ce: this.creVendor.tda_artg_fda_ce,
                    pog_ex_tax: parseFloat(this.creVendor.pog_ex_tax),
                    pog_in_tax: parseFloat(this.creVendor.pog_in_tax),
                    price_is_valid_for: this.creVendor.price_is_valid_for,
                    is_price_sub_to_roe: this.creVendor.is_price_sub_to_roe,
                    roe_from: parseFloat(this.creVendor.roe_from),
                    roe_to: parseFloat(this.creVendor.roe_to),
                    roe_cur_id: this.creVendor.roe_cur_id,
                    perc_of_price_sub_to_roe: this.creVendor.perc_of_price_sub_to_roe,
                    goods_docs: this.creVendor.goods_docs,
                }).then(() => {
                    isNull(this.creVendor.cag_id) && this.onReset();
                    Notifier.success('Goods Information has been successfully saved');
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
        };

        this.$onDestroy = () => {
            this.onReset();
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        };
    },
    template: require('./cre-vendors-goods.html')
};
