import * as EventsActionPlanActions from "../events-action-plan.actions";

/**
 * @const {object}
 * @memberOf module:Events
 */
export const eventsActionPlanLibrary = {
    /* @ngInject */
    controller: function($ngRedux, $state, $rootScope, $scope, NavigationService, $mdDialog, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(({ events }) => ({
                eventID: events.eventItem.id,
                actionPlan: events.eventActionPlan,
            }), {
                ...EventsActionPlanActions
            })(this);

            const navConfig = NavigationService.getConfig($state.$current);
            this.colorName = navConfig.colorName;
            this.icon = navConfig.icon;

            this.identifier = $state.$current.parent.name;
            this.state = `${$state.$current.parent.name}.actionPlanLibrary`;
        };

        this.onManage = (event) => {
            const actionPlanCtrl = function ($scope, ctrl) {
                $scope.save = () => {
                    if ($scope.actionPlanDialogForm.$valid) {
                        ctrl.manageActionPlan({
                            iev_id: ctrl.eventID,
                            pln_id: ctrl.actionPlan['planLib.pln_id'],
                            plan_name: ctrl.actionPlan['planLib.plan_name'],
                            summary: ctrl.actionPlan['planLib.description']
                        }).then(() => {
                            $mdDialog.hide();
                        }).catch((err) => {
                            $scope.actionPlanDialogForm.action_plan_details_name.$setValidity('unique', false);
                            Notifier.error(err.data);
                        });
                    }
                };
                $scope.cancel = () => {
                    $mdDialog.cancel();
                };
            };

            this.dialog = $mdDialog.show({
                controller: actionPlanCtrl,
                locals: { ctrl: this },
                template: require('./dialogs/events-action-plan-library-details-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                onShowing: () => {
                    if (event) this.setActionPlanLibraryDetails(event.item);
                }
            }).then(this.resetActionPlanLibraryDetails, this.resetActionPlanLibraryDetails);
        }

        this.onRemove = (event) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen Action Plan will be removed permanently.')
                .ariaLabel('Remove Action Plan')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.deleteActionPlan(event.item.pln_id);
            }, angular.noop);
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        };
    },
    template: `
        <events-action-plan-library-grid
            on-select="$ctrl.onManage($event)" 
            on-remove="$ctrl.onRemove($event)">
        </events-action-plan-library-grid>
        <tabs-navigation 
            identifier="{{$ctrl.identifier}}"
            state="{{$ctrl.state}}"
            on-exec="$ctrl.onManage()">
        </tabs-navigation>
    `
};
