import { GridReducer } from '../../../common/grid/grid.reducer';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';

const resolvePlanProgress = (tasks, statusList) => {
    if (tasks.length) {
        let completed = 0;
        for (const tsk of tasks) {
            if (findIndex(statusList, ['value', tsk.status_id]) === 2) {
                ++completed;
            }
        }
        if (completed > 0)
            return Math.floor(((5 * completed) / tasks.length) - 1);
        else
            return 0;
    }
    return null;
};

const resolveTaskStatus = (idx) => {
    switch (idx) {
        case 1:
            return 2;
        case 2:
            return 4;
        default:
            return 0;
    }
};

const initialState =  {
    eventActionPlan: {
        pln_id: null,
        pln_no: null,
        plan_name: null,
        description: null,
        save_plan_to_library: 0,
        actionPlanPreselectList: [],
        action_plan_preselect: null,
        tasks: [],
        task_name: null,
        task_details: null,
        responsible_person: null,
        responsiblePersons: [],
        completion_date: null,
        save_task_to_library: 0,
        actionTaskPreselectList: [],
        action_task_preselect: null,
        'actionTask.tsk_id': null,
        'actionTask.tsk_no': null,
        'actionTask.task_name': null,
        'actionTask.details': null,
        'actionTask.responsible_person': null,
        'actionTask.completion_date': null,
        'actionTask.status': null,
        'actionTask.status_id': null,
        'actionTask.save_task_to_library': 0,
        actionTaskResponsiblePersons: [],
        actionTaskStatusList: [],
        'planLib.pln_id': null,
        'planLib.pln_no': null,
        'planLib.plan_name': null,
        'planLib.description': null,
        'taskLib.tsk_id': null,
        'taskLib.tsk_no': null,
        'taskLib.task_name': null,
        'taskLib.details': null,
        progress: null
    },
    eventsActionTaskGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0,
        filter: {
            limit: null
        }
    },
    eventsActionPlanLibraryGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0,
    },
    eventsActionTaskLibraryGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0,
    }
};

export default (state = initialState, action) => {
    if (action) {
        switch (action.type) {
            case 'EVENT_ACTION_PLAN_DETAILS':
                if (action.id === 'eventActionPlan') {
                    if (action.data[0].length) {
                        return {
                            ...state,
                            pln_id: action.data[0][0].pln_id,
                            pln_no: action.data[0][0].pln_no,
                            plan_name: action.data[0][0].plan_name,
                            description: action.data[0][0].plan_summary,
                            save_plan_to_library: 0,
                            tasks: action.data[1].map((task) => {
                                return {
                                    tsk_id: task.tsk_id,
                                    tsk_no: task.tsk_no,
                                    task_name: task.task_name,
                                    details: task.task_details,
                                    responsible_person: {
                                        subject_uuid: task.responsible_person_uuid,
                                        first_name: task.first_name,
                                        last_name: task.last_name
                                    },
                                    completion_date: task.completion_date,
                                    status: task.status,
                                    status_id: task.status_id,
                                    save_task_to_library: 0,
                                    progress: resolveTaskStatus(findIndex(state.actionTaskStatusList, ['value', task.status_id]))
                                };
                            }),
                            progress: resolvePlanProgress(action.data[1], state.actionTaskStatusList)
                        };
                    }
                }
            case 'EVENT_ACTION_PLAN_LIST':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        actionPlanPreselectList: action.data,
                    };
                }
            case 'EVENT_ACTION_PLAN_LIST_SELECT':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        plan_name: state.action_plan_preselect.plan_name,
                        description: state.action_plan_preselect.plan_summary
                    };
                }
            case 'EVENT_ACTION_PLAN_LIST_RESET':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        actionPlanPreselectList: [],
                        action_plan_preselect: null
                    };
                }
            case 'EVENT_ACTION_PLAN_LIBRARY_DETAILS':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        'planLib.pln_id': action.plan.pln_id,
                        'planLib.pln_no': action.plan.pln_no,
                        'planLib.plan_name': action.plan.plan_name,
                        'planLib.description': action.plan.plan_summary
                    };
                }
            case 'EVENT_ACTION_PLAN_LIBRARY_RESET':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        'planLib.pln_id': null,
                        'planLib.pln_no': null,
                        'planLib.plan_name': null,
                        'planLib.description': null
                    };
                }
            case 'EVENT_ACTION_TASK_LIST':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        actionTaskPreselectList: action.data,
                    };
                }
            case 'EVENT_ACTION_TASK_LIST_SELECT':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        task_name: state.action_task_preselect.task_name,
                        task_details: state.action_task_preselect.task_details
                    };
                }
            case 'EVENT_ACTION_TASK_LIST_RESET':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        actionTaskPreselectList: [],
                        action_task_preselect: null
                    };
                }
            case 'EVENT_ACTION_TASK_ASSIGN':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        tasks: [...state.tasks, action.task],
                        progress: resolvePlanProgress([...state.tasks, action.task], state.actionTaskStatusList)
                    };
                }
            case 'EVENT_ACTION_TASK_UPDATE':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        tasks: state.tasks.map((item, idx) => {
                            if (idx !== action.data.idx) {
                                return item;
                            }
                            return {
                                task_name: action.data.task_name,
                                details: action.data.details,
                                responsible_person: action.data.responsible_person,
                                completion_date: action.data.completion_date,
                                status: find(state.actionTaskStatusList, ['value', action.data.status_id]).label,
                                status_id: action.data.status_id,
                                save_task_to_library: action.data.save_task_to_library,
                                progress: 0
                            };
                        })
                    };
                }
            case 'EVENT_ACTION_TASK_REMOVE':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        tasks: state.tasks.filter((item, index) => index !== action.index)
                    };
                }
            case 'EVENT_ACTION_TASK_RESET':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        task_name: null,
                        task_details: null,
                        responsible_person: null,
                        completion_date: null,
                        save_task_to_library: 0
                    };
                }
            case 'EVENT_ACTION_TASK_DETAILS':
                if (action.id === 'eventActionPlan') {
                    const task = state.tasks[action.index];
                    return {
                        ...state,
                        'actionTask.tsk_id': task.tsk_id,
                        'actionTask.tsk_no': task.tsk_no,
                        'actionTask.task_name': task.task_name,
                        'actionTask.details': task.details,
                        'actionTask.responsible_person': task.responsible_person,
                        'actionTask.completion_date': task.completion_date,
                        'actionTask.status': task.status,
                        'actionTask.status_id': task.status_id,
                        'actionTask.save_task_to_library': task.save_task_to_library,
                        actionTaskResponsiblePersons: [task.responsible_person]
                    };
                }
            case 'EVENT_ACTION_TASK_DETAILS_RESET':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        'actionTask.tsk_id': null,
                        'actionTask.tsk_no': null,
                        'actionTask.task_name': null,
                        'actionTask.details': null,
                        'actionTask.responsible_person': null,
                        'actionTask.completion_date': null,
                        'actionTask.status': null,
                        'actionTask.status_id': null,
                        'actionTask.save_task_to_library': 0,
                        actionTaskResponsiblePersons: []
                    };
                }
            case 'EVENT_ACTION_TASK_STATUS_LIST':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        actionTaskStatusList: action.data.map((item) => {
                            return { code: item.category_code, value: item.category_id, label: item.category_name };
                        })
                    };
                }
            case 'EVENT_ACTION_TASK_LIBRARY_DETAILS':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        'taskLib.tsk_id': action.task.tsk_id,
                        'taskLib.tsk_no': action.task.tsk_no,
                        'taskLib.task_name': action.task.task_name,
                        'taskLib.details': action.task.task_details
                    };
                }
            case 'EVENT_ACTION_TASK_LIBRARY_RESET':
                if (action.id === 'eventActionPlan') {
                    return {
                        ...state,
                        'taskLib.tsk_id': null,
                        'taskLib.tsk_no': null,
                        'taskLib.task_name': null,
                        'taskLib.details': null
                    };
                }
            default:
                return state;
        }
    }
    return state;
};
