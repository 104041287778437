import { IDS } from '../pnp-accred-action-id.constants';
import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import * as PnpAccredListActions from './details.actions';
import {GRID_RELOAD} from "../../../../common/grid/grid.constants";

class PnpAccredDetailsGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter, $mdDialog, $scope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
    }

    $onInit() {
        this.module = 'pnp';
        this.identifier = IDS.pnpAccred;
        this.statePath = IDS.pnpAccredDetailsGrid;
        this.filterTemplate = './list-grid-filter.html';
        this.exportComponent = false;
        this.title = false;
        this.icon = false;
        this.confirmDialogOnRemove = true;
        //this.autoload = false;
        this.filterOpen = true;
        this.filterExpandButtonVisible = false;

        this.columns = [
            {title: '#', mapper: null},
            {title: this.$filter('translate')('COMMON.DOCUMENT.DCR_NUMBER'),    mapper: 'doc_num'},
            {title: this.$filter('translate')('ACCREDITATION.COMMON.STANDARD'), mapper: 'standard_name'},
            {title: this.$filter('translate')('COMMON.FIELD.NAME'),             mapper: 'filename'},
            {title: this.$filter('translate')('COMMON.FIELD.UPLOADED_BY'),      mapper: 'uploaded_by'},
            {title: this.$filter('translate')('COMMON.DATE.ISSUE_DATE'),        mapper: 'uploaded_at'},
            {title: this.$filter('translate')('COMMON.FIELD.APPROVED_BY'),      mapper: 'approved_by'},
            {title: this.$filter('translate')('COMMON.FIELD.APPROVAL_DATE'),    mapper: 'last_validation_at'},
            {title: this.$filter('translate')('COMMON.DATE.DUE_DATE'),          mapper: 'due_date'},
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), DocumentsListActions)(this);

        this.$scope.$on('LOCATION_SUBJECT_SELECTED', this.onLocationSelected.bind(this));

//      this.dataAction = this.fetchPNPDocumentsListGrid;
        this.init();
    }

    onLocationSelected(event, location) {
        if(['hof','slo','vsl'].includes(location.in_subject_basic_type_code)) {
            this.filter.loc_uuid = location.in_subject_uuid;
            this.$ngRedux.dispatch({
                type: GRID_RELOAD,
                id: IDS.pnpAccred,
                path: IDS.pnpAccredListGrid,
            })
        }

    }

    $onDestroy() {
        this.destroy();
    }

}

PnpAccredDetailsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter', '$mdDialog', '$scope'];

export const pnpAccredDetailsGrid = {
    bindings: {
        onSelect: '&',

    },
    controller: PnpAccredDetailsGridController,
    ...gridTpl
}
