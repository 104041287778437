import { TabsReducer } from '../common/tabs/tabs.reducer';

export const artifactEvaluationTabs = {
    'app.mammoQualityControl.artifactEvaluation': Object.assign(TabsReducer(), {
        tabs: [
            {
                state: 'app.mammoQualityControl.artifactEvaluation.details',
                title: 'Add Artifact Evaluation',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Add Artifact Evaluation'}
                }
            },
            {
                state: 'app.mammoQualityControl.artifactEvaluation.listing',
                title: 'Artifact Evaluations',
                visible: true,
                disabled: false,
                loading: false
            }
        ]
    })
};
