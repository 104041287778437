/* @ngInject */
export function AddressFactory(Country) {
    return function Address() {
        this.address1 = null;
        this.address2 = null;
        this.address3 = null;
        this.suburb = null;
        this.postcode = null;
        this.country = new Country();
    };
}
