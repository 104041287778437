import moment from 'moment';
import isObject from "lodash/isObject";

/* @ngInject */
export function ServiceContractService($http, API_BASE, Helpers, ServiceContract, ServiceContractType, ServiceContractStatus, ServiceContractVisit, Subject, EquipmentType, EquipmentCategory, Equipment, EquipmentSparePart) {

    this.getContracts = function(filter) {
        return $http({
            url: API_BASE + "/service-contracts",
            method: "GET",
            params: {
                contract_no:    filter.number == '' ? null : filter.number,
                cty_id:         filter.type,
                loc_uuid:       filter.location,
                offset:         filter.offset,
                limit:          filter.limit,
                exportFormat:   filter.exportFormat,
                exportColumns:  filter.exportColumns
            }
        }).then(function(result) {
            if (filter.exportFormat) {
                return result.data;
            }
            else {
                var items = result.data.rows.map(function(item) {
                    var contract = new ServiceContract();
                    contract.id = item.con_id;
                    contract.number = item.contract_no;
                    contract.vendor.uuid = item.vendor_uuid;
                    contract.vendor.name = item.vendor_name;
                    contract.createDate = item.created_at;
                    contract.startDate = Helpers.formatDate(item.start_date);
                    contract.expiryDate = Helpers.formatDate(item.exp_date);
                    contract.attendanceHoursFrom = item.attendance_hours_start;
                    contract.attendanceHoursTo = item.attendance_hours_end;
                    contract.attendanceAdditionalCostsAmount = item.additional_costs;
                    contract.attendanceAdditionalCostsType = item.additional_costs_type;
                    contract.responseTime = item.quoted_response_time;
                    contract.partsDeliveryTime = item.quoted_delivery_time;
                    contract.price = item.cost;
                    contract.url = item.contract_uri;

                    var contractType    = new ServiceContractType();
                    contractType.id     = item.contract_type_id;
                    contractType.name   = item.contract_type_name;
                    contract.type       = contractType;

                    var contractStatus  = new ServiceContractStatus();
                    contractStatus.id   = item.status_id;
                    contractStatus.code = item.status_code;
                    contractStatus.name = item.status;
                    contract.status     = contractStatus;

                    return contract;
                });
                return {total: result.data.total, items: items};
            }
        });
    };

    this.getContractTypes = function() {
        return $http({
            url: API_BASE + "/service-contract/contract-types",
            method: "GET"
        }).then(function(result) {
            return result.data.map(function(item) {
                var contractType = new ServiceContractType();
                contractType.id = item.contract_type_id;
                contractType.code = item.contract_type_code;
                contractType.name = item.contract_type_name;
                return contractType;
            });
        }, function(error) {
            return error;
        });
    };

    this.getContractStatuses = function() {
        return $http({
            url: API_BASE + "/service-contract/contract-statuses",
            method: "GET"
        }).then(function(result) {
            return result.data.map(function(item) {
                var contractStatus  = new ServiceContractStatus();
                contractStatus.id   = item.contract_type_id;
                contractStatus.code = item.contract_type_code;
                contractStatus.name = item.contract_type_name;
                return contractStatus;
            });
        });
    };

    this.createContract = function(contract) {
        return $http({
            url: API_BASE + "/service-contract/create",
            method: "POST",
            data: {
                type: contract.type.id,
                vendorUuid: contract.vendor.uuid,
                number: contract.number,
                startDate: Helpers.formatDate(contract.startDate),
                numberOfYears: contract.numberOfYears,
                //expiryDate: Helpers.formatDate(contract.expiryDate),
                telephoneSupport: contract.telephoneSupport ? 1 : 0,
                attendanceCoverage: contract.attendanceCoverage ? 1 : 0,
                attendanceHoursFrom: contract.getAttendanceHoursFrom(),
                attendanceHoursTo: contract.getAttendanceHoursTo(),
                attendanceAdditionalCostsAmount: contract.attendanceAdditionalCostsAmount,
                attendanceAdditionalCostsType: contract.attendanceAdditionalCostsType,
                responseTime: contract.getResponseTime(),
                partsDeliveryTime: contract.getPartsDeliveryTime(),
                price: contract.price,
                initialResponseTime: contract.initialResponseTime,
                onsiteResponseTime: contract.onsiteResponseTime,
                url: contract.url
            }
        }).then(({ data }) => {
            return _.get(data, 'params', data);
        });
    };

    this.updateContract = function(contract) {
        return $http({
            url: API_BASE + "/service-contract/update",
            method: "POST",
            data: {
                id: contract.id,
                type: contract.type.id,
                vendorUuid: contract.vendor.uuid,
                number: contract.number,
                startDate: Helpers.formatDate(contract.startDate),
                numberOfYears: contract.numberOfYears,
                //expiryDate: Helpers.formatDate(contract.expiryDate),
                telephoneSupport: contract.telephoneSupport ? 1 : 0,
                attendanceCoverage: contract.attendanceCoverage ? 1 : 0,
                attendanceHoursFrom: contract.getAttendanceHoursFrom(),
                attendanceHoursTo: contract.getAttendanceHoursTo(),
                attendanceAdditionalCostsAmount: contract.attendanceAdditionalCosts ? contract.attendanceAdditionalCostsAmount : 0,
                attendanceAdditionalCostsType: contract.attendanceAdditionalCostsType,
                responseTime: contract.getResponseTime(),
                partsDeliveryTime: contract.getPartsDeliveryTime(),
                price: contract.price,
                initialResponseTime: contract.initialResponseTime,
                onsiteResponseTime: contract.onsiteResponseTime,
                url: contract.url
            }
        }).then(function(result) {
            return parseInt(result.data.status);
        }, function(error) {
            return error;
        });
    };

    this.getContract = function(id) {
        return $http({
            url: API_BASE + "/service-contract/contract",
            method: "GET",
            params: {id: id}
        }).then(function(result) {
            var contract = new ServiceContract();
            const body = isObject(result.data) ? result.data : JSON.parse(result.data);
            const estMonths = isObject(body.est_months) ? body.est_months.est_months : JSON.parse(body.estimatedMonths).est_months;
            _.each(body.rows, function(item, index) {
                switch (index) {
                    case 0:
                        var contractData = _.first(item);
                        contract.id = id;
                        contract.type.id = contractData.contract_type_id;
                        contract.type.name = contractData.contract_type_name;
                        contract.number = contractData.contract_no;
                        contract.vendor.uuid = contractData.vendor_uuid;
                        contract.vendor.name = contractData.vendor_name;
                        contract.createDate = Helpers.formatDate(contractData.created_at);
                        contract.startDate = Helpers.formatDate(contractData.start_date);
                        contract.expiryDate = Helpers.formatDate(contractData.exp_date);
                        contract.numberOfYears = contractData.number_of_years;
                        contract.telephoneSupport = contractData.phone_support ? true : false;
                        //contract.attendanceCoverage = parseInt(contractData.business_hours_attandance_only) ? true : false;
                        contract.attendanceCoverage = false;
                        contract.attendanceHoursFrom = contractData.attendance_hours_start;
                        contract.attendanceHoursFromPeriod = moment(contract.attendanceHoursFrom, 'hh:mm:ss').format('A');
                        contract.attendanceHoursTo = contractData.attendance_hours_end;
                        contract.attendanceHoursToPeriod = moment(contract.attendanceHoursTo, 'hh:mm:ss').format('A');
                        contract.attendanceAdditionalCosts = parseFloat(contractData.additional_costs) ? true : false;
                        contract.attendanceAdditionalCostsAmount = parseFloat(contractData.additional_costs);
                        contract.attendanceAdditionalCostsType = contractData.additional_costs_type;
                        contract.responseTime = contractData.quoted_response_time;
                        contract.partsDeliveryTime = contractData.quoted_delivery_time;
                        contract.price = parseFloat(contractData.cost);
                        contract.initialResponseTime = contractData.initial_response_time;
                        contract.onsiteResponseTime = contractData.onsite_response_time;
                        contract.url = contractData.contract_uri;
                        contract.equipmentSparePartsUrl = contractData.spare_parts_list_url;
                        contract.equipmentSparePartsDocument = _.isNull(contract.equipmentSparePartsUrl) ? 0 : 1;

                        var contractStatus  = new ServiceContractStatus();
                        contractStatus.id   = contractData.status_id;
                        contractStatus.code = contractData.status_code;
                        contractStatus.name = contractData.status_name;
                        contract.status     = contractStatus;
                        break;
                    case 1:
                        contract.accounts = item.map(function(obj) {
                            var account = new Subject();
                            account.in_subject_uuid = obj.account_id;
                            account.in_username_par = obj.account_name;
                            return account;
                        });
                        // contract.setEstimatedTimeFrames(JSON.parse(body.estimatedMonths).est_months.map(function(item) {
                        contract.setEstimatedTimeFrames(estMonths.map(function(item) {
                            return moment(item).format('MMMM YYYY');
                        }));
                        break;
                    case 2:
                        contract.equipmentCategories = item.map(function(obj) {
                            var category = new EquipmentCategory();
                            category.id = obj.category_id;
                            category.name = obj.category_name;
                            category.par_id = obj.category_par_id;
                            return category;
                        });
                        break;
                    case 3:
                        _.each(item, function(obj) {
                            var month = moment(obj.estimated_month).format('MMMM YYYY');
                            var schedule = contract.getVisitSchedule(obj.acc_uuid, month);
                            var date = Helpers.formatDate(obj.scheduled_date);
                            var allDayEvent = parseInt(obj.all_day) ? true : false;
                            var existingVisit = schedule.getVisit(date, allDayEvent, obj.start_time, obj.end_time);
                            var equipment = new Equipment();
                            if (_.isNull(existingVisit)) {
                                var visit = new ServiceContractVisit(obj.acc_uuid, month);
                                visit.id = obj.sda_id;
                                visit.subjectName = obj.subject;
                                visit.locationName = obj.location;
                                visit.allDayEvent = allDayEvent;
                                visit.date = date;
                                visit.startTime = obj.start_time;
                                visit.startTimePeriod = null; // TODO
                                visit.endTime = obj.end_time;
                                visit.endTimePeriod = null; // TODO
                                equipment.equipment_id = obj.equ_id;
                                equipment.user_friendly_id = obj.user_friendly_id;
                                equipment.serial = obj.equipment_serial;
                                equipment.category.name = obj.category;
                                equipment.manufacturer.name = obj.manufacturer;
                                equipment.model.name = obj.model;
                                visit.equipment.push(equipment);
                                schedule.visits.push(visit);
                            }
                            else {
                                equipment.equipment_id = obj.equ_id;
                                equipment.user_friendly_id = obj.user_friendly_id;
                                equipment.serial = obj.equipment_serial;
                                equipment.category.name = obj.category;
                                equipment.manufacturer.name = obj.manufacturer;
                                equipment.model.name = obj.model;
                                existingVisit.equipment.push(equipment);
                            }
                        });
                        break;
                    case 4:
                        contract.equipmentSpareParts = item.map(function(obj) {
                            var sparePart = new EquipmentSparePart();
                            sparePart.id = obj.spa_id;
                            sparePart.name = obj.name;
                            return sparePart;
                        });
                        contract.equipmentSparePartsIncluded = contract.equipmentSpareParts.length ? 1 : 0;
                        break;
                }
            });
            return contract;
        }, function(error) {
            return error;
        });
    };

    this.finalizeContract = function(id) {//todo
        return $http({
            url: API_BASE + "/service-contract/finalize",
            method: "GET",
            params: {id: id}
        }).then(function(response) {
            var data = _.first(response.data.result);
            var contractStatus  = new ServiceContractStatus();
            contractStatus.id   = data.status_id;
            contractStatus.code = data.status_code;
            contractStatus.name = data.status_name;
            return contractStatus;
        });
    };

    this.addAccount = function(contractId, accountUuid) {
        return $http({
            url: API_BASE + "/service-contract/account-add",
            method: "POST",
            data: {
                contractId: contractId,
                accountUuid: accountUuid
            }
        }).then(function(result) {
            return result.data.status;
        }, function(error) {
            return error;
        });
    };

    this.removeAccount = function(contractId, accountUuid) {
        return $http({
            url: API_BASE + "/service-contract/account-remove",
            method: "POST",
            data: {
                contractId: contractId,
                accountUuid: accountUuid
            }
        }).then(function(result) {
            return result.data.status;
        }, function(error) {
            return error;
        });
    };

    this.getEquipmentTypesWithCategories = function(contractTypeId, contractId) {
        return $http({
            url: API_BASE + "/equipment/types",
            method: "GET",
            params: {
                parent: null,
                direct: null,
                contractTypeId: contractTypeId,
                contractId: contractId
            }
        }).then(function(result) {
            const body = isObject(result.data.items) ? result.data.items : result.data;
            return body.reduce(function(result, item) {
                if (_.isNull(item.par_id)) {
                    var equipmentType = new EquipmentType();
                    equipmentType.id = item.ety_id;
                    equipmentType.par_id = item.par_id;
                    equipmentType.code = item.code;
                    equipmentType.name = item.name;
                    result.push(equipmentType);
                }
                else {
                    var equipmentCategory = new EquipmentCategory();
                    equipmentCategory.id = item.ety_id;
                    equipmentCategory.par_id = item.par_id;
                    equipmentCategory.code = item.code;
                    equipmentCategory.name = item.name;
                    equipmentCategory.selected = 0;

                    var equipmentType = _.find(result, ['id', equipmentCategory.par_id]);
                    if (equipmentType) equipmentType.categories.push(equipmentCategory);
                }
                return result;
            }, []).filter(function(item) {
                return item.categories.length > 0;
            });
        }, function(error) {
            return error;
        });
    };

    this.addEquipmentCategory = function(contractId, categoryId) {
        return $http({
            url: API_BASE + "/service-contract/equipment-category-add",
            method: "POST",
            data: {
                contractId: contractId,
                categoryId: categoryId
            }
        }).then(function(result) {
            return result.data.status;
        });
    };

    this.removeEquipmentCategory = function(contractId, categoryId) {
        return $http({
            url: API_BASE + "/service-contract/equipment-category-remove",
            method: "POST",
            data: {
                contractId: contractId,
                categoryId: categoryId
            }
        }).then(function(result) {
            return result.data.status;
        }, function(error) {
            return error;
        });
    };

    this.addEquipment = function(contractId, equipmentId) {
        return $http({
            url: API_BASE + "/service-contract/equipment-add",
            method: "POST",
            data: {
                contractId: contractId,
                equipmentId: equipmentId
            }
        }).then(function(response) {
            return response;
        });
    };

    this.removeEquipment = function(contractId, equipmentId) {
        return $http({
            url: API_BASE + "/service-contract/equipment-remove",
            method: "POST",
            data: {
                contractId: contractId,
                equipmentId: equipmentId
            }
        }).then(function(result) {
            return result.data.status;
        }, function(error) {
            return error;
        });
    };

    this.getEquipment = function(contract, filter) {

        var accounts;
        if (_.isNull(filter.account)) {
            accounts = contract.accounts.map(function(item) {
                return "'" + item.in_subject_uuid + "'";
            }).join();
        }
        else accounts = "'" + filter.account.in_subject_uuid + "'";

        var categories;
        if (_.isNull(filter.category)) {
            categories = contract.equipmentCategories.map(function(item) {
                return item.id;
            }).join();
        }
        else {
            categories = filter.category.id;
        }

        return $http({
            url: API_BASE + "/service-contract/equipment-get",
            method: "POST",
            data: {
                accounts: accounts,
                categories: categories,
                offset: filter.offset,
                limit: filter.limit
            }
        }).then(function(result) {
            var items = result.data.rows.map(function(item) {
                var equipment = new Equipment();
                equipment.equipment_id = item.equ_id;
                equipment.user_friendly_id = item.equipment_id;
                equipment.type.id = item.type_id;
                equipment.category.id = item.category_id;
                equipment.serial = item.serial;
                equipment.account.uuid = item.account_uuid;
                equipment.account.name = item.account_name;
                equipment.manufacturer.name = item.manufacturer;
                equipment.category.name = item.category;
                equipment.model.name = item.model;
                return equipment;
            });
            return {total: result.data.total, items: items};
        });
    };

    this.getSelectedEquipment = function(contract) {

        var accounts = contract.accounts.map(function(item) {
            return "'" + item.in_subject_uuid + "'";
        }).join();

        var categories = contract.equipmentCategories.map(function(item) {
            return item.id;
        }).join();

        return $http({
            url: API_BASE + "/service-contract/equipment-get-selected",
            method: "POST",
            data: {
                contractId: contract.id,
                accounts: accounts,
                categories: categories
            }
        }).then(function(result) {
            const data = isObject(result.data.items) ? result.data.items: result.data;
            var items = data.rows.map(function(item) {
                var equipment = new Equipment();
                equipment.equipment_id = item.equ_id;
                equipment.user_friendly_id = item.equipment_id;
                equipment.type.id = item.type_id;
                equipment.category.id = item.category_id;
                equipment.serial = item.serial;
                equipment.account.uuid = item.account_uuid;
                equipment.account.name = item.account_name;
                equipment.manufacturer.name = item.manufacturer;
                equipment.category.name = item.category;
                equipment.model.name = item.model;
                equipment.selected = 0;
                equipment.scheduled = false;
                return equipment;
            });
            return {total: data.total, items: items};
        }, function(error) {
            return error;
        });
    };

    this.createEquipmentSparePart = function(name) {
        return $http({
            url: API_BASE + "/service-contract/equipment-spare-part-create",
            method: "POST",
            data: {name: name}
        }).then(function(result) {
            var sparePart = new EquipmentSparePart();
            const body = isObject(result.data.params) ? {...result.data.params, status: result.data.status} : result.data;
            sparePart.id = body.spa_id;
            sparePart.name = name;
            return sparePart;
        }, function(error) {
            return error;
        });
    };

    this.assignEquipmentSparePart = function(contractId, sparePartId) {
        return $http({
            url: API_BASE + "/service-contract/equipment-spare-part-assign",
            method: "POST",
            data: {
                contractId: contractId,
                sparePartId: sparePartId
            }
        }).then(function(result) {
            return result.data.status;
        }, function(error) {
            return error;
        });
    };

    this.removeEquipmentSparePart = function(contractId, sparePartId) {
        return $http({
            url: API_BASE + "/service-contract/equipment-spare-part-remove",
            method: "POST",
            data: {
                contractId: contractId,
                sparePartId: sparePartId
            }
        }).then(function(result) {
            return result.data.status;
        }, function(error) {
            return error;
        });
    };

    this.getEquipmentSpareParts = function() {
        return $http({
            url: API_BASE + "/service-contract/equipment-spare-parts",
            method: "GET"
        }).then(function(result) {
            const body = isObject(result.data) ? result.data : JSON.parse(result.data);
            // return result.data.map(function(item) {
            return body.map(function(item) {
                var sparePart = new EquipmentSparePart();
                sparePart.id = item.spa_id;
                sparePart.name = item.name;
                sparePart.selected = 0;
                return sparePart;
            });
        }, function(error) {
            return error;
        });
    };

    this.addEquipmentSparePartDocument = function(contractId, url) {
        return $http({
            url: API_BASE + "/service-contract/equipment-spare-part-document",
            method: "POST",
            data: {
                contractId: contractId,
                url: url
            }
        }).then(function(result) {
            return result.data.status;
        }, function(error) {
            return error;
        });
    };

    this.setEstimatedTimeFrames = function(contract) {
        return $http({
            url: API_BASE + "/service-contract/estimated-time-frames",
            method: "POST",
            data: {
                contractId: contract.id,
                numOfVisits: contract.numberOfVisits
            }
        }).then(function(result) {
            const estMonths = isObject(result.data.params) ? result.data.params.est_months : JSON.parse(result.data.est_months).est_months;
            var months = estMonths.map(function(item) {
                return moment(item).format('MMMM YYYY');
            });
            return {months: months, status: result.data.status};
        }, function(error) {
            return error;
        });
    };

    this.getSubjectAndLocation = function(contractId, accountUuid) {
        return $http({
            url: API_BASE + "/service-contract/subject-and-location",
            method: "GET",
            params: {
                contractId: contractId,
                accountUuid: accountUuid
            }
        }).then(function({ data }) {
            data = _.get(data, 'params', data);
            const body = isObject(data) ? data : JSON.parse(data);
            return {
                subject: body.subject,
                location: body.location,
                status: body.status
            };
        }, function(error) {
            return error;
        });
    };

    this.scheduleEquipmentVisit = function(contractId, visit) {
        return $http({
            url: API_BASE + "/service-contract/schedule-equipment-visit",
            method: "POST",
            data: {
                contractId: contractId,
                estimatedMonth: moment(visit.month, 'MMMM YYYY').format('YYYY-MM-01'),
                visitsDate: Helpers.formatDate(visit.date),
                equipmentIds: visit.getEquipmentIds(),
                subject: visit.subject,
                location: visit.location,
                startTime: visit.getStartTime(),
                endTime: visit.getEndTime(),
                allDayEvent: visit.allDayEvent ? 1 : 0
            }
        }).then(function(result) {
            return result.data.status;
        }, function(error) {
            return error;
        });
    };

    this.unscheduleEquipmentVisit = function(contractId, month, equipmentId) {
        return $http({
            url: API_BASE + "/service-contract/unschedule-equipment-visit",
            method: "POST",
            data: {
                contractId: contractId,
                estimatedMonth: moment(month, 'MMMM YYYY').format('YYYY-MM-01'),
                equipmentId: equipmentId
            }
        }).then(function(result) {
            return result.data.status;
        }, function(error) {
            return error;
        });
    };
}
