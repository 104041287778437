/* @ngInject */
export function accApprovalForm($timeout, $http, ACC_ASSESSMENT_ITEM_TYPE, Notifier, DocumentManager, ACC_ASSESSMENT_ITEM_APPROVAL_STATUS) {
    return {
        restrict: 'E',
        scope: {
            accDocument: '=accDocument',
            isApprovalUser: '=isApprovalUser',
            dirty: '=dirty'
        },
        template: require('./acc-approval-form.html'),
        link: function (scope, element, attrs) {
            scope.APPROVED = ACC_ASSESSMENT_ITEM_APPROVAL_STATUS.APPROVED;
            scope.NOT_APPROVED = ACC_ASSESSMENT_ITEM_APPROVAL_STATUS.NOT_APPROVED;
            scope.NOT_APPLICABLE = ACC_ASSESSMENT_ITEM_APPROVAL_STATUS.NOT_APPLICABLE;

        }
    };
}
