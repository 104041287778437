import { DATE_FORMAT } from '../../../../common/utils/util.constants';

/* @ngInject */
export function EquipmentServiceUltrasound($http, API_BASE, $ngRedux, Ultrasound, EquipmentCategory, Transducer, Helpers) {

    this.addEquipment = function(data) {
        var ldata = {
            equipment_id: data.user_friendly_id,
            serial: data.serial,
            wave_length: data.waveLength,
            installation_date: Helpers.formatDate(data.equipmentInstallationDate),
            cap_sens_exp_date: Helpers.formatDate(data.capitalSensitivityExpiryDate)
        };

        if(data.location != null && data.location.uuid != null){
            ldata.loc_uuid = data.location.uuid;
        }
        if(data.category != null && data.category.id != null){
            ldata.ety_id = parseInt(data.category.id);
        }
        if(data.model != null && data.model.id != null){
            ldata.emo_id = data.model.id;
        }
        if(data.registrationData != null && data.registrationData.number != null){
            ldata.reg_number = data.registrationData.number;
            ldata.reg_date = Helpers.formatDate(data.registrationData.date);
            ldata.reg_due_date = Helpers.formatDate(data.registrationData.dueDate);
            ldata.reg_details = data.registrationData.details;
        }
        ldata.compliance_testing_required = data.complianceTestingRequired ? data.complianceTestingRequired : false;
        ldata.premises_certification_required = data.premisesCertificationRequired ? data.premisesCertificationRequired : false;
        ldata.servicing_required = data.servicingRequired ? data.servicingRequired : false;
        ldata.asset_id = data.assetId ? data.assetId : null;

        return $http({
            url: API_BASE + "/equipment/equipment-add",
            method: "POST",
            data: ldata
        }).then(function(result) {
            let params = _.isObject(result.data.params) ? result.data.params : JSON.parse(result.data.params);
            return params.equ_id;
        }, function(error) {
            return error;
        });
    };

    this.getEquipmentDetails = function(id) {
        return $http({
            url: API_BASE + "/equipment/" + id,
            method: "GET"
        }).then(function(data) {
            let employeeLocations = data.data[2][1] ? data.data[2] : _.last(data.data);

            var elemArray = data.data[0];
            var elem;

            if(elemArray!=null) {
                if (elemArray.length === 1) {
                    elem = elemArray[0];
                }
                else {
                    elem = elemArray;
                }
            }

            if(elem==null){
                return;
            }

            var item = new Ultrasound();
            item.equipment_id = elem.equ_id;
            item.user_friendly_id = elem.equipment_id;
            item.barcode = _.isNull(elem.barcode) ? elem.equipment_id : elem.barcode;
            item.transaction_id = elem.transaction_id;
            item.account.id = elem.account_id;
            item.account.name = elem.account_name;
            item.account.uuid = elem.account_uuid;
            item.account.lspn = elem.lspn;
            item.certificateData.expiryDate = Helpers.formatDate(elem.certification_expire_date);
            item.certificateData.certificate = elem.certification_number;
            item.equipmentInstallationDate =  Helpers.formatDate(elem.installation_date);
            item.capitalSensitivityExpiryDate = Helpers.formatDate(elem.cap_sens_exp_date);
            item.serviceDueDate = elem.service_due_date ? Helpers.formatDate(elem.service_due_date) : null;
            if (typeof elem.cap_sens_exp_date === 'undefined')
                item.capitalSensitivityExpiryDateShow = false;
            else
                item.capitalSensitivityExpiryDateShow = true;
            item.serial = elem.equ_serial;
            item.category.id = elem.equipment_category_id;
            item.category.code = elem.equipment_category_code;
            item.category.name = elem.equipment_category_name;
            item.category.isRegulated = elem.equipment_category_is_regulated;
            item.model.id = elem.equipment_model_id;
            item.model.name = elem.equipment_model_name;
            item.type.id = parseInt(elem.equipment_basic_category_id);
            item.type.code = elem.equipment_basic_category_code;
            item.type.name = elem.equipment_basic_category_name;
            item.manufacturer.code = elem.manufacturer_code;
            item.manufacturer.id = elem.manufacturer_id;
            item.manufacturer.name = elem.manufacturer_name;
            item.manufacturer.description = elem.manufacturer_description
            item.registrationData.number = elem.reg_number;
            item.registrationData.date = Helpers.formatDate(elem.reg_date);
            item.registrationData.dueDate = Helpers.formatDate(elem.reg_next_due_date);
            item.registrationData.details = elem.reg_details;
            item.status = elem.status;
            item.vendor.id = elem.vendor_uuid;
            item.vendor.name = elem.vendor_name;
            item.location.name = elem.dep_name;
            item.location.uuid = elem.dep_uuid;
            item.disposalDescription = elem.disposal_description;
            item.wayOfDisopsal = elem.disposal_way;
            item.disposal = elem.status === 'dis';

            item.complianceTestingRequired = parseInt(elem.compliance_required) !== 0;
            item.premisesCertificationRequired = parseInt(elem.premises_required) !== 0;
            item.servicingRequired = parseInt(elem.servicing_required) !== 0;
            item.assetID = elem.asset_id;

            _.each(data.data[1], function(obj) {
                var transducer = new Transducer();
                transducer.equipment_id = obj.equ_id;
                transducer.serial = obj.serial;
                transducer.type.id = parseInt(obj.equipment_basic_category_id);
                transducer.type.code = obj.equipment_basic_category_code;
                transducer.type.name = obj.equipment_basic_category_name;
                transducer.category.id = obj.category_id;
                transducer.category.name = obj.category;
                transducer.manufacturer.id = obj.manufacturer_id;
                transducer.manufacturer.name = obj.manufacturer;
                transducer.model.id = obj.model_id;
                transducer.model.name = obj.model;
                transducer.mhz_range_from = obj.mhz_range == 'null-null' ? null : _.first(obj.mhz_range.split('-'));
                transducer.mhz_range_to = obj.mhz_range == 'null-null' ? null : _.last(obj.mhz_range.split('-'));
                item.transducers.push(transducer);
            });

            $ngRedux.dispatch({
                type: 'EQUIPMENT_DATA',
                id: 'equAssetCost',
                data: {
                    equAssetCost: {
                        equ_id: elem.equ_id,
                        accountId: elem.account_id,
                        visualId: elem.equipment_id,
                        cost_centre_code: elem.cost_centre_code,
                        cost_centre_name: elem.cost_centre_name,
                        purchaseDate: Helpers.formatDate(elem.purchase_date),
                        purchase_order_number: elem.purchase_order_number,
                        installation_date: Helpers.formatDate(elem.installation_date),
                        cap_sens_exp_date: Helpers.formatDate(elem.cap_sens_exp_date),
                        warranty_period: elem.warranty_period,
                        warranty_exp_date: elem.warranty_exp_date,
                        assetCost: elem.asset_cost,
                        act_type_code: elem.act_type_code,
                        estimated_residual_value: elem.estimated_residual_value,
                        tax_estimated_useful_life: elem.tax_estimated_useful_life,
                        depreciation_rate_per_year: elem.depreciation_rate_per_year,
                        depreciation_type: elem.depreciation_type,
                        service_contract: elem.service_contract,
                        comment: elem.comment,
                        software_version: elem.software_version,
                        man_eof_support_date: elem.man_eof_support_date,
                    },
                },
            });

            return {
                equipment: item,
                locations: employeeLocations,
            };
        });
    };

    this.updateEquipment = function(equipment) {
        const state = $ngRedux.getState();
        const { equAssetCost } = state.equipment;
        return $http({
            url: API_BASE + "/equipment/edit",
            method: "POST",
            data: {
                equ_id: equipment.equipment_id,
                loc_uuid: equipment.location.uuid,
                ety_id: equipment.category.id,
                emo_id: equipment.model.id,
                serial: equipment.serial,

                installation_date: Helpers.formatDate(equipment.equipmentInstallationDate),
                cap_sens_exp_date: Helpers.formatDate(equipment.capitalSensitivityExpiryDate),
                service_due_date: Helpers.formatDate(equipment.serviceDueDate),

                compliance_testing_required: equipment.complianceTestingRequired ? equipment.complianceTestingRequired : false,
                premises_certification_required: equipment.premisesCertificationRequired ? equipment.premisesCertificationRequired : false,
                servicing_required: equipment.servicingRequired ? equipment.servicingRequired : false,
                asset_id: equipment.assetID,
                /**
                 * ASSET COST
                 */
                purchase_date: Helpers.formatDate(equAssetCost.purchaseDate, DATE_FORMAT.OUTPUT),
                purchase_order_number: equAssetCost.purchase_order_number,
                warranty_period: equAssetCost.warranty_period,
                warranty_exp_date: Helpers.formatDate(equAssetCost.warranty_exp_date, DATE_FORMAT.OUTPUT),
                asset_cost: equAssetCost.assetCost,
                act_type_code: equAssetCost.act_type_code,
                estimated_residual_value: equAssetCost.estimated_residual_value,
                depreciation_rate_per_year: equAssetCost.depreciation_rate_per_year,
                tax_estimated_useful_life: equAssetCost.tax_estimated_useful_life,
                depreciation_type: equAssetCost.depreciation_type,
                service_contract: equAssetCost.service_contract,
                comment: equAssetCost.comment,
                software_version: equAssetCost.software_version,
                man_eof_support_date: Helpers.formatDate(equAssetCost.man_eof_support_date, DATE_FORMAT.OUTPUT),
            }
        }).then(function(result) {
            return _.isObject(result.data.params) ? result.data.params : JSON.parse(result.data.params);
        });
    };

    this.getCategories = function() {
        return $http({
            url: API_BASE + "/equipment/ultrasound/categories",
            method: "GET"
        }).then(function(result) {
            var categories = [];
            result.data.forEach(function(item) {
                var category = new EquipmentCategory();
                category.id = item.category_id;
                category.name = item.category_name;
                category.code = item.category_code;
                category.isRegulated = item.category_is_regulated;
                categories.push(category);
            });
            return categories;
        }, function(error) {
            return error;
        });
    };
}
