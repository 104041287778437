import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import { getApplicantRequests } from '../ap-laser-request.actions';
import { AP_REQUEST_FILTER_RENEWAL } from '../../applications-permits.actions.types';
import moment from 'moment';

class ApplicationsPermitsLaserRequestGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'applicationsPermits';
        this.identifier = 'apLaserRequestGrid';
        this.filterTemplate = './ap-laser-request-grid-filter.html';

        this.columns = [
            {title: '#',                    mapper: null},
            {title: 'Applicant',            mapper: ({ title, first_name, last_name }) => `${title} ${first_name} ${last_name}`},
            {title: 'Email',                mapper: 'email'},
            {title: 'Campus',               mapper: 'campus'},
            {title: 'Department',           mapper: 'department'},
            {title: 'Application Type',     mapper: 'application_type'},
            {title: 'Request Date',         mapper: 'application_received_date'},
            {title: 'Approval Date',        mapper: 'approval_date'},
            {title: 'Renewal Date',         mapper: 'renewal_date'},
            {title: 'Status',               mapper: 'request_status_name'},
        ];

        // this.buttons = [
        //     { label: 'Cancel', class: 'btnGridRemove', callback: this.onCancel }
        // ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), { getApplicantRequests }
        )(this);

        this.dataAction = this.getApplicantRequests;
        this.exportExclude = ['Completion Status'];
        this.exportService = this.getApplicantRequests;
        this.filter.identifier = this.identifier;
        this.filter.type = this.type;

        this.filterActions.onRenewalPeriod = ({ input }, period) => {
            if (input) {
                const today = new Date();
                switch (period) {
                    case 30:
                        this.$ngRedux.dispatch({
                            type: AP_REQUEST_FILTER_RENEWAL,
                            id: 'apRequest',
                            filter: {
                                renewalFrom: today,
                                renewalTo: moment(today).add(1, 'M').toDate(),
                            },
                            data: {
                                renewal30Days: 1,
                                renewal60Days: 0,
                                renewal90Days: 0,
                            }
                        });
                        break;
                    case 60:
                        this.$ngRedux.dispatch({
                            type: AP_REQUEST_FILTER_RENEWAL,
                            id: 'apRequest',
                            filter: {
                                renewalFrom: today,
                                renewalTo: moment(today).add(2, 'M').toDate(),
                            },
                            data: {
                                renewal30Days: 0,
                                renewal60Days: 1,
                                renewal90Days: 0,
                            }
                        });
                        break;
                    case 90:
                        this.$ngRedux.dispatch({
                            type: AP_REQUEST_FILTER_RENEWAL,
                            id: 'apRequest',
                            filter: {
                                renewalFrom: today,
                                renewalTo: moment(today).add(3, 'M').toDate(),
                            },
                            data: {
                                renewal30Days: 0,
                                renewal60Days: 0,
                                renewal90Days: 1,
                            }
                        });
                        break;
                }
            } else {
                this.$ngRedux.dispatch({
                    type: AP_REQUEST_FILTER_RENEWAL,
                    id: this.identifier,
                    data: { reset: true }
                });
            }
            this.$ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: this.identifier
            });
        }

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const apLaserRequestGrid = {
    bindings: {
        title: '@',
        type: '@',
        onSelect: '&',
        onCancel: '&',
        onRemove: '&'
    },
    controller: ApplicationsPermitsLaserRequestGridController,
    ...gridTpl
};
