/** @namespace Reducers.Accreditation */

import { IDS }       from '../components/accreditation/accreditation-action-id.constants';
import { TYPES }       from '../components/accreditation/accreditation-action-type.constants';

import {IDS as AUDIT_ACTION_IDS }                   from '../components/accreditation/audit/audit-action-id.constants';
import {PREFIX as AUDIT_ACTION_PREFIX }             from '../components/accreditation/audit/audit-action-type.constants';
import { AuditReducer }                             from '../components/accreditation/audit/audit.reducer';
import { scheduleTabs }                             from "../components/accreditation/audit/schedule/schedule-tabs";

import {IDS as STANDARDS_ACTION_IDS }               from '../components/accreditation/standard/standard-action-id.constants';
import {PREFIX as STANDARDS_ACTION_PREFIX}          from '../components/accreditation/standard/standard-action-type.constants';
import { StandardReducer }                          from '../components/accreditation/standard/standard.reducer';
import { standardTabs }                             from '../components/accreditation/standard/standard-tabs';

import { IDS as SETTINGS_ACTION_IDS }               from '../components/accreditation/settings/settings-action-id.constants';
import { PREFIX as SETTINGS_ACTION_PREFIX }         from '../components/accreditation/settings/settings-action-type.constants';
import { SettingsReducer }                          from '../components/accreditation/settings/settings.reducer';
import { defaultDocumentsTabs }                     from '../components/accreditation/settings/default-documents/default-documents-tabs';
import { hofRequiredDocsTabs }                      from '../components/accreditation/settings/hof-required-docs/hof-required-docs-tabs';
import { sloRequiredDocsTabs }                      from '../components/accreditation/settings/slo-required-docs/slo-required-docs-tabs';
import { enabledAccountsTabs }                      from '../components/accreditation/settings/enabled-accounts/enabled-accounts-tabs';

import { IDS as ASSESSMENT_REPORTS_ACTION_IDS }     from '../components/accreditation/assessment-reports/assessment-reports-action-id.constants';
import { PREFIX as ASSESSMENT_REPORTS_PREFIX }      from '../components/accreditation/assessment-reports/assessment-reports-action-type.constants';
import { AssessmentReportsReducer }                 from '../components/accreditation/assessment-reports/assessment-reports.reducer';
import { assessmentReportsTabs }                    from '../components/accreditation/assessment-reports/assessment-reports-tabs';

import { IDS as MASTER_DOC_LIST_ACTION_IDS }        from '../components/accreditation/master-doc-list/master-doc-list-action-id.constants';
import { PREFIX as MASTER_DOC_LIST_ACTION_PREFIX }  from '../components/accreditation/master-doc-list/master-doc-list-action-type.constants';
import { MasterDocListReducer }                     from '../components/accreditation/master-doc-list/master-doc-list.reducer';
import { masterDocListTabs }                        from '../components/accreditation/master-doc-list/master-doc-list-tabs';

import { IDS as ADD_DOCUMENT_ACTION_IDS }           from '../components/accreditation/add-document/add-document-action-id.constants';
import { PREFIX as ADD_DOCUMENT_ACTION_PREFIX }     from '../components/accreditation/add-document/add-document-action-type.constants';
import { AddDocumentReducer }                       from '../components/accreditation/add-document/add-document.reducer';
import { addDocumentTabs }                          from '../components/accreditation/add-document/add-document-tabs';

import { IDS as ACC_DOCUMENTS_ACTION_IDS }          from '../components/accreditation/acc-documents/acc-documents-action-id.constants';
import { PREFIX as ACC_DOCUMENTS_ACTION_PREFIX }    from '../components/accreditation/acc-documents/acc-documents-action-type.constants';
import { AccDocumentsReducer }                      from '../components/accreditation/acc-documents/acc-documents.reducer';
import { accDocumentsTabs }                         from '../components/accreditation/acc-documents/acc-documents-tabs';

import { IDS as IMPROVEMENT_ACTION_IDS }            from '../components/accreditation/improvement/improvement-action-id.constants';
import { PREFIX as IMPROVEMENT_ACTION_PREFIX }      from '../components/accreditation/improvement/improvement-action-type.constants';
import { ImprovementReducer }                       from '../components/accreditation/improvement/improvement.reducer';
import { improvementTabs }                          from '../components/accreditation/improvement/improvement-tabs';

import {INPUT_TEXT, INPUT_SELECT, INPUT_CHECKBOX} from '../common/form/input/input.constants'
import * as TABS from '../common/tabs/tabs.constants';
import {TabsReducer} from '../common/tabs/tabs.reducer';
import {GridReducer} from '../common/grid/grid.reducer';
import {GridFilterReducer} from '../common/grid/filter/grid-filter.reducer';
import * as GRID from '../common/grid/grid.constants';
import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';

/**
 * @function accreditationReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function accreditationReducer(state, action) {

    if (typeof state === 'undefined') {
        const currentYear = new Date().getFullYear();
        return {
            sectionsTree: [],
            navigationFilter: {
                hrs: ['actask'],
                sro: [],
                // default: ['actask'],
            },

            [IDS.accDashboardLocationYear]: {
                enabled: false,
                headOffice:     null,
                siteLocation:   null,
                year:           currentYear,
                availableYears: getAvailableYearsRange(currentYear),
            },

            [IDS.addScheme]: {
                leadingZerosOptions: [
                    {value: '1', label: '1'},
                    {value: '2', label: '2'},
                    {value: '3', label: '3'},

                    {value: '9', label: 'a'},
                    {value: '8', label: 'A'},
                ],
                level1_num_digits: 1,
                level2_num_digits: 1,
                level3_num_digits: 1,
            },
            [IDS.accTasksGrid]: {
                ...GridReducer(),
                taskTypes:  [
                    {value: 'utaccr'},
                    {value: 'utaaud'},
                    {value: 'utaevd'},
                    {value: 'utaimp'},
                ],
                taskStatuses: [
                    {value: 'isopen'},
                    {value: 'isall'},
                    {value: 'wai'},
                    {value: 'pen'},
                    {value: 'upl'},
                    {value: 'cpl'},
                ],
                taskSchemes: [],
                filter: {
                    ...GridFilterReducer(),
                    task_type: 'utaccr',
                    task_status: 'isopen',
                    scheme_code: null,
                    keyword: null,
                    my_tasks_only: 1,
                    emp_uuid: null,
                    year_start: null,
                    year_end: null,
                }
            },

            accAssessmentReports: AssessmentReportsReducer(),
            ...assessmentReportsTabs,

            accAudit: AuditReducer(),
            ...scheduleTabs,

            accStandard: StandardReducer(),
            ...standardTabs,

            // accScheme: SchemeReducer(),
            // ...schemeTabs,

            accSettings: SettingsReducer(),
            ...enabledAccountsTabs,
            ...defaultDocumentsTabs,
            ...hofRequiredDocsTabs,
            ...sloRequiredDocsTabs,

            accAddDocument: AddDocumentReducer(),
            ...addDocumentTabs,

            accDocuments: AccDocumentsReducer(),
            ...accDocumentsTabs,

            accMasterDocList: MasterDocListReducer(),
            ...masterDocListTabs,

            accImprovement: ImprovementReducer(),
            ...improvementTabs
        };
    }

    switch (action.type) {
        case TYPES.FETCH_SCHEMES:
            if (action.id === IDS.accTasksGrid) {
                return set({...state}, `${IDS.accTasksGrid}.taskSchemes`, action.items)
            }
        case TYPES.LOCALIZE_TASKS_GRID_FILTER:
            if (action.id === IDS.accTasksGrid) {
                state = set({...state}, `${IDS.accTasksGrid}.taskStatuses`, action.taskStatuses)
                return set(state, `${IDS.accTasksGrid}.taskTypes`, action.taskTypes)
            }
        case TYPES.SELECT_ASSESSMENT_ITEM:
        case TYPES.FETCH_SITE_LOCATION_DETAILS:

        case TYPES.LOCATION_SEARCH_BAR_CHANGE:
            return merge({...state}, {
                accDashboardLocationYear: action.accDashboardLocationYear
            })

        case TYPES.FETCH_STANDARDS_TREE_PROGRESS:
            return { ...state,
                sectionsTree: action.data,
            }

        case TYPES.SELECT_STANDARD:
            const currentItem = state.selectedSection;
            if(currentItem)
                currentItem.selected = false;

            const sectionsList = index(state.sectionsTree);
            const newItem = action.standard_id ? sectionsList[action.standard_id] : null;
            const parent = sectionsList.find(standard =>
                standard
                && standard.children
                && standard.children.length
                && standard.children.find(item => item.id === action.standard_id)
            );
            if(newItem) {
                return  { ...state,
                    selectedSection: {...newItem, selected: true},
                    selectedSectionParent: parent,
                }
            }

        case TYPES.STANDARDS_TREE_UNSELECT_ALL:
            const unselectAllChildren = (children) => {
                children && children.forEach(item => {
                    if(item) {
                        item.selected = false
                        unselectAllChildren(item.children);
                    }
                })
                return children
            };
            return { ...state,
                sectionsTree: unselectAllChildren(state.sectionsTree),
            };

        case TYPES.SCHEME_SECTIONS_RESET:
            return merge({...state}, {
                accScheme: {
                    standards: {
                        selectedSection: null,
                    }
                }
            });

        case TYPES.SECTIONS_TREE_FETCH:
            return {...state, simpleSectionsTree: action.data};

        case TYPES.SIMPLE_STD_TREE_UNSELECT_ALL:
            if(state.simpleSectionsTree && state.simpleSectionsTree.length) {
                state.simpleSectionsTree.forEach(topNode => {
                    topNode.selected = false;
                    unselectChildren(topNode);
                });
            }
            return { ...state, sectionDetails: null };

        //*** COMMON ***
        case 'INPUT_TEXT_AREA_CHANGE':
        case INPUT_TEXT:
            if (action.id === IDS.addScheme) {
                return set({...state}, `${action.id}.${action.path}`, action.input)
            }
        case INPUT_CHECKBOX:
        case INPUT_SELECT:
            if (action.id === IDS.accTasksGrid) {
                const filter = {
                    [action.path.split('.').pop()]: action.input
                };
                return set({...state}, action.id,
                    GridReducer(get(state, action.id), {type: GRID.GRID_FILTER, filter})
                )
            }
            if ([IDS.addScheme, IDS.accDashboardLocationYear].includes(action.id)) {
                return set({...state}, `${action.id}.${action.path}`, action.input)
            }
        case TABS.TAB_INIT:
        case TABS.TAB_OVERRIDE:
        case TABS.TAB_PARAMS:
        case TABS.TAB_ENABLE:
        case TABS.TAB_DISABLE:
        case TABS.TAB_SHOW:
        case TABS.TAB_HIDE:
        case TABS.TAB_LOADING:
        case TABS.TAB_SELECT:
        case TABS.TAB_LABEL:
        case TABS.TAB_BUTTON_LABEL:
        case TABS.TAB_TRANSITION_DISABLE:
        case TABS.TAB_TRANSITION_ENABLE:
            if (action.id.startsWith('app.accreditation.') || action.id.startsWith('public.accreditation.')){
                return {
                    ...state,
                    [action.id]: TabsReducer(state[action.id], action)
                }
            }
        case TABS.TAB_SWITCH:
            if (action.id.startsWith('app.accreditation.') || action.id.startsWith('public.accreditation.')){
                return {
                    ...state,
                    [action.id]: Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                }
            }

        case GRID.GRID_INIT:
        case GRID.GRID_FILTER:
        case GRID.GRID_FILTER_TOGGLE:
        case GRID.GRID_RESET_FILTER:
        case GRID.GRID_INIT_FILTER:
        case GRID.GRID_LOADING:
        case GRID.GRID_PAGINATE:
        case GRID.GRID_RELOAD:
        case GRID.GRID_FETCH:
        case GRID.GRID_SELECT:
        case GRID.GRID_SELECT_ALL:
        case GRID.GRID_DESELECT:
        case GRID.GRID_DESELECT_ALL:
        case GRID.GRID_RESET:
            if (action.id === IDS.accTasksGrid) {
                state = set({...state}, action.id,
                    GridReducer( state[action.id],{
                        type: GRID.GRID_RESET_FILTER,
                        exclude: ['user_uuid','emp_uuid','year_start','year_end']
                    })
                );
                merge(state[action.id], {
                    // set "reset" flag to trigger slo SELECT_CASCADE_RESET in grid filter
                    reset: !state[action.id].reset,
                    reload: state[action.id].filter.page === 1 ? !state[action.id].reload : state[action.id].reload,
                    // set default values
                    filter: {
                        task_type: 'utaccr',
                        task_status: 'isopen',
                        my_tasks_only: 1,
                    }
                });
                return state;
            }

        case 'LOCATION_SELECTED':
            if (action.id === IDS.accTasksGrid) {
                if(action.type_code === 'slo') {
                    state = set({...state}, action.id, GridReducer(state[action.id], set(action, 'type', 'PAGINATE')));
                    return set(state, `${action.id}.filter.slo_uuid`, action.uuid);
                }
                return state;
            }
    }

    if(action.type.startsWith(ASSESSMENT_REPORTS_PREFIX)
    || action.id && ASSESSMENT_REPORTS_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, accAssessmentReports: AssessmentReportsReducer(state.accAssessmentReports, action) }
    }

    if(action.type.startsWith(AUDIT_ACTION_PREFIX)
    || action.id && AUDIT_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, accAudit: AuditReducer(state.accAudit, action) }
    }

    if(action.type.startsWith(STANDARDS_ACTION_PREFIX)
    || action.id && STANDARDS_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, accStandard: StandardReducer(state.accStandard, action) }
    }

    if(action.type.startsWith(SETTINGS_ACTION_PREFIX)
    || action.id && SETTINGS_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, accSettings: SettingsReducer(state.accSettings, action) }
    }

    if(action.type.startsWith(MASTER_DOC_LIST_ACTION_PREFIX)
    || action.id && MASTER_DOC_LIST_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, accMasterDocList: MasterDocListReducer(state.accMasterDocList, action) }
    }

    if(action.type.startsWith(ADD_DOCUMENT_ACTION_PREFIX)
    || action.id && ADD_DOCUMENT_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, accAddDocument: AddDocumentReducer(state.accAddDocument, action) }
    }

    if(action.type.startsWith(ACC_DOCUMENTS_ACTION_PREFIX)
    || action.id && ACC_DOCUMENTS_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, accDocuments: AccDocumentsReducer(state.accDocuments, action) }
    }

    if(action.type.startsWith(IMPROVEMENT_ACTION_PREFIX)
        || action.id && IMPROVEMENT_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, accImprovement: ImprovementReducer(state.accImprovement, action) }
    }

    return state;
}

const index = (children, list = []) => {
    children && children.forEach(item => {
        if(item) {
            list[parseInt(item.id)] = item;
            index(item.children, list);
        }
    })
    return list;
};

const unselectChildren = (node) => {
    node.selected = false
    if(node.children && node.children.length)
        node.children.forEach(childNode => {
            childNode.selected = false;
            unselectChildren(childNode);
        });
}

export function getAvailableYearsRange(year) {
    return [-2, -1, 0, 1]
        .map(i => i + year)
        .map(item => ({label: item, value: item}));
}