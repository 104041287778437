import {TAB_SHOW} from '../../../common/tabs/tabs.constants';
import {TYPES as ACTION_TYPES} from './rep-monthly-action-type.constants';
import {IDS} from './rep-monthly-action-id.constants';
import * as Actions from './rep-monthly.actions';
import watch from "redux-watch";
import get from "lodash/get";
import {GRID_FILTER, GRID_FILTER_TOGGLE, GRID_RELOAD} from '../../../common/grid/grid.constants';
import {error} from '../../../common/utils/util.notifier';
import {fetchRunsHofsGrid} from "./rep-monthly.actions";

class RepMonthlyController {
    constructor($ngRedux, $uiRouterGlobals, $state, $stateParams, $scope, $rootScope, $filter, NavigationService, AuthService, EmployeeService, DocumentManager, FileActions) {
        this.$ngRedux = $ngRedux;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.NavigationService = NavigationService;
        this.AuthService = AuthService;
        this.EmployeeService = EmployeeService;
        this.DocumentManager = DocumentManager;
        this.FileActions = FileActions;
    }

    $onInit() {
        this.identifier = IDS.repMonthly;
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.iconSvg.replace(/(\.\/)/g, '');

        this.unsubscribe = this.$ngRedux.connect(state => {
            return {
                runs:       state.reporting.repMonthly.runs,
                filter:     state.reporting.repMonthly.repMonthlyGrid.filter,
            };
        }, {...this.FileActions, ...Actions})(this);

        // const ctrl = this;
        // const watchSelectedState = watch(this.$ngRedux.getState, ['reporting', IDS.app_reporting_monthly, 'selectedState']);
        // this.unsubscribeWatch = this.$ngRedux.subscribe(watchSelectedState(selectedState => {
        //     if (selectedState === IDS.app_reporting_monthly_list) {
        //         this.$ngRedux.dispatch({
        //             id: IDS.repMonthly,
        //             type: ACTION_TYPES.NEW_ASSESSMENT_ITEM_RESET,
        //         })
        //     }
        // }));

        this.fetchRuns().then(() => {
            if(this.filter.mrp_id) {
                this.fetchRunsHofsGrid({mrp_id: this.filter.mrp_id})
            }
        });
    }

    onSelect({item}) {
        this.$ngRedux.dispatch({
            type:   ACTION_TYPES.SHOW_DETAILS,
            id:     IDS.repMonthly,
            item,
        });
        this.$ngRedux.dispatch({
            type: TAB_SHOW,
            id: IDS.app_reporting_monthly,
            state: IDS.app_reporting_monthly_details,
        });
    };

    onDownloadButton({item}){
        // this.DocumentManager.downloadFile(item.doc_uri);
        this.download(item.doc_uri);
    }

    // onButtonAddReport(event) {
    //     this.$ngRedux.dispatch({
    //         type: TAB_SHOW,
    //         id:     IDS.app_reporting_monthly,
    //         state:  IDS.app_reporting_monthly_add,
    //     });
    // };

    $onDestroy() {
        this.unsubscribe();
    }
}

RepMonthlyController.$inject = ['$ngRedux', '$uiRouterGlobals', '$state', '$stateParams', '$scope', '$rootScope', '$filter', 'NavigationService', 'AuthService','EmployeeService', 'DocumentManager','FileActions']

export const repMonthly = {
    controller: RepMonthlyController,
    template: `
        <rep-monthly-grid
                on-select="$ctrl.onSelect($event)"
                on-download="$ctrl.onDownloadButton($event)">
        </rep-monthly-grid>

<!--        <div layout-xs="column" layout-gt-xs="row" layout-align="center center" class="tabs">-->
<!--            <div flex></div>-->
<!--            <button type="submit" class="md-button btnColor{{$ctrl.colorName}}" ng-click="$ctrl.onButtonAddReport()" aria-label="Add Report">Add Report</button>-->
<!--        </div>-->
`}
