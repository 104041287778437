import get from 'lodash/get';

export const getGridSort = (filter, action) => {
    const column = get(filter, 'sort.column');
    if (column) {
        if (column === action.sort.column) {
            if (get(filter, 'sort.value') === 'asc') {
                return { ...action.sort, value: 'desc' };
            }
        }
    }
    return action.sort;
};
