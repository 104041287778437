import * as TYPE from '../../cre-vendors.actions.types';
import { getContactPositions, saveVendorAccounts } from '../../cre-vendors.actions';
import isUndefined from 'lodash/isUndefined';
import findIndex from 'lodash/findIndex';

/**
 * @const {object}
 * @memberOf module:Credentialing
 */
export const creVendorsAccounts = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $location, $mdDialog, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.title = 'Accounts Information';
            this.unsubscribe = $ngRedux.connect(({ credentialing }) => ({
                creInvite: credentialing.creInvite,
                creVendor: credentialing.creVendor,
            }), {
                getContactPositions,
                saveVendorAccounts,
            })(this);
            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.accounts`;
            this.token = $location.search().token;

            this.getContactPositions({
                token: this.token,
                contact_code: 'vhm',
            });
        };

        this.onContactManage = (idx) => {
            this.dialog = $mdDialog.show({
                /* @ngInject */
                controller: ($scope) => {
                    $scope.save = () => {
                        const contactIdx = findIndex(this.creInvite.contacts, ['email', this.creInvite.contact_email]);
                        if ($scope.dialogForm.$valid) {
                            if (
                                (isUndefined(idx) && contactIdx !== -1) ||
                                (contactIdx !== idx && contactIdx !== -1)
                            ) {
                                Notifier.error('Contact email already exists!');
                            } else {
                                $ngRedux.dispatch({
                                    type: isUndefined(idx) ? TYPE.CRE_CONTACT_CREATE : TYPE.CRE_CONTACT_UPDATE,
                                    id: 'creInvite',
                                    data: {
                                        sty_id: this.creInvite.contact_position,
                                        title: this.creInvite.contact_title,
                                        first_name: this.creInvite.contact_first_name,
                                        last_name: this.creInvite.contact_last_name,
                                        email: this.creInvite.contact_email,
                                        is_additional_contact: 1,
                                    },
                                    idx,
                                });
                                $mdDialog.hide();
                            }
                        }
                    };
                    $scope.cancel = () => $mdDialog.cancel();
                },
                template: require('../../invitation/dialogs/cre-vendors-contact-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                onShowing: () => {
                    if (typeof idx !== 'undefined') {
                        $ngRedux.dispatch({
                            type: TYPE.CRE_CONTACT_DETAILS,
                            id: 'creInvite',
                            idx,
                        });
                    }
                }
            }).then(contactReset, contactReset);
        };

        const contactReset = () => {
            $ngRedux.dispatch({
                type: TYPE.CRE_CONTACT_RESET,
                id: 'creInvite',
            });
        };

        this.onContactDelete = (idx) => {
            $ngRedux.dispatch({
                type: TYPE.CRE_CONTACT_DELETE,
                id: 'creInvite',
                email: this.creInvite.contacts[idx].email,
            });
        }

        this.onSave = () => {
            if (this.creVendorAccountsForm.$valid) {
                this.saveVendorAccounts({
                    token: this.token,
                    application_id: this.creVendor.application_id,
                    cred_account_contacts: this.creInvite.contacts,
                }).then(() => {
                    Notifier.success('Accounts Information has been successfully saved');
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
        }

        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: require('./cre-vendors-accounts.html')
};
