/** @namespace Reducers.System */

import { TabsReducer } from '../common/tabs/tabs.reducer';
import { GridReducer } from '../common/grid/grid.reducer';
import merge from 'lodash/merge';
import set from 'lodash/set';
import update from 'lodash/update';

/**
 * @function systemReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function systemReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            'app.system.welcome': Object.assign({}, TabsReducer(), {
                tabs: [
                    {state: 'app.system.welcome.home', title: 'Welcome', visible: true, disabled: false},
                    {state: 'app.system.welcome.documentation', title: 'Documentation', visible: true, disabled: false}
                ]
            }),
            'app.system.assignments': Object.assign({}, TabsReducer(), {
                tabs: [
                    {state: 'app.system.assignments.home', title: 'General', visible: true, disabled: false},
                    {state: 'app.system.assignments.my', title: 'My Assignments', visible: true, disabled: false}
                ]
            }),
            'app.system.alerts': Object.assign(TabsReducer(), {
                tabs: [{
                    state: 'app.system.alerts.listing',
                    title: 'Alerts & Warnings',
                    visible: true,
                    disabled: false,
                    loading: false,
                }]
            }),
            'app.system.activities': TabsReducer(),
            'app.system.assistant': TabsReducer(),
            'app.system.settings': TabsReducer(),
            systemAlertsGrid: merge(GridReducer(), {
                filter: {
                    moduleId: null,
                    keyword: null,
                    alertType: null,
                    equipmentId: null,
                    employeeUuid: null
                }
            })
        };
    }

    switch (action.type) {
        case 'TAB_SELECT':
            if (action.id === 'app.system.welcome') {
                return Object.assign({}, state, {
                    'app.system.welcome': TabsReducer(state[action.id], action)
                });
            }
            break;
        case 'TAB_ENABLE':
        case 'TAB_DISABLE':
        case 'TAB_SHOW':
        case 'TAB_HIDE':
            if (action.id === 'app.system.welcome') {
                return Object.assign({}, state, {
                    'app.system.welcome': TabsReducer(state[action.id], action)
                });
            }
            break;
        case 'TAB_INIT':
        case 'TAB_SWITCH':
            if (action.id === 'app.system.welcome') {
                return Object.assign({}, state, {
                    'app.system.welcome': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex})
                });
            }
            if (action.id === 'app.system.assignments') {
                return Object.assign({}, state, {
                    'app.system.assignments': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex})
                });
            }
            if (action.id === 'app.system.activities') {
                return Object.assign({}, state, {
                    'app.system.activities': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex})
                });
            }
            if (action.id === 'app.system.assistant') {
                return Object.assign({}, state, {
                    'app.system.assistant': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex})
                });
            }
            if (action.id === 'app.system.settings') {
                return Object.assign({}, state, {
                    'app.system.settings': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex})
                });
            }
            break;
        case 'INPUT_TEXT_CHANGE':
            if (action.id === 'systemAlertsGrid') {
                return Object.assign({}, state, {
                    systemAlertsGrid: set(GridReducer(state.systemAlertsGrid, set(action, 'type', 'PAGINATE')), 'filter.keyword', action.input)
                });
            }
            break;
        case 'GRID_RESET':
            if (action.id === 'systemAlertsGrid') {
                return Object.assign({}, state, {
                    systemAlertsGrid: update(GridReducer(state.systemAlertsGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            alertType: null,
                            keyword: null,
                            equipmentId: null,
                            employeeUuid: null
                        });
                    })
                });
            }
            break;
        case 'GRID_RESET_FILTER':
            if (action.id === 'systemAlertsGrid') {
                return Object.assign({}, state, {
                    systemAlertsGrid: GridReducer(state.systemAlertsGrid, action)
                });
            }
        case 'GRID_OVERRIDE':
        case 'SHOW_LOADING':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
            if (action.id === 'systemAlertsGrid') {
                return Object.assign({}, state, {
                    systemAlertsGrid: GridReducer(state.systemAlertsGrid, action)
                });
            }
            break;
    }
    return state;
}
