/**
 * Creates a new AuthPasswordResetController.
 * @class
 */
class AuthPasswordResetController {
    constructor($log, $ngRedux, $state, RecoveryService) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.RecoveryService = RecoveryService;
    }

    $onInit() {
        this.$log.debug('PasswordReset::init');
        this.credential = null;

        if (this.$state.params.message) {
            this.headlineClass = 'md-headline-error';
            this.headline = this.$state.params.message;
        }
        else this.initHeadline();
    }

    resetPassword() {
        this.initHeadline();
        if (this.recoveryRequestForm.$valid) {
            this.$ngRedux.dispatch({type: 'LOADING_START'});
            this.RecoveryService.resetPasswordInit(this.credential).then(() => {
                this.$ngRedux.dispatch({type: 'LOADING_END'});
                this.credential = null;
                this.$state.transitionTo('login.auth', {message: 'Please check your e-mail for password recovery instructions.'});
            }, ({ status }) => {
                this.$ngRedux.dispatch({type: 'LOADING_END'});
                this.headlineClass = 'md-headline-error';
                if (status === 429) {
                    this.headline = data;
                } else {
                    this.headline = 'Invalid username or e-mail. Please try again.';
                }
            });
        }
    }

    initHeadline() {
        this.headlineClass = 'md-headline';
        this.headline = this.$state.current.params.headline;
    }
}

AuthPasswordResetController.$inject = ['$log', '$ngRedux', '$state', 'RecoveryService'];

export const authPasswordReset =  {
    controller: AuthPasswordResetController,
    template: require('./auth-password-reset.html')
};
