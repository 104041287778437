/*
*   List of Action IDs that should be passed from accreditation.reducer.js to settings.reducer.js
 */

export const IDS = {
    admCategorisation: 'admCategorisation',

    // components:
    admCategorisationCenterDetails:           'admCategorisationCenterDetails',

    admCategorisationDocumentDetails:             'admCategorisationDocumentDetails',
    admCategorisationOccupationDetails:           'admCategorisationOccupationDetails',


    //paths
    // admCatCenters:                  'admCatCenters',
    admCatCentersGrid:              'admCatCentersGrid',
    admCatCenterDetails:            'admCatCenterDetails',

    admCatDocuments:                'admCatDocuments',
    admCatDocumentsGrid:            'admCatDocumentsGrid',
    admCatDocumentDetails:          'admCatDocumentDetails',

    admCatOccupations:              'admCatOccupations',
    admCatOccupationsGrid:          'admCatOccupationsGrid',
    admCatOccupationDetails:        'admCatOccupationDetails',


    // states
    app_administration_categorisation:                          'app.administration.categorisation',
    app_administration_categorisation_dashboard:                'app.administration.categorisation.dashboard',


    // tab states:

    app_administration_categorisation_centers:                  'app.administration.categorisation.centers',
    app_administration_categorisation_centers_grid:             'app.administration.categorisation.centers.grid',
    app_administration_categorisation_centers_details:          'app.administration.categorisation.centers.details',
    app_administration_categorisation_centers_licenses:         'app.administration.categorisation.centers.licenses',

    app_administration_categorisation_documents:                'app.administration.categorisation.documents',
    app_administration_categorisation_documents_grid:           'app.administration.categorisation.documents.grid',
    app_administration_categorisation_documents_details:        'app.administration.categorisation.documents.details',

    app_administration_categorisation_occupations:              'app.administration.categorisation.occupations',
    app_administration_categorisation_occupations_grid:         'app.administration.categorisation.occupations.grid',
    app_administration_categorisation_occupations_details:      'app.administration.categorisation.occupations.details',
};
