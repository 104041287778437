import { gridTpl } from '../../../../../common/grid/grid.controller';

export const administrationAccountsEquipmentGrid = {
    bindings: {
        title: '<',
        onSelect: '&'
    },
    controller: 'AdministrationAccountsEquipmentGridController',
    ...gridTpl
};
