export const rmTestAddState = {
    name: 'app.radioactiveMaterials.test.add',
    redirectTo: 'app.radioactiveMaterials.test.add.details',
    url: '/add',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'RM_TEST_RESET',
            id: 'rmTest'
        });
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.radioactiveMaterials.test.add',
            state: [
                'app.radioactiveMaterials.test.add.documents'
            ]
        });
    }
};

export const rmTestAddDetailsState = {
    name: 'app.radioactiveMaterials.test.add.details',
    url: '',
    component: 'rmTestDetails'
};

export const rmTestAddDocumentsState = {
    name: 'app.radioactiveMaterials.test.add.documents',
    url: '/documents',
    component: 'rmTestDocuments'
};

export const rmTestManageState = {
    name: 'app.radioactiveMaterials.test.manage',
    redirectTo: 'app.radioactiveMaterials.test.manage.listing',
    url: '/manage',
    component: 'tabs'
};

export const rmTestManageListingState = {
    name: 'app.radioactiveMaterials.test.manage.listing',
    url: '',
    component: 'rmTestGrid',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.radioactiveMaterials.test.manage',
            state: [
                'app.radioactiveMaterials.test.manage.details',
                'app.radioactiveMaterials.test.manage.documents',
                'app.radioactiveMaterials.test.manage.wipeTest',
                'app.radioactiveMaterials.test.manage.disposal'
            ]
        });
    }
};

export const rmTestManageDetailsState = {
    name: 'app.radioactiveMaterials.test.manage.details',
    url: '/details?id',
    component: 'rmTestDetails'
};

export const rmTestManageDocumentsState = {
    name: 'app.radioactiveMaterials.test.manage.documents',
    url: '/documents',
    component: 'rmTestDocuments'
};

export const rmTestManageWipeTestState = {
    name: 'app.radioactiveMaterials.test.manage.wipeTest',
    url: '/wipe-test?id',
    component: 'rmTestWipeTest',
    /* @ngInject */
    onExit: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'RM_TEST_WIPE_TEST_RESET',
            id: 'rmTestWipeTest'
        });
    },
};

export const rmTestManageDisposalState = {
    name: 'app.radioactiveMaterials.test.manage.disposal',
    url: '/disposal?id',
    component: 'rmTestDisposal'
};

export const rmTestAlertsState = {
    name: 'app.radioactiveMaterials.test.alerts',
    redirectTo: 'app.radioactiveMaterials.test.alerts.listing',
    url: '/alerts',
    component: 'tabs'
};

export const rmTestAlertsListingState = {
    name: 'app.radioactiveMaterials.test.alerts.listing',
    url: '',
    component: 'rmTestAlerts'
};
