import watch from 'redux-watch';
import moment from 'moment';

/**
 * Creates a new DrlCtScannerSystemStatusController.
 * @class
 */
class DrlCtScannerSystemStatusController {
    constructor($ngRedux, $state, NavigationService, $filter) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.NavigationService = NavigationService;
        this.filter = $filter
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.title = this.filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.GRID_DESC');

        const watchSysStatus = watch(this.$ngRedux.getState, 'doseReferenceLevels.drlCtScannerSystemStatusGrid.items');
        this.onSystemStatus = this.$ngRedux.subscribe(watchSysStatus(() => {
            this.execDate = this.filter('translate')('DLR.CT_SCANNER.SYSTEM_STATUS.REPORT_DATE') +
                                moment().format('MMMM Do YYYY, h:mm:ss a');
        }));
    }

    refreshData() {
        this.execDate =  '';
        this.$ngRedux.dispatch({
            type: 'GRID_RELOAD',
            id: 'drlCtScannerSystemStatusGrid'
        });
    }

    $onDestroy() {
        this.onSystemStatus();
    }
}

DrlCtScannerSystemStatusController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter'];

export const drlCtScannerSystemStatus = {
    controller: DrlCtScannerSystemStatusController,
    template: require('./drl-ct-scanner-system-status.html')
};
