/* @ngInject */
export const FileImageFactory = (API_BASE) => {
    return function FileImage() {

        this.id = null;
        this.type = null;
        this.url = null;
        this.thumb = null;
        this.description = null;
        this.width = null;
        this.height = null;

        this.getURL = function() {
            return API_BASE + '/img/' + localStorage.myrsoAuthToken + this.url;
        };
    };
};
