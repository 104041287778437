import { INIT_DASHBOARD } from '../../../common/navigation/navigation.actions.types';
import { IDS } from '../accreditation-action-id.constants';
import {TYPES as ACC_TYPES, TYPES} from "../accreditation-action-type.constants";
import { IDS as SCHEME_IDS} from '../scheme/scheme-action-id.constants';
import { IDS as ASSESSMENT_IDS} from '../standard/standard-action-id.constants';
import { TYPES as SCHEME_TYPES} from '../scheme/scheme-action-type.constants';
import * as StandardsActions from '../scheme/standards/standards.actions';
import * as EvidenceActions from '../scheme/evidence/evidence.actions';
import * as AccreditationActions from '../accreditation.actions';
import get from 'lodash/get';
import pick from 'lodash/pick';

export const accDashboardLocationYear = {
    /* @ngInject */
    controller: function ($ngRedux, $scope, $rootScope, $stateParams, $timeout, NavigationActions, AuthService, EmployeeService) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.identifier = IDS.accDashboardLocationYear;
            this.index = 0;
            this.level = 0;
            this.unsubscribe = $ngRedux.connect(({ navigation, accreditation }) => {
                return {
                    ...pick(accreditation.accDashboardLocationYear, ['headOffice', 'siteLocation', 'year', 'schemeId', 'schemeCode']),
                    sectionsTree: accreditation.sectionsTree,
                    navigationState: navigation.state,
                    navConfigs: navigation.configs,
                };
            }, { ...StandardsActions, ...EvidenceActions, ...NavigationActions, ...AccreditationActions})(this);

            this.unsubscribeSelectCascadeLoaded = $scope.$on('SELECT_CASCADE_SUBJECTS_LOADED', this.onSelectCascadeLoaded.bind(this));
            this.unsubscribeLocationNotFound = $scope.$on('LOCATION_SUBJECT_NOT_FOUND', this.onLocationNotFound.bind(this));
            this.unsubscribeLocationChange = $rootScope.$on('LOCATION_SUBJECT_SELECTED', this.onLocationChange.bind(this));

            switch(this.navigationState){
                case IDS.app_accreditation_dashboard:
                    this.yearSelectEnabled = false;
                    this.resetButtonEnabled = true;
                break;
                case IDS.app_accreditation_scheme_standards_dashboard:
                    this.yearSelectEnabled = true;
                    this.resetButtonEnabled = false;
                break;
                default:
                    this.yearSelectEnabled = true;
                    this.resetButtonEnabled = true;
            }
        }

        this.onSelectCascadeLoaded = () => {
            if(this.headOffice && this.siteLocation) {
                $rootScope.$broadcast('FORCE_SELECT_PARENT', 0, this.headOffice, this.siteLocation);
            }
            else {
                if($stateParams.slo) {
                    const siteLocation = $stateParams.slo;
                    EmployeeService.getEmploymentLocations(siteLocation).then(result => {
                        const headOffice = result[0].uuid;
                        $rootScope.$broadcast('FORCE_SELECT_PARENT', 0, headOffice, siteLocation );
                    })
                }
                if($stateParams.year){
                    $ngRedux.dispatch({
                        type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                        accDashboardLocationYear: {
                            year: $stateParams.year,
                        }
                    });
                }
            }
        }

        this.filterHeadOffices = (items) => {
            if(!this.schemeHeadOffices || !this.schemeHeadOffices.length || !items.length || items[0].in_subject_basic_type_code !== 'hof') {
                return items;
            }
            else {
                return items.filter(item => this.schemeHeadOffices.includes(item.in_subject_uuid))
            }
        }
        this.filterHeadOffices = this.filterHeadOffices.bind(this);

        this.onLocationChange = (evt, loc) => {
            const subjectType = get(loc,'in_subject_basic_type_code');
            switch(subjectType){
                case 'hof':
                    this.headOffice = get(loc, 'in_subject_uuid');
                break;

                case 'slo':
                    const siteLocation = get(loc, 'in_subject_uuid', this.siteLocation);
                    $ngRedux.dispatch({
                        type: TYPES.LOCATION_SEARCH_BAR_CHANGE,
                        accDashboardLocationYear: {
                            headOffice: this.headOffice,
                            siteLocation,
                            year: this.year,
                        }
                    });

                    this.updateTiles(siteLocation, this.year);
                break;
            }
        };

        this.onLocationNotFound = (event, location) => {
            this.resetButtonEnabled = false;
            var user = AuthService.getUser();
            if(/*this.siteLocation || */user.parentCount !== 1)
                return

            const ctrl = this;
            EmployeeService.getEmploymentLocations(user.uuid).then(siteLocations => {
                const siteLocation = get(siteLocations,'[0].uuid');
                $ngRedux.dispatch({
                    type: TYPES.LOCATION_SEARCH_BAR_CHANGE,
                    accDashboardLocationYear: {
                        siteLocation,
                    }
                });
                ctrl.updateTiles(siteLocation, ctrl.year);
            });
        }

        this.onYearChange = (evt) => {
            const year = get(evt, 'item.value', this.year);
            this.updateTiles(this.siteLocation, year);
        }

        this.updateTiles = (siteLocation, year) => {
            if (!siteLocation || !year)
                return

            switch(this.navigationState) {
                case IDS.app_accreditation_dashboard:
                    this.getConfigs('accreditation', siteLocation).then(data => {
                        $ngRedux.dispatch({
                            type: INIT_DASHBOARD,
                            dashboard: data.filter(item =>
                                item.order !== 0 && // hide abstract tiles
                                item.url.indexOf('accreditation/scheme/') === -1 // hide tiles: assessment, mas. doc. list, settings
                            ),
                        });
                    })
                break;

                case IDS.app_accreditation_scheme_standards_dashboard:
                    const { itemCode, standard } = $stateParams;
                    this.fetchStandardsTreeProgress(itemCode, siteLocation, year, SCHEME_IDS.accScheme).then(standards => {
                        if(standard){
                            const index = (children, list = []) => {
                                children && children.forEach(item => {
                                    if(item) {
                                        list[parseInt(item.id)] = item;
                                        index(item.children, list);
                                    }
                                })
                                return list;
                            };
                            const list = index(standards);
                            let selectedSection = list.find(item => item && item.id === parseInt(standard));
                            if(selectedSection.level === 3 || !selectedSection.children.length) {
                                selectedSection = list.find(item => item && item.id === selectedSection.parentId);
                            }
                            standards = selectedSection.children;
                            this.level = selectedSection.level;
                        }
                        $ngRedux.dispatch({
                            type: INIT_DASHBOARD,
                            dashboard: mapStandards(standards),
                        });
                    });
                break;
            }
        }

        const mapStandards = (data) => {
            return data.map((item, key) => {
                const ctrl = this;
                return {
                    color: 'a934e4',
                    icon: 'accreditation_polices_procedures.svg',
                    selector: `app-accreditation-scheme-standards${item.level === 3 ? '-standard' : ''}`,
                    state: item.level === 3 || item.children.length === 0
                        ? ASSESSMENT_IDS.app_accreditation_scheme_standards_documents
                        : ASSESSMENT_IDS.app_accreditation_scheme_standards,
                    code: $stateParams.itemCode,
                    title: item.name.length > 60 ? `${item.name.slice(0,60)}...` : item.name,
                    mark: {
                        text: `Appr:${item.assessment_items_approved_count} + Pen:${item.assessment_items_pending_count} / Tot:${item.assessment_items_total_count}`,
                        icon: 'documents.svg'
                    },
                    ...(item.children.length > 0 ? {
                        onClick: () => {
                            if (this.level === 0) {
                                this.index = key;
                            }
                            this.level++;
                            $ngRedux.dispatch({
                                type: INIT_DASHBOARD,
                                dashboard: mapStandards(item.children),
                            });
                        },
                    } : {
                        onNavigate: () => {
                            return {
                                standard:   item.id,
                                slo:        ctrl.siteLocation,
                                year:       ctrl.year,
                            };
                        },
                    }),
                };
            });
        };

        this.back = () => {
            const resolve = (data, level = 0) => {
                return level === this.level
                    ? data
                    : resolve(data.children, ++level);
            };
            if (this.level > 0) {
                this.level--;
                const dashboard = this.level === 0
                    ? mapStandards(this.sectionsTree)
                    : mapStandards(resolve(this.sectionsTree[this.index]));
                $ngRedux.dispatch({
                    type: INIT_DASHBOARD,
                    dashboard,
                });
            }
        };

        this.reset = () => {
            this.index = 0;
            this.level = 0;
            $rootScope.$broadcast('SELECT_CASCADE_RESET', IDS.accDashboardLocationYear);
            $ngRedux.dispatch({ type: TYPES.SCHEME_SECTIONS_RESET });
            $ngRedux.dispatch({ type: INIT_DASHBOARD, dashboard: [] });
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            this.unsubscribe();
            this.unsubscribeLocationChange();
            this.unsubscribeLocationNotFound();
            this.unsubscribeSelectCascadeLoaded();
        };
    },
    template: `
        <form name="accDashboardLocationYear" novalidate>
            <div layout-gt-xs="row" layout-align="center center">
                <div layout-gt-xs="row">
                    <select-cascade
                        identifier="{{$ctrl.identifier}}"
                        filter-function="$ctrl.filterHeadOffices"
                        purpose="'acc'"
                        scheme="{{$ctrl.$stateParams.itemCode}}"
                        state-path="siteLocation"
                        max="slo">
                    <!-- behaviour="'selectDefault'" -->
                    </select-cascade>
                </div>
                <input-select
                    flex-gt-sm="10"
                    ng-if="$ctrl.yearSelectEnabled"
                    label="{{'COMMON.DATE.YEAR' | translate}}"
                    identifier="{{$ctrl.identifier}}"
                    state-module="accreditation"
                    state-path="year"
                    state-data="availableYears"
                    on-change="$ctrl.onYearChange($event)">
                </input-select>
                <div ng-if="$ctrl.siteLocation && $ctrl.resetButtonEnabled" layout="row" layout-align-xs="center center">
                    <md-button class="btnReset" ng-click="$ctrl.reset()">Reset</md-button>
                </div>
                <div ng-if="$ctrl.level" layout="row" layout-align-xs="center center">
                    <md-button class="btnBack" ng-click="$ctrl.back()">Back</md-button>
                </div>
            </div>
        </form>
    `
};
