import API from '../../common/http/api.fetch';
import { API_BASE } from '../utils/util.constants';
import { variant } from '../../bootstrap.json';
import _ from 'lodash';
import {INIT_APP} from "../app.constants";

export class AuthService {
    constructor($http, $ngRedux, $state, $uiRouterGlobals, Session) {
        this.$http = $http;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.Session = Session;
    }

    login(username, password) {
        return this.$http({
            url: API_BASE + '/login',
            method: 'POST',
            data: {
                username,
                password,
                app: variant,
            }
        }).then(this.createSession.bind(this));
    }

    loginWithEntraID = (token) => {
        return API.fetch({
            url: '/login/microsoft',
            method: 'POST',
            data: {
                token,
                app: variant
            },
        }).then(this.createSession.bind(this));
    };

    logout(session) {
        return this.$http({
            url: API_BASE + '/logout',
            method: 'POST'
        }).then(response => {
            if (typeof session === 'undefined')
                this.destroySession();
            return response.data;
        });
    }

    isAuthenticated() {
        if (localStorage.myrsoAuthToken && localStorage.myrsoJWT) {
            this.$http.defaults.headers.common['X-Authorization'] = localStorage.myrsoAuthToken;
            this.$http.defaults.headers.common['X-Token'] = localStorage.myrsoJWT;
            API.init(this.$http);
        }
        return !!(localStorage.myrsoAuthToken && localStorage.myrsoJWT);
    }

    isPublicLogin() {
        return this.Session.getUser().isPublicLogin;
    }

    isVisitor() {
        return this.Session.getUser().uuid === null;
    }

    // TODO remove
    getUser() {
        return this.Session.getUser();
    }

    // TODO remove
    getUserInfo() {
        const user = this.Session.getUser();
        return user ? `${user.type}: ${user.name}` : null;
    }

    /**
     * @function createSession
     * @param {Object} response
     * @return {Object}
     */
    createSession(response) {
        this.$http.defaults.headers.common['X-Authorization'] = response.headers('X-Authorization');
        this.$http.defaults.headers.common['X-Token'] = response.headers('X-Token');
        API.init(this.$http);
        this.Session.setAuthTokens(response);
        this.Session.setUser(response);
        this.$ngRedux.dispatch({
            type: 'LOGIN',
            user: this.Session.getUser()
        });
        return response;
    }

    /**
     * @function saveSession
     * @param {string} state
     * @param {Object} params
     */
    saveSession(state, params) {
        if (state !== 'app.dashboard') {
            const store = this.$ngRedux.getState();
            const recovery = this.Session.getRecovery();
            const key = store.app.user.uuid || 'default';
            localStorage.setItem('myrsoRecovery', JSON.stringify({
                ...recovery,
                [key]: { state, params },
            }));
        }
    }

    destroySession(recovery) {
        if (!_.isUndefined(recovery) && this.$state.current.name.slice(0, 3) === 'app') {
            this.Session.setRecovery();
        }
        this.$http.defaults.headers.common['X-Authorization'] = '';
        this.$http.defaults.headers.common['X-Token'] = '';
        API.init();
        this.Session.destroy();
    }

    /**
     * @function recoverSession
     */
    recoverSession() {
        const recovery = this.Session.getRecovery();
        if (recovery) {
            const uuid = this.Session.getUser().uuid;
            const key = recovery[uuid] ? uuid : 'default';
            if (recovery[key]) {
                this.Session.destroyRecovery(key);
                this.$state.go(recovery[key].state, recovery[key].params);
                return;
            }
        }
        this.$state.go('app');
    }

    publicLogin(token) {
        return this.$http({
            url: API_BASE + '/public-login',
            method: 'POST',
            data: { token, app: variant }
        }).then(response => {
            this.$http.defaults.headers.common['X-Authorization'] = response.headers('X-Authorization');
            this.$http.defaults.headers.common['X-Token'] = response.headers('X-Token');
            API.init(this.$http);
            this.Session.setAuthTokens(response);
            this.Session.setUser(response);
            if(response.data.subject) { // public token assignee is registered user

                this.$ngRedux.dispatch({
                    type: 'LOGIN',
                    user: this.Session.getUser()
                });
            }
            else { // public token assignee is not registered user
                this.$ngRedux.dispatch({
                    type: INIT_APP,
                    user: this.Session.getUser()
                });
            }
            return response;
        });
    }
}

AuthService.$inject = ['$http', '$ngRedux', '$state', '$uiRouterGlobals', 'Session'];
