import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import { getIsotopes, getAtomicNums } from '../../../radioactive-materials/radioactive-materials.actions';
import { getIsotopesActivity } from '../../reporting.actions';
import watch from 'redux-watch';

class ReportingIsotopesActivityGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, ReportingActions) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.ReportingActions = ReportingActions;
    }

    $onInit() {
        this.module = 'reporting';
        this.identifier = 'reportingIsotopesActivityGrid';
        this.filterTemplate = './reporting-isotopes-activity-grid-filter.html';
        this.filterOpen = true;
        this.autoload = false;
        this.message = 'Select Site Location';

        this.columns = [
            {title: '#',                    mapper: null},
            {title: 'Site Location',        mapper: 'site_location'},
            {title: 'Licence number',       mapper: 'lic_num'},
            {title: 'Isotope',              mapper: 'isotope'},
            {title: 'Type',                 mapper: 'sealed_unsealed'},
            {title: 'Max Activity',         mapper: 'maximum_activity_allowed'},
            {title: 'Current Activity',     mapper: 'current_activity_on_site'},
            {title: 'Within Max Activity',  mapper: 'within_maximum_activity'}
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.ReportingActions)(this);

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this),
            {
                getIsotopesActivity,
                getIsotopes,
                getAtomicNums
            }
        )(this);

        this.getIsotopes('reportingIsotopesActivityGrid');

        const watchIsotopes = watch(this.$ngRedux.getState, 'reporting.' + this.identifier + '.filter.isotopeId');
        this.onIsotope = this.$ngRedux.subscribe(watchIsotopes(input => {
            if (input) {
                this.getAtomicNums('reportingIsotopesActivityGrid', input, true);
            }
        }));

        this.dataAction = this.getIsotopesActivity;
        this.exportService = this.getIsotopesActivity;
        this.filter.identifier = this.identifier;

        this.init();
    }

    $onDestroy() {
        this.destroy();
        this.onIsotope();
        this.$ngRedux.dispatch({
            type: 'GRID_RESET',
            id: 'reportingIsotopesActivityGrid',
        });
    };
}

ReportingIsotopesActivityGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'ReportingActions'];

export const reportingIsotopesActivityGrid = {
    bindings: {
        identifier: '@',
        onReset: '&'
    },
    controller: ReportingIsotopesActivityGridController,
    ...gridTpl
};
