import * as SettingsActions from '../import-settings.actions';
import {IDS} from "../import-settings-action-id.constants";
import {TYPES} from "../import-settings-action-type.constants";

class CentersController {
    constructor($ngRedux, $scope, $timeout, Notifier, $filter) {
        this.$ngRedux = $ngRedux;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.Notifier = Notifier;
        this.$filter = $filter;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(state => {
            return {}
        }, SettingsActions)(this);
    }

    onSelect({item}) {
        this.$ngRedux.dispatch({
            type: TYPES.FETCH_COST_CENTER_DETAILS,
            details: {siteLocation: item.slo_uuid}
        });
        this.$ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: IDS.app_employees_import_settings_centers,
            state: [IDS.app_employees_import_settings_centers_details],
        });
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

CentersController.$inject = ['$ngRedux', '$scope', '$timeout', 'Notifier', '$filter'];

export const empImportSettingsCentersList = {
    controller: CentersController,
    template: `
        <emp-import-settings-centers-list-grid 
            title="{{'EMPLOYEES.IMPORT_SETTINGS.CENTERS.TAB' | translate}}"
            on-select="$ctrl.onSelect($event)">
        </emp-import-settings-centers-list-grid>
`
};
