export const inputServiceContractNumber = {
    bindings: {
        module: '@',
        identifier: '@'
    },
    require : {
        form : '^form'
    },
    template: require('./input-service-contract-number.html'),
    controller: 'InputServiceContractNumberController'
};
