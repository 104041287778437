export const TAB_INIT = 'TAB_INIT';
export const TAB_OVERRIDE = 'TAB_OVERRIDE';
export const TAB_PARAMS = 'TAB_PARAMS';
export const TAB_ENABLE = 'TAB_ENABLE';
export const TAB_DISABLE = 'TAB_DISABLE';
export const TAB_SHOW = 'TAB_SHOW';
export const TAB_HIDE = 'TAB_HIDE';
export const TAB_LOADING = 'TAB_LOADING';
export const TAB_SELECT = 'TAB_SELECT';
export const TAB_SWITCH = 'TAB_SWITCH';
export const TAB_LABEL = 'TAB_LABEL';
export const TAB_BUTTON_LABEL = 'TAB_BUTTON_LABEL';
export const TAB_TRANSITION_DISABLE = 'TAB_TRANSITION_DISABLE';
export const TAB_TRANSITION_ENABLE = 'TAB_TRANSITION_ENABLE';
