/* @ngInject */
export function SafetyPlanTrainingMatrixRecordFactory(User) {
    return function SafetyPlanTrainingMatrixRecord() {

        this.user = new User();
        this.documentId = null;
        this.trainingStatus = null;
        this.siteLocation = null;
        this.accountId = null;
        this.endDate = null;
        this.dueDate = null;
        this.startDate = null;
        this.trainingName = null;
        this.presentationName = null;
    };
}
