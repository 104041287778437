angular
    .module('components.help')
    .component('help', {
        templateUrl: './help.html',
        controller: 'HelpController'
    })
    .config(function($stateProvider) {
        $stateProvider
            .state('app.help', {
                abstract: true,
                url: '/help'
            })
            .state('app.help.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.help.tutorials', {
                url: '/tutorials',
                template: require('./tutorials/help-tutorials.html'),
                controller: 'HelpTutorialsController'
            });
    });
