/* @ngInject */
export function fileUploader($rootScope, $http, API_BASE, FileUploader, $timeout, Notifier, Helpers) {
    return {
        restrict: 'E',
        scope: {
            dropzone: '@',
            inputId: '@',
            validate: '=',
            label: '@',
        },
        template: require('./file-uploader.html'),
        /* @ngInject */
        controller: function($scope) {

            $scope.inputId = _.isUndefined($scope.inputId) ? Helpers.generateIdentifier() : $scope.inputId;
            $scope.label = _.isUndefined($scope.label) ? 'Add document' : $scope.label;

            $scope.options = {
                url: API_BASE + "/file/stream",
                alias: "upstream",
                queueLimit: 1,
                headers: {
                    "X-Authorization": $http.defaults.headers.common['X-Authorization'],
                    "X-Token": $http.defaults.headers.common['X-Token']}
            };

            $scope.saveDisabled = true;

            $scope.uploader = new FileUploader({
                queueLimit: 1,
                autoUpload: true,
                identifier: $scope.inputId,
                onSuccessItem: function (item, response, status, headers) {
                    item.filePath = response.path;
                    item.type = _.get(response, 'type', null);
                    item.width = _.get(response, 'width', null);
                    item.height = _.get(response, 'height', null);
                    angular.element('#' + $scope.inputId)[0].value = '';
                    $scope.saveDisabled = false;
                },
                onBeforeUploadItem: function(item) {
                    item.filePath = null;
                    item.type = null;
                    item.width = null;
                    item.height = null;
                    $scope.saveDisabled = true;
                },
                onCancelItem: function() {
                    $scope.saveDisabled = false;
                },
                onErrorItem: function(fileItem, response, status, headers) {
                    Notifier.error(response);
                    $scope.removeFile(fileItem);
                },
            });

            $scope.validation = function() {
                if ($scope.validate) {
                    $rootScope.$broadcast('show-errors-check-validity', $scope.validate.$name);
                    if ($scope.validate.$invalid) {
                        Notifier.error('Please fill in the form fields');
                        return false;
                    }
                }
                return true;
            };

            $scope.uploadFile = function() {
                if ($scope.uploader.queue.length > 0)
                    return;

                if ($scope.validation()) {
                    //Prevent inprog error, double digest cycle
                    $timeout(function() {
                        angular.element('#' + $scope.inputId).trigger("click");
                    });
                }
            };

            $scope.removeFile = function (item) {
                item.remove();
                angular.element('#' + $scope.inputId)[0].value = '';
                $scope.saveDisabled = true;
            };

            $scope.cancelFile = function (item) {
                item.cancel();
                $scope.saveDisabled = true;
            };

            // TODO refactor this stupidity ASAP!!!
            $scope.saveFile = function () {
                if ($scope.validation()) {
                    $rootScope.$broadcast('FILE_UPLOAD_SUCCESS', $scope.uploader.queue.map(function(item) {
                        return item.filePath
                    }).join(';'), $scope.uploader);
                }
            };
        }
    };
}
