import { IDS } from '../improvement-action-id.constants';
import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import * as ImprovementListActions from './list.actions';
import {GRID_RELOAD} from "../../../../common/grid/grid.constants";
import {APPROVAL_USERS} from "../../accreditation.constants";
import watch from "redux-watch";

class ImprovementListGridController extends GridController {
    constructor($ngRedux, $state, $stateParams, NavigationService, $filter, $mdDialog, $scope, $rootScope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;
    }

    $onInit() {
        this.module = 'accreditation';
        this.identifier = IDS.accImprovement;
        this.statePath = IDS.improvementListGrid;

        this.filterTemplate = '../../improvement/list/list-grid-filter.html';
        this.filterOpen = true;

        this.exportComponent = false;
        this.title = false;
        this.icon = false;
        this.confirmDialogOnRemove = true;
        // this.autoload = false;

        this.columns = [
            {title: '#', mapper: null},
            {title: this.$filter('translate')('COMMON.FIELD.TASK_NUMBER'),      mapper: 'task_number'},
            {title: this.$filter('translate')('COMMON.FIELD.ASSIGNEE'),         mapper: 'assignee'},
            // {title: this.$filter('translate')('COMMON.LOGIN.USERNAME'),         mapper: 'username'},
            {title: this.$filter('translate')('ACCREDITATION.COMMON.STANDARD'), mapper: 'standard_name'},
            {title: this.$filter('translate')('COMMON.DATE.DUE_DATE'),          mapper: 'due_date'},
            {title: this.$filter('translate')('COMMON.FIELD.STATUS'),           mapper: 'status_name'},
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), ImprovementListActions)(this);
        const columnsDefault = this.columns.concat();
        this.unsubscribeCustom = this.$ngRedux.connect(state => {
            const isApprovalUser = APPROVAL_USERS.includes(state.app.user.typeCode);
            if(!isApprovalUser) {
                const columns = columnsDefault.filter(column => !['utaTypeName', 'assignee', 'username'].includes(column.mapper));
                columns.splice(4,0,{title: this.$filter('translate')('ACCREDITATION.COMMON.DOCUMENT'), mapper: 'description'});
                return {
                    columns,
                    filter: {...this.filter,
                        // task_type:'utaccr',
                        // task_status: 'isall',
                    }
                }
            }
            else {
                return {
                    filterActions: {...this.filterActions, isApprovalUser},
                }
            }
        }, {})(this);
        const $rootScope = this.$rootScope;
        const watchReset = watch(this.$ngRedux.getState, 'accreditation.accTasksGrid.reset');
        this.unsubscribeOnReset = this.$ngRedux.subscribe(watchReset(function() {
            $rootScope.$broadcast('SELECT_CASCADE_RESET', 'accreditationTasksFilterForm');
        }));
        this.$scope.$on('LOCATION_SUBJECT_SELECTED', this.onLocationSelected.bind(this));
        this.filter.scheme_code = this.$stateParams.itemCode;
        this.dataAction = this.fetchAssessedImprovementListGrid;
        this.init();
    }

    onLocationSelected(event, location) {
        if(location.in_subject_basic_type_code === 'slo') {
            this.filter.slo_uuid = location.in_subject_uuid;
            this.$ngRedux.dispatch({
                type: GRID_RELOAD,
                id: IDS.accImprovement,
                path: IDS.improvementListGrid,
            })
        }
    }

    $onDestroy() {
        this.destroy();
    }

}

ImprovementListGridController.$inject = ['$ngRedux', '$state', '$stateParams', 'NavigationService', '$filter', '$mdDialog', '$scope', '$rootScope'];

export const accImprovementListGrid = {
    bindings: {
        onSelect: '&',

    },
    controller: ImprovementListGridController,
    ...gridTpl
}
