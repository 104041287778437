import { GridController, gridTpl } from '../../../../common/grid/grid.controller';

class PersonalDosimetrySettingsGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, PersonalDosimetryActions) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.PersonalDosimetryActions = PersonalDosimetryActions;
    }

    $onInit() {
        this.module = 'personalDosimetry';
        this.identifier = 'personalDosimetrySettingsGrid';
        this.filterTemplate = './pd-settings-grid-filter.html';
        this.exportComponent = false;

        this.columns = [
            {title: '#',                    mapper: null},
            {title: 'Service Provider',     mapper: 'provider_name'},
            {title: 'Badge Type',           mapper: 'badge_type'},
            {title: 'Employee Occupation',  mapper: 'occupation'},
            {title: 'Location',             mapper: 'loc_name'},
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.PersonalDosimetryActions)(this);
        this.dataAction = this.getSettings;
        this.exportService = this.getSettings;
        this.filter.identifier = this.identifier;

        this.init();
    }

    $onDestroy() {
        this.destroy();
        this.$ngRedux.dispatch({
            type: 'GRID_RESET',
            id: 'personalDosimetrySettingsGrid',
        });
    }
}

PersonalDosimetrySettingsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'PersonalDosimetryActions'];

export const personalDosimetrySettingsGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onRemove: '&',
        onReset: '&'
    },
    controller: PersonalDosimetrySettingsGridController,
    ...gridTpl,
};
