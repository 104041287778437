/* @ngInject */
export function UserManagementAllUsersController($scope, $location, $rootScope, $routeParams, UserManagementService, UserService, AuthService, Account) {

    $rootScope.toolbarTitle = "Administration | All users";
    $rootScope.toolbarColor = "#795548";

    $rootScope.submenu = [
        {
            title: 'Administration',
            icon: 'accounts.svg',
            color: '795548',
            url: 'administration'
        },
        {
            title: 'Global user management',
            icon: 'clients.svg',
            color: '795548',
            url: 'administration/user_management'
        },
        {
            title: 'Add Main Account',
            icon: 'accounts.svg',
            color: '795548',
            url: 'administration/accounts/add'
        },
        {
            title: 'Add Site Location',
            icon: 'accounts.svg',
            color: '795548',
            url: 'administration/accounts/add'
        },
        {
            title: 'Manage Site Locations',
            icon: 'accounts.svg',
            color: '795548',
            url: 'administration/accounts/manage'
        }
    ];

    $scope.selectedUser = null;

    if ($routeParams.uuid === undefined){
        UserService.fetchUsersAvailable().then(function(data){
            $scope.users = data;
        });
    }
    else {
        UserService.fetchUsers($routeParams.uuid).then(function(data){
            $scope.parentName = data[0].account.parent.username;
            $scope.users = data;
        });
    }

    $scope.selectItem = function(item) {
        $scope.selectedUser = item;
        $scope.modifiedUser = null;
    };

    $scope.modify = function() {
        $scope.modifiedUser = $scope.selectedUser;
        $scope.selectedUser = null;
    };

    $scope.updateUser = function() {
        UserService.updateUser($scope.modifiedUser);
    };

    $scope.login = function() {
        AuthService.loginAsUser($scope.selectedUser.uuid).then(function(data) {
            $location.path("/");
        });
    };
}
