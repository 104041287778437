/* @ngInject */
export function ProtectiveEquipmentTestReportsController($controller, $ngRedux, $scope, $q, $timeout, Notifier, ProtectiveEquipmentTestService, ProtectiveEquipmentService, ProtectiveEquipmentManager, PersonalEquipmentTest, PersonalEquipmentTestComment, Grid, FileDocument) {

    angular.extend(this, $controller('ApplicationModuleController', {
        $scope: $scope,
        $timeout: $timeout,
        Notifier: Notifier
    }));

    angular.extend(this, $controller('ProtectiveEquipmentBaseTestController', {
        $scope: $scope,
        $ngRedux: $ngRedux,
        Notifier: Notifier,
        ProtectiveEquipmentTestService: ProtectiveEquipmentTestService,
        ProtectiveEquipmentManager: ProtectiveEquipmentManager,
        PersonalEquipmentTest: PersonalEquipmentTest,
        PersonalEquipmentTestComment: PersonalEquipmentTestComment,
        Grid: Grid,
        FileDocument: FileDocument
    }));

    $scope.tabs = ProtectiveEquipmentManager.getTestReportsTabs();
    _.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TESTS]).navigation = false;
    _.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS]).navigation = false;

    $scope.$watch('selectedTab', (function(newTab) {
        if (_.get(newTab, 'id', null) == ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TESTS) {
            _.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS]).disabled = true;
        }
    }).bind(this));

    _.extend(_.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TESTS]), {
        exec: function() {
            var deferred = $q.defer();
            ProtectiveEquipmentTestService.getPersonalEquipmentTestDetails($scope.equipmentTest.id).then(function(test) {
                _.assign($scope.equipmentTest, test);
                _.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS]).disabled = false;
                deferred.resolve();
            });
            return deferred.promise;
        },
        post: function() {
            var deferred = $q.defer();
            ProtectiveEquipmentTestService.getTesterCandidates($scope.equipmentTest.equipmentId).then(function(data) {
                $scope.equipmentTesters = data;
                deferred.resolve();
            }, function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS]), {
        exec: function() {
            var deferred = $q.defer();
            ProtectiveEquipmentTestService.updatePersonalEquipmentTest($scope.equipmentTest).then(function() {
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'protectiveEquipmentTestReportsGrid'
                });
                Notifier.success('Test was successfully updated');
                deferred.resolve();
            }, function(error) {
                Notifier.responseMessage(error);
            });
            return deferred.promise;
        }
    });
}
