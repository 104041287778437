class SelectReportingDateRangeController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);
    }

    onSelect() {
        if (this.selectedOption) {
            this.$ngRedux.dispatch({
                type: 'DATE_RANGE_SELECTED',
                id: this.identifier,
                dateRange: this.selectedOption
            });
        }
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeModule();
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        }
    }

    mapState(state) {
        return {
            options: state[this.module][this.identifier].dateRange,
            selectedOption: state[this.module][this.identifier].filter.expiryDateRange
        }
    }
}

SelectReportingDateRangeController.$inject = ['$ngRedux'];

export const selectReportingDateRange = {
    bindings: {
        module: '@',
        identifier: '@'
    },
    controller: SelectReportingDateRangeController,
    template: `
        <md-input-container class="md-block" flex>
            <label>Expires in</label>
            <md-select ng-model="$ctrl.selectedOption" md-on-close="$ctrl.onSelect()">
                <md-option ng-value="item.value" ng-repeat="item in $ctrl.options">
                    <span class="md-body-2">{{item.name}}</span>
                </md-option>
            </md-select>
        </md-input-container>
    `
};
