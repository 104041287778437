export const GRID_INIT = 'GRID_INIT';
export const GRID_INIT_FILTER = 'GRID_INIT_FILTER';
export const GRID_OVERRIDE = 'GRID_OVERRIDE';
export const GRID_LOADING = 'SHOW_LOADING';
export const GRID_PAGINATE = 'PAGINATE';
export const GRID_FILTER = 'FILTER';
export const GRID_FILTER_TOGGLE = 'GRID_FILTER_TOGGLE';
export const GRID_RESET = 'GRID_RESET';
export const GRID_RESET_FILTER = 'GRID_RESET_FILTER';
export const GRID_RELOAD = 'GRID_RELOAD';
export const GRID_FETCH = 'GRID_FETCH';
export const GRID_SELECT = 'GRID_SELECT';
export const GRID_SELECT_ALL = 'GRID_SELECT_ALL';
export const GRID_DESELECT = 'GRID_DESELECT';
export const GRID_DESELECT_ALL = 'GRID_DESELECT_ALL';
export const GRID_SHOW_LOADING = 'SHOW_LOADING';

