/** @module root/components/accreditation/standards */

import * as STATES                      from './standard.states';
import { IDS }                          from './standard-action-id.constants';
// import { accAssessmentDocuments }    from './documents-progress-grid/required-documents.component';
import { accStandardDocsGrid }          from './docs/standard-docs-grid.component';
import { accStandardDocDetails }        from "./doc-details/doc-details.component";
import { accStandardDocs }              from "./docs/standard-docs.component";
import { accStandardDocReviewsGrid }            from "./doc-details/doc-reviews-grid.component";

export const STANDARD_MODULE = angular.module(
    'migration.accreditation.standards',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
        Object.values({
            ...STATES,
        }).map(state => stateRegistry.register(state));
    }]
).component({
    accStandardDocs,
    accStandardDocsGrid,
    accStandardDocDetails,
    accStandardDocReviewsGrid,
}).run(['$templateCache', ({ put }) => {
    put(IDS.app_accreditation_scheme_standards_documents_list, '<acc-standard-docs></acc-standard-docs>');
    put(IDS.app_accreditation_scheme_standards_documents_details, '<acc-standard-doc-details></acc-standard-doc-details>');
}]);
