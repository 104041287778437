export const selectEquipment = {
    bindings: {
        identifier: '@',
        name: '@',
        statePath: '@',
        validate: '<',
        required: '<'
    },
    require : {
        form : '^form'
    },
    // templateUrl: './select-equipment.html',
    template: require('./select-equipment.html'),
    controller: 'SelectEquipmentController'
};
