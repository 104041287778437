export const drlCtScannerSystemStatusState = {
    name: 'app.doseReferenceLevels.ctScanner.systemStatus',
    redirectTo: 'app.doseReferenceLevels.ctScanner.systemStatus.listing',
    url: '/system-status',
    component: 'tabs'
};

export const drlCtScannerSystemStatusListingState = {
    name: 'app.doseReferenceLevels.ctScanner.systemStatus.listing',
    url: '',
    component: 'drlCtScannerSystemStatus'
};
