import * as ImportActions from '../../import.actions';
import get from 'lodash/get';
import {IDS} from "../../import-action-id.constants";
import {getDocFileNameFromPath} from "../../../../../common/utils/util.path";

class DetailsController {
    constructor($ngRedux, $transitions, $timeout, Notifier, $filter, FileActions) {
        this.$ngRedux = $ngRedux;
        this.$transitions = $transitions;
        this.$timeout = $timeout;
        this.Notifier = Notifier;
        this.$filter = $filter;
        this.FileActions = FileActions;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(state => {
            const details = get(state.employees,`${IDS.empImport}.${IDS.empImportHistoryDetails}`);
            return {
                ...details,
                filename: getDocFileNameFromPath(details.doc_uri) || '',
            }
        }, { ...ImportActions, ...this.FileActions })(this);
    }

    downloadSourceDoc() {
        if(this.doc_uri) {
            this.download(`storage${this.doc_uri}`, {removeDocIdPrefix:true});
        }
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

DetailsController.$inject = ['$ngRedux', '$transitions', '$timeout', 'Notifier', '$filter','FileActions'];

export const empImportHistoryDetails = {
    controller: DetailsController,
    template: `
        <tabs-header title="{{'EMPLOYEES.IMPORT.DETAILS.TAB' | translate}}"></tabs-header>
        <div layout-gt-md="row">
            <div layout="row" flex style="margin: 20px">
                <div layout-gt-xs="column" style="min-width:150px">
                    <div class="accreditation-table-item md-body-1">Issuer:</div>
                    <div class="accreditation-table-item md-body-1">File:</div>
                    <div class="accreditation-table-item md-body-1">Total Records:</div>
                    <div class="accreditation-table-item md-body-1">Status:</div>
                    <div class="accreditation-table-item md-body-1">Time:</div>
        
                </div>
                <span flex="5"></span>
                <div layout-gt-xs="column">
                    <div class="accreditation-table-item md-body-1">{{$ctrl.issuer}}</div>
                    <div class="accreditation-table-item md-body-1">{{$ctrl.filename}}</div>
                    <div class="accreditation-table-item md-body-1">{{$ctrl.total_records}}</div>
                    <div class="accreditation-table-item md-body-1">{{$ctrl.status_name}}</div>
                    <div class="accreditation-table-item md-body-1">{{$ctrl.started}}</div>
                </div>
            </div>
        </div>
        <emp-import-history-details-grid
            title="{{'EMPLOYEES.IMPORT.HISTORY.GRID_TITLE' | translate}}">
        </emp-import-history-details-grid>
        <div layout="row">
            <div layout="row" layout-align-xs="center center">
                <md-button class="btnDefault" ng-click="$ctrl.downloadSourceDoc()" ng-disabled="!$ctrl.doc_uri">Download Source Document</md-button>
            </div>
        </div>
`
};
