import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import defer from 'lodash/defer';

/**
 * Creates a new ScannerController.
 * @class
 */
class ScannerController {
    constructor($ngRedux, $rootScope, $state, $mdDialog, NavigationService, InventoryScannerActions, Notifier) {
        this.$ngRedux = $ngRedux;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.NavigationService = NavigationService;
        this.actions = InventoryScannerActions;
        this.Notifier = Notifier;
        this.dialog = null;
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);
    }

    onStart = () => {
        const scanCtrl = ($scope) => {
            $scope.equipment = [];
            $scope.headerColor = this.colorName;
            $scope.headerTitle = 'Scanning';

            if (this.scanner.location) {
                $scope.message = 'Please wait...';
                $scope.dialogControls = true;
                $scope.scannerControls = false;
            }
            else {
                $scope.message = 'First scan location QR CODE';
                $scope.dialogControls = false;
                $scope.scannerControls = true;
            }

            $scope.btnFinish = false;
            $scope.btnSubmitReport = false;
            $scope.listing = false;
            $scope.equipmentTypes = false;

            $scope.matching = (item) => {
                const match = find(this.scanner.equipment, ['id', item.user_friendly_id]);
                return match ? match.scanStatus : 'NOT_SCANNED';
            };

            $scope.onScanSuccess = (event) => {
                $scope.info = false;
                const data = JSON.parse(event.value);
                switch (data.action) {
                    case 'location':
                        if ($scope.equipment.length) {
                            $scope.message = 'Please scan equipment QR CODE';
                            $scope.status = false;
                            $scope.info = true;
                            this.Notifier.error('Incorrect');
                        }
                        else {
                            $scope.message = 'Please wait...';
                            this.$ngRedux.dispatch({
                                type: 'LOCATION_SELECTED',
                                id: 'inventoryScanner',
                                uuid: data.uuid,
                                locationName: data.name
                            });
                            this.getInventory(data.uuid).then((result) => {
                                $scope.equipment = result.items;
                                $scope.message = `${$scope.equipment.length} piece(s) of equipment are allocated to ${data.name}`;
                                $scope.message += `\nDo you want to scan all?`;
                                $scope.status = true;
                                $scope.info = true;
                                $scope.scannerControls = false;
                                $scope.btnSubmitReport = false;
                                $scope.dialogControls = true;
                                this.Notifier.success('Success');
                                defer(() => $scope.$digest());
                            });
                        }
                        break;
                    case 'equipment':
                        if ($scope.equipment.length) {
                            if (find($scope.equipment, ['user_friendly_id', data.id])) {
                                $scope.status = true;
                                this.Notifier.success('Success');
                                assignScanResult(data);
                            } else {
                                $scope.message = 'Please wait...';
                                this.getScanDetails(data.id).then((result) => {
                                    $scope.status = false;
                                    this.Notifier.error('Incorrect Equipment Location!');
                                    assignScanResult(Object.assign({}, data, result[0]));
                                }).catch(() => {
                                    this.Notifier.error('Something went wrong. Please try again.');
                                    this.$mdDialog.cancel();
                                });
                            }
                        } else {
                            $scope.message = 'First scan location QR CODE';
                            $scope.status = false;
                            this.Notifier.error('Incorrect');
                            $scope.info = true;
                            defer(() => $scope.$digest());
                        }
                        break;
                    default:
                    // TODO
                }
            };

            const assignScanResult = (data) => {
                this.$ngRedux.dispatch({
                    type: 'SCAN',
                    id: 'inventoryScanner',
                    data: Object.assign({}, data, {scanStatus: $scope.status ? 'PRESENT' : 'INCORRECT'})
                });

                if ($scope.equipment.length === this.scanner.equipment.length)
                    $scope.message = 'Scanning completed. Please click FINISH button.';
                else
                    $scope.message = `Please scan equipment ${this.scanner.equipment.length+1} of ${$scope.equipment.length}`;

                $scope.btnFinish = true;
                $scope.info = true;
                defer(() => $scope.$digest());
            };

            $scope.setMessage = (event) => {
                $scope.info = false;
                $scope.message = event.message;
                $scope.listing = false;
                defer(() => $scope.$digest());
            };

            $scope.finish = (event) => {
                $scope.misplaced = this.scanner.misplaced;
                $scope.info = false;
                $scope.listing = true;
                $scope.message = ($scope.equipment.length === this.scanner.equipment.length) ? 'SCANNING COMPLETED' : 'SCANNING INCOMPLETE';
                $scope.scannerControls = false;
                $scope.btnSubmitReport = true;
                $scope.dialogControls = true;
            };

            const dialogNo = (event) => {
                $scope.message = 'Please wait...';
                $scope.info = false;
                this.$ngRedux.dispatch({
                    type: 'EQUIPMENT_CALIBRATION_TYPES',
                    id: 'inventoryScanner',
                    items: orderBy(
                        $scope.equipment.reduce((result, item) => {
                            const { category } = item;
                            if (!find(result, ['id', category.id])) {
                                result.push({
                                    id: category.id,
                                    value: category.id,
                                    name: category.name,
                                    label: category.name,
                                    item: {
                                        type: category.id,
                                        typeName: category.name,
                                        typeCode: item.typeCode,
                                    }
                                });
                            }
                            return result;
                        }, []),
                        [item => item.name.toLowerCase()],
                        'asc',
                    ),
                });
                $scope.message = 'Do you want to scan a particular type?';
                $scope.dialogActionNo = dialogWithTypeNo;
                $scope.dialogActionYes = dialogWithTypeYes;
                $scope.equipmentTypes = true;
            };

            const dialogYes = (event) => {
                $scope.message = `Please scan equipment ${this.scanner.equipment.length+1} of ${$scope.equipment.length}`;
                $scope.info = false;
                $scope.equipmentTypes = false;
                $scope.dialogControls = false;
                $scope.scannerControls = true;
            };

            const dialogWithTypeNo = (event) => {
                $scope.message = 'Please scan equipment QR CODE';
                $scope.equipmentTypes = false;
                $scope.dialogControls = false;
                $scope.scannerControls = true;
            };

            const dialogWithTypeYes = (event) => {
                if ($scope.scannerForm.$valid) {
                    const data = $scope.equipment.filter(item => {
                        return item.category.id === this.scanner.type;
                    });
                    if (data.length) {
                        $scope.equipment = data;
                        $scope.message = `Please scan equipment ${this.scanner.equipment.length+1} of ${$scope.equipment.length}`;
                        $scope.equipmentTypes = false;
                        $scope.dialogControls = false;
                        $scope.btnFinish = true;
                        $scope.scannerControls = true;
                    }
                    else $scope.message = 'There is no equipment of this type!'
                }
            };

            $scope.continueScanning = (event) => {
                $scope.message = `Please scan equipment ${this.scanner.equipment.length+1} of ${$scope.equipment.length}`;
                $scope.listing = false;
                $scope.dialogControls = false;
                $scope.btnSubmitReport = false;
                $scope.scannerControls = true;
            };

            $scope.submitReport = (event) => {
                this.Notifier.info('Please wait...', true);
                this.genScanReport(this.scanner).then(() => {
                    this.$mdDialog.cancel();
                    this.Notifier.success('Scanning report has been successfully submitted!');
                });
            };

            $scope.cancel = (event) => {
                this.$mdDialog.cancel();
            };

            $scope.dialogActionNo = dialogNo;
            $scope.dialogActionYes = dialogYes;
        };
        scanCtrl.$inject = ['$scope'];

        this.dialog = this.$mdDialog.show({
            controller: scanCtrl,
            template: require('./dialogs/scanner-dialog.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            onComplete: (scope) => {
                if (this.scanner.location) {
                    scope.message = 'Please wait...';
                    this.getInventory(this.scanner.location).then((result) => {
                        scope.equipment = result.items;
                        scope.message = `${scope.equipment.length} piece(s) of equipment are allocated to ${this.scanner.locationName}`;
                        scope.message += `\nDo you want to scan all?`;
                        scope.btnFinish = true;
                        defer(() => scope.$digest());
                    });
                }
            }
        }).then(angular.noop, () => {
            this.$ngRedux.dispatch({type: 'SCANNER_RESET'});
            this.$rootScope.$broadcast('SELECT_CASCADE_RESET', 'scannerForm');
        });
    };

    mapState(state) {
        return {
            scanner: state.inventoryScanner.scanner
        }
    }

    $onDestroy() {
        this.unsubscribe();
        this.dialog && this.$mdDialog.hide(this.dialog);
    }
}

ScannerController.$inject = ['$ngRedux', '$rootScope', '$state', '$mdDialog', 'NavigationService', 'InventoryScannerActions', 'Notifier'];

export const scanner = {
    controller: ScannerController,
    template: `
        <md-button class="btnColor{{$ctrl.colorName}}" ng-click="$ctrl.onStart()">
            <asset cls="rightMargin" src="qrcode-scan.svg"></asset> QR CODE Scanner
        </md-button>
    `
};
