import { GridReducer } from '../common/grid/grid.reducer';
import { administrationAccountsLicencesGridFilter } from '../components/administration/accounts/grid/administration-accounts-licences-grid.component';
import merge from 'lodash/merge';
import set from 'lodash/set';
import update from "lodash/update";
import * as FORM from '../common/form/input/input.constants';
import {TYPES} from '../components/administration/accounts/head-offices/head-offices-action-type.constants';
import {IDS} from '../components/administration/accounts/head-offices/head-offices-action-id.constants';

/**
 * @function administrationAccountsHeadOfficesReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function administrationAccountsHeadOfficesReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            administrationAccountsHeadOfficesGrid: merge(GridReducer(), {
                filter: {
                    keyword: null
                }
            }),
            administrationAccountsHeadOffice: {
                addressStates: [],
                address1: null,
                suburb: null,
                postcode: null,
                country: null,
                state: null,
                uuid: null,
                id: null,
                name: null,
                email: null,
                phone: null,
                created: null,
                fiscalYearStart: 1,
                businessNumber: null,
                corporationNumber: null,
                verify_geo_location: null,
                disableCheckboxEnable2fa: null,
            },
            administrationAccountsHeadOfficeActiveModules: [],

            administrationAccountsHeadOfficeInputSelect: {
                options: [1,2,3,4,5,6,7,8,9,10,11,12].map(index => {
                    return {
                        value: index,
                        label:`COMMON.DATE.MONTHS.FULL.${index}`,
                    }
                })
            },
            administrationAccountsHeadOfficeContactsGrid: merge(set(GridReducer(), 'itemsCount', undefined), {filter: administrationAccountsLicencesGridFilter}),
            administrationAccountsHeadOfficeContact: {
                title: 'New Contact',
                contactCandidates: [],
                candidate: null,
                data: {
                    uuid: null,
                    username: null,
                    password: null,
                    managerType: null,
                    gender: null,
                    firstName: null,
                    lastName: null,
                    birthDate: null,
                    phone: null,
                    fax: null,
                    email: null
                }
            },
            administrationAccountsHeadOfficeLicencesGrid: merge(GridReducer(), {
                filter: {
                    uuid: null,
                    type: null,
                    status: 'act'
                }
            }),
        };
    }

    switch (action.type) {
        case 'HEAD_OFFICE_FETCH':
            if (action.id === 'administrationAccountsHeadOffice') {
                return Object.assign({...state}, {
                    administrationAccountsHeadOffice: {
                        ...action.data,
                        activeModules: undefined
                    },
                    administrationAccountsHeadOfficeActiveModules: action.data.activeModules
                });
            }
        case 'HEAD_OFFICE_RESET':
            if (action.id === 'administrationAccountsHeadOffice') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOffice: {
                        addressStates: [],
                        address1: null,
                        suburb: null,
                        postcode: null,
                        country: null,
                        state: null,
                        uuid: null,
                        id: null,
                        name: null,
                        email: null,
                        phone: null,
                        created: null,
                        fiscalYearStart: 1,
                        businessNumber: null,
                        corporationNumber: null,
                        verify_geo_location: null,
                        disableCheckboxEnable2fa: null,
                    },
                    administrationAccountsHeadOfficeActiveModules: []
                });
            }
        case 'CONTACT_CANDIDATES_FETCH':
            if (action.id === 'administrationAccountsHeadOfficeContact') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficeContact: Object.assign({}, state.administrationAccountsHeadOfficeContact, {contactCandidates: action.items})
                });
            }
        case 'CONTACT_FETCH':
            if (action.id === 'administrationAccountsHeadOfficeContact') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficeContact: Object.assign({}, state.administrationAccountsHeadOfficeContact, {
                        title: 'Contact Data',
                        data: {
                            uuid: action.data.uuid,
                            username: action.data.username,
                            password: action.data.password,
                            gender: action.data.gender,
                            firstName: action.data.firstName,
                            lastName: action.data.lastName,
                            birthDate: action.data.birthDate,
                            phone: action.data.phone,
                            fax: action.data.fax,
                            email: action.data.email,
                            managerType: action.data.managerType
                        }
                    })
                });
            }
        case 'CONTACT_RESET':
            if (action.id === 'administrationAccountsHeadOfficeContact') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficeContact: Object.assign({}, state.administrationAccountsHeadOfficeContact, {
                        title: 'New Contact',
                        candidate: null,
                        data: {
                            uuid: null,
                            username: null,
                            password: null,
                            gender: null,
                            firstName: null,
                            lastName: null,
                            birthDate: null,
                            phone: null,
                            fax: null,
                            email: null,
                            managerType: null
                        }
                    })
                });
            }
        case 'AUTOCOMPLETE_SELECTED':
            if (action.id === 'administrationAccountsHeadOfficeContact') {
                const administrationAccountsHeadOfficeContact = {...state.administrationAccountsHeadOfficeContact};
                if (action.item) {
                    set(administrationAccountsHeadOfficeContact, 'data.managerType', action.item.type_id);
                }
                return { ...state, administrationAccountsHeadOfficeContact: set(administrationAccountsHeadOfficeContact, action.path, action.item) };
            }
        case 'VALIDATE_USERNAME':
            if (action.id === 'administrationAccountsHeadOfficeContact' && action.valid) {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficeContact: set(Object.assign({}, state.administrationAccountsHeadOfficeContact), action.path, action.username)
                });
            }
        case 'DATE_SELECTED':
        case 'INPUT_SELECT':
            if (action.id === 'administrationAccountsHeadOfficeContact') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficeContact: set(Object.assign({}, state.administrationAccountsHeadOfficeContact), action.path, action.input)
                });
            }
            if (action.id === 'administrationAccountsHeadOffice') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOffice: set(Object.assign({}, state.administrationAccountsHeadOffice), action.path, action.input)
                });
            }
        case 'COUNTRY_SELECTED':
            if (action.id === 'administrationAccountsHeadOffice' && action.path === 'addressStates') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOffice: set(Object.assign({}, state.administrationAccountsHeadOffice), 'country', action.country)
                });
            }
        case 'COUNTRY_STATES_FETCH':
            if (action.id === 'administrationAccountsHeadOffice') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOffice: set(Object.assign({}, state.administrationAccountsHeadOffice), action.path, action.items)
                });
            }
        case 'COUNTRY_STATE_SELECTED':
            if (action.id === 'administrationAccountsHeadOffice' && action.path === 'addressStates') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOffice: set(Object.assign({}, state.administrationAccountsHeadOffice), 'state', action.countryState)
                });
            }
        case 'INPUT_TEXT_CHANGE':
            if (action.id === 'administrationAccountsHeadOfficesGrid') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficesGrid: set(GridReducer(state.administrationAccountsHeadOfficesGrid, set(action, 'type', 'PAGINATE')), 'filter.keyword', action.input)
                });
            }
        case 'GENDER_SELECTED':
            if (action.id === 'administrationAccountsHeadOfficeContact') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficeContact: set(Object.assign({}, state.administrationAccountsHeadOfficeContact), action.path, action.gender)
                });
            }
        case 'PASSWORD_CHANGE':
            if (action.id === 'administrationAccountsHeadOfficeContact') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficeContact: set(Object.assign({}, state.administrationAccountsHeadOfficeContact), action.path, action.password)
                });
            }
        case 'PASSWORD_RESET':
            if (action.id === 'administrationAccountsHeadOfficeContact') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficeContact: set(Object.assign({}, state.administrationAccountsHeadOfficeContact), action.path, null)
                });
            }
        case 'LOCATION_SELECTED':
            if (action.id === 'administrationAccountsHeadOfficeContactsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficeContactsGrid: set(GridReducer(state.administrationAccountsHeadOfficeContactsGrid, {type: 'PAGINATE'}), 'filter.uuid', action.hof)
                });
            }
            if (action.id === 'administrationAccountsHeadOfficeLicencesGrid') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficeLicencesGrid: set(GridReducer(state.administrationAccountsHeadOfficeLicencesGrid, {type: 'PAGINATE'}), 'filter.uuid', action.hof)
                });
            }
        case 'CHECKBOX_SELECTED':
            if (action.id === 'administrationAccountsHeadOffice') {
                return set({...state}, `administrationAccountsHeadOffice.${action.path}`, action.input);
            }
        case 'MANAGER_TYPES':
            if (action.id === 'administrationAccountsHeadOfficeContact') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficeContact: set(Object.assign({}, state.administrationAccountsHeadOfficeContact), action.path, action.items)
                });
            }
        case 'GRID_OVERRIDE':
        case 'SHOW_LOADING':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
            if (action.id === 'administrationAccountsHeadOfficesGrid') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficesGrid: GridReducer(state.administrationAccountsHeadOfficesGrid, action)
                });
            }
            if (action.id === 'administrationAccountsHeadOfficeContactsGrid') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficeContactsGrid: GridReducer(state.administrationAccountsHeadOfficeContactsGrid, action)
                });
            }
            if (action.id === 'administrationAccountsHeadOfficeLicencesGrid') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficeLicencesGrid: GridReducer(state.administrationAccountsHeadOfficeLicencesGrid, action)
                });
            }
        case 'GRID_RESET':
            if (action.id === 'administrationAccountsHeadOfficesGrid') {
                return Object.assign({}, state, {
                    administrationAccountsHeadOfficesGrid: update(GridReducer(state.administrationAccountsHeadOfficesGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            keyword: null
                        });
                    })
                });
            }
        case FORM.INPUT_CHECKBOX_LIST:
            if(action.id === IDS.administrationAccountsHeadOfficeActiveModules) {
                return {
                    ...state,
                    administrationAccountsHeadOfficeActiveModules: action.data,
                }
            }
        default:
            return state;
    }
}
