import { gridTpl } from '../../../../common/grid/grid.controller';

export const employeeLocationsGrid = {
    bindings: {
        title: '@',
        onStatus: '&',
    },
    controller: 'EmployeeLocationsGridController',
    ...gridTpl
};
