import { gridTpl} from "../../../../../common/grid/grid.controller";

export const signalAndContractRatioGrid = {
    bindings: {
        identifier: '@',
        onReset: '&'
    },
    controller: 'signalAndContractRatioGridController',
    ...gridTpl
};
