import { saveAs } from 'file-saver';
import moment from 'moment'
import isObject from 'lodash/isObject';

/* @ngInject */
export function ProtectiveEquipmentService($http, API_BASE, PersonalEquipment, PersonalEquipmentType, PersonalBodyProtection, PersonalLeadEquivalence, PersonalEquipmentHistoryActivity, FileImage) {

    this.getPersonalEquipmentTypes = function() {
        return $http({
            url: API_BASE + "/protective-equipment/personal/equipment-types",
            method: "GET"
        }).then(function(response) {
            var types = response.data.map(function(item, index, collection) {
                var equipmentType       = new PersonalEquipmentType();
                equipmentType.id        = item.ety_id;
                equipmentType.parentId  = _.isUndefined(_.find(collection, ['ety_id', item.par_id])) ? null : item.par_id;
                equipmentType.name      = item.name;
                equipmentType.testing   = item.is_regulated;
                return equipmentType;
            }).filter((function(item) {
                var parentId = item.parentId;
                item.children = this(item.id);
                return !(parentId && this(parentId).push(item));
            }).bind(_.memoize(function() { return []; })));

            function flatten(array, level, result) {
                array.forEach(function(el) {
                    el.level = level;
                    result.push(el);
                    if (el.children.length) {
                        flatten(el.children, level + 1, result);
                    }
                });
            }

            var result = [];
            flatten(types, 0, result);
            return result;
        }, function(error) {
            return error;
        });
    };

    this.addPersonalEquipmentType = function(equipmentType) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/equipment-type-add",
            method: "POST",
            data: {
                parentId:   equipmentType.parentId,
                name:       equipmentType.name,
                testing:    equipmentType.testing
            }
        }).then(function(response) {
            return response.data.id;
        });
    };

    this.getBodyProtection = function() {
        return $http({
            url: API_BASE + "/protective-equipment/personal/body-protection",
            method: "GET"
        }).then(function(response) {
            return response.data.map(function(item) {
                var bodyProtection  = new PersonalBodyProtection();
                bodyProtection.id   = item.ecl_id;
                bodyProtection.name = item.name;
                return bodyProtection;
            });
        }, function(error) {
            return error;
        });
    };

    this.getPersonalLeadEquivalences = function() {
        return $http({
            url: API_BASE + "/protective-equipment/personal/lead-equivalences",
            method: "GET"
        }).then(function(response) {
            return response.data.map(function(item) {
                var leadEquivalence     = new PersonalLeadEquivalence();
                leadEquivalence.id      = item.leq_id;
                leadEquivalence.value   = item.value;
                return leadEquivalence;
            });
        }, function(error) {
            return error;
        });
    };

    this.addPersonalLeadEquivalence = function(leadEquivalence) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/lead-equivalence-add",
            method: "POST",
            data: {
                leadEquivalence: leadEquivalence.value
            }
        }).then(({ data }) => {
            const params = isObject(data.params) ? data.params : JSON.parse(data.params);
            return params.leq_id;
        });
    };

    this.getPersonalEquipment = function(filter) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/equipment",
            method: "GET",
            params: {
                locationUuid:       filter.location,
                equipmentTypeId:    filter.type,
                offset:             filter.offset,
                limit:              filter.limit,
                exportFormat:       filter.exportFormat,
                exportColumns:      filter.exportColumns
            }
        }).then(function(response) {
            if (filter.exportFormat) {
                return response.data;
            }
            else {
                var equipment = response.data.rows.map(function(item) {
                    var personalEquipment                   = new PersonalEquipment();
                    personalEquipment.id                    = item.equ_id;
                    personalEquipment.location.uuid         = item.site_location_uuid;
                    personalEquipment.location.name         = item.site_location_name;
                    personalEquipment.type.id               = item.ety_id;
                    personalEquipment.type.name             = item.equipment_type;
                    personalEquipment.bodyProtection.id     = item.class_id;
                    personalEquipment.bodyProtection.name   = item.class_name;
                    personalEquipment.leadEquivalence.id    = item.leq_id;
                    personalEquipment.leadEquivalence.value = item.lead_equivalences;
                    personalEquipment.equipmentId           = item.equipment_id;
                    personalEquipment.barcode               = item.barcode;
                    return personalEquipment;
                });
                return {
                    items: equipment,
                    total: Number.isInteger(response.data.total)
                        ? response.data.total
                        : JSON.parse(response.data.total).total_records
                }
            }
        });
    };

    this.getPersonalEquipmentDetails = function(equipmentId) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/equipment-details",
            method: "GET",
            params: {
                equipmentId: equipmentId
            }
        }).then(function(response) {
            var item = _.first(_.first(response.data));
            var personalEquipment                   = new PersonalEquipment();
            personalEquipment.id                    = item.equ_id;
            personalEquipment.location.uuid         = item.site_location_uuid;
            personalEquipment.location.name         = item.site_location_name;
            personalEquipment.type.id               = item.ety_id;
            personalEquipment.type.name             = item.equipment_type;
            personalEquipment.bodyProtection.id     = item.class_id;
            personalEquipment.bodyProtection.name   = item.class_name;
            personalEquipment.leadEquivalence.id    = item.leq_id;
            personalEquipment.leadEquivalence.value = item.lead_equivalences;
            personalEquipment.description           = item.description;
            personalEquipment.equipmentId           = item.equipment_id;
            personalEquipment.barcode               = item.barcode;

            personalEquipment.locationList = response.data[1];

            personalEquipment.images = response.data[2].map(function(item) {
                var img         = new FileImage();
                img.id          = item.fil_id;
                img.type        = item.type;
                img.url         = item.url;
                img.description = item.description;
                img.width       = item.width;
                img.height      = item.height;
                return img;
            });
            return personalEquipment;
        });
    };

    this.getEquipmentHistory = function(filter) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/equipment-history",
            method: "GET",
            params: {
                equipmentId:    filter.equipmentId,
                offset:         filter.offset,
                limit:          filter.limit
            }
        }).then(function(response) {
            var activities =  response.data.rows.map(function(item) {
                var activity            = new PersonalEquipmentHistoryActivity();
                activity.id             = item.action_id;
                activity.code           = item.action_code;
                activity.date           = moment(item.action_time, 'YYYY-MM-DD hh:mm:ss').toDate();
                activity.type           = item.action_type;
                activity.description    = JSON.parse(item.action_description);
                activity.userUuid       = item.issuer_uuid;
                activity.userType       = item.issuer_type_name;
                activity.userFirstName  = item.issuer_first_name;
                activity.userLastName   = item.issuer_last_name;
                return activity;
            });
            return {
                items: activities,
                total: JSON.parse(response.data.total).total_records
            }
        }, function(error) {
            return error;
        });
    };

    this.addPersonalEquipment = function(personalEquipment) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/equipment-add",
            method: "POST",
            data: {
                locationUuid:       personalEquipment.location.uuid,
                equipmentTypeId:    personalEquipment.type.id,
                description:        personalEquipment.description,
                bodyProtectionId:   personalEquipment.bodyProtection.id,
                leadEquivalenceId:  personalEquipment.leadEquivalence.id,
                equipmentId:        personalEquipment.equipmentId,
                barcode:            personalEquipment.barcode,
                imgIdList:          personalEquipment.getImages()
            }
        }).then(({ data }) => {
            const params = isObject(data.params) ? data.params : JSON.parse(data.params);
            return params.equ_id;
        });
    };

    this.updatePersonalEquipment = function(personalEquipment) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/equipment-update",
            method: "POST",
            data: {
                id:                 personalEquipment.id,
                locationUuid:       personalEquipment.location.uuid,
                equipmentTypeId:    personalEquipment.type.id,
                description:        personalEquipment.description,
                bodyProtectionId:   personalEquipment.bodyProtection.id,
                leadEquivalenceId:  personalEquipment.leadEquivalence.id,
                equipmentId:        personalEquipment.equipmentId,
                barcode:            personalEquipment.barcode,
                imgIdList:          personalEquipment.getImages()
            }
        }).then(({ data }) => {
            return data.status;
        }, function(error) {
            return error;
        });
    };

    this.addPersonalEquipmentImage = function(equipmentId, image) {
        return $http({
            url: API_BASE + "/protective-equipment/personal/equipment-image-add",
            method: "POST",
            data: {
                equipmentId:    equipmentId,
                url:            image.url,
                description:    image.description,
                type:           image.type,
                width:          image.width,
                height:         image.height
            }
        }).then(({ data }) => {
            const params = isObject(data.params) ? data.params : JSON.parse(data.params);
            return params.fil_id;
        });
    };

    this.printQRCode = (equId, visualId, action) => {
        return $http({
            url: API_BASE + '/protective-equipment/qrcode/print',
            method: 'POST',
            responseType: 'arraybuffer',
            data: {
                equId,
                id: visualId,
                action,
            }
        }).then(response => {
            saveAs(new Blob([response.data], { type: 'application/pdf' }), `${visualId}.pdf`);
        });
    };
}
