export const mainState = {
    name: 'app.pnp',
    abstract: true,
    url: '/pnp'
};

export const dashboardState = {
    name: 'app.pnp.dashboard',
    url: '',
    component: 'dashboard'
};