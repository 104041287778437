import watch from 'redux-watch';

/* @ngInject */
export function EquipmentGridSwitchController($ngRedux, $filter) {
    var ctrl = this;
    var unsubscribe = angular.noop;
    var onSwitch = angular.noop;
    var onActiveCount = angular.noop;
    var onArchiveCount = angular.noop;
    var onFileManagement = angular.noop;
    this.$filter = $filter;

    var labels = {
        arc: this.$filter("translate")("COMMON.GRID.SWITCH_ACTIVE"),
        act: this.$filter("translate")("COMMON.GRID.SWITCH_ARCHIVED")
    };

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, {})(this);
        ctrl.label = labels.act;
        ctrl.switchCount = ctrl.archiveCount;

        var watchArchive = watch($ngRedux.getState, ctrl.module +  '.' + ctrl.identifier + '.filter.status');
        onSwitch = $ngRedux.subscribe(watchArchive(function(newValue) {
            ctrl.label = _.get(labels, newValue);
            switch (newValue) {
                case 'act':
                    ctrl.switchCount = ctrl.archiveCount;
                    break;
                case 'arc':
                    ctrl.switchCount = ctrl.activeCount;
                    break;
            }
        }));

        var watchActiveCount = watch($ngRedux.getState, ctrl.module + '.' + ctrl.identifier + '.activeCount');
        onActiveCount = $ngRedux.subscribe(watchActiveCount(function() {
            if (ctrl.status === 'arc')
                ctrl.switchCount = ctrl.activeCount;
        }));

        var watchArchiveCount = watch($ngRedux.getState, ctrl.module + '.' + ctrl.identifier + '.archiveCount');
        onArchiveCount = $ngRedux.subscribe(watchArchiveCount(function() {
            if (ctrl.status === 'act')
                ctrl.switchCount = ctrl.archiveCount;
        }));

        var watchStatusFileManagement = watch($ngRedux.getState, 'equipment.' + ctrl.identifier + '.fileManagement');
        onFileManagement = $ngRedux.subscribe(watchStatusFileManagement(function(newValue) {
            ctrl.fileManagement = newValue;
        }));
    };

    ctrl.switchData = function() {
        $ngRedux.dispatch({
            type: 'EQUIPMENT_SWITCH',
            id: ctrl.identifier,
            status: getStatus()
        });
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onSwitch();
        onActiveCount();
        onArchiveCount();
        onFileManagement();
    };

    function mapStateToThis(state) {
        return {
            status: state[ctrl.module][ctrl.identifier].filter.status,
            activeCount: state[ctrl.module][ctrl.identifier].activeCount,
            archiveCount: state[ctrl.module][ctrl.identifier].archiveCount,
        };
    }

    function getStatus() {
        switch(ctrl.status) {
            case 'act':
                return 'arc';
            case 'arc':
                return 'act';
        }
    }
}
