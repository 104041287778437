import {TYPES as ACC_TYPES} from "../accreditation-action-type.constants";
import {IDS} from "./acc-documents-action-id.constants";
import {TAB_HIDE, TAB_DISABLE} from '../../../common/tabs/tabs.constants';

const QUERY_PARAMS = '?year&slo&token';

export const accDocumentsTabsState = {
    name: IDS.app_accreditation_scheme_acc_documents,
    redirectTo: IDS.app_accreditation_scheme_acc_documents_list,
    url: `/:itemCode/acc-documents${QUERY_PARAMS}`,
    component: 'tabs'
};

export const accDocumentsListState = {
    name: IDS.app_accreditation_scheme_acc_documents_list,
    url: '/list',
    // component: 'accDocumentsList',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: IDS.app_accreditation_scheme_acc_documents,
            state: [
                IDS.app_accreditation_scheme_acc_documents_details,
                IDS.app_accreditation_scheme_acc_documents_add
            ]
        });
    }
};
accDocumentsListState.onEnter.$inject = ['$ngRedux'];

export const accDocumentsDetailState = {
    name: IDS.app_accreditation_scheme_acc_documents_details,
    url: '/details',
    // component: 'accDocumentsDetails',
};



export const accDocumentsAddState = {
    name: IDS.app_accreditation_scheme_acc_documents_add,
    url: '/add',
    // component: 'accDocumentsDetails',
};

