import API from '../../../../common/http/api.fetch';
import I18N from '../../../../common/utils/util.i18n';
import {success, responseMessage} from '../../../../common/utils/util.notifier';
import { GRID_FETCH, GRID_RELOAD, GRID_FILTER } from '../../../../common/grid/grid.constants';
import { TYPES } from '../settings-action-type.constants';
import { IDS } from '../settings-action-id.constants';
import { getDocFileNameFromPath } from "../../../../common/utils/util.path";

const localizeType = (code, defaultName) => {
    const textId = `DOCUMENT_MANAGEMENT.TYPES.${code.toUpperCase()}`;
    const translation = I18N.translate(textId);
    return translation === textId ? defaultName : translation
};

export const fetchDefaultDocumentsGrid = (filter) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/default-required-doc-list',
            method: 'GET',
            params: {
                ...filter,
                path: undefined
            }
        }).then(response => {
            dispatch({
                type:   GRID_FETCH,
                id:     IDS.accSettings,
                path:   filter.path,
                ...response.data,
                items:  response.data.items.length ? response.data.items.map(item => {
                    return {
                        ...item,
                        type_name: localizeType(item.type_code, item.type_name),
                        templateFilename: getDocFileNameFromPath(item.template) || 'N/A'
                    }
                }) : [],
            })
        }, error => {
            responseMessage(error);
        });
    };
}

export const addDefaultStandardRequiredDoc = (data) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/add-required-doc',
            method: 'POST',
            data
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_ADDED');
        }, error => {
            responseMessage(error);
        });
    };
}

export const editDefaultRequiredDocument = (data) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/default-required-doc-edit',
            method: 'POST',
            data,
        }).then(response => {
            dispatch({
                type:   GRID_RELOAD,
                id:     IDS.accSettings,
                path:   IDS.defaultDocumentsGrid,
            })
            success('COMMON.SERVER_RESPONSE.ITEM_UPDATED');
        }, error => {
            responseMessage(error);
        });
    };
}

export const invalidateRequiredDocument = (ard_id, path) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/invalidate-stand-required-doc',
            method: 'POST',
            data: { ard_id }
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_DELETED');
            dispatch({
                type:   GRID_RELOAD,
                id:     IDS.accSettings,
                path,
            });
        }, error => {
            responseMessage(error);
        });
    };
}

// export const setAssessmentItemTemplate = (aas_id, slo_uuid, template, path) => {
//     return dispatch => {
//         return API.fetch({
//             url: '/accreditation/assessment-template-set',
//             method: 'POST',
//             data: {
//                 aas_id,
//                 slo_uuid,
//                 template: template || undefined
//             }
//         }).then(response => {
//             success('COMMON.SERVER_RESPONSE.ITEM_UPDATED');
//             dispatch({
//                 type:   GRID_RELOAD,
//                 id:     IDS.accSettings,
//                 path,
//             });
//         }, error => {
//             responseMessage(error);
//         });
//     };
// }

export const swapAssessmentItemsOrder = (target_id, dest_id, path) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/assessment-swap',
            method: 'POST',
            data: {
                target_id,
                dest_id,
            }
        }).then(response => {
            dispatch({
                type:   GRID_RELOAD,
                id:     IDS.accSettings,
                path,
            })
        }, error => {
            responseMessage(error);
        });
    };
}

// export const restoreLocationAssessment = (ast_id, slo_uuid, path) => {
//     return dispatch => {
//         return API.fetch({
//             method: 'POST',
//             url: '/accreditation/assessment-location-restore',
//             data: { ast_id, slo_uuid }
//         }).then(() => {
//             dispatch({
//                 type:   GRID_RELOAD,
//                 id:     IDS.accSettings,
//                 path,
//             })
//             success('Location Assessment Restored');
//         }, error => {
//             responseMessage(error);
//         })
//     }
// }

export const eraseAssessmentItemsGrid = () => {
    return dispatch => {
        return dispatch({
            type:   GRID_FETCH,
            id:     IDS.accSettings,
            path:   IDS.defaultDocumentsGrid,
            items:  [],
            itemsCount: 0,
        })
    }
}

export const setLocationFilter = (slo_uuid) => {
    return dispatch => {
        return dispatch({
            type:   GRID_FILTER,
            id:     IDS.accSettings,
            path:   IDS.defaultDocumentsGrid,
            filter: { slo_uuid }
        })
    }
}

export const fetchAssessmentHeadOfficesGrid = (filter) => {
    return dispatch => {
        if(filter?.newAssessmentHeadOffices) {
            dispatch({
                type:   GRID_FETCH,
                id:     IDS.accSettings,
                path:   filter.path,
                itemsCount: filter.newAssessmentHeadOffices.length,
                items: filter.newAssessmentHeadOffices.slice(filter.offset, filter.offset + 10).map( item => ({
                    ...item,
                    template_filename: getDocFileNameFromPath(item.template) || 'N/A'
                })),
            });
            return
        }
        // if(filter?.aas_id) {
        //     return API.fetch({
        //         url:    '/accreditation/required-doc-head-offices-list',
        //         method: 'GET',
        //         params: {
        //             ...filter,
        //             path: undefined,
        //             newAssessmentHeadOffices: undefined
        //         }
        //     }).then(response => {
        //         dispatch({
        //             type:   GRID_FETCH,
        //             id:     IDS.accSettings,
        //             path:   filter.path,
        //             ...response.data,
        //             items: response.data.items.map( item => ({
        //                 ...item,
        //                 template_filename: getDocFileNameFromPath(item.template) || 'N/A'
        //             })),
        //         });
        //     }, error => {
        //         responseMessage(error);
        //     });
        // };
    }
}
// export const fetchAssignedAssessmentHeadOffices = (aas_id) => {
//     return dispatch => {
//         return API.fetch({
//             url:    '/accreditation/required-doc-head-offices-list',
//             method: 'GET',
//             params: {
//                 aas_id,
//                 limit: -1
//             }
//         }).then(response => {
//             return  response.data.items.map( item => (item.hof_uuid))
//         }, error => {
//             responseMessage(error);
//         });
//     };
// }

// export const fetchHeadOfficePNPTemplates = (loc_uuid) => {
//     return dispatch => {
//         return API.fetch({
//             url:    '/accreditation/head-office-pnp-templates-list',
//             method: 'GET',
//             params: { loc_uuid }
//         }).then(response => {
//             dispatch({
//                 type:   TYPES.ASSESSMENT_FETCH_PNP_TEMPLATES,
//                 id:     IDS.accSettings,
//                 items: response.data.items.map( item => ({
//                     value: item.id,
//                     label: item.template_name,
//                     description: item.description,
//                 })),
//             });
//         }, error => {
//             responseMessage(error);
//         });
//     };
// }

export const fetchSiteLocationDetails = (siteLocationUuid) => {
    return dispatch => {
        return API.fetch({
            url: `/location/sitelocation/${siteLocationUuid}`,
            method: 'GET',
            params: {}
        }).then(response => {
            return response.data[0][0];
        })
    }
}
//
// export const getPnpHeadOffices = (schemeId) => {
//     return dispatch => {
//         return API.fetch({
//             url: '/accreditation/scheme-locations',
//             method: 'GET',
//             params: {
//                 asc_id: schemeId
//             }
//         }).then(response => {
//             dispatch({
//                 type: TYPES.FETCH_PNP_HEAD_OFFICES,
//                 id: IDS.accreditationSettingsAssessmentDetails,
//                 items: response.data.items.map(item => {
//                     return {
//                         value: item.slo_uuid,
//                         label: item.slo_name,
//                     }
//                 })
//             });
//             return response.data.items;
//         })
//     }
// }


export const getSchemeHeadOffices = (schemeId, assignedHeadOffices) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/scheme-locations',
            method: 'GET',
            params: {
                asc_id: schemeId,
            }
        }).then(({data}) => {
            const unassignedHofsOnly = data.items.filter(item => assignedHeadOffices.includes(item.slo_uuid) === false);
            dispatch({
                type: TYPES.FETCH_ASS_HOF_SCHEME_HEAD_OFFICES,
                id: IDS.accreditationSettingsAssessmentHeadOffice,
                items: unassignedHofsOnly.map(item => ({
                    value: item.slo_uuid,
                    label: item.slo_name,
                })),
            });
            return data.items.length
        })
    }
}