import API from '../../../common/http/api.fetch';
// import I18N from '../../../../common/utils/util.i18n';
import {success, responseMessage} from '../../../common/utils/util.notifier';
import { GRID_FETCH } from '../../../common/grid/grid.constants';
import {TYPES as ACTION_TYPES} from './rep-monthly-action-type.constants';
import { IDS } from './rep-monthly-action-id.constants';
import { getDocFileNameFromPath } from "../../../common/utils/util.path";
import truncate from 'lodash/truncate';
import {formatDate} from "../../../common/utils/util.date";
import {DATE_FORMAT} from "../../../common/utils/util.constants";

// const localizeType = (code, defaultName) => {
//     const textId = `DOCUMENT_MANAGEMENT.TYPES.${code.toUpperCase()}`;
//     const translation = I18N.translate(textId);
//     return translation === textId ? defaultName : translation
// };

export const fetchRepMonthlyGrid = (filter) => {
    if(!filter.mrp_id) {
        return;
    }
    return dispatch => {
        return API.fetch({
            url:    '/reporting/list-monthly-report-history',
            method: 'GET',
            params: {
                ...filter,
            }
        }).then(({data}) => {
            dispatch({
                type:   GRID_FETCH,
                id:     IDS.repMonthly,
                path:   IDS.repMonthlyGrid,
                ...data,
                items: data.items.map( item => ({
                    ...item,
                    filename: getDocFileNameFromPath(item.doc_uri) || '',
                }))
            })
        }, error => {
            responseMessage(error);
        });
    };
}

export const fetchRuns = () => {
    return dispatch => {
        return API.fetch({
            url: '/reporting/list-monthly-report-runs',
            method: 'GET',
            params: {}
        }).then(({data}) => {
            if(data.items.length) {
                dispatch({
                    type: ACTION_TYPES.FETCH_RUNS,
                    id: IDS.repMonthly,
                    ...data,
                });
            }
        })
    }
}

export const fetchRunsHofsGrid = (filter) => {
    return dispatch => {
        return API.fetch({
            url: '/reporting/list-monthly-report-hofs-grid',
            method: 'GET',
            params: filter,
        }).then(({data}) => {
            dispatch({
                type:   ACTION_TYPES.FETCH_RUN_HOFS,
                id:     IDS.repMonthly,
                ...data,
            });
        })
    }
}

export const fetchSiteLocationDetails = (siteLocationUuid) => {
    return dispatch => {
        return API.fetch({
            url: `/location/sitelocation/${siteLocationUuid}`,
            method: 'GET',
            params: {}
        }).then(response => {
            return response.data[0][0];
        })
    }
}