export const reportingEquipmentSummaryState = {
    name: 'app.reporting.summary',
    redirectTo: 'app.reporting.summary.listing',
    url: '/equipment-summary',
    component: 'tabs'
};

export const reportingEquipmentSummaryListingState = {
    name: 'app.reporting.summary.listing',
    url: '',
    component: 'reportingEquipmentSummary'
};
