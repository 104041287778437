export const equipmentCalibrationState = {
    name: 'app.equipmentCalibration',
    abstract: true,
    url: '/equipment-calibration'
};

export const equipmentCalibrationDashboardState = {
    name: 'app.equipmentCalibration.dashboard',
    url: '',
    component: 'dashboard'
};
