import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import watch from 'redux-watch';
import concat from 'lodash/concat';
import find from 'lodash/find';

class ReportingEquipmentSummaryGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, ReportingActions, EquipmentService, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.ReportingActions = ReportingActions;
        this.EquipmentService = EquipmentService;
        this.grid_filter = $filter
    }

    $onInit() {
        this.module = 'reporting';
        this.identifier = 'reportingEquipmentSummaryGrid';
        this.autoload = false;
        this.filterTemplate = './reporting-equipment-summary-grid-filter.html';
        this.exportService = this.EquipmentService.filterInspections;

        this.columns = [
            {title: this.grid_filter('translate')('REPORTING.TABS.EQUIPMENT_SUMMARY.GRID_COL_EQUIP_TYPE'),       mapper: 'name'},
            {title: this.grid_filter('translate')('REPORTING.TABS.EQUIPMENT_SUMMARY.GRID_COL_NUM_MACHINES'),     mapper: 'count'}
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.ReportingActions)(this);
        this.dataAction = this.getEquipmentSummary;

        const watchGroupBy = watch(this.$ngRedux.getState, 'reporting.' + this.identifier + '.filter.groupByDepartment');
        this.onGroupBy = this.$ngRedux.subscribe(watchGroupBy(newValue => {
            this.updateColumns(newValue);
        }));

        this.init();
    }

    $onDestroy() {
        this.destroy();
        this.onGroupBy();
    };

    updateColumns(groupBy) {
        if (groupBy) {
            this.columns = concat(this.columns, [{title: 'Department', mapper: 'department'}]);
        }
        else {
            const column = find(this.columns, o => { return o.mapper === 'department'; });
            if (column) this.columns = this.columns.filter(o => { return o.mapper !== 'department' });
        }
    }
}

ReportingEquipmentSummaryGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'ReportingActions', 'EquipmentService', '$filter'];

export const reportingEquipmentSummaryGrid = {
    bindings: {
        identifier: '@',
        onReset: '&'
    },
    controller: ReportingEquipmentSummaryGridController,
    ...gridTpl
};
