import { GridController, gridTpl } from '../../../common/grid/grid.controller';
import * as EventActions from '../events.actions'

class EventsGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$filter = $filter;
    }

    $onInit() {
        this.module = 'events';
        this.identifier = 'eventsGrid';
        this.filterTemplate = './events-grid-filter.html';

        this.columns = [
            {title: '#',                    mapper: null},
            {title: 'Event ID',             mapper: 'print_event_id'},
            {title: 'Event Date',           mapper: 'event_date'},
            {title: 'Risk / Incident',      mapper: 'risk_name'},
            {title: 'Location',             mapper: 'head_office'},
            {title: 'Event',                mapper: 'event'},
            {title: 'Type',                 mapper: 'event_type'},
            {title: 'Category',             mapper: 'event_category'},
            {title: 'Subcategory',          mapper: 'event_sub_category'},
            {title: 'Responsible Person',   mapper: 'responsible_person'},
            {title: 'Improvement Status',   mapper: 'status'}
        ];

        this.buttons = [{label: 'PDF Report', class: 'btnGrid', callback: this.onReport}];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), EventActions)(this);

        this.dataAction = this.getEvents;
        this.exportService = this.getEvents;
        this.filter.identifier = this.identifier;

        this.filterActions.getEventTypes = (event) => {
            // this.$ngRedux.dispatch({
            //     type: 'GRID_RESET_FILTER',
            //     id: 'eventsGrid',
            //     exclude: ['code']
            // });
            // this.$ngRedux.dispatch({
            //     type: 'GRID_RELOAD',
            //     id: 'eventsGrid',
            // });
            this.getEventTypes('eventItem', event.input);
        };

        this.filterActions.getEventSubtypes = (event) => {
            this.getEventSubtypes('eventItem', this.filter.code);
        };

        this.filterActions.getEventCategories = (event) => {
            this.getEventCategories('eventItem', this.filter.code, this.filter.event_id, event.input);
        };

        this.filterActions.getEventSubcategories = (event) => {
            this.getEventSubcategories('eventItem', this.filter.code, this.filter.event_id, this.filter.type_id, event.input);
        };

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const eventsGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onReport: '&',
        onRemove: '&',
        onReset: '&'
    },
    controller: EventsGridController,
    ...gridTpl
};
