import API from '../../common/http/api.fetch';
import * as TYPE from './safety-training.actions.types';
import get from 'lodash/get';
import { ST_VIDEO_PROGRESS } from "./safety-training.actions.types";

export const getApplicantTrainings = () => {
    return (dispatch, getState) => {
        const state = getState();
        return API.fetch({
            url: '/applications-permits/applicant/trainings',
            method: 'GET',
            params: {
                token: get(state, 'safetyTraining.stVideo.token'),
                training_id: get(state, 'safetyTraining.stVideo.training_id')
            }
        }).then(({ data }) => {
            dispatch({
                type: TYPE.ST_VIDEOS,
                id: 'stVideo',
                path: 'trainings',
                items: data
            })
        });
    }
}

export const getCandidateReview = () => {
    return (dispatch, getState) => {
        const state = getState();
        return API.fetch({
            url: '/applications-permits/applicant/review',
            method: 'GET',
            params: {
                token: get(state, 'safetyTraining.stVideo.token'),
                training_id: get(state, 'safetyTraining.stVideo.training_id')
            }
        }).then(({ data }) => {
            if (typeof data !== 'undefined') {
                if(typeof data.params !== 'undefined') {
                    data = data.params;
                }
                dispatch({
                    type: TYPE.ST_CANDIDATE_REVIEW,
                    id: 'stVideo',
                    path: 'candidateReview',
                    item: {
                        nextSection: data.next_section,
                        reviewId: data.presentation_review_id
                    }
                })
                dispatch({
                    type: ST_VIDEO_PROGRESS,
                    id: 'stVideo',
                    completion: data.next_section,
                    progress: 0
                });
            } else {
                dispatch({
                    type: TYPE.ST_CANDIDATE_REVIEW,
                    id: 'stVideo',
                    path: 'candidateReview',
                    item: {
                        nextSection: null
                    }
                })
            }
        });
    }
}

export const updateCandidateReview = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        return API.fetch({
            url: '/applications-permits/applicant/update-review',
            method: 'POST',
            data: {
                token: get(state, 'safetyTraining.stVideo.token'),
                ...data
            }
        }).then(({ data }) => {
            return data;
        });
    }
}
