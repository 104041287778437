/*
*   List of Actions with TYPE that should be passed from accreditation.reducer.js to acc-documents.reducer.js
 */
export const PREFIX = 'ACC_DOCUMENTS';

export const TYPES = {
    DOCUMENT_SAMPLE_DOC_CHANGED:          `${PREFIX}_DOCUMENT_SAMPLE_DOC_CHANGED`,
    SECTION_DETAILS_REFRESH:      `${PREFIX}_SECTION_DETAILS_REFRESH`,
    DOC_DETAILS_REFRESH:                `${PREFIX}_DOC_DETAILS_REFRESH`,
    SELECT_CASCADE_STANDARD:            `${PREFIX}_SELECT_CASCADE_STANDARD`,
    SELECT_CASCADE_STANDARD_RESET:      `${PREFIX}_SELECT_CASCADE_STANDARD_RESET`,
    SCHEME_DETAILS_REFRESH:             `${PREFIX}_SCHEME_DETAILS_REFRESH`,
};
