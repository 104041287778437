import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';

class ReportingEquipmentInfoHistoryGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.grid_filter = $filter
    }

    $onInit() {
        this.module = 'reporting';
        this.identifier = 'reportingEquipmentInfoHistoryGrid';
        this.autoload = false;
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                mapper: null},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_HISTORY.GRID_TRANS_ID"),    mapper: 'id'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_HISTORY.GRID_TRANS_TYPE"),  mapper: 'type.name'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_HISTORY.GRID_BY"),          mapper: 'issuerName'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_HISTORY.GRID_TRANS_TIME"),  mapper: 'time'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_HISTORY.GRID_LOCATION"),    mapper: 'location.name'},
            {title: this.grid_filter('translate')("REPORTING.TABS.EQUIPMENT_HISTORY.GRID_DOCUMENT"),    mapper: 'file-download'}
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), {})(this);
        this.dataAction = () => {};

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

ReportingEquipmentInfoHistoryGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter'];

export const reportingEquipmentInfoHistoryGrid = {
    bindings: {
        title: '@'
    },
    controller: ReportingEquipmentInfoHistoryGridController,
    ...gridTpl
};
