angular
    .module('components.mammo-quality-control')
    .component('mammoQualityControl', {
        template: require('./mammo-quality-control.html'),
        controller: 'MammoQualityControlController'
    })
    .config(function($stateProvider) {
        $stateProvider
            .state('app.mammoQualityControl', {
                abstract: true,
                url: '/mammo-quality-control'
            })
            .state('app.mammoQualityControl.dashboard', {
                url: '',
                component: 'dashboard',
                params: {
                    minimizeSubmenu: true
                }
            })
            .state('app.mammoQualityControl.dicomQC', {
                abstract: true,
                url: '/dicom-qc'
            })
            .state('app.mammoQualityControl.dicomQC.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.mammoQualityControl.dicomQC.test', {
                url: '/test',
                template: require('../../components.html'),
                controller: 'DicomQualityControlTestController'
            })
            .state('app.mammoQualityControl.dicomQC.testMethod', {
                url: '/test-method',
                component: 'mammoQualityControl'
            })
            .state('app.mammoQualityControl.dicomQC.reports', {
                url: '/reports',
                component: 'mammoQualityControl'
            })
            .state('app.mammoQualityControl.detectorFFCalibration', {
                url: '/detector-ff-calibration',
                component: 'mammoQualityControl'
            })
            .state('app.mammoQualityControl.artifactEvaluation', {
                redirectTo: 'app.mammoQualityControl.artifactEvaluation.details',
                url: '/artifact-evaluation',
                component: 'tabs'
            })
            .state('app.mammoQualityControl.artifactEvaluation.details', {
                url: '/details',
                component: 'mammoQualityControlArtifactEvaluationDetails'
            })
            .state('app.mammoQualityControl.artifactEvaluation.listing', {
                url: '',
                component: 'mammoQualityControlArtifactEvaluation'
            })
            .state('app.mammoQualityControl.snAndCnRatioMeasurements', {
                url: '/sn-cn-ratio-measurements',
                template: require('./views/signal-and-contract-ratio-measurement.html'),
                controller: 'mammoSignalAndContractRatioMeasurementController'
            })
            .state('app.mammoQualityControl.phantomControl', {
                url: '/phantom-control',
                component: 'mammoQualityControl'
            })
            .state('app.mammoQualityControl.diagnosticReviewWorkstation', {
                url: '/diagnostic-review-workstation',
                component: 'mammoQualityControl'
            })
            .state('app.mammoQualityControl.compressionThicknessIndicator', {
                url: '/compression-thickness-indicator',
                component: 'mammoQualityControl'
            })
            .state('app.mammoQualityControl.viewboxViewingConditions', {
                url: '/viewbox-viewing-conditions',
                component: 'mammoQualityControl'
            })
            .state('app.mammoQualityControl.visualChecklist', {
                url: '/visual-checklist',
                component: 'mammoQualityControl'
            })
            .state('app.mammoQualityControl.repeatRejectAnalysis', {
                url: '/repeat-reject-analysis',
                component: 'mammoQualityControl'
            })
            .state('app.mammoQualityControl.compressions', {
                url: '/compressions',
                component: 'mammoQualityControl'
            })
            .state('app.mammoQualityControl.reports', {
                url: '/reports',
                component: 'mammoQualityControl'
            });
    });
