import {IDS} from '../assessment-reports-action-id.constants';
import {IDS as ASSESSMENT_IDS} from '../../standard/standard-action-id.constants';
import * as DocumentDetailsActions from './details.actions';
import * as DocumentListActions from '../list/list.actions';
import {GRID_RELOAD} from "../../../../common/grid/grid.constants";
import get from 'lodash/get';
import moment from "moment";
class documentDetailsController {
    constructor($ngRedux,
                $state,
                $stateParams,
                $filter,
                $timeout,
                Notifier,
                DocumentManager,
                EmployeeService,
                FileActions) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.$timeout = $timeout;
        this.Notifier = Notifier;
        this.DocumentManager = DocumentManager;
        this.EmployeeService = EmployeeService;
        this.FileActions = FileActions;
        this.standardNumber = 'Activity';
        this.identifier = IDS.accAssessmentReports;
    }

    $onInit() {
        this.schemeCode         = this.$stateParams.itemCode;
        this.adoId              = this.$stateParams.ado;
        this.sloFiscalYear      = this.$stateParams.year;

        this.unsubscribe = this.$ngRedux.connect(state => {
            const documentDetails   = state.accreditation.accDocument.documentDetails;
            const review            = documentDetails.review;
            const originalReview    = documentDetails.originalReview;
            const areChangesSaved   = review && originalReview
                                    && review.statusCode === originalReview.statusCode
                                    && review.reviewComment === originalReview.reviewComment
            return {
                ...documentDetails,
                review,
                disableSubmitButton: areChangesSaved,
                disableCommentField: areChangesSaved,
                standardNumber:      get(documentDetails,'standardName','').split(' ')[0],
                fiscalYearStart:     state.accreditation.accDocument.fiscalYearStart,
            }
        }, {
            ...DocumentDetailsActions,
            ...DocumentListActions,
            ...this.FileActions,
        })(this);

        this.initReviewerStatuses(['pen','app','nap']);
    }

    onSubmit(){
        if(this.reviewForm.$valid) {
            this.setDocumentResult({
                ado_id:         this.adoId,
                arq_id:         this.review.arqId,
                aar_id:         this.review.aarId          || undefined,
                status_code:    this.review.statusCode     || undefined,
                review_comment: this.review.reviewComment  || undefined,
            }).then( () => {
                this.$ngRedux.dispatch({
                    type: GRID_RELOAD,
                    id: IDS.accAssessmentReports,
                    path: IDS.accAssessmentReportsGrid,
                });
                this.fetchDocumentDetails(this.adoId);
            })
        }
    }

    onBackButton() {
        const docFiscalYear = this.fiscalYear;
        this.$state.go(ASSESSMENT_IDS.app_accreditation_scheme_standards_documents, {
            itemCode:   this.schemeCode,
            standard:   this.standardId,
            year:       this.sloFiscalYear || this.docFiscalYear || this.getFiscalYear(),
            slo:        this.siteLocationUuid,
        });
    }

    getFiscalYear () {
        const fiscalYearStart = moment().month(this.fiscalYearStart).day(0);
        return moment().isAfter(fiscalYearStart) ? moment().year() : moment().year() - 1;
    }

    onDownloadButton() {
        this.download(`storage${this.url}`);
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

documentDetailsController.$inject = [
    '$ngRedux',
    '$state',
    '$stateParams',
    '$filter',
    '$timeout',
    'Notifier',
    'DocumentManager',
    'EmployeeService',
    'FileActions']

export const accAssessmentReportDetails = {
    controller: documentDetailsController,
    template: require('./details.html')
}
