class TabsController {
    constructor($ngRedux, $state, NavigationService) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.NavigationService = NavigationService;
        this.icon = null;
        this.colorName = null;
        this.icon = null;
        this.tabs = [];
    }

    $onInit() {
        this.id = this.$state.$current.parent.name.replace(/\./g, '-');
        this.identifier = this.$state.$current.parent.name;
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);

        this.$ngRedux.dispatch({
            type: 'TAB_INIT',
            id: this.identifier,
            tabs: this.tabs
        });
    }

    selectTab(tab) {
        this.$ngRedux.dispatch({
            type: 'TAB_SWITCH',
            id: this.identifier,
            selectedState: tab.state,
            selectedTabIndex: this.selectedTabIndex,
            stateParams: this.stateParams
        });
        this.$state.go(tab.state, this.stateParams);
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeModule();
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        };
    }

    mapState(state) {
        return {
            tabs: state[this.module][this.identifier].tabs,
            selectedTabIndex: state[this.module][this.identifier].selectedTabIndex,
            stateParams: state[this.module][this.identifier].stateParams,
            isTransitionDisabled: state[this.module][this.identifier].isTransitionDisabled,
        };
    }
}

TabsController.$inject = ['$ngRedux', '$state', 'NavigationService'];

export const tabs = {
    controller: TabsController,
    template: require('./tabs.html')
};
