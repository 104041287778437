import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import { getAlertReceivers, setAlertReceivers }from '../events-alert-escalate.actions'

class EventsAlertReceiversGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService, $uiRouterGlobals, $filter) {
        super($ngRedux, $state, NavigationService, $uiRouterGlobals, $filter);
        this.$ngRedux = $ngRedux;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.$filter = $filter;
    }

    $onInit() {
        this.module = 'events';
        this.identifier = 'eventsAlertReceiversGrid';
        this.icon = false;
        this.filterComponent = false;
        this.filterControls = './events-alert-receivers-grid-filter-controls.html';
        this.exportComponent = false;

        this.columns = [
            {title: '#',            mapper: null, style: { width: '20px' }},
            {title: '',             mapper: 'checkbox', identifier: this.identifier, style: { width: '35px' }},
            {title: 'Type',         mapper: 'type'},
            {title: 'Role',         mapper: 'role'},
            {title: 'First Name',   mapper: 'first_name'},
            {title: 'Last Name',    mapper: 'last_name'},
            {title: 'Email',        mapper: 'email'}
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this),
            { getAlertReceivers, setAlertReceivers }
        )(this);

        this.dataAction = this.getAlertReceivers;
        this.actionSelect = this.setAlertReceivers;
        this.filter.identifier = this.identifier;
        this.filter.iev_id = this.$uiRouterGlobals.params.id;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const eventsAlertReceiversGrid = {
    bindings: {
        title: '@',
        eventId: '<',
        onRemove: '&'
    },
    controller: EventsAlertReceiversGridController,
    ...gridTpl
};
