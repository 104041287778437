import {TAB_SHOW} from '../../../../common/tabs/tabs.constants';
import {TYPES} from '../settings-action-type.constants';
import {IDS} from '../settings-action-id.constants';
import * as AssessmentActions from './default-documents.actions';
import * as AccreditationActions from '../../accreditation.actions';
import * as SectionsActions from '../sections/sections.actions';
import watch from "redux-watch";
import get from "lodash/get";
import {TYPES as ACC_TYPES} from "../../accreditation-action-type.constants";
import {GRID_FILTER} from '../../../../common/grid/grid.constants';

class DefaultDocumentsController {
    constructor($ngRedux, $uiRouterGlobals, $state, $stateParams, $scope, $rootScope, $filter, NavigationService, AuthService, EmployeeService) {
        this.$ngRedux = $ngRedux;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.NavigationService = NavigationService;
        this.AuthService = AuthService;
        this.EmployeeService = EmployeeService;
    }

    $onInit() {
        this.identifier = IDS.accSettings;
        this.schemeCode = this.$stateParams.itemCode;
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.iconSvg;

        this.unsubscribe = this.$ngRedux.connect(state => {
            const sectionDetails = state.accreditation.accSettings.sectionDetails;
//          const gridTitle = this.$filter('translate')('ACCREDITATION.SETTINGS.DEFAULT_DOCUMENTS.TAB');
            return {
                schemeId:  state.accreditation.accSettings.schemeDetails.id,
                siteLocation:   state.accreditation.accSettings.defaultDocumentsGrid.filter.slo_uuid,
                sectionsTree:  state.accreditation.simpleSectionsTree,
                standardSchemeCode: state.accreditation.accSettings.sectionDetails.schemeCode,
                standardId:     sectionDetails?.id,
                standardParentId: sectionDetails?.standardParentId,
                gridTitle: sectionDetails?.nodeName || '',
            };
        }, {...AccreditationActions, ...SectionsActions, ...AssessmentActions})(this);

        const ctrl = this;
        const watchSelectedState = watch(this.$ngRedux.getState, ['accreditation', IDS.app_accreditation_scheme_settings_default_documents, 'selectedState']);
        this.unsubscribeWatch = this.$ngRedux.subscribe(watchSelectedState(selectedState => {
            if (selectedState === IDS.app_accreditation_scheme_settings_default_documents_list) {
                this.$ngRedux.dispatch({
                    id: IDS.accSettings,
                    type: TYPES.NEW_ASSESSMENT_ITEM_RESET,
                })
            }
        }));

        var user = this.AuthService.getUser();
        if(user.parentCount !== 1){
            ctrl.fetchStandardsTree(ctrl.schemeCode).then(()=>{
                ctrl.initStandardsInputSelects();
            })
        }
        else{
            this.EmployeeService.getEmploymentLocations(user.uuid).then(siteLocations => {
                const siteLocation = get(siteLocations,'[0].uuid');
                this.$stateParams.slo = siteLocation;
                this.$ngRedux.dispatch({
                    type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                    accDashboardLocationYear: {
                        siteLocation,
                    }
                });
                ctrl.fetchStandardsTree(ctrl.schemeCode, siteLocation).then(()=>{
                    ctrl.initStandardsInputSelects();
                })
            });
        }
    }

    initStandardsInputSelects() {
        // insert standards to first standard select box
        this.$ngRedux.dispatch({
            type: TYPES.SELECT_CASCADE_STANDARD,
            id: IDS.accSettings,
            level:0,
            standards: this.sectionsTree,
            standardId: null,
            standardParentId: null,
        });

        let selectedLevel1Id, selectedLevel2Id, selectedLevel3Id;
        if(this.standardId && this.standardParentId && this.standardSchemeCode === this.schemeCode){
            const standard2 = this.findChildStandardById(this.standardParentId, this.sectionsTree);
            selectedLevel1Id = standard2.standardParentId;
            selectedLevel2Id = this.standardParentId;
            selectedLevel3Id = this.standardId;
        }
        else{
            selectedLevel1Id = get(this.sectionsTree, '[0].id');
            selectedLevel2Id = get(this.sectionsTree,'[0].children[0].id');
            selectedLevel3Id = get(this.sectionsTree,'[0].children[0].children[0].id');
        }

        if(selectedLevel1Id)
            this.onSelectStandard({item:{value: selectedLevel1Id}, init: true});
        if(selectedLevel2Id)
            this.onSelectStandard({item:{value: selectedLevel2Id}, init: true});
        if(selectedLevel3Id)
            this.onSelectStandard({item:{value: selectedLevel3Id}, init: true});
    }

    onSelectStandard({item, init}){
        const standard = this.findChildStandardById(item.value, this.sectionsTree);
        const level3Standards = standard.level === 1 && standard.children.length
            ? this.findChildStandardById(standard.children[0].id, this.sectionsTree).children
            : undefined;
        this.$ngRedux.dispatch({
            type:       TYPES.SELECT_CASCADE_STANDARD,
            id:         IDS.accSettings,
            standardId: standard.id,
            level:      standard.level,
            standards:  standard.children,
            level3Standards,
            schemeCode: this.schemeCode
        });
        if(!init || standard.level === 3){
            const level3Standard =
                level3Standards?.length && level3Standards[0]
                || standard.children?.length && standard.children[0]
                || standard;

            this.$ngRedux.dispatch({
                type: GRID_FILTER,
                id: IDS.accSettings,
                path: IDS.defaultDocumentsGrid,
                filter: {
                    standard_id: level3Standard.id
                }
            });
            level3Standard.schemeCode = this.schemeCode;
            this.getSectionDetails(level3Standard);
        }
    }

    findChildStandardById(id, standards) {
        return standards.reduce((result, item) => {
            if(result)
                return result;
            if(item.id === id)
                return item
            else if (item.children.length)
                return this.findChildStandardById(id, item.children)
        }, null);
    }

    onRestoreAllItemsButton(){
        this.restoreLocationAssessment(this.standardId, this.siteLocation, IDS.defaultDocumentsGrid);
    }

    onSelect({item}) {
        this.$ngRedux.dispatch({
            type:   TYPES.DEFAULT_DOCUMENT_DETAILS_REFRESH,
            id:     IDS.accSettings,
            data:  {
                schemeId: this.schemeId,
                name: undefined, // clear previous data
                description: undefined, // clear previous data
                ...item,
            }
        });
        this.$ngRedux.dispatch({
            type: TAB_SHOW,
            id: IDS.app_accreditation_scheme_settings_default_documents,
            state: IDS.app_accreditation_scheme_settings_default_documents_details,
        });
    };

    onRemove(event) {
        this.invalidateRequiredDocument(event.item.id, IDS.defaultDocumentsGrid);
    };

    onSubmit(event) {
        this.$ngRedux.dispatch({
            type: TYPES.DEFAULT_DOCUMENT_DETAILS_REFRESH,
            id: IDS.accSettings,
            data: {
                type_code: 'assupl',
                name: null, // clear previous data
                description: null, // clear previous data
            }
        });
        this.$ngRedux.dispatch({
            type: TAB_SHOW,
            id: IDS.app_accreditation_scheme_settings_default_documents,
            state: IDS.app_accreditation_scheme_settings_default_documents_add,
        });
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

DefaultDocumentsController.$inject = ['$ngRedux', '$uiRouterGlobals', '$state', '$stateParams', '$scope', '$rootScope', '$filter', 'NavigationService', 'AuthService','EmployeeService']

export const accDefaultDocuments = {
    controller: DefaultDocumentsController,
    template: `

        <form name="selectSiteLocationForm" novalidate layout="row" style="margin-top: 20px">
            <div layout-gt-xs="row">
                <input-select flex label="Standard"
                              identifier="{{$ctrl.identifier}}"
                              state-path="selectCascadeStandard.level1StandardId"
                              state-data="selectCascadeStandard.level1Standards"
                              on-change="$ctrl.onSelectStandard($event)"
                              select-first-item="true">
                </input-select>
                <input-select flex label="Standard"
                              identifier="{{$ctrl.identifier}}"
                              state-path="selectCascadeStandard.level2StandardId"
                              state-data="selectCascadeStandard.level2Standards"
                              on-change="$ctrl.onSelectStandard($event)"
                              select-first-item="true">
                </input-select>
                <input-select flex label="Standard"
                              identifier="{{$ctrl.identifier}}"
                              state-path="selectCascadeStandard.level3StandardId"
                              state-data="selectCascadeStandard.level3Standards"
                              on-change="$ctrl.onSelectStandard($event)"
                              select-first-item="true">
                </input-select>
            </div>
        </form>
        <default-documents-grid
                title="{{$ctrl.gridTitle}}"
                on-select="$ctrl.onSelect($event)"
                on-remove="$ctrl.onRemove($event)"
                on-detach="$ctrl.onDetach($event)">
        </default-documents-grid>
        <div layout-xs="column" layout-gt-xs="row" layout-align="center center" class="tabs">
            <div flex></div>
            <md-button ng-show="$ctrl.siteLocation" class="btnDefault" ng-click="$ctrl.onRestoreAllItemsButton()">{{'COMMON.BUTTON.RESTORE_ALL_ITEMS' | translate}}</md-button>
            <button type="submit" class="md-button btnColor{{$ctrl.colorName}}" ng-click="$ctrl.onSubmit()" aria-label="Add Default Document">Add Default Document</button>
        </div>
`}
