/* @ngInject */
export function AdministrationAccountsHeadOfficeEquipmentController($ngRedux) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, {})(ctrl);
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    function mapStateToThis(state) {
        return {
            title: state.administration.administrationAccountsHeadOffice.name + ' » Equipment'
        };
    }
}
