/* @ngInject */
export function SafetyPlanReviewHelpController($scope, $state, $rootScope, $q, $location, SafetyPlanService, Notifier) {

    $scope.mySubject = "";
    $scope.myQuestion = "";

    $scope.currentPage = 1;
    $scope.pageSize = 10;

    $scope.selectedItem = null;

    $scope.answerSubject = null;
    $scope.answer = null;
    $scope.showHistory = "pending";
    $scope.questions = [];

    $scope.trainingName = $state.current.trainingName;

    $scope.selectItem = function (item) {
        $scope.selectedItem = item;
        $scope.answerSubject = "Re: " + item.questionSubject;
    };

    $scope.fetchData = function () {
        $scope.answerSubject = null;
        $scope.answer = null;
        $scope.selectedItem = null;

        $scope.cursorState = 'wait';

        var includeHistory = 0;
        if ($scope.showHistory === "history") {
            includeHistory = 1;
        }

        SafetyPlanService.fetchEmployeeQuestionHistory(includeHistory, $state.current.trainingType)
            .then(function (response) {
                $scope.questions = response.data.result;
            });
    };

    $scope.askQuestion = function(subject, message) {
        SafetyPlanService.asqEmployeeQuestion(subject, message, $state.current.trainingType)
            .then(function successCallback(response) {
                $scope.mySubject = "";
                $scope.myQuestion = "";
                Notifier.success("Question has been successfully sent to Safety Officer");
            }, function errorCallback(response) {
                Notifier.error("There was a problem sending question to RSO");
            });
    };

    $scope.fetchData();
}
