import jsQR from 'jsqr';
import defer from 'lodash/defer';

/**
 * Creates a new InputCodeScannerController.
 * @class
 * @memberOf module:root/common/form
 * @example {@lang xml}
 * <input-text input-id=""
 *             label=""
 *             required=""
 *             disabled=""
 *             on-success="">
 * </input-text>
 * @see {@link https://docs.angularjs.org/guide/component|AngularJS Component}
 */
class InputCodeScannerController {
    /**
     * @constructor
     * @param $ngRedux {service}
     * @param $scope
     * @param $timeout {service}
     */
    constructor($ngRedux, $scope, $timeout) {
        this.$ngRedux = $ngRedux;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.displayVideo = false;
    }

    /**
     * @method $onInit
     * @memberof module:root/common/form.InputCodeScannerController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        if (typeof this.label === 'undefined')
            this.label = 'QR CODE Scanner';
    }

    onScan() {
        this.message('Please wait...');
        this.displayVideo = true;
        this.video = document.createElement('video');
        this.canvasElement = document.getElementById('canvas');
        this.canvas = this.canvasElement.getContext('2d');

        navigator.mediaDevices.getUserMedia({video: {facingMode: 'environment'}}).then(stream => {
            this.message(null);
            this.video.srcObject = stream;
            this.video.setAttribute('playsinline', true); // required to tell iOS safari we don't want fullscreen
            this.video.play();
            requestAnimationFrame(this.tick);
        });
    }

    drawLine = (begin, end, color) => {
        this.canvas.beginPath();
        this.canvas.moveTo(begin.x, begin.y);
        this.canvas.lineTo(end.x, end.y);
        this.canvas.lineWidth = 4;
        this.canvas.strokeStyle = color;
        this.canvas.stroke();
    };

    tick = () => {
        if (this.video && this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
            this.canvasElement.hidden = false;
            this.canvasElement.height = this.video.videoHeight;
            this.canvasElement.width = this.video.videoWidth;
            this.canvas.drawImage(this.video, 0, 0, this.canvasElement.width, this.canvasElement.height);
            const imageData = this.canvas.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height, {
                inversionAttempts: "dontInvert",
            });
            if (code) {
                this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
                this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
                this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
                this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
                this.scanTimeout = this.$timeout(() => {
                    this.destroyVideo();
                    if (this.onSuccess) {
                        this.onSuccess({
                            $event: {value: code.data}
                        });
                    }
                    defer(() => this.$scope.$digest());
                }, 500);
                return;
            } else {
                // TODO
            }
        }
        requestAnimationFrame(this.tick);
    };

    message = (msg) => {
        if (this.onMessage) {
            this.onMessage({
                $event: {message: msg}
            });
        }
    };

    cancel = () => {
        if (this.onCancel)
            this.onCancel();
    };

    finish = () => {
        this.destroyVideo();
        if (this.onFinish)
            this.onFinish();
    };

    destroyVideo = () => {
        if (this.video) {
            this.video.pause();
            this.video.removeAttribute('src'); // empty source
            this.video.load();
            this.video = null;
            this.canvasElement.hidden = true;
            this.displayVideo = false;
        }
    };

    /**
     * @method $onDestroy
     * @memberof module:root/common/form.InputCodeScannerController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.destroyVideo();
        if (this.scanTimeout)
            this.$timeout.cancel(this.scanTimeout);
    }
}

InputCodeScannerController.$inject = ['$ngRedux', '$scope', '$timeout'];

export const inputCodeScanner = {
    bindings: {
        /** @member {string} module:root/common/form.InputCodeScannerController#inputId */
        inputId: '@',
        /** @member {string} module:root/common/form.InputCodeScannerController#label */
        label: '@',
        /** @member {string} module:root/common/form.InputCodeScannerController#color */
        color: '@',
        /** @member {*|boolean} module:root/common/form.InputCodeScannerController#required */
        required: '<',
        /** @member {*|boolean} module:root/common/form.InputCodeScannerController#disabled */
        disabled: '<',
        /** @member {*|function} module:root/common/form.InputCodeScannerController#onSuccess */
        onSuccess: '&',
        /** @member {string} module:root/common/form.InputCodeScannerController#successMsg */
        successMsg: '@',
        /** @member {*|function} module:root/common/form.InputCodeScannerController#onError */
        onError: '&',
        /** @member {string} module:root/common/form.InputCodeScannerController#errorMsg */
        errorMsg: '@',
        /** @member {*|function} module:root/common/form.InputCodeScannerController#onMessage */
        onMessage: '&',
        /** @member {*|function} module:root/common/form.InputCodeScannerController#onCancel */
        onCancel: '&',
        /** @member {*|boolean} module:root/common/form.InputCodeScannerController#btnFinish */
        btnFinish: '<',
        /** @member {*|function} module:root/common/form.InputCodeScannerController#onFinish */
        onFinish: '&',
    },
    require: {
        /**
         * @member {*} module:root/common/form.InputCodeScannerController#form
         * @see {@link https://docs.angularjs.org/api/ng/type/form.FormController|FormController}
         */
        form: '^form'
    },
    controller: InputCodeScannerController,
    template: `
        <div ng-show="$ctrl.displayVideo" layout="row" layout-align="center center">
            <canvas id="canvas" class="video" hidden></canvas>
        </div>
        <div layout="row" layout-align="center center">
            <md-button class="btnShort" ng-click="$ctrl.cancel()">Cancel</md-button>
            <md-button class="btnShort{{$ctrl.color}}" ng-click="$ctrl.onScan()">
                <asset cls="rightMargin" src="qrcode-scan.svg"></asset> {{$ctrl.label}}
            </md-button>
            <md-button ng-if="$ctrl.btnFinish" class="btnShort" ng-click="$ctrl.finish()">Finish</md-button>
        </div>
    `
};
