/* @ngInject */
export function MammoQCService($http, API_BASE, MammoQCEquipment, MammoQCTesterCandidate, MammoQCMeasurement, Helpers, DATE_FORMAT) {

    this.getMammoQCEquipment = function(locationUuid) {
        return $http({
            url: API_BASE + "/mammo-qc/equipment",
            method: "GET",
            params: {
                locationUuid: _.isUndefined(locationUuid) ? null : locationUuid
            }
        }).then(function(response) {

            return response.data.map(function(item) {
                var equipment     = new MammoQCEquipment();
                equipment.equId                = item.equ_id;
                equipment.serial               = item.serial;
                equipment.typeName             = item.type_name;
                equipment.manufacturer         = item.manufacturer;
                equipment.modelName            = item.model_name;
                return equipment;
            });
        });
    };

    this.getMammoQCTesterCandidates = function(locationUuid) {
        return $http({
            url: API_BASE + "/mammo-qc/tester-candidates",
            method: "GET",
            params: {
                locationUuid: _.isUndefined(locationUuid) ? null : locationUuid
            }
        }).then(function(response) {
            return response.data.map(function(item) {
                var tester     = new MammoQCTesterCandidate();
                tester.uuid                  = item.uuid;
                tester.firstName             = item.first_name;
                tester.lastName              = item.last_name;
                tester.username              = item.username;
                tester.occupation            = item.occupation;
                return tester;
            });
        });
    };

    this.getMammoQCCNBaselineValue = function(equipmentId) {
        return $http({
            url: API_BASE + "/mammo-qc/mammo-qc-baseline-value",
            method: "GET",
            params: {
                equipmentId: equipmentId
            }
        }).then(function(response) {
            var params = JSON.parse(response.data['@params']);
            var baseValue  = params.baseline_value;
            return baseValue;
        });
    };

    this.getMammoQCTests = function(filter) {
        return $http({
            url: API_BASE + "/mammo-qc/mammo-qc-test",
            method: "GET",
            params: {
                equipmentId:        filter.equipment,
                measurementType:    filter.measurementType,
                from:               Helpers.formatDate(filter.dateFrom),
                to:                 Helpers.formatDate(filter.dateTo),
                exportFormat:       filter.exportFormat,
                exportColumns:      filter.exportColumns
            }
        }).then(function(response) {
            if (filter.exportFormat) {
                return response.data;
            }
            else {
                var test = response.data.map(function (item) {
                    var equipmentTest = new MammoQCMeasurement();
                    equipmentTest.testDate = Helpers.formatDate(item.tested_date, DATE_FORMAT.INPUT);
                    equipmentTest.isBaseline = item.is_baseline;

                    var test_results = JSON.parse(item.test_results)

                    equipmentTest.measurementType = test_results.measerment_type;
                    equipmentTest.meanInsideDisk = test_results.mean_inside_disk;
                    equipmentTest.meanBesideDisk = test_results.mean_beside_disk;
                    equipmentTest.standardDeviationBesideDisk = test_results.std_dev_beside_disk;
                    equipmentTest.kvp = test_results.kvp;
                    equipmentTest.mas = test_results.mas;
                    equipmentTest.signalToNoiseRatioResultCalculation = test_results.snr_result_calculation;
                    equipmentTest.contrastToNoiseRatioResultCalculation = test_results.cnr_result_calculation;
                    equipmentTest.contrastToNoiseRatioResultDifference = test_results.cnr_difference;
                    equipmentTest.remark = test_results.remarks;
                    equipmentTest.baselineValue = test_results.baseline_value;

                    equipmentTest.testerUuid = item.tester_uuid;
                    equipmentTest.firstName = item.tester_first_name;
                    equipmentTest.lastName = item.tester_last_name;
                    return equipmentTest;
                });
                return {
                    items: test
                }
            }
        });
    };

    this.getMammoQCSNCNTests = function(filter, measurementType) {
        return $http({
            url: API_BASE + "/mammo-qc/mammo-qc-test",
            method: "GET",
            params: {
                equipmentId:        filter.mammoQCEquipment.equId,
                measurementType:    measurementType,
                from:               Helpers.formatDate(filter.dateFrom),
                to:                 Helpers.formatDate(filter.dateTo)
            }
        }).then(function(response) {

            var test = response.data.map(function(item) {
                var equipmentTest                                           = new MammoQCMeasurement();
                equipmentTest.testDate                                      = Helpers.formatDate(item.tested_date, DATE_FORMAT.INPUT);
                equipmentTest.isBaseline                                    = item.is_baseline;

                var test_results = JSON.parse(item.test_results)

                equipmentTest.measurementType                               = test_results.measerment_type;
                equipmentTest.meanInsideDisk                                = test_results.mean_inside_disk;
                equipmentTest.meanBesideDisk                                = test_results.mean_beside_disk;
                equipmentTest.standardDeviationBesideDisk                   = test_results.std_dev_beside_disk;
                equipmentTest.kvp                                           = test_results.kvp;
                equipmentTest.mas                                           = test_results.mas;
                equipmentTest.signalToNoiseRatioResultCalculation           = test_results.snr_result_calculation;
                equipmentTest.contrastToNoiseRatioResultCalculation         = test_results.cnr_result_calculation;
                equipmentTest.contrastToNoiseRatioResultDifference          = test_results.cnr_difference;
                equipmentTest.remark                                        = test_results.remarks;
                equipmentTest.baselineValue                                 = test_results.baseline_value;

                equipmentTest.testerUuid                                    = item.tester_uuid;
                equipmentTest.firstName                                     = item.tester_first_name;
                equipmentTest.lastName                                      = item.tester_last_name;
                return equipmentTest;
            });
            return {
                items: test
            }
        });
    };

    this.addMammoQCMeasurement = function(QCTest) {
        return $http({
            url: API_BASE + "/mammo-qc/mammo-qc-measurement-add",
            method: "POST",
            data: {
                measurementType:                          QCTest.measurementType,
                equipmentId:                              QCTest.mammoQCEquipment.equId,

                meanInsideDisk:                           QCTest.meanInsideDisk,
                meanBesideDisk:                           QCTest.meanBesideDisk,
                standardDeviationBesideDisk:              QCTest.standardDeviationBesideDisk,
                kvp:                                      QCTest.kvp,
                mas:                                      QCTest.mas,
                signalToNoiseRatioResultCalculation:      QCTest.signalToNoiseRatioResultCalculation,
                contrastToNoiseRatioResultCalculation:    QCTest.contrastToNoiseRatioResultCalculation,
                contrastToNoiseRatioResultDifference:     QCTest.contrastToNoiseRatioResultDifference,
                remark:                                   QCTest.remark,
                baselineValue:                            QCTest.baselineValue
            }
        }).then(function(response) {
            var params = JSON.parse(response.data.params);
            return params.mqm_id;
        });
    };
}
