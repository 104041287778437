/* @ngInject */
export function AccIAQualityImprovementAddEdit($scope, $mdDialog, color, title, locationUuid, locationName,
                                        accShemaCode, quaImprItem, Notifier, AccreditationDocumentsService,
                                        AccQualityImprovementItem,EmployeeService, $filter) {

    $scope.headerColor = color;
    $scope.headerTitle = title;
    $scope.cursorState = 'default';

    $scope.accreditationSchemaCode = accShemaCode;
    $scope.locationName = locationName;
    $scope.locationUuid = locationUuid;
    $scope.selectedEmployee = null;
    $scope.employeeList = null;

    $scope.maxTestDate = new Date();

    if (quaImprItem == null) {
        $scope.qualityImprovementItem = new AccQualityImprovementItem();
    } else {
        $scope.qualityImprovementItem = quaImprItem;
        if ($scope.qualityImprovementItem.resPerson)
            $scope.searchText = $scope.qualityImprovementItem.resPerson;
    }

    /**
  * Create filter function for a query string */
    function createFilterFor(query) {
        var lowercaseQuery = query.toLowerCase();
        return function filterFn(employee) {
            return ((employee.firstName + ' ' + employee.lastName).toLowerCase().indexOf(lowercaseQuery) === 0);
        };
    }

    $scope.querySearch = function (query) {
        var results = $scope.employeeList.filter(createFilterFor(query));
        return results;
    };

    $scope.searchTextChange = function (text) { };
    $scope.selectedItemChange = function (selectedEmployee) {
        $scope.qualityImprovementItem.resPerson = selectedEmployee && (selectedEmployee.firstName+" "+selectedEmployee.lastName);
        $scope.qualityImprovementItem.resPersonUuid = selectedEmployee && selectedEmployee.uuid;
    };

      //Get Active employees for currently selected site location
    $scope.cursorState = 'wait';
    if ($scope.locationUuid) {
        EmployeeService.getEmployees({
            status: 'act', /* Active employees, hardcoded id*/
            location: $scope.locationUuid
        })
        .then(function (employeeList) {
            $scope.employeeList = employeeList.items;
            $scope.cursorState = 'default';
        }, function (error) {
            $scope.cursorState = 'default';
        });
    } else {
        $scope.employeeList = null;
    }

    //Dialog cancel button
    $scope.cancel = function () {
        $mdDialog.cancel();
    };

    $scope.submitQualImprItem = function () {
        $scope.$broadcast('show-errors-check-validity', 'qualImpItemAddForm');
        if ($scope.qualityImprovementItem.improvementActionItem && $scope.qualityImprovementItem.source && $scope.qualityImprovementItem.creationDate) {
            $scope.qualityImprovementItem.locationUuid = $scope.locationUuid;
            AccreditationDocumentsService.createAccQualityImprovementItem($scope.accreditationSchemaCode, $scope.locationUuid, $scope.qualityImprovementItem).then(function (response) {
                Notifier.success($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_SAVE_SUCCESS'));
            }, function (error) {
                Notifier.error($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_ITEM_SAVE_ERROR'));
            }).then(function () {
                $scope.$emit('acc-quality-imrpovement-item-added', $scope.uploadResult);
                $mdDialog.hide();
            });
        } else {
            Notifier.error($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_INPUT_ERROR'));
        }
    };
}
