import InputController from '../input.controller';
import { INPUT_SIGNATURE } from '../input.constants';
import SignaturePad from 'signature_pad';
import set from 'lodash/set';

/**
 * Creates a new InputSignatureController.
 * @class
 * @extends module:root/common/form.InputController
 * @memberOf module:root/common/form
 * @see {@link https://docs.angularjs.org/guide/component|AngularJS Component}
 */
class InputSignatureController extends InputController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $mdDialog {service} {@link https://material.angularjs.org/latest/api/service/$mdDialog|AngularJS Material $mdDilaog}
     * @param Notifier {service} {@link Notifier}
     */
    constructor($ngRedux, $mdDialog, $state, NavigationService, Notifier) {
        super($ngRedux);
        this.$mdDialog = $mdDialog;
        this.$state = $state;
        this.NavigationService = NavigationService;
        this.Notifier = Notifier;
    }

    /**
     * @method $onInit
     * @memberof module:root/common/form.InputSignatureController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.init();
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;

        if (typeof this.label === 'undefined')
            this.label = 'Signature';
    }

    /**
     * @method onSign
     * @memberof module:root/common/form.InputSignatureController
     */
    onSign() {
        const options = {
            controller: (scope) => {
                scope.clear = () => {
                    this.$ngRedux.dispatch({
                        type: INPUT_SIGNATURE,
                        id: this.identifier,
                        path: this.statePath,
                        index: this.stateIndex,
                        prop: this.stateProp,
                        input: null
                    });
                    scope.signaturePad.clear();
                };

                scope.save = () => {
                    if (scope.signaturePad.isEmpty()) {
                        this.Notifier.error('Signature can not be empty!');
                    } else {
                        this.$ngRedux.dispatch({
                            type: INPUT_SIGNATURE,
                            id: this.identifier,
                            path: this.statePath,
                            index: this.stateIndex,
                            prop: this.stateProp,
                            input: scope.signaturePad.toDataURL()
                        });
                        this.$mdDialog.hide();
                        this.Notifier.success('Signed Successfully!');
                    }
                };
            },
            template: require('./input-signature-dialog.html'),
            parent: angular.element(document.body),
            locals: {},
            clickOutsideToClose: true,
            onComplete: (scope, element) => {
                const canvas = element.find('canvas')[0];
                scope.signaturePad = new SignaturePad(canvas);
                if (this.input) {
                    // TODO check ratio
                    const ratio =  Math.max(window.devicePixelRatio || 1, 1);
                    canvas.width = 300 * ratio;
                    canvas.height = 150 * ratio;
                    canvas.getContext("2d").scale(ratio, ratio);
                    scope.signaturePad.clear();
                    scope.signaturePad.fromDataURL(this.input);
                }
            }
        };
        options.onComplete.$inject = ['$scope'];
        options.controller.$inject = ['$scope'];

        this.$mdDialog.show(options);
    }

    getClass() {
        if (this.form.signatureInput.$invalid && this.form.$submitted)
            return set({}, 'btnSignatureInvalid', true);
        else
            return set({}, 'btnSignatureColor' + this.colorName, true);
    }
}

InputSignatureController.$inject = ['$ngRedux', '$mdDialog', '$state', 'NavigationService', 'Notifier'];

export const inputSignature = {
    bindings: {
        identifier: '@',
        statePath: '@',
        stateIndex: '<',
        stateProp: '@',
        label: '@',
        required: '<',
        disabled: '<'
    },
    require : {
        /**
         * @member {*} module:root/common/form.InputSignatureController#form
         * @see {@link https://docs.angularjs.org/api/ng/type/form.FormController|FormController}
         */
        form : '^form'
    },
    controller: InputSignatureController,
    template: `
        <small>{{$ctrl.label}}</small>
        <input-text ng-show="!$ctrl.input" flex
            name="signatureInput"
            identifier="{{$ctrl.identifier}}"
            state-path="{{$ctrl.statePath}}"
            state-index="$ctrl.stateIndex"
            state-prop="{{$ctrl.stateProp}}"
            label=""
            required="$ctrl.required"
            disabled="true">
        </input-text>
        <div ng-if="$ctrl.input" layout="row" layout-align-xs="center center" layout-align-sm="center center">
            <img ng-src="{{$ctrl.input}}"/>
        </div>
        <div ng-if="!$ctrl.disabled" layout="row" layout-align-xs="center center" layout-align-sm="center center">
            <md-button ng-class="$ctrl.getClass()" ng-click="$ctrl.onSign()" aria-label="Signature">
                <asset src="signature.svg"></asset> Sign
            </md-button>
        </div>
    `
};
