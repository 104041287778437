import { AP_INVITATION_RESET } from '../applications-permits.actions.types';

export const apNuclearEnergyRequestState = {
    name: 'app.applicationsPermits.nuclearEnergy.request',
    redirectTo: 'app.applicationsPermits.nuclearEnergy.request.invitation',
    url: '/request',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({ type: AP_INVITATION_RESET });
    }
};

export const apNuclearEnergyRequestInvitationState = {
    name: 'app.applicationsPermits.nuclearEnergy.request.invitation',
    url: '',
    component: 'apLaserRequestInvitation'
};

export const apNuclearEnergyRequestEquipmentState = {
    name: 'app.applicationsPermits.nuclearEnergy.request.equipment',
    url: '',
    component: 'apLaserRequestEquipment'
};

export const apNuclearEnergyManageState = {
    name: 'app.applicationsPermits.nuclearEnergy.manage',
    redirectTo: 'app.applicationsPermits.nuclearEnergy.manage.listing',
    url: '/manage',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        // $ngRedux.dispatch({ type: AP_INVITATION_RESET });
    }
};

export const apNuclearEnergyManageListingState = {
    name: 'app.applicationsPermits.nuclearEnergy.manage.listing',
    url: '',
    component: 'apLaserRequestGrid',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.applicationsPermits.nuclearEnergy.manage',
            state: [
                'app.applicationsPermits.nuclearEnergy.manage.details',
                'app.applicationsPermits.nuclearEnergy.manage.equipment',
                'app.applicationsPermits.nuclearEnergy.manage.trainings',
                'app.applicationsPermits.nuclearEnergy.manage.safetyPlan'
            ]
        });
    }
};

export const apNuclearEnergyManageRequestDetailsState = {
    name: 'app.applicationsPermits.nuclearEnergy.manage.details',
    url: '/details?id',
    component: 'apLaserRequestDetails'
};

export const apNuclearEnergyManageRequestEquipmentState = {
    name: 'app.applicationsPermits.nuclearEnergy.manage.equipment',
    url: '/equipment?id',
    component: 'apLaserRequestEquipment'
};

export const apNuclearEnergyManageRequestTrainingsState = {
    name: 'app.applicationsPermits.nuclearEnergy.manage.trainings',
    url: '/trainings?id',
    component: 'apLaserRequestTrainings'
};

export const apNuclearEnergyManageRequestSafetyPlanState = {
    name: 'app.applicationsPermits.nuclearEnergy.manage.safetyPlan',
    url: '/safety-plan?id',
    component: 'apLaserRequestSafetyPlan'
};

export const apNuclearEnergyAlertsState = {
    name: 'app.applicationsPermits.nuclearEnergy.alerts',
    redirectTo: 'app.applicationsPermits.nuclearEnergy.alerts.listing',
    url: '/manage',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        // $ngRedux.dispatch({ type: AP_INVITATION_RESET });
    }
};

export const apNuclearEnergyAlertsListingState = {
    name: 'app.applicationsPermits.nuclearEnergy.alerts.listing',
    url: '',
    component: 'apLaserRequest'
};
