export function ServiceContractVisitScheduleFactory() {
    return function ServiceContractVisitSchedule(account, month) {

        this.accountUuid = account.uuid;
        this.accountName = account.name;
        this.month = month;
        this.visits = [];

        this.getVisit = function(date, allDayEvent, startTime, endTime) {
            var dateVisits = this.visits.filter(function(visit) {
                return visit.date.getTime() === date.getTime();
            });

            if (dateVisits.length) {
                var allDayVisit = _.find(dateVisits, ['allDayEvent', true]);
                if (!_.isUndefined(allDayVisit)) return allDayVisit;

                var timeVisit = _.find(dateVisits, {'startTime': startTime, 'endTime': endTime});
                if (!_.isUndefined(timeVisit)) return timeVisit;
            }
            return null;
        };

        this.isEquipmentValidForSchedule = function(equipment) {
            var result = true;
            _.each(this.visits, function(visit) {
                if (_.intersectionBy(visit.equipment, equipment, 'equipment_id').length > 0) {
                    result = false;
                    return false;
                }
            });
            return result;
        };

        this.clearVisits = function() {
            var keysForRemoval = [];
            _.each(this.visits, function(visit, key) {
                if (!visit.equipment.length) keysForRemoval.push(key);
            });

            keysForRemoval.forEach(function(item) {
                this.visits.splice(item, 1);
            }, this);
        };
    };
}
