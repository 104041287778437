/**
 * @const {object}
 * @memberOf module:Administration
 */
export const administrationAccountsHeadOfficeLicences = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $mdDialog, $rootScope, NavigationService) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect((state) => ({
                title: state.administration.administrationAccountsHeadOffice.name + ' » Licences'
            }), {})(this);
        }

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: `
        <administration-accounts-licences-grid
            identifier="administrationAccountsHeadOfficeLicencesGrid"
            title="$ctrl.title">
        </administration-accounts-licences-grid>
    `
};
