import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';

class PersonalDosimetryReportsDetailsGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, PersonalDosimetryActions, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.PersonalDosimetryActions = PersonalDosimetryActions;
        this.$filter = $filter;
    }

    $onInit() {
        this.module = 'personalDosimetry';
        this.filterControls = './personal-dosimetry-reports-details-grid-filter-controls.html';
        this.filterTemplate = './personal-dosimetry-reports-details-grid-filter.html';
        this.exportComponent = false;
        this.autoload = false;

        this.columns = [
            {title: '#',                                                                                        mapper: null},
            {title: 'Notify',                                                                                   mapper: 'checkbox', identifier: this.identifier},
            {title: '',                                                                                         mapper: 'alert'},
            {title: this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.GRID.ACCOUNT_NO'),        mapper: 'accountNum'},
            {title: this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.GRID.ACCOUNT_NAME'),      mapper: 'accountName'},
            {title: this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.GRID.PARTICIPANT_NO'),    mapper: 'participantNum'},
            {title: this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.GRID.PARTICIPANT_NAME'),  mapper: 'participantName'},
            {title: this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.GRID.PERIOD_BEGIN'),      mapper: 'periodFrom'},
            {title: this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.GRID.PERIOD_END'),        mapper: 'periodTo'},
            {title: this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.GRID.SERIAL'),            mapper: 'serialNum'},
            {title: this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.GRID.STATUS'),            mapper: 'progress-bar'},
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.PersonalDosimetryActions)(this);
        this.dataAction = this.getReportDetails;
        this.actionSelect = this.getReportDetailsIdList;
        this.filter.identifier = this.identifier;

        this.filterActions.onLimits = ({ id, value }) => {
            this.$ngRedux.dispatch({ type: 'PARTICIPANT_LIMITS', id, value });
            this.$ngRedux.dispatch({ type: 'GRID_RELOAD', id });
        };

        this.init();
    }

    $onDestroy() {
        this.destroy();
        this.$ngRedux.dispatch({
            type: 'GRID_RESET',
            id: 'personalDosimetryReportsDetailsGrid',
        });
    }
}

PersonalDosimetryReportsDetailsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'PersonalDosimetryActions', '$filter'];

export const personalDosimetryReportsDetailsGrid = {
    bindings: {
        identifier: '@',
        title: '@',
        onSelect: '&'
    },
    controller: PersonalDosimetryReportsDetailsGridController,
    ...gridTpl,
};
