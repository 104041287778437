import {IDS} from "./accreditation-action-id.constants";
import {IDS as SCHEME_IDS} from "./scheme/scheme-action-id.constants";
import {TAB_DISABLE, TAB_HIDE} from "../../common/tabs/tabs.constants";

const QUERY_PARAMS = '?itemCode&standard&year&slo&token';

export const mainState = {
    name: 'app.accreditation',
    abstract: true,
    url: '/accreditation'
};

export const dashboardState = {
    name: 'app.accreditation.dashboard',
    url: '',
    component: 'dashboard'
};

export const abstractState = {
    name: 'app.accreditation.scheme',
    abstract: true,
    url: '/scheme'
};

export const schemeDashboardState = {
    name: 'app.accreditation.scheme.dashboard',
    url: '/:itemCode',
    component: 'dashboard',
};

export const addSchemeState = {
    name: 'app.accreditation.add-scheme',
    url: '/add-scheme',
    component: IDS.addScheme,
};

export const tasksState = {
    name: 'app.accreditation.tasks',
    url: '/tasks',
    component: 'accTasks',
};

// TODO: Remove public states

export const publicState = {
    name: 'public.accreditation',
    abstract: true,
    redirectTo: 'public.accreditation.dashboard',
    url: '/public/accreditation'
};

export const tasksPublicState = {
    name: 'public.accreditation.tasks',
    url: '/tasks',
    component: 'accTasks',
};

export const publicDashboardState = {
    name: 'public.accreditation.dashboard',
    component: 'dashboard',
    url: '/public/accreditation/dashboard'
};

export const publicTaskState = {
    name: SCHEME_IDS.public_accreditation_task,
    url: '/task?token',
    component: 'publicTask',
    // resolve: {
    //     tokenStatus: ['$transition$', 'RecoveryService', ($transition$, RecoveryService) => RecoveryService.validatePasswordToken($transition$.params().token)]
    // },
    // params: {
    //     headline: 'COMMON.LOGIN.PASSWORD_RECOVERY'
    // },
};

// export const tabsPublicState = {
//     name: SCHEME_IDS.public_accreditation_scheme,
//     url: '/scheme',
//     component: 'tabs',
// }

// export const tabStandardsPublicState = {
//     name: SCHEME_IDS.public_accreditation_scheme_standards,
//     url: '/standards',
//     component: 'schemeStandards',
//     onEnter: $ngRedux => {
//         $ngRedux.dispatch({
//             type: TAB_HIDE,
//             id: SCHEME_IDS.public_accreditation_scheme,
//             state: [
//                 SCHEME_IDS.public_accreditation_scheme_documentEvaluation,
// //              SCHEME_IDS.public_accreditation_scheme_documentAdd,
//             ]
//         })
//     }
// };
// tabStandardsPublicState.onEnter.$inject = ['$ngRedux'];

// export const tabProgressPublicState = {
//     name: SCHEME_IDS.public_accreditation_scheme_progress,
//     url: `/progress${QUERY_PARAMS}`,
//     component: 'schemeProgress',
//     params: {
//         task: {dynamic: true},
//         token: {dynamic: true},
//         title: {dynamic: true},
//     },
//     onEnter: $ngRedux => {
//         $ngRedux.dispatch({
//             type: TAB_DISABLE,
//             id: SCHEME_IDS.public_accreditation_scheme,
//             state: [
//                 SCHEME_IDS.public_accreditation_scheme_evidence,
//             ]
//         });
//         // $ngRedux.dispatch({
//         //     type: TAB_HIDE,
//         //     id: SCHEME_IDS.public_accreditation_scheme_manage,
//         //     state: [
//         //         SCHEME_IDS.public_accreditation_scheme_manage_auditAdd,
//         //         SCHEME_IDS.public_accreditation_scheme_manage_auditDetails,
//         //     ]
//         // });
//     }
// };
// tabProgressPublicState.onEnter.$inject = ['$ngRedux'];

// export const tabEvidencePublicState = {
//     name: SCHEME_IDS.public_accreditation_scheme_evidence,
//     url: `/evidence${QUERY_PARAMS}`,
//     component: 'schemeEvidence',
//     onEnter: $ngRedux => {
//         $ngRedux.dispatch({
//             type: TAB_DISABLE,
//             id: SCHEME_IDS.public_accreditation_scheme,
//             state: [ SCHEME_IDS.public_accreditation_scheme_progress ]
//         });
//         $ngRedux.dispatch({
//             type: TAB_HIDE,
//             id: SCHEME_IDS.public_accreditation_scheme,
//             state: [
//                 SCHEME_IDS.public_accreditation_scheme_documentEvaluation,
// //              SCHEME_IDS.public_accreditation_scheme_documentAdd,
//             ]
//         });
//     }
// };
// tabEvidencePublicState.onEnter.$inject = ['$ngRedux'];

// export const tabDocumentEvaluationPublicState = {
//     name: SCHEME_IDS.public_accreditation_scheme_documentEvaluation,
//     url: `/document-evaluation${QUERY_PARAMS}`,
//     component: 'schemeDocumentEvaluation',
// };

// export const tabDocumentAddPublicState = {
//     name: SCHEME_IDS.public_accreditation_scheme_documentAdd,
//     url: `/document-add${QUERY_PARAMS}`,
//     component: 'schemeDocumentAdd',
// };