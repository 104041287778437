/* @ngInject */
export const EquipmentFactory = (Account, EquipmentType, EquipmentCategory, Manufacturer, EquipmentModel, EQUIPMENT_TYPE) => {
    return function Equipment() {

        this.equipment_id = null;
        this.user_friendly_id = null;
        this.transaction_id = null;
        this.transaction_type = null;
        this.serial = null;
        this.status = null;
        this.account = new Account();
        this.type = new EquipmentType();
        this.category = new EquipmentCategory();
        this.manufacturer = new Manufacturer();
        this.model = new EquipmentModel();
        this.training = {};
        this.services = [];
        this.disposalDetails = null;
        this.wayOfDisopsal = null;
        this.assetID = null;

        // HOTFIX
        this.getManageState = function() {
            switch (this.type.id) {
                case EQUIPMENT_TYPE.XRay:
                    return 'app.xray.equipment.manage';
                case EQUIPMENT_TYPE.laser:
                    return 'app.lasers.equipment.manage';
                case EQUIPMENT_TYPE.ultrasound:
                    return 'app.ultrasound.equipment.manage';
                case EQUIPMENT_TYPE.mammo:
                    return 'app.mammo.equipment.manage';
            }
        };

        this.getStatus = function() {
            switch(this.status) {
                case 'act':
                    return 'Active';
                case 'sel':
                    return 'Sold';
                case 'dis':
                    return 'Disposed';
                case 'sto':
                    return 'Stored';
            }
        };
    };
};
