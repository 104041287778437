import { saveAs } from 'file-saver';

/* @ngInject */
export function DocumentService($http, $q, API_BASE, Helpers, DATE_TYPE, EquipmentDocument, DATE_FORMAT) {

    var _this = this;
    this.documentTypes = [];

    this.downloadFile = function(url, docType) {
        docType = (_.isUndefined(docType)) ? "" : "?type=" + docType;
        return $http.get(API_BASE + "/file" + url + docType, {responseType: 'blob'}).then(function(response) {
            saveAs(new Blob([response.data], {type: response.data.type}), url.split('/').pop());
            return response;
        }, function(error) {
            return error;
        });
    };

    this.fetchEquipmentDocumentTypes = (equipment_id, equipment_type_id, due_date_types) => {
        return $http({
            url: API_BASE + "/equipment-document-types",
            method: "GET",
            params: {
                // hof_uuid,
                // all_app_variants,
                equipment_id,
                equipment_type_id,
                due_date_types
            }
        }).then(({data}) => {
            return data.map(item => ({
                    id: parseInt(item.doc_type_id),
                    name: item.doc_type_name,
                    code: item.doc_type_code,
                    dueDateRequired: item.due_date_required,
                    issueDateRequired: item.issue_date_required
            }))
        }, function(error) {
            return error;
        });
    };

    this.fetchEmployeeDocumentTypes = (loc_uuid) => {
        return $http({
            url: API_BASE + "/employee-document-types",
            method: "GET",
            params: {
                loc_uuid
            }
        }).then(({data}) => {
            return data.map(item => ({
                ...item,
                id: item.lty_id
            }))
        }, function(error) {
            return error;
        });
    };

    this.fetchEquipmentDocumentStatuses = function() {
        var documentStatuses = [];
        return $http({
            url: API_BASE + "/document/statuses",
            method: "GET"
        }).then(function(data) {
            data.data.forEach(function(item) {
                var status = {
                    id: parseInt(item.sta_id),
                    name: item.name,
                    code: item.code
                };
                documentStatuses.push(status);
            });
            return documentStatuses;
        }, function(error) {
            return error;
        });
    };

    this.fetchEquipmentDocuments = function(id) {
        return $http({
            url: API_BASE + "/equipment/" + id + "/documents",
            method: "GET",
            params: {
                offset: 0,
                limit: 200
            }
        }).then(function(response) {
            return response.data.map(function(item) {
                var doc     = new EquipmentDocument();
                doc.id      = item.doc_id;
                doc.type    = item.doc_type;
                doc.url     = item.doc_uri;
                doc.dueDate = Helpers.formatDate(item.due_date);
                return doc;
            });
        }, function(error) {
            return error;
        });
    };

    this.fetchEquipmentDocumentsList = function(filter) {
        return $http({
            url: API_BASE + "/equipment/documents/list",
            method: "GET",
            params: {
                equipmentId:        filter.equipmentId,
                documentTypeId:     filter.documentTypeId,
                documentStatusId:   filter.documentStatusId,
                offset:             filter.offset,
                limit:              filter.limit
            }
        }).then(function(response) {
            var equipmentDocuments = response.data.list.map(function(item) {
                var equipmentDocument                   = new EquipmentDocument();
                equipmentDocument.id                    = item.doc_id;
                equipmentDocument.documentType          = item.doc_type;
                equipmentDocument.uri                   = item.doc_uri;
                equipmentDocument.equipmentId           = item.equ_id;
                equipmentDocument.registrationDueDate   = Helpers.formatDate(item.registration_due_date, DATE_FORMAT.INPUT);
                equipmentDocument.certificationDueDate  = Helpers.formatDate(item.certification_due_date, DATE_FORMAT.INPUT);
                equipmentDocument.calibrationDueDate    = Helpers.formatDate(item.calibration_due_date, DATE_FORMAT.INPUT);
                equipmentDocument.equipmentSerial       = item.equipment_serial;
                equipmentDocument.modelName             = item.model_name;
                equipmentDocument.manufacturerName      = item.manufacturer_name;
                equipmentDocument.dueDate               = Helpers.formatDate(item.due_date, DATE_FORMAT.INPUT);
                equipmentDocument.comment               = item.comment;
                return equipmentDocument;
            });
            return {
                total: response.data.rows,
                items: equipmentDocuments
            };
        });
    };

    this.getEquipmentDocuments = function(id) {
        return $http({
            url: API_BASE + "/equipment/" + id + "/documents",
            method: "GET",
            params: {
                offset: 0,
                limit: 200
            }
        }).then(function(response) {
            return response.data.map(function(item) {
                var doc     = new EquipmentDocument();
                doc.id      = item.doc_id;
                doc.type    = item.doc_type;
                doc.uri     = item.doc_uri;
                doc.dueDate = Helpers.formatDate(item.due_date, DATE_FORMAT.INPUT);
                return doc;
            });
        }, function(error) {
            return error;
        });
    };

    this.addDocument = function(document) {
        return $http({
            url: API_BASE+ "/equipment/" + document.id + "/document",
            method: "POST",
            data: {
                documentType: document.type,
                issued: document.issued,
                nextDueDate: Helpers.formatDbDate(document.expires),
                documentUri: document.uri,
                serviceDate: Helpers.formatDbDate(document.serviceDate),
                inspected: Helpers.formatDbDate(document.inspected),
                inspector: document.inspector,
                result: document.result,
                comment: document.comment,
                number: document.number
            }
        }).then(function(response) {
            return response;
        }, function(error) {
            return error;
        });
    };

    this.editDocument = function(document) {
        return $http({
            url: API_BASE+ "/equipment/document/" + document.id,
            method: "PUT",
            data: {
                nextDueDate: Helpers.formatDbDate(document.dueDate),
            }
        }).then(function(response) {
            return {
                data: response.data,
                status: response.status
            };
        }, function(error) {
            return error;
        });
    };

    this.fetchDocumentToken = function (documentCode, filter, documentTypes = null) {
        return $http({
            url: API_BASE + "/grid-document-token",
            method: "GET",
            params: {
                documentCode,
                filter,
                documentTypes: JSON.stringify(documentTypes),
            },
        }).then(function successCallback(response) {
            return response;
        },
        function errorCallback(errorResponse) {
            return $q.reject(errorResponse);
        });
    };

    this.downloadZip = function (token) {
        return $http({
            url: window.location.origin+"/cgi-bin/zip?id="+token,
            method: "GET",
            responseType: 'arraybuffer'
        }).then(function successCallback(response) {
            return response;
        },
        function errorCallback(errorResponse) {
            return $q.reject(errorResponse);
        });
    };
}
