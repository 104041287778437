/* @ngInject */
export function SettingsController($controller, $ngRedux, $scope, $rootScope, $q, $timeout, AuthService, NavigationService, SettingsService, SettingsManager, User, Employee, EmployeeService, EmployeeManager, EmployeeQualificationService, EmployeeModalityTrainingService, LicenceService, LicenceManager, Licence, LicenceFilter, Grid, Notifier, AccountService, EmployeeQualification, EmployeeModalityTraining, Location, COUNTRY_SELECT_EVENT, GENDERS) {

    var currentUser = AuthService.getUser();
    $scope.user = new User();
    $scope.user.sub_uuid = currentUser.uuid;

    // hotfix for displaying Add new location
    $scope.displayAddLocationComponent = currentUser.basicTypeCode != 'emp';

    if (currentUser.basicTypeCode == 'emp') {
        angular.extend(this, $controller('EmployeesBaseController', {
            $ngRedux: $ngRedux,
            $scope: $scope,
            $rootScope: $rootScope,
            $q: $q,
            $timeout: $timeout,
            AuthService: AuthService,
            NavigationService: NavigationService,
            EmployeeService: EmployeeService,
            EmployeeQualificationService: EmployeeQualificationService,
            EmployeeModalityTrainingService: EmployeeModalityTrainingService,
            EmployeeManager: EmployeeManager,
            LicenceService: LicenceService,
            LicenceManager: LicenceManager,
            Notifier: Notifier,
            AccountService: AccountService,
            Grid: Grid,
            Employee: Employee,
            Licence: Licence,
            EmployeeQualification: EmployeeQualification,
            EmployeeModalityTraining: EmployeeModalityTraining,
            Location: Location,
            COUNTRY_SELECT_EVENT: COUNTRY_SELECT_EVENT,
            GENDERS: GENDERS
        }));

        $scope.MODULE_STATE = $scope.MODULE_STATE_UPDATE;
        $scope.EMPLOYEE_STATE = $scope.MODULE_STATE_UPDATE;

        $scope.employee.uuid = currentUser.uuid;

        $scope.tabs = _.cloneDeep(EmployeeManager.tabs);
        $scope.tabs.shift();
        $scope.tabs.unshift(_.find(SettingsManager.tabs, ['id', SettingsManager.TAB_SETTINGS_PASSWORD]));

        _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_DETAILS]).title = 'Account Details';

        EmployeeService.fetchEmployeeDetails($scope.employee.uuid).then((function(employee) {
            _.assign($scope.employee, employee);
            $scope.employee.locations = null;
            $ngRedux.dispatch({
                type: 'EMPLOYEE_SELECTED',
                id: 'employeesGrid',
                uuid: $scope.employee.uuid
            });

            LicenceService.getLicenceTypes($scope.employee.uuid).then(function(items) {
                $scope.documentTypes = items;
            });

            _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_LICENCES]).visible = true;
            $scope.updateLicence = null;

            $scope.isServiceEngineer.status = EmployeeManager.OCCUPATION_ENGINEER === $scope.employee.occupation.ocuCode;
            if ($scope.isServiceEngineer.status) {
                _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_QUALIFICATIONS]).visible = true;
                _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_MODALITY_TRAININGS]).visible = true;
                EmployeeQualificationService.getQualifications($scope.employee.uuid, 0).then(function(qualifications) {
                    $scope.qualifications = qualifications;
                });
                EmployeeQualificationService.getQualifications($scope.employee.uuid, 1).then(function(qualifications) {
                    $scope.employee.qualifications = qualifications;
                    $scope.gridQualifications.items = $scope.employee.qualifications;
                });
                EmployeeModalityTrainingService.getTrainings($scope.employee.uuid).then(function(trainings) {
                    $scope.employee.trainings = trainings;
                    $scope.gridTrainings.items = $scope.employee.trainings;
                });
                EmployeeModalityTrainingService.getTrainingCategories($scope.employee.uuid).then(function(data) {
                    $scope.trainingCategories = data.categories;
                });
            }
            this.enableTabs();
        }).bind(this));
    }
    else {
        angular.extend(this, $controller('ApplicationModuleController', {
            $scope: $scope,
            $timeout: $timeout,
            Notifier: Notifier
        }));
        $scope.tabs = _.cloneDeep(SettingsManager.tabs);
    }

    _.extend(_.find($scope.tabs, ['id', SettingsManager.TAB_SETTINGS_PASSWORD]), {
        exec: function() {
            var deferred = $q.defer();
            SettingsService.changePassword($scope.user).then(function() {
                $scope.user.password = null;
                $scope.user.passwordNew = null;
                $scope.user.passwordVerify = null;
                $scope.tabs[SettingsManager.TAB_SETTINGS_PASSWORD].form.$setPristine();
                $scope.tabs[SettingsManager.TAB_SETTINGS_PASSWORD].form.$setUntouched();
                Notifier.success('Your password has been successfully changed');
                deferred.resolve();
            }, function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_DETAILS]), {
        exec: function() {
            var deferred = $q.defer();
            EmployeeService.updateEmployee($scope.employee).then(function() {
                Notifier.success('Employee details successfully updated');
                deferred.resolve();
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_QUALIFICATIONS]), {
        exec: (function() {
            var deferred = $q.defer();
            this.assignQualification().then(function() {
                deferred.resolve();
            });
            return deferred.promise;
        }).bind(this)
    });

    _.extend(_.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_LOCATIONS]), {
        exec: (function() {
            var deferred = $q.defer();
            this.addEmploymentLocation().then(function() {
                deferred.resolve();
            });
            return deferred.promise;
        }).bind(this)
    });
}
