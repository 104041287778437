class ReportingEquipmentInspectionController {
    constructor($rootScope) {
        this.$rootScope = $rootScope;
    }

    onGridReset() {
        this.$rootScope.$broadcast('SELECT_CASCADE_RESET', 'reportingEquipmentInspectionFilterForm');
    }
}

ReportingEquipmentInspectionController.$inject = ['$rootScope'];

export const reportingEquipmentInspection = {
    controller: ReportingEquipmentInspectionController,
    template: `
        <reporting-equipment-inspection-grid on-reset="$ctrl.onGridReset()"></reporting-equipment-inspection-grid>
    `
};
