import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import { getDosimetryReports } from '../employees-dosimetry-badge.actions';

/**
 * Creates a new employeesDosimetryBadgesGridController.
 * @class
 * @memberOf module:root/legacy/components/employees
 */
class employeesDosimetryReportsGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$filter = $filter;
    }

    $onInit() {
        this.module = 'employees';
        this.identifier = 'employeeDosimetryReportsGrid';
        this.filterComponent = false;
        this.filterControls = './emp-pd-reports-grid-filter-controls.html';
        this.exportComponent = false;

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), { getDosimetryReports }
        )(this);

        this.columns = [
            {title: '#',                                                                                        mapper: null},
            {title: this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.GRID.PARTICIPANT_NO'),    mapper: 'participant_number'},
            {title: this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.GRID.PERIOD_BEGIN'),      mapper: 'period_begin_date'},
            {title: this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.GRID.PERIOD_END'),        mapper: 'period_end_date'},
            {title: this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.GRID.INCEPTION_DATE'),    mapper: 'inception_date'},
            {title: this.$filter('translate')('PERSONAL_DOSIMETRY.TABS.REPORT_DETAILS.GRID.STATUS'),            mapper: 'status_name'}
        ];

        this.buttons = [{
            label: 'Download',
            class: 'btnGrid',
            callback: this.onDownload,
        }];

        this.dataAction = this.getDosimetryReports;
        this.init();
    }

    $onDestroy() {
        this.destroy();
    };
}

export const employeesDosimetryReportsGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onDownload: '&',
    },
    controller: employeesDosimetryReportsGridController,
    ...gridTpl
};
