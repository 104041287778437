import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsHeadOfficeQualityManagerDetailsController($ngRedux, $uiRouterGlobals, AdministrationActions, Notifier) {
    var ctrl = this;
    var unsubscribe = angular.noop;
    var onQualityManager = angular.noop;

    ctrl.$onInit = function() {
        ctrl.identifier = 'administrationAccountsHeadOfficeQualityManager';
        ctrl.checkboxFlex = 25;

        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);
        ctrl.qualityManagerDetails = !_.isNull(ctrl.qualityManager.uuid);

        if (_.isUndefined(ctrl.stateParams.qualityManager))
            ctrl.getQualityManagerCandidates(ctrl.stateParams.hof, ctrl.identifier);

        var watchQualityManager = watch($ngRedux.getState, 'administration.administrationAccountsHeadOfficeQualityManager.data.uuid');
        onQualityManager = $ngRedux.subscribe(watchQualityManager(function(uuid) {
            ctrl.qualityManagerDetails = !_.isNull(uuid);
        }));
    };

    ctrl.onQualityManagerDetails = function() {
        ctrl.headOfficeQualityManagerForm.$setPristine();
        ctrl.headOfficeQualityManagerForm.$setUntouched();
        ctrl.checkboxFlex = ctrl.qualityManagerDetails ? 50 : 25;
    };

    ctrl.manageQualityManager = function() {
        if (ctrl.headOfficeQualityManagerForm.$valid) {
            if (ctrl.qualityManager.uuid) {
                ctrl.updateQualityManagerDetails('administrationAccountsHeadOfficeQualityManager', ctrl.qualityManager, 'administrationAccountsHeadOfficeQualityManagersGrid');
            }
            else {
                if (ctrl.qualityManagerDetails) {
                    ctrl.qualityManager.location = $uiRouterGlobals.params.hof;
                    ctrl.createQualityManagerDetails(ctrl.qualityManager, 'administrationAccountsHeadOfficeQualityManager', 'administrationAccountsHeadOfficeQualityManagersGrid');
                }
                else {
                    ctrl.qualityManagerCandidate.location = $uiRouterGlobals.params.hof;
                    ctrl.assignQualityManager(ctrl.qualityManagerCandidate, 'administrationAccountsHeadOfficeQualityManager', 'administrationAccountsHeadOfficeQualityManagersGrid');
                }
            }
        }
        else Notifier.error('Please fill in the form fields');
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onQualityManager();
    };

    function mapStateToThis(state) {
        return {
            qualityManager: state.administration.administrationAccountsHeadOfficeQualityManager.data,
            qualityManagerCandidate: state.administration.administrationAccountsHeadOfficeQualityManager.candidate,
            stateParams: state.administration['app.administration.accounts'].stateParams,
            loading: _.find(state.administration['app.administration.accounts'].tabs, ['state', 'app.administration.accounts.headOfficesQualityManagerDetails']).loading,
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsHeadOfficeQualityManager.title
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
