import { inviteApplicant } from '../ap-laser-request.actions';
import { getApprovedPermitHolders } from '../../applications-permits.actions';
import { AP_RESET_PERMIT_HOLDER } from '../../applications-permits.actions.types';
import i18n from '../../i18n/en.json';

/**
 * @const {object}
 * @memberOf module:ApplicationsPermits
 */
export const apLaserRequestInvitation = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $rootScope, $scope, Notifier, AuthService) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.title = i18n[this.type].invitation.title;
            this.unsubscribe = $ngRedux.connect(({ applicationsPermits }) => ({
                invite: applicationsPermits.apInvite
            }), {
                inviteApplicant,
                getApprovedPermitHolders
            })(this);
            this.identifier = $state.$current.parent.name;
            this.state = $state.$current.name;
            this.locationRequired = true;
            const user = AuthService.getUser();
            if (user.parents.length === 1) {
                if (user.parents[0].basicType === 'slo') {
                    this.locationRequired = false;
                }
            }
        }

        $scope.$on('LOCATION_SUBJECT_SELECTED', function(event, locationItem) {
            this.getApprovedPermitHolders(locationItem.in_subject_uuid);
        }.bind(this));

        this.onSend = () => {
            $rootScope.$broadcast('show-errors-check-validity', '$ctrl.apInvitationForm');
            if (this.apInvitationForm.$valid) {
                Notifier.info('Please wait...', false);
                var equipment_training_required = this.invite.equipment_training_required;
                if (this.invite.type === 'aus') {
                    equipment_training_required = 1;
                }
                this.inviteApplicant({
                    purpose: this.type,
                    type: this.invite.type,
                    title: this.invite.title,
                    first_name: this.invite.first_name,
                    last_name: this.invite.last_name,
                    email: this.invite.email,
                    location: this.invite.location,
                    position: this.invite.position,
                    permit_holder_uuid: this.invite.permit_holder_uuid,
                    equipment_training_required: equipment_training_required
                }).then(() => {
                    $rootScope.$broadcast('SELECT_CASCADE_RESET', '$ctrl.apInvitationForm');
                    this.apInvitationForm.$setPristine();
                    this.apInvitationForm.$setUntouched();
                    Notifier.success('Applicant invitation has been successfully sent');
                }).catch(() => {
                    Notifier.error('System Error');
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
        }

        this.onInviteType = () => {
            $ngRedux.dispatch({
                type: AP_RESET_PERMIT_HOLDER,
                id: 'apInvite'
            });
        };

        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: require('./ap-laser-request-invitation.html')
};
