import {TYPES as CAT_TYPES} from "./adm-categorisation-action-type.constants";
import {IDS} from "./adm-categorisation-action-id.constants";
import {TAB_HIDE, TAB_DISABLE} from '../../../common/tabs/tabs.constants';

export const categorisationAbstractState = {
    name: IDS.app_administration_categorisation,
    abstract: true,
    redirectTo: IDS.app_administration_categorisation_dashboard,
    url: '',
}


export const categorisationDashboardState = {
    name: IDS.app_administration_categorisation_dashboard,
    url: '/categorisation',
    component: 'dashboard',
};


export const centersTabsState = {
    name: IDS.app_administration_categorisation_centers,
    redirectTo: IDS.app_administration_categorisation_centers_grid,
    url: '/centers',
    component: 'tabs'
};


export const centersGridState = {
    name: IDS.app_administration_categorisation_centers_grid,
    url: '/types',
    component: 'centerDetails',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: IDS.app_administration_categorisation_centers,
            state: [
                IDS.app_administration_categorisation_centers_details,
            ]
        });
    }
};
centersGridState.onEnter.$inject = ['$ngRedux'];


export const centerDetailsState = {
    name: IDS.app_administration_categorisation_centers_details,
    url: '/details',
    component: 'centerDetails',
};


export const documentsTabsState = {
    name: IDS.app_administration_categorisation_documents,
    redirectTo: IDS.app_administration_categorisation_documents_grid,
    url: '/documents',
    component: 'tabs'
};


export const documentsGridState = {
    name: IDS.app_administration_categorisation_documents_grid,
    url: '/list',
    component: 'documentsGrid',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: IDS.app_administration_categorisation_documents,
            state: [
                IDS.app_administration_categorisation_documents_details,
            ]
        });
    }
};
documentsGridState.onEnter.$inject = ['$ngRedux'];


export const documentDetailsState = {
    name: IDS.app_administration_categorisation_documents_details,
    url: '/details',
    component: 'documentDetails',
};


export const occupationsTabsState = {
    name: IDS.app_administration_categorisation_occupations,
    redirectTo: IDS.app_administration_categorisation_occupations_grid,
    url: '/occupations',
    component: 'tabs'
};


export const occupationsGridState = {
    name: IDS.app_administration_categorisation_occupations_grid,
    url: '/list',
    component: 'occupationsGrid',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: IDS.app_administration_categorisation_occupations,
            state: [
                IDS.app_administration_categorisation_occupations_details,
            ]
        });
    }
};
occupationsGridState.onEnter.$inject = ['$ngRedux'];


export const occupationDetailsState = {
    name: IDS.app_administration_categorisation_occupations_details,
    url: '/details',
    component: 'occupationDetails',
};