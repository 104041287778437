import * as ImportActions from '../import.actions';
import {GRID_FILTER} from "../../../../common/grid/grid.constants";
import {IDS} from "../import-action-id.constants";
class NewImportController {
    constructor($ngRedux, $scope, $timeout, Notifier, $filter) {
        this.$ngRedux = $ngRedux;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.Notifier = Notifier;
        this.$filter = $filter;
    }

    $onInit() {
        this.identifier = IDS.empImport
        this.unsubscribe = this.$ngRedux.connect(state => {
            const empImportNewImport = state.employees[IDS.empImport][IDS.empImportNewImport]
            return {
                siteLocation:           empImportNewImport.siteLocation,
                deleteMissingUsers:     empImportNewImport.deleteMissingUsers
            }
        }, ImportActions)(this);
    }

    onButtonSave(event) {
        this.Notifier.info(this.$filter('translate')('COMMON.NOTIFICATION.PLEASE_WAIT_DOTS'));
        this.importUploadedFile(event, this.siteLocation, this.deleteMissingUsers).then(response => {
            this.$ngRedux.dispatch({
                type: GRID_FILTER,
                id: IDS.empImport,
                path: IDS.empImportNewResultGrid,
                filter: {gci_id: response.data.gci_id}
            });
            this.$ngRedux.dispatch({
                type: 'TAB_SHOW',
                id: IDS.app_employees_import_new,
                state: [IDS.app_employees_import_new_result]
            });
        });
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

NewImportController.$inject = ['$ngRedux', '$scope', '$timeout', 'Notifier', '$filter'];

export const empImportNewImport = {
    controller: NewImportController,
    template: `
        <tabs-header title="{{'EMPLOYEES.IMPORT.UPLOAD.TAB' | translate}}"></tabs-header>

        <form id="importForm" name="$ctrl.importForm" novalidate>
            <div layout-gt-sm="row" flex>
                <select-cascade
                    identifier="empImportNewImport"
                    max="slo"
                    preselected="true"
                    behaviour="'selectDefault'">
                </select-cascade>
            </div>
            <input-checkbox label="{{'EMPLOYEES.IMPORT.UPLOAD.REMOVE_EMPLOYEES_THAT_ARE_MISSING' | translate}}"
                            identifier="empImport"
                            state-path="empImportNewImport.deleteMissingUsers"></input-checkbox>
            <div layout="column" flex>
                <upload dropzone="true"
                        input-id="uploader"
                        label="{{'COMMON.BUTTON.UPLOAD_DOCUMENT' | translate}}"
                        validate="true"
                        valid-type="xlsx"
                        identifier="empImport"
                        on-save="$ctrl.onButtonSave($event)">
                </upload>
            </div>

        </form>
`
};
