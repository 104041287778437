export const rmWasteAddState = {
    name: 'app.radioactiveMaterials.waste.add',
    redirectTo: 'app.radioactiveMaterials.waste.add.details',
    url: '/add',
    component: 'tabs'
};

export const rmWasteAddDetailsState = {
    name: 'app.radioactiveMaterials.waste.add.details',
    url: '',
    component: 'rmWasteDetails',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'RM_WASTE_RESET',
            id: 'rmWaste'
        });
    }
};

export const rmWasteManageState = {
    name: 'app.radioactiveMaterials.waste.manage',
    redirectTo: 'app.radioactiveMaterials.waste.manage.listing',
    url: '/manage',
    component: 'tabs'
};

export const rmWasteManageListingState = {
    name: 'app.radioactiveMaterials.waste.manage.listing',
    url: '',
    component: 'rmWasteGrid',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.radioactiveMaterials.waste.manage',
            state: ['app.radioactiveMaterials.waste.manage.details']
        });
    }
};

export const rmWasteManageDetailsState = {
    name: 'app.radioactiveMaterials.waste.manage.details',
    url: '/details?id',
    component: 'rmWasteDetails'
};

export const rmWasteAlertsState = {
    name: 'app.radioactiveMaterials.waste.alerts',
    redirectTo: 'app.radioactiveMaterials.waste.alerts.listing',
    url: '/alerts',
    component: 'tabs'
};

export const rmWasteAlertsListingState = {
    name: 'app.radioactiveMaterials.waste.alerts.listing',
    url: '',
    component: 'rmWasteAlerts'
};
