import {TYPES} from '../scheme-action-type.constants';
import {IDS} from '../scheme-action-id.constants';
import API from '../../../../common/http/api.fetch';
import I18N from '../../../../common/utils/util.i18n';
import {TAB_SHOW} from '../../../../common/tabs/tabs.constants';
import {GRID_FILTER, GRID_RESET} from '../../../../common/grid/grid.constants';
import {responseMessage, success} from '../../../../common/utils/util.notifier';
import pick from 'lodash/pick';


export const fetchAuditUser = (uuid) => {
    return dispatch => {
        return API.fetch({
            url: `/contact-details/${uuid}`,
            method: 'GET'
        }).then(response => {
            dispatch({
                type: TYPES.FETCH_AUDIT_USER_DEAILS,
                id: IDS.accScheme,
                data: response.data[0]
            });
        }, error => {
            responseMessage(error);
        })
    }
}

export const fetchEvidenceAssessment = (standard_id, slo_uuid, year) => {
    return dispatch => {
        if(!standard_id || !slo_uuid)
            return
        return API.fetch({
            url: '/accreditation/versioned-document-list',
            method: 'GET',
            params: {
                standard_id,
                slo_uuid,
                year,
                assessment_type: 'assevd',
                limit: -1
            }
        }).then( response => {
            const assessmentItems = response.data.items;
            if(Array.isArray(assessmentItems)) {
                assessmentItems.forEach(assessmentItem => {
                    if (assessmentItem.documents) {
                        assessmentItem.documents.forEach(document => {
                            document.reviewers.forEach((reviewer, index) => {
                                const key = `COMMON.STATUS.${reviewer.status_code.toUpperCase()}`;
                                reviewer.status_name = I18N.translate(key);
                                document[`reviewer${index + 1}`] = reviewer.status_name; // create grid column for each reviewer
                            })
                        })
                    }
                })
            }
            return dispatch({
                type: TYPES.FETCH_EVIDENCE_ASSESSMENT,
                id: IDS.accScheme,
                items: response.data.items,
            });
        }, error => {
            responseMessage(error);
        });
    }
}

export const fetchEvidenceAssigneeCandidates = (keyword, location) => {
    return dispatch => {
        return API.fetch({
            url: `/employees`,
            method: 'GET',
            params: {
                keyword,
                location,
                status: 'act',
                //licences: false,
                offset: 0,
                limit: 20
            }
        }).then(response => {
            dispatch({
                id: IDS.accScheme,
                type: TYPES.FETCH_EVIDENCE_ASSIGNEE_CANDIDATES,
                data: response.data.list.map(item => ({
                        ...pick(item, ['first_name','last_name','email', 'subject_type_name']),
                        employee_uuid : item.subject_uuid
                    })
                )
            });
        });
    };
};

export const addEvidenceRequest = (aas_id,
                                   ast_id,
                                   slo_uuid,
                                   year,
                                   name,
                                   description,
                                   employee_uuid,
                                   first_name,
                                   last_name,
                                   email) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/add-evidence-request',
            method: 'POST',
            data: {
                aas_id,
                ast_id,
                slo_uuid,
                year,
                name,
                description,
                employee_uuid: employee_uuid || undefined,
                first_name,
                last_name,
                email,
            }
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_ASSIGNED');
        },error => {
            responseMessage(error)
        });
    };
};

// export const updateStandardAssessmentStatus = (ast_id, slo_uuid, year, status_code) => {
//     return dispatch => {
//         return API.fetch({
//             url: '/accreditation/update-standard-assessment-status',
//             method: 'PUT',
//             data: {
//                 ast_id,
//                 slo_uuid,
//                 year,
//                 status_code,
//             }
//         }).then(response => {
//             success('COMMON.SERVER_RESPONSE.ITEM_UPDATED');
//         },error => {
//             responseMessage(error)
//         });
//     };
// };

export const setLocationFilter = (slo_uuid) => {
    return dispatch => {
        return dispatch({
            type:   GRID_FILTER,
            id:     IDS.accScheme,
            path:   IDS.documentAddGrid,
            filter: { slo_uuid }
        })
    }
}

export const showTabRequestDocuments = (assessmentItem) => {
    return dispatch => {
        dispatch({
            type: TYPES.SELECT_ASSESSMENT_ITEM,
            id: IDS.accScheme,
            assessmentItem,
        });
        return dispatch({
            type: TAB_SHOW,
            id: IDS.app_accreditation_scheme_manage,
            state: [IDS.app_accreditation_scheme_manage_documentRequest]
        })
    }
}
