/* @ngInject */
export function VendorSiteLocationFactory(Country) {
    return function VendorSiteLocation() {

        this.hofUuid = null;
        this.hofName = null;
        this.uuid = null;
        this.country = new Country();
        this.name = null;
        this.email = null;
        this.phone = null;
        this.managers = [];

        this.getManager = function(uuid) {
            var manager = _.find(this.managers, ['uuid', uuid]);
            return _.isUndefined(manager) ? null : manager;
        };

        this.addManager = function(manager) {
            if (_.isNull(this.getManager(manager.uuid)))
                this.managers.push(manager);
        };

        this.removeManager = function(manager) {
            var idx = _.findIndex(this.managers, ['uuid', manager.uuid]);
            if (idx > -1) this.managers.splice(idx, 1);
        };
    };
}
