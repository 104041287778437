import { TabsReducer } from '../../../../common/tabs/tabs.reducer';
import {IDS} from '../import-settings-action-id.constants';
export const EmpImportSettingsOccupationsTabs = {
    [IDS.app_employees_import_settings_occupations]: Object.assign(TabsReducer(), {
        tabs: [{
            state: IDS.app_employees_import_settings_occupations_list,
            title: 'Occupations',
            visible: true,
            disabled: false,
            loading: false
        },
        {
            state: IDS.app_employees_import_settings_occupations_details,
            title: 'Occupation Details',
            visible: false,
            disabled: false,
            loading: false,
            buttons: {
                // execute: {label: 'Download'}
            }
        }]
    }),
};
