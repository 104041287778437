import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';
import { getManufacturerEquModels } from '../manufacturer-equ.actions';

class ManufacturerEquGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'manufacturers';
        this.identifier = 'manufacturerEquipmentGrid';
        this.filterTemplate = './manufacturer-equ-grid-filter.html';
        this.customComponent = 'manufacturerEquGridModel';

        this.columns = [
            { title: '#',               mapper: null },
            { title: 'Manufacturer',    mapper: 'manufacturer' },
            { title: 'Equipment Model', mapper: 'equipment_model' },
            { title: 'Equipment Type',  mapper: 'equipment_type' },
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), { getManufacturerEquModels }
        )(this);

        this.dataAction = this.getManufacturerEquModels;
        this.exportService = this.getManufacturerEquModels;
        this.filter.identifier = this.identifier;

        this.init();
    }

    $onDestroy() {
        this.destroy();
        this.$ngRedux.dispatch({
            type: 'GRID_RESET',
            id: 'manufacturerEquipmentGrid',
        });
    }
}

export const manufacturerEquipmentGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onModify: '&',
    },
    controller: ManufacturerEquGridController,
    ...gridTpl,
};
