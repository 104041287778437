/** @namespace Reducers.App */

import { VALIDATION } from './utils/util.constants';
import { VALIDATION_MESSAGES } from './utils/util.constants';
import mergeWith from 'lodash/mergeWith';

/**
 * @function appReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function appReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            mobile: null,
            loading: false,
            user: {
                uuid: null,
                type: null,
                username: null,
                country: null
            },
            country: null,
            validation: {
                email: null,
                phone: null
            },
            validationMessage: {
                phone: null
            },
            countries: [],
            genders: [
                {id: 1, title: 'Male'},
                {id: 2, title: 'Female'},
                {id: 3, title: 'Other'}
            ]
        };
    }

    switch (action.type) {
        case 'MOBILE_DETECT':
            return Object.assign({}, state, {mobile: action.device});
        case 'LOADING_START':
            return Object.assign({}, state, {loading: true});
        case 'LOADING_END':
            return Object.assign({}, state, {loading: false});
        case 'LOGIN':
            return Object.assign({}, state, {
                user: Object.assign({}, state.user, {
                    country: action.user.country
                })
            });
        case 'INIT_APP':
            return Object.assign({}, state, {
                user: Object.assign({}, state.user, {
                    uuid: action.user.uuid,
                    type: action.user.type,
                    typeCode: action.user.typeCode,
                    username: action.user.name,
                    country: action.user.country
                }),
                country: action.user.country,
                validation: action.user.country ? VALIDATION[state.user.country] : Object.values(VALIDATION).reduce((result, item) => {
                    result = mergeWith(result, item, (objValue, srcValue) => {
                        if (objValue !== srcValue)
                            return typeof objValue === 'undefined' ? srcValue : '(' + objValue + '|' + srcValue + ')';
                    });
                    return result;
                }, {}),
                validationMessage: action.user.country ? VALIDATION_MESSAGES[state.user.country] : Object.values(VALIDATION_MESSAGES).reduce((result, item) => {
                    result = mergeWith(result, item, (objValue, srcValue) => {
                        if (objValue !== srcValue)
                            return typeof objValue === 'undefined' ? srcValue.join('\n') : objValue + '\n' + srcValue.join('\n');
                    });
                    return result;
                }, {})
            });
        case 'COUNTRIES_FETCH':
            if (action.id === 'countries') {
                return Object.assign({}, state, {countries: action.items});
            }
        default:
            return state;
    }
}
