/** @namespace Reducers.Employees */
import { TabsReducer } from '../common/tabs/tabs.reducer';
import { GridReducer } from '../common/grid/grid.reducer';
import { getGridSort } from '../common/grid/grid.utils';
import { formatDate } from '../common/utils/util.date';
import { DATE_FORMAT } from '../common/utils/util.constants';
import merge from 'lodash/merge';
import set from 'lodash/set';
import update from 'lodash/update';
import omit from 'lodash/omit';

import * as TABS from "../common/tabs/tabs.constants";

import {EmpImportReducer}       from "../components/employees/import/import.reducer";
import {EmpImportHistoryTabs}   from "../components/employees/import/history/history-tabs";
import {EmpImportNewTabs}       from "../components/employees/import/new-import/new-import-tabs";
import {PREFIX as IMPORT_ACTION_PREFIX} from "../components/employees/import/import-action-type.constants";
import {IDS as IMPORT_ACTION_IDS} from "../components/employees/import/import-action-id.constants";

import {EmpImportSettingsReducer}       from "../components/employees/import-settings/import-settings.reducer";
import {EmpImportSettingsCentersTabs}   from "../components/employees/import-settings/centers/centers-tabs";
import {EmpImportSettingsOccupationsTabs}   from "../components/employees/import-settings/occupations/occupations-tabs";
import {EmpImportSettingsUsersTabs}   from "../components/employees/import-settings/users/users-tabs";
import {PREFIX as IMPORT_SETTINGS_ACTION_PREFIX} from "../components/employees/import-settings/import-settings-action-type.constants";
import {IDS as IMPORT_SETTINGS_ACTION_IDS} from "../components/employees/import-settings/import-settings-action-id.constants";

import * as TYPE from '../components/employees/employees.actions.types';

/**
 * @function employeesReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function employeesReducer(state, action) {
    if (typeof state === 'undefined') {
        return {
            'app.employees.alerts': Object.assign(TabsReducer(), {
                tabs: [{
                    state: 'app.employees.alerts.listing',
                    title: 'Alerts & Warnings',
                    visible: true,
                    disabled: false,
                    loading: false,
                }]
            }),
            'public.employees.licence': {
                ...TabsReducer(),
                tabs: [
                    {
                        state: 'public.employees.licence.details',
                        title: 'Licence',
                        visible: true,
                        disabled: false,
                        loading: false,
                        buttons: []
                    }
                ]
            },

            empImport: EmpImportReducer(),
            ...EmpImportHistoryTabs,
            ...EmpImportNewTabs,

            empImportSettings: EmpImportSettingsReducer(),
            ...EmpImportSettingsCentersTabs,
            ...EmpImportSettingsOccupationsTabs,
            ...EmpImportSettingsUsersTabs,

            employeesGrid: merge(GridReducer(), {
                filter: {
                    badges: null,
                    keyword: null,
                    searched_column: null,
                    location: null,
                    checkLocation: true,
                    occupation: null,
                    modalityTrainingCategory: null,
                    status: 'act',
                    licences_status: null,
                    imported_after: null,
                },
                modalityTrainingCategories: [],
                searchedColumns: [
                    {value: 'first_name', label: 'First Name'},
                    {value: 'last_name', label: 'Last Name'},
                    {value: 'username', label: 'User Name'},
                    {value: 'email', label: 'Email'}
                ],
                licencesStatusList: [
                    {value: 'null', label: 'All employees'},
                    {value: 'act', label: 'Employees with licences'},
                    {value: 'pen', label: 'Employees with pending licences'},
                    {value: 'wuc', label: 'Employees without use licence created'},
                    {value: 'wuu', label: 'Employees without use licence uploaded'},
                    {value: 'wbc', label: 'Employees without AHPRA/Board registration created'},
                    {value: 'wbu', label: 'Employees without AHPRA/Board registration uploaded'}
                ]
            }),
            employeesAlertsGrid: merge(GridReducer(), {
                filter: {
                    moduleId: null,
                    alertType: null,
                    keyword: null,
                    equipmentId: null,
                    employeeUuid: null
                }
            }),
            employeeDetails: {
                reload: false
            },
            employeeLocationsGrid: merge(set(GridReducer(), 'itemsCount', undefined), {
                filter: {
                    uuid: null
                }
            }),
            employeeLicencesGrid: merge(GridReducer(), {
                filter: {
                    uuid: null,
                    type: null,
                    status: 'act'
                }
            }),
            employeeDosimetryBadge: {
                participantNumber: null,
                badgeType: null,
                returnDate: null,
                distributedDate: null,
                inceptionDate: null,
                badgeTypes: [
                    {value: 'whb', label: 'Whole Body'},
                    {value: 'ext', label: 'Extremity'},
                    {value: 'ftb', label: 'Foetal Badge'}
                ],
                wearPeriod: null,
                wearPeriods: [
                    {value: '1', label: '1 Month'},
                    {value: '2', label: '2 Months'},
                    {value: '3', label: '3 Months'}
                ],
                doseReportsYear: new Date().getFullYear(),
                doseReportsYears: Array.from({ length: new Date().getFullYear() - 2019 }, (_, idx) => {
                    const year = new Date().getFullYear() - idx;
                    return { value: year, label: `${year}` };
                }),
            },
            employeeDosimetryBadgesGrid: {
                ...GridReducer(),
                items: [],
                itemsCount: 0
            },
            employeeDosimetryReportsGrid: merge(GridReducer(), {
                filter: { uuid: null },
            }),
            employeeTrainingsGrid: merge(GridReducer(), {
                trainingsStatusOptions: [
                    {value: 'act', label: 'Active'},
                    {value: 'fns', label: 'Finished'}
                ],
                items: [],
                itemsCount: 0,
                filter: {
                    status: 'act'
                }
            }),
            empLicence: {
                token: null,
                sub_uuid: null,
                first_name: null,
                last_name: null,
                username: null,
                email: null,
                slo_username: null,
                hof_username: null,
                lic_id: null,
                licence_type_id: null,
                licence_type: null,
                Licence_type_code: null,
                issue_date: null,
                exp_date: null,
                document_uri: null,
                lic_num: null,
                comment: null
            }
        };
    }

    switch (action.type) {
        case 'EMPLOYEES_SWITCH':
            if (action.id === 'employeesGrid') {
                return Object.assign({}, state, {
                    employeesGrid: set(GridReducer(state.employeesGrid, set(action, 'type', 'PAGINATE')), 'filter.licences', action.licences)
                });
            }
        case 'EMPLOYEES_SWITCH_BADGES':
            if (action.id === 'employeesGrid') {
                return Object.assign({}, state, {
                    employeesGrid: set(GridReducer(state.employeesGrid, set(action, 'type', 'PAGINATE')), 'filter.badges', action.badges)
                });
            }
        case 'KEYWORD_CHANGE':
            if (action.id === 'employeesGrid') {
                return Object.assign({}, state, {
                    employeesGrid: set(GridReducer(state.employeesGrid, set(action, 'type', 'PAGINATE')), 'filter.keyword', action.keyword)
                });
            }
        case 'INPUT_TEXT_CHANGE':
        case 'INPUT_RADIO_CHANGE':
        case 'INPUT_TEXT_AREA_CHANGE':
        case 'DATE_SELECTED':
            if (action.id === 'employeesAlertsGrid') {
                return Object.assign({}, state, {
                    employeesAlertsGrid: set(GridReducer(state.employeesAlertsGrid, set(action, 'type', 'PAGINATE')), 'filter.keyword', action.input)
                });
            }
            if (action.id === 'employeeDosimetryBadge' || action.id === 'empLicence') {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        [action.path]: action.input
                    }
                }
            }
            if (action.id === 'employeesGrid') {
                return {
                    ...state,
                    employeesGrid: GridReducer(
                        state.employeesGrid,
                        set({...action, type: 'FILTER'}, action.path, formatDate( action.input, DATE_FORMAT.OUTPUT ))
                    )
                };
            }

        case 'EMPLOYEE_OCCUPATION_SELECTED':
            if (action.id === 'employeesGrid') {
                return Object.assign({}, state, {
                    employeesGrid: set(GridReducer(state.employeesGrid, set(action, 'type', 'PAGINATE')), 'filter.occupation', action.occupation)
                });
            }
        case 'EMPLOYEE_STATUS_SELECTED':
            if (action.id === 'employeesGrid') {
                return Object.assign({}, state, {
                    employeesGrid: set(GridReducer(state.employeesGrid, set(action, 'type', 'PAGINATE')), 'filter.status', action.status)
                });
            }
        case 'LOCATION_SELECTED':
            if (action.id === 'employeesGrid') {
                return Object.assign({}, state, {
                    employeesGrid: set(GridReducer(state.employeesGrid, set(action, 'type', 'PAGINATE')), 'filter.location', action.uuid)
                });
            }
        case 'EMPLOYEES_MODALITY_TRAININGS':
            if (action.id === 'employeesGrid') {
                return Object.assign({}, state, {
                    employeesGrid: Object.assign({}, state.employeesGrid, {modalityTrainingCategories: action.items})
                });
            }
        case 'CHECK_LOCATION':
            if(action.id === 'employeesGrid')
            return Object.assign({}, state, set({}, action.id,  set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.checkLocation', action.checkLocation)));
        case 'EMPLOYEE_SELECTED':
            if (action.id === 'employeesGrid') {
                return Object.assign({}, state, {
                    employeeLocationsGrid: set(GridReducer(state.employeeLocationsGrid, set(action, 'type', 'PAGINATE')), 'filter.uuid', action.uuid),
                    employeeLicencesGrid: update(GridReducer(state.employeeLicencesGrid, set(action, 'type', 'GRID_RESET')), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            type: null,
                            status: 'act',
                            uuid: action.uuid
                        });
                    }),
                    employeeDosimetryBadgesGrid: set(GridReducer(state.employeeDosimetryBadgesGrid, {type: 'PAGINATE'}), 'filter.uuid', action.uuid),
                    employeeDosimetryReportsGrid: set(GridReducer(state.employeeDosimetryReportsGrid, {type: 'PAGINATE'}), 'filter.uuid', action.uuid),
                    employeeTrainingsGrid: update(GridReducer(state.employeeTrainingsGrid, set(action, 'type', 'GRID_RESET')), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            status: 'act',
                            uuid: action.uuid
                        });
                    }),
                });
            }
        case 'EMPLOYEE_LICENCE_TYPE_SELECTED':
            if (action.id === 'employeeLicencesGrid') {
                return Object.assign({}, state, {
                    employeeLicencesGrid: set(GridReducer(state.employeeLicencesGrid, set(action, 'type', 'PAGINATE')), 'filter.type', action.licenceType)
                });
            }
        case 'EMPLOYEE_LICENCE_STATUS_SELECTED':
            if (action.id === 'employeeLicencesGrid') {
                return Object.assign({}, state, {
                    employeeLicencesGrid: set(GridReducer(state.employeeLicencesGrid, set(action, 'type', 'PAGINATE')), 'filter.status', action.status)
                });
            }
        case 'RESET_BADGE_FORM':
            if (action.id === 'employeeDosimetryBadge') {
                return {
                    ...state,
                    employeeDosimetryBadge: {
                        ...state.employeeDosimetryBadge,
                        participantNumber: null,
                        badgeType: null,
                        returnDate: null,
                        distributedDate: null,
                        inceptionDate: null,
                        wearPeriod: null
                    }
                }
            }

        // -- COMMON IDS --

        case 'GRID_RESET':
            if (action.id === 'employeesGrid') {
                return Object.assign({}, state, {
                    employeesGrid: update(GridReducer(state.employeesGrid, action), 'filter', function(filter) {
                        return Object.assign({}, omit(filter, 'sort'), {
                            keyword: null,
                            searched_column: null,
                            location: null,
                            occupation: null,
                            modalityTrainingCategory: null,
                            status: 'act',
                            licences_status: null,
                            imported_after: null,
                        });
                    })
                });
            }
            if (action.id === 'employeesAlertsGrid') {
                return Object.assign({}, state, {
                    employeesAlertsGrid: update(GridReducer(state.employeesAlertsGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            alertType: null,
                            keyword: null,
                            equipmentId: null,
                            employeeUuid: null
                        });
                    })
                });
            }
            if (action.id === 'employeeLicencesGrid') {
                return Object.assign({}, state, {
                    employeeLicencesGrid: update(GridReducer(state.employeeLicencesGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            type: null,
                            status: 'act'
                        });
                    })
                });
            }
            if (action.id === 'employeeDosimetryBadgesGrid') {
                const dosimetryBadgesGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...dosimetryBadgesGrid
                    }
                }
            }
            if (action.id === 'employeeTrainingsGrid') {
                const trainingsGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...trainingsGrid,
                        filter: {
                            ...trainingsGrid.filter,
                            status: 'act'
                        }
                    }
                }
            }
        case 'GRID_SORT':
            if (action.id === 'employeesGrid') {
                return Object.assign({}, state, set({}, action.id, update(GridReducer(state[action.id], action), 'filter', (filter) => {
                    return Object.assign({}, filter, {
                        sort: getGridSort(filter, action)
                    });
                })));
            }
        case 'INPUT_SELECT':
            if (action.id === 'employeesGrid') {
                return {
                    ...state,
                    [action.id]: set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)
                }
            }
            if (action.id === 'employeeDosimetryBadge') {
                return {
                    ...state,
                    employeeDosimetryBadge: {
                        ...state.employeeDosimetryBadge,
                        doseReportsYear: action.input,
                    }
                }
            }
            if (action.id === 'employeeTrainingsGrid') {
                return {
                    ...state,
                    [action.id]: set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)
                }
            }
        case 'GRID_RESET_FILTER':
            if (action.id === 'employeesAlertsGrid') {
                return Object.assign({}, state, {
                    employeesAlertsGrid: GridReducer(state.employeesAlertsGrid, action)
                });
            }
            if (action.id === 'employeesGrid') {
                return Object.assign({}, state, {
                    employeesGrid: update(GridReducer(state.employeesGrid, action), 'filter', function(filter) {
                        return Object.assign({}, omit(filter, 'sort'), {
                            keyword: null,
                            searched_column: null,
                            location: null,
                            occupation: null,
                            modalityTrainingCategory: null,
                            status: 'act',
                            licences_status: null
                        });
                    })
                });
            }
        case 'GRID_INIT_FILTER':
        case 'SHOW_LOADING':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
            if (action.id === 'employeesGrid') {
                return Object.assign({}, state, {
                    employeesGrid: GridReducer(state.employeesGrid, action)
                });
            }
            if (action.id === 'employeesAlertsGrid') {
                return Object.assign({}, state, {
                    employeesAlertsGrid: GridReducer(state.employeesAlertsGrid, action)
                });
            }
            if (action.id === 'employeeLocationsGrid') {
                return Object.assign({}, state, {
                    employeeLocationsGrid: GridReducer(state.employeeLocationsGrid, action)
                });
            }
            if (action.id === 'employeeLicencesGrid') {
                return Object.assign({}, state, {
                    employeeLicencesGrid: GridReducer(state.employeeLicencesGrid, action)
                });
            }
            if (action.id === 'employeeDosimetryBadgesGrid') {
                const dosimetryBadgesGrid = GridReducer(state[action.id], action);
                return {
                    ...state,
                    [action.id]: {
                        ...dosimetryBadgesGrid
                    }
                }
            }
            if (action.id === 'employeeDosimetryReportsGrid') {
                return {
                    ...state,
                    [action.id]: GridReducer(state[action.id], action),
                };
            }
            if (action.id === 'employeeTrainingsGrid') {
                return Object.assign({}, state, {
                    employeeTrainingsGrid: GridReducer(state.employeeTrainingsGrid, action)
                });
            }
        case TABS.TAB_INIT:
        case TABS.TAB_OVERRIDE:
        case TABS.TAB_PARAMS:
        case TABS.TAB_ENABLE:
        case TABS.TAB_DISABLE:
        case TABS.TAB_SHOW:
        case TABS.TAB_HIDE:
        case TABS.TAB_LOADING:
        case TABS.TAB_SELECT:
        case TABS.TAB_LABEL:
        case TABS.TAB_BUTTON_LABEL:
            if (action.id.startsWith('app.employees.import')){
                return {
                    ...state,
                    [action.id]: TabsReducer(state[action.id], action)
                }
            }
        case TABS.TAB_SWITCH:
            if (action.id.startsWith('app.employees.import')){
                return {
                    ...state,
                    [action.id]: Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                }
            }
        case TYPE.EMP_LICENCE_TOKEN:
            if (action.id === 'empLicence') {
                return {
                    ...state,
                    empLicence: {
                        ...state.empLicence,
                        token: action.token,
                        lic_id: action.licence
                    }
                }
            }
        case TYPE.EMP_LICENCE_RESET:
            if(action.id === 'empLicence') {
                return {
                    ...state,
                    empLicence: {
                        ...state.empLicence,
                        token: null,
                        sub_uuid: null,
                        first_name: null,
                        last_name: null,
                        username: null,
                        email: null,
                        slo_username: null,
                        hof_username: null,
                        lic_id: null,
                        licence_type_id: null,
                        licence_type: null,
                        Licence_type_code: null,
                        issue_date: null,
                        exp_date: null,
                        document_uri: null,
                        lic_num: null,
                        comment: null
                    }
                }
            }
        case TYPE.EMP_LICENCE_DETAILS:
            if (action.id === 'empLicence') {
                return {
                    ...state,
                    empLicence: {
                        token: null,
                        sub_uuid: action.data.sub_uuid,
                        first_name: action.data.first_name,
                        last_name: action.data.last_name,
                        username: action.data.username,
                        email: action.data.email,
                        slo_username: action.data.slo_username,
                        hof_username: action.data.hof_username,
                        lic_id: action.data.lic_id,
                        licence_type_id: action.data.license_type_id,
                        licence_type: action.data.license_type,
                        Licence_type_code: action.data.license_type_code,
                        issue_date: formatDate(action.data.issue_date, DATE_FORMAT.INPUT),
                        exp_date: formatDate(action.data.exp_date, DATE_FORMAT.INPUT),
                        document_uri: action.data.document_uri,
                        lic_num: action.data.lic_num
                    }
                }
            }
    }


    if(action.type.startsWith(IMPORT_ACTION_PREFIX)
        || action.id && IMPORT_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, empImport: EmpImportReducer(state.empImport, action) }
    }


    if(action.type.startsWith(IMPORT_SETTINGS_ACTION_PREFIX)
        || action.id && IMPORT_SETTINGS_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, empImportSettings: EmpImportSettingsReducer(state.empImportSettings, action) }
    }

    return state;
}
