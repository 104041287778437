import { List } from 'immutable';

/* @ngInject */
export function EmployeeManager($rootScope, $mdDialog, NavigationService, EmployeeQualificationService, Notifier, $filter) {

    this.TAB_EMPLOYEES = 0;
    this.TAB_EMPLOYEE_DETAILS = 1;
    this.TAB_EMPLOYEE_LOCATIONS = 2;
    this.TAB_EMPLOYEE_LICENCES = 3;
    this.TAB_EMPLOYEE_QUALIFICATIONS = 4;
    this.TAB_EMPLOYEE_MODALITY_TRAININGS = 5;
    this.TAB_EMPLOYEE_DOSIMETRY_BADGES = 6;
    this.TAB_EMPLOYEE_TRAININGS = 7;

    this.tplEmployeeListing            = './employee-listing.html';
    this.tplEmployeeDetails            = './employee-add.html';
    this.tplEmployeeLocations          = './employee-locations.html';
    this.tplEmployeeLicences           = './employee-licences.html';
    this.tplEmployeeQualifications     = './employee-qualifications.html';
    this.tplEmployeeModalityTrainings  = './employee-modality-trainings.html';
    this.tplEmployeeDosimetryBadges    = './employee-dosimetry-badges.html';
    this.tplEmployeeTrainings          = './employee-trainings.html';

    this.OCCUPATION_ENGINEER = 'sng';
    this.USE_LICENCE = 'eul';
    this.CMRITO = 'cmt';
    this.RADIATION_USE_LICENCE = 'ulr';

    this.tabs = [
        {
            id: this.TAB_EMPLOYEES,
            title: $filter('translate')('EMPLOYEES.TABS.TITLE_EMPLOYEES'),
            content: this.tplEmployeeListing,
            form: null,
            formValidation: false,
            formValidationMsg: '',
            visible: true,
            disabled: false,
            stateless: true
        },
        {
            id: this.TAB_EMPLOYEE_DETAILS,
            title: $filter('translate')('EMPLOYEES.TABS.TITLE_DETAILS'),
            content: this.tplEmployeeDetails,
            form: null,
            formValidation: true,
            formValidationMsg: $filter('translate')('EMPLOYEES.GRID.FIELDS_REQUIRED'),
            visible: true,
            disabled: true,
            stateless: false
        },
        {
            id: this.TAB_EMPLOYEE_LOCATIONS,
            title: $filter('translate')('EMPLOYEES.TABS.TITLE_LOCATIONS'),
            content: this.tplEmployeeLocations,
            form: null,
            formValidation: true,
            formValidationMsg: $filter('translate')('EMPLOYEES.GRID.FIELDS_REQUIRED'),
            visible: true,
            disabled: true,
            stateless: false
        },
        {
            id: this.TAB_EMPLOYEE_LICENCES,
            title: $filter('translate')('EMPLOYEES.TABS.TITLE_LICENCES'),
            content: this.tplEmployeeLicences,
            form: null,
            formValidation: true,
            formValidationMsg: $filter('translate')('EMPLOYEES.GRID.FIELDS_REQUIRED'),
            visible: false,
            disabled: true,
            stateless: false
        },
        {
            id: this.TAB_EMPLOYEE_QUALIFICATIONS,
            title: $filter('translate')('EMPLOYEES.TABS.TITLE_QUALIFICATIONS'),
            content: this.tplEmployeeQualifications,
            form: null,
            formValidation: true,
            formValidationMsg: $filter('translate')('EMPLOYEES.GRID.FIELDS_REQUIRED'),
            visible: true,
            disabled: true,
            stateless: false
        },
        {
            id: this.TAB_EMPLOYEE_MODALITY_TRAININGS,
            title: $filter('translate')('EMPLOYEES.TABS.TITLE_MODALITY'),
            content: this.tplEmployeeModalityTrainings,
            form: null,
            formValidation: true,
            formValidationMsg: $filter('translate')('EMPLOYEES.GRID.FIELDS_REQUIRED'),
            visible: true,
            disabled: true,
            stateless: false
        },
        {
            id: this.TAB_EMPLOYEE_DOSIMETRY_BADGES,
            title: 'Dosimetry',
            content: this.tplEmployeeDosimetryBadges,
            form: null,
            formValidation: true,
            formValidationMsg: $filter('translate')('EMPLOYEES.GRID.FIELDS_REQUIRED'),
            visible: false,
            disabled: true,
            stateless: false
        },
        {
            id: this.TAB_EMPLOYEE_TRAININGS,
            title: 'Training',
            content: this.tplEmployeeTrainings,
            form: null,
            formValidation: true,
            formValidationMsg: 'Training',
            visible: false,
            disabled: true,
            stateless: false
        }
    ];

    this.employerColumns = new List([
        {title: '#',        mapper: false},
        {title: $filter('translate')('EMPLOYEES.GRID.LOCATION'), mapper: 'name'}
    ]);

    this.qualificationColumns = new List([
        {title: $filter('translate')('EMPLOYEES.GRID.QUALIFICATION'), mapper: 'name'}
    ]);

    this.trainingColumns = new List([
        {title: '#',            mapper: false},
        {title: $filter('translate')('EMPLOYEES.GRID.CATEGORY'),     mapper: 'getCategories'},
        {title: $filter('translate')('EMPLOYEES.GRID.ISSUE_DATE'),   mapper: 'getIssueDate'},
        {title: $filter('translate')('EMPLOYEES.GRID.DOCUMENT'),     mapper: null}
    ]);

    this.manageQualification = function(qualification, subUuid) {

        $mdDialog.show({
            controller: qualificationCtrl,
            template: require('../views/qualifications/employee-qualification-dialog.html'),
            parent: angular.element(document.body),
            locals: {
                qualification: qualification,
                subUuid: subUuid
            },
            clickOutsideToClose: true
        });

        function qualificationCtrl($scope, qualification, subUuid) {

            $scope.headerColor = NavigationService.getNavConfig().colorName;
            $scope.qualification = _.cloneDeep(qualification);
            $scope.STATE_CREATE = 'create';
            $scope.STATE_UPDATE = 'update';
            $scope.STATE = _.isNull(qualification.id) ? $scope.STATE_CREATE : $scope.STATE_UPDATE;

            $scope.manageQualification = function() {
                $scope.$broadcast('show-errors-check-validity');
                if ($scope.qualificationForm.$invalid) return;

                switch($scope.STATE) {
                    case $scope.STATE_CREATE:
                        EmployeeQualificationService.addQualification($scope.qualification, subUuid).then(function(data) {
                            // TODO return qualifications object
                            $scope.qualification.id = data.id;
                            _.assign(qualification, $scope.qualification);
                            $rootScope.$broadcast('CREATE_QUALIFICATION_COMPLETE');
                            Notifier.success($filter('translate')('EMPLOYEES.GRID.NOTIFIER_QUALIFICATION_ADDED'));
                            $mdDialog.hide();
                        }, function(error) {
                            Notifier.error($filter('translate')('EMPLOYEES.GRID.NOTIFIER_QUALIFICATION_EXISTS'));
                        });
                        break;
                    case $scope.STATE_UPDATE:
                        EmployeeQualificationService.updateQualification($scope.qualification).then(function(data) {
                            Notifier.success($filter('translate')('EMPLOYEES.GRID.NOTIFIER_QUALIFICATION_UPDATED'));
                            _.assign(qualification, $scope.qualification);
                            $mdDialog.hide();
                        });
                        break;
                }
            };

            $scope.cancel = function() {
                $mdDialog.cancel();
            };
        }
    };
}
