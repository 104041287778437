import { GridReducer } from '../../../common/grid/grid.reducer';
// import {GridFilterReducer} from '../../../common/grid/filter/grid-filter.reducer';
import * as GRID from '../../../common/grid/grid.constants';
import * as FORM from '../../../common/form/input/input.constants';
import { TYPES } from './rep-monthly-action-type.constants';
import { IDS } from './rep-monthly-action-id.constants';

import get from 'lodash/get';
import set from 'lodash/set';

/**
 * @function RepMonthlyReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function RepMonthlyReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            details: {},
            failedOnly: 0, // fetch only head offices with failed sites
            runs: [],
            runHeadOffices: [],
            repMonthlyGrid: GridReducer(),
            // repMonthlyGrid: {
            //     ...GridReducer(),
            //     filter: {
            //         ...GridFilterReducer(),
            //         path:       IDS.repMonthlyGrid,
            //         hof_uuid:   null,
            //         mrp_id:     null,
            //     }
            // },
        };
    }

    switch (action.type) {
        case 'LOCATION_SELECTED':
            // if(action.id === IDS.repMonthly) { // select-cascade
            //     switch(action.path) {
            //         case IDS.repMonthlyGrid:
            //             if(!state.repMonthlyGrid.filter.mrp_id)
            //                 return state;// check if Month (run) is selected
            //
            //             state = set({...state}, action.path, GridReducer(state[action.path], set(action, 'type', 'PAGINATE')));
            //             return set(state, `${action.path}.filter.hof_uuid`, action.uuid);
            //         default:
            //     }
            // }
        case TYPES.FETCH_RUNS:
            if (action.id === IDS.repMonthly) {
                state = set({...state, }, 'repMonthlyGrid.filter.mrp_id', action.items.length ? action.items[0].mrp_id : null);
                set(state, 'repMonthlyGrid.reload', !get(state, 'repMonthlyGrid.reload'));
                return set(state, 'runs', action.items.map(item => ({
                    value: item.mrp_id,
                    label: `${item.date} ${item.rownum > 1? '('+item.rownum+')' : ''}`
                })))
            }
        case TYPES.FETCH_RUN_HOFS:
            if (action.id === IDS.repMonthly) {
                return {
                    ...state,
                    runHeadOffices: action.items.map(item => {
                        const completed_count = item.total_sites_count ? ` - completed (${item.completed_sites_count}/${item.total_sites_count})` : '';
                        const failed_count = item.failed_sites_count ? ` - failed (${item.failed_sites_count})` : '';
                        return {
                            value: item.hof_uuid,
                            label: `${item.hof_name}${completed_count}${failed_count}`,
                        }
                    }),
                }
            }
        case TYPES.SHOW_DETAILS:
            if (action.id === IDS.repMonthly) {
                return set({...state}, 'details', action.item)
            }
        case GRID.GRID_INIT:
        case GRID.GRID_FILTER:
        case GRID.GRID_FILTER_TOGGLE:
        case GRID.GRID_RESET_FILTER:
        case GRID.GRID_INIT_FILTER:
        case GRID.GRID_LOADING:
        case GRID.GRID_PAGINATE:
        case GRID.GRID_RELOAD:
        case GRID.GRID_FETCH:
        case GRID.GRID_SELECT:
        case GRID.GRID_SELECT_ALL:
        case GRID.GRID_DESELECT:
        case GRID.GRID_DESELECT_ALL:
            if(action.id === IDS.repMonthly) {
                state = set({...state}, action.path, GridReducer(get(state, action.path), action));
                return state;
            }

        case GRID.GRID_RESET:
            if (action.id === IDS.repMonthly) {
                state = {...state};
                if(action.path === IDS.repMonthlyGrid) {
                    // set(state, 'runHeadOffices', []);
                    set(state, 'failedOnly', 0);
                    set(state, 'repMonthlyGrid.reload', !get(state, 'repMonthlyGrid.reload'))
                }
                return set(state, action.path,
                    GridReducer( get(state, action.path),{
                        type: GRID.GRID_RESET_FILTER,
                        exclude: action.exclude || (action.path === IDS.repMonthlyGrid ? ['mrp_id'] : []),
                    })
                )
            }
        case FORM.INPUT_SELECT:
            if (action.id === IDS.repMonthly) {
                const component = action.path.split('.')[0];
                switch(component) {
                    case IDS.repMonthlyGrid:
                        return set({...state}, component,
                            GridReducer(get(state, component),{
                                type:GRID.GRID_FILTER,
                                filter: {
                                    [action.path.split('.').pop()]: action.input
                                }
                            })
                        )
                    default:
                        return state;
                }
            }
        case FORM.INPUT_TEXT:
        case FORM.INPUT_TEXT_AREA:
        case FORM.INPUT_CHECKBOX:
        case FORM.INPUT_DATE:
        case FORM.INPUT_RADIO:
            if (action.id === IDS.repMonthly) {
                state = set({...state}, action.path, action.input);
                if (action.path.startsWith('repMonthlyGrid.filter')) {
                    set(state, 'repMonthlyGrid.reload', !get(state, 'repMonthlyGrid.reload'))
                }
                return state
            }
        // case 'LOCATION_SELECTED': // slo select-cascade.js
            // if (action.id === IDS.reportingSettingsHeadOffices) {
            //     return set({...state}, 'headOffices.selectedHeadOfficeUuid', action.uuid)
            // }
            // if(action.id === IDS.repMonthlyGrid && action.type_code === 'slo') {
            //     state = set({...state},  `${IDS.assessmentGrid}.selectedLocationUuid`, action.uuid);
            //     if(get(state, `${IDS.repMonthlyGrid}.filterOpened`))
            //         state = set(state, `${IDS.assessmentGrid}.filter.slo_uuid`, action.uuid);
            //     return set( state, IDS.repMonthlyGrid, GridReducer(get(state, IDS.repMonthlyGrid),{ type:GRID.GRID_RELOAD }))
            // }
        default:
            return state;
        }
}
