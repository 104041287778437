import API from '../../../common/http/api.fetch';
import {success, responseMessage}   from '../../../common/utils/util.notifier';
import {GRID_FETCH} from '../../../common/grid/grid.constants';
import {TYPES} from './import-settings-action-type.constants'
import {IDS} from "./import-settings-action-id.constants";

export const fetchCentersListGrid = (filter) => {
    if (!filter || !filter.hof_uuid)
        return {foo:'bar'};

    return dispatch => {
        return API.fetch({
            url: '/employees/import-settings/list-cost-centers',
            method: 'GET',
            params: {
                ...filter,
            },
        }).then(({data}) => {
            dispatch({
                type:   GRID_FETCH,
                id:     IDS.empImportSettings,
                path:   IDS.empImportSettingsCentersListGrid,
                items:  data.items.map(item => ({...item,
                    auto_import_off: item.auto_import_disabled ? '✓':'',
                })),
                itemsCount: parseInt(data.items_count),
            })
        }, error => {
            responseMessage(error);
        })
    }
};

export const fetchCostCenterDetails = (slo_uuid) => {
    return dispatch => {
        return API.fetch({
            url:    '/employees/import-settings/cost-center-details',
            method: 'GET',
            params: { slo_uuid }
        }).then(response => {
            dispatch({
                type:   TYPES.FETCH_COST_CENTER_DETAILS,
                details:  response.data[0]
            });
            // return response.data.details
        }, error => {
            responseMessage(error);
        });
    };
}

export const addCostCenter = (data) => {
    return dispatch => {
        return API.fetch({
            url:    '/employees/import-settings/add-cost-center',
            method: 'POST',
            data,
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_ADDED');
        }, error => {
            responseMessage(error);
        })
    }
}

export const fetchOccupationsListGrid = (filter) => {
    return dispatch => {
        return API.fetch({
            url: '/employees/import-settings/list-occupations',
            method: 'GET',
            params: {
                ...filter,
                client_code: 'GX',
            },
        }).then(({data}) => {
            dispatch({
                type:   GRID_FETCH,
                id:     IDS.empImportSettings,
                path:   IDS.empImportSettingsOccupationsListGrid,
                items:  data.items,
                itemsCount: parseInt(data.items_count),
            })
        }, error => {
            responseMessage(error);
        })
    }
};

export const fetchUsersRelocatedListGrid = (filter) => {
    return dispatch => {
        return API.fetch({
            url: '/employees/import-settings/list-slo-replacements',
            method: 'GET',
            params: {
                ...filter,
            },
        }).then(({data}) => {
            dispatch({
                type:   GRID_FETCH,
                id:     IDS.empImportSettings,
                path:   IDS.empImportSettingsUsersRelocatedListGrid,
                items:  data.items.map(item => ({
                    ...item,
                    id: item.eis_id,
                    name: `${item.first_name} ${item.last_name}`,
                })),
                itemsCount: parseInt(data.items_count),
            })
        }, error => {
            responseMessage(error);
        })
    }
};

export const fetchUsersSkippedListGrid = (filter) => {
    return dispatch => {
        return API.fetch({
            url: '/employees/import-settings/list-skipped-employees',
            method: 'GET',
            params: {
                ...filter,
            },
        }).then(({data}) => {
            dispatch({
                type:   GRID_FETCH,
                id:     IDS.empImportSettings,
                path:   IDS.empImportSettingsUsersSkippedListGrid,
                items:  data.items.map(item => ({
                    ...item,
                    id: item.eis_id,
                    name: `${item.first_name} ${item.last_name}`,
                })),
                itemsCount: parseInt(data.items_count),
            })
        }, error => {
            responseMessage(error);
        })
    }
};