import { NOTIFICATION } from './util.constants';
import isUndefined from 'lodash/isUndefined';
import isInteger from 'lodash/isInteger';
import isString from 'lodash/isString';
import has from 'lodash/has';
import notus from 'notus';
import { ResponseStatusService } from '../http/response-status.service';
import I18N from '../../common/utils/util.i18n';

/* 1/2 FUNCTIONS EXTRACTED FROM CLASS */

/**
 * @method send
 * @param message {string}
 * @param type {string}
 * @param autoClose {boolean}
 */
const send = (message, type, autoClose) => {
    const elements = document.getElementsByClassName('notus');
    while(elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
    }

    const notusId = notus({
        notusType: 'snackbar',
        notusPosition: 'top',
        closable: false,
        // autoCloseDuration: autoClose,
    }).send({
        message: I18N.translate(message),
        alertType: type,
        autoClose: isUndefined(autoClose),
        // autoCloseDuration: autoClose,
    });
    if(Number.isInteger(autoClose) && autoClose > 3) { // if autoClose is greater than default
        setTimeout(() => {
            const notusElement =  document.getElementById(notusId);
            notusElement.parentNode.removeChild(notusElement);
        }, autoClose * 1000);

    }
}

/**
 * @method success
 * @param message {string}
 * @param autoClose {boolean}
 */
export const success = (message, autoClose) => {
    send(message, NOTIFICATION.SUCCESS, autoClose);
}

/**
 * @method info
 * @param message {string}
 * @param autoClose {boolean}
 */
export const info = (message, autoClose) => {
    send(message, NOTIFICATION.INFO, autoClose);
}

/**
 * @method warn
 * @param message {string}
 * @param autoClose {boolean}
 */
export const warn = (message, autoClose) => {
    send(message, NOTIFICATION.WARN, autoClose);
}

/**
 * @method error
 * @param message {string}
 * @param autoClose {boolean}
 */
export const error = (message, autoClose) => {
    send(message, NOTIFICATION.ERROR, autoClose);
}

/**
 * @method responseMessage
 * @param response {*}
 * @param message {string}
 */
export const responseMessage = (response, message = null) => {
    let responseStatus = isInteger(response) ? ResponseStatusService.find(response) : response;
    if (responseStatus) {
        if (has(responseStatus, 'data')) {
            let msg = isString(response.data) ? response.data : response.statusText;

            if(has(response, 'data.message_id')) {
                const key = `COMMON.SERVER_ERROR.${response.data.message_id}`;
                msg = I18N.translate(key);
                if(msg === key && has(response, 'data.message'))
                    msg = response.data.message;
            }

            responseStatus = {
                status: response.status,
                authorized: true,
                reject: false,
                autoNotify: false,
                type: NOTIFICATION.ERROR,
                msg: msg
            }
        }
        if (isString(message)) responseStatus.msg = message;
        // TODO special case conditions if any
        send(responseStatus.msg, responseStatus.type);
    }
}



/* 2/2 ORIGINAL CLASS */

/**
 * Creates a new Notifier.
 * @class
 */
export class Notifier {
    /**
     * @constructor
     * @param ResponseStatusService
     * @param $filter
     */
    constructor(ResponseStatusService, $filter) {
        this.ResponseStatusService = ResponseStatusService;
        this.$filter = $filter;
        this.notification = notus({
            notusType: 'snackbar',
            notusPosition: 'top',
            closable: false
        });
    }

    /**
     * @method send
     * @param message {string}
     * @param type {string}
     * @param type {boolean}
     * @memberOf Notifier
     */
    send(message, type, autoClose) {
        const elements = document.getElementsByClassName('notus');
        while(elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }

        this.notification.send({
            message: this.translate(message),
            alertType: type,
            autoClose: isUndefined(autoClose)
        });
    }

    /**
     * @method success
     * @param message {string}
     * @param message {boolean}
     * @memberOf Notifier
     */
    success(message, autoClose) {
        if(message === message.toUpperCase() && message.indexOf(' ') === -1)
            message = this.$filter('translate')(message);
        this.send(message, NOTIFICATION.SUCCESS, autoClose);
    }

    /**
     * @method info
     * @param message {string}
     * @param message {boolean}
     * @memberOf Notifier
     */
    info(message, autoClose) {
        this.send(message, NOTIFICATION.INFO, autoClose);
    }

    /**
     * @method warn
     * @param message {string}
     * @param message {boolean}
     * @memberOf Notifier
     */
    warn(message, autoClose) {
        this.send(message, NOTIFICATION.WARN, autoClose);
    }

    /**
     * @method error
     * @param message {string}
     * @param message {boolean}
     * @memberOf Notifier
     */
    error(message, autoClose) {
        this.send(message, NOTIFICATION.ERROR, autoClose);
    }

    elementMessage(selector, message, type, placement) {
        throw new Error('Remove usage of Notifier.elementMessage()');
    }

    /**
     * @method responseMessage
     * @param response {*}
     * @param message {string}
     * @memberOf Notifier
     */
    responseMessage(response, message) {
        let responseStatus = isInteger(response) ? this.ResponseStatusService.find(response) : response;
        if (responseStatus) {
            if (has(responseStatus, 'data')) {
                let msg = isString(response.data) ? response.data : response.statusText;

                if(has(response, 'data.message_id')) {
                    const key = `COMMON.SERVER_ERROR.${response.data.message_id}`;
                    msg = this.$filter('translate')(key);
                    if(msg === key && has(response, 'data.message'))
                        msg = response.data.message;
                }

                responseStatus = {
                    status: response.status,
                    authorized: true,
                    reject: false,
                    autoNotify: false,
                    type: NOTIFICATION.ERROR,
                    msg: msg
                }
            }
            if (isString(message)) responseStatus.msg = message;
            // TODO special case conditions if any
            this.send(responseStatus.msg, responseStatus.type);
        }
    }

    /**
     * @method autoNotify
     * @param status {*}
     * @memberOf Notifier
     */
    autoNotify(status) {
        const responseStatus = this.ResponseStatusService.find(status);
        if (responseStatus) {
            if (responseStatus.autoNotify)
                this.responseMessage(responseStatus);
        }
    }

    /**
     * @method checkRestResponse
     * @param status {*}
     * @param data {*}
     * @param customError {string}
     * @memberOf Notifier
     */
    checkRestResponse(status, data, customError) {
        if ((status === 200) && (data != null)) {
            return true;
        } else if (status === 400 && typeof data === 'string') {
            this.error(data);
        } else if (status === 403) {
            this.error('Insufficient access rights.');
        } else if (status === 404) {
            this.error('Document not found or non-existent.');
        } else if (status === 500) {
            this.error('Server error. Please contact administrator.');
        } else if (status !== 200) {
            this.error(customError);
        }
        return false;
    }

    translate(message){
        if(message.includes(' ') === false
        && message.includes('.')
        && message === message.toUpperCase())
            return this.$filter('translate')(message);
        else
            return message;
    }
}

Notifier.$inject = ['ResponseStatusService', '$filter'];
