/* @ngInject */
export function EmployeesManageController(
    $controller,
    $ngRedux,
    $scope,
    $rootScope,
    $q,
    $timeout,
    $mdDialog,
    AccountService,
    NavigationService,
    Employee,
    EmployeeService,
    EmployeeManager,
    EmployeeQualificationService,
    EmployeeModalityTrainingService,
    LicenceService,
    LicenceManager,
    Licence,
    AuthService,
    Notifier,
    Grid,
    EmployeeQualification,
    EmployeeModalityTraining,
    Location,
    COUNTRY_SELECT_EVENT,
    GENDERS,
    $state,
    $uiRouterGlobals,
    $filter,
    DocumentManager
) {
    angular.extend(this, $controller('EmployeesBaseController', {
        $ngRedux: $ngRedux,
        $scope: $scope,
        $rootScope: $rootScope,
        $q: $q,
        $timeout: $timeout,
        $mdDialog: $mdDialog,
        AuthService: AuthService,
        NavigationService: NavigationService,
        EmployeeService: EmployeeService,
        EmployeeQualificationService: EmployeeQualificationService,
        EmployeeModalityTrainingService: EmployeeModalityTrainingService,
        EmployeeManager: EmployeeManager,
        LicenceService: LicenceService,
        LicenceManager: LicenceManager,
        Notifier: Notifier,
        AccountService: AccountService,
        Grid: Grid,
        Employee: Employee,
        Licence: Licence,
        EmployeeQualification: EmployeeQualification,
        EmployeeModalityTraining: EmployeeModalityTraining,
        Location: Location,
        COUNTRY_SELECT_EVENT: COUNTRY_SELECT_EVENT,
        GENDERS: GENDERS
    }));

    $scope.MODULE_STATE = $scope.MODULE_STATE_UPDATE;
    $scope.EMPLOYEE_STATE = $scope.MODULE_STATE_UPDATE;
    $scope.userType = $scope.MODULE_STATE_UPDATE;
    $scope.tabId = $uiRouterGlobals.params.tab;

    // TODO not needed?
    $scope.userStatus = true;

    $scope.userData = AuthService.getUser();
    $scope.isAdmin = $scope.userData.type == 'Administrator';

    if ($scope.userData.parentCount === 1) {
        if ($scope.userData.parents[0].basicType === 'slo' || $scope.userData.parents[0].basicType === 'dep') {
            $ngRedux.dispatch({
                type: 'CHECK_LOCATION',
                id: 'employeesGrid',
                checkLocation: false
            });
        }
    }

    if ($scope.userData.parentCount > 1) {
        if ($scope.userData.parents[0].basicType === 'dep') {
            $ngRedux.dispatch({
                type: 'CHECK_LOCATION',
                id: 'employeesGrid',
                checkLocation: false
            });
        }
    }

    $scope.tabs = _.cloneDeep(EmployeeManager.tabs);

    $scope.onEmployeeSelected = function(event) {
        _.assign($scope.employee, event.item);
        $scope.executeTab();
    };

    $scope.canResetPassword =   $scope.userData.funcs && $scope.userData.funcs.includes('pasres');
    $scope.isAdmin =            $scope.userData.type === 'Administrator';

    // OVERRIDE Alerts & Warnings
    $scope.onTabSelected = function(tab) {
        $scope.selectedTab = tab;
        if ($uiRouterGlobals.params.uuid) {
            _.assign($scope.employee, {
                uuid: $uiRouterGlobals.params.uuid,
                location: {uuid: undefined}
            });
            $uiRouterGlobals.params.uuid = null;
            $uiRouterGlobals.params.tab = null;
            window.history.replaceState(null, null, window.location.pathname);
            $scope.executeTab();
        }
    };

    $scope.$watch('selectedTab', (function(newTab) {
        if (!newTab) {
            this.disableTabs();
        }
    }).bind(this));

    $scope.resendLoginInfoDialog = function() {
        $mdDialog.show({
            controller: resendLoginInfoController,
            template: require('../views/resend-login-info-dialog.html'),
            locals: {
                title: 'Resend Login Info',
                employeeUuid: $scope.employee.uuid
            },
            clickOutsideToClose: false
        });
    };

    function resendLoginInfoController($scope, EmployeeService, title, employeeUuid) {
        $scope.headerColor = NavigationService.getNavConfig().colorName;
        $scope.title = title;

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.resendLoginInfo = function() {
            if (($scope.inputUsername && $scope.newUsername) || !$scope.inputUsername) {
                EmployeeService.resendLoginInfo(employeeUuid, $scope.newUsername).then(function () {
                    $mdDialog.hide();
                    $ngRedux.dispatch({
                        type: 'GRID_RELOAD',
                        id: 'employeesGrid'
                    });
                    Notifier.success('Login info is successfully sent');
                });
            } else {
                Notifier.error('Please input username');
            }
        }
    }

    _.extend(_.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEES]), {
        exec: (function() {
            _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_DOSIMETRY_BADGES]).visible = false;
            _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_TRAININGS]).visible = false;
            $rootScope.$broadcast('SELECT_CASCADE_RESET', _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_DETAILS]).form.$name);
            var deferred = $q.defer();
            $scope.employee.locations = null;
            EmployeeService.fetchEmployeeDetails($scope.employee.uuid, $scope.employee.location.uuid).then((function(employee) {
                _.assign($scope.employee, employee);
                _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_LICENCES]).visible = true;
                _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_DOSIMETRY_BADGES]).visible = true;
                _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_TRAININGS]).visible = true;

                if ($scope.employee.occupation.ocuCode === 'res') {
                    $scope.showPrincipalInvestigator = true;
                }

                LicenceService.getLicenceTypes($scope.employee.uuid, 'uli').then(function(items) {
                    $scope.documentTypes = items;
                });
                EmployeeService.getOccupations($scope.employee.uuid, 'emp').then(function(items) {
                    $scope.occupations = items;
                });

                $scope.displayAddLocationComponent = false;
                $scope.mutable = false;
                if ($scope.employee.mutable === 1) {
                    $scope.displayAddLocationComponent = true;
                    $scope.mutable = true;
                }

                $scope.licence = new Licence();
                $ngRedux.dispatch({
                    type: 'EMPLOYEE_SELECTED',
                    id: 'employeesGrid',
                    uuid: $scope.employee.uuid
                });
                $scope.isServiceEngineer.status = EmployeeManager.OCCUPATION_ENGINEER == $scope.employee.occupation.ocuCode;
                _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_QUALIFICATIONS]).visible = true;
                _.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_MODALITY_TRAININGS]).visible = true;
                EmployeeQualificationService.getQualifications($scope.employee.uuid, 0).then(function(qualifications) {
                    $scope.qualifications = qualifications;
                });
                EmployeeQualificationService.getQualifications($scope.employee.uuid, 1).then(function(qualifications) {
                    $scope.employee.qualifications = qualifications;
                    $scope.gridQualifications.items = $scope.employee.qualifications;
                });
                EmployeeModalityTrainingService.getTrainings($scope.employee.uuid).then(function(trainings) {
                    $scope.employee.trainings = trainings;
                    $scope.gridTrainings.items = $scope.employee.trainings;
                });
                EmployeeModalityTrainingService.getTrainingCategories($scope.employee.uuid).then(function(data) {
                    $scope.trainingCategories = data.categories;
                });

                EmployeeService.getLicensesRequiredByOccupation($scope.employee.occupation.id).then(data => {
                    $scope.employee.showAsarLicenceRequired = !!data.asar_licence_required;
                });

                this.enableTabs();
                $scope.tabId ? deferred.resolve(parseInt($scope.tabId)) : deferred.resolve();
            }).bind(this));
            return deferred.promise;
        }).bind(this)
    });

    _.extend(_.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_DETAILS]), {
        exec: function() {
            var deferred = $q.defer();
            EmployeeService.updateEmployee($scope.employee).then(function() {
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'employeesGrid'
                });
                Notifier.success($filter('translate')('EMPLOYEES.MANAGE.NOTIFIER_SUCCESS'));
                deferred.resolve();
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_QUALIFICATIONS]), {
        exec: (function() {
            var deferred = $q.defer();
            this.assignQualification().then(function() {
                deferred.resolve();
            });
            return deferred.promise;
        }).bind(this)
    });

    _.extend(_.find($scope.tabs, ['id', EmployeeManager.TAB_EMPLOYEE_LOCATIONS]), {
        exec: (function() {
            var deferred = $q.defer();
            this.addEmploymentLocation().then(function() {
                deferred.resolve();
            }, function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }).bind(this)
    });

    $scope.onSetLocationStatus = function(event) {
        $mdDialog.show({
            controller: setEmployeeLocationStatusController,
            template: require('../views/employee-location-status-dialog.html'),
            locals: {
                title: 'Set Employee Status',
                employeeUuid: event.item.employeeUuid,
                locationUuid: event.item.uuid
            },
            clickOutsideToClose: false
        });
    };

    function setEmployeeLocationStatusController($scope, EmployeeService, title, employeeUuid, locationUuid) {
        $scope.headerColor = NavigationService.getNavConfig().colorName;
        $scope.title = title;

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.setEmployeeLocationSatus = function() {
            if (($scope.item.pendingDateTo && $scope.item.employeeStatus === 'pen') || $scope.item.employeeStatus !== 'pen') {
                EmployeeService.setEmployeeLocationStatus(employeeUuid, locationUuid, $scope.item.pendingDateTo, $scope.item.employeeStatus).then(function () {
                    $mdDialog.hide();
                    $ngRedux.dispatch({
                        type: 'GRID_RELOAD',
                        id: 'employeesGrid'
                    });
                    $ngRedux.dispatch({
                        type: 'GRID_RELOAD',
                        id: 'employeeLocationsGrid'
                    });
                    Notifier.success('Employee Status is changed successfully');
                });
            } else {
                Notifier.error('Please input pending until date');
            }
        }
    }
}
