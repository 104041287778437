import { IDS } from '../import-action-id.constants';
import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import * as ImportActions from '../import.actions';
import watch from "redux-watch";

class HistoryGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter, $mdDialog, $scope, $rootScope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
        this.$rootScope = $rootScope;
    }

    $onInit() {
        this.module = 'employees';
        this.identifier = IDS.empImport;
        this.statePath = IDS.empImportHistoryListGrid;
        this.filterTemplate = './history-grid-filter.html';
        this.exportComponent = false;
        this.title = false;
        this.icon = false;
        this.confirmDialogOnRemove = true;
        //this.autoload = false;
        this.filterOpen = true;
        this.filterExpandButtonVisible = false;

        this.columns = [
            {title: '#',            mapper: null},
            {title: 'Id',           mapper: 'id'},
            {title: 'Date',         mapper: 'started'},
            {title: 'Time',         mapper: 'time'},
            {title: 'Site Location',mapper: 'site_location_name'},
            // {title: 'Duration',    mapper: 'finished'},
            {title: 'Issuer',       mapper: 'issuer'},
            //{title: 'Source',       mapper: 'source'},
            //{title: 'Records',      mapper: 'total_records'},
            {title: 'Records',        mapper: 'found_records'},
            {title: 'Mode',         mapper: 'append_mode'},

            {title: 'Status',       mapper: 'status_name'},
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), ImportActions)(this);

        const $rootScope = this.$rootScope;
        const watchReset = watch(this.$ngRedux.getState, `${this.module}.${this.identifier}.${this.statePath}.reset`);
        this.unsubscribeOnReset = this.$ngRedux.subscribe(watchReset(function() {
            $rootScope.$broadcast('SELECT_CASCADE_RESET', 'empImportHistoryGridFilterForm');
        }));

        this.dataAction = this.fetchImportHistoryListGrid;
        this.init();
    }

    $onDestroy() {
        if(this.unsubscribeOnReset)
            this.unsubscribeOnReset()

        this.destroy();
    }

}

HistoryGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter', '$mdDialog', '$scope', '$rootScope'];

export const empImportHistoryListGrid = {
    bindings: {
        onSelect: '&',
        title: '@'
    },
    controller: HistoryGridController,
    ...gridTpl
}
