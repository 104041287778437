/* @ngInject */
export function ManufacturerTypeActionsFactory(ManufacturerService) {
    return {
        getManufacturerTypes: function() {
            return function(dispatch) {
                return ManufacturerService.fetchTypes().then(function(data){
                    dispatch({
                        type: 'MANUFACTURER_TYPES_FETCH',
                        id: 'app.administration.manufacturers',
                        equipmentTypes: data,
                    });
                });
            };
        },
    };
}
