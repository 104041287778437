import { SUCCESS, SERVER_ERROR } from './response-status.constants';
import { EVENT } from '../utils/util.constants';
import has from 'lodash/has';

export function ResponseErrorInterceptor($rootScope, $q, ResponseStatusService, Logger, Notifier) {
    return {
        responseError: function(response) {
            if (has(response, 'data.exception')) {
                Notifier.responseMessage(SERVER_ERROR);
            }
            if (!ResponseStatusService.isAuthorized(response)) {
                $rootScope.$broadcast(EVENT.UNAUTHORIZED);
            }
            if (response.status !== SUCCESS.status)
                Logger.log(response);
            else if (has(response, 'data.status')) {
                if (ResponseStatusService.find(response.data.status))
                    Logger.log(response);
            }
            return $q.reject(response);
        }
    }
}

ResponseErrorInterceptor.$inject = ['$rootScope', '$q', 'ResponseStatusService', 'Logger', 'Notifier'];
