/**
 * @class
 */
class AdministrationAccountsSiteLocationModulesController {
    /**
     * @constructor
     * @param $ngRedux {service}
     * @param $state {service}
     * @param $filter {service}
     * @param AdministrationAccountsActions {service}
     */
    constructor($ngRedux, $state, $filter, AdministrationAccountsActions) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.actions = AdministrationAccountsActions;
        this.titlePostfix = $filter('translate')('ADMINISTRATION.ACCOUNTS.ACTIVE_MODULES');
    }

    /**
     * @method $onInit
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);
    }

    /**
     * @method onSubmit
     */
    onSubmit() {
        const activeModules = this.activeModules.map(item => {return {
            id: item.id,
            active: item.active,
        }});
        this.updateActiveModules(this.uuid, activeModules);
    }

    /**
     * @method $onDestroy
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.unsubscribe();
    }

    /**
     * @method mapState
     * @param state {object} Redux Store
     * @return {object}
     * @description Subscribe to Redux store updates
     */
    mapState(state) {
        return {
            title: `${state.administration.administrationAccountsSiteLocation.data.name} » ${this.titlePostfix}`,
            activeModules: state.administration.administrationAccountsSiteLocationActiveModules,
            uuid: state.administration.administrationAccountsSiteLocation.data.uuid
        }
    }
}

AdministrationAccountsSiteLocationModulesController.$inject = ['$ngRedux', '$state', '$filter', 'AdministrationAccountsActions'];

export const administrationAccountsSiteLocationModules = {
    controller: AdministrationAccountsSiteLocationModulesController,
    template: `
        <tabs-header title="{{$ctrl.title}}"></tabs-header>
        
        <form id="detailsForm" name="$ctrl.detailsForm" style="margin-bottom: 15px;" novalidate>
            <div layout="column">
                <md-divider></md-divider>
                <md-content flex>
                    <section>
                        <input-checkbox-list
                                identifier="administrationAccountsSiteLocationActiveModules"
                                property-name="active"
                                columns-count="3">
                        </input-checkbox-list>
                    </section>
                </md-content>
                <md-divider></md-divider>
            </div>
        </form>
        <tabs-navigation identifier="app.administration.accounts.siteLocations"
                         state="app.administration.accounts.siteLocations.modules"
                         on-exec="$ctrl.onSubmit()">
        </tabs-navigation>`
};
