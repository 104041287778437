import {
    saveVendorInfo,
    approveRequest,
    rejectRequest,
} from '../cre-vendors.actions';

/**
 * @const {object}
 * @memberOf module:Credentialing
 */
export const creVendorsDetails = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $location, $mdDialog, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.title = 'Vendor Information';
            this.unsubscribe = $ngRedux.connect(({ credentialing }) => ({
                creInvite: credentialing.creInvite,
                creVendor: credentialing.creVendor,
            }), {
                saveVendorInfo,
                approveRequest,
                rejectRequest,
            })(this);
            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.details`;
            this.token = $location.search().token;
        }

        this.onApprove = () => {
            if (this.creVendorDetailsForm.$valid) {
                const confirm = $mdDialog.confirm()
                    .title('Are you sure?')
                    .textContent(`Approve ${this.creInvite.vendor_name} application.`)
                    .ariaLabel('Approve Application')
                    .ok('Yes')
                    .cancel('No');
                this.dialog = $mdDialog.show(confirm).then(() => {
                    this.approveRequest({
                        purpose: 'venapp',
                        application_id: this.creVendor.application_id,
                        reviewer_signature: this.creInvite.approval_signature,
                    }).then(() => {
                        Notifier.success('Vendor application approved!');
                    });
                }, angular.noop);
            }
        };

        this.onReject = () => {
            if (this.creVendorDetailsForm.$valid) {
                const confirm = $mdDialog.confirm()
                    .title('Are you sure?')
                    .textContent(`Reject ${this.creInvite.vendor_name} application.`)
                    .ariaLabel('Reject Application')
                    .ok('Yes')
                    .cancel('No');
                this.dialog = $mdDialog.show(confirm).then(() => {
                    this.rejectRequest({
                        purpose: 'venapp',
                        application_id: this.creVendor.application_id,
                        reviewer_signature: this.creInvite.approval_signature,
                    }).then(() => {
                        Notifier.error('Vendor application rejected!');
                    });
                }, angular.noop);
            }
        };

        this.onSave = () => {
            if (this.creVendorDetailsForm.$valid) {
                this.saveVendorInfo({
                    application_id: this.creVendor.application_id,
                    app_type: this.creInvite.type,
                    healthcare: this.creInvite.supplyHealthcare,
                    non_healthcare: this.creInvite.supplyNonHealthcare,
                    account_number: this.creInvite.acn_num,
                    abn_number: this.creInvite.abn_num,
                    gst_reg_status: this.creInvite.gst,
                    billing_address: this.creInvite.address_billing,
                    delivery_address: this.creInvite.address_delivery,
                }).then(() => {
                    Notifier.success('Vendor Information has been successfully saved');
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
        }

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./cre-vendors-details.html')
};
