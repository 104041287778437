import { MANUFACTURER_RESET } from '../manufacturers.actions.types';

export const manufacturerDetails = {
    /* @ngInject */
    controller: function ($ngRedux, $state, ManufacturersActions, Notifier) {
        /**
        * @function $onInit
        */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(({ manufacturers }) => ({
                manufacturer: manufacturers.manufacturer,
                manufacturerTypesSelected: manufacturers.manufacturerTypesSelected,
            }), ManufacturersActions)(this);

            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.details`;

            if (this.identifier === 'app.administration.manufacturers.add') {
                this.title = 'Add Manufacturer';
                this.getManufacturerTypes();
            } else {
                this.title = 'Modify Manufacturer';
            }
        };

        /**
        * @function manageManufacturer
        */
        this.manageManufacturer = () => {
            if (this.manufacturerForm.$valid) {
                if (this.manufacturer.id) {
                    this.updateManufacturer(this.manufacturer, this.manufacturerTypesSelected);
                } else {
                    this.addManufacturer(this.manufacturer, this.manufacturerTypesSelected).then(() => {
                        this.reset();
                    });
                }
            } else {
                Notifier.error('Please fill in the form fields');
            }
        };

        /**
         * @function onUploadSave
         * @param {Object} event
         */
        this.onUploadSave = (event) => {
            Notifier.info('Please wait...');
            // TODO
        };

        /**
         * @function reset
         */
        this.reset = () => {
            $ngRedux.dispatch({
                type: MANUFACTURER_RESET,
                id: 'manufacturer',
            });
        };

        /**
        * @function $onDestroy
        */
        this.$onDestroy = () => {
            this.unsubscribe();
            this.reset();
        };
    },
    template: require('./manufacturer-details.html'),
};
