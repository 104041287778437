import watch from 'redux-watch';
import isNull from 'lodash/isNull';
import find from 'lodash/find';
import isString from 'lodash/isString';

/**
 * Creates a new AdministrationAccountsSiteLocationContactDetailsController.
 * @class
 * @memberOf module:root/administration/AdministrationAccountsSiteLocationContactDetailsController
 */
class AdministrationAccountsSiteLocationContactDetailsController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param $uiRouterGlobals {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/core.uirouterglobals.html|UI-Router UIRouterGlobals}
     * @param Notifier {service} {@link Notifier}
     * @param AdministrationAccountsActions {service}
     */
    constructor($ngRedux, $state, $uiRouterGlobals, AdministrationAccountsActions, Notifier) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.actions = AdministrationAccountsActions;
        this.Notifier = Notifier;
    }

    /**
     * @method $onInit
     * @memberOf module:root/components/administration.AdministrationAccountsSiteLocationContactDetailsController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.identifier = 'administrationAccountsSiteLocationContact';
        this.checkboxFlex = 25;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);
        this.contactDetails = !isNull(this.contact.uuid);
        this.grantAccess = false;

        this.getManagerTypesList(this.identifier, this.stateParams.slo);
        this.getContactCandidates(this.identifier, this.stateParams.slo);

        var watchContact = watch(this.$ngRedux.getState, 'administration.administrationAccountsSiteLocationContact.data.uuid');
        this.onContact = this.$ngRedux.subscribe(watchContact(uuid => {
            this.contactDetails = !isNull(uuid);
        }));
    }

    /**
     * @method onContactDetails
     * @param id {number}
     * @memberOf module:root/components/administration.AdministrationAccountsSiteLocationContactDetailsController
     */
    onContactDetails() {
        this.administrationAccountsSiteLocationContact.$setPristine();
        this.administrationAccountsSiteLocationContact.$setUntouched();
        this.checkboxFlex = this.contactDetails ? 50 : 25;
    }

    /**
     * @method manageContact
     * @param id {number}
     * @memberOf module:root/components/administration.AdministrationAccountsSiteLocationContactDetailsController
     */
    manageContact() {
        if (this.administrationAccountsSiteLocationContact.$valid) {
            if (this.contact.uuid) {
                this.updateContactDetails('administrationAccountsSiteLocationContact', this.contact, 'administrationAccountsSiteLocationContactsGrid');
            }
            else {
                if (this.contactDetails)
                    this.createContactDetails(this.contact, this.$uiRouterGlobals.params.slo, false, 'administrationAccountsSiteLocationContact', 'administrationAccountsSiteLocationContactsGrid');
                else
                    this.assignContact(this.contactCandidate, this.$uiRouterGlobals.params.slo, 'administrationAccountsSiteLocationContact', 'administrationAccountsSiteLocationContactsGrid');
            }
        }
        else this.Notifier.error('Please fill in the form fields');
    }

    /**
     * @method $onDestroy
     * @memberof module:root/components/administration.AdministrationAccountsSiteLocationContactDetailsController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.unsubscribe();
        this.onContact();
    }

    /**
     * @method mapState
     * @param state {object} Redux Store
     * @return {object}
     * @memberof module:root/components/administration.AdministrationAccountsSiteLocationContactDetailsController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapState(state) {
        return {
            contact: state.administration.administrationAccountsSiteLocationContact.data,
            contactCandidate: state.administration.administrationAccountsSiteLocationContact.candidate,
            stateParams: state.administration['app.administration.accounts.siteLocations'].stateParams,
            loading: find(state.administration['app.administration.accounts.siteLocations'].tabs, ['state', 'app.administration.accounts.siteLocations.contactDetails']).loading,
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                state.administration.administrationAccountsSiteLocationContact.title
            ].filter(function(item) {
                return isString(item);
            }).join(' » ')
        };
    }
}

AdministrationAccountsSiteLocationContactDetailsController.$inject = ['$ngRedux', '$state', '$uiRouterGlobals', 'AdministrationAccountsActions', 'Notifier'];

export const administrationAccountsSiteLocationContactDetails = {
    controller: AdministrationAccountsSiteLocationContactDetailsController,
    template: require('./administration-accounts-site-location-contact-details.html')
};
