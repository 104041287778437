import {TabsReducer} from '../../../../common/tabs/tabs.reducer';
import {IDS} from '../audit-action-id.constants';

export const scheduleTabs = {
    [IDS.app_accreditation_scheme_audit_schedule]: Object.assign(TabsReducer(), {
    tabs: [
        {
            state: IDS.app_accreditation_scheme_audit_schedule_table,
            title: 'ACCREDITATION.AUDIT.SCHEDULE.TAB',
            visible: true,
            disabled: false,
            loading: false
        },
        {
            state: IDS.app_accreditation_scheme_audit_schedule_details,
            title: 'ACCREDITATION.AUDIT.DETAILS.TAB',
            visible: false,
            disabled: false,
            loading: false
        },
    ]
    }),
};
