/** @module root/components/accreditation/document */

import * as STATES                      from './assessment-reports.states';
import { IDS }                          from './assessment-reports-action-id.constants';
import { accAssessmentReportDetails }   from './details/details.component';
import { accAssessmentReportAdd }       from './add/add.component';
import { accAssessmentReports }         from './list/list.component';
import { accAssessmentReportsGrid }     from './list/list-grid.component';

export const ASSESSMENT_REPORTS_MODULE = angular.module(
    'migration.accreditation.assessment-reports',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
        Object.values({
            ...STATES,
        }).map(state => stateRegistry.register(state));
    }]
).component({
    accAssessmentReportDetails,
    accAssessmentReportAdd,

    accAssessmentReports,
    accAssessmentReportsGrid,

}).run(['$templateCache', ({ put }) => {
    put(IDS.app_accreditation_scheme_assessment_reports_list,     '<acc-assessment-reports></acc-assessment-reports>');
    put(IDS.app_accreditation_scheme_assessment_reports_details,  '<acc-assessment-report-details></acc-assessment-report-details>');
    put(IDS.app_accreditation_scheme_assessment_reports_add,      '<acc-assessment-report-add></acc-assessment-report-add>');
    put('./list-grid-filter.html',  require('./list/list-grid-filter.html'));
}]);
