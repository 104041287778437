export const RSCFactory = () => {
    return function RSC() {
        this.username           = '';
        this.password           = '';
        this.password_verify    = '';
        this.first_name         = '';
        this.last_name          = '';
        this.birthday           = null;
        this.email              = '';
        this.phone              = '';
        this.fax                = '';
        this.contract_date      = '';
        this.status             = 'act';
        this.is_assistant       = false;
        this.uuid               = null;
        this.parent             = null;
    };
}
