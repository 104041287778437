export const verifyAccess = () => {
    const user = JSON.parse(localStorage.getItem('myrsoUser'));
    return [
        'adm',
        'hrs',
        'srs',
        'drs',
        'asr',
        'sro',
        'aso',
        'rsc',
        'dcr',
        'rst',
        'gen',
        'hqm',
        'apa',
        'ars',
        'hlo',
        'sqm',
        'aso',
        'prm',
        'prr',
        'arm',
        'clm',
        'num',
        'pos',
        'rma',
        'slc',
        'whc',
        'who',
        'rep',
        'lso',
        'hop',
        'scm',
        'spc'
    ].includes(user?.typeCode);
};

export const resolveTpl = (tpl) => {
    return verifyAccess() ? tpl : 'pd-protected';
};
