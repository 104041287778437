import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import get from 'lodash/get';

class SystemAlertsGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, $uiRouterGlobals, NavigationService, SystemAlertsActions) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.SystemAlertsActions = SystemAlertsActions;
    }

    $onInit() {
        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        this.filterTemplate = get(this.config, 'filter', './system-alerts-grid-filter.html');

        if (this.config && this.config.hasOwnProperty('columns')) {
            this.columns = this.config.columns;
        } else {
            this.columns = [
                {title: '#',    mapper: null},
                {title: '',     mapper: 'alert'},
            ];

            switch (this.module) {
                case 'equipment':
                    this.columns.push({title: 'Equipment ID',         mapper: 'equipmentUserId'});
                    this.columns.push({title: 'Equipment Serial',     mapper: 'equipmentSerial'});
                    this.columns.push({title: 'Equipment Category',   mapper: 'equipmentCategory'});
                    this.columns.push({title: 'Location',           mapper: 'location'});
                    break;
                case 'employees':
                    this.columns.push({title: 'First Name',   mapper: 'employeeFirstName'});
                    this.columns.push({title: 'Last Name',    mapper: 'employeeLastName'});
                    this.columns.push({title: 'Username',     mapper: 'employeeUsername'});
                    break;
                case 'myrso':
                    this.columns.push({title: 'Description', mapper: 'description'});
                    break;
            }
            this.columns.push({title: 'Document Num',       mapper: 'docNum'});
            this.columns.push({title: 'Document Type',      mapper: 'docType'});
            this.columns.push({title: 'Document Due Date',  mapper: 'docDueDate'});
        }

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.SystemAlertsActions)(this);
        this.dataAction = this.getAlerts;
        this.exportService = this.getAlerts;
        this.filter.identifier = this.identifier;
        this.filter.moduleId = this.moduleId;
        this.filter.alertType = this.$uiRouterGlobals.params.type;

        this.init();
    }

    $onDestroy() {
        this.unsubscribeModule();
        this.destroy();
    };

    mapModule(state) {
        return {
            module: state.navigation.module
        }
    }
}

export const systemAlertsGrid = {
    bindings: {
        identifier: '@',
        moduleId: '<',
        icon: '@',
        title: '@',
        config: '<',
        onSelect: '&'
    },
    controller: SystemAlertsGridController,
    ...gridTpl
};
