/* @ngInject */
export function SafetyPlanWatchPresentationsController($scope, $rootScope, $q, $state, $location, SafetyPlanReview, SafetyPlanService, $mdDialog, Notifier, $timeout, NetSpeedService, SAFETY_PLAN_REVIEW_NET_SPEED, DocumentManager) {

    $scope.reviewVideos = null;
    $scope.selectedVideo = null;
    $scope.initializationInProgress = true;

    $scope.fetchReviewData = function() {
        $scope.cursorState = 'wait';
        SafetyPlanService.getPresentationVideos()
            .then(function(items) {
                //  console.log("Presentation videos retrieved!");
                $scope.reviewVideos = items.list;
                if ($scope.reviewVideos.length > 0) {
                    $scope.selectedVideo = $scope.reviewVideos[0];
                }
                $scope.initializationInProgress = false;
                $scope.cursorState = 'default';
            }, function(error) {
                $scope.selectedVideo = null;
                $scope.scheduledVideo = null;
                $scope.reviewVideos = null;
                Notifier.error("There was a problem with server access. Unable to fetch Safety Plan Presentation!");
                $scope.initializationInProgress = false;
                $scope.cursorState = 'default';
            });
    };

    $scope.videoPlaybackEnded = function (video) {
        //console.log("Video with uri="+video.uri+" and videoSectionNumber="+video.videoSectionNumber+"playback has ended!");
        var maxVideoPresentationOrder = $scope.reviewVideos.reduce(function (max, item) {
            return parseInt(item.presentationOrder) > max ? parseInt(item.presentationOrder) : max;
        }, 0);

        if (video.presentationOrder == maxVideoPresentationOrder) {
            //show confirmation dialog
            $scope.onRscSafetyPlanReviewConfirmation(video);
        }
    };

    $scope.checkNetSpeed = function() {
        NetSpeedService.testNetSpeed()
            .then(function(result) {
                Notifier.info("Measured net speed is:" + result + " Kbps.");
                console.log("Measured net speed is:" + result + " kbs.");
                if (result < SAFETY_PLAN_REVIEW_NET_SPEED.BANDWIDTH_LIMIT) {
                    Notifier.info("Your network connection seems to be slow. You may experience difficulties watching presentation video. In case of video stuttering please pause playback and wait a bit for video to buffer, then continue.");
                }
            }, function(error) {
                console.log("Could not measure speed, error:" + error);
            });
    };

    $scope.onRscSafetyPlanReviewConfirmation = function (item) {
        showRscSafetyPlanReviewConfirmationDialog($scope, RscSafetyPlanReviewConfirmation, {
            color: $state.current.trainingColor,
            title: 'SafetyPlan Review Acknowledgement',
            video: item,
            rscConfirmation: true
        });
    };

    function showRscSafetyPlanReviewConfirmationDialog(scope, ctrl, data) {
        $mdDialog.show({
            controller: ctrl,
            templateUrl: './safety-plan-review-confirmation-dialog.html',
            scope: scope, // use parent scope in template
            preserveScope: true,
            locals: data,
            clickOutsideToClose: false
        });
    }

    function RscSafetyPlanReviewConfirmation($scope, $mdDialog, color, title, video, rscConfirmation) {
        $scope.headerColor = color;
        $scope.headerTitle = title;
        $scope.rscConfirmation = rscConfirmation;

        $scope.cancel = function() {
            $timeout(function() {
                $scope.fetchReviewData();
            }, 200);
            $mdDialog.cancel();
        };

        $scope.submit = function(subject, message) {

            $mdDialog.hide();

            $timeout(function() {
                showRscSafetyPlanReviewGetCertificateDialog($scope, RscSafetyPlanReviewGetCertificate, {
                    color: $state.current.trainingColor,
                    title: 'Safety Plan Download Certificate For RSC',
                    finishedReviewId: null
                });

            }, 200);

        };
    }

    function showRscSafetyPlanReviewGetCertificateDialog(scope, ctrl, data) {
        $mdDialog.show({
            controller: ctrl,
            templateUrl: './safety-plan-review-download-certificate-dialog.html',
            scope: scope, // use parent scope in template
            preserveScope: true,
            locals: data,
            clickOutsideToClose: false
        });
    }

    function RscSafetyPlanReviewGetCertificate($scope, $mdDialog, color, title, finishedReviewId) {
        $scope.headerColor = color;
        $scope.headerTitle = title;

        $scope.cancelDownload = function() {
            $timeout(function() {
                $scope.fetchReviewData();
            }, 200);
            $mdDialog.cancel();
        };

        $scope.download = function() {
            SafetyPlanService.generateCertificate(finishedReviewId, true).then(function(data) {
                //console.log("Certificate for review="+finishedReviewId+" generated:"+data.certificatePdf.data.path);
                DocumentManager.downloadFile("/"+data.certificatePdf.data.path, 3);
            }, function(error){
                Notifier.error("Error generating certificate!");
            });

            $timeout(function() {
                $scope.fetchReviewData();
            }, 200);
            $mdDialog.hide();
        };
    }

    $scope.checkNetSpeed();
    $scope.fetchReviewData();
}
