import {TAB_HIDE, TAB_DISABLE} from '../../../common/tabs/tabs.constants';
import {IDS} from './audit-action-id.constants';

const QUERY_PARAMS = '?std&year&slo';

export const abstractState = {
    name: 'app.accreditation.scheme.audit',
    abstract: true,
    url: '/:itemCode',
};

export const dashboardState = {
    name: 'app.accreditation.scheme.audit.dashboard',
    url: '/audit',
    component: 'dashboard',
};

export const auditAddState = {
    name: IDS.app_accreditation_scheme_audit_add,
    url: `/add-audit${QUERY_PARAMS}`,
    component: 'accAuditAdd',
}

export const scheduleTabsState = {
    name: IDS.app_accreditation_scheme_audit_schedule,
    redirectTo: IDS.app_accreditation_scheme_audit_schedule_table,
    url: `/audit-schedule${QUERY_PARAMS}`,
    component: 'tabs',
}

export const tabsScheduleTableState = {
    name: IDS.app_accreditation_scheme_audit_schedule_table,
    url: '/table',
    component: 'accAuditSchedule',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: IDS.app_accreditation_scheme_audit_schedule,
            state: [
                IDS.app_accreditation_scheme_audit_schedule_details,
            ]
        });
    }
};
tabsScheduleTableState.onEnter.$inject = ['$ngRedux'];

export const tabScheduleDetailsState = {
    name: IDS.app_accreditation_scheme_audit_schedule_details,
    url: '/details',
    component: 'auditScheduleDetails',
};



