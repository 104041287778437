import moment from 'moment';
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';

/* @ngInject */
export function EquipmentLaserAddController($controller, $ngRedux, $scope, $rootScope, $state, $uiRouterGlobals, $q, $location, $timeout, API_BASE, NavigationService, EquipmentService, EquipmentServiceLaser, EquipmentManager, VendorService, AuthService, VendorGridFilter, ManufacturerService, Laser, Manufacturer, Notifier, EQUIPMENT_TYPE, EQUIPMENT_TYPE_CODE) {

    this.unsubscribe = $ngRedux.connect(({ equipment }) => ({
        equAssetCost: equipment.equAssetCost,
    }), {})(this);

    angular.extend(this, $controller('EquipmentBaseController', {
        $scope: $scope,
        $state: $state,
        $location: $location,
        $timeout: $timeout,
        NavigationService: NavigationService,
        EquipmentManager: EquipmentManager,
        VendorService: VendorService,
        Notifier: Notifier,
        VendorGridFilter: VendorGridFilter,
        EquipmentService: EquipmentService
    }));

    $scope.MODULE_STATE = $scope.MODULE_STATE_CREATE;
    $scope.EQUIPMENT_STATE = $scope.MODULE_STATE_CREATE;

    $scope.templateCertification = './equipment-certification.html';
    $scope.templateRegistration = './equipment-registration.html';

    $scope.tabs = EquipmentManager.getEquipmentAddTabs();

    $scope.equipment = new Laser();
    $scope.equipment.model = null;

    $scope.replacableEquipment = [];

    // TODO next to be done
    $scope.investigators = [
        "Mike Brown",
        "Milla Milanovich",
        "Hewbert Roland",
        "Ted Sontry",
        "Peter Bronwinovich"
    ];

    $scope.userData = AuthService.getUser();
    $scope.headOfficeName = $scope.userData.hofName;

    if ($uiRouterGlobals.params.replace && $uiRouterGlobals.params.transaction) {
        $scope.replaceEquipment = true;
        $rootScope.toolbarTitle = "Lasers | REPLACE EQUIPMENT";

        EquipmentServiceLaser.getEquipmentDetails($uiRouterGlobals.params.replace).then(function(item) {
            $scope.locations = item.locations;
            $scope.replacableItem = item.equipment;
            $scope.replacableItem.transaction_id = $uiRouterGlobals.params.transaction;
            $scope.equipment.category = item.equipment.category;
            $scope.categories = [item.equipment.category];
        });
    }
    else {
        $scope.replaceEquipment = false;
        EquipmentServiceLaser.fetchCategories().then(function(items) {
            $scope.categories = items;
        });
    }

    EquipmentServiceLaser.fetchNextId(EQUIPMENT_TYPE.laser).then(function(data) {
        $scope.equipment.user_friendly_id = data;
    });

    EquipmentServiceLaser.fetchClasses().then(function(items) {
        $scope.classes = items;
    });

    ManufacturerService.fetchManufacturersByEquipmentType(EQUIPMENT_TYPE_CODE.laser).then(function(items) {
        $scope.manufacturers = items;
    });

    EquipmentServiceLaser.fetchOperationMode().then(function(items) {
        $scope.modes = items;
    });

    EquipmentServiceLaser.fetchLaserTypes('lty').then(function(items) {
        $scope.laserTypes = items;
    });

    $scope.units = [
        {id:1, code:'mj', name: 'mJ'},
        {id:2, code:'jls', name: 'Joules'},
        {id:3, code:'mw', name: 'mW'},
        {id:4, code:'wts', name: 'Watts'}
    ];

    $scope.hazardEvaluations = [
        {id:1, code:'tmc', name: 'Through Microscope'},
        {id:2, code:'tma', name: 'Through Microscope / Aperture'},
        {id:3, code:'tgp', name: 'Through G Probe'},
        {id:4, code:'aos', name: 'Applied on Skin'},
        {id:5, code:'fbr', name: 'Fiber'},
        {id:6, code:'nwr', name: 'NHZ Whole Room'},
        {id:7, code:'naa', name: 'NHZ around Aperture'},
        {id:8, code:'nhc', name: 'NHZ Confined'},
        {id:9, code:'no', name: 'No'},
        {id:10, code:'yes', name: 'Yes'}
    ];

    EquipmentManager.getEquipmentServices().then(function(data) {
        $scope.equipment.services = data;
    });

    EquipmentService.fetchEquipmentTypeProperties(EQUIPMENT_TYPE_CODE.laser).then(function(properties) {
        $scope.showPermitApplicationId = false;
        $scope.showPermitRequired = false;
        $scope.addLaserPopupText = false;
        for (const i in properties) {
            if (properties[i].prop_code === 'permit_application_id') {
                $scope.showPermitApplicationId = true;
            }
            if (properties[i].prop_code === 'permit_required') {
                $scope.showPermitRequired = true;
            }
            if (properties[i].prop_code === 'add_laser_popup_ottawa') {
                $scope.addLaserPopupText = 'All Class 3B and 4 Medical Lasers must be registered with the Laser & Radiation Safety Dept and require a permit prior to use';
                $scope.addLaserPopupSubtext = "All mandatory fields must be completed prior to submission";
                $scope.addLaserPopupTitle = 'LASER PERMIT APPLICATION';
            }
        }
        EquipmentManager.approvalToAcquire($scope.addLaserPopupTitle, $scope.addLaserPopupText, $scope.addLaserPopupSubtext);
    });

    EquipmentService.fetchEquipmentTrainings(EQUIPMENT_TYPE_CODE.laser).then(function(items) {
        $scope.trainings = items;
    });

    $scope.$on('LOCATION_SUBJECT_SELECTED', function(event, locationItem) {
        $scope.equipment.location.uuid = locationItem.uuid;
        $scope.equipment.account_id = locationItem.in_account_id;
        if (locationItem.in_subject_basic_type_code == 'slo') {
            $scope.equipment.account.lspn = locationItem.in_lspn;
            $scope.equipment.account.permitApplication = locationItem.in_permit;
        }
        if (locationItem.in_subject_basic_type_code == 'hof') {
            $scope.equipment.account.lspn = null;
            $scope.equipment.account.permitApplication = null;
        }

        if (locationItem.in_subject_basic_type_code === 'slo') {
            EquipmentService.fetchEquipmentTrainings(EQUIPMENT_TYPE_CODE.laser, locationItem.uuid).then(function (items) {
                $scope.trainings = items;
            });
        }

        EquipmentService.fetchEquipmentTypeProperties(EQUIPMENT_TYPE_CODE.laser, locationItem.uuid).then(function(properties) {
            $scope.showPermitApplicationId = false;
            $scope.showPermitRequired = false;
            $scope.equipment.permitRequired = false;
            for (const i in properties) {
                if (properties[i].prop_code === 'permit_application_id') {
                    $scope.showPermitApplicationId = true;
                }
                if (properties[i].prop_code === 'permit_required') {
                    $scope.showPermitRequired = true;
                }
            }
        });
    });

    $scope.categorySelected = function() {
        $scope.replacableItem = null;
        const { id } = $scope.equipment.category;
        if (id) {
            EquipmentServiceLaser.fetchReplaceableEquipment({
                account: null,
                category: { id },
                offset: 0,
                limit: 100
            }, true).then(function (result) {
                $scope.replacableEquipment = result.data.list;
            });
        }

        $scope.$broadcast('RESET_EQUIPMENT_MODEL', $scope.equipment.type.id);
        $scope.equipment.manufacturer = new Manufacturer();
    };

    $scope.manufacturerSelected = function() {
        ManufacturerService.fetchEquipmentModels($scope.equipment.manufacturer.id, $scope.equipment.category.id).then(function(items) {
            $scope.models = items;
        });
    };

    $scope.createEquipmentModel = function(equipment) {
        EquipmentManager.createNewModel(equipment);
    };

    $scope.createLaserName = function(equipment) {
        EquipmentManager.createNewLaserName(equipment);
    };

    $scope.$on(EquipmentManager.CREATE_NEW_MODEL_COMPLETE, function(event, data) {
        $scope.models.push(data);
        $scope.equipment.model = data;
    });

    $scope.$on(EquipmentManager.CREATE_NEW_CATEGORY_COMPLETE, function(event, data) {
        $scope.categories.push(data);
        $scope.equipment.category = data;
    });

    $scope.showReplacableItemDetails = function(item) {
        EquipmentManager.showDetails(item);
    };

    $scope.onNotApplicableTraining = () => {
        $scope.equipment.vendorTraining = false;
        $scope.equipment.internalTraining = false;
    };

    $scope.onTraining = () => {
        $scope.equipment.notApplicableTraining = false;
    };

    _.extend(_.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]), {
        exec: (function() {
            var deferred = $q.defer();
            switch ($scope.EQUIPMENT_STATE) {
                case $scope.MODULE_STATE_CREATE:
                    if (_.get($scope.replacableItem, 'transaction_id', null)) {
                        $scope.equipment.transaction_id = $scope.replacableItem.transaction_id;
                    }
                    EquipmentServiceLaser.addEquipment($scope.equipment).then((function(id) {
                        $scope.equipment.equipment_id = id;
                        EquipmentManager.getEquipmentServices(id).then(function(data) {
                            $scope.equipment.services = data;
                        });
                        $scope.EQUIPMENT_STATE = $scope.MODULE_STATE_UPDATE;
                        this.enableTabs();
                        Notifier.success('New piece of equipment was successfully added');
                        deferred.resolve();
                    }).bind(this), function(error) {
                        deferred.reject(error);
                    });
                    break;
                case $scope.MODULE_STATE_UPDATE:
                    EquipmentServiceLaser.updateEquipment($scope.equipment).then(function() {
                        Notifier.success('Equipment details successfully updated');
                        deferred.resolve();
                    }, function(error) {
                        deferred.reject(error);
                    });
                    break;
            }
            return deferred.promise;
        }).bind(this)
    });

    /**
     * ASSET COST
     */
    $scope.icon = 'lasers.svg';
    $scope.color = 'colorLasers';
    $scope.bgColor = 'lasers';
    $scope.btnColor = 'btnColorLasers';
    $scope.depreciation_type = this.equAssetCost.depreciation_type;

    $scope.initAssetCostForm = (form) => {
        $scope.equAssetCostForm = form;
    };

    $scope.setWarrantyExpiryDate = () => {
        const { purchaseDate, warranty_period } = this.equAssetCost;
        if (
            moment.isDate(purchaseDate) &&
            isNumber(warranty_period) &&
            !isNaN(warranty_period)
        ) {
            $ngRedux.dispatch({
                type: 'EQUIPMENT_WARRANTY_EXPIRY_DATE',
                id: 'equAssetCost',
                date: moment(purchaseDate).add(warranty_period, 'months').toDate(),
            });
        }
    };

    $scope.onDepreciationType = () => {
        $scope.depreciation_type = this.equAssetCost.depreciation_type;
    };

    $scope.genDepreciationReport = () => {
        if ($scope.equAssetCostForm.$valid) {
            if (this.equAssetCost.depreciation_type) {
                Notifier.info('Please wait...', false);
                EquipmentService.generateDepreciationReport(
                    'las',
                    $scope.equipment.equipment_id,
                    $scope.equipment.user_friendly_id,
                )
                .then(() => Notifier.success('Success'))
                .catch(() => Notifier.error('System Error!'));
            } else {
                Notifier.error('Please save asset cost');
            }
        } else {
            Notifier.error('Please fill in the form fields');
        }
    };

    $scope.saveAssetCost = () => {
        if ($scope.equAssetCostForm.$valid) {
            if ($scope.equipment.equipment_id) {
                if (this.equAssetCost.estimated_residual_value < this.equAssetCost.assetCost) {
                    EquipmentServiceLaser.updateEquipment($scope.equipment).then(() => {
                        Notifier.success('Asset cost has been successfully saved');
                    });
                } else {
                    Notifier.error('The estimated residual value cannot be higher than the cost of the asset');
                }
            }
        } else {
            Notifier.error('Please fill in the form fields');
        }
    };

    $scope.$on('$destroy', () => {
        $ngRedux.dispatch({
            type: 'EQUIPMENT_RESET',
            id: 'equAssetCost'
        });
        this.unsubscribe();
    });
}
