/* @ngInject */
export function AccIAQualityImprovementReg($scope, EXPORT_FORMAT_PDF, Notifier,
							AccountService, AccreditationDocumentsService, ACC_ACCREDITATION_CODE,
							$mdDialog, DOCUMENT_TYPE, DocumentManager, AuthService, SUBJECT_TYPE,
                                    AccreditationService, $filter) {

    //todo, find out somehow here which accreditation is this (DIAS, DENTAL)

    $scope.cursorState = 'default';

    $scope.currentDocPage = 1;
    $scope.pageSize = 10;
    $scope.sortArrow = 'keyboard_arrow_down';

    var ctrl = this;
    ctrl.accreditationSchemaCode = AccreditationService.getCurrentAccreditationFromState();

    $scope.SORT_OPTIONS = {
        ITEM_NUMBER: 'itemNumber',
        IMPROVEMENT_ACTION_ITEM: 'improvementActionItem',
        SOURCE: 'source',
        ACTION_UNDERTAKEN: 'actionUndertaken',
        PERSON_RESPONSIBLE: 'resPerson',
        CREATION_DATE: 'creationDate',
        COMPLETION_DATE: 'completionDate'
    };
    ctrl.sortBy = $scope.SORT_OPTIONS.ITEM_ID;
    ctrl.sortReverse = false;

    ctrl.selectedUserParentLocation = ""; //uuid
    ctrl.selectedUserParentLocationName = ""; //location name

    ctrl.YEARS = [new Date().getFullYear(), new Date().getFullYear()-1, new Date().getFullYear()-2];
    ctrl.year = new Date().getFullYear();
    ctrl.qualityImpItems = null;
    ctrl.selectedItem = null;

    var user = AuthService.getUser();

    ctrl.selectItem = function (item) {
        ctrl.selectedItem = item;
    };

    ctrl.fetchQualityImprovementItems = function () {
        AccreditationDocumentsService.fetchAccQualityImprovementItems(ctrl.accreditationSchemaCode,
            ctrl.selectedUserParentLocation, ctrl.year)
            .then(function (response) {
                ctrl.qualityImpItems = response.data.result;
                ctrl.selectedItem = null;
            }, function (error) {
                Notifier.error("Error loading quality improvement items from the server.");
            });
    };

    ctrl.setSort = function (sortColumn) {
        if (sortColumn === ctrl.sortBy) {
            ctrl.sortReverse = !ctrl.sortReverse;
        } else {
            ctrl.sortBy = sortColumn;
        }
    };

    $scope.$on('LOCATION_SUBJECT_SELECTED', function (event, locationItem) {
        if (locationItem.in_subject_basic_type_code === "slo") {
            ctrl.selectedUserParentLocation = locationItem.in_subject_uuid;
            ctrl.selectedUserParentLocationName = locationItem.in_username_par;
        } else {
            ctrl.selectedUserParentLocation = "";
            ctrl.selectedUserParentLocationName = "";
        }
    });

    $scope.$watch('accIAQualityImprovementReg.selectedUserParentLocation', function () {
        if (ctrl.selectedUserParentLocation != "") {
            ctrl.fetchQualityImprovementItems();
        }
    });

    $scope.$watch('accIAQualityImprovementReg.year', function () {
        if (ctrl.selectedUserParentLocation != "") {
            ctrl.fetchQualityImprovementItems();
        }
    });

    $scope.$watch('accIAQualityImprovementReg.selectedItem', function () {
        if (ctrl.selectedItem != null) {

        }
    });

    $scope.$watch('accIAQualityImprovementReg.sortReverse', function (value) {
        if (value) {
            $scope.sortArrow = 'keyboard_arrow_up';
        } else {
            $scope.sortArrow = 'keyboard_arrow_down';
        }
    });


     ctrl.addEditQualityImprovementItem = function (item) {
            $mdDialog.show({
                controller: 'AccIAQualityImprovementAddEdit',
                template: require('../../../../views/accreditation/quality-controll-documents/internal-audit/accreditation-quality-improvement-register-add-dialog.html'),
                // scope: scope, // use parent scope in template
                // preserveScope: true,
                locals: {
                    color: '#a934e4',
                    title: $filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.ADD_EDIT_TITLE'),
                    locationUuid: ctrl.selectedUserParentLocation,
                    locationName: ctrl.selectedUserParentLocationName,
                    accShemaCode: ctrl.accreditationSchemaCode,
                    quaImprItem: item
                },
                scope: $scope.$new(),
                preserveScope: false,
                clickOutsideToClose: false
            });
    };

    $scope.$on('acc-quality-imrpovement-item-added', function (events, args) {
        ctrl.fetchQualityImprovementItems();
    });

    ctrl.downloadPdfItemList = function () {
         AccreditationDocumentsService.fetchAccQualityImprovementItems(ctrl.accreditationSchemaCode,
            ctrl.selectedUserParentLocation, ctrl.year, null, EXPORT_FORMAT_PDF)
            .then(function (response) {
                DocumentManager.downloadFile("/" + response.data.result, DOCUMENT_TYPE.ACCREDITATION_DOCUMENT);
            }, function (error) {
                Notifier.error($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_ITEMLIST_LOAD_ERROR'));
            });
    };

    //Load data for parent site location if user belongs to only one site location
    if (user && (user.parentCount === 1) && (user.parents[user.parents.length-1])
        && (user.parents[user.parents.length-1].basicType == SUBJECT_TYPE.SITE_LOCATION)) {

        ctrl.selectedUserParentLocation = user.parents[user.parents.length-1].uuid;
        ctrl.selectedUserParentLocationName = user.parents[user.parents.length-1].name;
        ctrl.fetchQualityImprovementItems();
    }

}
