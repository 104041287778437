/*
*   List of Actions with TYPE that should be passed from accreditation.reducer.js to audit.reducer.js
 */

export const PREFIX = 'ACCREDITATION_AUDIT';

export const TYPES = {
    FETCH_SCHEDULE:                     `${PREFIX}_FETCH_SCHEDULE`,
    FETCH_SITE_LOCATION_DETAILS:        `${PREFIX}_FETCH_SITE_LOCATION_DETAILS`,
    FETCH_SITE_LOCATION_MANAGERS:       `${PREFIX}_FETCH_SITE_LOCATION_MANAGERS`,
    RESET_SCHEDULE:                     `${PREFIX}_RESET_SCHEDULE`,
    FETCH_USER_DEAILS:                  `${PREFIX}_FETCH_USER_DEAILS`,
    REFRESH_AUDIT_DETAILS:              `${PREFIX}_REFRESH_AUDIT_DETAILS`,
    ADD_AUDIT_ASSIGNEE:                 `${PREFIX}_ADD_AUDIT_ASSIGNEE`,
    SELECT_NEW_AUDIT_FISCAL_YEAR:       `${PREFIX}_SELECT_NEW_AUDIT_FISCAL_YEAR`,
    SELECT_NEW_AUDIT_STANDARD:          `${PREFIX}_SELECT_NEW_AUDIT_STANDARD`,
    REMOVE_AUDIT_ASSIGNEE:              `${PREFIX}_REMOVE_AUDIT_ASSIGNEE`,
    RESET_EVIDENCE_PROVIDERS:           `${PREFIX}_RESET_EVIDENCE_PROVIDERS`,
    FETCH_EVIDENCE_PROVIDER_CANDIDATES: `${PREFIX}_FETCH_EVIDENCE_PROVIDER_CANDIDATES`,
    RESET_ADD_AUDIT_FIELDS:             `${PREFIX}_RESET_ADD_AUDIT_FIELDS`,
    RESET_ADD_AUDIT_ASSIGNEES:          `${PREFIX}_RESET_ADD_AUDIT_ASSIGNEES`,
};
