/**
 * @module root/legacy/components/employees
 */
import { EmployeesBaseController } from './controllers/employees-controller-base';
import { EmployeesAddController } from './controllers/employees-controller-base-add';
import { EmployeesManageController } from './controllers/employees-controller-base-manage';
import { EmployeesGridController } from './grid/employees-grid.controller';
import { EmployeesGridSwitchController } from './grid/employees-grid-switch.controller';
import { EmployeesGridSwitchBadgesController } from './grid/employees-grid-switch-badges.controller';
import { InputEmployeeKeywordController } from './form/input-employee-keyword/input-employee-keyword.controller';
import { SelectEmployeeLicenceStatusController } from './form/select-employee-licence-status/select-employee-licence-status.controller';
import { SelectEmployeeLicenceTypeController } from './form/select-employee-licence-type/select-employee-licence-type.controller';
import { SelectEmployeeOccupationController } from './form/select-employee-occupation/select-employee-occupation.controller';
import { SelectEmployeeStatusController } from './form/select-employee-status/select-employee-status.controller';
import { EmployeeLicencesGridController } from './grid/employee-licences-grid.controller';
import { EmployeeLocationsGridController } from './grid/employee-locations-grid.controller';
import { EmployeeService } from './services/employee-service';
import { EmployeeQualificationService } from './services/employee-qualification-service';
import { EmployeeModalityTrainingService } from './services/employee-modality-training-service';
import { LicenceService } from './services/licence-service';
import { EmployeeManager } from './services/employee-manager';
import { LicenceManager } from './services/licence-manager';
import { EmployeesActionsFactory } from './employees.actions';
import { EmployeeFactory } from './factories/employee.factory';
import { EmployeeQualificationFactory } from './factories/employee-qualification.factory';
import { EmployeeModalityTrainingFactory } from './factories/employee-modality-training.factory';
import { EmployeeModalityTrainingCategoryFactory } from './factories/employee-modality-training-category.factory';
import { EmployeeSubjectFactory } from './factories/employee-subject.factory';
import { OccupationFactory } from './factories/occupation.factory';
import { LicenceFactory } from './factories/licence.factory';
import { LicenceTypeFactory } from './factories/licence-type.factory';
import { LicenceStatusFactory } from './factories/licence-status.factory';
import { LicenceGridFilterFactory } from './factories/licence-grid-filter.factory';
import { employeesAlerts } from './alerts/employess-alerts.component';
import { employeesGrid } from './grid/employees-grid.component';
import { employeesGridSwitch } from './grid/employees-grid-switch.component';
import { employeesGridSwitchBadges } from './grid/employees-grid-switch-badges.component';
import { inputEmployeeKeyword } from './form/input-employee-keyword/input-employee-keyword.component';
import { selectEmployeeLicenceStatus } from './form/select-employee-licence-status/select-employee-licence-status.component';
import { selectEmployeeLicenceType } from './form/select-employee-licence-type/select-employee-licence-type.component';
import { selectEmployeeOccupation } from './form/select-employee-occupation/select-employee-occupation.component';
import { selectEmployeeStatus } from './form/select-employee-status/select-employee-status.component';
import { employeeLicencesGrid } from './grid/employee-licences-grid.component';
import { employeeLocationsGrid } from './grid/employee-locations-grid.component';

angular.module(
    'components.employees',
    []
).controller({
    EmployeesBaseController,
    EmployeesAddController,
    EmployeesManageController,
    EmployeesGridController,
    EmployeesGridSwitchController,
    EmployeesGridSwitchBadgesController,
    InputEmployeeKeywordController,
    SelectEmployeeLicenceStatusController,
    SelectEmployeeLicenceTypeController,
    SelectEmployeeOccupationController,
    SelectEmployeeStatusController,
    EmployeeLicencesGridController,
    EmployeeLocationsGridController
}).service({
    EmployeeService,
    EmployeeQualificationService,
    EmployeeModalityTrainingService,
    LicenceService,
    EmployeeManager,
    LicenceManager
}).factory({
    EmployeesActions: EmployeesActionsFactory,
    Employee: EmployeeFactory,
    EmployeeQualification: EmployeeQualificationFactory,
    EmployeeModalityTraining: EmployeeModalityTrainingFactory,
    EmployeeModalityTrainingCategory: EmployeeModalityTrainingCategoryFactory,
    EmployeeSubject: EmployeeSubjectFactory,
    Occupation: OccupationFactory,
    Licence: LicenceFactory,
    LicenceType: LicenceTypeFactory,
    LicenceStatus: LicenceStatusFactory,
    LicenceFilter: LicenceGridFilterFactory
}).component({
    employeesAlerts,
    employeesGrid,
    employeesGridSwitch,
    employeesGridSwitchBadges,
    inputEmployeeKeyword,
    selectEmployeeLicenceStatus,
    selectEmployeeLicenceType,
    selectEmployeeOccupation,
    selectEmployeeStatus,
    employeeLicencesGrid,
    employeeLocationsGrid
}).run(($templateCache) => {
    $templateCache.put('app.employees.alerts.listing', '<employees-alerts></employees-alerts>');
    $templateCache.put('./employee-listing.html', require('./views/employee-listing.html'));
    $templateCache.put('./employee-add.html', require('./views/employee-add.html'));
    $templateCache.put('./employee-locations.html', require('./views/employee-locations.html'));
    $templateCache.put('./employee-licences.html', require('./views/employee-licences.html'));
    $templateCache.put('./employee-qualifications.html', require('./views/employee-qualifications.html'));
    $templateCache.put('./employee-modality-trainings.html', require('./views/employee-modality-trainings.html'));
    $templateCache.put('./employees-grid-filter.html', require('./grid/employees-grid-filter.html'));
    $templateCache.put('./employees-grid-filter-controls.html', require('./grid/employees-grid-filter-controls.html'));
    $templateCache.put('./employee-licences-grid-filter.html', require('./grid/employee-licences-grid-filter.html'));
    $templateCache.put('./employee-qualifications-grid.html', require('./views/qualifications/employee-qualifications-grid.html'));
    $templateCache.put('./employee-trainings-grid.html', require('./views/trainings/employee-trainings-grid.html'));
    $templateCache.put('./employee-licence-dialog.html', require('./views/licences/employee-licence-dialog.html'));
    $templateCache.put('./employee-dosimetry-badges.html', require('./views/employee-dosimetry-badges.html'));
    $templateCache.put('./employee-trainings.html', require('./views/employee-trainings.html'));
});
