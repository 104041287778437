/*
*   List of Action IDs that should be passed from reporting.reducer.js to rep-monthly.reducer.js
 */

export const IDS = {
    repMonthly: 'repMonthly',

    //paths
    repMonthlyGrid:             'repMonthlyGrid',
    repMonthlyDetails:          'repMonthlyDetails',
    repMonthlyVersions:         'repMonthlyVersions',
    repMonthlyVersionsGrid:     'repMonthlyVersionsGrid',

    // states
    app_reporting_monthly:         'app.reporting.monthly',

    // tab states:
    app_reporting_monthly_list:    'app.reporting.monthly.list',
    app_reporting_monthly_details: 'app.reporting.monthly.details',
};