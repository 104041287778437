/* @ngInject */
export function AdministrationAccountsSiteLocationAddressController($ngRedux, $transitions, AdministrationActions, Notifier) {
    var ctrl = this;
    var unsubscribe = angular.noop;
    var onBeforeExit = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);

        onBeforeExit = $transitions.onBefore({exiting: 'app.administration.accounts.siteLocations.address'}, function() {
            ctrl.siteLocationAddressForm.$setPristine();
            ctrl.siteLocationAddressForm.$setUntouched();
        });
    };

    ctrl. manageSiteLocation = function() {
        if (ctrl.siteLocationAddressForm.$valid) {
            ctrl.updateSiteLocationDetails(ctrl.siteLocation);
        }
        else Notifier.error('Please fill in the form fields');
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onBeforeExit();
    };

    function mapStateToThis(state) {
        return {
            siteLocation: state.administration.administrationAccountsSiteLocation.data,
            lock: _.isNull(_.find(state.administration['app.administration.accounts.siteLocations'].tabs, ['state', 'app.administration.accounts.siteLocations.address']).buttons.execute),
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                'Address'
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
