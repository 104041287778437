/**
 * Creates a new DrlCtScannerDoseReportController.
 * @class
 */
class DrlCtScannerDoseReportController {
    constructor($ngRedux, $state, NavigationService, DoseReferenceLevelsActions, Notifier, $filter) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.NavigationService = NavigationService;
        this.DoseReferenceLevelsActions = DoseReferenceLevelsActions;
        this.Notifier = Notifier;
        this.filter = $filter
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;
        this.title = this.filter('translate')('DLR.CT_SCANNER.DOSE_REPORT.GRID_DESC');

        this.unsubscribe = this.$ngRedux.connect(this.mapState, this.DoseReferenceLevelsActions)(this);
    }

    download() {
        if (this.doseReportForm.$valid) {
            this.Notifier.info(this.filter('translate')('DLR.CT_SCANNER.DOSE_REPORT.NOTIFIER_ERROR'));
            this.generateDoseReport(this.dateFrom, this.dateTo, this.bodyPart, this.protocol, this.dlp, this.ctdivol).then(() => {
                this.doseReportForm.$setPristine();
                this.doseReportForm.$setUntouched();
            }, error => {
                this.Notifier.responseMessage(error);
            });
        }
        else this.Notifier.error(this.filter('translate')('DLR.CT_SCANNER.DOSE_REPORT.NOTIFIER_ERROR'));
    }

    $onDestroy() {
        this.unsubscribe();
    }

    mapState(state) {
        return {
            dateFrom: state.doseReferenceLevels.drlCtScannerDoseReport.dateFrom,
            dateTo: state.doseReferenceLevels.drlCtScannerDoseReport.dateTo,
            bodyPart: state.doseReferenceLevels.drlCtScannerDoseReport.bodyPart,
            protocol: state.doseReferenceLevels.drlCtScannerDoseReport.protocol,
            dlp: state.doseReferenceLevels.drlCtScannerDoseReport.dlp,
            ctdivol: state.doseReferenceLevels.drlCtScannerDoseReport.ctdivol
        }
    }
}

DrlCtScannerDoseReportController.$inject = ['$ngRedux', '$state', 'NavigationService', 'DoseReferenceLevelsActions', 'Notifier', '$filter'];

export const drlCtScannerDoseReport = {
    controller: DrlCtScannerDoseReportController,
    template: require('./drl-ct-scanner-dose-report.html')
};
