export const reportingEquipmentHistoryState = {
    name: 'app.reporting.equipmentType',
    redirectTo: 'app.reporting.equipmentType.listing',
    url: '/equipment-history',
    component: 'tabs'
};

export const reportingEquipmentHistoryListingState = {
    name: 'app.reporting.equipmentType.listing',
    url: '',
    component: 'reportingEquipmentHistory'
};
