/*
*   List of Actions with TYPE that should be passed from accreditation.reducer.js to assessment.reducer.js
 */

export const PREFIX = 'ACCREDITATION_STANDARDS';

export const TYPES = {
    FETCH_EVIDENCE_ASSESSMENT:          `${PREFIX}_FETCH_EVIDENCE_ASSESSMENT`,
    FETCH_EVIDENCE_PROVIDER_CANDIDATES: `${PREFIX}_FETCH_EVIDENCE_PROVIDER_CANDIDATES`,
    FETCH_EVIDENCE_ASSIGNEE_CANDIDATES: `${PREFIX}_FETCH_EVIDENCE_ASSIGNEE_CANDIDATES`,
    RESET_EVIDENCE_ASSIGNEE:            `${PREFIX}_RESET_EVIDENCE_ASSIGNEE`,
    FETCH_STANDARD_AUDIT_TASKS:         `${PREFIX}_FETCH_STANDARD_AUDIT_TASKS`,
    FETCH_SITE_LOCATION_DETAILS:        `${PREFIX}_FETCH_SITE_LOCATION_DETAILS`,
    FETCH_DOCUMENT_DETAILS:             `${PREFIX}_FETCH_DOCUMENT_DETAILS`,
};
