import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

/**
 * Creates a new Gallery.
 * @class
 */
class GalleryController {
    constructor() {
        this.options = {index: 0, history: false};
    }

    /**
     * @method $onInit
     */
    $onInit() {
        if (typeof this.camera === 'undefined')
            this.camera = false;

        if (typeof this.disabled === 'undefined')
            this.disabled = false;

        if (this.camera) {
            if (typeof this.cameraId === 'undefined')
                throw new Error('Missing camera-id attribute');
            if (typeof this.cameraIdentifier === 'undefined')
                throw new Error('Missing camera-identifier attribute');
            if (typeof this.cameraStatePath === 'undefined')
                throw new Error('Missing camera-state-path attribute');
        }
    }

    openGallery() {
        const element = angular.element(document.querySelector('.pswp'));
        const gallery = new PhotoSwipe(element[0], PhotoSwipeUI_Default, this.getImages(), this.options);
        gallery.init();
    }

    getImages() {
        if (this.camera) {
            return this.images.map(item => {
                return {
                    src: item.url,
                    w: item.width,
                    h: item.height
                };
            })
        }
        else {
            return this.images.map(item => {
                return {
                    src: item.getURL(),
                    w: item.width,
                    h: item.height
                };
            })
        }
    }

    saveImage = async (event) => {
        if (this.onSave) {
            return await this.onSave({
                $event: event
            });
        }
    }
}

export const gallery = {
    bindings: {
        images: '<',
        camera: '<',
        cameraId: '@',
        cameraIdentifier: '@',
        cameraStatePath: '@',
        disabled: '<',
        onSave: '&'
    },
    controller: GalleryController,
    template: require('./gallery.html')
};
