import { product } from '../../../bootstrap.json';
import { VERSION } from '../../utils/util.constants';

export const footer = {
    /* @ngInject */
    controller: function() {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.product = product;
            this.version = VERSION;
            this.year = new Date().getFullYear();
        }
    },
    template: require('./footer.html'),
};
