class GridListItemOrderController {
    constructor($ngRedux, $scope) {
        this.$ngRedux = $ngRedux;
        this.$scope = $scope;
    }

    $onInit() {
        if (typeof this.disabled === 'undefined')
            this.disabled = false;
    }

    onButtonClick($event, dir) {
        $event.stopPropagation();
        this.$scope.$emit('grid-order-change', this.data, dir);
    }

    $onDestroy() {}
}

GridListItemOrderController.$inject = ['$ngRedux', '$scope'];

export const gridListItemOrder = {
    bindings: {
        identifier: '@',
        statePath: '@',
        data: '@',
        disabled: '@'
    },
    controller: GridListItemOrderController,
    template: `
        <md-button style="min-width: 20px" ng-click="$ctrl.onButtonClick($event, 'UP')" ng-disabled="$ctrl.disabled">▲</md-button>
        <md-button style="min-width: 20px" ng-click="$ctrl.onButtonClick($event, 'DOWN')" ng-disabled="$ctrl.disabled">▼</md-button>`
};
