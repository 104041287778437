/**
 * Creates a new EquipmentCalibrationAlertsController.
 * @class
 */
import { SystemAlertsController } from '../../../system/alerts/system-alerts.component';

class EquipmentCalibrationAlertsController extends SystemAlertsController{
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService)
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;
        this.equipmentCalibrationModuleID = 249;
    }

    selectEquipment(event) {
        this.$state.go(this.state, {id: event.item.equipmentId});
    }
}

EquipmentCalibrationAlertsController.$inject = ['$ngRedux', '$state', 'NavigationService'];

export const equipmentCalibrationAlerts = {
    bindings: {
        state: '@'
    },
    controller: EquipmentCalibrationAlertsController,
    template: ` 
        <system-alerts-grid identifier="equipmentAlertsGrid"
                           module-id="$ctrl.equipmentCalibrationModuleID"
                           icon="alerts.svg"
                           on-select="$ctrl.selectEquipment($event)">
        </system-alerts-grid>
    `
};
