export const measurementDateRange = {
    bindings: {
        identifier: '@'
    },
    require : {
        form : '^form'
    },
    template: require('./measurement-date-range.html'),
    controller: 'measurementDateRangeController'
};
