/** @module root/components/accreditation/audit */

import * as STATES      from './audit.states';
import { IDS }          from './audit-action-id.constants';
import {accAuditSchedule} from './schedule/schedule.component'
import {accAuditDetails} from './schedule/details/details.component'
import {accAuditAdd} from './add/add.component'

export const AUDIT_MODULE = angular.module(
    'migration.accreditation.audit',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
        Object.values({
            ...STATES,
        }).map(state => {
            stateRegistry.register(state)
        });
    }]
).component({
    accAuditSchedule,
    accAuditDetails,
    accAuditAdd,

}).run(['$templateCache', ({ put }) => {
    put(IDS.app_accreditation_scheme_audit_schedule_table,          '<acc-audit-schedule></acc-audit-schedule>');
    put(IDS.app_accreditation_scheme_audit_schedule_details,        '<acc-audit-details></acc-audit-details>');
    put(IDS.app_accreditation_scheme_audit_add,                     '<acc-audit-details></acc-audit-details>');
}]);
