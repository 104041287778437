/* @ngInject */
export function AccIAAuditChecklist($scope, EXPORT_FORMAT_PDF, Notifier, AuthService, AccountService,
                             $q, AccreditationDocumentsService, ACC_ACCREDITATION_CODE,
                             DocumentManager, ACC_AUDIT_CHECKLIST_ITEM_STATUS, DOCUMENT_TYPE,
                             $window, $location, ACC_ACCREDITATION_DOC_URI, SUBJECT_TYPE,
                             $mdDialog, AccQualityImprovementItem, Helpers, DATE_TYPE, $rootScope, $timeout,
                             $uiRouterGlobals, EmployeeService, AccreditationService, $filter) {

    //todo, find out somehow here which accreditation is this (DIAS, DENTAL)

    $scope.cursorState = 'default';
    $scope.ACC_AUDIT_CHECKLIST_ITEM_STATUS = ACC_AUDIT_CHECKLIST_ITEM_STATUS;

    var ctrl = this;
    ctrl.accreditationSchemaCode = AccreditationService.getCurrentAccreditationFromState();
    ctrl.accreditationUriPath = AccreditationService.getCurrentAccreditationPathFromState();

    ctrl.selectedUserParentLocation = ''; //uuid
    ctrl.selectedUserParentLocationName = ''; //location name

    ctrl.preselectedHomeLocation = void(0);
    ctrl.preselectedSiteLocation = void(0);

    ctrl.YEARS = [new Date().getFullYear(), new Date().getFullYear() + 1, new Date().getFullYear() + 2];

    ctrl.year = new Date().getFullYear();
    if ($uiRouterGlobals.params.year != null) {
        ctrl.year = Number($uiRouterGlobals.params.year);
    }

    ctrl.auditChecklists = [];
    ctrl.selectedChecklist = null;
    ctrl.dirty = false;

    var user = AuthService.getUser();
    ctrl.isAdmin = user.type == 'Administrator';

    var evidenceRequestChecklists = [];
    var evidenceRequestChecklistsAlreadyMet = [];

    ctrl.fetchChecklists = function () {
        AccreditationDocumentsService.fetchAccAuditCheckLists(ctrl.accreditationSchemaCode,
            ctrl.selectedUserParentLocation, ctrl.year)
            .then(function (response) {
                ctrl.auditChecklists = response.data.result;
                if (!(_.isEmpty($uiRouterGlobals.params.standard))) {
                    //find related standard checklist and select it
                    ctrl.auditChecklists.forEach(function (element) {
                        ctrl.fetchChecklistItems(element);
                        if ($uiRouterGlobals.params.standard.startsWith(element.standard)) {
                            ctrl.selectedChecklist = element;
                        }
                    });

                    if ((ctrl.selectedChecklist == null) && (ctrl.auditChecklists)) {
                        ctrl.selectedChecklist = ctrl.auditChecklists[0];
                    }
                } else {
                    ctrl.auditChecklists.forEach(function (element) {
                        ctrl.fetchChecklistItems(element);
                    });
                    if (ctrl.auditChecklists) {
                        ctrl.selectedChecklist = ctrl.auditChecklists[0];
                    }
                }
            }, function (error) {

            });
    };

    ctrl.confirmDropChanges = function () {
        if (ctrl.dirty) {
            return $rootScope.confirmationDialog($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_IGNORE_CHANGES'))
                .then(function (result) {
                    //Confirm, continue, drop changes
                    ctrl.dirty = false;
                    return true;
                }, function (error) {
                    //cancel, go back to save changes
                    return $q.reject();
                });
        } else {
            //no changes, continue
            return $q.when(true);
        }
    };

    ctrl.fetchChecklistItems = function (checklist) {
        AccreditationDocumentsService.fetchAccAuditCheckListItems(ctrl.accreditationSchemaCode,
            ctrl.selectedUserParentLocation, checklist.id)
            .then(function (response) {
                if (_.isEmpty(response.data.result)) {

                } else {
                    checklist.checklistItems = response.data.result;
                    ctrl.loadChecklistValue(checklist);
                }
            }, function (error) {

            });
    };

    ctrl.loadChecklistValue = function (checklist) {
        evidenceRequestChecklists = [];
        evidenceRequestChecklistsAlreadyMet = [];
        AccreditationDocumentsService.fetchAccAuditChecklistResult(ctrl.accreditationSchemaCode,
            ctrl.selectedUserParentLocation, ctrl.year, checklist.id)
            .then(function (response) {
                var loadedChecklistResult = response.data.result;
                for (var i = 0; i < checklist.checklistItems.length; i++) {
                    var currentItem = checklist.checklistItems[i];
                    if (loadedChecklistResult != null) {
                        currentItem.status = loadedChecklistResult[currentItem.id].status;
                        currentItem.actionRequired = loadedChecklistResult[currentItem.id].actionRequired;

                        if (currentItem.status == ACC_AUDIT_CHECKLIST_ITEM_STATUS.MET) {
                            //mark this item as met in current system state, not to generate evidence email for it
                            evidenceRequestChecklistsAlreadyMet.push(currentItem.id);
                        }
                    } else {
                        currentItem.status = null;
                        currentItem.actionRequired = null;
                    }
                }

                checklist.progress = ctrl.calculateChecklistProgress(checklist);

                $timeout(function () {
                    ctrl.dirty = false;
                }, 0);
            }, function (error) {

            });
    };

    ctrl.saveChecklistValue = function (checklist) {
        var checklistResult = {};
        for (var i = 0; i < checklist.checklistItems.length; i++) {
            var currentItem = checklist.checklistItems[i];
            checklistResult[currentItem.id] = {
                'status': currentItem.status,
                'actionRequired': currentItem.actionRequired
            };
        }

        AccreditationDocumentsService.editAccAuditChecklistResult(ctrl.accreditationSchemaCode,
            ctrl.selectedUserParentLocation, ctrl.year, checklist.id, checklistResult, evidenceRequestChecklists)
            .then(function (response) {
                Notifier.success($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_CHECKLIST_SAVE_SUCCESS'));
                checklist.progress = ctrl.calculateChecklistProgress(checklist);
                ctrl.dirty = false;
                ctrl.selectedChecklist = null;
            }, function (error) {

            });
    };

    ctrl.downloadPdfCheckList = function (checklist) {
        if (checklist) {
            AccreditationDocumentsService.fetchAccAuditChecklistResult(ctrl.accreditationSchemaCode,
                ctrl.selectedUserParentLocation, ctrl.year, checklist.id, EXPORT_FORMAT_PDF)
                .then(function (response) {
                    DocumentManager.downloadFile('/' + response.data.result, DOCUMENT_TYPE.ACCREDITATION_DOCUMENT);
                }, function (error) {

                });
        }
    };

    ctrl.selectChecklist = function (item) {
        ctrl.confirmDropChanges()
            .then(function (result) {
                ctrl.selectedChecklist = item;
            }, function (error) {
                //cancel switching selectChecklist, go back save changes
            });
    };

    ctrl.clearLastChanges = function () {
        ctrl.fetchChecklistItems(ctrl.selectedChecklist);
    };

    ctrl.uploadDocuments = function (checklist) {
        $location.path(ctrl.accreditationUriPath).search({'standard': checklist.standard});
    };

    ctrl.calculateChecklistProgress = function (item) {
        var resultProgress = 0;
        var finishedItems = 0;
        var countOfItems=0;
        if ((item != null) && (item.checklistItems != null) && (item.checklistItems.length > 0)) {

            for (var i = 0; i < item.checklistItems.length; i++) {
                if (item.checklistItems[i].hasValue) countOfItems++;
                if ((item.checklistItems[i].status != null) &&
                    ((item.checklistItems[i].status === ACC_AUDIT_CHECKLIST_ITEM_STATUS.MET) ||
                        (item.checklistItems[i].status === ACC_AUDIT_CHECKLIST_ITEM_STATUS.NOT_APPLICABLE))) {
                    finishedItems += 1;
                }
            }
            resultProgress = (finishedItems * 100) / countOfItems;

        }
        return resultProgress;
    };

    $scope.$on('LOCATION_SUBJECT_SELECTED', function (event, locationItem) {

        if ((ctrl.preselectedHomeLocation) && (ctrl.preselectedSiteLocation)) {
            //Some default hof and site location are set when page was loaded
            //Here we are sure that select cascade hsa been initialized, and data loaded
            //Call force select parent on select cascade
            $rootScope.$broadcast('FORCE_SELECT_PARENT', 0, ctrl.preselectedHomeLocation, ctrl.preselectedSiteLocation);
            ctrl.preselectedHomeLocation = null;
            ctrl.preselectedSiteLocation = null;
            return;
        }

        if (locationItem.in_subject_basic_type_code === 'slo') {
            ctrl.selectedUserParentLocation = locationItem.in_subject_uuid;
            ctrl.selectedUserParentLocationName = locationItem.in_username_par;
        }
    });

    $scope.$watch('accIAAuditChecklist.selectedUserParentLocation', function () {
        if (ctrl.selectedUserParentLocation != '') {
            ctrl.fetchChecklists();
        }
    });

    $scope.$watch('accIAAuditChecklist.selectedChecklist', function () {
        if (ctrl.selectedChecklist != null) {
            ctrl.fetchChecklistItems(ctrl.selectedChecklist);
        }
    });

    $scope.$watch('accIAAuditChecklist.year', function (newYear, oldYear) {
        if ((ctrl.selectedChecklist != null) && (ctrl.year != null)) {

            AccreditationDocumentsService.fetchAccAuditCheckLists(ctrl.accreditationSchemaCode,//For progress calculation for new year
                ctrl.selectedUserParentLocation, ctrl.year)
                .then(function (response) {
                    ctrl.auditChecklists = response.data.result;
                }, function (error) {

                });
            ctrl.loadChecklistValue(ctrl.selectedChecklist);
        }
    });

    //If Met status is selected, mark for evidence request
    ctrl.statusChanged = function (item, newValue) {
        var newChecklistItemId = item.id;

        if (newValue == ACC_AUDIT_CHECKLIST_ITEM_STATUS.MET) {
            if (!evidenceRequestChecklistsAlreadyMet.some(function (element) {
                    return element == newChecklistItemId;})) {
                evidenceRequestChecklists.push(newChecklistItemId);
            }
        } else {
            if (evidenceRequestChecklists.some(function (element) {
                    return element == newChecklistItemId;})) {
                //remove from list
                var index = evidenceRequestChecklists.indexOf(newChecklistItemId);
                evidenceRequestChecklists.splice(index, 1);
            }
        }
        ctrl.makeDirty();
    };

    ctrl.makeDirty = function () {
        ctrl.dirty = true;
    };

    ctrl.confirmChange2 = function (event, message) {
        var confirmed;
        if (ctrl.dirty) {
            confirmed = $window.alert(message);
            if (event && !confirmed) {
            }
        }
    };

    $scope.$on('$locationChangeStart', function (event) {
        var confirmed;
        if (ctrl.dirty) {
            confirmed = $window.confirm($filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.NOTIFY_CHECKLIST_SAVE_SUCCESS'));
            if (event && !confirmed) {
                event.preventDefault();
            }
        }
    });

    ctrl.addQirItem = function (item) {
        var newQirItem = new AccQualityImprovementItem();
        newQirItem.locationUuid = ctrl.selectedUserParentLocation;
        newQirItem.improvementActionItem = item.actionRequired;
        newQirItem.source = user.name + ' - ' + user.type;
        newQirItem.creationDate = new Date();
        newQirItem.creationDateStr = Helpers.formatDate(newQirItem.creationDate, DATE_TYPE.STRING);

        $mdDialog.show({
            controller: 'AccIAQualityImprovementAddEdit',
            template: require('../../../../views/accreditation/quality-controll-documents/internal-audit/accreditation-quality-improvement-register-add-dialog.html'),
            // scope: scope, // use parent scope in template
            // preserveScope: true,
            locals: {
                color: '#a934e4',
                title: $filter('translate')('ACCREDITATION.TABS.INTERNAL_AUDIT.ADD_EDIT_TITLE'),
                locationUuid: ctrl.selectedUserParentLocation,
                locationName: ctrl.selectedUserParentLocationName,
                accShemaCode: ctrl.accreditationSchemaCode,
                quaImprItem: newQirItem
            },
            scope: $scope.$new(),
            preserveScope: false,
            clickOutsideToClose: false
        });
    };

    //Load data for parent site location if user belongs to only one site location
    if (!(_.isEmpty($uiRouterGlobals.params.sloc))) {
        //Here, select appropriate site location in select cascade
        ctrl.preselectedSiteLocation = $uiRouterGlobals.params.sloc;
        EmployeeService.getEmploymentLocations(ctrl.preselectedSiteLocation)
            .then(function (result) {
                ctrl.preselectedHomeLocation = result[0].uuid;
            }, function (error) {
                ctrl.preselectedHomeLocation = null;
            });
    }
    else if (user && (user.parentCount === 1) && (user.parents[user.parents.length - 1]) && (user.parents[user.parents.length - 1].basicType == SUBJECT_TYPE.SITE_LOCATION)) {
        ctrl.selectedUserParentLocation = user.parents[user.parents.length - 1].uuid;
        ctrl.selectedUserParentLocationName = user.parents[user.parents.length - 1].name;
        ctrl.fetchChecklists();
    }
}
