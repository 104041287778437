/* @ngInject */
export function AccessSubjectTypesController($scope, $rootScope, SubjectTypesService) {

    $rootScope.toolbarTitle = "Administration | Accounts | MANAGE SUBJECT TYPES";

    $scope.selSubTypesPath = [];
    $scope.allFuncs;
    $scope.unasignedFuncs = [];
    $scope.selectSubjectType;
    $scope.showAddSubjectForm = false;
    $scope.showAddOccupationForm = false;

    SubjectTypesService.fetchSubjectTypes(
        1,      /**< Parent ID = 1 is ROOT. Lets leave this hardcoded for now. */
        null,   /**< Container ID */
        '1',    /**< List only direct children. */
        '', ''  /**< Limit and offset are currently not implemented. */
    ).
    then(function (subjectTypes) {
        $scope.subjectTypes = subjectTypes;
        var subType = { id: 1, name: "root" };
        $scope.selSubTypesPath.push(subType);
    });

    SubjectTypesService.fetchFuncsForSubjectType(1000000).then(
        function (funcs) {
            $scope.allFuncs = funcs;
        });

    $scope.selectItem = function(item,index){
        $scope.selectedItem = item;
        $scope.selectedOccupation =null;
        $scope.modifiedItem = null;

        $scope.itemSelected = null;
        $scope.itemSelected = index;

        $scope.selectSubjectTypeId = item.id;
        if (item.is_user) {
            SubjectTypesService.fetchFuncsForSubjectType(item.id).then(
                function (funcs) {
                    $scope.assignedFuncs = funcs;
                    $scope.unasignedFuncs.length = 0;
                    for (var i = 0; i < $scope.allFuncs.length; ++i) {
                        var found = false;
                        for (var j = 0; j < funcs.length; ++j) {
                            if ($scope.allFuncs[i].id === funcs[j].id) {
                                found = true;
                                break;
                            }
                        };
                        if (!found) {
                            $scope.unasignedFuncs.push($scope.allFuncs[i]);
                        }
                    };
                });
        }
        //        else {
        SubjectTypesService.fetchSubjectTypes(
            null,
            $scope.selectSubjectTypeId, //containerId,
            '1', //onlyDirect,
            '', ''
        ).then(function (occupations) {
            $scope.occupations = occupations;
        });
        //        }
    };

    $scope.getSubjectTypes = function(subjectTypeId, subjectTypeName
                                      /*, containerId, onlyDirect, offset, limit*/)
    {
        SubjectTypesService.fetchSubjectTypes(
            subjectTypeId,
            null, //containerId,
            '1', //onlyDirect,
            '', ''
        ).then(function (subjectTypes) {
            $scope.subjectTypes = subjectTypes;
            var subType = { id: subjectTypeId, name: subjectTypeName };
            $scope.selSubTypesPath.push(subType);
            $scope.selectedItem = null; // unselect previously selected item.
        });
    };

    $scope.fetchFuncsForSubjectType = function(subjectTypeId)
    {
        SubjectTypesService.fetchFuncsForSubjectType(subjectTypeId).then(
            function (funcs) {
                $scope.assignedFuncs = funcs;
            });
    };

    $scope.revSubjectTypes = function(subjectTypeId, subjectTypeName) {
        SubjectTypesService.fetchSubjectTypes(
            subjectTypeId,
            null, //containerId,
            '1', //onlyDirect,
            '', ''
        ).then(function (subjectTypes) {
            $scope.subjectTypes = subjectTypes;
            while ($scope.selSubTypesPath.pop().id !== subjectTypeId);
            var subType = { id: subjectTypeId, name: subjectTypeName };
            $scope.selSubTypesPath.push(subType);
            $scope.selectedItem = null; // unselect previously selected item.
        });
    };

    $scope.removeFunc = function(func) {
        SubjectTypesService.removeFunc(func.id, $scope.selectSubjectTypeId).
        then(function(){
            SubjectTypesService.fetchFuncsForSubjectType(
                $scope.selectSubjectTypeId, $scope.selectSubjectTypeId).then(
                function (funcs) {
                    $scope.assignedFuncs = funcs;
                    $scope.unasignedFuncs.length = 0;
                    for (var i = 0; i < $scope.allFuncs.length; ++i) {
                        var found = false;
                        for (var j = 0; j < funcs.length; ++j) {
                            if ($scope.allFuncs[i].id === funcs[j].id) {
                                found = true;
                                break;
                            }
                        };
                        if (!found) {
                            $scope.unasignedFuncs.push($scope.allFuncs[i]);
                        }
                    };
                }).then(function () {});
        });
    }

    $scope.assignFunc = function(func) {
        SubjectTypesService.assignFunc(func.id, $scope.selectSubjectTypeId).
        then(function(){
            SubjectTypesService.fetchFuncsForSubjectType(
                $scope.selectSubjectTypeId).then(
                function (funcs) {
                    $scope.assignedFuncs = funcs;
                    $scope.unasignedFuncs.length = 0;
                    for (var i = 0; i < $scope.allFuncs.length; ++i) {
                        var found = false;
                        for (var j = 0; j < funcs.length; ++j) {
                            if ($scope.allFuncs[i].id === funcs[j].id) {
                                found = true;
                                break;
                            }
                        };
                        if (!found) {
                            $scope.unasignedFuncs.push($scope.allFuncs[i]);
                        }
                    };
                });
        });
    }

    $scope.showAddSubjectFormFunc = function(){
        $scope.showAddSubjectForm = true;
    }

    $scope.showAddOccupationFormFunc = function(){
        $scope.showAddOccupationForm = true;
    }

    $scope.cancelAddSubjectFormFunc = function(){
        $scope.showAddSubjectForm = false;
    }

    $scope.cancelAddOccupationFormFunc = function(){
        $scope.showAddOccupationForm = false;
    }

    $scope.doAddSubjectFormFunc = function(subjectTypeName){
        SubjectTypesService.addSubjectType($scope.selectSubjectTypeId, null, subjectTypeName).then(
            function () {
                console.log("subject type created");
            });
        $scope.showAddSubjectForm = false;
    }

    $scope.doAddOccupationFormFunc = function(occupationName){

        // So bad, so wrong ...
        SubjectTypesService.addCustomerOccupation($scope.selectSubjectTypeId, occupationName).then(
            function () {
                console.log("occupation created");
            });
        $scope.showAddOccupationForm = false;
    }
}
