import { GridController, gridTpl } from '../../../../common/grid/grid.controller';

class PersonalDosimetryReportsGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, NavigationService, PersonalDosimetryActions, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.PersonalDosimetryActions = PersonalDosimetryActions;
        this.grid_filter = $filter;
    }

    $onInit() {
        this.module = 'personalDosimetry';
        this.identifier = 'personalDosimetryReportsGrid';
        this.filterTemplate = './personal-dosimetry-reports-grid-filter.html';

        this.columns = [
            {title: '#',                                                                                        mapper: null},
            {title: this.grid_filter('translate')('PERSONAL_DOSIMETRY.TABS.MANAGE_REPORTS.GRID_IMPORT_DATE'),   mapper: 'importDate'},
            {title: this.grid_filter('translate')('PERSONAL_DOSIMETRY.TABS.MANAGE_REPORTS.GRID_DOCUMENT'),      mapper: 'document'},
            {title: 'Service Provider',                                                                         mapper: 'service_provider'},
            {title: 'Dose Unit',                                                                                mapper: 'dose_unit'},
            {title: this.grid_filter('translate')('PERSONAL_DOSIMETRY.TABS.MANAGE_REPORTS.GRID_TOTAL_RECORDS'), mapper: 'totalRecords'},
            {title: this.grid_filter('translate')('PERSONAL_DOSIMETRY.TABS.MANAGE_REPORTS.GRID_USERNAME'),      mapper: 'username'},
            {title: '', mapper: 'download'},
        ];

        this.buttons = [{
            label: 'Over the Limits',
            class: 'btnGridExtended',
            callback: this.onLimits,
        }];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.PersonalDosimetryActions)(this);
        this.dataAction = this.getReports;
        this.exportService = this.getReports;
        this.filter.identifier = this.identifier;

        this.init();
    }

    $onDestroy() {
        this.destroy();
        this.$ngRedux.dispatch({
            type: 'GRID_RESET',
            id: 'personalDosimetryReportsGrid',
        });
    }
}

export const personalDosimetryReportsGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onRemove: '&',
        onLimits: '&'
    },
    controller: PersonalDosimetryReportsGridController,
    ...gridTpl,
};
