import API from '../../../common/http/api.fetch';
import {
    AP_INVITATION_RESET,
    AP_REQUEST_RESET,
    AP_REQUEST_DETAILS,
    AP_RESET_PERMIT_HOLDER
} from '../applications-permits.actions.types';
import { formatDate } from '../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../common/utils/util.constants';
import { resolveRequestProgress } from '../applications-permits.utils';
import findIndex from 'lodash/findIndex';

export const inviteApplicant = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/applications-permits/invitation',
            method: 'POST',
            data,
        }).then(() => {
            dispatch({ type: AP_INVITATION_RESET });
            dispatch({
                type: AP_RESET_PERMIT_HOLDER,
                id: 'apInvite'
            });
        });
    }
}

export const submitLaserRequest = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/applications-permits/laser/request',
            method: 'POST',
            data: {
                ...data,
                qualification_code: data.qualification_code ? data.qualification_code.startsWith('new') ? null : data.qualification_code : null,
                equipment: data.equipment.map((item) => parseInt(item.id))
            }
        }).then(() => {
            dispatch({ type: AP_REQUEST_RESET });
        });
    }
}

export const getApplicantRequests = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: '/applications-permits/requests',
            method: 'GET',
            params: {
                offset: filter.offset,
                limit: filter.limit,
                keyword: filter.keyword,
                type: filter.type,
                status: filter.status,
                renewalFrom: filter.renewalFrom ? formatDate(filter.renewalFrom, DATE_FORMAT.OUTPUT) : null,
                renewalTo: filter.renewalTo ? formatDate(filter.renewalTo, DATE_FORMAT.OUTPUT) : null,
                exportFormat: filter.exportFormat,
                exportColumns: filter.exportColumns
            }
        }).then(({ data }) => {
            if (filter.exportFormat) {
                return data;
            }
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.items.map((item) => {
                    let approval_date = null;
                    let renewal_date = null;
                    if (item.request_status_code === 'app' && item.request_status_date) {
                        approval_date = formatDate(item.request_status_date, DATE_FORMAT.INPUT);
                        renewal_date = formatDate(new Date(new Date(item.request_status_date).setFullYear(new Date(item.request_status_date).getFullYear() + 1)), DATE_FORMAT.INPUT);
                    }
                    return {
                        ...item,
                        approval_date: approval_date,
                        renewal_date: renewal_date,
                        progress: resolveRequestProgress(item.request_status_code)
                    }
                }),
                itemsCount: parseInt(data.items_count)
            })
        });
    }
}

export const getRequestDetails = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: '/applications-permits/laser/request/details',
            method: 'GET',
            params: { id }
        }).then(({ data }) => {
            dispatch({
                type: AP_REQUEST_DETAILS,
                id: 'apLaserRequest',
                data: data[0]
            })
        });
    }
}

export const cancelRequest = (id, email) => {
    return (dispatch) => {
        return API.fetch({
            url: '/applications-permits/request/cancel',
            method: 'POST',
            data: { id, email }
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'apLaserRequestGrid'
            })
        });
    }
}

export const permitApprove = (application) => {
    return (dispatch) => {
        return API.fetch({
            url: '/applications-permits/permit-approve',
            method: 'POST',
            data: {
                id: application.id,
                approval_officer_signature: application.approval_officer_signature
            }
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'apLaserRequestGrid'
            })
        });
    }
}

export const permitReject = (application, rejectForm) => {
    return (dispatch) => {
        return API.fetch({
            url: '/applications-permits/permit-reject',
            method: 'POST',
            data: {
                id: application.id,
                approval_officer_signature: application.approval_officer_signature,
                reason: rejectForm.rejectReason,
                add_new_qualification_document: rejectForm.addNewQualificationDocument,
                add_new_training_document: rejectForm.addNewTrainingDocument,
                watch_laser_safety_training: rejectForm.watchLaserSafetyTraining,
                purpose: 'lasper'
            }
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'apLaserRequestGrid'
            })
        });
    }
}

export const getRequestLocations = (data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/applications-permits/laser/request/locations',
            method: 'POST',
            data
        }).then(({ data }) => {
            return data[0];
        });
    }
}

export const getRequestEquipment = (filter) => {
    return (dispatch, getState) => {
        const state = getState();
        return API.fetch({
            url: '/applications-permits/request/equipment',
            method: 'GET',
            params: {
                token: state.applicationsPermits.apLaserRequest.token,
                invitation_id: state.applicationsPermits.apLaserRequest.invitation_id,
                location: filter.location,
                offset: filter.offset,
                limit: filter.limit,
            }
        }).then(({ data }) => {
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.items.map((item) => {
                    const selected = findIndex(state.applicationsPermits.apLaserRequest.equipment, ['id', item.equ_id]);
                    return {
                        ...item,
                        id: item.equ_id,
                        selected: selected !== -1,
                        laser_class: item.class,
                        class: selected !== -1 ? 'green' : ''
                    };
                }),
                itemsCount: parseInt(data.items_count)
            })
        });
    }
}

export const getRequestEquipmentForAuthorization = (filter) => {
    return (dispatch, getState) => {
        const state = getState();
        return API.fetch({
            url: '/applications-permits/laser/request/equipment',
            method: 'GET',
            params: {
                id: state.applicationsPermits.apLaserRequest.id,
                offset: filter.offset,
                limit: filter.limit,
                exportFormat: filter.exportFormat,
                exportColumns: filter.exportColumns
            }
        }).then(({ data }) => {
            if (filter.exportFormat) {
                return data;
            }
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.items.map((item) => ({
                    ...item,
                    laser_class: item.class,
                    status: 'Pending',
                })),
                itemsCount: parseInt(data.items_count)
            })
        });
    }
}

export const getRequestTrainings = (filter) => {
    return (dispatch, getState) => {
        const state = getState();
        return API.fetch({
            url: '/applications-permits/request/video-list',
            method: 'GET',
            params: {
                id: state.applicationsPermits.apLaserRequest.id,
            }
        }).then(({ data }) => {
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.items.map((item) => ({
                    ...item
                }))
            })
        });
    }
}
