import API from '../../../common/http/api.fetch';
import {GRID_FETCH, GRID_RELOAD} from "../../../common/grid/grid.constants";
import {API_BASE, DATE_FORMAT} from "../../../common/utils/util.constants";
import {formatDate} from "../../../common/utils/util.date";

export const getLicences = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: `/licences/` + filter.uuid,
            method: 'GET',
            params: {
                type:               filter.type,
                status:             filter.status,
                licenceCode:        filter.licenceCode,
                searchDescendants:  filter.searchDescendants,
                offset:             filter.offset,
                limit:              filter.limit,
                exportFormat:       filter.exportFormat,
                exportColumns:      filter.exportColumns
            }
        }).then(({ data }) => {
            if (filter.exportFormat) {
                return data;
            }
            dispatch({
                type: 'GRID_FETCH',
                id: filter.identifier,
                items: data.rows.map(item => {
                    return {
                        id:            item.lic_id,
                        username:      item.username,
                        headOffice:    item.head_office,
                        issued:        formatDate(item.issue_date, DATE_FORMAT.OUTPUT),
                        expires:       formatDate(item.exp_date, DATE_FORMAT.OUTPUT),
                        uri:           item.document_uri,
                        regIdNo:       item.lic_num,
                        state:         item.state,
                        alertType:     item.alert_type,
                        type: {
                            id: item.license_type_id,
                            name: item.license_type
                        }
                    };
                }),
                itemsCount: data.total
            })
        });
    }
}

export default function AdministrationAccountsActions($http, Notifier) {

    function getManagerTypesList(identifier, uuid) {
        return dispatch => {
            return $http({
                url: API_BASE + '/manager-types-list',
                method: 'GET',
                params: {
                    uuid: uuid
                }
            }).then(response => {
                dispatch({
                    type: 'MANAGER_TYPES',
                    id: identifier,
                    path: 'managerTypesData',
                    items: response.data.map(item => {
                        return {
                            value: item.id,
                            label: item.name
                        };
                    })
                });
            });
        }
    }

    function getContactsList(filter) {
        return dispatch => {
            return $http({
                url: API_BASE + '/location/contacts-list',
                method: 'GET',
                params: {
                    uuid:           filter.uuid,
                    exportFormat:   filter.exportFormat,
                    exportColumns:  filter.exportColumns
                }
            }).then(response => {
                if (filter.exportFormat) return response.data;
                dispatch({
                    type: GRID_FETCH,
                    id: filter.identifier,
                    items: response.data.map(item => {
                        item.uuid = item.sub_uuid;
                        item.contactType = item.sty_name;
                        return item;
                    }),
                });
            });
        }
    }

    function removeContact(uuid, location, managerType, identifier, grid) {
        return dispatch => {
            return $http({
                url: API_BASE + '/remove-contact',
                method: 'POST',
                data: {
                    uuid: uuid,
                    location: location,
                    managerType: managerType
                }
            }).then(response => {
                Notifier.success('Success');
                dispatch({
                    type: 'CONTACT_REMOVE',
                    id: identifier
                });
                dispatch({
                    type: GRID_RELOAD,
                    id: grid
                });
            });
        }
    }

    function getContactDetails(state, uuid) {
        return dispatch => {
            return $http({
                url: API_BASE + "/contact-details/" + uuid,
                method: 'GET'
            }).then(response => {
                const item = response.data[0];
                dispatch({
                    type: 'CONTACT_FETCH',
                    id: state,
                    data: {
                        uuid: item.sub_uuid,
                        username: item.username,
                        gender: parseInt(item.gender),
                        firstName: item.first_name,
                        lastName: item.last_name,
                        birthDate: formatDate(item.birthdate),
                        email: item.email,
                        phone: item.phone,
                        fax: item.fax,
                        managerType: item.sty_id
                    }
                });
            }, error => {
                Notifier.responseMessage(error);
            })
        }
    }

    function updateContactDetails(identifier, contact, grid) {
        return dispatch => {
            return $http({
                url: API_BASE + "/edit-contact/" + contact.uuid,
                method: 'PUT',
                data: {
                    gender:     parseInt(contact.gender),
                    firstName:  contact.firstName,
                    lastName:   contact.lastName,
                    birthday:   formatDate(contact.birthDate),
                    phone:      contact.phone,
                    email:      contact.email.toLowerCase(),
                    fax:        contact.fax,
                    managerType:contact.managerType
                }
            }).then(response => {
                Notifier.success('Success');
                dispatch({
                    type: 'CONTACT_UPDATE',
                    id: identifier,
                    data: contact
                });
                dispatch({
                    type: GRID_RELOAD,
                    id: grid
                });
            }, error => {
                Notifier.responseMessage(error);
            })
        }
    }

    function createContactDetails(contact, parent, assistant, identifier, grid) {
        return dispatch => {
            return $http({
                url: API_BASE + "/create-contact/" + parent,
                method: "POST",
                data: {
                    username:       contact.username,
                    password:       contact.password,
                    gender:         parseInt(contact.gender),
                    firstName:      contact.firstName,
                    lastName:       contact.lastName,
                    birthDate:      formatDate(contact.birthDate),
                    phone:          contact.phone,
                    fax:            contact.fax,
                    email:          contact.email.toLowerCase(),
                    managerType:    contact.managerType
                }
            }).then(response => {
                Notifier.success('New user have been saved into the system and a notification of his login details has been sent to him.');
                dispatch({
                    type: 'CONTACT_FETCH',
                    id: identifier,
                    data: {
                        ...contact,
                        uuid: response.data.uuid,
                        username: response.data.username
                    }
                });
                dispatch({
                    type: 'PASSWORD_RESET',
                    id: identifier,
                    path: 'data.password',
                    uuid: response.data.uuid
                });
                dispatch({
                    type: 'GRID_RELOAD',
                    id: grid
                });
            }, error => {
                Notifier.responseMessage(error);
            })
        }
    }

    function assignContact(contact, location, identifier, grid) {
        return (dispatch, getState) => {
            const state = getState();
            const managerType = state.administration[identifier].data.managerType;
            contact.managerType = managerType;
            return $http({
                url: API_BASE + "/assign-contact/" + location,
                method: "PUT",
                data: {
                    contact: contact.uuid,
                    contactType: managerType
                }
            }).then(response => {
                Notifier.success('Success');
                dispatch({
                    type: 'CONTACT_FETCH',
                    id: identifier,
                    data: contact
                });
                dispatch({
                    type: GRID_RELOAD,
                    id: grid
                });
            }, error => {
                Notifier.responseMessage(error);
            })
        }
    }

    function getContactCandidates(identifier, location) {
        return dispatch => {
            return $http({
                url: API_BASE + '/contact-candidates-list',
                method: 'GET',
                params: {
                    uuid: location,
                }
            }).then(response => {
                dispatch({
                    type: 'CONTACT_CANDIDATES_FETCH',
                    id: identifier,
                    items: response.data.map(item => {
                        item.uuid = item.sub_uuid;
                        item.firstName = item.first_name;
                        item.lastName = item.last_name;
                        item.birthDate = item.birthdate;
                        return item;
                    }),
                });
            });
        }
    }

    function updateActiveModules(slo_uuid, menu_items) {
        return () => {
            return $http({
                url: API_BASE + "/location/headoffice-active-modules-edit",
                method: 'POST',
                data: {
                    slo_uuid,
                    menu_items,
                }
            }).then(response => {
                Notifier.success('Changes Saved');
            }, error => {
                Notifier.responseMessage(error);
            })
        }
    }

    return {
        getManagerTypesList,
        getContactsList,
        removeContact,
        getContactDetails,
        updateContactDetails,
        createContactDetails,
        assignContact,
        getContactCandidates,
        updateActiveModules,
    }
}

AdministrationAccountsActions.$inject = ['$http', 'Notifier'];
