import moment from 'moment/moment';

/**
 * Creates a new EquipmentCalibrationDocumentsController.
 * @class
 */
class EquipmentCalibrationDocumentsController {
    constructor($ngRedux, $scope, $state, $mdDialog, $timeout, Notifier, NavigationService, EquipmentService, EquipmentCalibrationActions) {
        this.$ngRedux = $ngRedux;
        this.$scope = $scope;
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.$timeout = $timeout;
        this.Notifier = Notifier;
        this.NavigationService = NavigationService;
        this.EquipmentService = EquipmentService;
        this.actions = EquipmentCalibrationActions;
        this.dialog = null;
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;
        this.minDocumentExpiryDate = new Date();
        this.maxServiceDocumentDate = new Date();

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);

        if (this.equipmentId) {
            this.identifier = 'app.equipmentCalibration.manage';
            this.state = 'app.equipmentCalibration.manage.documents';
        }
        else {
            this.identifier = 'app.equipmentCalibration.add';
            this.state = 'app.equipmentCalibration.add.documents';
        }

        this.getEquipmentDocumentTypes(this.equipmentId);
        this.getDocumentStatusList();
        this.getDocumentResults();
    }

    onInspectedDate = (event) => {
        const {expiryDate} = this.document;
        if (expiryDate === null) {
            this.$ngRedux.dispatch({
                type: 'DATE_SELECTED',
                id: 'document',
                data: {expiryDate: moment(event.input).add(1, 'years').toDate()}
            });
        }
    };

    onDocumentTypeChange = (event) => {
        this.getDocumentInspectors(this.equipmentId, event.input);
    };

    onSave(event) {
        this.Notifier.info('Please wait...');
        this.saveDocument(event, this.document, this.equipmentId).then(response => {
            this.$scope.uploadForm.$setPristine();
            this.$scope.uploadForm.$setUntouched();
            this.Notifier.success('Import completed!');
        }, error => {
            this.Notifier.responseMessage(error);
        });
    }

    onRemove({ item }) {
        const confirm = this.$mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Chosen document will be removed permanently.')
            .ariaLabel('Remove document')
            .ok('Yes')
            .cancel('No');

        this.dialog = this.$mdDialog.show(confirm).then(() => {
            this.EquipmentService.invalidateDocument(item.id).then(() => {
                this.$ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'equipmentCalibrationDocumentsGrid'
                });
                this.Notifier.success('Success');
            }, (error) => {
                this.Notifier.responseMessage(error);
            });
        }, angular.noop);
    }

    mapState(state) {
        return {
            equipmentId: state.equipmentCalibration.equipment.id,
            document: state.equipmentCalibration.document
        };
    }

    $onDestroy() {
        this.unsubscribe();
        if (this.dialog) {
            this.$mdDialog.hide(this.dialog);
        }
    }
}

EquipmentCalibrationDocumentsController.$inject = [
    '$ngRedux',
    '$scope',
    '$state',
    '$mdDialog',
    '$timeout',
    'Notifier',
    'NavigationService',
    'EquipmentService',
    'EquipmentCalibrationActions'
];

export const equipmentCalibrationDocuments = {
    controller: EquipmentCalibrationDocumentsController,
    template: require('./equipment-calibration-documents.html')
};
