import {
    getManufacturerEquTypes,
    getManufacturerEquModelDetails,
} from './manufacturer-equ.actions';
import { LOADING } from '../../../../common/navigation/navigation.actions.types';
import { TAB_SHOW } from '../../../../common/tabs/tabs.constants';

export const manufacturerEquipment = {
    /* @ngInject */
    controller: function($ngRedux, $state, $uiRouterGlobals) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.stateId = $state.$current.parent.name;
            this.unsubscribe = $ngRedux.connect(() => ({}), {
                getManufacturerEquTypes,
                getManufacturerEquModelDetails,
            })(this);

            this.getManufacturerEquTypes();
        };

        /**
         * @function $onSelect
         */
        this.onSelect = ({ item }) => {
            $ngRedux.dispatch({ type: LOADING, data: true });
            this.getManufacturerEquModelDetails(item.emo_id).then(() => {
                $ngRedux.dispatch({
                    type: TAB_SHOW,
                    id: this.stateId,
                    state: [`${this.stateId}.equipmentModel`],
                    stateParams: {
                        id: $uiRouterGlobals.params.id,
                        model: item.emo_id,
                    },
                });
                $ngRedux.dispatch({ type: LOADING, data: false });
            });
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            this.unsubscribe();
        };
    },
    template: `
        <manufacturer-equipment-grid
            on-select="$ctrl.onSelect($event)"
            on-modify="$ctrl.onSelect($event)">
        </manufacturer-equipment-grid>
    `,
};
