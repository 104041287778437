/* @ngInject */
export function AdministrationAccountsRoomEquipmentController($ngRedux) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, {})(ctrl);
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    function mapStateToThis(state) {
        return {
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                state.administration.administrationAccountsDepartment.data.typeName,
                state.administration.administrationAccountsRoom.data.name,
                'Equipment'
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
