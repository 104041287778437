import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsSiteLocationRscAssistantsController($ngRedux, $uiRouterGlobals, $mdDialog, AdministrationActions) {
    var ctrl = this;
    var dialog = null;
    var unsubscribe = angular.noop;
    var onAssistant = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);

        var watchAssistant = watch($ngRedux.getState, 'administration.administrationAccountsSiteLocationRscAssistant.data.uuid');
        onAssistant = $ngRedux.subscribe(watchAssistant(function(uuid) {
            onAssistantReady(uuid);
        }));
    };

    ctrl.newAssistant = function() {
        if (ctrl.assistant.uuid) {
            $ngRedux.dispatch({
                type: 'RSC_RESET',
                id: 'administrationAccountsSiteLocationRscAssistant'
            });
        }
        $ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: 'app.administration.accounts.siteLocations',
            state: ['app.administration.accounts.siteLocations.rscAssistantDetails'],
            stateParams: $uiRouterGlobals.params
        });
    };

    ctrl.selectAssistant = function(event) {
        if (event.item.subject_uuid === ctrl.assistant.uuid)
            onAssistantReady(event.item.subject_uuid);
        else
            ctrl.getRscDetails('administrationAccountsSiteLocationRscAssistant', event.item.subject_uuid);
    };

    ctrl.removeAssistant = function(event) {
        var confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('RSC Assistant will be deactivated.')
            .ariaLabel('Deactivate RSC Assistant')
            .ok('Yes')
            .cancel('No');

        dialog = $mdDialog.show(confirm).then(function() {
            ctrl.removeRsc(event.item.subject_uuid, ctrl.siteLocation.uuid, 'administrationAccountsSiteLocationRscAssistant', 'administrationAccountsSiteLocationRscAssistantsGrid');
        }, angular.noop);
    };

    ctrl.$onDestroy = function() {
        if (dialog) $mdDialog.hide(dialog);
        unsubscribe();
        onAssistant();
    };

    function onAssistantReady(uuid) {
        $ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: 'app.administration.accounts.siteLocations',
            state: ['app.administration.accounts.siteLocations.rscAssistantDetails'],
            stateParams: {
                hof: $uiRouterGlobals.params.hof,
                slo: $uiRouterGlobals.params.slo,
                assistant: uuid
            }
        });
        $ngRedux.dispatch({
            type: 'TAB_LOADING',
            id: 'app.administration.accounts.siteLocations',
            state: 'app.administration.accounts.siteLocations.rscAssistantDetails',
            loading: false
        });
    }

    function mapStateToThis(state) {
        return {
            siteLocation: state.administration.administrationAccountsSiteLocation.data,
            assistant: state.administration.administrationAccountsSiteLocationRscAssistant.data,
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                'RSC Assistants'
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
