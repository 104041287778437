import { API_BASE, DATE_FORMAT } from '../../../../common/utils/util.constants';
import moment from 'moment';
import { formatDate } from '../../../../common/utils/util.date';
import isObject from 'lodash/isObject';

export default function EquipmentCalibrationLoanActions($http) {
    return {
        getLoanPersons: (keyword, location) => {
            return dispatch => {
                return $http({
                    url: `${API_BASE}/employees`,
                    method: 'GET',
                    params: {
                        keyword,
                        location,
                        status: 'act',
                        licences: false,
                        offset: 0,
                        limit: 20
                    }
                }).then(response => {
                    dispatch({
                        id: 'equipmentLoan',
                        type: 'EQUIPMENT_LOAN_PERSONS',
                        data: response.data
                    });
                });
            };
        },
        resetLoanPerson: () => {
            return dispatch => {
                dispatch({
                    id: 'equipmentLoan',
                    type: 'EQUIPMENT_LOAN_PERSON_RESET'
                });
            };
        },
        submitLoan: (data) => {
            return dispatch => {
                return $http({
                    url: `${API_BASE}/equipment-calibration/loan/submit`,
                    method: 'POST',
                    data
                }).then(response => {
                    dispatch({
                        id: 'equipmentLoan',
                        type: 'EQUIPMENT_LOAN_SUBMIT'
                    });
                    dispatch({
                        id: 'equipmentCalibrationGrid',
                        type: 'GRID_RELOAD'
                    });
                });
            };
        },
        getLoanDetails: (id) => {
            return dispatch => {
                return $http({
                    url: `${API_BASE}/equipment-calibration/loan/details`,
                    method: 'GET',
                    params: { id }
                }).then(({ data }) => {
                    dispatch({
                        id: 'equipmentLoan',
                        type: 'EQUIPMENT_LOAN_DETAILS',
                        data: isObject(data) ? [data] : data
                    });
                });
            };
        },
        getLoanHistory: (filter) => {
            return dispatch => {
                return $http({
                    url: `${API_BASE}/equipment-calibration/loan/history`,
                    method: 'GET',
                    params: {
                        equ_id: filter.equ_id,
                        loaned_person_keyword: filter.loan_person,
                        receipted_person_keyword: filter.receipt_person,
                        loan_date_from: formatDate(filter.date_from),
                        loan_date_to: formatDate(filter.date_to),
                        loan_due_date_from: formatDate(filter.due_date_from),
                        loan_due_date_to: formatDate(filter.due_date_to),
                        offset: filter.offset,
                        limit: filter.limit
                    }
                }).then(response => {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: filter.identifier,
                        items: response.data.items.map((item) => {
                            return {
                                ...item,
                                class: item.receipted_by && item.return_date ? '' : (moment().isAfter(moment(item.loan_due_date, DATE_FORMAT.OUTPUT)) ? 'loanOverdue' : '')
                            };
                        }),
                        itemsCount: response.data.items_count
                    });
                });
            };
        },
        getLoanHistoryDetails: (eql_id) => {
            return dispatch => {
                return $http({
                    url: `${API_BASE}/equipment-calibration/loan/history/details`,
                    method: 'GET',
                    params: { eql_id }
                }).then(({ data }) => {
                    dispatch({
                        type: 'EQUIPMENT_LOAN_HISTORY_DETAILS',
                        id: 'equipmentLoan',
                        data: isObject(data) ? [data] : data
                    });
                    return isObject(data) ? data : data[0];
                });
            };
        },
        getReceiptPersons: (keyword, location) => {
            return dispatch => {
                return $http({
                    url: `${API_BASE}/employees`,
                    method: 'GET',
                    params: {
                        keyword,
                        location,
                        status: 'act',
                        licences: false,
                        offset: 0,
                        limit: 20
                    }
                }).then((response) => {
                    dispatch({
                        id: 'equipmentLoan',
                        type: 'EQUIPMENT_LOAN_RECEIPT_PERSONS',
                        data: response.data.list
                    });
                });
            };
        },
        submitLoanReceipt: (data) => {
            return dispatch => {
                return $http({
                    url: `${API_BASE}/equipment-calibration/loan/history/receipt`,
                    method: 'POST',
                    data
                }).then(() => {
                    dispatch({
                        id: 'equipmentCalibrationLoanHistoryGrid',
                        type: 'GRID_RELOAD'
                    });
                    dispatch({
                        id: 'equipmentCalibrationGrid',
                        type: 'GRID_RELOAD'
                    });
                });
            };
        },
        resetLoanHistory: () => {
            return dispatch => {
                dispatch({
                    id: 'equipmentLoan',
                    type: 'EQUIPMENT_LOAN_HISTORY_RESET'
                });
            };
        },
    };
}

EquipmentCalibrationLoanActions.$inject = ['$http'];
