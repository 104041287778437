/*
*   List of IDs of actions that should be passed from parent reducer
 */

export const IDS = {
    // components:
    administrationAccountsHeadOfficeActiveModules:             'administrationAccountsHeadOfficeActiveModules',

    // tab states:
};
