import { formatDate } from '../../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../../common/utils/util.constants';
import find from 'lodash/find';

/**
 * Creates a new EquipmentCalibrationLoanController.
 * @class
 */
class EquipmentCalibrationLoanController {
    constructor($ngRedux, $state, $mdDialog, NavigationService, EquipmentCalibrationLoanActions, Notifier) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.NavigationService = NavigationService;
        this.actions = EquipmentCalibrationLoanActions;
        this.Notifier = Notifier;
        this.dialog = null;
    }

    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);

        this.identifier = 'app.equipmentCalibration.manage';
        this.state = 'app.equipmentCalibration.manage.loan';

        this.getLoanDetails(this.equipment.id);
    }

    onGetLoanPersons = async (event) => {
        const location = find(this.equipment.locations, ['type_code', 'hof']);
        return await this.getLoanPersons(event, location.sub_uuid);
    }

    onNewPerson() {
        const loanPersonCtrl = ($scope) => {
            $scope.save = () => {
                if ($scope.equipmentLoanPersonForm.$valid) {
                    this.$ngRedux.dispatch({
                        type: 'EQUIPMENT_LOAN_PERSON_SAVE',
                        id: 'equipmentLoan'
                    });
                    if (this.loan.id) {
                        this.submitLoan({
                            equ_id:         this.equipment.id,
                            eql_id:         this.loan.id,
                            emp_uuid:       this.loan.loan_person.subject_uuid,
                            first_name:     this.loan.loan_person_first_name,
                            last_name:      this.loan.loan_person_last_name,
                            email:          this.loan.loan_person_email,
                            contact_number: this.loan.loan_person_phone,
                            campus:         this.loan.loan_person_campus,
                            department:     this.loan.loan_person_department,
                            creation_date:  formatDate(this.loan.creation_date, DATE_FORMAT.OUTPUT),
                            return_by_date: formatDate(this.loan.return_date, DATE_FORMAT.OUTPUT),
                            comment:        this.loan.comments,
                            signature:      this.loan.signature,
                            date:           formatDate(this.loan.signature_date, DATE_FORMAT.OUTPUT)
                        }).then(() => {
                            this.Notifier.success('New loan person has been successfully saved');
                            this.$mdDialog.hide();
                        });
                    } else {
                        this.$mdDialog.hide();
                    }
                } else {
                    this.Notifier.error('Please fill in the form fields');
                }
            };
            $scope.cancel = () => {
                this.$mdDialog.cancel();
            };
        };
        loanPersonCtrl.$inject = ['$scope'];

        this.dialog = this.$mdDialog.show({
            controller: loanPersonCtrl,
            template: require('./dialogs/equipment-calibration-loan-person-dialog.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: true
        }).then(this.resetLoanPerson, this.resetLoanPerson);
    }

    onSubmit() {
        if (this.equipmentLoanForm.$valid) {
            const confirm = this.$mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Loan equipment form is entered correctly.')
                .ariaLabel('Submit Loan Form')
                .ok('Yes')
                .cancel('No');
            this.dialog = this.$mdDialog.show(confirm).then(() => {
                this.submitLoan({
                    equ_id: this.equipment.id,
                    eql_id: this.loan.id,
                    emp_uuid: this.loan.loan_person.subject_uuid,
                    first_name: this.loan.loan_person.first_name,
                    last_name: this.loan.loan_person.last_name,
                    email: this.loan.email,
                    contact_number: this.loan.contact_number,
                    campus: this.loan.campus,
                    department: this.loan.department,
                    creation_date: formatDate(this.loan.creation_date, DATE_FORMAT.OUTPUT),
                    return_by_date: formatDate(this.loan.return_date, DATE_FORMAT.OUTPUT),
                    comment: this.loan.comments,
                    signature: this.loan.signature,
                    date: formatDate(this.loan.signature_date, DATE_FORMAT.OUTPUT)
                }).then(() => {
                    this.$ngRedux.dispatch({
                        type: 'EQUIPMENT_LOAN_RESET',
                        id: 'equipmentLoan'
                    });
                    this.$ngRedux.dispatch({
                        type: 'TAB_SELECT',
                        id: 'app.equipmentCalibration.manage',
                        state: 'app.equipmentCalibration.manage.listing'
                    });
                    this.Notifier.success('Loan equipment form has been successfully submitted');
                }).catch((error) => {
                    this.Notifier.error(error.data);
                });
            }, angular.noop);
        } else {
            this.Notifier.error('Please fill in the form fields');
        }
    }

    mapState(state) {
        return {
            equipment: state.equipmentCalibration.equipment,
            loan: state.equipmentCalibration.equipmentLoan
        };
    }

    $onDestroy() {
        this.unsubscribe();
        if (this.dialog)
            this.$mdDialog.hide(this.dialog);
    }
}

EquipmentCalibrationLoanController.$inject = ['$ngRedux', '$state', '$mdDialog', 'NavigationService', 'EquipmentCalibrationLoanActions', 'Notifier'];

export const equipmentCalibrationLoan = {
    controller: EquipmentCalibrationLoanController,
    template: require('./equipment-calibration-loan.html')
};
