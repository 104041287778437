/** @module root/common/dashboard */

import * as STATES from './dashboard.states';
import { dashboard } from './dashboard.component';
import { dashboardAlerts } from './alerts/dashboard-alerts.component';
import { dashboardAnalytics } from './analytics/dashboard-analytics.component';
import { dashboardAnalyticsChart } from './analytics/chart/dashboard-analytics-chart.component';
import { dashboardSwitch } from './switch/dashboar-switch-component';

export const DASHBOARD_MODULE = angular.module(
    'common.dashboard',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values({
        ...STATES,
    }).map(state => stateRegistry.register(state))
}]).component({
    dashboard,
    dashboardAlerts,
    dashboardAnalytics,
    dashboardAnalyticsChart,
    dashboardSwitch,
});
