import {TAB_SHOW, TAB_HIDE, TAB_SELECT} from "../../../common/tabs/tabs.constants";

/**
 * Creates a new AdministrationMonthlyReportSettingsController.
 * @class
 * @memberOf module:root/legacy/components/administration
 */
class AdministrationMonthlyReportSettingsController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param $mdDialog {service}
     * @param $rootScope {service}
     * @param NavigationService {service} {@link module:root/common.NavigationService|NavigationService}
     * @param AdministrationMonthlyReportSettingsActions {service}
     */
    constructor($ngRedux, $state, $mdDialog, $rootScope, NavigationService, AdministrationMonthlyReportSettingsActions) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.$rootScope = $rootScope;
        this.NavigationService = NavigationService;
        this.actions = AdministrationMonthlyReportSettingsActions;
    }

    /**
     * @method $onInit
     * @memberOf module:root/components/administration.AdministrationMonthlyReportSettingsController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        const navConfig = this.NavigationService.getConfig(this.$state.$current);
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), this.actions)(this);
    }

    /**
     * @method onMonthlyReportSelect
     * @param event {object}
     * @memberOf module:root/components/administration.AdministrationMonthlyReportSettingsController
     */
    onMonthlyReportSelect = (event) => {
        this.$ngRedux.dispatch({
            type: 'MONTHLY_REPORT_DETAILS',
            id: 'administrationMonthlyReportSettings',
            data: {
                loc_uuid: event.item.loc_uuid,
                loc_name: event.item.loc_name,
                generate_corporate_report: event.item.generate_corporate_report === 'Yes' ? 1 : 0,
                generate_by_sits_report: event.item.generate_by_sits_report === 'Yes' ? 1 : 0,
                generate_corporate_report_to: event.item.generate_corporate_report_to,
                generate_by_sits_report_to: event.item.generate_by_sits_report_to
            }
        });
        let tabs = ['app.administration.monthlyReportSettings.details'];
        this.$ngRedux.dispatch({
            type: TAB_SHOW,
            id: 'app.administration.monthlyReportSettings',
            state: tabs
        });

        this.$ngRedux.dispatch({
            type: TAB_SELECT,
            id: 'app.administration.monthlyReportSettings',
            state: 'app.administration.monthlyReportSettings.details'
        });
    };

    /**
     * @method mapState
     * @param state {object} Redux Store
     * @return {object}
     * @memberof module:root/components/administration.AdministrationMonthlyReportSettingsController
     * @description Subscribe to Redux store updates
     * @see {@link https://github.com/angular-redux/ng-redux#api|Subscribe to Redux store updates}
     */
    mapState(state) {
        return {
            monthlyReport: state.administrationMonthlyReportSettings
        }
    }
}

AdministrationMonthlyReportSettingsController.$inject = ['$ngRedux', '$state', '$mdDialog', '$rootScope', 'NavigationService', 'AdministrationMonthlyReportSettingsActions'];

export const administrationMonthlyReportSettings = {
    controller: AdministrationMonthlyReportSettingsController,
    template: `
        <p>By default corporate reports are sent to Head Office RSC users and "By Sites" reports are sent to Site RSC and Site Assistant RSC users.</p>
        <administration-monthly-report-settings-grid 
            title="Monthly Report Settings" 
            identifier="administrationMonthlyReportSettingsGrid"
            on-select="$ctrl.onMonthlyReportSelect($event)">
        </administration-monthly-report-settings-grid>
    `
};
