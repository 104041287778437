import isEmpty from 'lodash/isEmpty';

/**
 * Creates a new Session.
 * @class
 */
export class Session {
    /**
     * @constructor
     */
    constructor($ngRedux, $state, $uiRouterGlobals) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$uiRouterGlobals = $uiRouterGlobals;
    }

    setAuthTokens(response) {
        localStorage.setItem('myrsoAuthToken', response.headers('X-Authorization'));
        localStorage.setItem('myrsoJWT', response.headers('X-Token'));
    }

    setUser({data, config}) {
        const user = {
            uuid:               data.subject,
            type:               data.type,
            typeCode:           data.typeCode,
            basicTypeName:      data.basicTypeName,
            basicTypeCode:      data.basicTypeCode,
            name:               config.data.username || data.username,
            country:            data.country,
            data:               data.parents.map(item => item.name).join(' / '),
            employees_mutable:  parseInt(data.employeesMutable),
            allowedContainers:  data.allowedContainers,
            funcs:              data.funcs,
            hofName:            data.hofName,
            hofUuid:            data.hofUuid,
            isPublicLogin:      !!data.task,
            parentCount:        data.parentCount,
            parents:            data.parents.map(par => ({
                uuid:       par.uuid,
                name:       par.name,
                hof:        par.hof_uuid,
                slo:        par.slo_uuid,
                typeId:     par.basic_type_id,
                basicType:  par.basic_type_code
            })),
        };

        localStorage.setItem('myrsoUser', JSON.stringify(user));
    }

    getUser() {
        return JSON.parse(localStorage.getItem('myrsoUser'));
    }

    getRecovery() {
        return JSON.parse(localStorage.getItem('myrsoRecovery'));
    }

    /**
     * @function setRecovery
     */
    setRecovery() {
        const store = this.$ngRedux.getState();
        if (store.app.user.uuid) {
            const recovery = this.getRecovery();
            localStorage.setItem('myrsoRecovery', JSON.stringify({
                ...recovery,
                [store.app.user.uuid]: {
                    state: this.$state.current.name,
                    params: this.$uiRouterGlobals.params,
                },
            }));
        }
    }

    /**
     * @function destroyRecovery
     * @param {string} key
     */
    destroyRecovery(key) {
        const recovery = this.getRecovery();
        delete recovery[key];
        delete recovery['default'];
        isEmpty(recovery)
            ? localStorage.removeItem('myrsoRecovery')
            : localStorage.setItem('myrsoRecovery', JSON.stringify(recovery));
    }

    destroy() {
        localStorage.removeItem('myrsoAuthToken');
        localStorage.removeItem('myrsoJWT');
        localStorage.removeItem('myrsoUser');
    }
}

Session.$inject = ['$ngRedux', '$state', '$uiRouterGlobals'];
