import { TAB_HIDE } from '../../common/tabs/tabs.constants';

export const eventsState = {
    name: 'app.events',
    abstract: true,
    url: '/events'
};

export const eventsDashboardState = {
    name: 'app.events.dashboard',
    url: '',
    component: 'dashboard'
};

export const eventsManageState = {
    name: 'app.events.manageRisksIncidents',
    redirectTo: 'app.events.manageRisksIncidents.listing',
    url: '/manage',
    component: 'tabs'
};

export const eventsManageListingState = {
    name: 'app.events.manageRisksIncidents.listing',
    url: '',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            id: 'eventItem',
            type: 'EVENT_RESET'
        });
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: 'app.events.manageRisksIncidents',
            state: [
                'app.events.manageRisksIncidents.details',
                'app.events.manageRisksIncidents.assessmentHazard',
                'app.events.manageRisksIncidents.assessmentIncident',
                'app.events.manageRisksIncidents.assessmentThreat',
                'app.events.manageRisksIncidents.assessmentIssue',
                'app.events.manageRisksIncidents.assessmentHistoricalRecord',
                'app.events.manageRisksIncidents.alertEscalate',
                'app.events.manageRisksIncidents.actionPlan',
                'app.events.manageRisksIncidents.actionPlanLibrary',
                'app.events.manageRisksIncidents.actionTaskLibrary'
            ]
        });
    }
};

export const eventsManageDetailsState = {
    name: 'app.events.manageRisksIncidents.details',
    url: '/details?id'
};

export const eventsManageAssessmentHazardState = {
    name: 'app.events.manageRisksIncidents.assessmentHazard',
    url: '/assessment?id',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            id: 'app.events.manageRisksIncidents',
            type: TAB_HIDE,
            state: ['app.events.manageRisksIncidents.assessmentHistoricalRecord']
        });
    }
};

export const eventsManageAssessmentThreatState = {
    name: 'app.events.manageRisksIncidents.assessmentThreat',
    url: '/assessment?id',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            id: 'app.events.manageRisksIncidents',
            type: TAB_HIDE,
            state: ['app.events.manageRisksIncidents.assessmentHistoricalRecord']
        });
    }
};

export const eventsManageAssessmentHistoricalRecordState = {
    name: 'app.events.manageRisksIncidents.assessmentHistoricalRecord',
    url: '/assessment/historical-record?id'
};

export const eventsManageAssessmentIncidentState = {
    name: 'app.events.manageRisksIncidents.assessmentIncident',
    url: '/assessment?id',
};

export const eventsManageAssessmentIssueState = {
    name: 'app.events.manageRisksIncidents.assessmentIssue',
    url: '/assessment?id',
};

export const eventsManageAlertEscalateState = {
    name: 'app.events.manageRisksIncidents.alertEscalate',
    url: '/alert-escalate?id'
};

export const eventsManageActionPlanState = {
    name: 'app.events.manageRisksIncidents.actionPlan',
    url: '/action-plan?id'
};

export const eventsManageActionPlanLibraryState = {
    name: 'app.events.manageRisksIncidents.actionPlanLibrary',
    url: '/action-plan-library',
    /* @ngInject */
    onExit: ($ngRedux) => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: 'app.events.manageRisksIncidents',
            state: ['app.events.manageRisksIncidents.actionPlanLibrary']
        });
    }
};

export const eventsManageActionTaskLibraryState = {
    name: 'app.events.manageRisksIncidents.actionTaskLibrary',
    url: '/action-task-library',
    /* @ngInject */
    onExit: ($ngRedux) => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: 'app.events.manageRisksIncidents',
            state: ['app.events.manageRisksIncidents.actionTaskLibrary']
        });
    }
};
