import moment from 'moment';
import { saveAs } from 'file-saver';
import isObject from 'lodash/isObject';
import first from 'lodash/first';
import {API_BASE} from '../../../../common/utils/util.constants';

/* @ngInject */
export function EquipmentService($http, API_BASE, Helpers, Manufacturer, Equipment, EquipmentSummary, Xray, EquipmentType, EquipmentCategory, EquipmentProperty, ServiceDetails, DATE_FORMAT) {

    function appendTransform(defaults, transform) {
        defaults = angular.isArray(defaults) ? defaults : [defaults];
        return defaults.concat(transform);
    }

    this.fetchTypes = function(parent, direct, sort) {
        var params = [];
        if (typeof parent != 'undefined' && parent != null)
            params['parent'] = parent;
        if (typeof direct != 'undefined' && direct != null)
            params['direct'] = (direct === true) ? 1 : 0;

        return $http({
            url: API_BASE + "/equipment/types",
            method: "GET",
            params: params
        }).then(function(data) {
            if (typeof sort != 'undefined' && sort)
                return sortTypes(data.data);
            else
                return data.data;
        }, function(error) {
            return error;
        });
    };

    this.getCategories = function(ety_code) {
        return $http({
            url: API_BASE + "/equipment/categories",
            method: "GET",
            params: {ety_code}
        }).then(function(response) {
            return response.data.map(function(item) {
                var category    = new EquipmentCategory();
                category.id     = item.category_id;
                category.name   = item.category_name;
                return category;
            });
        });
    };

    this.addCategory = function(category) {
        return $http({
            url: API_BASE + "/equipment/1/category",
            method: "POST",
            data: {
                categoryCode : category.code,
                categoryName : category.name
            }
        }).then(function(response) {
            return response;
        }, function(error) {
            return error;
        });
    };

    this.addDocument = function(doc) {
        return $http({
            url: API_BASE + "/equipment/" + doc.id + "/document",
            method: "POST",
            data: {
                documentType: doc.type,
                issued: doc.issued,
                nextDueDate: Helpers.formatDate(doc.expires),
                documentUri: doc.uri
            }
        }).then(function(response) {
            return response;
        }, function(error) {
            return error;
        });
    };

    this.editDocument = function(doc) {
        return $http({
            url: API_BASE+ "/equipment/document/" + doc.doc_id,
            method: "PUT",
            data: {
                nextDueDate: Helpers.formatDate(doc.due_date)
            }
        }).then(function(response) {
            return {
                data: response.data,
                status: response.status
            };
        }, function(error) {
            return error;
        });
    };

    this.fetchDocument = function(id, format) {
        return $http({
            url: API_BASE + "/equipment/" + id,
            method: "GET",
            params: {format: format}
        }).then(function(response) {
            return response.data;
        }, function(error) {
            return error;
        });
    };

    this.fetchNextId = function(typeID) {
        return $http({
            url: API_BASE + "/equipment/next/" + typeID,
            method: "GET"
        }).then(({ data }) => {
            return isObject(data) ? data.equipment_id : data;
        }, (error) => error);
    };

    this.fetchXrays = function() {
        return $http({
            url: API_BASE + "/equipment/xray",
            method: "GET"
        }).then(function(response) {
            var arr = [];
            response.data.list.forEach(function(elem) {
                var item = new Xray();
                item.user_friendly_id = elem.equipment_id;
                item.equipment_id = elem.equ_id;
                item.account.name = elem.account;
                item.manufacturer.name = elem.manufacturer;
                item.serial = elem.serial;
                item.category.name = elem.category;
                item.model.name = elem.model;
                arr.push(item);
            });
            return {
                list: arr,
                rows: response.data.rows
            };
        }, function(error) {
            return error;
        });
    };

    this.filterInspections = function(filter) {
        var params = [];
        params.location = filter.location;
        params.manufacturer = filter.manufacturer;
        params.status = filter.status;
        params.types = filter.categories.length ? filter.categories.join() : null;
        params['date-from'] = Helpers.formatDate(filter.inspectionDateFrom);
        params['date-to'] = Helpers.formatDate(filter.inspectionDateTo);
        params['exp-from'] = Helpers.formatDate(filter.expiryDateFrom);
        params['exp-to'] = Helpers.formatDate(filter.expiryDateTo);
        params['offset'] = filter.offset;
        params['limit'] = filter.limit;
        params['exportFormat'] = filter.exportFormat;
        params['exportColumns'] = filter.exportColumns;

        if (typeof filter.id != 'undefined' && filter.id != null)
            params['id'] = filter.id;

        return $http({
            url: API_BASE + "/inspections",
            method: "GET",
            params: params
        }).then(function(response) {
            if (filter.exportFormat) {
                return response.data;
            }
            else {
                var arr = [];
                response.data.list.forEach(function(elem) {
                    var item = {};
                    item.id         = elem.ins_id;
                    item.state      = elem.state == 0 || elem.state == 100 ? "Pass" : "Fail";
                    item.comment    = elem.comment;
                    item.equipment_id = elem.equipment_id;
                    item.equ_id     = elem.equ_id;
                    item.category   = elem.category;
                    item.manufacturer = elem.manufacturer;
                    item.model      =  elem.model;
                    item.account    = elem.account_name;
                    item.serial    = elem.serial;
                    item.report_type    = elem.report_type;
                    item.doc_uri    = elem.doc_uri;
                    item.inspected  = Helpers.formatDate(elem.date_inspected);
                    item.exp_date   = Helpers.formatDate(elem.exp_date);
                    // TODO these should be functions
                    item.getInspectedDate   = Helpers.formatDate(elem.date_inspected, DATE_FORMAT.INPUT);
                    item.getExpiryDate      = Helpers.formatDate(elem.exp_date, DATE_FORMAT.INPUT);
                    arr.push(item);
                });
                return {
                    list: arr,
                    rows: response.data.rows
                };
            }
        });
    };

    this.getEquipmentGrid = function(filter) {
        return $http({
            url: API_BASE + '/equipment/list-grid',
            method: "GET",
            params: {
                ...filter,
                dueDateFrom:    Helpers.formatDate(filter.dueDateFrom),
                dueDateTo:      Helpers.formatDate(filter.dueDateTo),
                dueDays:        filter.requirementsDaysRange,
                dueDateType:    filter.requirementType,
                allRequirements:filter.allRequirementsDue,
                order_column:   _.get(filter, 'sort.column'),
                order:          _.get(filter, 'sort.value')
            }
        }).then(function(response) {
            var items = response.data[0].map(function (item) {
                var equipment = new Equipment();
                equipment.typeCode = item.base_equipment_type_code;
                equipment.id = item.equ_id;
                equipment.user_friendly_id = item.equipment_id;
                equipment.equipment_id = item.equ_id;
                equipment.transaction_id = item.transaction_id;
                equipment.transaction_type = item.transaction_type;
                equipment.status = item.status;
                equipment.account.name = item.account_name;
                equipment.account.uuid = item.account_uuid;
                equipment.manufacturer.name = item.manufacturer;
                equipment.serial = item.serial;
                equipment.category.id = item.category_id;
                equipment.category.code = item.category_code;
                equipment.category.name = item.category;
                equipment.model.name = item.model;
                equipment.critical = item.critical;
                equipment.warning = item.warning;
                equipment.certificateDueDate = Helpers.formatDate(item.cer_due_date, DATE_FORMAT.INPUT);
                equipment.certificateNumber = item.cer_number;
                equipment.serviceDueDate = Helpers.formatDate(item.ser_due_date, DATE_FORMAT.INPUT);
                equipment.installationDate = Helpers.formatDate(item.installation_date, DATE_FORMAT.INPUT);
                equipment.capitalSensitivityExpiryDate = Helpers.formatDate(item.in_cap_sens_exp_date, DATE_FORMAT.INPUT);
                equipment.premisesCertification = Helpers.formatDate(item.premises_certification, DATE_FORMAT.INPUT);
                equipment.assetID = item.asset_id;
                equipment.roomName = item.room_name;
                equipment.roomNo = item.room_no;
                equipment.department = item.department;
                return equipment;
            });
            return {
                items,
                totalActive:  parseInt(response.data[1].find(item => item.status === 'act')?.count || 0),
                totalArchive: parseInt(response.data[1].find(item => item.status === 'dis')?.count || 0)
                    + parseInt(response.data[1].find(item => item.status === 'sel')?.count || 0)
            };
        });
    };

    this.getEquipmentExportDocument = function(filter) {
        return $http({
            url: API_BASE + '/equipment-export-document',
            method: "GET",
            params: {
                ...filter,
                dueDateFrom:    Helpers.formatDate(filter.dueDateFrom),
                dueDateTo:      Helpers.formatDate(filter.dueDateTo),
                dueDays:        filter.requirementsDaysRange,
                dueDateType:    filter.requirementType,
                allRequirements:filter.allRequirementsDue,
                order_column:   _.get(filter, 'sort.column'),
                order:          _.get(filter, 'sort.value')
            }
        }).then(function({data}) {
            return data;
        });
    };

    this.getEquipment = function(filter) {
        return $http({
            url: API_BASE + '/equipment/filter',
            method: "GET",
            params: {
                type:           filter.type,
                typeCode:       filter.typeCode,
                status:         filter.status,
                equipmentId:    filter.equipmentId,
                serial:         filter.serial,
                location:       filter.location,
                category:       filter.category,
                manufacturer:   filter.manufacturer,
                model:          filter.model,
                dueDateFrom:    Helpers.formatDate(filter.dueDateFrom),
                dueDateTo:      Helpers.formatDate(filter.dueDateTo),
                dueDays:        filter.requirementsDaysRange,
                dueDateType:    filter.requirementType,
                allRequirements:filter.allRequirementsDue,
                offset:         filter.offset,
                limit:          filter.limit,
                exportFormat:   filter.exportFormat,
                exportColumns:  filter.exportColumns,
                order_column:   _.get(filter, 'sort.column'),
                order:          _.get(filter, 'sort.value')
            }
        }).then(function(response) {
            if (filter.exportFormat) {
                return response.data;
            }
            else {
                var items = response.data.items.map(function (item) {
                    var equipment = new Equipment();
                    equipment.typeCode = item.base_equipment_type_code;
                    equipment.id = item.equ_id;
                    equipment.user_friendly_id = item.equipment_id;
                    equipment.equipment_id = item.equ_id;
                    equipment.transaction_id = item.transaction_id;
                    equipment.transaction_type = item.transaction_type;
                    equipment.status = item.status;
                    equipment.account.name = item.account_name;
                    equipment.account.uuid = item.account_uuid;
                    equipment.manufacturer.name = item.manufacturer;
                    equipment.serial = item.serial;
                    equipment.category.id = item.category_id;
                    equipment.category.code = item.category_code;
                    equipment.category.name = item.category;
                    equipment.model.name = item.model;
                    equipment.critical = item.critical;
                    equipment.warning = item.warning;
                    equipment.certificateDueDate = Helpers.formatDate(item.cer_due_date, DATE_FORMAT.INPUT);
                    equipment.certificateNumber = item.cer_number;
                    equipment.serviceDueDate = Helpers.formatDate(item.ser_due_date, DATE_FORMAT.INPUT);
                    equipment.installationDate = Helpers.formatDate(item.installation_date, DATE_FORMAT.INPUT);
                    equipment.capitalSensitivityExpiryDate = Helpers.formatDate(item.in_cap_sens_exp_date, DATE_FORMAT.INPUT);
                    equipment.premisesCertification = Helpers.formatDate(item.premises_certification, DATE_FORMAT.INPUT);
                    equipment.assetID = item.asset_id;
                    equipment.roomName = item.room_name;
                    equipment.roomNo = item.room_no;
                    equipment.department = item.department;
                    return equipment;
                });
                return {
                    items: items,
                    totalActive: parseInt(response.data.totalActive),
                    totalArchive: parseInt(response.data.totalArchived ? response.data.totalArchived : response.data.totalArchive)
                };
            }
        });
    };

    this.disposeEquipment = function(equipmentID, equipmentDisposalWay, equipmentDesc, equipmentURL, disposeDate, inoperableBy, contactedManufacturer, documentationCompleted, lsoContacted, laserDecontaminated, dispose) {

        disposeDate = Helpers.formatDate(disposeDate);

        return $http({
            url: API_BASE + "/equipment/dispose",
            method: "POST",
            data: {
                in_eqi_id: equipmentID,
                dwa_id: _.get(equipmentDisposalWay, 'id', null),
                description: equipmentDesc,
                in_doc_url: equipmentURL,
                dispose_date: disposeDate,
                inoperable_by: inoperableBy,
                contacted_manufacturer: contactedManufacturer,
                documentation_completed: documentationCompleted,
                lso_contacted: lsoContacted,
                laser_decontaminated: laserDecontaminated,
                dispose: parseInt(dispose)
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.fetchReplaceableEquipment = function(filter, replacement) {
        return $http({
            url: API_BASE + "/equipment/replacable/list",
            method: "POST",
            data: {
                in_par_uuid: filter.account,
                in_eca_id: _.isNull(_.get(filter.category, 'id', null)) ? filter.type : filter.category.id,
                in_offset: filter.offset,
                in_limit: filter.limit
            },
            transformResponse: appendTransform($http.defaults.transformResponse, function(data) {
                var list = [];
                if (!_.isUndefined(data.list)) {
                    list = data.list.reduce(function(result, item) {
                        var obj = new Equipment();
                        obj.equipment_id = item.equ_id;
                        obj.user_friendly_id = item.equipment_id;
                        obj.transaction_id = item.transaction_id;
                        obj.transaction_type = item.transaction_type;
                        obj.serial = item.serial;
                        obj.account.name = item.account_name;
                        obj.manufacturer.name = item.manufacturer;
                        obj.category.name = item.category;
                        obj.model.name = item.model;
                        result.push(obj);
                        return result;
                    }, []);

                    if (list.length && typeof replacement !== 'undefined') {
                        var equipment = new Equipment();
                        equipment.user_friendly_id = 'Please select equipment for replacement or continue with adding new equipment';
                        equipment.manufacturer.name = '';
                        equipment.model.name = '';
                        list.unshift(equipment);
                    }
                }
                return {
                    rows: data.rows,
                    list: list
                };
            })
        }).then(function(response) {
            return response;
        }, function(error) {
            return error;
        });
    };

    this.summary = function(filter, format) {
        var params = {};
        if(typeof filter.groupByDepartment != 'undefined')
            params.group = filter.groupByDepartment;
        params.status = filter.status;
        params.types = filter.categories.length ? filter.categories.join() : null;
        if(typeof format != 'undefined' && format != "")
            params.format = format;

        return $http({
            url: API_BASE + "/equipment/summary/" + filter.location,
            method: "GET",
            params: params
        }).then(function(response) {
            if (typeof format != 'undefined' && format != "")
                return response;

            return response.data.map(function(item) {
                var summary         = new EquipmentSummary();
                summary.id          = item.equipment_type_id;
                summary.name        = item.equipment_type;
                summary.count       = item.equipment_type_count;
                summary.department  = item.dept_type;
                return summary;
            });
        });
    };

    this.history = function(filter) {
        return $http({
            url: API_BASE + '/equipment/history',
            method: 'GET',
            params: {
                location:       filter.location,
                type:           filter.type,
                dateFrom:       Helpers.formatDate(filter.dateFrom),
                dateTo:         Helpers.formatDate(filter.dateTo),
                exportFormat:   filter.exportFormat,
                exportColumns:  filter.exportColumns
            }
        }).then(function(response) {
            if (filter.exportFormat) {
                return response.data;
            }
            else return response.data.map(function(item) {
                return {
                    category:   item.category,
                    countIn:    item.in_count,
                    countOut:   item.out_count
                };
            });
        });
    };

    this.sellEquipment = function(owner, item) {
        // TODO refactor Owner factory
        owner.in_new_owner_man_lic_exp_date = Helpers.formatDate(owner.in_new_owner_man_lic_exp_date);
        return $http({
            url: API_BASE + "/equipment/sell",
            method: "POST",
            data: {
                owner: owner,
                item: item
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    this.fetchWayOfDisposal = function(equipmentID) {
        return $http({
            url: API_BASE + "/equipment/way-of-disposal/" + equipmentID,
            method: "GET",
            params: {
                equ_id: equipmentID
            },
            transformResponse: appendTransform($http.defaults.transformResponse, function(data) {
                var result = data.map(function(item) {
                    return {
                        id: item.dwa_id,
                        title: item.description
                    };
                });
                return result;
            })
        }).then(function(response) {
            return response;
        }, function(error) {
            return error;
        });
    };

    this.getServiceDetails = function(equipmentID) {
        return $http({
            url: API_BASE + "/equipment/" + equipmentID + "/servicedetails",
            method: "GET"
        }).then(function(data) {
            var details = new ServiceDetails();
            details.lastInspection      = data.data.lastInspection;
            details.nextInspection      = data.data.nextInspection;
            details.frequency           = data.data.frequency;
            details.installationDate    = data.data.installation;
            details.retirementDate      = data.data.retirement;
            return details;
        }, function(error) {
            return error;
        });
    };

    this.inspectionInfo = function(id, filter) {
        var params = [];
        if (!_.isUndefined(filter))
            params['filter'] = filter;

        return $http({
            url: API_BASE + "/equipment/" + id+"/inspectioninfo",
            method: "GET",
            params: params
        }).then(function(data) {
            var elem = data.data.piece;
            var locations = data.data.locations;
            var item = new Xray();
            item.id = elem.equ_id;
            item.equipment_id = elem.equ_id;
            item.user_friendly_id = elem.equipment_id;
            item.title = elem.equipment_model_name + ' ( ' + elem.equipment_id + ' )';
            item.barcode = _.isNull(elem.barcode) ? elem.equipment_id : elem.barcode;
            item.transaction_id = elem.transaction_id;
            item.account.id = elem.account_id;
            item.account.name = elem.account_name;
            item.account.uuid = elem.account_uuid;
            item.account.lspn = elem.lspn;
            item.barcode = elem.barcode;
            item.certificateData.expiryDate = Helpers.formatDate(elem.certification_expire_date);
            item.certificateData.certificate = elem.certification_number;
            item.equipmentInstallationDate =  Helpers.formatDate(elem.installation_date);
            item.capitalSensitivityExpiryDate = Helpers.formatDate(elem.cap_sens_exp_date);
            item.serial = elem.equ_serial;
            item.category.id = elem.equipment_category_id;
            item.category.code = elem.equipment_category_code;
            item.category.name = elem.equipment_category_name;
            item.category.isRegulated = elem.equipment_category_is_regulated;
            //item.class.id = elem.equipment_class_id;
            //item.class.code = elem.equipment_class_code;
            //item.class.name = elem.equipment_class_name;
            item.model.id = elem.equipment_model_id;
            item.model.name = elem.equipment_model_name;

            item.type.id = parseInt(elem.equipment_basic_category_id);
            item.type.code = elem.equipment_basic_category_code;
            item.type.name = elem.equipment_basic_category_name;

            item.manufacturer.code = elem.manufacturer_code;
            item.manufacturer.id = elem.manufacturer_id;
            item.manufacturer.name = elem.manufacturer_name;
            item.manufacturer.description = elem.manufacturer_description
            item.registrationData.number = elem.reg_number;
            item.registrationData.date = Helpers.formatDate(elem.reg_date);
            item.registrationData.dueDate = Helpers.formatDate(elem.reg_next_due_date);
            item.registrationData.details = elem.reg_details;
            item.status = elem.status;

            item.vendor.id = elem.vendor_uuid;
            item.vendor.name = elem.vendor_name;

            item.location.name = elem.dep_name;
            item.location.uuid = elem.dep_uuid;

            var details = new ServiceDetails();
            details.lastInspection      = Helpers.formatDate(data.data.service_details.lastInspection, DATE_FORMAT.INPUT);
            details.nextInspection      = Helpers.formatDate(data.data.service_details.nextInspection, DATE_FORMAT.INPUT);
            details.frequency           = data.data.service_details.frequency;
            details.installationDate    = Helpers.formatDate(data.data.service_details.installation, DATE_FORMAT.INPUT);
            details.retirementDate      = Helpers.formatDate(data.data.service_details.retirement, DATE_FORMAT.INPUT);

            var arr = [];
            if (data.data.inspections.list.length !== 0) {
                data.data.inspections.list.forEach(function(elem) {
                    var item = {};
                    item.id         = elem.ins_id;
                    item.inspected  = Helpers.formatDate(elem.date_inspected, DATE_FORMAT.INPUT);
                    item.state      = elem.state == 0 ? "Pass" : "Fail";
                    item.comment    = elem.comment;
                    item.equipment_id = elem.equipment_id;
                    item.category   = elem.category;
                    item.manufacturer = elem.manufacturer;
                    item.model      =  elem.model;
                    item.account    = elem.account_name;
                    item.serial    = elem.serial;
                    item.report_type    = elem.report_type;
                    item.exp_date    = Helpers.formatDate(elem.exp_date);
                    item.uri    = elem.doc_uri;
                    arr.push(item);
                });
            }

            var history = [];
            if (data.data.history.length !== 0) {
                data.data.history.forEach(function(elem) {
                    var type = {};
                    type.id                 = elem.transaction_id;
                    type.equipmentId        = elem.equi_id;
                    type.equipmentSerial    = elem.equ_serial;
                    type.type               = {code: elem.transaction_type_code, name: elem.transaction_type_name}
                    type.issuer             = {uuid: elem.issuer_uuid, username: elem.username, firstName: elem.issuer_first_name, lastName: elem.issuer_last_name};
                    type.issuerName         = elem.issuer_first_name + ' ' + elem.issuer_last_name;
                    type.time               = moment(Helpers.formatDate(elem.transaction_time)).format('DD-MM-YYYY hh:mm:ss');
                    type.location           = {uuid: elem.location, name: elem.location_name};
                    type.document           = {id: elem.doc_id, dateDue: elem.doc_due_date, type: elem.doc_type_name};
                    type.uri                = elem.doc_uri;
                    if (!_.isUndefined(elem.doc_uri))
                        type.document.uri   = elem.doc_uri;
                    type.newOwner           = {
                        title:              elem.new_owner_title,
                        firstName:          elem.new_owner_first_name,
                        lastName:           elem.new_owner_last_name,
                        phone:              elem.new_owner_phone,
                        email:              elem.new_owner_email,
                        licence:            elem.new_owner_man_lic_no,
                        licenceExpires:     elem.new_owner_man_lic_exp_date,
                        buildingName:       elem.new_owner_loc_building_name,
                        locationUnit:       elem.new_owner_loc_unit_num,
                        buildingNo:         elem.new_owner_loc_building_num,
                        suburb:             elem.new_owner_loc_suburb,
                        state:              elem.new_owner_loc_state,
                        postcode:           elem.new_owner_loc_postocode,
                        mobile:             elem.new_owner_mobile,
                        fax:                elem.new_owner_fax,
                    };
                    history.push(type);
                });

                var serviceHistory = [];
                if (data.data.service_history.length !== 0) {
                    data.data.service_history.forEach(function (elem) {
                        var service = {};
                        service.issuer             = {uuid: elem.issuer_uuid, username: elem.username, firstName: elem.issuer_first_name, lastName: elem.issuer_last_name};
                        service.issuerName         = elem.issuer_first_name + ' ' + elem.issuer_last_name;
                        service.time               = moment(Helpers.formatDate(elem.transaction_time)).format('DD-MM-YYYY hh:mm:ss');
                        service.location           = {uuid: elem.location, name: elem.location_name};
                        service.document           = {id: elem.doc_id, dateDue: elem.doc_due_date, type: elem.doc_type_name};
                        service.uri                = elem.doc_uri;
                        service.expires            = Helpers.formatDate(elem.doc_due_date, DATE_FORMAT.INPUT);
                        serviceHistory.push(service);
                    });
                }
            }
            return {
                equipment: item,
                locations: locations,
                serviceDetails: details,
                inspections: {
                    rows: data.data.inspections.rows,
                    list:arr
                },
                history: history,
                serviceHistory: serviceHistory
            };
        }, function(error) {
            return error;
        });
    };

    this.inspectionInfoDocument = function(id,format) {
        return $http({
            url: API_BASE + "/equipment/" + id+ "/inspectioninfo",
            method: "GET",
            params: {format: format}
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    function sortTypes(result)  {
        return result.reduce(function(result, item) {
            if (_.isNull(item.par_id)) {
                var equipmentType = new EquipmentType();
                equipmentType.id = item.ety_id;
                equipmentType.par_id = item.par_id;
                equipmentType.code = item.code;
                equipmentType.name = item.name;
                result.push(equipmentType);
            }
            else {
                var equipmentCategory = new EquipmentCategory();
                equipmentCategory.id = item.ety_id;
                equipmentCategory.par_id = item.par_id;
                equipmentCategory.code = item.code;
                equipmentCategory.name = item.name;

                var equipmentType = _.find(result, ['id', equipmentCategory.par_id]);
                if (equipmentType) equipmentType.categories.push(equipmentCategory);
            }
            return result;
        }, []).filter(function(item) {
            return item.categories.length > 0;
        });
    };

    this.getInspectors = function(id, docTypeID) {
        return $http({
            url: API_BASE + '/equipment/inspectors',
            method: "GET",
            params: {
                id: id,
                docTypeID: docTypeID
            }
        }).then(function(response) {
            return response.data.map(function(item) {
                return {
                    uuid: item.uuid,
                    name: item.first_name + ' ' + item.last_name
                };
            });
        });
    };

    this.invalidateDocument = function(documentId) {
        return $http({
            url: API_BASE + "/equipment/invalidate-document",
            method: "POST",
            params: {
                documentId: documentId
            }
        }).then(function(data) {
            return data;
        });
    };

    this.fetchEquipmentFlags = function(location, equipmentType) {
        return $http({
            url: API_BASE + '/equipment/flags',
            method: "GET",
            params: {
                location: location,
                equipmentType: equipmentType
            }
        }).then(({ data }) => {
            return isObject(data.params) ? data.params : JSON.parse(data.params);
        });
    };

    this.fetchEquipmentTypeProperties = function(equipmentType, location) {
        return $http({
            url: API_BASE + '/equipment/properties',
            method: "GET",
            params: {
                equipmentType: equipmentType,
                location: location
            }
        }).then(function(response) {
            return response.data.map(function(item) {
                var property    = new EquipmentProperty();
                property.cpr_id     = item.cpr_id;
                property.prop_code   = item.prop_code;
                property.prop_name   = item.prop_name;
                property.prop_type   = item.prop_type;
                property.is_mandatory   = item.is_mandatory;
                property.is_readonly   = item.is_readonly;
                return property;
            });
        });
    };

    this.generateQRcode = function(id, assetId) {
        return $http({
            url: API_BASE + '/equipment-calibration/qrcode',
            method: 'POST',
            data: {
                id: id,
                asset_id: assetId
            }
        }).then(response => {
            return API_BASE + '/img/' + localStorage.myrsoAuthToken + response.data;
        });
    };

    this.printQRcode = function(equId, visualId, action) {
        return $http({
            url: API_BASE + '/equipment/qrcode/print',
            method: 'POST',
            responseType: 'arraybuffer',
            data: {
                equId,
                id: visualId,
                action,
            }
        }).then(response => {
            saveAs(new Blob([response.data], {type: 'application/pdf'}), `${visualId}.pdf`);
        });
    };

    this.fetchEquipmentTrainings = function(equipmentTypeCode, locationUuid, equipmentId){
        return $http({
            url: API_BASE + "/equipment/trainings",
            method: "GET",
            params: {
                equipmentTypeCode: equipmentTypeCode,
                location: locationUuid,
                equipmentId: equipmentId
            }
        }).then(function(response) {
            return response.data.map(function(item) {
                return {
                    id:             parseInt(item.training_id),
                    name:           item.training_name,
                    code:           item.training_code
                };
            });
        });
    };

    this.importEquipmentRecords = function(data) {
        return $http({
            url: API_BASE + '/equipment/import',
            method: 'POST',
            data: {
                document: first(data).filePath
            }
        }).then(response => {
            return response.data;
        })
    }

    this.getDepreciationReport = (ety_id) => {
        return $http({
            url: API_BASE + '/equipment/depreciation-report',
            method: 'GET',
            params: { ety_id, exportFormat: 'xlsx' },
        }).then(({ data }) => data);
    }

    this.generateDepreciationReport = (type, equ_id, visualId) => {
        return $http({
            url: API_BASE + '/equipment/asset/depreciation-report',
            method: 'GET',
            responseType: 'arraybuffer',
            params: {
                type,
                equ_id,
                exportFormat: 'pdf',
            },
        }).then((res) => {
            saveAs(new Blob([res.data], { type: 'application/pdf' }), `Depreciation-Report-${visualId}.pdf`);
        });
    }

    this.getLicenceFrequency = (equ_id, doc_date, dty_id) => {
        return $http({
            url: API_BASE + `/licence-expiry-date`,
            method: 'GET',
            params: {
                equ_id: parseInt(equ_id),
                doc_date: Helpers.formatDate(doc_date),
                dty_id: parseInt(dty_id)
            },
        }).then(({ data }) => data);
    }
}
