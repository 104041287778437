/* @ngInject */
export function DicomQualityControlService($http, API_BASE, Helpers, DicomQCTest, DATE_FORMAT) {

    this.addDicomQualityControlTest = function(QCTest) {

        return $http({
            url: API_BASE + "/mammo-qc/dicom-qc/test-add",
            method: "POST",
            data: {
                equipmentId:            QCTest.dicomQCEquipment.equId,
                mediumDensity:          QCTest.mediumDensity,
                lowDensity:             QCTest.lowDensity,
                differenceDensity:      QCTest.differenceDensity,
                //testerUuid:             QCTest.testerCandidate.uuid,
                testDate:               Helpers.formatDate(QCTest.testDate)
            }
        }).then(function(response) {
            var params = JSON.parse(response.data.params);
            return params.mqm_id;
        });
    };

    this.getQualityControlTestResult = function(filter) {
        return $http({
            url: API_BASE + "/mammo-qc/dicom-qc/equipment-tests",
            method: "GET",
            params: {
                equipmentId:    filter.dicomQCEquipment.equId,
                from:           Helpers.formatDate(filter.dateFrom),
                to:             Helpers.formatDate(filter.dateTo)
            }
        }).then(function(response) {
            var test = response.data.map(function(item) {
                var equipmentTest                   = new DicomQCTest();
                equipmentTest.mediumDensity         = item.md;
                equipmentTest.lowDensity            = item.ld;
                equipmentTest.differenceDensity     = item.dd;
                equipmentTest.testerUuid            = item.tester_uuid;
                equipmentTest.testDate              = Helpers.formatDate(item.tested_date, DATE_FORMAT.INPUT);
                equipmentTest.firstName             = item.tester_first_name;
                equipmentTest.lastName              = item.tester_last_name;
                return equipmentTest;
            });
            return {
                items: test
            }
        });
    };
}
