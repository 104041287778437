import { I18n } from 'i18n-js';
import { locale, variant } from '../../bootstrap.json';
import { MyQMO } from './util.constants';
import en_AU from '../../../i18n/myrso/en_AU.json';
import en_AU_myqmo from '../../../i18n/myqmo/en_AU.json';
import en_US from '../../../i18n/myrso/en_US.json';
import en_US_myqmo from '../../../i18n/myqmo/en_US.json';
import fr_CA from '../../../i18n/myrso/fr_CA.json';
import fr_CA_myqmo from '../../../i18n/myqmo/fr_CA.json';

const translations = {
    en_AU,
    en_AU_myqmo,
    en_US,
    en_US_myqmo,
    fr_CA,
    fr_CA_myqmo,
};

const i18n = new I18n({ [locale]: translations[locale] });

i18n.defaultLocale = locale;
i18n.locale = locale;
i18n.enableFallback = true;

if (variant === MyQMO) {
    i18n.store({ [locale]: translations[`${locale}_${variant}`] });
}

export default i18n;
