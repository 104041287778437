import { DATE_FORMAT } from '../../../utils/util.constants';
import moment from 'moment';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import isFunction from 'lodash/isFunction';

/**
 * Creates a new GridListItemController.
 * @class
 */
class GridListItemController {
    constructor() {
        // TODO
    }

    $onInit() {
        this.value = this.renderValue(this.column, this.item);
    }

    renderValue(column, item) {
        if (item.class)
            this.class = item.class;

        if (isNull(column.mapper))
            return this.orderNum;

        if (isFunction(column.mapper)) {
            return column.mapper(item);
        }

        const value = get(item, column.mapper, '');

        if (moment(value, DATE_FORMAT.OUTPUT, true).isValid())
            return moment(value).format(DATE_FORMAT.INPUT);

        if (moment(value, DATE_FORMAT.OUTPUT_TIME, true).isValid()) {
            const dateFormat = item.dateTime ? DATE_FORMAT.INPUT_TIME : DATE_FORMAT.INPUT;
            return moment(value, DATE_FORMAT.OUTPUT_TIME).format(dateFormat);
        }

        if (moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid()) {
            return moment(value).format(DATE_FORMAT.INPUT);
        }

        if (isFunction(value)) {
            return value.call(item);
        }
        else
            return value;
    }
}

export const gridListItem = {
    bindings: {
        identifier: '<',
        column: '<',
        item: '<',
        orderNum: '<'
    },
    controller: GridListItemController,
    template: require('./grid-list-item.html')
};
