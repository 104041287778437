import { GridController } from '../../../../../../../common/grid/grid.controller';

/* @ngInject */
export function AdministrationAccountsSiteLocationLicencesGridController($controller, $ngRedux, $state, NavigationService, LicenceService, AdministrationActions) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'administration';
        ctrl.identifier = 'administrationAccountsSiteLocationLicencesGrid';
        ctrl.autoload = false;
        ctrl.filterComponent = false;
        ctrl.exportService = LicenceService.exportSubjectLicences;

        ctrl.columns = [
            {title: '#',                mapper: null},
            {title: '',                 mapper: 'alert'},
            {title: 'Licence number',   mapper: 'regIdNo'},
            {title: 'Licence type',     mapper: 'type.name'},
            {title: 'Issued',           mapper: 'getIssuedDate'},
            {title: 'Expires',          mapper: 'getExpiryDate'},
            {title: 'Document',         mapper: 'file-download'}
        ];

        ctrl.buttons = [
            {label: 'Appendix', class: 'btnGrid', callback: ctrl.onAppendix},
            {label: 'Isotopes', class: 'btnGrid', callback: ctrl.onIsotopes}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), AdministrationActions)(ctrl);
        ctrl.dataAction = ctrl.getSiteLocationLicences;

        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
