import * as SettingsActions from '../import-settings.actions';
import { GRID_FILTER } from "../../../../common/grid/grid.constants";
import {IDS} from "../import-settings-action-id.constants";
import {TYPES} from "../import-settings-action-type.constants";

class OccupationsController {
    constructor($ngRedux, $scope, $timeout, Notifier, $filter) {
        this.$ngRedux = $ngRedux;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.Notifier = Notifier;
        this.$filter = $filter;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(state => {
            return {}
        }, SettingsActions)(this);
    }

    onSelect({item}) {
        this.$ngRedux.dispatch({
            type:   TYPES.SELECT_IMPORT_ITEM,
            id:     IDS.empImportSettingsOccupationsDetails,
            item
        });
        this.$ngRedux.dispatch({
            type:   GRID_FILTER,
            id:     IDS.empImportSettings,
            path:   IDS.empImportSettingsOccupationsDetailsGrid,
            filter: { gci_id: item.id }
        });
        this.$ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: IDS.app_employees_import_settings_centers,
            state: [IDS.app_employees_import_settings_centers_details]
        });
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

OccupationsController.$inject = ['$ngRedux', '$scope', '$timeout', 'Notifier', '$filter'];

export const empImportSettingsOccupationsList = {
    controller: OccupationsController,
    template: `
        <emp-import-settings-occupations-list-grid 
            title="{{'EMPLOYEES.IMPORT_SETTINGS.OCCUPATIONS.TAB' | translate}}"
            on-select="$ctrl.onSelect($event)">
        </emp-import-settings-occupations-list-grid>
`
};
