import API from '../http/api.fetch';
import { variant } from '../../bootstrap.json';

/**
 * @function otpVerify
 * @param {string} otp
 * @param {string} otp_token
 */
export const otpVerify = (otp, otp_token) => () => {
    return API.fetch({
        url: '/otp/verify',
        method: 'POST',
        data: {
            otp,
            otp_token,
            app: variant,
        },
    }).then((response) => response);
};

/**
 * @function otpGenerate
 */
export const otpGenerate = (token) => () => {
    return API.fetch({
        url: '/otp/generate',
        method: 'POST',
        data: { token },
    }).then((response) => response);
};
