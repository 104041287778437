import { InputSelectController } from '../input-select/input-select.component';

/**
 * Creates a new InputSelectMultipleController.
 * @class
 * @extends module:root/common/form.InputSelectController
 * @memberOf module:root/common/form
 * @see {@link https://docs.angularjs.org/guide/component|AngularJS Component}
 */
class InputSelectMultipleController extends InputSelectController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     */
    constructor($ngRedux) {
        super($ngRedux);
    }
}

InputSelectMultipleController.$inject = ['$ngRedux'];

export const inputSelectMultiple = {
    bindings: {
        identifier: '@',
        statePath: '@',
        stateIndex: '<',
        stateProp: '@',
        stateData: '@',
        label: '@',
        reset: '@',
        allowNull: '@',
        validate: '<',
        required: '<',
        onChange: '&',
        onReset: '&'
    },
    require : {
        /**
         * @member {*} module:root/common/form.InputSelectMultipleController#form
         * @see {@link https://docs.angularjs.org/api/ng/type/form.FormController|FormController}
         */
        form : '^form'
    },
    controller: InputSelectMultipleController,
    template: `
        <div layout-gt-xs="row">
            <md-input-container ng-class="$ctrl.getClass()" flex>
                <label>{{$ctrl.label}}</label>
                <md-select name="{{$ctrl.name}}" ng-model="$ctrl.input" md-on-close="$ctrl.onInputChange()" ng-required="$ctrl.required" multiple>
                    <md-option ng-value="item.value" ng-repeat="item in $ctrl.options">
                        <span class="md-body-2">{{item.label}}</span>
                    </md-option>
                </md-select>
                <asset ng-if="$ctrl.reset" src="{{$ctrl.ICON_RESET}}" ng-click="$ctrl.onInputReset()"></asset>
                <div ng-messages="$ctrl.form[$ctrl.name].$error">
                    <div ng-message="required">{{'COMMON.FIELD_REQUIRED' | translate}}</div>
                </div>
            </md-input-container>
        </div>
    `
};
