/* @ngInject */
export function LicenceService($ngRedux, $http, API_BASE, Helpers, Licence, LicenceType, LicenceStatus) {

    this.getLicence = function(id) {
        return $http({
            url: API_BASE+ "/licence/" + id,
            method: "GET"
        }).then(function(response) {
            var item = _.first(response.data);
            return {
                id:         item.lic_id,
                slo:        item.sub_uuid,
                issued:     Helpers.formatDate(item.issue_date),
                expires:    Helpers.formatDate(item.exp_date),
                type:       item.license_type_id,
                typeCode:   item.license_type_code,
                number:     item.lic_num,
                filePath:   item.document_uri
            };
        });
    };

    this.addLicence = function(employee, licence) {
        return $http({
            url: API_BASE+ "/licence/user/" + employee.uuid,
            method: "POST",
            data: {
                regIdNo:        licence.regIdNo,
                type:           licence.type.id,
                equipmentType:  licence.equipmentType,
                issued:         Helpers.formatDate(licence.issued),
                expires:        Helpers.formatDate(licence.expires),
                licencePath:    licence.uri,
                stateId:        _.isNull(licence.country.id) ? null : licence.country.state.id
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.editLicence = function(licence) {
        return $http({
            url: API_BASE + '/licence/' + licence.id,
            method: "PUT",
            data:{
                type:       licence.type.id,
                number:     licence.regIdNo,
                expires:    Helpers.formatDate(licence.expires),
                issued:     Helpers.formatDate(licence.issued)
            }
        }).then(function(response) {
            return response;
        });
    };

    this.updateLicence = function(licence) {
        return $http({
            url: API_BASE + '/licence/' + licence.id,
            method: "PUT",
            data:{
                type:       licence.type,
                number:     licence.number,
                uri:        licence.filePath,
                expires:    Helpers.formatDate(licence.expires),
                issued:     Helpers.formatDate(licence.issued)
            }
        }).then(function(response) {
            return response;
        });
    };

    this.removeLicence = function(licence) {
        return $http({
            url: API_BASE + '/remove-licence',
            method: "DELETE",
            params: {
                lic_id: licence.id,
                location_uuid: licence.location_uuid
            }
        }).then(function(response) {
            return response;
        });
    };

    this.subjectLicences = function(uuid, licenceCode, filter) {
        // hotfix
        if (_.isUndefined(licenceCode)) var licenceCode = 'loc';
        if (_.isUndefined(filter)) {
            var filter = {
                type: null,
                status: null,
                offset: 0,
                limit: -1
            };
        }

        return $http({
            url: API_BASE + "/licences/" + uuid,
            method: "GET",
            params: {
                licenceCode: licenceCode,
                type:   filter.type,
                status: filter.status,
                offset: filter.offset,
                limit:  filter.limit
            }
        }).then(function(response) {
            var licences = response.data.rows.map(function(item) {
                var licence         = new Licence();
                licence.id          = item.lic_id;
                //licence.number      = item.lic_num;
                licence.issued      = Helpers.formatDate(item.issue_date);
                licence.expires     = Helpers.formatDate(item.exp_date);
                licence.uri         = item.document_uri;
                licence.regIdNo     = item.lic_num;
                licence.type        = new LicenceType();
                licence.type.id     = item.license_type_id;
                licence.type.name   = item.license_type;
                licence.state       = item.state;
                licence.alertType   = item.alert_type;
                licence.headOffice  = item.head_office;
                licence.siteLocation= item.username;
                return licence;
            });
            return {
                items: licences,
                total: response.data.total
            }
        });
    };

    this.exportSubjectLicences = function(filter) {
        return $http({
            url: API_BASE + "/licences/" + filter.uuid,
            method: "GET",
            params: {
                type:           filter.type,
                status:         filter.status,
                offset:         filter.offset,
                limit:          filter.limit,
                exportFormat:   filter.exportFormat,
                exportColumns:  filter.exportColumns
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.getLicenceTypes = function(location, type) {
        return $http({
            url: API_BASE + "/licence/types/" + (_.isUndefined(type) ? 'uli' : type),
            method: "GET",
            params: {
                location: location
            }
        }).then(function (response) {
            return response.data.map(function (item) {
                var licenceType = new LicenceType();
                licenceType.id = item.lty_id;
                licenceType.code = item.code;
                licenceType.name = item.name;
                return licenceType;
            });
        });
    };

    this.getLicenceStatuses = function() {
        return $http({
            url: API_BASE + "/licence/statuses",
            method: "GET"
        }).then(function(response) {
            return response.data.map(function(item) {
                var licenceStatus   = new LicenceStatus();
                licenceStatus.id    = item.sta_id;
                licenceStatus.code  = item.code;
                licenceStatus.name  = item.name;
                return licenceStatus;
            });
        });
    };

    this.addManagementLicence = function(licence) {
        return $http({
            url: API_BASE + "/licence/add-site-location-licence",
            method: "POST",
            data: {
                lty_id:         licence.type,
                issue_date:     Helpers.formatDate(licence.issued),
                due_date:       Helpers.formatDate(licence.expires),
                licence_url:    licence.filePath,
                slo_uuid:       licence.slo,
                licence_number: licence.number
            }
        }).then(function(response) {
            return response.data.params.lic_id ? response.data.params.lic_id : JSON.parse(response.data.params).lic_id;
        });
    };

    this.getSiteLocationsForManagementLicence = function() {
        const state = $ngRedux.getState();
        const slo_uuid = state.administration.administrationAccountsSiteLocation.data.uuid;
        const lic_id = state.administration.administrationAccountsSiteLocationLicence.data.id;

        return $http({
            url: API_BASE + "/licence/list-site-locations-for-management-licence",
            method: "GET",
            params: {
                slo_uuid:   slo_uuid,
                lic_id:     lic_id
            }
        }).then(function(response) {
            return response.data.map(item => {
                return {
                    sloUuid: item.slo_uuid,
                    sloName: item.slo_name,
                    isSet: item.is_set
                }
            });
        });
    };

    this.assignSiteLocation = function(siteLocation) {
        const state = $ngRedux.getState();
        const lic_id = state.administration.administrationAccountsSiteLocationLicence.data.id;

        return $http({
            url: API_BASE + "/licence/assign-licence-to-site-location",
            method: "POST",
            data: {
                slo_uuid:     siteLocation,
                lic_id:       lic_id
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.resignSiteLocation = function(siteLocation) {
        const state = $ngRedux.getState();
        const lic_id = state.administration.administrationAccountsSiteLocationLicence.data.id;

        return $http({
            url: API_BASE + "/licence/resign-licence-from-site-location",
            method: "POST",
            data: {
                slo_uuid:     siteLocation,
                lic_id:       lic_id
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.approveLicence = function(id) {
        return $http({
            url: API_BASE + '/employee/licence/approve',
            method: "POST",
            params: {
                lic_id: id,
            }
        }).then(function(response) {
            return response;
        });
    };
}
