import { GridController } from '../../../../../../common/grid/grid.controller';

/* @ngInject */
export function AdministrationAccountsHeadOfficesGridController($controller, $ngRedux, $state, NavigationService, AccountService, AdministrationActions) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        if (!ctrl.admin) ctrl.onRemove = null;
        ctrl.module = 'administration';
        ctrl.identifier = 'administrationAccountsHeadOfficesGrid';
        ctrl.title = 'Head Offices';
        ctrl.filterOpen = true;
        ctrl.filterTemplate = './administration-accounts-head-offices-grid-filter.html';
        ctrl.exportService = AccountService.getAccounts;

        ctrl.columns = [
            {title: '#',                mapper: null},
            {title: 'Account ID',       mapper: 'id'},
            {title: 'Facility',         mapper: 'name'},
            {title: 'Facility Type',    mapper: 'typeName'},
            //{title: 'Parent',           mapper: 'parentUsername'},
            {title: '',                 mapper: 'warning'},
            {title: '',                 mapper: 'critical'}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), AdministrationActions)(ctrl);
        ctrl.dataAction = ctrl.getHeadOffices;

        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
