/* @ngInject */
export function userPasswordButton($mdDialog, NavigationService, SettingsService) {
    return {
        restrict: 'E',
        scope: {
            uuid: '=',
            title: '@'
        },
        template: require('./user-password-button.html'),
        link: function(scope, element) {

            scope.headerTitle = scope.title;
            scope.label = 'CHANGE PASSWORD';

            scope.user = {
                sub_uuid: scope.uuid,
                password: '',
                passwordNew: '',
                passwordVerify: ''
            };

            // TODO not good solution, problem with async model change
            scope.$on('USER_STATUS_CHANGE', function(event, status, userUuid) {
                scope.user.sub_uuid = userUuid;
            });

            var uuidWatch = scope.$watch('uuid', function(newUuid) {
                scope.user.sub_uuid = newUuid;
            });

            scope.onClick = function() {
                scope.info = {status: false};
                $mdDialog.show({
                    controller: PasswordController,
                    template: require('./user-password-dialog.html'),
                    parent: angular.element(document.body),
                    locals: {
                        title: scope.headerTitle,
                        label: scope.label,
                        user: scope.user,
                        info: scope.info
                    },
                    clickOutsideToClose: true
                });
            };

            function PasswordController($scope, $timeout, title, label, user, info) {
                $scope.headerColor = NavigationService.getNavConfig().colorName;
                $scope.headerTitle = title;
                $scope.buttonLabel = label;
                $scope.user = user;
                $scope.info = info;

                $scope.confirm = function() {

                    $scope.info.status = false;
                    $scope.$broadcast('show-errors-check-validity');
                    if ($scope.userForm.$invalid) { return; }

                    SettingsService.changePassword($scope.user).then(
                        function(result) {

                            if (result.headers('X-Status') == 14) {
                                $scope.info.class = 'alert-danger';
                                $scope.info.message = 'Not able to identify user. Please contact administrator!';
                            }
                            else if (result.headers('X-Status') == 290) {
                                $scope.user.password = '';
                                $scope.info.class = 'alert-danger';
                                $scope.info.message = 'Wrong current password!';
                            }
                            else if (result.status == 200 && result.headers('X-Status') == null) {
                                $scope.user.password = '';
                                $scope.user.passwordNew = '';
                                $scope.user.passwordVerify = '';
                                $scope.userForm.$setPristine();
                                $scope.userForm.$setUntouched();
                                $scope.info.class = 'alert-success';
                                $scope.info.message = 'Password has been successfully changed.';
                                $timeout($mdDialog.hide,3000);
                            }
                            else {
                                $scope.info.class = 'alert-danger';
                                $scope.info.message = 'Not able to change password. Please contact administrator!';
                            }
                            $scope.info.status = true;
                        }, function(error) {
                            // TODO
                        }
                        /*function(result) {
                         if (_.isUndefined(result)) {
                         $scope.user.password = '';
                         $scope.info.class = 'alert-danger';
                         $scope.info.message = 'Wrong current password!';
                         }
                         else {
                         $scope.user.password = '';
                         $scope.user.passwordNew = '';
                         $scope.user.passwordVerify = '';
                         $scope.userForm.$setPristine();
                         $scope.userForm.$setUntouched();
                         $scope.info.class = 'alert-success';
                         $scope.info.message = 'Password has been successfully changed.';
                         $timeout($mdDialog.hide,3000);
                         }
                         $scope.info.status = true;
                         }, function(error) {
                         // TODO
                         }*/);
                };

                $scope.cancel = function() {
                    $mdDialog.cancel();
                };
            }

            element.on('$destroy', function() {
                uuidWatch();
            });
        }
    };
}
