import { getAsset } from './asset.map';

/**
 * Creates a new AssetController.
 * @class
 * @memberOf module:root/common
 */
class AssetController {
    /**
     * @constructor
     * @param $scope {object} {@link https://docs.angularjs.org/guide/scope|AngularJS $scope}
     * @param $element
     * @param $compile {service} {@link https://docs.angularjs.org/api/ng/service/$compile|AngularJS $compile}
     */
    constructor($scope, $element, $compile) {
        this.$scope = $scope;
        this.$element = $element;
        this.$compile = $compile;
    }

    /**
     * @method $onInit
     */
    $onInit() {
        const asset = getAsset(this.src);
        const newScope = this.$scope.$new(true);
        newScope.id = this.id;
        newScope.src = asset.src;
        newScope.class = this.cls;
        newScope.alt = this.alt;

        const tpl = asset.type === 'svg'
            ? '<md-icon class="{{class}}" md-svg-src="{{src}}"></md-icon>'
            : '<img ng-attr-id="{{id}}" class="{{class}}" ng-src="{{src}}" alt="{{alt}}">';

        this.$element.append(this.$compile(tpl)(newScope));
    }
}

AssetController.$inject = ['$scope', '$element', '$compile'];

export const asset = {
    bindings: {
        id: '@',
        cls: '@',
        src: '@',
        alt: '@'
    },
    controller: AssetController,
    template: ''
};
