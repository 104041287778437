import { gridTpl } from '../../../../../common/grid/grid.controller';

export const serviceContractsGrid = {
    bindings: {
        onSelect: '&',
        onSummary: '&'
    },
    controller: 'ServiceContractsGridController',
    ...gridTpl
};
