import { GridController } from '../../../../common/grid/grid.controller';

/* @ngInject */
export function EquipmentDocumentsGridController($controller, $ngRedux, $state, NavigationService, EquipmentActions, $filter) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'equipment';
        ctrl.identifier = 'equipmentDocumentsGrid';
        ctrl.filterTemplate = './equipment-documents-grid-filter.html';
        ctrl.autoload = true;
        ctrl.exportComponent = false;

        ctrl.columns = [
            {title: $filter('translate')('EQUIPMENT.TABS.DOCUMENTS.GRID_COLUMN_TYPE'),      mapper: 'documentType'},
            {title: $filter('translate')('EQUIPMENT.TABS.DOCUMENTS.GRID_COLUMN_DUE_DATE'),  mapper: 'dueDate'},
            {title: $filter('translate')('EQUIPMENT.TABS.DOCUMENTS.GRID_COLUMN_DOCUMENT'),  mapper: 'file-download'},
            {title: 'Comment',                                                              mapper: 'comment'}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), EquipmentActions)(ctrl);
        ctrl.dataAction = ctrl.getEquipmentDocuments;
        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
