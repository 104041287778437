import { GridController } from '../../../../common/grid/grid.controller';
import watch from 'redux-watch';
import get from 'lodash/get';

/* @ngInject */
export function EquipmentGridController($controller, $ngRedux, $state, NavigationService, EquipmentActions, EquipmentService, DocumentService, DOCUMENT_CODE, $filter) {
    var ctrl = this;
    var onSwitch = angular.noop;
    var onFileManagement = angular.noop;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'equipment';
        ctrl.filterControls = './equipment-grid-filter-controls.html';
        ctrl.filterTemplate = './equipment-grid-filter.html';
        ctrl.exportService = EquipmentService.getEquipmentExportDocument;
        ctrl.fetchDocumentToken = DocumentService.fetchDocumentToken;
        ctrl.documentCode = DOCUMENT_CODE.EQUIPMENT;
        ctrl.documentsComponent = true;
        ctrl.importComponent = true;
        ctrl.importService = EquipmentService.importEquipmentRecords;

        ctrl.customComponent = get({
            equipmentXrayGrid: 'xraGridDepreciationReport',
            equipmentLasersGrid: 'lasGridDepreciationReport',
            equipmentUltrasoundGrid: 'usoGridDepreciationReport',
            equipmentMammoGrid: 'mamGridDepreciationReport',
        }, ctrl.identifier);

        ctrl.columns = [
            {title: '#',                                                                                mapper: null},
            {title: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_EQUIPMENT_ID'),          mapper: 'user_friendly_id', sort: 'equipment_id'},
            {title: 'Asset ID',                                                                         mapper: 'assetID', sort: 'asset_id'},
            {title: (ctrl.identifier === 'equipmentLasersGrid') ? $filter('translate')('EQUIPMENT.TABS.DETAILS.INPUT_LASER_NAME') : $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_CATEGORY'),              mapper: 'category.name', sort: 'category'},
            {title: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_MANUFACTURER'),          mapper: 'manufacturer.name', sort: 'manufacturer'},
            {title: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_MODEL'),                 mapper: 'model.name', sort: 'model'},
            {title: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_SERIAL_NUMBER'),         mapper: 'serial', sort: 'serial'},
            {title: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_SITE_LOCATION'),         mapper: 'account.name', sort: 'account_name'},
            {title: 'Department',                                                                       mapper: 'department', sort: 'department'},
            {title: 'Room Name',                                                                        mapper: 'roomName', sort: 'room_name'},
            {title: 'Room No',                                                                          mapper: 'roomNo', sort: 'room_no'},
            {title: 'Installation Date',                                                                mapper: 'installationDate', sort: 'installation_date'},
            {title: 'Status',                                                                           mapper: 'getStatus'},
            {title: '',                                                                                 mapper: 'warning'},
            {title: '',                                                                                 mapper: 'critical'}
        ];

        if (ctrl.identifier === 'equipmentLasersGrid' || ctrl.identifier === 'equipmentXrayGrid' || ctrl.identifier === 'equipmentMammoGrid') {
            ctrl.columns.splice(12, 0, {title: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_CERTIFICATE_DUE_DATE'), mapper: 'certificateDueDate', sort: 'cer_due_date'});
            ctrl.columns.splice(13, 0, {title: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_CERTIFICATE_NUM'), mapper: 'certificateNumber', sort: 'cer_number'});
        }

        if (ctrl.userHof != 'OTTAWA HOSPITAL') {
            if (ctrl.identifier === 'equipmentLasersGrid' || ctrl.identifier === 'equipmentXrayGrid' || ctrl.identifier === 'equipmentMammoGrid') {
                ctrl.columns.splice(14, 0, {title: 'Capital Sensitivity Expiry Date', mapper: 'capitalSensitivityExpiryDate', sort: 'in_cap_sens_exp_date'});
                ctrl.columns.splice(15, 0, {title: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_REPORT_DUE_DATE'), mapper: 'serviceDueDate', sort: 'ser_due_date'});
                ctrl.columns.splice(16, 0, {title: 'Premises Certification', mapper: 'premisesCertification', sort: 'premises_certification'});
            } else {
                ctrl.columns.splice(12, 0, {title: 'Capital Sensitivity Expiry Date', mapper: 'capitalSensitivityExpiryDate', sort: 'in_cap_sens_exp_date'});
                ctrl.columns.splice(13, 0, {title: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_REPORT_DUE_DATE'), mapper: 'serviceDueDate', sort: 'ser_due_date'});
            }
        }

        initButtons();

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), EquipmentActions)(ctrl);
        ctrl.dataAction = ctrl.getEquipmentGrid;
        ctrl.filter.identifier = ctrl.identifier;

        var watchStatus = watch($ngRedux.getState, 'equipment.' + ctrl.identifier + '.filter.status');
        onSwitch = $ngRedux.subscribe(watchStatus(function(newValue) {
            if (ctrl.identifier === "equipmentTechniqueChartsGrid") {
                //for optimized charts, overide default buttons if fileManagement is set
                initButtons(ctrl.fileManagement);
            } else {
                initButtons(newValue);
            }
        }));

        var watchStatusFileManagement = watch($ngRedux.getState, 'equipment.' + ctrl.identifier + '.fileManagement');
        onFileManagement = $ngRedux.subscribe(watchStatusFileManagement(function (newValue) {
            initButtons(newValue);
        }));

        if (ctrl.identifier === "equipmentTechniqueChartsGrid") {
            initButtons(ctrl.fileManagement);
        }

        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
        onSwitch();
        onFileManagement();
    };

    function initButtons(status) {
        switch (status) {
            case 'chr':
                ctrl.buttons = [
                    {label: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_BUTTON_REPORT'), class: 'btnGridInfo', callback: ctrl.onGenerateReport},
                    {label: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_BUTTON_DOWNLOAD'), class: 'btnGridModify', callback: ctrl.onDocDownload},
                    {label: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_BUTTON_UPLOAD'), class: 'btnGridModify', callback: ctrl.onDocUpload}
                ];
                break;
            case 'arc':
                //ctrl.columns.push({title: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_COLUMN_STATUS'),  mapper: 'getStatus'});
                ctrl.buttons = [{label: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_BUTTON_REPLACE'), class: 'btnGridModify', callback: ctrl.onReplace}];
                break;
            default:
                //var column = _.find(ctrl.columns, function(o) { return o.mapper === 'getStatus'; });
                //if (column) ctrl.columns.splice(ctrl.columns.indexOf(column), 1);
                ctrl.buttons = [
                    {label: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_BUTTON_SELL'), class: 'btnGridModify', callback: ctrl.onSell},
                    {label: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.GRID_BUTTON_DISPOSE'), class: 'btnGridDispose', callback: ctrl.onDispose}
                ];
        }
    }
}
