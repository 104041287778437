import unionBy from 'lodash/unionBy';

const initialState = {
    scanner: {
        accountId: null,
        location: null,
        locationName: null,
        locations: [],
        type: null,
        types: [],
        equipment: [],
        misplaced: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'LOCATION_SELECTED':
            if (action.id === 'inventoryScanner') {
                return {
                    ...state,
                    scanner: {
                        ...state.scanner,
                        accountId: action.accountId,
                        location: action.uuid,
                        locationName: action.locationName
                    }
                }
            }
        case 'EQUIPMENT_CALIBRATION_TYPES':
            if (action.id === 'inventoryScanner') {
                return {
                    ...state,
                    scanner: {
                        ...state.scanner,
                        types: action.items
                    }
                }
            }
        case 'INPUT_SELECT':
            if (action.id === 'scanner') {
                return {
                    ...state,
                    scanner: {
                        ...state.scanner,
                        type: action.input
                    }
                }
            }
        case 'SCAN':
            if (action.id === 'inventoryScanner') {
                let equipment = [];
                let misplaced = [];
                switch (action.data.scanStatus) {
                    case 'PRESENT':
                        equipment.push(action.data);
                        break;
                    case 'INCORRECT':
                        misplaced.push(action.data);
                        break;
                }
                return {
                    ...state,
                    scanner: {
                        ...state.scanner,
                        equipment: unionBy(state.scanner.equipment, equipment, 'id'),
                        misplaced: unionBy(state.scanner.misplaced, misplaced, 'id')
                    }
                }
            }
        case 'SCANNER_RESET':
            return initialState;
        default:
            return state;
    }
}
