import { TAB_SHOW } from '../../../common/tabs/tabs.constants';
import { formatDate } from '../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../common/utils/util.constants';
import omit from 'lodash/omit';
import * as EventActions from '../events.actions'
import * as EventsActionPlanActions from './events-action-plan.actions'
import * as FileActions from '../../../common/file/file.actions'

/**
 * @const {object}
 * @memberOf module:Events
 */

export const eventsActionPlan = {
    /* @ngInject */
    controller: function($ngRedux, $state, $rootScope, $scope, NavigationService, $mdDialog, Notifier, $timeout) {
        this.unsubscribe = $ngRedux.connect(({ events }) => ({
            eventID: events.eventItem.id,
            actionPlan: events.eventActionPlan
        }), {
            ...EventsActionPlanActions,
            ...FileActions
        })(this);

        /**
         * @function $onInit
         */
        this.$onInit = () => {
            const navConfig = NavigationService.getConfig($state.$current);
            this.colorName = navConfig.colorName;
            this.icon = navConfig.icon;

            this.identifier = $state.$current.parent.name;
            this.state = `${$state.$current.parent.name}.actionPlan`;

            this.getActionTaskStatusList();
            this.getActionPlanData();
        };

        this.getActionPlanData = () => {
            return this.getActionPlan(this.eventID).then(() => {
                $ngRedux.dispatch({
                    id: 'eventsActionTaskGrid',
                    type: 'GRID_RELOAD'
                });
            });
        };

        this.openActionPlanLibrary = () => {
            $ngRedux.dispatch({
                type: TAB_SHOW,
                id: this.identifier,
                state: [`${$state.$current.parent.name}.actionPlanLibrary`]
            });
        };

        this.selectActionPlan = () => {
            const actionPlanCtrl = ($scope) => {
                $scope.onGetActionPlanList = async (event) => await this.getActionPlanList(event);
                $scope.select = () => {
                    if ($scope.actionPlanDialogForm.$valid) {
                        this.preselectActionPlan();
                        $mdDialog.hide();
                    }
                };
                $scope.cancel = () => {
                    $mdDialog.cancel();
                };
            };

            this.dialog = $mdDialog.show({
                controller: actionPlanCtrl,
                template: require('./dialogs/events-action-plan-details-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true
            }).then(this.resetActionPlanList, this.resetActionPlanList);
        };

        this.onGetResponsiblePersons = async (event) => await this.getResponsiblePersons('eventActionPlan', event);

        this.openActionTaskLibrary = () => {
            $ngRedux.dispatch({
                type: TAB_SHOW,
                id: this.identifier,
                state: [`${$state.$current.parent.name}.actionTaskLibrary`]
            });
        };

        this.selectActionTask = () => {
            const actionTaskCtrl = ($scope) => {
                $scope.onGetActionTaskList = async (event) => await this.getActionTaskList(event);
                $scope.select = () => {
                    if ($scope.actionTaskDialogForm.$valid) {
                        this.preselectActionTask();
                        $mdDialog.hide();
                    }
                };
                $scope.cancel = () => {
                    $mdDialog.cancel();
                };
            };

            this.dialog = $mdDialog.show({
                controller: actionTaskCtrl,
                template: require('./dialogs/events-action-task-details-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true
            }).then(this.resetActionTaskList, this.resetActionTaskList);
        };

        this.onManageActionTask = (event) => {
            const actionTaskManageCtrl = ($scope) => {
                $scope.onGetResponsiblePersons = async (evt) => await this.getResponsiblePersons('eventActionTask', evt);
                $scope.save = async () => {
                    if ($scope.actionTaskDialogForm.$valid) {
                        const response = await this.updateActionTask({
                            idx: event.idx,
                            iev_id: this.eventID,
                            tsk_id: this.actionPlan['actionTask.tsk_id'],
                            task_name: this.actionPlan['actionTask.task_name'],
                            details: this.actionPlan['actionTask.details'],
                            responsible_person: this.actionPlan['actionTask.responsible_person'],
                            completion_date: this.actionPlan['actionTask.completion_date'],
                            status_id: this.actionPlan['actionTask.status_id'],
                            save_task_to_library: this.actionPlan['actionTask.save_task_to_library']
                        });
                        await this.getActionPlanData();
                        $mdDialog.hide();
                    }
                };
                $scope.cancel = () => {
                    $mdDialog.cancel();
                };
            };

            this.dialog = $mdDialog.show({
                controller: actionTaskManageCtrl,
                template: require('./dialogs/events-action-task-manage-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                onShowing: async (scope) => {
                    await $ngRedux.dispatch({
                        id: 'eventActionPlan',
                        type: 'EVENT_ACTION_TASK_DETAILS',
                        index: event.idx
                    });
                    scope.disableStatus = typeof this.actionPlan['actionTask.tsk_id'] === 'undefined';
                }
            }).then(this.resetActionTaskDetails, this.resetActionTaskDetails);
        };

        this.onRemoveActionTask = (event) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen Action Task will be removed from the list.')
                .ariaLabel('Remove Action Task')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(async () => {
                const { item, idx } = event;
                await this.removeActionTask(item.tsk_id, idx);
                $ngRedux.dispatch({
                    id: 'eventsActionTaskGrid',
                    type: 'GRID_RELOAD'
                });
            }, angular.noop);
        };

        this.onSaveActionPlan = () => {
            this.actionTaskRequired = false;
            $scope.eventsActionTaskForm.$setPristine();
            $scope.eventsActionTaskForm.$setUntouched();
            $timeout(() => {
                if (this.eventActionPlanForm.$valid) {
                    this.assignActionPlan({
                        iev_id: this.eventID,
                        pln_id: this.actionPlan.pln_id,
                        plan_name: this.actionPlan.plan_name,
                        description: this.actionPlan.description,
                        save_plan_to_library: this.actionPlan.save_plan_to_library,
                        tasks: this.actionPlan.tasks.map((task) => {
                            return {
                                ...omit(task, 'responsible_person'),
                                completion_date: formatDate(task.completion_date, DATE_FORMAT.OUTPUT),
                                responsible_person_uuid: task.responsible_person.subject_uuid,
                            };
                        })
                    }).then(() => {
                        this.getActionPlanData().then(() => {
                            Notifier.success('Action Plan has been successfully saved');
                        });
                    });
                } else Notifier.error('Please fill in the form fields');
            }, 100);
        };

        this.onSaveActionTask = () => {
            this.actionTaskRequired = true;
            const { eventsActionTaskForm } = $scope;
            $timeout(async () => {
                if (eventsActionTaskForm.$valid) {
                    this.actionTaskRequired = false;
                    await this.assignActionTask({
                        task_name: this.actionPlan.task_name,
                        details: this.actionPlan.task_details,
                        responsible_person: this.actionPlan.responsible_person,
                        completion_date: this.actionPlan.completion_date,
                        save_task_to_library: this.actionPlan.save_task_to_library,
                        status: this.actionPlan.actionTaskStatusList[0].label,
                        status_id: this.actionPlan.actionTaskStatusList[0].value,
                        progress: 0
                    });
                    this.resetActionTask();
                    this.getActionTasks();
                    eventsActionTaskForm.$setPristine();
                    eventsActionTaskForm.$setUntouched();
                } else {
                    for (const item of eventsActionTaskForm.$$controls) {
                        if (item.$invalid) {
                            item.$setTouched();
                        }
                    }
                    Notifier.error('Please fill in the form fields');
                }
            }, 100);
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            if (this.dialog)
                $mdDialog.hide(this.dialog);
        }


    },
    template: require('./events-action-plan.html')
};
