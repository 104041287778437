var training = {
    templateUrl: './safety-training.html',
    controller: 'SafetyTrainingController'
};

angular
    .module('components.safety-training')
    .component('safety-training', training)
    .config(function($stateProvider) {
        $stateProvider
            .state('app.safetyTraining', {
                abstract: true,
                url: '/safety-training'
            })
            .state('app.safetyTraining.dashboard', {
                url: '',
                component: 'dashboard'
            })
            //LASER SAFETY TRAINING
            .state('app.safetyTraining.laser', {
                abstract: true,
                url: '/laser'
            })
            .state('app.safetyTraining.laser.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.safetyTraining.laser.videos', {
                url: '/videos',
                trainingType: 'training_laser',
                trainingColor: '#ff355e',
                trainingName: 'LaserSafetyTraining',
                template: require('./views/safety-training-videos.html'),
                controller: 'SafetyTrainingReviewPresentationController'
            })
            .state('app.safetyTraining.laser.manageReview', {
                url: '/manage-review',
                trainingType: 'training_laser',
                trainingColor: '#ff355e',
                trainingName: 'LaserSafetyTraining',
                template: require('./views/safety-training-manage-review.html'),
                controller: 'SafetyTrainingManageReviewController as safetyTrainingManageReview'
            })
            .state('app.safetyTraining.laser.trainingMatrix', {
                url: '/manage-training-matrix',
                trainingType: 'training_laser',
                trainingColor: '#ff355e',
                trainingName: 'LaserSafetyTraining',
                template: require('../safety-plan/views/safety-plan-manage-training-matrix.html'),
                controller: 'SafetyPlanManageEmployeeTrainingMatrixController'
            })
            .state('app.safetyTraining.laser.reviewReport', {
                url: '/review-report',
                trainingType: 'training_laser',
                trainingColor: '#ff355e',
                trainingName: 'LaserSafetyTraining',
                template: require('../safety-plan/views/safety-plan-review-report.html'),
                controller: 'SafetyPlanReviewReportController'
            })
            //RADIATION SAFETY TRAINING
            .state('app.safetyTraining.radiation', {
                abstract: true,
                url: '/radiation'
            })
            .state('app.safetyTraining.radiation.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.safetyTraining.radiation.videos', {
                url: '/videos',
                trainingType: 'training_radiation',
                trainingColor: '#51a720',
                trainingName: 'RadiationSafetyTraining',
                template: require('./views/safety-training-videos.html'),
                controller: 'SafetyTrainingReviewPresentationController'
            })
            .state('app.safetyTraining.radiation.virtualTheatres', {
                abstract: true,
                url: '/virtual-theatres'
            })
            .state('app.safetyTraining.radiation.virtualTheatres.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.safetyTraining.radiation.virtualTheatres.Fluoroscopy', {
                url: '/fluoroscopy',
                trainingType: 'fluoroscopy',
                trainingColor: '#51a720',
                trainingName: 'RadiationSafetyTraining',
                template: require('./views/safety-training-animations.html'),
                controller: 'SafetyTrainingReviewAnimationController',
                resolve: {
                    loadUnityProgress: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load('./media/animation/training_radiation/fluoroscopy/TemplateData/UnityProgress.js');
                    }],
                    loadUnityLoader: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load('./media/animation/training_radiation/fluoroscopy/Build/UnityLoader.js');
                    }]
                }
            })
            .state('app.safetyTraining.radiation.virtualTheatres.nuclearMedicineFacility', {
                url: '/nuclear-medicine-facility',
                trainingType: 'nuclear-medicine-facility',
                trainingColor: '#51a720',
                trainingName: 'RadiationSafetyTraining',
                template: require('./views/safety-training-animations.html'),
                controller: 'SafetyTrainingReviewAnimationController',
                resolve: {
                    loadUnityProgress: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load('./media/animation/training_radiation/nuclear-medicine-facility/TemplateData/UnityProgress.js');
                    }],
                    loadUnityLoader: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load('./media/animation/training_radiation/nuclear-medicine-facility/Build/UnityLoader.js');
                    }]
                }
            })
            .state('app.safetyTraining.radiation.virtualTheatres.virtualTheatreCT', {
                abstract: true,
                url: '/virtual-theatre-ct'
            })
            .state('app.safetyTraining.radiation.virtualTheatres.virtualTheatreCT.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.safetyTraining.radiation.virtualTheatres.virtualTheatreCT.OarmCBVT', {
                url: '/o-arm-cbvt',
                trainingType: 'o-arm-cbvt',
                trainingColor: '#51a720',
                trainingName: 'RadiationSafetyTraining',
                template: require('./views/safety-training-animations.html'),
                controller: 'SafetyTrainingReviewAnimationController',
                resolve: {
                    loadUnityProgress: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load('./media/animation/training_radiation/o-arm-cbvt/TemplateData/UnityProgress.js');
                    }],
                    loadUnityLoader: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load('./media/animation/training_radiation/o-arm-cbvt/Build/UnityLoader.js');
                    }]
                }
            })
            .state('app.safetyTraining.radiation.manageReview', {
                url: '/manage-review',
                trainingType: 'training_radiation',
                trainingColor: '#51a720',
                trainingName: 'RadiationSafetyTraining',
                template: require('./views/safety-training-manage-review.html'),
                controller: 'SafetyTrainingManageReviewController as safetyTrainingManageReview'
            })
            .state('app.safetyTraining.radiation.trainingMatrix', {
                url: '/manage-training-matrix',
                trainingType: 'training_radiation',
                trainingColor: '#51a720',
                trainingName: 'RadiationSafetyTraining',
                template: require('../safety-plan/views/safety-plan-manage-training-matrix.html'),
                controller: 'SafetyPlanManageEmployeeTrainingMatrixController'
            })
            .state('app.safetyTraining.radiation.reviewReport', {
                url: '/review-report',
                trainingType: 'training_radiation',
                trainingColor: '#51a720',
                trainingName: 'RadiationSafetyTraining',
                // template: require('./views/safety-plan-review-report.html'),
                template: require('../safety-plan/views/safety-plan-review-report.html'),
                controller: 'SafetyPlanReviewReportController'
            });
    });
