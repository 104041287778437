import { TAB_HIDE } from '../../../common/tabs/tabs.constants';

export const eventsSafetyRiskAddState = {
    name: 'app.events.addSafetyRiskPeople',
    redirectTo: 'app.events.addSafetyRiskPeople.details',
    url: '/add',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            id: 'eventItem',
            type: 'EVENT_RESET'
        });
        $ngRedux.dispatch({
            id: 'app.events.addSafetyRiskPeople',
            type: TAB_HIDE,
            state: [
                'app.events.addSafetyRiskPeople.assessmentHazard',
                'app.events.addSafetyRiskPeople.assessmentIncident',
                'app.events.addSafetyRiskPeople.assessmentHistoricalRecord',
                'app.events.addSafetyRiskPeople.alertEscalate',
                'app.events.addSafetyRiskPeople.actionPlan',
                'app.events.addSafetyRiskPeople.actionPlanLibrary',
                'app.events.addSafetyRiskPeople.actionTaskLibrary'
            ]
        });
    },
};

export const eventsSafetyRiskAddDetailsState = {
    name: 'app.events.addSafetyRiskPeople.details',
    url: '?id',
};

export const eventsSafetyRiskAddAssessmentHazardState = {
    name: 'app.events.addSafetyRiskPeople.assessmentHazard',
    url: '/assessment?id',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            id: 'app.events.addSafetyRiskPeople',
            type: TAB_HIDE,
            state: ['app.events.addSafetyRiskPeople.assessmentHistoricalRecord']
        });
    }
};

export const eventsSafetyRiskAddAssessmentHistoricalRecordState = {
    name: 'app.events.addSafetyRiskPeople.assessmentHistoricalRecord',
    url: '/assessment/historical-record?id',
};

export const eventsSafetyRiskAddAssessmentIncidentState = {
    name: 'app.events.addSafetyRiskPeople.assessmentIncident',
    url: '/assessment?id',
};

export const eventsSafetyRiskAddAlertEscalateState = {
    name: 'app.events.addSafetyRiskPeople.alertEscalate',
    url: '/alert-escalate?id',
};

export const eventsSafetyRiskAddActionPlanState = {
    name: 'app.events.addSafetyRiskPeople.ActionPlan',
    url: '/action-plan?id',
};

export const eventsSafetyRiskAddActionPlanLibraryState = {
    name: 'app.events.addSafetyRiskPeople.ActionPlanLibrary',
    url: '/action-plan-library',
    /* @ngInject */
    onExit: ($ngRedux) => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: 'app.events.addSafetyRiskPeople',
            state: ['app.events.addSafetyRiskPeople.actionPlanLibrary']
        });
    }
};

export const eventsSafetyRiskAddActionTaskLibraryState = {
    name: 'app.events.addSafetyRiskPeople.ActionTaskLibrary',
    url: '/action-task-library',
    /* @ngInject */
    onExit: ($ngRedux) => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: 'app.events.addSafetyRiskPeople',
            state: ['app.events.addSafetyRiskPeople.ActionTaskLibrary']
        });
    }
};
