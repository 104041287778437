import { TAB_SHOW } from '../../common/tabs/tabs.constants';
import * as EventActions from './events.actions'

/**
 * @const {object}
 * @memberOf module:Events
 */
export const events = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $mdDialog, $rootScope, NavigationService, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            const navConfig = NavigationService.getConfig($state.$current);
            this.colorName = navConfig.colorName;
            this.icon = navConfig.icon;

            this.unsubscribe = $ngRedux.connect(({ events }) => ({
                eventItem: events.eventItem
            }), EventActions)(this);
        }

        this.onEventSelect = ({ item }) => {
            this.getEventDetails(item.iev_id).then(() => {
                const tabs = [
                    'app.events.manageRisksIncidents.details',
                    'app.events.manageRisksIncidents.alertEscalate',
                    'app.events.manageRisksIncidents.actionPlan'
                ];
                if (['srp','arr'].includes(item.risk_code)) {
                    switch (this.eventItem.event_code) {
                        case 'evnhzd':
                        case 'evnrme':
                        case 'evnxry':
                        case 'evnlas':
                            tabs.push('app.events.manageRisksIncidents.assessmentHazard');
                            break;
                        case 'evninc':
                            tabs.push('app.events.manageRisksIncidents.assessmentIncident');
                            break;
                    }
                } else if (item.risk_code === 'orb') {
                    switch (this.eventItem.event_code) {
                        case 'evnhzd':
                            tabs.push('app.events.manageRisksIncidents.assessmentThreat');
                            break;
                        case 'evninc':
                            tabs.push('app.events.manageRisksIncidents.assessmentIssue');
                            break;
                    }
                }
                $ngRedux.dispatch({
                    type: TAB_SHOW,
                    id: 'app.events.manageRisksIncidents',
                    state: tabs,
                    stateParams: { id: item.iev_id }
                });
            });
        };

        this.onEventReport = ({ item }) => {
            Notifier.info('Please wait...', false);
            this.getEventReport(item.iev_id, item.print_event_id)
                .then(() => Notifier.success('Success'))
                .catch(() => Notifier.error('System Error!'));
        };

        this.onEventRemove = (event) => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Chosen event will be removed permanently.')
                .ariaLabel('Remove event')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                this.removeEvent(event.item.iev_id, 'eventsGrid');
            }, angular.noop);
        };

        this.onGridReset = (event) => {
            $rootScope.$broadcast('SELECT_CASCADE_RESET', 'equipmentFilterForm');
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        };
    },
    template: `
        <events-grid
            on-select="$ctrl.onEventSelect($event)"
            on-report="$ctrl.onEventReport($event)" 
            on-remove="$ctrl.onEventRemove($event)"
            on-reset="$ctrl.onGridReset($event)">
        </events-grid>
    `
};
