import API                          from '../../../common/http/api.fetch';
import {success, responseMessage}   from '../../../common/utils/util.notifier';
import I18N                         from '../../../common/utils/util.i18n';
import { TYPES }                    from './audit-action-type.constants';
import { TYPES as ACC_TYPES }                    from '../accreditation-action-type.constants';
import { IDS }                      from './audit-action-id.constants';
import moment from 'moment';


export const fetchAudits = (scheme_code, slo_uuid, year, fiscalYear, task_type = undefined, exportFormat = undefined) => {
    return dispatch => {
        const numYearsDisplayed = 2;
        return API.fetch({
            url: '/accreditation/list-audits',
            method: 'GET',
            params: {
                scheme_code,
                slo_uuid,
                fiscal_year: year,
            }
        }).then(response => {
                if(exportFormat)
                    return response;

                const standardRows = response.data.map(audit => {
                    const cells = audit.standard_level === 1 ? [] : Array(numYearsDisplayed * 12);
                    const reviewers = JSON.parse(audit.review_tasks);
                    const firstReviewTask = reviewers.length ? reviewers[0] : null;
                    if(firstReviewTask) {
                        const dueDate = moment(firstReviewTask.due_date);
                        const yearOffset = (dueDate.year() - year) * 12;
                        const cellIndex = dueDate.month() - fiscalYear + yearOffset;
                        cells[cellIndex] = {
                            reviewers,
                            auditId:        audit.id,
                            standardName:   audit.standard_name,
                            statusCode:     audit.status,
                            statusName:     I18N.translate(`ACCREDITATION.TASKS.STATUS.${audit.status.toUpperCase()}`),
                            date:           firstReviewTask.due_date,
                        }
                    }
                    return {
                        id: audit.ast_id,
                        name: audit.standard_level === 1 ? '' : audit.standard_name,
                        title: audit.standard_level === 1 ? audit.standard_name : '',
                        colspan: audit.standard_level === 1 ? numYearsDisplayed * 12 : 1,
                        cells,
                    }
                });

                const date = moment().year(year).month(fiscalYear).subtract(1, 'months');
                const monthColumns = [...Array(numYearsDisplayed * 12).keys()].map(() => {
                    return date.add(1, 'months').format('MMM YYYY');
                });

                const currentYear = moment().year();
                const currentMonth = moment().month();
                let currentFiscalYear = currentYear;
                if (currentMonth < fiscalYear) {
                    currentFiscalYear -= 1;
                }

                dispatch({
                    type: TYPES.FETCH_SCHEDULE,
                    id: IDS.accAudit,
                    monthColumns,
                    standardRows,
                    currentFiscalYear,
                });
            },
            error => responseMessage(error))
    }
}

export const fetchSiteLocationDetails = (siteLocationUuid) => {
    return dispatch => {
        return API.fetch({
            url: `/location/sitelocation/${siteLocationUuid}`,
            method: 'GET',
            params: {}
        }).then(response => {
            const siteLocation = response.data[0][0];
            const fiscalYearStart = parseInt(siteLocation.fiscal_year_start) - 1;
            let maxYear = moment().year();
            if(fiscalYearStart <= moment().month()) {
                maxYear++;
            }
            const maxDueDate = moment(`${maxYear}-01-01`).add(fiscalYearStart, 'months').subtract(1,'days').toDate();
            const minDueDate = new Date();
            dispatch({
                type: TYPES.FETCH_SITE_LOCATION_DETAILS,
                siteLocation: {
                    locationName: siteLocation.name,
                    uuid: siteLocation.subject_uuid,
                    fiscalYearStart,
                    maxDueDate,
                    minDueDate,
                },
            })

            let fiscalYear = moment().year();
            if(fiscalYearStart > moment().month()) {
                fiscalYear--;
            }
            dispatch({
                type: ACC_TYPES.LOCATION_SEARCH_BAR_CHANGE,
                accDashboardLocationYear: {
                    year: fiscalYear,
                }
            })
        })
    }
}

export const fetchSiteLocationContacts = (uuid) => {
    return dispatch => {
        return API.fetch({
            url: `/location/contacts-list`,
            method: 'GET',
            params: { uuid }
        }).then(({data}) => {
            dispatch({
                type: TYPES.FETCH_SITE_LOCATION_MANAGERS,
                autocompleteReviewers: data.reduce((result, user) => {
                    if(!result.find(item => item.sub_uuid === user.sub_uuid)) {
                        result.push({ ...user,
                            employee_uuid: user.sub_uuid,
                            subject_type_name: user.sty_name,
                        })
                    }
                    return result;
                },[]),
            })
        })
    }
}
