import {TabsReducer} from '../../../common/tabs/tabs.reducer';
import {IDS} from './standard-action-id.constants';

export const standardTabs = {
    [IDS.app_accreditation_scheme_standards_documents]: Object.assign( TabsReducer(),
        { tabs: [
            {
                state: IDS.app_accreditation_scheme_standards_documents_list,
                title: 'Standard Documents',
                visible: true,
                disabled: false,
                loading: false,
            },
            {
                state: IDS.app_accreditation_scheme_standards_documents_details,
                title: 'Document Details',
                visible: false,
                disabled: false,
                loading: false
            }
        ]}
    ),
}
