import API from '../../../../common/http/api.fetch';
// import I18N from '../../../../common/utils/util.i18n';
import {success, responseMessage} from '../../../../common/utils/util.notifier';
import { GRID_FETCH, GRID_RELOAD, GRID_FILTER, GRID_SELECT_ALL } from '../../../../common/grid/grid.constants';
import { TYPES } from '../settings-action-type.constants';
import { IDS } from '../settings-action-id.constants';
import { getDocFileNameFromPath } from "../../../../common/utils/util.path";
import truncate from 'lodash/truncate';
import {TAB_SELECT} from "../../../../common/tabs/tabs.constants";

// const localizeType = (code, defaultName) => {
//     const textId = `DOCUMENT_MANAGEMENT.TYPES.${code.toUpperCase()}`;
//     const translation = I18N.translate(textId);
//     return translation === textId ? defaultName : translation
// };

export const fetchHofRequiredDocsGrid = (filter) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/required-doc-list',
            method: 'GET',
            params: {
                ...filter,
                scope_subject_types: 'cli,hof',
                path: undefined
            }
        }).then(({data}) => {

            // parse json string to array of objects
            const items = data.items.map( item => ({
                ...item,
                standards: JSON.parse(item.standards)
            }));

            dispatch({
                type:   GRID_FETCH,
                id:     IDS.accSettings,
                path:   filter.path,
                ...data,
                items: items.map( item => ({
                    ...item,
                    type: item.type_name,
                    filename: getDocFileNameFromPath(item.doc_uri),
                    standards: item.standards.map(doc => doc.name).join(),
                    standardsColumn: truncate(item.standards.map(doc => doc.name).join(),{ 'length': 50}),
                    sampleDocFilename: getDocFileNameFromPath(item.sample_doc_uri),
                }))
            })
        }, error => {
            responseMessage(error);
        });
    };
}

export const addHeadOfficeRequiredDoc = (data) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/add-required-doc',
            method: 'POST',
            data
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_ADDED');
            dispatch({
                type: GRID_RELOAD,
                id: IDS.accSettings,
                path: IDS.hofRequiredDocsGrid,
            });
            dispatch({
                type: TAB_SELECT,
                id: IDS.app_accreditation_scheme_settings_hof_required_docs,
                state: IDS.app_accreditation_scheme_settings_hof_required_docs_list,
            });
        }, error => {
            responseMessage(error);
        });
    };
}

export const editHeadOfficeStandardRequiredDocument = (data) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/edit-hof-required-doc',
            method: 'POST',
            data,
        }).then(response => {
            dispatch({
                type:   GRID_RELOAD,
                id:     IDS.accSettings,
                path:   IDS.hofRequiredDocsGrid,
            })
            success('COMMON.SERVER_RESPONSE.ITEM_UPDATED');
        }, error => {
            responseMessage(error);
        });
    };
}

export const invalidateRequiredDocument = (ard_id, path) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/invalidate-stand-required-doc',
            method: 'POST',
            data: { ard_id }
        }).then(response => {
            success('COMMON.SERVER_RESPONSE.ITEM_DELETED');
            dispatch({
                type:   GRID_RELOAD,
                id:     IDS.accSettings,
                path,
            });
        }, error => {
            responseMessage(error);
        });
    };
}

// export const removeDocumentFromAllStandards = (vdo_uuid, path) => {
//     return dispatch => {
//         return API.fetch({
//             url: '/accreditation/remove-doc-from-all-standards',
//             method: 'POST',
//             data: {
//                 vdo_uuid,
//                 only_default_and_head_office_documents: true
//             }
//         }).then(response => {
//             success('COMMON.SERVER_RESPONSE.ITEM_DELETED');
//             dispatch({
//                 type:   GRID_RELOAD,
//                 id:     IDS.accSettings,
//                 path,
//             });
//         }, error => {
//             responseMessage(error);
//         });
//     };
// }

// export const setAssessmentItemTemplate = (aas_id, slo_uuid, template, path) => {
//     return dispatch => {
//         return API.fetch({
//             url: '/accreditation/assessment-template-set',
//             method: 'POST',
//             data: {
//                 aas_id,
//                 slo_uuid,
//                 template: template || undefined
//             }
//         }).then(response => {
//             success('COMMON.SERVER_RESPONSE.ITEM_UPDATED');
//             dispatch({
//                 type:   GRID_RELOAD,
//                 id:     IDS.accSettings,
//                 path,
//             });
//         }, error => {
//             responseMessage(error);
//         });
//     };
// }

// export const swapAssessmentItemsOrder = (target_id, dest_id, path) => {
//     return dispatch => {
//         return API.fetch({
//             url: '/accreditation/assessment-swap',
//             method: 'POST',
//             data: {
//                 target_id,
//                 dest_id,
//             }
//         }).then(response => {
//             dispatch({
//                 type:   GRID_RELOAD,
//                 id:     IDS.accSettings,
//                 path,
//             })
//         }, error => {
//             responseMessage(error);
//         });
//     };
// }

// export const restoreLocationAssessment = (ast_id, slo_uuid, path) => {
//     return dispatch => {
//         return API.fetch({
//             method: 'POST',
//             url: '/accreditation/assessment-location-restore',
//             data: { ast_id, slo_uuid }
//         }).then(() => {
//             dispatch({
//                 type:   GRID_RELOAD,
//                 id:     IDS.accSettings,
//                 path,
//             })
//             success('Location Assessment Restored');
//         }, error => {
//             responseMessage(error);
//         })
//     }
// }

export const fetchHofRequiredDocHofsGrid = (filter) => {
    // if(!filter?.ard_id)
    //     return;
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/required-doc-head-offices-list',
            method: 'GET',
            params: {
                ...filter,
                path: undefined,
                newAssessmentHeadOffices: undefined
            }
        }).then(({data}) => {
            dispatch({
                type:   GRID_FETCH,
                id:     IDS.accSettings,
                path:   filter.path,
                items: data.map( item => ({
                    ...item,
                    filename: getDocFileNameFromPath(item.doc_uri) || 'N/A'
                })),
            });
            dispatch({
                type:   GRID_SELECT_ALL,
                id:     IDS.accSettings,
                path:   filter.path,
                data:   data.filter( item => item.applicable === 1).map( item => item.hof_uuid)
            });
        }, error => {
            responseMessage(error);
        });
    };
}
// export const fetchAssignedAssessmentHeadOffices = (aas_id) => {
//     return dispatch => {
//         return API.fetch({
//             url:    '/accreditation/required-doc-head-offices-list',
//             method: 'GET',
//             params: {
//                 aas_id,
//                 limit: -1
//             }
//         }).then(response => {
//             return  response.data.items.map( item => (item.hof_uuid))
//         }, error => {
//             responseMessage(error);
//         });
//     };
// }

export const fetchSiteLocationDetails = (siteLocationUuid) => {
    return dispatch => {
        return API.fetch({
            url: `/location/sitelocation/${siteLocationUuid}`,
            method: 'GET',
            params: {}
        }).then(response => {
            return response.data[0][0];
        })
    }
}

export const fetchEnabledHeadOffices = (schemeId) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/scheme-locations',
            method: 'GET',
            params: {
                asc_id: schemeId
            }
        }).then(({data}) => {
            dispatch({
                type: TYPES.FETCH_SCHEME_ENABLED_HEAD_OFFICES,
                id: IDS.hofRequiredDocs,
                items: data.items.map( item => ({
                        value: item.slo_uuid,
                        label: item.slo_name,
                }))
            });
            return data.items;
        })
    }
}


export const getSchemeHeadOffices = (schemeId, assignedHeadOffices) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/scheme-locations',
            method: 'GET',
            params: {
                asc_id: schemeId,
            }
        }).then(({data}) => {
            const unassignedHofsOnly = data.items.filter(item => assignedHeadOffices.includes(item.slo_uuid) === false);
            dispatch({
                type: TYPES.FETCH_ASS_HOF_SCHEME_HEAD_OFFICES,
                id: IDS.accreditationSettingsAssessmentHeadOffice,
                items: unassignedHofsOnly.map(item => ({
                    value: item.slo_uuid,
                    label: item.slo_name,
                })),
            });
            return data.items.length
        })
    }
}