/* @ngInject */
export function AccStandardFactory(ACC_STANDARD_TYPE) {
    return function AccStandard() {

        this.standardId = null;
        this.accreditationId = null;
        this.parentId = null;
        this.number = null;
        this.name = null;
        this.description = null;
        this.type = ACC_STANDARD_TYPE.NONE;
        this.templateDocuments = [];
        this.children = {};
        this.progress = 0;
        this.assessment = null;
    };
}
