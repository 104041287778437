/* @ngInject */
export function LicenceGridFilterFactory(GridFilter, LicenceType, LicenceStatus) {
    function LicenceFilter() {

        GridFilter.call(this);

        this.type = new LicenceType();
        this.status = new LicenceStatus();

        this.reset = function() {
            this.type = new LicenceType();
            this.status = new LicenceStatus();
            GridFilter.prototype.reset();
        };
    }

    LicenceFilter.prototype = Object.create(GridFilter.prototype);

    return LicenceFilter;
}
