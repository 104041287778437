export const personalDosimetryState = {
    name: 'app.personalDosimetry',
    abstract: true,
    url: '/personal-dosimetry'
};

export const personalDosimetryDashboardState = {
    name: 'app.personalDosimetry.dashboard',
    url: '',
    component: 'dashboard'
};
