/* @ngInject */
export function SafetyTrainingReviewPresentationController(
    $scope,
    $rootScope,
    $state,
    $filter,
    Notifier,
    NetSpeedService,
    SAFETY_PLAN_REVIEW_NET_SPEED,
    SafetyTrainingService,
    $mdDialog,
    $timeout,
) {
    $scope.cursorState = 'wait';
    $scope.review = null;
    $scope.reviewVideos = null;
    $scope.selectedVideo = null;
    $scope.year = new Date().getFullYear();
    $scope.initializationInProgress = true;

    $scope.fetchReviewData = function () {

        SafetyTrainingService.getCandidateReview($state.current.trainingType, null, $scope.year, null)
            .then(function (data) {

                if (data.result != null) {
                    $scope.review = data.result;

                    SafetyTrainingService.getPresentationVideos($state.current.trainingType, null, $scope.review.presentation.id)
                        .then(function (items) {
                            $scope.reviewVideos = items.list;
                            for (var i = 0; i < $scope.reviewVideos.length; i++) {
                                if ($scope.review.nextSection == $scope.reviewVideos[i].presentationOrder) {
                                    $scope.scheduledVideo = $scope.reviewVideos[i];
                                    $scope.selectedVideo = $scope.reviewVideos[i];
                                }
                            }
                            $scope.cursorState = 'default';
                            $scope.initializationInProgress = false;
                        }, function (error) {
                            Notifier.error($filter('translate')("SAFETY_TRAINING.TRAINING_VIDEO_REVIEW.ERROR_LOADING_USER_TRAINING_REVIEW_DATA"));
                            $scope.cursorState = 'default';
                            $scope.initializationInProgress = false;
                        });

                } else {
                    //No sheduled review
                    $timeout(function () {
                        $scope.selectedVideo = null;
                        $scope.scheduledVideo = null;
                        $scope.reviewVideos = null;
                        Notifier.info("There are no more pending Training reviews");
                        $scope.initializationInProgress = false;
                        $scope.cursorState = 'default';
                    }, 300);
                }

            }, function (error) {
                $scope.selectedVideo = null;
                $scope.scheduledVideo = null;
                $scope.reviewVideos = null;
                Notifier.error($filter('translate')("SAFETY_TRAINING.TRAINING_VIDEO_REVIEW.ERROR_LOADING_USER_TRAINING_REVIEW_DATA"));
                $scope.initializationInProgress = false;
                $scope.cursorState = 'default';
            });
    };

    $scope.checkNetSpeed = function () {
        NetSpeedService.testNetSpeed()
            .then(function (result) {
                Notifier.info($filter('translate')("SAFETY_TRAINING.TRAINING_VIDEO_REVIEW.INFO_MEASURE_SPEED") + result + " Kbps.");
                if (result < SAFETY_PLAN_REVIEW_NET_SPEED.BANDWIDTH_LIMIT) {
                    Notifier.warn($filter('translate')("SAFETY_TRAINING.TRAINING_VIDEO_REVIEW.WARN_SLOW_NETWORK"));
                }
            }, function (error) {
                //console.log($filter('translate')("SAFETY_TRAINING.TRAINING_VIDEO_REVIEW.ERROR_MEASURE_SPEED") + error);
            });
    };

    $scope.videoPlaybackEnded = function (video) {
        var maxVideoPresentationOrder = $scope.reviewVideos.reduce(function (max, item) {
            return parseInt(item.presentationOrder) > max ? parseInt(item.presentationOrder) : max;
        }, 0);
        if (video.presentationOrder == maxVideoPresentationOrder) {
            //show confirmation dialog
            $scope.onRsppReviewFinished(video);
        } else {
            SafetyTrainingService.updateEmployeeReview($state.current.trainingType, $scope.review.id, video.presentationOrder)
                .then(function (items) {
                    $scope.fetchReviewData();
                }, function (error) {
                    Notifier.error($filter('translate')("SAFETY_TRAINING.TRAINING_VIDEO_REVIEW.ERROR_UPDATING_USER_REVIEW"));
                    $scope.cursorState = 'default';
                });
        }
    };

    $scope.onRsppReviewFinished = function (item) {
        $mdDialog.show({
            controller: TrainingReviewConfirmationController,
            templateUrl: './safety-training-review-confirmation-dialog.html',
            scope: $scope, // use parent scope in template
            preserveScope: true,
            locals: {
                color: $state.current.trainingColor,
                title: $filter('translate')("SAFETY_TRAINING.TRAINING_VIDEO_REVIEW.DIALOG.REVIEW_FINISHED.TITLE"),
                reviewName: $filter('translate')("SAFETY_TRAINING.TRAINING_VIDEO_REVIEW.DIALOG.REVIEW_FINISHED.NAME"),
                video: item
            },
            clickOutsideToClose: false
        });
    };

    function TrainingReviewConfirmationController($scope, $mdDialog, color, title, reviewName, video) {
        $scope.headerColor = color;
        $scope.headerTitle = title;
        $scope.reviewName = reviewName;

        $scope.cancel = function () {
            $timeout(function () {
                $scope.fetchReviewData();
            }, 200);
            $mdDialog.cancel();
        };

        $scope.submit = function (subject, message) {
            var finishedReviewId = $scope.review.id;
            SafetyTrainingService.updateEmployeeReview($state.current.trainingType, finishedReviewId, video.presentationOrder)
                .then(function (items) {
                    $scope.fetchReviewData();
                    $mdDialog.hide();
                    $timeout(function () {
                        showTrainingReviewGetCertificateDialog($scope, finishedReviewId, $scope.reviewName);
                    }, 200);
                }, function (error) {
                    Notifier.error($filter('translate')("SAFETY_TRAINING.TRAINING_VIDEO_REVIEW.ERROR_UPDATING_USER_REVIEW"));
                    $scope.cursorState = 'default';
                    $mdDialog.hide();
                });
        };
    }

    function showTrainingReviewGetCertificateDialog(scope, finishedReviewId, reviewName) {
        $mdDialog.show({
            controller: TrainingReviewGetCertificate,
            templateUrl: './safety-training-review-download-certificate-dialog.html',
            scope: scope, // use parent scope in template
            preserveScope: true,
            locals: {
                color: $state.current.trainingColor,
                title: $filter('translate')("SAFETY_TRAINING.TRAINING_VIDEO_REVIEW.DIALOG.DOWNLOAD_CERTIFICATE.TITLE"),
                finishedReviewId: finishedReviewId,
                reviewName: reviewName
            },
            clickOutsideToClose: false
        });
    }

    function TrainingReviewGetCertificate($scope, $mdDialog, color, title, finishedReviewId, reviewName) {
        $scope.headerColor = color;
        $scope.headerTitle = title;
        $scope.reviewName = reviewName;

        $scope.cancelDownload = function () {
            $timeout(function () {
                $scope.fetchReviewData();
            }, 200);
            $mdDialog.cancel();
        };

        $scope.download = () => {
            SafetyTrainingService.generateCertificate($state.current.trainingType, finishedReviewId).then(() => {
                $mdDialog.hide();
                $scope.fetchReviewData();
            }, () => {
                Notifier.error($filter('translate')("SAFETY_TRAINING.TRAINING_VIDEO_REVIEW.ERROR_GENERATING_CERTIFICATE"));
                $mdDialog.hide();
                $scope.fetchReviewData();
            });
        };
    }

    $scope.checkNetSpeed();
    $scope.fetchReviewData();
}
