import { IDS } from '../../settings-action-id.constants';
import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';
import * as HeadOfficesActions from './head-offices.actions';

class HeadOfficesGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$filter = $filter;
        this.$state = $state;
    }

    $onInit() {
        this.module = 'accreditation';
        this.identifier = IDS.accSettings;
        this.statePath = IDS.headOfficesGrid;
        this.filterExpandButtonVisible = false;
        this.exportComponent = false;
        this.autoload = false;
        this.columns = [
            {title: '#', mapper: null},
            {title: this.$filter('translate')('ACCREDITATION.SETTINGS.HEAD_OFFICES.ENABLED_HEAD_OFFICES'), mapper: 'slo_name'},
        ];
        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), {...HeadOfficesActions})(this);
        this.dataAction = this.listHeadOfficesByScheme;
        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

HeadOfficesGridController.$inject = ['$ngRedux', '$state', 'NavigationService', '$filter']

export const headOfficesGrid = {
    bindings: {
        title: '@',
        onRemove: '&',
        onSelect: '&',
    },
    controller: HeadOfficesGridController,
    ...gridTpl
}
