import has from 'lodash/has';

export function ResponseStatusInterceptor($q, Notifier, ResponseStatusService) {
    return {
        response: function(response) {
            if (has(response, 'data.status')) {
                if (parseInt(response.data.status)) {
                    Notifier.autoNotify(response.data.status);
                    if (ResponseStatusService.rejectStatus(response.data.status))
                        return $q.reject(response);
                }
            }
            return response;
        }
    }
}

ResponseStatusInterceptor.$inject = ['$q', 'Notifier', 'ResponseStatusService'];
