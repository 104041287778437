/* @ngInject */
export function EmployeeQualificationService($http, API_BASE, EmployeeQualification) {

    this.getQualifications = function(employeeUuid, onlyAssigned) {
        return $http({
            url: API_BASE + "/administration/employee/qualifications",
            method: "GET",
            params: {
                employeeUuid: employeeUuid,
                onlyAssigned: onlyAssigned
            }
        }).then(function(response) {
            return response.data.map(function(item) {
                var qualification   = new EmployeeQualification();
                qualification.id    = item.qua_id;
                qualification.name  = item.name;
                return qualification;
            });
        });
    };

    this.addQualification = function(qualification, subUuid) {
        return $http({
            url: API_BASE + "/administration/employee/qualification-add",
            method: "POST",
            data: {
                id: qualification.id,
                name: qualification.name,
                sub_uuid: subUuid
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.updateQualification = function(qualification) {
        return $http({
            url: API_BASE + "/administration/employee/qualification-update",
            method: "POST",
            data: {
                id: qualification.id,
                name: qualification.name
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.assignQualification = function(uuid, id) {
        return $http({
            url: API_BASE + "/administration/employee/qualification-assign",
            method: "POST",
            data: {
                uuid: uuid,
                id: id
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.rejectQualification = function(uuid, id) {
        return $http({
            url: API_BASE + "/administration/employee/qualification-reject",
            method: "POST",
            data: {
                uuid: uuid,
                id: id
            }
        }).then(function(response) {
            return response.data;
        });
    };
}
