import moment from 'moment'

/* @ngInject */
export function PersonalEquipmentHistoryActivityFactory() {
    return function PersonalEquipmentHistoryActivity() {

        this.id = null;
        this.code = null;
        this.date = null;
        this.type = null;
        this.description = null;
        this.userUuid = null;
        this.userType = null;
        this.userFirstName = null;
        this.userLastName = null;
        
        this.getDate = function() {
            return moment(this.date).format('DD-MM-YYYY hh:mm:ss a');
        };
        
        this.getIssuer = function() {
            return this.userFirstName + ' ' + this.userLastName + ' (' + this.userType + ')';
        }
    };
}
