/**
 * @module root/legacy/components/equipment
 */
import { EquipmentController } from './equipment.controller';
import { EquipmentBaseController } from './controllers/equipment-controller-base';
import { EquipmentXrayAddController } from './controllers/xray/equipment-xray-controller-add';
import { EquipmentXrayManageController } from './controllers/xray/equipment-xray-controller-manage';
import { EquipmentLaserAddController } from './controllers/lasers/equipment-laser-controller-add';
import { EquipmentLaserManageController } from './controllers/lasers/equipment-laser-controller-manage';
import { EquipmentUltrasoundAddController } from './controllers/ultrasound/equipment-ultrasound-controller-add';
import { EquipmentUltrasoundManageController } from './controllers/ultrasound/equipment-ultrasound-controller-manage';
import { EquipmentUltrasoundTransducerController } from './controllers/ultrasound/transducer/equipment-ultrasound-transducer-controller';
import { EquipmentMammoAddController } from './controllers/mammo/equipment-mammo-controller-add';
import { EquipmentMammoManageController } from './controllers/mammo/equipment-mammo-controller-manage';
import { EquipmentGridController } from './grid/equipment-grid.controller';
import { EquipmentGridSwitchController } from './grid/equipment-grid-switch.controller';
import { EquipmentDocumentsGridController } from './grid/equipment-documents-grid.controller';
import { InputEquipmentIdController } from './form/input-equipment-id/input-equipment-id.controller';
import { InputEquipmentSerialController } from './form/input-equipment-serial/input-equipment-serial.controller';
import { SelectEquipmentTypeController } from './form/select-equipment-type/select-equipment-type.controller';
import { SelectEquipmentCategoryController } from './form/select-equipment-category/select-equipment-category.controller';
import { MultiSelectEquipmentCategoryController } from './form/multi-select-equipment-category/multi-select-equipment-category.controller';
import { SelectEquipmentDocumentTypeController } from './form/select-equipment-document-type/select-equipment-document-type.controller';
import { SelectEquipmentDocumentStatusController } from './form/select-equipment-document-status/select-equipment-document-status.controller';
import { SelectEquipmentTestStatusController } from './form/select-equipment-test-status/select-equipment-test-status.controller';
import { SelectEquipmentRequirementsTypeController } from './form/select-equipment-requirements-type/select-equipment-requirements-type.controller';
import { SelectRequirementsDaysRangeController } from './form/select-requirements-days-range/select-requirements-days-range.controller';
import { RequirementsDueController } from './form/requirements-due/requirements-due.controller';
import { EquipmentService } from './services/equipment-service';
import { EquipmentManager } from './services/equipment-manager';
import { EquipmentServiceXray } from './services/equipment-service-xray';
import { EquipmentServiceLaser } from './services/equipment-service-laser';
import { EquipmentServiceUltrasound } from './services/equipment-service-ultrasound';
import { EquipmentServiceTransducer } from './services/equipment-service-transducer';
import { EquipmentServiceMammo } from './services/equipment-service-mammo';
import { EquipmentProposalManager } from './services/proposal/proposal-manager';
import { ProposalService } from './services/proposal/proposal-request-service';
import { ProposalResponseService } from './services/proposal/proposal-response-service';
import { EquipmentActionsFactory } from './equipment.actions';
import { EquipmentFactory } from './factories/equipment-factory';
import { EquipmentTypeFactory } from './factories/equipment-type-factory';
import { EquipmentCategoryFactory } from './factories/equipment-category-factory';
import { EquipmentModelFactory } from './factories/equipment-model-factory';
import { EquipmentSummaryFactory } from './factories/equipment-summary.factory';
import { EquipmentDocumentFactory } from './factories/equipment-document.factory';
import { EquipmentGridFilterFactory } from './factories/equipment-grid-filter-factory';
import { EquipmentOwnerFactory } from './factories/equipment-owner-factory';
import { EquipmentSparePartFactory } from './factories/equipment-spare-part-factory';
import { ProposalPurposeFactory } from './factories/proposal-purpose-factory';
import { ProposalRequestFactory } from './factories/proposal-request-factory';
import { ProposalResponseFactory } from './factories/proposal-response-factory';
import { XrayFactory } from './factories/xray/xray-factory';
import { LaserFactory } from './factories/laser/laser-factory';
import { LaserTypeFactory } from './factories/laser/laser-type-factory';
import { LaserModeFactory } from './factories/laser/laser-mode-factory';
import { LaserClassFactory } from './factories/laser/laser-class-factory';
import { LaserUnitFactory } from './factories/laser/laser-unit-factory';
import { LaserHazardEvaluationFactory } from './factories/laser/laser-hazard-evaluation-factory';
import { LaserPermitApplicationFactory } from './factories/laser/laser-permit-application-factory';
import { UltrasoundFactory } from './factories/ultrasound/ultrasound-factory';
import { TransducerFactory } from './factories/ultrasound/transducer-factory';
import { MammoFactory } from './factories/mammo/mammo-factory';
import { TubeHousingFactory } from './factories/tube-housing-factory';
import { TubeInsertFactory } from './factories/tube-insert-factory';
import { RoomFactory } from './factories/room-factory';
import { RegistrationDataFactory } from './factories/equipment-registration-data-factory';
import { CertificateDataFactory } from './factories/equipment-certificate-data-factory';
import { ServiceDetailsFactory } from './factories/equipment-service-details-factory';
import { EquipmentPropertyFactory } from './factories/equipment-property-factory';
import { equipmentGrid } from './grid/equipment-grid.component';
import { equipmentGridSwitch } from './grid/equipment-grid-switch.component';
import { equipmentGridDepreciationReport } from './grid/equipment-grid-depreciation-report.component';
import { equipmentDocumentsGrid } from './grid/equipment-documents-grid.component';
import { equipmentAlerts } from './alerts/equipment-alerts.component';
import { inputEquipmentId } from './form/input-equipment-id/input-equipment-id.component';
import { inputEquipmentSerial } from './form/input-equipment-serial/input-equipment-serial.component';
import { selectEquipmentType } from './form/select-equipment-type/select-equipment-type.component';
import { selectEquipmentCategory } from './form/select-equipment-category/select-equipment-category.component';
import { multiSelectEquipmentCategory } from './form/multi-select-equipment-category/multi-select-equipment-category.component';
import { selectEquipmentDocumentType } from './form/select-equipment-document-type/select-equipment-document-type.component';
import { selectEquipmentDocumentStatus } from './form/select-equipment-document-status/select-equipment-document-status.component';
import { selectEquipmentTestStatus } from './form/select-equipment-test-status/select-equipment-test-status.component';
import { selectEquipmentRequirementsType } from './form/select-equipment-requirements-type/select-equipment-requirements-type.component';
import { selectRequirementsDaysRange } from './form/select-requirements-days-range/select-requirements-days-range.component';
import { requirementsDue } from './form/requirements-due/requirements-due.component';

angular.module(
    'components.equipment',
    []
).controller({
    EquipmentController,
    EquipmentBaseController,
    EquipmentXrayAddController,
    EquipmentXrayManageController,
    EquipmentLaserAddController,
    EquipmentLaserManageController,
    EquipmentUltrasoundAddController,
    EquipmentUltrasoundManageController,
    EquipmentUltrasoundTransducerController,
    EquipmentMammoAddController,
    EquipmentMammoManageController,
    EquipmentGridController,
    EquipmentGridSwitchController,
    EquipmentDocumentsGridController,
    InputEquipmentIdController,
    InputEquipmentSerialController,
    SelectEquipmentTypeController,
    SelectEquipmentCategoryController,
    MultiSelectEquipmentCategoryController,
    SelectEquipmentDocumentTypeController,
    SelectEquipmentDocumentStatusController,
    SelectEquipmentTestStatusController,
    SelectEquipmentRequirementsTypeController,
    SelectRequirementsDaysRangeController,
    RequirementsDueController
}).service({
    EquipmentService,
    EquipmentManager,
    EquipmentServiceXray,
    EquipmentServiceLaser,
    EquipmentServiceUltrasound,
    EquipmentServiceTransducer,
    EquipmentServiceMammo,
    EquipmentProposalManager,
    ProposalService,
    ProposalResponseService
}).factory({
    EquipmentActions: EquipmentActionsFactory,
    Equipment: EquipmentFactory,
    EquipmentType: EquipmentTypeFactory,
    EquipmentCategory: EquipmentCategoryFactory,
    EquipmentModel: EquipmentModelFactory,
    EquipmentSummary: EquipmentSummaryFactory,
    EquipmentDocument: EquipmentDocumentFactory,
    EquipmentFilter: EquipmentGridFilterFactory,
    EquipmentOwner: EquipmentOwnerFactory,
    EquipmentSparePart: EquipmentSparePartFactory,
    ProposalPurpose: ProposalPurposeFactory,
    ProposalRequest: ProposalRequestFactory,
    ProposalResponse: ProposalResponseFactory,
    Xray: XrayFactory,
    Laser: LaserFactory,
    LaserType: LaserTypeFactory,
    LaserMode: LaserModeFactory,
    LaserClass: LaserClassFactory,
    LaserUnit: LaserUnitFactory,
    LaserHazardEvaluation: LaserHazardEvaluationFactory,
    LaserPermitApplication: LaserPermitApplicationFactory,
    Ultrasound: UltrasoundFactory,
    Transducer: TransducerFactory,
    Mammo: MammoFactory,
    TubeHousing: TubeHousingFactory,
    TubeInsert: TubeInsertFactory,
    Room: RoomFactory,
    RegistrationData: RegistrationDataFactory,
    CertificateData: CertificateDataFactory,
    ServiceDetails: ServiceDetailsFactory,
    EquipmentProperty: EquipmentPropertyFactory
}).component({
    equipmentGrid,
    equipmentGridSwitch,
    equipmentGridDepreciationReport,
    equipmentDocumentsGrid,
    equipmentAlerts,
    inputEquipmentId,
    inputEquipmentSerial,
    selectEquipmentType,
    selectEquipmentCategory,
    multiSelectEquipmentCategory,
    selectEquipmentDocumentType,
    selectEquipmentDocumentStatus,
    selectEquipmentTestStatus,
    selectEquipmentRequirementsType,
    selectRequirementsDaysRange,
    requirementsDue
}).config(['$provide', ($provide) => {
    $provide.decorator('EquipmentServiceXray', ['$delegate', 'EquipmentService',
        ($delegate, EquipmentService) => {
            Object.setPrototypeOf($delegate, EquipmentService);
            return $delegate;
        }
    ]);
    $provide.decorator('EquipmentServiceLaser', ['$delegate', 'EquipmentService',
        ($delegate, EquipmentService) => {
            Object.setPrototypeOf($delegate, EquipmentService);
            return $delegate;
        }
    ]);
    $provide.decorator('EquipmentServiceUltrasound', ['$delegate', 'EquipmentService',
        ($delegate, EquipmentService) => {
            Object.setPrototypeOf($delegate, EquipmentService);
            return $delegate;
        }
    ]);
    $provide.decorator('EquipmentServiceTransducer', ['$delegate', 'EquipmentService',
        ($delegate, EquipmentService) => {
            Object.setPrototypeOf($delegate, EquipmentService);
            return $delegate;
        }
    ]);
    $provide.decorator('EquipmentServiceMammo', ['$delegate', 'EquipmentService',
        ($delegate, EquipmentService) => {
            Object.setPrototypeOf($delegate, EquipmentService);
            return $delegate;
        }
    ]);
}]).run(($templateCache) => {
    $templateCache.put('app.xray.alerts.listing', '<equipment-alerts state="app.xray.manage"></equipment-alerts>');
    $templateCache.put('app.lasers.alerts.listing', '<equipment-alerts state="app.lasers.manage"></equipment-alerts>');
    $templateCache.put('app.ultrasound.alerts.listing', '<equipment-alerts state="app.ultrasound.manage"></equipment-alerts>');
    $templateCache.put('app.mammo.alerts.listing', '<equipment-alerts state="app.mammo.manage"></equipment-alerts>');
    $templateCache.put('xraGridDepreciationReport', '<equipment-grid-depreciation-report type="xra"></equipment-grid-depreciation-report>');
    $templateCache.put('lasGridDepreciationReport', '<equipment-grid-depreciation-report type="las"></equipment-grid-depreciation-report>');
    $templateCache.put('usoGridDepreciationReport', '<equipment-grid-depreciation-report type="uso"></equipment-grid-depreciation-report>');
    $templateCache.put('mamGridDepreciationReport', '<equipment-grid-depreciation-report type="mam"></equipment-grid-depreciation-report>');
    $templateCache.put('./equipment-xray-add.html', require('./views/xray/equipment-xray-add.html'));
    $templateCache.put('./equipment-xray-details.html', require('./views/xray/equipment-xray-details.html'));
    $templateCache.put('./equipment-xray-filter.html', require('./views/xray/equipment-xray-filter.html'));
    $templateCache.put('./equipment-lasers-add.html', require('./views/lasers/equipment-lasers-add.html'));
    $templateCache.put('./equipment-lasers-details.html', require('./views/lasers/equipment-lasers-details.html'));
    $templateCache.put('./equipment-lasers-filter.html', require('./views/lasers/equipment-lasers-filter.html'));
    $templateCache.put('./equipment-ultrasound-add.html', require('./views/ultrasound/equipment-ultrasound-add.html'));
    $templateCache.put('./equipment-ultrasound-details.html', require('./views/ultrasound/equipment-ultrasound-details.html'));
    $templateCache.put('./equipment-ultrasound-filter.html', require('./views/ultrasound/equipment-ultrasound-filter.html'));
    $templateCache.put('./equipment-ultrasound-transducers.html', require('./views/ultrasound/equipment-ultrasound-transducers.html'));
    $templateCache.put('./transducer-add.html', require('./views/ultrasound/transducer/transducer-add.html'));
    $templateCache.put('./transducer-details.html', require('./views/ultrasound/transducer/transducer-details.html'));
    $templateCache.put('./transducer-manage.html', require('./views/ultrasound/transducer/transducer-manage.html'));
    $templateCache.put('./transducer-listing.html', require('./views/ultrasound/transducer/transducer-listing.html'));
    $templateCache.put('./equipment-mammo-add.html', require('./views/mammo/equipment-mammo-add.html'));
    $templateCache.put('./equipment-mammo-details.html', require('./views/mammo/equipment-mammo-details.html'));
    $templateCache.put('./equipment-mammo-filter.html', require('./views/mammo/equipment-mammo-filter.html'));
    $templateCache.put('./equipment-registration.html', require('./views/equipment-registration.html'));
    $templateCache.put('./equipment-certification.html', require('./views/equipment-certification.html'));
    $templateCache.put('./equipment-vendors.html', require('./views/equipment-vendors.html'));
    $templateCache.put('./equipment-technique-charts.html', require('./views/optimized-charts/equipment-technique-charts.html'));
    $templateCache.put('./equipment-listing.html', require('./views/equipment-listing.html'));
    $templateCache.put('./equipment-grid-filter.html', require('./grid/equipment-grid-filter.html'));
    $templateCache.put('./equipment-grid-filter-controls.html', require('./grid/equipment-grid-filter-controls.html'));
    $templateCache.put('./equipment-documents-listing.html', require('./views/equipment-documents-listing.html'));
    $templateCache.put('./equipment-documents-grid-filter.html', require('./grid/equipment-documents-grid-filter.html'));
    $templateCache.put('./equipment-asset-cost.html', require('./views/equipment-asset-cost.html'));
    $templateCache.put('./grid-equipment-sale-dialog.html', require('../../common/grid/directives/grid-equipment-sale-dialog.html'));
    $templateCache.put('./grid-equipment-disposal-dialog.html', require('../../common/grid/directives/grid-equipment-disposal-dialog.html'));
    $templateCache.put('./grid-equipment-sale-print-dialog.html', require('../../common/grid/directives/grid-equipment-sale-print-dialog.html'));
    $templateCache.put('./grid-equipment-details-dialog.html', require('../../common/grid/directives/grid-equipment-details-dialog.html'));
});
