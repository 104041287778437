import { IDS } from '../add-document-action-id.constants';
import { IDS as MASTER_DOC_LIST_IDS } from '../../master-doc-list/master-doc-list-action-id.constants';
import { IDS as STANDARD_IDS } from '../../standard/standard-action-id.constants';
import * as Actions from '../add-document.actions';
import get from 'lodash/get';
import pick from 'lodash/pick';
import I18N from "../../../../common/utils/util.i18n";
import {GRID_FILTER} from "../../../../common/grid/grid.constants";

class AccAddFromExistingController {
    constructor($ngRedux,
                $state,
                $stateParams,
                $filter,
                $http,
                $mdDialog,
                FileUploader,
                Notifier,
                FileActions,
                EmployeeService,
                DocumentManager) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.$http = $http;
        this.$mdDialog = $mdDialog;
        this.FileUploader = FileUploader;
        this.Notifier = Notifier;
        this.FileActions = FileActions;
        this.EmployeeService = EmployeeService;
        this.DocumentManager = DocumentManager;
        this.identifier = IDS.accAddDocument;
    }

    $onInit() {
        this.isPublic = this.$state.$current.name.split('.')[0] === 'public';
        this.schemeCode = this.$stateParams.itemCode;
        this.showStandard = !!this.$stateParams.standard;

        this.$ngRedux.dispatch({ type: 'INPUT_TEXT_AREA_CHANGE', id: IDS.accAddDocument, path: 'documentName',          input: '' });
        this.$ngRedux.dispatch({ type: 'INPUT_TEXT_AREA_CHANGE', id: IDS.accAddDocument, path: 'documentDescription',   input: '' });

        this.unsubscribe = this.$ngRedux.connect(state => {
            const accAddDocument    = state.accreditation.accAddDocument;
            const assessmentItem            = accAddDocument?.assessmentItem;
            const evidenceTaskStatusCode    = assessmentItem?.evidenceTask?.status_code;
            const accDashboardLocationYear = state.accreditation.accDashboardLocationYear;
            return {
                ...pick(accAddDocument,[
                'siteLocationName',
                'siteLocationUuid',
                'standardId',
                'standardName',
                'standardDescription',
                'standardStatusName',
                'standardStatusCode',
                'documentTypes',
                'documentType',
                'documentName',
                'documentDescription',
                ]),
                uploadButtonDisabled:   !assessmentItem || assessmentItem.evidenceTask && evidenceTaskStatusCode !== 'pen',
                docGeneratingEnabled:   get(accAddDocument, 'assessmentItem.is_generated'),
                evidenceTask:           assessmentItem?.evidenceTask,
                evidenceTaskStatus:     evidenceTaskStatusCode && I18N.translate(`ACCREDITATION.EVIDENCE.STATUS.${evidenceTaskStatusCode.toUpperCase()}`),
                isUserEmployee:         !state.app.user.typeCode,
                sampleDocPath:          assessmentItem?.sample_doc_path,
                sampleDocFilename:      assessmentItem?.sampleDocFilename,
                fiscalYear:             accDashboardLocationYear.year,
            }
        }, {
            ...Actions,
            // ...EvidenceActions,
            ...this.FileActions,
        })(this);

        const { standard, slo } = this.$stateParams;
        if(standard && standard !== this.standardId) {
            this.getSectionDetails(standard);
        }
        this.fetchSiteLocationDetails(slo).then(() => {
            this.$ngRedux.dispatch({
                type: GRID_FILTER,
                id: this.identifier,
                path: IDS.addFromExistingGrid,
                filter: {
                    siteLocationUuid: slo,
                    standardId: standard,
                }
            });
        });
    }

    onAddButton({item}) {
        this.assignVersionedDocToStandard(item.vdo_uuid, this.standardId, this.siteLocationUuid).then(() => {
            this.$ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: this.identifier,
                path: IDS.addFromExistingGrid,
            });
        });
    }

    onDownloadButton({item}) {
        this.DocumentManager.downloadFile(item.doc_url);
    }

    onButtonBack() {
        if(this.standardName)
            this.goToStandard();
        else
            this.goToMasterDocList();
    }

    goToStandard() {
        this.$state.go(STANDARD_IDS.app_accreditation_scheme_standards_documents, {
            itemCode:   this.schemeCode,
            standard:   this.standardId,
            year:       this.fiscalYear,
            slo:        this.siteLocationUuid,
        });
    }

    goToMasterDocList() {
        this.$state.go(MASTER_DOC_LIST_IDS.app_accreditation_scheme_master_doc_list, {
            itemCode: this.schemeCode,
        });
    }

    downloadSampleDoc() {
        let path = this.sampleDocPath;
        if(path) {
            if(path.includes('/') === false) // template with default path
                path = `/acc_doc/reference/${path}`;
            this.download(`storage${path}`);
        }
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

AccAddFromExistingController.$inject = [
    '$ngRedux',
    '$state',
    '$stateParams',
    '$filter',
    '$http',
    '$mdDialog',
    'FileUploader',
    'Notifier',
    'FileActions',
    'EmployeeService',
    'DocumentManager']

export const accAddFromExisting = {
    controller: AccAddFromExistingController,
    template: require('./from-existing.html')
}
