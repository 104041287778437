/* @ngInject */
export function UploadController($ngRedux, $http, $timeout, API_BASE, FileUploader, Notifier) {
    var ctrl = this;

    ctrl.$onInit = function() {
        if (_.isUndefined(ctrl.inputId))
            throw new Error('Missing input-id attribute');
        if (_.isUndefined(ctrl.label))
            ctrl.label = 'Add Document';
        if (_.isUndefined(ctrl.validate))
            ctrl.validate = false;

        ctrl.saveDisabled = true;
        ctrl.options = {
            url: API_BASE + "/file/stream",
            alias: "upstream",
            queueLimit: 1,
            headers: {
                "X-Authorization": $http.defaults.headers.common['X-Authorization'],
                "X-Token": $http.defaults.headers.common['X-Token']
            }
        };

        ctrl.uploader = new FileUploader({
            queueLimit: 1,
            autoUpload: true,
            onSuccessItem: function (item, response, status, headers) {
                item.filePath = response.path;
                item.type = _.get(response, 'type', null);
                item.width = _.get(response, 'width', null);
                item.height = _.get(response, 'height', null);
                angular.element('#' + ctrl.inputId)[0].value = '';
                ctrl.saveDisabled = false;
                $ngRedux.dispatch({
                    type: 'FILE_UPLOAD',
                    file: item.filePath
                });
            },
            onBeforeUploadItem: function(item) {
                item.filePath = null;
                item.type = null;
                item.width = null;
                item.height = null;
                ctrl.saveDisabled = true;
            },
            onCancelItem: function() {
                ctrl.saveDisabled = false
            },
            onErrorItem: function(item, response, status, headers) {
                Notifier.error(response);
                item.filePath = null;
                item.type = null;
                item.width = null;
                item.height = null;
                ctrl.saveDisabled = true;
            },
        });

        if (typeof ctrl.validType !== 'undefined') {
            ctrl.uploader.filters.push({
                name: 'typeFilter',
                fn: function(item) {
                    var types = ctrl.validType.split(',');
                    if (types.indexOf(item.name.split('.').pop()) === -1) {
                        Notifier.error('Invalid document type!');
                        return false;
                    }
                    else return true;
                }
            });
        }
    };

    ctrl.validation = function() {
        if (ctrl.validate && ctrl.form.$invalid) {
            _.each(ctrl.form.$error, function(field) {
                _.each(field, function(errorField) {
                    errorField.$setTouched();
                });
            });
            Notifier.error('Please fill in the form fields');
            return false;
        }
        return true;
    };

    ctrl.uploadFile = function() {
        if (ctrl.uploader.queue.length > 0)
            return;

        if (ctrl.validation()) {
            $timeout(function() {
                angular.element('#' + ctrl.inputId).trigger('click');
            });
        }
    };

    ctrl.cancelFile = function(item) {
        item.cancel();
        ctrl.saveDisabled = true;
    };

    ctrl.removeFile = function(item) {
        item.remove();
        angular.element('#' + ctrl.inputId)[0].value = '';
        ctrl.saveDisabled = true;
    };

    ctrl.saveFile = function() {
        if (ctrl.validation()) {
            if (_.isFunction(ctrl.onSave)) {
                ctrl.saveData(ctrl.uploader.queue.map(function(item) {
                    return {
                        filePath: item.filePath,
                        type: item.type,
                        width: item.width,
                        height: item.height
                    }
                }));
            }
            ctrl.uploader.clearQueue();
            ctrl.uploader.cancelAll();
        }
    };

    ctrl.saveData = function(event) {
        ctrl.onSave({ $event: event });
    };
}
