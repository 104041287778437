import {TAB_DISABLE, TAB_HIDE} from "../../common/tabs/tabs.constants";

export const administrationState = {
    name: 'app.administration',
    abstract: true,
    url: '/administration'
};

export const administrationDashboardState = {
    name: 'app.administration.dashboard',
    url: '',
    component: 'dashboard'
};

export const administrationAccountsState = {
    name: 'app.administration.accounts',
    redirectTo: 'app.administration.accounts.headOffices',
    url: '/accounts',
    component: 'tabs'
};

export const administrationAccountsHeadOfficesState = {
    name: 'app.administration.accounts.headOffices',
    url: '',
    component: 'administrationAccountsHeadOffices',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_DISABLE,
            id: 'app.administration.accounts',
            state: [
                'app.administration.accounts.headOfficesDetails',
                'app.administration.accounts.headOfficesContacts',
                'app.administration.accounts.headOfficesContactDetails',
                'app.administration.accounts.headOfficesLicences',
                'app.administration.accounts.headOfficesEquipment',
                'app.administration.accounts.headOfficesModules',
            ]
        });
        $ngRedux.dispatch({
            type: 'HEAD_OFFICE_RESET',
            id: 'administrationAccountsHeadOffice'
        });
    }
};
administrationAccountsHeadOfficesState.onEnter.$inject = ['$ngRedux'];

export const administrationAccountsHeadOfficeDetailsState = {
    name: 'app.administration.accounts.headOfficesDetails',
    url: '/details?hof',
    component: 'administrationAccountsHeadOfficeDetails'
};

export const administrationAccountsHeadOfficeContactsState = {
    name: 'app.administration.accounts.headOfficesContacts',
    url: '/contacts?hof',
    component: 'administrationAccountsHeadOfficeContacts'
};

export const administrationAccountsHeadOfficeContactDetailsState = {
    name: 'app.administration.accounts.headOfficesContactDetails',
    url: '/contact-details?hof&contact',
    component: 'administrationAccountsHeadOfficeContactDetails',
    onExit: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: 'app.administration.accounts',
            state: ['app.administration.accounts.headOfficesContactDetails']
        });
    }
};
administrationAccountsHeadOfficeContactDetailsState.onExit.$inject = ['$ngRedux'];

export const administrationAccountsHeadOfficeLicencesState = {
    name: 'app.administration.accounts.headOfficesLicences',
    url: '/licences?hof',
    component: 'administrationAccountsHeadOfficeLicences'
};

export const administrationAccountsSiteLocationsState = {
    name: 'app.administration.accounts.siteLocations',
    redirectTo: 'app.administration.accounts.siteLocations.list',
    url: '/site-locations?hof',
    component: 'tabs'
};

export const administrationAccountsSiteLocationsListState = {
    name: 'app.administration.accounts.siteLocations.list',
    url: '',
    component: 'administrationAccountsSiteLocations',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_DISABLE,
            id: 'app.administration.accounts.siteLocations',
            state: [
                'app.administration.accounts.siteLocations.details',
                'app.administration.accounts.siteLocations.address',
                'app.administration.accounts.siteLocations.invoiceAddress',
                'app.administration.accounts.siteLocations.contacts',
                'app.administration.accounts.siteLocations.contactDetails',
                'app.administration.accounts.siteLocations.licences',
                'app.administration.accounts.siteLocations.licenceDetails',
                'app.administration.accounts.siteLocations.licenceIsotopes',
                'app.administration.accounts.siteLocations.licenceAppendix',
                'app.administration.accounts.siteLocations.equipment',
                'app.administration.accounts.siteLocations.employees',
                'app.administration.accounts.siteLocations.modules',
            ]
        });
        $ngRedux.dispatch({
            type: 'SITE_LOCATION_RESET',
            id: 'administrationAccountsSiteLocation'
        });
    }
};
administrationAccountsSiteLocationsListState.onEnter.$inject = ['$ngRedux'];

export const administrationAccountsSiteLocationContactsState = {
    name: 'app.administration.accounts.siteLocations.contacts',
    url: '/contacts?slo',
    component: 'administrationAccountsSiteLocationContact'
};

export const administrationAccountsSiteLocationContactDetailsState = {
    name: 'app.administration.accounts.siteLocations.contactDetails',
    url: '/contact-details?slo&contact',
    component: 'administrationAccountsSiteLocationContactDetails',
    onExit: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: 'app.administration.accounts.siteLocations',
            state: ['app.administration.accounts.siteLocations.contactDetails']
        });
    }
};
administrationAccountsSiteLocationContactDetailsState.onExit.$inject = ['$ngRedux'];

export const administrationAccountsSiteLocationLicenceIsotopeState = {
    name: 'app.administration.accounts.siteLocations.licenceIsotopes',
    url: '/licence-isotopes?slo&lic',
    component: 'administrationAccountsSiteLocationLicenceIsotopes',
    onExit: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: 'app.administration.accounts.siteLocations',
            state: ['app.administration.accounts.siteLocations.licenceIsotopes']
        });
        $ngRedux.dispatch({
            type: 'RESET_ISOTOPE_FORM',
            id: 'administrationAccountsLicenceIsotope'
        });
    }
};
administrationAccountsSiteLocationLicenceIsotopeState.onExit.$inject = ['$ngRedux'];

export const administrationAccountsSiteLocationLicenceAppendixState = {
    name: 'app.administration.accounts.siteLocations.licenceAppendix',
    url: '/licence-appendix?slo&lic',
    component: 'administrationAccountsSiteLocationLicenceAppendix',
    onExit: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: 'app.administration.accounts.siteLocations',
            state: ['app.administration.accounts.siteLocations.licenceAppendix']
        });
        $ngRedux.dispatch({
            type: 'RESET_APPENDIX_FORM',
            id: 'administrationAccountsLicenceAppendix'
        });
    }
};
administrationAccountsSiteLocationLicenceAppendixState.onExit.$inject = ['$ngRedux'];

export const administrationAccountsDepartmentsState = {
    name: 'app.administration.accounts.departments',
    redirectTo: 'app.administration.accounts.departments.list',
    url: '/departments?hof&slo',
    component: 'tabs'
};

export const administrationAccountsDepartmentsListState = {
    name: 'app.administration.accounts.departments.list',
    url: '',
    component: 'administrationAccountsDepartments',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_DISABLE,
            id: 'app.administration.accounts.departments',
            state: [
                'app.administration.accounts.departments.details',
                'app.administration.accounts.departments.contacts',
                'app.administration.accounts.departments.contactDetails',
                'app.administration.accounts.departments.equipment',
                'app.administration.accounts.departments.employees'
            ]
        });
        $ngRedux.dispatch({
            type: 'SITE_LOCATION_RESET',
            id: 'administrationAccountsDepartment'
        });
        $ngRedux.dispatch({
            type: 'DEPARTMENT_RESET',
            id: 'administrationAccountsDepartment'
        });
    }
};
administrationAccountsDepartmentsListState.onEnter.$inject = ['$ngRedux'];

export const administrationAccountsDepartmentContactsState = {
    name: 'app.administration.accounts.departments.contacts',
    url: '/contacts?dep',
    component: 'administrationAccountsDepartmentContacts'
};

export const administrationAccountsDepartmentContactDetailsState = {
    name: 'app.administration.accounts.departments.contactDetails',
    url: '/contact-details?dep&contact',
    component: 'administrationAccountsDepartmentContactDetails',
    onExit: $ngRedux => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: 'app.administration.accounts.departments',
            state: ['app.administration.accounts.departments.contactDetails']
        });
    }
};
administrationAccountsDepartmentContactDetailsState.onExit.$inject = ['$ngRedux'];

export const administrationNotificationSettingsState = {
    name: 'app.administration.notificationSettings',
    redirectTo: 'app.administration.notificationSettings.listing',
    url: '/notification-settings',
    component: 'tabs'
};

export const administrationNotificationSettingsListingState = {
    name: 'app.administration.notificationSettings.listing',
    url: '',
    component: 'administrationNotificationSettings',
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.administration.notificationSettings',
            state: [,
                'app.administration.notificationSettings.details',
                'app.administration.notificationSettings.timeline'
            ]
        });
    }
};
administrationNotificationSettingsListingState.onEnter.$inject = ['$ngRedux'];

export const administrationNotificationSettingsDetailsState = {
    name: 'app.administration.notificationSettings.details',
    url: '/details',
    component: 'administrationNotificationSettings'
};

export const administrationNotificationSettingsTimelineState = {
    name: 'app.administration.notificationSettings.timeline',
    url: '/timeline',
    component: 'administrationNotificationSettings'
};

export const administrationMonthlyReportSettingsState = {
    name: 'app.administration.monthlyReportSettings',
    redirectTo: 'app.administration.monthlyReportSettings.listing',
    url: '/monthly-report-settings',
    component: 'tabs'
};

export const administrationMonthlyReportSettingsListingState = {
    name: 'app.administration.monthlyReportSettings.listing',
    url: '',
    component: 'administrationMonthlyReportSettings',
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.administration.monthlyReportSettings',
            state: [
                'app.administration.monthlyReportSettings.details'
            ]
        });
    }
};
administrationMonthlyReportSettingsListingState.onEnter.$inject = ['$ngRedux'];

export const administrationMonthlyReportSettingsDetailsState = {
    name: 'app.administration.monthlyReportSettings.details',
    url: '/details',
    component: 'administrationMonthlyReportSettings'
};
