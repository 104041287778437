var employees = {
    templateUrl: './employees.html',
    controller: 'EmployeesController'
};

angular
    .module('components.employees')
    .component('employees', employees)
    .config(function($stateProvider) {
        $stateProvider
            .state('app.employees', {
                abstract: true,
                url: '/employees'
            })
            .state('app.employees.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.employees.add', {
                url: '/add',
                template: require('../../components.html'),
                controller: 'EmployeesAddController'
            })
            .state('app.employees.manage', {
                url: '/manage?uuid&tab',
                template: require('../../components.html'),
                controller: 'EmployeesManageController',
                onExit: function($ngRedux) {
                    $ngRedux.dispatch({
                        type: 'GRID_RESET_FILTER',
                        id: 'employeesGrid'
                    });
                }
            })
            .state('app.employees.alerts', {
                redirectTo: 'app.employees.alerts.listing',
                url: '/alerts',
                component: 'tabs'
            })
            .state('app.employees.alerts.listing', {
                url: '?type',
                component: 'employeesAlerts',
                onExit: function($ngRedux) {
                    $ngRedux.dispatch({
                        type: 'GRID_RESET_FILTER',
                        id: 'employeesAlertsGrid'
                    });
                }
            });
    });
