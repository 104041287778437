/* @ngInject */
export function InputServiceContractNumberController($ngRedux) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, {})(ctrl);
    };

    ctrl.onChange = function() {
        if (ctrl.form.$valid) {
            $ngRedux.dispatch({
                type: 'CONTRACT_NUMBER_CHANGE',
                id: ctrl.identifier,
                number: ctrl.contractNumber
            });
        }
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    function mapStateToThis(state) {
        return {
            contractNumber: _.get(state, getStateContainer('filter.number'), null)
        };
    }

    function getStateContainer(statePath) {
        return [ctrl.module, ctrl.identifier].join('.') + '.' + statePath;
    }
}
