import { gridTpl } from '../../../../../../common/grid/grid.controller';

export const administrationAccountsDepartmentsGrid = {
    bindings: {
        title: '<',
        onSelect: '&'
    },
    controller: 'AdministrationAccountsDepartmentsGridController',
    ...gridTpl
};
