/* @ngInject */
export function DocumentManager($q, $mdDialog, NavigationService, AuthService, DocumentService, Notifier) {

    this.showEquipmentDocuments = function(equipment) {

        var template = './equipment-documents.html';

        var isAdmin = AuthService.getUser().type == 'Administrator';

        if (isAdmin)
            template = './equipment-documents-edit.html';

        $mdDialog.show({
            controller: documentsCtrl,
            templateUrl: template,
            parent: angular.element(document.body),
            locals: {
                equipment: equipment,
                isAdmin: isAdmin
            },
            clickOutsideToClose: true
        });

        function documentsCtrl($scope, equipment, isAdmin) {
            $scope.headerColor = NavigationService.getNavConfig().colorName;
            $scope.piece = equipment.user_friendly_id;
            $scope.isAdmin  = isAdmin;
            $scope.inEdit = -1;

            DocumentService.fetchEquipmentDocuments(equipment.equipment_id).then(function(items) {
                $scope.items = items;

                if ($scope.isAdmin) {
                    DocumentService.fetchEquipmentDocumentTypes(equipment.equipment_id).then(function(items) {
                        $scope.documentTypes = items;
                    });
                }
            });

            $scope.saveLicence = function(index, item) {
                DocumentService.editDocument(item).then(function(data) {

                    if (data.status == 200 && data.data.status == 0) {
                        Notifier.success("Document saved!");
                        $scope.inEdit = -1;
                    }
                    else if (data.status == 200 && data.data.status != 0) {
                        Notifier.error("Problem with saving document. Check expiry date.")
                    }
                    else if (data.status == 403) {
                        Notifier.error("Not able to save document. Insufficient access rights.");
                        $scope.inEdit = -1;
                    }
                    else if (data.status == 400 && typeof data.data == 'string')
                    {
                        Notifier.error(data.data);
                    }
                    else
                    {
                        Notifier.error("Document has not been saved.");
                        $scope.inEdit = -1;
                    }
                });
            };

            $scope.cancelLicence = function() {
                $scope.inEdit = -1;
            };

            $scope.modifyLicence = function(index, item) {
                $scope.inEdit = index;
            };

            $scope.cancel = function() {
                $mdDialog.cancel();
            };
        }
    };

    // TODO temporary solution, this is not the right place
    this.clearUploader = function(uploader, document) {
        uploader.clearQueue();
        uploader.cancelAll();
        document.expires = null;
        document.type = {};
        document.inspectionDate = null;
        document.inspector = null;
        document.result = null;
        document.comment = null;
    };

    this.downloadFile = function(uri,docType)
    {
        if (uri) {
            if (_.isUndefined(docType))
                docType = 1;

            //If uri has +, change it to %2B as it is treated by Middleware as space
            var sanitizedUri = uri.replace(new RegExp('\\+', 'g'), "%2B");
            sanitizedUri = sanitizedUri.replace(new RegExp('\#', 'g'), "%23");

            return DocumentService.downloadFile(sanitizedUri, docType).then(function (data) {
                if (data.status == 400 && typeof data.data == 'string') {
                    Notifier.error(data.data);
                }
                else if (data.status == 403) {
                    Notifier.error("Insufficient access rights.");
                }
                else if (data.status == 404) {
                    Notifier.error("Document not found or non-existent.");
                }
                else if (data.status == 500) {
                    Notifier.error("Server error. Please contact administrator.");
                }
                else if (data.status != 200) {
                    Notifier.error("Document could not be downloaded.")
                }
            });
        } else {
            Notifier.error("There is not uploaded document.")
        }
    };

    this.saveDocument = function(document) {

        var deferred = $q.defer();
        DocumentService.addDocument(document).then(function(data) {

            const { data: resData } = data;

            if (data.status === 200 && resData === 0) {
                Notifier.success("Document added!");
                deferred.resolve();
            }
            else if (data.status == 200 && (resData.status == 0 || resData.status == "0")) {
                Notifier.success("Document added!");
                deferred.resolve();
            }
            else if (data.status == 200 && !(resData.licence == null || resData.licence == "null")) {
                Notifier.error("Problem with adding document.");
                deferred.reject();
            }
            else if (data.headers('X-Status') == 18) {
                Notifier.error('Saving document failed. Please upload file and try again.');
                deferred.reject();
            }
            else if (data.status == 403) {
                Notifier.error("Not able to save document. Insufficient access rights.");
                $scope.inEdit = -1;
                deferred.reject();
            }
            else if (data.status == 400 && typeof resData == 'string')
            {
                Notifier.error(resData);
                deferred.reject();
            }
            else if (data.status == 400 && _.isObject(resData))
            {
                Notifier.error(resData.message);
                deferred.reject();
            }
            else
            {
                Notifier.error("Document has not been added.");
                deferred.reject();
            }
        });
        return deferred.promise;
    };
}
