import JsBarcode from 'jsbarcode';

/* @ngInject */
export function EquipmentManager($ngRedux, $rootScope, API_BASE, $http, $q, $mdDialog, NavigationService, EquipmentModel,
                          EquipmentService, LocationService, ManufacturerService, EquipmentServiceLaser, Notifier, EquipmentCategory,
                          EquipmentOwner, VendorService, VendorGridFilter, FileUploader, AccDocument,
                          AccreditationDocumentsService, DocumentManager, ACC_ACCREDITATION_CODE, AccOptimizedChartDocument,
                          EXPORT_FORMAT_PDF, EQUIPMENT_TYPE_CODE, $filter) {

    var _this = this;

    this.TAB_EQUIPMENT_LISTING          = 0;
    this.TAB_EQUIPMENT_DETAILS          = 1;
    this.TAB_EQUIPMENT_MODIFY           = 2;
    this.TAB_EQUIPMENT_DOCUMENTS        = 3;
    this.TAB_EQUIPMENT_TRANSDUCERS      = 4;
    this.TAB_EQUIPMENT_VENDORS          = 5;
    this.TAB_EQUIPMENT_TECHNIQUE_CHARTS = 6;
    this.TAB_EQUIPMENT_ASSET_COST       = 7;

    this.CREATE_NEW_CATEGORY_COMPLETE   = 'create_new_category_complete';
    this.CREATE_NEW_MODEL_COMPLETE      = 'create_new_model_complete';

    this.templateEquipmentListing       = './equipment-listing.html';
    this.templateEquipmentDetails       = './equipment-lasers-add.html';
    this.templateEquipmentDocuments       = './equipment-documents-listing.html';
    this.templateEquipmentTransducers   = './equipment-ultrasound-transducers.html';
    this.templateEquipmentVendors       = './equipment-vendors.html';
    this.templateEquipmentTechniqueCharts = './equipment-technique-charts.html';
    this.templateEquipmentAssetCost     = './equipment-asset-cost.html';

    this.tabs = [
        {
            id: this.TAB_EQUIPMENT_LISTING,
            title: $filter('translate')('EQUIPMENT.TABS.EQUIPMENT.TAB_TITLE'),
            content: this.templateEquipmentListing,
            form: null,
            formValidation: false,
            formValidationMsg: '',
            visible: true,
            disabled: false,
            stateless: true
        },
        {
            id: this.TAB_EQUIPMENT_DETAILS,
            title: $filter('translate')('EQUIPMENT.TABS.DETAILS.TAB_TITLE'),
            content: this.templateEquipmentDetails,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: false,
            stateless: false
        },
        {
            id: this.TAB_EQUIPMENT_MODIFY,
            title: $filter('translate')('EQUIPMENT.TABS.MODIFY.TAB_TITLE'),
            content: this.templateEquipmentDetails,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: false,
            disabled: false,
            stateless: false
        },
        {
            id: this.TAB_EQUIPMENT_DOCUMENTS,
            title: $filter('translate')('EQUIPMENT.TABS.DOCUMENTS.TAB_TITLE'),
            content: this.templateEquipmentDocuments,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: false,
            disabled: false,
            stateless: false
        },
        {
            id: this.TAB_EQUIPMENT_TRANSDUCERS,
            title: $filter('translate')('EQUIPMENT.TABS.TRANSDUCERS.TAB_TITLE'),
            content: this.templateEquipmentTransducers,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: false,
            disabled: false,
            stateless: false
        },
        {
            id: this.TAB_EQUIPMENT_VENDORS,
            title: $filter('translate')('EQUIPMENT.TABS.VENDORS.TAB_TITLE'),
            content: this.templateEquipmentVendors,
            form: null,
            formValidation: false,
            formValidationMsg: '',
            visible: true,
            disabled: true,
            stateless: false
        },
        {
            id: this.TAB_EQUIPMENT_TECHNIQUE_CHARTS,
            title: $filter('translate')('EQUIPMENT.TABS.TECHNIQUE_CHARTS.TAB_TITLE'),
            content: this.templateEquipmentTechniqueCharts,
            form: null,
            formValidation: false,
            formValidationMsg: '',
            visible: true,
            disabled: true,
            stateless: false
        },
        {
            id: this.TAB_EQUIPMENT_ASSET_COST,
            title: 'Asset Cost',
            content: this.templateEquipmentAssetCost,
            form: null,
            formValidation: false,
            formValidationMsg: '',
            visible: true,
            disabled: true,
            stateless: false
        }
    ];

    this.getEquipmentAddTabs = function() {
        const tabs = _.cloneDeep(this.tabs);
        return [
            _.find(tabs, ['id', this.TAB_EQUIPMENT_DETAILS]),
            _.find(tabs, ['id', this.TAB_EQUIPMENT_VENDORS]),
            _.find(tabs, ['id', this.TAB_EQUIPMENT_ASSET_COST]),
        ];
    };

    this.approvalToAcquire = function(title, dialog, subdialog) {

        $mdDialog.show({
            controller: approvalCtrl,
            template: require('../views/equipment-approval-dialog.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: false
        });

        function approvalCtrl($scope) {
            $scope.headerColor = NavigationService.getNavConfig().colorName;
            if (dialog) {
                $scope.approvalDialog = dialog;
                $scope.headerTitle = title;
                $scope.approvalConfirmation = subdialog;
                $scope.approvalConfirmationButtons = false;
            } else {
                $scope.approvalDialog = $filter('translate')('EQUIPMENT.TABS.DETAILS.POPUP_APPROVAL_DESCRIPTION');
                $scope.headerTitle = $filter('translate')('EQUIPMENT.TABS.DETAILS.POPUP_APPROVAL_TITLE');
                $scope.approvalConfirmation = $filter('translate')('EQUIPMENT.TABS.DETAILS.POPUP_APPROVAL_CONFIRMATION');
                $scope.approvalConfirmationButtons = true;
            }
            $scope.message = false;

            $scope.accept = function() {
                $mdDialog.cancel();
            };

            $scope.decline = function() {
                $scope.message = true;
                setTimeout(function() {
                    $mdDialog.cancel();
                }, 4000);
            };
        }
    };

    this.createCategory = function() {

        $mdDialog.show({
            controller: newCategoryCtrl,
            templateUrl: 'views/partial/add_category_dialog.html',
            parent: angular.element(document.body),
            clickOutsideToClose: true
        }).then(function(category) {
            $rootScope.$broadcast(_this.CREATE_NEW_CATEGORY_COMPLETE, category);
        });

        function newCategoryCtrl($scope, $rootScope, $mdDialog) {

            $scope.toolbarColor = $rootScope.toolbarColor;
            $scope.category = new EquipmentCategory();

            $scope.addCategory = function() {
                $scope.$broadcast('show-errors-check-validity');
                if ($scope.categoryForm.$invalid) { return; }

                EquipmentService.addCategory($scope.category).then(function(item) {
                    $scope.category.id = item.data.categoryId;
                    $mdDialog.hide($scope.category);
                });
            };

            $scope.cancel = function() {
                $mdDialog.cancel();
            };
        };
    };

    this.createNewModel = function(equipment) {

        $mdDialog.show({
            controller: newModelCtrl,
            template: require('../views/equipment-model-dialog.html'),
            parent: angular.element(document.body),
            locals: {equipment: equipment},
            clickOutsideToClose: true
        }).then(function(model) {
            $rootScope.$broadcast(_this.CREATE_NEW_MODEL_COMPLETE, model);
        }, angular.noop);

        function newModelCtrl($scope, equipment) {

            $scope.headerColor = NavigationService.getNavConfig().colorName;
            $scope.model = new EquipmentModel();
            $scope.model.type = _.has(equipment.category, 'id', null) ? equipment.category.id : equipment.type.id;
            $scope.model.manufacturer = equipment.manufacturer;

            $scope.addModel = function() {
                $scope.$broadcast('show-errors-check-validity');
                if ($scope.modelForm.$invalid) { return; }

                ManufacturerService.addEquipmentModel($scope.model).then(function(item) {
                    $scope.model.id = item.data.emo_id;
                    $scope.model.type = equipment.type.id;
                    $mdDialog.hide($scope.model);
                });
            };

            $scope.cancel = function() {
                $mdDialog.cancel();
            };
        }
    };

    this.createNewLaserName = function(equipment) {

        $mdDialog.show({
            controller: newLaserNameCtrl,
            template: require('../views/laser-name-dialog.html'),
            parent: angular.element(document.body),
            locals: {equipment: equipment},
            clickOutsideToClose: true
        }).then(function(category) {
            $rootScope.$broadcast(_this.CREATE_NEW_CATEGORY_COMPLETE, category);
        }, angular.noop);

        function newLaserNameCtrl($scope, equipment) {
            $scope.headerColor = NavigationService.getNavConfig().colorName;
            $scope.category = new EquipmentCategory();

            $scope.addLaserName = function() {
                $scope.$broadcast('show-errors-check-validity');
                if ($scope.laserNameForm.$invalid) { return; }

                EquipmentServiceLaser.addLaserName($scope.category).then(function(item) {
                    $scope.category.id = item.data.params.category_id;
                    $mdDialog.hide($scope.category);
                });
            };

            $scope.cancel = function() {
                $mdDialog.cancel();
            };
        }
    };

    this.showDetails = function(equipment) {

        $mdDialog.show({
            controller: detailsCtrl,
            template: require('../../../common/grid/directives/grid-equipment-dialog.html'),
            parent: angular.element(document.body),
            locals: {equipment: equipment},
            clickOutsideToClose: true
        });

        function detailsCtrl($scope, equipment) {

            $scope.headerColor = NavigationService.getNavConfig().colorName;
            $scope.headerTitle = 'Equipment details';
            $scope.formTemplate = './grid-equipment-details-dialog.html';
            $scope.replacableItem = equipment;

            $scope.cancel = function() {
                $mdDialog.cancel();
            };
        }
    };

    this.generateBarcode = function(barcode) {
        JsBarcode('#barcode', barcode, {displayValue: true});
    };

    this.getEquipmentServices = function(equipmentId) {
        var deferred = $q.defer();
        VendorService.getVendorServiceTypes(equipmentId).then(function(data) {
            deferred.resolve(data);
        });
        return deferred.promise;
    };

    this.manageEquipmentService = function(equipmentId, serviceType) {
        if (_.isEmpty(serviceType.vendors)) {
            var vendorFilter = new VendorGridFilter();
            vendorFilter.offset = null;
            vendorFilter.limit = null;
            vendorFilter.serviceType = serviceType.id;
            VendorService.getVendorHeadOfficeList(vendorFilter).then(function(data) {
                serviceType.vendors = data.items;
            });
        }
        if (!serviceType.selected && _.has(serviceType, 'selectedVendor.uuid')) {
            VendorService.rejectEquipmentVendor(equipmentId, serviceType).then(function() {
                serviceType.selectedVendor = null;
                Notifier.success(serviceType.name + ' Vendor successfully removed');
            }, function(error) {
                Notifier.responseMessage(error);
            });
        }
    };

    this.equipmentServiceVendorSelected = function(equipmentId, serviceType) {
        if (serviceType.selectedVendor) {
            VendorService.assignEquipmentVendor(equipmentId, serviceType).then(function() {
                Notifier.success(serviceType.name + ' Vendor successfully assigned');
            }, function(error) {
                Notifier.responseMessage(error);
            });
        }
    };

    this.onSell = function(identifier, item) {
        $mdDialog.show({
            controller: SellCtrl,
            template: require('../../../common/grid/directives/grid-equipment-dialog.html'),
            parent: angular.element(document.body),
            locals: {
                identifier: identifier,
                title: 'Sell equipment',
                item: item,
                form: './grid-equipment-sale-dialog.html'
            },
            clickOutsideToClose: true
        });
    };

    this.onDispose = function(identifier, item) {
        $mdDialog.show({
            controller: DisposeCtrl,
            template: require('../../../common/grid/directives/grid-equipment-dialog.html'),
            parent: angular.element(document.body),
            locals: {
                identifier: identifier,
                title: 'Dispose equipment',
                item: item,
                form: './grid-equipment-disposal-dialog.html'
            },
            clickOutsideToClose: true
        });
    };

    function SellCtrl($scope, $rootScope, identifier, title, item, form) {
        updateScope($scope);

        $scope.$on('LOCATION_SUBJECT_SELECTED', function(event, locationItem, formName) {
            if(formName === 'sellForm') {
                $scope.owner.newLocationUuid = locationItem.uuid;
            }
        });

        $scope.headerColor = NavigationService.getNavConfig().colorName;
        $scope.headerTitle = title;
        $scope.formTemplate = form;
        $scope.item = item;
        $scope.item.ownerType = 0;
        $scope.item.ownerAction = 0;
        $scope.item.relocateType = 0;
        $scope.isOwnerLicensed = true;
        $scope.owner = new EquipmentOwner(item.equipment_id);

        $scope.item.stateLabel = 'State';
        $scope.item.ownerActions = false;
        $scope.item.licenseData = true;
        $scope.item.permitData = false;
        $scope.item.ownerCountry = 'AU';
        $scope.item.permitApplicationID = null;
        $scope.item.permitRequired = null;
        if($scope.item.typeCode === 'las') {
            EquipmentServiceLaser.getEquipmentDetails($scope.item.equipment_id).then(function(result) {
                $scope.item.assetID = result.equipment.assetID;
                $scope.item.laserType = result.equipment.laserType;
                $scope.item.class = result.equipment.class;
                $scope.item.locations = result.locations;
                if(result.equipment.permitRequired) {
                    $scope.item.permitApplicationID = result.equipment.permitApplicationID;
                    $scope.item.permitRequired = result.equipment.permitRequired;
                }
                $scope.item.relocateLocations = [];
                result.locations.forEach(function (location) {
                    if (location.type_code === 'hof') {
                        $scope.item.relocateLocations.push(location);
                    }
                    if (location.type_code === 'slo') {
                        $scope.item.relocateLocations.push(location);
                        $scope.item.ownerBusinessSiteLocationName = location.name;
                        $scope.item.newLocationAddress = location.address;
                    }
                    if (location.type_code === 'dep') {
                        const locationName = location.name.split('|');
                        $scope.item.ownerBusinessDepartmentName = locationName[2];
                    }
                    if (location.type_code === 'rom') {
                        const locationName = location.name.split('|');
                        $scope.item.ownerBusinessRoomName = locationName[3];
                        $scope.item.ownerBusinessBuildingName = location.building;
                        $scope.item.ownerBusinessRoomNumber = ' No.'+location.room_number;
                        $scope.item.ownerBusinessRoomLevel = ' Level:'+location.room_level;
                        $scope.item.ownerBusinessRoom = locationName[3]+$scope.item.ownerBusinessRoomNumber+$scope.item.ownerBusinessRoomLevel;
                    }
                });
            });

            EquipmentService.fetchEquipmentTypeProperties(EQUIPMENT_TYPE_CODE.laser, $scope.item.account.uuid).then(function(properties) {
                for (const i in properties) {
                    if (properties[i].prop_code === 'add_laser_popup_ottawa') {
                        $scope.item.stateLabel = 'Province';
                        $scope.item.ownerActions = true;
                        $scope.item.licenseData = false;
                        $scope.item.permitData = true;
                        $scope.item.ownerCountry = 'CA';
                    }
                }
            });
        }

        $scope.initForm = function(form) {
            $scope.sellForm = form;
        };

        $scope.selectFacility = function() {
            $rootScope.$broadcast('SELECT_CASCADE_RESET', 'sellForm');
            $scope.item.newLocationAddress = '';
        };

        LocationService.getCountries().then(function(result) {
            if ($scope.item.ownerCountry === 'AU')
                $scope.owner.country = result.data[0];
            else
                $scope.owner.country = result.data[2];
            $scope.countries = result.data;
            $scope.selectCountry();
        });

        $scope.selectCountry = function() {
            LocationService.getStates($scope.owner.country.id).then(function(result) {
                $scope.states = result.data;
            });
        };

        $scope.print = function() {
            var printContent = document.getElementById('printable');
            var winPrint = window.open('', '', 'left=0, top=0, width=' + screen.width + ', height=' + screen.height + ', toolbar=0, scrollbars=0, status=0');
            winPrint.document.write(printContent.innerHTML);
            winPrint.document.close();
            winPrint.focus();
            winPrint.print();
            winPrint.close();
        };

        $scope.submit = function() {
            $scope.$broadcast('show-errors-check-validity');
            if ($scope.sellForm.$invalid) return;

            $scope.owner.in_doc_url = $scope.documentPath;
            EquipmentService.sellEquipment($scope.owner, $scope.item).then(function() {
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: identifier
                });
                Notifier.success('Success');
                $mdDialog.cancel();
            }, function(error) {
                // TODO
            });
        };
    }

    function DisposeCtrl($scope, identifier, title, item, form) {
        updateScope($scope);
        if (identifier == 'equipmentCalibrationGrid') {
            $scope.headerColor = NavigationService.getNavConfig('state','app.equipmentCalibration.manage').colorName;
        } else {
            $scope.headerColor = NavigationService.getNavConfig().colorName;
        }
        $scope.headerTitle = title;
        $scope.formTemplate = form;
        $scope.item = item;
        $scope.disposal = {description: ''};
        $scope.disposalWay = {};

        $scope.item.permitApplicationID = null;
        if($scope.item.typeCode === 'las') {
            EquipmentServiceLaser.getEquipmentDetails($scope.item.equipment_id).then(function(result) {
                $scope.item.assetID = result.equipment.assetID;
                $scope.item.laserType = result.equipment.laserType;
                $scope.item.class = result.equipment.class;
                if(result.equipment.showSellPermitRequired) {
                    $scope.item.permitApplicationID = result.equipment.permitApplicationID;
                }
            });
        }

        $scope.initForm = function(form) {
            $scope.disposeForm = form;
        };

        $scope.disposalTypes = EquipmentService.fetchWayOfDisposal(item.equipment_id).then(function(result) {
            $scope.disposalTypes = result.data;
        }, function(error) {
            // TODO
        });

        $scope.submit = function() {
            $scope.$broadcast('show-errors-check-validity');

            if ($scope.disposeForm.$invalid) return;

            EquipmentService.disposeEquipment(item.equipment_id, $scope.disposal.disposalWay, $scope.disposal.description, $scope.documentPath, item.disposeDate, item.inoperableBy, item.contactedManufacturer, item.documentationCompleted, item.lsoContacted, item.laserDecontaminated, item.dispose).then(function() {
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: identifier
                });
                $mdDialog.cancel();
            }, function(error) {
                // TODO
            });
        };
    }

    this.onDocUpload = function(identifier, item) {
        $mdDialog.show({
            controller: DocUpload,
            template: require('../../../common/grid/directives/grid-equipment-dialog.html'),
            parent: angular.element(document.body),
            locals: {
                identifier: identifier,
                title: 'Upload Charts Document',
                item: item,
                form: './grid-equipment-charts-upload-dialog.html'
            },
            clickOutsideToClose: true
        });
    };


    function DocUpload($scope, identifier, title, item, form) {
        updateScope($scope);
        $scope.headerColor = NavigationService.getNavConfig().colorName;
        $scope.headerTitle = title;
        $scope.formTemplate = form;
        $scope.item = item;
        $scope.chartDocument = null;

        if ($scope.item.optimizedChart == null) {
            $scope.item.optimizedChart = new AccOptimizedChartDocument();

        }

        $scope.chartDocument = $scope.item.optimizedChart;

        $scope.initForm = function(form) {
            $scope.chartUploadForm = form;
        };

        $scope.submit = function() {
            $scope.$broadcast('show-errors-check-validity');

            if ($scope.chartUploadForm.$invalid) {
                Notifier.error("Please fill in all required fields");
                return;
            }

            if (!$scope.documentPath) {
                Notifier.error("Please upload document");
                return;
            }

            $scope.chartDocument.docPath = $scope.documentPath;

            AccreditationDocumentsService.saveAccOptimizedChart(ACC_ACCREDITATION_CODE.ANY, $scope.item.equipment_id, $scope.chartDocument)
                .then(function (success) {
                    $ngRedux.dispatch({
                        type: 'GRID_RELOAD',
                        id: identifier
                    });
                    Notifier.success('Document uploaded');
                    $mdDialog.cancel();
                }, function (error) {
                    // TODO
                });
        };
    }


    this.onDocDownload = function(identifier, item) {
        DocumentManager.downloadFile("/" + item.optimizedChart.docPath, 3);
    };

    function updateScope(scope) {
        scope.options = {
            url: API_BASE + "/file/stream",
            alias: "upstream",
            headers: {
                "X-Authorization": $http.defaults.headers.common['X-Authorization'],
                "X-Token": $http.defaults.headers.common['X-Token']
            }
        };

        scope.uploader = new FileUploader({
            onSuccessItem: function(fileItem, response, status, headers) {
                scope.documentPath = response.path;
                scope.saveDisabled = false;
            },
            onBeforeUploadItem: function(item) {
                item.filePath = null;
                if (!_.isUndefined(scope.licence))
                    scope.licence.uri = null;
                scope.saveDisabled = true;
            },
            onCancelItem: function() {
                scope.saveDisabled = false
            },
            onErrorItem: function(fileItem, response, status, headers) {
                // TODO
                scope.documentPath = '';
                scope.saveDisabled = true;
            },
            autoUpload: true
        });

        scope.removeDocument = function(item) {
            item.remove();
            angular.element('#flu')[0].value = '';
        };

        scope.clickOnUpload = function() {
            if (scope.uploader.queue.length > 0)
                return;

            angular.element("#flu").trigger("click");
        };

        scope.cancel = function() {
            $mdDialog.cancel();
        };
    }

    this.onGenerateReport = function(identifier, item) {
        //here call accreditation service to generate report
        var year = new Date().getFullYear();

        AccreditationDocumentsService.fetchAccOptimizedChartReport(ACC_ACCREDITATION_CODE.ANY, item.optimizedChart.id, year, EXPORT_FORMAT_PDF)
            .then(function (response) {
                DocumentManager.downloadFile("/" + response.data.result, 3);
            }, function (error) {
                // TODO
            });

    };
}
