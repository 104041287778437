export const selectDepartmentType = {
    bindings: {
        module: '@',
        identifier: '@'
    },
    require : {
        form : '^form'
    },
    template: require('./select-department-type.html'),
    controller: 'SelectDepartmentTypeController'
};
