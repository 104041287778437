import {API_BASE} from "../../../common/utils/util.constants";
import isObject from "lodash/isObject";

export default function AdministrationNotificationSettingsActions($http) {
    return {
        getNotificationSettings: (filter) => {
            return dispatch => {
                if (filter.location) {
                    return $http({
                        url: API_BASE + '/administration/notification/notification-list',
                        method: 'GET',
                        params: {
                            locationUuid: filter.location
                        }
                    }).then(response => {
                        dispatch({
                            type: 'COUNT_ITEMS',
                            id: 'administrationNotificationSettingsGrid',
                            itemsCount: response.data.items.length
                        });
                        dispatch({
                            type: 'GRID_FETCH',
                            id: 'administrationNotificationSettingsGrid',
                            items: response.data.items.map(item => {
                                item.derived = parseInt(item.derived) === 1 ? 'Yes' : 'No';
                                item.overriden = parseInt(item.overriden) === 1 ? 'Yes' : 'No';
                                item.suppress_notifications = parseInt(item.suppress_notifications) === 1 ? 'Inactive' : 'Active';
                                return item;
                            })
                        });
                    });
                } else {
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'administrationNotificationSettingsGrid',
                        items: []
                    });
                }
            }
        },
        getNotificationSettingsDetails: (notificationId, location) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/administration/notification/notification-details',
                    method: 'GET',
                    params: {
                        notificationId: parseInt(notificationId),
                        locationUuid: location
                    }
                }).then(response => {
                    const item = response.data[0];
                    dispatch({
                        type: 'NOTIFICATION_DETAILS',
                        id: 'administrationNotificationSettings',
                        data: {
                            nse_id: parseInt(item.nse_id),
                            nty_id: parseInt(item.nty_id),
                            nty_name: item.nty_name,
                            loc_uuid: item.loc_uuid,
                            location: item.location,
                            email_template: item.email_template,
                            message_subject: item.message_subject,
                            derived: parseInt(item.derived),
                            overriden: parseInt(item.overriden),
                            suppress_notifications: parseInt(item.suppress_notifications),
                            sitelocation_name: item.sitelocation_name,
                            headoffice_name: item.headoffice_name
                        }
                    });
                });
            };
        },
        addNotificationSettings: (data, location) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/administration/notification/notification-add',
                    method: 'POST',
                    data: {
                        loc_uuid: location,
                        nty_id: parseInt(data.notificationTypeId),
                        suppress_notifications: data.suppressNotifications,
                        message_subject: data.messageSubject
                    }
                }).then(response => {
                    const params = isObject(response.data.params) ? response.data.params : JSON.parse(response.data.params);
                    dispatch({
                        type: 'NOTIFICATION_DETAILS',
                        id: 'administrationNotificationSettings',
                        data: {
                            nse_id: parseInt(params.nse_id),
                            derived: 0,
                            loc_uuid: location,
                            location: data.locationName,
                            nty_id: parseInt(data.notificationTypeId),
                            nty_name: data.notificationTypeName,
                            suppress_notifications: data.suppressNotifications,
                            message_subject: data.messageSubject,
                            overriden: data.overriden
                        }
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationNotificationSettingsGrid'
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationNotificationSettingsTimelineGrid'
                    });
                });
            }
        },
        editNotificationSettings: (data) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/administration/notification/notification-edit',
                    method: 'POST',
                    data: {
                        nse_id: parseInt(data.notificationId),
                        suppress_notifications: data.suppressNotifications,
                        message_subject: data.messageSubject
                    }
                }).then(response => {
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationNotificationSettingsGrid'
                    });
                });
            }
        },
        removeNotification: (data) => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/administration/notification/notification-remove',
                    method: 'POST',
                    data: {
                        nse_id: parseInt(data.notificationId)
                    }
                }).then(() => {
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationNotificationSettingsGrid'
                    });
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationNotificationSettingsTimelineGrid'
                    });
                });
            }
        },
        getNotificationSettingsTimelineList: () => {
            return (dispatch, getState) => {
                const state = getState();
                const notificationId = state.administration.administrationNotificationSettings.notificationId;
                return $http({
                    url: API_BASE + '/administration/notification/notification-timeline-list',
                    method: 'GET',
                    params: {
                        notificationId: notificationId
                    }
                }).then(response => {
                    dispatch({
                        type: 'COUNT_ITEMS',
                        id: 'administrationNotificationSettingsTimelineGrid',
                        itemsCount: response.data.items[0].length
                    });
                    dispatch({
                        type: 'GRID_FETCH',
                        id: 'administrationNotificationSettingsTimelineGrid',
                        items: response.data.items[0].map(item => {
                            return item;
                        })
                    });
                });
            }
        },
        getSubjectTypes: () => {
            return dispatch => {
                return $http({
                    url: API_BASE + '/administration/notification/notification-timeline-subject-types',
                    method: 'GET'
                }).then(response => {
                    dispatch({
                        type: 'NOTIFICATION_SETTINGS_SUBJECT_TYPES',
                        id: 'administrationNotificationSettings',
                        items: response.data.items.map(item => {
                            return {
                                value: item.sty_id,
                                label: item.sty_name,
                                item: {
                                    subjectTypeId: item.sty_id,
                                    subjectTypeName: item.sty_name,
                                    subjectTypeCode: item.sty_code
                                }
                            }
                        })
                    });
                });
            }
        },
        addNotificationSettingsTimeline: (data) => {
            return (dispatch, getState) => {
                const state = getState();
                const notificationId = state.administration.administrationNotificationSettings.notificationId;
                return $http({
                    url: API_BASE + '/administration/notification/notification-timeline-add',
                    method: 'POST',
                    data: {
                        nse_id: parseInt(notificationId),
                        sty_id: data.subjectTypeId,
                        repeat: parseInt(data.repeat),
                        days: parseInt(data.days),
                        repeat_from: parseInt(data.repeatFrom),
                        repeat_frequency: parseInt(data.repeatFrequency)
                    }
                }).then(response => {
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationNotificationSettingsTimelineGrid'
                    });
                    dispatch({
                        type: 'NOTIFICATION_SETTINGS_TIMELINE_RESET'
                    });
                });
            }
        },
        removeNotificationSettingsTimeline: (data) => {
            return (dispatch) => {
                return $http({
                    url: API_BASE + '/administration/notification/notification-timeline-remove',
                    method: 'POST',
                    data: {
                        nti_id: parseInt(data.item.nti_id),
                        sty_id: parseInt(data.item.sty_id)
                    }
                }).then(() => {
                    dispatch({
                        type: 'GRID_RELOAD',
                        id: 'administrationNotificationSettingsTimelineGrid'
                    });
                });
            }
        },
        sendNotificationSample: (data) => {
            return () => {
                return $http({
                    url: API_BASE + '/administration/notification/notification-sample',
                    method: 'POST',
                    data: {
                        loc_uuid: data.location,
                        nty_id: parseInt(data.notificationTypeId)
                    }
                });
            }
        }
    };
}

AdministrationNotificationSettingsActions.$inject = ['$http', 'Notifier'];
