import 'timepicker';

/* @ngInject */
export function timePicker(Helpers) {
    return {
        restrict: 'E',
        require: ['^form', 'ngModel'],
        scope: {
            name: '@name',
            time: '=ngModel',
            label: '@',
            required: '=?required',
            timeFormat: "@dateFormat",
            validate: '=?validate',
            minTime: "@minTime",
            maxTime: "@maxTime",
            change: '&onChange'
        },
        template: require('./time-picker.html'),
        link: function(scope, element, attrs, controllers) {

            var ICON_SCHEDULE = 'schedule.svg';
            var ICON_REMOVE = 'remove.svg';

            scope.identifier = _.isUndefined(scope.name) ? Helpers.generateIdentifier() : scope.name;
            scope.timeFormat = _.isUndefined(scope.timeFormat) ? 'h:i a' : scope.timeFormat;
            scope.validate = _.isUndefined(scope.validate) ? false : scope.validate;
            scope.required = _.isUndefined(scope.required) ? false : scope.required;

            scope.containerClass = '';
            scope.inputElement = element.find('input')[0];
            scope.inputForm = controllers[0];
            scope.inputField = controllers[1];
            scope.inputField.$name = scope.identifier;
            scope.inputForm.$addControl(scope.inputField);

            var modelWatch = scope.$watch('time', function(value) {
                if (_.isUndefined(value) || _.isNull(value)) {
                    scope.iconPath = ICON_SCHEDULE;
                }
                else {
                    scope.iconPath = ICON_REMOVE;
                    angular.element(element.find('md-icon')).on('click', onDelete);
                    $(scope.inputElement).timepicker('setTime', scope.time);
                    modelWatch();
                }
            });

            var options = {
                timeFormat: scope.timeFormat,
                minTime: scope.minTime,
                maxTime: scope.maxTime
            };

            $(scope.inputElement).timepicker(options).on('change', function() {
                scope.change();

                scope.inputForm[scope.identifier].$setValidity('required', true);
                scope.containerClass = '';

                if (_.isEmpty(scope.time)) {
                    scope.iconPath = ICON_SCHEDULE;
                    angular.element(element.find('md-icon')).off('click', onDelete);
                }
                else {
                    scope.iconPath = ICON_REMOVE;
                    angular.element(element.find('md-icon')).on('click', onDelete);
                }
            });

            function onDelete() {
                scope.time = null;
                scope.iconPath = ICON_SCHEDULE;
                angular.element(element).blur();
                angular.element(element.find('md-icon')).off('click', onDelete);
            }

            var validationListener = scope.$on('show-errors-check-validity', function(event, formName) {
                if (typeof formName !== 'undefined')
                    if (scope.inputForm.$name != formName) return;

                if (scope.validate && _.isEmpty(scope.time)) {
                    scope.inputForm[scope.identifier].$setValidity('required', false);
                    scope.inputForm[scope.identifier].$setDirty();
                    scope.containerClass = 'md-input-invalid';
                }
            });

            var validationWatch = scope.$watch('validate', function(value) {
                if (!value) {
                    scope.inputForm[scope.identifier].$setValidity('required', true);
                    scope.containerClass = '';
                    scope.iconPath = ICON_SCHEDULE;
                    angular.element(element.find('md-icon')).off('click', onDelete);
                }
            });

            element.on('$destroy', function () {
                validationListener();
                validationWatch();
                $(scope.inputElement).timepicker('remove');
                scope.inputForm.$removeControl(scope.inputField);
            });
        }
    };
}
