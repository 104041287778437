import find from 'lodash/find';
import * as EventsAssessmentActions from "./events.assessment.actions";

/**
 * @const {object}
 * @memberOf module:Events
 */
export const eventsAssessmentIssue = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $mdDialog, NavigationService, Notifier, $scope) {
        this.dialog = null;

        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(( state ) => ({
                eventID: state.events.eventItem.id,
                eventType: state.events.eventItem.event,
                eventAssessment: state.events.eventAssessment
            }), {
                ...EventsAssessmentActions
            })(this);

            const navConfig = NavigationService.getConfig($state.$current);
            this.colorName = navConfig.colorName;
            this.icon = navConfig.icon;

            this.identifier = $state.$current.parent.name;
            this.state = `${$state.$current.parent.name}.assessmentIssue`;

            this.getAssessmentIssueImpacts(this.eventID);
            this.getAssessmentIssueCodes();
            this.getAssessmentIssue(this.eventID).then((iac) => {
                if (iac) {
                    this.assessmentCode = iac;
                }
            });

            const evtType = this.eventType ? `${this.eventType} - ` : '';
            this.title = `${evtType}Assessment Review`;
        };

        this.onCreateImpact = (event) => {
            const impactCtrl = function ($scope, ctrl) {
                $scope.save = () => {
                    if ($scope.impactDialogForm.$valid) {
                        ctrl.createAssessmentIssueImpact({
                            iev_id: ctrl.eventID,
                            title: ctrl.eventAssessment.issue_impact_title,
                            description: ctrl.eventAssessment.issue_impact_description
                        }).then(() => {
                            ctrl.getAssessmentIssueImpacts(ctrl.eventID);
                            Notifier.success('New assessment issue impact has been successfully created');
                            $mdDialog.hide();
                        });
                    }
                };
                $scope.cancel = () => {
                    $mdDialog.cancel();
                };
            };

            this.dialog = $mdDialog.show({
                controller: impactCtrl,
                locals: { ctrl: this },
                template: require('./dialogs/events-assessment-issue-impact-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true
            }).then(this.resetImpact, this.resetImpact);
        };

        this.onEditImpact = () => {
            const impactCtrl = function ($scope, ctrl) {
                $scope.save = () => {
                    if ($scope.impactDialogForm.$valid) {
                        ctrl.updateAssessmentIssueImpact({
                            imp_id: ctrl.eventAssessment.issue_impact_id,
                            title: ctrl.eventAssessment.issue_impact_title,
                            description: ctrl.eventAssessment.issue_impact_description
                        }).then(() => {
                            ctrl.getAssessmentIssueImpacts(ctrl.eventID);
                            Notifier.success('Assessment issue impact has been successfully updated');
                            $mdDialog.hide();
                        });
                    }
                };
                $scope.cancel = () => {
                    $mdDialog.cancel();
                };
            };

            this.dialog = $mdDialog.show({
                controller: impactCtrl,
                locals: { ctrl: this },
                template: require('./dialogs/events-assessment-issue-impact-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                onShowing: () => {
                    const impact = find(this.eventAssessment.issueImpacts, ['value', this.eventAssessment.issue_impact]);
                    if (impact) {
                        this.editImpact({
                            id: impact.value,
                            title: impact.label,
                            description: impact.description
                        });
                    }
                }
            }).then(this.resetImpact, this.resetImpact);
        };

        this.onRemoveImpact = () => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Selected issue impact will be removed permanently.')
                .ariaLabel('Remove Issue Impact')
                .ok('Yes')
                .cancel('No');
            this.dialog = $mdDialog.show(confirm).then(() => {
                const impact = find(this.eventAssessment.issueImpacts, ['value', this.eventAssessment.issue_impact]);
                if (impact) {
                    this.deleteAssessmentIssueImpact({
                        iev_id: this.eventID,
                        imp_id: impact.value
                    }).then(() => {
                        this.getAssessmentIssueImpacts(this.eventID);
                        Notifier.success('Assessment issue impact has been successfully removed');
                    });
                }
            }, angular.noop);
        };

        this.onIssueAssessmentCodeChange = (event) => {
            const code = find(this.eventAssessment.issueAssessmentCodes, ['value', event.input]);
            if (code) {
                this.assessmentCode = code.display;
            }
        };

        this.onSaveAssessment = () => {
            if (this.eventAssessmentForm.$valid) {
                this.saveAssessmentIssue({
                    iev_id: this.eventID,
                    imp_id: this.eventAssessment.issue_impact,
                    iac_id: this.eventAssessment.issue_assessment_code
                }).then(() => {
                    Notifier.success('New event assessment has been successfully saved');
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
        };

        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        };
    },
    template: require('./events-assessment-issue.html'),
    bindings: {
        type: '@'
    }
};
