import { gridTpl } from '../../../../../../common/grid/grid.controller';

export const administrationAccountsHeadOfficesGrid = {
    bindings: {
        onSelect: '&',
        onRemove: '&',
        admin: '<'
    },
    controller: 'AdministrationAccountsHeadOfficesGridController',
    ...gridTpl
};
