import { GridReducer } from '../../../common/grid/grid.reducer';

const initialState = {
    eventAssessment: {
        saved: false,
        likelihood_id: null,
        likelihoodTypes: [],
        severity_potential_id: null,
        severityPotentialTypes: [],
        assessment_score: null,
        assessment_color: null,
        assessment_action: null,
        rle_id: null,
        rac_id: null,
        racActions: [],
        rac_officer: null,
        racOfficers: [],
        rac_record: null,
        rac_cause: null,
        rac_responses: null,
        responsible_person: null,
        responsiblePersons: [],
        assessment_update_date: new Date(),
        incident_person_type: null,
        incidentPersonTypes: [],
        incident_severity_assessment: null,
        incidentSeverityAssessments: [],
        issue_impact: null,
        issueImpacts: [],
        issue_impact_id: null,
        issue_impact_title: null,
        issue_impact_description: null,
        issue_assessment_code: null,
        issueAssessmentCodes: [],
        quality_manager: null,
        qualityManagers: [],
        quality_manager_review: null,
        quality_manager_signature: null,
        quality_manager_review_date: null
    },
    eventAssessmentHistoricalRecord: {
        id: null,
        accountId: null,
        visualId: null,
        location: null,
        locationName: null,
        locationCode: null,
        locations: [],
        additional_area: null,
        date: null,
        event: null,
        event_type: null,
        event_code: null,
        eventTypes: [],
        event_subtype: null,
        eventSubtypes: [],
        event_category: null,
        eventCategories: [],
        event_subcategory: null,
        eventSubcategories: [],
        consequence_level: null,
        consequenceLevels: [],
        likelihood_estimate: null,
        likelihoodEstimates: [],
        risk_rating: null,
        sac_score: null,
        followup_period: null,
        responsible_person: null,
        responsiblePersons: [],
        event_location: null,
        event_location_desc: null,
        eventLocations: [],
        event_harm: null,
        event_details: null,
        eventHarmData: [{value: 1, label: 'Yes'}, {value: 0, label: 'No'}],
        'eventInvolvedPerson.person_type_id': null,
        'eventInvolvedPerson.age_group_id': null,
        'eventInvolvedPerson.first_name': null,
        'eventInvolvedPerson.last_name': null,
        'eventInvolvedPerson.mrn': null,
        'eventInvolvedPerson.email': null,
        'eventInvolvedPerson.phone': null,
        eventInvolvedPersonTypes: [],
        eventInvolvedPersonAgeGroups: [],
        eventInvolvedPersons: [],
        camera: null,
        gallery: [],
        image_urls: [],
        document: null,
        document_urls: [],
        signature: null,
        signature_date: null,
    },
    eventsAssessmentGrid: {
        ...GridReducer(),
        items: [],
        itemsCount: 0,
    }
};

export default (state = initialState, action) => {
    if (action) {
        switch (action.type) {
            case 'EVENT_ASSESSMENT_RESPONSIBLE_OFFICER':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                        racOfficers: action.data.list
                    };
                }
            case 'EVENT_ASSESSMENT_RESPONSIBLE_PERSONS':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                        responsiblePersons: action.data.list
                    };
                }
            case 'EVENT_RESPONSIBLE_PERSON_DETAILS':
                if (action.id === 'eventItem') {
                    const responsiblePerson = {
                        ...action.data[0][0],
                        subject_uuid: action.data[0][0].uuid
                    };
                    return {
                        ...state,
                        responsible_person: responsiblePerson,
                        responsiblePersons: [responsiblePerson]
                    };
                }
            case 'EVENT_ASSESSMENT_SAVE':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                        saved: true
                    };
                }
            case 'EVENT_ASSESSMENT_INCIDENT_PERSON_TYPES':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                        incidentPersonTypes: action.data.map((item) => {
                            return {
                                value: item.category_id,
                                label: item.category_name
                            };
                        })
                    };
                }
            case 'EVENT_ASSESSMENT_INCIDENT_PERSON_TYPE_SET':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                        incident_person_type: action.data
                    };
                }
            case 'EVENT_ASSESSMENT_INCIDENT_CODES':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                        incidentSeverityAssessments: action.data.map((item) => {
                            return {
                                value: item.category_id,
                                label: item.category_description,
                                display: item.category_name
                            };
                        })
                    };
                }
            case 'EVENT_ASSESSMENT_INCIDENT_SAVE':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                        saved: true
                    };
                }
            case 'EVENT_ASSESSMENT_INCIDENT_DETAILS':
                if (action.id === 'eventAssessment') {
                    if (action.data.length) {
                        let responsiblePerson = null;
                        if (action.data[0].created_by_uuid) {
                            responsiblePerson = {
                                subject_uuid: action.data[0].created_by_uuid,
                                first_name: action.data[0].created_by_first_name,
                                last_name: action.data[0].created_by_last_name
                            };
                        }
                        let qualityManager = null;
                        if (action.data[0].quality_manager_uuid) {
                            qualityManager = {
                                subject_uuid: action.data[0].quality_manager_uuid,
                                first_name: action.data[0].quality_manager_first_name,
                                last_name: action.data[0].quality_manager_last_name
                            };
                        }
                        return {
                            ...state,
                            saved: action.data[0].assessment_saved === 'true',
                            responsible_person: responsiblePerson,
                            responsiblePersons: responsiblePerson ? [responsiblePerson] : [],
                            assessment_update_date: action.data[0].updated_on || new Date(),
                            incident_person_type: action.data[0].person_type_id,
                            incident_severity_assessment: action.data[0].sac_id,
                            quality_manager: qualityManager,
                            qualityManagers: qualityManager ? [qualityManager] : [],
                            quality_manager_review: action.data[0].ass_review_comment,
                            quality_manager_signature: action.data[0].quality_manager_signature,
                            quality_manager_review_date: action.data[0].ass_review_date
                        };
                    }
                }
            case 'EVENT_ASSESSMENT_IMPACTS':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                        issueImpacts: action.data.map((item) => {
                            return {
                                value: item.imp_id,
                                label: item.title,
                                description: item.description
                            };
                        })
                    };
                }
            case 'EVENT_ASSESSMENT_IMPACT_CREATE':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                        issue_impact: action.data
                    };
                }
            case 'EVENT_ASSESSMENT_IMPACT_EDIT':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                        issue_impact_id: action.data.id,
                        issue_impact_title: action.data.title,
                        issue_impact_description: action.data.description
                    };
                }
            case 'EVENT_ASSESSMENT_IMPACT_REMOVE':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                        issue_impact: null
                    };
                }
            case 'EVENT_ASSESSMENT_IMPACT_RESET':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                        issue_impact_id: null,
                        issue_impact_title: null,
                        issue_impact_description: null
                    };
                }
            case 'EVENT_ASSESSMENT_ISSUE_CODES':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                        issueAssessmentCodes: action.data.map((item) => {
                            return {
                                value: item.category_id,
                                label: item.category_name,
                                display: item.category_name
                            };
                        })
                    };
                }
            case 'EVENT_ASSESSMENT_ISSUE_DETAILS':
                if (action.id === 'eventAssessment') {
                    if (action.data.length) {
                        return {
                            ...state,
                            issue_impact: action.data[0].imp_id,
                            issue_assessment_code: action.data[0].iac_id
                        };
                    }
                }
            case 'EVENT_ASSESSMENT_QUALITY_MANAGERS':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                        qualityManagers: action.data.list
                    };
                }
            case 'EVENT_ASSESSMENT_REVIEW_SAVE':
                if (action.id === 'eventAssessment') {
                    return {
                        ...state,
                    };
                }
            default:
                return state;
        }
    }
    return state;
};
