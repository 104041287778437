import { IDS } from '../../settings-action-id.constants';
import { TAB_SELECT, TAB_SHOW } from '../../../../../common/tabs/tabs.constants'
import * as SettingsActions from "../../settings.actions";
import * as Actions from '../default-documents.actions';
import {TYPES} from "../../settings-action-type.constants";
import {GRID_FILTER} from "../../../../../common/grid/grid.constants";
import get from "lodash/get";

export const defaultDocumentDetails = {
        /* @ngInject */
        constructor: function ($ngRedux, $state, $stateParams, $scope, $rootScope, $filter, NavigationService, AuthService, Notifier, FileActions) {
            /**
             * @function $onInit
             */
        this.$onInit = async () => {
            this.identifier = IDS.accSettings;
            this.parentState = IDS.app_accreditation_scheme_settings_default_documents;

            this.unsubscribe = $ngRedux.connect(state => {
                const accSettings = state.accreditation.accSettings;
                const defaultDocumentDetails = accSettings.defaultDocumentDetails;
                const tabState = IDS.app_accreditation_scheme_settings_default_documents_details;
                // const policyTemplateId = defaultDocumentDetails.policyTemplateId

                let typeName;
                const isTypePNP = defaultDocumentDetails && ['asspol', 'assprc'].includes(defaultDocumentDetails.document_type);
                const textId = `DOCUMENT_MANAGEMENT.TYPES.${defaultDocumentDetails && defaultDocumentDetails.document_type.toUpperCase()}`;
                typeName = $filter('translate')(textId);
                if (typeName === textId)
                    typeName = defaultDocumentDetails.type_name;

                return {
                    tabState,
                    defaultDocumentDetails,
                    isTypePNP,
                    typeName,
                    schemeName:     accSettings.schemeDetails.title,
                    nodeName:       accSettings.sectionDetails.nodeName,
                    standard_id:    accSettings.sectionDetails.id,
                    conditionText:  accSettings.conditionText,
                }
            }, {
                ...FileActions,
                ...SettingsActions,
                ...Actions,
            })(this);

            this.schemeCode = $stateParams.itemCode;

            this.getSchemeDetails(this.schemeCode).then(scheme => {
            })
        }

        this.onTemplateUploadSave = (event) => {
            $ngRedux.dispatch({
                type: TYPES.DOCUMENT_SAMPLE_DOC_CHANGED,
                id: IDS.accSettings,
                path: IDS.defaultDocumentDetails,
                template: event[0].filePath,
            });
        }

        this.onDownloadTemplate = () => {
            this.download(`storage${this.template}`);
        };

        this.onResetTemplate = () => {
            $ngRedux.dispatch({
                type: TYPES.DOCUMENT_SAMPLE_DOC_CHANGED,
                id: IDS.accSettings,
                path: IDS.defaultDocumentDetails,
                template: null,
            });
        }

        // onDownloadButton() {
        //     this.download(`storage${this.pnpTemplatePath}`);
        // }

        this.onSaveChangesButton = () => {
            $scope.$broadcast('show-errors-check-validity', '$ctrl.defaultRequiredDocumentDetailsForm');
            if (this.defaultRequiredDocumentDetailsForm.$valid === false) {
                Notifier.error('COMMON.FIELDS_REQUIRED');
                return;
            }
            this.editDefaultRequiredDocument({
                ard_id: this.defaultDocumentDetails.id,
                name: this.defaultDocumentDetails.name,
                description: this.defaultDocumentDetails.description,
            }).then(() => {
                $ngRedux.dispatch({
                    type: TAB_SELECT,
                    id: IDS.app_accreditation_scheme_settings_default_documents,
                    state: IDS.app_accreditation_scheme_settings_default_documents_list,

                });
            });
        }

        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: require('./default-document-details.html')
}
