import { GridController, gridTpl } from '../../../../common/grid/grid.controller';

/**
 * Creates a new AdministrationNotificationSettingsGridController.
 * @class
 * @memberOf module:root/legacy/components/administration
 */
class AdministrationNotificationSettingsGridController extends GridController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param NavigationService {service} {@link module:root/common.NavigationService|NavigationService}
     * @param AdministrationNotificationSettingsActions {service}
     */
    constructor($ngRedux, $state, NavigationService, AdministrationNotificationSettingsActions) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.actions = AdministrationNotificationSettingsActions;
    }

    /**
     * @method $onInit
     * @memberof module:root/legacy/components/administration.AdministrationNotificationSettingsGridController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.module = 'administration';
        this.filterTemplate = './administration-notification-settings-grid-filter.html';
        this.exportComponent = false;

        this.columns = [
            {title: '#',                        mapper: null},
            {title: 'Location',                 mapper: 'location'},
            {title: 'Type',                     mapper: 'nty_name'},
            {title: 'Subject',                  mapper: 'message_subject'},
            {title: 'Template',                 mapper: 'email_template'},
            {title: 'Status',                   mapper: 'suppress_notifications'},
            {title: 'Default',                  mapper: 'derived'},
            {title: 'Overriden',                mapper: 'overriden'}
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.actions)(this);
        this.dataAction = this.getNotificationSettings;

        this.init();
    }

    /**
     * @method $onDestroy
     * @memberof module:root/components/administration.AdministrationNotificationSettingsGridController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.destroy();
    };
}

AdministrationNotificationSettingsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'AdministrationNotificationSettingsActions'];

export const administrationNotificationSettingsGrid = {
    bindings: {
        identifier: '@',
        title: '@',
        onSelect: '&',
        onReset: '&'
    },
    controller: AdministrationNotificationSettingsGridController,
    ...gridTpl
};
