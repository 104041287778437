
export const IDS = {
    // modules
    dmsImport:                         'dmsImport',

    // components
    dmsImportList:                     'dmsImportList',
    dmsImportListGrid:                 'dmsImportListGrid',
    dmsImportDetails:                  'dmsImportDetails',
    dmsImportDetailsGrid:              'dmsImportDetailsGrid',
    dmsImportVersionsGrid:             'dmsImportVersionsGrid',
    dmsImportVersionDetails:           'dmsImportVersionDetails',

    // import state:
    app_dms_import:                    'app.document-management.import',
    app_dms_import_upload:             'app.document-management.import.upload',
    app_dms_import_list:               'app.document-management.import.list',
    app_dms_import_details:            'app.document-management.import.details',

    // manage state:
    app_dms_manage:                    'app.document-management.manage',
    app_dms_manage_list:               'app.document-management.manage.list',
    app_dms_manage_details:            'app.document-management.manage.details',

    // new-import tab states:
};
