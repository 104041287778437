import moment from 'moment';

/* @ngInject */
export function ServiceContractsBaseController($controller, $scope, $rootScope, $mdDialog, $timeout, $log, ServiceContractService, ServiceContractManager, ServiceContract, EquipmentManager, Grid, EquipmentFilter, EquipmentSparePart, AccountService, Notifier, VendorService, VendorGridFilter) {
    var dialog = null;

    $scope.contract = new ServiceContract();

    angular.extend(this, $controller('ApplicationModuleController', {
        $scope: $scope,
        $timeout: $timeout,
        Notifier: Notifier
    }));

    $scope.templateEquipmentFilter = './service-contracts-equipment-filter.html';
    $scope.templateEquipmentGrid = './service-contracts-equipment-grid.html';
    $scope.templateVisitsScheduleFilter = './service-contracts-visits-schedule-filter.html';
    $scope.templateVisitsScheduleGrid = './service-contracts-visits-schedule-grid.html';

    $scope.timePeriods = ['AM', 'PM'];
    $scope.contractTypes = [];
    $scope.equipmentTypes = [];
    $scope.vendors = [];
    $scope.sparePart = new EquipmentSparePart();
    $scope.enableNextButton = false;

    var selectedAccount = null;
    var vendorFilter = new VendorGridFilter();
    vendorFilter.offset = null;
    vendorFilter.limit = null;

    $scope.gridEquipment = new Grid(EquipmentFilter);
    $scope.gridEquipment.columns = ServiceContractManager.equipmentColumns.toJS();

    $scope.gridEquipmentForVisits = new Grid(EquipmentFilter);
    $scope.gridEquipmentForVisits.columns = ServiceContractManager.equipmentColumns.delete(-2).toJS();

    ServiceContractService.getContractTypes().then(function(data) {
        $scope.contractTypes = data;
    });

    $scope.$watch('selectedTabIndex', function(newTab) {
        if (_.isInteger(newTab)) {
            if (newTab + 1 < $scope.tabs.length) {
                $scope.enableNextButton = !$scope.tabs[newTab + 1].disabled;
            }
        }
    });

    $scope.contractTypeSelected = function() {
        vendorFilter.serviceType = $scope.contract.type.id;
        VendorService.getVendorHeadOfficeList(vendorFilter).then(function(data) {
            $scope.vendors = data.items;
            if (_.isUndefined(_.find($scope.vendors, ['uuid', $scope.contract.vendor.uuid])))
                $scope.contract.resetVendor();
        });
    };

    $scope.$on('FILE_UPLOAD_SUCCESS', function(event, filePath, uploader) {
        switch ($scope.selectedTab.id) {
            case ServiceContractManager.TAB_CONTRACT_DETAILS:
                $scope.contract.url = filePath;
                if ($scope.CONTRACT_STATE == $scope.MODULE_STATE_UPDATE) {
                    ServiceContractService.updateContract($scope.contract).then(function(status) {
                        if (status) Notifier.error('System Error! Document not saved.');
                    });
                }
                break;
            case ServiceContractManager.TAB_SPARE_PARTS:
                ServiceContractService.addEquipmentSparePartDocument($scope.contract.id, filePath).then(function(status) {
                    $scope.contract.equipmentSparePartsUrl = filePath;
                });
                break;
        }
        uploader.clearQueue();
        uploader.cancelAll();
    });

    $scope.$on('LOCATION_SUBJECT_SELECTED', function(event, subject) {
        selectedAccount = subject;
    });

    $scope.addAccount = function() {
        $scope.$broadcast('show-errors-check-validity', $scope.selectedTab.form.$name);
        if ($scope.selectedTab.form.$invalid) return;

        if (_.isUndefined(_.find($scope.contract.accounts, ['uuid', selectedAccount.uuid]))) {
            ServiceContractService.addAccount($scope.contract.id, selectedAccount.uuid).then(function() {
                $scope.contract.accounts.push(selectedAccount);
                selectedAccount = null;
                $rootScope.$broadcast('SELECT_CASCADE_RESET');
                if ($scope.contract.equipmentCategories.length)
                    $scope.getEquipment();
            });
            var serviceList = [];

            if ($scope.contract.accounts.length) {
                serviceList.push(ServiceContractService.getEquipment($scope.contract, $scope.gridEquipment.filter).then(function(data) {
                    $scope.gridEquipment.totalItems = data.total;
                    $scope.gridEquipment.items =  data.items;
                }));
            }
            serviceList.push(ServiceContractService.getEquipmentTypesWithCategories($scope.contract.type.id, $scope.contract.id).then(function(data) {
                $scope.equipmentTypes = data;

                _.each($scope.equipmentTypes, function(equipmentType) {
                    _.each(equipmentType.categories, function(category) {
                        category.selected = _.isUndefined(_.find($scope.contract.equipmentCategories, ['id', category.id])) ? 0 : 1;
                    });
                });
                $scope.setSelectedEquipmentTypes();
            }));
        }
        else Notifier.error('Selected Account has been already added');
    };

    $scope.removeAccount = (function(uuid) {
        var account = _.find($scope.contract.accounts, ['uuid', uuid]);
        if (_.isUndefined(account))
            $log.warn('Contract account already removed');
        else
            ServiceContractService.removeAccount($scope.contract.id, uuid).then((function() {
                var affectedVisits = $scope.contract.removeAccount(account);
                if (affectedVisits) Notifier.warn('Scheduled visits are modified by Account removal');
                $scope.resetFilter($scope.gridEquipment.filter.id);
                $scope.gridEquipmentForVisits.filter.account = _.first($scope.contract.accounts);
                $scope.resetFilter($scope.gridEquipmentForVisits.filter.id);
                if ($scope.contract.accounts.length == 0) this.disableTabs();

                var serviceList = [];

                if ($scope.contract.accounts.length) {
                    serviceList.push(ServiceContractService.getEquipment($scope.contract, $scope.gridEquipment.filter).then(function(data) {
                        $scope.gridEquipment.totalItems = data.total;
                        $scope.gridEquipment.items =  data.items;
                    }));
                }
                serviceList.push(ServiceContractService.getEquipmentTypesWithCategories($scope.contract.type.id, $scope.contract.id).then(function(data) {
                    $scope.equipmentTypes = data;

                    _.each($scope.equipmentTypes, function(equipmentType) {
                        _.each(equipmentType.categories, function(category) {
                            category.selected = _.isUndefined(_.find($scope.contract.equipmentCategories, ['id', category.id])) ? 0 : 1;
                        });
                    });
                    $scope.setSelectedEquipmentTypes();
                }));
            }).bind(this));
    }).bind(this);

    $scope.manageEquipmentCategories = (function(category) {
        if (_.find($scope.contract.equipmentCategories, ['id', category.id])) {
            ServiceContractService.removeEquipmentCategory($scope.contract.id, category.id).then((function(status) {
                var affectedVisits = $scope.contract.removeCategory(category);
                if (affectedVisits) Notifier.warn('Scheduled visits are modified by Category removal');
                $scope.resetFilter($scope.gridEquipment.filter.id);
                $scope.resetFilter($scope.gridEquipmentForVisits.filter.id);
                $scope.setSelectedEquipmentTypes();
                if ($scope.contract.equipmentCategories.length == 0) this.disableTabs();
            }).bind(this));
        }
        else {
            ServiceContractService.addEquipmentCategory($scope.contract.id, category.id).then(function(status) {
                $scope.contract.equipmentCategories.push(category);
                $scope.resetFilter($scope.gridEquipment.filter.id);
                $scope.setSelectedEquipmentTypes();
            }, function(error) {
                category.selected = 0;
                Notifier.error('This equipment type cant be added to the selected service contract type.');
            });
        }
    }).bind(this);

    $scope.setSelectedEquipmentTypes = function() {
        $scope.contract.equipmentTypes = $scope.equipmentTypes.filter(function(equipmentType) {
            return _.intersectionBy(equipmentType.categories, $scope.contract.equipmentCategories, 'id').length > 0;
        });
    };

    $scope.getEquipment = function(page) {
        if (page || $scope.contract.accounts.length || $scope.contract.equipmentCategories.length) {
            $scope.gridEquipment.filter.init(page);
            ServiceContractService.getEquipment($scope.contract, $scope.gridEquipment.filter).then(function(data) {
                $scope.gridEquipment.totalItems = data.total;
                $scope.gridEquipment.items = data.items;
            });
        }
        else $scope.gridEquipment.items = [];
    };

    $scope.manageEquipment = (function(item) {
        if (_.isUndefined(_.find($scope.contract.equipment, ['equipment_id', item.equipment_id]))) {
            ServiceContractService.addEquipment($scope.contract.id, item.equipment_id).then(function(status) {
                $scope.contract.equipment.push(_.assign({selected: 0, scheduled: false}, item));
                $scope.gridEquipmentForVisits.items = $scope.contract.equipment;
            }, function(error) {
                Notifier.responseMessage(error);
            });
        }
        else {
            ServiceContractService.removeEquipment($scope.contract.id, item.equipment_id).then((function(status) {
                var affectedVisits = $scope.contract.removeEquipment([item]);
                if (affectedVisits) Notifier.warn('Scheduled visits are modified by Equipment removal');
                $scope.resetFilter($scope.gridEquipmentForVisits.filter.id);
                if ($scope.contract.equipment.length == 0) this.disableTabs();
            }).bind(this));
        }
    }).bind(this);

    $scope.showEquipmentDetails = function(equipment) {
        EquipmentManager.showDetails(equipment);
    };

    $scope.createEquipmentSparePart = function() {
        // TODO validate
        if ($scope.sparePart.name) {
            ServiceContractService.createEquipmentSparePart($scope.sparePart.name).then(function(sparePart) {
                $scope.sparePart = new EquipmentSparePart();
                $scope.equipmentSpareParts.push(sparePart);
            });
        }
    };

    $scope.includeEquipmentSpareParts = function() {
        if ($scope.contract.equipmentSparePartsIncluded) {
            ServiceContractService.removeEquipmentSparePart($scope.contract.id, -1).then(function(status) {
                $scope.contract.equipmentSpareParts = [];
            });
        }
    };

    $scope.manageEquipmentSpareParts = function(item) {
        if (_.isUndefined(_.find($scope.contract.equipmentSpareParts, ['id', item.id]))) {
            ServiceContractService.assignEquipmentSparePart($scope.contract.id, item.id).then(function(status) {
                $scope.contract.equipmentSpareParts.push(item);
            });
        }
        else {
            ServiceContractService.removeEquipmentSparePart($scope.contract.id, item.id).then(function(status) {
                var idx = $scope.contract.equipmentSpareParts.indexOf(item);
                if (idx > -1) $scope.contract.equipmentSpareParts.splice(idx, 1);
            });
        }
    };

    $scope.onSelectNumberOfVisits = function() {
        if ($scope.contract.numberOfVisits) {
            ServiceContractService.setEstimatedTimeFrames($scope.contract).then(function(data) {
                $scope.contract.setEstimatedTimeFrames(data.months);
            });
        }
    };

    $scope.getEquipmentForVisits = function() {
        $scope.gridEquipmentForVisits.items = $scope.contract.equipment.filter(function(equipment) {
            var accountCondition = false;
            var typeCondition = false;
            if ($scope.gridEquipmentForVisits.filter.account) {
                accountCondition = equipment.account.uuid == $scope.gridEquipmentForVisits.filter.account.uuid;
            }
            if ($scope.gridEquipmentForVisits.filter.category) {
                typeCondition = equipment.category.id == $scope.gridEquipmentForVisits.filter.category.id;
            }
            if (!$scope.gridEquipmentForVisits.filter.category) {
                return accountCondition;
            }
            else {
                if (typeCondition == false && accountCondition == false) {
                    return false;
                } else if (!$scope.gridEquipmentForVisits.filter.account) {
                    return typeCondition;
                } else {
                    return typeCondition && accountCondition;
                }
            }
        });
    };

    $scope.manageVisit = function(month) {
        var monthData = month.split(" ");
        var selectedDate = new Date(Date.parse(monthData[0] + " 1, " + monthData[1]));
        var currentDate = new Date();

        if( moment(currentDate.getFullYear()+'-'+(currentDate.getMonth()+1)).isSameOrBefore(selectedDate.getFullYear()+'-'+(selectedDate.getMonth()+1)) ) {
            var equipment = $scope.contract.equipment.filter(function(item) {
                return item.selected == 1;
            });

            var account = $scope.gridEquipmentForVisits.filter.account;
            if (!!account) {
                var schedule = $scope.contract.getVisitSchedule(account.uuid, month);
                if (equipment.length || schedule.visits.length) {
                    if (schedule.isEquipmentValidForSchedule(equipment))
                        ServiceContractManager.showVisitSchedule($scope.contract.id, schedule, equipment, month);
                    else
                        Notifier.error('One of the selected equipment is already scheduled for ' + schedule.month);
                }
                else
                    Notifier.error('You have to select at least one peace of Equipment');
            } else {
                Notifier.error('Please select site location from filter');
            }
        } else {
            Notifier.error('Please select a month that has not passed');
        }
    };

    $scope.filterVisitsAccountSelected = function() {
        _.each($scope.contract.equipment, function(item) {
            item.selected = 0;
        });
        $scope.getEquipmentForVisits();
    };

    $scope.unscheduleEquipment = function(month, equipment) {

        var confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Scheduled visit will be removed.')
            .ariaLabel('Remove Scheduled visit')
            .ok('Yes')
            .cancel('No');

        dialog = $mdDialog.show(confirm).then(function() {
            ServiceContractService.unscheduleEquipmentVisit($scope.contract.id, month, equipment.equipment_id).then(function(status) {
                $scope.contract.unscheduleEquipment(month, equipment);
                reorderEquipmentVisitColumns();
            });
        }, angular.noop);
    };

    $scope.finalizeContract = function() {
        ServiceContractService.finalizeContract($scope.contract.id).then(function(status) {
            $scope.contract.status = status;
            Notifier.success('Contract was finalized successfully');
        });
    };

    $scope.resetFilter = function(filterId) {
        switch (filterId) {
            case $scope.gridEquipment.filter.id:
                $scope.gridEquipment.filter.reset();
                $scope.getEquipment();
                break;
            case $scope.gridEquipmentForVisits.filter.id:
                $scope.gridEquipmentForVisits.filter.page = 1;
                $scope.gridEquipmentForVisits.filter.offset = 0;
                $scope.gridEquipmentForVisits.filter.type = null;
                $scope.gridEquipmentForVisits.filter.account = null;
                $scope.gridEquipmentForVisits.filter.category = null;
                $scope.gridEquipmentForVisits.items = $scope.contract.equipment;
                //$scope.getEquipmentForVisits();
                break;
            default:
                $scope.grid.filter.reset();
                $scope.getContracts();
        }
    };

    // this.disableTabs = function() {
    //     for (var i = $scope.selectedTab + 1; i < $scope.tabs.length; i++) {
    //         $scope.tabs[i].disabled = true;
    //     }
    //     Notifier.error($scope.tabs[$scope.selectedTab].formValidationMsg);
    // };

    $scope.$on(ServiceContractManager.SCHEDULE_VISIT_COMPLETE, reorderEquipmentVisitColumns);

    function reorderEquipmentVisitColumns() {
        var maxScheduled = 0;
        _.each($scope.contract.equipment, function(equipment) {
            var countScheduled = 0;
            _.each($scope.contract.visitSchedules, function(schedule) {
                _.each(schedule.visits, function(visit) {
                    if ($scope.contract.isEquipmentScheduled(visit.month, equipment))
                        countScheduled++;
                });
            });
            if (maxScheduled < countScheduled)
                maxScheduled = countScheduled;
            if (maxScheduled == $scope.contract.monthsScheduled.length)
                return false;
        });

        var visibleColumns = ServiceContractManager.equipmentColumns.delete(-2).size;
        if (maxScheduled > 5)
            visibleColumns = Math.round($scope.contract.months.length * 3 / maxScheduled) - 1;

        $scope.gridEquipmentForVisits.columns = ServiceContractManager.equipmentColumns.delete(-2).slice(0, visibleColumns).toJS();
    };

    $scope.getMonthShortFormat = function(month) {
        return moment(month, 'MMMM YYYY').format('MMM YYYY');
    };

    $scope.$on("$destroy", function() {
        if (dialog)
            $mdDialog.hide(dialog);
    });
}
