import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';

/**
 * Creates a new InputTextController.
 * @class
 * @extends InputController
 */
class InputTextController {
    /**
     * @constructor
     * @param $ngRedux
     */
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
        this.unsubscribe = () => {};
        this.style = {};
    }

    $onInit() {
        if (isUndefined(this.name))
            throw new Error('Missing input name attribute');
        if (isUndefined(this.identifier))
            throw new Error('Missing input ' + this.name + ' identifier attribute');

        if (isUndefined(this.required))
            this.required = false;
        if (isUndefined(this.disabled))
            this.disabled = false;
        if (this.disabled && this.underline)
            this.style = { 'border-bottom': 'solid 2px #000' };

        switch (this.validate) {
            case 'NUMBER':
                this.pattern = /^\d+$/;
                this.msg = 'Numbers only';
                break;
            case 'DECIMAL':
                this.pattern = /^\d+([,.]\d+)?$/;
                this.msg = 'Decimal numbers only';
                break;
            default:
                this.pattern = null;
                this.msg = '';
        }

        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        if (this.statePath) this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);
    }

    onInput() {
        let value;
        switch (this.validate) {
            case 'NUMBER':
                value = parseInt(this.model);
                break;
            case 'DECIMAL':
                value = parseFloat(this.model);
                break;
            default:
                value = this.model;
        }

        this.$ngRedux.dispatch({
            type: 'INPUT_TEXT_CHANGE',
            id: this.identifier,
            path: this.statePath,
            input: value,
            data: {[this.statePath]: value}
        });
        this.form[this.name].$setValidity('unique', true);

        if (this.onChange) {
            this.onChange({
                $event: {input: value}
            });
        }
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeModule();
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        };
    }

    mapState(state) {
        return {
            model: get(state[this.module][this.identifier], this.statePath)
        }
    }
}

InputTextController.$inject = ['$ngRedux'];

export const inputText = {
    bindings: {
        identifier: '@',
        statePath: '@',
        name: '@',
        label: '@',
        maxLength: '<',
        minLength: '<',
        required: '<',
        disabled: '<',
        underline: '<',
        model: '=?',
        validate: '@',
        onChange: '&'
    },
    require : {
        form : '^form'
    },
    controller: InputTextController,
    template: `
        <md-input-container class="md-block" flex>
            <label>{{$ctrl.label}}</label>
            <input
                type="text"
                id="{{$ctrl.name}}"
                name="{{$ctrl.name}}"
                ng-style="$ctrl.style"
                ng-model="$ctrl.model"
                ng-change="$ctrl.onInput()"
                ng-maxlength="$ctrl.maxLength"
                ng-minlength="$ctrl.minLength"
                ng-required="$ctrl.required"
                ng-disabled="$ctrl.disabled"
                ng-pattern="$ctrl.pattern">
            <div ng-messages="$ctrl.form[$ctrl.name].$error">
                <div ng-message="required">{{'COMMON.FIELD_REQUIRED' | translate}}</div>
                <div ng-message="unique">Field has to be unique</div>
                <div ng-message="pattern">{{$ctrl.msg}}</div>
            </div>
        </md-input-container>
    `
};
