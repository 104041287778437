export function SubjectTypeFactory() {
    return function SubjectType() {
        this.id = null;
        this.code = null;
        this.name = null;
        this.par_id = null;
        this.level = null;
        this.abs_level = null;
        this.is_user = null;
    };
}
