import { TabsReducer } from '../common/tabs/tabs.reducer';

export const administrationAccountsTabs = {
    'app.administration.accounts': Object.assign(TabsReducer(), {
        tabs: [
            {
                state: 'app.administration.accounts.headOffices',
                title: 'Head Offices',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'New Head Office'}
                }
            },
            {
                state: 'app.administration.accounts.headOfficesDetails',
                title: 'Details',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Site Locations',
                        state: 'app.administration.accounts.siteLocations',
                        reload: 'app.administration.accounts',
                        stateProp: true
                    }],
                    execute: {label: 'Add Head Office'}
                }
            },
            {
                state: 'app.administration.accounts.headOfficesContacts',
                title: 'Contacts',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Site Locations',
                        state: 'app.administration.accounts.siteLocations',
                        reload: 'app.administration.accounts'
                    }],
                    execute: {label: 'New Contact'}
                }
            },
            {
                state: 'app.administration.accounts.headOfficesContactDetails',
                title: 'Contact Details',
                visible: false,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Site Locations',
                        state: 'app.administration.accounts.siteLocations',
                        reload: 'app.administration.accounts'
                    }],
                    execute: {label: 'Add Contact'}
                }
            },
            {
                state: 'app.administration.accounts.headOfficesLicences',
                title: 'Licences',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Site Locations',
                        state: 'app.administration.accounts.siteLocations',
                        reload: 'app.administration.accounts'
                    }],
                }
            },
            {
                state: 'app.administration.accounts.headOfficesEquipment',
                title: 'Equipment',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Site Locations',
                        state: 'app.administration.accounts.siteLocations',
                        reload: 'app.administration.accounts'
                    }]
                }
            },
            {
                state: 'app.administration.accounts.headOfficesModules',
                title: 'Modules',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            }
        ]
    }),
    'app.administration.accounts.siteLocations': Object.assign(TabsReducer(), {
        tabs: [
            {
                state: 'app.administration.accounts.siteLocations.list',
                title: 'Site Locations',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    redirect: [
                        {
                            label: 'Head Office',
                            state: 'app.administration.accounts.headOfficesDetails',
                            reload: 'app.administration.accounts'
                        }
                    ],
                    execute: {label: 'New Site Location'}
                }
            },
            {
                state: 'app.administration.accounts.siteLocations.details',
                title: 'Details',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Departments',
                        state: 'app.administration.accounts.departments',
                        reload: 'app.administration.accounts',
                        stateProp: true
                    }],
                    execute: {label: 'Add Site Location'}
                }
            },
            {
                state: 'app.administration.accounts.siteLocations.address',
                title: 'Address',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Departments',
                        state: 'app.administration.accounts.departments',
                        reload: 'app.administration.accounts'
                    }],
                    execute: {label: 'Modify'}
                }
            },
            {
                state: 'app.administration.accounts.siteLocations.invoiceAddress',
                title: 'Invoice Address',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Departments',
                        state: 'app.administration.accounts.departments',
                        reload: 'app.administration.accounts'
                    }],
                    execute: {label: 'Modify'}
                }
            },
            {
                state: 'app.administration.accounts.siteLocations.contacts',
                title: 'Contacts',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Departments',
                        state: 'app.administration.accounts.departments',
                        reload: 'app.administration.accounts'
                    }],
                    execute: {label: 'New Contact'}
                }
            },
            {
                state: 'app.administration.accounts.siteLocations.contactDetails',
                title: 'Contact Details',
                visible: false,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Departments',
                        state: 'app.administration.accounts.departments',
                        reload: 'app.administration.accounts'
                    }],
                    execute: {label: 'Add Contact'}
                }
            },
            {
                state: 'app.administration.accounts.siteLocations.licences',
                title: 'Licences',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Departments',
                        state: 'app.administration.accounts.departments',
                        reload: 'app.administration.accounts'
                    }],
                    execute: {label: 'New Licence'}
                }
            },
            {
                state: 'app.administration.accounts.siteLocations.licenceDetails',
                title: 'Licence Details',
                visible: false,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Departments',
                        state: 'app.administration.accounts.departments',
                        reload: 'app.administration.accounts'
                    }],
                    execute: {label: 'Add Licence'}
                }
            },
            {
                state: 'app.administration.accounts.siteLocations.licenceIsotopes',
                title: 'Licence Isotopes',
                visible: false,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Departments',
                        state: 'app.administration.accounts.departments',
                        reload: 'app.administration.accounts'
                    }]
                }
            },
            {
                state: 'app.administration.accounts.siteLocations.licenceAppendix',
                title: 'Licence Appendix',
                visible: false,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Departments',
                        state: 'app.administration.accounts.departments',
                        reload: 'app.administration.accounts'
                    }]
                }
            },
            {
                state: 'app.administration.accounts.siteLocations.equipment',
                title: 'Equipment',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Departments',
                        state: 'app.administration.accounts.departments',
                        reload: 'app.administration.accounts'
                    }]
                }
            },
            {
                state: 'app.administration.accounts.siteLocations.employees',
                title: 'Employees',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Departments',
                        state: 'app.administration.accounts.siteLocations',
                        reload: 'app.administration.accounts'
                    }]
                }
            },
            {
                state: 'app.administration.accounts.siteLocations.modules',
                title: 'Modules',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Departments',
                        state: 'app.administration.accounts.departments',
                        reload: 'app.administration.accounts'
                    }],
                    execute: {label: 'Save'}
                }
            }
        ]
    }),
    'app.administration.accounts.departments': Object.assign(TabsReducer(), {
        tabs: [
            {
                state: 'app.administration.accounts.departments.list',
                title: 'Departments',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    redirect: [
                        {
                            label: 'Site Location',
                            state: 'app.administration.accounts.siteLocations.details',
                            reload: 'app.administration.accounts'
                        }
                    ],
                    execute: {label: 'New Department'}
                }
            },
            {
                state: 'app.administration.accounts.departments.details',
                title: 'Details',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Rooms',
                        state: 'app.administration.accounts.rooms',
                        reload: 'app.administration.accounts',
                        stateProp: true
                    }],
                    execute: {label: 'Add Department'}
                }
            },
            {
                state: 'app.administration.accounts.departments.contacts',
                title: 'Contacts',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Rooms',
                        state: 'app.administration.accounts.rooms',
                        reload: 'app.administration.accounts'
                    }],
                    execute: {label: 'New Contact'}
                }
            },
            {
                state: 'app.administration.accounts.departments.contactDetails',
                title: 'Contact Details',
                visible: false,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Rooms',
                        state: 'app.administration.accounts.rooms',
                        reload: 'app.administration.accounts'
                    }],
                    execute: {label: 'Add Contact'}
                }
            },
            {
                state: 'app.administration.accounts.departments.equipment',
                title: 'Equipment',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Rooms',
                        state: 'app.administration.accounts.rooms',
                        reload: 'app.administration.accounts'
                    }]
                }
            },
            {
                state: 'app.administration.accounts.departments.employees',
                title: 'Employees',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    redirect: [{
                        label: 'Rooms',
                        state: 'app.administration.accounts.rooms',
                        reload: 'app.administration.accounts'
                    }]
                }
            }
        ]
    }),
    'app.administration.accounts.rooms': Object.assign(TabsReducer(), {
        tabs: [
            {
                state: 'app.administration.accounts.rooms.list',
                title: 'Rooms',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    redirect: [
                        {
                            label: 'Department',
                            state: 'app.administration.accounts.departments.details',
                            reload: 'app.administration.accounts'
                        }
                    ],
                    execute: {label: 'New Room'}
                }
            },
            {
                state: 'app.administration.accounts.rooms.details',
                title: 'Details',
                visible: true,
                disabled: true,
                loading: false,
                buttons: {
                    execute: {label: 'Add Room'}
                }
            },
            {
                state: 'app.administration.accounts.rooms.equipment',
                title: 'Equipment',
                visible: true,
                disabled: true,
                loading: false
            }
        ]
    })
};
