/* @ngInject */
export function SafetyPlanReviewEmployeeDocumentsController($scope, $state, $rootScope, $q, $location, SafetyPlanService, Notifier, SAFETY_PLAN_REVIEW_SETTINGS, $filter, DocumentManager) {

    $scope.tplReviewDocuments = './safety-plan-review-documents-filter.html';

    $scope.currentPage = 1;
    $scope.pageSize = 10;

    $scope.selectedItem = null;
    $scope.documents = null;

    $scope.trainingName = $state.current.trainingName;

    $scope.selectItem = function(item) {
        $scope.selectedItem = item;
    };

    $scope.fetchData = function() {
        $scope.selectedItem = null;

        $q.all([
            SafetyPlanService.fetchEmployeeSafetyPlanDownloadDocuments($state.current.trainingType)
                .then(function(data) {
                    $scope.documents = data.list.reverse();
                    $scope.documents.forEach(function (item) {
                        if (item.docDateEdited != null) {
                            item.docDateEditedStr = $filter('date')(item.docDateEdited, SAFETY_PLAN_REVIEW_SETTINGS.DATE_FORMAT);
                        }
                    });
                }, function(error) {
                    Notifier.error('Unable to fetch Safety Plan document list.');
                })
        ]).then(function() {});
    };

    $scope.downloadDocument = function(item) {
        DocumentManager.downloadFile("/"+item.documentVariant.docVariantPath, 3);
    };

    $scope.fetchData();
}
