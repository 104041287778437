import { IDS } from '../settings-action-id.constants';
import * as SettingsActions from '../settings.actions';
import * as SectionsActions from '../sections/sections.actions';
import * as AccreditationActions from '../../accreditation.actions';
/**
 * Creates a new SchemeDetailsController.
 * @class
 */
class SchemeDetailsController {
    constructor($ngRedux, $state, $scope, $uiRouterGlobals, $filter, Notifier, NavigationActions, AuthService) {
        this.$ngRedux           = $ngRedux;
        this.$state             = $state;
        this.$scope             = $scope;
        this.$uiRouterGlobals   = $uiRouterGlobals;
        this.$filter            = $filter;
        this.Notifier           = Notifier;
        this.NavigationActions  = NavigationActions;
        this.AuthService        = AuthService;
        this.stateName          = this.$state.$current.name;
        this.tabStateName       = this.$state.$current.parent.name;
    }

    $onInit() {
        this.identifier = IDS.accSettings;
        this.unsubscribe = this.$ngRedux.connect(
            state => {
                return {
                    schemeDetails:  state.accreditation.accSettings.schemeDetails,
                    title:          state.accreditation.accSettings.schemeDetails.title,
                }
            }, {
                ...AccreditationActions,
                ...SettingsActions,
                ...SectionsActions,
            }
        )(this);

        this.schemeCode = this.$uiRouterGlobals.params.itemCode;
        this.getSchemeDetails(this.schemeCode);
    }

    onSubmit() {
        this.$scope.$broadcast('show-errors-check-validity', '$ctrl.detailsForm');
        if (this.detailsForm.$valid) {
            this.editScheme(this.schemeDetails).then(() => {
                this.$ngRedux.dispatch(this.NavigationActions.getConfigs());
                this.fetchStandardsTree(this.schemeCode);
            });
        }
        else this.Notifier.error('COMMON.FIELDS_REQUIRED');
    }

    onActivate() {
        this.activateScheme(this.schemeDetails.id).then(()=>{
            this.getSchemeStatus(this.schemeDetails.id);
        });
    }

    onDeactivate() {
        this.deactivateScheme(this.schemeDetails.id).then(()=>{
            this.getSchemeStatus(this.schemeDetails.id);
        });
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

SchemeDetailsController.$inject = ['$ngRedux', '$state', '$scope', '$uiRouterGlobals', '$filter', 'Notifier', 'NavigationActions', 'AuthService']

export const schemeDetails = {
    controller: SchemeDetailsController,
    template: require('./scheme-details.html')
}
