export const pdSettingsState = {
    name: 'app.personalDosimetry.settings',
    redirectTo: 'app.personalDosimetry.settings.listing',
    url: '/settings',
    component: 'tabs'
};

export const pdSettingsListingState = {
    name: 'app.personalDosimetry.settings.listing',
    url: '',
    component: 'personalDosimetrySettings',
    onEnter: ($ngRedux) => {
        // TODO
    },
    onExit: ($ngRedux) => {
        // TODO
    }
};
pdSettingsListingState.onEnter.$inject = ['$ngRedux'];
pdSettingsListingState.onExit.$inject = ['$ngRedux'];

export const pdSettingsDetailsState = {
    name: 'app.personalDosimetry.settings.details',
    url: '/details?id',
    component: 'personalDosimetrySettingsDetails',
    onExit: ($ngRedux) => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.personalDosimetry.settings',
            state: ['app.personalDosimetry.settings.details']
        });
        $ngRedux.dispatch({
            type: 'PD_LIMIT_RESET',
            id: 'personalDosimetry',
        });
    },
};
pdSettingsDetailsState.onExit.$inject = ['$ngRedux'];
