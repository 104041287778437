/* @ngInject */
export function SafetyPlanService($http, API_BASE,
                                  SafetyPlanQuestion, SafetyPlanCertificate, SafetyPlanTrainingDocument, SafetyPlanTrainingMatrixRecord,
                                  User, SAFETY_PLAN_REVIEW_SETTINGS, $q, Notifier, Helpers) {

    /**
     * Method   - GET
     * Endpoint - /safety-plan/config
     * Params   - (none)
     * Response - Object
     *
     * Get Radiation Safetu and Protection Plan Configuration
     * (In particular Review Period)
     *
     * Fields:
     * Return Safety Plan configuration object
     * {
     *  REVIEW_DEFAULT_LENGTH: length
     * }

     *
     * */
    this.fetchSafetyPlanConfig = function () {
        var params = [];

        return $http({
            url: API_BASE + "/safety-plan/config",
            params: params,
            method: "GET"
        }).then(function successCallback(response) {

                var config = {};

                if (response.data != null) {
                    config.SAFETY_PLAN_REVIEW_LENGTH = response.data.SAFETY_PLAN_REVIEW_LENGTH;
                } else {
                    config.SAFETY_PLAN_REVIEW_LENGTH = SAFETY_PLAN_REVIEW_SETTINGS.REVIEW_DEFAULT_LENGTH;
                }
                return config;
            },
            function errorCallback(errorResponse) {
                return {
                    SAFETY_PLAN_REVIEW_LENGTH: SAFETY_PLAN_REVIEW_SETTINGS.REVIEW_DEFAULT_LENGTH
                };
            });
    };


    this.asqEmployeeQuestion = function(mySubject, myQuestion, trainingType) {
        return $http({
            url: API_BASE + "/review-help/ask-question",
            method: "POST",
            data: {
                subject: mySubject,
                question: myQuestion,
                trainingType: trainingType
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };

    /**
     * Method   - GET
     * Endpoint - /review-help/rsc-questions
     * Params   - includeAnswered - include already answered questions in the list
     * Response - Object
     *
     * Get employee questions
     * Fields:

     *
     * */
    this.fetchEmployeesQuestions = function (includeAnswered, trainingType) {
        var params = [];
        params['includeAnswered'] = includeAnswered;
        params['trainingType'] = trainingType;
        return $http({
            url: API_BASE + "/review-help/rsc-questions",
            params: params,
            method: "GET"
        }).then(function (response) {
                var arr = [];
                response.data.list.forEach(function (elem) {
                    var item = new SafetyPlanQuestion();
                    item.questionId = elem.rqu_id;
                    item.user.firstName = elem.first_name;
                    item.user.lastName = elem.last_name;
                    item.user.uuid = elem.sub_uuid;
                    item.fullName = elem.first_name + " " + elem.last_name;
                    item.questionTime = elem.question_time;
                    item.questionSubject = elem.question_subject;
                    item.question = elem.question;
                    item.answerTime = elem.answer_time;
                    item.answer = elem.answer;
                    arr.push(item);
                });

                response.data.result = arr;

                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };


    /**
     * Method   - GET
     * Endpoint - /review-help/questions
     * Params   - includeAnswered - include already answered questions in the list
     *          - filter - json filte (for future use)
     * Response - Object
     *
     * Get employee questions
     * Fields:

     *
     * */
    this.fetchEmployeeQuestionHistory = function (includeAnswered, trainingType) {
        var params = [];
        params['includeAnswered'] = includeAnswered;
        params['trainingType'] = trainingType;
        return $http({
            url: API_BASE + "/review-help/questions",
            params: params,
            method: "GET"
        }).then(function (response) {
                var arr = [];
                response.data.list.forEach(function (elem) {
                    var item = new SafetyPlanQuestion();
                    item.questionId = elem.rqu_id;
                    item.user.firstName = elem.first_name;
                    item.user.lastName = elem.last_name;
                    item.user.uuid = elem.sub_uuid;
                    item.fullName = elem.first_name + " " + elem.last_name;
                    item.questionTime = elem.question_time;
                    item.questionSubject = elem.question_subject;
                    item.question = elem.question;
                    item.answerTime = elem.answer_time;
                    item.answer = elem.answer;
                    item.answeredBy = elem.answered_by;
                    arr.push(item);
                });

                response.data.result = arr;

                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };

    this.sendEmployeeQuestionAnswer = function(mySubjectUuid, myNotifcationSubject, myNotificationMessage, myQuestionId) {
        return $http({
            url: API_BASE + "/manage-employee/send-employee-question-answer",
            method: "POST",
            data: {
                subUuid: mySubjectUuid,
                notificationSubject: myNotifcationSubject,
                notificationMessage: myNotificationMessage,
                questionId: myQuestionId
            }
        }).then(function(data) {
            return data;
        }, function(error) {
            return error;
        });
    };


    /**
     * Method   - GET
     * Endpoint - /safety-plan/review-report/get-certificates
     * Response - Object
     *
     * Get employee certificate list for finished reviews
     * Fields:

     *
     * */
    this.fetchEmployeeCertificates = function(type) {
        var params = [];
        params['trainingType'] = type;
        return $http({
            url: API_BASE + "/safety-plan/review-report/get-certificates",
            params: params,
            method: "GET"
        }).then(function(data) {
            var arr = [];
            data.data.forEach(function(elem) {
                var item = new SafetyPlanCertificate();
                item.reviewId = elem.rre_id;
                item.user.firstName = elem.first_name;
                item.user.lastName = elem.last_name;
                item.user.uuid = elem.sub_uuid;
                item.fullName = elem.first_name + " " + elem.last_name;
                item.presentationId = elem.pre_id;
                item.presentationName = elem.pre_name;
                item.presentationDescription = elem.pre_description;
                item.startDate = elem.start_date;
                item.endDate = elem.end_date;
                arr.push(item);
            });
            return {
                list: arr,
                rows: data.data.rows
            };
        }, function(error) {
            return error;
        });
    };

    /**
     * Method   - GET
     * Endpoint - /safety-plan/review-report/generate-certificate
     * Response - Object
     *
     * Generate pdf certificate for employee particular review
     * Fields:

     *
     * */
    this.generateCertificate = function(reviewId, isRscCertificate) {
        var params = [];
        params['reviewId'] = reviewId;
        params['isRscCertificate'] = isRscCertificate ? 1:0;
        return $http({
            url: API_BASE + "/safety-plan/review-report/safety-plan-certificate",
            params: params,
            method: "GET"
        }).then(function(data) {
            return {
                certificatePdf:data
            };
        }, function(error) {
            return error;
        });
    };

    /**
     * Method   - GET
     * Endpoint - /safety-plan/matrix/documents
     * Response - Object
     *
     * Get documents relevant for training matrix
     * Fields:
     * */
    this.fetchTrainingMatrixDocuments = function (trainingType, location_sub_uuid) {
        var params = [];
        params['trainingType'] = trainingType;
        params['locationSubUuid'] = location_sub_uuid;
        return $http({
            url: API_BASE + "/safety-plan/matrix/documents",
            params: params,
            method: "GET"
        }).then(function (response) {
                var arr = [];
                response.data.forEach(function (elem) {
                    var item = new SafetyPlanTrainingDocument();
                    item.documentId = elem.vdo_id;
                    item.documentName = elem.name;
                    arr.push(item);
                });

                if (arr.length > 0)
                    response.data.result = arr;
                else
                    response.data.result = null;
                return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };

    /**
     * Method   - GET
     * Endpoint - safety-plan/matrix/users
     * Response - Object
     *
     * Get training matrix data
     * Fields:
     * */
    this.getTrainingMatrix = function (type, siteLocationUuid, year, filter, isPdf) {
        var params = [];

        params['year'] = year !=null ? year : new Date().getFullYear();

        if (filter != null) {
            var strippedFilter = {};

            if (filter.firstName!= null) {
                strippedFilter.firstName = filter.firstName;
            }

            if (filter.lastName!= null) {
                strippedFilter.lastName = filter.lastName;
            }

            if (filter.occupation!= null) {
                strippedFilter.occupation = filter.occupation;
            }


            if (filter.dueDateAfter!= null) {
                strippedFilter.dueDateAfter = Helpers.formatDbDate(filter.dueDateAfter);
            }

            if (filter.dueDateBefore!= null) {
                strippedFilter.dueDateBefore = Helpers.formatDbDate(filter.dueDateBefore);
            }

            params['filter'] = strippedFilter;
        }


        if (isPdf === true) {
            params['format'] = "pdfphp";
        }

        return $http({
            url: API_BASE + "/" + type + "/matrix/users/"+siteLocationUuid,
            params: params,
            method: "GET"
        }).then(function (response) {
            if (isPdf) {
                response.data.result = response.data.path;
            } else {
                var arr = [];
                response.data.list.forEach(function (elem) {
                    var item = new SafetyPlanTrainingMatrixRecord();
                    item.user.firstName = elem.first_name;
                    item.user.lastName = elem.last_name;
                    item.user.occupation = elem.occupation;
                    item.user.uuid = elem.sub_uuid;
                    item.user.isRsc = elem.is_rsc;
                    item.accountId = elem.account_id;
                    item.documentId = elem.vdo_id;
                    item.trainingStatus = elem.training_status;
                    item.siteLocation = elem.site_location;
                    item.endDate = elem.end_date;
                    item.dueDate = elem.due_date;
                    item.startDate = elem.start_date;
                    item.trainingName = elem.training_name;
                    item.presentationName = elem.presentation_name;
                    arr.push(item);
                });

                if (arr.length > 0) {
                    response.data.result = arr;
                } else {
                    response.data.result = null;
                }
            }

            return response;
            },
            function errorCallback(errorResponse) {
                Notifier.responseMessage(errorResponse.status);
                return $q.reject(errorResponse);
            });
    };

    /**
     * Method   - GET
     * Endpoint - /safety-plan/safety-plan/configmanage-training-matrix/generate-training-matrix-pdf
     * Response - Object
     *
     * Generate training matrix pdf for download for RSC
     * Fields:

     *
     * */
    this.generateTrainingMatrixPdf = function(data, headers) {
        return $http({
            url: API_BASE + "/safety-plan/manage-training-matrix/generate-training-matrix-pdf",
            method: "POST",
            data: {
                dataMatrix: data,
                headers: headers
            }
        }).then(function(data) {
            return {trainingMatrixPdf: data};
        }, function(error) {
            return error;
        });
    };

    /**
     * Method   - GET
     * Endpoint - /safety-plan/review-report/get-rso-contact
     * Response - Object
     *
     * Get employee RSC contact based on employee token and provided location uuid
     * Fields:
     *
     * */
    this.fetchRSOData = function(locationUuid) {
        var params = [];
        params['location'] = locationUuid;
        return $http({
            url: API_BASE + "/safety-plan/review-report/get-rso-contact",
            params: params,
            method: "GET"
        }).then(function(data) {
            var myRso = new User();
            myRso.firstName = data.data['0'].first_name;
            myRso.lastName = data.data['0'].last_name;
            myRso.phone = data.data['0'].phone;
            myRso.fax = data.data['0'].fax;
            myRso.email = data.data['0'].email;
            myRso.siteLocation = data.data['0'].site_location;
            return {rso: myRso};
        }, function(error) {
            return error;
        });
    };

    /**
     * Method   - GET
     * Endpoint - /safety-plan/review-report/get-employee-safety-plan-download-documents
     * Response - Object
     *
     * Get employee Safety Plan documents for download
     * For Documents submodule in employee Safety Plan Section
     * Fields:
     *
     * */
    this.fetchEmployeeSafetyPlanDownloadDocuments = function(trainingType, locationUuid) {
        var params = [];
        params['trainingType'] = trainingType;
        params['locationUuid'] = locationUuid;
        return $http({
            url: API_BASE + "/safety-plan/review-report/get-employee-safety-plan-download-documents",
            params: params,
            method: "GET"
        }).then(function(data) {
            var arr = [];
            data.data.forEach(function(elem) {
                var item = new SafetyPlanTrainingDocument();
                item.documentId = elem.vdo_id;
                item.docDcrNo = elem.doc_dcr_no;
                item.trainingDocumentId = elem.training_type_id;
                item.documentName = elem.title;
                item.documentVersion = elem.version;
                if (elem.date_edited != null) {
                    item.docDateEdited = new Date(Date.parse(elem.date_edited));
                }
                if (elem.valid_until != null) {
                    item.validUntil = new Date(Date.parse(elem.valid_until));
                }
                item.writtenBy = elem.written_by;
                item.documentVariant.docVariantLocation = elem.location;
                item.documentVariant.docVariantPath = elem.doc_path;
                arr.push(item);
            });
            return {
                list: arr,
                rows: data.data.rows
            };
        }, function(error) {
            return error;
        });
    };

    this.addDocument = function(document, trainingType) {
        return $http({
            url: API_BASE+ "/safety-plan/review-report/add-document",
            method: "POST",
            data: {
                locationUuid:   document.locationUuid,
                issueDate:      Helpers.formatDate(document.dateEdited),
                documentPath:   document.uri,
                trainingType,
                validUntil:     Helpers.formatDate(document.validUntil)
            }
        }).then(function(response) {
            return response.data;
        });
    };

    this.removeDocument = function(item) {
        return $http({
            url: API_BASE+ "/safety-plan/review-report/remove-document",
            method: "POST",
            data: {
                documentId:   parseInt(item.documentId)

            }
        }).then(function(response) {
            return response.data;
        });
    }
}
