/* @ngInject */
export function measurementDateRangeController($ngRedux) {
    var ctrl = this;
    ctrl.rangeConfig = [
        {num:"7", label:"Last week"},
        {num:"30", label:"Last month"},
        {num:"90", label:"Last three months"},
        {num:"180", label:"Last six months"},
        {num:"365", label:"Last year"}
    ];

    ctrl.setDateRange = function(numberOfDays) {
        var dateTo = new Date();
        var dateFrom = new Date(dateTo.getTime() - numberOfDays * 24 * 60 * 60 * 1000);

        $ngRedux.dispatch({
            type: 'DATE_RANGE_SELECTED',
            id: ctrl.identifier,
            dateRange: dateFrom,
            formValid: ctrl.form.$valid
        });

        if (ctrl.form.$valid) {
            $ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: ctrl.identifier
            });
        }

        return false;
    }
}
