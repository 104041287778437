import { GridController } from '../../../../../../common/grid/grid.controller';

/* @ngInject */
export function AdministrationAccountsSiteLocationsGridController($controller, $ngRedux, $state, NavigationService, AccountService, AdministrationActions) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        if (!ctrl.admin) ctrl.onRemove = null;
        ctrl.module = 'administration';
        ctrl.identifier = 'administrationAccountsSiteLocationsGrid';
        ctrl.filterOpen = true;
        ctrl.filterTemplate = './administration-accounts-site-locations-grid-filter.html';
        ctrl.exportService = AccountService.getAccounts;

        ctrl.columns = [
            //{title: '#',            mapper: null},
            {title: 'ID',           mapper: 'id'},
            {title: 'Account Name', mapper: 'name'},
            {title: 'Account Number', mapper: 'accountNumber'},
            {title: 'Center Type',  mapper: 'typeName'},
            {title: 'Cost Centre Code', mapper: 'costCentreCode'},
            //{title: 'Create Date',  mapper: 'created'},
            {title: 'Parent',       mapper: 'parentUsername'},
            {title: '',             mapper: 'warning'},
            {title: '',             mapper: 'critical'}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), AdministrationActions)(ctrl);
        ctrl.dataAction = ctrl.getSiteLocations;

        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
