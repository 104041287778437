/** @namespace Reducers.MammoQualityControl */

import { TabsReducer } from '../common/tabs/tabs.reducer';
import { GridReducer } from '../common/grid/grid.reducer';
import { artifactEvaluationTabs } from './mammo-quality-control-tabs';
import merge from 'lodash/merge';
import set from 'lodash/set';
import update from 'lodash/update';

/**
 * @function mammoQualityControlReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function mammoQualityControlReducer(state, action) {

    if (typeof state === 'undefined') {
        return Object.assign({},
            artifactEvaluationTabs,
            {
                mammoQualityControlArtifactEvaluationGrid: merge(GridReducer(), {
                    filter: {
                        measurementType: 'arteva',
                        dateFrom: null,
                        dateTo: null,
                        equipment: null,
                        location: null,
                        equipmentList: null
                    }
                }),
                mammoQualityControlArtifactEvaluationDetails: {
                    title: 'New Artifact evaluation',
                    data: {
                        measurementType: 'arteva',
                        mas: null,
                        kvp: null,
                        location: null,
                        targetFilter: null,
                        comments: null,
                        equipment: null,
                        artifacts: null,
                        artifactsPhantomPosition2: null,
                        commentsPhantomPosition2: null
                    },
                    targetsFilters: [
                        {id: 1, title: 'Mo/Mo'},
                        {id: 2, title: 'Mo/Rh'},
                        {id: 3, title: 'Rh/Rh'}
                    ]
                },
                signalAndContractRatioGrid: merge(GridReducer(), {
                    filter: {
                        measurementType: 'sncnrm',
                        dateFrom: null,
                        dateTo: null,
                        equipment: null,
                        location: null
                    }
                })
            }
        );
    }

    switch (action.type) {
        case 'TAB_SELECT':
            if (action.id === 'app.mammoQualityControl.artifactEvaluation') {
                return Object.assign({}, state, {
                    'app.mammoQualityControl.artifactEvaluation': TabsReducer(state[action.id], action)
                });
            }
        case 'TAB_INIT':
        case 'TAB_OVERRIDE':
        case 'TAB_PARAMS':
        case 'TAB_ENABLE':
        case 'TAB_DISABLE':
        case 'TAB_SHOW':
        case 'TAB_HIDE':
        case 'TAB_LOADING':
            if (action.id === 'app.mammoQualityControl.artifactEvaluation') {
                return Object.assign({}, state, {
                    'app.mammoQualityControl.artifactEvaluation': TabsReducer(state[action.id], action)
                });
            }
        case 'TAB_SWITCH':
            if (action.id === 'app.mammoQualityControl.artifactEvaluation') {
                return Object.assign({}, state, {
                    'app.mammoQualityControl.artifactEvaluation': Object.assign({}, state[action.id], {selectedTabIndex: action.selectedTabIndex, selectedState: action.selectedState})
                });
            }
        case 'LOCATION_SELECTED':
            if (action.id === 'signalAndContractRatioGrid' || action.id === 'mammoQualityControlArtifactEvaluationGrid') {
                return Object.assign({}, state, set({}, action.id, set(Object.assign({}, state[action.id]), 'filter.location', action.uuid)));
            }
            if (action.id === 'mammoQualityControlArtifactEvaluationDetails') {
                return Object.assign({}, state, set({}, action.id, set(Object.assign({}, state[action.id]), 'data.location', action.uuid)))
            }
        case 'TARGET_FILTER_SELECTED':
            if (action.id === 'mammoQualityControlArtifactEvaluationDetails') {
                return Object.assign({}, state, set({}, action.id, set(Object.assign({}, state[action.id]), action.path, action.targetFilter)));
            }
        case 'EQUIPMENT':
            if (action.id === 'signalAndContractRatioGrid') {
                return Object.assign({}, state, set({}, action.id, Object.assign(state[action.id], {equipmentList: action.items})));
            }
            if (action.id === 'mammoQualityControlArtifactEvaluationGrid' || action.id === 'mammoQualityControlArtifactEvaluationDetails') {
                return Object.assign({}, state, set({}, action.id, Object.assign(state[action.id], {equipmentList: action.items})));
            }
        case 'EQUIPMENT_SELECTED':
            if (action.id === 'signalAndContractRatioGrid' || action.id === 'mammoQualityControlArtifactEvaluationGrid') {
                if (action.formValid)
                    return Object.assign({}, state, set({}, action.id, set(GridReducer(state[action.id], {type: 'PAGINATE'}), 'filter.equipment', action.equipment)));
                else
                    return Object.assign({}, state, set({}, action.id, set(Object.assign({}, state[action.id]), 'filter.equipment', action.equipment)));
            }
            if (action.id === 'mammoQualityControlArtifactEvaluationDetails') {
                return Object.assign({}, state, set({}, action.id, set(Object.assign({}, state[action.id]), 'data.equipment', action.equipment)));
            }
        case 'DATE_SELECTED':
            if (action.id === 'signalAndContractRatioGrid' || action.id === 'mammoQualityControlArtifactEvaluationGrid') {
                if (action.formValid)
                    return Object.assign({}, state, set({}, action.id, set(GridReducer(state[action.id], {type: 'PAGINATE'}), action.path, action.input)));
                else
                    return Object.assign({}, state, set({}, action.id, set(Object.assign({}, state[action.id]), action.path, action.input)));
            }
        case 'DATE_RANGE_SELECTED':
            if (action.id === 'signalAndContractRatioGrid') {
                return Object.assign({}, state, {
                    signalAndContractRatioGrid: update(Object.assign({}, state.signalAndContractRatioGrid), 'filter', function (filter) {
                        return Object.assign({}, filter, {
                            dateFrom: new Date(action.dateRange),
                            dateTo: new Date()
                        });
                    })
                });
            }
            if (action.id === 'mammoQualityControlArtifactEvaluationGrid') {
                return Object.assign({}, state, {
                    mammoQualityControlArtifactEvaluationGrid: update(Object.assign({}, state.mammoQualityControlArtifactEvaluationGrid), 'filter', function (filter) {
                        return Object.assign({}, filter, {
                            dateFrom: new Date(action.dateRange),
                            dateTo: new Date()
                        });
                    })
                });
            }
        case 'GRID_INIT':
        case 'GRID_RESET':
            if (action.id === 'signalAndContractRatioGrid') {
                return Object.assign({}, state, {
                    signalAndContractRatioGrid: update(GridReducer(state.signalAndContractRatioGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            dateFrom: null,
                            dateTo: null,
                            equipment: null,
                            location: null
                        });
                    })
                });
            }
            if (action.id === 'mammoQualityControlArtifactEvaluationGrid') {
                return Object.assign({}, state, {
                    mammoQualityControlArtifactEvaluationGrid: update(GridReducer(state.mammoQualityControlArtifactEvaluationGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            dateFrom: null,
                            dateTo: null,
                            equipment: null,
                            location: null,
                            equipmentList: null
                        });
                    })
                });
            }
        case 'SHOW_LOADING':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
            if (action.id === 'signalAndContractRatioGrid') {
                return Object.assign({}, state, {
                    signalAndContractRatioGrid: GridReducer(state.signalAndContractRatioGrid, action)
                });
            }
            if (action.id === 'mammoQualityControlArtifactEvaluationGrid') {
                return Object.assign({}, state, {
                    mammoQualityControlArtifactEvaluationGrid: GridReducer(state.mammoQualityControlArtifactEvaluationGrid, action)
                });
            }
        default:
            return state;
    }
}
