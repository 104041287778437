/**
 * @module root/components/accreditation
 */
import * as STATES          from './accreditation.states';
import { AUDIT_MODULE }     from './audit/audit.module';
import { STANDARD_MODULE } from './standard/standard.module';
import { SETTINGS_MODULE }          from './settings/settings.module';
import { ADD_DOCUMENT_MODULE }      from './add-document/add-document.module';
import { ACC_DOCUMENTS_MODULE }     from './acc-documents/acc-documents.module';
import { MASTER_DOC_LIST_MODULE }   from './master-doc-list/master-doc-list.module';
import {ASSESSMENT_REPORTS_MODULE}  from './assessment-reports/assessment-reports.module';
import { IMPROVEMENT_MODULE }       from './improvement/improvement.module';

import { accDashboardLocationYear}  from './dashboard/acc-location-year.component';
import { publicTask }               from './public-task/public-task.component';
import { addScheme }                from './add-scheme/add-scheme.component';
import { accTasks }                 from './tasks/tasks.component';
import { accTasksGrid }             from './tasks/tasks-grid.component';

export const ACCREDITATION_MODULE = angular.module(
    'migration.accreditation',
    [
        // 'ui.tree',
        'TreeWidget',
        AUDIT_MODULE.name,
        STANDARD_MODULE.name,
        SETTINGS_MODULE.name,
        ADD_DOCUMENT_MODULE.name,
        ACC_DOCUMENTS_MODULE.name,
        MASTER_DOC_LIST_MODULE.name,
        ASSESSMENT_REPORTS_MODULE.name,
        IMPROVEMENT_MODULE.name,
    ]
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values(STATES).map(state => stateRegistry.register(state))
}]).component({
    accDashboardLocationYear,
    addScheme,
    accTasks,
    accTasksGrid,
    publicTask,
}).run(['$templateCache', ({ put }) => {
    put('app.accreditation.add-scheme', '<add-scheme></add-scheme>');
    put('app.accreditation.tasks',      '<acc-tasks></acc-tasks>');
    put('public.accreditation.tasks',   '<acc-tasks></acc-tasks>');

    put('./tasks-grid-filter.html', require('./tasks/tasks-grid-filter.html'));
    put('./tasks-link-dialog.html', require('./tasks/tasks-link-dialog.html'));
}]);
