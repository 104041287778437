import {IDS} from './add-document-action-id.constants';

const QUERY_PARAMS = '?standard&year&slo&token';

export const tabState = {
    name: IDS.app_accreditation_scheme_add_document,
    url: `/:itemCode/add-document${QUERY_PARAMS}`,
    component: 'tabs',
};

export const tabNewDocumentState = {
    name: IDS.app_accreditation_scheme_add_document_new_document,
    url: `/new-document`,
    component: 'accAddNewDocument',
};

export const tabFromExistingState = {
    name: IDS.app_accreditation_scheme_add_document_from_existing,
    url: `/from-existing`,
    component: 'accAddFromExisting',
};