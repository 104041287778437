import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import {getTests} from "../../../radioactive-materials/rm-test/rm-test.actions";
import {getActionTasks} from '../events-action-plan.actions'

class EventsActionTaskGridController extends GridController {
    constructor($ngRedux, $state, NavigationService) {
        /* @ngInject */
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'events';
        this.identifier = 'eventsActionTaskGrid';
        this.icon = false;
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                        mapper: null},
            {title: 'Task ID No.',              mapper: 'tsk_no'},
            {title: 'Task Name',                mapper: 'task_name'},
            {title: 'Responsible Person',       mapper: ({ responsible_person }) => `${responsible_person.first_name} ${responsible_person.last_name}`},
            {title: 'Completion Date',          mapper: 'completion_date'},
            {title: 'Task Status',              mapper: 'progress-bar'},
            {title: '',                         mapper: 'status'}
        ];

        this.buttons = [{label: 'Manage', class: 'btnGridModify', callback: this.onSelect}];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this),
            { getActionTasks }
        )(this);

        this.dataAction = this.getActionTasks;
        this.exportService = this.getActionTasks;
        this.filter.identifier = this.identifier;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const eventsActionTaskGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onRemove: '&'
    },
    controller: EventsActionTaskGridController,
    ...gridTpl
};
