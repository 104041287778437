import moment from 'moment';

/* @ngInject */
export function DepartmentService($http, API_BASE, Helpers, Location, LocationDepartment, LocationDepartmentRso, LocationDepartmentType, Room, RSC, DATE_FORMAT) {
    this.addDepartment = function(department) {

        if (department.email) var email = department.email.toLowerCase();

        return $http({
            url: API_BASE + "/location/department",
            method: "POST",
            data: {
                uuid:           department.parentUuid,
                type:           department.type,
                email:          email,
                phone:          department.phone,
                dosimetry_account_number: department.accountNumber,
                doseUnit:           department.doseUnit,
                serviceProvider:    department.serviceProvider
            }
        }).then(function(response) {
            return {
                id: response.data.id,
                uuid: response.data.uuid,
                created: moment().format('DD-MM-YYYY')
            };
        });
    };

    this.addRoom = function(room) {
        return $http({
            url: API_BASE + "/location/room",
            method: "POST",
            data: {
                country: room.country,
                parent: room.parentUuid,
                name: room.name,
                building: room.building,
                level: room.level,
                number: room.number,
                room_type_id: room.roomType
            }
        }).then(function(response) {
            return response.data.uuid;
        });
    };

    this.getDepartment = function(departmentID) {
        return $http({
            url: API_BASE + "/location/department/" + departmentID,
            method: "GET"
        }).then(function(data) {
            var location    = new Location();
            var dept        = data.data[0][0];
            var rso         = data.data[1];

            location.uuid           = departmentID;
            location.created        = moment(dept.created_time).format('DD-MM-YYYY');
            location.parentUuid     = dept.slo_uuid;
            location.accountId      = dept.account_id;
            location.name           = dept.type;
            location.typeName       = dept.type;
            location.type           = dept.type_id;
            location.email          = dept.email;
            location.phone          = dept.phone;
            location.accountNumber  = dept.dosimetry_account_number;
            location.rsc = [];

            location.doseUnit = dept.dose_unit;
            location.serviceProvider = dept.service_provider;

            if (rso.length == 0)
                return location;

            rso.forEach(function(element) {
                var newRsc = new RSC();
                newRsc.first_name   = element.first_name;
                newRsc.last_name    = element.last_name;
                newRsc.email        = element.email;
                newRsc.phone        = element.phone;
                newRsc.fax          = element.fax;
                newRsc.gender       = element.gender;
                newRsc.username     = element.username;
                newRsc.uuid         = element.subject_uuid;
                location.rsc.push(newRsc);
            });
            return location;
        });
    };

    this.updateDepartment = function(department) {

        if (department.email) var email = department.email.toLowerCase();

        return $http({
            url: API_BASE + "/location/sitelocation/department/update",
            method: "POST",
            data: {
                uuid:           department.uuid,
                type:           department.type,
                email:          email,
                phone:          department.phone,
                dosimetry_account_number: department.accountNumber,
                doseUnit:           department.doseUnit,
                serviceProvider:    department.serviceProvider
            }
        }).then(function(response) {
            return response;
        });
    };

    this.getRoom = function(roomUUID) {
        return $http({
            url: API_BASE + "/location/room/" + roomUUID,
            method: "GET"
        }).then(function(response) {
            var room            = response.data[0][0];
            var location        = new Room();
            location.uuid       = roomUUID;
            location.parentUuid = room.dep_uuid;
            location.accountId  = room.account_id;
            location.name       = room.name;
            location.number     = room.number;
            location.building   = room.building;
            location.level      = room.level;
            location.phone      = room.phone;
            location.email      = room.email;
            location.created    = Helpers.formatDate(room.created_time, DATE_FORMAT.INPUT);
            location.roomType   = room.type_id;
            return location;
        });
    };
}
