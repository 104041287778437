class ReportingIsotopesActivityController {
    constructor($rootScope) {
        this.$rootScope = $rootScope;
    }

    onGridReset() {
        this.$rootScope.$broadcast('SELECT_CASCADE_RESET', 'reportingIsotopesActivityFilterForm');
    }
}

ReportingIsotopesActivityController.$inject = ['$rootScope'];

export const reportingIsotopesActivity = {
    controller: ReportingIsotopesActivityController,
    template: `
        <reporting-isotopes-activity-grid on-reset="$ctrl.onGridReset()"></reporting-isotopes-activity-grid>
    `
};
