/* @ngInject */
export function VendorAddController($controller, $scope, $rootScope, $q, $timeout, Notifier, VendorService, VendorManager, VendorHeadOffice, VendorSiteLocation, VendorManagerUser, Grid, GENDERS, COUNTRY_SELECT_EVENT) {

    angular.extend(this, $controller('VendorBaseController', {
        $scope: $scope,
        $rootScope: $rootScope,
        $q: $q,
        $timeout: $timeout,
        Notifier: Notifier,
        VendorService: VendorService,
        VendorManager: VendorManager,
        VendorHeadOffice: VendorHeadOffice,
        VendorSiteLocation: VendorSiteLocation,
        VendorManagerUser: VendorManagerUser,
        Grid: Grid,
        GENDERS: GENDERS,
        COUNTRY_SELECT_EVENT: COUNTRY_SELECT_EVENT
    }));

    $scope.MODULE_STATE = $scope.MODULE_STATE_CREATE;
    $scope.VENDOR_STATE = $scope.MODULE_STATE_CREATE;

    $scope.tabs = _.cloneDeep(VendorManager.tabs);
    $scope.tabs.shift();

    $scope.gridSiteLocations.items = $scope.headOffice.siteLocations;

    VendorService.getVendorContactPositions('vhm').then(function(positions) {
        $scope.positions = positions;
    });

    _.extend(_.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_DETAILS]), {
        exec: function() {
            var deferred = $q.defer();
            switch ($scope.VENDOR_STATE) {
                case $scope.MODULE_STATE_CREATE:
                    VendorService.addVendorHeadOffice($scope.headOffice).then(function(data) {
                        $scope.headOffice.uuid = data.hof_uuid;
                        $scope.headOffice.manager.uuid = data.hrso_uuid;
                        $scope.headOffice.manager.username = data.hrso_username;
                        $scope.VENDOR_STATE = $scope.MODULE_STATE_UPDATE;
                        Notifier.success('Vendor successfully added');
                        deferred.resolve();
                    }, function(error) {
                        deferred.reject(error);
                    });
                    break;
                case $scope.MODULE_STATE_UPDATE:
                    VendorService.editVendorHeadOffice($scope.headOffice).then(function() {
                        Notifier.success('Vendor details successfully updated');
                        deferred.resolve();
                    });
                    break;
            }
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SERVICES]), {
        exec: function() {
            var deferred = $q.defer();
            $timeout(function() {
                deferred.resolve();
            }, 200);
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', VendorManager.TAB_ADD_SITE_LOCATION]), {
        exec: (function() {
            var deferred = $q.defer();
            if ($scope.manager.siteLocationManagerExisting.uuid) $scope.manager.siteLocationManager = $scope.manager.siteLocationManagerExisting;
            VendorService.addVendorSiteLocation($scope.headOffice.uuid, $scope.siteLocation, $scope.manager.siteLocationManager).then((function(data) {
                this.addSiteLocation(data);
                $scope.selectedTab.form.$setPristine();
                $scope.selectedTab.form.$setUntouched();
                deferred.resolve(VendorManager.TAB_VENDOR_SITE_LOCATIONS);
            }).bind(this));
            return deferred.promise;
        }).bind(this)
    });

    _.extend(_.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATIONS]), {
        exec: function() {
            var deferred = $q.defer();
            $timeout(function() {
                deferred.resolve();
            }, 200);
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_DETAILS]), {
        exec: (function() {
            var deferred = $q.defer();
            this.updateSiteLocation().then((function() {
                deferred.resolve();
            }, function(error) {
                deferred.reject(error);
            }).bind(this));
            return deferred.promise;
        }).bind(this)
    });

    _.extend(_.find($scope.tabs, ['id', VendorManager.TAB_VENDOR_SITE_LOCATION_MANAGER]), {
        exec: (function() {
            var deferred = $q.defer();
            switch ($scope.MANAGER_STATE) {
                case $scope.MODULE_STATE_CREATE:
                    if ($scope.manager.newManager) {
                        VendorService.addVendorSiteLocationManager($scope.siteLocation.uuid, $scope.manager.siteLocationManager).then((function(data) {
                            $scope.manager.siteLocationManager.uuid = data.vsm_uuid;
                            $scope.siteLocation.addManager(_.cloneDeep($scope.manager.siteLocationManager));
                            this.addManagerCandidate(_.cloneDeep($scope.manager.siteLocationManager));
                            $scope.manager.siteLocationManager = new VendorManagerUser();
                            $scope.manager.siteLocationManagerExisting = new VendorManagerUser();
                            deferred.resolve(VendorManager.TAB_VENDOR_SITE_LOCATION_DETAILS - 1);
                        }).bind(this));
                    }
                    else {
                        if ($scope.manager.siteLocationManagerExisting.uuid) $scope.manager.siteLocationManager = $scope.manager.siteLocationManagerExisting;
                        $scope.manageSiteLocationManager($scope.manager.siteLocationManager).then(function() {
                            $scope.manager.siteLocationManager = new VendorManagerUser();
                            $scope.manager.siteLocationManagerExisting = new VendorManagerUser();
                            deferred.resolve(VendorManager.TAB_VENDOR_SITE_LOCATION_DETAILS - 1);
                        });
                    }
                    break;
                case $scope.MODULE_STATE_UPDATE:
                    VendorService.editVendorSiteLocationManager($scope.manager.siteLocationManager).then(function() {
                        Notifier.success('Vendor Site Location Manager successfully updated');
                        deferred.resolve(VendorManager.TAB_VENDOR_SITE_LOCATION_MANAGER - 1);
                    });
                    break;
            }
            return deferred.promise;
        }).bind(this)
    });

    VendorService.getVendorServiceTypes().then(function(data) {
        $scope.vendorServices = data;
    });
}
