import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';

/**
 * Creates a new AdministrationNotificationSettingsTimelineGridController.
 * @class
 * @memberOf module:root/legacy/components/administration
 */
class AdministrationNotificationSettingsTimelineGridController extends GridController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param NavigationService {service} {@link module:root/common.NavigationService|NavigationService}
     * @param AdministrationNotificationSettingsActions {service}
     */
    constructor($ngRedux, $state, NavigationService, AdministrationNotificationSettingsActions) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.actions = AdministrationNotificationSettingsActions;
    }

    /**
     * @method $onInit
     * @memberof module:root/legacy/components/administration.AdministrationNotificationSettingsTimelineGridController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onInit}
     */
    $onInit() {
        this.module = 'administration';
        this.filterComponent = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                        mapper: null},
            {title: 'Subject Type',             mapper: 'subject_type'},
            {title: 'Days',                     mapper: 'days'},
            {title: 'Repeat Frequency',         mapper: 'repeat_frequency'},
            {title: 'Repeat From',              mapper: 'repeat_from'}
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.actions)(this);
        this.dataAction = this.getNotificationSettingsTimelineList;

        this.init();
    }

    /**
     * @method $onDestroy
     * @memberof module:root/components/administration.AdministrationNotificationSettingsTimelineGridController
     * @description Component lifecycle hook
     * @see {@link https://docs.angularjs.org/guide/component#component-based-application-architecture|AngularJS component lifecycle hook $onDestroy}
     */
    $onDestroy() {
        this.destroy();
    };
}

AdministrationNotificationSettingsTimelineGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'AdministrationNotificationSettingsActions'];

export const administrationNotificationSettingsTimelineGrid = {
    bindings: {
        identifier: '@',
        title: '@',
        onRemove: '&'
    },
    controller: AdministrationNotificationSettingsTimelineGridController,
    ...gridTpl
};
