
export const IDS = {
    // modules
    pnpAccred:               'pnpAccred',

    // components
    pnpAccredList:              'pnpAccredList',
    pnpAccredListGrid:          'pnpAccredListGrid',
    pnpAccredDetails:           'pnpAccredDetails',
    pnpAccredDetailsGrid:       'pnpAccredDetailsGrid',
    pnpAccredVersionsGrid:      'pnpAccredVersionsGrid',
    pnpAccredVersionDetails:    'pnpAccredVersionDetails',

    // tab states:
    app_pnp_accred:             'app.pnp.accred',
    app_pnp_accred_list:        'app.pnp.accred.list',
    app_pnp_accred_details:     'app.pnp.accred.details',
    app_pnp_add_documents:      'app.pnp.add-documents',
};
