import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';

/**
 * Creates a new InputDocumentsController.
 * @class
 */
class InputDocumentsController {
    /**
     * @constructor
     * @param $ngRedux
     * @param DocumentManager
     * @param Notifier
     */
    constructor($ngRedux, DocumentManager, Notifier) {
        this.$ngRedux = $ngRedux;
        this.DocumentManager = DocumentManager;
        this.Notifier = Notifier;
        this.unsubscribe = () => {};
        this.list = false;
    }

    $onInit() {
        if (isUndefined(this.identifier))
            throw new Error('Missing input-documents identifier attribute');

        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        if (this.statePath) this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);
    }

    open = () => {
        this.list = !this.list;
    }

    onRemove = (idx) => {
        this.$ngRedux.dispatch({
            id: 'eventItem',
            type: 'INPUT_DOCUMENTS_REMOVE',
            statePath: this.statePath,
            idx,
        });
        this.list = this.documents.length !== 0;
        this.Notifier.success('Document has been successfully deleted.');
    };

    onDownload = (doc) => {
        this.DocumentManager.downloadFile(doc.url, 4);
    }

    $onDestroy() {
        this.unsubscribe();
        this.unsubscribeModule();
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        };
    }

    mapState(state) {
        return {
            documents: get(state[this.module][this.identifier], this.statePath)
        }
    }
}

InputDocumentsController.$inject = ['$ngRedux', 'DocumentManager', 'Notifier'];

export const inputDocuments = {
    bindings: {
        identifier: '@',
        statePath: '@',
        download: '<',
        disabled: '<'
    },
    require : {
        form : '^form'
    },
    controller: InputDocumentsController,
    template: `
        <div layout="column">
            <div layout-gt-xs="row">
                <div layout="row" layout-align-xs="center center" layout-align-sm="center center">
                    <md-button class="btnDefault" ng-click="$ctrl.open()" ng-disabled="!$ctrl.documents.length">
                        <asset src="documents-gray.svg"></asset>Documents ({{$ctrl.documents.length}})
                    </md-button>
                </div>
                <file-upload flex
                             dropzone="true"
                             input-id="uploader"
                             label="Add Document"
                             identifier="{{$ctrl.identifier}}"
                             state-path="{{$ctrl.statePath}}"
                             disabled="$ctrl.disabled">
                </file-upload>
            </div>
            <div ng-if="$ctrl.list" layout="column" layout-padding md-whiteframe="1">
                <div layout="row" class="noMargin" flex ng-repeat="doc in $ctrl.documents">
                    <asset cls="input-icon" src="documents-black.svg"></asset>
                    <md-input-container class="md-block" flex>
                        <input type="text" value="{{doc.url}}" ng-disabled="true" aria-label="document{{$index}}">
                    </md-input-container>
                    <asset ng-if="$ctrl.download" cls="input-icon-download" src="download_black.svg" ng-click="$ctrl.onDownload(doc)"></asset>
                    <asset cls="input-icon-remove" src="input-remove.svg" ng-click="$ctrl.onRemove($index)"></asset>
                </div>
            </div>
        </div>
    `
};
