import watch from 'redux-watch';
import Chart from 'chart.js';
import SignaturePad from 'signature_pad';
import { verifyAccess } from '../../../personal-dosimetry.utils';
import moment from 'moment';
import find from 'lodash/find';

export const personalDosimetryReportsDetailsResult = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $uiRouterGlobals, NavigationService, PersonalDosimetryActions, $filter, $mdDialog, Notifier) {
        this.$onInit = () => {
            this.verifyAccess = verifyAccess;
            const navConfig = NavigationService.getConfig($state.$current);
            this.colorName = navConfig.colorName;
            this.icon = navConfig.icon;

            this.tabsState = $state.$current.parent.name;
            switch (this.tabsState) {
                case 'app.personalDosimetry.import':
                    this.currentState = 'app.personalDosimetry.import.detailsResult';
                    break;
                case 'app.personalDosimetry.reports':
                    this.currentState = 'app.personalDosimetry.reports.detailsResult';
                    break;
            }

            this.unsubscribe = $ngRedux.connect(({ app, personalDosimetry }) => ({
                user: app.user,
                data: personalDosimetry.personalDosimetryReportsDetailsResult,
                participant: personalDosimetry.personalDosimetryParticipant,
            }), PersonalDosimetryActions)(this);

            this.chartType = null;

            if (this.data.id) {
                createChart('current');
            } else {
                const watchResultId = watch($ngRedux.getState, 'personalDosimetry.personalDosimetryReportsDetailsResult.id');
                this.onResultId = $ngRedux.subscribe(watchResultId((id) => {
                    id && createChart('current');
                }));
            }
        };

        this.getClass = (prop) => {
            if (Array.isArray(this.data.measurement_result)) {
                const result = find(this.data.measurement_result, prop);
                if (result) {
                    return result[prop];
                }
            }
            return '';
        };

        this.dosimetryBadges = () => {
            $state.go('app.employees.manage', { uuid: this.data.participantUuid, tab: 6 });
        };

        this.download = () => {
            if (this.data.statusCode !== 'ack') {
                const confirm = $mdDialog.confirm()
                    .title('Acknowledgement')
                    .textContent('You are obliged to sign the document download.')
                    .ariaLabel('Acknowledgement')
                    .ok('Agree')
                    .cancel('Cancel');
                this.dialog = $mdDialog.show(confirm).then(sign, angular.noop);
            } else {
                this.generateParticipantReport(
                    $uiRouterGlobals.params.measurement,
                    this.data.signature,
                    this.data.participantNum,
                );
            }
        };

        const sign = () => {
            this.dialog = $mdDialog.show({
                controller: function (scope, ctrl) {
                    scope.clear = () => scope.signaturePad.clear();
                    scope.save = () => {
                        if (scope.signaturePad.isEmpty()) {
                            Notifier.error('Signature can not be empty!');
                        } else {
                            ctrl.signDoseReport(
                                $uiRouterGlobals.params.measurement,
                                scope.signaturePad.toDataURL(),
                            ).then((res) => {
                                if (res) {
                                    $mdDialog.hide();
                                    ctrl.generateParticipantReport(
                                        $uiRouterGlobals.params.measurement,
                                        scope.signaturePad.toDataURL(),
                                        ctrl.data.participantNum,
                                    );
                                    ctrl.getIndividualDoseReport(
                                        $state.$current.parent.name,
                                        $uiRouterGlobals.params.measurement,
                                    );
                                }
                            });
                        }
                    };
                },
                onComplete: (scope, element) => {
                    const canvas = element.find('canvas')[0];
                    scope.signaturePad = new SignaturePad(canvas);
                },
                locals: { ctrl: this },
                template: require('../../../../../common/form/input/input-signature/input-signature-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
            }).then(angular.noop, angular.noop);
        };

        this.displayChart = (type) => {
            createChart(type);
        };

        this.getChartClass = (type) => {
            return type === this.chartType ? 'active' : '';
        };

        const createChart = (type) => {
            this.chartType = type;
            const dose_unit = this.data.providerCode === 'pdoarp' ? 'μSv' : 'mSv';
            const legend = {
                current: {
                    title: 'Current Dose',
                    value: this.data.currentDDE,
                    valMax: this.data.monthlyThresholdLimit,
                    ticks: {
                        min: 0,
                        max: this.data.monthlyAbsoluteLimit < 4 ? 4 : this.data.monthlyAbsoluteLimit,
                        stepSize: this.data.monthlyAbsoluteLimit < 4 ? 1: Math.round(this.data.monthlyAbsoluteLimit / 4),
                    },
                    lblAverage: `Average Current Dose (${dose_unit})`,
                    lblLimit: `Current Dose Threshold Limit (${dose_unit})`,
                    lblAbsoluteLimit: `Current Dose Absolute Limit (${dose_unit})`,
                },
                quarter: {
                    title: 'Quarter Dose',
                    value: this.data.quarterDDE,
                    valMax: this.data.quarterlyThresholdLimit,
                    ticks: {
                        min: 0,
                        max: this.data.quarterlyAbsoluteLimit < 4 ? 4 : this.data.quarterlyAbsoluteLimit,
                        stepSize: this.data.quarterlyAbsoluteLimit < 4 ? 1: Math.round(this.data.quarterlyAbsoluteLimit / 4),
                    },
                    lblAverage: `Average Quarter Dose (${dose_unit})`,
                    lblLimit: `Quarter Dose Threshold Limit (${dose_unit})`,
                    lblAbsoluteLimit: `Quarter Dose Absolute Limit (${dose_unit})`,
                },
                year: {
                    title: '12-Month Dose',
                    value: this.data.yearDDE,
                    valMax: this.data.yearlyThresholdLimit,
                    ticks: {
                        min: 0,
                        max: this.data.yearlyAbsoluteLimit < 4 ? 4 : this.data.yearlyAbsoluteLimit,
                        stepSize: this.data.yearlyAbsoluteLimit < 4 ? 1: Math.round(this.data.yearlyAbsoluteLimit / 4),
                    },
                    lblAverage: `Average Yearly Dose (${dose_unit})`,
                    lblLimit: `Yearly Dose Threshold Limit (${dose_unit})`,
                    lblAbsoluteLimit: `Yearly Dose Absolute Limit (${dose_unit})`,
                },
                life: {
                    title: 'Life Dose',
                    value: this.data.lifeDDE,
                    valMax: this.data.yearlyThresholdLimit,
                    ticks: {
                        min: 0,
                        max: this.data.yearlyAbsoluteLimit < 4 ? 4 : this.data.yearlyAbsoluteLimit,
                        stepSize: this.data.yearlyAbsoluteLimit < 4 ? 1: Math.round(this.data.yearlyAbsoluteLimit / 4), // 4,
                    },
                    lblAverage: `Average Life Dose (${dose_unit})`,
                    lblLimit: `Life Dose Threshold Limit (${dose_unit})`,
                    lblAbsoluteLimit: `Life Dose Absolute Limit (${dose_unit})`,
                }
            };
            const valueDde = (legend[type].value === 'M') ? 0.01 : legend[type].value;
            const valueDl = (this.data.use === 'FOETAL' || this.data.use === 'FM') ? 1 : legend[type].valMax;
            if (window.doseChart instanceof Chart) {
                window.doseChart.destroy();
            }
            const ctx = document.getElementById('doseChart').getContext('2d');
            window.doseChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: [
                        legend[type].lblAverage + ((legend[type].value === 'M') ? ' < 0.01' : ' = ' + legend[type].value),
                        legend[type].lblLimit + ' = ' + valueDl,
                    ],
                    datasets: [{
                        label: legend[type].title,
                        data: [valueDde, valueDl],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: legend[type].lblAbsoluteLimit,
                            },
                            ticks: legend[type].ticks,
                        }]
                    }
                }
            });
        };

        this.sendReportNotification = () => {
            this.dialog = $mdDialog.show({
                controller: function (scope, ctrl) {
                    scope.search = async (keyword) => {
                        return await ctrl.searchEmployees(keyword);
                    };
                    scope.send = () => {
                        if (scope.pdParticipantEmailForm.$valid) {
                            $mdDialog.hide();
                            ctrl.sendNotification(
                                ctrl.data.imp_id,
                                ctrl.data.id,
                                ctrl.participant.participantEmail,
                            ).then(() => {
                                ctrl.getIndividualDoseReport(
                                    $state.$current.parent.name,
                                    ctrl.data.id,
                                );
                            });
                        } else {
                            scope.pdParticipantEmailForm.participantEmail.$setTouched();
                        }
                    };
                    scope.cancel = $mdDialog.cancel;
                },
                locals: { ctrl: this },
                template: require('./dialogs/pd-participant-email-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                onShowing: () => {
                    if (this.data.participantEmail) {
                        $ngRedux.dispatch({
                            type: 'PARTICIPANT_EMAIL',
                            id: 'personalDosimetryParticipant',
                            email: this.data.participantEmail,
                        });
                    }
                },
            }).then(
                () => $ngRedux.dispatch({ type: 'PARTICIPANT_RESET' }),
                () => $ngRedux.dispatch({ type: 'PARTICIPANT_RESET' }),
            );
        }

        this.$onDestroy = () => {
            this.unsubscribe && this.unsubscribe();
            this.onResultId && this.onResultId();
            this.dialog && $mdDialog.hide(this.dialog);
        };
    },
    template: require('./pesonal-dosimetry-reports-details-result.html')
};
