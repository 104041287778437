import { systemState, systemDashboardState } from './system.states';
import { systemWelcomeState } from './welcome/system-welcome.states';
import { systemAssignmentsState } from './assignments/system-assignments.states';
import { systemAlertsState, systemAlertsListingState } from './alerts/system-alerts.states';
import { systemActivitiesState } from './activities/system-activities.states';
import { systemAssistantState } from './assistant/system-assistant.states';
import { systemSettingsState } from './settings/system-settings.states';
import { system } from './system.component';
import { systemAlerts } from './alerts/system-alerts.component';
import { systemAlertsGrid } from './alerts/grid/system-alerts-grid.component';
import SystemAlertsActions from './alerts/system-alerts.actions';

export const SYSTEM_MODULE = angular.module(
    'migration.system',
    []
).config(['$uiRouterProvider', $uiRouter => {
    const $stateRegistry = $uiRouter.stateRegistry;
    $stateRegistry.register(systemState);
    $stateRegistry.register(systemDashboardState);
    $stateRegistry.register(systemWelcomeState);
    $stateRegistry.register(systemAssignmentsState);
    $stateRegistry.register(systemAlertsState);
    $stateRegistry.register(systemAlertsListingState);
    $stateRegistry.register(systemActivitiesState);
    $stateRegistry.register(systemAssistantState);
    $stateRegistry.register(systemSettingsState);
}]).service({
    SystemAlertsActions
}).component({
    system,
    systemAlerts,
    systemAlertsGrid
}).run(['$templateCache', $templateCache => {
    $templateCache.put('app.system.alerts.listing', '<system-alerts></system-alerts>');
    $templateCache.put('./system-alerts-grid-filter.html', require('./alerts/grid/system-alerts-grid-filter.html'));
}]);
