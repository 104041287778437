export function UserCredentialsFactory() {
    function UserCredentials() {
        this.username = null;
        this.password = null;
        this.passwordVerify = null;
    }

    UserCredentials.prototype.reset = function() {
        this.password = null;
        this.passwordVerify = null;
    };

    return UserCredentials;
}
