import { TAB_HIDE } from '../../../common/tabs/tabs.constants';
import {eventsSafetyRiskAddAssessmentHazardState} from "../safety-risk/events-safety-risk.states";

export const eventsRadiationRiskAddState = {
    name: 'app.events.addRadiationRisk',
    redirectTo: 'app.events.addRadiationRisk.details',
    url: '/add',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            id: 'eventItem',
            type: 'EVENT_RESET'
        });
        $ngRedux.dispatch({
            id: 'app.events.addRadiationRisk',
            type: TAB_HIDE,
            state: [
                'app.events.addRadiationRisk.assessmentHazard',
                'app.events.addRadiationRisk.assessmentIncident',
                'app.events.addRadiationRisk.assessmentHistoricalRecord',
                'app.events.addRadiationRisk.alertEscalate',
                'app.events.addRadiationRisk.actionPlan',
                'app.events.addRadiationRisk.actionPlanLibrary',
                'app.events.addRadiationRisk.actionTaskLibrary'
            ]
        });
    }
};

export const eventsRadiationRiskAddDetailsState = {
    name: 'app.events.addRadiationRisk.details',
    url: '?id',
};

export const eventsRadiationRiskAddAssessmentHazardState = {
    name: 'app.events.addRadiationRisk.assessmentHazard',
    url: '/assessment?id',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            id: 'app.events.addRadiationRisk',
            type: TAB_HIDE,
            state: ['app.events.addRadiationRisk.assessmentHistoricalRecord']
        });
    }
};

export const eventsRadiationRiskAddAssessmentHistoricalRecordState = {
    name: 'app.events.addRadiationRisk.assessmentHistoricalRecord',
    url: '/assessment/historical-record?id',
};

export const eventsRadiationRiskAddAssessmentIssueState = {
    name: 'app.events.addRadiationRisk.assessmentIncident',
    url: '/assessment?id',
};

export const eventsRadiationRiskAddAlertEscalateState = {
    name: 'app.events.addRadiationRisk.alertEscalate',
    url: '/alert-escalate?id',
};

export const eventsRadiationRiskAddActionPlanState = {
    name: 'app.events.addRadiationRisk.ActionPlan',
    url: '/action-plan?id',
};

export const eventsRadiationRiskAddActionPlanLibraryState = {
    name: 'app.events.addRadiationRisk.ActionPlanLibrary',
    url: '/action-plan-library',
    /* @ngInject */
    onExit: ($ngRedux) => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: 'app.events.addRadiationRisk',
            state: ['app.events.addRadiationRisk.actionPlanLibrary']
        });
    }
};

export const eventsRadiationRiskAddActionTaskLibraryState = {
    name: 'app.events.addRadiationRisk.ActionTaskLibrary',
    url: '/action-task-library',
    /* @ngInject */
    onExit: ($ngRedux) => {
        $ngRedux.dispatch({
            type: TAB_HIDE,
            id: 'app.events.addRadiationRisk',
            state: ['app.events.addRadiationRisk.ActionTaskLibrary']
        });
    }
};
