/* @ngInject */
export function SafetyPlanManageEmployeeQuestionsController($scope, $state, $filter, $rootScope, $q, $location, $document, Notifier, SafetyPlanService) {

    $scope.cursorState = 'wait';

    $scope.currentPage = 1;
    $scope.pageSize = 10;

    $scope.selectedItem = null;

    $scope.answerSubject = null;
    $scope.answer = null;
    $scope.showHistory = "pending";
    $scope.questions = [];

    $scope.trainingName = $state.current.trainingName;

    $scope.selectItem = function (item) {
        $scope.selectedItem = item;
        $scope.answerSubject = "Re: " + item.questionSubject;
    };

    $scope.fetchData = function () {
        $scope.answerSubject = null;
        $scope.answer = null;
        $scope.selectedItem = null;

        $scope.cursorState = 'wait';

        var includeHistory = 0;
        if ($scope.showHistory === "history") {
            includeHistory = 1;
        }

        SafetyPlanService.fetchEmployeesQuestions(includeHistory, $state.current.trainingType)
            .then(function (response) {
                $scope.questions = response.data.result;
            });
    };

    $scope.sendAnswer = function (item) {
        $scope.cursorState = 'wait';
        SafetyPlanService.sendEmployeeQuestionAnswer(item.user.uuid, $scope.answerSubject, $scope.answer, item.questionId)
            .then(function (data) {
                $scope.cursorState = 'default';
                if (data.status == 200 && data.data.status == 0) {
                    Notifier.success($filter('translate')("SAFETY_PLAN.MESSAGE_SENT_TO_USER"));
                    $scope.fetchData();
                } else {
                    Notifier.error($filter('translate')("SAFETY_PLAN.ERROR_SENDING_MESSAGE"));
                }
            }, function (error) {
                $scope.cursorState = 'default';
                Notifier.error($filter('translate')("SAFETY_PLAN.ERROR_SENDING_MESSAGE"));
            });
    };

    $scope.fetchData();
}
