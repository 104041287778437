import {TYPES} from './assessment-reports-action-type.constants';
import {IDS} from './assessment-reports-action-id.constants';
import * as TABS from '../../../common/tabs/tabs.constants';
import {TabsReducer} from '../../../common/tabs/tabs.reducer';
import * as FORM from '../../../common/form/input/input.constants';
import {GridReducer} from '../../../common/grid/grid.reducer';
import {GridFilterReducer} from '../../../common/grid/filter/grid-filter.reducer';
import {getAvailableYearsRange} from '../../../reducers/accreditation.reducer';
import * as GRID from '../../../common/grid/grid.constants';
import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import moment from 'moment'
import {INPUT_TEXT} from "../../../common/form/input/input.constants";

/**
 * @function AssessmentReportsReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function AssessmentReportsReducer(state, action) {
    if (typeof state === 'undefined') {
        return {
            siteLocationUuid: null,
            siteLocationName: null,

            documentDetails:{
                assessmentItem: null,
                document:       null,
                review:         null,
            },
            accreditationBodiesOptions: [],
            documentAdd: {
                acc_body_type: null,
            },

            accAssessmentReportsGrid: GridReducer(),
            documentReviewsGrid: GridReducer(),
        }
    }

    switch (action.type) {
        case TYPES.FETCH_SITE_LOCATION_DETAILS:
            if (action.id === IDS.accAssessmentReports) {
                return { ...state, ...action.data }
            }
        case TYPES.FETCH_SCHEME_DETAILS:
            if (action.id === IDS.accAssessmentReports) {
                return { ...state, ...action.data }
            }
        case TYPES.FETCH_DOCUMENT_DETAILS:
            if (action.id === IDS.accAssessmentReports) {
                return {...state,
                    documentDetails: action.details,
                    documentReviewsGrid: {
                        ...state.documentReviewsGrid,
                        items: action.reviews,
                        itemsCount: action.reviews.length
                    }
                }
            }

        case TYPES.FETCH_ACC_BODIES_OPTIONS:
            if(action.id === IDS.accAssessmentReports) {
                state = {...state, accreditationBodiesOptions: action.options };
                return set(state, 'documentAdd.acc_body_type', action.options[0].value);
            }

        /* GENERIC TYPES */

        case 'LOCATION_SELECTED':
            if(action.id === IDS.accAssessmentReports) { // slo select-cascade
                if(action.type_code === 'slo') {
                    return {...state, siteLocationUuid: action.uuid}
                }
                else return state;
            }
        case 'AUTOCOMPLETE_SELECTED':
            if (action.id === IDS.accAssessmentReports && action.item) {
                return set({...state}, action.path, action.item)
            }
        case FORM.INPUT_SELECT:
        case FORM.INPUT_TEXT:
        case FORM.INPUT_CHECKBOX:
        case FORM.INPUT_DATE:
        case FORM.INPUT_RADIO:
        case 'INPUT_EMAIL_CHANGE':
        case 'INPUT_PHONE_CHANGE':
        case 'INPUT_TEXT_AREA_CHANGE':
        case 'INPUT_SIGNATURE':
            if (action.id === IDS.accAssessmentReports) { //selectedYear
                const gridId = action.path.split('.')[0];
                if([IDS.accAssessmentReportsGrid].includes(gridId)) {
                    const filter = {
                        [action.path.split('.').pop()]: action.input
                    };
                    return set({...state}, gridId,
                        GridReducer(get(state, gridId), { type: GRID.GRID_FILTER, filter })
                    )
                }
                else
                    return set({...state}, action.path, action.input)
            }
        case GRID.GRID_INIT:
        case GRID.GRID_FILTER:
        case GRID.GRID_FILTER_TOGGLE:
        case GRID.GRID_RESET_FILTER:
        case GRID.GRID_INIT_FILTER:
        case GRID.GRID_LOADING:
        case GRID.GRID_PAGINATE:
        case GRID.GRID_RELOAD:
        case GRID.GRID_FETCH:
        case GRID.GRID_SELECT:
        case GRID.GRID_SELECT_ALL:
        case GRID.GRID_DESELECT:
        case GRID.GRID_DESELECT_ALL:
            if (action.id === IDS.accAssessmentReports) {
                return set({...state}, action.path,
                    GridReducer( get(state, action.path), action)
                )
            }
        case GRID.GRID_RESET:
            if (action.id === IDS.accAssessmentReports) {
                return set({...state}, action.path,
                    GridReducer( get(state, action.path),{
                        type: GRID.GRID_RESET_FILTER,
                        exclude: action.exclude || (action.path === IDS.documentAddGrid ? ['slo_uuid','excludeDocIds'] : []),
                    })
                )
            }
        default:
            return state;
    }
}

const index = (children, list = []) => {
    children && children.forEach(item => {
        if(item) {
            list[parseInt(item.id)] = item;
            index(item.children, list);
        }
    })
    return list;
};
