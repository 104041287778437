import { GridController } from "../../../../../common/grid/grid.controller";

/* @ngInject */
export function ProtectiveEquipmentHistoryGridController($controller, $ngRedux, $state, NavigationService, ProtectiveEquipmentActions) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'protectiveEquipment';
        ctrl.identifier = 'protectiveEquipmentHistoryGrid';
        ctrl.autoload = false;
        ctrl.filterComponent = false;
        ctrl.exportComponent = false;

        ctrl.columns = [
            {title: '#',                mapper: null},
            {title: 'Date and Time',    mapper: 'getDate'},
            {title: 'Activity Type',    mapper: 'type'},
            {title: 'Issuer',           mapper: 'getIssuer'}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), ProtectiveEquipmentActions)(ctrl);
        ctrl.dataAction = ctrl.getProtectiveEquipmentHistory;
        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
