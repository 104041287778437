import InputController from '../input.controller';
import { INPUT_CHECKBOX } from '../input.constants';

/**
 * Creates a new InputCheckboxController.
 * @class
 * @extends module:root/common/form.InputController
 * @memberOf module:root/common/form
 * @see {@link https://docs.angularjs.org/guide/component|AngularJS Component}
 */
class InputCheckboxController extends InputController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     */
    constructor($ngRedux) {
        super($ngRedux);
    }

    /**
     * @method onInputChange
     * @memberOf module:root/common/form.InputCheckboxController
     */
    onInputChange() {
        this.$ngRedux.dispatch({
            type: INPUT_CHECKBOX,
            id: this.identifier,
            path: this.statePath,
            index: this.stateIndex,
            prop: this.stateProp,
            input: this.input,
            data: {[this.statePath]: this.input}
        });

        if (this.onChange) {
            this.onChange({
                $event: {input: this.input}
            });
        }
    }
}

InputCheckboxController.$inject = ['$ngRedux'];

export const inputCheckbox = {
    bindings: {
        identifier: '@',
        statePath: '@',
        stateIndex: '<',
        stateProp: '@',
        label: '@',
        disabled: '@',
        onChange: '&'
    },
    require : {
        /**
         * @member {*} module:root/common/form.InputCheckboxController#form
         * @see {@link https://docs.angularjs.org/api/ng/type/form.FormController|FormController}
         */
        form : '^form'
    },
    controller: InputCheckboxController,
    template: `
        <md-input-container class="md-block checkbox">
            <md-checkbox ng-model="$ctrl.input"
                         ng-true-value="1"
                         ng-false-value="0"
                         ng-change="$ctrl.onInputChange()"
                         ng-disabled="$ctrl.disabled"
                         aria-label="$ctrl.label">
                <span>{{$ctrl.label}}</span>
            </md-checkbox>
        </md-input-container>
    `
};
