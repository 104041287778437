/**
 * @module root/legacy/components/mammo-quality-control
 */

import {mammoQualityControlArtifactEvaluation} from "./artifact-evaluation/mammo-quality-control-artifact-evaluation.component";
import {mammoQualityControlArtifactEvaluationDetails} from "./artifact-evaluation/details/mammo-quality-control-artifact-evaluation-details.component";
import {selectTargetFilter} from "./artifact-evaluation/form/select-target-filter/select-target-filter.component";
import {mammoQualityControlArtifactEvaluationGrid} from './artifact-evaluation/grid/mammo-quality-control-artifact-evaluation-grid.component';
import {measurementDateRange} from "./form/measurement-date-range/measurement-date-range.component";
import {selectEquipment} from "./form/select-equipment/select-equipment.component";
import {signalAndContractRatioGrid} from "./signal-and-contract-ratio-measurement/grid/signal-and-contract-ratio-grid.component";

import {MammoQualityControlController} from "./mammo-quality-control.controller";
import {MammoQualityControlArtifactEvaluationController} from "./artifact-evaluation/mammo-quality-control-artifact-evaluation.controller";
import {MammoQualityControlArtifactEvaluationDetailsController} from "./artifact-evaluation/details/mammo-quality-control-artifact-evaluation-details.controller";
import {SelectTargetFilterController} from "./artifact-evaluation/form/select-target-filter/select-target-filter.controller";
import {mammoQualityControlArtifactEvaluationGridController} from "./artifact-evaluation/grid/mammo-quality-control-artifact-evaluation-grid.controller";
import {mammoSignalAndContractRatioMeasurementController} from "./controllers/signal-and-contract-ratio-measurement-controller";
import {DicomQualityControlTestController} from "./controllers/dicom-quality-control/dicom-quality-control-controller-test";
import {measurementDateRangeController} from "./form/measurement-date-range/measurement-date-range.controller";
import {SelectEquipmentController} from "./form/select-equipment/select-equipment.controller";
import {signalAndContractRatioGridController} from "./signal-and-contract-ratio-measurement/grid/signal-and-contract-ratio-grid.controller";

import {MammoQualityControlActionsFactory} from "./mammo-quality-control.actions";
import {MammoQualityControlEquipmentFactory} from "./factories/mammo-qc-equipment-factory";
import {MammoQualityControlMeasurementFactory} from "./factories/mammo-qc-measurement-factory";
import {MammoQCPlotDiagramFilterFactory} from "./factories/mammo-qc-measurement-filter-factory";
import {MammoQualityControlTesterCandidateFactory} from "./factories/mammo-qc-tester-candidate-factory";
import {DicomQCPlotDiagramFilterFactory} from "./factories/dicom-quality-control/dicom-qc-plot-diagram-filter-factory";
import {DicomQualitycontrolTestFactory} from "./factories/dicom-quality-control/dicom-qc-test-factory";

import {ArtifactEvaluationService} from "./services/artifact-evaluation/artifact-evaluation-service";
import {DicomQualityControlService} from "./services/dicom-quality-control/dicom-qc-service";
import {DicomQCTestManager} from "./services/dicom-quality-control/dicom-qc-test-manager";
import {MammoQCService} from "./services/mammo-qc-service";
import {MammoSNRCNRManager} from "./services/signal-and-contract-to-noise-ratio/mammo-snr-cnr-manager";

angular
    .module(
        'components.mammo-quality-control',
        [])
    .component({
        mammoQualityControlArtifactEvaluation,
        mammoQualityControlArtifactEvaluationDetails,
        selectTargetFilter,
        mammoQualityControlArtifactEvaluationGrid,
        measurementDateRange,
        selectEquipment,
        signalAndContractRatioGrid
    })
    .controller({
        MammoQualityControlController,
        MammoQualityControlArtifactEvaluationController,
        MammoQualityControlArtifactEvaluationDetailsController,
        SelectTargetFilterController,
        mammoQualityControlArtifactEvaluationGridController,
        mammoSignalAndContractRatioMeasurementController,
        DicomQualityControlTestController,
        measurementDateRangeController,
        SelectEquipmentController,
        signalAndContractRatioGridController
    })
    .factory({
        MammoQualityControlActions: MammoQualityControlActionsFactory,
        MammoQCEquipment: MammoQualityControlEquipmentFactory,
        MammoQCMeasurement: MammoQualityControlMeasurementFactory,
        MammoQCPlotDiagramFilter: MammoQCPlotDiagramFilterFactory,
        MammoQCTesterCandidate: MammoQualityControlTesterCandidateFactory,
        DicomQCPlotDiagramFilter: DicomQCPlotDiagramFilterFactory,
        DicomQCTest: DicomQualitycontrolTestFactory
    })
    .service({
        ArtifactEvaluationService,
        DicomQualityControlService,
        DicomQCTestManager,
        MammoQCService,
        MammoSNRCNRManager
    })
    .run(function($templateCache) {
        $templateCache.put('app.mammoQualityControl.artifactEvaluation.details', '<mammo-quality-control-artifact-evaluation-details></mammo-quality-control-artifact-evaluation-details>');
        $templateCache.put('app.mammoQualityControl.artifactEvaluation.listing', '<mammo-quality-control-artifact-evaluation></mammo-quality-control-artifact-evaluation>');
        $templateCache.put('./mammo-quality-control-artifact-evaluation-grid-filter.html', require('./artifact-evaluation/grid/mammo-quality-control-artifact-evaluation-grid-filter.html'));
        $templateCache.put('./dicom-quality-control-test-add.html', require('./views/dicom-quality-control/dicom-quality-control-test-add.html'));
        $templateCache.put('./dicom-quality-control-plot-diagram.html', require('./views/dicom-quality-control/dicom-quality-control-plot-diagram.html'));
        $templateCache.put('./dicom-quality-control-filter.html', require('./views/dicom-quality-control/dicom-quality-control-filter.html'));
        $templateCache.put('./signal-and-contract-ratio-measurement-add.html', require('./views/signal-and-contract-ratio-measurement/signal-and-contract-ratio-measurement-add.html'));
        $templateCache.put('./signal-and-contract-ratio-measurement-plot-diagram.html', require('./views/signal-and-contract-ratio-measurement/signal-and-contract-ratio-measurement-plot-diagram.html'));
        $templateCache.put('./signal-and-contract-ratio-measurement-grid.html', require('./views/signal-and-contract-ratio-measurement/signal-and-contract-ratio-measurement-grid.html'));
        $templateCache.put('./signal-and-contract-ratio-measurement-filter.html', require('./views/signal-and-contract-ratio-measurement/signal-and-contract-ratio-measurement-filter.html'));
        $templateCache.put('./signal-and-contract-ratio-grid-filter.html', require('./signal-and-contract-ratio-measurement/grid/signal-and-contract-ratio-grid-filter.html'));
    });
