
export const IDS = {
    // components
    accDashboardLocationYear: 'accDashboardLocationYear',

    // paths
    accTasksGrid:                   'accTasksGrid',
    accImprovementGrid:             'accImprovementGrid',
    addScheme:                      'addScheme',

    // states:
    app_accreditation_dashboard:                    'app.accreditation.dashboard',
    app_accreditation_scheme_standards_dashboard:  'app.accreditation.scheme.standards.dashboard',
};
