import { getCountries, getCurrencies, saveVendorFinance } from '../../cre-vendors.actions';

/**
 * @const {object}
 * @memberOf module:Credentialing
 */
export const creVendorsFinance = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $location, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.title = 'Financial Information';
            this.unsubscribe = $ngRedux.connect(({ credentialing }) => ({
                creVendor: credentialing.creVendor,
            }), {
                getCountries,
                getCurrencies,
                saveVendorFinance,
            })(this);
            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.finance`;
            this.token = $location.search().token;

            this.getCountries({ token: this.token });
            this.getCurrencies({ token: this.token });
        };

        this.onSave = () => {
            if (this.creVendor.company_local === 0 && this.creVendor.company_international === 0) {
                Notifier.error('Please select at least one company');
            } else {
                if (this.creVendorsFinanceForm.$valid) {
                    this.saveVendorFinance({
                        token: this.token,
                        application_id: this.creVendor.application_id,
                        local_company: this.creVendor.company_local,
                        bank_acc_name: this.creVendor.bank_acc_name,
                        bsb_code: this.creVendor.bank_bsb_code,
                        bank_acc_number: this.creVendor.bank_acc_no,
                        pay_id: this.creVendor.bank_pay_id,
                        international_company: this.creVendor.company_international,
                        ben_acc_name: this.creVendor.beneficiary_acc_name,
                        acc_number_iban: this.creVendor.beneficiary_acc_num_iban,
                        ben_cou_id: this.creVendor.beneficiary_country,
                        ben_address: this.creVendor.beneficiary_address,
                        ben_swift_bic_code: this.creVendor.beneficiary_swift_bic_code,
                        ben_bank_address: this.creVendor.beneficiary_bank_address,
                        ben_bank_cou_id: this.creVendor.beneficiary_bank_country,
                        cur_of_payment_id: this.creVendor.currency,
                    }).then(() => {
                        Notifier.success('Financial Information has been successfully saved');
                    });
                } else {
                    Notifier.error('Please fill in the form fields');
                }
            }
        }

        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: require('./cre-vendors-finance.html')
};
