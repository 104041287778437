/* @ngInject */
export function kdValidateSelect($timeout) {
    return {
        require: '^form',
        scope: {
            'kdValidateSelect':'@',
            'kdMatch':'@'
        },
        link: function (scope, elm, attrs, formCtrl) {
            var inputs;
            $timeout(function () {
                // we search for a .form-control whose name attriubte is set
                var selectField = elm.find('md-select');

                // convert the native text box element to an angular element
                var inputNgEl = angular.element(selectField);
                // get the name on the text box
                var inputName = inputNgEl.attr('name');

                inputNgEl.bind('blur', function ()
                {
                    if (formCtrl[inputName].$viewValue == null || (typeof formCtrl[inputName].$viewValue === 'object' && formCtrl[inputName].$viewValue.id == null && formCtrl[inputName].$viewValue.uuid == null)) {
                        formCtrl[inputName].$setValidity('required', false);
                        formCtrl[inputName].$setDirty();
                        formCtrl[inputName].$setTouched();
                    }
                    else
                    {
                        formCtrl[inputName].$setValidity('required', true);
                    }
                    elm.toggleClass('has-error', formCtrl[inputName].$invalid);
                });

                scope.$on('show-errors-check-validity', function(event, formName)
                {
                    if (!_.isUndefined(formName) && formCtrl.$name != formName)
                        return false;

                    if (!_.isUndefined(inputNgEl.attr('required'))) {
                        if (formCtrl[inputName].$viewValue == null || (typeof formCtrl[inputName].$viewValue === 'object' && formCtrl[inputName].$viewValue.id == null && formCtrl[inputName].$viewValue.uuid == null)) {
                            formCtrl[inputName].$setValidity('required', false);
                            formCtrl[inputName].$setDirty();
                            formCtrl[inputName].$setTouched();

                            elm.toggleClass('has-error', formCtrl[inputName].$invalid);
                        }
                    }
                });

            });
        }
    };
}
