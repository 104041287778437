import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsDepartmentRscController($ngRedux, $uiRouterGlobals, $mdDialog, AdministrationActions) {
    var ctrl = this;
    var dialog = null;
    var unsubscribe = angular.noop;
    var onRsc = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);

        var watchRsc = watch($ngRedux.getState, 'administration.administrationAccountsDepartmentRsc.data.uuid');
        onRsc = $ngRedux.subscribe(watchRsc(function(uuid) {
            onRscReady(uuid);
        }));
    };

    ctrl.newRsc = function() {
        if (ctrl.rsc.uuid) {
            $ngRedux.dispatch({
                type: 'RSC_RESET',
                id: 'administrationAccountsDepartmentRsc'
            });
        }
        $ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: 'app.administration.accounts.departments',
            state: ['app.administration.accounts.departments.rscDetails'],
            stateParams: $uiRouterGlobals.params
        });
    };

    ctrl.selectRSC = function(event) {
        if (event.item.subject_uuid === ctrl.rsc.uuid)
            onRscReady(event.item.subject_uuid);
        else
            ctrl.getRscDetails('administrationAccountsDepartmentRsc', event.item.subject_uuid);
    };

    ctrl.removeRSC = function(event) {
        var confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Radiation Safety Coordinator will be deactivated.')
            .ariaLabel('Deactivate RSC')
            .ok('Yes')
            .cancel('No');

        dialog = $mdDialog.show(confirm).then(function() {
            ctrl.removeRsc(event.item.subject_uuid, ctrl.department.uuid, 'administrationAccountsDepartmentRsc', 'administrationAccountsDepartmentRscGrid');
        }, angular.noop);
    };

    ctrl.$onDestroy = function() {
        if (dialog) $mdDialog.hide(dialog);
        unsubscribe();
        onRsc();
    };

    function onRscReady(uuid) {
        $ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: 'app.administration.accounts.departments',
            state: ['app.administration.accounts.departments.rscDetails'],
            stateParams: {
                hof: $uiRouterGlobals.params.hof,
                slo: $uiRouterGlobals.params.slo,
                dep: $uiRouterGlobals.params.dep,
                rsc: uuid
            }
        });
        $ngRedux.dispatch({
            type: 'TAB_LOADING',
            id: 'app.administration.accounts.departments',
            state: 'app.administration.accounts.departments.rscDetails',
            loading: false
        });
    }

    function mapStateToThis(state) {
        return {
            department: state.administration.administrationAccountsDepartment.data,
            rsc: state.administration.administrationAccountsDepartmentRsc.data,
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                state.administration.administrationAccountsDepartment.data.typeName,
                'RSCs'
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
