import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsSiteLocationQualityManagerDetailsController($ngRedux, $uiRouterGlobals, AdministrationActions, Notifier) {
    var ctrl = this;
    var unsubscribe = angular.noop;
    var onQualityManager = angular.noop;

    ctrl.$onInit = function() {
        ctrl.identifier = 'administrationAccountsSiteLocationQualityManager';
        ctrl.checkboxFlex = 25;

        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);
        ctrl.qualityManagerDetails = !_.isNull(ctrl.qualityManager.uuid);

        if (_.isUndefined(ctrl.stateParams.qualityManager))
            ctrl.getQualityManagerCandidates(ctrl.stateParams.slo, ctrl.identifier);

        var watchQualityManager = watch($ngRedux.getState, 'administration.administrationAccountsSiteLocationQualityManager.data.uuid');
        onQualityManager = $ngRedux.subscribe(watchQualityManager(function(uuid) {
            ctrl.qualityManagerDetails = !_.isNull(uuid);
        }));
    };

    ctrl.onQualityManagerDetails = function() {
        ctrl.siteLocationQualityManagerForm.$setPristine();
        ctrl.siteLocationQualityManagerForm.$setUntouched();
        ctrl.checkboxFlex = ctrl.qualityManagerDetails ? 50 : 25;
    };

    ctrl.manageQualityManager = function() {
        if (ctrl.siteLocationQualityManagerForm.$valid) {
            if (ctrl.qualityManager.uuid) {
                ctrl.updateQualityManagerDetails('administrationAccountsSiteLocationQualityManager', ctrl.qualityManager, 'administrationAccountsSiteLocationQualityManagersGrid');
            }
            else {
                if (ctrl.qualityManagerDetails) {
                    ctrl.qualityManager.location = $uiRouterGlobals.params.slo;
                    ctrl.createQualityManagerDetails(ctrl.qualityManager, 'administrationAccountsSiteLocationQualityManager', 'administrationAccountsSiteLocationQualityManagersGrid');
                }
                else {
                    ctrl.qualityManagerCandidate.location = $uiRouterGlobals.params.slo;
                    ctrl.assignQualityManager(ctrl.qualityManagerCandidate, 'administrationAccountsSiteLocationQualityManager', 'administrationAccountsSiteLocationQualityManagersGrid');
                }
            }
        }
        else Notifier.error('Please fill in the form fields');
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
        onQualityManager();
    };

    function mapStateToThis(state) {
        return {
            qualityManager: state.administration.administrationAccountsSiteLocationQualityManager.data,
            qualityManagerCandidate: state.administration.administrationAccountsSiteLocationQualityManager.candidate,
            stateParams: state.administration['app.administration.accounts.siteLocations'].stateParams,
            loading: _.find(state.administration['app.administration.accounts.siteLocations'].tabs, ['state', 'app.administration.accounts.siteLocations.qualityManagerDetails']).loading,
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                state.administration.administrationAccountsSiteLocationQualityManager.title
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
