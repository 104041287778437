import moment from 'moment';

/* @ngInject */
export function ServiceContractFactory(ServiceContractType, ServiceContractStatus, ServiceContractVisitSchedule, VendorHeadOffice, Helpers, DATE_FORMAT) {
    return function ServiceContract() {

        this.id = null;
        this.number = null;
        this.type = new ServiceContractType();
        this.status = new ServiceContractStatus();
        this.vendor = new VendorHeadOffice();
        this.createDate = null;
        this.startDate = null;
        this.expiryDate = null;
        this.numberOfYears = null;
        this.telephoneSupport = false;
        this.attendanceCoverage = false;
        this.attendanceHoursFrom = null;
        this.attendanceHoursFromPeriod = null;
        this.attendanceHoursTo = null;
        this.attendanceHoursToPeriod = null;
        this.attendanceAdditionalCosts = false;
        this.attendanceAdditionalCostsAmount = null;
        this.attendanceAdditionalCostsType = 0;
        this.responseTime = 24;
        this.responseTimeType = 0;
        this.partsDeliveryTime = 36;
        this.partsDeliveryTimeType = 0;
        this.price = null;
        this.url = null;
        this.accounts = [];
        this.equipmentTypes = [];
        this.equipmentCategories = [];
        this.equipment = [];
        this.equipmentSpareParts = [];
        this.equipmentSparePartsIncluded = 1;
        this.equipmentSparePartsDocument = 0;
        this.equipmentSparePartsUrl = null;
        this.numberOfVisits = null;
        this.visitSchedules = [];
        this.visitSchedulesAvailable = [];
        this.months = [];
        this.monthsScheduled = [];
        this.visits = [1,2,3,4,5,6,7,8,9,10,11,12];

        this.isStatusPending = function() {
            return this.status.code == null || this.status.code == 'pen';
        };

        this.getCosts = function() {
            return parseFloat(this.price).toFixed(2)
        };

        this.getStartDate = function() {
            return Helpers.formatDate(this.startDate, DATE_FORMAT.INPUT);
        };

        this. getExpiryDate = function() {
            return Helpers.formatDate(this.expiryDate, DATE_FORMAT.INPUT);
        };

        this.getAttendanceHoursFrom = function(format) {
            return this.attendanceCoverage ? null : (_.isUndefined(format) ? this.attendanceHoursFrom : moment(this.attendanceHoursFrom, 'hh:mm:ss').format('LT'));
        };

        this.getAttendanceHoursTo = function(format) {
            return this.attendanceCoverage ? null : (_.isUndefined(format) ? this.attendanceHoursTo : moment(this.attendanceHoursTo, 'hh:mm:ss').format('LT'));
        };

        this.getAttendanceCoverage = function() {
            return this.attendanceCoverage ? 'Only covered during Business Hours (8:30 am to 5:30 pm)' : 'Hours covered from ' + this.getAttendanceHoursFrom(true) + ' to ' + this.getAttendanceHoursTo(true);
        };

        this.getAttendanceAdditionalCosts = function() {
            return this.attendanceAdditionalCosts ? this.attendanceAdditionalCostsAmount + ' (' +(this.attendanceAdditionalCostsType ? 'per hour' : 'fixed') + ')' : 'None';
        }

        this.getResponseTime = function() {
            return this.responseTimeType ? (this.responseTime * 24) : this.responseTime;
        };

        this.getResponseTimeType = function() {
            return this.responseTimeType ? 'days' : 'hours';
        };

        this.getPartsDeliveryTime = function() {
            return this.partsDeliveryTimeType ? (this.partsDeliveryTime) * 24 : this.partsDeliveryTime;
        };

        this.getPartsDeliveryTimeType = function() {
            return this.partsDeliveryTimeType ? 'days' : 'hours';
        };

        this.getAccountsSummary = function() {
            return this.accounts.map(function(account) {
                return account.name;
            }).join(', ');
        };

        this.getMonthsScheduledSummary = function() {
            return this.monthsScheduled.join(', ');
        };

        this.getEquipmentSummary = function() {
            return _.chunk(this.equipment, 2);
        };

        this.getEquipmentSparePartsSummary = function() {
            return this.equipmentSpareParts.map(function(sparePart) {
                return sparePart.name;
            }).join(', ');
        };

        this.getVisitsSummary = function() {
            var visitsSummary = this.monthsScheduled.map(function(month) {
                return {month: month, schedules: []}
            });
            _.each(this.visitSchedulesAvailable, function(schedule) {
                if (schedule.visits.length) {
                    var summary = _.find(visitsSummary, ['month', schedule.month]);
                    summary.schedules.push(schedule);
                }
            });
            return visitsSummary.filter(function(item) {
                return item.schedules.length;
            });
        };

        this.initMonths = function() {
            function yearsDiff(d1, d2) {
                let date1 = new Date(d1);
                let date2 = new Date(d2);
                let yearsDiff =  date2.getFullYear() - date1.getFullYear();
                return yearsDiff;
            }

            let date1 = new Date(this.startDate);
            let date2 = new Date(this.expiryDate);
            let years = yearsDiff(this.startDate, this.expiryDate);
            let monthsNumber = (years * 12) + (date2.getMonth() - date1.getMonth()) ;

            if (!this.months.length) {
                var count = 0;
                var names = [];
                while (count < 12) names.push(moment().month(count++).format('MMMM'));

                var month = this.startDate.getMonth();
                var year = this.startDate.getFullYear();

                for (var i = 0; i < monthsNumber; ++i) {
                    this.months.push(names[month] + ' ' + year);
                    if (++month === 12) {
                        month = 0;
                        ++year;
                    }
                }
            }
        };

        this.isEquipmentSelected = function(item) {
            return !_.isUndefined(_.find(this.equipment, ['equipment_id', item.equipment_id]));
        };

        this.isEquipmentScheduled = function(month, equipment) {
            var result = false;
            var schedule = this.getVisitSchedule(equipment.account.uuid, month);

            if(!_.isUndefined(schedule)) {
                _.each(schedule.visits, function (visit) {
                    if (!_.isUndefined(_.find(visit.equipment, ['equipment_id', equipment.equipment_id]))) {
                        result = true;
                        return false;
                    }
                });
                return result;
            } else {
                return false;
            }
        };

        this.isEquipmentScheduleAvailable = function(equipment) {
            var self = this;
            var totalScheduled = this.monthsScheduled.filter(function(month) {
                return self.isEquipmentScheduled(month, equipment);
            });
            return totalScheduled.length < this.monthsScheduled.length;
        };

        this.unscheduleEquipment = function(month, equipment) {
            var schedule = this.getVisitSchedule(equipment.account.uuid, month);
            _.each(schedule.visits, function(visit) {
                if (visit.removeEquipment(equipment)) return false;
            });
            schedule.clearVisits();
        };

        this.removeAccount = function(account) {
            var accountEquipment = this.equipment.filter(function(item) {
                return account.uuid == item.account.uuid;
            });

            var affectedVisits = 0;
            if (accountEquipment.length) affectedVisits = this.removeEquipment(accountEquipment);

            var idx = _.findIndex(this.accounts, ['in_subject_uuid', account.in_subject_uuid]);
            if (idx > -1) this.accounts.splice(idx, 1);

            return affectedVisits;
        };

        this.removeCategory = function(category) {
            var categoryEquipment = this.equipment.filter(function(item) {
                if (category.id == item.category.id) return true;
                if (category.par_id == item.category.id) return true;
                return false;
            });

            var affectedVisits = 0;
            if (categoryEquipment.length) affectedVisits = this.removeEquipment(categoryEquipment);

            var idx = _.findIndex(this.equipmentCategories, ['id', category.id]);
            if (idx > -1) this.equipmentCategories.splice(idx, 1);

            return affectedVisits;
        };

        this.removeEquipment= function(equipmentList) {
            var affectedVisits = this.removeEquipmentFromVisits(equipmentList);
            var self = this;
            _.each(equipmentList, function(item) {
                var element = _.find(self.equipment, ['equipment_id', item.equipment_id]);
                if (_.isUndefined(element) == false)
                    self.equipment.splice(self.equipment.indexOf(element), 1);
            });
            return affectedVisits;
        };

        this.removeEquipmentFromVisits = function(equipmentList) {
            var self = this;
            var removedItems = 0;
            _.each(equipmentList, function(equipment) {
                _.each(self.visitSchedules, function(schedule) {
                    _.each(schedule.visits, function(visit) {
                        if (visit.removeEquipment(equipment)) removedItems++;
                    });
                    schedule.clearVisits();
                });
            });
            return removedItems;
        };

        this.setEstimatedTimeFrames = function(months) {
            this.monthsScheduled = months;
            this.numberOfVisits = months.length;

            this.accounts.forEach(function(account) {
                this.monthsScheduled.forEach(function(month) {
                    var schedule = new ServiceContractVisitSchedule(account, month);
                    this.visitSchedules.push(schedule);
                }, this);
            }, this);
        };

        this.getVisitSchedule = function(accountUuid, month) {
            this.initMonths();
            this.accounts.forEach(function(account) {
                this.months.forEach(function(month) {
                    var schedule = new ServiceContractVisitSchedule(account, month);
                    this.visitSchedulesAvailable.push(schedule);
                }, this);
            }, this);
            return _.find(this.visitSchedulesAvailable, {'accountUuid': accountUuid, 'month': month});
        };

        this.resetVendor = function() {
            this.vendor = new VendorHeadOffice();
        };
    };
}
