import { GridController } from '../../../../../common/grid/grid.controller';

/* @ngInject */
export function AdministrationVendorsGridController($controller, $ngRedux, $state, NavigationService, AdministrationActions) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'administration';
        ctrl.identifier = 'administrationVendorsGrid';
        ctrl.filterComponent = false;
        ctrl.exportComponent = false;

        ctrl.columns = [
            {title: '#',                mapper: null},
            {title: 'Account ID',       mapper: 'id'},
            {title: 'Facility',         mapper: 'name'}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), AdministrationActions)(ctrl);
        ctrl.dataAction = ctrl.getVendors;
        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
