/* @ngInject */
export function EquipmentUltrasoundTransducerController($scope, Transducer, EquipmentServiceTransducer, EquipmentManager, Manufacturer, ManufacturerService, Notifier, EQUIPMENT_TYPE) {

    $scope.templateTransducerAdd = './transducer-add.html';
    $scope.templateTransducerManage = './transducer-manage.html';

    $scope.transducerTabs = [
        {
            id: 0,
            title: 'Transducers List',
            content: './transducer-listing.html',
            visible: true
        },
        {
            id: 1,
            title: 'Details',
            content: './transducer-details.html',
            visible: false
        }
    ];

    $scope.STATE_DISPLAY = 'state_display';
    $scope.STATE_MODIFY = 'state_modify';
    $scope.state = $scope.STATE_DISPLAY;

    $scope.transducer = new Transducer();
    $scope.transducer.type.id = EQUIPMENT_TYPE.transducer;
    $scope.transducer.model = null;
    $scope.transducerModels = [];

    $scope.selectedItemModels = [];

    $scope.onTransducerTabSelected = function(tab) {
        if (tab.id == 0) _.find($scope.transducerTabs, ['id', 1]).visible = false;
    };

    EquipmentServiceTransducer.getCategories().then(function(items) {
        $scope.transducerCategories = items;
    });

    $scope.initAddForm = function(form) {
        $scope.addForm = form;
    };

    $scope.initModifyForm = function(form) {
        $scope.modifyForm = form;
    };

    $scope.transducerCategorySelected = function() {
        if ($scope.state == $scope.STATE_MODIFY) {
            $scope.selectedItem.manufacturer = new Manufacturer();
            $scope.selectedItem.model = null;
            $scope.$broadcast('RESET_EQUIPMENT_MODEL', $scope.selectedItem.type.id);
        }
        else {
            $scope.transducer.manufacturer = new Manufacturer();
            $scope.$broadcast('RESET_EQUIPMENT_MODEL', $scope.transducer.type.id);
        }
    };

    $scope.transducerManufacturerSelected = function() {
        if ($scope.state == $scope.STATE_MODIFY && !_.isNull($scope.selectedItem.manufacturer)) {
            ManufacturerService.fetchEquipmentModels($scope.selectedItem.manufacturer.id, $scope.selectedItem.category.id).then(function(items) {
                $scope.selectedItemModels = items;
                $scope.selectedItem.model = null;
            });
        }
        else if (!_.isNull($scope.transducer.manufacturer)) {
            ManufacturerService.fetchEquipmentModels($scope.transducer.manufacturer.id, $scope.transducer.category.id).then(function(items) {
                $scope.transducerModels = items;
                $scope.transducer.model = null;
            });
        }
    };

    $scope.createEquipmentModel = function(equipment) {
        EquipmentManager.createNewModel(equipment);
    };

    $scope.$on(EquipmentManager.CREATE_NEW_MODEL_COMPLETE, function(event, model) {
        if ($scope.selectedTab.id == EquipmentManager.TAB_EQUIPMENT_TRANSDUCERS) {
            if ($scope.state == $scope.STATE_MODIFY) {
                $scope.selectedItemModels.push(model);
                $scope.selectedItem.model = model;
            }
            else {
                $scope.transducerModels.push(model);
                $scope.transducer.model = model;
            }
        }
    });

    $scope.addTransducer = function() {
        $scope.$broadcast('show-errors-check-validity', 'addForm');
        if ($scope.addForm.$invalid) {
            Notifier.error('Please fill in the form fields');
            return;
        }

        EquipmentServiceTransducer.addEquipment($scope.equipment.equipment_id, $scope.transducer).then(function(id) {
            $scope.transducer.equipment_id = id;
            $scope.transducersGrid.items.push(_.cloneDeep($scope.transducer));
            $scope.transducer = new Transducer();
            $scope.transducer.model = null;
            $scope.transducer.type.id = EQUIPMENT_TYPE.transducer;
            $scope.addForm.$setPristine();
            $scope.addForm.$setUntouched();
            Notifier.success('Transducer successfully added');
        });
    };

    $scope.selectTransducer = function(item) {
        if ($scope.selectedTab.id == EquipmentManager.TAB_EQUIPMENT_TRANSDUCERS) {
            $scope.state = $scope.STATE_MODIFY;
            $scope.selectedItem = _.cloneDeep(item);
            ManufacturerService.fetchEquipmentModels($scope.selectedItem.manufacturer.id, $scope.selectedItem.category.id).then(function(items) {
                $scope.selectedItemModels = items;
                _.find($scope.transducerTabs, ['id', 1]).title = item.model.name;
                _.find($scope.transducerTabs, ['id', 1]).visible = true;
                $scope.selectedTransducerIndex = 1;
            });
        }
    };

    $scope.updateTransducer = function() {
        $scope.$broadcast('show-errors-check-validity', 'modifyForm');
        if ($scope.modifyForm.$invalid) {
            Notifier.error('Please fill in the form fields');
            return;
        }

        EquipmentServiceTransducer.updateEquipment($scope.selectedItem).then(function() {
            _.assign(_.find($scope.transducersGrid.items, {equipment_id: $scope.selectedItem.equipment_id}), $scope.selectedItem);
            $scope.state = $scope.STATE_DISPLAY;
            Notifier.success('Transducer successfully updated');
            $scope.cancelTransducerUpdate();
        });
    };

    $scope.removeTransducer = function(item) {
        EquipmentServiceTransducer.removeEquipment(item).then(function() {
            _.remove($scope.transducersGrid.items, {equipment_id: item.equipment_id});
            Notifier.success('Transducer successfully removed');
        }, function(error) {
            Notifier.responseMessage(error);
        });
    };

    $scope.cancelTransducerUpdate = function() {
        _.find($scope.transducerTabs, ['id', 1]).visible = false;
        $scope.selectedTransducerIndex = 0;
    };

    $scope.ultrasoundDetails = function() {
        $scope.nextTab();
    };
}
