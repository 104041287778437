/* @ngInject */
export function SafetyTrainingManageReviewController($scope, $rootScope, $state, $filter, Notifier,
                                        AccountService,
                                        $mdDialog,
                                        SUBJECT_TYPE,
                                        SafetyTrainingService,
                                        SAFETY_PLAN_REVIEW_SETTINGS,
                                        $timeout,
                                        AuthService,
                                        Helpers) {

    $scope.cursorState = 'default';
    $scope.currentDocPage = 1;
    $scope.pageSize = 10;
    $scope.sortArrow = 'keyboard_arrow_down';
    $scope.trainingName = $state.current.trainingName;

    var ctrl = this;

    $scope.SORT_OPTIONS = {
        REVIEW_ID: 'id',
        FIRST_NAME: 'firstName',
        LAST_NAME: 'lastName',
        USERNAME: 'userName',
        OCCUPATION: 'occupation',
        DUE_DATE: 'dueDateStr',
        START_DATE: 'startDateStr',
        END_DATE: 'endDateStr',
        TRAINING_NAME: 'trainingName'
    };

    ctrl.filter = {
        firstName: null,
        lastName: null,
        occupation: null,
        dueDateAfter: null,
        dueDateBefore: null
    };

    ctrl.sortBy = $scope.SORT_OPTIONS.ITEM_ID;
    ctrl.sortReverse = false;

    ctrl.selectedUserParentLocation = ""; //uuid
    ctrl.selectedUserParentLocationName = ""; //location name

    ctrl.YEARS = [new Date().getFullYear()-3, new Date().getFullYear()-2, new Date().getFullYear()-1, new Date().getFullYear(), new Date().getFullYear()+1, new Date().getFullYear()+2, new Date().getFullYear()+3];
    ctrl.year = new Date().getFullYear();
    ctrl.employeeReviewItems = [];
    ctrl.selectedItem = null;
    ctrl.config = {};
    ctrl.config.TRAINING_REVIEW_LENGTH = SAFETY_PLAN_REVIEW_SETTINGS.REVIEW_DEFAULT_LENGTH;

    var user = AuthService.getUser();
    ctrl.isAdmin = (user.type == 'Administrator');

    ctrl.selectAll = function () {
        ctrl.employeeReviewItems.forEach(function(item){
            if (ctrl.allSelected)
                item.selected = true;
            else
                item.selected = false
        });
    };

    ctrl.anySelected = function () {

        if (ctrl.employeeReviewItems == null) {
            return false;
        }

        return ctrl.employeeReviewItems.some(function selected(item) {
            return item.selected == true;
        });
    };

    ctrl.resetFilter = function () {
        ctrl.filter = {
            firstName: null,
            lastName: null,
            occupation: null,
            dueDateAfter: null,
            dueDateBefore: null
        };
    };

    ctrl.resetList = function () {
        ctrl.resetFilter();
        ctrl.fetchEmployeeReviewItems();
    };

    ctrl.filterList = function () {
        ctrl.employeeReviewItems = [];
        ctrl.selectedItem = null;
        $timeout(
            function () {
                ctrl.fetchEmployeeReviewItems();
            }
        );
    };

    ctrl.selectItem = function (item) {
        ctrl.selectedItem = item;
    };

    ctrl.determineColour = function (item) {
        // Split timestamp into [ Y, M, D, h, m, s ]
        if (item.dueDate == null) return '#fcf8e3'; //return 'table-warning';
        try {
            var myDueDate = item.dueDate;
            var myExpiredDate = new Date(myDueDate.getTime());
            myExpiredDate.setDate(myExpiredDate.getDate() + ctrl.config.TRAINING_REVIEW_LENGTH);
            var myStartDate = item.startDate;
            var myEndDate = item.endDate;
        } catch (Exception) {
        }
        var currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        if (myEndDate != null) {
            //review is finished, mark it success
            return '#dff0d8';//return 'table-success';
        } else if (myDueDate.getTime() < currentDate.getTime()) {
            //Review not finished, due date passed
            if ((myStartDate == null) || (myStartDate.getTime() > myDueDate.getTime())) {
                //review not started in due time
                return '#f2dede'; // return 'table-danger';
            } else if ((myStartDate != null) && (myStartDate.getTime() <= myExpiredDate.getTime())) {
                if (currentDate.getTime() <= myExpiredDate.getTime()) {
                    //review started on time, still pending
                    return '#d9edf7'; //return 'table-info';
                } else {
                    //time window passed, review not finished
                    return '#f2dede';  // return 'table-danger';
                }
            }
        } else if (myDueDate.getTime() >= currentDate.getTime()) {
            //Review not finished, Due date still not passed
            return '#d9edf7'; //return 'table-info';
        }
    };

    ctrl.fetchEmployeeReviewItems = function () {
        SafetyTrainingService.getEmployeeReviews($state.current.trainingType, ctrl.selectedUserParentLocation, ctrl.year, ctrl.filter)
            .then(function (data) {
                ctrl.employeeReviewItems = data.result;
                ctrl.selectedItem = null;
            }, function (error) {
                ctrl.employeeReviewItems = [];
                ctrl.selectedItem = null;
                Notifier.error($filter('translate')("SAFETY_TRAINING.MANAGE_TRAINING_REVIEW.ERROR_FETCH_EMPLOYEE_REVIEW_ITEMS"));
            });
    };

    ctrl.setSort = function (sortColumn) {
        if (sortColumn === ctrl.sortBy) {
            ctrl.sortReverse = !ctrl.sortReverse;
        } else {
            ctrl.sortBy = sortColumn;
        }
    };

    $scope.$on('LOCATION_SUBJECT_SELECTED', function (event, locationItem) {
        if (locationItem.in_subject_basic_type_code === "slo") {
            ctrl.selectedUserParentLocation = locationItem.in_subject_uuid;
            ctrl.selectedUserParentLocationName = locationItem.in_username_par;
        } else {
            ctrl.selectedUserParentLocation = null;
        }
    });

    $scope.$watch('safetyTrainingManageReview.selectedUserParentLocation', function () {
        if (ctrl.selectedUserParentLocation != "") {
            if(ctrl.selectedUserParentLocation === null) {
                ctrl.employeeReviewItems = [];
                ctrl.selectedItem = null;
            } else {
                ctrl.fetchEmployeeReviewItems();
            }
        }
    });

    $scope.$watch('safetyTrainingManageReview.selectedItem', function () {
        if (ctrl.selectedItem != null) {

        }
    });

    $scope.$watch('safetyTrainingManageReview.sortReverse', function (value) {
        if (value) {
            $scope.sortArrow = 'keyboard_arrow_up';
        } else {
            $scope.sortArrow = 'keyboard_arrow_down';
        }
    });

    $scope.$watch('safetyTrainingManageReview.year', function () {
        if (ctrl.selectedUserParentLocation != "") {
            ctrl.fetchEmployeeReviewItems();
        }
    });

    ctrl.editTrainingReviewDueDate = function (item) {
        $mdDialog.show({
            controller: SafetyTrainingReviewEditDueDateController,
            templateUrl: './safety-training-set-review-due-date-dialog.html',
            scope: $scope, // use parent scope in template
            preserveScope: true,
            // parent: angular.element(document.body),
            locals: {
                color: $state.current.trainingColor,
                title: $filter('translate')("SAFETY_TRAINING.MANAGE_TRAINING_REVIEW.DIALOG.EDIT_TRAINING_DUE_DATE.TITLE"),
                review: item,
                siteLocationUuid: ctrl.selectedUserParentLocation,
                multiReview: null
            },
            clickOutsideToClose: true
        }).then(angular.noop, angular.noop);
    };

    ctrl.multiEditSafetyTrainingReviewDueDate = function (item) {
        var selectedReviews = ctrl.employeeReviewItems.filter(function (item) {
            return item.selected == true;
        });

        $mdDialog.show({
            controller: SafetyTrainingReviewEditDueDateController,
            templateUrl: './safety-training-set-review-due-date-dialog.html',
            scope: $scope, // use parent scope in template
            preserveScope: true,
            // parent: angular.element(document.body),
            locals: {
                color: $state.current.trainingColor,
                title: $filter('translate')("SAFETY_PLAN.SAFETY_PLAN_SET_DUE_DATE_MULTIPLE"),
                review: null,
                siteLocationUuid: ctrl.selectedUserParentLocation,
                multiReview: selectedReviews
            },
            clickOutsideToClose: true
        }).then(angular.noop, angular.noop);
    };

    function SafetyTrainingReviewEditDueDateController($scope, $mdDialog, color, title, review, siteLocationUuid, multiReview) {
        $scope.headerColor = color;
        $scope.headerTitle = title;
        $scope.review = review;
        $scope.multiReview = multiReview;
        $scope.siteLocationUuid = siteLocationUuid;
        $scope.trainingFinished = false;
        //$scope.newDueDate = review.dueDate;
        if (review != null)
            $scope.newDueDate = review.dueDate;
        else
            $scope.newDueDate = null;

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.clearDueDate = function () {
            var multiReviewId = null;
            var multiSubjectUuid = null;

            if ($scope.review != null) {
                //Set one due date
                if (($scope.review != null) && ($scope.review.id != null)) {
                    multiReviewId = [$scope.review.id];
                } else {
                    multiSubjectUuid = [$scope.review.userUuid];
                }
            } else {
                //multi set due date
                multiReviewId = $scope.multiReview.filter(function (item) {
                    return (item.id != null && item.id!=0);
                }).map(function (item) {
                    return item.id;
                });

                multiSubjectUuid = $scope.multiReview.filter(function (item) {
                    return !(item.id != null && item.id!=0);
                }).map(function (item) {
                    return item.userUuid;
                });
            }

            SafetyTrainingService.manageEmployeeReview($state.current.trainingType, $scope.siteLocationUuid, new Date(1900, 0, 1),  multiReviewId, multiSubjectUuid)
                .then(function (response) {
                    $mdDialog.hide();
                    ctrl.fetchEmployeeReviewItems();
                }, function (error) {
                    Notifier.error($filter('translate')("SAFETY_TRAINING.MANAGE_TRAINING_REVIEW.ERROR_SETTING_DUE_DATE"));
                    $scope.cursorState = 'default';
                    $mdDialog.hide();
                    ctrl.fetchEmployeeReviewItems();
                });
        };

        $scope.setDueDate = function () {
            var multiReviewId = null;
            var multiSubjectUuid = null;

            if (review != null) {
                //Set one due date
                if (($scope.review != null) && ($scope.review.id != null)) {
                    multiReviewId = [$scope.review.id];
                } else {
                    multiSubjectUuid = [$scope.review.userUuid];
                }
            } else {
                //multi set due date
                multiReviewId = $scope.multiReview.filter(function (item) {
                    return (item.id != null && item.id!=0);
                }).map(function (item) {
                    return item.id;
                });

                multiSubjectUuid = $scope.multiReview.filter(function (item) {
                    return !(item.id != null && item.id!=0);
                }).map(function (item) {
                    return item.userUuid;
                });
            }

            if ($scope.newDueDate) {
                SafetyTrainingService.manageEmployeeReview($state.current.trainingType, $scope.siteLocationUuid, $scope.newDueDate, multiReviewId, multiSubjectUuid, $scope.trainingFinished)
                    .then(function (response) {
                        $mdDialog.hide();
                        ctrl.fetchEmployeeReviewItems();
                    }, function (error) {
                        Notifier.error($filter('translate')("SAFETY_TRAINING.MANAGE_TRAINING_REVIEW.ERROR_SETTING_DUE_DATE"));
                        $scope.cursorState = 'default';
                        $mdDialog.hide();
                        ctrl.fetchEmployeeReviewItems();
                    });
            } else {
                Notifier.error($filter('translate')("SAFETY_TRAINING.MANAGE_TRAINING_REVIEW.ERROR_INSERT_DUE_DATE"));
            }
        };
    }

    $scope.$on('training-revie-item-changed', function (events, args) {
        ctrl.fetchEmployeeReviewItems();
    });

    //Load data for parent site location if user belongs to only one site location
    if (user && (user.parentCount === 1) && (user.parents[user.parents.length - 1])
        && (user.parents[user.parents.length - 1].basicType == SUBJECT_TYPE.SITE_LOCATION)) {

        ctrl.selectedUserParentLocation = user.parents[user.parents.length - 1].uuid;
        ctrl.selectedUserParentLocationName = user.parents[user.parents.length - 1].name;
        ctrl.fetchEmployeeReviewItems();
    }
}
