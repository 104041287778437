/* @ngInject */
export function SelectEmployeeLicenceStatusController($ngRedux, LicenceService) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, {})(this);
        ctrl.options = [];

        LicenceService.getLicenceStatuses().then(function(items) {
            ctrl.options = items;
        });
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            $ngRedux.dispatch({
                type: 'EMPLOYEE_LICENCE_STATUS_SELECTED',
                id: ctrl.identifier,
                status: ctrl.selectedOption
            });
        }
    };

    function mapStateToThis(state) {
        return {
            selectedOption: state.employees[ctrl.identifier].filter.status,
        };
    }
}
