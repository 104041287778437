/**
 * @const EMP_LICENCE_TOKEN
 * @type {string}
 */
export const EMP_LICENCE_TOKEN = 'EMP_LICENCE_TOKEN';

/**
 * @const EMP_LICENCE_RESET
 * @type {string}
 */
export const EMP_LICENCE_RESET = 'EMP_LICENCE_RESET';

/**
 * @const EMP_LICENCE_DETAILS
 * @type {string}
 */
export const EMP_LICENCE_DETAILS = 'EMP_LICENCE_DETAILS';
