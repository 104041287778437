import API from '../../common/http/api.fetch';
import * as TYPE from './applications-permits.actions.types';
import get from 'lodash/get';

export const getRequestTypes = () => {
    return (dispatch) => {
        return API.fetch({
            url: '/applications-permits/request/types',
            method: 'GET',
            params: {}
        }).then(({ data }) => {
            dispatch({
                type: TYPE.AP_REQUEST_TYPES,
                id: 'apRequest',
                path: 'types',
                items: data.map((item) => {
                    return {
                        value: item.code,
                        label: item.name
                    };
                })
            })
        });
    }
}

export const getRequestStatuses = () => {
    return (dispatch) => {
        return API.fetch({
            url: '/applications-permits/request/statuses',
            method: 'GET',
            params: {}
        }).then(({ data }) => {
            dispatch({
                type: TYPE.AP_REQUEST_STATUSES,
                id: 'apRequest',
                path: 'statuses',
                items: data.map((item) => {
                    return {
                        value: item.code,
                        label: item.name
                    };
                })
            })
        });
    }
}

export const getApplicantQualificationTypes = () => {
    return (dispatch, getState) => {
        const state = getState();
        return API.fetch({
            url: '/applications-permits/applicant/qualification/types',
            method: 'GET',
            params: { token: get(state, 'applicationsPermits.apLaserRequest.token') }
        }).then(({ data }) => {
            dispatch({
                type: TYPE.AP_APPLICANT_QUALIFICATION_TYPES,
                id: 'apRequest',
                path: 'qualificationTypes',
                items: data.map((item) => {
                    return {
                        value: item.code,
                        label: item.name
                    };
                })
            })
        });
    }
}

export const getApplicantQualifications = () => {
    return (dispatch, getState) => {
        const state = getState();
        return API.fetch({
            url: '/applications-permits/applicant/qualifications',
            method: 'GET',
            params: { token: get(state, 'applicationsPermits.apLaserRequest.token') }
        }).then(({ data }) => {
            dispatch({
                type: TYPE.AP_APPLICANT_QUALIFICATIONS,
                id: 'apRequest',
                path: 'qualifications',
                items: data.map((item) => {
                    return {
                        value: item.code,
                        label: item.name,
                        subtype: item.code === 'bde' ? 2 : null
                    };
                })
            })
        });
    }
}

export const getApplicantSpecialities = () => {
    return (dispatch, getState) => {
        const state = getState();
        return API.fetch({
            url: '/applications-permits/applicant/specialities',
            method: 'GET',
            params: { token: get(state, 'applicationsPermits.apLaserRequest.token') }
        }).then(({ data }) => {
            dispatch({
                type: TYPE.AP_APPLICANT_SPECIALITIES,
                id: 'apRequest',
                path: 'qualifications',
                items: [
                    {
                        value: 'new',
                        label: '-- Add New Speciality --',
                        subtype: 1
                    },
                    ...data.map((item) => {
                        return {
                            value: item.code,
                            label: item.name,
                            subtype: 1
                        };
                    })
                ]
            })
        });
    }
}

export const getApprovedPermitHolders = (location_uuid) => {
    return (dispatch) => {
        return API.fetch({
            url: '/applications-permits/permit-holders',
            method: 'GET',
            params: { location_uuid }
        }).then(({ data }) => {
            dispatch({
                type: TYPE.AP_PERMIT_HOLDERS,
                id: 'apInvite',
                path: 'permitHolders',
                items: data.map((item) => {
                    return {
                        value: item.sub_uuid,
                        label: item.first_name+' '+item.last_name
                    };
                })
            })
        });
    }
}

export const getApprovedContactPersons = (invitation_id) => {
    return (dispatch, getState) => {
        const state = getState();
        return API.fetch({
            url: '/applications-permits/contact-persons',
            method: 'GET',
            params: {
                application_id: invitation_id,
                invitation_id: get(state, 'applicationsPermits.apLaserRequest.invitation_id'),
                token: get(state, 'applicationsPermits.apLaserRequest.token')
            }
        }).then(({ data }) => {
            dispatch({
                type: TYPE.AP_CONTACT_PERSONS,
                id: 'apRequest',
                path: 'contactPersons',
                items: data.map((item) => {
                    return {
                        value: item.sub_uuid,
                        label: item.first_name+' '+item.last_name
                    };
                })
            })
        });
    }
}

export const getPermitInvitationDetails = (invitation_id) => {
    return (dispatch, getState) => {
        const state = getState();
        return API.fetch({
            url: '/applications-permits/invitation-details',
            method: 'GET',
            params: {
                invitation_id: invitation_id ? invitation_id : get(state, 'applicationsPermits.apLaserRequest.invitation_id'),
                token: get(state, 'applicationsPermits.apLaserRequest.token')
            }
        }).then(({ data }) => {
            dispatch({
                type: TYPE.AP_INVITATION_DETAILS,
                id: 'apLaserRequest',
                data: data[0]
            })
        });
    }
}

export const inactivatePermit = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: '/applications-permits/inactivate-permit',
            method: 'POST',
            params: {
                permit_id: id
            }
        }).then(() => {
            dispatch({
                type: 'GRID_RELOAD',
                id: 'apLaserRequestGrid'
            })
        });
    }
}
