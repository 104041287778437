/** @namespace Reducers.documentManagementReducer */

import {IDS as DMS_MANAGE_ACTION_IDS}               from '../components/document-management/manage/dms-manage-action-id.constants';
import {IDS as DMS_IMPORT_ACTION_IDS}               from '../components/document-management/import/dms-import-action-id.constants';
import {PREFIX as DMS_MANAGE_ACTION_PREFIX }        from '../components/document-management/manage/dms-manage-action-type.constants';
import {PREFIX as DMS_IMPORT_ACTION_PREFIX }        from '../components/document-management/import/dms-import-action-type.constants';
import { DmsManageReducer }                         from '../components/document-management/manage/dms-manage.reducer';
import { DmsImportReducer }                         from '../components/document-management/import/dms-import.reducer';

import { dmsManageTabs }                            from "../components/document-management/manage/dms-manage-tabs";
import { dmsImportTabs }                            from "../components/document-management/import/dms-import-tabs";


/**
 * @function documentManagementReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function documentManagementReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            dmsManage: DmsManageReducer(),
            ...dmsManageTabs,

            dmsImport: DmsImportReducer(),
            ...dmsImportTabs,
        };
    }
    switch(action.type) {
    }

    if(action.type.startsWith(DMS_MANAGE_ACTION_PREFIX)
    || action.id && DMS_MANAGE_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, dmsManage: DmsManageReducer(state.dmsManage, action) }
    }

    if(action.type.startsWith(DMS_IMPORT_ACTION_PREFIX)
        || action.id && DMS_IMPORT_ACTION_IDS.hasOwnProperty(action.id.split('.').join('_'))) {
        return { ...state, dmsImport: DmsImportReducer(state.dmsImport, action) }
    }

    return state;
}
