/* @ngInject */
export function VendorGridFilterFactory(GridFilter) {
    function VendorGridFilter() {

        GridFilter.call(this);

        this.serviceType = null;

        this.reset = function() {
            this.serviceType = null;
            GridFilter.prototype.reset();
        };
    }

    VendorGridFilter.prototype = Object.create(GridFilter.prototype);

    return VendorGridFilter;
}
