import watch from 'redux-watch';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';

/**
 * Creates a new InputPasswordController.
 * @class
 * @extends InputController
 */
class InputPasswordController {
    /**
     * @constructor
     * @param $ngRedux
     */
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
        this.unsubscribe = () => {};
    }

    $onInit() {
        if (isUndefined(this.validate))
            this.validate = false;
        if (isUndefined(this.required))
            this.required = false;
        if (isUndefined(this.disabled))
            this.disabled = false;

        this.unsubscribeModule = this.$ngRedux.connect(this.mapModule, {})(this);
        if (this.statePath) this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this), {})(this);

        const watchPassword = watch(this.$ngRedux.getState, [this.module, this.identifier, this.statePath]);
        this.onPassword = this.$ngRedux.subscribe(watchPassword((password) => {
            if (isNull(password)) this.passwordVerify = null;
        }));
    }

    $postLink() {
        this.form.passwordVerify.$validators.equality = modelValue => {
            return this.password === modelValue;
        };
    }

    onChange() {
        this.$ngRedux.dispatch({
            type: 'PASSWORD_CHANGE',
            id: this.identifier,
            path: this.statePath,
            password: this.password
        });
    }

    $onDestroy() {
        this.onPassword();
        this.unsubscribe();
        this.unsubscribeModule();
    }

    mapModule(state) {
        return {
            module: state.navigation.module
        }
    }

    mapState(state) {
        return {
            password: state[this.module][this.identifier][this.statePath]
        }
    }
}

InputPasswordController.$inject = ['$ngRedux'];

export const inputPassword = {
    bindings: {
        identifier: '@',
        statePath: '@',
        name: '@',
        validate: '<',
        required: '<',
        disabled: '<'
    },
    require : {
        form : '^form'
    },
    controller: InputPasswordController,
    template: require('./input-password.html')
};
