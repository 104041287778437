import has from 'lodash/has';

const progressList = ['20%', '40%', '60%', '80%', '100%'];

/**
 * Creates a new ProgressBarController.
 * @class
 * @memberOf module:root/common
 */
class ProgressBarController {
    $onInit() {
        this.style = { width: progressList[this.progress] };
    }

    $onChanges(changes) {
        if (has(changes, 'progress')) {
            this.style = { width: progressList[changes.progress.currentValue] };
        }
    }
}

export const progressBar = {
    bindings: {
        progress: '<',
        label: '<',
    },
    controller: ProgressBarController,
    template: `
        <div layout="column">
            <div layout="row" layout-align="center center">
                <small ng-if="$ctrl.label">{{$ctrl.label}}</small>
            </div>
            <div class="progress-bar color{{$ctrl.progress}}">
                <span ng-style="$ctrl.style"></span>
            </div>
        </div>
    `
};
