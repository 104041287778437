export const drlCtScannerDoseReportState = {
    name: 'app.doseReferenceLevels.ctScanner.doseReport',
    redirectTo: 'app.doseReferenceLevels.ctScanner.doseReport.generator',
    url: '/dose-report',
    component: 'tabs'
};

export const drlCtScannerDoseReportGeneratorState = {
    name: 'app.doseReferenceLevels.ctScanner.doseReport.generator',
    url: '',
    component: 'drlCtScannerDoseReport',
    onExit: $ngRedux => {
        $ngRedux.dispatch({
            type: 'DOSE_REPORT_RESET',
            id: 'drlCtScannerDoseReport'
        });
    }
};
drlCtScannerDoseReportGeneratorState.onExit.$inject = ['$ngRedux'];
