import { generateQRCode } from '../../../../../../common/utils/util.qrcode';
import PersonalDosimetryActions from '../../../../../../components/personal-dosimetry/personal-dosimetry.actions';

/* @ngInject */
export function AdministrationAccountsSiteLocationDetailsController(
    $ngRedux,
    $state,
    $uiRouterGlobals,
    $transitions,
    $timeout,
    $mdDialog,
    AdministrationActions,
    PersonalDosimetryActions,
    AccountService,
    FileActions,
    Notifier,
    NavigationService,
) {
    const ctrl = this;
    let dialog = null;
    let unsubscribe = angular.noop;
    let onEnter = angular.noop;
    let onBeforeExit = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, {
            ...AdministrationActions,
            ...FileActions,
            ...PersonalDosimetryActions,
        })(ctrl);

        const navConfig = NavigationService.getConfig($state.$current);
        ctrl.colorName = navConfig.colorName;

        if (_.get($uiRouterGlobals.params, 'hof', null)) {
            ctrl.getHeadOfficeDetails($uiRouterGlobals.params.hof);
        }

        if (_.has($uiRouterGlobals.params, 'slo')) {
            const slo =_.isUndefined($uiRouterGlobals.params.slo) ? ctrl.stateParams.slo : $uiRouterGlobals.params.slo;
            if (slo) {
                ctrl.getSiteLocationDetails(slo, true);
            } else {
                if ($state.current.name === 'app.administration.accounts.siteLocations.details') {
                    $ngRedux.dispatch({
                        type: 'TAB_ENABLE',
                        id: 'app.administration.accounts.siteLocations',
                        state: ['app.administration.accounts.siteLocations.details']
                    });

                    $timeout(function() {
                        $ngRedux.dispatch({
                            type: 'TAB_SELECT',
                            id: 'app.administration.accounts.siteLocations',
                            state: 'app.administration.accounts.siteLocations.details',
                            stateParams: ctrl.stateParams
                        });
                    }, 200);
                }
            }
        }

        ctrl.getReportProviders('administrationAccountsSiteLocation');

        onBeforeExit = $transitions.onBefore({exiting: 'app.administration.accounts.siteLocations.details'}, function() {
            ctrl.siteLocationForm.$setPristine();
            ctrl.siteLocationForm.$setUntouched();
        });
    };

    ctrl.initQRCode = () => {
        if (ctrl.siteLocation.uuid) {
            generateQRCode('SLO-QRCode', {
                uuid: ctrl.siteLocation.uuid,
                name: ctrl.siteLocation.name,
                action: 'location',
            });
        }
    };

    ctrl.printQRCode = () => {
        AccountService.printQRcode(
            ctrl.siteLocation.uuid,
            ctrl.siteLocation.name,
            'location',
        );
    };

    ctrl.onMonthlyReport = () => {
        ctrl.getMonthlyReport({
            type: 'slo',
            hof: ctrl.headOffice.name,
            slo: ctrl.siteLocation.name,
        }).then(({ data }) => {
            if (data?.doc) {
                ctrl.download(`storage/${data.doc}`);
            } else {
                Notifier.error('Monthly Report is currently not available!');
            }
        });
    };

    ctrl.manageSiteLocation = function() {
        if (ctrl.siteLocationForm.$valid) {
            if (ctrl.siteLocation.uuid) {
                ctrl.updateSiteLocationDetails(ctrl.siteLocation);
            }
            else {
                ctrl.siteLocation.location = $uiRouterGlobals.params.hof;
                ctrl.createSiteLocationDetails(ctrl.siteLocation).then(function() {
                    $state.go($state.current.name, _.assign({}, $uiRouterGlobals.params, {slo: ctrl.siteLocation.uuid}), {notify:false, reload:false});
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            }
        }
        else Notifier.error('Please fill in the form fields');
    };

    ctrl.resendLoginInfoDialog = function(subjectUuid) {
        var confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('This action will reset passwords for all employees from this location and new Login info wil be sent to all employees.')
            .ariaLabel('Resend login info')
            .ok('Resend')
            .cancel('Cancel');

        dialog = $mdDialog.show(confirm).then(function() {
            ctrl.resendLoginInfo(subjectUuid);
        }, angular.noop);
    };

    ctrl.$onDestroy = function() {
        if (dialog) $mdDialog.hide(dialog);
        unsubscribe();
        onEnter();
        onBeforeExit();
    };

    function mapStateToThis(state) {
        return {
            headOffice: state.administration.administrationAccountsHeadOffice,
            siteLocation: state.administration.administrationAccountsSiteLocation.data,
            stateParams: state.administration['app.administration.accounts.siteLocations'].stateParams,
            lock: _.isNull(_.find(state.administration['app.administration.accounts.siteLocations'].tabs, ['state', 'app.administration.accounts.siteLocations.details']).buttons.execute),
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                state.administration.administrationAccountsSiteLocation.title
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
