/**
 * @module root/components/equipment-calibration
 */
import { equipmentCalibrationState } from './equipment-calibration.states';
import { equipmentCalibrationDashboardState } from './equipment-calibration.states';
import * as EQUIPMENT_STATES from './equipment/equipment.states';
import { equipmentCalibration } from './equipment-calibration.component';
import { equipmentCalibrationGrid } from './equipment/grid/equipment-calibration-grid.component';
import { equipmentCalibrationGridDepreciationReport } from './equipment/grid/equipment-calibration-grid-depreciation-report.component';
import { equipmentCalibrationInfo } from './equipment/info/equipment-calibration-info.component';
import { equipmentCalibrationLoan } from './equipment/loan/equipment-calibration-loan.component';
import { equipmentCalibrationLoanHistory } from './equipment/loan-history/equipment-calibration-loan-history.component';
import { equipmentCalibrationLoanHistoryGrid } from './equipment/loan-history/grid/equipment-calibration-loan-history-grid.component';
import { equipmentCalibrationDetails } from './equipment/details/equipment-calibration-details.component';
import { equipmentCalibrationAssetCost } from './equipment/asset-cost/equipment-calibration-asset-cost.component';
import { equipmentCalibrationDocuments } from './equipment/documents/equipment-calibration-documents.component';
import { equipmentCalibrationDocumentsGrid } from './equipment/documents/grid/equipment-calibration-documents-grid.component';
import { equipmentCalibrationDetectorProbe } from './equipment/detector-probe/equipment-calibration-detector-probe.component';
import { equipmentCalibrationDetectorProbeGrid } from './equipment/detector-probe/grid/equipment-calibration-detector-probe-grid.component';
import { equipmentCalibrationDetectors } from './equipment/detectors/equipment-calibration-detectors.component';
import { equipmentCalibrationDetectorsGrid } from './equipment/detectors/grid/equipment-calibration-detectors-grid.component';
import { equipmentCalibrationChambers } from './equipment/chambers/equipment-calibration-chambers.component';
import { equipmentCalibrationChambersGrid } from './equipment/chambers/grid/equipment-calibration-chambers-grid.component';
import { equipmentCalibrationVendors } from './equipment/vendors/equipment-calibration-vendors.component';
import { equipmentCalibrationAlerts } from './equipment/alerts/equipment-calibration-alerts.component';
import EquipmentCalibrationActions from './equipment-calibration.actions';
import EquipmentCalibrationLoanActions from './equipment/loan/equipment-calibration-loan.actions';

export const EQUIPMENT_CALIBRATION_MODULE = angular.module(
    'migration.equipment-calibration',
    []
).config(['$uiRouterProvider', $uiRouter => {
    $uiRouter.stateRegistry.register(equipmentCalibrationState);
    $uiRouter.stateRegistry.register(equipmentCalibrationDashboardState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationAddState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationAddDetailsState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationAddAssetCostState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationAddDocumentsState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationAddDetectorProbeState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationAddDetectorsState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationAddChambersState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationAddVendorsState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationManageState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationManageLoanState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationManageLoanHistoryState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationManageInfoState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationManageListingState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationManageDetailsState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationManageAssetCostState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationManageDocumentsState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationManageDetectorProbeState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationManageDetectorsState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationManageChambersState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationManageVendorsState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationAlertsState);
    $uiRouter.stateRegistry.register(EQUIPMENT_STATES.equipmentCalibrationAlertsListingState);
}]).service({
    EquipmentCalibrationActions,
    EquipmentCalibrationLoanActions
}).component({
    equipmentCalibration,
    equipmentCalibrationGrid,
    equipmentCalibrationGridDepreciationReport,
    equipmentCalibrationLoan,
    equipmentCalibrationInfo,
    equipmentCalibrationLoanHistory,
    equipmentCalibrationLoanHistoryGrid,
    equipmentCalibrationDetails,
    equipmentCalibrationAssetCost,
    equipmentCalibrationDocuments,
    equipmentCalibrationDocumentsGrid,
    equipmentCalibrationDetectorProbe,
    equipmentCalibrationDetectorProbeGrid,
    equipmentCalibrationDetectors,
    equipmentCalibrationDetectorsGrid,
    equipmentCalibrationChambers,
    equipmentCalibrationChambersGrid,
    equipmentCalibrationVendors,
    equipmentCalibrationAlerts,
}).run(['$templateCache', $templateCache => {
    $templateCache.put('app.equipmentCalibration.add.details', '<equipment-calibration-details></equipment-calibration-details>');
    $templateCache.put('app.equipmentCalibration.add.assetCost', '<equipment-calibration-asset-cost></equipment-calibration-asset-cost>');
    $templateCache.put('app.equipmentCalibration.add.documents', '<equipment-calibration-documents></equipment-calibration-documents>');
    $templateCache.put('app.equipmentCalibration.add.detectorProbe', '<equipment-calibration-detector-probe></equipment-calibration-detector-probe>');
    $templateCache.put('app.equipmentCalibration.add.detectors', '<equipment-calibration-detectors></equipment-calibration-detectors>');
    $templateCache.put('app.equipmentCalibration.add.chambers', '<equipment-calibration-chambers></equipment-calibration-chambers>');
    $templateCache.put('app.equipmentCalibration.add.vendors', '<equipment-calibration-vendors></equipment-calibration-vendors>');
    $templateCache.put('app.equipmentCalibration.manage.listing', '<equipment-calibration></equipment-calibration>');
    $templateCache.put('app.equipmentCalibration.manage.loan', '<equipment-calibration-loan></equipment-calibration-loan>');
    $templateCache.put('app.equipmentCalibration.manage.loanHistory', '<equipment-calibration-loan-history></equipment-calibration-loan-history>');
    $templateCache.put('app.equipmentCalibration.manage.info', '<equipment-calibration-info></equipment-calibration-info>');
    $templateCache.put('app.equipmentCalibration.manage.details', '<equipment-calibration-details></equipment-calibration-details>');
    $templateCache.put('app.equipmentCalibration.manage.assetCost', '<equipment-calibration-asset-cost></equipment-calibration-asset-cost>');
    $templateCache.put('app.equipmentCalibration.manage.documents', '<equipment-calibration-documents></equipment-calibration-documents>');
    $templateCache.put('app.equipmentCalibration.manage.detectorProbe', '<equipment-calibration-detector-probe></equipment-calibration-detector-probe>');
    $templateCache.put('app.equipmentCalibration.manage.detectors', '<equipment-calibration-detectors></equipment-calibration-detectors>');
    $templateCache.put('app.equipmentCalibration.manage.chambers', '<equipment-calibration-chambers></equipment-calibration-chambers>');
    $templateCache.put('app.equipmentCalibration.manage.vendors', '<equipment-calibration-vendors></equipment-calibration-vendors>');
    $templateCache.put('app.equipmentCalibration.alerts.listing', '<equipment-calibration-alerts></equipment-calibration-alerts>');
    $templateCache.put('ecGridDepreciationReport', '<equipment-calibration-grid-depreciation-report></equipment-calibration-grid-depreciation-report>');
    $templateCache.put('./equipment-calibration-grid-filter.html', require('./equipment/grid/equipment-calibration-grid-filter.html'));
    $templateCache.put('./equipment-calibration-grid-filter-controls.html', require('./equipment/grid/equipment-calibration-grid-filter-controls.html'));
    $templateCache.put('./equipment-calibration-documents-grid-filter.html', require('./equipment/documents/grid/equipment-calibration-documents-grid-filter.html'));
    $templateCache.put('./equipment-calibration-loan-history-grid-filter.html', require('./equipment/loan-history/grid/equipment-calibration-loan-history-grid-filter.html'));
}]);
