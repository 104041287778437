export function AccDocumentFactory() {
    return function AccDocument() {
        this.id = null; //ado_id from database
        this.locationUuid = null; //site location to which this document is relevant
        this.docDcrNumber = null;
        this.docNumber = null; //every hospital has internal doc number
        this.title = null;
        this.version = null;
        this.writtenBy = null;
        this.dateEdited = null;
        this.approvedBy = null;
        this.dateApproved = null;
        this.trainingDocumentId = null; //rdo_id
        this.template = null;
        this.docVariant = null;
        this.docPath = null; // document storage path
        this.validUntil = null; // document valid until date
        this.status = null; //like pending, ...
        this.statusComment = null; //like pending, ...
        this.comment = null; //description of document
        this.misc = null; //something specific about document
        this.trainingType = null; //Training type of document, like rspp
    };
}
