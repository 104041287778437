import { locale } from '../../bootstrap.json';
import en_AU from '../../../i18n/locale-en_AU.json';
import en_US from '../../../i18n/locale-en_US.json';
import fr_CA from '../../../i18n/locale-fr_CA.json';

export const initLocale = ($translateProvider) => {
    $translateProvider.translations('en_AU', en_AU);
    $translateProvider.translations('en_US', en_US);
    $translateProvider.translations('fr_CA', fr_CA);
    $translateProvider.useSanitizeValueStrategy('sanitizeParameters').preferredLanguage(locale);

};

const I18N = (() => {
    let $filter;
    let instance;
    return {
        getInstance: () => {
            if (!instance) {
                instance = {
                    init: (filter) => {
                        $filter = filter;
                    },
                    translate: (key, fallbackValue = null) => {
                        if(!$filter)
                            throw new Error('I18N not initialized');

                        if(key.includes(' ') === false
                        && key.includes('.')
                        && key === key.toUpperCase()) {
                            const translated = $filter('translate')(key);
                            if(fallbackValue && translated === key)
                                return fallbackValue
                            return translated
                        }
                        else
                            return key
                    },
                };
            }
            return instance;
        },
    };
})();

export default I18N.getInstance();
