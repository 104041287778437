import API from '../../common/http/api.fetch';
import * as TYPE from './employees.actions.types';
import get from 'lodash/get';

export const getEmpLicenceDetails = (licence_id) => {
    return (dispatch, getState) => {
        const state = getState();
        return API.fetch({
            url: '/licence/'+licence_id,
            method: 'GET',
            params: {
                token: get(state, 'employees.empLicence.token')
            }
        }).then(({ data }) => {
            dispatch({
                type: TYPE.EMP_LICENCE_DETAILS,
                id: 'empLicence',
                data: data[0]
            })
        });
    }
}

export const approveLicence = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: '/employee/licence/approve',
            method: 'POST',
            params: {
                lic_id: id
            }
        }).then(() => {});
    }
}

export const disapproveLicence = (id, comment) => {
    return (dispatch) => {
        return API.fetch({
            url: '/employee/licence/disapprove',
            method: 'POST',
            params: {
                lic_id: id,
                comment: comment
            }
        }).then(() => {});
    }
}
