/** @module root/components/reporting/rep-monthly */
import * as STATES                  from './rep-monthly.states';
import { IDS }                      from './rep-monthly-action-id.constants';

import { repMonthly }               from './rep-monthly.component'
import { repMonthlyGrid }           from './grid/rep-monthly-grid.component'
import { repMonthlyDetails }        from './details/rep-monthly-details.component'


export const REP_MONTHLY_MODULE = angular.module(
    'migration.reporting.monthly',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values(STATES).map(
        state => stateRegistry.register(state)
    );
}]).component({
    repMonthly,
    repMonthlyGrid,
    repMonthlyDetails,

}).run(['$templateCache', ({ put }) => {
    put(IDS.app_reporting_monthly_list,       '<rep-monthly></rep-monthly>');
    put(IDS.app_reporting_monthly_details,    '<rep-monthly-details></rep-monthly-details>');

    put('./rep-monthly-grid-filter.html', require('./grid/rep-monthly-grid-filter.html'));
    // put('./rep-monthly-details.html', require('./details/rep-monthly-details.html'));
    // put('./rep-monthly-grid-filter-controls.html', require('./rep-monthly-grid-filter-controls.html'));
}]);
