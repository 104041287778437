/* @ngInject */
export function SafetyTrainingVideoFactory(SafetyTrainingPresentation) {
    return function SafetyTrainingVideo() {
        this.id = null;
        this.presentation =  new SafetyTrainingPresentation();
        this.uri = null;
        this.presentationOrder = null;
        this.name = null;
        this.description = null;
        this.videoSectionNumber = null; //section/chapter to which video belongs
    };
}
