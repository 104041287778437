import { GridController } from '../../../../../common/grid/grid.controller';
import { variant } from '../../../../../bootstrap.json';
import { MyQMO } from '../../../../../common/utils/util.constants';

/* @ngInject */
export function AdministrationAccountsEquipmentGridController(
    $controller,
    $ngRedux,
    $state,
    NavigationService,
    EquipmentActions,
    EquipmentCalibrationActions,
) {
    const ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'administration';
        ctrl.identifier = 'administrationAccountsEquipmentGrid';
        ctrl.autoload = false;
        ctrl.filterComponent = false;
        ctrl.exportComponent = false;

        ctrl.columns = [
            {title: '#',                mapper: null},
            {title: 'Equipment ID',     mapper: 'user_friendly_id'},
            {title: 'Category',         mapper: 'category.name'},
            {title: 'Manufacturer',     mapper: 'manufacturer.name'},
            {title: 'Model',            mapper: 'model.name'},
            {title: 'Serial number',    mapper: 'serial'},
            {title: 'Site Location',    mapper: 'account.name'},
            {title: '',                 mapper: 'warning'},
            {title: '',                 mapper: 'critical'}
        ];

        ctrl.actions = EquipmentActions;
        if (variant === MyQMO) {
            ctrl.actions = EquipmentCalibrationActions;
        }
        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), ctrl.actions)(ctrl);
        ctrl.dataAction = ctrl.getEquipmentGrid;
        ctrl.filter.identifier = ctrl.identifier;
        ctrl.filter.type = variant === MyQMO ? 74 : null;

        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
