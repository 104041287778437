/**
 * Creates a new GridImportController.
 * @class
 * @memberOf module:root/common/grid
 * @see {@link https://docs.angularjs.org/guide/component|AngularJS Component}
 */
class GridImportController {
    /**
     * @constructor
     * @param $ngRedux {service} {@link https://github.com/angular-redux/ng-redux|ng-redux}
     * @param $state {service} {@link https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html|UI-Router StateService}
     * @param $scope {object} {@link https://docs.angularjs.org/guide/scope|AngularJS $scope}
     * @param $filter
     * @param Notifier {service} {@link Notifier}
     *
     */
    constructor($ngRedux, $state, $scope, $filter, Notifier) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$scope = $scope;
        this.$filter = $filter;
        this.Notifier = Notifier;
        this.disabled = false;
    }

    onSave(event) {
        this.Notifier.info('Import data is in progress!', false);
        this.importService(event).then(response => {
            this.Notifier.success('Import equipment data is finished');
            this.$ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: this.gridIdentifier,
            });
        });
    }
}

GridImportController.$inject = ['$ngRedux', '$state', '$scope', '$filter', 'Notifier'];

export const gridImport = {
    bindings: {
        filter: '<',
        gridIdentifier: '<',
        importService: '<'
    },
    controller: GridImportController,
    template: require('./grid-import.html')
};
