/** @module root/components/accreditation/acc-documents */
import * as STATES                          from './acc-documents.states';
import { IDS }                              from './acc-documents-action-id.constants';

import { accDocuments }              from './acc-documents.component'
import { accDocumentsGrid }          from './acc-documents-grid.component'
import { accDocumentDetails }        from './details/acc-document-details.component'
import { accDocumentAdd }            from './add/acc-document-add.component'


export const ACC_DOCUMENTS_MODULE = angular.module(
    'migration.accreditation.acc-documents',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values(STATES).map(
        state => stateRegistry.register(state)
    );
}]).component({
    accDocuments,
    accDocumentsGrid,
    accDocumentDetails,
    accDocumentAdd,

}).run(['$templateCache', ({ put }) => {
    put(IDS.app_accreditation_scheme_acc_documents_list,       '<acc-documents></acc-documents>');
    put(IDS.app_accreditation_scheme_acc_documents_details,    '<acc-document-details></acc-document-details>');
    put(IDS.app_accreditation_scheme_acc_documents_add,        '<acc-document-add></acc-document-add>');

    // put('./acc-documents-grid-filter.html', require('./acc-documents-grid-filter.html'));
    // put('./acc-documents-grid-filter-controls.html', require('./acc-documents-grid-filter-controls.html'));
}]);
