import QRCode from 'qrcode';

export const generateQRCode = (id, data) => {
    const canvas = document.getElementById(id);
    if (canvas) {
        QRCode.toCanvas(canvas, JSON.stringify(data), (error) => {
            error && console.error(error)
        });
    }
};
