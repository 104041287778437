import { TabsReducer } from '../../../../common/tabs/tabs.reducer';
import {IDS} from "../settings-action-id.constants";

export const hofRequiredDocsTabs = {
    [IDS.app_accreditation_scheme_settings_hof_required_docs]: Object.assign(TabsReducer(), {
        tabs: [
            {
                state: IDS.app_accreditation_scheme_settings_hof_required_docs_list,
                title: 'ACCREDITATION.SETTINGS.HOF_REQUIRED_DOCS.LIST.TAB',
                visible: true,
                disabled: false,
                loading: false,
            },
            {
                state: IDS.app_accreditation_scheme_settings_hof_required_docs_details,
                title: 'ACCREDITATION.SETTINGS.HOF_REQUIRED_DOCS.DETAILS.TAB',
                visible: false,
                disabled: false,
                loading: false,
            },
            {
                state: IDS.app_accreditation_scheme_settings_hof_required_docs_add,
                title: 'ACCREDITATION.SETTINGS.HOF_REQUIRED_DOCS.ADD.TAB',
                visible: false,
                disabled: false,
                loading: false,
            },
        ],
    }),
};
