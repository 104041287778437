import watch from 'redux-watch';

export const loader = {
    bindings: {
        size: '@',
        color: '@',
    },
    /* @ngInject */
    controller: function($ngRedux, $rootScope, $timeout) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.blades = [...Array(8).keys()];
            this.class = ['spinner', 'spinner-large'];
            this.size === 'small' && this.class.pop();

            this.unsubscribe = $ngRedux.connect(({ navigation }) => ({
                loading: navigation.loading,
                style: { 'background-color': this.color || `#${navigation.color}` }
            }), {})(this);

            this.container = this.loading;
            this.spinner = this.loading;

            const watchLoading = watch($ngRedux.getState, 'navigation.loading');
            this.onLoading = $ngRedux.subscribe(watchLoading((loading) => {
                if (loading) {
                    this.container = true;
                    this.spinner = true;
                } else {
                    this.spinner = false;
                    $timeout(() => {
                        this.container = false;
                    }, 1000);
                }
            }));
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            this.unsubscribe();
            this.onLoading();
        };
    },
    template: `
        <div ng-if="$ctrl.container" class="spinner-container" layout="column">
            <div ng-if="$ctrl.spinner" ng-class="$ctrl.class">
                <div ng-repeat="item in $ctrl.blades"
                     class="spinner-blade"
                     ng-style="$ctrl.style">
                </div>
            </div>
            <div ng-if="$ctrl.spinner" class="spinner-info">
                <small>Please wait loading...</small>
            </div>
        </div>
    `,
};
