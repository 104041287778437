import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';
import forOwn from 'lodash/forOwn';
import isArray from 'lodash/isArray';

export function GridFilterReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            offset: 0,
            limit: 10,
            page: 1
        }
    }

    switch (action.type) {
        case 'GRID_INIT':
            return Object.assign({}, state, {
                offset: 0,
                page: 1
            });
        case 'GRID_INIT_FILTER':
            if (typeof action.input != 'undefined') {
                return set(Object.assign({}, state), action.path, action.input);
            } else {
                return Object.assign({}, state, action.filter);
            }
        case 'GRID_RESET':
            return Object.assign({}, state, {
                page: 1
            });
        case 'GRID_RESET_FILTER':
            const filter = Object.assign({}, state);
            forOwn(filter, (value, key) => {
                if (action.exclude) {
                    if (!action.exclude.includes(key)) {
                        filter[key] = isArray(value) ? [] : null;
                    }
                } else {
                    filter[key] = isArray(value) ? [] : null;
                }
            });
            return Object.assign(filter, {
                offset: 0,
                limit: 10,
                page: 1
            });
        case 'PAGINATE':
            return merge({...state,
                offset: (get(action, 'page', 1) - 1) * state.limit,
                page: get(action, 'page', 1)
            }, action.filter)
        default:
            return state;
    }
}
