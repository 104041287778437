import { GridController, gridTpl } from '../../../../common/grid/grid.controller';

class ReportingCnscGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, ReportingActions, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.ReportingActions = ReportingActions;
        this.grid_filter = $filter
    }

    $onInit() {
        this.module = 'reporting';
        this.identifier = 'reportingCnscGrid';
        this.autoload = false;
        this.filterTemplate = './reporting-cnsc-grid-filter.html';
        this.exportComponent = false;
        this.filterOpen = true;
        this.message = 'Select Head Office';

        this.columns = [
            {title: '#',                mapper: null},
            {title: 'Site location',    mapper: 'siteLocation'},
            {title: 'Licence number',   mapper: 'regIdNo'},
            {title: 'Licence type',     mapper: 'type.name'},
            {title: 'Issued',           mapper: 'getIssuedDate'},
            {title: 'Expires',          mapper: 'getExpiryDate'}
        ];

        this.buttons = [
            {label: 'CNSC Report', class: 'btnGrid', callback: this.onGetCnsc},
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.ReportingActions)(this);
        this.dataAction = this.getLicences;

        this.init();
    }

    $onDestroy() {
        this.destroy();
        this.$ngRedux.dispatch({
            type: 'GRID_RESET',
            id: 'reportingCnscGrid',
        });
    };
}

ReportingCnscGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'ReportingActions', '$filter'];

export const reportingCnscGrid = {
    bindings: {
        identifier: '@',
        onReset: '&',
        onGetCnsc: '&',
    },
    controller: ReportingCnscGridController,
    ...gridTpl
};
