/* @ngInject */
export function SafetyPlanVideoFactory(SafetyPlanPresentation) {
    return function SafetyPlanVideo() {

        this.id = null;
        this.presentation =  new SafetyPlanPresentation();
        this.uri = null;
        this.presentationOrder = null;
        this.name = null;
        this.description = null;
        this.videoSectionNumber = null; //sectin/chapter to which video belongs
    };
}
