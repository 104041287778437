angular
    .module('components.administration')
    .component('administration', {
        templateUrl: './administration.html',
        controller: 'AdministrationController'
    })
    .config(function($stateProvider) {
        $stateProvider
            .state('app.administration.accounts.headOfficesEquipment', {
                url: '/equipment?hof',
                component: 'administrationAccountsHeadOfficeEquipment'
            })
            .state('app.administration.accounts.siteLocations.details', {
                url: '/details?slo',
                component: 'administrationAccountsSiteLocationDetails'
            })
            .state('app.administration.accounts.siteLocations.address', {
                url: '/address?slo',
                component: 'administrationAccountsSiteLocationAddress'
            })
            .state('app.administration.accounts.siteLocations.invoiceAddress', {
                url: '/invoice-address?slo',
                component: 'administrationAccountsSiteLocationInvoiceAddress'
            })
            .state('app.administration.accounts.siteLocations.licences', {
                url: '/licences?slo',
                component: 'administrationAccountsSiteLocationLicences'
            })
            .state('app.administration.accounts.siteLocations.licenceDetails', {
                url: '/licence?slo&lic',
                component: 'administrationAccountsSiteLocationLicenceDetails',
                onExit: function($ngRedux) {
                    $ngRedux.dispatch({
                        type: 'TAB_HIDE',
                        id: 'app.administration.accounts.siteLocations',
                        state: ['app.administration.accounts.siteLocations.licenceDetails']
                    });
                }
            })
            .state('app.administration.accounts.siteLocations.equipment', {
                url: '/equipment?slo',
                component: 'administrationAccountsSiteLocationEquipment'
            })
            .state('app.administration.accounts.siteLocations.employees', {
                url: '/employees?slo',
                component: 'administrationAccountsSiteLocationEmployees'
            })
            .state('app.administration.accounts.departments.details', {
                url: '/details?dep',
                component: 'administrationAccountsDepartmentDetails'
            })
            .state('app.administration.accounts.departments.equipment', {
                url: '/equipment?dep',
                component: 'administrationAccountsDepartmentEquipment'
            })
            .state('app.administration.accounts.departments.employees', {
                url: '/employees?dep',
                component: 'administrationAccountsDepartmentEmployees'
            })
            .state('app.administration.accounts.rooms', {
                redirectTo: 'app.administration.accounts.rooms.list',
                url: '/rooms?hof&slo&dep'
            })
            .state('app.administration.accounts.rooms.list', {
                url: '',
                component: 'administrationAccountsRooms',
                onEnter: function($ngRedux) {
                    $ngRedux.dispatch({
                        type: 'TAB_DISABLE',
                        id: 'app.administration.accounts.rooms',
                        state: [
                            'app.administration.accounts.rooms.details',
                            'app.administration.accounts.rooms.equipment'
                        ]
                    });
                }
            })
            .state('app.administration.accounts.rooms.details', {
                url: '/details?room',
                component: 'administrationAccountsRoomDetails'
            })
            .state('app.administration.accounts.rooms.equipment', {
                url: '/equipment?room',
                component: 'administrationAccountsRoomEquipment'
            })
            .state('app.administration.siteLocation', {
                url: '/accounts/add?hof',
                template: require('./views/accounts/accounts-site-location-add.html'),
                controller: 'AdministrationAddAccountController',
                params: {
                    title: 'Administration » Accounts » Add Site Location',
                    navConfig: 'app.administration.accounts'
                }
            })
            .state('app.administration.vendors', {
                abstract: true,
                url: '/vendors'
            })
            .state('app.administration.vendors.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.administration.vendors.add', {
                url: '/add',
                template: require('./views/vendors/vendors.html'),
                controller: 'VendorAddController'
            })
            .state('app.administration.vendors.manage', {
                url: '/manage',
                template: require('./views/vendors/vendors.html'),
                controller: 'VendorManageController'
            })
            .state('app.administration.access', {
                abstract: true,
                url: '/access'
            })
            .state('app.administration.access.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.administration.access.subjectTypes', {
                url: '/subject-types',
                template: require('./views/access/access-subject-types.html'),
                controller: 'AccessSubjectTypesController'
            })
            .state('app.administration.access.menu', {
                url: '/menu',
                template: require('./views/access/access-menu.html'),
                controller: 'AccessMenuController'
            })
            .state('app.settings', {
                url: '/settings',
                template: require('./views/settings/settings.html'),
                controller: 'SettingsController',
                params: {
                    title: 'My Account'
                }
            });
    });
