import {TYPES as ACC_TYPES} from "../accreditation-action-type.constants";
import {IDS} from "./settings-action-id.constants";
import {TAB_HIDE, TAB_DISABLE} from '../../../common/tabs/tabs.constants';

export const settingsAbstractState = {
    name: IDS.app_accreditation_scheme_settings,
    abstract: true,
    redirectTo: IDS.app_accreditation_scheme_settings_dashboard,
    url: '/:itemCode',
}


export const settingsDashboardState = {
    name: IDS.app_accreditation_scheme_settings_dashboard,
    url: '/settings',
    component: 'dashboard',
};


export const schemeDetailsState = {
    name: IDS.app_accreditation_scheme_settings_scheme_details,
    url: '/scheme-details',
    component: 'schemeDetails',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: ACC_TYPES.SIMPLE_STD_TREE_UNSELECT_ALL,
        });
    }
};
schemeDetailsState.onEnter.$inject = ['$ngRedux'];


export const enabledAccountsTabsState = {
    name: IDS.app_accreditation_scheme_settings_enabled,
    redirectTo: IDS.app_accreditation_scheme_settings_enabled_head_offices,
    url: '/enabled',
    component: 'tabs'
};

export const enabledAccountsHeadOfficesState = {
    name: IDS.app_accreditation_scheme_settings_enabled_head_offices,
    url: '/headOffices',
    component: 'headOffices',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: IDS.app_accreditation_scheme_settings_enabled,
            state: [
                IDS.app_accreditation_scheme_settings_enabled_site_locations,
            ]
        });
        $ngRedux.dispatch({
            type: ACC_TYPES.SIMPLE_STD_TREE_UNSELECT_ALL,
        });
    }
};
enabledAccountsHeadOfficesState.onEnter.$inject = ['$ngRedux'];

export const enabledAccountsSiteLocationsState = {
    name: IDS.app_accreditation_scheme_settings_enabled_site_locations,
    url: '/siteLocations',
    component: 'siteLocations',
};

export const sectionsState = {
    name: IDS.app_accreditation_scheme_settings_sections,
    url: '/sections',
    component: 'sections',
};


export const defaultDocumentsTabsState = {
    name: IDS.app_accreditation_scheme_settings_default_documents,
    redirectTo: IDS.app_accreditation_scheme_settings_default_documents_list,
    url: '/default-documents',
    component: 'tabs'
};


export const defaultDocumentsListState = {
    name: IDS.app_accreditation_scheme_settings_default_documents_list,
    url: '/list',
    // component: 'assessment',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: IDS.app_accreditation_scheme_settings_default_documents,
            state: [
                IDS.app_accreditation_scheme_settings_default_documents_add,
                IDS.app_accreditation_scheme_settings_default_documents_details,
                IDS.app_accreditation_scheme_settings_default_documents_versions,
            ]
        });
    }
};
defaultDocumentsListState.onEnter.$inject = ['$ngRedux'];

export const defaultDocumentsAddState = {
    name: IDS.app_accreditation_scheme_settings_default_documents_add,
    url: '/add',
    // component: 'accDefaultDocumentsDetails',
};

export const defaultDocumentsDetailsState = {
    name: IDS.app_accreditation_scheme_settings_default_documents_details,
    url: '/details',
    // component: 'accDefaultDocumentsDetails',
};

export const headOfficeDocumentsTabsState = {
    name: IDS.app_accreditation_scheme_settings_hof_required_docs,
    redirectTo: IDS.app_accreditation_scheme_settings_hof_required_docs_list,
    url: '/hof-required-docs',
    component: 'tabs'
};

export const headOfficeDocumentsListState = {
    name: IDS.app_accreditation_scheme_settings_hof_required_docs_list,
    url: '/list',
    // component: 'headOfficeDocumentsList',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: IDS.app_accreditation_scheme_settings_hof_required_docs,
            state: [
                IDS.app_accreditation_scheme_settings_hof_required_docs_details,
                IDS.app_accreditation_scheme_settings_hof_required_docs_add
            ]
        });
    }
};
headOfficeDocumentsListState.onEnter.$inject = ['$ngRedux'];

export const headOfficeDocumentsDetailState = {
    name: IDS.app_accreditation_scheme_settings_hof_required_docs_details,
    url: '/details',
    // component: 'headOfficeDocumentsDetails',
};



export const headOfficeDocumentsAddState = {
    name: IDS.app_accreditation_scheme_settings_hof_required_docs_add,
    url: '/add',
    // component: 'headOfficeDocumentsDetails',
};



export const siteLocationDocumentsTabsState = {
    name: IDS.app_accreditation_scheme_settings_slo_required_docs,
    redirectTo: IDS.app_accreditation_scheme_settings_slo_required_docs_list,
    url: '/slo-required-doc',
    component: 'tabs'
};

export const siteLocationDocumentsListState = {
    name: IDS.app_accreditation_scheme_settings_slo_required_docs_list,
    url: '/list',
    // component: 'siteLocationDocumentsList',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: IDS.app_accreditation_scheme_settings_slo_required_docs,
            state: [
                IDS.app_accreditation_scheme_settings_slo_required_docs_details,
                IDS.app_accreditation_scheme_settings_slo_required_docs_add
            ]
        });
    }
};
siteLocationDocumentsListState.onEnter.$inject = ['$ngRedux'];

export const siteLocationDocumentsDetailState = {
    name: IDS.app_accreditation_scheme_settings_slo_required_docs_details,
    url: '/details',
    // component: 'siteLocationDocumentsDetails',
};



export const siteLocationDocumentsAddState = {
    name: IDS.app_accreditation_scheme_settings_slo_required_docs_add,
    url: '/add',
    // component: 'siteLocationDocumentsDetails',
};

