import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAddAccountController($scope, $uiRouterGlobals, $rootScope, $http, API_BASE, $mdDialog, Account, Address, Contact, AccountService, FileUploader, Notifier, SUBJECT_TYPE) {

    var ADD_NEW_INVOICE_ADDRESS = "0";
    var COPY_PARENT_TO_INVOICE_ADDRESS = "1";
    var COPY_LOCATION_TO_INVOICE_ADDRESS = "2";

    $scope.selectedIndex = 0;
    $scope.tabs = [
        {title: 'Account Data'},
        {title: 'Site Radiation Safety Officer'},
        {title: 'Assistant Site RSO'},
        {title: 'Invoice Address'},
        {title: ' Shipping Address'}
    ];

    $scope.hofUuid = $uiRouterGlobals.params.hof;

    $scope.fourthTab = 'fourthTab';
    $scope.fifthTab = 'fifthTab';

    var skip = [false,false,false,false,false];

    $scope.$watch('selectedIndex', function (current, old)
    {
        if($scope.selectedIndex !== 0)
            retrieveContacts();

        if($scope.selectedIndex == 1 )
        {
            $scope.$broadcast('show-errors-check-validity','firstTab');
            if(!isFirstTabValid())
                $scope.selectedIndex = 0;
        }
        else if($scope.selectedIndex == 2)
        {
            if(skip[1])
                return;

            if(!$scope.oldPrimary)
            {
                $scope.$broadcast('show-errors-check-validity', 'secondTab');
                if (!isSecondTabValid())
                    $scope.selectedIndex = 1;
            }
            else
            {

                if($scope.primarySelectedItem == null)
                {
                    //there is a bug in MD that prevents usability of this
                    //TODO: update Material Design
                    $scope.userForm.primaryContact.$setValidity('required', false);
                    $scope.userForm.primaryContact.$setDirty();
                    $scope.userForm.primaryContact.$setTouched();

                    $scope.selectedIndex = 1;
                }
            }

        }
        else if($scope.selectedIndex == 3)
        {
            if(skip[2])
                return;

            if(!$scope.oldSecondary)
            {
                $scope.$broadcast('show-errors-check-validity', 'thirdTab');
                if (!isThirdTabValid())
                    $scope.selectedIndex = 2;
            }
            else
            {
                if($scope.secondarySelectedItem == null)
                {
                    $scope.userForm.secondaryContact.$setValidity('required', false);
                    $scope.userForm.secondaryContact.$setDirty();
                    $scope.userForm.secondaryContact.$setTouched();

                    $scope.selectedIndex = 2;
                }
            }
        }
        else if($scope.selectedIndex == 4 )
        {
            $scope.$broadcast('show-errors-check-validity','fourthTab');
            if(!isFourthTabValid())
                $scope.selectedIndex = 3;
        }
        else if($scope.selectedIndex == 5 )
        {
            $scope.$broadcast('show-errors-check-validity','fifthTab');
            if(!isFifthTabValid())
                $scope.selectedIndex = 4;
        }
    });

    function retrieveContacts()
    {
        if(typeof $scope.account.location.uuid == 'undefined')
            return false;

        AccountService.fetchRscCandidates($scope.account.location.uuid,SUBJECT_TYPE.SITE_RSC)
            .then(function (data) {
                if(data!=null){
                    $scope.primaryContacts = data;
                    $scope.secondaryContacts = data;
                }
            });
    }

    function isFirstTabValid()
    {
        return (!$scope.showHeadOffices || $scope.userForm.accountLocation.$valid)
            && $scope.userForm.accountName.$valid
            && $scope.userForm.centreType.$valid
            && $scope.userForm.accountEmail.$valid
            && $scope.userForm.accountPhone.$valid;
    }

    function isSecondTabValid()
    {
        if($scope.userForm.primaryPassword.$viewValue !== $scope.userForm.primaryRepassword.$viewValue)
            $scope.userForm.primaryRepassword.$setValidity('match',false);
        else
            $scope.userForm.primaryRepassword.$setValidity('match',true);

        return $scope.userForm.primaryUsername.$valid
            && $scope.userForm.primaryPassword.$valid
            && $scope.userForm.primaryRepassword.$valid
            && $scope.userForm.primaryFirst.$valid
            && $scope.userForm.primaryLast.$valid
            && $scope.userForm.primaryPhone.$valid
            && $scope.userForm.primaryFax.$valid
            && $scope.userForm.primaryEmail.$valid;
    }

    function isThirdTabValid()
    {
        if($scope.userForm.secondaryPassword.$viewValue !== $scope.userForm.secondaryRepassword.$viewValue)
            $scope.userForm.secondaryRepassword.$setValidity('match',false);
        else
            $scope.userForm.secondaryRepassword.$setValidity('match',true);

        return $scope.userForm.secondaryUsername.$valid
            && $scope.userForm.secondaryPassword.$valid
            && $scope.userForm.secondaryRepassword.$valid
            && $scope.userForm.secondaryFirst.$valid
            && $scope.userForm.secondaryLast.$valid
            && $scope.userForm.secondaryPhone.$valid
            && $scope.userForm.secondaryFax.$valid
            && $scope.userForm.secondaryEmail.$valid;
    }

    function isFourthTabValid()
    {
        return $scope.userForm.addressFirst.$valid
            && $scope.userForm.addressSecond.$valid
            && $scope.userForm.addressThird.$valid
            && $scope.userForm.suburb.$valid
            && $scope.userForm.postcode.$valid
            && !_.isNull(_.get($scope.shipping.country, 'id', null))
            && !_.isNull(_.get($scope.shipping.country.state, 'id', null));
    }

    function isFifthTabValid()
    {
        return ($scope.userForm.addressFirstInvoice.$valid
            && $scope.userForm.addressSecondInvoice.$valid
            && $scope.userForm.addressThirdInvoice.$valid
            && $scope.userForm.suburbInvoice.$valid
            && $scope.userForm.postcodeInvoice.$valid)
            && !_.isNull(_.get($scope.invoice.country, 'id', null))
            && !_.isNull(_.get($scope.invoice.country.state, 'id', null));
    }

    $scope.nextTab = function ()
    {
        skip[$scope.selectedIndex] = false;
        $scope.selectedIndex += 1;
    };

    $scope.previousTab = function ()
    {
        $scope.selectedIndex -= 1;
    };

    $scope.skipTab = function ()
    {
        skip[$scope.selectedIndex] = true;
        $scope.selectedIndex += 1;
    };

    $scope.newUsers = [{id: 0, oldSelected: true}];
    $scope.lastInsertedId = 0;
    $scope.oldPrimary = false;
    $scope.newPrimaryUser = new Contact();

    $scope.oldSecondary = false;
    $scope.newSecondaryUser = new Contact();


    $scope.invoiceAddressRadio = 0;

    $scope.account = new Account();

    $scope.accounts = [];
    AccountService.fetchAccounts().then(function(data){
        $scope.accounts = data;
    });

    $scope.center_types = [];
    AccountService.fetchCenterTypes().then(function(data){
        $scope.center_types = data;
    });

    $scope.uploader = new FileUploader({
        onSuccessItem: function(item, response, status, headers){
            $scope.saveDisabled = false;
            $scope.account.purchaseOrderFile = response.path;
        },
        onBeforeUploadItem: function(item)
        {
            $scope.account.purchaseOrderFile = null;
            $scope.saveDisabled = true;
        },
        onCancelItem: function(){$scope.saveDisabled = false},
        onErrorItem: function(){$scope.saveDisabled = false},
        autoUpload: true
    });

    $scope.options = {
        url:API_BASE+"/file/stream",
        alias: "upstream",
        queueLimit: 1,
        headers: {
            "X-Authorization": $http.defaults.headers.common['X-Authorization'],
            "X-Token": $http.defaults.headers.common['X-Token']
        }
    };

    $scope.clickOnUpload = function(){
        if ($scope.uploader.queue.length > 0)
            return;

        angular.element("#flu").trigger("click");
    };

    $scope.clients = [];
    $scope.showHeadOffices = false;
    $scope.primaryContacts = [];
    $scope.secondaryContacts = [];
    $scope.shipping = new Address();
    $scope.invoice = new Address();
    $scope.sameAsInvoice = false;


    $scope.primarySearch = function (query) {
        var results = query ? $scope.primaryContacts.filter(createFilterFor(query)) : $scope.primaryContacts;
        return results;
    };

    $scope.primarySearchTextChange = function (item) {
        if (item == "" || item == null || typeof item == 'undefined') $scope.oldPrimary = false;
        else $scope.oldPrimary = true;
    };

    $scope.createNewPrimary = function () {
        $scope.primarySearchText = "Create new Site RSO";
        $scope.oldPrimary = false;
        var autoChild = document.getElementById('primaryAutoComplete').firstElementChild;
        var el = angular.element(autoChild);
        el.scope().$mdAutocompleteCtrl.listLeave();
    };

    $scope.createNewSecondary = function () {
        $scope.secondarySearchText = "Create new Assistant Site RSO";
        $scope.oldSecondary = false;
        var autoChild = document.getElementById('secondaryAutoComplete').firstElementChild;
        var el = angular.element(autoChild);
        el.scope().$mdAutocompleteCtrl.listLeave();
    };

    $scope.secondarySearch = function (query) {
        var results2 = query ? $scope.secondaryContacts.filter(createFilterFor(query)) : $scope.secondaryContacts;
        return results2;
    };

    $scope.secondarySearchTextChange = function (item, id) {
        if (item == "" || item == null || typeof item == 'undefined') $scope.oldSecondary = false;
        else $scope.oldSecondary = true;
    };

    $scope.indexOfWithNull = function(value,query)
    {
        return (value === null)?-1:value.toLowerCase().indexOf(query);
    };

    function createFilterFor(query) {
        var lowercaseQuery = query.toLowerCase();
        return function filterFn(item) {
            return (($scope.indexOfWithNull(item.firstName,lowercaseQuery) !== -1
                || $scope.indexOfWithNull(item.lastName,lowercaseQuery) !== -1
                || $scope.indexOfWithNull(item.email,lowercaseQuery) !== -1) );
        };
    }

    AccountService.fetchHeadoffices().then(function(data) {
        $scope.clients = data;
        if ($scope.clients.length > 0)
            $scope.showHeadOffices = true;
    });

    $scope.addForm = function () {
        var newItemNo = ++$scope.lastInsertedId;
        $scope.newUsers.push({'id': newItemNo, 'oldSelected': true, 'contact': null });
    };

    $scope.removeForm = function (id) {
        for (var i = 0; i < $scope.newUsers.length; i++) {
            if ($scope.newUsers[i].id == id) {
                $scope.newUsers.splice(i, 1);
                break;
            }
        }
    };

    $scope.createNewSecondary = function () {
        $scope.secondarySearchText = "Create new Assistant Site RSO";
        $scope.oldSecondary = false;
        //hide dropdown
        var autoChild = document.getElementById('secondaryAutoComplete').firstElementChild;
        var el = angular.element(autoChild);
        el.scope().$mdAutocompleteCtrl.listLeave();
    };

    $scope.addAccount = function() {

        $scope.saveDisabled = true;

        if($scope.invoiceAddressRadio == ADD_NEW_INVOICE_ADDRESS)
        {
            $scope.$broadcast('show-errors-check-validity', 'fifthTab');
            if(!isFifthTabValid())
                return false;
        }

        var primary = null;
        var secondary = null;
        var oldPrimary = false;
        var oldSecondary = false;

        if(!skip[1])
        {
            if($scope.newPrimaryUser.username)
            {
                primary = $scope.newPrimaryUser;
            }
            else
            {
                primary = $scope.primarySelectedItem;
                oldPrimary = true;
            }
        }

        if(!skip[2])
        {
            if($scope.newSecondaryUser.username)
            {
                secondary = $scope.newSecondaryUser;
            }
            else
            {
                secondary = $scope.secondarySelectedItem;
                oldSecondary = true;
            }
        }

        var invoiceAdr;
        switch ($scope.invoiceAddressRadio) {
            case ADD_NEW_INVOICE_ADDRESS:
                invoiceAdr = JSON.parse(JSON.stringify($scope.invoice));
                break;
            case COPY_PARENT_TO_INVOICE_ADDRESS:
                break;
            case COPY_LOCATION_TO_INVOICE_ADDRESS:
                invoiceAdr = JSON.parse(JSON.stringify($scope.shipping));
                break;
            default:
                invoiceAdr = JSON.parse(JSON.stringify($scope.invoice));
                break;
        }

        AccountService.addAccount(
            $scope.account,
            !oldPrimary,
            primary,
            !oldSecondary,
            secondary,
            $scope.shipping,
            invoiceAdr)
            .then(function (data) {
                //SUCCESS
                Notifier.info("Account created");
                $state.go('app.administration.accounts');
                return true;
            }, function(error) {
                Notifier.responseMessage(error);
            });
    };
}
