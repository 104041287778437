import API                          from '../../../../common/http/api.fetch';
import {success, responseMessage}   from '../../../../common/utils/util.notifier';
import I18N                         from '../../../../common/utils/util.i18n';
import { TYPES }                    from '../assessment-reports-action-type.constants';
import { IDS }                      from '../assessment-reports-action-id.constants';
import {GRID_RELOAD} from "../../../../common/grid/grid.constants";

export const initAccreditationBodiesOptions = (statuses) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/list-accreditation-bodies',
            method: 'GET',
        }).then(({data}) => {
            dispatch({
                type: TYPES.FETCH_ACC_BODIES_OPTIONS,
                id: IDS.accAssessmentReports,
                options: data.items.map(item => {
                    const key = `ACCREDITATION.ASSESSMENT_REPORTS.ACC_BODIES_OPTIONS.${item.code.toUpperCase()}`;
                    const translated = I18N.translate(key);
                    return {
                        value: item.code,
                        label: translated === key ? `${item.name} (${item.description})` : translated
                    }
                })
            })
        }, error => {
            responseMessage(error);
        });
    }
}

export const addAssessmentReport = (data) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/add-assessment-report',
            method: 'POST',
            data
        }).then(response => {
            dispatch({
                type:   GRID_RELOAD,
                id:     IDS.accAssessmentReports,
                path:   IDS.accAssessmentReportsGrid,
            })
            success('COMMON.SERVER_RESPONSE.ITEM_ADDED');
        }, error => {
            responseMessage(error);
        });
    };
}