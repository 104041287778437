import { IDS } from '../rep-monthly-action-id.constants';
import * as RepMonthlyActions from '../rep-monthly.actions';

export const repMonthlyDetails = {
    /* @ngInject */
    controller: function($ngRedux, AuthService) {
        this.$onInit = async () => {
            this.identifier = IDS.repMonthly;
            this.parentState = IDS.app_reporting_monthly;

            this.isAdmin = AuthService.getUser().type === 'Administrator';
            this.unsubscribe = $ngRedux.connect(state => ({
                details: state.reporting.repMonthly.details,
            }), {
                ...RepMonthlyActions,
            })(this);
        }
        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: require('./rep-monthly-details.html')
}
