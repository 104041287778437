import { variant } from '../../bootstrap.json';
import { TabsReducer } from './tabs.reducer';

export const TabsInit = (tabs) => ({
    tabs: tabs.filter((tab) => !(tab.exclude && tab.exclude.includes(variant))),
});

export const TabsResolver = (state, action) => {
    switch (action.type) {
        case 'TAB_INIT':
        case 'TAB_OVERRIDE':
        case 'TAB_PARAMS':
        case 'TAB_ENABLE':
        case 'TAB_DISABLE':
        case 'TAB_SHOW':
        case 'TAB_HIDE':
        case 'TAB_LOADING':
        case 'TAB_SELECT':
            return {
                ...state,
                [action.id]: TabsReducer(state[action.id], action)
            }
        case 'TAB_SWITCH':
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    selectedTabIndex: action.selectedTabIndex,
                    selectedState: action.selectedState
                }
            }
        default:
            return state;
    }
}
