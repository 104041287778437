export const equipmentGridDepreciationReport = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, EquipmentService, DocumentManager, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(({ equipment }) => ({
                ety_id: equipment.equ.category,
            }), {})(this);
        }

        this.onReport = () => {
            if (this.equGridCustomForm.$valid) {
                Notifier.info('Please wait...', false);
                EquipmentService.getDepreciationReport(this.ety_id)
                    .then((data) => {
                        DocumentManager.downloadFile('/' + data.path);
                        Notifier.success('Export Complete!');
                    })
                    .catch(() => Notifier.error('System Error!'));
            } else {
                Notifier.error('Please select depreciation report equipment type');
            }
        };

        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: `
        <form id="equGridCustomForm"
              name="$ctrl.equGridCustomForm"
              layout-gt-xs="row"
              layout-align-xs="center center"
              layout-align-sm="center center"
              novalidate>
            <div flex hide-xs hide-sm></div>
            <div layout="row" flex-xs="100" layout-align-xs="center center">
                <div layout="row" style="width: 206px">
                    <input-select flex label="Depreciation Report"
                                  identifier="equ"
                                  state-path="category"
                                  state-data="categories"
                                  required="true">
                    </input-select>
                </div>
            </div>
            <div layout="row"
                 layout-align-xs="center center"
                 layout-align-sm="center center">
                <md-button type="submit" class="btnDefault" ng-click="$ctrl.onReport()">
                    <asset src="export-gray.svg"></asset> Export
                </md-button>
            </div>
        </form>
    `,
};
