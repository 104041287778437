import { TAB_DISABLE, TAB_SHOW } from '../../../common/tabs/tabs.constants';
import { formatDate } from "../../../common/utils/util.date";
import { DATE_FORMAT } from "../../../common/utils/util.constants";
import { updateCandidateReview, getCandidateReview } from '../safety-training.actions';

/**
 * @const {object}
 * @memberOf module:SafetyTraining
 */
export const stVideoAcknowledgement = {
    /* @ngInject */
    controller: function ($ngRedux, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(({ safetyTraining }) => ({
                completion: safetyTraining.stVideo.trainings.length,
                progress: safetyTraining.stVideo.progress,
                candidateReview: safetyTraining.stVideo.candidateReview,
                applicant_signature_date: formatDate(safetyTraining.stVideo.applicant_signature_date, DATE_FORMAT.OUTPUT),
                applicant_signature: safetyTraining.stVideo.applicant_signature
            }), { updateCandidateReview, getCandidateReview })(this);
            this.identifier = 'public.safetyTraining.laser.video';
            this.state = 'public.safetyTraining.laser.video.acknowledgement';
            this.title = 'Applicant Acknowledgement';
        }

        this.onConfirm = () => {
            if (this.stVideoAckForm.$valid) {
                this.updateCandidateReview({
                    ...this.candidateReview,
                    watchedSection: this.candidateReview.nextSection,
                    applicant_signature: this.applicant_signature
                }).then(() => {
                    Notifier.success('Laser Safety Training has been successfully completed');

                    this.getCandidateReview().then(() => {
                        $ngRedux.dispatch({
                            type: TAB_DISABLE,
                            id: 'public.safetyTraining.laser.video',
                            state: [
                                'public.safetyTraining.laser.video.player',
                                'public.safetyTraining.laser.video.acknowledgement'
                            ],
                        });
                        $ngRedux.dispatch({
                            type: TAB_SHOW,
                            id: 'public.safetyTraining.laser.video',
                            state: 'public.safetyTraining.laser.video.confirmation'
                        });
                    });
                }).catch((error) => {
                    Notifier.error(error.data);
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
        }

        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: require('./st-video-acknowledgement.html')
};
