import API from '../../../../common/http/api.fetch';
import {responseMessage} from '../../../../common/utils/util.notifier';
import { TAB_SELECT, TAB_ENABLE } from '../../../../common/tabs/tabs.constants';
import I18N from '../../../../common/utils/util.i18n';
import { TYPES } from '../scheme-action-type.constants';
import { TYPES as ACC_TYPES } from '../../accreditation-action-type.constants';
import { IDS } from '../scheme-action-id.constants';
import moment from "moment";

export const fetchSiteLocationDetails = (siteLocationUuid) => {
    return dispatch => {
        return API.fetch({
            url: `/location/sitelocation/${siteLocationUuid}`,
            method: 'GET',
            params: {}
        }).then(response => {
            const siteLocation = response.data[0][0];
            dispatch({
                type: TYPES.FETCH_SITE_LOCATION_DETAILS,
                id: IDS.accScheme,
                locationName: siteLocation.name,
                locationUuid: siteLocation.subject_uuid,
                fiscalYearStart: parseInt(siteLocation.fiscal_year_start) - 1,
                autocompleteQualityManagers: response.data[1].map(item => ({ ...item,
                    employee_uuid: item.subject_uuid
                })),
            })
        })
    }
}

export const getSchemeDetails = (code) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/scheme-details',
            method: 'GET',
            params: { code }
        }).then(response => {
            return response.data.details
        }, error => {
            responseMessage(error);
        });
    };
}

export const selectParentStandard = (standardId, sectionsTree) => {

    const sectionsList = index(sectionsTree);
    try {
        const parent = sectionsList.find(standard =>
            standard
            && standard.children
            && standard.children.length
            && standard.children.find(item => item.id === standardId)
        );
    }catch(e){}
    if(parent) {
        return dispatch => {
            dispatch({
                type: ACC_TYPES.SELECT_STANDARD,
                standard_id: parent.id,
            });
            return parent.id
        }
    }
}

export const unselectAllTreeNodes = () => {
    return dispatch => {
        return dispatch({
            type: ACC_TYPES.STANDARDS_TREE_UNSELECT_ALL,
            id: IDS.accScheme,
        });
    }
}

export const resetStandards = () => {
    return dispatch => {
        return dispatch({
            type: ACC_TYPES.FETCH_STANDARDS_TREE_PROGRESS,
            id: IDS.accScheme,
            data: []
        })

    }
}

export const switchToProgressTab = (standard, location, year, $timeout, isPublic = false, itemCode = null) => {
    return dispatch => {
        dispatch({
            type: TAB_ENABLE,
            id: isPublic ? IDS.public_accreditation_scheme : IDS.app_accreditation_scheme_manage,
            state: [ isPublic ? IDS.public_accreditation_scheme_progress : IDS.app_accreditation_scheme_manage_progress ]
        })

        const stateParams = { standard, location, year};
        if(isPublic)
            stateParams.itemCode = itemCode;
        $timeout(() => {
            dispatch({
                type: TAB_SELECT,
                id: isPublic ? IDS.public_accreditation_scheme : IDS.app_accreditation_scheme_manage,
                state: isPublic ? IDS.public_accreditation_scheme_progress : IDS.app_accreditation_scheme_manage_progress,
                stateParams,
            })
        })
    }
}

export const switchToEvidenceTab = (standard, location, year, $timeout, isPublic = false, itemCode = null) => {
    return dispatch => {
        dispatch({
            type: TAB_ENABLE,
            id: isPublic ? IDS.public_accreditation_scheme : IDS.app_accreditation_scheme_manage,
            state: [
                isPublic ? IDS.public_accreditation_scheme_progress : IDS.app_accreditation_scheme_manage_progress,
                isPublic ? IDS.public_accreditation_scheme_evidence : IDS.app_accreditation_scheme_manage_evidence,
            ]
        })

        const stateParams = { standard, location, year};
        if(isPublic)
            stateParams.itemCode = itemCode;
        $timeout(() => {
            dispatch({
                type: TAB_SELECT,
                id: isPublic ? IDS.public_accreditation_scheme : IDS.app_accreditation_scheme_manage,
                state: isPublic ? IDS.public_accreditation_scheme_evidence : IDS.app_accreditation_scheme_manage_evidence,
                stateParams,
            })
        })
    }
}

// export const enableScheduleTab = () => {
//     return dispatch => {
//         return dispatch({
//             type:   TAB_ENABLE,
//             id:     IDS.app_accreditation_scheme_manage,
//             state: [IDS.app_accreditation_scheme_manage_auditSchedule]
//         })
//     }
// }

const index = (children, list = []) => {
    children && children.forEach(item => {
        if(item) {
            list[parseInt(item.id)] = item;
            index(item.children, list);
        }
    })
    return list;
};
