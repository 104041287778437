import { IDS } from '../../settings-action-id.constants';
import * as SettingsActions from '../../settings.actions';
import * as HeadOfficesActions from './head-offices.actions';
import * as GRID from "../../../../../common/grid/grid.constants";

/**
 * Creates a new HeadOfficesController.
 * @class
 */
class HeadOfficesController {
    constructor($ngRedux, $state, $scope, $mdDialog, $uiRouterGlobals, Notifier, NavigationService) {
        this.$ngRedux   = $ngRedux;
        this.$state     = $state;
        this.$scope     = $scope;
        this.$uiRouterGlobals = $uiRouterGlobals;
        this.$mdDialog  = $mdDialog;
        this.Notifier   = Notifier;
        this.NavigationService = NavigationService;
    }

    $onInit() {
        this.dataIdentifier = IDS.accSettings;
        this.identifier     = IDS.accreditationSettingsHeadOffices;
        this.parentState    = IDS.app_accreditation_scheme_settings_enabled;
        this.state          = IDS.app_accreditation_scheme_settings_enabled_head_offices;
        this.unsubscribe    = this.$ngRedux.connect(
            state => {
                const accSettings =             state.accreditation.accSettings;
                const schemeDetails =           accSettings.schemeDetails;
                const headOfficesGrid =         accSettings.headOfficesGrid;
                const selectedHeadOfficeUuid =  accSettings.headOffices.selectedHeadOfficeUuid;

                return {
                    title:                  schemeDetails.title,
                    schemeId:               schemeDetails.id,
                    isSchemeStatusPending:  schemeDetails.status_code === 'pen',
                    selectedHeadOfficeUuid,
                    selectedHofUsedAlready: headOfficesGrid.items &&
                                            headOfficesGrid.items.map(item => item.slo_uuid).includes(selectedHeadOfficeUuid)
                }
            },
            {...SettingsActions, ...HeadOfficesActions})(this);

        this.schemeCode = this.$uiRouterGlobals.params.itemCode;
        this.getSchemeDetails(this.schemeCode).then(()=> {
            this.getNotEnabledHeadOffices(this.schemeId);
            this.$ngRedux.dispatch({
                type: GRID.GRID_RELOAD,
                id: IDS.accSettings,
                path: IDS.headOfficesGrid,
            });
        })

        const navConfig = this.NavigationService.getConfig(this.$state.$current)
        this.colorName = navConfig.colorName;
    }

    onAssignButton() {
        if(this.selectedHofUsedAlready === false) {
            this.assignSchemeToSiteLocation(this.schemeId, this.selectedHeadOfficeUuid).then(() => {
                this.getNotEnabledHeadOffices(this.schemeId);
            });
        }
    }

    onSelect(event) {
        this.showSiteLocationsTab(event.item);
    }

    onRemove(event) {
        if(this.isSchemeStatusPending === false) {
            this.Notifier.warn('ACCREDITATION.SETTINGS.HEAD_OFFICES.UNABLE_TO_REMOVE_HEAD_OFFICE_AFTER_SCHEME_WAS_ACTIVATED');
            return
        }
        const confirm = this.$mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Chosen Head Office location will be removed from scheme.')
            .ariaLabel('Remove Head Office location')
            .ok('Yes')
            .cancel('No');
        this.dialog = this.$mdDialog.show(confirm).then(() => {
            this.detachSiteLocationFromScheme({...event.item, schemeId: this.schemeId}).then(() => {
                this.getNotEnabledHeadOffices(this.schemeId);
            });
        })
    }

    $onDestroy() {
        this.unsubscribe();
        if (this.dialog)
            this.$mdDialog.hide(this.dialog);
    }
}

HeadOfficesController.$inject = ['$ngRedux', '$state', '$scope', '$mdDialog', '$uiRouterGlobals', 'Notifier', 'NavigationService']

export const headOffices = {
    controller: HeadOfficesController,
    template: `
        <head-offices-grid
            title="{{$ctrl.title}}"
            on-select="$ctrl.onSelect($event)"
            on-remove="$ctrl.onRemove($event)">
        </head-offices-grid>
        
        <form id="headOfficeForm" name="$ctrl.headOfficeForm" noValidate>
            <div layout-gt-xs="row">
                <div layout="column" flex-gt-xs="60">
                   <input-select flex 
                        label="{{ 'COMMON.FIELD.HEAD_OFFICE' | translate}}"
                        identifier="{{$ctrl.dataIdentifier}}"
                        state-data="headOffices.notEnabledHeadOffices"
                        state-path="headOffices.selectedHeadOfficeUuid">
                   </input-select>
        
                </div>
                <div layout="row" layout-align-xs="center center" layout-align-sm="center center" layout-align-gt-sm=" end">
                    <md-button
                        class="md-button btnColor{{$ctrl.colorName}}" 
                        ng-click="$ctrl.onAssignButton()"
                        ng-disabled="!$ctrl.selectedHeadOfficeUuid">Assign</md-button>
                </div>
            </div>
        </form>`,
}
