import * as RmActions from '../../radioactive-materials.actions';
import * as RmTestActions from '../rm-test.actions';
import { generateQRCode } from '../../../../common/utils/util.qrcode';
import { RM_TEST_USE_TYPE_SELECT, RM_RESET_TEST_LICENCE, RM_RESET_TEST_LICENCE_LIST } from '../../radioactive-materials.actions.types';
import { formatDate } from '../../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../../common/utils/util.constants';
import moment from 'moment';
import isNull from 'lodash/isNull';
import find from 'lodash/find';

/**
 * @const {object}
 * @memberOf module:RadioactiveMaterials
 */
export const rmTestDetails = {
    /* @ngInject */
    controller: function ($ngRedux, $state, $scope, $rootScope, $mdDialog, FileActions, Notifier, AuthService) {
        const RM_TEST = 'rmTest';
        const unsubscribe = $ngRedux.connect(({ radioactiveMaterials }) => ({
            rmTest: radioactiveMaterials.rmTest
        }), {
            ...FileActions,
            ...RmActions,
            ...RmTestActions
        })(this);

        /**
         * @function $onInit
         */
        this.$onInit = () => {
            if (this.rmTest.id) {
                this.identifier = 'app.radioactiveMaterials.test.manage';
                this.state = 'app.radioactiveMaterials.test.manage.details';
                this.getAtomicNums(RM_TEST, this.rmTest.isotope);
                generateQRCode('qrcode', {
                    type: RM_TEST,
                    id: this.rmTest.id,
                    visualId: this.rmTest.visualId,
                });
            }
            else {
                this.identifier = 'app.radioactiveMaterials.test.add';
                this.state = 'app.radioactiveMaterials.test.add.details';
                this.getNextId(RM_TEST).then((id) => {
                    generateQRCode('qrcode', {
                        type: RM_TEST,
                        visualId: id,
                    });
                });
            }

            this.disabled = this.rmTest.status === 'dis';
            this.user = AuthService.getUser();
            if (typeof this.user.hofName === 'undefined') {
                this.licenceRequired = true;
                this.sourceHeadOffice = null;
            } else {
                this.sourceHeadOffice = this.user.hofName;
                this.user.hofName === 'OTTAWA HOSPITAL' ? this.licenceRequired = true : this.licenceRequired = false;
            }

            this.getTestTypes();
            this.getTestUseTypes();
            this.getIsotopes(RM_TEST);
            this.getUnits(RM_TEST, this.rmTest.unitType, 'units');
            this.getTestManufacturers();
            this.onSealed();
        }

        $scope.$on('LOCATION_SUBJECT_SELECTED', function(event, locationItem) {
            $ngRedux.dispatch({
                id: 'rmTest',
                type: RM_RESET_TEST_LICENCE_LIST
            });
            if (locationItem.in_subject_basic_type_code !== 'hof') {
                this.getLocationLicences('rmTest', locationItem.in_subject_uuid, 'rdt');
            } else {
                this.sourceHeadOffice = locationItem.in_username_par;
                this.sourceHeadOffice === 'OTTAWA HOSPITAL' ? this.licenceRequired = true : this.licenceRequired = false;
            }
        }.bind(this));

        this.onIsotope = ({ input }) => {
            this.getAtomicNums(RM_TEST, input, true);
            checkLicenceCompatibility();
        };

        this.onLicence = () => {
            checkLicenceCompatibility();
        }

        const checkLicenceCompatibility = () => {
            if (this.licenceRequired && this.rmTest.isotope && this.rmTest.locationLicence && this.rmTest.atomicNum) {
                this.checkIsotopeInLicence(this.rmTest).then((data) => {
                    if (!data.is_assigned) {
                        Notifier.error('Selected Isotope is not assigned to the licence. Please select different licence');
                        $ngRedux.dispatch({
                            id: 'rmTest',
                            type: RM_RESET_TEST_LICENCE
                        });
                    }
                });
            }
        };

        this.onAtomicNum = ({ item }) => {
            this.setHalfLife(RM_TEST, 'halfLife', item.halfLife);
            this.onGetCurrentActivity();
            checkLicenceCompatibility();
        };

        this.onUnit = ({ input }) => {
            this.getUnits(RM_TEST, input, 'units');
        };

        this.onGetCurrentActivity = () => {
            const { activity, activityDate, atomicNum } = this.rmTest;
            const halfLife = find(this.rmTest.atomicNums, ['value', atomicNum]);
            const initActivity = activity && activity !== '';
            if (halfLife && initActivity && activityDate) {
                this.getCurrentActivity({
                    activity,
                    activity_date: formatDate(activityDate, DATE_FORMAT.OUTPUT),
                    half_life: parseFloat(halfLife.halfLifeValue),
                    half_life_unit: halfLife.halfLifeUnit
                });
            }
            if (this.rmTest.unit && this.rmTest.activityDate && this.rmTest.useType) {
                this.onUseType({input: this.rmTest.useType});
            }
        };

        this.onUseType = ({ input }) => {
            let dueDate = null;
            switch (input) {
                case 'utpinu':
                    dueDate = moment(this.rmTest.activityDate).add(6, 'months').calendar();
                    break;
                case 'utpind':
                    dueDate = moment(this.rmTest.activityDate).add(12, 'months').calendar();
                    break;
                case 'utpins':
                    dueDate = moment(this.rmTest.activityDate).add(24, 'months').calendar();
                    break;
            }
            let dueDateObject = new Date(dueDate);
            const unitList = [7, 714];
            if ((parseInt(this.rmTest.unit) === 6 && parseInt(this.rmTest.activity) > 49) || unitList.includes(parseInt(this.rmTest.unit))) {
                $ngRedux.dispatch({
                    id: 'rmTest',
                    type: RM_TEST_USE_TYPE_SELECT,
                    dueDate,
                    wipeTesting: 1
                });
                $ngRedux.dispatch({
                    id: 'rmTest',
                    type: 'DATE_SELECTED',
                    path: 'wipeTestingDueDate',
                    input: dueDateObject
                });
            } else {
                $ngRedux.dispatch({
                    id: 'rmTest',
                    type: RM_TEST_USE_TYPE_SELECT,
                    dueDate: null,
                    wipeTesting: 0
                });
                $ngRedux.dispatch({
                    id: 'rmTest',
                    type: 'DATE_SELECTED',
                    path: 'wipeTestingDueDate',
                    input: null
                });
            }
        };

        this.onExemptionQuantity = ({ input }) => {
            if (this.sourceHeadOffice === 'OTTAWA HOSPITAL') {
                input == 1 ? this.licenceRequired = false : this.licenceRequired = true;
            } else {
                this.licenceRequired = false;
            }
        }

        this.onSealed = () => {
            if (this.rmTest.sealed) {
                this.initialDateLabel = 'Initial Calibration Date';
                this.assetLabel = 'Asset ID';
            } else {
                this.initialDateLabel = 'Initial Date';
                this.assetLabel = 'Order ID';
            }
            checkLicenceCompatibility();
        }

        this.createType = () => {
            const ctrl = this;
            this.dialog = $mdDialog.show({
                /* @ngInject */
                controller: function ($scope) {
                    $scope.save = () => {
                        if ($scope.dialogForm.$valid) {
                            ctrl.saveTestType($scope.typeName).then(ctrl.getTestTypes);
                            $mdDialog.hide();
                        }
                    };
                    $scope.cancel = () => $mdDialog.cancel();
                },
                template: require('./dialogs/rm-test-type-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true
            }).then(() => {}, () => {});
        }

        this.createManufacturer = (path) => {
            const ctrl = this;
            this.dialog = $mdDialog.show({
                /* @ngInject */
                controller: function ($scope) {
                    $scope.save = () => {
                        if ($scope.dialogForm.$valid) {
                            ctrl.saveTestManufacturer($scope.manufacturerName, path).then(ctrl.getTestManufacturers);
                            $mdDialog.hide();
                        }
                    };
                    $scope.cancel = () => $mdDialog.cancel();
                },
                template: require('./dialogs/rm-test-manufacturer-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true
            }).then(() => {}, () => {});
        }

        this.onManufacturer = ({ input }) => {
            this.getTestManufacturerModels(input, 'models');
        };

        this.onManufacturerOfRadiationDevice = ({ input }) => {
            this.getTestManufacturerModels(input, 'modelsOfRadiationDevice');
        };

        this.createModel = (path) => {
            const ctrl = this;
            this.dialog = $mdDialog.show({
                /* @ngInject */
                controller: function($scope) {
                    let manufacturerId = null;
                    if (path === 'model') {
                        manufacturerId = ctrl.rmTest.manufacturer;
                    } else {
                        manufacturerId = ctrl.rmTest.manufacturerOfRadiationDevice;
                    }
                    $scope.save = () => {
                        if ($scope.dialogForm.$valid) {
                            ctrl.saveTestManufacturerModel(
                                manufacturerId,
                                ctrl.rmTest.testType,
                                $scope.modelName,
                                path
                            ).then(() => {
                                ctrl.getTestManufacturerModels(ctrl.rmTest.manufacturer);
                            });
                            $mdDialog.hide();
                        }
                    };
                    $scope.cancel = () => $mdDialog.cancel();
                },
                template: require('./dialogs/rm-test-model-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true
            }).then(() => {}, () => {});
        }

        this.saveDetails = () => {
            $scope.$broadcast('show-errors-check-validity', '$ctrl.rmTestForm');
            if (this.rmTestForm.$valid) {

                this.saveTest(this.rmTest).then(() => {
                    Notifier.success('New radioactive material source/leak test has been successfully saved');
                    isNull(this.rmTest.id) && resetForm();
                }).catch((error) => {
                    Notifier.error(_.get(error.data, 'message', error.data));
                });
            } else {
                Notifier.error('Please fill in the form fields');
            }
        }

        const resetForm = () => {
            $rootScope.$broadcast('SELECT_CASCADE_RESET', '$ctrl.rmTestForm');
            this.rmTestForm.$setPristine();
            this.rmTestForm.$setUntouched();
            this.getNextId(RM_TEST).then((id) => {
                this.qrCodeGenerate(RM_TEST, this.rmTest);
            });
            this.getUnits(RM_TEST, this.rmTest.unitType, 'units');
        };

        this.onPrintQRcode = () => {
            this.qrCodePrint(RM_TEST, this.rmTest);
        };

        this.onSaveImage = async (event) => {
            const { filePath, width, height } = event[0];
            return await this.saveImage(filePath, width, height);
        }

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./rm-test-details.html')
};
