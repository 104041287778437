angular
    .module('components.safety-plan')
    .component('safety-plan', {
        template: require('./safety-plan.html'),
        controller: 'SafetyPlanController'
    })
    .config(function($stateProvider) {
        $stateProvider
            .state('app.safetyPlan', {
                abstract: true,
                url: '/safety-plan'
            })
            .state('app.safetyPlan.dashboard', {
                url: '',
                component: 'dashboard'
            })
            //LASER SAFETY PLAN
            .state('app.safetyPlan.laser', {
                abstract: true,
                url: '/laser'
            })
            .state('app.safetyPlan.laser.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.safetyPlan.laser.employeeReview', {
                url: '/employee-review',
                trainingType: 'lspp',
                trainingColor: '#ff0033',
                trainingName: 'LaserSafetyPlan',
                template: require('./views/safety-plan-manage-review.html'),
                controller: 'SafetyPlanManageReviewController as safetyPlanManageReview'
            })
            .state('app.safetyPlan.laser.employeeQuestions', {
                url: '/employee-questions',
                trainingType: 'lspp',
                trainingColor: '#ff0033',
                trainingName: 'LaserSafetyPlan',
                template: require('./views/safety-plan-manage-employee-questions.html'),
                controller: 'SafetyPlanManageEmployeeQuestionsController'
            })
            .state('app.safetyPlan.laser.reviewPresentation', {
                url: '/review-presentation',
                trainingType: 'lspp',
                trainingColor: '#ff0033',
                trainingName: 'LaserSafetyPlan',
                template: require('./views/safety-plan-review-presentation.html'),
                controller: 'SafetyPlanReviewPresentationController'
            })
            .state('app.safetyPlan.laser.reviewDocuments', {
                url: '/review-documents',
                trainingType: 'lspp',
                trainingColor: '#ff0033',
                trainingName: 'LaserSafetyPlan',
                template: require('./views/safety-plan-review-documents.html'),
                controller: 'SafetyPlanReviewEmployeeDocumentsController'
            })
            .state('app.safetyPlan.laser.reviewContacts', {
                url: '/contact-info',
                trainingType: 'lspp',
                trainingColor: '#ff0033',
                trainingName: 'LaserSafetyPlan',
                template: require('./views/safety-plan-review-contacts.html'),
                controller: 'SafetyPlanReviewContactController'
            })
            .state('app.safetyPlan.laser.reviewHelp', {
                url: '/review-help',
                trainingType: 'lspp',
                trainingColor: '#ff0033',
                trainingName: 'LaserSafetyPlan',
                template: require('./views/safety-plan-review-help.html'),
                controller: 'SafetyPlanReviewHelpController'
            })
            .state('app.safetyPlan.laser.trainingMatrix', {
                url: '/manage-training-matrix',
                trainingType: 'lspp',
                trainingColor: '#ff0033',
                trainingName: 'LaserSafetyPlan',
                template: require('./views/safety-plan-manage-training-matrix.html'),
                controller: 'SafetyPlanManageEmployeeTrainingMatrixController'
            })
            .state('app.safetyPlan.laser.customize', {
                url: '/customize',
                trainingType: 'lspp',
                trainingColor: '#ff0033',
                trainingName: 'LaserSafetyPlan',
                template: require('./views/safety-plan-customize.html'),
                controller: 'SafetyPlanCustomizeController'
            })
            .state('app.safetyPlan.laser.watchPresentations', {
                url: '/watch-presentation',
                trainingType: 'lspp',
                trainingColor: '#ff0033',
                trainingName: 'LaserSafetyPlan',
                template: require('./views/safety-plan-watch-presentation.html'),
                controller: 'SafetyPlanWatchPresentationsController'
            })
            .state('app.safetyPlan.laser.reviewReport', {
                url: '/review-report',
                trainingType: 'lspp',
                trainingColor: '#ff0033',
                trainingName: 'LaserSafetyPlan',
                template: require('./views/safety-plan-review-report.html'),
                controller: 'SafetyPlanReviewReportController'
            })
            //RADIATION SAFETY PLAN
            .state('app.safetyPlan.radiation', {
                abstract: true,
                url: '/radiation'
            })
            .state('app.safetyPlan.radiation.dashboard', {
                url: '',
                component: 'dashboard'
            })
            .state('app.safetyPlan.radiation.employeeReview', {
                url: '/employee-review',
                trainingType: 'rspp',
                trainingColor: '#cddc39',
                trainingName: 'RadiationSafetyPlan',
                template: require('./views/safety-plan-manage-review.html'),
                controller: 'SafetyPlanManageReviewController as safetyPlanManageReview'
            })
            .state('app.safetyPlan.radiation.employeeQuestions', {
                url: '/employee-questions',
                trainingType: 'rspp',
                trainingColor: '#cddc39',
                trainingName: 'RadiationSafetyPlan',
                template: require('./views/safety-plan-manage-employee-questions.html'),
                controller: 'SafetyPlanManageEmployeeQuestionsController'
            })
            .state('app.safetyPlan.radiation.reviewPresentation', {
                url: '/review-presentation',
                trainingType: 'rspp',
                trainingColor: '#cddc39',
                trainingName: 'RadiationSafetyPlan',
                template: require('./views/safety-plan-review-presentation.html'),
                controller: 'SafetyPlanReviewPresentationController'
            })
            .state('app.safetyPlan.radiation.reviewDocuments', {
                url: '/review-documents',
                trainingType: 'rspp',
                trainingColor: '#cddc39',
                trainingName: 'RadiationSafetyPlan',
                template: require('./views/safety-plan-review-documents.html'),
                controller: 'SafetyPlanReviewEmployeeDocumentsController'
            })
            .state('app.safetyPlan.radiation.reviewContacts', {
                url: '/contact-info',
                trainingType: 'rspp',
                trainingColor: '#cddc39',
                trainingName: 'RadiationSafetyPlan',
                template: require('./views/safety-plan-review-contacts.html'),
                controller: 'SafetyPlanReviewContactController'
            })
            .state('app.safetyPlan.radiation.reviewHelp', {
                url: '/review-help',
                trainingType: 'rspp',
                trainingColor: '#cddc39',
                trainingName: 'RadiationSafetyPlan',
                template: require('./views/safety-plan-review-help.html'),
                controller: 'SafetyPlanReviewHelpController'
            })
            .state('app.safetyPlan.radiation.trainingMatrix', {
                url: '/manage-training-matrix',
                trainingType: 'rspp',
                trainingColor: '#cddc39',
                trainingName: 'RadiationSafetyPlan',
                template: require('./views/safety-plan-manage-training-matrix.html'),
                controller: 'SafetyPlanManageEmployeeTrainingMatrixController'
            })
            .state('app.safetyPlan.radiation.customize', {
                url: '/customize',
                trainingType: 'rspp',
                trainingColor: '#cddc39',
                trainingName: 'RadiationSafetyPlan',
                template: require('./views/safety-plan-customize.html'),
                controller: 'SafetyPlanCustomizeController'
            })
            .state('app.safetyPlan.radiation.watchPresentations', {
                url: '/watch-presentation',
                trainingType: 'rspp',
                trainingColor: '#cddc39',
                trainingName: 'RadiationSafetyPlan',
                template: require('./views/safety-plan-watch-presentation.html'),
                controller: 'SafetyPlanWatchPresentationsController'
            })
            .state('app.safetyPlan.radiation.reviewReport', {
                url: '/review-report',
                trainingType: 'rspp',
                trainingColor: '#cddc39',
                trainingName: 'RadiationSafetyPlan',
                template: require('./views/safety-plan-review-report.html'),
                controller: 'SafetyPlanReviewReportController'
            });
    });
