/** @module root/components/accreditation/improvement */

import * as STATES                      from './improvement.states';
import { IDS }                          from './improvement-action-id.constants';
import { accImprovementDetails }              from './details/details.component';
import { accImprovementList }                 from './list/list.component';
import { accImprovementListGrid }             from './list/list-grid.component';

export const IMPROVEMENT_MODULE = angular.module(
    'migration.accreditation.improvement',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
        Object.values({
            ...STATES,
        }).map(state => stateRegistry.register(state));
    }]
).component({
    accImprovementDetails,

    accImprovementList,
    accImprovementListGrid,

}).run(['$templateCache', ({ put }) => {
    put(IDS.app_accreditation_scheme_improvement_list,     '<acc-improvement-list></acc-improvement-list>');
    put(IDS.app_accreditation_scheme_improvement_details,  '<acc-improvement-details></acc-improvement-details>');
    put(IDS.app_accreditation_scheme_improvement_add,      '<acc-improvement-details></acc-improvement-details>');
    put('../../improvement/list/list-grid-filter.html', require('./list/list-grid-filter.html'));
}]);
