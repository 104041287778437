import * as ImportActions from '../import.actions';
import { GRID_FILTER } from "../../../../common/grid/grid.constants";
import {IDS} from "../import-action-id.constants";
import {TYPES} from "../import-action-type.constants";

class HistoryController {
    constructor($ngRedux, $scope, $timeout, Notifier, $filter) {
        this.$ngRedux = $ngRedux;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.Notifier = Notifier;
        this.$filter = $filter;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(state => {
            return {}
        }, ImportActions)(this);
    }

    onSelect({item}) {
        this.$ngRedux.dispatch({
            type:   TYPES.SELECT_IMPORT_ITEM,
            id:     IDS.empImportHistoryDetails,
            item
        });
        this.$ngRedux.dispatch({
            type:   GRID_FILTER,
            id:     IDS.empImport,
            path:   IDS.empImportHistoryDetailsGrid,
            filter: { gci_id: item.id }
        });
        this.$ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: IDS.app_employees_import_history,
            state: [IDS.app_employees_import_history_details]
        });
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

HistoryController.$inject = ['$ngRedux', '$scope', '$timeout', 'Notifier', '$filter'];

export const empImportHistoryList = {
    controller: HistoryController,
    template: `
        <emp-import-history-list-grid 
            title="{{'EMPLOYEES.IMPORT.HISTORY.TAB' | translate}}"
            on-select="$ctrl.onSelect($event)">
        </emp-import-history-list-grid>
`
};
