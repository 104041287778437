import { List } from 'immutable';

/* @ngInject */
export function ServiceContractManager($rootScope, $timeout, $mdDialog, NavigationService, ServiceContractService, ServiceContractVisit, Grid) {

    this.TAB_CONTRACTS              = 0;
    this.TAB_CONTRACT_DETAILS       = 1;
    this.TAB_ACCOUNTS               = 2;
    this.TAB_EQUIPMENT_COVERED      = 3;
    this.TAB_EQUIPMENT              = 4;
    this.TAB_SPARE_PARTS            = 5;
    this.TAB_ESTIMATED_TIME_FRAMES  = 6;
    this.TAB_SCHEDULE_VISITS        = 7;
    this.TAB_CONTRACT_SUMMARY       = 8;

    this.tplContracts               = './service-contracts-listing.html';
    this.tplContractDetails         = './service-contracts-contract.html';
    this.tplAccounts                = './service-contracts-accounts.html';
    this.tplEquipmentCovered        = './service-contracts-equipment-covered.html';
    this.tplEquipment               = './service-contracts-equipment.html';
    this.tplEquipmentSpareParts     = './service-contracts-equipment-spare-parts.html';
    this.tplVisits                  = './service-contracts-visits.html';
    this.tplVisitsSchedule          = './service-contracts-visits-schedule.html';
    this.tplVisitsScheduleDialog    = './service-contracts-visits-schedule-dialog.html';
    this.tplContractSummary         = './service-contracts-summary.html';

    this.SCHEDULE_VISIT_COMPLETE = 'scheduleVisitComplete';

    var self = this;

    this.equipmentColumns = new List([
        {title: '#',                mapper: false},
        {title: 'Equipment ID',     mapper: 'user_friendly_id'},
        {title: 'Category',         mapper: 'category.name'},
        {title: 'Manufacturer',     mapper: 'manufacturer.name'},
        {title: 'Model',            mapper: 'model.name'},
        {title: 'Serial number',    mapper: 'serial'},
        {title: 'Account',          mapper: 'account.name'}
    ]);

    this.tabs = [
        {
            id: this.TAB_CONTRACTS,
            title: 'Contracts',
            content: this.tplContracts,
            form: null,
            formValidation: false,
            formValidationMsg: '',
            visible: true,
            disabled: false,
            stateless: true
        },
        {
            id: this.TAB_CONTRACT_DETAILS,
            title: 'Contract Details',
            content: this.tplContractDetails,
            form: null,
            formValidation: true,
            formValidationMsg: 'Please fill in the form fields',
            visible: true,
            disabled: true,
            stateless: false
        },
        {
            id: this.TAB_ACCOUNTS,
            title: 'Accounts',
            content: this.tplAccounts,
            form: null,
            formValidation: true,
            formValidationMsg: 'You have to select at least one Account',
            visible: true,
            disabled: true
        },
        {
            id: this.TAB_EQUIPMENT_COVERED,
            title: 'Equipment Covered',
            content: this.tplEquipmentCovered,
            form: null,
            formValidation: true,
            formValidationMsg: 'You have to select at least one Equipment Category',
            visible: true,
            disabled: true
        },
        {
            id: this.TAB_EQUIPMENT,
            title: 'Equipment',
            content: this.tplEquipment,
            form: null,
            formValidation: true,
            formValidationMsg: 'You have to select at least one peace of Equipment',
            visible: true,
            disabled: true
        },
        {
            id: this.TAB_SPARE_PARTS,
            title: 'Spare Parts',
            content: this.tplEquipmentSpareParts,
            form: null,
            formValidation: false,
            visible: true,
            disabled: true
        },
        {
            id: this.TAB_ESTIMATED_TIME_FRAMES,
            title: 'Estimated Time Frames',
            content: this.tplVisits,
            form: null,
            formValidation: true,
            visible: true,
            disabled: true
        },
        {
            id: this.TAB_SCHEDULE_VISITS,
            title: 'Schedule Visits',
            content: this.tplVisitsSchedule,
            form: null,
            formValidation: false,
            visible: true,
            disabled: true
        },
        {
            id: this.TAB_CONTRACT_SUMMARY,
            title: 'Contract Summary',
            content: this.tplContractSummary,
            form: null,
            formValidation: false,
            formValidationMsg: '',
            visible: false,
            disabled: false,
            stateless: false
        }
    ];

    this.showVisitSchedule = function(contractId, schedule, equipment, month) {

        $mdDialog.show({
            controller: scheduleVisitCtrl,
            templateUrl: this.tplVisitsScheduleDialog,
            parent: angular.element(document.body),
            locals: {
                contractId: contractId,
                schedule: schedule,
                equipment: equipment,
                month: month
            },
            clickOutsideToClose: true
        });

        function scheduleVisitCtrl($scope, contractId, schedule, equipment, month) {
            $scope.DIALOG_STATE_SCHEDULE = 'schedule';
            $scope.DIALOG_STATE_VISIT = 'visit';

            $scope.DIALOG_STATE = schedule.visits.length ? $scope.DIALOG_STATE_SCHEDULE : $scope.DIALOG_STATE_VISIT;

            $scope.headerColor = NavigationService.getNavConfig().colorName;
            $scope.schedule = schedule;
            $scope.visit = new ServiceContractVisit(schedule.accountUuid, schedule.month);
            $scope.visit.equipment = equipment;
            $scope.timePeriods = ['am', 'pm'];

            var selectedVisit = null;

            $scope.scheduleGrid = new Grid();
            $scope.scheduleGrid.items = schedule.visits;
            $scope.scheduleGrid.columns = [
                {title: '#',                mapper: false},
                {title: 'Scheduled Date',   mapper: 'getDateString'},
                {title: 'Start Time',       mapper: 'getFormattedStartTime'},
                {title: 'End Time',         mapper: 'getFormattedEndTime'},
                {title: 'All Day Event',    mapper: 'getAllDayEvent', style: {'text-align': 'center'}},
                {title: 'Equipment name',  mapper: 'getEquipmentUserFriendlyIds', style: {'text-align': 'center'}}
            ];

            let minDate = new Date();
            let selectedDate = month.split(" ");

            let months = [
                'January', 'February', 'March', 'April', 'May',
                'June', 'July', 'August', 'September',
                'October', 'November', 'December'
            ];
            let monthNumber = months.indexOf(selectedDate[0]);

            minDate.setFullYear(parseInt(selectedDate[1]));
            minDate.setMonth(monthNumber);
            minDate.setDate(1);

            var currentDate = new Date();
            var currentMonth = currentDate.getUTCMonth();
            var currentYear = currentDate.getUTCFullYear();

            if ((monthNumber > currentMonth && parseInt(selectedDate[1]) === currentYear) || parseInt(selectedDate[1]) > currentYear) {
                $scope.minScheduleDate = minDate;
                $scope.visit.date = minDate;
            } else {
                $scope.minScheduleDate = new Date();
                $scope.visit.date = new Date();
            }

            ServiceContractService.getSubjectAndLocation(contractId, schedule.accountUuid).then(function(result) {
                $scope.subject = result.subject;
                $scope.location = result.location;
            });

            $scope.initForm = function(form) {
                $scope.visitScheduleDialogForm = form;
                $scope.visitScheduleDialogForm.$setPristine();
            };

            $scope.selectVisit = function(visit) {
                $scope.visit.date = null;
                $scope.DIALOG_STATE = $scope.DIALOG_STATE_VISIT;
                $timeout(function() {
                    if (visit.equipment.date) selectedVisit = visit;
                    $scope.visit = _.cloneDeep(visit);
                    $scope.visit.equipment = _.union(visit.equipment, equipment);
                }, 100);
            };

            $scope.onAllDayEvent = function() {
                if ($scope.visit.allDayEvent) {
                    $scope.visit.startTime = null;
                    $scope.visit.endTime = null;
                }
            };

            $scope.setEndTime = function(hours) {
                let AMPM = $scope.visit.startTime.slice(-2);
                let timeArr = $scope.visit.startTime.slice(0, -2).split(":");

                let selectedHour = parseInt(timeArr[0]);
                let hoursToAdd = parseInt(hours);

                if (selectedHour >= 12 - hoursToAdd) {
                    if (AMPM === "am") {
                        AMPM = "pm";
                    } else {
                        AMPM = "am";
                    }
                }

                if (selectedHour > 12 - hoursToAdd) {
                    timeArr[0] = selectedHour - 12 + hoursToAdd;
                } else {
                    timeArr[0] = selectedHour + hoursToAdd;
                }

                let newTime = timeArr.join(":")+AMPM;
                $scope.visit.endTime = newTime;
            };

            $scope.scheduleVisit = function() {
                $scope.$broadcast('show-errors-check-validity', 'visitScheduleDialogForm');
                if ($scope.visitScheduleDialogForm.$invalid) return;

                ServiceContractService.scheduleEquipmentVisit(contractId, $scope.visit).then(function(status) {
                    _.each($scope.visit.equipment, function(item) {
                        item.selected = 0;
                    });

                    if (selectedVisit) _.assign(selectedVisit, $scope.visit);
                    else {
                        var existingVisit = schedule.getVisit($scope.visit.date, $scope.visit.allDayEvent, $scope.visit.startDate, $scope.visit.endDate);
                        if (existingVisit) {
                            $scope.visit.equipment = _.union($scope.visit.equipment, existingVisit.equipment);
                            _.assign(existingVisit, $scope.visit);
                        }
                        else schedule.visits.push($scope.visit);
                    }

                    $mdDialog.cancel();
                    $rootScope.$broadcast(self.SCHEDULE_VISIT_COMPLETE);
                });
            };

            $scope.back = function() {
                selectedVisit = null;
                $scope.DIALOG_STATE = $scope.DIALOG_STATE_SCHEDULE;
            };

            $scope.cancel = function() {
                $mdDialog.cancel();
            };
        }
    };
}
