export const systemAlertsState = {
    name: 'app.system.alerts',
    redirectTo: 'app.system.alerts.listing',
    url: '/alerts',
    component: 'tabs'
};

export const systemAlertsListingState = {
    name: 'app.system.alerts.listing',
    url: '',
    component: 'systemAlerts',
    onExit: function($ngRedux) {
        $ngRedux.dispatch({
            type: 'GRID_RESET_FILTER',
            id: 'systemAlertsGrid'
        });
    }
};
systemAlertsListingState.onExit.$inject = ['$ngRedux'];
