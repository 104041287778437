import { DATE_FORMAT } from '../../../../common/utils/util.constants';

/* @ngInject */
export function EquipmentServiceLaser($http, API_BASE, $ngRedux, Laser, EquipmentCategory, LaserClass, LaserMode, LaserPermitApplication, Helpers) {

    this.addEquipment = function(data) {
        var ldata = {
            equipment_id: data.user_friendly_id,
            serial: data.serial,
            wave_length: data.waveLength,
            installation_date: Helpers.formatDate(data.equipmentInstallationDate),
            power: data.power,
            power_notes: data.powerNotes,
        };

        if(data.location != null && data.location.uuid != null){
            ldata.loc_uuid = data.location.uuid;
        }
        if(data.category != null && data.category.id != null){
            ldata.ety_id = parseInt(data.category.id);
        }
        if(data.model != null && data.model.id != null){
            ldata.emo_id = parseInt(data.model.id);
        }
        if(data.class != null && data.class.id != null){
            ldata.lcl_id = parseInt(data.class.id);
        }
        if(data.mode != null && data.mode.id != null){
            ldata.operation_mode_id = parseInt(data.mode.id);
        }
        if(data.laserType != null && data.laserType.id != null){
            ldata.lty_id = parseInt(data.laserType.id);
        }
        if(data.unit != null && data.unit.id != null){
            ldata.power_units = data.unit.name;
        }
        if(data.training && data.internalTraining){
            ldata.training_id = parseInt(data.training.id);
        }
        if(data.hazardEvaluation != null && data.hazardEvaluation.id != null){
            ldata.laser_hazard_evaluation = data.hazardEvaluation.name;
        }
        if(data.registrationData != null && data.registrationData.number != null){
            ldata.reg_number = data.registrationData.number;
            ldata.reg_date = Helpers.formatDate(data.registrationData.date);
            ldata.reg_due_date = Helpers.formatDate(data.registrationData.dueDate);
            ldata.reg_details = data.registrationData.details;
        }
        ldata.compliance_testing_required = data.complianceTestingRequired ? data.complianceTestingRequired : false;
        ldata.premises_certification_required = data.premisesCertificationRequired ? data.premisesCertificationRequired : false;
        ldata.servicing_required = data.servicingRequired ? data.servicingRequired : false;
        ldata.permit_required = data.permitRequired ? data.permitRequired : false;

        if (data.assetID) ldata.asset_id = data.assetID;
        ldata.vendor_training = data.vendorTraining ? data.vendorTraining : false;
        ldata.internal_training = data.internalTraining ? data.internalTraining : false;
        ldata.not_applicable_training = data.notApplicableTraining ? data.notApplicableTraining : false;

        return $http({
            url: API_BASE + "/equipment/equipment-add",
            method: "POST",
            data: ldata
        }).then(function(result) {
            let params = _.isObject(result.data.params) ? result.data.params : JSON.parse(result.data.params);
            return params.equ_id;
        }, function(error) {
            return error;
        });
    };

    this.getEquipmentDetails = function(id) {
        return $http({
            url: API_BASE + "/equipment/" + id,
            method: "GET"
        }).then(function(data) {
            let employeeLocations = data.data[1][1] ? data.data[1] : data.data[3];

            var units = [
                {id:1, code:'mj', name: 'mJ'},
                {id:2, code:'jls', name: 'Joules'},
                {id:3, code:'mw', name: 'mW'},
                {id:4, code:'wts', name: 'Watts'}
            ];

            var hazardEvaluations = [
                {id:1, code:'tmc', name: 'Through Microscope'},
                {id:2, code:'tma', name: 'Through Microscope / Aperture'},
                {id:3, code:'tgp', name: 'Through G Probe'},
                {id:4, code:'aos', name: 'Applied on Skin'},
                {id:5, code:'fbr', name: 'Fiber'},
                {id:6, code:'nwr', name: 'NHZ Whole Room'},
                {id:7, code:'naa', name: 'NHZ around Aperture'},
                {id:8, code:'nhc', name: 'NHZ Confined'},
                {id:9, code:'no', name: 'No'},
                {id:10, code:'yes', name: 'Yes'}
            ];

            // TODO clear this mess
            var elem;
            var elemArray = data.data[0];
            if (elemArray != null) {
                if (elemArray.length === 1) {
                    elem = elemArray[0];
                }
                else {
                    elem = elemArray;
                }
            }

            var laser = new Laser();

            units.forEach(function(unit){
                if(unit.name === elem.power_units) {
                    laser.unit.name = unit.name;
                    laser.unit.id = unit.id;
                    laser.unit.code = unit.name.code;
                }
            });

            hazardEvaluations.forEach(function(hazardEvaluation){
                if(hazardEvaluation.name === elem.laser_hazard_evaluation) {
                    laser.hazardEvaluation.name = hazardEvaluation.name;
                    laser.hazardEvaluation.id = hazardEvaluation.id;
                    laser.hazardEvaluation.code = hazardEvaluation.code;
                }
            });

            laser.equipment_id = elem.equ_id;
            laser.user_friendly_id = elem.equipment_id;
            laser.serial = elem.equ_serial;
            laser.barcode = _.isNull(elem.barcode) ? elem.equipment_id : elem.barcode;
            laser.transaction_id = elem.transaction_id;
            laser.status = elem.status;
            laser.waveLength = elem.wave_length;

            laser.account.id = elem.account_id;
            laser.account.name = elem.account_name;
            laser.account.uuid = elem.account_uuid;
            laser.account.lspn = elem.lspn;

            laser.type.id = parseInt(elem.equipment_basic_category_id);
            laser.type.code = elem.equipment_basic_category_code;
            laser.type.name = elem.equipment_basic_category_name;

            laser.category.id = elem.equipment_category_id;
            laser.category.name = elem.equipment_category_name;
            laser.category.isRegulated = elem.equipment_category_is_regulated;

            laser.manufacturer.description = elem.manufacturer_description;
            laser.manufacturer.code = elem.manufacturer_code;
            laser.manufacturer.name = elem.manufacturer_name;
            laser.manufacturer.id = elem.manufacturer_id;

            laser.model.name = elem.equipment_model_name;
            laser.model.id = elem.equipment_model_id;

            laser.class.name = elem.equipment_class_name;
            laser.class.id = elem.equipment_class_id;
            laser.class.code = elem.equipment_class_code;

            laser.mode.id = elem.equipment_operation_mode_id;
            laser.mode.code = elem.equipment_operation_mode_code;
            laser.mode.name = elem.equipment_operation_mode_name;

            laser.training.name = elem.training_name;
            laser.training.id = parseInt(elem.rdo_id);

            laser.certificateData.expiryDate = Helpers.formatDate(elem.certification_expire_date);
            laser.certificateData.certificate = elem.certification_number;

            laser.registrationData.date = Helpers.formatDate(elem.reg_date);
            laser.registrationData.details = elem.reg_details;
            laser.registrationData.dueDate = Helpers.formatDate(elem.reg_next_due_date);
            laser.registrationData.number = elem.reg_number;
            laser.equipmentInstallationDate =  Helpers.formatDate(elem.installation_date);
            laser.serviceDueDate = elem.service_due_date ? Helpers.formatDate(elem.service_due_date) : null;

            laser.location.name = elem.dep_name;
            laser.location.uuid = elem.dep_uuid;

            laser.disposalDescription = elem.disposal_description;
            laser.wayOfDisopsal = elem.disposal_way;
            laser.disposal = elem.status === 'dis';

            laser.complianceTestingRequired = parseInt(elem.compliance_required) !== 0;
            laser.premisesCertificationRequired = parseInt(elem.premises_required) !== 0;
            laser.servicingRequired = parseInt(elem.servicing_required) !== 0;
            laser.permitRequired = parseInt(elem.permit_required) !== 0;
            laser.permitApplicationID = elem.permit_application_id
            laser.power = elem.power;
            laser.powerNotes = elem.power_notes;
            laser.laserType.name = elem.lty_name;
            laser.laserType.id = elem.lty_id;

            laser.assetID = elem.asset_id;
            laser.vendorTraining = parseInt(elem.vendor_training_required) !== 0;
            laser.internalTraining = parseInt(elem.internal_training_required) !== 0;
            laser.notApplicableTraining = parseInt(elem.not_applicable_training) !== 0;

            $ngRedux.dispatch({
                type: 'EQUIPMENT_DATA',
                id: 'equAssetCost',
                data: {
                    equAssetCost: {
                        equ_id: elem.equ_id,
                        accountId: elem.account_id,
                        visualId: elem.equipment_id,
                        cost_centre_code: elem.cost_centre_code,
                        cost_centre_name: elem.cost_centre_name,
                        purchaseDate: Helpers.formatDate(elem.purchase_date),
                        purchase_order_number: elem.purchase_order_number,
                        installation_date: Helpers.formatDate(elem.installation_date),
                        cap_sens_exp_date: Helpers.formatDate(elem.cap_sens_exp_date),
                        warranty_period: elem.warranty_period,
                        warranty_exp_date: elem.warranty_exp_date,
                        assetCost: elem.asset_cost,
                        act_type_code: elem.act_type_code,
                        estimated_residual_value: elem.estimated_residual_value,
                        tax_estimated_useful_life: elem.tax_estimated_useful_life,
                        depreciation_rate_per_year: elem.depreciation_rate_per_year,
                        depreciation_type: elem.depreciation_type,
                        service_contract: elem.service_contract,
                        comment: elem.comment,
                        software_version: elem.software_version,
                        man_eof_support_date: elem.man_eof_support_date,
                    },
                },
            });

            return {
                equipment: laser,
                locations: employeeLocations,
            };
        });
    };

    this.updateEquipment = function(equipment) {
        const state = $ngRedux.getState();
        const { equAssetCost } = state.equipment;
        return $http({
            url: API_BASE + "/equipment/edit",
            method: "POST",
            data: {
                equ_id: equipment.equipment_id,
                loc_uuid: equipment.location.uuid,
                ety_id: equipment.category.id,
                emo_id: equipment.model.id,
                serial: equipment.serial,
                wave_length: equipment.waveLength,

                lcl_id: parseInt(equipment.class.id),
                operation_mode_id: parseInt(equipment.mode.id),

                installation_date: Helpers.formatDate(equipment.equipmentInstallationDate),
                service_due_date: Helpers.formatDate(equipment.serviceDueDate),

                reg_number: equipment.registrationData.number,
                reg_date: Helpers.formatDate(equipment.registrationData.date),
                reg_due_date: Helpers.formatDate(equipment.registrationData.dueDate),
                reg_details: equipment.registrationData.details,

                power: equipment.power,
                power_notes: equipment.powerNotes,
                power_units: equipment.unit.name,
                lty_id: parseInt(equipment.laserType.id),
                laser_hazard_evaluation: equipment.hazardEvaluation.name,

                training_id: equipment.internalTraining && equipment.training ? equipment.training.id : null,

                compliance_testing_required: equipment.complianceTestingRequired ? equipment.complianceTestingRequired : false,
                premises_certification_required: equipment.premisesCertificationRequired ? equipment.premisesCertificationRequired : false,
                servicing_required: equipment.servicingRequired ? equipment.servicingRequired : false,
                permit_required: ( equipment.permitRequired && equipment.showPermitRequired ) ? equipment.permitRequired : false,

                asset_id: equipment.assetID,
                vendor_training: equipment.vendorTraining ? equipment.vendorTraining : false,
                internal_training: equipment.internalTraining ? equipment.internalTraining : false,
                not_applicable_training: equipment.notApplicableTraining ? equipment.notApplicableTraining : false,
                /**
                 * ASSET COST
                 */
                purchase_date: Helpers.formatDate(equAssetCost.purchaseDate, DATE_FORMAT.OUTPUT),
                purchase_order_number: equAssetCost.purchase_order_number,
                warranty_period: equAssetCost.warranty_period,
                warranty_exp_date: Helpers.formatDate(equAssetCost.warranty_exp_date, DATE_FORMAT.OUTPUT),
                asset_cost: equAssetCost.assetCost,
                act_type_code: equAssetCost.act_type_code,
                estimated_residual_value: equAssetCost.estimated_residual_value,
                depreciation_rate_per_year: equAssetCost.depreciation_rate_per_year,
                tax_estimated_useful_life: equAssetCost.tax_estimated_useful_life,
                depreciation_type: equAssetCost.depreciation_type,
                service_contract: equAssetCost.service_contract,
                comment: equAssetCost.comment,
                software_version: equAssetCost.software_version,
                man_eof_support_date: Helpers.formatDate(equAssetCost.man_eof_support_date, DATE_FORMAT.OUTPUT),
            }
        }).then(function(result) {
            return _.isObject(result.data.params) ? result.data.params : JSON.parse(result.data.params);
        });
    };

    this.fetchClasses = function() {
        return $http({
            url: API_BASE + "/equipment/laser/classes",
            method: "GET"
        }).then(function(result) {
            var arr = [];
            result.data.forEach(function(elem) {
                var clazz = new LaserClass();
                clazz.id = elem.ecl_id;
                clazz.name = elem.name;
                arr.push(clazz);
            });
            return arr;
        }, function(error) {
            return error;
        });
    };

    this.fetchOperationMode = function() {
        return $http({
            url: API_BASE + "/equipment/modes/laser",
            method: "GET"
        }).then(function(result) {
            var operationModes = [];
            result.data.forEach(function(elem) {
                var mode = new LaserMode();
                mode.id = elem.op_mode_id;
                mode.code = elem.op_mode_code;
                mode.name = elem.op_mode_name;
                mode.isRegulated = elem.category_is_regulated;
                operationModes.push(mode);
            });
            return operationModes;
        }, function(error) {
            return error;
        });
    };

    this.fetchCategories = function() {
        return $http({
            url: API_BASE + "/equipment/laser/categories",
            method: "GET"
        }).then(function(result) {
            var categories = [];
            result.data.forEach(function(item) {
                var category = new EquipmentCategory();
                category.id = item.category_id;
                category.name = item.category_name;
                categories.push(category);
            });
            return categories;
        }, function(error) {
            return error;
        });
    };

    this.fetchLaserTypes = function(equipmentTypeCode) {
        return $http({
            url: API_BASE + "/equipment/laser/types",
            method: "GET",
            params: {
                equipmentTypeCode: equipmentTypeCode
            }
        }).then(function(result) {
            var categories = [];
            result.data.forEach(function(item) {
                var category = new EquipmentCategory();
                category.id = item.category_id;
                category.name = item.category_name;
                categories.push(category);
            });
            return categories;
        }, function(error) {
            return error;
        });
    };

    this.addLaserName = function(category) {
        return $http({
            url: API_BASE + '/equipment-calibration/type/add',
            method: 'POST',
            data: {
                ety_code: 'las',
                category_name: category.name
            }
        }).then(function(response) {
            return response;
        });
    };
}
