/* @ngInject */
export function SelectLicenceTypeController($ngRedux, AdministrationActions) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);
        ctrl.getSiteLocationLicenceTypes(this.siteLocation.uuid);
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            let licence = ctrl.options.find(obj => obj.id === ctrl.selectedOption);
            $ngRedux.dispatch({
                type: 'SITE_LOCATION_LICENCE_TYPE_SELECTED',
                id: 'administrationAccountsSiteLocationLicence',
                licenceType: ctrl.selectedOption,
                licenceCode: licence.code
            });
        }
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    function mapStateToThis(state) {
        return {
            options: state.administration.administrationAccountsSiteLocationLicence.types,
            selectedOption: state.administration.administrationAccountsSiteLocationLicence.data.type,
            siteLocation: state.administration.administrationAccountsSiteLocation.data
        };
    }
}
