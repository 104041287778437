import { GridController, gridTpl } from '../../../../common/grid/grid.controller';
import {GRID_FILTER_TOGGLE} from '../../../../common/grid/grid.constants';
import { IDS } from '../settings-action-id.constants';
import * as Actions from './hof-required-docs.actions';

class HofRequiredDocsGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, AuthService, $filter, $mdDialog, $scope) {
        super($ngRedux, $state, NavigationService, $filter, $mdDialog);
        this.$scope = $scope;
        this.AuthService = AuthService;
    }

    $onInit() {
        this.module = 'accreditation';
        this.identifier = IDS.accSettings;
        this.statePath = IDS.hofRequiredDocsGrid;
        this.filterOpen = true;
        this.filterExpandButtonVisible = false;
        this.exportComponent = false;
        this.confirmDialogOnRemove = true;
        this.autoload = false;
        this.header = false;

        this.isAdmin = this.AuthService.getUser().type === 'Administrator';

        this.columnsDefault = [
            {title: '#',                                                            mapper: null},
            {title: this.$filter('translate')(`COMMON.DOCUMENT.NUMBER`),            mapper: 'doc_num'},
            {title: this.$filter('translate')(`COMMON.FIELD.TYPE`),                 mapper: 'type_name'},
            {title: this.$filter('translate')(`COMMON.FIELD.HEAD_OFFICE`),          mapper: 'location'},
            {title: this.$filter('translate')(`COMMON.FIELD.NAME`),                 mapper: 'name'},
            {title: this.$filter('translate')(`ACCREDITATION.COMMON.STANDARDS`),    mapper: 'standardsColumn'},
            // {title: this.$filter('translate')('COMMON.FIELD.ORDER'),                mapper: 'orderChange', style:{ width:'40px' }},
        ];
        this.columns = this.columnsDefault.concat();

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), Actions)(this);
        this.unsubscribeCustom = this.$ngRedux.connect(state => {
            const hofRequiredDocsGrid = state.accreditation.accSettings.hofRequiredDocsGrid;
            return {
                selectedHeadOfficeUuid:   hofRequiredDocsGrid.selectedHeadOfficeUuid,
                overrideColumns:          hofRequiredDocsGrid.override.columns
            }
        }, {})(this);

        this.dataAction = this.fetchHofRequiredDocsGrid;

        this.buttons.push({
            class: 'btnGridAction',
            label: 'Download',
            callback: event => {
                this.onDownload(event);
            },
            filter: item => {
                return item.sample_doc_uri
            },
        });
        this.init();

        this.buttonsDefault = this.buttons.concat();
        // this.hideDetachButton();
    }

    // showAllColumns() {
    //     this.columns = this.columnsDefault.concat();
    // }

    // hideDeleteButton() {
    //     this.buttons = this.buttonsDefault.concat().filter(item => item.class !== 'btnGridRemove');
    // }

    // hideDetachButton() {
    //     this.buttons = this.buttonsDefault.concat().filter(item => item.class !== 'btnGridAction');
    // }

    $onDestroy() {
        this.destroy();
        if(this.unsubscribeCustom)
            this.unsubscribeCustom();
    }
}

HofRequiredDocsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'AuthService', '$filter', '$mdDialog', '$scope'];

export const hofRequiredDocsGrid = {
    bindings: {
        onSelect: '&',
        onRemove: '&',
        onDownload: '&',
    },
    controller: HofRequiredDocsGridController,
    ...gridTpl
}
