import {EMP_LICENCE_RESET} from '../employees.actions.types';

export const employeesPublicState = {
    name: 'public.employees',
    abstract: true,
    url: '/public/employees'
};

export const employeesDashboardPublicState = {
    name: 'public.employees.dashboard',
    url: '',
    component: 'dashboard'
};

export const employeesLicencePublicState = {
    name: 'public.employees.licence',
    redirectTo: 'public.employees.licence.details',
    url: '/licence',
    component: 'tabs',
    /* @ngInject */
    onEnter: ($ngRedux) => {
        $ngRedux.dispatch({
            type: EMP_LICENCE_RESET,
            id: 'empLicence'
        });
    }
};

export const employeesLicenceDetailsPublicState = {
    name: 'public.employees.licence.details',
    url: '?token&licence',
    component: 'empLicenceDetails'
};
