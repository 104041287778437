/* @ngInject */
export const GridFilterFactory = (Helpers) => {
    function GridFilter() {

        // TODO remove id after migrating to component
        this.id = Helpers.generateIdentifier();
        this.template = null;
        this.keyword = null;
        this.offset = 0;
        this.limit = 10;
        this.page = 1;

        this.filterItems = angular.noop;
        this.search = angular.noop;
    }

    GridFilter.prototype.init = function(page) {
        this.page = _.isUndefined(page) ? this.page : page;
        this.offset = (this.page - 1) * this.limit;
    };

    GridFilter.prototype.reset = function() {
        this.keyword = null;
        this.offset = 0;
        this.page = 1;
    };

    return GridFilter;
};
