import { GridController } from './grid.controller';

export const grid = {
    bindings: {
        identifier: '@',
        config: '<',
        items: '<',
        itemsCount: '<',
        title: '<'
    },
    controller: GridController,
    template: require('./grid.html')
};
