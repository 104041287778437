import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';

class EquipmentCalibrationDocumentsGridController extends GridController {
    constructor($ngRedux, $state, NavigationService, EquipmentCalibrationActions, $filter) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.actions = EquipmentCalibrationActions;
        this.$filter = $filter;
    }

    $onInit() {
        this.module = 'equipmentCalibration';
        this.identifier = 'equipmentCalibrationDocumentsGrid';
        this.filterTemplate = './equipment-calibration-documents-grid-filter.html';
        this.autoload = true;
        this.exportComponent = false;

        this.columns = [
            {title: this.$filter('translate')('EQUIPMENT.TABS.DOCUMENTS.GRID_COLUMN_TYPE'),      mapper: 'documentType'},
            {title: this.$filter('translate')('EQUIPMENT.TABS.DOCUMENTS.GRID_COLUMN_DUE_DATE'),  mapper: 'dueDate'},
            {title: this.$filter('translate')('EQUIPMENT.TABS.DOCUMENTS.GRID_COLUMN_DOCUMENT'),  mapper: 'file-download'},
            {title: this.$filter('translate')('EQUIPMENT.TABS.DOCUMENTS.GRID_COLUMN_STATUS'),    mapper: 'status'}
        ];

        this.unsubscribe = this.$ngRedux.connect(this.mapState().bind(this), this.actions)(this);
        this.dataAction = this.getDocuments;

        this.init();
    }
}

EquipmentCalibrationDocumentsGridController.$inject = ['$ngRedux', '$state', 'NavigationService', 'EquipmentCalibrationActions', '$filter'];

export const equipmentCalibrationDocumentsGrid = {
    bindings: {
        title: '@',
        onRemove: '&'
    },
    controller: EquipmentCalibrationDocumentsGridController,
    ...gridTpl
};
