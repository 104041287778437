import Chart from 'chart.js';

/* @ngInject */
export function DicomQualityControlTestController($controller, $scope, $rootScope, $q, $timeout, Notifier, MammoQCService, DicomQualityControlService, DicomQCTestManager, DicomQCTest, DicomQCPlotDiagramFilter, Helpers, AuthService, SUBJECT_TYPE, UserService, NavigationService) {

    angular.extend(this, $controller('ApplicationModuleController', {
        $scope: $scope,
        $timeout: $timeout,
        Notifier: Notifier
    }));

    $scope.tplDicomQualitycontrolFilter = './dicom-quality-control-filter.html';

    $scope.QCTest = new DicomQCTest();
    $scope.PlotDiagram = new DicomQCPlotDiagramFilter();

    $scope.MODULE_STATE = $scope.MODULE_STATE_CREATE;
    $scope.DICOMQC_TEST_STATE = $scope.MODULE_STATE_CREATE;

    $scope.tabs = _.cloneDeep(DicomQCTestManager.tabs);

    var navConfig = NavigationService.getNavConfig();

    UserService.getUserAllowedActions(navConfig.id).then(function(result) {

        if(!result.includes('action_add_plot_values')) {
            $scope.tabs[DicomQCTestManager.TAB_DICOM_QC_TEST].visible = false;
        }
    });

    $scope.equipmentList = [];

    $rootScope.$on('form-completed', function(event, data) {
        $scope.savedForm = data;
        var locationItem = data.locationItem;
        $rootScope.$broadcast('LOCATION_SUBJECT_SELECTED', locationItem);
    })

    var user = AuthService.getUser();

    //Load data for parent site location if user belongs to only one site location
    if (user && (user.parentCount === 1) && (user.parents[user.parents.length - 1])
        && (user.parents[user.parents.length - 1].basicType == SUBJECT_TYPE.SITE_LOCATION)) {

        MammoQCService.getMammoQCEquipment(user.parents[user.parents.length - 1].uuid).then(function(data) {
            $scope.equipmentList = data;
        });
    }

    $scope.$on('LOCATION_SUBJECT_SELECTED', function(event, locationItem) {
        $scope.QCTest.locationId = locationItem.uuid;

        MammoQCService.getMammoQCEquipment($scope.QCTest.locationId).then(function(data) {
            $scope.equipmentList = data;
        });

        /*MammoQCService.getMammoQCTesterCandidates($scope.QCTest.locationId).then(function(data) {
            $scope.testerCandidatesList = data;
        });*/
    });

    $scope.maxTestDate = new Date();

    _.extend(_.find($scope.tabs, ['id', DicomQCTestManager.TAB_DICOM_QC_TEST]), {

        exec: function() {

            var deferred = $q.defer();
            DicomQualityControlService.addDicomQualityControlTest($scope.QCTest).then(function(id) {

                $scope.PlotDiagram.visible = true;

                Notifier.success('Test was successfully added');
                deferred.resolve(DicomQCTestManager.TAB_DICOM_QC_TEST_PLOT_DIAGRAM);
                $scope.setSearchDate(null, 1);
                $scope.PlotDiagram.dicomQCEquipment.equId = $scope.QCTest.dicomQCEquipment.equId;
                $scope.PlotDiagram.dicomQCEquipment = $scope.QCTest.dicomQCEquipment;
                getPlotDiagram();
            }, function(error) {
                Notifier.responseMessage(error);
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', DicomQCTestManager.TAB_DICOM_QC_TEST_PLOT_DIAGRAM]), {

        exec: function() {

            $scope.PlotDiagram.visible = true;
            var deferred = $q.defer();

            getPlotDiagram();

            return deferred.promise;
        }
    });

    function getPlotDiagram() {

        DicomQualityControlService.getQualityControlTestResult($scope.PlotDiagram).then(function(data) {

            var arrMediumDensity = [];
            var arrLowDensity = [];
            var arrDifferenceDensity = [];
            var arrTestDate = [];
            if( data.items ) {
                for (var i = 0; i < data.items.length; i++) {
                    arrMediumDensity.push(data.items[i].mediumDensity);
                    arrLowDensity.push(data.items[i].lowDensity);
                    arrDifferenceDensity.push(data.items[i].differenceDensity);
                    arrTestDate.push(data.items[i].testDate+" ("+data.items[i].firstName.charAt(0)+'.'+data.items[i].lastName.charAt(0)+'.)');
                }
            }

            Chart.pluginService.register({
                beforeDraw: function (chart) {
                    if (chart.config.options.chartArea && chart.config.options.chartArea.backgroundColor) {
                        var ctx = chart.chart.ctx;
                        var chartArea = chart.chartArea;

                        ctx.save();
                        ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
                        ctx.fillRect(0, 5, chartArea.right + 80, chartArea.bottom + 40);
                        ctx.restore();
                    }
                }
            });

            var config = {
                type: 'line',
                data: {
                    labels: arrTestDate,
                    datasets: [{
                        label: "Medium Density",
                        backgroundColor: '#ff0000',
                        borderColor: '#ff0000',
                        fill: false,
                        data: arrMediumDensity,
                        pointRadius: 10,
                        pointHoverRadius: 15,
                        showLine: false
                    }, {
                        label: "Low Density",
                        backgroundColor: '#00ff00',
                        borderColor: '#00ff00',
                        fill: false,
                        data: arrLowDensity,
                        pointRadius: 10,
                        pointHoverRadius: 15,
                        showLine: false
                    }, {
                        label: "Difference Density",
                        backgroundColor: '#0000ff',
                        borderColor: '#0000ff',
                        fill: false,
                        data: arrDifferenceDensity,
                        pointRadius: 10,
                        pointHoverRadius: 15,
                        showLine: false
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    title:{
                        display:true,
                        text: 'Dicom Quality Control Test for '+$scope.PlotDiagram.dicomQCEquipment.serial+' | '+$scope.PlotDiagram.dicomQCEquipment.manufacturer+' | '+$scope.PlotDiagram.dicomQCEquipment.typeName+' | '+$scope.PlotDiagram.dicomQCEquipment.modelName
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                        }],
                        yAxes: [{
                            ticks: {
                                min: 0,
                                max: 100,
                                stepSize: 10
                            }
                        }]
                    },
                    chartArea: { backgroundColor: 'rgb(250,250,250)' },
                    animation: { onComplete: exportChart },
                    elements: {
                        point: {
                            pointStyle: 'circle'
                        }
                    }
                }
            };

            if(data['items']) {
                if (data['items'].length) {
                    var ctx = document.getElementById("myChart").getContext('2d');
                    $scope.myChart = new Chart(ctx, config);

                    $scope.noResult = false;
                } else {
                    $scope.PlotDiagram.visible = false;
                    $scope.noResult = true;
                    //Notifier.error('No result');
                }
            }
        }, function(error) {
            Notifier.responseMessage(error);
        });
    }

    function exportChart() {
        if (document.getElementById("myChart")) {
            var canvas = document.getElementById("myChart");
            var img    = canvas.toDataURL("image/png");
            export_link.href = img;

            $scope.chartName = $scope.PlotDiagram.dicomQCEquipment.serial + '-' + $scope.PlotDiagram.dicomQCEquipment.manufacturer + '-' + $scope.PlotDiagram.dicomQCEquipment.typeName + '-' + $scope.PlotDiagram.dicomQCEquipment.modelName + '.png';
        }
    }

    $scope.resetFilter = function() {
        $scope.PlotDiagram.reset();
        $rootScope.$broadcast('SELECT_CASCADE_RESET', 'dicomQCPlotDiagramForm');

        $scope.selectedTab.form.$setUntouched();
        $scope.selectedTab.form.$setPristine();
        $scope.selectedTab.form.$setValidity();
    };

    $scope.setSearchDate = function(numberOfDays, numberOfMonths) {

        $scope.PlotDiagram.dateTo = new Date();
        $scope.PlotDiagram.dateFrom = new Date();
        if( numberOfDays != null ) {
            $scope.PlotDiagram.dateFrom = new Date($scope.PlotDiagram.dateTo.getTime() - numberOfDays * 24 * 60 * 60 * 1000);
        } else {
            $scope.PlotDiagram.dateFrom.setMonth($scope.PlotDiagram.dateFrom.getMonth() - numberOfMonths);
        }
    }
}
