/* @ngInject */
export function AccStandardsPanelController (ACC_STANDARD_TYPE, AccreditationService, Notifier, $timeout, ACC_ASSESSMENT_ITEM_TYPE, AccreditationDocumentsService, DocumentManager, DOCUMENT_TYPE, $rootScope, $filter) {
    var ctrl = this;
    ctrl.SUB_SUB_STANDARD = ACC_STANDARD_TYPE.SUB_SUB_STANDARD;
    ctrl.currentLevel = []; //what is currently displayed in controll, like standards, or substandards of selected standard
    ctrl.history = [];
    ctrl.getProgressIcon = function (item) {

        if (item.progressNA) {
            return {
                icon: "remove",
                color: "#0288d1"
            };
        } else if ((item.progress >= 0) && (item.progress <= 99)) {
            return {
                icon: "warning",
                color: "#f2dede"
            };
        } else if ((item.progress > 99) && (item.progress <= 100)) {
            return {
                icon: "done",
                color: "#7BC37B"
            };
        } else {
            return {
                icon: "warning",
                color: "#f2dede"
            };
        }
    };

    ctrl.selectStandardItem = function (item) {
        ctrl.history.push(ctrl.selectedStandard);
        ctrl.selectedStandard = item;
        ctrl.currentLevel = item.children;
        ctrl.itemSelected({
            id: (ctrl.selectedStandard != null ? ctrl.selectedStandard.standardId : null)
        });
    };

    ctrl.makeDirty = function(assessment) {
        assessment.dirty = true;
    };

    ctrl.returnBack = function () {
        //check if current item is dirty?
        if ((ctrl.selectedStandard) && (ctrl.selectedStandard.assessment)
            && (ctrl.selectedStandard.assessment.dirty === true)) {
            //Ask if user is sure to leave the page without saving
            $rootScope.confirmationDialog($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.CONFIRM_UNSAVED"))
                .then(function (result) {
                    var tempItem = ctrl.history.pop();
                    ctrl.selectedStandard = tempItem;
                    ctrl.currentLevel = tempItem != null ? tempItem.children : ctrl.accStandards;
                    ctrl.itemSelected({
                        id: (ctrl.selectedStandard != null ? ctrl.selectedStandard.standardId : null)
                    });
                }, function (error) {
                    //Cancel
                });
        } else {
            var tempItem = ctrl.history.pop();
            ctrl.selectedStandard = tempItem;
            ctrl.currentLevel = tempItem != null ? tempItem.children : ctrl.accStandards;
            ctrl.itemSelected({
                id: (ctrl.selectedStandard != null ? ctrl.selectedStandard.standardId : null)
            });
        }
    };

    ctrl.resetCurrentForm = function () {
        //First ask if user is sure about this
        //Reset all data and save to system
        $rootScope.confirmationDialog($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.CONFIRM_RESET"))
            .then(function (result) {
                //Perform reset here
                ctrl.selectedStandard.assessment.value = null;
                ctrl.selectedStandard.assessment.comment = null;
                ctrl.selectedStandard.assessment.assessmentItems.forEach(function (assItem) {
                    assItem.value = null;
                });
                ctrl.saveCurrentForm();
                ctrl.selectedStandard.assessment.dirty = false;
                ctrl.clearCurrentForm();

            }, function (error) {
                //Cancel
            });
    };

    ctrl.clearCurrentForm = function () {
        //fetch current form values from database
        //Force reloading values;
        if (ctrl.assessmentValueWatch != null) {
            ctrl.assessmentValueWatch();
        }
        var tempItem = ctrl.selectedStandard;
        ctrl.selectedStandard = {};

        $timeout(
            function () {
                ctrl.selectedStandard = tempItem;
            }
        );
    };

    ctrl.saveCurrentForm = function () {
        //save current form values to database
        AccreditationService.saveAccreditationAssessment(ctrl.accreditationSchemaCode, ctrl.locationUuid,
            ctrl.selectedStandard.standardId, ctrl.selectedStandard.assessment, ctrl.year)
            .then(function (success) {
                ctrl.selectedStandard.assessment.dirty = false;
                Notifier.success($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_SAVE_SUCCESS"));
            }, function (error) {
                Notifier.error($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_SAVE_FAILED"));
            });
    };

    ctrl.setApprovalCurrentForm = function () {
        //save current approval form values to database
        ctrl.selectedStandard.assessment.assessmentItems.forEach(function (assItem) {
            if ((assItem.assessmentItemType === ACC_ASSESSMENT_ITEM_TYPE.DOCUMENT) ||
                (assItem.assessmentItemType === ACC_ASSESSMENT_ITEM_TYPE.DOCUMENT_GENERATED)) {

                setDocumentApprovalStatus(assItem.value, 0);
            }
        });
    };

    function setDocumentApprovalStatus(documentList, index) {
        AccreditationDocumentsService.editAccDocumentApprovalStatus(documentList[index].documentId, documentList[index].status, documentList[index].statusComment)
            .then(function (success) {
                ctrl.selectedStandard.assessment.dirty = false;
                Notifier.success($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_SAVE_SUCCESS"));
                if (index < documentList.length-1) {
                    setDocumentApprovalStatus(documentList, index + 1);
                }
            }, function (error) {
                Notifier.error($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_SAVE_FAILED"));
            });
    }

    ctrl.fetchStandardProgress = function (requestedStandardId) {

        AccreditationService.fetchAccShemaStandardProgress(ctrl.accreditationSchemaCode,
            ctrl.locationUuid, requestedStandardId, ctrl.year)
            .then(function (response) {
                if ((response.status >= 200) && (response.status < 300) &&
                    (response.data.result != null)) {
                    //Update all current shown standards with accreditation progress
                    for (var stdId in ctrl.currentLevel) {
                        if (ctrl.currentLevel.hasOwnProperty(stdId)) {
                            if (response.data.result[stdId] != null) {
                                if ((response.data.result[stdId].applicable != null) && (response.data.result[stdId].applicable == "no")) {
                                    //this standard is not applicable
                                    ctrl.currentLevel[stdId].progressNA = true;
                                }
                                ctrl.currentLevel[stdId].progress = response.data.result[stdId].progress * 100;
                            }
                        }
                    }
                } else if ((response.status >= 500) && (response.status < 600)) {
                    Notifier.error($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_PROGRESS_FETCH_ERROR"));
                } else if ((response.status >= 400) && (response.status < 500)) {
                    Notifier.error($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_PAGE_NOT_FOUND"));
                } else if ((response.data == null) || (response.data.result == null)) {
                    //Error fetching data for this item
                    Notifier.error($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_PROGRESS_FETCH_ERROR"));
                }
            }, function (error) {
                Notifier.error($filter('translate')("ACCREDITATION.TABS.DOCUMENTS.NOTIFIER_PROGRESS_FETCH_ERROR"));
            });
    };

    ctrl.downloadTemplate = function (item) {
        item.templateDocuments.forEach(function(docPath) {
            DocumentManager.downloadFile('/'+docPath, DOCUMENT_TYPE.ACCREDITATION_DOCUMENT);
        });

    };
}
