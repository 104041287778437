export const personalDosimetryImportState = {
    name: 'app.personalDosimetry.import',
    redirectTo: 'app.personalDosimetry.import.document',
    url: '/import',
    component: 'tabs'
};

export const personalDosimetryImportDocumentState = {
    name: 'app.personalDosimetry.import.document',
    url: '',
    component: 'personalDosimetryImport',
    onEnter: $ngRedux => {
        $ngRedux.dispatch({
            type: 'TAB_DISABLE',
            id: 'app.personalDosimetry.import',
            state: ['app.personalDosimetry.import.details']
        });
        $ngRedux.dispatch({
            type: 'GRID_RESET_FILTER',
            id: 'personalDosimetryReportsDetailsGrid'
        });
    },
    onExit: $ngRedux => {
        $ngRedux.dispatch({
            type: 'IMPORT_RESET',
            id: 'personalDosimetryImport'
        });
    }
};
personalDosimetryImportDocumentState.onEnter.$inject = ['$ngRedux'];
personalDosimetryImportDocumentState.onExit.$inject = ['$ngRedux'];

export const personalDosimetryImportDetailsState = {
    name: 'app.personalDosimetry.import.details',
    url: '/details?id',
    component: 'personalDosimetryReportsDetails'
};

export const personalDosimetryImportDetailsResultState = {
    name: 'app.personalDosimetry.import.detailsResult',
    url: '/result?id&measurement',
    component: 'personalDosimetryReportsDetailsResult',
    onExit: $ngRedux => {
        $ngRedux.dispatch({
            type: 'TAB_HIDE',
            id: 'app.personalDosimetry.import',
            state: ['app.personalDosimetry.import.detailsResult']
        });
    }
};
personalDosimetryImportDetailsResultState.onExit.$inject = ['$ngRedux'];
