/**
 * @module RadioactiveMaterials
 */
import * as STATES from './radioactive-materials.states';
import * as STATES_WASTE from './rm-waste/rm-waste.states';
import * as STATES_TEST from './rm-test/rm-test.states';
import { rmWaste } from './rm-waste/rm-waste.component';
import { rmWasteDetails } from './rm-waste/details/rm-waste-details.component';
import { rmWasteGrid } from './rm-waste/grid/rm-waste-grid.component';
import { rmWasteAlerts } from './rm-waste/alerts/rm-waste-alerts.component';
import { rmTest } from './rm-test/rm-test.component';
import { rmTestDetails } from './rm-test/details/rm-test-details.component';
import { rmTestGrid } from './rm-test/grid/rm-test-grid.component';
import { rmTestDocuments } from './rm-test/documents/rm-test-documents.component';
import { rmTestDocumentsGrid } from './rm-test/documents/grid/rm-test-documents-grid.component';
import { rmTestWipeTest } from './rm-test/wipe-test/rm-test-wipe-test.component';
import { rmTestDisposal } from './rm-test/disposal/rm-test-disposal.component';
import { rmTestAlerts } from './rm-test/alerts/rm-test-alerts.component';

export const RADIOACTIVE_MATERIALS_MODULE = angular.module(
    'migration.radioactive-materials',
    []
).config(['$uiRouterProvider', ({ stateRegistry }) => {
    Object.values({
        ...STATES,
        ...STATES_WASTE,
        ...STATES_TEST
    }).map(state => stateRegistry.register(state));
}]).component({
    rmWaste,
    rmWasteDetails,
    rmWasteGrid,
    rmWasteAlerts,
    rmTest,
    rmTestDetails,
    rmTestGrid,
    rmTestDocuments,
    rmTestDocumentsGrid,
    rmTestWipeTest,
    rmTestDisposal,
    rmTestAlerts
}).run(['$templateCache', ({ put }) => {
    put('app.radioactiveMaterials.waste.add.details', '<rm-waste-details></rm-waste-details>');
    put('app.radioactiveMaterials.waste.manage.listing', '<rm-waste></rm-waste>');
    put('app.radioactiveMaterials.waste.manage.details', '<rm-waste-details></rm-waste-details>');
    put('app.radioactiveMaterials.waste.alerts.listing', '<rm-waste-alerts></rm-waste-alerts>');
    put('app.radioactiveMaterials.test.add.details', '<rm-test-details></rm-test-details>');
    put('app.radioactiveMaterials.test.add.documents', '<rm-test-documents></rm-test-documents>');
    put('app.radioactiveMaterials.test.manage.listing', '<rm-test></rm-test>');
    put('app.radioactiveMaterials.test.manage.details', '<rm-test-details></rm-test-details>');
    put('app.radioactiveMaterials.test.manage.documents', '<rm-test-documents></rm-test-documents>');
    put('app.radioactiveMaterials.test.manage.wipeTest', '<rm-test-wipe-test></rm-test-wipe-test>');
    put('app.radioactiveMaterials.test.manage.disposal', '<rm-test-disposal></rm-test-disposal>');
    put('app.radioactiveMaterials.test.alerts.listing', '<rm-test-alerts></rm-test-alerts>');
    put('./rm-waste-grid-filter.html', require('./rm-waste/grid/rm-waste-grid-filter.html'));
    put('./rm-waste-alerts-grid-filter.html', require('./rm-waste/alerts/rm-waste-alerts-grid-filter.html'));
    put('./rm-test-grid-filter.html', require('./rm-test/grid/rm-test-grid-filter.html'));
    put('./rm-test-grid-filter-controls.html', require('./rm-test/grid/rm-test-grid-filter-controls.html'));
    put('./rm-test-documents-grid-filter.html', require('./rm-test/documents/grid/rm-test-documents-grid-filter.html'));
    put('./rm-test-alerts-grid-filter.html', require('./rm-test/alerts/rm-test-alerts-grid-filter.html'));
}]);
