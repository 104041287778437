import { tabs } from './tabs.component';
import { tabsHeader } from './header/tabs-header.component';
import { tabsLoader } from './loader/tabs-loader.component';
import { tabsNavigation } from './navigation/tabs-navigation.component';

export const TABS_MODULE = angular.module('common.tabs', []);

TABS_MODULE.component('tabs', tabs);
TABS_MODULE.component('tabsHeader', tabsHeader);
TABS_MODULE.component('tabsLoader', tabsLoader);
TABS_MODULE.component('tabsNavigation', tabsNavigation);
