import { TAB_SELECT, TAB_DISABLE, TAB_SHOW } from '../../../../common/tabs/tabs.constants';
import { AP_REQUEST_EQUIPMENT } from '../../applications-permits.actions.types';
import { submitLaserRequest } from '../ap-laser-request.actions';
import { formatDate } from '../../../../common/utils/util.date';
import { DATE_FORMAT } from '../../../../common/utils/util.constants';
import i18n from '../../i18n/en.json';
import isEmpty from 'lodash/isEmpty';

/**
 * @const {object}
 * @memberOf module:ApplicationsPermits
 */
export const apLaserRequestEquipment = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $scope, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.identifier = $state.$current.parent.name;
            this.state = `${this.identifier}.equipment`;
            this.minSignatureDate = new Date();
            this.permitInfo = i18n[this.type].equipment.info;
            this.notifySuccess = i18n[this.type].equipment.notify.success;

            this.unsubscribe = $ngRedux.connect(({ applicationsPermits }) => ({
                apLaserRequest: applicationsPermits.apLaserRequest,
                applicant_signature_date: formatDate(applicationsPermits.apLaserRequest.applicant_signature_date, DATE_FORMAT.OUTPUT)
            }), { submitLaserRequest })(this);
        }

        this.onSelect = ({ item }) => {
            $ngRedux.dispatch({
                type: AP_REQUEST_EQUIPMENT,
                id: 'apLaserRequestEquipmentGrid',
                item
            });
            $ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: 'apLaserRequestEquipmentGrid'
            });
        };

        const validateApplicant = () => {
            const form = {
                title: this.apLaserRequest.title,
                first_name: this.apLaserRequest.first_name,
                last_name: this.apLaserRequest.last_name,
                email: this.apLaserRequest.email
            };
            if (this.apLaserRequest.training_completion) {
                form.training_title = this.apLaserRequest.training_title;
                form.training_doc_uri = this.apLaserRequest.training_doc_uri;
            }
            return !Object.values(form).some((item) => isEmpty(item));
        };

        this.onSubmit = () => {
            if (this.apLaserRequest.equipment.length || this.apLaserRequest.sta_id !== 4) {
                if (this.apLaserRequestEquipment.$valid) {
                    if (validateApplicant()) {
                        Notifier.info('Please wait...', false);
                        this.submitLaserRequest({
                            ...this.apLaserRequest,
                            applicant_signature_date: this.applicant_signature_date
                        }).then(() => {
                            Notifier.success(this.notifySuccess);
                            $ngRedux.dispatch({
                                type: TAB_DISABLE,
                                id: this.identifier,
                                state: [
                                    `${this.identifier}.intro`,
                                    `${this.identifier}.details`,
                                    `${this.identifier}.equipment`,
                                ],
                            });
                            $ngRedux.dispatch({
                                type: TAB_SHOW,
                                id: this.identifier,
                                state: [`${this.identifier}.confirmation`],
                            });
                        }).catch((error) => {
                            Notifier.error(error.data)
                        });
                    } else {
                        Notifier.error('Please fill in the form fields');
                        $ngRedux.dispatch({
                            type: TAB_SELECT,
                            id: this.identifier,
                            state: `${this.identifier}.details`
                        });
                    }
                } else {
                    Notifier.error('Please fill in the form fields');
                }
            } else {
                Notifier.error('Please select Equipment for Use');
            }
        }

        this.$onDestroy = () => {
            this.unsubscribe();
        }
    },
    template: require('./ap-laser-request-equipment.html')
};
