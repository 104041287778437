import { generateQRCode } from '../../../../../common/utils/util.qrcode';
import moment from 'moment/moment';
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';

/* @ngInject */
export function EquipmentLaserManageController($controller, $ngRedux, $state, $uiRouterGlobals, $scope, $rootScope, $q, $timeout, $mdDialog, API_BASE, NavigationService, Notifier, EquipmentServiceLaser, EquipmentManager, ManufacturerService, DocumentService, DocumentManager, VendorService, VendorHeadOffice, VendorGridFilter, AuthService, EquipmentService, EQUIPMENT_TYPE, EQUIPMENT_TYPE_CODE, $filter) {

    this.unsubscribe = $ngRedux.connect(({ equipment }) => ({
        equAssetCost: equipment.equAssetCost,
    }), {})(this);

    angular.extend(this, $controller('ApplicationModuleController', {
        $scope: $scope,
        $timeout: $timeout,
        Notifier: Notifier
    }));

    $scope.MODULE_STATE     = $scope.MODULE_STATE_UPDATE;
    $scope.EQUIPMENT_STATE  = $scope.MODULE_STATE_UPDATE;

    $scope.gridIdentifier = 'equipmentLasersGrid';

    $scope.templateFilter           = './equipment-lasers-filter.html';
    $scope.templateDetails          = './equipment-lasers-details.html';
    $scope.templateCertification    = './equipment-certification.html';
    $scope.templateRegistration     = './equipment-registration.html';

    var navConfig = NavigationService.getNavConfig();
    $scope.moduleColor = navConfig.colorName;
    $scope.moduleIcon = navConfig.icon;

    $scope.userData = AuthService.getUser();
    $scope.headOfficeName = $scope.userData.hofName;
    $scope.isAdmin = $scope.userData.type === 'Administrator';

    if ($scope.userData.parentCount === 1) {
        if ($scope.userData.parents[0].basicType === 'slo' || $scope.userData.parents[0].basicType === 'dep') {
            $ngRedux.dispatch({
                type: 'CHECK_LOCATION',
                id: $scope.gridIdentifier,
                checkLocation: false
            });
        }
    }

    if ($scope.userData.parentCount > 1) {
        if ($scope.userData.parents[0].basicType === 'dep') {
            $ngRedux.dispatch({
                type: 'CHECK_LOCATION',
                id: $scope.gridIdentifier,
                checkLocation: false
            });
        }
    }

    $scope.locations = [];
    $scope.enableEquipmentRegistration = true;

    $scope.minDocumentExpiryDate = new Date();
    $scope.maxServiceDocumentDate = new Date();
    $scope.maxEquipmentInstalationDate = new Date();
    $scope.maxRegistrationDate = new Date();
    $scope.minNextDueDate = new Date();

    // TODO create factory with reset method
    $scope.doc = {
        type: null,
        inspectionDate: null,
        inspector: null
    };

    $scope.onEquipmentGridReset = function() {
        $rootScope.$broadcast('SELECT_CASCADE_RESET', 'equipmentFilterForm');
    };

    $scope.onEquipmentSell = function(event) {
        EquipmentManager.onSell($scope.gridIdentifier, event.item, EQUIPMENT_TYPE_CODE.laser);
    };

    $scope.onEquipmentDispose = function(event) {
        EquipmentManager.onDispose($scope.gridIdentifier, event.item, EQUIPMENT_TYPE_CODE.laser);
    };

    $scope.onEquipmentReplace = function(event) {
        $state.go('app.lasers.add', {
            replace: event.item.equipment_id,
            transaction: event.item.transaction_id
        });
    };

    EquipmentService.fetchEquipmentTypeProperties(EQUIPMENT_TYPE_CODE.laser).then(function(properties) {
        $scope.showPermitApplicationId = false;
        $scope.showPermitRequired = false;
        for (const i in properties) {
            if (properties[i].prop_code === 'permit_application_id') {
                $scope.showPermitApplicationId = true;
            }
            if (properties[i].prop_code === 'permit_required') {
                $scope.showPermitRequired = true;
            }
        }
    });

    $scope.tabs = _.cloneDeep(EquipmentManager.tabs);
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]).visible = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]).content = $scope.templateDetails;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DOCUMENTS]).visible = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DOCUMENTS]).disabled = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_VENDORS]).visible = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_VENDORS]).disabled = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_TECHNIQUE_CHARTS]).visible = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_TECHNIQUE_CHARTS]).disabled = true;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_ASSET_COST]).visible = false;
    _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_ASSET_COST]).disabled = false;

    $scope.$watch('selectedTabIndex', function(newTab) {
        if (newTab === EquipmentManager.TAB_EQUIPMENT_LISTING) {
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]).visible = false;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_MODIFY]).visible = false;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DOCUMENTS]).visible = false;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_VENDORS]).visible = false;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_ASSET_COST]).visible = false;
            $rootScope.$broadcast('SELECT_CASCADE_RESET', 'equipmentForm');
            $scope.locations = [];
        }
    });

    $scope.$on('LOCATION_SUBJECT_SELECTED', function(event, locationItem, formName) {
        if (locationItem.in_subject_basic_type_code === 'hof') {
            $scope.headOfficeName = locationItem.in_username_par;
        }

        if (formName == 'equipmentFilterForm' || formName == 'sellForm') {
            $scope.location = locationItem.uuid;
        } else {
            $scope.equipment.location.uuid = locationItem.uuid;
            $scope.equipment.account_id = locationItem.in_account_id;
            if (locationItem.in_subject_basic_type_code == 'slo') {
                $scope.equipment.account.permitApplication = locationItem.in_permit;
            }
            if (locationItem.in_subject_basic_type_code == 'hof') {
                $scope.equipment.account.permitApplication = null;
            }

            if (locationItem.in_subject_basic_type_code === 'slo') {
                EquipmentService.fetchEquipmentTrainings(EQUIPMENT_TYPE_CODE.laser, locationItem.uuid).then(function (items) {
                    $scope.trainings = items;
                });
            }

            EquipmentService.fetchEquipmentTypeProperties(EQUIPMENT_TYPE_CODE.laser, locationItem.uuid).then(function(properties) {
                $scope.showPermitApplicationId = false;
                $scope.showPermitRequired = false;
                for (const i in properties) {
                    if (properties[i].prop_code === 'permit_application_id') {
                        $scope.showPermitApplicationId = true;
                    }
                    if (properties[i].prop_code === 'permit_required') {
                        $scope.showPermitRequired = true;
                    }
                }
            });
        }
    });

    $scope.selectItem = function(event) {
        var item = event.item;

        EquipmentService.fetchEquipmentTrainings(null, null, item.equipment_id).then(function(items) {
            $scope.trainings = items;
        });

        EquipmentServiceLaser.getEquipmentDetails(item.equipment_id).then(function(result) {
            $scope.equipment = result.equipment;
            $scope.locations = result.locations;
            $scope.manufacturerSelected(false);
            $scope.doc.expires = null;
            $scope.doc.type = null;
            $scope.doc.inspectionDate = null;

            $ngRedux.dispatch({
                type: 'EQUIPMENT_SELECTED',
                id: 'equipmentDocumentsGrid',
                equipmentId: item.equipment_id
            });

            DocumentService.fetchEquipmentDocumentTypes(item.equipment_id).then(function(items) {
                $scope.documentTypes = items;
            });

            $q.all([
                EquipmentManager.getEquipmentServices($scope.equipment.equipment_id),
                VendorService.getEquipmentVendors($scope.equipment.equipment_id)
            ]).then(function(result) {
                $scope.equipment.services = _.first(result);
                _.each(result[1], function(item) {
                    var serviceType = _.find($scope.equipment.services, ['id', item.service_type_id]);
                    if (_.isUndefined(serviceType))
                        throw new Error('Undefined ServiceType ' + item.service_type_name);
                    else {
                        var vendor = new VendorHeadOffice();
                        vendor.uuid = item.vendor_uuid;
                        vendor.name = item.vendor_name;
                        serviceType.selectedVendor = vendor;
                        serviceType.selected = 1;
                        serviceType.disable = item.is_mutable;
                        var vendorFilter = new VendorGridFilter();
                        vendorFilter.offset = null;
                        vendorFilter.limit = null;
                        vendorFilter.serviceType = serviceType.id;
                        VendorService.getVendorHeadOfficeList(vendorFilter).then(function(data) {
                            serviceType.vendors = data.items;
                        });
                    }
                });
            });

            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]).title = $scope.equipment.user_friendly_id;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DETAILS]).visible = true;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_MODIFY]).visible = true;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_DOCUMENTS]).visible = true;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_VENDORS]).visible = true;
            _.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_ASSET_COST]).visible = true;

            $timeout(() => {
                generateQRCode('info', {
                    id: $scope.equipment.user_friendly_id,
                    asset_id: $scope.equipment.assetID ? $scope.equipment.assetID : '',
                    action: 'equipment',
                });

                generateQRCode('qrcode', {
                    id: $scope.equipment.user_friendly_id,
                    asset_id: $scope.equipment.assetID ? $scope.equipment.assetID : '',
                    action: 'equipment',
                });

                let params;
                if ($uiRouterGlobals.params.id) {
                    params = $uiRouterGlobals.params.id.split(',');
                }
                $uiRouterGlobals.params.id = null;
                window.history.replaceState(null, null, window.location.pathname);
                if (params && params[1]) {
                    $scope.selectedTabIndex = parseInt(params[1]);
                } else {
                    $scope.selectedTabIndex = EquipmentManager.TAB_EQUIPMENT_DETAILS;
                }
            }, 200);
        });
    };

    $scope.showDocuments = function() {
        DocumentManager.showEquipmentDocuments($scope.equipment);
    };

    $scope.initUploadForm = function(form) {
        $scope.uploadForm = form;
    };

    $scope.selectDocumentTypeChange = function() {
        $scope.doc.inspector = null;
        $scope.notApplicable = false;
        $scope.showExpiryDate = true;
        EquipmentServiceLaser.getInspectors($scope.equipment.equipment_id, $scope.doc.type.id).then(function (result) {
            $scope.inspectors = result;
        });
    };

    $scope.setExpiryDate = function() {
        if ($scope.doc.inspectionDate && !$scope.doc.expires) {
            var startDate = new Date($scope.doc.inspectionDate);
            if ($scope.headOfficeName === 'OTTAWA HOSPITAL' && $scope.doc.type.code === 'ctp') {
                $scope.doc.expires = new Date(startDate.setMonth(startDate.getMonth() + 6));
            } else {
                EquipmentService.getLicenceFrequency($scope.equipment.equipment_id, $scope.doc.inspectionDate, $scope.doc.type.id).then(function (result) {
                    if (result.expiration_date) {
                        $scope.doc.expires = new Date(result.expiration_date);
                    } else {
                        $scope.doc.expires = new Date(startDate.setFullYear(startDate.getFullYear() + 1));
                    }
                });
            }
        }
    };

    $scope.$on('FILE_UPLOAD_SUCCESS', function(event, filePath, uploader) {
        var doc = {
            id: $scope.equipment.equipment_id,
            type: $scope.doc.type.id,
            issued: "",
            expires: $scope.doc.expires,
            uri: filePath
        };

        if ($scope.doc.type.code == 'ctp') {
            doc.inspected = $scope.doc.inspectionDate;
            doc.inspector = $scope.doc.inspector;
            doc.result = $scope.doc.result;
            doc.comment = $scope.doc.comment;
            doc.number = $scope.doc.number;
        }

        if ($scope.doc.type.code == 'ser') {
            doc.inspected = $scope.doc.inspectionDate;
            doc.inspector = $scope.doc.inspector;
        }

        if ($scope.doc.type.code == 'cal' || $scope.doc.type.code == 'rsr') {
            doc.serviceDate = $scope.doc.inspectionDate;
        }

        DocumentManager.saveDocument(doc).then(function() {
            $scope.uploadForm.$setPristine();
            $scope.uploadForm.$setUntouched();
            DocumentManager.clearUploader(uploader, $scope.doc);
            $ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: 'equipmentDocumentsGrid',
                equipmentId: $scope.equipment.equipment_id
            });
            $ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: $scope.gridIdentifier
            });
        }, function(error) {
            DocumentManager.clearUploader(uploader, $scope.doc);
            Notifier.responseMessage(error);
        });
    });

    $scope.generateBarcode = function() {
        EquipmentManager.generateBarcode($scope.equipment.barcode);
    };

    $scope.manageEquipmentService = function(serviceType) {
        EquipmentManager.manageEquipmentService($scope.equipment.equipment_id, serviceType);
    };

    $scope.onEquipmentServiceVendor = function(serviceType) {
        EquipmentManager.equipmentServiceVendorSelected($scope.equipment.equipment_id, serviceType);
    };

    if ($uiRouterGlobals.params.id) {
        const params = $uiRouterGlobals.params.id.split(',');
        $scope.selectItem({
            item: {equipment_id: params[0]}
        });
    }

    EquipmentServiceLaser.fetchCategories().then(function(items) {
        $scope.categories = items;
    });

    ManufacturerService.fetchManufacturersByEquipmentType(EQUIPMENT_TYPE_CODE.laser).then(function(items) {
        $scope.manufacturers = items;
    });

    EquipmentServiceLaser.fetchClasses().then(function(items) {
        $scope.classes = items;
    });

    EquipmentServiceLaser.fetchOperationMode().then(function(items) {
        $scope.modes = items;
    });

    EquipmentServiceLaser.fetchLaserTypes('lty').then(function(items) {
        $scope.laserTypes = items;
    });

    $scope.units = [
        {id:1, code:'mj', name: 'mJ'},
        {id:2, code:'jls', name: 'Joules'},
        {id:3, code:'mw', name: 'mW'},
        {id:4, code:'wts', name: 'Watts'}
    ];

    $scope.hazardEvaluations = [
        {id:1, code:'tmc', name: 'Through Microscope'},
        {id:2, code:'tma', name: 'Through Microscope / Aperture'},
        {id:3, code:'tgp', name: 'Through G Probe'},
        {id:4, code:'aos', name: 'Applied on Skin'},
        {id:5, code:'fbr', name: 'Fiber'},
        {id:6, code:'nwr', name: 'NHZ Whole Room'},
        {id:7, code:'naa', name: 'NHZ around Aperture'},
        {id:8, code:'nhc', name: 'NHZ Confined'},
        {id:9, code:'no', name: 'No'},
        {id:10, code:'yes', name: 'Yes'}
    ];

    $scope.manufacturerSelected = function(resetSelectedModel) {
        if (_.isUndefined(resetSelectedModel)){
            $scope.equipment.model = null;
        }
        ManufacturerService.fetchEquipmentModels($scope.equipment.manufacturer.id, $scope.equipment.category.id).then(function(items) {
            $scope.models = items;
        });
    };

    $scope.createEquipmentModel = function(equipment) {
        EquipmentManager.createNewModel(equipment);
    };

    $scope.createLaserName = function(equipment) {
        EquipmentManager.createNewLaserName(equipment);
    };

    $scope.$on(EquipmentManager.CREATE_NEW_MODEL_COMPLETE, function(event, data) {
        $scope.models.push(data);
        $scope.equipment.model = data;
    });

    $scope.$on(EquipmentManager.CREATE_NEW_CATEGORY_COMPLETE, function(event, data) {
        $scope.categories.push(data);
        $scope.equipment.category = data;
    });

    _.extend(_.find($scope.tabs, ['id', EquipmentManager.TAB_EQUIPMENT_MODIFY]), {
        exec: function() {
            var deferred = $q.defer();
            $scope.equipment.showPermitRequired = $scope.showPermitRequired;
            EquipmentServiceLaser.updateEquipment($scope.equipment).then(function() {
                generateQRCode('qrcode', {
                    id: $scope.equipment.user_friendly_id,
                    asset_id: $scope.equipment.assetID ? $scope.equipment.assetID : '',
                    action: 'equipment',
                });
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: $scope.gridIdentifier
                });
                Notifier.success('Equipment details successfully updated');
                deferred.resolve();
            }, function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }
    });

    $scope.manageEquipmentRegistration = function() {
        $scope.enableEquipmentRegistration = !$scope.enableEquipmentRegistration;
        if (!$scope.enableEquipmentRegistration) {
            $scope.equipment.registrationData.number = null;
            $scope.equipment.registrationData.date = null;
            $scope.equipment.registrationData.dueDate = null;
            $scope.equipment.registrationData.details = null;
        }
    };

    $scope.onEquipmentDocumentInvalidate = function(event) {
        var confirm = $mdDialog.confirm()
            .title($filter('translate')('EQUIPMENT.TABS.DOCUMENTS.REMOVE_MESSAGE'))
            .textContent($filter('translate')('EQUIPMENT.TABS.DOCUMENTS.REMOVE_DIALOG_CONTENT'))
            .ariaLabel($filter('translate')('EQUIPMENT.TABS.DOCUMENTS.REMOVE_LABEL'))
            .ok($filter('translate')('EQUIPMENT.TABS.DOCUMENTS.REMOVE_OK'))
            .cancel($filter('translate')('EQUIPMENT.TABS.DOCUMENTS.REMOVE_CANCEL'));

        $mdDialog.show(confirm).then(function() {
            EquipmentService.invalidateDocument(event.item.id).then(function() {
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'equipmentDocumentsGrid'
                });
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: $scope.gridIdentifier
                });
                Notifier.success($filter('translate')('EQUIPMENT.TABS.DOCUMENTS.NOTIFIER_REMOVE_SUCCESS'));
            }, function(error) {
                Notifier.responseMessage(error);
            });
        }, angular.noop);
    };

    $scope.onPrintQRcode = () => {
        EquipmentService.printQRcode(
            $scope.equipment.equipment_id,
            $scope.equipment.user_friendly_id,
            'equipment',
        );
    };

    $scope.onUpdateQRcode = function() {
        EquipmentService.generateQRcode($scope.equipment.user_friendly_id, $scope.equipment.assetID).then(data => {
            $scope.qrcodeImg = data;
            $scope.qrCodedata = data;
        });
    };

    $scope.onNotApplicableTraining = () => {
        $scope.equipment.vendorTraining = false;
        $scope.equipment.internalTraining = false;
    };

    $scope.onTraining = () => {
        $scope.equipment.notApplicableTraining = false;
    };

    $scope.onChangeDocumentApplicable = function (notApplicable) {
        $scope.showExpiryDate = !notApplicable;
    }

    /**
     * ASSET COST
     */
    $scope.icon = 'lasers.svg';
    $scope.color = 'colorLasers';
    $scope.bgColor = 'lasers';
    $scope.btnColor = 'btnColorLasers';
    $scope.depreciation_type = this.equAssetCost.depreciation_type;

    $scope.initAssetCostForm = (form) => {
        $scope.equAssetCostForm = form;
    };

    $scope.setWarrantyExpiryDate = () => {
        const { purchaseDate, warranty_period } = this.equAssetCost;
        if (
            moment.isDate(purchaseDate) &&
            isNumber(warranty_period) &&
            !isNaN(warranty_period)
        ) {
            $ngRedux.dispatch({
                type: 'EQUIPMENT_WARRANTY_EXPIRY_DATE',
                id: 'equAssetCost',
                date: moment(purchaseDate).add(warranty_period, 'months').toDate(),
            });
        }
    };

    $scope.onDepreciationType = () => {
        $scope.depreciation_type = this.equAssetCost.depreciation_type;
    };

    $scope.genDepreciationReport = () => {
        if ($scope.equAssetCostForm.$valid) {
            if (this.equAssetCost.depreciation_type) {
                Notifier.info('Please wait...', false);
                EquipmentService.generateDepreciationReport(
                    'las',
                    this.equAssetCost.equ_id,
                    this.equAssetCost.visualId,
                )
                .then(() => Notifier.success('Success'))
                .catch(() => Notifier.error('System Error!'));
            } else {
                Notifier.error('Please save asset cost');
            }
        } else {
            Notifier.error('Please fill in the form fields');
        }
    };

    $scope.saveAssetCost = () => {
        if ($scope.equAssetCostForm.$valid) {
            if (this.equAssetCost.equ_id) {
                if (this.equAssetCost.estimated_residual_value < this.equAssetCost.assetCost) {
                    EquipmentServiceLaser.updateEquipment($scope.equipment).then(() => {
                        Notifier.success('Asset cost has been successfully saved');
                    });
                } else {
                    Notifier.error('The estimated residual value cannot be higher than the cost of the asset');
                }
            }
        } else {
            Notifier.error('Please fill in the form fields');
        }
    };

    $scope.$on('$destroy', () => {
        $ngRedux.dispatch({
            type: 'EQUIPMENT_RESET',
            id: 'equAssetCost'
        });
        this.unsubscribe();
    });
}
