import Chart from 'chart.js';

/* @ngInject */
export function mammoSignalAndContractRatioMeasurementController($controller, $scope, $rootScope, $q, $timeout, Notifier, MammoQCService, MammoSNRCNRManager, MammoQCMeasurement, MammoQCPlotDiagramFilter, Helpers, AuthService, SUBJECT_TYPE, UserService, NavigationService) {

    angular.extend(this, $controller('ApplicationModuleController', {
        $scope: $scope,
        $timeout: $timeout,
        Notifier: Notifier
    }));

    $scope.tplSignalAndContractRatioMeasurementFilter = './signal-and-contract-ratio-measurement-filter.html';

    $scope.QCTest = new MammoQCMeasurement();
    $scope.PlotDiagram = new MammoQCPlotDiagramFilter();

    $scope.MODULE_STATE = $scope.MODULE_STATE_CREATE;
    $scope.DICOMQC_TEST_STATE = $scope.MODULE_STATE_CREATE;

    $scope.tabs = _.cloneDeep(MammoSNRCNRManager.tabs);

    var navConfig = NavigationService.getNavConfig();

    UserService.getUserAllowedActions(navConfig.id).then(function (result) {

        if (!result.includes('action_add_plot_values')) {
            $scope.tabs[MammoSNRCNRManager.TAB_MAMMO_SNR_CNR_MEASUREMENT].visible = false;
        }
    });

    $scope.equipmentList = [];

    $rootScope.$on('form-completed', function (event, data) {
        $scope.savedForm = data;
        var locationItem = data.locationItem;
        $rootScope.$broadcast('LOCATION_SUBJECT_SELECTED', locationItem);
    })

    var user = AuthService.getUser();

    //Load data for parent site location if user belongs to only one site location
    if (user && (user.parentCount === 1) && (user.parents[user.parents.length - 1])
        && (user.parents[user.parents.length - 1].basicType == SUBJECT_TYPE.SITE_LOCATION)) {

        MammoQCService.getMammoQCEquipment(user.parents[user.parents.length - 1].uuid).then(function (data) {
            $scope.equipmentList = data;
        });
    }

    $scope.$on('LOCATION_SUBJECT_SELECTED', function (event, locationItem) {
        $scope.QCTest.locationId = locationItem.uuid;

        MammoQCService.getMammoQCEquipment($scope.QCTest.locationId).then(function (data) {
            $scope.equipmentList = data;
        });
    });

    $scope.maxTestDate = new Date();

    _.extend(_.find($scope.tabs, ['id', MammoSNRCNRManager.TAB_MAMMO_SNR_CNR_MEASUREMENT]), {

        exec: function () {
            var deferred = $q.defer();

            $scope.QCTest.measurementType = 'sncnrm';

            MammoQCService.addMammoQCMeasurement($scope.QCTest).then(function(id) {

                $scope.PlotDiagram.visible = true;

                Notifier.success('Measurement was successfully added');
                deferred.resolve(MammoSNRCNRManager.TAB_MAMMO_SNR_CNR_PLOT_DIAGRAM);
                $scope.setSearchDate(null, 1);
                $scope.PlotDiagram.mammoQCEquipment.equId = $scope.QCTest.mammoQCEquipment.equId;
                $scope.PlotDiagram.mammoQCEquipment = $scope.QCTest.mammoQCEquipment;
                getPlotDiagram();
            }, function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', MammoSNRCNRManager.TAB_MAMMO_SNR_CNR_PLOT_DIAGRAM]), {

        exec: function () {

            $scope.PlotDiagram.visible = true;

            var deferred = $q.defer();

            getPlotDiagram();

            return deferred.promise;
        }
    });

    _.extend(_.find($scope.tabs, ['id', MammoSNRCNRManager.TAB_MAMMO_SNR_CNR_GRID]), {

        exec: function () {

            var deferred = $q.defer();

            return deferred.promise;
        }
    });

    function getPlotDiagram() {

        MammoQCService.getMammoQCSNCNTests($scope.PlotDiagram, 'sncnrm').then(function(data) {

            var deferred = $q.defer();

            var arrSignalToNoiseRatioResultCalculation = [];
            var arrContrastToNoiseRatioResultCalculation = [];
            var arrContrastToNoiseRatioResultDifference = [];
            var arrTestDate = [];
            var arrSNRLimit = [];
            var arrCNRTopLimit = [];
            var arrCNRBottomLimit = [];
            if (data.items) {
                for (var i = 0; i < data.items.length; i++) {
                    arrSignalToNoiseRatioResultCalculation.push(data.items[i].signalToNoiseRatioResultCalculation);
                    arrContrastToNoiseRatioResultDifference.push(data.items[i].contrastToNoiseRatioResultDifference);
                    arrContrastToNoiseRatioResultCalculation.push(data.items[i].contrastToNoiseRatioResultCalculation);
                    arrTestDate.push('\n' + data.items[i].testDate + ' \n(' + data.items[i].firstName.charAt(0) + '.' + data.items[i].lastName.charAt(0) + '.)\n'+'kVp: '+data.items[i].kvp+'\nmAs: '+data.items[i].mas);
                    arrSNRLimit.push(40);
                    arrCNRTopLimit.push(15);
                    arrCNRBottomLimit.push(-15);
                }
            }

            Chart.pluginService.register({
                beforeDraw: function (chart) {
                    if (chart.config.options.chartArea && chart.config.options.chartArea.backgroundColor) {
                        var ctx = chart.chart.ctx;
                        var chartArea = chart.chartArea;

                        ctx.save();
                        ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
                        ctx.fillRect(-10, -10, chartArea.right + 80, chartArea.bottom + 120);
                        ctx.restore();
                    }
                }
            });

            Chart.pluginService.register({
                beforeInit: function (chart) {
                    var hasWrappedTicks = chart.config.data.labels.some(function (label) {
                        return label.indexOf('\n') !== -1;
                    });

                    if (hasWrappedTicks) {
                        // figure out how many lines we need - use fontsize as the height of one line
                        var tickFontSize = Chart.helpers.getValueOrDefault(chart.options.scales.xAxes[0].ticks.fontSize, Chart.defaults.global.defaultFontSize);
                        var maxLines = chart.config.data.labels.reduce(function (maxLines, label) {
                            return Math.max(maxLines, label.split('\n').length);
                        }, 0);
                        var height = (tickFontSize + 2) * maxLines + (chart.options.scales.xAxes[0].ticks.padding || 0);

                        // insert a dummy box at the bottom - to reserve space for the labels
                        Chart.layoutService.addBox(chart, {
                            draw: Chart.helpers.noop,
                            isHorizontal: function () {
                                return true;
                            },
                            update: function () {
                                return {
                                    height: this.height
                                };
                            },
                            height: height,
                            options: {
                                position: 'bottom',
                                fullWidth: 1,
                            }
                        });

                        // turn off x axis ticks since we are managing it ourselves
                        chart.options = Chart.helpers.configMerge(chart.options, {
                            scales: {
                                xAxes: [{
                                    ticks: {
                                        display: false,
                                    }
                                }]
                            }
                        });

                        chart.hasWrappedTicks = {
                            tickFontSize: tickFontSize
                        };
                    }
                },
                afterDraw: function (chart) {
                    if (chart.hasWrappedTicks) {
                        // draw the labels and we are done!
                        chart.chart.ctx.save();
                        var tickFontSize = chart.hasWrappedTicks.tickFontSize;
                        var tickFontStyle = Chart.helpers.getValueOrDefault(chart.options.scales.xAxes[0].ticks.fontStyle, Chart.defaults.global.defaultFontStyle);
                        var tickFontFamily = Chart.helpers.getValueOrDefault(chart.options.scales.xAxes[0].ticks.fontFamily, Chart.defaults.global.defaultFontFamily);
                        var tickLabelFont = Chart.helpers.fontString(tickFontSize, tickFontStyle, tickFontFamily);
                        chart.chart.ctx.font = tickLabelFont;
                        chart.chart.ctx.textAlign = 'center';
                        var tickFontColor = Chart.helpers.getValueOrDefault(chart.options.scales.xAxes[0].fontColor, Chart.defaults.global.defaultFontColor);
                        chart.chart.ctx.fillStyle = tickFontColor;

                        var meta = chart.getDatasetMeta(0);
                        var xScale = chart.scales[meta.xAxisID];
                        var yScale = chart.scales[meta.yAxisID];

                        chart.config.data.labels.forEach(function (label, i) {
                            label.split('\n').forEach(function (line, j) {
                                chart.chart.ctx.fillText(line, xScale.getPixelForTick(i), (chart.options.scales.xAxes[0].ticks.padding || 0) + yScale.getPixelForValue(yScale.min) +
                                    // move j lines down
                                    j * (chart.hasWrappedTicks.tickFontSize));
                            });
                        });

                        chart.chart.ctx.restore();
                    }
                }
            });

            var config_snr = {
                type: 'line',
                data: {
                    labels: arrTestDate,
                    datasets: [{
                        label: "Signal-To-Noise Ratio (SNR)",
                        backgroundColor: '#0000ff',
                        borderColor: '#0000ff',
                        fill: false,
                        data: arrSignalToNoiseRatioResultCalculation,
                        pointRadius: 10,
                        pointHoverRadius: 15,
                        showLine: false
                    }, {
                        label: 'Minimum Limit',
                        backgroundColor: 'red',
                        borderColor: 'red',
                        fill: false,
                        data: arrSNRLimit,
                        type: 'line'
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: 'Signal-To-Noise Ratio (SNR) for ' + $scope.PlotDiagram.mammoQCEquipment.serial + ' | ' + $scope.PlotDiagram.mammoQCEquipment.manufacturer + ' | ' + $scope.PlotDiagram.mammoQCEquipment.typeName + ' | ' + $scope.PlotDiagram.mammoQCEquipment.modelName
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                        }],
                        yAxes: [{
                            ticks: {
                                min: 30,
                                max: 70,
                                stepSize: 5
                            }
                        }]
                    },
                    chartArea: {backgroundColor: 'rgb(250,250,250)'},
                    animation: {onComplete: exportChart},
                    elements: {
                        point: {
                            pointStyle: 'circle'
                        }
                    },
                    layout: {
                        padding: {
                            bottom: 50,
                            left: 10,
                            right: 50
                        }
                    }
                }
            };

            var config_cnr = {
                type: 'line',
                data: {
                    labels: arrTestDate,
                    datasets: [{
                        label: "Contrast-To-Noise Ratio (CNR)",
                        backgroundColor: '#00ff00',
                        borderColor: '#00ff00',
                        fill: false,
                        data: arrContrastToNoiseRatioResultCalculation,
                        pointRadius: 10,
                        pointHoverRadius: 15,
                        showLine: false
                    }, {
                        label: 'Upper Limit',
                        backgroundColor: 'red',
                        borderColor: 'red',
                        fill: false,
                        data: arrCNRTopLimit,
                        type: 'line'
                    }, {
                        label: 'Lower Limit',
                        backgroundColor: 'red',
                        borderColor: 'red',
                        fill: false,
                        data: arrCNRBottomLimit,
                        type: 'line'
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: 'Contrast-To-Noise Ratio (CNR) for ' + $scope.PlotDiagram.mammoQCEquipment.serial + ' | ' + $scope.PlotDiagram.mammoQCEquipment.manufacturer + ' | ' + $scope.PlotDiagram.mammoQCEquipment.typeName + ' | ' + $scope.PlotDiagram.mammoQCEquipment.modelName
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                        }],
                        yAxes: [{
                            ticks: {
                                min: -30,
                                max: 30,
                                stepSize: 5
                            }
                        }]
                    },
                    chartArea: {backgroundColor: 'rgb(250,250,250)'},
                    animation: {onComplete: exportChart},
                    elements: {
                        point: {
                            pointStyle: 'circle'
                        }
                    },
                    layout: {
                        padding: {
                            bottom: 50,
                            left: 10,
                            right: 50
                        }
                    }
                }
            };

            if (data['items']) {
                if (data['items'].length) {
                    var ctx = document.getElementById("myChartSNR").getContext('2d');
                    $scope.myChart = new Chart(ctx, config_snr);

                    var ctx = document.getElementById("myChartCNR").getContext('2d');
                    $scope.myChart = new Chart(ctx, config_cnr);

                    $scope.noResult = false;
                } else {
                    $scope.PlotDiagram.visible = false;
                    $scope.noResult = true;
                }
            }

            deferred.resolve();
        }, function (error) {
            Notifier.responseMessage(error);
        });
    }

    function exportChart() {

        var currentDate = new Date()
        var day = currentDate.getDate()
        var month = currentDate.getMonth() + 1
        var year = currentDate.getFullYear()

        currentDate = day+'-'+month+'-'+year;

        if (document.getElementById("myChartSNR")) {
            var canvas_snr = document.getElementById("myChartSNR");
            var img = canvas_snr.toDataURL("image/png");
            export_snr_link.href = img;

            $scope.chartSNRName = 'SNR-' + currentDate + '-' + $scope.PlotDiagram.mammoQCEquipment.serial + '-' + $scope.PlotDiagram.mammoQCEquipment.manufacturer + '-' + $scope.PlotDiagram.mammoQCEquipment.typeName + '-' + $scope.PlotDiagram.mammoQCEquipment.modelName + '.png';
        }

        if (document.getElementById("myChartCNR")) {
            var canvas_cnr = document.getElementById("myChartCNR");
            var img = canvas_cnr.toDataURL("image/png");
            export_cnr_link.href = img;

            $scope.chartCNRName = 'CNR-' + currentDate + '-' + $scope.PlotDiagram.mammoQCEquipment.serial + '-' + $scope.PlotDiagram.mammoQCEquipment.manufacturer + '-' + $scope.PlotDiagram.mammoQCEquipment.typeName + '-' + $scope.PlotDiagram.mammoQCEquipment.modelName + '.png';
        }
    }

    $scope.resetFilter = function () {
        $scope.PlotDiagram.reset();
        $rootScope.$broadcast('SELECT_CASCADE_RESET', 'signalAndContractRatioMeasurementPlotDiagramForm');

        $scope.selectedTab.form.$setUntouched();
        $scope.selectedTab.form.$setPristine();
        $scope.selectedTab.form.$setValidity();
    };

    $scope.setSearchDate = function(numberOfDays, numberOfMonths) {

        $scope.PlotDiagram.dateTo = new Date();
        $scope.PlotDiagram.dateFrom = new Date();
        if (numberOfDays != null) {
            $scope.PlotDiagram.dateFrom = new Date($scope.PlotDiagram.dateTo.getTime() - numberOfDays * 24 * 60 * 60 * 1000);
        } else {
            $scope.PlotDiagram.dateFrom.setMonth($scope.PlotDiagram.dateFrom.getMonth() - numberOfMonths);
        }
    }

    $scope.calculateMeasurement = function() {
        if( $scope.QCTest.meanInsideDisk && $scope.QCTest.meanBesideDisk && $scope.QCTest.standardDeviationBesideDisk && $scope.QCTest.mammoQCEquipment ) {
            MammoQCService.getMammoQCCNBaselineValue($scope.QCTest.mammoQCEquipment.equId).then(function(baseline_value) {
                var baselineValue = baseline_value;
                var SNResult = ($scope.QCTest.meanBesideDisk - 50) / $scope.QCTest.standardDeviationBesideDisk;
                var formattedSNR = SNResult.toFixed(2).replace(/[.,]00$/, "");

                $scope.QCTest.signalToNoiseRatioResultCalculation = formattedSNR;

                if ($scope.QCTest.isBaseline == true || _.isUndefined(baselineValue) || baselineValue == null) {
                    var baseCNR = ($scope.QCTest.meanBesideDisk - $scope.QCTest.meanInsideDisk) / $scope.QCTest.standardDeviationBesideDisk;
                    var formattedBaseCNR = baseCNR.toFixed(2).replace(/[.,]00$/, "");
                    $scope.QCTest.contrastToNoiseRatioResultCalculation = formattedBaseCNR;
                    $scope.QCTest.baselineValue = formattedBaseCNR;
                    $scope.QCTest.contrastToNoiseRatioResultDifference = 0;
                } else {
                    var CNResult = ($scope.QCTest.meanBesideDisk - $scope.QCTest.meanInsideDisk) / $scope.QCTest.standardDeviationBesideDisk;
                    var formatedCNR = CNResult.toFixed(2).replace(/[.,]00$/, "");
                    var cnrDifference = (formatedCNR - baselineValue) / baselineValue * 100;
                    var formatedCNRDifference = cnrDifference.toFixed(2).replace(/[.,]00$/, "");
                    $scope.QCTest.contrastToNoiseRatioResultCalculation = formatedCNR;
                    $scope.QCTest.contrastToNoiseRatioResultDifference = formatedCNRDifference;
                }

                if ($scope.QCTest.signalToNoiseRatioResultCalculation < 40 || $scope.QCTest.contrastToNoiseRatioResultCalculation < -15 || $scope.QCTest.contrastToNoiseRatioResultCalculation > 15) {
                    $scope.showRemark = true;
                } else {
                    $scope.showRemark = false;
                }
            });
        } else if ($scope.QCTest.contrastToNoiseRatioResultCalculation && $scope.QCTest.mammoQCEquipment) {
            MammoQCService.getMammoQCCNBaselineValue($scope.QCTest.mammoQCEquipment.equId).then(function(baseline_value) {
                var baselineValue = baseline_value;

                if ($scope.QCTest.isBaseline == true || _.isUndefined(baselineValue) || baselineValue == null) {
                    $scope.QCTest.baselineValue = $scope.QCTest.contrastToNoiseRatioResultCalculation;
                }
            });

            if ($scope.QCTest.contrastToNoiseRatioResultCalculation && $scope.QCTest.signalToNoiseRatioResultCalculation ) {
                if ($scope.QCTest.signalToNoiseRatioResultCalculation < 40 || $scope.QCTest.contrastToNoiseRatioResultCalculation < -15 || $scope.QCTest.contrastToNoiseRatioResultCalculation > 15) {
                    $scope.showRemark = true;
                } else {
                    $scope.showRemark = false;
                }
            }
        }
    }

    $scope.onSignalAndContractRatioGridReset = function() {
        $rootScope.$broadcast('SELECT_CASCADE_RESET', 'singalAndContractRatioFilterForm');
    };
}
