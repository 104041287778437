import { API_BASE } from '../../../../../common/utils/util.constants';
import watch from 'redux-watch';

export const manufacturerLogo = {
    bindings: {},
    /* @ngInject */
    controller: function($ngRedux) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.unsubscribe = $ngRedux.connect(({ manufacturers }) => ({
                name: manufacturers.manufacturer.name,
                logo: manufacturers.manufacturer.logo,
            }), {})(this);

            const watchLogo = watch($ngRedux.getState, 'manufacturers.manufacturer.logo');
            this.onLogoChange = $ngRedux.subscribe(watchLogo((logo) => {
                this.img = `${API_BASE}/img/${localStorage.myrsoAuthToken}${logo}`;
            }));

            if (this.logo) {
                this.img = `${API_BASE}/img/${localStorage.myrsoAuthToken}${this.logo}`;
            }
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            this.unsubscribe();
            this.onLogoChange();
        };
    },
    template: `
        <img ng-if="$ctrl.logo" src="{{$ctrl.img}}" alt="{{$ctrl.name}}">
    `,
};
