import { gridTpl } from '../../../../../../common/grid/grid.controller';

export const administrationAccountsSiteLocationsGrid = {
    bindings: {
        title: '<',
        onSelect: '&',
        onRemove: '&',
        admin: '<'
    },
    controller: 'AdministrationAccountsSiteLocationsGridController',
    ...gridTpl
};
