import * as TYPE from '../cre-vendors.actions.types';
import { getContactPositions, getVendors, sendInvite } from '../cre-vendors.actions';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import findIndex from 'lodash/findIndex';

/**
 * @const {object}
 * @memberOf module:Credentialing
 */
export const creVendorsInvitation = {
    bindings: {
        type: '@',
    },
    /* @ngInject */
    controller: function ($ngRedux, $state, $mdDialog, Notifier) {
        /**
         * @function $onInit
         */
        this.$onInit = () => {
            this.title = 'Vendor Applicant Invitation';
            this.unsubscribe = $ngRedux.connect(({ credentialing }) => ({
                creInvite: credentialing.creInvite,
            }), {
                getContactPositions,
                getVendors,
                sendInvite,
            })(this);
            this.identifier = $state.$current.parent.name;
            this.state = $state.$current.name;

            this.getContactPositions({ contact_code: 'vhm' });
            this.getVendors();
        }

        this.onSelectVendor = () => {
            this.dialog = $mdDialog.show({
                /* @ngInject */
                controller: ($scope) => {
                    $scope.getVendors = async (evt) => await this.getVendors(evt);
                    $scope.save = () => {
                        if ($scope.dialogForm.$valid) {
                            $ngRedux.dispatch({
                                type: TYPE.CRE_VENDOR_SELECT,
                                id: 'creInvite',
                                uuid: this.creInvite.vendor.vendor_uuid,
                                name: this.creInvite.vendor.vendor_name,
                            });
                            $mdDialog.hide();
                        }
                    };
                    $scope.cancel = () => $mdDialog.cancel();
                },
                template: require('./dialogs/cre-vendors-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                onShowing: () => {
                    this.getVendors();
                }
            }).then(vendorReset, vendorReset);
        };

        const vendorReset = () => {
            $ngRedux.dispatch({
                type: TYPE.CRE_VENDOR_RESET,
                id: 'creInvite',
            });
        };

        this.onContactManage = (idx) => {
            this.dialog = $mdDialog.show({
                /* @ngInject */
                controller: ($scope) => {
                    $scope.save = () => {
                        const contactIdx = findIndex(this.creInvite.contacts, ['email', this.creInvite.contact_email]);
                        if ($scope.dialogForm.$valid) {
                            if (
                                (isUndefined(idx) && contactIdx !== -1) ||
                                (contactIdx !== idx && contactIdx !== -1)
                            ) {
                                Notifier.error('Contact email already exists!');
                            } else {
                                $ngRedux.dispatch({
                                    type: isUndefined(idx) ? TYPE.CRE_CONTACT_CREATE : TYPE.CRE_CONTACT_UPDATE,
                                    id: 'creInvite',
                                    data: {
                                        sty_id: this.creInvite.contact_position,
                                        title: this.creInvite.contact_title,
                                        first_name: this.creInvite.contact_first_name,
                                        last_name: this.creInvite.contact_last_name,
                                        email: this.creInvite.contact_email,
                                        is_additional_contact: 1,
                                    },
                                    idx,
                                });
                                $mdDialog.hide();
                            }
                        }
                    };
                    $scope.cancel = () => $mdDialog.cancel();
                },
                template: require('./dialogs/cre-vendors-contact-dialog.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                onShowing: () => {
                    if (typeof idx !== 'undefined') {
                        $ngRedux.dispatch({
                            type: TYPE.CRE_CONTACT_DETAILS,
                            id: 'creInvite',
                            idx,
                        });
                    }
                }
            }).then(contactReset, contactReset);
        };

        const contactReset = () => {
            $ngRedux.dispatch({
                type: TYPE.CRE_CONTACT_RESET,
                id: 'creInvite',
            });
        };

        this.onContactDelete = (idx) => {
            $ngRedux.dispatch({
                type: TYPE.CRE_CONTACT_DELETE,
                id: 'creInvite',
                email: this.creInvite.contacts[idx].email,
            });
        }

        this.onSend = () => {
            if (this.creInviteForm.$valid) {
                if (validateVendor()) {
                    this.sendInvite({
                        purpose: 'venapp',
                        app_type: this.creInvite.type,
                        healthcare: this.creInvite.supplyHealthcare,
                        non_healthcare: this.creInvite.supplyNonHealthcare,
                        vendor_uuid: this.creInvite.vendor_uuid,
                        company_name: this.creInvite.vendor_name,
                        cred_invitation_contacts: [
                            {
                                sty_id: this.creInvite.position,
                                title: this.creInvite.title,
                                first_name: this.creInvite.first_name,
                                last_name: this.creInvite.last_name,
                                email: this.creInvite.email,
                                is_additional_contact: 0,
                            },
                            ...this.creInvite.contacts,
                        ],
                    }).then(() => {
                        this.creInviteForm.$setPristine();
                        this.creInviteForm.$setUntouched();
                        Notifier.success('Applicant invitation has been successfully sent.');
                    });
                } else {
                    Notifier.error('Company name already exists. Please use existing Vendor.');
                }
            } else {
                Notifier.error('Please fill in the form fields.');
            }
        }

        const validateVendor = () => {
            const { vendor_uuid, vendor_name, vendors } = this.creInvite;
            if (isNull(vendor_uuid)) {
                return findIndex(vendors, ['vendor_name', vendor_name.toUpperCase()]) === -1;
            }
            return true;
        };

        /**
         * @function $onDestroy
         */
        this.$onDestroy = () => {
            this.unsubscribe();
            this.dialog && $mdDialog.hide(this.dialog);
        }
    },
    template: require('./cre-vendors-invitation.html')
};
