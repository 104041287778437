import { GridReducer } from '../../../common/grid/grid.reducer';
import {GridFilterReducer} from '../../../common/grid/filter/grid-filter.reducer';
import * as GRID from '../../../common/grid/grid.constants';
import * as FORM from '../../../common/form/input/input.constants';
import { TYPES } from './dms-import-action-type.constants';
import { IDS } from './dms-import-action-id.constants';
import merge from 'lodash/merge';
import get from 'lodash/get';
import set from 'lodash/set';
/**
 * @function DmsImportReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function DmsImportReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            [IDS.dmsImportListGrid]: GridReducer(),
            [IDS.dmsImportDetails]: {

            },
        };
    }

    switch (action.type) {
        case GRID.GRID_INIT:
        case GRID.GRID_FILTER:
        case GRID.GRID_FILTER_TOGGLE:
        case GRID.GRID_RESET_FILTER:
        case GRID.GRID_INIT_FILTER:
        case GRID.GRID_LOADING:
        case GRID.GRID_PAGINATE:
        case GRID.GRID_RELOAD:
        case GRID.GRID_FETCH:
        case GRID.GRID_SELECT:
        case GRID.GRID_SELECT_ALL:
        case GRID.GRID_DESELECT:
        case GRID.GRID_DESELECT_ALL:
            if (action.id === IDS.dmsImport) {
                return set({...state}, action.path,
                    GridReducer( get(state, action.path), action)
                )
            }
        case GRID.GRID_RESET:
            if (action.id === IDS.dmsImport) {
                return set({...state}, action.path,
                    GridReducer( get(state, action.path),{
                        type: GRID.GRID_RESET_FILTER,
                        exclude: action.exclude || [],
                    })
                )
            }

        case FORM.INPUT_SELECT:
        case FORM.INPUT_TEXT:
        case FORM.INPUT_TEXT_AREA:
        case FORM.INPUT_CHECKBOX:
        case FORM.INPUT_DATE:
        case FORM.INPUT_RADIO:
            if (action.id === IDS.dmsImport) {
                const filter = {
                    [action.path.split('.').pop()]: action.input
                };
                action.path = action.path.split('.').shift()
                return set({...state}, action.path,
                    GridReducer(get(state, action.path), { type: GRID.GRID_FILTER, filter })
                )
            }
        default:
            return state;
        }
}
