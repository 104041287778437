import { gridTpl } from '../../../../../common/grid/grid.controller';

export const administrationAccountsRscGrid = {
    bindings: {
        identifier: '@',
        title: '<',
        onSelect: '&',
        onRemove: '&'
    },
    controller: 'AdministrationAccountsRscGridController',
    ...gridTpl
};
