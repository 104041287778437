/**
 * @const MANUFACTURERS_FETCH
 * @type {string}
 */
export const MANUFACTURERS_FETCH = 'MANUFACTURERS_FETCH';

/**
 * @const MANUFACTURER_TYPES_FETCH
 * @type {string}
 */
export const MANUFACTURER_TYPES_FETCH = 'MANUFACTURER_TYPES_FETCH';

/**
 * @const MANUFACTURER_TYPES_SELECT
 * @type {string}
 */
export const MANUFACTURER_TYPES_SELECT = 'MANUFACTURER_TYPES_SELECT';

/**
 * @const MANUFACTURER_SELECTED
 * @type {string}
 */
export const MANUFACTURER_SELECTED = 'MANUFACTURER_SELECTED';

/**
 * @const MANUFACTURER_RESET
 * @type {string}
 */
export const MANUFACTURER_RESET = 'MANUFACTURER_RESET';

/**
 * @const MANUFACTURER_EQU_TYPES
 * @type {string}
 */
export const MANUFACTURER_EQU_TYPES = 'MANUFACTURER_EQU_TYPES';

/**
 * @const MANUFACTURER_EQU_MODEL
 * @type {string}
 */
export const MANUFACTURER_EQU_MODEL = 'MANUFACTURER_EQU_MODEL';

/**
 * @const MANUFACTURER_EQU_MODEL_CREATE
 * @type {string}
 */
export const MANUFACTURER_EQU_MODEL_CREATE = 'MANUFACTURER_EQU_MODEL_CREATE';

/**
 * @const MANUFACTURER_EQU_MODEL_RESET
 * @type {string}
 */
export const MANUFACTURER_EQU_MODEL_RESET = 'MANUFACTURER_EQU_MODEL_RESET';
