class ReportingEquipmentInfoHistoryController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this.mapState, {})(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    mapState(state) {
        return {
            equipment: state.reporting.reportingEquipmentDetails
        }
    }
}

ReportingEquipmentInfoHistoryController.$inject = ['$ngRedux'];

export const reportingEquipmentInfoHistory = {
    controller: ReportingEquipmentInfoHistoryController,
    template: `
        <reporting-equipment-info-history-grid title="{{$ctrl.equipment.title}}"></reporting-equipment-info-history-grid>
        <tabs-navigation identifier="app.reporting.equipmentInfo"
                         state="app.reporting.equipmentInfo.history">
        </tabs-navigation>
    `
};
