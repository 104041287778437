import { GridController } from '../../../../common/grid/grid.controller';

/* @ngInject */
export function ManufacturersGridController($controller, $ngRedux, $state, NavigationService, ManufacturersActions) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'manufacturers';
        ctrl.identifier = 'manufacturersGrid';
        ctrl.filterOpen = true;
        ctrl.filterTemplate = './manufacturers-grid-filter.html';
        ctrl.exportComponent = false;

        ctrl.columns = [
            {title: '#',                        mapper: null},
            {title: 'Manufacturer Name',        mapper: 'name'},
            {title: 'Manufacturer Description', mapper: 'description'},
            {title: 'Equipment Type',           mapper: 'equipmentTypeName'}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), ManufacturersActions)(ctrl);
        ctrl.dataAction = ctrl.getManufacturers;

        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
