import { GridController } from "../../../../../common/grid/grid.controller";
import watch from 'redux-watch';

/* @ngInject */
export function ProtectiveEquipmentGridController($controller, $ngRedux, $state, $rootScope, NavigationService, ProtectiveEquipmentActions, ProtectiveEquipmentService) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'protectiveEquipment';
        ctrl.identifier = 'protectiveEquipmentGrid';
        ctrl.filterTemplate = './protective-equipment-grid-filter.html';
        ctrl.exportService = ProtectiveEquipmentService.getPersonalEquipment;

        ctrl.columns = [
            {title: '#',                    mapper: null},
            {title: 'Equipment ID',         mapper: 'equipmentId'},
            {title: 'Site Location',        mapper: 'location.name'},
            {title: 'Type',                 mapper: 'type.name'},
            {title: 'Full Body Protection', mapper: 'bodyProtection.name'},
            {title: 'Lead Equivalence',     mapper: 'leadEquivalence.getValue'}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), ProtectiveEquipmentActions)(ctrl);
        ctrl.dataAction = ctrl.getProtectiveEquipment;
        ctrl.init.call(ctrl);
    };

    var watchReset = watch($ngRedux.getState, 'protectiveEquipment.protectiveEquipmentGrid.reset');
    var onReset = $ngRedux.subscribe(watchReset(function() {
        $rootScope.$broadcast('SELECT_CASCADE_RESET', 'personalEquipmentFilterForm');
    }));

    ctrl.$onDestroy = function() {
        onReset();
        ctrl.destroy();
    };
}
