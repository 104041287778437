/*
*   List of Action IDs that should be passed from accreditation.reducer.js to settings.reducer.js
 */

export const IDS = {
    accSettings: 'accSettings',

    // components:
    accreditationSettingsSchemeDetails:           'accreditationSettingsSchemeDetails',

    accreditationSettingsHeadOffices:             'accreditationSettingsHeadOffices',
    accreditationSettingsSiteLocations:           'accreditationSettingsSiteLocations',

    accreditationSettingsSectionDetails:          'accreditationSettingsSectionDetails',

    accreditationSettingsHeadOfficeDocsGrid:      'accreditationSettingsHeadOfficeDocsGrid',
    accreditationSettingsAssessmentDetails:       'accreditationSettingsAssessmentDetails',
    accreditationSettingsAssessmentHeadOffice:    'accreditationSettingsAssessmentHeadOffice',

    //paths
    headOfficesGrid:                    'headOfficesGrid',
    siteLocationsGrid:                  'siteLocationsGrid',

    defaultDocumentsGrid:               'defaultDocumentsGrid',
    defaultDocumentDetails:             'defaultDocumentDetails',

    hofRequiredDocs:                    'hofRequiredDocs',
    hofRequiredDocsGrid:                'hofRequiredDocsGrid',
    hofRequiredDocDetails:              'hofRequiredDocDetails',
    hofRequiredDocActiveHofsGrid:       'hofRequiredDocActiveHofsGrid',

    sloRequiredDocs:                    'sloRequiredDocs',
    sloRequiredDocsGrid:                'sloRequiredDocsGrid',
    sloRequiredDocDetails:              'sloRequiredDocDetails',
    sloRequiredDocActiveSitesGrid:      'sloRequiredDocActiveSitesGrid',

    assessmentDetails:                  'assessmentDetails',
    assessmentHeadOffice:               'assessmentHeadOffice',
    assessmentHeadOfficesGrid:          'assessmentHeadOfficesGrid',

    // states
    app_accreditation_add_scheme:                                   'app.accreditation.add-scheme',

    // tab states:
    app_accreditation_scheme_settings:                              'app.accreditation.scheme.settings',
    app_accreditation_scheme_settings_dashboard:                    'app.accreditation.scheme.settings.dashboard',

    app_accreditation_scheme_settings_scheme_details:               'app.accreditation.scheme.settings.scheme-details',

    app_accreditation_scheme_settings_enabled:                      'app.accreditation.scheme.settings.enabled',
    app_accreditation_scheme_settings_enabled_head_offices:         'app.accreditation.scheme.settings.enabled.head-offices',
    app_accreditation_scheme_settings_enabled_site_locations:       'app.accreditation.scheme.settings.enabled.site-locations',

    app_accreditation_scheme_settings_sections:                     'app.accreditation.scheme.settings.sections',

    app_accreditation_scheme_settings_default_documents:            'app.accreditation.scheme.settings.default-documents',
    app_accreditation_scheme_settings_default_documents_list:       'app.accreditation.scheme.settings.default-documents.list',
    app_accreditation_scheme_settings_default_documents_add:        'app.accreditation.scheme.settings.default-documents.add',
    app_accreditation_scheme_settings_default_documents_details:    'app.accreditation.scheme.settings.default-documents.details',
    app_accreditation_scheme_settings_default_documents_versions:   'app.accreditation.scheme.settings.default-documents.versions',

    app_accreditation_scheme_settings_hof_required_docs:            'app.accreditation.scheme.settings.hof-required-docs',
    app_accreditation_scheme_settings_hof_required_docs_list:       'app.accreditation.scheme.settings.hof-required-docs.list',
    app_accreditation_scheme_settings_hof_required_docs_add:        'app.accreditation.scheme.settings.hof-required-docs.add',
    app_accreditation_scheme_settings_hof_required_docs_details:    'app.accreditation.scheme.settings.hof-required-docs.details',

    app_accreditation_scheme_settings_slo_required_docs:            'app.accreditation.scheme.settings.slo-required-docs',
    app_accreditation_scheme_settings_slo_required_docs_list:       'app.accreditation.scheme.settings.slo-required-docs.list',
    app_accreditation_scheme_settings_slo_required_docs_add:        'app.accreditation.scheme.settings.slo-required-docs.add',
    app_accreditation_scheme_settings_slo_required_docs_details:    'app.accreditation.scheme.settings.slo-required-docs.details',
};