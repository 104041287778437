import { gridTpl} from "../../../../../common/grid/grid.controller";

export const mammoQualityControlArtifactEvaluationGrid = {
    bindings: {
        identifier: '@',
        onReset: '&'
    },
    controller: 'mammoQualityControlArtifactEvaluationGridController',
    ...gridTpl
};
