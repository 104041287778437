import { GridController, gridTpl } from '../../../../../common/grid/grid.controller';
import { getActionPlanLibrary } from '../../events-action-plan.actions'

class EventsActionPlanLibraryGridController extends GridController {
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.module = 'events';
        this.identifier = 'eventsActionPlanLibraryGrid';
        this.filterTemplate = './action-plan-library-grid-filter.html';
        this.exportComponent = false;

        this.columns = [
            {title: '#',                    mapper: null},
            {title: 'Action Plan ID No.',   mapper: 'pln_no'},
            {title: 'Name',                 mapper: 'plan_name'},
            {title: 'Summary',              mapper: 'plan_summary'}
        ];

        this.buttons = [{label: 'Modify', class: 'btnGridModify', callback: this.onSelect}];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this),
            { getActionPlanLibrary }
        )(this);

        this.dataAction = this.getActionPlanLibrary;
        this.exportService = this.getActionPlanLibrary;
        this.filter.identifier = this.identifier;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const eventsActionPlanLibraryGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onRemove: '&'
    },
    controller: EventsActionPlanLibraryGridController,
    ...gridTpl
};
