/* @ngInject */
export function VendorAgentFactory(Vendor) {
    return function VendorAgent() {
        this.id = null;
        this.vendor = new Vendor();
        this.email = null;
        this.firstName = null;
        this.lastName = null;
        this.phone = null;
        this.username = null;
        this.fax = null;
        this.address = null;
    };
}
