import { GridController, gridTpl } from '../../../../../../common/grid/grid.controller';
import { getVendorWarranties } from '../../../cre-vendors.actions';

class CredentialingVendorsWarrantiesGridController extends GridController {
    /* @ngInject */
    constructor($ngRedux, $state, $location, NavigationService) {
        super($ngRedux, $state, NavigationService);
        this.$ngRedux = $ngRedux;
        this.$location = $location;
    }

    $onInit() {
        this.module = 'credentialing';
        this.identifier = 'creVendorsWarrantiesGrid';
        this.header = false;
        this.exportComponent = false;

        this.columns = [
            {title: '#',                        mapper: null},
            {title: 'Warranty Description',     mapper: 'warranty_desc'},
            {title: 'Warranty Period',          mapper: 'warranty_period'},
            {title: 'Additional Costs',         mapper: (item) => item.warranty_additional_cost === 1 ? 'Yes' : 'No'},
            {title: 'After Sales Service',      mapper: (item) => item.warranty_after_sales_service === 1 ? 'Yes' : 'No'},
            {title: 'Installation Required',    mapper: (item) => item.warranty_install === 1 ? 'Yes' : 'No'},
            {title: 'Other Requirements',       mapper: 'warranty_other_requirements'},
        ];

        this.unsubscribe = this.$ngRedux.connect(
            this.mapState().bind(this), { getVendorWarranties }
        )(this);

        this.dataAction = this.getVendorWarranties;
        this.filter.token = this.$location.search().token;
        this.filter.identifier = this.identifier;
        this.filter.application_id = this.$location.search().invite || this.$ngRedux.getState().credentialing.creVendor.application_id;

        this.init();
    }

    $onDestroy() {
        this.destroy();
    }
}

export const creVendorsWarrantiesGrid = {
    bindings: {
        title: '@',
        onSelect: '&',
        onRemove: '&',
    },
    controller: CredentialingVendorsWarrantiesGridController,
    ...gridTpl
};
