import { TabsReducer } from '../../../common/tabs/tabs.reducer';
import {IDS} from './dms-manage-action-id.constants';
export const dmsManageTabs = {
    'app.document-management.manage': Object.assign(TabsReducer(), {
        tabs: [
            {
                state: IDS.app_dms_manage_list,
                title: 'DOCUMENT_MANAGEMENT.MANAGE.LIST.TAB',
                visible: true,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Add New Document'}
                }
            },
            {
                state: IDS.app_dms_manage_details,
                title: 'DOCUMENT_MANAGEMENT.MANAGE.DETAILS.TAB',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Save'}
                }
            },
            {
                state: IDS.app_dms_manage_add,
                title: 'DOCUMENT_MANAGEMENT.MANAGE.DOCUMENT_ADD.TAB',
                visible: false,
                disabled: false,
                loading: false,
                buttons: {
                    execute: {label: 'Add New Document'}
                }
            },
        ],
    }),
};
