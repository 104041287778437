/**
 * Creates a new QcTasksController.
 * @class
 */
class QcTasksController {
    constructor($log, NavigationService) {
        this.$log = $log;
        this.NavigationService = NavigationService;
    }

    $onInit() {
        this.$log.debug('QcTasks::init');
        const navConfig = this.NavigationService.getNavConfig();
        this.colorName = navConfig.colorName;
        this.icon = navConfig.icon;

        this.brands = [
            {id:0, name:"Dentronix"},
            {id:1, name:"StatIM"},
            {id:2, name:"StatIM 2120 steam condenser"},
            {id:3, name:"Midmark M9"},
            {id:4, name:"Midmark M11"},
            {id:5, name:"Tuttnauer"},
            {id:6, name:"Cox 600"}
        ];
    }
}

QcTasksController.$inject = ['$log', 'NavigationService'];

export const qcTasks = {
    controller: QcTasksController,
    // template: `
    //     <div class="loader formHeader">
    //         <asset class="color{{$ctrl.colorName}}" src="{{$ctrl.icon}}"></asset>
    //         <div class="color{{$ctrl.colorName}}">Coming soon...</div>
    //     </div>
    // `
    template: require('./qc-tasks.html')
};
