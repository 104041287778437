/* @ngInject */
export function ProtectiveEquipmentBaseTestController($scope, $ngRedux, $mdDialog, Notifier, ProtectiveEquipmentTestService, ProtectiveEquipmentManager, PersonalEquipmentTest, PersonalEquipmentTestComment, FileDocument) {

    $scope.TEST_STATE = $scope.MODULE_STATE_CREATE;

    $scope.equipmentTest = new PersonalEquipmentTest();
    $scope.equipmentTestComment = new PersonalEquipmentTestComment();
    $scope.equipmentTestCommentList = [];
    $scope.equipmentTesters = [];

    let removeDialog = null;
    let unsubscribe = angular.noop;

    $scope.onEquipmentTestSelected = function(event) {
        _.assign($scope.equipmentTest, event.item);
        $scope.TEST_STATE = $scope.MODULE_STATE_UPDATE;
        $scope.executeTab();
    };

    $scope.onEquipmentTestRemove = (event) => {
        const confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('Chosen equipment test will be inactivated.')
            .ariaLabel('Inactivate equipment test')
            .ok('Yes')
            .cancel('No');

        removeDialog = $mdDialog.show(confirm).then(() => {
            ProtectiveEquipmentTestService.disposeEquipmentTest(event.item.id).then(function() {
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'protectiveEquipmentTestsGrid'
                });
                $mdDialog.cancel();
            }, function(error) {
                Notifier.error('System error! Please contact your admin.');
            });
        });
    };

    $scope.selectScreeningResult = function() {
        if (parseInt($scope.equipmentTest.annualScreening))
            $scope.equipmentTest.annualScreeningResult = 'passed';
        else
            ProtectiveEquipmentManager.selectScreeningResult($scope.equipmentTest);
    };

    $scope.addTestComment = function() {
        if ($scope.equipmentTestComment.text) {
            ProtectiveEquipmentTestService.addComment($scope.equipmentTestComment, $scope.protectiveEquipment.id).then(function(id) {
                $scope.equipmentTestComment.id = id;
                $scope.equipmentTestCommentList.unshift(_.cloneDeep($scope.equipmentTestComment));
                $scope.equipmentTestComment = new PersonalEquipmentTestComment();
                Notifier.success('New Comment was successfully added');
            }, function(error) {
                $scope.equipmentTestComment = new PersonalEquipmentTestComment();
                Notifier.responseMessage(error);
            });
        }
        else Notifier.error('Comment field is required');
    };

    $scope.updateTestComment = function(comment) {
        // TODO
    };

    $scope.assignTestComment = function(comment) {
        if ($scope.TEST_STATE == $scope.MODULE_STATE_CREATE) {
            assignTestCommentInternal(comment);
        }
        else {
            ProtectiveEquipmentTestService.assignComment($scope.equipmentTest.id, comment.id).then(function(status) {
                assignTestCommentInternal(comment);
            }, function(error) {
                Notifier.responseMessage(error);
            });
        }
    };

    function assignTestCommentInternal(comment) {
        if ($scope.equipmentTest.assignComment(comment)) {
            // TODO hotfix, not so elegant solution
            $ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: 'protectiveEquipmentTestsGrid'
            });
            $ngRedux.dispatch({
                type: 'GRID_RELOAD',
                id: 'protectiveEquipmentTestReportsGrid'
            });
            Notifier.success('Comment was successfully assigned');
        }
        else
            Notifier.error('Comment is already assigned');
    }

    $scope.removeTestComment = function(commentId) {
        if ($scope.TEST_STATE == $scope.MODULE_STATE_CREATE) {
            $scope.equipmentTest.removeComment(commentId);
            Notifier.success('Comment was successfully removed');
        }
        else {
            ProtectiveEquipmentTestService.removeComment($scope.equipmentTest.id, commentId).then(function(status) {
                $scope.equipmentTest.removeComment(commentId);
                // TODO hotfix, not so elegant solution
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'protectiveEquipmentTestsGrid'
                });
                $ngRedux.dispatch({
                    type: 'GRID_RELOAD',
                    id: 'protectiveEquipmentTestReportsGrid'
                });
                Notifier.success('Comment was successfully removed');
            }, function(error) {
                Notifier.responseMessage(error);
            });
        }
    };

    $scope.deleteTestComment = function(commentId) {
        // TODO remove comment first then execute delete
        ProtectiveEquipmentTestService.deleteComment(commentId).then(function(status) {
            $scope.equipmentTest.removeComment(commentId);
            var idx = _.findIndex(this.equipmentTestCommentList, ['id', commentId]);
            if (idx > -1) this.equipmentTestCommentList.splice(idx, 1);
            Notifier.success('Comment was successfully removed');
        });
    };

    $scope.$on('FILE_UPLOAD_SUCCESS', function(event, filePath, uploader) {
        if ($scope.selectedTab.id == ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_CREATE ||
            $scope.selectedTab.id == ProtectiveEquipmentManager.TAB_PERSONAL_EQUIPMENT_TEST_DETAILS) {
            var document = new FileDocument();
            document.url = filePath;
            ProtectiveEquipmentTestService.addPersonalEquipmentTestDocument(document).then(function(id) {
                document.id = id;
                uploader.clearQueue();
                uploader.cancelAll();
                if ($scope.TEST_STATE == $scope.MODULE_STATE_CREATE) {
                    $scope.equipmentTest.addDocument(document);
                    Notifier.success('Document was successfully added');
                }
                else {
                    ProtectiveEquipmentTestService.assignPersonalEquipmentTestDocument($scope.equipmentTest.id, document.id).then(function(status) {
                        $scope.equipmentTest.addDocument(document);
                        Notifier.success('Document was successfully added');
                    }, function(error) {
                        Notifier.responseMessage(error);
                    });
                }
            }, function(error) {
                Notifier.responseMessage(error);
            });
        }
    });

    $scope.removeTestDocument = function(documentId) {
        if ($scope.TEST_STATE == $scope.MODULE_STATE_CREATE) {
            $scope.equipmentTest.removeDocument(documentId);
            Notifier.success('Document was successfully removed');
        }
        else {
            ProtectiveEquipmentTestService.removePersonalEquipmentTestDocument($scope.equipmentTest.id, documentId).then(function(status) {
                $scope.equipmentTest.removeDocument(documentId);
                Notifier.success('Document was successfully removed');
            }, function(error) {
                Notifier.responseMessage(error);
            });
        }
    };

    $scope.setTestDueDate = () => {
        if ($scope.equipmentTest.testedDate) {
            let testDate = new Date($scope.equipmentTest.testedDate);
            $scope.equipmentTest.dueDate = new Date(testDate.setMonth(testDate.getMonth() + parseInt($scope.equipmentTest.testDateNotificationFrequency)));
        }
    };

    $scope.$onDestroy = function() {
        if (removeDialog) $mdDialog.hide(removeDialog);
        unsubscribe();
    };
}
