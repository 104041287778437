/**
 * Creates a new SelectContrastController.
 * @class
 */
class SelectContrastController {
    constructor($ngRedux, DoseReferenceLevelsActions, $filter) {
        this.$ngRedux = $ngRedux;
        this.DoseReferenceLevelsActions = DoseReferenceLevelsActions;
        this.$filter = $filter;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this.mapState, this.DoseReferenceLevelsActions)(this);
        this.getContrastList();
    };

    onSelect() {
        if (this.selectedOption) {
            this.$ngRedux.dispatch({
                type: 'CONTRAST_SELECTED',
                id: 'drlCtScannerContrastReport',
                contrast: this.selectedOption
            });
        }
    }

    $onDestroy() {
        this.unsubscribe();
    }

    mapState(state) {
        return {
            options: state.doseReferenceLevels.drlCtScannerContrastReport.contrastList,
            selectedOption: state.doseReferenceLevels.drlCtScannerContrastReport.contrast
        }
    }
}

SelectContrastController.$inject = ['$ngRedux', 'DoseReferenceLevelsActions'];

export const selectContrast = {
    bindings: {
        name: '@'
    },
    require : {
        form : '^form'
    },
    controller: SelectContrastController,
    template: `
        <md-input-container class="md-block" flex>
            <label>{{'DLR.CT_SCANNER.CONTRAST_REPORT.FORM_TITLE' | translate}}</label>
            <md-select id="{{$ctrl.name}}" name="{{$ctrl.name}}" ng-model="$ctrl.selectedOption" md-on-close="$ctrl.onSelect()" required>
                <md-option ng-value="item.id" ng-repeat="item in $ctrl.options">
                    <span class="md-body-2">{{item.name}}</span>
                </md-option>
            </md-select>
            <div ng-messages="$ctrl.form[$ctrl.name].$error" ng-show="$ctrl.form.$submitted || $ctrl.form[$ctrl.name].$touched">
                <div ng-message="required">{{'DLR.CT_SCANNER.CONTRAST_REPORT.FORM_FIELD_REQUIRED' | translate}}</div>
            </div>
        </md-input-container>
    `
};
