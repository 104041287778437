import { SystemAlertsController } from '../../../../components/system/alerts/system-alerts.component';

/**
 * Creates a new EmployeesAlertsController.
 * @class
 */
class EmployeesAlertsController extends SystemAlertsController {
    constructor($ngRedux, $state, NavigationService) {
        super($ngRedux, $state, NavigationService)
    }

    selectEmployee(event) {
        this.$state.go('app.employees.manage', {uuid: event.item.employeeUuid});
    }
}

EmployeesAlertsController.$inject = ['$ngRedux', '$state', 'NavigationService'];

export const employeesAlerts = {
    controller: EmployeesAlertsController,
    template: `
        <system-alerts-grid identifier="{{$ctrl.identifier}}"
                           module-id="$ctrl.moduleId"
                           icon="alerts.svg"
                           on-select="$ctrl.selectEmployee($event)">
        </system-alerts-grid>
    `
};
