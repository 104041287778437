import API from '../../common/http/api.fetch';

export default function ReportingActions(EquipmentService, Notifier, LicenceService) {
    function getEquipment(filter) {
        return dispatch => {
            EquipmentService.getEquipment(filter).then(data => {
                dispatch({
                    type: 'GRID_FETCH',
                    id: 'reportingEquipmentGrid',
                    items: data.items,
                    itemsCount: data.totalActive
                });
            });
        }
    }

    function getEquipmentInfo(id) {
        return dispatch => {
            EquipmentService.inspectionInfo(id).then(data => {
                dispatch({
                    type: 'EQUIPMENT_FETCH',
                    id: 'reportingEquipmentInfo',
                    equipment: data.equipment,
                    service: data.serviceDetails
                });
                dispatch({
                    type: 'GRID_FETCH',
                    id: 'reportingEquipmentInfoServiceHistoryGrid',
                    items: data.serviceHistory
                });
                dispatch({
                    type: 'GRID_FETCH',
                    id: 'reportingEquipmentInfoInspectionsGrid',
                    items: data.inspections.list
                });
                dispatch({
                    type: 'GRID_FETCH',
                    id: 'reportingEquipmentInfoHistoryGrid',
                    items: data.history
                });
            });
        }
    }

    function getEquipmentInspections(filter) {
        return dispatch => {
            EquipmentService.filterInspections(filter).then(data => {
                dispatch({
                    type: 'GRID_FETCH',
                    id: 'reportingEquipmentInspectionGrid',
                    items: data.list,
                    itemsCount: data.rows
                });
            });
        }
    }

    function getEquipmentSummary(filter) {
        return dispatch => {
            EquipmentService.summary(filter).then(data => {
                dispatch({
                    type: 'GRID_FETCH',
                    id: 'reportingEquipmentSummaryGrid',
                    items: data
                });
            });
        }
    }

    function getEquipmentInOutHistory(filter) {
        return dispatch => {
            EquipmentService.history(filter).then(data => {
                dispatch({
                    type: 'GRID_FETCH',
                    id: 'reportingEquipmentHistoryGrid',
                    items: data
                });
            });
        }
    }

    function getLicences(filter) {
        return dispatch => {
            LicenceService.subjectLicences(filter.location, 'loc', filter).then(data => {
                dispatch({
                    type: 'GRID_FETCH',
                    id: 'reportingCnscGrid',
                    items: data.items,
                    itemsCount: data.total
                });
            });
        }
    }

    return {
        getEquipment,
        getEquipmentInfo,
        getEquipmentInspections,
        getEquipmentSummary,
        getEquipmentInOutHistory,
        getLicences
    }
}

export const getIsotopesActivity = (filter) => {
    return (dispatch) => {
        return API.fetch({
            url: '/reporting/isotopes-activity',
            method: 'GET',
            params: {
                offset: filter.offset,
                limit: filter.limit,
                location: filter.location,
                isotopeId: filter.isotopeId,
                atomicNoId: filter.atomicNoId,
                licenceNo: filter.licenceNo,
                sealed: filter.sealed,
                exportFormat: filter.exportFormat,
                exportColumns: filter.exportColumns
            }
        }).then(({ data }) => {
            if (filter.exportFormat) {
                return data;
            }
            dispatch({
                type: 'GRID_FETCH',
                id: 'reportingIsotopesActivityGrid',
                items: data.items,
                itemsCount: data.items_count
            })
        });
    }
}

ReportingActions.$inject = ['EquipmentService', 'Notifier', 'LicenceService'];
