/* @ngInject */
export function SelectEmployeeLicenceTypeController($ngRedux, LicenceService) {
    var ctrl = this;
    var unsubscribe = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, {})(this);
        ctrl.options = [];

        LicenceService.getLicenceTypes(ctrl.employeeUuid).then(function(items) {
            ctrl.options = items;
        });
    };

    ctrl.$onDestroy = function() {
        unsubscribe();
    };

    ctrl.onSelect = function() {
        if (ctrl.selectedOption) {
            $ngRedux.dispatch({
                type: 'EMPLOYEE_LICENCE_TYPE_SELECTED',
                id: ctrl.identifier,
                licenceType: ctrl.selectedOption
            });
        }
    };

    function mapStateToThis(state) {
        return {
            selectedOption: state.employees[ctrl.identifier].filter.type,
            employeeUuid: state.employees[ctrl.identifier].filter.uuid
        };
    }
}
