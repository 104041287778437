import API from '../../common/http/api.fetch';
import * as TYPE from './radioactive-materials.actions.types';
import { saveAs } from 'file-saver';

export const getNextId = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: '/radioactive-materials/next-id',
            method: 'GET'
        }).then(({ data: { params } }) => {
            const visualId = params.ram_id;
            dispatch({ type: TYPE.NEXT_ID, id, visualId });
            return visualId;
        });
    }
}

export const getIsotopes = (id) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/isotopes`,
            method: 'GET',
            params: {}
        }).then((response) => {
            dispatch({
                type: TYPE.RM_ISOTOPES,
                id,
                path: 'isotopes',
                items: response.data.map((item) => {
                    return {
                        value: item.type_id,
                        label: item.name
                    };
                })
            })
        });
    }
}

export const getAtomicNums = (id, isotope, reset) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/atomic-nums`,
            method: 'GET',
            params: { isotope }
        }).then((response) => {
            dispatch({
                type: TYPE.RM_ATOMIC_NUMS,
                id,
                path: 'atomicNums',
                items: response.data.items.map((item) => {
                    return {
                        value: item.ato_id,
                        label: item.atomic_no,
                        halfLife: item.half_life,
                        halfLifeUnit: item.half_life_unit_id,
                        halfLifeValue: item.half_life_value
                    };
                }),
                reset: reset ? {
                    atomicNum: null,
                    halfLife: null
                } : {}
            })
        });
    }
}

export const setHalfLife = (id, path, value) => {
    return (dispatch) => {
        dispatch({ type: TYPE.RM_HALF_LIFE, id, path, value });
    }
};

export const getUnits = (id, code, path, reset) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/units`,
            method: 'GET',
            params: { code }
        }).then((response) => {
            dispatch({
                type: TYPE.RM_UNITS,
                id,
                path,
                items: response.data.map((item) => {
                    return {
                        value: item.type_id,
                        label: item.name
                    };
                }),
                reset: reset || {}
            })
        });
    }
}

export const qrCodeGenerate = (identifier, data) => {
    return (dispatch) => {
        return API.fetch({
            url: '/radioactive-materials/qrcode',
            method: 'POST',
            data: {
                id: data.id,
                visualId: data.visualId
            }
        }).then((response) => {
            dispatch({
                type: 'QR_CODE',
                id: identifier,
                qrcode: API.url(`/img/${localStorage.myrsoAuthToken}${response.data}`)
            });
        });
    }
}

export const qrCodePrint = (type, data) => {
    return () => {
        return API.fetch({
            url: '/radioactive-materials/qrcode/print',
            method: 'POST',
            responseType: 'arraybuffer',
            data: {
                type,
                id: data.id,
                visualId: data.visualId
            }
        }).then((response) => {
            saveAs(new Blob([response.data], {type: 'application/pdf'}), `${data.visualId}.pdf`);
        });
    }
}

export const getDocumentTypes = (documentCode) => {
    return (dispatch) => {
        return API.fetch({
            url: `/radioactive-materials/document-types`,
            method: 'GET',
            params: {
                documentCode: documentCode
            }
        }).then((response) => {
            dispatch({
                type: 'DOCUMENT_TYPES',
                id: 'rmTest',
                items: response.data.map(item => {
                    return {
                        value: item.doc_type_id,
                        label: item.doc_type_name,
                        item: {
                            documentTypeId: item.doc_type_id,
                            documentTypeName: item.doc_type_name,
                            documentTypeCode: item.doc_type_code
                        }
                    }
                })
            });
        });
    }
}

export const getDocumentStatusList = () => {
    return (dispatch) => {
        return API.fetch({
            url: `/document-statuses`,
            method: 'GET',
        }).then((response) => {
            dispatch({
                type: 'DOCUMENT_STATUS_LIST',
                id: 'rmTest',
                items: response.data.map(item => {
                    return {
                        value: item.sta_id,
                        label: item.name,
                        item: {
                            id: parseInt(item.sta_id),
                            name: item.name,
                            code: item.code
                        }
                    }
                })
            });
        });
    }
}

export const getLocationLicences = (identifier, uuid, licenceCode) => {
    return (dispatch) => {
        return API.fetch({
            url: `/licences/` + uuid,
            method: 'GET',
            params: {
                licenceCode: licenceCode,
                status: 'act'
            }
        }).then((response) => {
            dispatch({
                type: 'GET_LOCATION_LICENCES',
                id: identifier,
                items: response.data.rows.map((item) => {
                    return {
                        value: item.lic_id,
                        label: item.license_type
                    };
                })
            })
        });
    }
}

export const checkIsotopeInLicence = (testData) => {
    return () => {
        return API.fetch({
            url: '/radioactive-materials/check-isotope-in-licence',
            method: 'GET',
            params: {
                isotopeId: testData.isotope,
                licenceId: testData.locationLicence,
                atomicNumberId: testData.atomicNum,
                sourceTypeId: testData.sealed
            }
        }).then(({ data: { params } }) => {
            return params;
        });
    }
}
