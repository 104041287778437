import { gridTpl } from '../../../../common/grid/grid.controller';

export const equipmentGrid = {
    bindings: {
        identifier: '@',
        onSelect: '&',
        onReset: '&',
        onSell: '&',
        onDispose: '&',
        onReplace: '&',
        onDocDownload: '&',
        onDocUpload: '&',
        onGenerateReport: '&',
        userHof: '@'
    },
    controller: 'EquipmentGridController',
    ...gridTpl
};
