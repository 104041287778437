import watch from 'redux-watch';

/* @ngInject */
export function AdministrationAccountsSiteLocationLicencesController($ngRedux, $uiRouterGlobals, $mdDialog, LicenceService, AdministrationActions, Notifier) {
    var ctrl = this;
    var dialog = null;
    var unsubscribe = angular.noop;
    var onLicence = angular.noop;

    ctrl.$onInit = function() {
        unsubscribe = $ngRedux.connect(mapStateToThis, AdministrationActions)(ctrl);

        var watchLicence = watch($ngRedux.getState, 'administration.administrationAccountsSiteLocationLicence.data.id');
        onLicence = $ngRedux.subscribe(watchLicence(function(id) {
            onLicenceReady(id);
        }));
    };

    ctrl.onSelectLicence = function(event) {
        if (event.item.id === ctrl.licence.id)
            onLicenceReady(event.item.id);
        else
            ctrl.getSiteLocationLicence(event.item.id);
    };

    ctrl.onRemoveLicence = function(event) {
        var confirm = $mdDialog.confirm()
            .title('Are you sure?')
            .textContent('The licence will be permanently removed.')
            .ariaLabel('Remove Licence')
            .ok('Yes')
            .cancel('No');

        dialog = $mdDialog.show(confirm).then(function() {
            event.item.location_uuid = $uiRouterGlobals.params.slo;
            ctrl.removeSiteLocationLicence(event.item);
        }, angular.noop);
    };

    ctrl.onLicenceIsotopes = function(event) {
        let filter = {
            licenceCode: 'rdt',
            status: 'act',
            offset: 0,
            limit: -1
        }

        LicenceService.subjectLicences($uiRouterGlobals.params.slo, 'rdt', filter).then(function(data) {
            if (_.find(data.items, ['id', event.item.id])) {
                $ngRedux.dispatch({
                    type: 'TAB_SHOW',
                    id: 'app.administration.accounts.siteLocations',
                    state: ['app.administration.accounts.siteLocations.licenceIsotopes'],
                    stateParams: {
                        hof: $uiRouterGlobals.params.hof,
                        slo: $uiRouterGlobals.params.slo,
                        lic: event.item.id
                    }
                });
                $ngRedux.dispatch({
                    type: 'SELECT_LICENCE',
                    id: 'administrationAccountsLicenceIsotopesGrid',
                    licenceId: event.item.id
                });
            }
            else Notifier.warn('You cant add isotopes for this licence type');
        });
    }

    ctrl.onLicenceAppendix = function(event) {
        let filter = {
            licenceCode: 'rdt',
            status: 'act',
            offset: 0,
            limit: -1
        }
        $ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: 'app.administration.accounts.siteLocations',
            state: ['app.administration.accounts.siteLocations.licenceAppendix'],
            stateParams: {
                hof: $uiRouterGlobals.params.hof,
                slo: $uiRouterGlobals.params.slo,
                lic: event.item.id
            }
        });
        $ngRedux.dispatch({
            type: 'SELECT_LICENCE',
            id: 'administrationAccountsLicenceAppendixGrid',
            licenceId: event.item.id
        });
    }

    ctrl.newLicence = function() {
        if (ctrl.licence.id) {
            $ngRedux.dispatch({
                type: 'SITE_LOCATION_LICENCE_RESET',
                id: 'administrationAccountsSiteLocationLicence'
            });
        }
        $ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: 'app.administration.accounts.siteLocations',
            state: ['app.administration.accounts.siteLocations.licenceDetails'],
            stateParams: $uiRouterGlobals.params
        });
    };

    ctrl.$onDestroy = function() {
        if (dialog) $mdDialog.hide(dialog);
        unsubscribe();
        onLicence();
    };

    function onLicenceReady(id) {
        $ngRedux.dispatch({
            type: 'TAB_SHOW',
            id: 'app.administration.accounts.siteLocations',
            state: ['app.administration.accounts.siteLocations.licenceDetails'],
            stateParams: {
                hof: $uiRouterGlobals.params.hof,
                slo: $uiRouterGlobals.params.slo,
                lic: id
            }
        });
    }

    function mapStateToThis(state) {
        return {
            licence: state.administration.administrationAccountsSiteLocationLicence.data,
            title: [
                state.administration.administrationAccountsHeadOffice.name,
                state.administration.administrationAccountsSiteLocation.data.name,
                'Licences'
            ].filter(function(item) {
                return _.isString(item);
            }).join(' » ')
        };
    }
}
