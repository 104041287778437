import Pikaday from 'pikaday';
import svgCalendar from '../../../../../img/svg/calendar.svg';
import svgRemove from '../../../../../img/svg/remove.svg';
import moment from "moment/moment";
import {DATE_FORMAT} from "../../../../common/utils/util.constants";

/* @ngInject */
export function datePicker(Helpers, DATE_FORMAT) {
    return {
        restrict: 'E',
        require: ['^form', 'ngModel'],
        scope: {
            name: '@name',
            date: '=ngModel',
            label: '@',
            required: '=?required',
            dateFormat: '@dateFormat',
            maxDate: '=?maxDate',
            minDate: '=?minDate',
            change: '&onChange',
            disable: '<'
        },
        template: require('./date-picker.html'),
        link: function(scope, element, attrs, controllers) {
            const ICON_CALENDAR = svgCalendar;
            const ICON_REMOVE = svgRemove;

            scope.identifier = _.isUndefined(scope.name) ? Helpers.generateIdentifier() : scope.name;
            scope.dateFormat = _.isUndefined(scope.dateFormat) ? DATE_FORMAT.INPUT : scope.dateFormat;
            scope.required = _.isUndefined(scope.required) ? false : scope.required;

            scope.containerClass = '';
            scope.iconPath = ICON_CALENDAR;
            scope.inputElement = element.find('input')[0];
            scope.inputForm = controllers[0];
            scope.inputField = controllers[1];
            scope.inputField.$name = scope.identifier;
            scope.inputForm.$addControl(scope.inputField);
            scope.dateString = null;
            if (scope.date != null) {
                scope.dateString = Helpers.formatDate(scope.date, DATE_FORMAT.INPUT);
                scope.iconPath = ICON_REMOVE;
                angular.element(element.find('md-icon')).on('click', reset);
            }

            var modelWatch = scope.$watch('date', function(newValue, oldValue) {
                if (_.isUndefined(newValue) || _.isNull(newValue)) reset();
                else {
                    var newDate = newValue instanceof Date ? newValue.getTime() : null;
                    var oldDate = oldValue instanceof Date ? oldValue.getTime() : null;
                    if (newDate != oldDate) {
                        scope.iconPath = ICON_REMOVE;
                        angular.element(element.find('md-icon')).on('click', reset);
                        picker.setDate(scope.date);
                    }
                }
            });

            var pikaDayMaxDate = null;
            if( scope.maxDate ) { pikaDayMaxDate = scope.maxDate; }

            var pikaDayMinDate = null;
            if (scope.minDate) { pikaDayMinDate = scope.minDate; }

            var options = {
                maxDate: pikaDayMaxDate,
                minDate: pikaDayMinDate,
                field: scope.inputElement,
                format: scope.dateFormat,
                yearRange: [1900, new Date().getFullYear()+50],
                onSelect: function(date) {

                    scope.change();

                    scope.date = date;
                    scope.inputForm[scope.identifier].$setValidity('required', true);
                    scope.containerClass = '';
                    scope.iconPath = ICON_REMOVE;
                    angular.element(element.find('md-icon')).on('click', reset);
                    scope.$applyAsync();
                }
            };

            function reset() {
                scope.date = null;
                scope.dateString = null;
                scope.iconPath = ICON_CALENDAR;
                angular.element(element).blur();
                angular.element(element.find('md-icon')).off('click', reset);
                scope.$applyAsync();
            }

            var picker = new Pikaday(options);
            if (pikaDayMinDate) {
                let minMonth = pikaDayMinDate.getUTCMonth();
                let minYear = pikaDayMinDate.getUTCFullYear();
                picker.gotoDate(new Date(minYear, minMonth));
            }

            var validationListener = scope.$on('show-errors-check-validity', function(event, formName) {
                if (typeof formName !== 'undefined')
                    if (scope.inputForm.$name != formName) return;

                if (scope.required && _.isNull(scope.date)) {
                    scope.inputForm[scope.identifier].$setValidity('required', false);
                    scope.inputForm[scope.identifier].$setTouched();
                    scope.containerClass = 'md-input-invalid';
                }
            });

            element.on('$destroy', function() {
                modelWatch();
                validationListener();
                picker.destroy();
                scope.inputForm.$removeControl(scope.inputField);
            });
        }
    };
}
