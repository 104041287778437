import moment from 'moment';

/* @ngInject */
export function LicenceManager($mdDialog, NavigationService, LicenceService, Licence, UserService, Notifier, AuthService) {

    this.tplLicenceDetails = './employee-licence-dialog.html';

    var licenseManager = {
        accountSettings: false,
        UPDATE_COMPLETE: 'update_complete',
    };

    this.viewLicenses = function (subject) {
        var accountSettings = this.accountSettings;
        LicenceService.subjectLicences(subject.uuid, 'uli').then(function(result) {

            var template = './subject-licences-dialog.html';

            var isAdmin = false;
            isAdmin = AuthService.getUser().type == 'Administrator';
            if (isAdmin || accountSettings) {
                // TODO remove isAdmin from template
                isAdmin = true;
                template = './subject-licences-edit-dialog.html';
            }

            $mdDialog.show({
                controller: ViewLicensesController,
                templateUrl: template,
                parent: angular.element(document.body),
                locals: {
                    title: subject.firstName + ' ' + subject.lastName,
                    licenses: result.items,
                    types: getLicenseTypes(),
                    isAdmin: isAdmin
                },
                clickOutsideToClose: true
            });
        });
    };

    function ViewLicensesController($scope, title, licenses, types, isAdmin) {
        $scope.headerColor = NavigationService.getNavConfig().colorName;
        $scope.employee = title;
        $scope.items = licenses;
        $scope.documentTypes = types;
        $scope.isAdmin = isAdmin;

        $scope.inEdit = -1;
        $scope.modifyLicence = function (index, item) {
            $scope.inEdit = index;
        };


        $scope.cancelLicence = function () {
            $scope.inEdit = -1;
        };

        $scope.saveLicence = function (index, item) {
            LicenceService.editLicence(item).then(function (data) {

                var docIndex = _.findIndex($scope.documentTypes, ['id', item.typeId]);
                item.type = $scope.documentTypes[docIndex].name;

                if (data.status == 200 && !(data.data.lic_id == null || data.data.lic_ide == "null")) {

                    Notifier.success('Licence saved!');

                    var licence = new Licence();
                    licence.id = data.data.lic_id;
                    licence.type = data.data.license_type;
                    licence.expires = moment(data.data.exp_date).format('DD-MM-YYYY');
                    licence.uri = data.data.document_uri;

                    $scope.inEdit = -1;
                    item = licence;
                }
                else if (data.status == 200 && (data.data.lic_id == null || data.data.lic_id == "null")) {
                    Notifier.error('Problem with saving licence. Check expiry date or licence type.');
                }
                else if (data.status == 403) {
                    Notifier.error('Not able to save licence. Insufficient access rights.');
                    $scope.inEdit = -1;
                }
                else {
                    Notifier.error('Licence has not been saved.');
                    $scope.inEdit = -1;
                }
            });
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    }

    // TODO duplicate call needs refactoring on service level
    function getLicenseTypes() {
        return UserService.licenceTypes('123', 'uli').then(function (result) {
            return result;
        });
    }

    this.modifyLicence = function(licence, types) {
        return $mdDialog.show({
            controller: LicenceController,
            templateUrl: this.tplLicenceDetails,
            parent: angular.element(document.body),
            locals: {
                licence: licence,
                types: types
            },
            clickOutsideToClose: true,
            onComplete: function($scope) {
                $scope.licence = $scope.licenceClone;
            }
        });

        function LicenceController($scope, licence, types) {
            $scope.headerColor = NavigationService.getNavConfig().colorName;
            $scope.licence = new Licence();
            $scope.licenceClone = _.cloneDeep(licence);
            $scope.licenceTypes = types;

            $scope.maxLicenseIssueDate = new Date();
            $scope.minLicenseExpiryDate= new Date();

            $scope.modifyLicence = function() {
                $scope.$broadcast('show-errors-check-validity', 'licenceForm');
                if ($scope.licenceForm.$invalid) return;

                LicenceService.editLicence($scope.licence).then(function() {
                    _.assign(licence, $scope.licence);
                    Notifier.success('Licence was successfully modified');
                    $mdDialog.hide();
                }, function(error) {
                    Notifier.responseMessage(error);
                });
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };
        }
    };
}
