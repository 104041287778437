/*
*   List of Actions with TYPE that should be passed from accreditation.reducer.js to scheme.reducer.js
 */

export const PREFIX = 'ADD_DOCUMENT';

export const TYPES = {
    FETCH_SITE_LOCATION_DETAILS:        `${PREFIX}_FETCH_SITE_LOCATION_DETAILS`,
    FETCH_STANDARD_DETAILS:             `${PREFIX}_FETCH_STANDARD_DETAILS`,
    REFRESH_DOCUMENT_DETAILS:           `${PREFIX}_REFRESH_DOCUMENT_DETAILS`,
};
