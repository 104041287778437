export function VendorServiceTypeFactory() {
    return function VendorServiceType() {
        this.id = null;
        this.code = null;
        this.name = null;
        this.selected = 0;
        this.selectedVendor = null;
        this.vendors = [];
    };
}
