import API from '../../../../common/http/api.fetch';
import {TYPES} from '../assessment-reports-action-type.constants';
import {IDS} from '../assessment-reports-action-id.constants';
import {success, responseMessage} from '../../../../common/utils/util.notifier';
import {GRID_FETCH, GRID_FILTER, GRID_RELOAD} from '../../../../common/grid/grid.constants';
import {TAB_ENABLE, TAB_SHOW} from "../../../../common/tabs/tabs.constants";
import camelCase from 'lodash/camelCase';
import I18N from "../../../../common/utils/util.i18n";

export const fetchAssessmentReportsGrid = (filter) => {
    return dispatch => {
        if(!filter.slo_uuid)
            return;
        return API.fetch({
            url: '/accreditation/list-assessment-reports-grid',
            method: 'GET',
            params: filter,
        }).then(response => {
            dispatch({
                type:   GRID_FETCH,
                id:     IDS.accAssessmentReports,
                path:   IDS.accAssessmentReportsGrid,
                ...response.data,
                items : response.data.items.map(item => ({
                    ...item,
                    id: item.ado_id,
                    ado_id: undefined,
                    filename: item.doc_url.split('/').pop().replace(/^[0-9]*-(.*)/, '$1'),
                    icon: item.file_missing ? 'file-red.svg' : 'file-gray.svg',
                })),
                itemsCount: parseInt(response.data.items_count)
            })
        }, error => {
            responseMessage(error);
        })
    }
}

export const removeAssessmentReport = (data) => {
    return dispatch => {
        return API.fetch({
            url: '/accreditation/remove-assessment-report',
            method: 'POST',
            data
        }).then(response => {
            dispatch({
                type:   GRID_RELOAD,
                id:     IDS.accAssessmentReports,
                path:   IDS.accAssessmentReportsGrid,
            })
            success('COMMON.SERVER_RESPONSE.ITEM_REMOVED');
        }, error => {
            responseMessage(error);
        });
    };
}

export const fetchSiteLocationDetails = (siteLocationUuid) => {
    return dispatch => {
        return API.fetch({
            url: `/location/sitelocation/${siteLocationUuid}`,
            method: 'GET',
            params: {}
        }).then(response => {
            const details = response.data[0][0];
            dispatch({
                type: TYPES.FETCH_SITE_LOCATION_DETAILS,
                id: IDS.accAssessmentReports,
                data: {
                    siteLocationUuid,
                    siteLocationName: details.name,
                    fiscalYearStart: parseInt(details.fiscal_year_start) - 1,
                    sloQualityManagers: response.data[1].map(item => ({...item, employee_uuid: item.subject_uuid})),
                }
            })
        })
    }
}

export const fetchDocumentDetails = (id) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/document-details',
            method: 'GET',
            params: { id }
        }).then( response => {
            const reviews = response.data[0]
                .map(item => camelCaseObject(item))
                .map(item => ({...item,
                    nameAndUsername: item.name + (item.username ?` (${item.username})` : ''),
                    statusName: I18N.translate(`ACCREDITATION.DOCUMENT_EVALUATION.STATUS.${item.statusCode.toUpperCase()}`)
                }))
            const review = reviews?.find(item => item.authorised)
            response = camelCaseObject(JSON.parse(response.data[2][0]['@params']));
            const filename = response.url.split('/').pop().replace(/^[0-9]*-(.*)/, '$1');
            const limit = 128;
            dispatch({
                type: TYPES.FETCH_DOCUMENT_DETAILS,
                id: IDS.accAssessmentReports,
                details: {
                    ...response,
                    filename:  filename.length > limit ? `${filename.substr(0, limit)}...` : filename,
                    filenameTooltip: filename,
                    review,
                    originalReview: review ? {...review} : null,
                },
                reviews,
            })
            return response
        },
        error => responseMessage(error) );
    };
}

export const fetchSchemeDetails = (code) => {
    return dispatch => {
        return API.fetch({
            url:    '/accreditation/scheme-details',
            method: 'GET',
            params: { code }
        }).then(({data}) => {
            dispatch({
                type: TYPES.FETCH_SCHEME_DETAILS,
                id: IDS.accAssessmentReports,
                data: { schemeDetails: data.details }
            })
            return data.details
        }, error => {
            responseMessage(error);
        });
    };
}

export const switchToDetailsTab = ($timeout, stateParams) => {
    return dispatch => {
        dispatch({
            type: TAB_ENABLE,
            id: IDS.app_accreditation_scheme_assessment_reports,
            state: [ IDS.app_accreditation_scheme_assessment_reports_details]
        })

        $timeout(() => {
            dispatch({
                type: TAB_SHOW,
                id: IDS.app_accreditation_scheme_assessment_reports,
                state: IDS.app_accreditation_scheme_assessment_reports_details,
                stateParams,
            })
        })
    }
}


function camelCaseObject (obj) {
    return Object.entries(obj).reduce(
        (result, item) => ( {...result, [camelCase(item[0])]: item[1]} ), {}
    );
}