export const dashboardState = {
    name: 'app.dashboard',
    component: 'dashboard',
    url: '/',
    params: {
        title: 'Dashboard'
    }
};

export const dashboardAnalyticsState = {
    name: 'app.analytics',
    component: 'dashboardAnalytics',
    url: '/analytics',
    params: {
        title: 'Analytics'
    }
};
