import { GridController } from '../../../../../common/grid/grid.controller';

/* @ngInject */
export function AdministrationAccountsEmployeesGridController($controller, $ngRedux, $state, NavigationService, EmployeesActions) {
    var ctrl = this;

    Object.setPrototypeOf(ctrl, new GridController($ngRedux, $state, NavigationService));

    ctrl.$onInit = function() {
        ctrl.module = 'administration';
        ctrl.identifier = 'administrationAccountsEmployeesGrid';
        ctrl.autoload = false;
        ctrl.filterComponent = false;
        ctrl.exportComponent = false;

        ctrl.columns = [
            {title: '#',                mapper: null},
            {title: 'Username',         mapper: 'username'},
            {title: 'First Name',       mapper: 'firstName'},
            {title: 'Last Name',        mapper: 'lastName'},
            {title: 'Occupation',       mapper: 'occupation.name'},
            {title: 'Site Location',    mapper: 'siteLocation.name'},
            {title: '',                 mapper: 'warning'},
            {title: '',                 mapper: 'critical'}
        ];

        ctrl.unsubscribe = $ngRedux.connect(ctrl.mapState().bind(ctrl), EmployeesActions)(ctrl);
        ctrl.dataAction = ctrl.getEmployees;
        ctrl.filter.identifier = ctrl.identifier;

        ctrl.init.call(ctrl);
    };

    ctrl.$onDestroy = function() {
        ctrl.destroy();
    };
}
