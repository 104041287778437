/* @ngInject */
export function HelpTutorialsController($scope, $rootScope, $q, $location,
                                 $mdDialog, Notifier, $timeout,
                                 NetSpeedService, SAFETY_PLAN_REVIEW_NET_SPEED, AuthService) {

    $scope.reviewVideos = null;
    $scope.selectedVideo = null;
    $scope.initializationInProgress = true;

    var isAdmin = false;
    var isRsc = false;
    var isEmployee = false;


    var user = AuthService.getUser();
    isAdmin = false;

    if (user.type == 'Administrator') {
        isAdmin = true;
    } else if ((user.typeCode == 'sro') || (user.typeCode == 'aso') || (user.typeCode == 'drs') || (user.typeCode == 'hrs')) {
        isRsc = true;
    } else {
        isEmployee = true;
    }


    $scope.fetchReviewData = function () {

        $scope.reviewVideos = [];

        if (isAdmin) {
            $scope.reviewVideos.push(
                {
                    videoSectionNumber: 0,
                    name: 'RSC Admin Tutorial',
                    uri: "/media/video/help/RSC_Admin_Tutorial.mp4"
                });

            $scope.reviewVideos.push(
                {
                    videoSectionNumber: 1,
                    name: 'RSC Equipment Tutorial',
                    uri: "/media/video/help/RSC_Equipment_Tutorial.mp4"
                });

            $scope.reviewVideos.push(
                {
                    videoSectionNumber: 2,
                    name: 'RSC Employee Tutorial',
                    uri: "/media/video/help/RSC_Employee_Tutorial.mp4"
                });

            $scope.reviewVideos.push(
                {
                    videoSectionNumber: 3,
                    name: 'RSC RSPP Tutorial',
                    uri: "/media/video/help/RSC_RSPP_Tutorial.mp4"
                });

            $scope.reviewVideos.push(
                {
                    videoSectionNumber: 4,
                    name: 'Employee RSPP Tutorial',
                    uri: "/media/video/help/Employee_RSPP_Tutorial.mp4"
                });

            $scope.reviewVideos.push(
                {
                    videoSectionNumber: 5,
                    name: 'Employee Licence Tutorial',
                    uri: "/media/video/help/Employee_Licence_Tutorial.mp4"
                });
        }

        if (isRsc) {

            $scope.reviewVideos.push(
                {
                    videoSectionNumber: 0,
                    name: 'RSC Admin Tutorial',
                    uri: "/media/video/help/RSC_Admin_Tutorial.mp4"
                });

            $scope.reviewVideos.push(
                {
                    videoSectionNumber: 1,
                    name: 'RSC Equipment Tutorial',
                    uri: "/media/video/help/RSC_Equipment_Tutorial.mp4"
                });

            $scope.reviewVideos.push(
                {
                    videoSectionNumber: 2,
                    name: 'RSC Employee Tutorial',
                    uri: "/media/video/help/RSC_Employee_Tutorial.mp4"
                });

            $scope.reviewVideos.push(
                {
                    videoSectionNumber: 3,
                    name: 'RSC RSPP Tutorial',
                    uri: "/media/video/help/RSC_RSPP_Tutorial.mp4"
                });
        }

        if (isEmployee) {

            $scope.reviewVideos.push(
                {
                    videoSectionNumber: 0,
                    name: 'Employee RSPP Tutorial',
                    uri: "/media/video/help/Employee_RSPP_Tutorial.mp4"
                });

            $scope.reviewVideos.push(
                {
                    videoSectionNumber: 1,
                    name: 'Employee Licence Tutorial',
                    uri: "/media/video/help/Employee_Licence_Tutorial.mp4"
                });
        }


        if ($scope.reviewVideos.length > 0) {
            $scope.selectedVideo = $scope.reviewVideos[0];
        }

    };

    $scope.videoPlaybackEnded = function (video) {
        //console.log("Video with uri="+video.uri+" and videoSectionNumber="+video.videoSectionNumber+"playback has ended!");
        var maxVideoPresentationOrder = $scope.reviewVideos.reduce(function (max, item) {
            return parseInt(item.presentationOrder) > max ? parseInt(item.presentationOrder) : max;
        }, 0);
    };

    $scope.checkNetSpeed = function () {
        NetSpeedService.testNetSpeed()
            .then(function (result) {
                Notifier.info("Measured net speed is:" + result + " Kbps.");
                console.log("Measured net speed is:" + result + " kbs.");
                if (result < SAFETY_PLAN_REVIEW_NET_SPEED.BANDWIDTH_LIMIT) {
                    Notifier.warn("Your network connection seems to be slow. You may experience difficulties watching presentation video. In case of video stuttering please pause playback and wait a bit for video to buffer, then continue.");
                }
            }, function (error) {
                console.log("Could not measure speed, error:" + error);
            });
    }

    $scope.checkNetSpeed();
    $scope.fetchReviewData();
}
