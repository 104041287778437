/** @namespace Reducers.ProtectiveEquipment */

import { GridReducer } from '../common/grid/grid.reducer';
import merge from 'lodash/merge';
import set from 'lodash/set';
import update from 'lodash/update';

/**
 * @function protectiveEquipmentReducer
 * @param state {object}
 * @param action {object}
 * @returns {object} Redux Store
 * @memberOf Reducers
 */
export function protectiveEquipmentReducer(state, action) {

    if (typeof state === 'undefined') {
        return {
            protectiveEquipmentGrid: merge(GridReducer(), {
                filter: {
                    location: null,
                    type: null
                }
            }),
            protectiveEquipmentHistoryGrid: merge(GridReducer(), {
                filter: {
                    equipmentId: null
                }
            }),
            protectiveEquipmentTestsGrid: merge(GridReducer(), {
                filter: {
                    equipmentId: null,
                    status: 'act'
                }
            }),
            protectiveEquipmentTestReportsGrid: merge(GridReducer(), {
                filter: {
                    equipmentId: null,
                    location: null,
                    type: null
                }
            })
        };
    }

    switch (action.type) {
        case 'GRID_FILTER':
            if (action.id === 'protectiveEquipmentTestsGrid') {
                return Object.assign({}, state, {
                    protectiveEquipmentTestsGrid: set(GridReducer(state.protectiveEquipmentTestsGrid, set(action, 'type', 'PAGINATE')), 'filter.equipmentId', action.equipmentId)
                });
            }
        case 'LOCATION_SELECTED':
            if (action.id === 'protectiveEquipmentGrid') {
                return Object.assign({}, state, {
                    protectiveEquipmentGrid: set(GridReducer(state.protectiveEquipmentGrid, set(action, 'type', 'PAGINATE')), 'filter.location', action.uuid)
                });
            }
            if (action.id === 'protectiveEquipmentTestReportsGrid') {
                return Object.assign({}, state, {
                    protectiveEquipmentTestReportsGrid: set(GridReducer(state.protectiveEquipmentTestReportsGrid, set(action, 'type', 'PAGINATE')), 'filter.location', action.uuid)
                });
            }
        case 'EQUIPMENT_TYPE_SELECTED':
            if (action.id === 'protectiveEquipmentGrid') {
                return Object.assign({}, state, {
                    protectiveEquipmentGrid: set(GridReducer(state.protectiveEquipmentGrid, set(action, 'type', 'PAGINATE')), 'filter.type', action.equipmentType)
                });
            }
            if (action.id === 'protectiveEquipmentTestReportsGrid') {
                return Object.assign({}, state, {
                    protectiveEquipmentTestReportsGrid: set(GridReducer(state.protectiveEquipmentTestReportsGrid, set(action, 'type', 'PAGINATE')), 'filter.type', action.equipmentType)
                });
            }
        case 'EQUIPMENT_HISTORY_SELECTED':
            if (action.id === 'protectiveEquipmentHistoryGrid') {
                return Object.assign({}, state, {
                    protectiveEquipmentHistoryGrid: set(GridReducer(state.protectiveEquipmentHistoryGrid, set(action, 'type', 'PAGINATE')), 'filter.equipmentId', action.equipmentId)
                });
            }
        case 'PPE_TEST_STATUS_SELECTED':
            if (action.id === 'protectiveEquipmentTestsGrid') {
                return Object.assign({}, state, {
                    protectiveEquipmentTestsGrid: set(GridReducer(state.protectiveEquipmentTestsGrid, set(action, 'type', 'PAGINATE')), 'filter.status', action.status)
                });
            }
        case 'GRID_RESET':
            if (action.id === 'protectiveEquipmentGrid') {
                return Object.assign({}, state, {
                    protectiveEquipmentGrid: update(GridReducer(state.protectiveEquipmentGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            location: null,
                            type: null
                        });
                    })
                });
            }
            if (action.id === 'protectiveEquipmentTestsGrid') {
                return Object.assign({}, state, {
                    protectiveEquipmentTestsGrid: update(GridReducer(state.protectiveEquipmentTestsGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            status: 'act'
                        });
                    })
                });
            }
            if (action.id === 'protectiveEquipmentTestReportsGrid') {
                return Object.assign({}, state, {
                    protectiveEquipmentTestReportsGrid: update(GridReducer(state.protectiveEquipmentTestReportsGrid, action), 'filter', function(filter) {
                        return Object.assign({}, filter, {
                            location: null,
                            type: null
                        });
                    })
                });
            }
        case 'SHOW_LOADING':
        case 'PAGINATE':
        case 'GRID_RELOAD':
        case 'GRID_FETCH':
            if (action.id === 'protectiveEquipmentGrid') {
                return Object.assign({}, state, {
                    protectiveEquipmentGrid: GridReducer(state.protectiveEquipmentGrid, action)
                });
            }
            if (action.id === 'protectiveEquipmentHistoryGrid') {
                return Object.assign({}, state, {
                    protectiveEquipmentHistoryGrid: GridReducer(state.protectiveEquipmentHistoryGrid, action)
                });
            }
            if (action.id === 'protectiveEquipmentTestsGrid') {
                return Object.assign({}, state, {
                    protectiveEquipmentTestsGrid: GridReducer(state.protectiveEquipmentTestsGrid, action)
                });
            }
            if (action.id === 'protectiveEquipmentTestReportsGrid') {
                return Object.assign({}, state, {
                    protectiveEquipmentTestReportsGrid: GridReducer(state.protectiveEquipmentTestReportsGrid, action)
                });
            }
        default:
            return state;
    }
}
